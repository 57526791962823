<div style="padding: 7px 10px; color: #c7c2c2;">
    <div class="dropdown">
        <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
            aria-expanded="false">
            <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
        </div>
        <div tabindex="-1" class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
            style="width: 100%;">
            <div class="context-menu-item pb-3" (click)="updateStatus('on hold')">
                <span class="context-menu-text text-center">On Hold</span>  
            </div>
            
            <div class="context-menu-item pb-3" (click)="updateStatus('closed')">
                <span class="context-menu-text text-center">Closed</span>  
            </div>
        </div>
    </div>
</div>