<app-dashboard>
    <app-page-header-title
      mainTitle="Repeating Invoice"
      subTitle="Home"
      backURL="/">
      <div class="full-width text-align-right">
        <button type="button" class="eCount-def-btn ml-4" (click)="getSelectedRowData()">Create Invoice</button>
      </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center ">
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData(0)" [routerLinkActive]="['active']" routerLink="/income/repeating-invoice/0">All ({{categoryCount.all}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData(1)" [routerLinkActive]="['active']" routerLink="/income/repeating-invoice/1">Daily ({{categoryCount.daily}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData(2)" [routerLinkActive]="['active']" routerLink="/income/repeating-invoice/2">Weekly ({{categoryCount.weekly}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData(3)" [routerLinkActive]="['active']" routerLink="/income/repeating-invoice/3">Monthly ({{categoryCount.monthly}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData(4)" [routerLinkActive]="['active']" routerLink="/income/repeating-invoice/4">Yearly ({{categoryCount.yearly}})</a>
      </li>
    </ul>

    <div class="row pt-3">
        <ag-grid-angular
            style="font-size: 14px;"
            class="ag-theme-balham col"
            [rowData]="filteredInvoice"
            [columnDefs]="columnDefs"
            [domLayout]="domLayout"
            [defaultColDef]="defaultColDef"
            [rowSelection]="rowSelection"
            [suppressRowClickSelection]="true"
            (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
    </div>
    
    </app-dashboard>
