import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AddAccountComponentComponent } from '../add-account-component/add-account-component.component'
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service';
import { ChartOfAccounts } from '../../../models/accounting.model'
import  jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
//ngRx
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditChartOfAccountComponent } from '../edit-chart-of-account/edit-chart-of-account.component';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MainServiceService } from '../../../service/Main/main-service.service'
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-chart-of-accounts',
  templateUrl: './chart-of-accounts.component.html',
  styleUrls: ['./chart-of-accounts.component.css']
})
export class ChartOfAccountsComponent implements OnInit {
  AccountingData: any
  chartOfAccounts: ChartOfAccounts[]
  chartOfAccountsMenu: any = []
  chartOfAccountsData: any = []
  searchText:string;
  deletableAccounts = [];
  enableDelete = false;
  redirectUrl: string='/fixed-asset/setup/draft';
  hasStep: boolean=false;
  selectedListForDelete = [];

  constructor(private modalService: NgbModal,
    public ngxSmartModalService: NgxSmartModalService, 
    private accountingService: AcountingServiceService, 
    public authService: AuthService,
    private mainService: MainServiceService,
    private router: Router,
    private store: Store<AppState>, 
    private spinner: NgxSpinnerService,
    private toaster: ToastrService) { 
    store.select(AccountingData => AccountingData).subscribe((x)=>{ this.AccountingData = x.Accounting })
  }

  ngOnInit(): void {
    //this.checkStepRequired();
    this.getChartOfAccounts();
  }

  AddAccount=()=>{
    this.ngxSmartModalService.create('AddAccountComponent', AddAccountComponentComponent).open();
  }

  getChartOfAccounts = () => {
    this.spinner.show()
    this.accountingService.GetChartOfAccountsList().subscribe((x: ChartOfAccounts[]) => {
      this.chartOfAccounts = x
      this.spinner.hide();
      x.map(menu=>{ 
        this.store.dispatch({
          type: 'ADD_COIN',
          payload: x
        })
        if(!menu.AccountCode){
          this.chartOfAccountsMenu.push(menu);
        }else{
          this.chartOfAccountsData.push(menu)
        }
      }) 
    },(err)=>{
      this.spinner.hide();
    })
  }

  getAccountType=(item)=>{
    let type = this.chartOfAccountsMenu.filter(x => x.Id == item)
      return type[0].AccountName;    
  }
  getFilteredData=(Id)=>{
    let Accounts = this.chartOfAccounts.filter(x => x.ParentId == Id)
    return Accounts;
  }
  dataLog=(con)=>{
    console.log(con);
  }

  convetToPDF=()=> {
    var data = document.getElementById('chartOfAccount');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('chartOfAccount.pdf');
    })
 }
 editAccount=(id:number)=>{
     const modalRef = this.modalService.open(EditChartOfAccountComponent, { size: 'lg', backdrop: 'static', keyboard: false, centered: true });
     modalRef.componentInstance.accountId =id;
     modalRef.result.then((result) => {
       this.getChartOfAccountAfterEdit();
     }, (reason) => {
     });
 }

 getChartOfAccountAfterEdit(){
   this.accountingService.GetChartOfAccountsList().subscribe((x: ChartOfAccounts[]) => {
     this.chartOfAccounts = x
   });
 }

 deleteAccounts(){
   if(!this.enableDelete) {
    this.toaster.warning("Please select accounts!");
    return;
   }
   if(this.deletableAccounts.filter(x => x.Amount != 0).length != 0) {
    this.toaster.warning("You can not delete accounts which have not zero amount!");
    return;
   }
   this.spinner.show();
   this.accountingService.DeleteAccounts(this.deletableAccounts).subscribe(x => {
    if(x.Success) {
      this.toaster.success(x.Message);
      location.reload();
    }
    else {
      this.toaster.error(x.Message);
    }
    this.spinner.hide();
   });
 }

 selectToDelete (account: any){
   let index = this.deletableAccounts.findIndex(x => x == account);
   index == -1 ? this.deletableAccounts.push(account) : this.deletableAccounts.splice(index, 1);
   this.enableDelete = this.deletableAccounts.length == 0 ? false : true;  
 }

 formatAmount(amount){
  return amount < 0 ? '('+Math.abs(amount)+')' : amount;
 }

}