
<app-dashboard>
    <app-page-header-title
        mainTitle="Account Transaction"
        subTitle="Home"
        backURL="{{returnUrl}}">
        <!-- <input  type="text" [(ngModel)]="searchText" placeholder="search" class="searchBox"> -->
        
    </app-page-header-title>
    <div class="row d-block">
        <div class="col-md-12 d-flex">
            <div class="col-md-4 pl-0px d-flex">
                <div class="col-md-12 p-all-0px d-flex">
                    <div class="col-md-3 p-all-0px f-ss-14px">
                        <label class="p-t-7px font-weight-bold">Accounts</label>
                    </div>
                    <div class="form-group col-md-9 d-block p-all-0px">
                        <ng-select [items]="accountNameList" 
                                   [searchable]="true"  
                                   [multiple]="true" 
                                   [clearable]="false" 
                                   bindLabel="Name" 
                                   bindValue="Id"  
                                   (change)=(ClearBySelect(selectedAccountIdList))
                                   [(ngModel)]="selectedAccountIdList">
                                   <ng-template ng-header-tmp>
                                     <div>
                                         <button class="btn btn-link" (click)="onSelectAll()">All</button>
                                     </div>
                                  </ng-template>
                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngIf="accountNameList.length === selectedAccountIdList.length">
                                        <span class="ng-value-label">Selected All</span>
                                        <span class="ng-value-icon right" (click)="onClearAll()" aria-hidden="true">×</span>
                                    </div>
                                    <div style="display: inline-flex;" *ngIf="accountNameList.length > selectedAccountIdList.length">
                                        <div *ngFor="let item of items; index as i">
                                            <div class="ng-value" *ngIf="i < 1" >
                                                <span class="ng-value-label">{{item.Name}}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                            </div>
                                        </div>
                                        <div class="ng-value" *ngIf="items.length > 1">
                                            <span class="ng-value-label">{{items.length - 1}} more...</span>
                                        </div>
                                    </div>
                                </ng-template>
    
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" [ngModel]="item$.selected" class="custom-control-input"/>
                                        <label class="form-check-label ml-2 custom-control-label">{{item.Name}}</label>
                                    </div>
                                </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-md-8 row ml-2">
                    
                    <div class="form-group p-all-0px col-md-3">
                        <input class="form-control" type="date"  placeholder="From Date" [(ngModel)]="fromDate" id="atest" data-date="" data-date-format="D MMM YYYY"  (change)="show($event)">
                    </div>

                    <div class="form-group p-all-0px col-md-3 ml-3">
                        <input class="form-control" type="date" [(ngModel)]="toDate" id="atest1" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)">
                    </div>
                    <button type="button" (click)="reportGenerate()" class="eCount-def-btn ml-4">Generate Report</button>
            </div>
        </div>
    </div>
    
    <div id="printGeneralReport" >
        <div class="customReportPage">
        <div class="report-header row" style="margin-bottom: 10px;">
            <div class="col-md-8">
            <div>
               <p class="txtsize">Account Transaction Reports</p>
           </div>
           <div class="row ml-0">
               <span>{{companyName}}</span>
               
               <p><span class="ml-2 mr-2 f-ss-14px">|</span>For the period {{convertDateIntoDisplay(fromDate)}} to {{convertDateIntoDisplay(toDate)}}</p>
               
           </div>
           
           </div>
           <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
               <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
           </div>
   
           </div>

        <div class="sticky">
            
            <div id="tableHeader" class="tableHeader d-flex">
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Date</p>
                </div>
                <div class="tableHeaderColumn col-md-1">
                    <p class="tableHeaderText pt-1">Source</p>
                </div>
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Description</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1" style="float: left;">Reference</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">Debit</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">Credit</p>
                </div>
                <div class="tableHeaderColumn col-md-1">
                    <p class="tableHeaderText pt-1">Balance</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">Gross</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">VAT</p>
                </div>
                <div class="tableHeaderColumn col-md-1 ">
                    <p class="tableHeaderText pt-1">AIT</p>
                </div>
            </div>
        </div>
        <div id="tableBody"  class="AccountSection" *ngFor="let item of allAccountTransaction">
            <div class="x-grid-group-title  f-ssb-14px">
                <h1 style="border-bottom: 1px solid #999;">{{item.Name}}</h1>
            </div>
            <div class="d-flex f-ss-14px" [ngClass]="{
                'paddingTopBottom': itemInner.IsOpeningBalance,
                'normalRowSpaceing': !itemInner.IsOpeningBalance
            }" *ngFor="let itemInner of item.Items">
                <div class="tableHeaderColumn2 col-md-2">
                    <span *ngIf="itemInner.IsOpeningBalance" class="runningBalanceText">{{itemInner.SourceName}}</span>
                    <span *ngIf="!itemInner.IsOpeningBalance" class="runningBalanceText">{{convertDateIntoDisplay(itemInner.PostingDate)}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="tableHeaderText"></span>
                </div>
                <div class="tableHeaderColumn2 col-md-2">
                    <span class="tableHeaderText">{{itemInner.Narration}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="tableHeaderText">{{itemInner.ReferenceNo}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1 ml-2">
                    <span class="float-right">{{itemInner.DebitView ? itemInner.DebitView : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.CreditView ? itemInner.CreditView : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.RunningBalanceValue ? itemInner.RunningBalanceValue : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.GrossAmountValue ? itemInner.GrossAmountValue : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.TaxAmount ? itemInner.TaxAmount : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.AdvanceTaxAmount ? itemInner.AdvanceTaxAmount : '-'}}</span>
                </div>
            </div>

            <div class="d-flex normalRowSpaceing f-ss-14px">
                <div class="tableHeaderColumn2 col-md-6">
                    <span class="runningBalanceText font-weight-bold colorBlack">Total {{item.Name}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1 ml-2">
                    <span class="float-right">{{item.DebitTotalView}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right font-weight-bold colorBlack">{{item.CreditTotalView}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right font-weight-bold colorBlack">{{item.LastRunningBalance}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.GrossAmountTotalView}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.TaxAmountTotal}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.TotalAdvanceTaxAmount}}</span>
                </div>
            </div>
            <div  *ngIf="item.HasClosingBalance" class="paddingTopBottom d-flex f-ss-14px ">
                <div class="tableHeaderColumn2 col-md-6">
                    <span class="runningBalanceText">Closing Balance</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1 ml-2">
                    <span class="float-right" *ngIf="item.IsClosingBalanceAsDebit">{{item.LastRunningBalance}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right" *ngIf="!item.IsClosingBalanceAsDebit">{{item.LastRunningBalance}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.LastRunningBalance}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">-</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">-</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">-</span>
                </div>
            </div>   
        </div>
        <div class="paddingTopBottom mr-t-20px d-flex f-ss-14px" style="background-color: #f9f9f9;">
            <div class="tableHeaderColumn2 col-md-2">
                <span class="runningBalanceText">Total</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="tableHeaderText"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-2">
                <span class="tableHeaderText"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="tableHeaderText"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-1 ml-1">
                <span class="float-right">{{totalDebit}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalCredit}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalGrossAmount}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalTaxAmount}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalAdvanceTaxAmount}}</span>
            </div>
        </div>
    </div>
    </div>
</app-dashboard>

