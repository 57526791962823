<app-dashboard>
    <app-page-header-title mainTitle="{{'SIDEBAR.trailbalance'|translate}}" subTitle="Home" backURL="/">
     
           

    </app-page-header-title>
    <div class="row d-block">
        
        <div class="col-md-12 d-flex mrl">
            
                <div class="form-group col-md-2">
                    <input class="form-control" style="outline: none;" type="date" [(ngModel)]="toDate" id="tbtest" data-date="" data-date-format="D MMM YYYY"  (change)="show($event)">
                </div>
                <button type="button" (click)="reportGenerate()" class="eCount-def-btn ml-4">Generate Report</button>
            </div>
    </div>

    <div id="printGeneralReport" class="customReportPage f-ss-14px">
        <div class="report-header row" style="margin-bottom: 10px;">
            <div class="col-md-8">
            <div>
               <p class="txtsize">Trial Balance</p>
           </div>
           <div class="row ml-1">
               <span class="f-ss-14px">{{companyName}}</span>
               
               <p class="f-ss-14px"><span class="ml-2 mr-2 f-ss-14px">|</span>{{convertDateIntoDisplay(toDate)}}</p>
               
           </div>
           
           </div>
           <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
               <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
           </div>
   
           </div>
        <div>
            <div class="tableHeader d-flex">
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText">Account Code</p>
                </div>
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText">Account</p>
                </div>
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText">Account Type</p>
                </div>
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText float-right">Debit</p>
                </div>
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText float-right">Credit</p>
                </div>
            </div>
        </div>
        <div class="AccountSection" *ngFor="let item of trialBalanceData.Data; let i=index"
            [ngClass]="{'lastRow': trialBalanceData.Data.length == i+1}">
            <div class="d-flex normalRowSpaceing">
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText">{{item.AccountCode}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText">{{item.AccountName}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText">{{item.AccountType}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right text-link" (click)="openAccountTransaction(item.AccountId)">{{item.DebitAmountView}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right text-link" (click)="openAccountTransaction(item.AccountId)">{{item.CreditAmountView}}</span>
                </div>
            </div>
        </div>
        <div class="AccountSection">
            <div class="d-flex normalRowSpaceing">
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText"
                        style="color: black;font-size: 14px;font-weight: bold;">Total</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText"></span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText"></span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right"
                        style="color: black;font-size: 14px;font-weight: bold;">{{trialBalanceData.TotalDebit}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right"
                        style="color: black;font-size: 14px;font-weight: bold;">{{trialBalanceData.TotalCredit}}</span>
                </div>
            </div>
        </div>
    </div>
</app-dashboard>