<app-dashboard>
    <app-page-header-title [customTitleTemplate]="anchorgrp"
        mainTitle=" {{'Accounting.conversionBalances' | translate}}  {{convertDateIntoDisplay(previousConversionDate)}}"
        subTitle="Home" backURL="/">
        <ng-template #anchorgrp>
            <!-- <a [hidden]="showHideDateIcon" class="anchorBlue" (click)="openConversionDatePopup()">Set Conversion
                Date</a> -->
            <a [hidden]="!showHideDateIcon" (click)="openConversionDatePopup()">
                <img style="width: 16px;height: 16px;margin-bottom: 3px;margin-left: 10px;"
                    src="../../../../assets/image/changeConversionDate.png" />
            </a>
        </ng-template>
        <div class="full-width text-align-right" *ngIf="showStrartDateDiv == true">
            <button type="button"  (click)="saveConversionBalance()" class="eCount-def-btn ml-4">Save</button>
        </div>
    </app-page-header-title>
    <div class="box-left" *ngIf="showStrartDateDiv==false && showBusinessFeature == true">

        <div class="boxs x-box ml-4 mt-4" >
            <div class="">
                <input class="ml-2" type="radio" name="radiogroup" [value]="1" (change)="changeOnSelection($event)">
                <label class="mt-4 fnt-wt ml-2" for="1">New Business</label>
                <p class="fss-14px txt-lf">Staring a new business ? select "new business" and enoiv other features in Iquidi.</p>
              </div>
              <div class="">
                <input class="ml-2" type="radio" name="radiogroup" [value]="2" (change)="changeOnSelection($event)">
                <label class="mt-4 fnt-wt ml-2" for="2">Set Existing Balance</label>
                <p class="fss-14px txt-lf">To set up previous business informations select "set existing balance" and provide informations of past conversions.</p>
              </div>
              <div class="">
                <input class="ml-2" type="radio" name="radiogroup" [value]="3" (change)="changeOnSelection($event)">
                <label class="mt-4 fnt-wt ml-2" for="2">Later</label>
                <p class="fss-14px txt-lf">Will set existing balance later.</p>
              </div>
        </div>
       <div class="text-align-center mt-4" style="margin-left: 380px;">
         <button class="eCount-def-btn" (click)="routeDecider()">Continue</button>
       </div>
    </div>
    <div class="row">

        <div class="boxs co-crud-no-items ml-4 mt-4 bs" *ngIf="showStrartDateDiv==false && showBusinessFeature == false">
            <div class="fa-crud-no-items-watermark" style="margin-left: 380px;margin-top: 50px;" >
            </div>
            <div class="txt pt" id="component-1150" style="margin:10px;margin-left: 220px;">
                <p class="fa-crud-no-items-label">Choose a Start Date</p>
    
                Enter the date that you began processing all your transaction in eCount.It's easier
                when you set your existing date to be the start of a Tax period.Tips for choosing an existing date.
            </div>
            <button class="btn_design" (click)="openConversionDatePopup()">Set Existing date</button>
        </div>
    </div>
    <div class="bodySection" *ngIf="showStrartDateDiv===true">
        <div class="row ma-0px" style="margin:0px;" id="conversionbalance">
            <div class="w-100 col pa-0px" style="padding:0px;">
                <div id="comparativebalance">
                    <div class="col-sm-12 pa-0px" style="margin-top: 10px;display: inline-flex; padding: 0px;">        
                        <div class="col-sm-8 pa-0px" style="padding-left: 0; padding-right: 20px;">
                            <div class="mt-4 col-sm-12">
                                <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Account Name</th>
                                        <th>Debit</th>
                                        <th>Credit</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    
                                    <tbody  *ngFor="let item of rowData;let i=index">
                                    
                                    <tr >
                                        
                                    <td class="grid-cell">
                            
                                        <input type="text" class="form-control"  [(ngModel)]="rowData[i].AccountFullName"  appDecimaNumericValidator >
                                   
                                    </td>
                                        <td class="grid-cell">
                                            <input type="text" class="form-control" [(ngModel)]="rowData[i].Debit" (change)="calculateDebitTotalData()" (focus)="onFocusEvent($event)"  appDecimaNumericValidator >
                                        </td>
                            
                                        <td class="grid-cell">
                                            <input type="text" class="form-control" [(ngModel)]="rowData[i].Credit" (change)="calculateCreditTotalData()" (focus)="onFocusEvent($event)"  appDecimaNumericValidator >
                                        </td>
                                        <td class="grid-cell">
                                        <span (click)="deleteRows(i,rowData[i])">
                                            <img  class="dlt" src="../../../../assets/image/icon-Trash.png">
                                        </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                
                               
                                  </table>
                            </div>
                            <div>
                                <table class="f-ss-14px" style="width:100%;">
                                    <tr>
                                        <td colspan="4"></td>
                                        <td class="right">
                                            <a class="anchorBlue" (click)="showAllRows()">Show all accounts</a>
                                            <a class="anchorBlue" (click)="removeAllZeroRows()">Remove zero balances</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <table class="conversion-total">
                                    <tbody class="f-ss-14px">
                                        <tr>
                                            <td>Total Debits</td>
                                            <td id="totalDebits" class="right">{{TotalDebit}}</td>
                                            <td class="noRule">&nbsp;</td>
                                            <td>Total Credits</td>
                                            <td id="totalCredits" class="right">{{TotalCredit}}</td>
                                        </tr>
                                        <tr class="no-border">
                                            <td class="f-ss-14px" colspan="4"><strong>Adjustments</strong><br><small>
                                                    This accounts for the difference between debits and credits and for
                                                    FX gains and losses</small></td>
                                            <td id="adjustments" class="right">{{AdjustmentAmount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="col-sm-4 f-ss-14px" style="padding-left: 20px;padding-right:0px;">
                            <div id="accordion">

                                <div class="card f-ss-14px">
                                    <div class="card-header">
                                        <a class="card-link" data-toggle="collapse" href="#collapseOne">
                                            What's this?
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="collapse" data-parent="#accordion">
                                        <div class="card-body">
                                            Ecount needs to pick up where your old system left off. Entering your
                                            opening balances gives Ecount an accurate set of numbers to start from.
                                            Just trying to correct a bank balance? Read the first step, then confirm
                                        </div>
                                    </div>
                                </div>

                                <div class="card f-ss-14px">
                                    <div class="card-header">
                                        <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                                            1. Enter bank balances as they were on this date
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                        <div class="card-body">
                                            <ul>
                                                <li>
                                                    Check your internet banking or a statement for balances as they were
                                                    by the end of the date on this screen.
                                                </li>
                                                <li>
                                                    Enter any bank account balances above zero into the Debit column.
                                                </li>
                                                <li>
                                                    Enter the balance of any overdrawn accounts or credit cards into the
                                                    Credit column.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="card f-ss-14px">
                                    <div class="card-header">
                                        <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                            2. Enter total outstanding invoices on this date
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-parent="#accordion">
                                        <div class="card-body">
                                            <ul>
                                                <li>
                                                    Enter the outstanding balance of all unpaid or partly paid sales
                                                    invoices into the Debit column for Accounts Receivable.
                                                </li>
                                                <li>
                                                    After confirming your existing balances, you’ll need to enter the
                                                    outstanding invoices that make up this amount.
                                                </li>
                                                <li>
                                                    Run a Trial Balance report in your old system to find this balance,
                                                    along with any others you’d like to enter.
                                                </li>
                                                <li>
                                                    Always enter positive numbers. If you have a negative total, for
                                                    example due to an unpaid customer credit note, enter it into the
                                                    Credit column as positive.
                                                </li>
                                                <li>
                                                    We recommend you don't use tracked inventory items in these
                                                    invoices. To avoid double-counting, import inventory items and enter
                                                    inventory opening balances separately.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="card f-ss-14px">
                                    <div class="card-header">
                                        <a class="card-link" data-toggle="collapse" href="#collapsefour">
                                            3. Enter total outstanding bills on this date
                                        </a>
                                    </div>
                                    <div id="collapsefour" class="collapse" data-parent="#accordion">
                                        <div class="card-body">
                                            <ul>
                                                <li>
                                                    Enter the outstanding balance of all unpaid or partly paid supplier
                                                    invoices into the Credit column for Accounts Payable.
                                                </li>
                                                <li>
                                                    After confirming your balances, you’ll need to enter the outstanding
                                                    bills that make up this amount.
                                                </li>
                                                <li>
                                                    Run a Trial Balance report in your old system to find this balance,
                                                    along with any others you’d like to enter.
                                                </li>
                                                <li>
                                                    Always enter positive numbers. If you have a negative total, for
                                                    example due to a credit note owed to you, enter it into the Credit
                                                    column as positive.
                                                </li>
                                                <li>
                                                    We recommend you don't use tracked inventory items in these bills.
                                                    To avoid double-counting, import inventory items and enter inventory
                                                    opening balances separately.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="card f-ss-14px">
                                    <div class="card-header">
                                        <a class="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                                            4. Enter any other balances
                                        </a>
                                    </div>
                                    <div id="collapsefive" class="collapse" data-parent="#accordion">
                                        <div class="card-body">
                                            <ul>
                                                <li>
                                                    Add any other accounts you’d like to transition over.
                                                </li>
                                                <li>
                                                    Run a Trial Balance report in your old system to find these
                                                    balances.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="card f-ss-14px">
                                    <div class="card-header">
                                        <a class="collapsed card-link" data-toggle="collapse" href="#collapseSix">
                                            5. Confirm
                                        </a>
                                    </div>
                                    <div id="collapseSix" class="collapse" data-parent="#accordion">
                                        <div class="card-body">
                                            <ul>
                                                <li>
                                                    Confirm your balances as at the date above.
                                                </li>
                                                <li>
                                                    You’ll next need to enter any outstanding invoices and bills, equal
                                                    to the Accounts Receivable and Payable totals.
                                                </li>
                                                <li>
                                                    If total debits don’t equal total credits, eCount will add an
                                                    adjustment using the Historical Adjustment account.
                                                    <a id="helplink-historical-adjustment"
                                                        href="#"
                                                        target="_blank">How do I account for this later?</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-smart-modal #conversionDate identifier="conversionDate">
        <div (ngInit)="getConversionDate()">
            <span class="modalHeaderText pl-2">Add Existing Date</span>
            <hr>
            <div class="row pr-2 pl-2">
                <div class="col-md-12">
                    <div>
                        <div class="col-sm-12"
                            style="display: inline-block; font-size: 13px;font-weight: 400;margin-left: 17px;">
                            <p>Enter the date that you began processing all your transactions in eCount.
                                It's the
                                easiest when you set your existing date to be the start of a Tax period. Tips for
                                Choosing
                                an existing date.
                            </p>
                        </div>
                        <div class="col-sm-12" style="display: inline-flex;">
                            <div class="col-sm-6">
                                <label>Month</label>
                                <ng-select [items]="monthList" [clearable]="false" bindLabel="Text" bindValue="Value"
                                    [(ngModel)]="conversionMonth" (change)="selectionMonthChanged($event)">
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        <span>{{item.Text}}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Year</label>
                                <input class="form-control" readonly type="text" [(ngModel)]="conversionYear">
                            </div>
                        </div>
                        <div class="col-sm-12"
                            style="font-size: 13px;margin-left: 14px;color: #c95a00;padding-top: 10px;">
                            <p>For this existing date you need to enter existing balances (also known as opening
                                balances) as at: {{convertDateIntoDisplay(lastConversionDate)}}</p>
                        </div>
                        <div class="col-sm-12" [hidden]="!showHideWarningMessage"
                            style="font-size: 13px;margin-left: 14px;;padding-top: 10px;">
                            <span style="color: #a00910">Warning</span>
                            <p style="color: #e9180a">If you change your existing date you will need to confirm your
                                balances and any related sales invoices and bills.!</p>
                        </div>
                        <div class="form-group pt-4" style="float: right;">
                            <button type="button" style="float: right;margin-right: 27px;"
                                (click)="saveConversionDate()" class="eCount-def-btn ml-4">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ngx-smart-modal>
</app-dashboard>