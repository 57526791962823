import { Component, OnInit ,NgZone} from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '../../../service/Subscription/subscription.service';
import { RouterLinkRendereComponent } from 'src/app/components/router-link-rendere/router-link-rendere.component';
import { AuthService } from 'src/app/service/auth/auth.service';
import * as moment from 'moment';
import { MainServiceService } from 'src/app/service/Main/main-service.service';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.css']
})
export class SubscriptionListComponent implements OnInit {
  userInfo :any;
  constructor(private spinner: NgxSpinnerService, 
    private router: Router, 
    public ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private authService : AuthService,
    private subscriptionService: SubscriptionService,
    private mainService: MainServiceService,
) { }
  SubscriptionList : any[] = [];
  WithoutAdminSubscriptionList : any[] = [];
  private gridApi;
  private gridColumnApi;
  domLayout='autoHeight';
  loggedInUserRule: any;
  hasRenewPackage: boolean;
  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.getAllUser();
    this.getAllCompany();
  }

  columnDefs = [
    {headerName: 'Name', field: 'CompanyName', width:220, sortable: true, filter:true},
    { headerName: 'Plan', field: 'PackageTypeName', width: 80, sortable: true, filter:true },
    { headerName: 'Purchase Date', 
      field: 'PaymentDate', 
      valueFormatter: this.convertDateIntoDisplay, 
      width: 130, sortable: true,
      filter:true,
      cellRenderer: (params) => {
        if(params.data.PackageTypeId == 4) {
          return this.convertDateIntoDisplay(params.data.StartDate);
        }
        else{
          return this.convertDateIntoDisplay(params.data.PaymentDate);
        }
      }
    },
    { headerName: 'Status', 
      field: 'ActiveStatus',
      width: 80,
      sortable: true,
      filter:true,
      cellRenderer: (params) => {
        if(params.data.IsExpired == true) {
          return 'Expired';
        }
        else if (params.data.IsActive == true) {
          return params.data.ActiveStatus;
        }
        else if (params.data.IsActive == false && params.data.IsExpired == false) {
          return 'Waiting';
        }
      }
    },
    {
      width:50,
      cellStyle: { 'text-align': 'center', 'padding-top': '3px', 'padding-left': '14.5px' },
      cellRenderer: (params) => {
        if(params.data.IsExpired == true) {
          return '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#d60202" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>'
        }
        else if (params.data.IsActive == true) {
          return '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#79b984" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>'
        }
        else if (params.data.IsActive == false && params.data.IsExpired == false) {
          return '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="gray" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M304 48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zm0 416c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM48 304c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm464-48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM142.9 437c18.7-18.7 18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zm0-294.2c18.7-18.7 18.7-49.1 0-67.9S93.7 56.2 75 75s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zM369.1 437c18.7 18.7 49.1 18.7 67.9 0s18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9z"/></svg>';
        }
      }
    },
    { headerName: 'Active Time', field: 'ActiveTime', width: 210, sortable: true, filter:true},
    { headerName: 'Users', field: 'AdditionalUser', width: 80, sortable: true, filter:true},
    { headerName: 'Amount', field: 'PackagePrice', width:90, sortable: true, filter:true },
    { headerName: 'Expires In Days', field: 'RemainingDays', width:130, sortable: true, filter:true,
      cellRenderer: (params) => {
        if(params.data.IsExpired == true) {
          return '0';
        }
        else if (params.data.IsActive == false && params.data.IsExpired == false) {
          return '-';
        }
        else if(params.data.IsActive == true) {
          return params.data.RemainingDays.toString();
        }
      } 
    },
    { headerName: 'Coupon Name', field: 'Coupons.Name', width:90, sortable: true, filter:true },
    { headerName: 'Coupon Discount Amount', field: 'Coupons.DiscountAmount', width:90, sortable: true, filter:true,
      cellRenderer: (params) => {
          return params.data.Coupons.DiscountAmount+'%';
      } 
    },
    { headerName: 'Total Amount After Discount', field: 'TotalAmount', width:90, sortable: true, filter:true },
    //{ headerName: '',width:105,template: ` <button class="eCount-def-btn" style="padding-top:0px;border-radius:2px;height: 23px;" (click)="manageCompanySubscription()">Manage</button>` }
  ];
  defaultColDef = {
    resizable: true
    // minWidth: 200,
    // sortable: true,
    // filter: true,
    // flex: 1,
  };

  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  getAllCompany=()=>{
    this.spinner.show()
    this.SubscriptionList = [];
    const data = {
      CompanyName: this.userInfo.SelectedCompany.Name.toString(),
      CompanyId: this.userInfo.SelectedCompany.CompanyId
    }
    this.subscriptionService.GetSubscriberByCompany(data).subscribe((x)=>{
      this.spinner.hide();
      this.SubscriptionList = [...x, ...this.SubscriptionList].sort((a, b) => {
        if (a.IsActive === b.IsActive) {
          if (a.IsActive && b.IsActive) {
              return 0;
          } 
          else if (!a.IsActive && !b.IsActive) {
              if (a.IsExpired === b.IsExpired) {
                return 0;
              } 
              else if (a.IsExpired) {
                return 1;
              } 
              else {
                  return -1;
              }
          }
          else {
            return a.IsActive ? -1 : 1;
          }
        }
        else {
          return a.IsActive ? -1 : 1;
        }
      });
      this.SubscriptionList = [ ...this.SubscriptionList.filter( x => x.PackageTypeId != 4 ), ...this.SubscriptionList.filter( x => x.PackageTypeId == 4 ) ];
      this.hasRenewPackage = this.SubscriptionList.filter(x => x.IsActive == false && x.IsExpired == false).length != 0 ? true : false;
    });
  }

  getAllUser() {
    this.mainService.GetUserList().subscribe((x) => {
      this.loggedInUserRule = x.loggedInUserRule;
    });
  };

  isTrialActive(){
    return this.SubscriptionList.some( x => x.IsActive == true && x.PackageTypeId == 4 );  
  }

  Purchase(){
    this.router.navigate(['/subscription/manage-subscription']);
  }

  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    //this.getAllCompany();
  }

  convertPaidAmount(value){
    let valueData = Number(value.data.BillAmount.toFixed(2))
    return valueData.toLocaleString();
  }
  onRowClicked(event){
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "") {
      this.router.navigate(['/subscription/manage-subscription']);
    }
    
  }
  manageCompanySubscription()
  {

  }

  goToAddMoreUsersPage(){
    this.router.navigate(['/add-more-users-in-subscription/add-more-users-in-subscription']);
  }
    onRenewClicked(){
      if(this.hasRenewPackage){
        this.toaster.warning('You can only renew a new package after finishing the last renewed subscription')
      }
      else{
        this.router.navigate(['/subscription-renew/subscription-renew']);
      }
  }

}
