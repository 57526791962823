import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { AccountTypes } from 'src/app/models/accounting.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-chart-of-account',
  templateUrl: './edit-chart-of-account.component.html',
  styleUrls: ['./edit-chart-of-account.component.css']
})
export class EditChartOfAccountComponent implements OnInit {
  @Input() accountId;
  addAccountForm:FormGroup;
  accountTypes;
  account;
  constructor(
    private accountingService: AcountingServiceService,
    public fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }
  ngOnInit(): void {
    this.getAccountTypes();
    this.getChartOfAccountById();
    this.addAccountForm = this.fb.group({
      AccountTypeId: [3],
      AccountCode: [''],
      AccountName: [''],
      AccountGroupId: [],
      ShowInExpenseClaim: [false],
      Description:[''],
      Id:this.accountId
      
    })
  }

  saveCreateItem=()=>{

  }
  submitAddAccountForm=()=>{

    this.spinner.show()
    this.accountingService.AddChartOfAccount(this.addAccountForm.value).subscribe((x) => {
      this.spinner.hide()
      if (x.Data != null) {
        this.activeModal.close('Cross click');
        this.toastr.success('Account Successfully Added !', 'Success!');
        //this.router.navigate(['accounting/chart-of-accounts']);
      } else {
        this.spinner.hide();
        this.toastr.error('Account code already exists or something else went wrong!');
      }
    }, (err) => { this.spinner.hide(); });

  }
changeAccountType(event){

}

  getAccountTypes = () => {
    this.accountingService.GetAccountTypes().subscribe((x: AccountTypes[]) => {
      this.accountTypes = x
    })
  }

  getChartOfAccountById(){
    this.accountingService.GetChartOfAccountById(this.accountId).subscribe((z=>{
      this.account = z
      this.addAccountForm.patchValue({
        AccountTypeId: this.account.AccountTypeId,
        AccountCode: this.account.AccountCode,
        AccountName: this.account.AccountName,
        AccountGroupId: this.account.AccountGroupId,
        ShowInExpenseClaim: this.account.ShowInExpenseClaim,
        Description: this.account.Description,
        Id:this.accountId
      });
    }));
  }
  cancel(){
    this.activeModal.close('Cross click');
  }
}
