<app-dashboard>
    <app-page-header-title
      mainTitle="User-Role-Set-Up"
      subTitle="Settings"
      backURL="/settings"
    >
    
    </app-page-header-title>


    <div id="controlPanel">
    
        <div class="form-panel-body">
           
            <div class="row">
                <span class="selectFont ml-2 mt-2">Select Role</span>
                <div class="form-group col-sm-2">
                    <select  class="form-control" style="width: 100%;height: 33px;" [(ngModel)]="RoleId" (change)="getAllCompanyUserRole(RoleId)">
                        <option *ngFor="let role of roleOptions" [value]="role.id">{{ role.name }}</option>
                    </select>
                </div>
            </div>
            <div id="grid-block" *ngFor="let item of module">
                <h4 style="font-weight: bold">Module : {{item.ModuleName}} </h4>
                <table class="table table-hover table-light">
                    <thead>
                        <tr>
                            <th width="25%" class="font">Menu Title</th>
                            <th width="25%" class="font">Module</th>
                            <th width="15%" class="font"></th>
                        </tr>
                    </thead>
                    <tr *ngFor="let menu of item.RuleMenuList">
                        <td width="25%">{{menu.Name}}</td>
                        <td width="25%">{{item.ModuleName}}</td>
                        <td width="15%" style="cursor: pointer;">
                            <span  class="label label-default ml-4" *ngIf="!menu.IsActive" (click)="addOrRemoveMenuPermission(menu.Id)">Add</span>
                            <span  class="label label-success ml-4" *ngIf="menu.IsActive" (click)="addOrRemoveMenuPermission(menu.Id)">Remove</span>
                        </td>
                    </tr>
              
                </table>
            </div>
        </div>
    </div>
    
    