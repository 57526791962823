import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { WareHouseOperationType } from 'src/app/enum/wareHouseOperationType';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';

@Component({
  selector: 'app-sales-order-delivery-list',
  templateUrl: './sales-order-delivery-list.component.html',
  styleUrls: ['./sales-order-delivery-list.component.css']
})
export class SalesOrderDeliveryListComponent implements OnInit {
  searchText: string;
  orderNo: string;
  private gridApi;
  allData: any = [];
  domLayout = "autoHeight";
  salesOrderId: any;

  constructor(
    private route: ActivatedRoute,
    private salesService: SalesOrderService,
    private deliveryOrderService: InventoryOverviewService
  ) { }

  ngOnInit(): void {
    this.searchText = this.route.snapshot.paramMap.get("status");
    this.salesOrderId = this.route.snapshot.paramMap.get("id");
    this.getOrderDetails(this.salesOrderId);
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  };

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  getOrderDetails = (id: any) => {
    this.salesService.GetOrderDetails(id).subscribe((x) => {
      if (x) {
        this.orderNo = x.data.OrderNo;
        this.pageLoad(this.searchText);
      }
    });
  }

  public pageLoad(status: string) {
    this.deliveryOrderService.GetDeliveryOrderBySalesOrderNo(this.orderNo).subscribe((x) => {
      this.allData = x;
      this.allData = this.allData.filter((x) => x.OperationType == 3 || x.OperationType == 5);
      switch (status) {
        case "All":
          this.allData = this.allData.filter((x) => x.OperationType == 3 || x.OperationType == 5);
          break;
        case "Waiting":
          this.allData = this.allData.filter((x) => x.StatusId == 1);
          break;
        case "Ready":
          this.allData = this.allData.filter((x) => x.StatusId == 3);
          break;
        case "Shipped":
          this.allData = this.allData.filter((x) => x.StatusId == 2 && x.OperationType != WareHouseOperationType.SoldItemReturnedForSalesOrder);
          break;
        case "Received":
          this.allData = this.allData.filter((x) => x.StatusId == 2 && x.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder);
          break;
      }
      
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].SerialNo = i + 1;
        if(this.allData[i].OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder) {
          this.allData[i].WHNO = "WH-RET-" + this.allData[i].WareHouseProductNo;
          if(this.allData[i].ParentWareHouse.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder) {
            this.allData[i].ReferenceNo = "WH-RET-"+this.allData[i].ParentId;
          }
          else{
            this.allData[i].ReferenceNo = "WH-Out-"+this.allData[i].ParentId;
          }
        }
        else{
          this.allData[i].WHNO = "WH-Out-" + this.allData[i].WareHouseProductNo;
          this.allData[i].ReferenceNo = "SO-"+this.allData[i].ReferenceNo;
        }
      }
    });
  }

  columnDefs = [
    {
      headerName: "Serial No.",
      field: "SerialNo",
      cellStyle: { color: "blue", cursor: "pointer" },
    },
    {
      headerName: "WH No.",
      field: "WHNO",
      cellStyle: { color: "blue", cursor: "pointer" },
      cellRenderer: function (params) {
        if(params.data.OperationType == WareHouseOperationType.SalesOrder)
          return `<a href="#/inventory/delivery-order-waiting/${params.data.Id}" >${params.data.WHNO}</a>`;
        if(params.data.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder)
          return `<a href="#/inventory/receipt-waiting/${params.data.Id}" >${params.data.WHNO}</a>`;
      },
    },
    { headerName: "To", field: "Contacts.CompanyName" },
    {
      headerName: "Date",
      field: "OperationDate",
      valueFormatter: this.convertGridDateIntoDisplay,
    },
    {
      headerName: "Reference",
      field: "ReferenceNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      cellRenderer: function (params) {
        if(params.data.OperationType == WareHouseOperationType.SalesOrder)
          return `<a href="#/sales/sales-order-details/${params.data.SalesOrderData.Id}" >${params.data.ReferenceNo}</a>`;
        if(params.data.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder)
          if(params.data.ParentWareHouse.OperationType != WareHouseOperationType.SoldItemReturnedForSalesOrder)
            return `<a href="#/inventory/delivery-order-waiting/${params.data.ParentWareHouse.Id}" >${params.data.ReferenceNo}</a>`;
          else
            return `<a href="#/inventory/receipt-waiting/${params.data.ParentWareHouse.Id}" >${params.data.ReferenceNo}</a>`;
      },
    },
    {
      headerName: "Status",
      field: "Status",
      valueFormatter: this.setOrderStatus,
    },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };

  convertGridDateIntoDisplay(date) {
    const dateString = date.value ? moment(date.value).format("Do MMM YYYY") : "";
    return dateString;
  }
  setOrderStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return "Waiting";
      case 2:
        if(id.data.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder)
          return 'Received';
        else
          return "Shipped";
      case 3:
        return "Ready";
      default:
        return "unknown";
    }
  }

}
