import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ProductServiceService } from "../../../service/Products/product-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SubscriptionService } from "../../../service/Subscription/subscription.service";
import moment = require("moment");
import { AuthService } from 'src/app/service/auth/auth.service';
import { iQuidiActionType } from "src/app/enum/iquidiActionType";
import { DocumentType } from "src/app/models/documentType.model"
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from "./upload-adapter";
import { UploadAdapterAngular } from "./upload-adapter-angular";
import { HttpClient } from "@angular/common/http";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { TagsAttachmentVersionsSetting } from "src/app/models/tagsAttachmentVersionsSetting.model";
@Component({
  selector: "app-document-create-page",
  templateUrl: "./document-create-page.component.html",
  styleUrls: ["./document-create-page.component.css"],
})

export class DocumentCreatePageComponent implements OnInit {

  public Editor: any = ClassicEditor;
  public model = {
    data: ''
  };

  @Input() actionType: number;
  @Input() documentingType: number;
  @Input() parentData: any;

  documentForm: FormGroup;
  files: any;
  allTags: any;
  allDocumentTypes: any;
  contactList: any;
  loading = false;
  documentTypeLoading = false;
  today = new Date();
  approvalFlow: boolean;
  selectedTags: Array<any>;
  userInfo: any;
  tagsAttachmentVersionSetting: TagsAttachmentVersionsSetting;
  dynamicLabelNameForDocumentInputsList: any[];

  documentLabel1Name: string;
  documentLabel2Name: string;
  documentLabel3Name: string;
  documentLabel4Name: string;
  documentLabel5Name: string;
  documentLabel6Name: string;

  constructor(
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    public subscriptionService: SubscriptionService,
    private productServiceService: ProductServiceService,
    private authService: AuthService,
    private settingsService: SettingsServiceService,
    private http: HttpClient,
    private mainService: MainServiceService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.getTagAttachmentVersionSettings();
    this.getAllTags();
    this.getAllDocumentTypes();
    this.initializeItemForm();
    if(this.actionType == iQuidiActionType.Update)
      this.setParentData();
    this.getAllDynamicLabelNameForDocumentInputs();
  }

  initializeItemForm() {
    this.documentForm = this.fb.group({
      HasApprovalFlow: [false],
      TagId: [],
      selectedTags: [], // selectedTags work as EmployeeId
      ActionAt: [Validators.required],
      ContactId: [Validators.required],
      Reference1: [],
      Reference2: [],
      File: [null],
      DocumentTypeId: [],
      EmployeeId: [], // EmployeeId work as Tag
    });
    this.documentForm.get("ActionAt").patchValue(new Date().toISOString().split("T")[0] + " " + new Date().toISOString().split("T")[1].split(".")[0]);
    this.showSelectedDate(this.documentForm.value.ActionAt);
  }


  setParentData() {
    this.model.data = this.parentData?.AuthorDocumentContent;
    if (this.parentData.ApprovalStatus != null){
      this.approvalFlow = true;
      this.documentForm.controls['HasApprovalFlow'].patchValue(true);
    }
    else{
      this.approvalFlow = false;
      this.documentForm.controls['HasApprovalFlow'].patchValue(false);
    }
    this.documentForm.controls['TagId'].patchValue(this.parentData.Id);
    let selectedTagsValues = this.parentData.TagsAttachmentTag.map(x => x.CompanyTagsId);
    this.documentForm.controls['selectedTags'].patchValue(selectedTagsValues);
    this.documentForm.controls['ContactId'].patchValue(this.parentData.ContactId);
    this.documentForm.controls['Reference1'].patchValue(this.parentData.Reference1 == 'null' ? '' : this.parentData.Reference1);
    this.documentForm.controls['Reference2'].patchValue(this.parentData.Reference2 == 'null' ? '' : this.parentData.Reference2);
    this.documentForm.controls['File'].patchValue(this.parentData);
    this.documentForm.controls['DocumentTypeId'].patchValue(this.parentData.DocumentTypeId);
    this.documentForm.controls['EmployeeId'].patchValue(this.parentData.EmployeeId == 'null' ? '' : this.parentData.EmployeeId);
  }

  handleFile(event) {
    const formData: FormData = new FormData();
    this.files = event.target.files;
    if(!this.mainService.IsNull(this.files)){
      for(let i = 0; i < this.files.length; i++){
        formData.append('file', this.files[i]);
      }
    }
    this.documentForm.patchValue({ File: this.files });
  }

  save = () => {
    if (this.documentForm.invalid || this.documentForm.value.selectedTags == null){
      this.toaster.warning('Please fill all the required field');
      return;
    }
    if(this.documentingType == 1 && this.mainService.IsNull(this.files)){ // 1 means Normal Document
      this.toaster.warning('Please fill all the required field');
      return;
    }
    //const tagIds = this.documentForm.value.selectedTags.join(',');
    const formData = new FormData();
    if(!this.mainService.IsNull(this.files)){
      for(let i = 0; i < this.files.length; i++){
        formData.append('file', this.files[i]);
      }
    }
    formData.append('TagIds', this.documentForm.value.selectedTags[0]);
    formData.append('TagId', this.documentForm.value.TagId);
    formData.append('ContactId', this.documentForm.value.ContactId);
    formData.append('ActionAt', this.documentForm.value.ActionAt);
    formData.append('ApprovalFlow', this.approvalFlow?.toString());
    formData.append('Reference1', this.documentForm.value.Reference1);
    formData.append('Reference2', this.documentForm.value.Reference2);
    formData.append('BusinessUnitRoleId', this.userInfo.RoleId);
    formData.append('DocumentTypeId', this.documentForm.value.DocumentTypeId);
    formData.append('AuthorDocumentContent', this.model?.data?.toString());
    formData.append('TagType', this.documentingType.toString());
    formData.append('EmployeeId', this.documentForm.value.EmployeeId);
    formData.append('Name', this.getFormattedFileName());
    this.spinner.show();
    this.subscriptionService.saveTagsAttachment(formData).subscribe((xy) => {
      this.spinner.hide();
      this.activeModal.close(xy);
      this.approvalFlow = false;
    });
  }

  getFormattedFileName(){
    let documentTypeName = this.allDocumentTypes.find(x => x. Id == this.documentForm.value.DocumentTypeId).Name;
    let employeeId = this.allTags.find(x => x. Id == this.documentForm.value.selectedTags[0]).TypeName;
    return employeeId+"_"+documentTypeName;
  }

  approvalFlowChange() {
    if (this.approvalFlow == false || this.approvalFlow == undefined){
      this.approvalFlow = true;
      this.documentForm.controls['HasApprovalFlow'].patchValue(true);
    }
    else{
      this.approvalFlow = false;
      this.documentForm.controls['HasApprovalFlow'].patchValue(false);
    }
  }

  CreateNewTags(customerName) {
    let Data = { TypeName: customerName };
    this.loading = true;
    this.subscriptionService.saveTags(Data).subscribe((x) => {
        if (x.Success) {
          this.toaster.success("Employee Id has been successfully added!");
          this.loading = false;
          this.allTags.push(x["Data"]);
          let Obj = this.allTags.filter((xy) => xy.TypeName == customerName);
          this.documentForm.get("TagId").patchValue(Obj[0]["Id"]);
          this.getAllTags();
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.loading = false;
      }
    );
  };

  getAllTags() {
    this.subscriptionService.GetAllTags().subscribe((x) => {
      this.allTags = x;
    });
    this.subscriptionService.GetAllCustomer().subscribe((x: []) => {
      this.contactList = x;
    });
  }

  showSelectedDate(e) {
    const value = this.documentForm.value.ActionAt;
    $("#dctest1").on("change", function () {
        this.setAttribute("data-date", moment(value, "YYYY-MM-DD").format(this.getAttribute("data-date-format")));
      }).trigger("change");
  }

  getAllDocumentTypes() {
    this.settingsService.GetAllDocumentTypes().subscribe((x) => {
      this.allDocumentTypes = x;
    });
  }

  update = (actionType, doNotCreateNewVersion) => {
    if (this.documentForm.invalid || this.documentForm.value.selectedTags == null){
      this.toaster.warning('Please fill all the required field');
      return;
    }
    //const tagIds = this.documentForm.value.selectedTags.join(',');
    const formData = new FormData();
    formData.append('file', this.files);
    formData.append('TagIds', this.documentForm.value.selectedTags[0]);
    formData.append('TagId', this.documentForm.value.TagId);
    formData.append('ContactId', this.documentForm.value.ContactId);
    formData.append('ActionAt', this.documentForm.value.ActionAt);
    formData.append('ApprovalFlow', this.approvalFlow.toString());
    formData.append('Reference1', this.documentForm.value.Reference1);
    formData.append('Reference2', this.documentForm.value.Reference2);
    formData.append('BusinessUnitRoleId', this.userInfo.RoleId);
    formData.append('DocumentTypeId', this.documentForm.value.DocumentTypeId);
    formData.append('AuthorDocumentContent', this.model?.data?.toString());
    formData.append('TagType', this.documentingType.toString());
    formData.append('ActionType', actionType.toString());
    formData.append('EmployeeId', this.documentForm.value.EmployeeId);
    formData.append('Name', this.getFormattedFileName());
    formData.append('doNotCreateNewVersion', doNotCreateNewVersion);
    this.spinner.show();
    this.subscriptionService.UpdateTagFiles(formData).subscribe((xy) => {
      this.spinner.hide();
      this.activeModal.close(xy);
      this.approvalFlow = false;
    });
  }

  onReady(editor: ClassicEditor): void {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter( loader );
  };
}

  getTagAttachmentVersionSettings() {
    this.settingsService.GetTagAttachmentVersionSettings().subscribe(x => {
      this.tagsAttachmentVersionSetting = x;
    });
  }

  getAllDynamicLabelNameForDocumentInputs() {
    this.settingsService.GetAllDocumentInputFieldDynamicLabelName().subscribe((x) => {
      this.dynamicLabelNameForDocumentInputsList = x;
      this.documentLabel1Name = this.dynamicLabelNameForDocumentInputsList[0]?.LabelName;
      this.documentLabel2Name = this.dynamicLabelNameForDocumentInputsList[1]?.LabelName;
      this.documentLabel3Name = this.dynamicLabelNameForDocumentInputsList[2]?.LabelName;
      this.documentLabel4Name = this.dynamicLabelNameForDocumentInputsList[3]?.LabelName;
      this.documentLabel5Name = this.dynamicLabelNameForDocumentInputsList[4]?.LabelName;
      this.documentLabel6Name = this.dynamicLabelNameForDocumentInputsList[5]?.LabelName;
    });
  }

}