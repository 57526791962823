import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessOwnerService } from 'src/app/service/Business Owner/business-owner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MainServiceService } from 'src/app/service/Main/main-service.service';

@Component({
  selector: 'app-view-owner-loan',
  templateUrl: './view-owner-loan.component.html',
  styleUrls: ['./view-owner-loan.component.css']
})
export class ViewOwnerLoanComponent implements OnInit {

  id: any;
 bankAccounts: any;
 SettlementForm: FormGroup;
 loanModel: any = {};
 url: any;
 IfSettle = false;
 SettlementList: any;
  constructor( private route: ActivatedRoute,
               private router: Router,
               private toaster: ToastrService,
               private spinner: NgxSpinnerService,
               public fb: FormBuilder,
               private ngxmodalService: NgxSmartModalService,
               private mainService: MainServiceService,
               private ownerService: BusinessOwnerService,
               private modalService: NgbModal) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getBPLoanData();
    this.initForm();
    this.getBanks();
  }
  initForm = () => {
    this.SettlementForm = this.fb.group({
      Amount: ['', Validators.required],
      Description: ['', Validators.required],
      Date: ['', Validators.required],
      OwnerLoanId: [],
      ContactId: [],
      AccountId: ['', Validators.required]
    });
    this.SettlementForm.get('Date').patchValue(this.formatDate(new Date()));
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  getBanks = () =>
  {
    this.bankAccounts = [];
    this.mainService.GetAllBank().subscribe((x) => {
        this.bankAccounts = x;
    });
  }
  getBPLoanData = () => {

    this.spinner.show();
    this.ownerService.GetOwnerLoanbyId(this.id).subscribe((x: any) => {

      this.loanModel = x.result;
      this.IfSettle = ((this.loanModel.TotalAmount + this.loanModel.InterestAmount) - this.loanModel.SettleAmount) <= 0 ? true : false;
      this.url =  'owner/business-owner-list';
      this.SettlementList = x.SettlementList;
      console.log(this.SettlementList);
      this.spinner.hide();
    });
  }

  submitDiscountAmount(){

    if (this.SettlementForm.invalid)
    {
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    this.spinner.show();
    if (this.loanModel.LoanTypeId == 1){
    this.ownerService.SaveGrantSettleMent(this.SettlementForm.value).subscribe((x) => {
      if (x)
      {
        this.spinner.hide();
        this.ngxmodalService.getModal('AddSettlement').close();
        this.ngOnInit();
      }
    });
  }
  else
  {
    this.ownerService.SaveReceiveSettleMent(this.SettlementForm.value).subscribe((x) => {
      if (x)
      {
        this.spinner.hide();
        this.ngxmodalService.getModal('AddSettlement').close();
        this.ngOnInit();
      }
    });
  }
  }

  openSettlementPopup = () => {
    this.SettlementForm.get('OwnerLoanId').patchValue(this.loanModel.Id);
    this.SettlementForm.get('ContactId').patchValue(this.loanModel.OwnerId);
    this.ngxmodalService.create('AddSettlement', 'content').open();
  }
}
