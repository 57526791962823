<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">Edit Shipment</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <form [formGroup]="shipmentForm" class="f-ss-14px">
        <div class="pb-4">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <b>Delivery Details</b>
                        <p style="margin-top: 15px;">To</p>
                        <label>{{getControlLabel('DeliveryTo')}}</label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                          <label style="margin-top: 15px;">Date</label>
                          <input
                              class="form-control"
                              type="date"
                              formControlName="DeliveryDate"
                              id="itest" data-date=""
                              (change)="showSelectedDate()"
                              data-date-format="D MMM YYYY"
                          />
                      </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <p style="margin-top: 15px;">Notes</p>
                      <textarea class ="text-area" style="width: 250px;height: 60px;" formControlName="DeliveryBy"></textarea>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <p style="margin-top: 15px;">Address</p>
                      <textarea class ="text-area" style="width: 250px;height: 60px;" formControlName="DeliveryAddress"></textarea>
                    </div>
                </div>
                <div style="margin-top: 25px;" class="row">
                    <div class="invoice-grid col-md-12" id="my" >
                        <div class="d-flex">
                            <div class="col-md-3">Item</div>
                            <div class="col-md-3">Remaining Quantity</div>
                            <div class="col-md-3">Current Quantity</div>
                            <div class="col-md-3">Delivery Quantity</div>
                        </div>
                        <hr class="pb-0 mb-0" />
                        <div formArrayName="OrderShipmentItem" *ngFor="let item of shipmentForm.get('OrderShipmentItem')['controls']; let i = index;">
                            
                            <div class="invoiceGridItemRow d-block">

                            <div class="row" [formGroupName]="i" style="margin-top: 10px;">
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control"
                                        type="text"
                                        formControlName="ItemName"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control"
                                        type="text"
                                        formControlName="RemainingQuantity"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control"
                                        type="text"
                                        formControlName="CurrentQuantity"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        appDecimaNumericValidator
                                        class="form-control"
                                        formControlName="Quantity"
                                        (change)="checkQuantity(item)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </form>
</div>
<hr>
<div class="modal-footer">
    <button type="button" (click)="save()" class="eCount-def-btn  ml-4 p-save-button">Save</button>
    <button type="button" style="background-color: #5cac00;" (click)="arrive()" class="eCount-def-btn  ml-4 p-save-button">Shipped</button>
    <button type="button" style="background-color:#898989;" (click)="cancel()" class="eCount-def-btn  ml-4 p-save-button">Cancelled</button>
</div>