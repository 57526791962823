import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { AgGridAngular } from 'ag-grid-angular';
import { ActivatedRoute } from '@angular/router';
import { ProductServiceService } from 'src/app/service/Products/product-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageItemCreateComponent } from '../storage-item-create/storage-item-create.component';
import * as moment from 'moment';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { CreateItem } from '../../../models/product.model';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import {ConsumptionHistoryListComponent} from '../consumption-history-list/consumption-history-list.component';
import { RouterLinkRendereComponent } from 'src/app/components/router-link-rendere/router-link-rendere.component';
@Component({
  selector: 'app-view-storage-item',
  templateUrl: './view-storage-item.component.html',
  styleUrls: ['./view-storage-item.component.css']
})
export class ViewStorageItemComponent implements OnInit {
  public aModel: CreateItem = new CreateItem();
  isEditable = false;
  rowData: any;
  stockQuantity: number;
  redirectUrl = '/product/storage-item-list';
  id: any;
  item: any;
  length: any;
  totalFile: number=0;
  title: any;
  SaleAccountName: any;
  PurchaseAccountName: any;
  consumeItemList: any[];
  @ViewChild("agGrid", { static: false }) agGrid: AgGridAngular;
  gridOptions = {
    columnDefs: [
      { headerName: 'Date', field: 'TransDate', width: 50, valueFormatter: this.convertGridDateIntoDisplay},
      { headerName: 'Type', field: 'TransType', width: 50, valueFormatter: this.formatType},
      { headerName: 'Reference', field: 'ReferenceNo', width: 50,
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: {
          inRouterLink: "/expense/bill/"
        }
      },
      { headerName: "Quantity", field: 'Quantity',width: 30},
      { headerName: "Unit Price", field: 'UnitPrice', width: 30},
      { headerName: "Total", field: 'LineTotal', width :30}
    ],

    defaultColDef: {
      flex: 1,
    },
    domLayout: 'autoHeight',
    rowHeight: 275,

    getRowStyle: this.changeRowColor,
  };
  changeRowColor(params) {
    if (params.data.Debit > 0 && params.data.Credit > 0) {
      return { background: '#F89406' };
    }
    return { background: '#FFFFFF' };
  }
  constructor(private _eref: ElementRef,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private productServiceService: ProductServiceService,
              private fileEmitterService: FileEmitterService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    this.getItemById();
  }

  editItem() {

    const modalRef = this.modalService.open(StorageItemCreateComponent,{ size: 'lg', backdrop: 'static', keyboard : false, centered : true});
    this.aModel = this.item;
    modalRef.componentInstance.fromParent = this.aModel;
    modalRef.result.then((result) => {
      if (result.Success){
        this.getItemById();
      }
    }, (reason) => {
    });
  }
  getItemById(){
    this.spinner.show();
    this.productServiceService.GetStorageItemById(this.id).subscribe(p => {
      this.spinner.hide();
      this.item = p.data;
      this.consumeItemList = p.consumedList;
      this.title = this.item.ItemName;
      this.rowData = p.transactionList;
      for(var i = 0; i < this.rowData.length; i++){
        this.rowData[i].ReferenceNo = this.rowData[i].ReferenceNo != '' ? 'Bill-'+this.rowData[i].ReferenceNo : '';
      }
      this.PurchaseAccountName = p.accountName;
      this.stockQuantity = this.item.StockQuantity;
      this.isEditable = this.rowData?.length > 0 ? true : false;
      this.redirectUrl = '/product/storage-item-list';
    });
  }
  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }

  formatType(data) {
    if(data.value == 'Factory Out') {
      return 'Consumed';
    }
    else {
      return data.value;
    }
  }

  convertDateIntoDisplay(date){
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }
  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  OpenConsumptioHistoryPopUp(itemid, id) {

    const modalRef = this.modalService.open(ConsumptionHistoryListComponent,{ size: 'lg', backdrop: 'static', keyboard : false, centered : true});

    modalRef.componentInstance.ItemId = itemid;
    modalRef.componentInstance.ConsumptionId = id;
    modalRef.result.then((result) => {

    }, (reason) => {
    });
 }


}
