import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductServiceService } from '../../../service/Products/product-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ValueCache } from 'ag-grid-community';

@Component({
  selector: 'app-storage-item-consumption-list',
  templateUrl: './storage-item-consumption-list.component.html',
  styleUrls: ['./storage-item-consumption-list.component.css']
})

export class StorageItemConsumptionListComponent implements OnInit {
  @Input() itemList: any[];
  public ConsumptionForm: FormGroup;
  public ConsumptionItems: FormArray;
  ifSubmit = false;
  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private productServiceService: ProductServiceService
  ) { }
  ngOnInit(): void {
    this.initializeForm();
    this.setParentData();
    this.itemList.forEach(item => {
      this.addItem(item);
    });
  }
  public addItem(x: any): void {
     this.ConsumptionItems = this.ConsumptionForm.get('ConsumptionItems') as FormArray;
     this.ConsumptionItems.push(this.createItem(x));
  }
  initializeForm() {
    this.ConsumptionForm = this.fb.group({
      Id: [0],
      CreatedById: [''],
      CompanyId: [0],
      Notes: [''],
      ConsumptionItems: this.fb.array([])
    });

  }
  public createItem(x: any): FormGroup {
    return this.fb.group({
      Id: [0],
      ItemId: [x.Id],
      ItemCode: [x.ItemCode],
      ItemName: [x.ItemName],
      ConsumptionId: [0],
      StockQuantity: [x.StockQuantity],
      ConsumptionQuantity: [0],
    });
  }
  setParentData()
  {
    this.ConsumptionForm.controls['Id'].patchValue(0);
    this.ConsumptionForm.controls['CreatedById'].patchValue('');
    this.ConsumptionForm.controls['CompanyId'].patchValue(0);
    this.ConsumptionForm.controls['Notes'].patchValue('');

  }

  saveConsumption()
  {
    this.ifSubmit = true;
    const result = this.validateData();
    const checkItem = this.checkItemQuantity();
    if (result){
      this.toaster.warning('Consumption quantity should be less than or equal to Stock quantity');
      return;
    }
    if (!checkItem){
      this.toaster.warning('Please select at least one used quantity');
      return;
    }
    this.spinner.show();
    this.productServiceService.saveStorageItemConsumption(this.ConsumptionForm.value).subscribe((x) => {
    console.log(x);
    this.spinner.hide();
    this.activeModal.close(x);
    });
  }

  validateData = () => {
    let value = false;
    this.ConsumptionItems = this.ConsumptionForm.get('ConsumptionItems') as FormArray;
    this.ConsumptionItems.value.map((x, index) => {
      if (x.ConsumptionQuantity > x.StockQuantity) {
        value = true;
      }
    });
    return value;
  }
  checkItemQuantity= () => {
    let value = false;
    this.ConsumptionItems = this.ConsumptionForm.get('ConsumptionItems') as FormArray;
    this.ConsumptionItems.value.map((x, index) => {
      if (x.ConsumptionQuantity > 0) {
        value = true;
      }
    });
    return value;
  }
  onFocusEvent(event: any){
    event.target.value=event.target.value==0?null:event.target.value;
  }

  ConsumptionQuantity(item, i){
    let items = this.ConsumptionForm.get('ConsumptionItems') as FormArray;
    items.value.map((item, i) => {
      items.controls[i].get('ConsumptionQuantity').patchValue(Number(item.ConsumptionQuantity) < 0 || item.ConsumptionQuantity == null ? 0 : item.ConsumptionQuantity);
    });
  }

}
