import { Component, OnInit,OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {MainServiceService} from '../../../service/Main/main-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { IfStmt } from '@angular/compiler';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userForm: FormGroup;
  email: string;
  isEmail: boolean;
  formData: FormData;
  imageURL: string;
  fileUrl = environment.fileBaseUrl;
  deleteImage: number = -1;
  constructor(
    private attachmentService: AttachmentServiceService,
    private authService: AuthService,
    private dataService: DataService,
    public fb: FormBuilder,
    private settingsService: SettingsServiceService,
    private router: Router, private toaster: ToastrService,
    private mainService: MainServiceService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getLogInUserEmail();
    this.organizationData();
    this.getUserDetails();
    this.deleteImage = -1;
  }

  organizationData() {
    this.userForm = this.fb.group({
      Name: ['', Validators.required],
      PhoneNumber: ['', Validators.required],
      LogInEmail: [],
      Email: ['', Validators.required],
      JobTitle: [''],
      Address: [''],

    });
    this.userForm.patchValue({ LogInEmail: this.email });
  }
  onSaveOrganization() {
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getLogInUserEmail()
  {
    this.email = localStorage.getItem('user');
    this.isEmail = this.email.includes('@');
  }

  getUserDetails(){
    this.getLogInUserEmail();
    this.mainService.GetUserData(this.email).subscribe((x) => {
      if (x.Success)
      {
        this.userForm.patchValue({
          Name: x.data.FirstName,
          Email: x.data.Email,
          PhoneNumber: x.data.PhoneNumber,
          JobTitle : x.data.JobTitle,
          Address : x.data.Address
        });
        if(x.data.Signature!=null && x.data.Signature.toString().length!=0) this.imageURL = this.fileUrl + x.data.Signature;
        console.log(this.imageURL)
      }
      if (x.data.Email != null)
      {
        this.userForm.get('Email').disable();
      }
    });
  }
  UpdateUserDetails(){
    if(!confirm("Are you sure?")) return;
    this.spinner.show();
    this.userForm.get('Email').enable();
    this.mainService.UpdateUserData(this.userForm.value).subscribe((x) => {
      if(this.myForm.value.file!=null && this.myForm.value.file.toString().length!=0){
        if (x.Success)
        {
          console.log(x);
          this.mainService.saveSignature(this.formData).subscribe((xy) => {
            this.spinner.hide();
            const userInfo = this.authService.getUserInfo();
            userInfo.UserName = x.data.FirstName[0].toString();
            localStorage.removeItem('userInfo');
            this.authService.setUserInfo(JSON.stringify(userInfo));
            console.log(userInfo);
            this.userForm.patchValue({
              Name: x.data.FirstName,
              Email: x.data.Email,
              PhoneNumber: x.data.PhoneNumber,
              JobTitle : x.data.JobTitle,
              Address : x.data.Address
            });
            if(x.data.Signature!=null && x.data.Signature.toString().length!=0) this.imageURL = this.fileUrl + x.data.Signature;
          });
          this.toaster.success('User updated successfully.')
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
        else{
          this.spinner.hide();
          this.toaster.warning(x.Message);
        }

      }
      else{
        if (x.Success)
        {
          console.log(x);
          if(this.deleteImage==1){
            this.mainService.deleteSignature(x.data.Signature).subscribe((xy) => {
              this.spinner.hide();
              this.toaster.success('User updated successfully.')
              location.reload();
            });
          }
          else{
            this.toaster.success('User updated successfully.')
            location.reload();
          }
        }
        else{
          this.spinner.hide();
          this.toaster.warning(x.Message);
        }
      }

    });
  }

  //signature upload work

  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('type=> ', event.target.files[0]);
      let fileType = file.type.toString().split('/');
      if(fileType[0]!='image'){
        this.toaster.warning("File type needs to be image."); this.myForm.controls['file'].setValue(''); this.formData = new FormData(); return;
      }
      this.myForm.patchValue({
        fileSource: file
      });
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
    this.formData = new FormData();
    this.formData.append('file', this.myForm.get('fileSource').value);
    this.deleteImage = -1;
  }

  deleteSignature(){
    this.myForm.controls['file'].setValue('');
    this.formData = new FormData();
    this.imageURL = '';
    this.deleteImage = 1;
  }
}
