<app-dashboard>
    <app-page-header-title
        [mainTitle]="title"
        subTitle="Back"
        backURL="{{redirectUrl}}">
        <div class="text-align-right col-md-12 row">
            <div class="dropdown col-md-9 ml-3">
            <button id="attachment_btn" type="button" data-toggle="dropdown" class="btn attachment-button" style="z-index: 1;" (click)="openAttachmentDropdown()"><i style="font-size: 20px !important;" id="attachment_btn" class="material-icons">insert_drive_file</i><span id="attachment_btn" *ngIf="totalFile>0" style="color:darkgrey; font-size: 12px;" >{{totalFile}}</span></button>
            <div class="x-tip-tl" style="height: 0px;">
                <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
            </div>
            <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="3"></app-attachment-uploader>
            </div>
        </div>
        <div class="col-md-0" *ngIf="!isEditable">
           <span><button  type="button" (click)="editItem()" class="eCount-def-btn">Edit Item</button></span>
       </div>
        <div class="dropdown col-md-1">
            <button type="button" data-toggle="dropdown" class="eCount-button3"><span>Options</span><span style="display: inline;" class="iconify" data-icon="bi:chevron-down" data-inline="false"></span></button>
            <ul class="dropdown-menu">
                <li ><a class="dropdownLink" href="#">Mark as Inactive</a></li>
            </ul>
        </div>
        </div>
    </app-page-header-title>
    <div class="container">
        <div [hidden]="!item?.IsTrackItem" class="row f-ss-14px" style="padding:30px 10px 0px 10px"> 
            <div class="col-md-2 center-alignment" style="padding: 0px 10px;"> 
                <div class="center-alignment" >
                    <p class="tx-c-br mb-0px">Quantity on Hand </p>
                    <p class="mb-0px" style="font-weight: bold;">{{stockQuantity}}</p>
                </div>
                <hr class="custom-hr">
            </div>
        </div>
        <div [hidden]="!item?.IsTrackItem" class="row"> 
            <div class="col-md-6 f-ss-14px " style="padding: 30px 30px 0px 30px;"> 
                
                    <table class="full-width">
                        <th class="borderbottom">Inventory Item</th>
                        <th class="borderbottom" ></th>
                        <tr class="borderbottom tx-c-br" >
                            <td>Inventory asset account</td>
                            <td>630 Inventory</td>
                        </tr>
                    </table>
            </div>
        </div>
        <div class="row f-ss-14px"> 
            <div class="col-md-6 f-ss-14px " style="padding: 30px 30px 0px 30px;"> 
                <table class="full-width">
                    <th class="borderbottom">Purchases</th>
                    <th class="borderbottom" ></th>
                    <tr class="tx-c-br borderbottom" >
                        <td>UnitPrice</td>
                        <td>{{item?.PurchasePrice}}</td>
                    </tr>
                    <tr class="tx-c-br borderbottom" >
                        <td>Account</td>
                        <td>{{PurchaseAccountName}}</td>
                    </tr>
                    <tr class="tx-c-br borderbottom" >
                        <td>Description</td>
                        <td>{{item?.PurchaseDescription}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row f-ss-14px"> 
            <div class="col-md-12" style="padding-bottom: 30px;"> 
                <p class="f-ss-14px tx-c-lb mb-0px gird-table-header">Recent Transactions</p>
                <ag-grid-angular #agGrid id="myGrid" class="ag-theme-balham col"
                                [columnDefs]="gridOptions.columnDefs" [defaultColDef]="gridOptions.defaultColDef"
                                [domLayout]="gridOptions.domLayout" [rowData]="rowData"
                                [getRowStyle]="gridOptions.getRowStyle">
                </ag-grid-angular>
            </div>
        </div>
        <div class="mt-4 col-sm-12">
            <p class="f-ss-14px tx-c-lb mb-0px">Consumption History</p>
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Consumed Id</th>
                    <th>Date</th>
                    <th>Consumed Quantity</th>
                    <th>Quantity Before Consumption</th>
                  </tr>
                </thead>
                
                <tbody  *ngFor="let item of consumeItemList; let i = index;">
                <tr >
                <td class="grid-cell text-blue cursor-pointer" (click)="OpenConsumptioHistoryPopUp(item.ItemId,item.ConsumptionId)">
                  Con{{item.ConsumptionId }}
                </td>
                <td class="grid-cell">
                     {{convertDateIntoDisplay(item.CreatedAt)}}   
                </td>
                <td class="grid-cell">
                    {{item.ConsumptionQuantity}}
                </td>
                <td class="grid-cell">
                    {{item.StockQuantity}}
                </td>
                  </tr>
                </tbody>
            
           
              </table>
        </div>
    </div>

</app-dashboard>