<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">Dispose {{fromParent.AssetNumber}} {{fromParent.AssetName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="disposeForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-4">
                                <label for="DisposedDate" style="font-weight: 600;" >Date Disposed<span class="required-sign">*</span></label>
                                <input type="date" class="form-control form-input" formControlName="DisposedDate" id="checkindate" [min]="disposedDate" required>
                            </div>
                            <div class="form-group  col-sm-4">
                                <label for="SaleProceeds" style="font-weight: 600;">Sale Proceeds<span class="required-sign">*</span></label>
                                <input type="text" class="form-control form-input" formControlName="SaleProceeds" required appDecimaNumericValidator>
                            </div>
                            <div class="form-group  col-sm-4">
                                <label for="CustomerId" style="font-weight: 600;" >Sale Proceeds To<span class="required-sign">*</span></label>
                                <ng-select [items]="allCustomer" bindLabel="CompanyName" bindValue="Id" [addTag]="CreateNewCustomer"
                                    [loading]="loading" formControlName="CustomerId">
                                    <ng-template ng-tag-tmp let-search="searchTerm" required>
                                        <b>+ Add New Customer</b>: {{search}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="SaleProceedsAccountId" style="font-weight: 600;" >Sale Proceeds Account<span class="required-sign">*</span></label>
                                <ng-select [items]="allChartOfAccount" placeholder="Select Account"
                                        class="customSaleItem" [clearable]="false" bindLabel="AccountName"
                                        bindValue="Id" formControlName="SaleProceedsAccountId" groupBy="GroupName" required>
                                </ng-select>
                            </div>
                            <div class="form-group  col-sm-6">
                                <label for="DepreciationTypeId" style="font-weight: 600;" >Depreciation for this financial year<span class="required-sign">*</span></label>
                                <ng-select [items]="DepreciationTypeList" (keydown)="ShowSummary()"  [clearable]="false" bindLabel="Name"
                                    bindValue="Id" formControlName="DepreciationTypeId" required></ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div *ngIf="canShowDate()" class="form-group  col-sm-4">
                                <label for="SelectedDepreciationDate" style="font-weight: 600;" >Date</label>
                                <ng-select [items]="toDateList" [clearable]="false" bindLabel="Text" bindValue="Value" 
                                    formControlName="SelectedDepreciationDate">
                                </ng-select>
                                <!-- <input type="text" class="form-control form-input" formControlName="SelectedDepreciationDate" id="itest" disabled> -->
                                <!-- <input type="date" class="form-control form-input" formControlName="SelectedDepreciationDate" id="itest"> -->
                            </div>
                            <div class="form-group  col-sm-8 text-align-right" [ngClass]="{'col-sm-12': canShowDate() == false}">
                                <div>
                                    <label for="DemoDate" style="font-weight: 600; color: white;" >Summary</label>
                                </div>
                                <button type="button" class="eCount-def-btn ml-4" (click)="ShowSummary()"><span *ngIf="isShowSummary">Show Summary</span><span *ngIf="!isShowSummary">Update</span></button>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder" *ngIf="!isShowSummary">
                        <div class="col-sm-12" style="display: flex;">
                            <table class="table f-ss-14px">
                                <thead>
                                    <tr class="tableHeader">
                                        <th scope="col" class="table-header-color text-align-left col-md-8" style="color: #02aced;">Disposal Summary</th>
                                        <th scope="col" class="table-header-color text-align-right col-md-4" style="color: #02aced;">Book</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-align-left col-md-8">Cost</td>
                                        <td class="text-align-right col-md-4">{{fromParent?.PurchasePrice}}</td>
                                    </tr>
                                    <tr *ngIf="fromParent?.DepreciationValue != 0 && fromParent?.DepreciationValue != null">
                                        <td class="text-align-left col-md-8">Current Accumulated Depreciation</td>
                                        <td class="text-align-right col-md-4">{{ fromParent?.DepreciationValue == null ? 0 : fromParent?.DepreciationValue.toFixed(2)}}</td>
                                    </tr>
                                    <tr *ngIf="disposalDetails?.ReversalOfDepreciation != 0">
                                        <td class="text-align-left col-md-8">Reversal of Depreciation <span style="color: gray;">({{disposeForm.value.SelectedDepreciationDate == '' ? depreciationStartDate : selectedDepreciationDate}} to {{depreciatedToDate}})</span></td>
                                        <td class="text-align-right col-md-4">{{disposalDetails?.ReversalOfDepreciation.toFixed(2)}}</td>
                                    </tr>
                                    <tr *ngIf="disposalDetails?.PostedOfDepreciation">
                                        <td class="text-align-left col-md-8">Depreciation to be posted <span style="color: gray;">(to {{disposeForm.value.SelectedDepreciationDate}})</span></td>
                                        <td class="text-align-right col-md-4">{{disposalDetails?.PostedOfDepreciation.toFixed(2)}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-align-left col-md-8">Sale Proceeds</td>
                                        <td class="text-align-right col-md-4">{{disposeForm.value.SaleProceeds}}</td>
                                    </tr>
                                    <tr *ngIf="disposalDetails?.GainAmount">
                                        <td class="text-align-left col-md-8">Gain on Disposal</td>
                                        <td class="text-align-right col-md-4">{{disposalDetails?.GainAmount.toFixed(2)}}</td>
                                    </tr>
                                    <tr *ngIf="disposalDetails?.CapitalGainAmount">
                                        <td class="text-align-left col-md-8">Capital Gain on Disposal</td>
                                        <td class="text-align-right col-md-4">{{disposalDetails?.CapitalGainAmount.toFixed(2)}}</td>
                                    </tr>
                                    <tr *ngIf="disposalDetails?.LossAmount">
                                        <td class="text-align-left col-md-8">Loss on Disposal</td>
                                        <td class="text-align-right col-md-4">{{disposalDetails?.LossAmount.toFixed(2)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12 text-align-right">
                                <button type="button" class="eCount-def-btn ml-4" *ngIf="!isShowSummary" (click)="openReviewDisposalJournalModal()">Review Journals</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
