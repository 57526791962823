import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { ProductServiceService } from "../../../service/Products/product-service.service";
import { DataService } from "../../../service/EventEmitter/data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateItem } from "../../../models/product.model";
import { Subscription } from "rxjs";
import { ItemCreateComponent } from "../item-create/item-create.component";
import { InventoryOverviewService } from "src/app/service/InventoryOverview/inventory-overview.service";
import { CellClickedEvent } from 'ag-grid-community';
import { SalesOrderBookedStockPopupComponent } from "../../sales/sales-order-booked-stock-popup/sales-order-booked-stock-popup.component";
import { StorageItemCreateComponent } from "../storage-item-create/storage-item-create.component";
import { ManufactureOrderStatus } from "src/app/enum/manufacture-order-status";
import { ManufactureService } from "src/app/service/Manufacture/manufacture.service";
import { ToastrService } from "ngx-toastr";
import { WareHouseOperationType } from "src/app/enum/wareHouseOperationType";
import { AuthService } from "src/app/service/auth/auth.service";
@Component({
  selector: "app-item-list",
  templateUrl: "./item-list.component.html",
  styleUrls: ["./item-list.component.css"],
})
export class ItemListComponent implements OnInit, OnDestroy {
  public aModel: CreateItem = new CreateItem();
  itemTypeId: number = 1;
  dataSubscription: Subscription;
  itemListTitle: string = "Trading Items";
  gridHideForOverview: boolean = true;
  allData: any = [];
  processSum: any = 0;
  waitingSum: any = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private dataService: DataService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private productServiceService: ProductServiceService,
    private deliveryOrderService: InventoryOverviewService,
    private manufactureService: ManufactureService,
    private toaster: ToastrService,
    public authService: AuthService
  ) {}

  private gridApi;
  private gridColumnApi;
  domLayout = "autoHeight";
  isItemListUpdate = false;
  searchText: string;
  planId = 0;
  ProductList: any = [];
  grilcol;
  deliveryOrderData: any = [];
  allreceiptsData: any = [];
  allreceiptsDataWaiting: any = [];
  allreceiptsDataReady: any = [];

  alldeliveryData: any = [];
  alldeliveryDataWaiting: any = [];
  alldeliveryDataReady: any = [];
  allDeliveryBackOrderData: any = [];

  receiptsWaitingCount = 0;
  receiptsReadyCount = 0;

  deliveryWaitingCount = 0;
  deliveryReadyCount = 0;
  deliveryBackOrderCount: number = 0;

  manufacturingOrderWaitingCount: number = 0;
  manufacturingOrderToProcessCount: number = 0;
  manufacturingOrderBackOrderCount: number = 0;

  allReturnData: any = [];
  allReturnDataWaiting: any = [];
  allReturnDataReady: any = [];
  allReturnBackOrderData: any = [];

  returnWaitingCount: number = 0;
  returnReadyCount: number = 0;
  returnBackOrderCount: number = 0;

  allPurchaseReturnData
  allPurchaseReturnBackOrderData
  allPurchaseReturnWaitingData
  allPurchaseReturnReadyData

  purchaseReturnBackOrderCount: number = 0;
  purchaseReturnWaitingCount: number = 0;
  purchaseReturnReadyCount: number = 0;

  userInfo: any;

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.dataSubscription = this.route.params.subscribe((params) => {
      this.ProductList = [];
      this.itemTypeId = Number(this.route.snapshot.paramMap.get("typeId"));
      switch (this.itemTypeId) {
        case 1:
          this.itemListTitle = "Trading Items";
          break;
        case 2:
          this.itemListTitle = "Raw Materials";
          break;
        case 3:
          this.itemListTitle = "Finished Products";
          break;
        case 4:
          this.itemListTitle = "Storage Items";
          break;
        case 5:
          this.itemListTitle = "Services";
          break;
        case 6:
          this.itemListTitle = "Inventory Overview";
          break;
        default:
          this.itemListTitle = "Trading Items";
          break;
      }
      if (this.itemTypeId == 6) {
        this.gridHideForOverview = false;
      } else {
        this.gridHideForOverview = true;
      }

      if (this.itemTypeId == 1) {
        this.getLastPlan();
      } else {
        this.getAllProduct();
      }
    });
    this.dataService.changeSelectedOrganization.subscribe((x) => {
      this.isItemListUpdate = x;
      this.getAllProduct();
    });
    this.getCounts();
    this.getManufactureOrderCounts();
  }

  getManufactureOrderCounts() {
    this.manufactureService.getManufactureOrderCounts().subscribe(x => {
      let manufacturingOrderWaiting = x.find(m => m.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED && m.IsBackOrder == false)?.NumberOfOrders;
      this.manufacturingOrderWaitingCount = manufacturingOrderWaiting == null ? 0 : manufacturingOrderWaiting;

      let manufacturingOrderToProcess = x.find(m => m.Status == ManufactureOrderStatus.TO_BE_PROCESSED && m.IsBackOrder == false)?.NumberOfOrders;
      this.manufacturingOrderToProcessCount = manufacturingOrderToProcess == null ? 0: manufacturingOrderToProcess;

      let backOrderList = x.filter(m => (m.Status == ManufactureOrderStatus.TO_BE_PROCESSED || m.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED) && m.IsBackOrder == true);
      backOrderList?.forEach( item => { this.manufacturingOrderBackOrderCount += item.NumberOfOrders; });
    });
  }

  public getCounts() {
    this.deliveryOrderService.GetDeliveryOrder().subscribe((x) => {
      this.deliveryOrderData = x;

      this.allreceiptsData = this.deliveryOrderData.filter((x) => x.OperationType == 1 || x.OperationType == 2);
      this.allreceiptsDataWaiting = this.allreceiptsData.filter((x) => x.StatusId == 1);
      this.receiptsWaitingCount = this.allreceiptsDataWaiting.length;

      this.allreceiptsDataReady = this.allreceiptsData.filter((x) => x.StatusId == 3);
      this.receiptsReadyCount = this.allreceiptsDataReady.length;

      this.alldeliveryData = this.deliveryOrderData.filter((x) => x.OperationType == 3 || x.OperationType == 4);
      this.allDeliveryBackOrderData = this.alldeliveryData.filter((x) => x.ParentId != null && x.StatusId == 1 && (x.OperationType == 3 || x.OperationType == 4));
      this.deliveryBackOrderCount = this.allDeliveryBackOrderData.length;
      
      this.alldeliveryDataWaiting = this.alldeliveryData.filter((x) => x.StatusId == 1 && (x.ParentId == null || x.ParentId == ""));
      this.deliveryWaitingCount = this.alldeliveryDataWaiting.length;

      this.alldeliveryDataReady = this.alldeliveryData.filter((x) => x.StatusId == 3);
      this.deliveryReadyCount = this.alldeliveryDataReady.length;

      this.allReturnData = this.deliveryOrderData.filter((x) => x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice || x.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder);
      this.allReturnBackOrderData = this.allReturnData.filter((x) => x.ParentId != null && x.StatusId == 1 && (x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice || x.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder));
      this.allReturnDataWaiting = this.allReturnData.filter((x) => x.StatusId == 1);
      this.allReturnDataReady = this.allReturnData.filter((x) => x.StatusId == 3);
      
      this.returnBackOrderCount = this.allReturnBackOrderData.length;
      this.returnWaitingCount = this.allReturnDataWaiting.length;
      this.returnReadyCount = this.allReturnDataReady.length;

      this.allPurchaseReturnData = this.deliveryOrderData.filter((x) => x.OperationType == WareHouseOperationType.PurchaseItemReturnedForBill || x.OperationType == WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder);
      this.allPurchaseReturnBackOrderData = this.allPurchaseReturnData.filter((x) => x.ParentId != null && x.StatusId == 1 && (x.OperationType == WareHouseOperationType.PurchaseItemReturnedForBill || x.OperationType == WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder));
      this.allPurchaseReturnWaitingData = this.allPurchaseReturnData.filter((x) => x.StatusId == 1);
      this.allPurchaseReturnReadyData = this.allPurchaseReturnData.filter((x) => x.StatusId == 3);
      
      this.purchaseReturnBackOrderCount = this.allPurchaseReturnBackOrderData.length;
      this.purchaseReturnWaitingCount = this.allPurchaseReturnWaitingData.length;
      this.purchaseReturnReadyCount = this.allPurchaseReturnReadyData.length;
    });
  }

  public btnStatus() {
    this.deliveryOrderService.GetDeliveryOrder().subscribe((x) => {
      this.allData = x;
    });
  }

  columnDefs = [
    {
      headerName: "Item Code",
      field: "ItemCode",
      cellStyle: { color: "blue", cursor: "pointer" },
    },
    { headerName: "Item Name", field: "ItemName" },
    { headerName: "Cost Price", field: "PurchasePrice",
      cellRenderer: (params) => { return this.formatCostPrice(params); }
    },
    { headerName: "Sale Price", field: "SaleUnitPrice",
      cellRenderer: (params) => { return this.formatValue(params); }
    },
    { headerName: "Quantity", field: "StockQuantity" },
    {
      headerName: "Booked Stock",
      field: "BookedStock",
      cellStyle: { color: "blue", cursor: "pointer" },
      onCellClicked: (event: CellClickedEvent) => this.openSalesOrderBookedStockPopup(event.data),
    },
    { headerName: "Balance", field: "Balance" },
  ];

  formatValue(data) {
    if(data.data.IsSaleItem == true) {
      return data.data.SaleUnitPrice;
    }
    else{
      return '-';
    }
  }

  formatCostPrice(data) {
    if(data.data.IsPurchaseItem == true) {
      return data.data.PurchasePrice;
    }
    else{
      return '-';
    }
  }

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };

  openSalesOrderBookedStockPopup(data: any){
    const modalRef = this.modalService.open(SalesOrderBookedStockPopupComponent, { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.salesOrderData = data;
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

  getLastPlan = () => {
    this.productServiceService.GetLastPlan().subscribe((x) => {
      this.planId = x;
      this.getAllProduct();
    });
  };
  getAllProduct = () => {
    this.spinner.show();
    this.ProductList = [];
    this.productServiceService.GetItemList(this.itemTypeId).subscribe((x) => {
      this.spinner.hide();
      this.ProductList = [...x, ...this.ProductList];
      this.itemTypeId === 5 ? this.HideValue(false) : this.HideValue(true);
    });
  };
  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
  }

  HideValue(b) {
    const columns = this.gridColumnApi?.getAllColumns();
    const valueColumn = columns?.filter((column) => column.getColDef().headerName === "Booked Stock")[0];
    const valueColumn1 = columns?.filter((column) => column.getColDef().headerName === "Quantity")[0];
    const valueColumn2 = columns?.filter((column) => column.getColDef().headerName === "Cost Price")[0];
    const valueColumn3 = columns?.filter((column) => column.getColDef().headerName === "Balance")[0];
    this.gridColumnApi?.setColumnsVisible([valueColumn, valueColumn1, valueColumn2, valueColumn3], b);
    this.gridApi.sizeColumnsToFit();
  }

  HideTwoCol(b) {
    const columns = this.gridColumnApi?.getAllColumns();
    const valueColumn = columns.filter((column) => column.getColDef().headerName === "Booked Stock")[0];
    const valueColumn3 = columns.filter((column) => column.getColDef().headerName === "Balance")[0];
    this.gridColumnApi.setColumnsVisible([valueColumn, valueColumn3], b);
    this.gridApi.sizeColumnsToFit();
  }

  HideRawColumn(b) {
    const columns = this.gridColumnApi.getAllColumns();
    const valueColumn = columns.filter((column) => column.getColDef().headerName === "Sale Price")[0];
    this.gridColumnApi.setColumnsVisible([valueColumn], b);
    this.gridApi.sizeColumnsToFit();
  }
  public createNewItem = () => {
    const modalRef = this.modalService.open(ItemCreateComponent, { size: "lg", backdrop: "static", keyboard: false, centered: true });
    this.aModel.Id = 0;
    this.aModel.ItemTypeId = this.itemTypeId;
    modalRef.componentInstance.fromParent = this.aModel;
    modalRef.result.then(
      (result) => {
        if (result.Success) {
          this.getAllProduct();
        }
      },
      (reason) => {}
    );
  };

  public createNewService = () => {
    const modalRef = this.modalService.open(StorageItemCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered: true });
    this.aModel.Id = 0;
    this.aModel.ItemTypeId = this.itemTypeId;
    modalRef.componentInstance.fromParent = this.aModel;
    modalRef.result.then((result) => {
      if (result.Success){
        this.getAllProduct();
      }}, (reason) => {}
    );
  }

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  onRowClicked(event) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Item Code") {
      let id = event.data.Id;
      this.router.navigate([`/products/${id}`]);
    }
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  };

  Refresh = () => {
    this.spinner.show();
    this.deliveryOrderService.RecalculateStatus().subscribe( x =>  {
      if( x.Success ){
        this.spinner.hide();
        location.reload();
      }
    });
  }

  ManufacturingOrdersRefresh() {
    this.spinner.show();
    this.manufactureService.recalculateStatus().subscribe((x) => {
      if(x.Success){
        this.toaster.success(x.Message);
        this.spinner.hide();
        location.reload();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

}
