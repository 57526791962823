

    <ul class="nav nav-tabs justify-content-center">
   
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" [ngClass]="{'active': message ===2}" (click)="setTab('budget/monthly-budget')">Budget</a>
        </li>
        
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" [ngClass]="{'active':message===1}" (click)="setTab('expense/monthly-expense')">Expense</a>
        </li>
    </ul>
   

