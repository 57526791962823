<div class="container">
    <div class="modal-header">
        <button type="button" class="close mr-2" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ag-grid-angular #agGrid id="myGrid" class="ag-theme-balham col"
            [columnDefs]="gridOptions.columnDefs" [defaultColDef]="gridOptions.defaultColDef"
            [domLayout]="gridOptions.domLayout" [rowData]="billList" (gridReady)="onGridReady($event)"
            [domLayout]="'autoHeight'">
        </ag-grid-angular>
    </div>    
</div>