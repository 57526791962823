import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { MainServiceService } from '../../../service/Main/main-service.service'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'contact-page',
  templateUrl: './details-contact.component.html',
  styleUrls: ['./details-contact.component.css']
})
export class ContactPageComponent implements OnInit {
  contactForm: FormGroup;
  contactType = 1;
  contactId: number;
  selected = true;
  contactData: any = [];
  ContactItem: any = [];
  constructor(public fb: FormBuilder,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private toaster: ToastrService,
              private mainService: MainServiceService,
              private spinner: NgxSpinnerService ) { }

  ngOnInit(): void {
    this.contactId  = Number(this.activeRoute.snapshot.paramMap.get("contactId"));
    this.initContactForm();
    this.getcustomerDetails();
  }

  getcustomerDetails = () => {
    this.mainService.GetCustomerById(this.contactId).subscribe((x) => {

      if (x) {
        this.contactData = x;
        this.ContactItem = x.ContactItems;
        this.contactForm.patchValue({
          Id: this.contactData.Id,
          PrimaryPersonFirstName : this.contactData.PrimaryPersonFirstName,
          PrimaryPersonLastName : this.contactData.PrimaryPersonLastName,
          CompanyName : this.contactData.CompanyName,
          Email : this.contactData.Email,
          StreetAddress : this.contactData.StreetAddress,
          ContactNo : this.contactData.ContactNo,
          Website : this.contactData.Website,
          SkypeNo : this.contactData.SkypeNo,
          IsCustomer : this.contactData.IsCustomer,
          IsSupplier : this.contactData.IsSupplier,
          IsDeliveryPartner : this.contactData.IsDeliveryPartner
        });
        if (this.contactForm.value.IsDeliveryPartner == true)
        {
          this.contactForm.get('IsCustomer').disable();
          this.contactForm.get('IsSupplier').disable();
        }
      }
    });
  }

  initContactForm = () => {
    this.contactForm = this.fb.group({
      Id:[''],
      PrimaryPersonFirstName : ['', Validators.required],
      CompanyName : ['', Validators.required],
      Email : [''],
      StreetAddress : [ ''],
      Website : [''],
      SkypeNo : [''],
      ContactNumber : [''],
      IsCustomer : [false],
      IsSupplier : [false],
      ContactNo: [''],
      IsDeliveryPartner: ['']
    });
  }

  SaveContactInfo = () => { 
    if (this.contactForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    this.spinner.show();
    var s = this.setData();
    this.mainService.saveContact(s).subscribe((x)=>{
      this.toaster.success('Contact updated successfully');
      this.spinner.hide();
      this.router.navigate(['/contacts/list/all']);
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  setData() {
    let data = {
     Id: this.contactForm.value.Id,
     PrimaryPersonFirstName: this.contactForm.value.PrimaryPersonFirstName,
     CompanyName: this.contactForm.value.CompanyName,
     Email : this.contactForm.value.Email,
     ContactNumber: this.contactForm.value.ContactNumber,
     IsCustomer : this.contactForm.value.IsCustomer,
     IsSupplier : this.contactForm.value.IsSupplier,
     ContactItems: this.ContactItem,
     SkypeNo: this.contactForm.value.SkypeNo,
     Website: this.contactForm.value.Website,
     StreetAddress: this.contactForm.value.StreetAddress,
     ContactNo: this.contactForm.value.ContactNo,
     IsDeliveryPartner: this.contactForm.value.IsDeliveryPartner
    };
    return data;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  createBlankItem = () => {
    const item = { Phone: '', Email: '', Notes: '', selected: true };
    this.ContactItem.push(item);
  }

  removeContactItem = (i) => {
    let data = [];
    this.ContactItem.map((x, index) => {
      if (index != i) {
        data.push(x);
      }
    }) ;
    this.ContactItem = data;

  }
}
