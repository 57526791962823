import { Component, OnInit } from '@angular/core';
import {BillsServiceService} from '../../../service/Bill/bills-service.service'
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { RepeatingBillCount } from '../../../models/income.model';
import {RouterLinkRendereComponent} from '../../../components/router-link-rendere/router-link-rendere.component';
import {AlertService} from '../../../service/Alert/alert.service';
@Component({
  selector: 'app-repeating-bill-list',
  templateUrl: './repeating-bill-list.component.html',
  styleUrls: ['./repeating-bill-list.component.css']
})
export class RepeatingBillListComponent implements OnInit {

  billList: any = [];
  gridApi;
  name: string;
  billId: number;
  catId: number;
  filteredBills:any=[];
  categoryCount:RepeatingBillCount= new RepeatingBillCount();

  constructor(
    private billService: BillsServiceService,
    private dataService: DataService,
    private toastr: ToastrService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.catId = Number(this.router.snapshot.paramMap.get('catId'));
    this.dataService.changeSelectedOrganization.subscribe(x => {
      this.getBillList();
    });
    this.getBillList();
  }

 private getBillList=()=>{
    this.spinner.show();
    this.billService.GetRepeatingBills().subscribe((x)=>{
      this.billList=x;
      for(let i=0;i<this.billList.length;i++) this.billList[i].BillNo = 'Bill-' + this.billList[i].BillNo;
      this.reLoadRowData(this.catId);
      this.setCategoryCount();
      this.spinner.hide();
    }, err=>{
      this.toastr.error("There is an error,please check");
      this.spinner.hide();
    })
  }

 private setCategoryCount() {
  this.categoryCount.all=this.billList.length;
  this.categoryCount.daily=this.billList.filter(x=>x.RepeatingTypeId==1).length;
  this.categoryCount.weekly=this.billList.filter(x=>x.RepeatingTypeId==2).length;
  this.categoryCount.monthly=this.billList.filter(x=>x.RepeatingTypeId==3).length;
  this.categoryCount.yearly=this.billList.filter(x=>x.RepeatingTypeId==4).length;
 }

 public reLoadRowData = (catId: number) => {
    this.catId = catId;
    this.filteredBills = catId == 0 ? this.billList : this.billList.filter(y=>y.RepeatingTypeId == catId);
  }

  domLayout= 'autoHeight'
  rowHeight= 275
  columnDefs = [
    { headerName: 'Bill No', field: 'BillNo',flex: 1,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    cellRendererFramework: RouterLinkRendereComponent,
    cellRendererParams: {
        inRouterLink: '/expense/bill/',
      }},
    { headerName: 'Description',field: 'Description', flex: 1 },
    { headerName: 'Date', field: 'BillDate', flex: 1, valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Bill Amount', field: 'BillAmount', flex: 1, valueFormatter: this.formatBillAmount },
    { headerName: 'Last Created At', field: 'LastCreatedAt',flex: 1, valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: ' ',width: 40, field: 'IsBillCreated', cellRenderer: function(params) {
        return params.value? "<image src='../../../../assets/image/basic_bill/check.png' style='height:15px;margin-top:-4px;'></image>":'';
      }
  },
  { headerName: "", width: 35, suppressMenu: true, suppressSorting: true, template: `<span  (click)="deleteRows()"><img class="icon-Trash" src="../../../../assets/image/icon-Trash.png"></span>` }
]
  defaultColDef = {
    sortable: true,
    filter: true,
  };
  rowSelection='multiple';
  onGridReady(event){
    this.gridApi = event.api;
  }

  getSelectedRowData() {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    for(let i=0;i<selectedData.length;i++){
      selectedData[i].BillNo = selectedData[i].BillNo.toString().substr(5,10)
    }
    console.log(selectedData);
    if(selectedData.length==0){
      this.toastr.warning("select at least one bill");
      return;
    }
    this.spinner.show();
    this.billService.CreateBillFromRepeating(selectedData).subscribe((x)=>{
      this.toastr.success("Bills successfully created.");
      this.getBillList();
      this.spinner.hide();
    });
  }


  formatBillAmount(value){
    let valueData = Number(value.data.BillAmount.toFixed(2))
    return valueData.toLocaleString();
  }

  convertGridDateIntoDisplay(date) {
    if(date.value==null)
     return '';
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  onCellClicked(e) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "") {
      this.billId = e.data.Id;
      console.log(e);
      this.ConfirmationCheck(e);
    }
  }

  ConfirmationCheck(e)
  {
    this.alertService.confirm('Do you want to stop repeating this bill?', '', `Bill No: ${e.data.BillNo}`, `Repeating Type: ${e.data.RepeatingType}`)
    .then((confirmed) => {
      if (confirmed){
        this.stopRepeating();
      }

      },
      err => {
    }
    ).catch(() =>
    {this.spinner.hide();
    });
}



  stopRepeating = () => {
    this.spinner.show();
    this.billService.StopRepeating(this.billId).subscribe((x) => {
      if (x.Success){
      this.toastr.success('Stop repeated succesfully.');
      this.getBillList();
      this.spinner.hide();
      }
    });
  }
}
