import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as html2canvas from "html2canvas";
import jspdf from 'jspdf';
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { RouterLinkRendereComponent } from "src/app/components/router-link-rendere/router-link-rendere.component";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { ReceivemoneyServiceService } from "src/app/service/ReceiveMoney/receivemoney-service.service";
import { ViewReceiveMoneyComponent } from "../../view-receive-money/view-receive-money/view-receive-money.component";

@Component({
  selector: "app-receive-money-list",
  templateUrl: "./receive-money-list.component.html",
  styleUrls: ["./receive-money-list.component.css"],
})
export class ReceiveMoneyListComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private dataService: DataService,
    private modalService: NgbModal,
    private _ngZone: NgZone,
    public ngxSmartModalService: NgxSmartModalService,
    private receivemoneyServiceService: ReceivemoneyServiceService
  ) {}

  private gridApi;
  private gridColumnApi;
  domLayout = "autoHeight";
  isItemListUpdate = false;
  searchText: string;
  ReceiveMoneyList: any = [];
  ReceiveMoneyFilterList: any = [];
  draftCount:number = 0;
  submitCount:number = 0;
  currentMonthCount: number = 0;
  lastMonthCount: number = 0;
  lastTwoMonthCount: number = 0;
  SubmitterReceiveMoneyFilterList: any[];
  isMonthTabOpen: Boolean = false;
  ngOnInit(): void {}

  columnDefs = [
    {
      headerName: "Date",
      field: "ReceivedDate",
      valueFormatter: this.convertGridDateIntoDisplay,
    },
    { headerName: "From", field: "FromName" },
    { headerName: "Account Name", field: "ChartOfAccount.AccountName" },
    { headerName: "Reference", field: "ReferenceNo" },
    { headerName: "Received Amount", 
      field: "SubTotalAmount",
      valueFormatter: this.convertToLocaleString
    },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    cellStyle: function (params) {
      //mark police cells as red
      if (params.column.colId == "ReceivedDate") {
        return { color: "blue", cursor: "pointer" };
      }
    },
  };

  convertToLocaleString(params) {
    const subTotalAmount = params.value;
    return subTotalAmount.toLocaleString();
  }

  getAllProduct = () => {
    this.spinner.show();
    this.ReceiveMoneyList = [];
    this.ReceiveMoneyFilterList = [];
    this.receivemoneyServiceService.GetReceiveMoneyList().subscribe((x) => {
      this.spinner.hide();
      console.log(x);
      this.ReceiveMoneyList = [...x, ...this.ReceiveMoneyList];
      this.ReceiveMoneyFilterList = this.ReceiveMoneyList;
      this.draftCount = this.ReceiveMoneyList.filter(x=>x.IsDraft==true).length;
      this.submitCount = this.ReceiveMoneyList.length - this.draftCount;
      this.SubmitterReceiveMoneyFilterList = this.ReceiveMoneyList.filter(x=>x.IsDraft==false);
      this.currentMonthCount = this.currentMonthReceiveMoneyList(this.SubmitterReceiveMoneyFilterList).length;
      this.lastMonthCount = this.lastMonthReceiveMoneyList(this.SubmitterReceiveMoneyFilterList).length;
      this.lastTwoMonthCount = this.lastTwoMonthsReceiveMoneyList(this.SubmitterReceiveMoneyFilterList).length;
      console.log(this.ReceiveMoneyList);
    });
  };

  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getAllProduct();
  }
  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }
  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }
  onRowClicked(event) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Date") {
      let id = event.data.Id;
      this.router.navigate([`/receive-money/${id}`]);
    }
  }
  editItems(e) {
    const modalRef = this.modalService.open(ViewReceiveMoneyComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.receiveId = e.data.Id;
    modalRef.result.then(
      (result) => {
        this.getAllProduct();
      },
      (reason) => {}
    );
  }
  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  };
  gotoUrl = () => {
    this.router.navigate([`/receivemoney/create`]);
  };
  convetToPDF = () => {
    var data = document.getElementById("rcv");
    (html2canvas as any)(data).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("ReceiveMoneyList.pdf");
    });
  };

  reLoadRowData(data:any){
    this.spinner.show();
    switch(data){
      case 'All':
        this.isMonthTabOpen = false;
        this.ReceiveMoneyFilterList = this.ReceiveMoneyList;
        break;
      case 'Draft':
        this.isMonthTabOpen = false;
        this.ReceiveMoneyFilterList = this.ReceiveMoneyList.filter(x=>x.IsDraft==true);
        break;
      case 'Submitted':
        this.isMonthTabOpen = true;
        this.ReceiveMoneyFilterList = this.ReceiveMoneyList.filter(x=>x.IsDraft==false);
        break;
      case 'Current_Month':
        this.isMonthTabOpen = true;
        this.ReceiveMoneyFilterList = this.ReceiveMoneyList.filter(x=>x.IsDraft==false);
        this.ReceiveMoneyFilterList = this.currentMonthReceiveMoneyList(this.ReceiveMoneyFilterList);
        break;
      case 'Last_Month':
        this.isMonthTabOpen = true;
        this.ReceiveMoneyFilterList = this.ReceiveMoneyList.filter(x=>x.IsDraft==false);
        this.ReceiveMoneyFilterList = this.lastMonthReceiveMoneyList(this.ReceiveMoneyFilterList);
        break;
      case 'Last_Two_Months':
        this.isMonthTabOpen = true;
        this.ReceiveMoneyFilterList = this.ReceiveMoneyList.filter(x=>x.IsDraft==false);
        this.ReceiveMoneyFilterList = this.lastTwoMonthsReceiveMoneyList(this.ReceiveMoneyFilterList);
        break;
    }
    this.spinner.hide();
  }

  currentMonthReceiveMoneyList(ReceiveMoneyList) {
    const filteredList = ReceiveMoneyList.filter(item => {
      const isCurrentMonth = this.IsCurrentMonth(item.ReceivedDate);
      return isCurrentMonth;
    });
    return filteredList;
  }

  IsCurrentMonth(ReceivedDate){
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const receivedMonth = new Date(ReceivedDate).getMonth();
    const receivedYear = new Date(ReceivedDate).getFullYear();

    return currentYear === receivedYear && currentMonth === receivedMonth;
  }

  lastMonthReceiveMoneyList(ReceiveMoneyList) {
    const filteredList = ReceiveMoneyList.filter(item => {
      const isLastMonth = this.IsLastMonth(item.ReceivedDate);
       return isLastMonth;
    });
    return filteredList;
  }

  IsLastMonth(ReceivedDate){
    const currentMonth = new Date().getMonth();
    const receivedMonth = new Date(ReceivedDate).getMonth();

    const monthDifference = currentMonth - receivedMonth;
    return monthDifference != 0 && (monthDifference == 1 || (monthDifference + 12) == 1);
  }

  lastTwoMonthsReceiveMoneyList(ReceiveMoneyList) {
    const filteredList = ReceiveMoneyList.filter(item => {
      const isLastTwoMonth = this.IsLastTwoMonth(item.ReceivedDate);
      return isLastTwoMonth;
    });
    return filteredList;
  }

  IsLastTwoMonth(ReceivedDate){
    const currentMonth = new Date().getMonth();
    const receivedMonth = new Date(ReceivedDate).getMonth();

    const monthDifference = currentMonth - receivedMonth;
    return monthDifference != 0 && (monthDifference == 2 || (monthDifference + 12) == 2);
  }
}
