import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvanceService } from '../../../service/Advance/advance.service';

@Component({
  selector: 'app-sales-order-adjustment',
  templateUrl: './sales-order-adjustment.component.html',
  styleUrls: ['./sales-order-adjustment.component.css']
})
export class SalesOrderAdjustmentComponent implements OnInit {

  public adjustAdvanceForm: FormGroup;
  @Input() fromParent: any;

  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private advanceService: AdvanceService) { }

  ngOnInit(): void {
    console.log(this.fromParent);
    this.initializeForm();
  }

  initializeForm() {
    this.adjustAdvanceForm = this.fb.group({
      RefId: [this.fromParent.RefId],
      ContactId: [this.fromParent.ContactId],
      TotalAdvanceAmount: [this.fromParent.TotalAdvanceAmount],
      AdjustableAmount: [this.fromParent.AdjustableAmount],
      RemainingBalance: [this.fromParent.TotalAdvanceAmount],
      AdjustAmount: [this.fromParent.AdjustAmount, Validators.required],
      RemainingDueAmount: [this.fromParent.RemainingDueAmount]
    });
  }

  /**
   * CalculateDue
   */
  public CalculateDue() {
    var fValue = this.adjustAdvanceForm.value;
    if (fValue.AdjustAmount > fValue.TotalAdvanceAmount){
      this.toaster.warning('Invalid adjustment amount!');
      this.adjustAdvanceForm.controls['AdjustAmount'].patchValue(0);
      this.adjustAdvanceForm.controls['RemainingBalance'].patchValue(fValue.TotalAdvanceAmount);
      return;
    }

    this.adjustAdvanceForm.controls['RemainingDueAmount'].patchValue(fValue.AdjustableAmount - fValue.AdjustAmount);
    this.adjustAdvanceForm.controls['RemainingBalance'].patchValue(fValue.TotalAdvanceAmount - fValue.AdjustAmount);
  }
  public save() {
    if (this.adjustAdvanceForm.invalid){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    if (this.adjustAdvanceForm.value.AdjustAmount <= 0){
      this.toaster.warning('Invalid Adjustment Amount !');
      return;
    }
    this.spinner.show();
    this.advanceService.SalesAdjustment(this.adjustAdvanceForm.value).subscribe((x) => {
      if (x.Success){
        this.toaster.success('Adjustment has been successfully updated.');
        this.activeModal.close(x.Success);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }


     public skipAdjustment() {
      const obj = {Success: false};
      this.activeModal.close(obj);
    }
    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

}
