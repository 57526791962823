<app-dashboard>
    <app-page-header-title
      mainTitle="Quotation Overview"
      subTitle="Home"
      backURL="/">
      <div class="full-width text-align-right">
        <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
        <button type="button" routerLink="/quotation/create-new-quotation" class="eCount-def-btn ml-4">Create New Quotaion</button>
        <button type="button" (click)="convertToPDF()" class="eCount-button3 ml-4">{{'Contacts.contactList.export'| translate}}</button>
      </div>
      
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center ">
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('All')" [routerLinkActive]="['active']" routerLink="/quotation/quotation-list/All">All</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData('Active')" [routerLinkActive]="['active']" routerLink="/quotation/quotation-list/Active">Active ({{activeCount}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData('Inactive')" [routerLinkActive]="['active']" routerLink="/quotation/quotation-list/Inactive">Inactive ({{inactiveCount}})</a>
      </li>

    </ul>
    <div class="row pt-3" id="printBills">
        <ag-grid-angular
            style="font-size: 14px;"
            class="ag-theme-balham col"
            [rowData]="filteredQuotations"
            [columnDefs]="columnDefs"
            [domLayout]="domLayout"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
    </div>
    
    </app-dashboard>
    
    