import { Component, OnInit } from '@angular/core';
import {BillsServiceService} from '../../../service/Bill/bills-service.service';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MonthlyExpenseDetailsComponent } from '../monthly-expense-details/monthly-expense-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CellClickedEvent } from 'ag-grid-community';
@Component({
  selector: 'app-monthly-expense',
  templateUrl: './monthly-expense.component.html',
  styleUrls: ['./monthly-expense.component.css']
})
export class MonthlyExpenseComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
 currentYear: any;
 currentMonth: any;
 Year: any;
 paidFromBank: number;
 message: number;
 remainingAmount: number;
 TotalDeposit: number;
 totalPaidAmount: number;
 openingBalance: any;
 cashReceievedMonthly: any = [];
 paidFromCash: number;
 paidFromDigitalCash: number;
 Expense: any = [];
 monthlyExpense: any = [];
 checkDeposit = false;
 date = new Date().getFullYear();
 yearList = [
  {Value: this.date - 1 , year: this.date - 1},
  {Value: this.date , year: this.date},
 ];
 monthList = [
  { Value: 1, Text: 'January' },
  { Value: 2, Text: 'February' },
  { Value: 3, Text: 'March' },
  { Value: 4, Text: 'April' },
  { Value: 5, Text: 'May' },
  { Value: 6, Text: 'June' },
  { Value: 7, Text: 'July' },
  { Value: 8, Text: 'August' },
  { Value: 9, Text: 'September' },
  { Value: 10, Text: 'October' },
  { Value: 11, Text: 'November' },
  { Value: 12, Text: 'December' }
];

totalExpense: number = 0;
totalBudget: number = 0;
totalRemainingBudget: number = 0;

  constructor(private billService: BillsServiceService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.currentYear = this.date;
    this.currentMonth = new Date().getMonth() + 1;
    this.getData();
  }
  currencyFormatter(currency, sign) {
    var sansDec = currency.toFixed(2);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
  }
  currencyFormatters(currency, sign) {
    var sansDec = currency.toFixed(0);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
  }
  columnDefs = [
    { headerName: 'Account Name', field: 'AccountName', width: 155},
    { headerName: 'Expense',
      field: 'ExpenseView',
      width: 130,
      cellStyle: { color: "blue", cursor: "pointer" },
      valueFormatter: params => this.currencyFormatter(params.data.Expense, ''),
      onCellClicked: (event: CellClickedEvent) => this.openMonthlyExpenseDetails(event.data),
    },
    { headerName: 'Budget Amount', field: 'BudgetView',width: 140,valueFormatter: params => this.currencyFormatter(params.data.Budget, '') },
    { headerName: 'Remaining Budget Amount',
      field: 'RemainingBudgetView',
      width: 200,
      cellStyle: function(params) {
        if (params.data.RemainingBudget < 0) {
          return { color: "red" };
        }
      }
    },

  ];
  columnDefsm = [
    { headerName: 'Date', field: 'CreatedAt',width: 200, valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Deposit Amount', field: 'Debit',width: 400,type: 'rightAligned' }

  ];

  domLayout ='autoHeight';

  defaultColDef = {
    resizable: true,
    flex: 1
  };
  defaultColDefs = {
    resizable: true
  };
getData = () => {
  this.monthlyExpense = [];
  this.cashReceievedMonthly = [];
  this.spinner.show();
  this.billService.getMonthlyExpense(this.currentMonth, this.currentYear).subscribe((x: any) => {
      this.monthlyExpense = [ ...x.expense, ...this.monthlyExpense];
      this.monthlyExpense = this.monthlyExpense.filter(x=>x.AccountName!='Accounts Payable' && x.AccountName!='Accounts Receivable');
      this.totalExpense = this.monthlyExpense.reduce((prev, next) => prev + next.Expense, 0);
      this.totalBudget = this.monthlyExpense.reduce((prev, next) => prev + next.Budget, 0);
      this.totalRemainingBudget = this.monthlyExpense.reduce((prev, next) => prev + next.RemainingBudget, 0);
      this.paidFromBank = x.paidFromBank ?? 0;
      this.paidFromCash = x.paidFromCash ?? 0;
      this.paidFromDigitalCash = x.paidFromDigitalCash ?? 0;
      this.openingBalance = x.openingBlance;
      this.TotalDeposit = x.totalDeposit;
      this.totalPaidAmount = x.totalPaidAmount;
      this.remainingAmount = x.remainingAmount;
      this.cashReceievedMonthly = [...x.cashReceievedMonthly, ...this.cashReceievedMonthly];
      if(this.cashReceievedMonthly.length != 0) {
        this.cashReceievedMonthly.map(element => {
          element.Debit = element.Debit.toFixed(2);
        });
        this.cashReceievedMonthly.push(null);
      }
      this.spinner.hide();
      console.log(x);
      if(this.TotalDeposit>0){
        this.checkDeposit = true;
      }
      else{
        this.checkDeposit = false;
      }
    },(err)=>{
      this.spinner.hide();
  });
}
onGridReady(event) {
  this.gridApi = event.api;
  this.gridColumnApi = event.columnApi;
  this.gridApi.sizeColumnsToFit();
}

onBtnExport() {
  var params = this.getParams();
  this.gridApi.exportDataAsCsv(params);
}

getParams() {
  return {
  suppressQuotes: '',
  columnSeparator: '',
  customHeader: '',
  customFooter: '',};
}

convertCalenderDate(date) {
  let dateString = moment(date).format("Do MMM YYYY")
  return dateString;
}
convertGridDateIntoDisplay(date) {
  if(date.value==null)
   return '';
  let dateString = moment(date.value).format("Do MMM YYYY")
  return dateString;
}

openMonthlyExpenseDetails(data: any){
  const modalRef = this.modalService.open(MonthlyExpenseDetailsComponent,
  { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
  modalRef.componentInstance.month = this.currentMonth;
  modalRef.componentInstance.year = this.currentYear;
  modalRef.componentInstance.accountId = data.AccountId;
}

formatTotalRemainingBudget() {
  let amount = 0;
  if(this.totalRemainingBudget < 0) amount = this.totalRemainingBudget * (-1);
  let formatAmount = this.currencyFormatter(amount, '');
  if(this.totalRemainingBudget < 0){
    return '(' + formatAmount + ')'
  }
  return formatAmount;
}

}
