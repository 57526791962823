<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle">Do you want to return this item?</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<div class="assetDetails">
    <p>Asset No: {{ fromParent[0].AssetNo }}</p>
    <p>Asset Name: {{ fromParent[0].AssetName }}</p>
</div>  
<hr>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="noteForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-12">
                                <label for="DamageReason" class="formLabelFontWeight">Comment</label>
                                <div class="textarea-wrapper">
                                    <textarea #myInput rows="5" maxlength="200" type="text" class="form-control brs custom-textarea" id="this-textarea"
                                        placeholder="Write down some important notes using only 200 characters" formControlName="DamageReason"></textarea>
                                    <span class="remaning" >{{myInput.value.length}}/200</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-12 text-align-right">
                                <button type="button" class="eCount-def-btn ml-4" (click)="confirm()">Confirm</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
  
  