import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SubscriptionService } from '../Subscription/subscription.service';
import { promise } from 'protractor';
import { SubscriptionExpiredWarningModalComponent } from 'src/app/pages/Subscription/subscription-expired-warning-modal/subscription-expired-warning-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainServiceService } from '../Main/main-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedUserInfo: any
  baseurl = environment.baseUrl;
  subscriptionList: any[] = [];
  constructor(private http: HttpClient,
    private subscriptionService: SubscriptionService,
    private modalService: NgbModal,
    private mainService: MainServiceService) { }

  //updateLocalUserInfo
  updateLocalUserInfo(data): void {
    this.loggedUserInfo = data
  }

  // LoginService
  LoginService(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Account/Login', data).pipe(retry(1));
  }

   //PreRegister Account
   PreRegisterNewAccount(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Account/PreRegister', data).pipe(retry(1))
  }
   //Otp Validation
   IsValidateOtpCode(email, otpCode): Observable<any> {
    return this.http.post<any>(this.baseurl + `Account/IsValidateOtpCode?email=${email}&otpCode=${otpCode}`,{}).pipe(retry(1))
  }

  //Register Account
  RegisterNewAccount(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Account/Register',data,{}).pipe(retry(1))
  }

  SendForgetPasswordOTP(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Account/SendForgetPasswordOTP', data, {}).pipe(retry(1))
  }

  //ResetPassword Account
  ResetPassword(email, password): Observable<any> {
    return this.http.post<any>(this.baseurl + `Account/ResetPassword?email=${email}&password=${password}`,{}).pipe(retry(1))
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  IsLoggedIn(){
    const currentUser = JSON.parse(localStorage.getItem('userInfo'));
        if (currentUser && currentUser.Token){
          return true;
        }
        return false;
  }

  setUserInfo(info:string){
    localStorage.setItem('userInfo', info);
  }

  setAndStringifyUserInfo(info: any){
    let data = JSON.stringify(info);
    localStorage.setItem('userInfo', data);
  }

  getUserInfo(){
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  IsCompanySubscriptionExpired() {
    let userData = this.getUserInfo();
    let isStepConfigured = userData.SelectedCompany.StepConfigured;
    let isCompanySubscriptionExpired = userData.IsCompanySubscriptionExpired;
    let isGracePeriodExpired = userData.IsGracePeriodExpired;
    let isExpired = isCompanySubscriptionExpired ? isGracePeriodExpired : false;
    return isExpired && isStepConfigured;
  }

  IsCompanyHasOnlyDocumentFeature() {
    return false;
    let userData = this.getUserInfo();
    return userData.IsCompanyHasOnlyDocumentFeature && userData.SelectedCompany.StepConfigured;
  }

  getMyCompanyList(): Observable<any>{
    return this.http.get<any>(this.baseurl+'Account/GetMyCompanyList').pipe(retry(1));
  }

  public logout(){
        localStorage.removeItem('userInfo');
    }
    GetLastPlan(): Observable<any> {

      return this.http.get<any>(this.baseurl + 'SubscriptionPlan/GetLastPlan')
        .pipe(
          retry(1)
        )
    }

    openSubscriptionExpiredWarningModal() {
      const modalRef = this.modalService.open(SubscriptionExpiredWarningModalComponent, { size: "md", backdrop: "static", keyboard: false, centered: true,});
      modalRef.componentInstance.userInformation = this.getUserInfo();
      modalRef.result.then((result) => {}, (reason) => {console.log(reason);});
    }

    private sideMenuSource = new Subject<any>();
    sideMenuChanges$ = this.sideMenuSource.asObservable();
    reloadSideMenuList(): void {
      this.mainService.GetMenu().subscribe((x) => {
        // let userInfo = this.getUserInfo();
        // userInfo.IsCompanyHasOnlyDocumentFeature =  this.IsOnlyDocumentCompany(x);
        // this.setAndStringifyUserInfo(userInfo);
        this.sideMenuSource.next(x);
      });
    }

    IsOnlyDocumentCompany(x) {
      if(x.BillMenuList.length == 0 && x.InvoiceMenuList.length == 0 && x.InvenotryList.length == 0 && x.FactoryMenuList.length == 0 && x.AdvanceMenuList.length == 0 && x.FixedAssetMenuList.length == 0 && x.ManufactureMenuList.length == 0) {
        return true;
      }
      else
        return false;
    }
}
