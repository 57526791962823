
<app-dashboard>
    <app-page-header-title
        mainTitle="{{ 'quickBill.basicBills' | translate    }}"
        subTitle="Home"
        backURL="/">
        <div class="full-width text-align-right">
           
        </div>
    </app-page-header-title>
    <div class="headSection">
        <div class="row header-row">
            <div class="col-md-6">{{ 'quickBill.createABillFromTemplate' | translate }} </div>
            <div class="col-md-6"></div>
        </div>
        <div class="row">

            <div class="card create-card" *ngFor="let item of categoryList" (click)="openCreateBasicBill(item)">
                <div class="card-body">
                    <img src="{{item.imagePath}}" class="card-image"  alt="no image">
                  <h5 class="card-title"></h5>
                </div>
            </div>
        </div>
        <div class="float-right mr-4">
            <button (click)="showTemp(1)" *ngIf="show==true" class="buttdgn bn">{{ 'quickBill.showMore' | translate }}</button>
            <button (click)="showTemp(2)" *ngIf="hide==true" class="buttdgn bn">{{ 'quickBill.showLess' | translate }}</button>
        </div>
    </div>
    <div class="row" *ngIf="billList.length>0">
       <span style="margin-left: 20px;margin-bottom: 20px;font-weight: bold;"> {{ 'quickBill.last20Bills' | translate }}</span>
    </div>
    <div class="row" *ngIf="billList.length>0">
        <div class="w-100 col">
            <table class="table  f-ss-14px">
                <thead>
                    <tr class="tableHeader">
                        <th scope="col" class="table-header-color text-align-left" > {{ 'quickBill.billNo' | translate }}
                        </th>
                        <th scope="col" class="table-header-color text-align-left" >{{ 'quickBill.status' | translate }}
                        </th>
                        <th scope="col" class="table-header-color text-align-left" >{{ 'quickBill.account' | translate }}
                        </th>
                        <th scope="col" class="table-header-color text-align-center">{{ 'quickBill.billType' | translate }}
                        </th>
                        <th scope="col" class="table-header-color text-align-center">{{ 'quickBill.billAmount' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of billList">
                        <td class="text-align-left" style="color: blue;cursor: pointer;" (click)="openPopUp(item)">
                            {{item.BillNo}}
                        </td>
                        <td class="text-align-center">{{getStatus(item.StatusId)}}</td>
                        <td class="text-align-center">{{item.AccountName}}</td>
                        <td class="text-align-center">{{getName(item.RepeatingTypeId)}}</td>
                        <td class="text-align-center">{{item.BillAmount | currency:'':''}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-dashboard>