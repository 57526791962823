import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AssetItemCreateComponent } from '../asset-item-create/asset-item-create.component';
import { AssetItemModel } from '../../../models/fixedAsset.model';
import * as moment from 'moment';

@Component({
  selector: 'app-fixed-asset-draft',
  templateUrl: './fixed-asset-draft.component.html',
  styleUrls: ['./fixed-asset-draft.component.css']
})
export class FixedAssetDraftComponent implements OnInit {
  public aModel : AssetItemModel= new AssetItemModel();
  public assetList: any = [];
  public totalDraft:number =0;
  public totalRegistered:number =0;
  public totalSold:number =0;
  private draftStatusId: number =1;
  constructor(
    private pService:FixedAssetService,
    private dataService: DataService,
    private modalService:NgbModal,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.dataService.fixedAssetItemUpdate.subscribe(x=>{
      this.getAssetList();
    })
    this.getAssetList();
    
  }

  private getAssetList = () => {
    this.spinner.show();
    this.pService.GetFixedAssetItemList(this.draftStatusId).subscribe((x) => {
      this.assetList = x.Data;
      this.assetList.forEach(element => {
        element.PurchaseDate = this.convertDateIntoDisplay(element.PurchaseDate);
      });
      this.totalDraft=x.TotalDraft;
      this.totalRegistered=x.TotalRegistered;
      this.totalSold=x.TotalSold;
      this.spinner.hide()
    },(err)=>{
      this.spinner.hide();
    })
  };

  convertDateIntoDisplay(date) {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  public EditAssetItem=(item: any)=>{
    this.spinner.show();
    this.pService.GetAssetItemDetails(item.Id).subscribe((x) => {
      const modalRef = this.modalService.open(AssetItemCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = x;
      this.spinner.hide();
      modalRef.result.then((result) => {
      }, (reason) => {
        console.log('Dismissed action: ' + reason);
      });
    },(err)=>{
      this.spinner.hide();
    })
  };

}
