import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridAngular } from 'ag-grid-angular';
import { ActivatedRoute } from '@angular/router';
import { ProductServiceService } from 'src/app/service/Products/product-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemCreateComponent } from '../item-create/item-create.component';
import * as moment from 'moment';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { CreateItem } from '../../../models/product.model';
import { environment } from 'src/environments/environment';
import { RouterLinkRendereComponent } from 'src/app/components/router-link-rendere/router-link-rendere.component';
import { StorageItemCreateComponent } from '../storage-item-create/storage-item-create.component';
import { ItemCreateViewModel } from 'src/app/models-viewModels/itemCreateViewModel';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { UpdateItemQuantityPopupComponent } from '../update-item-quantity-popup/update-item-quantity-popup.component';
@Component({
  selector: 'app-view-purchase-product',
  templateUrl: './view-purchase-product.component.html',
  styleUrls: ['./view-purchase-product.component.css'],
  host: {
    '(document:click)': 'onWindowClick($event)',
  },
})
export class ViewPurchaseProductComponent implements OnInit {
  public aModel : ItemCreateViewModel= new ItemCreateViewModel();
  isEditable:boolean=false;
  rowData: any = [];
  stockQuantity:number;
  redirectUrl:string="/products/item-list/1";
  id:any;
  item:any;
  length:any;
  totalFile: number=0;
  rowInvoData: any = [];
  soldItemReturnData: any = [];
  purchaseItemReturnData: any = [];
  purchaseItemManuallyUpdatedTransactionList: any = [];
  soldItemManuallyUpdatedTransactionList: any = [];
  title:any;
  baseurl = environment.baseUrl;
  SaleAccountName: any;
  PurchaseAccountName:any;
  allChartOfAccount:any[];
  @ViewChild("agGrid", { static: false }) agGrid: AgGridAngular;

  gridOptions = {
    columnDefs: [
      { headerName: 'Date', field: 'TransDate', width: 50, valueFormatter: this.convertGridDateIntoDisplay},
      { headerName: 'Type', field: 'TransType', width: 50},
      {
        headerName: "Reference",
        field: "ReferenceNo",
        width: 50,
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: {
          inRouterLink: "/expense/bill/",
        },
      },
      { headerName: "Quantity", field: 'Quantity',width: 30},
      { headerName: "Unit Price", field: 'UnitPrice', width: 30},
      { headerName: "Total", field: 'LineTotal', width :30}
    ],

    defaultColDef: {
      flex: 1,
    },
    domLayout: 'autoHeight',
    rowHeight: 275,

    getRowStyle: this.changeRowColor,
  }

  gridOptions2 = {
    columnDefs: [
      { headerName: 'Date', field: 'TransDate', width: 50, valueFormatter: this.convertGridDateIntoDisplay},
      { headerName: 'Type', field: 'TransType', width: 50},
      {
        headerName: "Reference",
        field: "ReferenceNo",
        width: 50,
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: {
          inRouterLink: "/income/invoice/",
        },
      },
      { headerName: "Quantity", field: 'Quantity',width: 30},
      { headerName: "Unit Price", field: 'UnitPrice', width: 30},
      { headerName: "Total", field: 'LineTotal', width :30}
    ],

    defaultColDef: {
      flex: 1,
    },
    domLayout: 'autoHeight',
    rowHeight: 275,

    getRowStyle: this.changeRowColor,
  };

  gridOptions3 = {
    columnDefs: [
      { headerName: 'Date', field: 'TransDate', width: 50, valueFormatter: this.convertGridDateIntoDisplay},
      { headerName: 'Type', field: 'TransType', minWidth: 250 },
      {
        headerName: "Reference",
        field: "ReferenceNo",
        width: 50,
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: {
          inRouterLink: "/income/invoice/",
        },
      },
      { headerName: "Quantity", field: 'Quantity',width: 30},
      { headerName: "Unit Price", field: 'UnitPrice', width: 30},
      { headerName: "Total", field: 'LineTotal', width :30}
    ],

    defaultColDef: {
      flex: 1,
    },
    domLayout: 'autoHeight',
    rowHeight: 275,

    getRowStyle: this.changeRowColor,
  };

  updateItemManuallyGridOptions = {
    columnDefs: [
      { headerName: 'Date', field: 'TransDate', width: 50, valueFormatter: this.convertGridDateIntoDisplay},
      { headerName: 'Type', field: 'TransType', minWidth: 250 },
      { headerName: "Quantity", field: 'Quantity',width: 30},
      { headerName: "Unit Price", field: 'UnitPrice', width: 30},
      { headerName: "Total", field: 'LineTotal', width :30}
    ],

    defaultColDef: {
      flex: 1,
    },
    domLayout: 'autoHeight',
    rowHeight: 275,

    getRowStyle: this.changeRowColor,
  };


  changeRowColor(params) {
    if (params.data.Debit > 0 && params.data.Credit > 0) {
      return { background: '#F89406' };
    }
    return { background: '#FFFFFF' };
  }
  constructor(private _eref: ElementRef,
    private route: ActivatedRoute, 
    private productServiceService: ProductServiceService, 
    private fileEmitterService:FileEmitterService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) { 
    }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    this.getAllChartOfAccount();
  }

  public getBackUrl(){
    this.redirectUrl="/products/item-list/"+this.item.ItemTypeId;
  }


  public setTotalFile(tf:number) {
    this.totalFile=tf;
  }

  convertDateIntoDisplay(date){
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }
  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  onGridReady(event){
    this.getItemById();
  }
  onGridReady2(event){
    this.getItemById();
  }
  onRowClicked(event){
  }
  getAllChartOfAccount = () => {
    this.productServiceService.GetChartOfAccountsforItems().subscribe((x) => {
      this.allChartOfAccount = x.RevenueList;

    });
  }
  getItemById(){
    this.productServiceService.GetItemById(this.id).subscribe(p=>{
      this.item = p.data;
      this.title = this.item.ItemName;
      this.rowData = p.billTransactionList;
      this.rowInvoData = p.invoTransactionList;
      this.soldItemReturnData = p.soldItemReturnTransactionList;
      this.purchaseItemReturnData = p.purchaseItemReturnTransactionList;
      this.purchaseItemManuallyUpdatedTransactionList = p.purchaseItemManuallyUpdatedTransactionList;
      this.soldItemManuallyUpdatedTransactionList = p.soldItemManuallyUpdatedTransactionList;
      for(var i = 0; i < this.rowData.length; i++){
        this.rowData[i].ReferenceNo = 'Bill-'+this.rowData[i].ReferenceNo;
      }
      for(var i = 0; i < this.rowInvoData.length; i++){
        this.rowInvoData[i].ReferenceNo = 'INV-'+this.rowInvoData[i].ReferenceNo;
      }
      for(var i = 0; i < this.soldItemReturnData.length; i++){
        if(this.soldItemReturnData[i].TransType == "Sold Item Returned From Invoice")
          this.soldItemReturnData[i].ReferenceNo = 'INV-'+this.soldItemReturnData[i].ReferenceNo;
        else
          this.soldItemReturnData[i].ReferenceNo = 'SO-'+this.soldItemReturnData[i].ReferenceNo;
      }
      for(var i = 0; i < this.purchaseItemReturnData.length; i++){
        this.purchaseItemReturnData[i].ReferenceNo = 'Bill-'+this.purchaseItemReturnData[i].ReferenceNo;
      }
      console.log(p);
      const x = this.allChartOfAccount.find(px => px.Id === this.item.SaleAccountId);
      const y = this.allChartOfAccount.find(px => px.Id === this.item.PurchaseAccountId);
      this.SaleAccountName = x != null ? x.AccountName : null;
      this.PurchaseAccountName =  y != null ? y.AccountName : null;
      this.fileEmitterService.emitFileRefIdUpdate({id: this.id});
      this.stockQuantity = p.stockQuantity;
      this.isEditable = this.rowData.length > 0 ? true : false;
      this.redirectUrl="/products/item-list/"+this.item.ItemTypeId;
    });
  }

  
  editItem() {
    if(this.item.ItemTypeId === 5){
      this.editService();
    }
    else{
      const modalRef = this.modalService.open(ItemCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered : true});
    
      this.aModel=this.item;
      modalRef.componentInstance.fromParent = this.aModel;
      modalRef.result.then((result) => {
        if(result.Success){
          this.getItemById();
        }
      }, (reason) => {
      });
    }
  }

  editService() {

    const modalRef = this.modalService.open(StorageItemCreateComponent,{ size: 'lg', backdrop: 'static', keyboard : false, centered : true});
    this.aModel = this.item;
    modalRef.componentInstance.fromParent = this.aModel;
    modalRef.result.then((result) => {
      if (result.Success){
        this.getItemById();
      }
    }, (reason) => {
    });
  }

  
  displayCounter(obj){
    obj.isUpload === true ? this.length += obj.filesToUpload : this.length -= 1;
  }

  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id == "Preview")) {

    }
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown();
    }
    else {
      let val = "do something";
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }

  markAsInactive() {
    this.spinner.show();
    this.productServiceService.MarkAsInactive(this.item).subscribe(x => {
      if(x) {
        this.toastr.success("Mark as Inactive Successfully.");
      }
      else{
        this.toastr.error('Somethings went wrong!')
      }
      this.spinner.hide();
    });
  }

  updateItemQuantity() {
    const modalRef = this.modalService.open(UpdateItemQuantityPopupComponent,{ size: 'sm',backdrop: 'static', keyboard : false, centered : true});
    modalRef.componentInstance.ItemId = this.item.Id;
    modalRef.result.then((result) => {
      if(result == 'Success'){
        this.getItemById();
      }
    }, (reason) => {});
  }

}
