import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetItemCreateComponent } from '../asset-item-create/asset-item-create.component';
import { AssetItemModel,AssetTypeModel } from '../../../models/fixedAsset.model';
import{ChangeStartDateComponent} from '../change-start-date/change-start-date.component';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-fixed-asset-setup',
  templateUrl: './fixed-asset-setup.component.html',
  styleUrls: ['./fixed-asset-setup.component.css']
})
export class FixedAssetSetupComponent implements OnInit {

  public aModel : AssetItemModel= new AssetItemModel();
  public assetTypeList: any = [];
  showStrartDateDiv: boolean=true;
  startDate: any={};
  lastDepreciationDate: string;
  depreciationList: any = [];
  droPdownController={
    "Date":false,
    "Asset":false,
    "Dep":false
  };
  constructor(private modalService:NgbModal, private spinner: NgxSpinnerService,private pService: FixedAssetService) { 
  }

  ngOnInit(): void {
   this.getStartDate();
   this.GetDepreciationDateList();
  }

  public CreateNewAsset=()=>{
    this.pService.GetLastFixedAssetItemNumber().subscribe((x) =>{
      this.aModel.TypeDetails=new AssetTypeModel();
      const modalRef = this.modalService.open(AssetItemCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered : true});
      modalRef.componentInstance.fromParent = this.aModel;
      modalRef.componentInstance.lastFixedAssetItemNumber = x
      modalRef.result.then((result) => { }, (reason) => {console.log('Dismissed action: ' + reason);});
    });
  };
  
  changeStartDate=()=>
  {
   
    const modalRef = this.modalService.open(ChangeStartDateComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered : true});
    modalRef.result.then((result) => 
    {
      console.log(result.Success);
      this.showStrartDateDiv = result.Success !=undefined ? result.Success : false;
      

    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }

  getStartDate()
  {
    this.pService.GetStartDate().subscribe(x=>{
       this.startDate=x;
       this.showStrartDateDiv=this.startDate==null ? false:true;
       console.log(this.startDate);
    })
  }
  showOrHideData(id:string){
    console.log(this.droPdownController[id]);
    if (id === 'Asset'){
      this.droPdownController.Asset = !this.droPdownController.Asset;
      this.droPdownController.Date = false;
      this.droPdownController.Dep = false;
    }
    else if (id === 'Date'){
      this.droPdownController.Date = !this.droPdownController.Date;
      this.droPdownController.Asset = false;
      this.droPdownController.Dep = false;
    }
    else {
      this.droPdownController.Dep = !this.droPdownController.Dep;
      this.droPdownController.Asset = false;
      this.droPdownController.Date = false;
    }
  }

 RightToDown(id:string){
    return this.droPdownController[id] ? true : false;
  }

  GetDepreciationDate() {
    this.pService.GetDepreciationDate().subscribe((x) => {
      if(this.depreciationList.length == 0) {
        this.lastDepreciationDate = 'None'
      }
      else {
        this.lastDepreciationDate = x.lastDepreciationDate;
      }
    });
  }

  GetDepreciationDateList() {
    this.pService.GetDepreciationDateList().subscribe((x) => {
      this.depreciationList = x;
      this.GetDepreciationDate();
    });
  }

}
