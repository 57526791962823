<app-dashboard>
    <app-page-header-title mainTitle="Document Settings" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <button class="eCount-def-btn ml-4" type="button" (click)="Save()">Save</button>
        </div>
    </app-page-header-title>

    <div class="headSection">
        <form [formGroup]="DocumentSettingsForm" class="f-ss-14px">
            <div class="col-md-12 col-sm-12 ml-0 mt-4 d-flex mb-2 ">
                <div class="col-md-3">
                    <label class="font-weight-bold">Custom Document Approval</label>
                </div>
                <div class="col-md-6">
                    <label class="switch ml-2">
                        <input class="switch-input" type="checkbox" (change)="documentApprovalSettingsChange()" formControlName="DocumentApproval">
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 ml-0 mt-4 d-flex mb-2 ">
                <div class="col-md-3">
                    <label class="font-weight-bold">Document Type Groups</label>
                </div>
                <div class="col-md-6">
                    <label class="switch ml-2">
                        <input class="switch-input" type="checkbox" (change)="documentTypeGroupsSettingsChange()" formControlName="HasDocumentTypeGroup">
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 ml-0 mt-4 d-flex mb-2 ">
                <div class="col-md-3">
                    <label class="font-weight-bold">Mobile OTP Verification</label>
                </div>
                <div class="col-md-6">
                    <label class="switch ml-2">
                        <input class="switch-input" type="checkbox" (change)="mobileOTPVerificationSettingsChange()" formControlName="IsMobileOTPRequiredForApproval">
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 ml-0 mt-4 d-flex mb-2 ">
                <div class="col-md-3">
                    <label class="font-weight-bold">Email OTP Verification</label>
                </div>
                <div class="col-md-6">
                    <label class="switch ml-2">
                        <input class="switch-input" type="checkbox" (change)="emailOTPVerificationSettingsChange()" formControlName="IsEmailOTPRequiredForApproval">
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 ml-0 mt-4 d-flex mb-2 ">
                <div class="full-width text-align-right pb-2 d-flex justify-content-end">
                    <button *ngIf="DocumentSettingsForm.value.HasDocumentTypeGroup" type="button" class="eCount-def-btn ml-1" (click)="addDocumentTypeGroups()">Add Document Type Groups</button>
                    <button *ngIf="!DocumentSettingsForm.value.HasDocumentTypeGroup" type="button" class="eCount-def-btn ml-1" (click)="addDocumentTypes()">Add Document Type</button>
                </div>
            </div>
        </form>
    </div>
    <div class="headSection ml-3 mr-3">
        <div class="row">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col" 
                [rowData]="documentTypeList" 
                [columnDefs]="columnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
    <hr>
    <!-- <div class="col-md-12 col-sm-12 ml-0 mt-4 d-flex mb-2 ">
        <p class="font-weight-bold">Dynamic Label Name For Document Inputs</p>
        
    </div> -->
    <!-- <div class="headSection ml-3 mr-3">
        <div class="row">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col" 
                [rowData]="dynamicLabelNameForDocumentInputsList" 
                [columnDefs]="dynamicLabelNameForDocumentInputsColumnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div> -->
    <div class="modal-body modalBody">
        <div class="pb-4">
            <div class="row">
                <div class="col-md-12">
                    <p class="mt-3 mb-3 font-weight-bold">Please set dynamic label name for document inputs</p>
                    <form [formGroup]="DocumentDynamicLabelForm" class="f-ss-14px">
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group col-sm-6">
                                    <input type="text" class="form-control" formControlName="LabelName00" placeholder="Employee Id">
                                </div>
                                <div class="form-group col-sm-6">
                                    <input type="text" class="form-control" formControlName="LabelName01" placeholder="Date">
                                </div>
                            </div>
                        </div>
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group col-sm-6">
                                    <input type="text" class="form-control" formControlName="LabelName10" placeholder="Reference 1">
                                </div>
                                <div class="form-group col-sm-6">
                                    <input type="text" class="form-control" formControlName="LabelName11" placeholder="Reference 2">
                                </div>
                            </div>
                        </div>
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group col-sm-6">
                                    <input type="text" class="form-control" formControlName="LabelName20" placeholder="Document Type">
                                </div>
                                <div class="form-group col-sm-6">
                                    <input type="text" class="form-control" formControlName="LabelName21" placeholder="Tag">
                                </div>
                            </div>
                        </div>
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="full-width text-align-right pb-2 d-flex justify-content-end">
                                    <button type="button" class="eCount-def-btn mr-3" (click)="saveDocumentDynamicLabelName()">Update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</app-dashboard>
