import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  baseurl = environment.baseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private http: HttpClient) { }

  httpAttachmentOptions: any = {
    reportProgress: true, observe: 'events', headers: new HttpHeaders({
      'Authorization': `Bearer ${this.userInfo ? this.userInfo.Token : null}`
    })
  }

  public GetQuotation(id:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Quotation/GetQuotation?id='+id).pipe(retry(1));
  }

  public GetQuotationByNo(qNo:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Quotation/GetQuotationByNo?qNo='+qNo).pipe(retry(1));
  }

  public ConfirmQuotation(id:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Quotation/ConfirmQuotation?id='+id).pipe(retry(1));
  }

  public GetQuotationList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Quotation/GetQuotationList').pipe(retry(1));
  }

  public GetQuotationFiles(id:number): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Quotation/GetQuotationFiles?quotationId='+id).pipe(retry(1));
  }

  public DeleteFile(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Quotation/DeleteFile').pipe(retry(1));
  }

  public SaveQuotation(data:any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Quotation/Save', data).pipe(retry(1));
  }

  public UploadQuotationFiles(data:any,id:number): Observable<any> {
    return this.http.post(this.baseurl+'Quotation/UploadQuotationFiles?quotationId=' + id, data, this.httpAttachmentOptions).pipe(
      retry(1)
    )
  }

  public SendMailWithAttachment(data:any): Observable<any> {
    return this.http.post(this.baseurl+'Quotation/SendMailWithAttachment', data, this.httpAttachmentOptions).pipe(
      retry(1)
    )
  }


  GetAllContact(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Contact/GetAllCustomer').pipe(retry(1));
  }

  GetPurchaseableItems(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetPurchaseableItems').pipe(retry(1));
  }

  public SendMail(data:any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Quotation/SendMail', data).pipe(retry(1));
  }

  public GetMailDetails(id:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Quotation/GetMailDetails?id='+id).pipe(retry(1));
  }
   GetAllSaleableItem(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/getsaleableitems')
      .pipe(
        retry(1)
      );
  }
  GenerateQuotNo(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Quotation/GenerateQuotationNo')
      .pipe(
        retry(1)
      );
  }
  DeleteQuotation(quotationId): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Quotation/DeleteQuotation?id='+quotationId ,quotationId).pipe(retry(1));
  }

    /* Major Refactor Code */ 
    Create(quotation): Observable<any> {
      return this.http.post<any>(this.baseurl + 'Quotation/Create', quotation);
    }
  
    StatusUpdate(quotation): Observable<any> {
      return this.http.post<any>(this.baseurl + 'Quotation/StatusUpdate', quotation);
    }
  
    Update(quotation): Observable<any> {
      return this.http.post<any>(this.baseurl + 'Quotation/Update', quotation);
    }

}
