import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup,FormArray, Validators, FormControl } from '@angular/forms';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-factory-in',
  templateUrl: './edit-factory-in.component.html',
  styleUrls: ['./edit-factory-in.component.css']
})
export class EditFactoryInComponent implements OnInit {
  ifSubmit: boolean;
  subTotalAmount = 0;
  public salesOrderCount: any = [];
  public salesOrders: any = [{}];
  public factoryInForm: FormGroup;
  public ProductionInItem: FormArray;
  materialList: any = [];
  productList: any = [];
  TransNo: any;
  data: any;
  totInQuantity = 0;
  totalCost: number;
  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private pService: FactoryProductionService) { }

  ngOnInit(): void {
    this.getProducts();
    this.getInitSalesOrder();
    this.TransNo = this.route.snapshot.paramMap.get("transNo");
    this.initializeForm();
    this.getTransactionDetails(this.TransNo);
  }
  public getInitSalesOrder(){
    this.pService.GetAllFactoryOutSalesOrder().subscribe((x) => {
      this.salesOrders = x.salesOrders;
    });
  }
  getProducts = () => {
    this.pService.GetRawMaterials().subscribe((x) => {
      this.materialList = x;
      console.log(this.materialList);
    });
    this.pService.GetFinishProducts().subscribe((x) => {
      this.productList = x;
    });
  }

  initializeForm() {
    this.factoryInForm = this.fb.group({
      InDate: [''],
      ReferrenceNo: [''],
      Description: [''],
      Id: [0],
      TransactionNo: [''],
      StatusId: [''],
      CreatedById: [''],
      CreatedAt: [''],
      ProductionInItem: this.fb.array([])
    });
  }
private getTransactionDetails(transNo: string){
  this.spinner.show();
  this.pService.GetProductionIn(transNo).subscribe((x) => {
    this.data = x;
    this.spinner.hide();
    this.setRawItem(this.data);
    for (const item of this.data.ProductionItemView)
    {
      this.addItem(item);
      this.totalCost += (item.PricePerUnit * item.InQuantity);
    }
  });
}
public getStock( id: number)
{
   console.log(id);
   const itemList = this.materialList.filter(x => x.Id === id);
   console.log(itemList);
   return itemList[0].StockQuantity;
}
public addItem(x:any): void {
this.ProductionInItem = this.factoryInForm.get('ProductionInItem') as FormArray;
this.ProductionInItem.push(this.createItem(x));
}

public createItem(x:any): FormGroup {
console.log(x);
return this.fb.group({
  SalesOrderId: [{value : x.SalesOrderId, disabled: true}],
  InQuantity: [x.InQuantity],
  PricePerUnit: [x.PricePerUnit],
  LineTotal: [x.InQuantity*x.PricePerUnit],
  StockQuantity: [this.getStock(x.MaterialId)],
  MaterialId: [x.MaterialId],
  ProductId: [x.ProductId],
  Id: [x.Id],
  MovementId: [x.MovementId]
});
}

public setRawItem(x:any): void {
  console.log(x);
this.factoryInForm.controls['Description'].patchValue(x.Description);
this.factoryInForm.controls['InDate'].patchValue((x.InDate));
this.factoryInForm.controls['ReferrenceNo'].patchValue(x.ReferrenceNo);
this.factoryInForm.controls['TransactionNo'].patchValue(x.TransactionNo);
this.factoryInForm.controls['Id'].patchValue(x.Id);
this.factoryInForm.controls['StatusId'].patchValue(x.StatusId);
this.factoryInForm.controls['CreatedById'].patchValue(x.CreatedById);
this.factoryInForm.controls['CreatedAt'].patchValue(x.CreatedAt);
this.show(x.InDate);
}

  public getSalesOrder(i:number,item:any){
    this.pService.GetFactoryOutSalesOrder(item.value.ProductId).subscribe((x) => {
      this.salesOrders[i] = x.salesOrders;
      this.salesOrderCount = x.salesOrderCount;
    });
  }
  public addBlankItem(): void {
    this.ProductionInItem = this.factoryInForm.get('ProductionInItem') as FormArray;
    this.ProductionInItem.push(this.createBlankItem());
  }

  public deleteItem(i:number){
    this.ProductionInItem.removeAt(i);
  }
  
  public updatePrice(i: number, item:any){
    let itemList = this.materialList.filter(x => x.Id == item.value.MaterialId);
    if (itemList[0].StockQuantity < item.value.InQuantity){
      item.controls['InQuantity'].patchValue(0);
      item.controls['LineTotal'].patchValue(0);
      this.toaster.error("Used Qty must be less than or equal to Stock Qty");
    }
    else{
      var purchasePrice=0,stockQuantity = 0;
      if (itemList.length > 0){
      purchasePrice = itemList[0].PurchasePrice;
      stockQuantity = itemList[0].StockQuantity;
    }
      item.controls['PricePerUnit'].patchValue(purchasePrice);
      item.controls['StockQuantity'].patchValue(stockQuantity);
      var total = purchasePrice * (isNaN(item.value.InQuantity) ? 0 : item.value.InQuantity);
      item.controls['LineTotal'].patchValue(total);
    }
  }
  checkValidation = () =>
   {
    this.ProductionInItem = this.factoryInForm.get('ProductionInItem') as FormArray;
    for (const item of this.ProductionInItem.value)
    {

        this.totInQuantity = this.totInQuantity +  Number(item.InQuantity);

    }

   }
   public Save(){

    this.factoryInForm.get('StatusId').patchValue(1);
    this.SaveFactoryIn();
   }
   public Approve(){

    this.factoryInForm.get('StatusId').patchValue(2);
    this.ApproveFactoryIn();
   }
  /**
   * SaveFactoryIn
   */
  public SaveFactoryIn() {
    this.checkValidation();
    if (this.factoryInForm.invalid){
      this.ifSubmit = true;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    if (this.totInQuantity === 0)
    {
      this.toaster.warning('Total item qunatity can not be 0');
      return;
    }
    this.spinner.show();
    this.pService.UpdateProdInAsDraft(this.factoryInForm.value).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Factory In has been successfully updated.');
        this.router.navigate(['inventory/factory-in-list/All']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  public ApproveFactoryIn() {
    this.checkValidation();
    if (this.factoryInForm.invalid){
      this.ifSubmit = true;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    if (this.totInQuantity === 0)
    {
      this.toaster.warning('Total item qunatity can not be 0');
      return;
    }
    this.spinner.show();
    this.pService.UpdateApproveProdIn(this.factoryInForm.value).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Factory In has been successfully approved.');
        this.router.navigate(['inventory/factory-in-list/All']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }
  show(e) {
    const value = this.factoryInForm.value.InDate;
    $("#efitest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  public createBlankItem(): FormGroup {
    return this.fb.group({
      Id: 0,
      MaterialId: ['',Validators.required],
      ProductId: [''],
      SalesOrderId: [''],
      InQuantity: [0,Validators.required],
      PricePerUnit: [0,Validators.required],
      LineTotal: [0,Validators.required],
      StockQuantity: [0]
    });
  }
}
