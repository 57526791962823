import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BillsServiceService } from 'src/app/service/Bill/bills-service.service';
import { IncomeServiceService } from 'src/app/service/Income/income-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { environment } from 'src/environments/environment';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
@Component({
  selector: 'app-reject-popup-modal',
  templateUrl: './reject-popup-modal.component.html',
  styleUrls: ['./reject-popup-modal.component.css']
})
export class RejectPopupModalComponent implements OnInit {

  @Input() TypeId: number;
  @Input() data: any;
  @Output() isProcessDone = new EventEmitter();
  RejectReason: string;
  imageURL: string;
  fileUrl = environment.fileBaseUrl;
  constructor(private activeModal: NgbActiveModal,
              private billService: BillsServiceService,
              private incomeService: IncomeServiceService,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService,
              private mainServiceService: MainServiceService) { }

  ngOnInit() {
    this.getApprovalSignatureData();
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    if (this.TypeId == 1)
    {
      this.data.RejectReason = this.RejectReason;
      this.rejectBill();
    }
    else if (this.TypeId == 2)
    {
      this.data.RejectReason = this.RejectReason;
      this.rejectInvoice();
    }else {
      this.rejectOther();
    }
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  rejectOther(){
    if(this.RejectReason=='' || this.RejectReason==undefined || !this.imageURL){
      this.toaster.warning("Please fillup the required file"); return;
    }
    this.data.RejectReason = this.RejectReason;
    this.isProcessDone.emit('Success');
    this.activeModal.close(true);
  }

  rejectBill() {
    if(this.RejectReason=='' || this.RejectReason==undefined || !this.imageURL){
      this.toaster.warning("Please fillup the required file"); return;
    }
    this.spinner.show();
    this.data.IQuidiActionType = iQuidiActionType.Reject;
    this.billService.UpdateBillStatus(this.data).subscribe((x) => {
      if (x.Success) {
        this.isProcessDone.emit('Success');
        this.toaster.success(`Bill ${this.data.BillNo} has been successfully rejected`, 'Success!');
        this.spinner.hide();
        this.activeModal.close(true);
      } else {
        this.isProcessDone.emit('Failed');
        this.toaster.warning(x.Message);
        this.spinner.hide();
        this.activeModal.close(false);
      }
    });
  }
  rejectInvoice() {
    if(this.RejectReason=='' || this.RejectReason==undefined || !this.imageURL){
      this.toaster.warning("Please fillup the required file"); return;
    }
    this.spinner.show();
    this.data.IQuidiActionType = iQuidiActionType.Reject;
    this.incomeService.StatusUpdate(this.data).subscribe((x) => {
      if (x.Success) {
        this.isProcessDone.emit('Success');
        this.toaster.success(`Invoice ${this.data.InvoicceNo} has been successfully rejected`, 'Success!');
        this.spinner.hide();
        this.activeModal.close(true);
      } else {
        this.isProcessDone.emit('Failed');
        this.toaster.warning(x.Message);
        this.spinner.hide();
        this.activeModal.close(false);
      }
    });
  }

  getApprovalSignatureData(){
    this.mainServiceService.GetUserDetailsId().subscribe((x) => {
      if (x.Success){
        if(x.data.Signature!=null && x.data.Signature.toString().length!=0){
          this.imageURL = this.fileUrl + x.data.Signature;
        }
      }
    });
  }
}
