
<app-dashboard>
    <app-page-header-title mainTitle="Receive Loan" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <!-- <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox"> -->
            <button type="button" (click)="createNewItem()"
                class="eCount-def-btn ml-4">Receive Loan</button>
            <button 
                class="eCount-button3 ml-4">{{'Contacts.contactList.export'| translate}}</button>
        </div>
        
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item ">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('All')" [routerLinkActive]="['active']" routerLink="/business-partner/receive-loan-list/All">Receive Loan</a>
        </li>
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('active')" [routerLinkActive]="['active']" routerLink="/business-partner/receive-loan-list/active">Active ({{activeCount}})</a>
        </li>
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('closed')" [routerLinkActive]="['active']" routerLink="/business-partner/receive-loan-list/closed">Closed ({{closeCount}})</a>
        </li>
    </ul>
    <div class="pt-3">
        <ag-grid-angular class="ag-theme-alpine"  style="width: 100%;height: 420px;" #topGrid id="topGrid"  [rowData]="grantData"
        [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (rowClicked)="onRowClicked($event)"  [defaultColDef]="defaultColDef">
    </ag-grid-angular>
    </div>
    
</app-dashboard>