import { Component, OnInit } from '@angular/core';
import { MainServiceService } from '../../../service/Main/main-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { MessageManagerService } from 'src/app/service/MessageManager/messagemanager.service';
@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.css']
})
export class BankAccountsComponent implements OnInit {

  constructor(private mainService: MainServiceService,
              private router: Router,
              private dataService: DataService,
              private messageManagerService: MessageManagerService,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService) { }

  private gridApi;
  domLayout="autoHeight"
  private gridColumnApi;
  bankList: any = [];
  searchText:string;
  hasStep: boolean=false;
  steps: number;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.steps = params.step ===  undefined ? 0 : 1;
      this.hideSidebar(this.steps);
    });
  }
  columnDefs = [
    { headerName: 'Account Type', field: 'AccountTypeName' },
    { headerName: 'Account Code', field: 'AccountCode' },
    { headerName: 'Account Name', field: 'AccountName' },
    { headerName: 'Bank Name', field: 'BankName' },
    { headerName: "Action", suppressMenu: true, suppressSorting: true, template: `<span  (click)="editItems()"><img style="height: 18px;margin-bottom:7px;cursor:pointer" src="../../../../assets/icons/download.png"></span>` },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };
  
  hideSidebar = (type) => {
    if (type == 1)
    {
      this.dataService.emitSidebarHide(false);
    }
  }
  getData=()=>{
    this.spinner.show()
    this.bankList = []
    this.mainService.GetAllBank().subscribe((x)=>{
      this.spinner.hide()
      this.bankList = [...x, ...this.bankList]
    },(err)=>{
      this.spinner.hide();
        this.toaster.error('Something went wrong please try again !');
    });
  }

  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getData();
  }

  onRowClicked(e) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Action") {
      this.editItems(e);
    }
  }

  editItems(e) {
    this.router.navigate(['accounting/bank-accounts-details',e.data.Id]);
  }
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
    suppressQuotes: '',
    columnSeparator: '',
    customHeader: '',
    customFooter: '',
  };
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }
  backToPreviousPage = () => {
    this.router.navigate(['/']);
  }
  public UpdateStep() {
    this.spinner.show();
    this.messageManagerService.StepDone().subscribe(x => {
      this.spinner.hide();
      this.getMessage();
      this.router.navigate(['/accounting/existing-balances'], { queryParams: { step: 1 }  });
    });
  }
  private getMessage(){
    this.messageManagerService.GetMessage().subscribe(x => {
      this.dataService.emitSetMessageUpdate(x);
  });
}
}
