import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvanceService } from 'src/app/service/Advance/advance.service';
import {MainServiceService} from '../../../service/Main/main-service.service';
import * as moment from 'moment';

@Component({
  selector: 'app-sales-order-advance',
  templateUrl: './sales-order-advance.component.html',
  styleUrls: ['./sales-order-advance.component.css']
})
export class SalesOrderAdvanceComponent implements OnInit {

  public advanceForm: FormGroup;
  advanceTypeList = [
    {Id: 1, Name: 'New Advance'}
  ];
  contactList: any[];
  accountList: [];
  loading = false;
  existingAdvance: any;
  @Input() fromparent;
  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    public mainService: MainServiceService,
    private advanceService: AdvanceService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getMasterData();
    console.log(this.fromparent);
  }

  initializeForm() {
    this.advanceForm = this.fb.group({
      Id: 0,
      TypeId: [1],
      TransactionTypeId: [1],
      TransactionDate: [new Date()],
      ContactId: [this.fromparent.contactId, Validators.required],
      AccountId: [0],
      Description: [''],
      SalesOrderId: [this.fromparent.salesOrderId],
      Amount: ['', Validators.required]
    });
    this.showDefaultDate(new Date());
  }
  getMasterData(){
    this.advanceService.GetBankAccounts().subscribe((x: []) => {
      this.accountList = x;
    });
  }


  public save() {
    if (this.advanceForm.invalid){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    if (this.advanceForm.value.TypeId == 1 && this.advanceForm.value.AccountId == 0){
      this.toaster.warning('Please fill all the required field !');
      return;
    }

    this.spinner.show();
    this.advanceService.save(this.advanceForm.value).subscribe((x) => {
      if (x.Success){
        this.toaster.success('Advance has been successfully saved.');
        this.activeModal.close(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });

  }
  showDefaultDate(e) {
    const value = this.advanceForm.value.TransactionDate;
    $('#adstest').on('change', function() {
      this.setAttribute(
          'data-date',
          moment(value, 'YYYY-MM-DD')
          .format( this.getAttribute('data-date-format') )
      );
  }).trigger('change');
  }

}
