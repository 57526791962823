import { Component, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import { ToastrService } from 'ngx-toastr';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ReportServiceService } from 'src/app/service/Report/report-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.css']
})
export class BalanceSheetComponent implements OnInit {
  fromDate: string="" ; //'2020-03-01';
  toDate: string=""; // = '2020-03-12';
  AssetList: any = []
  Liabilities: any=[]
  EquityList: any=[]
  totalLiabilities: number = 0;
  NetAssets: number= 0;
  totalOperatingExpense:number = 0;
  len:number = 0;
  TotalAssets:number = 0;
  netProfit:number = 0;
  companyName: string ="";
  constructor(private spinner: NgxSpinnerService,
    private reportService: ReportServiceService,
    private router:Router,
    private toaster: ToastrService) { }

  ngOnInit(): void {
    this.setOrganization();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    this.fromDate = date.getFullYear()+"-01-01";
    this.toDate = date.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + date.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + date.toLocaleDateString('en-GB', { day: '2-digit'});
    this.show(this.toDate);
  }

  setOrganization(){
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.companyName = userInfo.SelectedCompany.Name;
    }
  }

     /**
   * openAccountTransaction
id:number   */
public openAccountTransaction(id:number) {
  this.router.navigate(['reports/account-transactions'], 
  { 
    queryParams: 
    { 
      accountId:id,
      fromDate:this.fromDate,
      toDate:this.toDate,
      returnUrl:"balance-sheet" 
  } 
});
}

  reportGenerate = () => {
   
    if (!this.toDate) {
      this.toaster.warning('Date is required !');
      return;
    }

    this.spinner.show();
    this.reportService.GetBalanceSheet(this.toDate).subscribe((x) => {
      this.spinner.hide();
      this.AssetList = x.Assets;
      this.TotalAssets=x.TotalAssetsView;
      this.Liabilities=x.Liabilities;
      this.totalLiabilities=x.TotalLiabilitiesView;
      this.NetAssets=x.NetAssetsView;
      this.EquityList=x.Equity;
    })
  }
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  convetToPDF = () => {
    var data = document.getElementById('balancesheet');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      let tillDate=this.convertDateIntoDisplay(this.toDate);
      var DocumentName = `Balance Sheet report at ${tillDate}.pdf`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    })
  }
  show(e) {
    const value = this.toDate;
    $("#bstest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

}
