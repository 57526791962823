import { Component, OnInit } from '@angular/core';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import * as $ from 'node_modules/jquery';

@Component({
  selector: 'app-print-file',
  templateUrl: './print-file.component.html',
  styleUrls: ['./print-file.component.css']
})
export class PrintFileComponent implements OnInit {
  fileUrl = environment.fileBaseUrl

  constructor(private attachmentService: AttachmentServiceService, private route: ActivatedRoute) { }
  files:any;
  id:any;
  src:any;
  file:any;
  isPdf:boolean;
  ngOnInit(): void {
    this.getAllFilesByType();
    //window.print();
    document.addEventListener("DOMContentLoaded", function (event) {
      //do work

    });  }
  getAllFilesByType() {
    let type = localStorage.getItem('type');
    console.log(type);
    this.attachmentService.getAllFilesByType(type).subscribe((x) => {
      console.log(x);
      this.id = Number(this.route.snapshot.paramMap.get('id'));
      this.files = x;
      this.getSelectFile();
    });
  }

  getSelectFile=()=>{
    console.log(this.id)
    console.log(this.files);
    this.file = this.files.find(x => x.Id == this.id)
    this.src = this.fileUrl+this.file.BlobName;
    console.log(this.file.BlobName.split('.')[1]);
    this.isPdf = this.file.FileName.split('.')[1]=='pdf'?true:false;


    if(this.isPdf){
      setTimeout(() => { window.print(); }, 3000);
    }else{
      $(document).ready(function () {
        console.log("Ready!");
        setTimeout(() => { window.print(); }, 1000);
      });
    }
  }
}
