
<app-dashboard>
    <div class="headSection marginNegative5">
        <div class="row borderBottom1Px pb-2">
            <div class="col-md-12 row" style="padding: 0px 15px;">
                <div class="d-block col-md-3">
                    <div class="pointer" *ngIf="this.steps == 0" (click)="backToPreviousPage()">
                        <i class="icon-arrow-left52 mr-2 subTextTitle"></i>
                        <span class="subTextTitle f-ss-14px" >Home</span>
                    </div>
                    <span class="mainTextTitle f-ssb-14px" style="width: 220px;">Cash/Bank Accounts</span>

                        
                </div>
                <div class="col-md-9 mt-2 text-align-right">
                    <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox searchWidth">
                    <button type="button" routerLink="/accounting/bank-accounts-create" class="eCount-def-btn ml-4">Add Cash/Bank Account</button>
                    <button type="button" (click)="UpdateStep()" *ngIf="this.steps > 0" class="eCount-def-btn ml-4">Finish & Continue</button>
                    <button (click)="onBtnExport()" class="eCount-button3 ml-4">{{'Contacts.contactList.export'| translate}}</button>
                </div>
            </div>

    </div>
    </div>
    <div class="headSection">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                [domLayout]="domLayout"
                class="ag-theme-balham col"
                [rowData]="bankList"
                [columnDefs]="columnDefs"
                (rowClicked)="onRowClicked($event)"
                (gridReady)="onGridReady($event)"
                [defaultColDef]="defaultColDef"
            ></ag-grid-angular>
        </div>
    </div>
</app-dashboard>