import { Component, ViewChild, OnInit , OnDestroy} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';
import { ConversionbalancesService } from '../../../../service/ConversionBalance/conversionbalances.service';
import { MessageManagerService } from '../../../../service/MessageManager/messagemanager.service';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridAngular } from 'ag-grid-angular';
import { from } from 'rxjs';
import * as moment from 'moment';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AuthService} from '../../../../service/auth/auth.service';
@Component({
  selector: 'app-existing-balance',
  templateUrl: './existing-balance.component.html',
  styleUrls: ['./existing-balance.component.css']
})
export class ExistingBalanceComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  showStrartDateDiv: boolean = false;
  TotalDebit: any;
  TotalCredit: any;
  AdjustmentAmount: any;
  conversionMonth: any;
  conversionYear: any;
  selectType = 0;
  showBusinessFeature = true;
  conversionId: Number = 0;
  previousConversionDate: any;
  previousConversionMonth: Number = 0;
  showHideWarningMessage: boolean = false;
  showHideDateIcon: boolean = false;
  yearList: any = [];
  rowData: any =  [];
  steps: number;
  masterRowData: any = [];
  lastConversionDate: string = null;
  constructor(public ngxSmartModalService: NgxSmartModalService,
              private toaster: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private messageManagerService: MessageManagerService,
              private dataService: DataService,
              private spinner: NgxSpinnerService,
              private conversionBalanceService: ConversionbalancesService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.steps = params.step ===  undefined ? 0 : 1;
      this.hideSidebar(this.steps);
    });

    this.dataService.changeSelectedOrganization.subscribe(x => {
      this.getCurrentYear();
      this.getConversionDate();
      this.getInitialConversionBalance();
    });
    this.getCurrentYear();
    this.getConversionDate();
    this.getInitialConversionBalance();
  }

  hideSidebar = (type) => {
    if (type == 1)
    {
    this.dataService.emitSidebarHide(false);
    }
  }

  getInitialConversionBalance() {
    this.spinner.show();
    this.conversionBalanceService.getConversionBalance().subscribe((res) => {
      if (res != null) {
        this.spinner.hide();
        this.masterRowData = res["AccountList"];
        this.TotalDebit = res["TotalDebit"];
        this.TotalCredit = res["TotalCredit"];
        this.AdjustmentAmount = res["AdjustmentAmount"];

        this.rowData = res["DefaultAccountList"];
        console.log(this.rowData);
      } else {
        this.spinner.hide();
        this.toaster.error("Something went wrong. Please check.", "Error");
      }
    }, (err) => { 
      this.toaster.error("Something went wrong. Please check.", "Error");
      this.spinner.hide();
    })
  }

  showAllRows() {

    for (let i = 0; i < this.masterRowData.length; i++) {
      for (let j = 0; j < this.rowData.length; j++) {
        if ((this.masterRowData[i]['AccountFullName'] == this.rowData[j]['AccountFullName']) &&
          (this.rowData[j]['Debit'] > 0 || this.rowData[j]['Credit'] > 0)) {
          this.masterRowData[i] = this.rowData[j];
        }
      }
    }
    this.rowData = this.masterRowData;
  }

  deleteRows(e: number, v) {
    console.log(this.rowData);
    console.log(v);
    for (let i = 0; i < this.masterRowData.length; i++) {
      if (this.masterRowData[i]['AccountFullName'] == v.AccountFullName) {
        this.masterRowData[i]["Credit"] = 0;
        this.masterRowData[i]["Debit"] = 0;
        console.log('yes');
      }
    }
    this.rowData.splice(e, 1);
    this.calculateDebitTotalData();
    this.calculateCreditTotalData();
  }

  removeAllZeroRows() {

    let removeRowData = [];

    for (let index = 0; index < this.rowData.length; index++) {
      if (this.rowData[index]['Debit'] > 0 || this.rowData[index]['Credit'] > 0) {
        removeRowData.push(this.rowData[index]);
      }
    }
    this.rowData = removeRowData;
  }



  calculateCreditTotalData() {
    let result = 0;
    for(var i=0;i< this.rowData.length;i++)
    {
      if ( this.rowData[i].Credit != null && this.rowData[i].Credit !== '')
      {
     
        result += parseFloat(this.rowData[i].Credit);

      }
  }
    this.TotalCredit = result;
    this.AdjustmentAmount = this.TotalDebit - this.TotalCredit;
  }
  onFocusEvent(event: any){
    
    event.target.value=event.target.value==0?null:event.target.value;
  }



  calculateDebitTotalData()
  {
    let result = 0;
    for(var i=0;i< this.rowData.length;i++)
    {
      if ((this.rowData[i].Debit != null) && (this.rowData[i].Debit !== '')){
        console.log(this.rowData[i].Debit);
        result += parseFloat(this.rowData[i].Debit);
        console.log(result);
      }
    }
    this.TotalDebit = result;
    this.AdjustmentAmount = this.TotalDebit - this.TotalCredit;
  }

  saveConversionBalance = () => {

    this.spinner.show();

    if (this.masterRowData.length != this.rowData.length) {
      for (let i = 0; i < this.masterRowData.length; i++) {
        for (let j = 0; j < this.rowData.length; j++) {
          if (this.masterRowData[i]['AccountFullName'] == this.rowData[j]['AccountFullName']) {
            this.masterRowData[i] = this.rowData[j];
          }
        }
      }
    }

    let data = {
      AccountList: this.masterRowData,
      TotalDebit: this.TotalDebit,
      TotalCredit: this.TotalCredit,
      AdjustmentAmount: this.AdjustmentAmount
    }

    this.conversionBalanceService.saveConversionBalance(data).subscribe((x) => {
      if (x.Success) {
        this.spinner.hide();
        this.toaster.success('Conversion Balance Successfully Save !', 'Success!');

        if (x.Data.InvoiceAdjustmentDue == true) {
          this.router.navigate(['accounting/historic-conversionbalancesInvoice']);
        }
        else if (x.Data.BillAdjustmentDue == true) {
          this.router.navigate(['accounting/historic-conversionbalancesBill']);
        }
      } else {
        this.spinner.hide()
        this.toaster.warning(x.Message, 'Warning!');
      }
    }, (err) => { this.spinner.hide() })

  }
  //#endregion

  //#region NumericInput

  private getNumericCellEditor() {

    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }

    function isCharDecimal(charStr) {
       return !!/\./.test(charStr);
    }

    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr) || isCharDecimal(charStr);
    }

    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    }

    function NumericCellEditor() { }

    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement('input');
      this.eInput.style.width = '100%';
      this.eInput.style.height = '100%';
      this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
      var that = this;
      this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };

    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };

    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };

    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };

    NumericCellEditor.prototype.isCancelAfterEnd = function () { };
   
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
   
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log('NumericCellEditor.focusIn()');
    };
  
    NumericCellEditor.prototype.focusOut = function () {
      console.log('NumericCellEditor.focusOut()');
    };
    return NumericCellEditor;
  }
  //#endregion

  //#region ConversionDate

  openConversionDatePopup = () => {
    this.getCurrentYear();

    this.conversionBalanceService.getConversionConfig().subscribe((res) => {

      if (res != null) {
        this.conversionId = res['Id']
        this.conversionMonth = res['ConversionMonth'];
        this.conversionYear = res['ConversionYear'];

        this.showHideDateIcon = this.conversionMonth == null ? false : true;
        this.showStrartDateDiv = this.conversionMonth == null ? false : true;
        var currentDate = new Date();
        currentDate.setMonth(this.conversionMonth - 1);
        currentDate.setDate(1);
        currentDate.setHours(-1);

        this.previousConversionMonth = this.conversionMonth;
        this.previousConversionDate = currentDate.toString()
        this.lastConversionDate = currentDate.toString();
        this.showHideWarningMessage = false;
        this.ngxSmartModalService.create('conversionDate', 'content').open();

      }
      else {
        this.ngxSmartModalService.create('conversionDate', 'content').open();
      }
    },(err) =>{
       this.toaster.error("Something went wrong. Please check.", "Error");
      this.spinner.hide();
    })

    //this.ngxSmartModalService.create('conversionDate', 'content').open();
  }

  getConversionDate() {
    this.conversionBalanceService.getConversionConfig().subscribe((res) => {

      if (res != null) {
        this.conversionId = res['Id']
        this.conversionMonth = res['ConversionMonth'];
        this.conversionYear = res['ConversionYear'];

        this.showHideDateIcon = this.conversionMonth == null ? false : true;
        this.showStrartDateDiv = this.conversionMonth == null ? false : true;
        var currentDate = new Date();
        currentDate.setMonth(this.conversionMonth - 1);
        currentDate.setDate(1);
        currentDate.setHours(-1);

        this.previousConversionMonth = this.conversionMonth;
        this.previousConversionDate = currentDate.toString()
        this.lastConversionDate = currentDate.toString();

      }
    }, (err) => { this.toaster.error("Something went wrong. Please check.", "Error"); })
  }
public UpdateStep() {
  this.spinner.show();
  this.messageManagerService.StepDone().subscribe(x => {
    this.spinner.hide();
    if (x.AllDone){
      var userInfo = this.authService.getUserInfo();
      userInfo.CompanyList = x.Data;
      this.authService.setUserInfo(JSON.stringify(userInfo));
      this.dataService.emitUpdateLastStep(x.Data);
    }
    this.getMessage();
  });
}
private getMessage(){
  this.spinner.show();
  this.messageManagerService.GetMessage().subscribe(x => {

    this.dataService.emitSetMessageUpdate(x);
    this.spinner.hide();
});
}

  getCurrentYear() {
    this.conversionYear = new Date().getFullYear();
  }

  monthList = [
    { Value: 1, Text: 'January' },
    { Value: 2, Text: 'February' },
    { Value: 3, Text: 'March' },
    { Value: 4, Text: 'April' },
    { Value: 5, Text: 'May' },
    { Value: 6, Text: 'June' },
    { Value: 7, Text: 'July' },
    { Value: 8, Text: 'August' },
    { Value: 9, Text: 'September' },
    { Value: 10, Text: 'October' },
    { Value: 11, Text: 'November' },
    { Value: 12, Text: 'December' }
  ];

  selectionMonthChanged = (event) => {

    this.showHideWarningMessage = this.previousConversionMonth != 0 ? this.previousConversionMonth != event.Value ? true : false : false;

    var currentDate = new Date();
    currentDate.setMonth(event.Value - 1);
    currentDate.setDate(1);
    currentDate.setHours(-1);
    this.lastConversionDate = currentDate.toString();
  }

  convertDateIntoDisplay = (date) => {
    if (date == null)
      return date;

    let dateString = moment(date).format("D MMM YYYY");
    return dateString;
  }

  saveConversionDate = () => {
    if (!this.conversionMonth) {
      this.toaster.warning('Month  cannot be empty !', 'Warning!');
      return;
    } else if (!this.conversionYear) {
      this.toaster.warning('Year cannot be empty !', 'Warning!');
      return;
    }

    this.conversionBalanceService.saveConversionConfig(this.conversionId, this.conversionMonth, this.conversionYear).subscribe((x) => {
      this.spinner.hide();
      if (x.Id > 0) {

        this.toaster.success('Conversion Date Successfully Added !', 'Success!');
        if (this.showStrartDateDiv === false)
        {
          this.UpdateStep();
        }

        this.conversionMonth = x['ConversionMonth'];
        this.conversionYear = x['ConversionYear'];

        this.showHideDateIcon = this.conversionMonth == null ? false : true;
        this.showStrartDateDiv= this.conversionMonth == null ? false : true;
        var currentDate = new Date();
        currentDate.setMonth(this.conversionMonth - 1);
        currentDate.setDate(1);
        currentDate.setHours(-1);

        this.previousConversionMonth = this.conversionMonth;
        this.previousConversionDate = currentDate.toString();

        this.showHideWarningMessage = false;
        this.getCurrentYear();
        this.getConversionDate();

        this.ngxSmartModalService.closeAll(); //.close();

      } else {
        this.spinner.hide()
        this.toaster.warning('Please fill all the required fields!', 'Warning!');
      }
    }, (err) => { this.spinner.hide(); });
  }

  changeOnSelection = (e) => {
    this.selectType =  e.target.value;
  }
  routeDecider = () => {
    console.log(this.selectType);
    if (this.selectType === 0)
    {
      this.toaster.warning('Please select at leaast one item');
      return;
    }
    else if (this.selectType == 2)
    {
      this.showBusinessFeature = false;
    }
    else if (this.selectType == 1)
    {
      this.UpdateStep();
      this.router.navigate(['/']);
    }
    else
    {
      this.UpdateStep();
      this.router.navigate(['/']);
    }

  }
}
