import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManufactureOrderStatus } from 'src/app/enum/manufacture-order-status';
import { ManufactureOrder } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';
import { SalesOrderProgressComponent } from '../../sales/sales-order-progress/sales-order-progress.component';

@Component({
  selector: 'app-manufacture-order-list',
  templateUrl: './manufacture-order-list.component.html',
  styleUrls: ['./manufacture-order-list.component.css']
})
export class ManufactureOrderListComponent implements OnInit {

  gridApi;
  orderList: any[];
  draftOrderList: ManufactureOrder[];
  approveOrderList: ManufactureOrder[];
  waitingOrderList: ManufactureOrder[];
  toProcessOrderList: ManufactureOrder[];
  producedOrderList: ManufactureOrder[];
  filteredOrderList: ManufactureOrder[];
  status: string;
  searchText: string;
  isApprovedTabOpen: Boolean = false;
  isBackOrder: Boolean = false;
  isInventoryAllOrWaitingOrToProcess: Boolean = false;

  subTitle: string;
  backURL: string;

  constructor(
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private manufactureService: ManufactureService
  ) { }

  ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get("status");
    this.setStatus();
    this.subTitle = this.setSubTitle();
    this.backURL = this.setBackURL();
    this.getManufactureOrderList();
  }

  setStatus() {
    if(this.status == 'BackOrder'){
      this.status = 'All';
      this.isBackOrder = true;
    }
    else if(this.status == 'InventoryAll' || this.status == 'InventoryWaiting' || this.status == 'InventoryToProcess') {
      this.isInventoryAllOrWaitingOrToProcess = true;
      if(this.status == 'InventoryAll') {
        this.status = 'All';
      }
      else if(this.status == 'InventoryWaiting') {
        this.status = 'Waiting';
      }
      else if(this.status == 'InventoryToProcess') {
        this.status = 'ToProcess';
      }
    }
  }

  setSubTitle() {
    if(this.isBackOrder || this.isInventoryAllOrWaitingOrToProcess) {
      return 'Inventory Overview';
    }
    else {
      return 'Home';
    }
  }

  setBackURL() {
    if(this.isBackOrder || this.isInventoryAllOrWaitingOrToProcess) {
      return '/products/item-list/6';
    }
    else {
      return '/';
    }
  }

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  search = () => {
    this.gridApi.setFilterModel(null);
    const searchText = this.searchText.toLowerCase();
    if (searchText.includes(',')) {
      const amount = parseInt(searchText.replace(',', ''), 10);
      this.gridApi.setQuickFilter(null);
      this.gridApi.setRowData(this.orderList?.filter(row => {
        const billAmount = parseFloat(row.BillAmount);
        const discountAmount = parseFloat(row.DiscountAmount);
        const dueAmount = parseFloat(row.DueAmount);
        return ( Math.floor(billAmount) === amount || Math.floor(discountAmount) === amount ||Math.floor(dueAmount) === amount );
      }));
    } else {
      this.gridApi.setQuickFilter(searchText);
    }
  }

  getManufactureOrderList() {
    this.manufactureService.getManufactureOrderList().subscribe((x) => {
      this.orderList = x;
      this.orderList.map((item) => {
        item.OrderNo = 'MO-'+item.ManufactureOrderNo;
        item.ManufactureOrderReferenceNo = item.ParentId == null ? '-' : ('MO-'+item.ParentId);
        item.MaterialCostPerUnit = (item.ItemTotalAmount/item.ProductionQuantity).toFixed(2);
        item.OperatingCostPerUnit = (item.OperationTotalAmount/item.ProductionQuantity).toFixed(2);
        item.TotalProductionCost = (item.ItemTotalAmount+item.OperationTotalAmount).toFixed(2);
      });

      this.draftOrderList = this.orderList?.filter((x) => x.Status == ManufactureOrderStatus.WAITING);
      this.approveOrderList = this.orderList?.filter((x) => x.Status != ManufactureOrderStatus.WAITING);
      this.waitingOrderList = this.orderList.filter((x) => x.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED);
      this.toProcessOrderList = this.orderList.filter((x) => x.Status == ManufactureOrderStatus.TO_BE_PROCESSED);
      this.producedOrderList = this.orderList.filter((x) => x.Status == ManufactureOrderStatus.PROCESSED);
      this.pageLoad(this.status);
    });
  }

  public pageLoad(status: string) {
    this.status = status;
    if(!this.isBackOrder && !this.isInventoryAllOrWaitingOrToProcess) {
      this.filteredDefaultOrderList(status);
    }
    else if(this.isInventoryAllOrWaitingOrToProcess) {
      this.filteredInventoryAllOrWaitingOrToProcessList(status);
    }
    else if(this.isBackOrder){
      this.filteredBackOrderList(status);
    }
  }

  filteredDefaultOrderList(status: string) {
    switch (status) {
      case "All":
        this.isApprovedTabOpen = false;
        this.filteredOrderList = this.orderList;
        break;
      case "Draft":
        this.isApprovedTabOpen = false;
        this.filteredOrderList = this.draftOrderList;
        break;
      case "Approve":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.approveOrderList;
        break;
      case "Waiting":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.waitingOrderList;
        break;
      case "ToProcess":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.toProcessOrderList;
        break;
      case "Produced":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.producedOrderList;
        break;
    }
  }

  filteredInventoryAllOrWaitingOrToProcessList(status: string) {
    switch (status) {
      case "All":
        this.isApprovedTabOpen = false;
        this.filteredOrderList = this.orderList;
        break;
      case "Draft":
        this.isApprovedTabOpen = false;
        this.filteredOrderList = this.draftOrderList;
        break;
      case "Approve":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.approveOrderList;
        break;
      case "Waiting":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.waitingOrderList.filter(x => x.ParentId == null && x.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED);
        break;
      case "ToProcess":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.toProcessOrderList.filter(x => x.ParentId == null && x.Status == ManufactureOrderStatus.TO_BE_PROCESSED);
        break;
      case "Produced":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.producedOrderList;
        break;
    }
  }

  filteredBackOrderList(status: string) {
    switch (status) {
      case "All":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.approveOrderList.filter(x => x.ParentId != null);
        break;
      case "Waiting":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.waitingOrderList.filter(x => x.ParentId != null && x.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED);
        break;
      case "ToProcess":
        this.isApprovedTabOpen = true;
        this.filteredOrderList = this.toProcessOrderList.filter(x => x.ParentId != null && x.Status == ManufactureOrderStatus.TO_BE_PROCESSED);
        break;
    }
  }

  domLayout = "autoHeight";
  rowHeight = 275;
  ColumnDefs = [
    { headerName: "MO Number",
      field: "OrderNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      minWidth: 125,
      cellRenderer: (params) => {
        return this.openManufactureOrderDetails(params.data);
      }
    },
    { headerName: "Item Name", field: "Item.ItemName" },
    { headerName: "Batch No", field: "BatchNo" },
    { headerName: "Back Order Ref", field: "ManufactureOrderReferenceNo",
      cellRenderer: (params) => {
        return this.openManufactureOrderBackOrderDetails(params.data);
      }
     },
    { headerName: "Produce Qty", field: "ProductionQuantity" },
    { headerName: "Manufacture Date", field: "CreatedDate", valueFormatter: this.convertDateIntoDisplay },
    { headerName: "Total Material Cost", field: "ItemTotalAmount",
      cellRenderer: (params) => { return this.formatValue(params); }
    },
    { headerName: "Total Operating Cost", field: "OperationTotalAmount",
      cellRenderer: (params) => { return this.formatValue(params); }
    },
    { headerName: "Total Production Cost", field: "TotalProductionCost",
      cellRenderer: (params) => { return this.formatValue(params); }
    },
    {
      headerName: "Status", field: "Status",
      cellRenderer: (params) => { return this.formatStatus(params); }
    },
    { 
      headerName: 'Progress', field: 'Progress', 
      cellRendererFramework: SalesOrderProgressComponent 
    }
  ];

  formatValue(data) {
    if(data.data.Status != ManufactureOrderStatus.PROCESSED) {
      return '-';
    }
    else{
      return data.value;
    }
  }

  formatStatus(data) {
    if(data.data.Status == ManufactureOrderStatus.WAITING) {
      return 'Draft';
    }
    else if(data.data.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED) {
      return 'Waiting';
    }
    else if(data.data.Status == ManufactureOrderStatus.TO_BE_PROCESSED) {
      return 'To Process';
    }
    else if(data.data.Status == ManufactureOrderStatus.PROCESSED) {
      return 'Produced';
    }
  }

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
    scrollbarWidth: 0,
    resizable: true
  };

  onGridReady(event) {
    this.gridApi = event.api;
  }

  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }

  openManufactureOrderDetails(order: any){
    if(order.Status == ManufactureOrderStatus.PROCESSED) {
      return `<a href="#/manufacture/manufacutring-order-produce-details/${order.Id}" >${order.OrderNo}</a>`;
    }
    else {
      return `<a href="#/manufacture/manufacutring-order-details/${order.Id}" >${order.OrderNo}</a>`;
    }
  }

  openManufactureOrderBackOrderDetails(order: any){
    if(order.ParentId != null) {
      let backOrder = this.orderList.find(x => x.ManufactureOrderNo == order.ParentId);
      return `<a href="#/manufacture/manufacutring-order-produce-details/${backOrder.Id}" >${order.ManufactureOrderReferenceNo}</a>`;
    }
    else{
      return '-';
    }
  }

  CreateManufactureOrder() {
    this.router.navigate(['manufacture/create-manufacutring-order']);
  }

  refresh() {
    this.spinner.show();
    this.manufactureService.recalculateStatus().subscribe((x) => {
      if(x.Success){
        this.toaster.success(x.Message);
        this.spinner.hide();
        this.getManufactureOrderList();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

}
