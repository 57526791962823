import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from './../../../service/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProductServiceService } from '../../../service/Products/product-service.service'
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../service/EventEmitter/data.service'; 


@Component({
  selector: 'app-edit-purchase-product',
  templateUrl: './edit-purchase-product.component.html',
  styleUrls: ['./edit-purchase-product.component.css']
})
export class EditPurchaseProductComponent implements OnInit {

  @Input() productId;
  @Input()  editable;
  idParam: number = 0;
  sub: any;
  data: any = [];

  itemForm: FormGroup;
  chartOfAccounts: any;
  inverntoryChartOfAccounts: any;
  purchaseChartOfAccounts: any;
  sellChartOfAccounts: any;
  TrackDisable: boolean;
  showpurchaseDiv: boolean = false;
  showsellDiv: boolean = true;
  showTrackDiv: boolean = true;

  chckedboxpurchase: boolean = true;
  chckedboxsell: boolean = false;

  isTrackItem :boolean=false;


  constructor(private spinner: NgxSpinnerService, 
              public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              private router: Router, 
              public authService: AuthService, 
              private toastr: ToastrService, 
              private dataService: DataService, 
              private productServiceService: ProductServiceService, 
              private accountingServiceService: AcountingServiceService) { }

  ngOnInit(): void {
    this.itemData();
    this.getAllChartOfAccount();

    if (this.productId != null) {
      this.getItems(this.productId);
    } 
    console.log("editable " + this.editable);
  }

 
  changeTrackDiv() {
    this.showpurchaseDiv = !this.showTrackDiv;
    this.showsellDiv = this.showTrackDiv == true ?   false :true;
    this.showTrackDiv = this.showTrackDiv == true ?  false : true;
    this.chckedboxpurchase = !this.showTrackDiv;
    this.chckedboxsell = !this.showTrackDiv;
   // this.isTrackItem= !this.showTrackDiv;

   if(this.showTrackDiv == true){
    this.itemForm.controls['IsPurchaseItem'].enable();
    this.itemForm.controls['IsSaleItem'].enable();
   }
   else{
    this.itemForm.controls['IsPurchaseItem'].disable();
    this.itemForm.controls['IsSaleItem'].disable();
   }
    
  }

  getAllChartOfAccount = () => {
    this.accountingServiceService.GetChartOfAccountsforItems().subscribe((x) => {
      this.chartOfAccounts = x.ExpenseList;
      this.inverntoryChartOfAccounts = this.chartOfAccounts.filter(c => {return c.AccountCode == 630 });
      this.purchaseChartOfAccounts = x.ExpenseList;
      this.sellChartOfAccounts =x.RevenueList;
    })
  }

  itemData() {
    this.itemForm = this.fb.group({
      Id: [''],
      ItemCode: ['', Validators.required],
      ItemName: ['', Validators.required],
      IsPurchaseItem: [true],
      PurchasePrice: [''],
      PurchaseDescription: [''],
      PurchaseAccountId: [''],
      IsSaleItem: [''],
      SaleUnitPrice: [''],
      SalesDescription:[''],
      SaleAccountId: [''] ,
      InventoryAccountId: [''],
      IsTrackItem: [''],
    })
  }

  saveCreateItem() {
    this.spinner.show();
    if (this.itemForm.controls.ItemCode.invalid || this.itemForm.controls.ItemName.invalid) 
    {
      this.spinner.hide();
      return this.toastr.error('ItemCode and ItemName are required', 'Wrong!');
    } 
    else 
    {
        if(this.itemForm.value.IsTrackItem == true)
        {
          this.itemForm.value.IsPurchaseItem = true;
          this.itemForm.value.IsSaleItem = true;
          this.submitCreateItem(this.itemForm);
          this.spinner.hide();
        }
        else
        {         
          this.itemForm.patchValue({InventoryAccountId :''});

          if(this.itemForm.value.IsPurchaseItem == false)
          {
            this.itemForm.patchValue({PurchasePrice :''});
            this.itemForm.patchValue({PurchaseDescription :'' });
            this.itemForm.patchValue({PurchaseAccountId :'' });
          }
          if(this.itemForm.value.IsSaleItem == false)
          {
            this.itemForm.patchValue({SaleUnitPrice :''});
            this.itemForm.patchValue({SalesDescription :'' });
            this.itemForm.patchValue({SaleAccountId :'' });
          }
          this.submitCreateItem(this.itemForm);
          this.spinner.hide();
        }
    }
    this.spinner.hide();
  }

  submitCreateItem(itemsForm){
    
    this.productServiceService.updateProductData(itemsForm.value).subscribe(res => {
      if(res.Success){
        this.toastr.success(res.Message, 'Success!');
        this.dataService.emitItemListUpdate(true);
      }
      else{
        this.toastr.error(res.Message, 'Wrong!');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide()
      this.toastr.error('Wrong itemCode or sellAccountId, please try again', 'Wrong!');
    })
    
  }
 
  getItems(id){

    this.productServiceService.GetItemById(id).subscribe((x) => {
      if (x) {
        this.data = x.data;
           console.log( this.data);
           this.itemForm.patchValue({Id :this.data.Id});
            this.itemForm.patchValue({ItemCode :this.data.ItemCode});
            this.itemForm.patchValue({ItemName :this.data.ItemName});

            this.itemForm.patchValue({IsPurchaseItem :this.data.IsPurchaseItem});
            this.itemForm.patchValue({PurchasePrice :this.data.PurchasePrice});
            this.itemForm.patchValue({PurchaseDescription :this.data.PurchaseDescription });
            this.itemForm.patchValue({PurchaseAccountId :this.data.PurchaseAccountId });
            
            this.itemForm.patchValue({IsSaleItem :this.data.IsSaleItem});
            this.itemForm.patchValue({SaleUnitPrice :this.data.SaleUnitPrice});
            this.itemForm.patchValue({SalesDescription :this.data.SalesDescription });
            this.itemForm.patchValue({SaleAccountId :this.data.SaleAccountId });

            this.itemForm.patchValue({IsTrackItem :this.data.IsTrackItem});
            this.itemForm.patchValue({InventoryAccountId :this.data.InventoryAccountId});

            this.showpurchaseDiv = this.data.IsPurchaseItem == true ? false: true;
            this.showsellDiv =  this.data.IsSaleItem == true ? false: true;
            this.showTrackDiv =this.data.IsTrackItem == true ? false: true;
            if(this.editable && this.data.IsTrackItem)
            {
              this.TrackDisable=true;
            }
            if(this.showTrackDiv == true){
              this.itemForm.controls['IsPurchaseItem'].enable();
              this.itemForm.controls['IsSaleItem'].enable();
             }
             else{
              this.itemForm.controls['IsPurchaseItem'].disable();
              this.itemForm.controls['IsSaleItem'].disable();
             }
      }
    })
  }

}
