import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth/auth.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { MainServiceService} from '../../service/Main/main-service.service'
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.css']
})
export class AcceptInvitationComponent implements OnInit {
  passwordSetForm: FormGroup;
  activationKey: string = ''
  isNewUser: boolean;
  showHideNewUser: boolean = false;
  validInvalidToken = true;
  constructor(private route: Router,public authService: AuthService, private spinner: NgxSpinnerService,private mainService: MainServiceService, private toaster: ToastrService, public fb: FormBuilder) { 
    //activationKey
  }


  ngOnInit(): void {
    let stringKey = this.route.url
    this.activationKey = stringKey.replace("/accept-invitation?trackno=", "");
    this.spinner.show();
    this.mainService.CheckInvitationType(this.activationKey).subscribe((x)=>{
      this.isNewUser = x.IsNew;
      this.validInvalidToken = x.Success;
      console.log(x)
      if(x.Success)
      {
        this.AcceptInvitation();

      }
      else
      {
        this.spinner.hide();
        this.toaster.warning(x.Message,'warning');
      }
      
      
    })
    this.passwordSetForm = this.fb.group({
      Password: ['', Validators.required],
      rePassword: ['', Validators.required],
      TrackNo: ['']
    })
  }

  AcceptInvitation = () => {
    if (!this.isNewUser) {
      this.mainService.AcceptInvitation(this.activationKey).subscribe((x) => {
        if (x.Success) {
          // const userInfo = this.authService.getUserInfo();
          // const data = {
          //     SelectedCompany: userInfo.SelectedCompany,
          //     CompanyList: userInfo.CompanyList,
          //   };
          // this.authService.setUserInfo(JSON.stringify(data));
          this.route.navigate(['/']);
          this.spinner.hide();
          this.toaster.success(x.Message);
     } else {
          this.toaster.warning(x.Message);
        }
      }, (err) => {
        this.toaster.warning('You are unauthorised to do this action');
        this.spinner.hide();
      });
    } else {
      this.showHideNewUser = true;
      this.spinner.hide();
    }
  }
  validatePassword = password => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{5,}$/;
    console.log(re.test(password));
    return re.test(password);
  }
  submitChangePassword = () => {
    if (!this.validatePassword(this.passwordSetForm.value.Password)){
       this.toaster.warning('Password should be minimum 6 digit long and must have one Capital letter,small letter and one number.');
        return ;
    }
    this.passwordSetForm.value.TrackNo = this.activationKey;
    if (!this.passwordSetForm.invalid && this.passwordSetForm.value.Password == this.passwordSetForm.value.rePassword){
      this.mainService.AcceptInvitationNewUser(this.passwordSetForm.value).subscribe((x) => {
        if (x.Success){
          this.toaster.success('Your Account has been successfully created, please login now to use eCount');
          this.route.navigate(['/auth/login'])
        }else{
          this.toaster.warning('Something went wrong please try again !');
        }
      }, (err) => {
          this.toaster.error('Something went wrong please try again !');
      })

    }

}

}
