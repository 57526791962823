<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle">Reverse Transfer</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="ReturnForm" class="f-ss-14px">
                    <div class="d-flex">
                        <div class="col-md-8 text-bold">Items</div>
                        <div class="col-md-2">Quantity</div>
                    </div>
                    <hr class="pb-0 mb-0 pt-1 mt-1">
                    <div formArrayName="ReturnItems" *ngFor="let item of ReturnForm.get('ReturnItems')['controls']; let i = index;">
                        <div class="row f-ss-14px" [formGroupName]="i">
                            <div class="col-md-8 ptb">
                                <input class="form-control ProductField" type="text" formControlName="ProductName" readonly>
                            </div>
                            <div class="col-md-2 ptb">
                                <input class="form-control ShippedField" type="text" appDecimaNumericValidator formControlName="ReturnedQuantity" (change)="updateReturnedQuantity()">
                            </div>
                            <div class="col-md-2 ptb">
                                <img class="mt-2 pointer" src="../../../assets/icons/delete.png" width="18px" height="18px" (click)="removeItems(i)">
                            </div>
                        </div>
                        <hr class="pb-0 mb-0 pt-0 mt-0">
                    </div>
                    <div class="full-width text-align-left" *ngIf="WareHouseOutData?.allData?.WareHouseItems?.length != ReturnItems?.value?.length">
                        <button type="button" class="eCount-button3 mt-4 mr-4" (click)="addRemovedItem()">Add New Item</button>
                    </div>
                    <div class="full-width text-align-right">
                        <button type="button" class="eCount-def-btn mt-4 mr-4 blueBg" (click)="return()">Return</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>