import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ManufactureItemType } from 'src/app/enum/manufacture-order-item-type';
import { ManufactureOrderStatus } from 'src/app/enum/manufacture-order-status';
import { ManufactureOrderDetailsPageViewModel } from 'src/app/models-viewModels/manufactureOrderDetailsPageViewModel';
import { ManufactureOrderProductionPageViewModel } from 'src/app/models-viewModels/manufactureViewModel.model';
import { ItemDetails } from 'src/app/models/inventoryOverview.model';
import { ManufactureOrderDetails, OperatingCostItem } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';

@Component({
  selector: 'app-manufacture-order-produce-details',
  templateUrl: './manufacture-order-produce-details.component.html',
  styleUrls: ['./manufacture-order-produce-details.component.css']
})
export class ManufactureOrderProduceDetailsComponent implements OnInit {

  ManufactureOrderForm: FormGroup;
  OrderItems: FormArray;
  MiscellaneousItems: FormArray;

  manufactureOrderId: number;
  manufactureOrderData: ManufactureOrderDetailsPageViewModel;
  itemMap: Map<number, ItemDetails> = new Map<number, ItemDetails>();

  totalOrderItemsConsume: number = 0;
  totalMiscellaneousItemsConsume: number = 0;
  totalOrderItemsAmount: number = 0.0;
  totalMiscellaneousItemsAmount: number = 0.0;

  constructor(
    private route: ActivatedRoute,
    private manufactureService: ManufactureService,
    private fb: FormBuilder 
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let no = +params['id'];
      this.manufactureOrderId = no;
      this.getOrder();
   });
   this.initializeForm();
  }

  initializeForm() {
    this.ManufactureOrderForm = this.fb.group({
      Id: [0],
      FinishedProductId: [''],
      FormulaName: [''],
      Quantity: [''],
      CreatedDate: [''],
      StartDate: [''],
      EndDate: [''],
      Reference: [''],
      BatchNo: [''],
      OperatingCostPerUnit: [0],
      MaterialCostPerUnit: [0],
      MiscellaneousItemCostPerUnit: [0],
      ProductionCostPerUnit: [0],
      TotalOperatingCost: [0],
      TotalMaterialCost: [0],
      TotalMiscellaneousItemCost: [0],
      TotalProductionCost: [0],
      OrderItems: this.fb.array([]),
      MiscellaneousItems: this.fb.array([]),
      OperatingCostItems: this.fb.array([])
    });
  }

  getOrder(){
    this.manufactureService.getOrder(this.manufactureOrderId).subscribe(x => {
      this.manufactureOrderData = x;
      x.ItemDetails.forEach(obj => {
        let key = obj.Id;
        this.itemMap.set(key, obj);
      });
      this.setParentData(this.manufactureOrderData);
      this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.NORMAL).forEach((item, i) => {
        this.totalOrderItemsConsume += item.TotalConsumed;
        this.totalOrderItemsAmount += item.LineTotal;
        this.addItem(item, i);
      });

      this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).forEach((item, i) => {
        this.totalMiscellaneousItemsConsume += item.TotalConsumed;
        this.totalMiscellaneousItemsAmount += item.LineTotal;
        this.addMiscellaneousItem(item, i);
      });

      this.manufactureOrderData.OperatingCostItem.forEach((item, i) => {
        this.addOperatingCostItem(item, i);
      });
    });
  }

  setParentData(orderData) {
    let totalMaterialCost = 0.0;
    orderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.NORMAL).forEach((item, i) => {
      totalMaterialCost += item.LineTotal;
    });

    let totalMiscellaneousItemCost = 0.0;
    orderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).forEach((item, i) => {
      totalMiscellaneousItemCost += item.LineTotal;
    });

    let createdDate = moment(orderData.ManufactureOrder.CreatedDate).format("Do MMM YYYY");
    let startDate = moment(orderData.ManufactureOrder.StartDate).format("Do MMM YYYY");
    let endDate = moment(orderData.ManufactureOrder.EndDate).format("Do MMM YYYY");
    this.ManufactureOrderForm.controls['Id'].patchValue(orderData.ManufactureOrder.Id);
    this.ManufactureOrderForm.controls['FinishedProductId'].patchValue(orderData.ManufactureOrder.Item.ItemCode);
    this.ManufactureOrderForm.controls['FormulaName'].patchValue(orderData.ManufactureFormula.FormulaName);
    this.ManufactureOrderForm.controls['Quantity'].patchValue(orderData.ManufactureOrder.ProductionQuantity);
    this.ManufactureOrderForm.controls['CreatedDate'].patchValue(createdDate);
    this.ManufactureOrderForm.controls['StartDate'].patchValue(startDate);
    this.ManufactureOrderForm.controls['EndDate'].patchValue(endDate);
    this.ManufactureOrderForm.controls['Reference'].patchValue(orderData.ManufactureOrder.Reference);
    this.ManufactureOrderForm.controls['BatchNo'].patchValue(orderData.ManufactureOrder.BatchNo);

    this.ManufactureOrderForm.controls['MaterialCostPerUnit'].patchValue((totalMaterialCost/orderData.ManufactureOrder.ProductionQuantity).toFixed(2));
    this.ManufactureOrderForm.controls['MiscellaneousItemCostPerUnit'].patchValue((totalMiscellaneousItemCost/orderData.ManufactureOrder.ProductionQuantity).toFixed(2));
    this.ManufactureOrderForm.controls['OperatingCostPerUnit'].patchValue((orderData.ManufactureOrder.OperationTotalAmount/orderData.ManufactureOrder.ProductionQuantity).toFixed(2));
    this.ManufactureOrderForm.controls['ProductionCostPerUnit'].patchValue(((orderData.ManufactureOrder.ItemTotalAmount + orderData.ManufactureOrder.OperationTotalAmount)/orderData.ManufactureOrder.ProductionQuantity).toFixed(2));

    this.ManufactureOrderForm.controls['TotalMaterialCost'].patchValue(totalMaterialCost.toFixed(2));
    this.ManufactureOrderForm.controls['TotalMiscellaneousItemCost'].patchValue(totalMiscellaneousItemCost.toFixed(2));
    this.ManufactureOrderForm.controls['TotalOperatingCost'].patchValue(orderData.ManufactureOrder.OperationTotalAmount.toFixed(2));
    this.ManufactureOrderForm.controls['TotalProductionCost'].patchValue((orderData.ManufactureOrder.ItemTotalAmount + orderData.ManufactureOrder.OperationTotalAmount).toFixed(2));
  }

  addItem(item: ManufactureOrderDetails, i: number) {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.push(this.createItem(item, i));
  }

  createItem(item: ManufactureOrderDetails, i: number): FormGroup {
    let formulaQuantity = this.manufactureOrderData.ManufactureFormula.Quantity
    let formulaItemQuantity = this.manufactureOrderData.ManufactureFormula.ManufactureFormulaDetails.find(x => x.ItemId == item.ItemId).Quantity;
    let consume = (formulaItemQuantity / formulaQuantity) * this.manufactureOrderData.ManufactureOrder.ProductionQuantity;
    return this.fb.group({
      ItemId: [item.ItemId],
      RawMaterialId: [item.Item.ItemCode],
      ToConsume: [consume.toFixed(2)],
      Reserved: [this.getItemReserved(item)],
      UsedItems: [item.UsedItem.toFixed(2)],
      BrokenItems: [item.BrokenItem.toFixed(2)],
      Consumed: [item.TotalConsumed.toFixed(2)],
      UnitPrice: [item.PricePerUnit.toFixed(2)],
      LineTotal: [item.LineTotal.toFixed(2)],
      ManufactureOrderDetailsId: [item.Id]
    });
  }

  getItemReserved(item: ManufactureOrderDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.TotalConsumed <= stockQuantity ? item.TotalConsumed : stockQuantity;
  }

  addOperatingCostItem(item: OperatingCostItem, i: number) {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('OperatingCostItems') as FormArray;
    this.MiscellaneousItems.push(this.createOperatingCostItem(item, i));
  }

  createOperatingCostItem(item: OperatingCostItem, i: number) {
    return this.fb.group({
      OperatingCostId: [item.OperationCostType.Name],
      OperatingCostAmount: [item.Amount.toFixed(2)]
    });
  }

  getMiscellaneousItemReserved(item: ManufactureOrderDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.TotalConsumed <= stockQuantity ? item.TotalConsumed : stockQuantity;
  }

  addMiscellaneousItem(item: ManufactureOrderDetails, i: number) {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.push(this.createMiscellaneousItem(item, i));
  }

  createMiscellaneousItem(item: ManufactureOrderDetails, i: number): FormGroup {
    return this.fb.group({
      ItemId: [item.ItemId],
      MiscellaneousRawMaterialId: [item.Item.ItemCode],
      MiscellaneousToConsume: [item.TotalConsumed],
      MiscellaneousReserved: [this.getMiscellaneousItemReserved(item)],
      MiscellaneousConsumed: [item.TotalConsumed],
      MiscellaneousUnitPrice: [item.PricePerUnit],
      MiscellaneousLineTotal: [item.LineTotal],
      ManufactureOrderDetailsId: [item.Id],
    });
  }

  canShowMiscellaneousItem() {
    let hasMiscellaneousItem = this.manufactureOrderData?.ManufactureOrder?.ManufactureOrderDetails?.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).length != 0;
    return hasMiscellaneousItem;
  }

  setMainTitle() {
    let status = this.formatStatus();
    let mainTitle = 'MO-'+this.manufactureOrderData?.ManufactureOrder?.ManufactureOrderNo+' / '+status;
    return mainTitle;
  }

  formatStatus() {
    if(this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING) {
      return 'Draft';
    }
    else if(this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED) {
      return 'Waiting';
    }
    else if(this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.TO_BE_PROCESSED) {
      return 'To Process';
    }
    else if(this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.PROCESSED) {
      return 'Produced';
    }
  }

  canShowOrderItems() {
    let hasOrderItems = this.manufactureOrderData?.ManufactureOrder?.ManufactureOrderDetails?.filter(x => x.ItemType == ManufactureItemType.NORMAL).length != 0;
    return hasOrderItems;
  }

  canShowMiscellaneousItems() {
    let hasMiscellaneousItems = this.manufactureOrderData?.ManufactureOrder?.ManufactureOrderDetails?.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).length != 0;
    return hasMiscellaneousItems;
  }

  canShowOperatingCosts() {
    let hasOperatingCosts = this.manufactureOrderData?.OperatingCostItem?.length != 0;
    return hasOperatingCosts;
  }
  
}
