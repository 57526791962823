import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ReceivemoneyServiceService } from '../../../service/ReceiveMoney/receivemoney-service.service'
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service'
import { customer } from '../../../models/customer.model'
import { ReceiveMoneyItem, createReceiveMoney } from '../../../models/receiveMoney.model'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { MainServiceService } from '../../../service/Main/main-service.service'
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgbModal, NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseProductComponent } from '../../Products/purchase-product/purchase-product.component';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';


@Component({
  selector: 'app-receive-money',
  templateUrl: './receive-money.component.html',
  styleUrls: ['./receive-money.component.css']
})

export class ReceiveMoneyComponent implements OnInit{
  allSupplier: any= [];
  PurchaseableItems: any = []
  allChartOfAccount: any = []
  bankList: any = [];
  receiveMoneyTypeList: any =[];
  selectedSupplier: number;
  selectedBank:number;
  selectedReceiveMoneyTypeId:number;
  showBankWarningDiv: boolean = true;
  showItemRow: boolean= true;
  showHideInvoiceNo: boolean =true;
  receiveMoneyData: createReceiveMoney;
  receiveMoneyItem: ReceiveMoneyItem[] = [];
  selectedDate: any = new Date();
  selectedDueDate: Date;
  totalPrice: number = 0.00;
  ReferenceNo: string="";
  InvoiceNo: string="";
  loading = false;
 isItemListUpdate = false;

 TaxTypeId: null;
 selectedTaxType: number = 3;
 taxTypeList: any = [];
 taxRateList: any = [];

 subTotalAmount: number = 0.00;
 taxAmount: number = 0.00;
 advanceTaxAmount: number = 0.00;


  constructor(private receiveMoneyService: ReceivemoneyServiceService,
              private accountingService: AcountingServiceService,
              private toaster: ToastrService,
              private mainService: MainServiceService,
              private dataService: DataService,
              private router: Router,
              private modalService: NgbModal,
              public ngxSmartModalService: NgxSmartModalService,
              private spinner: NgxSpinnerService,
              private settingService: SettingsServiceService ) { }

  ngOnInit(): void {
    this.getFormData();
    this.show(this.selectedDate);
    this.dataService.change.subscribe(x => {
      this.isItemListUpdate = x;
      this.getAllSaleableItem();
    });
  }
  forMatDate() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.selectedDate = firstDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + firstDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + firstDay.toLocaleDateString('en-GB', { day: '2-digit'});
  }

  defaultAccountSettings = (item) => {
    const account = this.allChartOfAccount.filter(x => x.AccountCode === '200');
    console.log(account);
    item.map((x) => {
      if (x.AccountId == null) {
      x.AccountId = account[0].Id ;
      }
    }) ;
  }
  getFormData = () => {
    this.getAllCustomer();
    this.getAllSaleableItem();
    this.getAllChartOfAccount();
    this.getBankList();
    this.getReceiveMoneyTypeList();
    this.getTaxTypeList();
    this.getTaxRateList();
  }

  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
      if (this.taxTypeList.length >= 2) {
        this.TaxTypeId = this.taxTypeList[2].Id;
      }
    });
  }

  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({ "Id": "", "Name": "Select" });
    });
  }

  CreateNewCustomer = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : true,
      IsSupplier : false,

    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
        this.selectedSupplier = x.Data.Id;
        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.allSupplier.push(x["Data"]);
        this.getAllCustomer();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }
  show(e) {
    const value = this.selectedDate;
    $("#rmtest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

  getBankList(){
    this.bankList = [];
    this.mainService.GetAllBank().subscribe((x)=>{
      this.spinner.hide()
      this.bankList = x;
      if(this.bankList.length == 0){
        this.showBankWarningDiv = false;
      }
    })
  }

  getReceiveMoneyTypeList(){
    this.receiveMoneyTypeList = [];
    this.receiveMoneyService.GetReceiveMoneyTypeList().subscribe((x)=>{
      this.spinner.hide()
      this.receiveMoneyTypeList = x;
      if(this.receiveMoneyTypeList !=null){
        this.selectedReceiveMoneyTypeId=this.receiveMoneyTypeList[0].Id;
      }
    })
  }

  onReceiveMoneyTypeChange(){
    if(this.selectedReceiveMoneyTypeId === 1){
      this.showItemRow = false;
      this.showHideInvoiceNo = true;
    }
    else if (this.selectedReceiveMoneyTypeId === 2){
      this.showHideInvoiceNo = false;
      this.showItemRow = true;
    }
    else{
      this.showHideInvoiceNo = true;
      this.showItemRow = true;
    }
  }

  getAllCustomer = () => {
    this.mainService.GetAllCustomer().subscribe((x: []) => {
      this.allSupplier = x;
    });
  }
  getAllSaleableItem = () => {
    this.receiveMoneyService.GetPurchaseableItems().subscribe((x) => this.PurchaseableItems = x);
  }
  getAllChartOfAccount = () => {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      //this.allChartOfAccount = x.RevenueList.filter(xy => xy.AccountGroupId !== 4 && xy.AccountGroupId !== 2 && xy.AccountCode !== '900');
      this.allChartOfAccount = x.RevenueList.filter(xy => xy.AccountTypeId == 14);
      this.createBlankItem();
    });
  }

  openItemModal(selectItem: NgSelectComponent){
    selectItem.close();
    const modalRefs = this.modalService.open(PurchaseProductComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
  }

  Bills = []


  changespurchaseableItems = (index) => {
    for (let i = 0; i < this.PurchaseableItems.length; i++) {

      if (this.PurchaseableItems[i].Id == this.receiveMoneyItem[index]['ItemId']) {
        console.log(this.PurchaseableItems[i]);
        this.receiveMoneyItem[index]['ItemId'] = this.PurchaseableItems[i]['Id'];
        this.receiveMoneyItem[index]['AccountId'] = this.PurchaseableItems[i]['PurchaseAccountId'];
        this.receiveMoneyItem[index]['Quantity'] = 1;
        this.receiveMoneyItem[index]['ItemDescription']=this.PurchaseableItems[i]['PurchaseDescription'];
        this.receiveMoneyItem[index]['UnitPrice'] = this.PurchaseableItems[i]['PurchasePrice'];
        this.updateDataInvoiceItem(this.receiveMoneyItem[index]['ItemId']);
      }
    }
  }
  changeItemPosition(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.Bills, event.previousIndex, event.currentIndex);
  }
  changeSelectRowInItem = (itemId, index) => {
    let data = []
    this.receiveMoneyItem.map((x, i) => {
      x.ItemId == itemId && i == index ? x.selected = true : x.selected = false
      data.push(x)
    })
    this.receiveMoneyItem = data;
  }
  selectionChanged = (event, itemId, index) => {
    this.receiveMoneyItem.map((x,i) => {
      if (x.ItemId == itemId && i == index) { x.AccountId = event.Id }
    })
  }
  onFocusEvent(event: any){

    event.target.value=event.target.value==0?null:event.target.value;
  }

  updateTotalPrice = () => {
    let SubTotalAmount = 0.00;
    let TaxAmount: number = 0.00;
    let AdvanceTaxAmount: number = 0.00;
    let TotalPrice: number = 0.00;
    this.receiveMoneyItem.map((x) => {
      SubTotalAmount = SubTotalAmount + x.LineTotal;
      TaxAmount += this.getItemTaxAmount(x.LineTotal, x.TaxRateId);
      AdvanceTaxAmount += this.getItemTaxAmount(x.LineTotal, x.AdvanceTaxRateId);
    });

    this.taxAmount = TaxAmount;
    this.advanceTaxAmount = AdvanceTaxAmount;
    this.subTotalAmount = SubTotalAmount;

    switch (this.selectedTaxType) {
      case 1: //tax exclusive
      TotalPrice = this.subTotalAmount + TaxAmount + AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
      TotalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
      TotalPrice = this.subTotalAmount;
        break;
    }
    this.totalPrice = TotalPrice;
    if (this.selectedTaxType === 3) {
      this.checkTaxType();
    }
  }

  private getItemTaxAmount(lineTotal: number, taxRateId: any) {
    let taxPrice = 0.00;
    if (taxRateId == "" || taxRateId == null)
      return taxPrice;

    var taxPercent = this.getTaxRatePercent(taxRateId);

    switch (this.selectedTaxType) {
      case 1: //tax exclusive
        taxPrice = lineTotal * taxPercent / 100;
        break;
      case 2: //tax inclusive
        taxPrice = Number(((lineTotal * taxPercent) / (100 + taxPercent)).toFixed(2));
        break;
      default: //no tax
        taxPrice = 0
        break;
    }
    return taxPrice;
  }

  private getTaxRatePercent(taxRateId: any) {
    let taxRateObj = this.taxRateList.filter(x => x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }

  checkTaxType = () => {
    this.receiveMoneyItem.map((x, i) => {
      x.TaxRateId = null;
      x.AdvanceTaxRateId = null;
    });
  };

  updateDataInvoiceItem = (itemId) => {
    this.receiveMoneyItem.map((x) => {
      if (x.ItemId == itemId) { x.LineTotal = x.Quantity * x.UnitPrice }
    })
    this.updateTotalPrice()
  }
  updateDataInvoiceItemLineTotal = (itemId) => {
    this.receiveMoneyItem.map((x) => {
      if (x.ItemId == itemId) {
        x.UnitPrice = x.LineTotal / x.Quantity
      }
    })
    this.updateTotalPrice()
  }
  createBlankItem = () => {
    this.changeSelectRowInItem(0, 0)
    let item = { ItemId: null,ItemDescription: "", Quantity: 0, UnitPrice: 0, LineTotal: 0, AccountId: null, selected: false, TaxRateId: 0, TaxRatePercent: 0, AdvanceTaxRateId: 0, AdvanceTaxPercent: 0}
    this.receiveMoneyItem.push(item);
    this.defaultAccountSettings(this.receiveMoneyItem);
  }

  createInvoiceItem = (data) => {
    console.log(data);
    this.changeSelectRowInItem(0, 0);
    let item = {
      ItemId: data.Id,
      ItemDescription: data.ItemName,
      Quantity: 1,
      UnitPrice: data.SaleUnitPrice,
      LineTotal: data.SaleUnitPrice,
      AccountId: data.SaleAccountId,
      selected: true,
      TaxRateId: data.TaxRateId,
      TaxRatePercent: data.TaxRatePercent,
      AdvanceTaxRateId: data.AdvanceTaxRateId,
      AdvanceTaxPercent: data.AdvanceTaxPercent
    }
    this.receiveMoneyItem.push(item);
    this.updateTotalPrice()
  }
  removeInvoiceItem = (itemId, i) => {
    let data = []
    this.receiveMoneyItem.map((x, index) => {
      if (index != i) {
        data.push(x);
      }
    })
    this.receiveMoneyItem = data;
    this.updateTotalPrice();
  }
  checkValidation=(receiveMoneyItem)=>{
    var b=true;
    console.log(receiveMoneyItem);
    receiveMoneyItem.forEach(element => {
        if(element.ItemDescription=="" || element.AccountId==null || element.LineTotal<=0)
        {
            b=false;
        }
      });
      return b;
  }


  saveCreateReceiveMoney = () => {

    let selectedCustomer= this.allSupplier.find( ({ Id }) => Id === this.selectedSupplier );
    let fullName= selectedCustomer? selectedCustomer['PrimaryPersonLastName']!=null? selectedCustomer['PrimaryPersonFirstName'] + " " + selectedCustomer['PrimaryPersonLastName']:selectedCustomer['PrimaryPersonFirstName']:null;

    this.receiveMoneyData = {

      ReceivedTypeId: this.selectedReceiveMoneyTypeId,
      ReceivedAccountId:this.selectedBank,
      ReceivedDate: this.selectedDate,
      FromId: this.selectedSupplier,
      FromName: fullName,
      ReferenceNo: this.ReferenceNo.toString(),
      SubTotalAmount: this.totalPrice,
      ReceiveMoneyItems: this.receiveMoneyItem,
      TaxId: this.selectedTaxType,
      TaxAmount: this.taxAmount,
      AdvanceTaxAmount: this.advanceTaxAmount
    }
    let check=this.checkValidation(this.receiveMoneyData.ReceiveMoneyItems);
    if (!this.receiveMoneyData.FromId) {
      this.toaster.warning('From  cannot be empty !', 'Warning!');
      return;
    }
    else if (!this.receiveMoneyData.ReceivedAccountId) {
      this.toaster.warning('To cannot be empty !', 'Warning!');
      return;
    } else if (!this.receiveMoneyData.ReceivedDate) {
      this.toaster.warning('Date cannot be empty !', 'Warning!');
      return;
    } else if(this.receiveMoneyItem.length==0)
    {
      this.toaster.warning('Please add at least one item in the receive money.', 'Warning!');
      return;
    }
    else if (!check) {
      this.toaster.warning('Please fill all the required fields!', 'Warning!');
      return;
    }

    this.spinner.show()
    this.receiveMoneyService.saveReceiveMoneyData(this.receiveMoneyData).subscribe((x) => {
      if (x.Success){
        this.toaster.success('Transaction has been Successful.', 'Success!');
        this.router.navigate(['receivemoney/receive-money-list/all']);
        this.spinner.hide()
      }else{
        this.toaster.warning(x.Message);
        this.spinner.hide()
      }

    })

  }
}
