<div class="modal-header">
    <div>
        <h4 class="modal-title" style="font-size: 17px;">Sales Orders</h4>
    </div>
    <div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
    </div>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <form [formGroup]="salesForm" class="f-ss-14px">
        <div class="pb-4">
            <div class="col-md-12">
                <div style="margin-top: 25px;" class="row">
                    <div class="invoice-grid col-md-12" id="my" >
                        <div class="d-flex">
                            <div class="col-md-3">Order No</div>
                            <div class="col-md-3">Booked Quantity</div>
                            <div class="col-md-3">Amount</div>
                            <div class="col-md-3">Delivery Date</div>
                        </div>
                        <hr class="pb-0 mb-0" />
                        <div formArrayName="SalesOrderItem" *ngFor="let item of salesForm.get('SalesOrderItem')['controls']; let i = index;">
                            
                            <div class="invoiceGridItemRow d-block">

                            <div class="row" [formGroupName]="i" style="margin-top: 10px;">
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control noBorder"
                                        type="text"
                                        formControlName="OrderNo"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control noBorder"
                                        type="text"
                                        formControlName="OrderQty"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control noBorder"
                                        formControlName="OrderAmount"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control noBorder"
                                        formControlName="DeliveryDate"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </form>
</div>
<hr>
<div class="modal-footer">
</div>