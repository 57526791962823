import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asset-type-create',
  templateUrl: './asset-type-create.component.html',
  styleUrls: ['./asset-type-create.component.css']
})
export class AssetTypeCreateComponent implements OnInit {

 public assetTypeForm: FormGroup;
 public inputHelp: any = {};
 public depricatedExpenseAccountId:number = 0;
 public expenseAccounts: any =[];
  @Input() fromParent: any;
  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private pService: FixedAssetService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.getInputHelp();
   this.initializeAssetTypeForm();
  }

  private getInputHelp = () => {
    this.pService.GetAssetTypeInputHelp().subscribe((x) => {
      this.inputHelp = x;
    },(err)=>{});

    this.pService.GetExpenseAccounts().subscribe((x) => {
      this.expenseAccounts = x;console.log(this.expenseAccounts)
      this.depricatedExpenseAccountId = this.expenseAccounts.filter(x=>x.AccountName == "416 - Depreciation")[0].Id;
      this.assetTypeForm.controls['DepreciationExpenseAccountId'].patchValue(this.depricatedExpenseAccountId);
    },(err)=>{});

  };

  public setDepreciationValueType() {
    if(this.assetTypeForm.value.IsRatePercent){
      this.assetTypeForm.controls['EffectiveLifeYears'].setValue(null);
    }
    else{
      this.assetTypeForm.controls['RatePercent'].setValue(null);
    }
  }

  initializeAssetTypeForm() {
    this.assetTypeForm = this.fb.group({
      Id:[this.fromParent.Id],
      AssetType: [this.fromParent.AssetType, Validators.required],
      AssetAccountId: [this.fromParent.AssetAccountId],
      DepreciationAccountId: [this.fromParent.DepreciationAccountId],
      DepreciationExpenseAccountId: [this.depricatedExpenseAccountId],
      DepreciationMethodId: [this.fromParent.DepreciationMethodId],
      AveragingMethodId: [this.fromParent.AveragingMethodId],
      RatePercent: [this.fromParent.RatePercent],
      EffectiveLifeYears: [this.fromParent.EffectiveLifeYears],
      IsRatePercent: [this.fromParent.IsRatePercent]
    });
  }

  /** saveAssetType **/
  public saveAssetType(): void {
    if (this.assetTypeForm.invalid) {
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
  
    this.spinner.show();
  
    this.pService
      .SaveFixedAssetType(this.assetTypeForm.value)
      .subscribe({
        next: (result) => {
          if (result.Success) {
            const successMessage = this.fromParent.Id === 0
              ? 'Asset type has been successfully added.'
              : 'Asset Type updated successfully.';
  
            this.toaster.success(successMessage);
            this.activeModal.close(result);
          } else {
            this.toaster.error(result.Message);
          }
        },
        error: () => {
          this.toaster.error('Oops, something went wrong!');
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

}
