
<app-dashboard>
    <app-page-header-title
        mainTitle="Factory In Transactions"
        subTitle="Home"
        backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
            <button type="button" routerLink="/inventory/factory-in-entry" class="eCount-def-btn ml-4">Create New Factory In</button>
            <button type="button"  class="eCount-button3 ml-4" (click)="onBtnExport()">{{'Contacts.contactList.export'| translate}}</button>
        </div>
        
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('All')" [routerLinkActive]="['active']" routerLink="/inventory/factory-in-list/All">All</a>
        </li>
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData('Draft')" [routerLinkActive]="['active']" routerLink="/inventory/factory-in-list/Draft"> Draft </a>
        </li>
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData('Approved')" [routerLinkActive]="['active']" routerLink="/inventory/factory-in-list/Approved"> Approved </a>
        </li>

      </ul>
    <div class="headSection mt-4">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="TransactionList"
                [columnDefs]="columnDefs"
                [domLayout] = "domLayout"
                (rowClicked)="onRowClicked($event)"
                (gridReady)="onGridReady($event)"
                [defaultColDef]="defaultColDef"
            ></ag-grid-angular>
        </div>
    </div>
</app-dashboard>