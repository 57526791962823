import { Component, HostListener, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import { ReportServiceService} from '../../../service/Report/report-service.service'
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-account-transactions',
  templateUrl: './account-transactions.component.html',
  styleUrls: ['./account-transactions.component.css']
})
export class AccountTransactionsComponent implements OnInit {
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    console.log("scrolling");
  this.constantTableHeader();
  }
  fromDate: string = '';
  toDate: string = '';
  allAccountTransaction: any = []
  totalCredit: number=0;
  totalDebit: number=0;
  totalGrossAmount: number=0;
  totalTaxAmount: number=0;
  totalAdvanceTaxAmount: number=0;
  accountNameList : any =[];
  selectedAccountIdList = [];
  companyName: string= "";
  sticky;
  tops;
  returnUrl:string="/";

  constructor(private spinner: NgxSpinnerService, 
    private reportService: ReportServiceService, 
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService){}

  ngOnInit(){
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.fromDate = firstDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + firstDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + firstDay.toLocaleDateString('en-GB', { day: '2-digit'});
    this.toDate = lastDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + lastDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + lastDay.toLocaleDateString('en-GB', { day: '2-digit'});
    this.show(this.fromDate);
    this.showSelectedDate(this.toDate);
    var x = document.getElementsByClassName("wrapper");
    console.log(x);
    this.GetAccountNameList();
    this.setOrganization();
    this.setQueryParams();
  }

  private setQueryParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.accountId!=undefined){
       this.selectedAccountIdList.push(Number(params.accountId));
      }
      if(params.fromDate!=undefined){
       this.fromDate=params.fromDate;
      }
      if(params.toDate!=undefined){
       this.toDate=params.toDate;
       this.reportGenerate();
      }
      if(params.returnUrl!=undefined){
        this.returnUrl= 'reports/'+params.returnUrl;
       }
     });
  }
  GetAccountNameList(){
    this.reportService.GetAccountNameList().subscribe((x)=>{
      this.accountNameList = x;
    })
  }
  setOrganization(){
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.companyName = userInfo.SelectedCompany.Name;
    }
  }
  filterItemsOfType(type, itemList){
    return itemList.filter(x => x.IsOpeningBalance == type);
  }

  public onSelectAll() {
      this.selectedAccountIdList = this.accountNameList.map(item => item.Id);
  }
  ClearBySelect(selectedAccountIdList){
    this.selectedAccountIdList=selectedAccountIdList;
  }
  public onClearAll() {
    this.selectedAccountIdList = [];
  }
  reportGenerate=()=>{
    if (!this.toDate || !this.fromDate){
      this.toaster.warning('To Date and From date are required !');
      return;
    }
    let data = {
      fromDate: this.fromDate,
      toDate: this.toDate,
      accountIdList:this.selectedAccountIdList.join(", ")
    }
    
    if(data.accountIdList!=""){
      this.spinner.show();
    this.reportService.GetAccountTransaction(data).subscribe((x)=>{
      console.log(x);
      this.allAccountTransaction = x.accountTransList;
      this.totalCredit= x.TotalCreditView;
      this.totalDebit= x.TotalDebitView;
      this.totalGrossAmount= x.TotalGrossAmount;
      this.totalTaxAmount= x.TotalTaxAmount;
      this.totalAdvanceTaxAmount=x.TotalAdvanceTaxAmount;
      this.spinner.hide()
    },(err)=>{
      this.toaster.error("There is an error please check");
      this.spinner.hide();
    })
  }
  else
  {
    this.toaster.warning("please select at least one Account")
  }
}

  convertDateIntoDisplay=(date)=>{
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  show(e) {
    const value = this.fromDate;
    $("#atest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  showSelectedDate(e) {
    const value = this.toDate;
    $("#atest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }

  convetToPDF = () => {
    var data = document.getElementById('printGeneralReport');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      let fromDate=this.convertDateIntoDisplay(this.fromDate);
      let toDate=this.convertDateIntoDisplay(this.toDate);
      var DocumentName = `Account Transaction report (${fromDate} to ${toDate}).pdf)`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    })
  }

  calculateBalance=(identifier, data)=>{
    let total = 0
    data.map((x)=>{
      if(x[identifier]){
        total = total + x[identifier]
      }
    })
    return total.toFixed(2);
  }

  constantTableHeader() {
    let header = document.getElementById("tableHeader");
    let bodyt = document.getElementById("printGeneralReport");
    let title = document.getElementById("tableTitle");
    let tableBody = document.getElementById("tableBody");
    
    if(!header.classList.contains("sticky")){
      this.sticky = bodyt.offsetTop;
    }
    
    if (window.pageYOffset > this.sticky+55) {
      header.classList.add("sticky");
      header.style.width = (bodyt.offsetWidth-50) + 'px'
      if(tableBody){
        tableBody.classList.add("pt-p50px");
      }
    } else {
      header.classList.remove("sticky");
      header.style.width = (bodyt.offsetWidth-50) + 'px'
      if(tableBody){
        tableBody.classList.remove("pt-p50px");
      }
      
    }
  }
}
//printGeneralReport