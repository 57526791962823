import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { AuthService } from "./../../../service/auth/auth.service";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  FormsModule,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-auth-page",
  templateUrl: "./auth-page.component.html",
  styleUrls: ["./auth-page.component.css"],
})
export class AuthPageComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  IssueArr: any = [];
  constructor(
    private dataService: DataService,
    public translate: TranslateService,
    public fb: FormBuilder,
    private router: Router,
    public authService: AuthService,
    private toaster: ToastrService,
    private SpinnerService: NgxSpinnerService
  ) {}

  ngOnInit() {
    const lang = localStorage["language"];
    this.dataService.emitsetSidebarUpdate(false);
    if (lang != undefined) {
      this.translate.use("en");
    } else {
      console.log("en");
      this.translate.use("en");
      localStorage.setItem("language", "en");
    }
    this.loginData();
    if (this.authService.IsLoggedIn()) {
      this.router.navigate(["/"]);
    }
  }
  ngOnDestroy() {
    /* *Here below code reload side menu options when we login into Iquidi, if you only give true* */ 
    this.dataService.emitsetSidebarUpdate(true); 
    //this.dataService.emitSidebarUpdate(true);
  }
  loginData() {
    this.loginForm = this.fb.group({
      Email: [""],
      Password: [""],
    });
  }

  submitLoginForm() {
    this.SpinnerService.show();
    this.authService.LoginService(this.loginForm.value).subscribe(
      (res) => {
        this.authService.setUserInfo(JSON.stringify(res));
        localStorage.setItem("user", this.loginForm.value.Email);
        localStorage.setItem("LoggedInUser", res.LoggedInUser);
        console.log(res);
        if (localStorage["language"] != undefined) {
          this.translate.use(localStorage["language"]);
        } else {
          this.translate.use("en");
          localStorage.setItem("language", "en");
        }

        if (res.CompanyList.length < 1) {
          this.SpinnerService.hide();
          this.router.navigate(["/organization/create"]);
          this.toaster.warning(
            "You have not created any company yet, please create company first !",
            "Company Required!"
          );
        } else {
          this.router.navigate([res.RedirectUrl]);
          this.SpinnerService.hide();
        }
        this.dataService.emitOrganizationUpdate(true);
      },
      (err) => {
        console.log(err);
        this.SpinnerService.hide();
        const message =
          "Your email/phone or password is incorrect. Please try again!";
        this.toaster.error(message);
      }
    );
  }
}
