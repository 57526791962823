import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
//import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';

import { NgxSpinnerService } from "ngx-spinner";
import { DocumentServiceService } from 'src/app/service/DocumentService/document-service.service';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { environment } from 'src/environments/environment';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckCircle, faTimesCircle, faExclamationCircle  } from '@fortawesome/free-solid-svg-icons';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-document-info-modal',
  templateUrl: './document-info-modal.component.html',
  styleUrls: ['./document-info-modal.component.css']
})
export class DocumentInfoModalComponent implements OnInit {

  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faExclamationCircle = faExclamationCircle;

  level: number = 0;
  index: number = 0;

  approvalItem: any = [];
  emailList: any = [];
  approverEmailList: any = [];
  approverRoleList: any = [];
  userList: any = [];
  modalInfoData:any;
  modalHistory:any = [];
  approveLevelForm: FormGroup;
  fileUrl = environment.fileBaseUrl;
  currentUserRoleName:any;
  currentUserEmail:any;

  seeFullHistory: boolean = false;
  downloadedData: any;
  downloadLink: any;

  constructor(
    public activeModal: NgbActiveModal,
    private pService: SettingsServiceService,
    private spinner: NgxSpinnerService,
    private documentService: DocumentServiceService,
    private mainService: MainServiceService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initializeForm();
  }

  initializeForm(){
    this.approveLevelForm = new FormGroup({
      Approvers: new FormControl(''),
      Email: new FormControl(''),
      ApprovedBy: new FormControl(''),
      ApprovedAt: new FormControl(''),
      Comments: new FormControl('')
    });
    
    this.modalInfoData = this.documentService.getModalInfoData();
    let fileName = this.modalInfoData.DocumentBlobName.split('.')[0];
    this.downloadLink = this.fileUrl + fileName  + ".pdf";
    console.log("Modal Info Data",this.modalInfoData)
    if( this.documentService.seeFullHistory == true ){
      this.getDocumentHistoryAll();
      this.seeFullHistory = true;
    }else{
      this.getMasterDataList();
    }
  }


  getApprovalLevel = () => {
    this.pService.GetBillApprovalLevel().subscribe((x) => {
      console.log(this.approverEmailList,'\n',x);
      x.forEach(x => {
        x.ApprovalLevelItem.forEach(y => {
          console.log(y);
          this.approvalItem.push(
            {
              LevelId: x.LevelId,
              ApproverEmail: this.approverEmailList.filter( a => a.Id == y.ApproverEmailId )[0].Name,
              ApproverType: this.approverRoleList.filter( a => a.Id == this.approverEmailList.filter( a => a.Id == y.ApproverEmailId )[0].RoleId  )[0].Name,
              ModifiedAt: y.ModifiedAt,
              ModifiedById: y.ModifiedById
            }
          )
        });
      });
      this.getDocumentHistory();
    });
    console.log( this.approvalItem );
  };


  getMasterDataList = () => {
    this.pService.GetCompanyUsers().subscribe((x) => {
      this.emailList = x;
      this.approverEmailList = x;

      this.pService.GetCompanyRoles().subscribe((x) => {
        this.approverRoleList = x;

        this.pService.GetAllUsers().subscribe((x) => {
          this.userList = x;
          this.getApprovalLevel();
        });

      });
    });
  };

  getDocumentHistory(){
    this.documentService.getDocumentHistory(this.modalInfoData.Number).subscribe((x)=>{
      this.modalHistory = x.Result;
      console.log(this.modalHistory);
      for(let i=0;i<this.modalHistory.length;i++){
        this.modalHistory[i].LevelId = i + 1;
        this.modalHistory[i].RoleId = this.modalHistory[i].ApprovalRole;
        this.modalHistory[i].Email = this.modalHistory[i].ApprovalEmail;
        this.modalHistory[i].UserId = this.modalHistory[i].User.Id;
        this.modalHistory[i].User.Signature = this.fileUrl + this.modalHistory[i].User.Signature;
        this.modalHistory[i].ApprovedBy = this.modalHistory[i]?.ApprovedBy?.split(" ")[0];  // firstname
      }
      console.log(x.Result);
      this.getUserDetails();
    })
  }

  getDocumentHistoryAll(){
    this.documentService.getDocumentHistoryAll(this.modalInfoData.Number).subscribe((x) => {
      this.modalHistory = x.Result;
      for(let i=0;i<this.modalHistory.length;i++){
        if( !this.isNull(this.modalHistory[i].Signature) ){
          this.modalHistory[i].Signature = this.fileUrl + this.modalHistory[i].Signature;
        }

        if( !this.isNull(this.modalHistory[i].ApprovedBy) ){
          this.modalHistory[i].ApprovedBy = this.modalHistory[i].ApprovedBy.split(" ")[0];  // firstname
        }
      }
      this.getUserDetails();
    })
    this.documentService.seeFullHistory = false; //reseting the modal data view type
  }

  getUserDetails(){
    let userMail = localStorage.getItem('user');
    this.mainService.GetUserList().subscribe((x) => {
      this.spinner.hide();
      for(let i=0;i<x.userList.length;i++){
        console.log(x.userList[i].Email,'\n', userMail);
        if(x.userList[i].Email==userMail){
          this.currentUserRoleName = x.userList[i].CompanyWiseUserList[0].RoleName;
          this.currentUserEmail = userMail;
          break;
        }
      }
    });
  }


  isApproved(data, i){
    if( data.ApprovalStatus != 4 && !this.isNull(data.ApprovedBy) ){
      return true;
    }else if( data.ApprovalStatus == 4 && !this.isNull(data.ApprovedBy) && (i+1) < this.modalHistory.length ){
      return true;
    }else{
      return false;
    }
  }

  isRejected(data, i){
    if( data.ApprovalStatus == 4 
        && !this.isNull(data.ApprovedBy) 
        && (i+1) == this.modalHistory.length ){
      return true;
    }else{
      return false;
    }
  }

  isWaiting(data, i){
    if( (data.ApprovalStatus == 2 || data.ApprovalStatus == 1) &&  this.isNull(data.ApprovedBy) ){
      return true;
    }else{
      return false;
    }
  }

  isNull(data){
    if( data == null || data == undefined || data == "" || !data ){
      return true;
    }else{
      return false;
    }
  }

  isNotSameLevel(item, prev_index){
    if( this.isNot_Array_Index_OutOfBounds(this.modalHistory, prev_index) ){
      if( this.modalHistory[prev_index].LevelId != item.LevelId ){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  }

  isNot_Array_Index_OutOfBounds(array, index){
    if( index < array.length && index >= 0 ){
      return true;
    }else{
      return false;
    }
  }

  isLastItem(index){
    if( this.modalHistory.length == (index+1) ){
      return true;
    }else{
      return false;
    }
  }

  progress;
  downloadPdf() {
    let data = {
      documentHistoryModel: this.modalHistory,
      documentDataViewModel: this.modalInfoData
    };
    this.documentService.GenerateApprovalHistoryPdfDocument(data).subscribe((x) => {
          this.downloadFile(x);
    });
  }


downloadFile(data: ArrayBuffer) {
  const blob = new Blob([data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = this.modalInfoData.DocumentBlobName.split('.')[0]+'.pdf';
  link.click();
}


}
