<app-dashboard>
    <app-page-header-title
        mainTitle="Settings"
        subTitle="Home"
        backURL="/">
    </app-page-header-title>
    <div class="headSection">
        <div class="row">
            <div class="col-md-6">
                <p>General</p>
                <ol class="os-listreset xui-panel">
                    <li class="xui-panel--section">
                        <a [routerLink]="['/settings/organization']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow f-ss-14px">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Organisation details</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Address, logo and basic financial information</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    
                    <li class="xui-panel--section">
                        <a [routerLink]="['/settings/user']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow f-ss-14px">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>All Users</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Add, remove or modify users of this organisation</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <li class="xui-panel--section">
                        <a [routerLink]="['/organization/create']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow f-ss-14px">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Add New Organisation</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Add New Organisation</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <!-- <li class="xui-panel--section">
                        <a href="#" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Connected Apps</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Add and manage third party connections to eCount</span>
                                </p>
                            </div>
                        </a>
                    </li> -->
                    <li class="xui-panel--section">
                        <a [routerLink]="['/settings/subscription-list']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow f-ss-14px">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Subscription and billing</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Change plan and update credit card details</span>
                                    <br>
                                    <span>Managed by</span> Isahaque Miah
                                </p>
                                </div>
                            </a>
                        </li>
                        <li class="xui-panel--section" [hidden]="isAdmin == false">
                            <a [routerLink]="['/settings/approval-settings']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                                <div class="xui-u-flex-grow f-ss-14px">
                                    <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                        <span>Approval Settings</span>
                                    </h3>
                                    <p class="xui-text-secondary xui-margin-none">
                                        <span>Add approvers for invoice and bills</span>
                                    </p>
                                </div>
                            </a>
                        </li>
                        <li class="xui-panel--section" [hidden]="isAdmin == false">
                            <a [routerLink]="['/user/user-role-set-up']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                                <div class="xui-u-flex-grow f-ss-14px">
                                    <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                        <span>Role Settings</span>
                                    </h3>
                                    <p class="xui-text-secondary xui-margin-none">
                                        <span>Add or Remove Menu Permission</span>
                                    </p>
                                </div>
                            </a>
                        </li>
                        <li class="xui-panel--section" [hidden]="isAdmin == false">
                            <a [routerLink]="['/settings/return-policy-settings']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                                <div class="xui-u-flex-grow f-ss-14px">
                                    <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                        <span>Return Policy Settings</span>
                                    </h3>
                                    <!-- <p class="xui-text-secondary xui-margin-none">
                                        <span>Add or Remove Menu Permission</span>
                                    </p> -->
                                </div>
                            </a>
                        </li>
                </ol>
            </div>
            <div class="col-md-6">
                <p>Features</p>
                <ol class="os-listreset xui-panel">
                    <li class="xui-panel--section">
                        <a [routerLink]="['/settings/invoice']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow f-ss-14px">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Template settings</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Choose template and hide fields in Bill, Invoice, Quotation Print templates</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <!-- <li class="xui-panel--section">
                        <a href="#" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Payment services</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Add and manage online payment options for your sales invoices</span>
                                </p>
                            </div>
                        </a>
                    </li> -->
                    <li class="xui-panel--section">
                        <a href="#" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow f-ss-14px">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Email settings</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Set a reply-to email address and email template content</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <!-- <li class="xui-panel--section">
                        <a href="#" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Pay runs</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Manage your pay run settings</span>
                                </p>
                            </div>
                        </a>
                    </li> -->
                    <li class="xui-panel--section">
                        <a href="#" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow f-ss-14px">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Expenses</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Manage your expense settings</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <li class="xui-panel--section">
                        <a [routerLink]="['/settings/tax-rate-list']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>VAT/AIT Rates</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>add, edit or delete tax rates</span>
                                </p>
                            </div>
                        </a>
                    </li>
                    <li class="xui-panel--section">
                        <a [routerLink]="['/user-company-history']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Company History</span>
                                </h3>
                            </div>
                        </a>
                    </li>
                    <li class="xui-panel--section">
                        <a [routerLink]="['/delivery-partner-list']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Third Party Delivery Partner</span>
                                </h3>
                            </div>
                        </a>
                    </li>
                    <!-- <li class="xui-panel--section">
                        <a href="#" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Custom contact links</span>
                                </h3>
                                <p class="xui-text-secondary xui-margin-none">
                                    <span>Create shortcuts for your favourite eCount contacts and other tools</span>
                                </p>
                                </div>
                            </a>
                        </li> -->
                    <li class="xui-panel--section" [hidden]="isAdmin == false">
                        <a [routerLink]="['/business-unit']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Business Unit</span>
                                </h3>
                            </div>
                        </a>
                    </li>
                    <li class="xui-panel--section" [hidden]="isAdmin == false">
                        <a [routerLink]="['/settings/document-settings']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Document Settings</span>
                                </h3>
                            </div>
                        </a>
                    </li>
                    <li class="xui-panel--section" [hidden]="isAdmin == false">
                        <a [routerLink]="['/settings/document-version-settings']" class="os-rowlink xui-rowlink xui-padding-vertical xui-padding-horizontal-large xui-u-flex">
                            <div class="xui-u-flex-grow">
                                <h3 class="os-panel--heading xui-margin-none xui-text-deemphasis xui-heading-small">
                                    <span>Document Version Settings</span>
                                </h3>
                            </div>
                        </a>
                    </li>
                </ol>
            </div>

        </div>
    </div>
</app-dashboard>

