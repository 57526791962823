
<app-dashboard>
    <app-page-header-title mainTitle="Subscription" subTitle="Settings" backURL="/settings">
        <div class="full-width text-align-right">
            <button *ngIf="!isTrialActive() && loggedInUserRule?.RoleId == 1" (click)="goToAddMoreUsersPage()" type="button" class="eCount-def-btn ml-4" >Add More Users</button>
            <button *ngIf="!isTrialActive() && loggedInUserRule?.RoleId == 1" type="button" class="eCount-def-btn ml-4" (click)="onRenewClicked()" >Renew</button>
            <button *ngIf="isTrialActive()" type="button" class="eCount-def-btn ml-4" (click)="Purchase()" >Purchase</button>
            <a class="newStyle ml-4" href="https://iquidi.com/pricing/" target="_blank"><u>Packages</u></a> 
        </div>
    
    </app-page-header-title>
    

    <div class="headSection" *ngIf="loggedInUserRule?.RoleId == 1">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="SubscriptionList"
                [columnDefs]="columnDefs"
                [domLayout] = "domLayout"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
                [defaultColDef]="defaultColDef"
            ></ag-grid-angular>
        </div>
    </div>
    <div class="headSection" *ngIf="loggedInUserRule?.RoleId != 1">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="WithoutAdminSubscriptionList"
                [columnDefs]="columnDefs"
                [domLayout] = "domLayout"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
                [defaultColDef]="defaultColDef"
            ></ag-grid-angular>
        </div>
    </div>
</app-dashboard>
