export enum iQuidiActionType {
    Save = 0,
    Submit = 1,
    Approve = 2,
    Update = 3,
    Delete = 4,
    Reject = 5,
    Confirm = 6,
    ResetToDraft = 7,
    AuthoringDocument = 8
}