<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">
       <span *ngIf="fromParent.Id==0">Add New Asset</span> 
       <span *ngIf="fromParent.Id>0">Edit Asset</span> 
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12 text-align-right">
                <div *ngIf="fromParent.Id>0" class="dropdown">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Options</button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="DeleteFixedAssetItem()">Delete</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="assetItemForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-8">
                                <label for="AssetName" style="font-weight: 600;">Asset Name <span
                                        class="required-sign">*</span></label>
                                <input type="text" class="form-control form-input" required formControlName="AssetName">
                            </div>
                            <div class="form-group  col-sm-4">
                                <label for="AssetNumber" style="font-weight: 600;">Asset Number</label>
                                <input type="text" class="form-control form-input" formControlName="AssetNumber">
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-3">
                                <label for="PurchaseDate" style="font-weight: 600;" >Purchase Date</label>
                                <input type="date" class="form-control form-input" formControlName="PurchaseDate" id="fatest" data-date="" data-date-format="D MMM YYYY" (change)="showpurchaseDate($event)">
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="PurchasePrice" style="font-weight: 600;">Purchase Price<span
                                    class="required-sign">*</span></label>
                                <input type="text" appDecimaNumericValidator class="form-control form-input" formControlName="PurchasePrice">
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="WarrantyExpiryDate" style="font-weight: 600;">Warranty Expiry</label>
                                <input type="date" class="form-control form-input" formControlName="WarrantyExpiryDate" id="fatest2" data-date="" data-date-format="D MMM YYYY" (change)="showWarrentyDate($event)">
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="SerialNo" style="font-weight: 600;">Serial No</label>
                                <input type="text" class="form-control form-input" formControlName="SerialNo">
                            </div>
                        </div>
                       
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="AssetTypeId" style="font-weight: 600;">Asset Type</label>
                                <ng-select class="custom" [items]="assetTypeList" #selectItem
                                         [clearable]="false" bindLabel="AssetType"
                                        bindValue="Id" formControlName="AssetTypeId" placeholder=""
                                        (change)="getTypeDetails()">
                                        <ng-template ng-header-tmp>
                                            <a (click)="openItemModal(selectItem)" role="button" aria-haspopup="false"
                                                aria-expanded="false"><span style="color: #007bff;">+ Add
                                                    Asset Type</span></a>
                                                    
                                        </ng-template>
                                </ng-select>
                            </div>

                            <div class="form-group  col-sm-6" *ngIf="fromParent.TypeDetails.Id>0">
                                <label for="AssetTypeId" style="font-weight: 600;">Asset Account</label>
                                <label class="form-control form-input">{{fromParent.TypeDetails.AssetAccount}}</label>
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder" *ngIf="fromParent.TypeDetails.Id>0">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="AssetTypeId" style="font-weight: 600;">Depreciation Account</label>
                                <label class="form-control form-input text-overflow">{{fromParent.TypeDetails.DepreciationAccount}}</label>
                            </div>
                            <div class="form-group  col-sm-6">
                                <label for="AssetTypeId" style="font-weight: 600;">Expense Account</label>
                                <label class="form-control form-input">{{fromParent.TypeDetails.ExpenseAccount}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="Description" style="font-weight: 600;">Description</label>
                                <input type="text" class="form-control form-input" formControlName="Description">
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="DepreciationStartDate" style="font-weight: 600;">Depreciation Start Date<span
                                    class="required-sign">*</span></label>
                                <input type="date" [min]="assetItemForm.value.PurchaseDate" class="form-control form-input" formControlName="DepreciationStartDate" id="fatest1" data-date="" data-date-format="D MMM YYYY" (change)="showStartDate($event)">
                            </div>
                            <div class="form-group  col-sm-6">
                                <label for="DepreciationMethodId" style="font-weight: 600;">Depreciation Method</label>
                                <ng-select [items]="inputHelp.DepreciationMethods" placeholder="Select Account"
                                         [clearable]="false" bindLabel="Name" (change)="setDepreciationType()"
                                        bindValue="Id" formControlName="DepreciationMethodId">
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder" *ngIf="assetItemForm.value.DepreciationMethodId != 1">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-4">
                                <label for="AveragingMethodId" style="font-weight: 600;">Averaging Method</label>
                                <ng-select [items]="inputHelp.AveragingMethods" placeholder="Select Account"
                                         [clearable]="false" bindLabel="Name"
                                        bindValue="Id" formControlName="AveragingMethodId">
                                </ng-select>
                            </div>
                            <div class="form-group  col-sm-2">
                                <label style="font-weight: 600;">&nbsp;</label>
                                <label class="switch">
                                    <input name="IsRatePercent" formControlName="IsRatePercent" class="switch-input"
                                        type="checkbox"  (change)="setDepreciationValueType()"/>
                                    <span class="switch-label" data-on="Rate" data-off="LifeYear"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="RatePercent" style="font-weight: 600;">Rate % </label>
                                <input type="text" appDecimaNumericValidator [readonly]="!assetItemForm.value.IsRatePercent" class="form-control form-input" formControlName="RatePercent">
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="EffectiveLifeYears" style="font-weight: 600;">Effective Life(Yrs)</label>
                                <input type="text" appDecimaNumericValidator [readonly]="assetItemForm.value.IsRatePercent" class="form-control form-input" formControlName="EffectiveLifeYears">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12">
                <button type="button" (click)="saveAssetItem(2)" class="eCount-def-btn  ml-4 p-save-button">Register</button>
                <button type="button" (click)="saveAssetItem(1)" class="eCount-def-btn  ml-4 p-save-button" *ngIf="fromParent.StatusId != 2">Save As Draft</button>
            </div>
        </div>
    </div>
</div>