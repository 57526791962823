import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-update',
  templateUrl: './status-update.component.html',
  styleUrls: ['./status-update.component.css']
})
export class StatusUpdateComponent implements OnInit {

  data:any;

  agInit(params){
    this.data = params.value;
  }
  
  constructor() { }

  ngOnInit(): void {
  }
  updateStatus(status) {
    console.log(status)
    // this.spinner.show();
    // this.IncomeService.deleteInvoice(this.InvoiceData.Id).subscribe((x) => {
    //   if (x.Success) {
    //     this.toaster.success(`Invoice ${this.InvoiceData.InvoicceNo} has been successfully deleted`, 'Success!');
    //     this.router.navigate([this.redirectUrl]);
    //     this.spinner.hide();
    //   } else {
    //     this.toaster.warning(x.Message);
    //     this.spinner.hide();
    //   }
    // });
  }
}
