import { ValueCache } from 'ag-grid-community';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { RouterLinkRendereComponent } from '../../../components/router-link-rendere/router-link-rendere.component';
import { BillsServiceService } from '../../../service/Bill/bills-service.service';
import { CellClickedEvent } from 'ag-grid-community';
import { OperationType } from 'src/app/enum/operation-type';
import { ApprovalHistoryModalService } from 'src/app/service/Approval History Modal/approval-history-modal.service';
import { ApprovalHistoryModalComponent } from 'src/app/components/approval-history-modal/approval-history-modal/approval-history-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bill-overview',
  templateUrl: './bill-overview.component.html',
  styleUrls: ['./bill-overview.component.css']
})
export class BillOverviewComponent implements OnInit {
  allBills: any = [];
  searchText: string;
  gridApi;
  status =  'All';
  totalBillAmount: number;
  totalDueAmount: number;
  totalDiscountAmount: number;
  filteredBills: any = [];
  draftCount: number = 0;
  awaitCount = 0;
  paidCount: number = 0;
  allCount: number = 0;
  awaitingCount: number = 0;
  payable: boolean;
  overDueCount = 0;
  rejectCount = 0;
  contactId: number;
  fileName = 'Bill';
  OperationType = OperationType.BILL;

  billStatus: any = {
    "All": 0,
    "draft": 1,
    "Awaiting-approval": 2,
    "Awaiting-payment": 3,
    "Paid": 4,
    "Repeating": 5,
    "Overdue-payment": 6,
    "rejected": 7
  };

  rowSelection = 'multiple';
  constructor(private attachmentService: AttachmentServiceService, private activeroute: ActivatedRoute, private billService: BillsServiceService, private dataService: DataService, private toastr: ToastrService, private spinner: NgxSpinnerService, private router: ActivatedRoute, private approvalHistoryModalService: ApprovalHistoryModalService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.status = this.router.snapshot.paramMap.get('status');
    console.log(this.status);
    this.activeroute.queryParams.subscribe(params => {
      this.payable = params.IsPayable == undefined ? false : true;
      if (this.payable) {
        this.contactId = params.contactId;
      }
    });
    this.dataService.changeSelectedOrganization.subscribe(x => {
      this.getAllBillList();
    });
    this.getAllBillList();
  }
  domLayout = 'autoHeight'
  rowHeight = 275
  columnDefs = [
    { headerName: 'Serial No.',
      field: 'SerialNo',
      maxWidth: 96.5
    },
    {
      headerName: 'Bill No', field: 'BillNo', minWidth: 120, maxWidth: 250,
      cellRendererFramework: RouterLinkRendereComponent,
      cellRendererParams: {
        inRouterLink: '/expense/bill/',
      }
    },
    { headerName: 'Ref', field: 'ReferenceNo', maxWidth: 200 },
    { headerName: 'To', field: 'CompanyName' },
    { headerName: 'Due Date', field: 'DueDate',  maxWidth: 105 },
    { headerName: 'Bill Amount', field: 'BillAmount', valueFormatter: this.convertPaidAmount, maxWidth: 135 },
    { headerName: 'Discount', field: 'DiscountAmount', maxWidth: 100 },
    { headerName: 'Due', field: 'DueAmount', valueFormatter: this.convertPaidDueAmount, maxWidth: 100 },
    {headerName: 'Modified Date', field: 'UpdatedAt',  maxWidth: 140 },
    { headerName: 'Status', field: 'StatusId',valueFormatter: this.setBillStatus, maxWidth: 100 },
    {
      headerName: 'History', maxwidth: 20, onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data),
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      }
    }
  ];

  columnDefsForDraft = [
    { headerName: 'Serial No.',
      field: 'SerialNo',
      maxWidth: 96.5,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true
    },
    {
      headerName: 'Bill No', field: 'BillNo', minWidth: 120, maxWidth: 250,
      cellRendererFramework: RouterLinkRendereComponent,
      cellRendererParams: {
        inRouterLink: '/expense/bill/',
      }
    },
    { headerName: 'Ref', field: 'ReferenceNo', maxWidth: 200 },
    { headerName: 'To', field: 'CompanyName' },
    { headerName: 'Due Date', field: 'DueDate',  maxWidth: 105 },
    { headerName: 'Bill Amount', field: 'BillAmount', valueFormatter: this.convertPaidAmount, maxWidth: 135 },
    { headerName: 'Discount', field: 'DiscountAmount', maxWidth: 100 },
    { headerName: 'Due', field: 'DueAmount', valueFormatter: this.convertPaidDueAmount, maxWidth: 100 },
    {headerName: 'Modified Date', field: 'UpdatedAt',  maxWidth: 140 },
    { headerName: 'Status', field: 'StatusId',valueFormatter: this.setBillStatus, maxWidth: 100 },
    {
      headerName: 'History', maxwidth: 20, onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data),
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      }
    }
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
   scrollbarWidth: 0,
   resizable: true
  };
  getAllBillList = () => {
    this.spinner.show();
    this.billService.getAllBillList().subscribe((x) => {
      this.allBills = x.data;
      console.log(this.allBills);
      for(let i=0;i<this.allBills.length;i++) {
        this.allBills[i].BillNo = 'Bill-' + this.allBills[i].BillNo;
        if( this.allBills[i].ReferenceNo != null ){
          if(this.allBills[i].ReferenceNo.length == 10 && this.containsOnlyNumbers(this.allBills[i].ReferenceNo)){
            this.allBills[i].ReferenceNo = 'PO-' + this.allBills[i].ReferenceNo; //remove it when reference no issue solve
          }
        }
      }
      this.allBills = this.payable ? this.allBills.filter(xy => xy.ContactId == this.contactId) : this.allBills;
      this.allCount = this.allBills.length;
      this.allBills.forEach(z => {
        z.StatusId == 1 ? this.draftCount += 1 : z.StatusId == this.billStatus.Paid ?
         this.paidCount += 1 : z.StatusId == 3 ?
          this.awaitingCount += 1 : z.StatusId == 6 ? this.overDueCount += 1 : z.StatusId == 2 ? this.awaitCount += 1 :
          z.StatusId == 7 ? this.rejectCount += 1 : 0 ;
      });
      this.reLoadRowData(this.status);
      this.spinner.hide();
    }, err => {
      this.toastr.error("There is an error,please check");
      this.spinner.hide()
    });
  }

  containsOnlyNumbers(referenceNO) {
    return /^[0-9]+$/.test(referenceNO);
  }

  convertPaidDueAmount(value) {
    let amount = value.data.BillAmount - (value.data.PaidAmount + value.data.DiscountAmount);
    return amount.toLocaleString();
  }
  convertPaidAmount(value) {
    let valueData = Number(value.data.BillAmount.toFixed(2))
    return valueData.toLocaleString();
  }
  setBillStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return 'Draft';
      case 2:
        return 'A. Approval';
      case 3:
        return 'A. Payment';
      case 4:
        return 'Paid';
      case 6:
        return 'Overdue';
      case 7:
        return 'Rejected';
      default:
        return '';
    }
  }
  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }
  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }
  onGridReady(event) {
    this.gridApi = event.api;
  }
  renderLinkBillNo(data) {
    console.log(data.data.Id)
    //return `<a class="bold" [routerLink]="['/expense/bill/', ${data.data.Id}]" >${data.data.Id}</a>`

  }
  convertToPDF = () => {
    this.attachmentService.convertToPDF();
  }

  search = () => {
    this.gridApi.setFilterModel(null);
    const searchText = this.searchText.toLowerCase();
    if (searchText.includes(',')) {
      const amount = parseInt(searchText.replace(',', ''), 10);
      this.gridApi.setQuickFilter(null);
      this.gridApi.setRowData(this.filteredBills.filter(row => {
        const billAmount = parseFloat(row.BillAmount);
        const discountAmount = parseFloat(row.DiscountAmount);
        const dueAmount = parseFloat(row.DueAmount);
        return ( Math.floor(billAmount) === amount || Math.floor(discountAmount) === amount ||Math.floor(dueAmount) === amount );
      }));
    } else {
      this.gridApi.setQuickFilter(searchText);
    }
  }
  
  
  

  reLoadRowData = (status: string) => {
    this.status = status;
    let data = this.allBills;

    if (status != 'All') {
      data = this.allBills.filter(x => x.StatusId == this.billStatus[status]);
    }
    this.totalDiscountAmount = 0, this.totalBillAmount = 0, this.totalDueAmount = 0;
    data.forEach(element => {
      this.totalBillAmount += element.BillAmount;
      this.totalDueAmount += element.BillAmount - (element.PaidAmount + element.DiscountAmount);
      this.totalDiscountAmount += element.DiscountAmount;
    });
    this.filteredBills = this.billStatus[status] == this.billStatus.All ? this.allBills :
    this.allBills.filter(x => x.StatusId == this.billStatus[status]);
    for(var i =0 ; i < this.filteredBills.length ; i++)
    {
      this.filteredBills[i].SerialNo = i + 1;
    }

  }

  modalViewFullHistory(data:any){
    this.spinner.show();
    let modalData = {
      ActionNo: data.BillNo,
	    CreatedDate: data.BillDate,
	    DueDate: data.DueDate,
	    Number: data.Id,
	    ApprovalStatus: data.StatusId,
      CustomerName: data.CompanyName
    }
    this.approvalHistoryModalService.saveModalInfoData(modalData);
    const modalRef = this.modalService.open(ApprovalHistoryModalComponent,
    { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.action = this.fileName; //Important! Bill, Invoice, Purchase Order etc not actual file name
    modalRef.componentInstance.OperationType = this.OperationType;
  }

  onCellClicked(e) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "") {
      console.log(e);
    }
  }

  getSelectedBill(): void {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('Please select at least one bill!');
      return;
    }
    let billIds = this.getBillIds(selectedData);
    if(this.status == 'draft') {
      this.submittedBillsData(billIds);
    }
    else {
      this.approvedBillsData(billIds);
    }
  }

  getBillIds(selectedData: any[]): number[] {
    const ids = [];
    for (const bill of selectedData) {
      ids.push(bill.Id);
    }
    return ids;
  }

  submittedBillsData(billIds: number[]): void {
    this.spinner.show();
    this.billService.SubmitBills(billIds).subscribe((x) => {
      this.toastr.success('Bill/s successfully submitted.');
      this.spinner.hide();
      location.reload();
    });
  }

  approvedBillsData(billIds: number[]): void {
    this.spinner.show();
    this.billService.ApproveBills(billIds).subscribe((x) => {
      this.toastr.success('Bill/s successfully approved.');
      this.spinner.hide();
      location.reload();
    });
  }

  isShowingCheckbox(): boolean {
    if(this.status == 'draft' || this.status == 'Awaiting-approval')
      return true;
    else
      return false;
  }

}
