<div class="modal-header">
  <h4 class="modal-title" style="font-size: 19px; margin-top: 20px">
    {{ this.getModalData }}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.close('Cross click')"
  >
    <span aria-hidden="true" style="font-size: 25px">&times;</span>
  </button>
</div>
<div class="modal-body">
    <p>Proceed to Draft Bill</p>
    <a><p><button class="btn btn-sm btn-primary" [routerLink]="['/expense/create-new-bill/']" (click)="activeModal.close('Cross click')">Proceed</button></p></a>
</div>
<!--[routerLink]="['/expense/bill/', this.wareHouseRef]"  -->
