import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MainServiceService } from '../../../service/Main/main-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-all-contact-list-page',
  templateUrl: './all-contact-list-page.component.html',
  styleUrls: ['./all-contact-list-page.component.css']
})
export class AllContactListPageComponent implements OnInit {
  constructor(private mainService: MainServiceService,
              public fb: FormBuilder,
              private router: Router,
              private activeRouter: ActivatedRoute,
              private ngxSmartModalService: NgxSmartModalService,
              private spinner: NgxSpinnerService) { }
  
  private gridApi;
  private gridColumnApi;
  contactList: any = []
  contacts:any=[];
  searchText:string;
  status:string;
  tempData: any;
  BalanceHistoryFrom: FormGroup;
  supplierCount:number=0;
  customerCount:number=0;
  SystemCount = 0;
  allCount:number=0;
  ngOnInit(): void {
    this.initFrom();
  }

  contactStatus: any = {
    "all": 0,
    "customer": 1,
    "supplier": 2,
    'system' : 3,
    'delivery': 4
  }

  columnDefs = [
    { headerName: 'Serial No.', field: 'SerialNo' },
    { headerName: 'Company Name', field: 'CompanyName',cellStyle: {color: 'blue',cursor: 'pointer'}},
    { headerName: 'Name', field: 'PrimaryPersonFullName' },
    { headerName: 'Email', field: 'Email' },
    { headerName: 'Contact No', field: 'ContactNo' },

    { headerName: 'Payable', field: 'TotalPayableBalanceView', cellStyle: {color: 'blue',cursor: 'pointer'} },
    { headerName: 'Receivable', field: 'TotalReceivableAmount', cellStyle: {color: 'blue',cursor: 'pointer'}},
    { headerName: 'Advance', field: 'AdvanceBalanceView', cellStyle: {color: 'blue',cursor: 'pointer'}},
    { headerName: 'Grant Loan', field: 'TotalGrantLoan', cellStyle: {color: 'blue',cursor: 'pointer'}},
    { headerName: 'Receive Loan', field: 'TotalReceiveLoan', cellStyle: {color: 'blue',cursor: 'pointer'}},
    { headerName: 'Balance', field: 'BalanceView', cellStyle: {color: 'blue',cursor: 'pointer'}},

    { headerName: "Action", suppressMenu: true, suppressSorting: true, template: `<span  (click)="editItems()" class=""><img style="height: 20px;" src="../../assets/icons/download.png"></span>` },
  ];


  domLayout ='autoHeight';

  defaultColDef = {
    resizable: true,
    flex: 1,
    sortable: true,
    filter: true,
    cellClass: 'cell-wrap-text',
  };
  
 
  getData=(status:string)=>{
    this.allCount =0;
    this.contactList = []
    if(status == null){
      this.contacts =[];
      this.spinner.hide();
      return;
    }
    this.mainService.GetContactBalance().subscribe((x) => {
      this.supplierCount = 0;
      this.customerCount = 0;
      this.SystemCount = 0;
      this.spinner.hide();
      this.contactList = [...x, ...this.contactList];
      this.contactList.map(c => {
        c.IsCustomer == true ? this.customerCount += 1 : this.customerCount += 0;
        c.IsSupplier == true && c.IsSystemGenerated == false ? this.supplierCount += 1 : this.supplierCount += 0 ;
        c.IsSystemGenerated == true ? this.SystemCount += 1 : this.SystemCount += 0;
      });
      this.reLoadRowData(status);
    }, (err) => {
      this.spinner.hide();
    });
  }

  onGridReady(event) {
    this.spinner.show()
    this.gridApi = event.api;
    this.gridApi.setHeaderHeight(35);
    this.gridColumnApi = event.columnApi;
    let type = this.activeRouter.snapshot.paramMap.get("type");
    this.getData(type);
  }
  HideRawColumn(b)
  {
    console.log(b);
    const columns = this.gridColumnApi.getAllColumns();
    this.gridApi.sizeColumnsToFit();
    const valueColumn = columns.filter(column => column.getColDef().headerName === 'Receivable')[0];
    const valueColumn1 = columns.filter(column => column.getColDef().headerName === 'Advance')[0];
    const valueColumn2 = columns.filter(column => column.getColDef().headerName === 'Grant Loan')[0];
    const valueColumn3 = columns.filter(column => column.getColDef().headerName === 'Receive Loan')[0];
    const valueColumn4 = columns.filter(column => column.getColDef().headerName === 'Balance')[0];
    this.gridColumnApi.setColumnsVisible([valueColumn, valueColumn1, valueColumn2, valueColumn3, valueColumn4], b);
    console.log(valueColumn);
    this.gridApi.sizeColumnsToFit();

  }
  onRowClicked(e) {
    const colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Action") {
      this.editItems(e);
    }
    else if (colHeaderName  == 'Payable'){
      this.router.navigate(['expense/bill-overview/All'], { queryParams: { IsPayable: true, contactId: e.data.Id }});
    }
    else if (colHeaderName  == 'Receivable'){
      this.router.navigate(['income/invoice-overview/All'], { queryParams: { IsReceivable: true, contactId: e.data.Id }});
    }
    else if (colHeaderName  == 'Advance'){
      this.router.navigate(['accounting/advance/advance-list/Active'], { queryParams: { IsAdvance: true, contactId: e.data.Id }});
    }
   else if (colHeaderName  == 'Balance'){
      this.openBalanceHistoryPopup(e.data);
    }
    else if (colHeaderName == 'Company Name')
    {
      this.router.navigate(['business-partner/business-partner-report', e.data.Id]);
    }
  }

  editItems(e) {
    this.router.navigate(['contacts/details-contact', e.data.Id]);
  }
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
    suppressQuotes: '', 
    columnSeparator: '',
    customHeader: '',
    customFooter: '',};
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }
  reLoadRowData = (status: string) => {
    console.log(status);
    status == 'system' ? this.HideRawColumn(false) : this.HideRawColumn(true);
    this.contacts = this.contactStatus[status] == this.contactStatus.all ? this.contactList.filter(con => con.IsSystemGenerated === false)
    : this.contactStatus[status] == 
    this.contactStatus.supplier ?
    this.contactList.filter(sup => sup.IsSupplier == true && sup.IsSystemGenerated == false) :
    this.contactStatus[status] == this.contactStatus.customer ?
    this.contactList.filter(con => con.IsCustomer == true) : this.contactStatus[status] == this.contactStatus.system ?
    this.contactList.filter(con =>  con.IsSystemGenerated == true) :
    this.contactList.filter(con =>  con.IsDeliveryPartner == true);
    for(var i =0 ; i < this.contacts.length ; i++)
    {
      this.contacts[i].SerialNo = i + 1;
    }
   
  }
  initFrom = () => {
    this.BalanceHistoryFrom = this.fb.group({
      PayableAmount: [0],
      ReceivableAmount: [0],
      AdvanceAmount: [0],
      TotalAmount: [],
      GrantLoan: [0],
      ReceiveLoan: [0]
    });
  }
  openBalanceHistoryPopup = (x) => {
    console.log(x);
    this.BalanceHistoryFrom.get('PayableAmount').patchValue(x.TotalPayableAmount);
    this.BalanceHistoryFrom.get('ReceivableAmount').patchValue(x.TotalReceivableAmount);
    this.BalanceHistoryFrom.get('AdvanceAmount').patchValue(x.AdvanceBalanceView);
    this.BalanceHistoryFrom.get('GrantLoan').patchValue(x.TotalGrantLoan);
    this.BalanceHistoryFrom.get('ReceiveLoan').patchValue(x.TotalReceiveLoan);
    this.BalanceHistoryFrom.get('TotalAmount').patchValue(x.BalanceView);
    this.ngxSmartModalService.create('balanceHistory', 'content').open();
  }

}
