import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProjectService } from '../../../service/Project/project.service';
import { CreateProjectComponent } from '../create-project/create-project.component';
import { ChartDataSets, ChartOptions,ChartType  } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {AlertService} from '../../../service/Alert/alert.service';
@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.css']
})
export class ProjectOverviewComponent implements OnInit {

  public projectList : any = [];
  num: number=30;
  public statusWiseProjectList: any =[];
  public statusCountModel: any ={TotalDraft:0,TotalInProgress:0,TotalClosed:0};
  public activeStatusId: number =2;
  constructor(private pService:ProjectService,
    private toaster:ToastrService, 
    private spinner: NgxSpinnerService,
    private router:Router,
    private alertService: AlertService,
    private modalService:NgbModal) {

     }

  ngOnInit(): void {
    
    this.getProjectList();
    this.num=(this.num*100)/35;

  }

 private getProjectList = () => {
    this.spinner.show()
    
    this.pService.GetProjectList().subscribe((x) => {
      this.projectList = x.Data;
      this.statusCountModel=x.StatusCount;
      this.SetFilterData(this.activeStatusId);
      
      this.spinner.hide()
    },(err)=>{
      this.toaster.error("There is an error,please check");
      this.spinner.hide();
    })
  };
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    tooltips: {
      enabled: false,
     
    },
    elements: {
        line: {
            tension: 0 // disables bezier curves
        }
	},
  };

  pieChartLabels: Label[] = [];

  pieChartData: number[] = [78.09, 20.95];

  pieChartType: ChartType = 'horizontalBar';

  pieChartLegend = false;

  pieChartPlugins = [];

  pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)'],
    },
  ];

  public CreateNewProject=()=>{
    const modalRef = this.modalService.open(CreateProjectComponent,{ size: 'md',backdrop: 'static', keyboard : false});
    modalRef.componentInstance.fromParent = {Id:0,Title:"",StatusId:1,ContactId:"",Deadline:"",EstimatedCost:"",WinPrice:""};
    modalRef.result.then((result) => {
                   this.AddItemToProjectList(result);
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  };

  public EditProject=(item: any)=>{
    const modalRefEdit = this.modalService.open(CreateProjectComponent,{ size: 'md',backdrop: 'static', keyboard : false});
    modalRefEdit.componentInstance.fromParent = item;
    modalRefEdit.result.then((result) => {
                   this.UpdateItemToProjectList(result);
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  };

 private AddItemToProjectList=function(x: any) {
   if(x.Success){
    this.statusCountModel=x.StatusCount;
    this.projectList.push(x.Data);
    this.SetFilterData(this.activeStatusId);
   }
}

private UpdateItemToProjectList=function(result: any) {
  var d=result.Data;
  if(result.Success){
    this.statusCountModel=result.StatusCount;
    for(let i=0;i<this.projectList.length;i++){
      if(this.projectList[i].Id==d.Id){
        this.projectList[i]=d;
        this.SetFilterData(this.activeStatusId);
      }
    }
  }
}

  public SetFilterData=function(a : number){
   this.statusWiseProjectList= this.projectList.filter(y=>y.StatusId == a);
   this.activeStatusId=a;
  }

  public DetailsProject=function(item:any){
    this.router.navigate(['project/details/'+item.Id]);
  }

  public CopyProject=function(item:any){
    this.spinner.show();
    this.pService.CopyProject(item.Id).subscribe((x)=>{
      if(x.Success){
        this.toaster.success("Project copied successfully.");
        this.AddItemToProjectList(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err=>{
        this.toaster.error('ooh, something went wrong !');
    });
  }

  confirmDeletion(item)
  {
    this.alertService.confirm('Do you want to delete  this Project?This process can not be undone.', '', `Project Name: ${item.Title}`, '')
    .then((confirmed) => {
      if (confirmed){
        this.DeleteProject(item);
      }

      },
      err => {
    }
    ).catch(() =>
    {this.spinner.hide();
    });

  }
  public DeleteProject=function(item:any){
    this.spinner.show();
    this.pService.DeleteProject(item.Id).subscribe((x)=>{
      if(x.Success){
        this.toaster.success("Project deleted successfully.");
        this.statusCountModel=x.StatusCount;
        for(let i=0;i<this.projectList.length;i++){
          if(this.projectList[i].Id==item.Id){
            this.projectList.splice(i,1);
          }
        }
        this.SetFilterData(this.activeStatusId);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err=>{
        this.toaster.error('ooh, something went wrong !');
    });
  }

  


}
