<app-dashboard>
    <app-page-header-title mainTitle="{{'SIDEBAR.profitloss'|translate}}" subTitle="Home" backURL="/">
        

    </app-page-header-title>
   
        
        <div class="row col-md-12 mrl">
            
            <div class="form-group col-sm-2">
                    <input class="form-control" type="date" [(ngModel)]="fromDate" id="pltest" data-date="" data-date-format="D MMM YYYY"  (change)="show($event)">
            </div>
        
            <div class="form-group col-sm-2  ml">
                    <input class="form-control" type="date" [(ngModel)]="toDate" id="pltest1" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)">
            </div>
            <div class="col-md-4 f-ss-14px">
                <button type="button" (click)="reportGenerate()" class="eCount-def-btn">Generate Report</button>
            </div>
        </div>
       
    

    <div id="printProfitAndLossReport" class="customReportPage">
        <div class="report-header row" style="margin-bottom: 10px;">
            <div class="col-md-8">
            <div>
               <p class="txtsize ml-1">Profit and Loss</p>
           </div>
           <div class="row ml-1">
               <span>{{companyName}}</span>
               
               <p class="f-ss-14px"><span class="ml-2 mr-2 f-ss-14px">|</span>For the period {{convertDateIntoDisplay(fromDate)}} to {{convertDateIntoDisplay(toDate)}}</p>
               
           </div>
           
           </div>
           <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
               <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
           </div>
   
           </div>

        <div class="AccountSection f-ss-14px" *ngFor="let item of reportData.Data">
            <div class="paddingBottom d-flex">
                <div class="tableHeaderColumn2 dateColumn">
                    <h1 class="runningBalanceText">{{item.AccountName}}</h1>
                </div>
            </div>

            <div class="d-flex normalRowSpaceing" *ngFor="let itemInner of item.Items">
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText">{{itemInner.AccountName}}</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="runningBalanceText  text-link" (click)="openAccountTransaction(itemInner.AccountId)">{{itemInner.BalanceView}}</span>
                </div>
            </div>

            <div class="d-flex normalRowSpaceing">
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText font-weight-bold colorBlack">Total {{item.AccountName}}</span>
                </div>
                <div class="tableHeaderColumn2 ">
                    <span class="float-right">{{item.TotalView}}</span>
                </div>
            </div>

            <div class="d-flex paddingTopBottom" *ngIf="item.HeadTypeId == '2'">
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText">Gross Profit</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="runningBalanceText float-right">{{reportData.GrossProfitValue}}</span>
                </div>
            </div>
        </div>

        <div class="AccountSection f-ss-14px">
            <div class="d-flex paddingTopBottom">
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText">Net Profit</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="runningBalanceText float-right">{{reportData.NetProfitValue}}</span>
                </div>
            </div>
        </div>
    </div>
</app-dashboard>