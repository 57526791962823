<div class="modal-header" style="background-color: #F0F8FF; padding-bottom: 10px;">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<div class="modal-body modalBody">
    <div class="pb-4 m-2">
        <div class="row">
            <div class="col-md-12">
                <div class="row mm-5px pbp-20px">
                    <div class="col-sm-12" style="margin-top: 10px">
                        <form [formGroup]="DocumentUnlockForm">
                            <div class="row">
                                <div class="col-sm-12">
                                    <p class="font-weight-bold">Documents will be locked for these users until you decide to unlock it</p>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="col-md-4">User Role</div>
                                <div class="col-md-6">Email</div>
                            </div>
                            <div formArrayName="DocumentLockDetails" 
                                *ngFor="let details of DocumentUnlockForm?.get('DocumentLockDetails')['controls']; let i = index">
                                <div [formGroupName]="i">
                                    <div class="invoiceGridItemRow d-block pb-1">
                                        <div style="margin-left: 0px; margin-top: 2px" class="row">
                                            <div class="col-md-4 margin-top-5">
                                                <ng-select class="auto-grow" 
                                                [items]="userRoleList" [clearable]="false" 
                                                bindLabel="Name" bindValue="Id" 
                                                formControlName="UserId"
                                                (change)="changeUserRole (details, i)">
                                            </ng-select>
                                            </div>
                                            <div class="col-md-6 margin-top-5">
                                                <ng-select class="auto-grow" 
                                                [items]="userEmailList" [clearable]="true" 
                                                bindLabel="Name" bindValue="Id" 
                                                formControlName="UserEmailId" 
                                                (change)="changeUserEmail(details, i)" [searchable]="false" (open)="setRoleWiseEmail(details)">
                                            </ng-select>
                                            </div>
                                            <div class="col-md-2 margin-top-5 d-flex" *ngIf="i > 0">
                                                <div style="padding: 7px 10px; color: #c7c2c2">
                                                    <div class="dropdown mr-4">
                                                        <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                            <i class="icon-more float-right"></i>
                                                        </div>
                                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="cursor: pointer">
                                                            <div class="productRow pb-2" (click)="removeDocumentDetails(i, details)">
                                                                <span class="lead1 ml-4">Delete</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row addItem">
                                <div class="BottomAddItem">
                                    <div class="dropdown mr-4">
                                        <a class="nav-link" id="navbarDropdown"
                                            href="#" role="button" data-toggle="dropdown" 
                                            aria-haspopup="false" aria-expanded="false" 
                                            (click)="addBlankDocumentDetails()">
                                            <img style="height: 30px" class="languageImg" src="../../../assets/image/addIcon.png"/> 
                                            Add New User
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="full-width text-align-right">
                    <button type="button" class="eCount-def-btn mt-4" (click)="update()">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>