<app-dashboard>

    <div class="headSection marginNegative5">
        <div class="row borderBottom1Px pb-2">
            <div class="col-md-12 row" style="padding: 0px 15px;">
                <div class="d-block col-md-3">
                    <div class="pointer" (click)="backToPreviousPage()">
                        <i class="icon-arrow-left52 mr-2 subTextTitle"></i>
                        <span class="subTextTitle f-ss-14px" *ngIf="this.steps == 0">{{'Owner.allBusinessOwner'| translate}}</span>
                        <span class="subTextTitle f-ss-14px" *ngIf="this.steps > 0">Back</span>
                    </div>
                    <span class="mainTextTitle f-ssb-14px" style="width: 220px;">{{'Owner.createNewOwner'| translate}}</span>

                        
                </div>
                <div class="col-md-9 mt-2 text-align-right">
                    <button type="button" (click)="SaveOwnerInfo()" class="eCount-def-btn mr-4" *ngIf = "this.ownerForm.value.Id == 0">{{'Owner.CreateOwner.save' | translate}}</button>
                    <button type="button" (click)="CancelAndContinue()" class="eCount-def-btn mr-4" *ngIf = "this.steps > 0 && isOwnerCreated">{{'Owner.CreateOwner.cancel' | translate}}</button>
                    <!-- <button type="button" (click)="SaveAndContinue()" class="eCount-def-btn mr-4" *ngIf = "this.steps > 0">{{'Owner.CreateOwner.continue' | translate}}</button> -->
                    <button type="button" (click)="SaveAndContinue()" class="eCount-def-btn mr-4">Save & Exit</button>
                </div>
            </div>

    </div>
    </div>
    <div class="pt-4 row" [ngClass]="{'stepLeft': steps > 0 }">
        <form [formGroup]="ownerForm" class="d-flex w-100">
            <div class="col-6 f-ss-14px">
                
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.name'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="Name" placeholder="Name"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.phone'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="Phone" appDecimaNumericValidator placeholder="Phone"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.email'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="Email" (keypress)="disallowSpace($event)" placeholder="Email"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.role'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <select required class="form-control" style="width: 100%;" formControlName="RoleId">
                            <option value="1" >Admin</option>
                            <option value="2" >Accountant</option>
                            <option value="3" >Auditor</option>
                        </select>
                    </div>
                </div>
                <div class="form-group d-flex" *ngIf="this.steps > 0">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.ownerShip'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <div>
                            <input type="number"  formControlName="OwnerShipPercentage" (change)="validateOwnerShip()" appDecimaNumericValidator placeholder="%"   class="form-control inputSection" />
                        </div>
                    </div>
                </div>   
                <div class="form-group d-flex" *ngIf="this.steps == 0">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.ownerShip'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <div>
                            <input type="number"  formControlName="OwnerShipPercentage" (change)="validateOwnerShip()" appDecimaNumericValidator placeholder="%"   class="form-control inputSection" />
                        </div>
                    </div>
                </div>   
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.address'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <textarea type="text"   formControlName="Address" style="height: 145px;" rows="3" placeholder="Address"  class="form-control inputSection"></textarea>
                    </div>
                </div>
                <!-- <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.capital'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="ShareCapital"  class="form-control inputSection" readonly />
                    </div>
                </div> -->
                <!-- <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.isActive'| translate }}</span>
                    </div>
                    <div class="col-8">
                        <label class="switch">
                            <input name="IsCustomer" formControlName="IsActive" class="switch-input"
                                type="checkbox" />
                            <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                </div> -->
            </div>
            <!-- <div class="col-6 f-ss-14px">
                
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.address'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <textarea type="text"   formControlName="Address" style="height: 145px;" rows="3" placeholder="Address"  class="form-control inputSection"></textarea>
                    </div>
                </div> -->
                <!-- <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.ownerShip'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <div>
                            <input type="number"  formControlName="OwnerShipPercentage" (change)="validateOwnerShip()" appDecimaNumericValidator placeholder="%"   class="form-control inputSection" />
                        </div>
                    </div>
                </div>                 --> 
                <!-- <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.loan'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <div>
                            <input type="text"  formControlName="GrantLoan"   class="form-control inputSection"  readonly/>
                        </div>
                    </div>
                </div>    
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.receiveLoan'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <div>
                            <input type="text"  formControlName="ReceiveLoan"   class="form-control inputSection" readonly/>
                        </div>
                    </div>
                </div>    --> 
            <!-- </div>  -->
        </form>
    </div>
    <!-- <div class="contianer mt-4">
        <div  class="row nav nav-tabs" id="myTab" role="tablist">
            <div class="col-md-2 nav-item nav-link active" id="shipment-tab" data-toggle="tab" (click)="openShareTab(true)" role="tab" aria-controls="shipment" aria-selected="true">
              <a class="" >Add Share Capital</a>
            </div>
            <div class="col-md-2 nav-item nav-link" id="expense-tab" data-toggle="tab" (click)="openLoanTab(true)" role="tab" aria-controls="expense" aria-selected="false">
              <a class="">Add Loan</a>
            </div>
       
        <div class="col-md-8 text-align-right" *ngIf= "isShareActive && this.ownerForm.value.Id > 0">
            <button class="eCount-def-btn mb-2" (click)="createNewItem()">Add Share Capital</button>
        </div>
        <div class="col-md-8 text-align-right" >
            <button class="eCount-def-btn mb-2" (click)="createNewItems()">Add Loan</button>
        </div>
    </div> 
</div>  -->
<!-- <div class="headSection" *ngIf = "isShareActive">
    <div class="row">
        <ag-grid-angular
            style="font-size: 14px;"
            class="ag-theme-balham col"
            [rowData]="shareCapitalList"
            [columnDefs]="columnDefs"
            [domLayout] = "domLayout"
            (gridReady)="onGridReady($event)"
            [defaultColDef]="defaultColDef"
        ></ag-grid-angular>
    </div>
</div> -->
<div>
    <p class="ml-2 mt-3 row" style="font-weight: 600;" *ngIf="steps > 0">Owner List <i
          style="color: #0078c8; font-size: 17px"
          class="float-right material-icons text-color-black ml-2"
          tooltip="You can edit your owner info from the owner list."
          tooltip-class="tooltip-feedback"
          >help</i>
        </p>
</div>
<div class="pt-3" id="owner" *ngIf="steps > 0">
    <ag-grid-angular class="ag-theme-alpine"  style="width: 100%;height: 220px;" #topGrid id="topGrid"  [rowData]="ownerList"
    [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"  [defaultColDef]="defaultColDef">
</ag-grid-angular>
</div>

</app-dashboard>

