import { Component, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import { ReportServiceService } from '../../../service/Report/report-service.service'
import { ToastrService } from 'ngx-toastr';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-trail-balance',
  templateUrl: './trail-balance.component.html',
  styleUrls: ['./trail-balance.component.css']
})

export class TrailBalanceComponent implements OnInit {
  toDate: string="";
  fromDate: string="";

  trialBalanceData:any={};
  
  companyName: string= "";
  constructor(private spinner: NgxSpinnerService, 
    private reportService: ReportServiceService, 
    private router:Router,
    private toaster: ToastrService) { }

  ngOnInit(): void {
   this.initialiseOrganization();
   var date = new Date();
   this.toDate = date.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + date.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + date.toLocaleDateString('en-GB', { day: '2-digit'});
   this.fromDate = date.getFullYear()+"-01-01";
   this.show(this.toDate);
  }
  reportGenerate = () => {
    if (!this.toDate) {
      this.toaster.warning('To Date and From date are required !');
      return;
    }
    let data = {
      toDate: this.toDate
    }
    this.spinner.show();
    this.reportService.GetTrialBalance(data).subscribe((x) => {
      this.spinner.hide();
      this.trialBalanceData=x;
    })
  }

    /**
   * openAccountTransaction
id:number   */
public openAccountTransaction(id:number) {
  this.router.navigate(['reports/account-transactions'], 
  { 
    queryParams: 
    { 
      accountId:id,
      fromDate:this.fromDate,
      toDate:this.toDate,
      returnUrl:"trail-balance" 
  } 
});
}

  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  initialiseOrganization(){
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.companyName = userInfo.SelectedCompany.Name;
    }
  }
  show(e) {
    const value = this.toDate;
    $("#tbtest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  convetToPDF = () => {
    var data = document.getElementById('printGeneralReport');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      let toDate=this.convertDateIntoDisplay(this.toDate);
      var DocumentName = `Trail Balance report at ${toDate}.pdf`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    });
  }
}
