<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">Storage Items Consumption</h4>
    <button type="button" class="close" aria-label="Close"(click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body sidebar-scroll" style="padding: 0em 1em 1em 1em!important;" id="sidebars">
<form [formGroup]="ConsumptionForm" class="f-ss-14px">
<div class="mt-4 col-sm-12">
    <table class="table table-bordered">
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Remaining Quantity</th>
            <th>Used Quantity</th>
            <th></th>
          </tr>
        </thead>
        
        <tbody formArrayName="ConsumptionItems" *ngFor="let item of ConsumptionForm.get('ConsumptionItems')['controls']; let i = index;">
        <tr [formGroupName]="i">
        <td class="grid-cell">
            <input type="text" class="form-control" formControlName="ItemCode"  appDecimaNumericValidator readonly>
        </td>
        <td class="grid-cell">
            <input type="text" class="form-control" formControlName="ItemName"  appDecimaNumericValidator readonly>
        </td>
        <td class="grid-cell">
            <input type="text" class="form-control" formControlName="StockQuantity"  appDecimaNumericValidator readonly>
        </td>
        <td class="grid-cell">
            <input type="number" class="form-control"  formControlName="ConsumptionQuantity" min="0" [ngClass]="{'submitted': ifSubmit == true && item.value.ConsumptionQuantity > item.value.StockQuantity}" (change)="ConsumptionQuantity(item, i)" >
        </td>
          </tr>
        </tbody>
    
   
      </table>
</div>

<div class="col-sm-12 row">
    <div class="form-group col-sm-6">
        <label>Notes</label>
         <textarea  rows="5" type="text" class="form-control"   formControlName="Notes"></textarea>
    </div>
<div class="col-sm-6">
    <button type="button" class="eCount-def-btn ml-2 mb-4 float-right" (click)="saveConsumption()">Save</button>
</div> 
</div>
</form>
</div>
