<app-dashboard>
  <app-page-header-title
    mainTitle="{{ 'billOverview.billOverview' | translate }}"
    subTitle="Home"
    backURL="/"
  >
    <div class="full-width text-align-right">
      <input
        type="text"
        [(ngModel)]="searchText"
        (ngModelChange)="search()"
        placeholder="search"
        class="searchBoxOfBillOverview"
      />
      <button 
        type="button" 
        *ngIf="status == 'draft' && filteredBills?.length != 0" 
        (click)="getSelectedBill()" 
        class="eCount-def-btn ml-4">
        Submit
      </button>
      <button 
        type="button" 
        *ngIf="status == 'Awaiting-approval' && filteredBills?.length != 0" 
        (click)="getSelectedBill()" 
        class="eCount-def-btn ml-4">
        Approve
      </button>
      <button
        type="button"
        routerLink="/expense/create-new-bill"
        class="eCount-def-btn ml-4"
      >
        {{ "billOverview.createNewBill" | translate }}
      </button>
      <button
        type="button"
        (click)="convertToPDF()"
        class="eCount-button3 ml-4"
      >
        {{ "billOverview.export" | translate }}
      </button>
    </div>
  </app-page-header-title>
  <ul class="nav nav-tabs justify-content-center">
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="reLoadRowData('All')"
        [routerLinkActive]="['active']"
        routerLink="/expense/bill-overview/All"
        >{{ "billOverview.all" | translate }}</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="reLoadRowData('draft')"
        [routerLinkActive]="['active']"
        routerLink="/expense/bill-overview/draft"
        >{{ "billOverview.draft" | translate }} ({{ draftCount }})</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="reLoadRowData('Awaiting-approval')"
        [routerLinkActive]="['active']"
        routerLink="/expense/bill-overview/Awaiting-approval"
        >{{ "billOverview.awaitingApproval" | translate }} ({{ awaitCount }})</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="reLoadRowData('Awaiting-payment')"
        [routerLinkActive]="['active']"
        routerLink="/expense/bill-overview/Awaiting-payment"
        >{{ "billOverview.awaitingPayment" | translate }} ({{
          awaitingCount
        }})</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="reLoadRowData('rejected')"
        [routerLinkActive]="['active']"
        routerLink="/expense/bill-overview/rejected"
        >{{ "billOverview.rejected" | translate }} ({{
          rejectCount
        }})</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="reLoadRowData('Overdue-payment')"
        [routerLinkActive]="['active']"
        routerLink="/expense/bill-overview/Overdue-payment"
        >{{ "billOverview.billOverdue" | translate }} ({{
          overDueCount
        }})</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="reLoadRowData('Paid')"
        [routerLinkActive]="['active']"
        routerLink="/expense/bill-overview/Paid"
        >{{ "billOverview.paid" | translate }} ({{ paidCount }})</a
      >
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link" style="padding: 7px 1rem;" (click)="reLoadRowData('Repeating')" [routerLinkActive]="['active']" routerLink="/expense/bill-overview/Repeating">Repeating</a>
    </li> -->
  </ul>
  <div class="row pt-3 agwd" id="printBills" *ngIf="!isShowingCheckbox()">
    <ag-grid-angular
      style="font-size: 14px"
      class="ag-theme-balham col"
      [rowData]="filteredBills"
      [columnDefs]="columnDefs"
      [domLayout]="domLayout"
      [defaultColDef]="defaultColDef"
      [pagination]="true"
      [paginationPageSize]="50"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

  <div class="row pt-3 agwd" id="printBills" *ngIf="isShowingCheckbox()">
    <ag-grid-angular
      style="font-size: 14px"
      class="ag-theme-balham col"
      [rowData]="filteredBills"
      [columnDefs]="columnDefsForDraft"
      [domLayout]="domLayout"
      [defaultColDef]="defaultColDef"
      [rowSelection]="rowSelection"
      [suppressRowClickSelection]="true"
      [pagination]="true"
      [paginationPageSize]="50"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
  <div class="row d-flex grid ml-0 mr-0">
    <div class="col-sm-5">Total</div>
    <div class="col-sm-1 ml-3 pl-5">{{ totalBillAmount | currency:'':'' }}</div>
    <div class="col-sm-1 ml-5 pl-3">{{ totalDiscountAmount | currency:'':'' }}</div>
    <div class="col-sm-1 ml-2">{{ totalDueAmount | currency:'':'' }}</div>
  </div>
</app-dashboard>
