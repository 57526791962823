import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SpendmoneyServiceService } from '../../../service/SpendMoney/spendmoney-service.service'
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service'
import { SpendMoneyItem, createSpendMoney } from '../../../models/spendMoney.model'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { MainServiceService } from '../../../service/Main/main-service.service'
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgbModal, NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseProductComponent } from '../../Products/purchase-product/purchase-product.component';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';


@Component({
  selector: 'app-spend-money',
  templateUrl: './spend-money.component.html',
  styleUrls: ['./spend-money.component.css']
})

export class SpendMoneyComponent implements OnInit {
  allSupplier: any = [];
  PurchaseableItems: any = []
  allChartOfAccount: any = []
  bankList: any = [];
  spendMoneyTypeList: any =[];
  taxTypeList: any = [];
  taxRateList: any = [];
  selectedSupplier: number;
  selectedBank:number;
  selectedSpendMoneyTypeId:number;
  showBankWarningDiv: boolean = true;
  spendMoneyData: createSpendMoney;
  spendMoneyItem: SpendMoneyItem[] = [];
  selectedDate: any = new Date();
  selectedDueDate: Date;
  totalPrice: number = 0.00;
  ReferenceNo: string="";
  loading = false;
  isItemListUpdate = false;
  isDescriptionNull: boolean= true;
  pushedItem: boolean=false;
  countPushedItem: number=0;
  TaxTypeId: null;

  TaxRateId: any;
  AdvanceTaxRateId: any;
  selectedTaxType: number = 3;

  subTotalAmount: number = 0.00;
  taxAmount: number = 0.00;
  advanceTaxAmount: number = 0.00;

  constructor(private spendMoneyService: SpendmoneyServiceService,
              private accountingService: AcountingServiceService,
              private toaster: ToastrService,
              public ngxSmartModalService: NgxSmartModalService,
              private dataService: DataService,
              private mainService: MainServiceService,
              private router: Router,
              private modalService: NgbModal,
              private spinner: NgxSpinnerService,
              private settingService: SettingsServiceService ) { }

  ngOnInit(): void {
    this.getFormData();
    this.show(new Date());
    this.dataService.change.subscribe(x => {
      this.isItemListUpdate = x;
      this.getAllSaleableItem();
    });
  }

  getFormData = () => {
    this.getAllSupplier();
    this.getAllSaleableItem();
    this.getAllChartOfAccount();
    this.getBankList();
    this.getSpendMoneyTypeList();
    this.getTaxTypeList();
    this.getTaxRateList();
  }

  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
      if (this.taxTypeList.length >= 2) {
        this.TaxTypeId = this.taxTypeList[2].Id;
      }
    });
  }

  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({ "Id": "", "Name": "Select" });
    });
  }

  forMatDate() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.selectedDate = firstDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + firstDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + firstDay.toLocaleDateString('en-GB', { day: '2-digit'});
  }
  getBankList(){
    this.bankList = [];
    this.mainService.GetAllBank().subscribe((x)=>{
      this.spinner.hide()
      this.bankList = x;
      if(this.bankList.length == 0){
        this.showBankWarningDiv = false;
      }
    })
  }
  defaultAccountSettings = (item) => {
    console.log(item);
    const account = this.allChartOfAccount.filter(x => x.AccountCode === '429');
    console.log(account);
    item.map((x) => {
      if (x.AccountId == null) {
      x.AccountId = account[0].Id ;
      }
    }) ;
  }
  getSpendMoneyTypeList(){
    this.spendMoneyTypeList = [];
    this.spendMoneyService.GetSpendMoneyTypeList().subscribe((x)=>{
      this.spinner.hide()
      console.log(x)
      this.spendMoneyTypeList = x.filter(y=>y.Id==1);
      if(this.spendMoneyTypeList !=null){
        this.selectedSpendMoneyTypeId=this.spendMoneyTypeList[0].Id;
      }
    })
  }

  onSpendMoneyTypeChange(){

  }

  getAllSupplier = () => {
    this.mainService.GetAllSupplier().subscribe((x: []) => {
      this.allSupplier = x;
    });
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : false,
      IsSupplier : true,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
        this.selectedSupplier = x.Data.Id;
        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.allSupplier.push(x["Data"]);
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }


  getAllSaleableItem = () => {
    this.spendMoneyService.GetPurchaseableItems().subscribe((x) => this.PurchaseableItems = x );
  }

  getAllChartOfAccount = () => {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      this.allChartOfAccount = x.ExpenseList.filter(xy => xy.AccountCode !== '900');
      this.createBlankItem();
    });
  }

  Bills = []

  changespurchaseableItems = (index) => {
    for (let i = 0; i < this.PurchaseableItems.length; i++) {
      if (this.PurchaseableItems[i].Id == this.spendMoneyItem[index]['ItemId']) {
        this.spendMoneyItem[index]['ItemId'] = this.PurchaseableItems[i]['Id'];
        this.spendMoneyItem[index]['AccountId'] = this.PurchaseableItems[i]['PurchaseAccountId'];
        this.spendMoneyItem[index]['Quantity'] = 1;
        this.spendMoneyItem[index]['ItemDescription']=this.PurchaseableItems[i]['PurchaseDescription'];
        this.spendMoneyItem[index]['UnitPrice'] = this.PurchaseableItems[i]['PurchasePrice'];
        this.updateDataInvoiceItem(this.spendMoneyItem[index]['ItemId']);
      }
    }
  }
  updateDescriptionSpendItem=(index)=>{
    this.spendMoneyItem.map((x,i) => {
      if (x.ItemId == null && i == index)
      {
         x.AccountId = this.allChartOfAccount.filter(c => {return c.AccountCode == 429 })[0].Id; //Expense Account
      }
    })
  }

  changeItemPosition(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.Bills, event.previousIndex, event.currentIndex);
  }
  changeSelectRowInItem = (itemId, index) => {
    let data = []
    this.spendMoneyItem.map((x, i) => {
      x.ItemId == itemId && i == index ? x.selected = true : x.selected = false
      data.push(x)
    })
    this.spendMoneyItem = data;
  }
  show(e) {
    const value = this.selectedDate;
    $("#smtest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

  openItemModal(selectItem: NgSelectComponent){
    selectItem.close();
    const modalRefs = this.modalService.open(PurchaseProductComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
  }

  selectionChanged = (event, itemId, index) => {
    this.spendMoneyItem.map((x, i) => {
      if (x.ItemId == itemId && i == index) { x.AccountId = event.Id }
    })
  }

  updateTotalPrice = () => {
    let SubTotalAmount = 0.00;
    let TaxAmount: number = 0.00;
    let AdvanceTaxAmount: number = 0.00;
    let TotalPrice: number = 0.00;
    let price = 0.00;
    this.spendMoneyItem.map((x) => {
      SubTotalAmount = SubTotalAmount + x.LineTotal;
      TaxAmount += this.getItemTaxAmount(x.LineTotal, x.TaxRateId);
      AdvanceTaxAmount += this.getItemTaxAmount(x.LineTotal, x.AdvanceTaxRateId);
    });

    this.taxAmount = TaxAmount;
    this.advanceTaxAmount = AdvanceTaxAmount;
    this.subTotalAmount = SubTotalAmount;

    switch (this.selectedTaxType) {
      case 1: //tax exclusive
      TotalPrice = this.subTotalAmount + TaxAmount + AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
      TotalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
      TotalPrice = this.subTotalAmount;
        break;
    }
    this.totalPrice = TotalPrice;
    if (this.selectedTaxType === 3) {
      this.checkTaxType();
    }
  }

  private getItemTaxAmount(lineTotal: number, taxRateId: any) {
    let taxPrice = 0.00;
    if (taxRateId == "" || taxRateId == null)
      return taxPrice;

    var taxPercent = this.getTaxRatePercent(taxRateId);

    switch (this.selectedTaxType) {
      case 1: //tax exclusive
        taxPrice = lineTotal * taxPercent / 100;
        break;
      case 2: //tax inclusive
        taxPrice = Number(((lineTotal * taxPercent) / (100 + taxPercent)).toFixed(2));
        break;
      default: //no tax
        taxPrice = 0
        break;
    }
    return taxPrice;
  }

  private getTaxRatePercent(taxRateId: any) {
    let taxRateObj = this.taxRateList.filter(x => x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }

  checkTaxType = () => {
    this.spendMoneyItem.map((x, i) => {
      x.TaxRateId = null;
      x.AdvanceTaxRateId = null;
    });
  };

  updateDataInvoiceItem = (itemId) => {
    this.spendMoneyItem.map((x) => {
      if (x.ItemId == itemId) { x.LineTotal = x.Quantity * x.UnitPrice }
    })
    this.updateTotalPrice();
  }
  updateDataInvoiceItemLineTotal = (itemId) => {
    this.spendMoneyItem.map((x) => {
      if (x.ItemId == itemId) {
        x.UnitPrice = x.LineTotal / x.Quantity
      }
    })
    this.updateTotalPrice()
  }
  createBlankItem = () => {
    this.changeSelectRowInItem(0, 0)
    let item = { ItemId: null, ItemDescription: "", Quantity: 0, UnitPrice: 0, LineTotal: 0, AccountId: null, selected: false, TaxRateId: 0, AdvanceTaxRateId: 0}
    this.spendMoneyItem.push(item);
    this.defaultAccountSettings(this.spendMoneyItem);
  }

  createInvoiceItem = (data) => {
    this.changeSelectRowInItem(0, 0);
    let item = {
      ItemId: data.Id,
      ItemDescription: data.ItemName,
      Quantity: 1,
      UnitPrice: data.SaleUnitPrice,
      LineTotal: data.SaleUnitPrice,
      AccountId: data.SaleAccountId,
      selected: true,
      TaxRateId: data.TaxRateId,
      AdvanceTaxRateId: data.AdvanceTaxRateId
    }
    this.spendMoneyItem.push(item);
    this.updateTotalPrice()
  }
  checkDescription=(spendMoneyItem)=>{
    var b=true;
    console.log(spendMoneyItem);
      spendMoneyItem.forEach(element => {
        if(element.ItemDescription=="" || element.AccountId==null || element.LineTotal<=0)
        {
            b=false;

        }
      });
      return b;
  }
  removeInvoiceItem = (itemId, i) => {
    let data = []
    this.spendMoneyItem.map((x, index) => {
      if (index != i) {
        data.push(x);
      }
    })
    this.spendMoneyItem = data;
    this.updateTotalPrice();
  }
  onFocusEvent(event: any){

    event.target.value=event.target.value==0?null:event.target.value;
  }
  saveCreateSpendMoney = () => {

    let selectedCustomer= this.allSupplier.find( ({ Id }) => Id === this.selectedSupplier );

    let fullName= selectedCustomer?selectedCustomer['PrimaryPersonLastName']!=null? selectedCustomer['PrimaryPersonFirstName'] + " " + selectedCustomer['PrimaryPersonLastName']:selectedCustomer['PrimaryPersonFirstName']:null;

    this.spendMoneyData = {

      SpendTypeId: this.selectedSpendMoneyTypeId,
      SpendAccountId:this.selectedBank,
      SpendDate: this.selectedDate,
      FromId: this.selectedSupplier,
      FromName: fullName,
      ReferenceNo: this.ReferenceNo.toString(),
      SubTotalAmount: this.totalPrice,
      SpendMoneyItems: this.spendMoneyItem,
      TaxId: this.selectedTaxType,
      TaxAmount: this.taxAmount,
      AdvanceTaxAmount: this.advanceTaxAmount
    }
    let check=this.checkDescription(this.spendMoneyData.SpendMoneyItems);


    if (!this.spendMoneyData.SpendAccountId) {
      this.toaster.warning('To cannot be empty !', 'Warning!');
      return;
    }
    else if (!this.spendMoneyData.FromId) {
      this.toaster.warning('From  cannot be empty !', 'Warning!');
      return;
    } else if (!this.spendMoneyData.SpendDate) {
      this.toaster.warning('Date cannot be empty !', 'Warning!');
      return;
    }
     else if(this.spendMoneyItem.length==0)
    {
      this.toaster.warning('Please add at least one item in the spend money.', 'Warning!');
      return;
    }
    else if (!check) {
      this.toaster.warning('Please fill all the required fields!', 'Warning!');
      return;
    }

    this.spinner.show()
    this.spendMoneyService.saveSpendMoneyData(this.spendMoneyData).subscribe((x) => {

      if (x.Success){
        this.toaster.success('Transaction has been Successful.', 'Success!');
        this.router.navigate(['spendmoney/spend-money-list/all']);
        this.spinner.hide()
      }else{
        this.toaster.warning(x.Message);
        this.spinner.hide()
      }

    })

  }
}
