<app-dashboard>
    <app-page-header-title
        mainTitle="Factory Stock Report"
        subTitle="Home"
        backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox searchWidth">
            <button class="eCount-def-btn ml-4" (click)="OpenPopup()">Return To Inventory</button>
        </div>
    </app-page-header-title>
    <div class="headSection">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                [domLayout]="domLayout"
                class="ag-theme-balham col"
                [rowData]="itemList"
                [columnDefs]="columnDefs"
                (gridReady)="onGridReady($event)"
                [defaultColDef]="defaultColDef"
                [rowSelection]="rowSelection"
            ></ag-grid-angular>
        </div>
    </div>
</app-dashboard>