import { Component, OnInit, AfterViewInit, Input, ElementRef, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BillsServiceService } from '../../../service/Bill/bills-service.service';
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service';
import { BillItem, createBill } from '../../../models/income.model';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as moment from 'moment';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemCreateComponent } from '../../Products/item-create/item-create.component';
import { CreateItem } from '../../../models/product.model';
import { BillAdvanceAdjustmentComponent } from '../bill-advance-adjustment/bill-advance-adjustment.component';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { CreatePopupItemComponent } from '../../Products/create-popup-item/create-popup-item.component';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { PurchaseOrderService } from 'src/app/service/PurchaseOrder/purchase-order.service';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
import { AuthService } from 'src/app/service/auth/auth.service';
@Component({
  selector: 'app-create-new-bill',
  templateUrl: './create-new-bill.component.html',
  styleUrls: ['./create-new-bill.component.css'],
  host: {
    '(document:click)': 'onWindowClick($event)',
  },
})
export class CreateNewBillComponent implements OnInit {
  allSupplier: any = [];
  PurchaseableItems: any = [];
  allChartOfAccount: any = [];
  public aModel: CreateItem = new CreateItem();
  percentDone: number;
  @Input() itemCode;
  uploadSuccess: boolean;
  selectedSupplier: number;
  billData: createBill;
  today = new Date();
  BillNotes: string;
  billItem: BillItem[] = [];
  canApprove = true;
  selectedDate: any;
  selectedDueDate: Date;
  totalPrice: number = 0.00;
  ReferenceNo: string = "";
  isUploaded: boolean = false;
  uploadCounter: number;
  filesToUpload: number = 0;
  billNo: string = "";
  Isconversion: boolean;
  loading = false;
  BillNo: string = "";
  isItemListUpdate = false;
  PostingModuleId: number = 1;
  ExternalTransactionId: number = null;
  taxTypeList: any = [];
  taxRateList: any = [];
  expenseAccountId: any;
  TaxTypeId: null;
  subTotalAmount: number = 0.00;
  TaxAmount: number = 0.00;
  AdvanceTaxAmount: number = 0.00;
  totalFile: number = 0;
  ifSubmit: boolean;
  dataFromFactoryOut:any;
  repeatingTypeList:any=[
    {Id: 1, Name:"Daily"},
    {Id: 2, Name:"Weekly"},
    {Id: 3, Name:"Monthly"},
    {Id: 4, Name:"Yearly"}
  ];
  public billForm: FormGroup;
  public BillItems: FormArray;
  ShowBillNo: string;
  wareHouseGlag:number=0;
  purchaseOrderId: number = 0;
  fromPurchaseOrder: boolean = false;
  purchaseOrderNo: string;
  wareHouseData: any;
  wareHouseId: any;
  bankAccounts: any;
  isReverseTransaction: boolean = false;
  mainTitle: string;
  userInfo: any;

  @ViewChildren('quantityInput') quantityInputs: QueryList<ElementRef>;

  constructor(private mainService: MainServiceService,
              private billService: BillsServiceService,
              private dataService: DataService,
              private accountingService: AcountingServiceService,
              public ngxSmartModalService: NgxSmartModalService,
              private toaster: ToastrService,
              private router: Router,
              public fb: FormBuilder,
              private modalService: NgbModal,
              private spinner: NgxSpinnerService,
              private activeroute: ActivatedRoute,
              private settingService: SettingsServiceService,
              private _eref: ElementRef,
              private deliveryOrderService:InventoryOverviewService,
              private fileEmitterService: FileEmitterService,
              private purchaseOrderService: PurchaseOrderService,
              private authService: AuthService) { }


  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.dataFromFactoryOut=this.deliveryOrderService.getDataForCreateBill();
    if(this.dataFromFactoryOut != null){
      this.wareHouseId = this.dataFromFactoryOut.allData.WareHouseData.Id;
      this.billService.GetDataForCreateBillFromWareHouseOut(this.wareHouseId).subscribe((x) => {
        this.dataFromFactoryOut.allData.SalesOrderData = x;
        this.initializeForm();
        this.getFormData();
      });
    }
    this.activeroute.queryParams.subscribe(params => {
      this.Isconversion = params.Isconversion == undefined ? false : true;
      this.isReverseTransaction = params.IsBillReverseTransaction;
      this.mainTitle = this.setMainTitle();
      console.log(this.Isconversion);
      if (params.PostingModuleId != undefined) {
        this.PostingModuleId = params.PostingModuleId;
      }
      if (params.ExternalTransactionId != undefined) {
        this.ExternalTransactionId = params.ExternalTransactionId;
      }
      if(params.ExternalReferenceNo != undefined){
        this.purchaseOrderId = params.ExternalReferenceNo;
        this.fromPurchaseOrder = true;
        //this.purchaseOrderService.GetOrderDetails(this.purchaseOrderId).
        this.purchaseOrderService.GetOrderDetails_ForCreateBill_FromPurchaseOrder(this.purchaseOrderId).
        subscribe((x) => {
          if (x) {
            this.purchaseOrderNo = x.Details.OrderNo;
            this.billForm.controls["SupplierId"].patchValue(x.Details.Contacts.Id);
            this.billForm.controls["DueDate"].patchValue(x.Details.DeliveryDate);
            this.billForm.controls["TaxTypeId"].patchValue(x.Details.TaxTypeId);
            this.billForm.controls["ReferenceNo"].patchValue(x.Details.OrderNo);
            this.billForm.controls["Notes"].patchValue(x.Details.Notes);
            this.show(x.Details.DeliveryDate);
            x.Details.PurchaseOrderItems.forEach((item) => {
                this.addPurchaseItem(item);
            });
            this.updateTotalPrice();
          }
        }
        )}
        this.initializeForm();
        this.getFormData();
    });
    this.dataService.change.subscribe(x => {
      this.isItemListUpdate = x;
      this.getAllSaleableItem();
    });
    this.mainService.GetMaxBillId().subscribe((x) => {
      this.fileEmitterService.emitFileRefIdUpdate({ id: x.MaxBillId + 1 });
    });
    this.GetBankAccounts();
  }

  setMainTitle() {
    let isReverseTransaction = '' + this.isReverseTransaction;
    let title = isReverseTransaction == 'true' ? 'Create New Reverse Bill' : 'Create New Bill';
    return title;
  }

  public addPurchaseItem(x: any): void {
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    this.BillItems.push(this.createPurchaseItem(x));
  }

  public createPurchaseItem(x: any): FormGroup {
    let quantity = x.Quantity < 0 ? ((-1) * x.Quantity) : x.Quantity;
    return this.fb.group({
      Id: [0],
      BillId: [0],
      ItemId: [x.ItemId],
      Description: [x.Description],
      Quantity: [quantity],
      UnitPrice: [x.UnitPrice],
      LineTotal: [quantity * x.UnitPrice],
      AccountId: [x.AccountId],
      TaxRateId: [x.TaxRateId],
      AdvanceTaxRateId: [x.AdvanceTaxRateId],
    });
  }

//For Ware House Out
  public addItemForWareHouOut(x:any): void { 
    this.BillItems = this.billForm.get('BillItems') as FormArray;
    this.BillItems.push(this.createItemFromFactoryOut(x));
    this.deliveryOrderService.passDataToCreateBill(null);
  }

 //End 

  public addItem(): void {
    this.BillItems = this.billForm.get('BillItems') as FormArray;
    this.BillItems.push(this.createItem());
  }
  initializeForm() {
    this.billForm = this.fb.group({
      Id: [0],
      BillNo: [''],
      SupplierId: [null, Validators.required],
      ReferenceNo: [this.ReferenceNo],
      BillDate: [''],
      DueDate: [''],
      BillAmount: [0],
      IsConversion: [this.Isconversion],
      TaxTypeId: [3],
      PostingModuleId: [this.PostingModuleId],
      TaxAmount: [''],
      AdvanceTaxAmount: [''],
      ExternalTransactionId: [this.ExternalTransactionId],
      Notes: [''],
      StatusId: [''],
      IsRepeating: [''],
      RepeatingTypeId: [null],
      BillItems: this.dataFromFactoryOut==null && !this.fromPurchaseOrder? this.fb.array([ this.createItem() ]): this.fb.array([ ]),
      BillType: [0], // 0 = for normal bill, 1 = for fixed asset bill
      IQuidiActionType: [0],
      BusinessUnitRoleId: [0]
    });
    this.billForm.get('BillDate').patchValue(new Date());
    this.showSelectedDate(this.billForm.value.BillDate);
    if(this.dataFromFactoryOut!=null)
    {
      this.billForm.get('ReferenceNo').patchValue("");
      this.wareHouseGlag=1;
      console.log("FromService",this.dataFromFactoryOut)
      this.dataFromFactoryOut.allData.SalesOrderData.SalesOrderItem.forEach(item=>
        {
              this.addItemForWareHouOut(item);
        });
    }
  }
  public createItem(): FormGroup {
    return this.fb.group({
      ItemId: [null],
      Description: ['', Validators.required],
      Quantity: [0, Validators.required],
      UnitPrice: [0, Validators.required],
      LineTotal: [0, Validators.required],
      AccountId: [this.expenseAccountId, Validators.required],
      selected: [false],
      TaxRateId: [null],
      AdvanceTaxRateId: [null]
    });
  }

  public createItemFromFactoryOut(x:any): FormGroup {
    this.subTotalAmount+=(x.UnitPrice*x.Quantity);
    this.billForm.get('BillAmount').patchValue(Number(this.subTotalAmount).toFixed(2));
    return this.fb.group({
      ItemId: [ x.ItemId],
      Description: [x.Description, Validators.required],
      Quantity: [x.Quantity, Validators.required],
      UnitPrice: [x.UnitPrice, Validators.required],
      LineTotal: [x.UnitPrice*x.Quantity, Validators.required],
      AccountId: [this.expenseAccountId, Validators.required],
      selected: [false],
      TaxRateId: [null],
      AdvanceTaxRateId: [null]
    });
    
    
  }

  openItemModal(selectItem: NgSelectComponent){
    selectItem.close();
    const modalRef = this.modalService.open(CreatePopupItemComponent, { size: 'lg', backdrop: 'static', keyboard: false, });
    modalRef.componentInstance.refId = 1;
    modalRef.result.then((result) => {
      this.getAllSaleableItem();
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }


  getFormData = () => {
    this.generateBillNo();
    this.CheckApprover();
    this.getAllSupplier();
    this.getAllSaleableItem();
    this.getAllChartOfAccount();
    this.getTaxTypeList();
    this.getTaxRateList();
  }
  generateBillNo = () => {
    this.billService.GenerateBillNo().subscribe((x: any) => {
      let val = x.toString(); let pre = "", post = "";
      for (let i = 0; i < 6; i++) pre += val[i].toString();
      for (let i = 6; i < 10; i++) post += val[i].toString();
      console.log(pre, 'ok ', post);
      this.billForm.get('BillNo').patchValue(post);
      this.BillNo = post;
      this.ShowBillNo = 'Bill-' + pre;
      console.log(this.ShowBillNo);
    });
  }
  CheckApprover = () => {
    this.billService.CheckApprover().subscribe((x: any) => {
      this.canApprove = x;
    });
  }
  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
      if (this.taxTypeList.length >= 2) {
        this.TaxTypeId = this.taxTypeList[2].Id;
      }
    });
  }
  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({ "Id": "", "Name": "Select" });
    });
  }
  getAllSupplier = () => {
    this.billService.GetAllContact().subscribe((x: []) => {
      this.allSupplier = x;
    });
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: false,
      IsSupplier: true,
    };
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {

        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.allSupplier.push(x["Data"]);
        let customerObj = this.allSupplier.filter(x => x.PrimaryPersonFirstName == customerName);
        this.selectedSupplier = customerObj[0]['Id'];
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    });
  }

  changespurchaseableItems = (index) => {
    this.setFocusOnQuantityInput(index);
    this.BillItems = this.billForm.get('BillItems') as FormArray;
    for (let i = 0; i < this.PurchaseableItems.length; i++) {
      if (this.PurchaseableItems[i].Id == this.BillItems.controls[index].value.ItemId) {
        this.BillItems.controls[index].get('ItemId').patchValue(this.PurchaseableItems[i]['Id']);
        this.BillItems.controls[index].get('AccountId').patchValue(this.PurchaseableItems[i]["IsTrackItem"] === true ?
          this.PurchaseableItems[i]['InventoryAccountId'] : this.PurchaseableItems[i]['PurchaseAccountId']);
        this.BillItems.controls[index].get('Quantity').patchValue(1);
        this.BillItems.controls[index].get('UnitPrice').patchValue(this.PurchaseableItems[i]['PurchasePrice']);
        this.BillItems.controls[index].get('Description').patchValue(this.PurchaseableItems[i]['PurchaseDescription']);
        this.updateDataInvoiceItem(this.BillItems.controls[index].value.ItemId);
      }
    }
  }

  private setFocusOnQuantityInput(index: number): void {
    if (index >= 0 && index < this.quantityInputs.length) {
      this.quantityInputs.toArray()[index].nativeElement.focus();
      this.quantityInputs.toArray()[index].nativeElement.select();
    }
  }

  getAllSaleableItem = () => { this.billService.GetPurchaseableItems().subscribe((x) => this.PurchaseableItems = x) }

  getAllChartOfAccount = () => {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      this.allChartOfAccount = x.ExpenseList.filter(y => y.AccountTypeId != 2);
      this.expenseAccountId = this.allChartOfAccount?.filter(x => x.AccountName == "429 - General Expenses")[0]?.Id;
      const myForm = (<FormArray>this.billForm.get("BillItems")).at(0);
      myForm?.patchValue({ AccountId: this.expenseAccountId });
      let len = (<FormArray>this.billForm.get("BillItems")).length;
      for( var i = 0; i < len; i++ ){
        const form = (<FormArray>this.billForm.get("BillItems")).at(i);
        form.patchValue({
          AccountId: this.expenseAccountId,
        });
      }
    });
  }
  onFocusEvent(event: any) {
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }

  updateTotalPrice = () => {
    this.subTotalAmount = 0.00;
    let TaxAmount: number = 0.00;
    let AdvanceTaxAmount: number = 0.00;
    let totalPrice = 0.00;
    this.BillItems = this.billForm.get('BillItems') as FormArray;
    this.BillItems.value.map((x) => {
      this.subTotalAmount = this.subTotalAmount + x.LineTotal;
      TaxAmount += this.getItemTaxAmount(x.LineTotal, x.TaxRateId);
      AdvanceTaxAmount += this.getItemTaxAmount(x.LineTotal, x.AdvanceTaxRateId);
    });
    switch (this.billForm.value.TaxTypeId) {
      case 1: //tax exclusive
        totalPrice = this.subTotalAmount + TaxAmount + AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
        totalPrice = this.subTotalAmount;
        break;
    }
    if (this.billForm.value.TaxTypeId === 3) {
      this.checkTaxType();
    }
    this.billForm.get('TaxAmount').patchValue(Number(TaxAmount).toFixed(2));
    this.billForm.get('AdvanceTaxAmount').patchValue(Number(AdvanceTaxAmount).toFixed(2));
    this.billForm.get('BillAmount').patchValue(Number(totalPrice).toFixed(2));
  }

  checkTaxType = () => {

    this.BillItems = this.billForm.get('BillItems') as FormArray;
    this.BillItems.value.map((x, i) => {

      const myForm = (<FormArray>this.billForm.get("BillItems")).at(i);
      myForm.patchValue({
        TaxRateId: 0,
        AdvanceTaxRateId: 0
      });
    });
  }




  show(e) {
    const value = this.billForm.value.DueDate;
    $("#btest").on("change", function () {
      this.setAttribute(
        "data-date",
        moment(value, "YYYY-MM-DD")
          .format(this.getAttribute("data-date-format"))
      );
    }).trigger("change")

  }
  showSelectedDate(e) {
    let value = this.billForm.value.BillDate;
    $('#btest').attr('min', value = moment.isDate(value) ? value.toISOString().split('T')[0] : value);
    $("#btest1").on("change", function () {
      this.setAttribute(
        "data-date",
        moment(value, "YYYY-MM-DD")
          .format(this.getAttribute("data-date-format"))
      );
    }).trigger("change");
  }

  private getItemTaxAmount(lineTotal: number, taxRateId: any) {
    let taxPrice = 0.00;
    if (taxRateId == "" || taxRateId == null)
      return taxPrice;

    var taxPercent = this.getTaxRatePercent(taxRateId);

    switch (this.billForm.value.TaxTypeId) {
      case 1: //tax exclusive
        taxPrice = lineTotal * taxPercent / 100;
        break;
      case 2: //tax inclusive
        taxPrice = Number(((lineTotal * taxPercent) / (100 + taxPercent)).toFixed(2));
        break;
      default: //no tax
        taxPrice = 0
        break;
    }
    return taxPrice;
  }
  private getTaxRatePercent(taxRateId: any) {
    let taxRateObj = this.taxRateList.filter(x => x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }
  defaultAccountSettings = (item) => {
    console.log(item);
    const account = this.allChartOfAccount.filter(x => x.AccountCode === '429');
    console.log(account);
    item.map((x) => {
      if (x.AccountId == null) {
        x.AccountId = account[0].Id;
      }
    });
  }
  updateDataInvoiceItem = (itemId) => {
    this.BillItems = this.billForm.get('BillItems') as FormArray;
    this.BillItems.value.map((x, i) => {
      x.LineTotal = x.Quantity * x.UnitPrice;
      this.BillItems.controls[i].get('LineTotal').patchValue(x.LineTotal);
    });
    this.updateTotalPrice();
  }
  removeInvoiceItem = (itemId, i) => {
    this.BillItems = this.billForm.get('BillItems') as FormArray;
    this.BillItems.value.map((x, index) => {
      if (index === i) {
        this.BillItems.removeAt(index);
      }
    });
    this.updateTotalPrice();
  }



  checkValidation = (billItem) => {
    var b = true;
    billItem.forEach(element => {
      if (element.Description == "" || element.AccountId == null || element.LineTotal < 0 || (this.billForm.value.TaxTypeId != 3 && (element.TaxRateId == null && element.AdvanceTaxRateId == null))) {
        b = false;
      }
    });
    return b;
  }
  private isValidData() {
    this.BillItems = this.billForm.get('BillItems') as FormArray;
    let check = this.checkValidation(this.BillItems.value);
    if (this.billForm.invalid) {
      this.toaster.warning('Required field can not be empty!', 'Warning!');
      return false;
    }
    else if (this.billForm.value.IsRepeating == true && this.billForm.value.RepeatingTypeId == null) {
      this.toaster.warning('Required field can not be empty!', 'Warning!');
      return false;
    } else if (this.BillItems.value.length === 0) {
      this.toaster.warning('Please add at least one item to the Bill', 'No Item Added!');
      return false;
    }
    else if (!check) {
      this.toaster.warning('Required field can not be empty!');
      return false;
    }
    return true;
  }

  async checkBillNo(){
    //var val = this.billForm.value.BillNo.toString();
    var val = this.billForm.get('BillNo')?.value;
    if(val=='' || val==null){
      this.toaster.error('Please Input Bill No.!');
      return false;
    }

    if (val.length != 4) {
      this.toaster.error('Bill Number needs to be 4 digits.');
      return false;
    }

    return true;
  }

  IsValidVATAndAIT() {
    if(this.billForm.value.TaxTypeId != 3 && this.billForm.value.BillItems.some(x => x.TaxRateId == 0 || x.AdvanceTaxRateId == 0)) {
      this.toaster.warning('VAT and AIT can not be zero!');
      return false;
    }
    else {
      return true;
    }
  }

  saveAsDraftCreateBill = async () => {
    if(!this.IsValidVATAndAIT()) {
      return;
    }
    if (!await this.checkBillNo()) return;
    this.billForm.get('StatusId').patchValue(1);
    this.billForm.get('IQuidiActionType').patchValue(iQuidiActionType.Save);
    this.billForm.get('BusinessUnitRoleId').patchValue(this.userInfo.RoleId);
    this.ifSubmit = true;
    if (!this.isValidData())
      return;
    this.Isconversion ? this.saveConversionBalanceBillData() : this.saveAsDraftBillData();
  }
  saveAsSubmitBill = async () => {
    if(!this.IsValidVATAndAIT()) {
      return;
    }
    if (!await this.checkBillNo()) return;
    this.billForm.get('StatusId').patchValue(2);
    this.billForm.get('IQuidiActionType').patchValue(iQuidiActionType.Submit);
    this.billForm.get('BusinessUnitRoleId').patchValue(this.userInfo.RoleId);
    this.ifSubmit = true;
    if (!this.isValidData())
      return;
    this.Isconversion ? this.saveConversionBalanceBillData() : this.saveAsDraftBillData();
  }
  saveBillData = async () => {
    if(!this.IsValidVATAndAIT()) {
      return;
    }
    if (!await this.checkBillNo()) return;
    this.ifSubmit = true;
    if (!this.isValidData())
      return;
    this.billForm.get('StatusId').patchValue(2);
    this.billForm.get('IQuidiActionType').patchValue(iQuidiActionType.Approve);
    this.billForm.get('BusinessUnitRoleId').patchValue(this.userInfo.RoleId);
    if( this.fromPurchaseOrder ){
      this.Isconversion ? this.saveConversionBalanceBillData() : this.saveDefaultBillDataForPurchaseOrder();
    }else{
      this.Isconversion ? this.saveConversionBalanceBillData() : this.saveDefaultBillData();
    }
  }

  saveDefaultBillData() {
    this.spinner.show();
    let preValue = "";
    let temp = this.billForm.value.BillNo;
    for (let i = 5; i < this.ShowBillNo.length; i++) preValue += this.ShowBillNo[i].toString();
    this.billForm.value.BillNo = preValue + this.billForm.value.BillNo.toString();
    this.billService.saveAsBillData(this.billForm.value).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Bill ${this.billForm.value.BillNo} has been successfully Approved`);
        if(x.HasAdvance) {
          this.openAdvanceAdjustmentPopup(x.BalanceData);
          this.spinner.hide();
        }
        else {
          this.router.navigate(['/expense/bill','Bill-'+this.billForm.value.BillNo]);
          this.spinner.hide();
        }
      }
      else {
        this.billForm.controls['BillNo'].setValue(temp);
        this.toaster.warning("Bill No already exists");
        this.spinner.hide();
      }
    });
  }

  saveDefaultBillDataForPurchaseOrder() {
    this.spinner.show();
    let preValue = "";
    let temp = this.billForm.value.BillNo;
    for (let i = 5; i < this.ShowBillNo.length; i++) preValue += this.ShowBillNo[i].toString();
    this.billForm.value.BillNo = preValue + this.billForm.value.BillNo.toString();
    let data = this.billForm.value;
    data.IsReverseTransaction = this.isReverseTransaction;
    this.billService.saveAsBillData(data).subscribe((x) => {
      if (x.Success) {

        this.displaySuccessResponse(x, `Bill ${this.billForm.value.BillNo} has been successfully Approved`);
      }
      else {
        this.billForm.controls['BillNo'].setValue(temp);
        this.spinner.hide();
        this.toaster.warning("Bill No already exists");
      }
    });
  }

  GetBankAccounts(){
    this.accountingService.GetBankAccounts().subscribe((s) => {
      this.bankAccounts = s;
    });
  }

  private openAdvanceAdjustmentPopup(x: any) {
    const modalRef = this.modalService.open(BillAdvanceAdjustmentComponent, { size: 'md', backdrop: 'static', keyboard: false, });
    modalRef.componentInstance.fromParent = x;
    modalRef.componentInstance.bankAccounts = this.bankAccounts;
    modalRef.result.then((result) => {
      this.router.navigate(["/expense/bill","Bill-"+this.billForm.value.BillNo]);
      //this.displaySuccessResponse(x, `Bill ${this.billForm.value.BillNo} has been successfully Approved`);
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }

  saveAsDraftBillData() {
    this.spinner.show();
    let preValue = "";
    for (let i = 5; i < this.ShowBillNo.length; i++) preValue += this.ShowBillNo[i].toString();
    this.billForm.value.BillNo = preValue + this.billForm.value.BillNo.toString();
    let data = this.billForm.value;
    data.IsReverseTransaction = this.isReverseTransaction;
    this.billService.saveAsBillData(data).subscribe((x) => {
      this.displaySuccessResponse(x, `Bill ${this.billForm.value.BillNo} has been successfully saved`);
    });
  }

  saveConversionBalanceBillData() {
    this.billForm.get('IsConversion').patchValue(this.Isconversion);
    this.spinner.show();
    let preValue = "";
    for (let i = 5; i < this.ShowBillNo.length; i++) preValue += this.ShowBillNo[i].toString();
    this.billForm.value.BillNo = preValue + this.billForm.value.BillNo.toString();
    this.billService.saveConversionBalanceBillData(this.billForm.value).subscribe((x) => {
      this.displaySuccessResponse(x, `Bill ${this.billForm.value.BillNo} has been successfully saved`);
    });
  }

  private displaySuccessResponse(x: any, sMessage: string) {
    if (x.Success) {
      this.toaster.success(sMessage, 'Success!');
      this.redirectToListPage();
      this.spinner.hide();
    } else {
      this.toaster.warning(x.Message);
      this.spinner.hide();
    }
  }


  private redirectToListPage() {
    var rUrl = 'expense/bill-overview/All';
    if(this.fromPurchaseOrder == true){
      rUrl = 'procurement/purchase-order-bill-details/'+this.purchaseOrderId+'/'+this.purchaseOrderNo;
    }
    if (this.Isconversion) {
      rUrl = 'accounting/historic-conversionbalancesBill';
    }

    if (this.PostingModuleId == 2) { //here 2 means project module
      rUrl = 'project/details/' + this.ExternalTransactionId;
    }
    this.router.navigate([rUrl]);
  }

  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  fileName: string;
  fileType: string;

  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id == "Preview")) {

    }
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown()
    }
    else {
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }
  changeType = () => {
    if (this.billForm.value.IsRepeating === false) {
      this.billForm.get('RepeatingTypeId').patchValue(null);
    }
  }

}
