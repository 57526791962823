<div class="headSection marginNegative5">
    <div class="row borderBottom1Px pb-1">
        <div class="col-md-4 d-flex" style="padding: 0px 15px;">
            <div class="d-block">
                <div class="pointer" (click)="backToPreviousPage()">
                    <i class="icon-arrow-left52 mr-2 subTextTitle"></i>
                    <span class="subTextTitle f-ss-14px">{{subTitle}}</span>
                </div>
                <i class="icon-key icon-rotate-180 mr-2 mainTextTitle" *ngIf="mainTitle === 'Change Password'" ></i>
                <span class="mainTextTitle f-ssb-14px" style="width: 220px;">{{mainTitle}}</span>
                <ng-template *ngTemplateOutlet="customTemplate"></ng-template>
            </div>
        </div>
        <div class="col-md-8" style="padding: 0px 15px;" [hidden]="!rightAcion">
            <div class="filter d-flex float-right mt-2" style="width: 100%;">
                <ng-content></ng-content>
            </div>
        </div> 
    </div>
</div>