<app-dashboard>
    <app-page-header-title mainTitle="{{'FIXEDASSET.menuTitle' |translate}}" subTitle="Home" backURL="/">
    </app-page-header-title>
    <div class="row" style="margin-bottom: 20px;">
        <div>
            <p class="txtsize"> {{'FIXEDASSET.trackBusiness' |translate}}</p>
        </div>
        <div class="col-md-12" *ngIf="showStrartDateDiv===true">
            <button class="eCount-def-btn"  (click)="CreateNewAsset()">{{'FIXEDASSET.newAsset' |translate}}</button>
            <button class="eCount-def-btn  ml-4"  [routerLink]="['/fixed-assets/depreciate']">{{'FIXEDASSET.runDepreciation' |translate}}</button>
            <button class="eCount-def-btn  ml-4"  [routerLink]="['/fixed-asset/fixed-asset-settings/All']">{{'FIXEDASSET.settings' |translate}}</button>
            <button style="border: none; font-size: 14px !important; background-color: white;">Last Depreciation: {{ lastDepreciationDate }}</button>
        </div>
    </div>
    <div class="row col-md-12">

        <div class="co-crud-no-items mt-4 col-md-6" *ngIf="showStrartDateDiv===false" style="height: 270px;">
            <div class="fa-crud-no-items-watermark ">
            </div>
            <div class="txt pt calnder-dgn" id="component-1150 ">
                <p class="fa-crud-no-items-label ">Choose a Start Date</p>
    
                Before you can enter your Fixed Assets, 
                we need to confirm which financial year you want to start
                 managing your Fixed Assets.

            </div>
            <button class="btn_design" (click)="changeStartDate()">set start date</button>
        </div>
        <div *ngIf="showStrartDateDiv===true">
            <router-outlet></router-outlet>
        </div>
        <div class="col-md-6 mt-4" *ngIf="showStrartDateDiv===false">
            <div class="borderall">
                <p style="margin-bottom: 0;" class="padding__l--p15px padding__tb--p5px border__bottom">Whats this ? </p>
                <ol class="ls__none padding__l--p0px margin__b--p0px">
                    <li class="border__bottom padding__tb--p5px padding__l--p15px">
                        <div class="padding--all">
                            <span >1.</span>
                            <span class="padding__l--p10px" >Start Date</span>
                            <span data-toggle="modal" data-target="#selectModal" (click)="showOrHideData('Date')" class="float-right pointer_cursor"><span *ngIf="RightToDown('Date')"><i  class="material-icons">arrow_drop_up</i> </span><span><i *ngIf="!RightToDown('Date')" class="material-icons">arrow_drop_down</i></span></span>
                        </div>
                        <div *ngIf="droPdownController.Date==true">
                            <ul style="list-style: disc;" class="showhide padding__l--p20px">
                                <li class="tree-view font_styling tree_child"  >Set the start date first. (Depreciation start date)</li>
                            </ul>
                        </div>
                    </li>
                    <li class="border__bottom padding__tb--p5px padding__l--p15px">
                        <div class="padding--all">
                            <span>2.</span>
                            <span class="padding__l--p10px" >New Asset </span>
                            <span (click)="showOrHideData('Asset')" class="float-right pointer_cursor"><span *ngIf="RightToDown('Asset')"><i  class="material-icons">arrow_drop_up</i> </span><span><i *ngIf="!RightToDown('Asset')" class="material-icons">arrow_drop_down</i></span></span>
                        </div>
                        <div *ngIf="droPdownController.Asset==true">
                            <ul  style="list-style: disc;" class="showhide padding__l--p20px">
                                <li class="tree-view font_styling tree_child  pb-p10px" >After maintaining the date go to 'New Asset' to register assets</li>
                            </ul>
                        </div>
                    </li>
                    <li class="border__bottom padding__tb--p5px padding__l--p15px">
                        <div class="padding--all">
                            <span >3.</span>
                            <span class="padding__l--p10px" >Run Depreciation</span>
                            <span (click)="showOrHideData('Dep')" class="float-right pointer_cursor"><span *ngIf="RightToDown('Dep')"><i  class="material-icons">arrow_drop_up</i> </span><span><i *ngIf="!RightToDown('Dep')" class="material-icons">arrow_drop_down</i></span></span>
                        </div>
                        <div *ngIf="droPdownController.Dep==true">
                            <ul  style="list-style: disc;" class="showhide padding__l--p20px">
                                <li class="tree-view font_styling tree_child  pb-p10px" >For depreciation go 'Run Depreciation' and select the date range </li>
                            </ul>
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</app-dashboard>

