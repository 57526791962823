<app-dashboard>
    <app-page-header-title
      mainTitle="Invoice Overview"
      subTitle="Home"
      backURL="/"
    >
      <div class="full-width text-align-right">
        <input
          type="text"
          [(ngModel)]="searchText"
          (ngModelChange)="search()"
          placeholder="search"
          class="searchBox"
        />
        <button
          type="button"
          routerLink="/user/user-role-set-up"
          class="eCount-def-btn ml-4"
        >
          Role Setup
        </button>
        <button type="button" (click)="openUserRolePopup()" class="eCount-def-btn ml-4">Create Role </button>
        <button type="button" class="eCount-button3 ml-4" (click)="convetToPDF()">
          Export
        </button>
      </div>
    </app-page-header-title>
    <div>
      
      <div class="row pt-3">
        <ag-grid-angular
          style="font-size: 14px"
          class="ag-theme-balham col"
          [rowData]="allUserRole"
          [gridOptions]="gridOptions"
          [domLayout]="gridOptions.domLayout"
          (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
      </div>
      <!-- <div class="row col-sm-12 grid ml-0">
        <div class="col-sm-1 ml" >
          {{ totalInvoiceAmount | currency:'':''}}
        </div>
        <div class="col-sm-1 ml-4">{{ totalDiscountAmount | currency:'':'' }}</div>
        <div class="col-sm-1 ml-2">{{ totalDueAmount | currency:'':''}}</div>
      </div> -->
    </div>

    <ngx-smart-modal #AddRole identifier="AddRole">
        <div class="pb-4">
            <span class="modalHeaderText pl-2">Add User Role</span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                <div>
                    <form [formGroup]="CompanyUserRoleForm" (ngSubmit)="saveComapnyWiseUserRole()">

                        <div class="form-group col-sm-12">
                            <ng-select  [items]="userRoleList" bindLabel="Name" bindValue="Id"  formControlName ="RoleId"  [multiple]="true" [clearable]="false" >

                            </ng-select> 
                         </div>
                         <div class="form-group col-sm-12">
                            <ng-select [items]="userList" formControlName="UserId"  bindLabel="Name" bindValue="Id" required></ng-select>
                            </div>
                        <div class="form-group pt-4" style="float: right;">
                            <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4">Save</button>
                        </div>
                    </form>
        
                </div>
            </div>
        </div>
        
        </div>
    </ngx-smart-modal>
  </app-dashboard>
