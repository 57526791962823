import { Component, OnInit, ɵbypassSanitizationTrustResourceUrl,Input } from '@angular/core';
import { AuthService } from './../../../service/auth/auth.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProductServiceService } from '../../../service/Products/product-service.service'
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service'
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-purchase-product',
  templateUrl: './purchase-product.component.html',
  styleUrls: ['./purchase-product.component.css']
})
export class PurchaseProductComponent implements OnInit {

  itemForm: FormGroup;
  chartOfAccounts: any;
  inverntoryChartOfAccounts: any;
  purchaseChartOfAccounts: any;
  sellChartOfAccounts: any;

  showpurchaseDiv: boolean = false;
  showsellDiv: boolean = true;
  showTrackDiv: boolean = true;

  chckedboxpurchase: boolean = true;
  chckedboxsell: boolean = false;

  isTrackItem :boolean= false;

  constructor(private spinner: NgxSpinnerService, 
             public ngxSmartModalService: NgxSmartModalService,
             public fb: FormBuilder, 
             private router: Router, 
             public authService: AuthService, 
             private toastr: ToastrService,
             private dataService: DataService, 
             public activeModal: NgbActiveModal,
             private productServiceService: ProductServiceService, 
             private accountingServiceService: AcountingServiceService) { }

  ngOnInit(): void {
    this.itemData();
    this.getAllChartOfAccount();
  }

  changeTrackDiv() {
    this.showpurchaseDiv = !this.showTrackDiv;
    this.showsellDiv = this.showTrackDiv == true ?   false :true;
    this.showTrackDiv = this.showTrackDiv == true ?  false : true;
    this.chckedboxpurchase = !this.showTrackDiv;
    this.chckedboxsell = !this.showTrackDiv;


    if(this.showTrackDiv == true){
      this.itemForm.controls['IsPurchaseItem'].enable();
      this.itemForm.controls['IsSaleItem'].enable(); 
     }
     else{
      
      this.itemForm.controls['IsPurchaseItem'].disable();
      this.itemForm.controls['IsSaleItem'].disable();
     }
  }

  getAllChartOfAccount = () => {
    this.accountingServiceService.GetChartOfAccountsforItems().subscribe((x) => {
      this.chartOfAccounts = x.ExpenseList;
      console.log(x);
      this.inverntoryChartOfAccounts = this.chartOfAccounts.filter(c =>  {return c.AccountCode == 630 } );
      const defaultPurchaseAccounts = this.chartOfAccounts.filter(c => {return c.AccountCode == 310 });
      const defaultSellAccounts = this.chartOfAccounts.filter(c => {return c.AccountCode == 200 });
      this.purchaseChartOfAccounts = x.ExpenseList;
      this.sellChartOfAccounts =x.RevenueList;
      this.itemForm.patchValue({PurchaseAccountId : defaultPurchaseAccounts[0].Id });
      this.itemForm.patchValue({SaleAccountId : defaultSellAccounts[0].Id });
      this.itemForm.patchValue({InventoryAccountId : this.inverntoryChartOfAccounts[0].Id});
      console.log(x);
    }) ;
  }
  onOptionsSelected(item){
    var value=item.value.ItemName;

    
  
       if(this.itemForm.value.PurchaseDescription=='')
       {
          this.itemForm.patchValue({  PurchaseDescription :value })
       }
     
    
       if(this.itemForm.value.SalesDescription=='')
       {
          this.itemForm.patchValue({  SalesDescription :value })
       }
     
}
  itemData() {
    this.itemForm = this.fb.group({
      ItemCode: ['', Validators.required],
      ItemName: ['', Validators.required],
      IsPurchaseItem: [true],
      PurchasePrice: [''],
      PurchaseDescription: [''],
      PurchaseAccountId: [''],
      IsSaleItem: [''],
      SaleUnitPrice: [''],
      SalesDescription:[''],
      SaleAccountId: [''] ,
      InventoryAccountId: [''],
      IsTrackItem: [''],
    })
  }

  saveCreateItem() {
  
    if (this.itemForm.controls.ItemCode.invalid || this.itemForm.controls.ItemName.invalid) 
    {

      return this.toastr.error('Item Code and Item Name are required', '');
    } 
    else 
    {
        if(this.itemForm.value.IsTrackItem == true)
        {
          this.itemForm.value.IsPurchaseItem = true;
          this.itemForm.value.IsSaleItem = true;
          this.submitCreateItem(this.itemForm);
   
        }
        else
        {         
          this.itemForm.patchValue({InventoryAccountId :''});

          if(this.itemForm.value.IsPurchaseItem == false)
          {
            this.itemForm.patchValue({PurchasePrice :''});
            this.itemForm.patchValue({PurchaseDescription :'' });
            this.itemForm.patchValue({PurchaseAccountId :'' });
          }
          if(this.itemForm.value.IsSaleItem == false)
          {
            this.itemForm.patchValue({SaleUnitPrice :''});
            this.itemForm.patchValue({SalesDescription :'' });
            this.itemForm.patchValue({SaleAccountId :'' });
          }
          this.submitCreateItem(this.itemForm);
       
        }
    }

  }

  submitCreateItem(itemsForm){
   this.spinner.show();
    this.productServiceService.saveProductData(itemsForm.value).subscribe(res => {
      if(res.Success){
       
        this.toastr.success(res.Message, 'Success!');
        this.dataService.emitItemListUpdate(true);
        this.spinner.hide();
        this.activeModal.close();
        //this.ngxSmartModalService.getModal('createProduct').close();
        itemsForm.reset();
      }
      else{
        this.toastr.error(res.Message, 'Wrong!');
        this.spinner.hide();
      }
     
    }, err => {
      this.spinner.hide()
      this.toastr.error('Wrong itemCode or sellAccountId, please try again', 'Wrong!');
    })
    
  }
}
