import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators  } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DocumentServiceService } from 'src/app/service/DocumentService/document-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { environment } from 'src/environments/environment';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
import { AppConfig } from 'src/app/app.config';
import { WindowService } from 'src/app/service/Windows/window.service';
import * as firebase from "firebase";
import { DocumentOTPViewModel } from 'src/app/models-viewModels/documentOTPViewModel';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
@Component({
  selector: 'app-reject-document-modal',
  templateUrl: './reject-document-modal.component.html',
  styleUrls: ['./reject-document-modal.component.css']
})

export class RejectDocumentModalComponent implements OnInit {
  public recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  
  noteForm: FormGroup;
  RejectDocumentModalData: any;
  modalType:any;
  approvalData:any;
  customApprovalData: any;
  finalLevel: any;
  modalData:any;
  imageURL: string;
  finalUserLevel: any;
  fileUrl = environment.fileBaseUrl;

  otpForm: FormGroup;
  emailForm: FormGroup
  isMobileValidNo: boolean = false;
  isValidEmail: boolean = false;
  windowRef: any;
  showCaptcha: boolean = true;
  isOTPSent: boolean = false;
  isEmailOTPSent: boolean = false;

  isSmSOTPVerified: boolean = false;
  isEmailOTPVerified: boolean = false;

  documentSettingsData: any;
  isMobileOTPRequiredForApproval: boolean = false;
  isEmailOTPRequiredForApproval: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private documentServiceService: DocumentServiceService,
    private mainService: MainServiceService,
    private appConfig: AppConfig,
    private toastr: ToastrService,
    public win: WindowService,
    private settingsService: SettingsServiceService
  ) { 
    if (!firebase.apps.length) {
      firebase.initializeApp(appConfig.firebaseConfig);
    }
  }

  ngOnInit(): void {
    this.windowRef = this.win.windowRef;
    this.initializeItemForm();
    this.getDocumentSettings();
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container"
    );
    this.RejectDocumentModalData = this.documentServiceService.getRejectDocumentModalData();
    this.modalType = this.RejectDocumentModalData.dataType;
    this.approvalData = this.RejectDocumentModalData.approvalData;
    this.modalData = this.RejectDocumentModalData.selectedData;
    this.finalUserLevel = this.RejectDocumentModalData.finalUserLevel;
    if(this.modalData[0].IsCustomApprovalLevel == true) {
      this.getCustomApprovalData();
    }
    this.getApprovalSignatureData();
  }

  getDocumentSettings() {
    this.settingsService.GetOTPVerificationSettings().subscribe(x => {
      this.documentSettingsData = x;
      this.isMobileOTPRequiredForApproval = x.IsMobileOTPRequiredForApproval;
      this.isEmailOTPRequiredForApproval = x.IsEmailOTPRequiredForApproval;
    });
  }

  initializeItemForm() {
    this.noteForm = this.fb.group({
      Note: new FormControl('')
    });
    
    this.otpForm = new FormGroup({
      MobileNumber: new FormControl('', [Validators.required, Validators.pattern(/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/)]),
      OTPCode: new FormControl(),
    });

    this.emailForm = new FormGroup({
      Email: new FormControl('', [Validators.required, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
      OTPCode: new FormControl()
    });
    
  }

  getCustomApprovalData(){
    this.mainService.UserCustomApprovalForDocument(this.modalData[0].Number).subscribe(x => {
      this.customApprovalData = x.approvalUser;
      this.finalLevel = x.finalLevel;
    });
  }

  getApprovalSignatureData(){
    this.mainService.GetUserDetailsId().subscribe((x) => {
      if (x.Success){
        if(x.data.Signature!=null && x.data.Signature.toString().length!=0){
          this.imageURL = this.fileUrl + x.data.Signature;
        }
      }
    });
  }

  confirm(event:any){
    const button = (event.srcElement.disabled === undefined) ? event.srcElement.parentElement : event.srcElement;
    button.setAttribute('disabled', true);
    setTimeout(function () {
      button.removeAttribute('disabled');
    }, 1000);
    if(this.modalType =='approve' && !this.imageURL){
      this.toaster.warning("Please fillup the required file"); return;
    }
    if(this.modalType !='approve' && (this.noteForm.controls.Note.value=='' || this.noteForm.controls.Note.value==undefined || !this.imageURL)){
      this.toaster.warning("Please fillup the required file"); return;
    }
    // var data = {
    //   ApprovalData: this.modalData[0].IsCustomApprovalLevel == true ? this.formatCustomApprovalData() : this.approvalData,
    //   TagAttachmentData: this.modalData[0],
    //   FinalLevelData: this.modalData[0].IsCustomApprovalLevel == true ? this.finalLevel : this.finalUserLevel,
    //   DocumentDecision: this.modalType=='approve'?1:2,
    //   DecisionNote: this.noteForm.controls.Note.value,
    //   ActionAt: new Date().toISOString().split('T')[0],
    // };
    this.modalData[0].iQuidiActionType = this.modalType == 'approve' ? iQuidiActionType.Approve : iQuidiActionType.Reject;
    this.modalData[0].DecisionNote = this.noteForm.controls.Note.value;

    this.spinner.show();
    this.documentServiceService.documentDecision(this.modalData[0]).subscribe(x=>{
      this.spinner.hide();
      if(x?.Success){
        if(this.modalType == 'approve') {
          this.toaster.success("Data approved successfully");
        }
        else {
          this.toaster.success("Data rejected successfully");
        }
        this.activeModal.close('Success');
      }
      else{
        this.toaster.error("Something went wrong");
        this.activeModal.close('Success');
      }
    });
  }

  formatCustomApprovalData(){
    let data = {
      ApprovalLevelItem: this.customApprovalData.CustomApprovalLevelItem,
      ApprovalMenuId: this.customApprovalData.ApprovalMenuId,
      CompanyId: this.customApprovalData.CompanyId,
      Id: this.customApprovalData.Id,
      LevelId: this.customApprovalData.LevelId
    };
    return data;
  }

  submitOTP(){
    debugger
    if (!this.otpForm.valid) {
      this.toastr.warning("Please fill all the required fields!");
      return;
    }

    this.validateMobileNo(this.otpForm.value.MobileNumber);

    if (this.isMobileValidNo) {
      // Mobile No

      const appVerifier = this.recaptchaVerifier;
      const phoneNumberString = "+88" + this.otpForm.value.MobileNumber; //"+8801715425456" this.ngForm.value.phonenumber;

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumberString, appVerifier)
        .then((confirmationResult) => {
          this.windowRef.confirmationResult = confirmationResult;
          this.showCaptcha = false;
          this.isOTPSent = true;
        })
        .catch((err) => {
          console.log("sms not sent", err);
        });
    }
  }

  submitEmailOTP(){
    debugger
    if (!this.emailForm.valid) {
      this.toastr.warning("Please fill all the required fields!");
      return;
    }
    this.validateEmail(this.emailForm.value.Email);
    if (this.isValidEmail) {
      let data : DocumentOTPViewModel = {
        TagId: this.modalData[0].Number,
        Email:this.emailForm.value.Email
      }
      this.documentServiceService.generateEmailOTP_ForDocumentApproval(data).subscribe(x => {
        this.toaster.success("OTP sent to your email.");
        this.isEmailOTPSent = true;
      });
    }
  }

  submitValidationCode = () => {
    if (this.otpForm.invalid) {
      this.toastr.warning("This field is required");
      return;
    }

    if (this.isMobileValidNo) {
      this.windowRef.confirmationResult
        .confirm(this.otpForm.value.OTPCode)
        .then((result) => {
          this.toastr.success("Verfication Successfully", "Success!");
          this.isSmSOTPVerified = true;
        })
        .catch((err) => {
          console.log(err);
          if (err.code == "auth/invalid-verification-code") {
            this.toastr.warning(
              "Your OTP is wrong, Please try the right one",
              "Error:"
            );
          } else if (err.code == "auth/code-expired") {
            this.toastr.warning(
              "Your OTP is expired .Please apply again for new OTP",
              "Error:"
            );
          } else {
            this.toastr.error("Sorry there was some error. Please check");
          }
        });
    }
  }

  submitEmailOTPCode(){
    if (!this.emailForm.valid) {
      this.toastr.warning("Please fill all the required fields!");
      return;
    }
    if (this.isValidEmail) {
      debugger
      let data: DocumentOTPViewModel = {
        TagId: this.modalData[0].Number,
        OTPCode: Number(this.emailForm.value.OTPCode)
      }
      this.documentServiceService.verifyOTP_ForDocumentApproval(data).subscribe(x => {
        if(x.Success){
          this.isEmailOTPVerified = true;
          this.toaster.success(x.Message);
        }else{
          this.toaster.error(x.Message);
        }
      });
    }
  }

  validateMobileNo(mobileInput){
    var mobilePattern = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
    this.isMobileValidNo = mobileInput.match(mobilePattern) ? true : false;
  };

  validateEmail(emailInput){
    var mobilePattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    this.isValidEmail = emailInput.match(mobilePattern) ? true : false;
  };


  canPressConfirmButton(){
    if(this.isEmailOTPRequiredForApproval && this.isMobileOTPRequiredForApproval){
      
      if(this.imageURL && this.isSmSOTPVerified && this.isEmailOTPVerified){
        return true;
      }else{
        return false;
      }

    }else if(this.isEmailOTPRequiredForApproval){
      
      if(this.imageURL && this.isEmailOTPVerified){
        return true;
      }else{
        return false;
      }

    }else if(this.isMobileOTPRequiredForApproval){
      
      if(this.imageURL && this.isSmSOTPVerified){
        return true;
      }else{
        return false;
      }

    }else{
      return true;
    }
  }
  

}
