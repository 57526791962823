import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DocumentLockDetails } from 'src/app/models/documentLockDetails.model';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-document-unlock-modal',
  templateUrl: './document-unlock-modal.component.html',
  styleUrls: ['./document-unlock-modal.component.css']
})

export class DocumentUnlockModalComponent implements OnInit {

  @Input() document;

  DocumentUnlockForm: FormGroup;
  DocumentLockDetails: FormArray;

  userRoleList: any = [];
  userEmailList: any = [];
  EmailList: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private pService: SettingsServiceService,
    private attachmentService: AttachmentServiceService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  getCompanyUsers() {
    this.spinner.show();
    this.pService.GetCompanyUsers().subscribe((x) => {
      this.pService.GetCompanyRolesWithBusinessUnit().subscribe((y) => {
        this.userRoleList = y.Result.filter(user => user.Name != 'PortalUser' && user.Name != 'Admin');
        this.EmailList = x.filter(user => user.RoleId != 1);
        this.userEmailList = x.filter(user => user.RoleId != 1);
        this.getDocumentLockDetails();
        this.spinner.hide();
      });
    });
  }

  initializeForm() {
    this.DocumentUnlockForm = this.fb.group({
      DocumentLockDetails: this.fb.array([])
    });
    this.getCompanyUsers();
  }

  getDocumentLockDetails() {
    this.spinner.show();
    this.attachmentService.GetDocumentLockDetails(this.document.Id).subscribe((x) => {
      this.spinner.hide();
      x.forEach((item, i) => {
        this.setParentData(item, i);
      });
    });
  }

  setParentData(item, i) {
    this.DocumentLockDetails = this.DocumentUnlockForm.get('DocumentLockDetails') as FormArray;
    this.DocumentLockDetails.push(this.addDetails(item, i));
  }

  addDetails(item, i): FormGroup{
    return this.fb.group({
      UserId: [item.RoleId],
      UserEmailId: [item.UserId]
    })
  }

  changeUserRole(details, index) {
    this.userEmailList = this.EmailList.filter(x => x.RoleId == details.value.UserId);
    this.DocumentLockDetails = this.DocumentUnlockForm.get('DocumentLockDetails') as FormArray;
    this.DocumentLockDetails.at(index).get('UserEmailId').patchValue(this.userEmailList[0]?.Id);
  }

  changeUserEmail(details, index) {
    this.DocumentLockDetails = this.DocumentUnlockForm.get('DocumentLockDetails') as FormArray;
    const userEmailList = this.EmailList.filter(x => x.Id === this.DocumentLockDetails.value[index].UserEmailId);
    if (userEmailList.length > 1) {
      for (let i = 0; i < userEmailList.length; i++) {
        if (userEmailList[i].RoleId == this.DocumentLockDetails.at(index).get('UserId')) {
          this.DocumentLockDetails.at(index).get('UserId').patchValue(userEmailList[i].RoleId);
          break;
        }
      }
    }
    else {
      this.DocumentLockDetails.at(index).get('UserId').patchValue(userEmailList[0].RoleId);
    }

  }

  setRoleWiseEmail(details: any){
    this.userEmailList = this.EmailList.filter(x => x.RoleId == details.value.UserId);
  }

  removeDocumentDetails(index: number, details: any) {
    this.DocumentLockDetails = this.DocumentUnlockForm.get('DocumentLockDetails') as FormArray;
    this.DocumentLockDetails.value.map((x, i) => {
      if(index == i) {
        this.DocumentLockDetails.removeAt(index);
      }
    });
  }

  addBlankDocumentDetails() {
    this.DocumentLockDetails = this.DocumentUnlockForm.get('DocumentLockDetails') as FormArray;
    this.DocumentLockDetails.push(this.createBlankDetails());
  }

  createBlankDetails(): FormGroup {
    return this.fb.group({
      UserId: [],
      UserEmailId: []
    });
  }

  update() {
    const data = this.formatData();
    this.spinner.show();
    this.attachmentService.UpdateDocumentLockDetails(data).subscribe((x) => {
      if (x.Success) {
        this.toaster.success("Updated successfully.");
        this.activeModal.close('Success');
      } else {
        this.toaster.error(x.Message);
        this.activeModal.close();
      }
      this.spinner.hide();
    },
    (err) => {
      this.toaster.error("ooh, something went wrong !");
    });
  }

  formatData(){
    let documentLockDetails: DocumentLockDetails[] = [];
    this.DocumentUnlockForm.value.DocumentLockDetails.forEach(lockDocument => {
      if(lockDocument.UserEmailId != null){
        let lockDocumentDetails = new DocumentLockDetails();
        lockDocumentDetails.Id = 0;
        lockDocumentDetails.CompanyId = 0;
        lockDocumentDetails.TagId = this.document.Id;
        lockDocumentDetails.UserId = lockDocument.UserEmailId
        documentLockDetails.push(lockDocumentDetails);
      }
      else{
        let userList = this.EmailList.filter(x => x.RoleId == lockDocument.UserId);
        userList.forEach(user => {
          let lockDocumentDetails = new DocumentLockDetails();
          lockDocumentDetails.Id = 0;
          lockDocumentDetails.CompanyId = 0;
          lockDocumentDetails.TagId = this.document.Id;
          lockDocumentDetails.UserId = user.Id
          documentLockDetails.push(lockDocumentDetails);
        });
      }
    });
    return documentLockDetails;
  }

}

