import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ReplenishmentViewModel } from 'src/app/models-viewModels/replenishmentViewModel';
import { ResponseModel } from 'src/app/models/responseModel.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReplenishmentService {

  baseurl = environment.baseUrl;
  constructor(
    private http: HttpClient
  ) { }

  public getReplenishmentList(): Observable<ReplenishmentViewModel[]> {
    const url = this.baseurl +'Replenishment/GetReplenishmentList';
    return this.http.get<ReplenishmentViewModel[]>(url).pipe(retry(1));
  }

  public replenish(replenishments: ReplenishmentViewModel[]): Observable<ResponseModel> {
    const url = this.baseurl +'Replenishment/Replenish';
    return this.http.post<ResponseModel>(url, replenishments);
  }
}
