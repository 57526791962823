<app-dashboard>
    <app-page-header-title mainTitle="{{itemListTitle}}" subTitle="Back" backURL="{{ redirectTo }}">
        <div class="full-width text-align-right">
            <div class="row">
                <div class="col-md-11" [ngClass]="{ 'col-md-12' : !canShowDropdownButton() }">
                    <button *ngif="this.pageValue==2" type="button" class="eCount-def-btn ml-4 " style="background: #5cac00;">Received Product</button>
                    <button *ngIf="isNotShipped() && !isAllProductsAvailableInReserved() && !isReturnWareHouse()" class="eCount-def-btn ml-4 blueBg" type="button" (click)="openCreateBasicBill()">Create Bill</button>
                    <button type="button" class="eCount-def-btn ml-4" style="background: #5cac00;" (click)="shippedProduct()" *ngIf="allData?.WareHouseData?.StatusId != 2">Shipped Product</button>
                    <button type="button" class="eCount-def-btn ml-4 blueBg" (click)="returnProduct()" *ngIf="canShowReturnButton()">Return</button>
                </div>
                <div class="col-md-1">
                    <div style="padding: 7px 10px; color: #c7c2c2;" *ngIf="canShowDropdownButton()">
                        <div class="dropdown">
                            <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
                            </div>
                            <div class="dropdown-menu context-menu-wrapper mt-3" aria-labelledby="navbarDropdown" style="width: 100%;">
                                <div class="context-menu-item" style="cursor: pointer;" (click)="CancelItemReturned()">
                                  <span class="context-menu-text text-center">Cancel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-page-header-title>
    
    <div class="m" id="printBill">
        <div class="row">
            <div class="col-md-12 d-flex f-ss-14px" id="my1">
                <div class="col-md-4 d-block pl-1">
                    <p class="ToHeading">To</p>
                    <div>Name :{{ allData?.WareHouseData?.Contacts ? allData?.WareHouseData?.Contacts?.CompanyName : ' ' }}</div>
                    <div>Email :{{this.allData?.WareHouseData?.Contacts ? this.allData?.WareHouseData?.Contacts?.Email :' ' }}</div>
                    <div>Address :{{this.allData?.WareHouseData?.Contacts ? this.allData?.WareHouseData?.Contacts?.StreetAddress :' ' }}</div>
                    <div *ngIf="this.allData?.WareHouseData?.ParentId && allData?.WareHouseData?.OperationType != 7 && allData?.WareHouseData?.OperationType != 8"> BackOrder Of : <a [href]="getWareHouseBackOrderReferenceNoLink()">{{getWareHouseBackOrderReferenceNo()}}</a>
                    </div>
                </div>
                <div class="col-md-4">
                    <p class="ToHeading">Date</p>
                    <div>Created Date :{{convertDateIntoDisplay(this.allData?.WareHouseData?.Contacts? this.allData?.WareHouseData?.Contacts?.CreatedAt :' ') }}</div>
                    <div>Order Date :{{convertDateIntoDisplay(this.allData?.WareHouseData?.Contacts? this.allData?.WareHouseData?.Contacts?.OperationDate :' ') }}</div>
                </div>
                <div class="col-md-4">
                    <p></p>
                    <div>Reference No # <a href={{getReferenceNoLink()}}>{{getReferenceNo()}}</a>
                    </div>
                    <div *ngIf="isavailable && statusId != 2">Product Availability: <span class="text-danger" style="margin-left: 5px;" *ngIf="this.allData?.WareHouseData?.StatusId!=2 && !isProductAvailable">Not Available</span>
                        <span class="text-danger" style="margin-left: 5px;" *ngIf="this.allData?.WareHouseData?.StatusId==2">Not Applicable</span>
                        <span class="text-success" style="margin-left: 5px;" *ngIf="this.allData?.WareHouseData?.StatusId!=2 && isProductAvailable">Available</span>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="headSection">
            <form [formGroup]="billForm">
                <div class="row d-block topSection f-ss-14px">
                    <div class="d-flex">
                        <div class="col-md-3">Product</div>
                        <div *ngIf="!isNotShipped()" class="col-md-1"></div>
                        <div class="col-md-3">Demand</div>
                        <div *ngIf="!isNotShipped()" class="col-md-1"></div>
                        <div *ngIf="isNotShipped()" class="col-md-3">Reserved</div>
                        <div class="col-md-3">Shipped</div>
                    </div>
                    <hr />
                    <div>
                        <div class="invoiceGridItemRow d-block" formArrayName="ShippedFormArray" *ngFor="let bill of billForm.get('ShippedFormArray')['controls']; let i = index;">
                            <div class="row pb-1 pt-2" [formGroupName]="i">
                                <div class="col-md-2 margin-top-5">
                                    <input class="form-control" formControlName="Product" readonly>
                                </div>
                                <div class="col-md-1 margin-top-5"></div>
                                <div *ngIf="!isNotShipped()" class="col-md-1 margin-top-5"></div>
                                <div class="col-md-2 margin-top-5">
                                    <input class="form-control" formControlName="Demand" readonly>
                                </div>
                                <div class="col-md-1 margin-top-5"></div>
                                <div *ngIf="!isNotShipped()" class="col-md-1 margin-top-5"></div>
                                <div *ngIf="isNotShipped()" class="col-md-2 margin-top-5">
                                    <input class="form-control" formControlName="Reserved" readonly>
                                </div>
                                <div *ngIf="isNotShipped()" class="col-md-1 margin-top-5"></div>
                                <div class="col-md-2 margin-top-5">
                                    <input class="form-control" formControlName="Shipped" [readonly]="allData.WareHouseData.StatusId==2" appDecimaNumericValidator required (focus)="onFocusEvent($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-dashboard>