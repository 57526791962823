import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductServiceService } from 'src/app/service/Products/product-service.service';

@Component({
  selector: 'app-update-item-quantity-popup',
  templateUrl: './update-item-quantity-popup.component.html',
  styleUrls: ['./update-item-quantity-popup.component.css']
})

export class UpdateItemQuantityPopupComponent implements OnInit {

  UpdateForm: FormGroup;
  @Input() ItemId: number;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private productServiceService: ProductServiceService
  ) { }

  ngOnInit(): void {
    this.initializeForm()
  }

  initializeForm() {
    this.UpdateForm = this.fb.group({
      ItemId: [this.ItemId],
      Quantity: ['', Validators.required]
    });
  }
  

  save() {
    if(!this.UpdateForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    if(Number(this.UpdateForm.value.Quantity) == 0.0 || Number(this.UpdateForm.value.Quantity) == 0){
      this.toaster.warning('Quantity can not be zero!');
      return;
    }
    this.spinner.show();
    this.productServiceService.updateItemQuantity(this.UpdateForm.value.ItemId, this.UpdateForm.value.Quantity).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Quantity updated Successfully');
        this.spinner.hide();
        this.activeModal.close('Success');
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
        this.activeModal.close();
      }
    });
  }

}

