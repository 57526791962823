import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterLinkRendereComponent } from 'src/app/components/router-link-rendere/router-link-rendere.component';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { PurchaseOrderService } from 'src/app/service/PurchaseOrder/purchase-order.service';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-purchase-order-bill-details',
  templateUrl: './purchase-order-bill-details.component.html',
  styleUrls: ['./purchase-order-bill-details.component.css']
})
export class PurchaseOrderBillDetailsComponent implements OnInit {

  gridApi;
  PurchaseOrderId: any;
  PurchaseOrderData: any = [];
  isBillCreated: boolean = false;
  billStatus: string;
  chartOfAccount: any = [];
  allSaleableItem: any = [];
  taxRateList:any=[];
  billDetailsOfPurchaseOrder: any;
  filteredBills = [];
  PurchaseOrderNo: string = null;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private spinner: NgxSpinnerService,
      private purchaseService: PurchaseOrderService,
      public fileEmitterService: FileEmitterService,
      private accountingService: AcountingServiceService,
      private settingService:SettingsServiceService) { }

  ngOnInit(): void {
    this.PurchaseOrderId = this.route.snapshot.paramMap.get("id");
    this.PurchaseOrderNo = this.route.snapshot.paramMap.get("orderNo")
    this.getOrderDetails(this.PurchaseOrderId);
    this.getBillDetailsForPurchaseOrder();
    this.getAllSaleableItem();
    this.getTaxRateList();
    this.getChartOfAccount();
  }

  getOrderDetails = async (id:any) => {
    this.spinner.show();
    this.purchaseService.GetOrderDetails(id).subscribe((x) => {
      console.log(x);
      if (x) {
        this.fileEmitterService.emitFileRefIdUpdate({id:this.PurchaseOrderId});
        this.PurchaseOrderNo = x.Details.OrderNo;
        this.PurchaseOrderData = x.Details;
        this.getAccountName(this.PurchaseOrderData.PurchaseOrderItems);
        this.spinner.hide();
      }
    })
  }

  getBillDetailsForPurchaseOrder(){
    this.purchaseService.GetBillDetailsForPurchaseOrder(this.PurchaseOrderId).subscribe( (x) => {
      this.billDetailsOfPurchaseOrder = x;
      this.filteredBills = x.bills;
      for(var i = 0; i < this.filteredBills.length; i++){
        this.filteredBills[i].ReferenceNo = 'PO-' + this.filteredBills[i].ReferenceNo;
        if(this.filteredBills[i].IsReverseTransaction == true)
          this.filteredBills[i].FormattedBillNo = 'RBill-' + this.filteredBills[i].BillNo;
        else
          this.filteredBills[i].FormattedBillNo = 'Bill-' + this.filteredBills[i].BillNo;
      }
    });
  }

  getAccountName=(item)=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

        item.forEach(element =>
        {
           var x=this.chartOfAccount.find(p=> p.Id===element.AccountId);
           element.AccountName=x.AccountName;
        });
      })
      return item;
  }

  getAllSaleableItem = () => {
    this.purchaseService.GetPurchaseableItems().subscribe((x) =>
      this.allSaleableItem = x)
  }
  formatItem(id: string) {

    let value = this.allSaleableItem.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.ItemName;
    }
  }

  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  }

  formatTaxRate(id: number) {

    let value = this.taxRateList.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }

  getChartOfAccount=()=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;
    })
  }

  domLayout = 'autoHeight'
  rowHeight = 275
  columnDefs = [
  {
      headerName: 'Bill No', field: 'FormattedBillNo', Width: 250,
      cellRendererFramework: RouterLinkRendereComponent,
      cellRendererParams: { inRouterLink: '/expense/bill/', orderNo: this.route.snapshot.paramMap.get("orderNo") }
    },
    { headerName: 'Ref', field: 'ReferenceNo', Width: 200 },
    { headerName: 'Date', field: 'BillDate', valueFormatter: this.convertDateIntoDisplay, Width: 90 },
    { headerName: 'Due Date', field: 'DueDate', valueFormatter: this.convertDateIntoDisplay,  Width: 105 },
    { headerName: 'Bill Amount', field: 'PaidAmount', valueFormatter: this.convertPaidAmount, Width: 135 },
    { headerName: 'Due', field: 'PaidAmount', valueFormatter: this.convertPaidDueAmount, Width: 100 },
    { headerName: 'Status', field: 'StatusId',valueFormatter: this.setBillStatus, Width: 100 }
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
   scrollbarWidth: 0,
   resizable: true
  };

  convertPaidAmount(value) {
    let valueData = Number(value.data.BillAmount.toFixed(2))
    return valueData.toLocaleString();
  }

  convertPaidDueAmount(value) {
    let amount = value.data.BillAmount - (value.data.PaidAmount + value.data.DiscountAmount);
    return amount.toLocaleString();
  }

  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }
  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  setBillStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return 'Draft';
      case 2:
        return 'A. Approval';
      case 3:
        return 'A. Payment';
      case 4:
        return 'Paid';
      case 6:
        return 'Overdue';
      case 7:
        return 'Rejected';
      default:
        return '';
    }
  }

  onGridReady(event) {
    this.gridApi = event.api;
  }

  public createNewBill = () => {
    this.router.navigate(["expense/create-new-bill"], {
      queryParams: { ExternalReferenceNo: this.PurchaseOrderData.Id, IsBillReverseTransaction: this.billDetailsOfPurchaseOrder?.unbilled < 0 ? true : false },
    });
  };

  closeModal() {
    document.getElementById("logoUploader").classList.remove("show")
    document.getElementById("logoUploader").classList.remove('shown');
  }

  public createNewBillPopup = () => {
    document.getElementById("logoUploader").classList.add("show")
    document.getElementById("logoUploader").classList.add('shown');
  }
}
