<app-dashboard>
    <app-page-header-title
    mainTitle="Expense"
    subTitle="Back" 
    backURL="/sales/sales-order-details/{{SalesOrderId}}">
    </app-page-header-title>
    <div style="margin-top: 15px;">
        <div class="row pt-3">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="salesExpenses"
                [columnDefs]="expenseGridOptions.columnDefs"
                [domLayout]="expenseGridOptions.domLayout"
                [defaultColDef]="expenseGridOptions.defaultColDef"
                (gridReady)="onExpenseGridReady($event)"
                >
            </ag-grid-angular>
        </div>
    </div>
</app-dashboard>