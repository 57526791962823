import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { FixedAssetService } from "src/app/service/FixedAsset/fixedasset.service";
import { AddAssigneeComponent } from "../add-assignee/add-assignee.component";
import { AssigneeService } from "src/app/service/Assignee/assignee.service";
import { AssignedItemComponent } from "../assigned-item/assigned-item.component";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { AssignedItem } from "src/app/models/assignee";
import { AssignStatus } from "src/app/enum/assign-status";
import { CellClickedEvent } from "ag-grid-community";
import { AssignedItemDetailsComponent } from "../assigned-item-details/assigned-item-details.component";
import { AssetItemDetailsComponent } from "../asset-item-details/asset-item-details.component";
import { ReturnAssignedItemModalComponent } from "../return-assigned-item-modal/return-assigned-item-modal.component";
import { FixedAssetItem } from "src/app/models/fixedAsset.model";

@Component({
  selector: 'app-fixed-asset-assigned-unassigned-list',
  templateUrl: './fixed-asset-assigned-unassigned-list.component.html',
  styleUrls: ['./fixed-asset-assigned-unassigned-list.component.css']
})

export class FixedAssetAssignedUnassignedListComponent implements OnInit {
  gridApi;
  status: string;
  AllItems: any[] = [];
  AssignedItems: AssignedItem[];
  Unassignedtems: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private assigneeService: AssigneeService,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private pService:FixedAssetService
  ) {}

  ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get("status");
    this.getAssignedItemList();
  }

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  getAssignedItemList() {
    this.assigneeService.GetAllItemList().subscribe((x) => {
      this.AllItems = x.AllItems.Result;
      this.AssignedItems = x.AssignedItems;
      this.Unassignedtems = x.AllItems.Result.filter(x => x.AssignedStatus == 2);
      this.pageLoad(this.status);
    });
  }

  public pageLoad(status: string) {
    this.status = status;
    switch (status) {
      case "All":
        this.AllItems = this.AllItems;
        break;
      case "Assigned":
        this.AssignedItems = this.AssignedItems;
        break;
      case "Unassigned":
        this.Unassignedtems = this.AllItems.filter(x => x.AssignedStatus == 2);
        break;
    }
  }

  domLayout = "autoHeight";
  rowHeight = 275;
  assignedColumnDefs = [
    {
      headerName: "Assignee No",
      field: "AssignedNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      onCellClicked: (event: CellClickedEvent) => this.openAssignedItemDetailsModal(event.data)
    },
    { headerName: "Asset Name", field: "AssetName" },
    { headerName: "Assignee Name", field: "AssigneeName" },
    {
      headerName: "Assigned Date",
      field: "AssignedDate",
      valueFormatter: this.convertDateIntoDisplay,
    },
    { headerName: "Used Days", field: "UsedDays" },
    {
      headerName: "Estimated Return Date",
      field: "EstimatedReturnDate",
      cellRenderer: (params) => {
        return this.convertEstimatedReturnDateIntoDisplay(params.value);
      }
    },
    { headerName: "Asset Rank", field: "AssetRank" },
    { headerName: "Asset No", 
      field: "AssetNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      onCellClicked: (event: CellClickedEvent) => this.openAssetItemDetailsModal(event.data) },
  ];

  unassignedColumnDefs = [
    { headerName: "Asset No",
      field: "AssetNumber", 
      cellStyle: { color: "blue", cursor: "pointer" },
      onCellClicked: (event: CellClickedEvent) => this.openAssetItemDetailsModal(event.data) 
    },
    { headerName: "Asset Name", field: "AssetName" },
  ];

  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }

  convertEstimatedReturnDateIntoDisplay(date) {
    if (date != null) {
      let dateString = moment(date).format("Do MMM YYYY");
      return dateString;
    } else {
      return '-';
    }
  }

  allColumnDefs = [
    { headerName: "Asset No",
      field: "AssetNumber", 
      cellStyle: { color: "blue", cursor: "pointer" },
      onCellClicked: (event: CellClickedEvent) => this.openAssetItemDetailsModal(event.data) 
    },
    { headerName: "Asset Name", field: "AssetName" },
    { headerName: "Status",
     field: "AssignedStatus",
     cellRenderer: (params) => {
      return this.convertStatusIntoDisplay(params.value);
    }
     }
  ];

  convertStatusIntoDisplay(status: any) {
    if(status == 1) {
      return 'Assigned'
    }
    else {
      return 'Unassigned'
    }
  }

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
    scrollbarWidth: 0,
    resizable: true,
  };

  onGridReady(event) {
    this.gridApi = event.api;
  }

  openAssignedItemDetailsModal(data: any) {
    this.spinner.show();
    this.assigneeService.getAssignedItemById(data.FixedAssetItemId).subscribe((x) => {
      if(x) {
        const modalRef = this.modalService.open(AssignedItemDetailsComponent, { size: "xl", backdrop: "static", keyboard: false, centered: true,});
        modalRef.componentInstance.fromParent = x;
        modalRef.componentInstance.parentStatus = this.status;
        this.spinner.hide();
        modalRef.result.then(
          (result) => {
          },
          (reason) => {
            console.log(reason);
          }
        );
      }
    });
  }

  openAssetItemDetailsModal(item: any) {
    this.spinner.show();
    let id = item.Id;
    if(this.status == 'Assigned'){
      id = item.FixedAssetItemId;
    }
    this.pService.GetAssetItemDetails(id).subscribe((x) => {
      const modalRef = this.modalService.open(AssetItemDetailsComponent,{ size: 'lg',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = x;
      modalRef.componentInstance.fromAssignee = true;
      this.spinner.hide();
      modalRef.result.then((result) => {
        this.spinner.hide();
      }, (reason) => {
        console.log('Dismissed action: ' + reason);
      });
    },(err)=>{
      this.spinner.hide();
    })
  };

}


