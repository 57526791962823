import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { createContact } from "./../../models/main.model";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { createBSUnit } from "src/app/models/businessUnit.model";
import { PendingViewModel } from "src/app/models-viewModels/pendingViewModel";
import { ResponseModel } from "src/app/models/responseModel.model";

@Injectable({
  providedIn: "root",
})
export class MainServiceService {
  baseurl = environment.baseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private http: HttpClient) { }

  httpOptionAttachment = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.userInfo ? this.userInfo.Token : null}`,
    }),
  };

  //save saveContact
  public saveContact(data: any): Observable<any> {
    return this.http
      .post<createContact>(this.baseurl + "Contact/Save", data)
      .pipe(retry(1));
  }

  public saveBankAccount(data: any): Observable<any> {
    return this.http
      .post<createContact>(this.baseurl + "BankAccount/Save", data)
      .pipe(retry(1));
  }

  public updateBankAccount(data: any): Observable<any> {
    return this.http
      .post<createContact>(this.baseurl + "BankAccount/Update", data)
      .pipe(retry(1));
  }
  //get all bank account
  public GetAllBank(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "BankAccount/GetBankAccounts")
      .pipe(retry(1));
  }

  //get bank account By Id
  public GetBankDetialsById(data: any): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `BankAccount/GetBankDetialsById?id=${data}`)
      .pipe(retry(1));
  }

  //get all customer
  public GetAllContact(): Observable<any> {
    return this.http.get<any>(this.baseurl + "Contact/GetAll").pipe(retry(1));
  }

  //get all customer
  public GetContactBalance(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Contact/GetContactBalance")
      .pipe(retry(1));
  }

  // Get All Deliver yPartner
  public GetAllDeliveryPartner(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + 'Contact/GetAllDeliveryPartner')
      .pipe(retry(1));
  }

  //get all customer
  public GetAllCustomer(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Contact/GetAllCustomer")
      .pipe(retry(1));
  }

  public GetReceiveableContactsList(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Invoice/GetCustomerwiseInvoiceDue")
      .pipe(retry(1));
  }

  public GetPayableContactsList(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Bill/GetCustomerwiseBillDue")
      .pipe(retry(1));
  }

  //get all supplier
  public GetAllSupplier(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Contact/GetAllSupplier")
      .pipe(retry(1));
  }
  //get  customer by Id
  public GetCustomerById(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `Contact/GetCustomerById?id=${data}`)
      .pipe(retry(1));
  }
  //MENU
  public GetMenu(): Observable<any> {
    return this.http.get<any>(this.baseurl + "Menu/GetMenu").pipe(retry(1));
  }

  //save organization
  public SaveOrganizationData(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Company/Save", data)
      .pipe(retry(1));
  }
  // Buy Organaization
  public SaveAndBuyOrganizationData(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Company/SaveAndBuy", data)
      .pipe(retry(1));
  }
  //update organization
  public UpdateOrganizationData(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Company/Update", data)
      .pipe(retry(1));
  }

  //setSelectedOrganization
  public setSelectedOrganization(data: any): Observable<any> {
    var url = this.baseurl + `Account/ResetToken?companyId=${data}`;
    return this.http.get<any>(url).pipe(retry(1));
  }
  //get User Details
  public GetUserData(data: any): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `Account/GetUserDetails?email=${data}`)
      .pipe(retry(1));
  }
  //get User Details By Id
  public GetUserDetailsId(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `Account/GetUserDetailsId`)
      .pipe(retry(1));
  }
  //update User Details
  public UpdateUserData(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Account/UpdateUserDetails", data)
      .pipe(retry(1));
  }

  //update Signature Data
  public saveSignature(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Account/UploadSignature", data)
      .pipe(retry(1));
  }

  //delete signature
  public deleteSignature(data: any): Observable<any> {
    return this.http.get<any>(this.baseurl + `Account/DeleteSignature?blobName=${data}`)
  }
  public UpdateUserPasswordData(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Account/UpdateUserPasswordDetails", data)
      .pipe(retry(1));
  }
  //Company/GetCompanyDetails?id=
  public getSelectedOrganizationDetails(data: any): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `Company/GetCompanyDetails?id=${data}`)
      .pipe(retry(1));
  }

  //Invitation/SendInvitation
  public inviteNewUserData(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Invitation/SendInvitation", data)
      .pipe(retry(1));
  }
  public EditUserData(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Invitation/UpdateUser", data)
      .pipe(retry(1));
  }
  // update subsciber
  public CheckInvitationType(data: any): Observable<any> {
    return this.http
      .post<any>(
        this.baseurl + `Invitation/CheckInvitationType?trackNo=${data}`,
        {}
      )
      .pipe(retry(1));
  }
  //Invitation/AcceptInvitation?trackNo=
  public AcceptInvitation(data: any): Observable<any> {
    return this.http
      .post<any>(
        this.baseurl + `Invitation/AcceptInvitation?trackNo=${data}`,
        {}
      )
      .pipe(retry(1));
  }

  //Invitation/RevokeUser
  public RevokeUser(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Invitation/RevokeUser", data)
      .pipe(retry(1));
  }
  // revoke use role
  public RevokeUserRole(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Menu/RemoveUserFromRole", data)
      .pipe(retry(1));
  }
  // Cancel Invitation
  public CancelInvitationUser(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Invitation/CancelInvitation", data)
      .pipe(retry(1));
  }
  //Account/CheckForgotPasswordEmail?email=
  public CheckForgotPasswordEmail(data: any): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `Account/CheckForgotPasswordEmail?email=${data}`)
      .pipe(retry(1));
  }
  //Account/ResetPassword?trackNo=&newPassword=
  public ResetPassword(data: any, newPassword: any): Observable<any> {
    return this.http
      .get<any>(
        this.baseurl +
        `Account/ResetPassword?trackNo=${data}&newPassword=${newPassword}`
      )
      .pipe(retry(1));
  }

  //Invitation/AcceptInvitationNewUser
  public AcceptInvitationNewUser(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Invitation/AcceptInvitationNewUser", data)
      .pipe(retry(1));
  }

  public GetUserList(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `CompanyUser/GetUserList`)
      .pipe(retry(1));
  }

  public GetSubscribedUserList(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "CompanyUser/GetMyCompanysUserList")
      .pipe(retry(1));
  }

  public uploadFiles(ff: FormData) {
    return this.http
      .post(
        this.baseurl + "AzureFileStorageApi/UploadFile",
        ff,
        this.httpOptionAttachment
      )
      .pipe(retry(1));
  }
  public GetSubscribedCompanyListByUser(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "AdminPanelSubscription/GetSubscriptionByUser")
      .pipe(retry(1));
  }
  public GetCompanyTotalUser(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "SubscriptionPlan/GetTotalUserByCompany")
      .pipe(retry(1));
  }
  public GetPendingInvoices(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Invoice/PendingInvoiceList")
      .pipe(retry(1));
  }
  public GetPendingBills(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Bill/PendingBillList")
      .pipe(retry(1));
  }

  // get pending document
  public getPendingDocument(): Observable<any> {
    return this.http.get<any>(this.baseurl + "Settings/UserApprovalDocuments").pipe(retry(1));
  }

  public getPendingApprovalCount(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Settings/PendingApprovalCount")
      .pipe(retry(1));
  }

  public GetPendingPurchaseOrders(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "PurchaseOrder/PendingPurchaseOrderList")
      .pipe(retry(1));
  }

  public GetPendingLoanList(loanType: number): Observable<PendingViewModel[]> {
    return this.http.get<PendingViewModel[]>(this.baseurl + "BPLoan/GetPendingLoanList"+"?loanType="+loanType);
  }

  public ApproveInvoice(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Invoice/ApproveInvoice", data)
      .pipe(retry(1));
  }

  public ApproveSingleInvoice(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Invoice/ApproveSingleInvoice', data)
      .pipe(
        retry(1)
      )
  }

  public ApproveBill(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "Bill/ApproveBill", data)
      .pipe(retry(1));
  }
  public ApprovePurchaseData(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "PurchaseOrder/ApprovePurchaseOrder", data)
      .pipe(retry(1));
  }

  public UpdateStatusGrantLoanList(data: any): Observable<ResponseModel>{
    return this.http.post<any>(this.baseurl + "BPLoan/UpdateStatusGrantLoanList", data);
  }

  public GetBSReports(data: any): Observable<any> {
    return this.http.get<any>(this.baseurl + `Contact/GetBSReport?fromDate=${data.fromDate}&toDate=${data.toDate}&contactId=${data.contactId}`)
      .pipe(
        retry(1)
      );
  }

  public GetCurrentUserInvoiceApprovalStatus(): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/GetCurrentUserInvoiceApprovalStatus`).pipe(retry(1));
  }

  public GetCanApproveForCreateInvoice(): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/GetCanApproveForCreateInvoice`).pipe(retry(1));
  }

  //Save Business Unit
  public saveBSUnit(data: any): Observable<any> {
    return this.http.post<createBSUnit>(this.baseurl + "BusinessUnit/Save", data);
  }

  //Update Business Unit
  public updateBSUnit(data: any): Observable<any> {
    return this.http.post<createBSUnit>(this.baseurl + "BusinessUnit/Update", data);
  }

  //Get All Business Unit
  public GetAllBusinessUnit(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + 'BusinessUnit/GetAllBusinessUnit')
      .pipe(retry(1));
  }

  public RejectBill(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + 'Bill/RejectBills', data)
      .pipe(retry(1));
  }

  public RejectInvoice(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + 'Invoice/RejectInvoices', data)
      .pipe(retry(1));
  }

  public GetMaxBillId(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Bill/GetMaxBillId').pipe(retry(1));
  }

  public ReActivateSubscription(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Company/ReActivateSubscription');
  }

  public UserCustomApprovalForDocument(referenceId: number): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/UserCustomApprovalForDocument?referenceId=${referenceId}`).pipe(retry(1));
  }

  public IsNull(data: any){
    if(data == null || data == 'null' || data == undefined || data == 'undefined' || data == '') return true;
    return false;
  }

}


