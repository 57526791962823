import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-order-progress',
  templateUrl: './sales-order-progress.component.html',
  styleUrls: ['./sales-order-progress.component.css']
})
export class SalesOrderProgressComponent implements OnInit {

  data:any;

  agInit(params){
    this.data = params.value;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
