import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal  } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { AssetDisposeDetailsComponent } from '../asset-dispose-details/asset-dispose-details.component';
import { AssetItemCreateComponent } from '../asset-item-create/asset-item-create.component';
import { AssigneeService } from 'src/app/service/Assignee/assignee.service';
import { AssignedItem } from 'src/app/models/assignee';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asset-item-details',
  templateUrl: './asset-item-details.component.html',
  styleUrls: ['./asset-item-details.component.css']
})
export class AssetItemDetailsComponent implements OnInit {
  @Input() fromParent: any;
  @Input() fromAssignee: boolean = false;
  @Input() disposalData: any;
  public activeTabId: number =1;
  depreciationValueList: any[]=[];
  AssetNumber: string;
  assignedItem: any;
  purchaseDate: string;
  constructor(public activeModal: NgbActiveModal,
    private pService:FixedAssetService,
    private modalService:NgbModal,
    private spinner: NgxSpinnerService,
    private assigneeService: AssigneeService,
    private toaster: ToastrService,
    private router:Router
    ) { }

  ngOnInit(): void {
    this.getDepreciationValue();
    this.formatType();
  }

  formatType() {
    this.purchaseDate = moment(this.fromParent?.PurchaseDate).format("Do MMM YYYY");
    if(this.fromParent.AssetNumber.length < 2) {
      this.AssetNumber = "FA-00"+this.fromParent.AssetNumber;
    }
    else{
      this.AssetNumber = "FA-"+this.fromParent.AssetNumber;
    }
  }

  private getDepreciationValue = () => {
    this.pService.GetDepreciationDetails(this.fromParent.Id).subscribe((x) => {
      this.depreciationValueList = x;
    },(err)=>{
    })
  };

  getAccumulatedDepreciation(index) {
   return this.depreciationValueList.slice(0, index+1).reduce( (acc, val) => acc + val.DepreciationAmount, 0 );
  }

  public setActivePanel=(v)=>{
    this.activeTabId=v;
  }

  openDisposeModal(){
    this.assigneeService.getAssignedItemByFixedAssetItemId(this.fromParent.Id).subscribe((x) => {
      this.assignedItem = x;
      if(!!this.assignedItem && this.assignedItem.AssignStatus == 1) {
        this.toaster.warning('This asset can not be disposed. It is assigned to '+this.assignedItem.Assignee.Name+'. If you want to dispose it, first you need to return this asset.');
        return;
      }
      else{
        const modalRef = this.modalService.open(AssetDisposeDetailsComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
        modalRef.componentInstance.fromParent = this.fromParent;
        this.activeModal.close();
        modalRef.result.then((result) => {
          console.log(result);
        }, (reason) => {
          console.log('Dismissed action: ' + reason);
        });
      }
    });
    
  }

  public EditAssetItem() {
    this.spinner.show();
    this.pService.GetAssetItemDetails(this.fromParent.Id).subscribe((x) => {
      const modalRef = this.modalService.open(AssetItemCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = x;
      this.spinner.hide();
      this.activeModal.close();
      modalRef.result.then((result) => {
      }, (reason) => {
        console.log('Dismissed action: ' + reason);
      });
    },(err)=>{
      this.spinner.hide();
    })
  }

  DeleteFixedAssetItem() {
    this.spinner.show();
    this.pService.ReverseDepreciation(this.fromParent).subscribe((x) => {
      if(x){
        this.pService.DeleteRegisteredFixedAssetItem(this.fromParent.Id).subscribe((y) => {
          if(y){
            this.toaster.success('Asset item has been successfully deleted.');
            this.activeModal.close();
            this.spinner.hide();
            location.reload();
          }
        });
      }
    });
  }

}
