import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManufactureItemType } from 'src/app/enum/manufacture-order-item-type';
import { ManufactureOrderStatus } from 'src/app/enum/manufacture-order-status';
import { ManufactureOrderDetailsPageViewModel } from 'src/app/models-viewModels/manufactureOrderDetailsPageViewModel';
import { ManufactureOrderProductionPageViewModel } from 'src/app/models-viewModels/manufactureViewModel.model';
import { ItemDetails } from 'src/app/models/inventoryOverview.model';
import { ManufactureOrder, ManufactureOrderDetails } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';

@Component({
  selector: 'app-manufacutre-order-details',
  templateUrl: './manufacutre-order-details.component.html',
  styleUrls: ['./manufacutre-order-details.component.css']
})
export class ManufacutreOrderDetailsComponent implements OnInit {

  ManufactureOrderForm: FormGroup;
  OrderItems: FormArray;
  MiscellaneousItems: FormArray;

  manufactureOrderId: number;
  manufactureOrderData: ManufactureOrderDetailsPageViewModel;
  itemMap: Map<number, ItemDetails> = new Map<number, ItemDetails>();

  totalOrderItemsConsume: number = 0;
  totalMiscellaneousItemsConsume: number = 0;

  constructor(
    private route: ActivatedRoute,
    private manufactureService: ManufactureService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder 
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let no = +params['id'];
      this.manufactureOrderId = no;
      this.getOrder();
   });
   this.initializeForm();
  }

  initializeForm() {
    this.ManufactureOrderForm = this.fb.group({
      Id: [0],
      FinishedProductId: [''],
      FormulaName: [''],
      Quantity: [''],
      CreatedDate: [''],
      StartDate: [''],
      EndDate: [''],
      Reference: [''],
      BatchNo: [''],
      OrderItems: this.fb.array([]),
      MiscellaneousItems: this.fb.array([])
    });
    this.ManufactureOrderForm.get('CreatedDate').patchValue(new Date());
  }

  getOrder(){
    this.manufactureService.getOrder(this.manufactureOrderId).subscribe(x => {
      this.manufactureOrderData = x;
      x.ItemDetails.forEach(obj => {
        let key = obj.Id;
        this.itemMap.set(key, obj);
      });
      this.setParentData(this.manufactureOrderData);
      this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.NORMAL).forEach((item, i) => {
        this.totalOrderItemsConsume += item.TotalConsumed;
        this.addItem(item, i);
      });

      this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).forEach((item, i) => {
        this.totalMiscellaneousItemsConsume += item.TotalConsumed;
        this.addMiscellaneousItem(item, i);
      });
    });
  }

  setParentData(orderData) {
    let createdDate = moment(orderData.ManufactureOrder.CreatedDate).format("Do MMM YYYY");
    let startDate = moment(orderData.ManufactureOrder.StartDate).format("Do MMM YYYY");
    let endDate = moment(orderData.ManufactureOrder.EndDate).format("Do MMM YYYY");
    this.ManufactureOrderForm.controls['Id'].patchValue(orderData.ManufactureOrder.Id);
    this.ManufactureOrderForm.controls['FinishedProductId'].patchValue(orderData.ManufactureOrder.Item.ItemCode);
    this.ManufactureOrderForm.controls['FormulaName'].patchValue(orderData?.ManufactureFormula?.FormulaName);
    this.ManufactureOrderForm.controls['Quantity'].patchValue(orderData.ManufactureOrder.ProductionQuantity);
    this.ManufactureOrderForm.controls['CreatedDate'].patchValue(createdDate);
    this.ManufactureOrderForm.controls['StartDate'].patchValue(startDate);
    this.ManufactureOrderForm.controls['EndDate'].patchValue(endDate);
    this.ManufactureOrderForm.controls['Reference'].patchValue(orderData.ManufactureOrder.Reference);
    this.ManufactureOrderForm.controls['BatchNo'].patchValue(orderData.ManufactureOrder.BatchNo);
  }

  addItem(item: ManufactureOrderDetails, i: number) {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.push(this.createItem(item, i));
  }

  createItem(item: ManufactureOrderDetails, i: number): FormGroup {
    return this.fb.group({
      RawMaterialId: [item.Item.ItemName],
      RecipeQuantity: [this.getItemRecipeQuantity(item).toFixed(2)],
      Balance: [this.getItemBalance(item)],
      ToConsume: [item.UsedItem]
    });
  }

  getItemRecipeQuantity(item: ManufactureOrderDetails) {
    let formulaQuantity = this.manufactureOrderData.ManufactureFormula.Quantity;
    let itemQuantity = this.manufactureOrderData.ManufactureFormula.ManufactureFormulaDetails.filter(x => x.ItemId == item.ItemId)[0].Quantity;
    let recipeQuantity = itemQuantity / formulaQuantity;
    return recipeQuantity;
  }

  getItemBalance(item: ManufactureOrderDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.UsedItem <= stockQuantity ? item.UsedItem : stockQuantity;
  }

  addMiscellaneousItem(item: ManufactureOrderDetails, i: number) {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.push(this.createMiscellaneousItem(item, i));
  }

  createMiscellaneousItem(item: ManufactureOrderDetails, i: number): FormGroup {
    return this.fb.group({
      MiscellaneousRawMaterialId: [item.Item.ItemName],
      Balance: [this.getItemBalance(item)],
      ToConsume: [item.UsedItem]
    });
  }

  editManufactureOrder(){
    this.router.navigate(["manufacture/edit-manufacture-order", this.manufactureOrderId]);
  }

  approveManufactureOrder() {
    if(this.manufactureOrderData.ManufactureOrder.ManufactureFormulaId == null){
      this.toaster.warning('Please add a formula!');
      return;
    }
    let data: ManufactureOrder = this.formatData();
    this.spinner.show();
    this.manufactureService.approveManufactureOrder(data).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manufacture/manufacutring-order-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  formatData(): ManufactureOrder {
    this.manufactureOrderData.ManufactureOrder.Item = null;
    this.manufactureOrderData.ManufactureOrder.ManufactureFormula = null;
    this.manufactureOrderData.ManufactureOrder.Status = this.getManufactureOrderStatus(this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails);
    this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.forEach(item => {item.Item = null; });
    return this.manufactureOrderData.ManufactureOrder
  }

  getManufactureOrderStatus(manufactureOrderDetails: ManufactureOrderDetails[]) {
    let status = ManufactureOrderStatus.TO_BE_PROCESSED;
    manufactureOrderDetails.forEach((element) => {
      let stockQuantity = this.itemMap.get(element.ItemId).StockQuantity;
      if(element.UsedItem > stockQuantity){
        status =  ManufactureOrderStatus.WAITING_AFTER_APPROVED;
        return status;
      }
    });
    return status;
  }

  deleteManufactureOrder(){
    this.spinner.show();
    let data: ManufactureOrder = this.formatData();
    this.manufactureService.deleteManufactureOrder(data).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manufacture/manufacutring-order-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  produceManufactureOrder() {
    this.router.navigate(["manufacture/produce-manufacutring-order", this.manufactureOrderData.ManufactureOrder.Id]);
  }

  canShowOrderItems() {
    let hasOrderItems = this.manufactureOrderData?.ManufactureOrder?.ManufactureFormulaId != null;
    return hasOrderItems;
  }

  canShowMiscellaneousItems() {
    let hasMiscellaneousItems = this.manufactureOrderData?.ManufactureOrder?.ManufactureOrderDetails?.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).length != 0;
    return hasMiscellaneousItems;
  }

  canEditManufactureOrder() {
    let canEdit = this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING;
    return canEdit;
  }

  canApproveManufactureOrder() {
    let canApprove = this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING;
    return canApprove;
  }

  canDeleteManufactureOrder() {
    let canDelete = this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING;
    return canDelete;
  }

  canProduceManufactureOrder() {
    let canProduce = this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.TO_BE_PROCESSED || this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED;
    return canProduce;
  }

  setMainTitle() {
    let status = this.formatStatus();
    let mainTitle = 'MO-'+this.manufactureOrderData?.ManufactureOrder?.ManufactureOrderNo+' / '+status;
    return mainTitle;
  }

  formatStatus() {
    if(this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING) {
      return 'Draft';
    }
    else if(this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED) {
      return 'Waiting';
    }
    else if(this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.TO_BE_PROCESSED) {
      return 'To Process';
    }
    else if(this.manufactureOrderData?.ManufactureOrder?.Status == ManufactureOrderStatus.PROCESSED) {
      return 'Produced';
    }
  }

}
