import { TranslateService } from "@ngx-translate/core";
import { IncomeServiceService } from "./../../../service/Income/income-service.service";
import { MainServiceService } from "./../../../service/Main/main-service.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "./../../../service/auth/auth.service";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  FormsModule,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import * as firebase from "firebase";
import { WindowService } from "../../../service/Windows/window.service";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { AppConfig } from "../../../app.config";
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-registration-page",
  templateUrl: "./registration-page.component.html",
  styleUrls: ["./registration-page.component.css"],
})
export class RegistrationPageComponent implements OnInit, OnDestroy {
  public recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  preregisterForm: FormGroup;
  registerForm: FormGroup;
  passwordFrom: FormGroup;
  verfiationForm: FormGroup;
  loginForm: FormGroup;

  showGettingStartedDiv: boolean = false;
  showVerificationDiv: boolean = true;
  showPasswordDiv: boolean = true;
  isMobileNo: boolean = false;
  emailorPhone: string = null;
  windowRef: any;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private dataService: DataService,
    private appConfig: AppConfig,
    public win: WindowService,
    private route: ActivatedRoute
  ) {
    if (!firebase.apps.length) {
      firebase.initializeApp(appConfig.firebaseConfig);
    }
  }
  ngOnInit(): void {
    this.dataService.emitsetSidebarUpdate(false);
    this.windowRef = this.win.windowRef;
    this.registerData();
    debugger
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container"
    );
    //QueryString Tafsir 25.11.21
    this.route.queryParams.subscribe((params) => {
      if (params.email) {
        this.emailorPhone = params.email;
      } else {
        this.emailorPhone = params.phone;
      }
      this.preregisterForm.get("email").setValue(this.emailorPhone);
    });
  }
  ngOnDestroy() {
    this.dataService.emitSidebarUpdate(false);
  }
  registerData() {
    this.preregisterForm = this.fb.group({
      firstname: ["", [Validators.required]],
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(\d{11}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
          ),
        ]),
      ],
      acceptTerms: [false],
    });
    this.verfiationForm = this.fb.group({
      OTPCode: ["", [Validators.required]],
    });
    this.passwordFrom = this.fb.group({
      Password: ["", [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: ["", [Validators.required, Validators.minLength(6)]],
    });
    this.loginForm = this.fb.group({
      Email: [""],
      Password: [""],
    });
    this.registerForm = this.fb.group({
      Email: [""],
      Password: [""],
      Firstname: [""],
    });
  }
  validateEmailOrPhoneNo = (emailtxtbx) => {
    var emailPattern =
      /^[a-z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/;
    this.isMobileNo = emailtxtbx.match(emailPattern) ? false : true;
  };

  validatePassword = (password) => {
    console.log(password);
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{5,}$/;
    return re.test(password);
  };

  submitRegisterForm = () => {
    if (this.preregisterForm.invalid) {
      this.toastr.warning("Please fill all the required fields!");
      return;
    }

    if (!this.preregisterForm.value.acceptTerms) {
      this.toastr.warning("Please accept terms and condition");
      return;
    }

    this.validateEmailOrPhoneNo(this.preregisterForm.value.email);

    if (this.isMobileNo) {
      // Mobile No

      const appVerifier = this.recaptchaVerifier;
      const phoneNumberString = "+88" + this.preregisterForm.value.email; //"+8801715425456" this.ngForm.value.phonenumber;

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumberString, appVerifier)
        .then((confirmationResult) => {
          this.showGettingStartedDiv = true;
          this.showVerificationDiv = false;
          this.windowRef.confirmationResult = confirmationResult;
        })
        .catch((err) => {
          console.log("sms not sent", err);
        });
    }

    if (!this.isMobileNo) {
      //email
      this.SpinnerService.show();
      this.authService
        .PreRegisterNewAccount(this.preregisterForm.value)
        .subscribe(
          (res) => {
            if (res.Success) {
              this.showGettingStartedDiv = true;
              this.showVerificationDiv = false;
              this.SpinnerService.hide();
              this.toastr.success(res.Message, "Success!");
            } else {
              this.SpinnerService.hide();
              if (!res.Errors) {
                this.toastr.warning(res.Message);
              } else {
                let error = res.Errors;
                error.map((s) => {
                  this.toastr.warning(s.Description);
                });
              }
            }
          },
          (err) => {
            this.SpinnerService.hide();
            this.toastr.error(
              "Wrong Email or Contact No, please try again",
              "Wrong!"
            );
          }
        );
    }
  };

  submitValidationCode = () => {
    if (this.verfiationForm.invalid) {
      this.toastr.warning("This field is required");
      return;
    }

    if (this.isMobileNo) {
      this.windowRef.confirmationResult
        .confirm(this.verfiationForm.value.OTPCode)
        .then((result) => {
          var user = result.user;
          this.showGettingStartedDiv = true;
          this.showVerificationDiv = true;
          this.showPasswordDiv = false;
          this.toastr.success("Verfication Successfully", "Success!");
        })
        .catch((err) => {
          console.log(err);
          if (err.code == "auth/invalid-verification-code") {
            this.toastr.warning(
              "Your OTP is wrong, Please try the right one",
              "Error:"
            );
          } else if (err.code == "auth/code-expired") {
            this.toastr.warning(
              "Your OTP is expired .Please apply again for new OTP",
              "Error:"
            );
          } else {
            this.toastr.error("Sorry there was some error. Please check");
          }
        });
    }

    if (!this.isMobileNo) {
      //email
      this.authService
        .IsValidateOtpCode(
          this.preregisterForm.value.email,
          this.verfiationForm.value.OTPCode
        )
        .subscribe(
          (res) => {
            if (res.Success) {
              this.showGettingStartedDiv = true;
              this.showVerificationDiv = true;
              this.showPasswordDiv = false;
              this.toastr.success(res.Message, "Success!");
            } else {
              if (!res.Errors) {
                this.toastr.warning(res.Message);
              } else {
                let error = res.Errors;
                error.map((s) => {
                  this.toastr.warning(s.Description);
                });
              }
            }
          },
          (err) => {
            this.toastr.error(
              "Wrong Email or Contact No, please try again",
              "Wrong!"
            );
          }
        );
    }
  };

  submitPassword = () => {
    if (
      this.passwordFrom.invalid &&
      (this.passwordFrom.value.ConfirmPassword.length == 0 ||
        this.passwordFrom.value.Password.length == 0)
    ) {
      console.log(this.passwordFrom.value.ConfirmPassword);
      this.toastr.warning("All the fields are required");
      return;
    }
    if (!this.validatePassword(this.passwordFrom.value.Password)) {
      this.toastr.warning(
        "Password should be minimum 6 characters long and must have one Capital letter and small letter"
      );
      return;
    }
    if (
      this.passwordFrom.value.Password !=
      this.passwordFrom.value.ConfirmPassword
    ) {
      this.toastr.warning("Password and confirm password should be same");
      return;
    }
    this.SpinnerService.show();

    this.registerForm.value.Email = this.preregisterForm.value.email;
    this.registerForm.value.Firstname = this.preregisterForm.value.firstname;
    this.registerForm.value.Password = this.passwordFrom.value.Password;

    this.authService.RegisterNewAccount(this.registerForm.value).subscribe(
      (res) => {
        if (res.Success) {
          this.SpinnerService.hide();
          this.toastr.success(res.Message, "Success!");
          this.submitLoginForm();
        } else {
          this.SpinnerService.hide();
          if (!res.Errors) {
            this.toastr.warning(res.Message);
          } else {
            let error = res.Errors;
            error.map((s) => {
              this.toastr.warning(s.Description);
            });
          }
        }
      },
      (err) => {
        this.SpinnerService.hide();
        this.toastr.error(
          "Wrong Email or Contact No, please try again",
          "Wrong!"
        );
      }
    );
  };

  submitLoginForm() {
    this.loginForm.value.Email = this.preregisterForm.value.email;
    this.loginForm.value.Password = this.passwordFrom.value.Password;

    this.SpinnerService.show();
    this.authService.LoginService(this.loginForm.value).subscribe(
      (res) => {
        let obj = res;
        this.authService.setUserInfo(JSON.stringify(obj));
        this.router.navigate(["/organization/create"]);
        this.dataService.emitOrganizationUpdate(true);
        this.dataService.emitDefaultNotificationUpdate(true);
        this.SpinnerService.hide();
        this.toastr.success("Login Successful", "Success!");
      },
      (err) => {
        console.log(err.error);
        this.toastr.error(err.error.Message);
        this.SpinnerService.hide();
      }
    );
  }
}
