import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FixedAssetRollbackDepreciationPopupComponent } from '../fixed-asset-rollback-depreciation-popup/fixed-asset-rollback-depreciation-popup.component';

@Component({
  selector: 'app-fixed-asset-depreciate',
  templateUrl: './fixed-asset-depreciate.component.html',
  styleUrls: ['./fixed-asset-depreciate.component.css']
})
export class FixedAssetDepreciateComponent implements OnInit {

  public itemForm: FormGroup;
  public fromDate: string;
  toDateList:any=[];
  depreciateableItems:any=[];
  items: any = [];
  constructor(private pService: FixedAssetService,
    public fb: FormBuilder, 
    private spinner: NgxSpinnerService, 
    private router:Router,
    private toaster: ToastrService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.initializeForm();
    this.pService.GetDepreciationDate().subscribe((x) => {
     this.toDateList = x.toDateList;
     this.depreciateableItems=x.Data;
     this.items = this.depreciateableItems?.filter(y => y.BookAmount != 0);
     this.itemForm.controls['fromDate'].setValue(x.fromDate);
     this.itemForm.controls['toDate'].setValue(x.toDateList[0].Value);
     }, (err) => {});
  }

  initializeForm() {
    this.itemForm = this.fb.group({
      fromDate:['',Validators.required],
      toDate: ['', Validators.required]
    });
  }

  /**
   * getDepreciateableItems
   */
  public getDepreciateableItems() {
    this.spinner.show();
    var fValue=this.itemForm.value;
    this.pService.GetDepreciateableItems(fValue.fromDate,fValue.toDate).subscribe((x) => {
      this.depreciateableItems=x;
      this.items = this.depreciateableItems?.filter(y => y.BookAmount != 0);
      this.spinner.hide();
      },(err)=>{});
  }

  /**
   * ConfirmDepreciation
   */
  public ConfirmDepreciation() {
    this.spinner.show();
    var fValue=this.itemForm.value;
    this.pService.RunDepreciation(fValue.fromDate,fValue.toDate).subscribe((x) => {
      this.toaster.success('Depriciation run successfully.');
      this.spinner.hide();
      this.router.navigate(['fixed-asset/setup/registered']);
      }, (err) => {
        this.spinner.hide();
      });
  }

  gotoRollbackDepreciationModal() {
    const modalRef = this.modalService.open(FixedAssetRollbackDepreciationPopupComponent, {
      size: "md",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.result.then(
      (result) => {
      },
      (reason) => {
        console.log(reason);
      }
    );
  }
}
