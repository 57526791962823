import { Component, OnInit, Input } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { AcountingServiceService } from "src/app/service/Accounting/acounting-service.service";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ItemCreateComponent } from "../../Products/item-create/item-create.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateItem } from "src/app/models/product.model";
import { SalesOrderService } from "src/app/service/SalesOrder/sales-order.service";
import { CreatePopupItemComponent } from "../../Products/create-popup-item/create-popup-item.component";
import { SalesOrderModalComponent } from "../../Inventory/sales-order-modal/sales-order-modal.component";
import { InventoryOverviewService } from "src/app/service/InventoryOverview/inventory-overview.service";
import { ProductServiceService } from "src/app/service/Products/product-service.service";
import { WareHouseItems } from "src/app/models/warehouseitems.model";
import { ItemType } from "src/app/enum/item-type";
import { IncomeServiceService } from "src/app/service/Income/income-service.service";
import { iQuidiActionType } from "src/app/enum/iquidiActionType";

@Component({
  selector: "app-sales-order-create",
  templateUrl: "./sales-order-create.component.html",
  styleUrls: ["./sales-order-create.component.css"],
})
export class SalesOrderCreateComponent implements OnInit {
  subTotalAmount: number = 0;
  AdvanceTaxAmount: number = 0.0;
  TaxAmount: number = 0.0;
  totalPrice: number = 0.0;
  totalFile: number = 0;
  costAccountId: number = 0;
  public salesOrderForm: FormGroup;
  public SalesOrderItem: FormArray;
  productList = [];
  taxTypeList = [];
  supplierList = [];
  accountList = [];
  taxRateList = [];
  ContactId: number;
  isVatAitDisabled: boolean = true;
  contactIdRequired: boolean = true;
  unitPriceRequired: boolean = true;
  deliveryDateRequired: boolean = true;
  descriptionRequired: boolean = true;
  qtyRequired: boolean = true;
  loading = false;
  public aModel: CreateItem = new CreateItem();
  salesOderNo: any;
  pageValue: any;
  allData: any = [];
  successLogo: boolean = false;
  dangerLogo: boolean = false;
  findProduct: any = [];
  quantityValue: any;
  dataFromWareHouseOut: any;
  ProductList: any = [];
  itemDetailsList: any = [];

  ShowSalesOrderNo: string;
  prevSales: number;
  postSales: number;
  sale: number;
  OrderNo: string = "";
  //sales: string;
  stockUnavailableCount: number = 0;
  stockAvailableCount: number = 0;
  itemStockAvailable: boolean[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private settingService: SettingsServiceService,
    private mainService: MainServiceService,
    private accountingService: AcountingServiceService,
    private pService: SalesOrderService,
    private route: ActivatedRoute,
    private deliveryOrderService: InventoryOverviewService,
    private productServiceService: ProductServiceService,
    private incomeService: IncomeServiceService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.pageLoad();
    this.getMasterDataList();
    this.forMatDate();
    this.getSaleableItemDetails();
  }

  getSaleableItemDetails(){
    this.incomeService.getSaleableItemDetails().subscribe((x) => {
      this.itemDetailsList = x;
    });
  }

  pageLoad() {
    this.pageValue = Number(this.route.snapshot.paramMap.get("id"));
    this.deliveryOrderService.GetDeliveryOrder().subscribe((x) => {
      this.allData = x;
    });
    
    this.dataFromWareHouseOut = this.deliveryOrderService.getWareHouseData();

    this.incomeService.getSaleableItemDetails().subscribe((x) => {
      this.spinner.hide();
      this.ProductList = x;
    });
  }

  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }
  getMasterDataList = () => {
    this.generateSalesNo();
    this.getAllSupplier();
    this.getTaxTypeList();
    this.getTaxRateList();
    this.getItems();
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      this.accountList = x.ExpenseList;
      this.costAccountId = this.accountList.filter(
        (x) => x.AccountName == "200 - Sales"
      )[0].Id;
      const myForm = (<FormArray>this.salesOrderForm.get("SalesOrderItem")).at(
        0
      );
      myForm.patchValue({
        AccountId: this.costAccountId,
      });
    });
  };

  generateSalesNo = () => {
    this.pService.GenerateSalesNo().subscribe((x: any) => {
      let val = x.toString();
      let pre = "",
        post = "";
      for (let i = 0; i < 6; i++) pre += val[i].toString();
      for (let i = 6; i < 10; i++) post += val[i].toString();
      this.salesOrderForm.get("OrderNo").patchValue(post);
      this.OrderNo = post;
      this.prevSales = Number(pre);
      this.ShowSalesOrderNo = "SO-" + pre;
    });
  };
  private getItems = () => {
    this.incomeService.GetAllSaleableItem().subscribe((x) => {
      console.log("All Items==>", x);
      this.productList = x;
    });
  };
  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
      if (this.taxTypeList.length >= 2) {
        this.salesOrderForm.controls["TaxTypeId"].patchValue(
          this.taxTypeList[2].Id
        );
      }
    });
  };
  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({ Id: "", Name: "Select" });
    });
  };

  async initializeForm() {
    this.salesOrderForm = this.fb.group({
      Id: [0],
      ContactId: [null, Validators.required],
      OrderNo: [""],
      OrderDate: ["", Validators.required],
      DeliveryDate: ["", Validators.required],
      ReferenceNo: [""],
      TaxTypeId: [""],
      TaxAmount: [""],
      AdvanceTaxAmount: [""],
      OrderAmount: [""],
      SalesRepresentative: [""],
      Notes: [""],
      SalesOrderItem: this.fb.array([this.createItem()]),
    });
  }
  public createItem(): FormGroup {
    return this.fb.group({
      Id: 0,
      ItemId: [""],
      ItemTypeId: [''],
      Description: ["", Validators.required],
      AccountId: [this.costAccountId],
      Quantity: ["", Validators.required],
      DangerLogo: [""],
      SuccessLogo: [""],
      UnitPrice: ["", Validators.required],
      TaxRateId: [null],
      AdvanceTaxRateId: [null],
      LineTotal: [0],
    });
  }

  public addItem(): void {
    this.SalesOrderItem = this.salesOrderForm.get(
      "SalesOrderItem"
    ) as FormArray;
    this.SalesOrderItem.push(this.createItem());
  }

  public deleteItem(i: number) {
    let isStockUnavailable = this.salesOrderForm.value.SalesOrderItem[i]["DangerLogo"];
    if( isStockUnavailable )
    {
      this.stockUnavailableCount -= 1;
    }else{
      this.stockAvailableCount -= 1;
    }
    this.SalesOrderItem.removeAt(i);
  }

  /**
   * updatePrice
   */

  public updatePrice(i: number, item: any) {
    if(!item.value.ItemId) {
      item.controls["LineTotal"].patchValue(item.value.Quantity * item.value.UnitPrice);
      item.controls["DangerLogo"].patchValue(false);
      item.controls["SuccessLogo"].patchValue(false);
      item.controls["ItemTypeId"].patchValue(null);
    }
    else{
      this.findProduct = this.productList.filter((x) => x.Id == item.value.ItemId);
      let findItem = this.itemDetailsList.find((x) => x.Id == item.value.ItemId);
      this.quantityValue = item.value.Quantity;
      if(findItem.ItemTypeId == ItemType.Service) {
        item.controls["LineTotal"].patchValue(item.value.Quantity * item.value.UnitPrice);
        item.controls["DangerLogo"].patchValue(false);
        item.controls["SuccessLogo"].patchValue(false);
        item.controls["ItemTypeId"].patchValue(findItem.ItemTypeId);
      } else if (findItem.StockQuantity < Number(this.quantityValue) && findItem.ItemTypeId != ItemType.Service) {
        this.dangerLogo = true;
        this.successLogo = false;
        this.stockUnavailableCount = this.stockUnavailableCount + 1;
        this.salesOrderForm.value.SalesOrderItem.map((x) => {
          if (x.ItemId == item.value.ItemId) {
            item.controls["DangerLogo"].patchValue(true);
            item.controls["SuccessLogo"].patchValue(false);
            item.controls["ItemTypeId"].patchValue(findItem.ItemTypeId);
          }
        });
      } else if (findItem.StockQuantity >= Number(this.quantityValue) && findItem.ItemTypeId != ItemType.Service) {
        this.successLogo = true;
        this.dangerLogo = false;
        this.stockAvailableCount += 1;
        this.salesOrderForm.value.SalesOrderItem.map((x) => {
          if (x.ItemId == item.value.ItemId) {
            item.controls["DangerLogo"].patchValue(false);
            item.controls["SuccessLogo"].patchValue(true);
            item.controls["ItemTypeId"].patchValue(findItem.ItemTypeId);
          }
        });
      }
      this.salesOrderForm.value.SalesOrderItem.map((x) => {
        if (x.ItemId == item.value.ItemId) {
          item.controls["LineTotal"].patchValue(item.value.Quantity * item.value.UnitPrice);
        }
      });
    }
    this.updateTotalPrice();
  }

  updateTotalPrice = () => {
    this.subTotalAmount = 0.0;
    this.TaxAmount = 0.0;
    this.AdvanceTaxAmount = 0.0;
    this.isVatAitDisabled =
      this.salesOrderForm.value.TaxTypeId == 3 ? true : false;
    this.salesOrderForm.value.SalesOrderItem.map((x) => {
      this.subTotalAmount =
        this.subTotalAmount + this.AdvanceTaxAmount + x.LineTotal;
      this.TaxAmount += this.getItemTaxAmount(x.LineTotal, x.TaxRateId);
      this.AdvanceTaxAmount += this.getItemTaxAmount(
        x.LineTotal,
        x.AdvanceTaxRateId
      );
    });
    switch (this.salesOrderForm.value.TaxTypeId) {
      case 1: //tax exclusive
        this.totalPrice =
          this.subTotalAmount + this.TaxAmount + this.AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        this.totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
        this.totalPrice = this.subTotalAmount;
        break;
    }
    if (this.salesOrderForm.value.TaxTypeId === 3) {
      this.checkTaxType();
    }
  };

  checkTaxType = () => {
    this.SalesOrderItem = this.salesOrderForm.get(
      "SalesOrderItem"
    ) as FormArray;
    this.SalesOrderItem.value.map((x, i) => {
      const myForm = (<FormArray>this.salesOrderForm.get("SalesOrderItem")).at(
        i
      );
      myForm.patchValue({
        TaxRateId: null,
        AdvanceTaxRateId: null,
      });
    });
  };
  private getItemTaxAmount(lineTotal: number, taxRateId: any) {
    let taxPrice = 0.0;
    if (taxRateId == "" || taxRateId == null) return taxPrice;

    var taxPercent = this.getTaxRatePercent(taxRateId);
    switch (this.salesOrderForm.value.TaxTypeId) {
      case 1: //tax exclusive
        taxPrice = (lineTotal * taxPercent) / 100;
        break;
      case 2: //tax inclusive
        taxPrice = Number(
          ((lineTotal * taxPercent) / (100 + taxPercent)).toFixed(2)
        );
        break;
      default: //no tax
        taxPrice = 0;
        break;
    }
    return taxPrice;
  }
  private getTaxRatePercent(taxRateId: any) {
    let taxRateObj = this.taxRateList.filter((x) => x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }

  async checkSalesOrderNo() {
    var val = this.salesOrderForm.value.OrderNo.toString();
    if (val == "" || val == null) {
      this.toaster.error("Please Input Sales Order No.!");
      return false;
    }

    if (val.length != 4) {
      this.toaster.error("Sales Oder Number needs to be 4 digits.");
      return false;
    }

    return true;
  }
  /*async checkSalesOrderNo(){
    var val = this.salesOrderForm.value.OrderNo.toString();
    if(val=='' || val==null){
      this.toaster.error('Please Input Sales Order No.!');
      return false;
    }

    let order = this.prevSales.toString();
    let concat = "";
    for(let i=0;i<6;i++) concat+=order[i];
    if(val.length!=10){
      console.log(val.length);
      this.toaster.error("Sales Order number must start with " + concat + " and ends with four-digit numbers");
      return false;
    }

    for(let i=0;i<concat.length;i++){
      if(val[i]!=concat[i]){
        this.toaster.error("Sales Order number must start with " + concat + " and ends with four-digit numbers");
        return false;
      }
    }

    return true;
  }*/

  /**
   * saveAsDraft
   */

  public async saveAsAwaitingApproval() {
    if (!(await this.checkSalesOrderNo())) return;
    if (this.salesOrderForm.invalid) {
      this.contactIdRequired = false;
      this.deliveryDateRequired = false;
      this.descriptionRequired = false;
      this.qtyRequired = false;
      this.unitPriceRequired = false;
      this.toaster.warning("Please fill all the required fields!");
      return;
    }
    this.salesOrderForm.controls["TaxAmount"].patchValue(this.TaxAmount);
    this.salesOrderForm.controls["AdvanceTaxAmount"].patchValue(
      this.AdvanceTaxAmount
    );
    this.salesOrderForm.controls["OrderAmount"].patchValue(this.totalPrice);
    this.spinner.show();
    let preValue = "";
    let temp = this.salesOrderForm.value.OrderNo;
    for (let i = 3; i < this.ShowSalesOrderNo.length; i++)
      preValue += this.ShowSalesOrderNo[i].toString();
    this.salesOrderForm.value.OrderNo =preValue + this.salesOrderForm.value.OrderNo.toString();
    const data = this.salesOrderForm.value;
    data.IQuidiActionType = iQuidiActionType.Save;
    this.pService.Save(data).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("Saved successfully.");
          this.router.navigate(["sales/sales-order-list/All"]);
        } else {
          this.salesOrderForm.controls["OrderNo"].setValue(temp);
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      }
    );
  }

  /*public async saveAsAwaitingApproval() {
    if(! await this.checkSalesOrderNo()) return;

    if (this.salesOrderForm.invalid){
      this.contactIdRequired = false;
      this.deliveryDateRequired = false;
      this.descriptionRequired = false;
      this.qtyRequired = false;
      this.unitPriceRequired = false;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    this.postSales = this.salesOrderForm.get('OrderNo')?.value;
    let temp = this.salesOrderForm.value.OrderNo;
    this.sale = (this.prevSales + this.postSales);
    this.salesOrderForm.controls['TaxAmount'].patchValue(this.TaxAmount);
    this.salesOrderForm.controls['AdvanceTaxAmount'].patchValue(this.AdvanceTaxAmount);
    this.salesOrderForm.controls['OrderAmount'].patchValue(this.totalPrice);
    let xyz =this.salesOrderForm.controls['OrderNo'].patchValue(this.sale);
    this.spinner.show();
    this.pService.SaveSalesOrder(this.salesOrderForm.value).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Saved successfully.');
        this.router.navigate(['sales/sales-order-list/All']);
      } else {
        this.salesOrderForm.controls['OrderNo'].setValue(temp);
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }*/

  /**
   * saveAsApproved
   */
  // public async saveAsApproved() {
  //   if (!(await this.checkSalesOrderNo())) return;
  //   if (this.salesOrderForm.invalid) {
  //     this.contactIdRequired = false;
  //     this.deliveryDateRequired = false;
  //     this.descriptionRequired = false;
  //     this.qtyRequired = false;
  //     this.unitPriceRequired = false;
  //     this.toaster.warning("Please fill all the required fields!");
  //     return;
  //   }
  //   this.salesOrderForm.controls["TaxAmount"].patchValue(this.TaxAmount);
  //   this.salesOrderForm.controls["AdvanceTaxAmount"].patchValue(
  //     this.AdvanceTaxAmount
  //   );
  //   this.salesOrderForm.controls["OrderAmount"].patchValue(this.totalPrice);
  //   this.spinner.show();
  //   let preValue = "";
  //   let temp = this.salesOrderForm.value.OrderNo;
  //   for (let i = 3; i < this.ShowSalesOrderNo.length; i++)
  //     preValue += this.ShowSalesOrderNo[i].toString();
  //   this.salesOrderForm.value.OrderNo =
  //     preValue + this.salesOrderForm.value.OrderNo.toString();
  //   this.pService.Approve(this.salesOrderForm.value).subscribe(
  //     (x) => {
  //       if (x.Success) {
  //         this.toaster.success("Approved successfully.");
  //         this.router.navigate(["sales/sales-order-list/OnGoing"]);
  //       } else {
  //         this.salesOrderForm.controls["OrderNo"].setValue(temp);
  //         this.toaster.error(x.Message);
  //       }
  //       this.spinner.hide();
  //     },
  //     (err) => {
  //       this.toaster.error("ooh, something went wrong !");
  //     }
  //   );
  // }

  public async saveAsApproved() {
    if (!(await this.checkSalesOrderNo())) return;
    if (this.salesOrderForm.invalid) {
      this.contactIdRequired = false;
      this.deliveryDateRequired = false;
      this.descriptionRequired = false;
      this.qtyRequired = false;
      this.unitPriceRequired = false;
      this.toaster.warning("Please fill all the required fields!");
      return;
    }
    this.postSales = this.salesOrderForm.get("OrderNo")?.value;
    this.sale = this.prevSales + this.postSales;
    this.salesOrderForm.controls["TaxAmount"].patchValue(this.TaxAmount);
    this.salesOrderForm.controls["AdvanceTaxAmount"].patchValue(
      this.AdvanceTaxAmount
    );
    this.salesOrderForm.controls["OrderAmount"].patchValue(this.totalPrice);

    this.SalesOrderItem = this.salesOrderForm.get(
      "SalesOrderItem"
    ) as FormArray;
    console.log("Product Received=>", this.SalesOrderItem);
    let modalFlag = false,
      i = 0;
    this.findProduct = this.productList.filter(
      (x) => x.Id == this.SalesOrderItem.value.ItemId
    );
    console.log("HereFindProduct", this.findProduct);
    
    let notInStockCount = this.SalesOrderItem.value.filter( x => x.DangerLogo == true ).length;
    let inStockCount = this.SalesOrderItem.value.filter( x => x.SuccessLogo == true ).length; 

    if( notInStockCount > 0 && inStockCount > 0 ){
      const modalRef = this.modalService.open(SalesOrderModalComponent, {
        size: "lg",
        backdrop: "static",
        keyboard: false,
        centered: true,
      });

      let wareHouseData = {
        ReferenceNo: this.sale, //Reference No
        statusId: 1,
        OperationType: 3,
        ParentId: "",
        ContactId: this.salesOrderForm.get("ContactId").value,
        OperationDate: this.salesOrderForm.get("OrderDate").value,
        allDATA: this.salesOrderForm.value,
        wareHouseItems: this.ProductList,
      };
      let data = {
        WareHouseData: wareHouseData,
      };
      console.log(data);
      modalRef.componentInstance.fromParent = data;
      modalRef.componentInstance.SalesOrderItemsList = this.SalesOrderItem.value;

        modalRef.result.then(
        (result) => {
          console.log("Modal Result", result);
          if(result=='Success'){
            this.salesOrderForm.controls["OrderNo"].patchValue(this.sale);
            this.salesOrderForm.value.OrderQty = -1 * this.salesOrderForm.value.OrderQty;
            this.spinner.show();
            const data = this.salesOrderForm.value;
            data.IQuidiActionType = iQuidiActionType.Approve;
            this.pService.Save(data).subscribe(
              (x) => {
                if (x.Success) {
                  this.toaster.success("Approved successfully.");
                  this.router.navigate(["sales/sales-order-list/OnGoing"]);
                } else {
                  this.toaster.error(x.Message);
                }
                this.spinner.hide();
              },
              (err) => {
                this.toaster.error("ooh, something went wrong !");
              }
            );
          }
          else{
            return;
          }
        },
        (reason) => {}
      );
      return;
    }
    this.salesOrderForm.controls["OrderNo"].patchValue(this.sale);
    this.spinner.show();
    const data = this.salesOrderForm.value;
    data.IQuidiActionType = iQuidiActionType.Approve;
    this.pService.Save(data).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("Approved successfully.");
          this.router.navigate(["sales/sales-order-list/OnGoing"]);
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      }
    );
  }

  CreateNewSupplier = (customerName) => {
    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: true,
      IsSupplier: false,
    };
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("Contact has been successfully added !");
          this.loading = false;
          this.supplierList.push(x["Data"]);
          let customerObj = this.supplierList.filter(
            (x) => x.PrimaryPersonFirstName == customerName
          );

          this.salesOrderForm.controls["ContactId"].patchValue(
            customerObj[0]["Id"]
          );
          this.getAllSupplier();
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.loading = false;
      }
    );
  };
  getAllSupplier = () => {
    this.pService.GetSupplierList().subscribe((x) => {
      this.supplierList = x;
      console.log("SuplierList", this.supplierList);
    });
  };
  show(e) {
    const value = this.salesOrderForm.value.OrderDate;
    $("#itest1").attr(
      "min",
      moment.isDate(value) ? value.toISOString().split("T")[0] : value
    );
    $("#itest")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  showSelectedDate(e) {
    const value = this.salesOrderForm.value.DeliveryDate;
    $("#itest1")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  forMatDate() {
    var date = new Date();
    this.salesOrderForm.controls["OrderDate"].patchValue(date);
    this.show(date);
  }

  changespurchaseableItems = (index) => {
    this.SalesOrderItem = this.salesOrderForm.get("SalesOrderItem") as FormArray;
    const myForm = (<FormArray>this.salesOrderForm.get("SalesOrderItem")).at(
      index
    );
    console.log("FormArray Selected Value", myForm);

    for (let i = 0; i < this.productList.length; i++) {
      if (
        this.productList[i].Id ==
        this.salesOrderForm.value.SalesOrderItem[index]["ItemId"]
      ) {
        this.findProduct = this.productList.filter(
          (x) => x.Id == this.productList[i].Id
        );
        console.log(this.findProduct)
        myForm.patchValue({
          ItemId: this.productList[i]["Id"],
          Description: this.productList[i]["PurchaseDescription"],
          AccountId:
            this.productList[i]["IsTrackItem"] == true
              ? this.productList[i]["InventoryAccountId"]
              : this.productList[i]["PurchaseAccountId"],
          Quantity: 1,
          UnitPrice: this.productList[i]["SaleUnitPrice"],
          LineTotal: this.productList[i]["SaleUnitPrice"],
          DangerLogo: (this.findProduct[0].StockQuantity < 1) ? true : false,
          SuccessLogo: (this.findProduct[0].StockQuantity > 0) ? true : false,
          ItemTypeId: this.itemDetailsList.find((x) => x.Id == this.findProduct[0].Id).ItemTypeId
        });
        this.updatePrice(index, this.SalesOrderItem.controls[index]);
        console.log(myForm)
      }
    }
    this.updateTotalPrice();
  };
  openItemModal(selectItem: NgSelectComponent) {
    selectItem.close();

    const modalRef = this.modalService.open(CreatePopupItemComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.refId = 2;
    modalRef.componentInstance.type = "sales";
    modalRef.result.then(
      (result) => {
        this.getItems();
      },
      (reason) => {
        console.log("Dismissed action: " + reason);
      }
    );
  }

  toggleStockAvailability(index: number) {
    let i;
    this.itemStockAvailable[index] = !this.itemStockAvailable[index];
    for(i = 0; i < this.itemStockAvailable.length; i++) {
      if(i != index && this.itemStockAvailable[i] == true) {
        this.itemStockAvailable[i] = !this.itemStockAvailable[i];
      }
    }
  }


}
