<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">{{AssetNumber}} {{fromParent.AssetName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    
    <div class="pb-4">
        <div class="row">
            <div class="col-md-10">
                <ul class="nav nav-tabs" style="margin-bottom: 30px;">
                    <li class="nav-item">
                      <a class="nav-link" (click)="setActivePanel(1)" [ngClass]="{'active': activeTabId===1}">Summary</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" (click)="setActivePanel(2)" [ngClass]="{'active': activeTabId===2}">Depreciation Value</a>
                    </li>
                  </ul>
            </div>
            <div class="col-md-2">
                <div>
                    <p *ngIf="fromParent.StatusId == 3" style="font-weight: bold;">Status: <span style="color: red; font-weight: bold">Disposed</span></p>
                </div>
                <div *ngIf="fromParent.StatusId != 3 && fromAssignee == false" class="dropdown">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Options
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" (click)="EditAssetItem()">Edit</a>
                      <a class="dropdown-item" (click)="openDisposeModal()">Dispose</a>
                      <a class="dropdown-item" (click)="DeleteFixedAssetItem()">Delete</a>
                    </div>
                  </div>
            </div>
        </div>
          
        <div class="row" *ngIf="activeTabId==1">
            <div class="col-md-12">
                <form class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-8">
                                <label for="AssetName" style="font-weight: 600;">Asset Name <span
                                        class="required-sign">*</span></label>
                                 <label class="form-control form-input">{{fromParent.AssetName}}</label>       
                            </div>
                            <div class="form-group  col-sm-4">
                                <label for="AssetNumber" style="font-weight: 600;">Asset Number</label>
                                <label class="form-control form-input">{{fromParent.AssetNumber}}</label>
                            </div>

                        </div>
                    </div>


                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-3">
                                <label for="PurchaseDate" style="font-weight: 600;">Purchase Date</label>
                                <label class="form-control form-input">{{fromParent.PurchaseDateVw}}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="PurchasePrice" style="font-weight: 600;">Purchase Price</label>
                                <label class="form-control form-input">{{fromParent.PurchasePrice}}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="WarrantyExpiryDate" style="font-weight: 600;">Warranty Expiry</label>
                                <label class="form-control form-input">{{fromParent.WarrantyExpiryDateVw}}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="SerialNo" style="font-weight: 600;">Serial No</label>
                                <label class="form-control form-input">{{fromParent.SerialNo}}</label>
                            </div>

                        </div>
                       
                    </div>



                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="AssetTypeId" style="font-weight: 600;">Asset Type</label>
                                <label class="form-control form-input">{{fromParent.AssetTypeName}}</label>
                            </div>
                            <div class="form-group  col-sm-6">
                                <label for="AssetTypeId" style="font-weight: 600;">Asset Account</label>
                                <label class="form-control form-input">{{fromParent.TypeDetails.AssetAccount}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="AssetTypeId" style="font-weight: 600;">Depreciation Account</label>
                                <label class="form-control form-input text-overflow">{{fromParent.TypeDetails.DepreciationAccount}}</label>
                            </div>
                            <div class="form-group  col-sm-6">
                                <label for="AssetTypeId" style="font-weight: 600;">Expense Account</label>
                                <label class="form-control form-input">{{fromParent.TypeDetails.ExpenseAccount}}</label>
                            </div>
                        </div>
                    </div>


                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="Description" style="font-weight: 600;">Description</label>
                                <label class="form-control form-input">{{fromParent.Description}}</label>
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div *ngIf="fromParent.DepreciationMethodId != 1" class="form-group  col-sm-6">
                                <label for="DepreciationStartDate" style="font-weight: 600;">Depreciation Start Date</label>
                                <label class="form-control form-input">{{fromParent.DepreciationStartDateVw}}</label>
                            </div>
                            <div class="form-group  col-sm-6">
                                <label for="DepreciationMethodId" style="font-weight: 600;">Depreciation Method</label>
                                <label class="form-control form-input">{{fromParent.DepreciationMethodName}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder" *ngIf="fromParent.DepreciationMethodId != 1">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-4">
                                <label for="AveragingMethodId" style="font-weight: 600;">Averaging Method</label>
                                <label class="form-control form-input">{{fromParent.AveragingMethodName}}</label>
                            </div>
                            <div class="form-group  col-sm-4">
                                <label for="RatePercent" style="font-weight: 600;">Rate % </label>
                                <label class="form-control form-input">{{fromParent.RatePercent}}</label>
                            </div>
                            <div class="form-group  col-sm-4">
                                <label for="EffectiveLifeYears" style="font-weight: 600;">Effective Life(Yrs)</label>
                                <label class="form-control form-input">{{fromParent.EffectiveLifeYears}}</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="row" *ngIf="activeTabId==2">
            <div *ngIf="fromParent.StatusId != 3" class="col-md-12" style="margin-bottom: 20px; display: flex;">
                <div class="card col-sm-4">
                    <div class="card-body">
                      <p class="font-weight-bold">Purchase Price</p>
                      <p>{{fromParent.PurchasePrice}}</p>
                    </div>
                </div>
                <div class="card col-sm-4">
                    <div class="card-body">
                      <p class="font-weight-bold">Purchase Date</p>
                      <p>{{purchaseDate}}</p>
                    </div>
                </div>
                <div class="card col-sm-4">
                    <div class="card-body">
                      <p class="font-weight-bold">Asset Type</p>
                      <p>{{fromParent.AssetTypeName}}</p>
                    </div>
                </div>
            </div>
            <div *ngIf="fromParent.StatusId == 3" class="col-md-12" style="margin-bottom: 20px; display: flex;">
                <div class="card col-sm-2">
                    <div class="card-body">
                      <p class="font-weight-bold">Purchase Price</p>
                      <p>{{fromParent.PurchasePrice}}</p>
                    </div>
                </div>
                <div class="card col-sm-2">
                    <div class="card-body">
                      <p class="font-weight-bold">Purchase Date</p>
                      <p>{{purchaseDate}}</p>
                    </div>
                </div>
                <div class="card col-sm-2">
                    <div class="card-body">
                      <p class="font-weight-bold">Sale Proceeds</p>
                      <p>{{disposalData?.SoldPrice}}</p>
                    </div>
                </div>
                <div class="card col-sm-2">
                    <div class="card-body">
                      <p class="font-weight-bold">Sale Date</p>
                      <p>{{disposalData?.ActionDate}}</p>
                    </div>
                </div>
                <div class="card col-sm-4">
                    <div class="card-body">
                      <p class="font-weight-bold">Asset Type</p>
                      <p>{{fromParent.AssetTypeName}}</p>
                    </div>
                </div>
            </div>

            <div *ngIf="fromParent.StatusId != 3" class="col-sm-12">
                <table class="table f-ss-14px">
                    <thead>
                        <tr class="tableHeader">
                            <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Basis</th>
                            <th scope="col" class="table-header-color text-align-center col-md-2" style="color: #02aced;">Cost Basis</th>
                            <th scope="col" class="table-header-color text-align-center col-md-2" style="color: #02aced;">Value</th>
                            <th scope="col" class="table-header-color text-align-center col-md-2" style="color: #02aced;">Accumulated Depreciationth</th>
                            <th scope="col" class="table-header-color text-align-center col-md-2" style="color: #02aced;">YTD Depreciationth</th>
                            <th scope="col" class="table-header-color text-align-center col-md-2" style="color: #02aced;">Depreciated To</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of depreciationValueList; let i = index;">
                            <td class="text-align-left col-md-2">Book</td>
                            <td class="text-align-center col-md-2">{{fromParent.PurchasePrice}}</td>
                            <td class="text-align-center col-md-2">{{item.BasisValue - getAccumulatedDepreciation(i)}}</td>
                            <td class="text-align-center col-md-2">{{item.DepreciationAmount}}</td>
                            <td class="text-align-center col-md-2">{{item.DepreciationAmount}}</td>
                            <td class="text-align-center col-md-2">{{item.DepreciatedTo}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="fromParent.StatusId == 3" class="col-sm-12">
                <table class="table f-ss-14px">
                    <thead>
                        <tr class="tableHeader">
                            <th scope="col" class="table-header-color text-align-left col-md-3" style="color: #02aced;">Basis</th>
                            <th scope="col" class="table-header-color text-align-center col-md-3" style="color: #02aced;">Cost Basis</th>
                            <th scope="col" class="table-header-color text-align-center col-md-3" style="color: #02aced;">Value</th>
                            <th scope="col" class="table-header-color text-align-center col-md-3" style="color: #02aced;">Gain or Loss</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-align-left col-md-2">Book</td>
                            <td class="text-align-center col-md-2">{{fromParent.PurchasePrice}}</td>
                            <td class="text-align-center col-md-2">0.00</td>
                            <td *ngIf="disposalData?.GainOrLoss >= 0.00" class="text-align-center col-md-2">{{disposalData?.GainOrLoss.toFixed(2)}}</td>
                            <td *ngIf="disposalData?.GainOrLoss < 0.00" class="text-align-center col-md-2">({{((-1) * disposalData?.GainOrLoss).toFixed(2)}})</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>    
    </div>
</div>