import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class FileEmitterService {

  @Output() fileRefIdUpdate: EventEmitter<any> = new EventEmitter();

  constructor() {}

  emitFileRefIdUpdate(data:any){
    this.fileRefIdUpdate.emit(data);
  }

}