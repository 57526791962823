import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MainServiceService } from '../../../service/Main/main-service.service';

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-create-contacts-page',
  templateUrl: './create-contacts-page.component.html',
  styleUrls: ['./create-contacts-page.component.css']
})
export class CreateContactsPageComponent implements OnInit {
  contactForm: FormGroup;
  contactType: number = 1;
  ContactItem: any = [];
  constructor(public fb: FormBuilder, private router: Router, private toaster: ToastrService, private mainService: MainServiceService, private spinner: NgxSpinnerService ) { }

  ngOnInit(): void {
    this.initContactForm();
    this.createBlankItem();
  }
  initContactForm = () => {
    this.contactForm = this.fb.group({
      PrimaryPersonFirstName : ['', Validators.required],
      CompanyName : ['', Validators.required],
      Email : [''],
      PostalAddress : [''],
      StreetAddress : [ ''],
      Website : [''],
      SkypeNo : [''],
      ContactNo : [''],
      IsCustomer : [false],
      IsSupplier : [false]
    });
  }

 setData() {
   let data = {
    PrimaryPersonFirstName: this.contactForm.value.PrimaryPersonFirstName,
    CompanyName: this.contactForm.value.CompanyName,
    Email : this.contactForm.value.Email,
    ContactNumber: this.contactForm.value.ContactNumber,
    IsCustomer : this.contactForm.value.IsCustomer,
    IsSupplier : this.contactForm.value.IsSupplier,
    ContactItems: this.ContactItem,
    SkypeNo: this.contactForm.value.SkypeNo,
    Website: this.contactForm.value.Website,
    StreetAddress: this.contactForm.value.StreetAddress,
    ContactNo: this.contactForm.value.ContactNo

   };
   return data;
 }
 numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}
  SaveContactInfo = () => {

    if (this.contactForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    var s = this.setData();
    this.spinner.show();
    this.mainService.saveContact(s).subscribe((x) => {
      this.toaster.success('Contact added successfully');
      this.spinner.hide();
      this.router.navigate(['/contacts/list/all']);
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }


  createBlankItem = () => {
    const item = { Phone: '', Email: '', Notes: '', selected: true };
    this.ContactItem.push(item);
  }

  removeContactItem = (i) => {
    let data = []
    this.ContactItem.map((x, index) => {
      if (index != i) {
        data.push(x);
      }
    })
    this.ContactItem = data;

  }
}
