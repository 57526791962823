import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { MainServiceService } from '../../../service/Main/main-service.service'
import { AuthService } from 'src/app/service/auth/auth.service';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-details-bank-accounts',
  templateUrl: './details-bank-accounts.component.html',
  styleUrls: ['./details-bank-accounts.component.css']
})

export class DetailsBankAccountsComponent implements OnInit {
  BankAcountForm: FormGroup;
  bankData: any = [];
  ifSubmit: boolean;
  bankId: number;
  redirectUrl: string='/accounting/bank-accounts';
  hasStep: boolean=false;

  constructor(public fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    public authService: AuthService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.bankId = Number(this.activeRoute.snapshot.paramMap.get("bankId"));
    //this.checkStepRequired();
    this.initBankAcountForm();
    this.GetBankAccountDetails();
  }

   GetBankAccountDetails = () => {

    this.mainService.GetBankDetialsById(this.bankId).subscribe((x) => {
      if (x) {

        this.bankData = x.Result;
        this.BankAcountForm.patchValue({
          Id: this.bankData.Id,
          BankName: this.bankData.BankName,
          AccountName: this.bankData.AccountName,
          AccountCode: this.bankData.AccountCode,
          AccountTypeId: this.bankData.AccountTypeId,
          IsBankRemittance: this.bankData.IsBankRemittance,
          IsCash: this.bankData.IsCash,
          IsBankTransfer: this.bankData.IsBankTransfer,
          IsCheque: this.bankData.IsCheque
        });
      }
    });
  }
  bankInfo(v)
  {
      this.ChnageBankInfoByDropdown(v.value.AccountTypeId);

  }
  initBankAcountForm = () => {

    this.BankAcountForm = this.fb.group({
      Id: [''],
      BankName: [''],
      AccountName: ['', Validators.required],
      AccountCode: ['', Validators.required],
      IsBankRemittance: [''],
      IsCash: [''],
      IsBankTransfer: [''],
      IsCheque: [''],
      AccountTypeId: ['0'],
    });
  }

  SaveBankInfo = () => {
    this.ifSubmit = true;
    if (this.BankAcountForm.invalid) {
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    this.spinner.show();
    console.log(this.BankAcountForm.value);
    this.mainService.updateBankAccount(this.BankAcountForm.value).subscribe((x) => {
      this.spinner.hide();
      if(x.Success){
        this.toaster.success('Bank Account has been successfully Updated !');
        this.router.navigate([this.redirectUrl]);
      }
      else{
        this.toaster.error(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
    });

  }

  ChnageBankInfoByDropdown(value)
  {
    if (value === '3'){
    this.BankAcountForm.patchValue({
      BankName: '',
      IsCash: false,
      IsBankTransfer: false,
      IsCheque: false
    });
  } else if (value === '0'){
    this.BankAcountForm.patchValue({
      Id: this.bankData.Id,
      BankName: this.bankData.BankName,
      AccountName: this.bankData.AccountName,
      AccountCode: this.bankData.AccountCode,
      AccountTypeId: value,
      IsBankRemittance: this.bankData.IsBankRemittance,
      IsCash: this.bankData.IsCash,
      IsBankTransfer: this.bankData.IsBankTransfer,
      IsCheque: this.bankData.IsCheque
    });
}else{
  this.BankAcountForm.patchValue({
    BankName: '',
    IsBankRemittance: false,
    IsCash: false,
    IsBankTransfer: false,
    IsCheque: false
  });
}

}
}
