import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditShipmentComponent } from '../edit-shipment/edit-shipment.component';

@Component({
  selector: 'app-shipment-edit',
  templateUrl: './shipment-edit.component.html',
  styleUrls: ['./shipment-edit.component.css']
})
export class ShipmentEditComponent implements OnInit {

  ShipmentId: string;
  public canEdit:boolean = true;
  agInit(params){
    this.ShipmentId = params.value;
    this.canEdit = params.data.StatusId==1?true:false;
  }

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {}
  
  editShipment=()=>{
    const modalRef = this.modalService.open(EditShipmentComponent, { size: 'lg', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.fromParent = {ShipmentId:this.ShipmentId};
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
}
