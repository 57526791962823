import { Component, OnInit } from '@angular/core';
import { AdvanceService } from '../../../../service/Advance/advance.service'
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvanceCreateComponent } from '../advance-create/advance-create.component';
import { AdvanceViewComponent } from '../advance_view/advance-view/advance-view.component';
@Component({
  selector: 'app-advance-list',
  templateUrl: './advance-list.component.html',
  styleUrls: ['./advance-list.component.css']
})
export class AdvanceListComponent implements OnInit {

  constructor(
    private advanceService: AdvanceService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private toaster: ToastrService) { }

private gridApi;
domLayout="autoHeight"
private gridColumnApi;
dataList: any = [];
datas: any = [];
searchText:string;
IsAdvance: boolean;
fromContactId: number;
 advStatus: any = {
  "All": 0,
  "Active": 1,
  "Old": 2
}
hasStep: boolean=false;
ngOnInit(): void {
  this.route.queryParams.subscribe(params => {
    this.IsAdvance = params.IsAdvance == undefined ? false : true;
    if (this.IsAdvance){
      this.fromContactId = params.contactId;
    }
  });
}
columnDefs = [
{ headerName: 'Advance No.', field: 'AdvanceNo', cellStyle: {color: 'blue',cursor: 'pointer'} },
{ headerName: 'Advance Type', field: 'TransactionTypeName'},
{ headerName: 'Date', field: 'TransactionDateVw' },
{ headerName: 'Business Partner', field: 'ContactName' },
{ headerName: 'Amount', field: 'Amount' },
{ headerName: 'Settlement', field: 'Settlement' },
{headerName: 'Balance',field:'Balance'}
];

defaultColDef = {
sortable: true,
filter: true,
flex: 1,
};

getData = (status: string) => {
  this.spinner.show();
  this.dataList = [];
  this.advanceService.GetAdvanceList().subscribe((x) => {
  this.spinner.hide();
  this.dataList = this.IsAdvance ? x.filter(xy => xy.ContactId == this.fromContactId) : x ;
  
  this.datas = this.advStatus[status] == this.advStatus.All ? this.dataList.filter(xy => xy.ParentId != null && (xy.TransactionTypeId == 1 || xy.TransactionTypeId == 2)) : 
      this.advStatus[status] == this.advStatus.Active ? this.dataList.filter(xy => xy.Balance != 0 && xy.ParentId != null && (xy.TransactionTypeId == 1 || xy.TransactionTypeId == 2)) :
      this.dataList.filter(xy => xy.Balance == 0 && xy.ParentId != null && (xy.TransactionTypeId == 1 || xy.TransactionTypeId == 2));
   
  }, (err) => {
  this.spinner.hide();
  this.toaster.error('Something went wrong please try again !');
  });
}

onGridReady(event) {
this.gridApi = event.api;
this.gridColumnApi = event.columnApi;
let type = this.route.snapshot.paramMap.get("status");
console.log(type);
this.reLoadRowData(type);
}

setChildData(e)
{
   const obj = {
    AccountId: e.data.AccountId,
    Amount: e.data.Amount,
    Name: e.AccountName,
    ContactId : e.data.ContactId,
    Description: e.data.Description,
    Id:  e.data.Id,
    TransactionDate: e.data.TransactionDate,
    TransactionTypeId: e.data.TransactionTypeId,
    AdvNo : e.data.AdvNo
   };
   return obj;
}


onRowClicked(e) {
  const value = this.setChildData(e);
  const data = this.dataList.filter(x => x.ContactId == e.data.ContactId && x.AccountId == e.data.AccountId && x.ParentId != null);
  const modalRef = this.modalService.open(AdvanceViewComponent, { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
  modalRef.componentInstance.fromParent = value;
  modalRef.componentInstance.childData = data;
  modalRef.result.then((result) => {
               }, (reason) => {
                 console.log('Dismissed action: ' + reason);
               });
}

/**
 * openAdvancePopup
 */
public openAdvancePopup() {
  const modalRef = this.modalService.open(AdvanceCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered: true });
  modalRef.result.then((result) => {
    if (result.Success)
    {
     this.getData('All');
    }
               }, (reason) => {
                 console.log('Dismissed action: ' + reason);
               });
}


onBtnExport() {
var params = this.getParams();
this.gridApi.exportDataAsCsv(params);
}

getParams() {
return {
suppressQuotes: '', 
columnSeparator: '',
customHeader: '',
customFooter: '',};
}

search=()=>{
this.gridApi.setQuickFilter(this.searchText);
}
reLoadRowData = (status: string) => {
  this.advStatus[status] == this.advStatus.All ? 
  this.getData('All') : this.advStatus[status] == this.advStatus.Active ?
  this.getData('Active') : this.advStatus[status] == this.advStatus.Old ?
  this.getData('Old') : this.getData('All');

}

}
