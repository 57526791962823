<nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
  <div class="sidebar-heading sidebarHead" style="width: 256px; height: 45px;">
    <a
      [routerLink]="['/']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <p>
        <img src="../../../assets/image/logo_dark.png" alt="logo_dark" class="logo" />
      </p>
    </a>
  </div>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
      <li class="nav-item dropdown">
        <div class="nav-item" aria-labelledby="navbarDropdown">
          <li>
            <span
              (click)="this.changeLanguage('en')"
              [ngClass]="{ selected: selectedLanguage == 'en' }"
              class="languageText languageName pall-0"
            >
              {{ 'HEADER.English' | translate }}
            </span>
            <span class="pall-0" style="font-weight: 900;">|</span>
            <span
              (click)="this.changeLanguage('bn')"
              [ngClass]="{ selected: selectedLanguage == 'bn' }"
              class="languageText languageName pall-0"
            >
              {{ 'HEADER.Bengali' | translate }}
            </span>
          </li>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="ml-2">
  <a href="tel: 01625592122" id="contact">For Support: 01625592122</a>
</div>
