<app-dashboard>
    <app-page-header-title
        mainTitle="Bank Account List"
        subTitle="Bank Account"
        backURL="/accounting/bank-accounts">
        <div class="full-width text-align-right">
            <button type="button" (click)="SaveBankInfo()" class="eCount-def-btn ml-4">
                Save
            </button>
        </div>
    </app-page-header-title>
    <div class="bodySection">
        <form [formGroup]="BankAcountForm" class="f-ss-14px"  [ngClass]="{'submitted':ifSubmit===true}">
        <div class="col-sm-12 row">
            <div class="col-sm-7">
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-3 col-form-label">Account Type</label>
                <div class="col-sm-6">
                    <select required class="form-control" style="width: 100%;" formControlName="AccountTypeId" (change)="bankInfo(BankAcountForm)">
                        <option value="0">Bank Account</option>
                        <option value="3">Cash Account</option>
                        <option value="5">Digital Cash Account</option>
                    </select>
                </div>
            </div>    

            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-3 col-form-label">Account Name</label>
                <div class="col-sm-6">
                    <input type="text" formControlName="AccountName" class="form-control" />
                </div>
            </div>

            <div class="form-group row"  *ngIf="BankAcountForm.value.AccountTypeId!=3">
                <label for="colFormLabel" class="col-sm-3 col-form-label">Bank Name</label>
                <div class="col-sm-6">
                    <input type="text" formControlName="BankName" class="form-control" />
                </div>
            </div> 
        </div> 
        <div class="col-sm-5" *ngIf="BankAcountForm.value.AccountTypeId == 0">
            <P class="til">Payment Mode</P>
            <div class="form-check form-group" *ngIf="BankAcountForm.value.AccountTypeId == 0">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="IsBankRemittance"
                  id="flexCheckDefault1"
                />
                <label class="form-check-label f-ss-17px" for="flexCheckDefault1">
                  Bank Remittance
                </label>
              </div>
              <div class="form-check form-group" *ngIf="BankAcountForm.value.AccountTypeId == 0">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="IsBankTransfer"
                  id="flexCheckDefault2"
                />
                <label class="form-check-label f-ss-17px" for="flexCheckDefault2">
                  Bank Transfer
                </label>
              </div>
              <div class="form-check form-group" *ngIf="BankAcountForm.value.AccountTypeId == 0">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="IsCash"
                  id="flexCheckDefault3"
                />
                <label class="form-check-label f-ss-17px" for="flexCheckDefault3">
                  Cash
                </label>
              </div>
              <div class="form-check form-group" *ngIf="BankAcountForm.value.AccountTypeId == 0">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="IsCheque"
                  id="flexCheckDefault4"
                />
                <label class="form-check-label f-ss-17px" for="flexCheckDefault4">
                  Cheque
                </label>
              </div>
        </div>
    </div>
        </form>
    </div>
</app-dashboard>