import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WareHouseShippedData } from 'src/app/models/inventoryOverview.model';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';

@Component({
  selector: 'app-modal-for-receive-product',
  templateUrl: './modal-for-receive-product.component.html',
  styleUrls: ['./modal-for-receive-product.component.css']
})
export class ModalForReceiveProductComponent implements OnInit {

  @Input() fromParent: any;
  AllDaTa:any=[]
  receiveProduct:any;
  ReceiveProductForm:FormGroup
  wareHouseShippedData :WareHouseShippedData
  sumOfShipped:any=0;
  sumOfDemand:any=0;
  constructor(
    public activeModal: NgbActiveModal,
    private deliveryOrderService:InventoryOverviewService,
    private toaster: ToastrService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.pageLoad();
  }

  public pageLoad()
  {
    this.AllDaTa=this.fromParent.allDATA;
    this.receiveProduct=this.fromParent.receiveProduct;
    this.sumOfProduct();

  }

  public initializeForm()
  {
    this.ReceiveProductForm=new FormGroup({
      BtnOne: new FormControl('1'),
    })
  }

  sumOfProduct()
  {
    this.AllDaTa.WareHouseItems.forEach(item=>
      {
        this.sumOfDemand+=Number((item.Demand));
        this.sumOfShipped+=Number(item.ProductInOut);
      })
  }

  public Received()
  {
    this.activeModal.close()
    this.AllDaTa.WareHouseShippedType=this.ReceiveProductForm.controls['BtnOne'].value;
    var data = {
      WareHouseData : this.AllDaTa.WareHouseData,
      WareHouseItemsData:this.AllDaTa.WareHouseItems,
      WareHouseShippedType:this.AllDaTa.WareHouseShippedType
    }
    this.deliveryOrderService.sendReceiveData(data).subscribe((x)=>
    {
      if (x.Success) {
        this.toaster.success('Product Received successfully!');
        this.route.navigate(['inventory/receipt-waiting/', this.AllDaTa.WareHouseData.Id]);
        location.reload();
      }
      else{
        this.toaster.warning('Something Went Wrong!');
        this.route.navigate(['/']);
      }

    });
  }

}
