<div>
    <div class="float-right col-sm-12">
        <div style="float: left;">
            <h4 class="modal-title" style="font-size: 19px;margin-top: 20px;">Create {{fromParent.Description}}</h4>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
      </div>
    
    <div>
        
    <div class="mrl">
        <button class="eCount-def-btn" [disabled]="billCreationForm.invalid" (click)="saveBasicBill()">Save</button>
        <button class="eCount-def-btn ml-4 approve_bg" [disabled]="billCreationForm.invalid" (click)="approveBasicBill()">Approve</button>
    </div>
    </div>
    <hr>
</div>


<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">
        <form [formGroup]="billCreationForm">
            <div class="row">
                <div class="col-md-6">
                
                    <div class="form-group">
                        <label for="Description" style="font-weight: 600;">Description <span
                                class="required-sign">*</span></label>
                        <textarea class="form-control form-input" style="height: 60px;"
                            formControlName="Description"></textarea>
                    </div>
                    <div class="form-group" *ngIf="fromParent.CategoryId==1">
                        <label for="To" style="font-weight: 600;">To <span
                            class="required-sign">*</span></label>

                            <ng-select [items]="supplierList" bindLabel="CompanyName" bindValue="Id"
                                [addTag]="CreateNewSupplier" [loading]="loading" formControlName="SupplierId">
                                <ng-template ng-tag-tmp let-search="searchTerm">
                                    <b>+ Add New Supplier</b>: {{search}}
                                </ng-template>
                            </ng-select>
    
                    </div>
                    <div class="form-group">
                        <label for="BillDate" style="font-weight: 600;">Date <span
                                class="required-sign">*</span></label>
                        <input type="date" class="form-control form-input" formControlName="BillDate" id="bbtest" data-date="" data-date-format="D MMM YYYY"  (change)="show($event)" required>
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Amount" style="font-weight: 600;">Amount <span
                                class="required-sign">*</span></label>
                        <input type="text" class="form-control form-input" appDecimaNumericValidator
                            style="font-size: 26px;font-weight: bold;" formControlName="Amount">
                    </div>
                    <div class="form-group" *ngIf="fromParent.CategoryId==1">
                        <label for="AccountId" style="font-weight: 600;">Accounts</label>
                        <ng-select [items]="accountList" placeholder="Select Account" class="customSaleItem"
                            [clearable]="false" bindLabel="AccountName" bindValue="Id" formControlName="AccountId"
                            groupBy="GroupName">
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label style="font-weight: 600;">Repeating</label>
                        <label class="switch">
                            <input class="switch-input" type="checkbox" formControlName="IsRepeating">
                            <span class="switch-label" data-on="Yes" data-off="No" (change)="validateType(billCreationForm.value.IsRepeating)"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                    <div class="form-group" *ngIf="billCreationForm.value.IsRepeating">
                        <label for="RepeatingTypeId" style="font-weight: 600;">Repeating Types<span
                            class="required-sign">*</span></label>
                        <ng-select [items]="repeatingTypeList" bindLabel="Name" bindValue="Id" 
                            formControlName="RepeatingTypeId" (change)="validateType(billCreationForm.value.IsRepeating)">
                        </ng-select>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>