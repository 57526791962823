<app-dashboard>
  <app-page-header-title mainTitle="Pending List" subTitle="Home" backURL="/">
    <div class="full-width text-align-right pb-2">
      <input type="text" placeholder="search" class="searchBox" [(ngModel)]="searchText" (ngModelChange)="search()">
      <button type="button" (click)="getSelectedRowData()" class="eCount-def-btn ml-4"
        >Approve</button>
      <button type="button" style="background: red;" (click)="getSelectedRowDataForReject()" class="eCount-def-btn ml-4"
        *ngIf="canApprove && pageType != PageType.PurchaseOrder">Reject</button>
      <button class="eCount-button3 ml-4" (click)="convetToPDF()">Export</button>
    </div>

  </app-page-header-title>
  <ul class="nav nav-tabs justify-content-center" *ngIf="!IsOnlyHasDocumentFeature()">
    <li class="nav-item ">
      <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('invoices')" [routerLinkActive]="['active']"
        routerLink="/pending-list/invoices">Invoice ({{invoiceCount}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('bill')" [routerLinkActive]="['active']"
        routerLink="/pending-list/bill">Bill ({{billCount}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('purchase-order')"
        [routerLinkActive]="['active']" routerLink="/pending-list/purchase-order">Purchase Order
        ({{purchaseOrderCount}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('document')" [routerLinkActive]="['active']"
        routerLink="/pending-list/document">Document ({{documentCount}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('grantLoan')" [routerLinkActive]="['active']"
        routerLink="/pending-list/grantLoan">GrantLoan ({{loanCount}})</a>
    </li>
  </ul>
  <div class="row pt-3" id="pl" *ngIf="status!='document' && status!='purchase-order'">
    <ag-grid-angular class="ag-theme-balham col" style="font-size: 14px;" class="ag-theme-balham col" [rowData]="data"
      [columnDefs]="columnDefs" [domLayout]="domLayout" (cellClicked)="onCellClicked($event)"
      [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

  <div class="row pt-3" id="pl" *ngIf="status=='purchase-order'">
    <ag-grid-angular class="ag-theme-balham col" style="font-size: 14px;" class="ag-theme-balham col" [rowData]="data"
      [columnDefs]="columnDefs4" [domLayout]="domLayout" (cellClicked)="onCellClicked($event)"
      [defaultColDef]="defaultColDef" [rowSelection]="rowSelection2" [suppressRowClickSelection]="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

  <div class="row pt-3" id="pl" *ngIf="status=='document' && hideData">
    <ag-grid-angular class="ag-theme-balham col" style="font-size: 14px;" class="ag-theme-balham col" [rowData]="data"
      [columnDefs]="columnDefs2" [domLayout]="domLayout" (cellClicked)="onCellClicked($event)"
      [defaultColDef]="defaultColDef" [rowSelection]="rowSelection2" [suppressRowClickSelection]="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>

  <div class="row pt-3" id="pl" *ngIf="status=='document' && !hideData">
    <ag-grid-angular class="ag-theme-balham col" style="font-size: 14px;" class="ag-theme-balham col" [rowData]="data"
      [columnDefs]="columnDefs3" [domLayout]="domLayout" (cellClicked)="onCellClicked($event)"
      [defaultColDef]="defaultColDef" [rowSelection]="rowSelection2" [suppressRowClickSelection]="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>


  <ngx-smart-modal #AttachmentViewer identifier="AttachmentViewer">
    <div class="pb-4">
      <span class="modalHeaderText pl-2" class="attachment-header">{{
        selected?.FileName
        }}</span>
      <hr class="notShow" />
      <div class="row pr-2 pl-2 mr-2">
        <div class="col-md-12 notShow">
          <div class="row">
            <div class="col-md-4">
              <table style="color: #7f7f7f; font-size: 13px">
                <tr>
                  <td>Document Name :</td>
                  <td>
                    {{ selected?.DocumentName }}
                  </td>
                </tr>
                <tr>
                  <td>Tag :</td>
                  <td>
                    {{ selected?.DocumentType }}
                  </td>
                </tr>
                <tr>
                  <td>Created Date :</td>
                  <td>
                    {{ convertDateToDisplay(selected?.UploadedAt) }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-8" style="text-align: right">
              <a><span class="option-icon-color" (click)="zoomIn()"><i
                    class="material-icons borderradiusleft attachmenticon">zoom_in</i></span></a>
              <a><span class="option-icon-color" (click)="zoomOut()"><i
                    class="material-icons attachmenticon">zoom_out</i></span></a>
              <a [hidden]="isPdf"><span class="option-icon-color" (click)="rotate('left')">
                  <i class="material-icons attachmenticon">rotate_left</i>
                </span></a>
              <a [hidden]="isPdf"><span class="option-icon-color" (click)="rotate('right')">
                  <i class="material-icons attachmenticon borderradiusright">rotate_right</i>
                </span></a>

            </div>
          </div>
        </div>
        <div id="contentToPrint" class="col-md-12">
          <div [hidden]="!isPdf">
            <!-- <iframe id="iframepdf" src={{src}}></iframe> -->
            <!-- <embed width="100%" height="1000px" src={{src}}/> -->
            <pdf-viewer [src]="src" [render-text]="true" [fit-to-page]="true" [zoom]="zoom" style="display: block">
            </pdf-viewer>
          </div>
          <div [hidden]="isPdf">
            <div style="overflow: overlay">
              <img id="image" style="width: 100%" src="{{ src }}" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
</app-dashboard>