<div class="modal-header" style="top: 10px;">
  <h4  class="modal-title" style="font-size: 17px; font-weight: 500;" *ngIf="modalType!='approve'">Do you want to Reject this document?</h4>
  <h4  class="modal-title" style="font-size: 17px; font-weight: 500;" *ngIf="modalType=='approve'">Do you want to Approve this document?</h4>
  <div class="mr-3">
    <button type="button" class="close" aria-label="Close"(click)="activeModal.close('Cross click')">
      <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
  </div>
</div>
<hr>
<div id="modalContainer" class="modal-body" >
    <div class="sub_section">
        <div class="sub_section_title" ><p *ngIf="RejectDocumentModalData?.BillNo" >{{RejectDocumentModalData?.BillNo}}</p></div>

        <div [formGroup]="otpForm" *ngIf="isMobileOTPRequiredForApproval">
          <label>Mobile OTP Verification<span style="color: red;">*</span></label>
          <div>
            <input *ngIf="!isOTPSent" placeholder="Enter Mobile No 01XXXXXXXXX" formControlName="MobileNumber" class="form-control" type="text" />
            <input *ngIf="isOTPSent" formControlName="OTPCode" class="form-control" type="text" placeholder="Enter OTP" />
          </div>
          <div>
            <button *ngIf="!isOTPSent" class="bg_skyblue" (click)="submitOTP()">Generate OTP</button>
            <button *ngIf="isOTPSent" class="bg_skyblue" (click)="submitValidationCode()">Submit OTP</button>
          </div>
          <div *ngIf="isSmSOTPVerified" class="text_color_green">Verified</div>
        </div>
        <div id="recaptcha-container" style="margin: 36px;"></div>


        <div [formGroup]="emailForm" class="mt-2" *ngIf="isEmailOTPRequiredForApproval">
          <label>Email OTP Verification<span style="color: red;">*</span></label>
          <div>
            <input *ngIf="!isEmailOTPSent" placeholder="Enter Email" formControlName="Email" class="form-control" type="text" />
            <input *ngIf="isEmailOTPSent" formControlName="OTPCode" class="form-control" type="text" placeholder="Enter OTP" />
          </div>
          <div>
            <button *ngIf="!isEmailOTPSent" class="bg_skyblue" (click)="submitEmailOTP()">Generate OTP</button>
            <button *ngIf="isEmailOTPSent" class="bg_skyblue" (click)="submitEmailOTPCode()">Submit OTP</button>
          </div>
          <div *ngIf="isEmailOTPVerified" class="text_color_green">Verified</div>
        </div>


        <div class="sub_section_content_1 mt-2" >
            <form [formGroup]="noteForm" >
                <label>Note <span style="color: red;" *ngIf="RejectDocumentModalData?.dataType !='approve'">*</span></label>
                <!-- <input class="form-control noteInput" type="text"  /> -->
                <textarea
                    rows="6"
                    class="form-control m-1"
                    formControlName="Note"
                >
                </textarea>
            </form>
        </div>

        <div class="sub_section_content_2 mt-2" >
          <label>Signature <span style="color: red;">*</span></label>
          <div class="das mla row m-1" *ngIf="imageURL">
            <div class="col-md-3 ml-0 pl-0"> </div>
            <div class="imagePreview col-md-6" *ngIf="imageURL">
              <img [src]="imageURL" [alt]="" class="mt-2" style="height: 120px; width: 350px;">
            </div>
          </div>
          <div *ngIf="!imageURL" >
            <p class="red" >Please provide an image of your signature in the profile page.</p>
          </div>
        </div>



        <div class="sub_section_content_3 text-right" >
            <button *ngIf="canPressConfirmButton()" class="bg_skyblue" (click)="confirm($event)">Confirm</button>
            <button *ngIf="!canPressConfirmButton()" class="bg_gray" disabled>Confirm</button>
            <button class="bg_gray" (click)="activeModal.close('Cross click')" >Cancel</button>
        </div>

        

    </div>
</div>

