<app-auth-header></app-auth-header>

<div class="page-content">
  <!-- Main content -->
  <div class="content-wrapper">
    <div class="login">
      <h3
        class="my-4"
        style="
          text-align: center;
          padding-top: 80px;
          color: #245b9f;
          font-family: 'Segoe UI semibold';
        "
      >
        Reset your password?
      </h3>
      <h5 class="text-center font-weight-bold text-justify">
        Your password should be difficult for others to guess.
      </h5>
      <p
        style="
          text-align: center;
          font-weight: 600;
          font-family: Segoe UI Semilight;
        "
        class="text-center text-justify"
      >
        We recommend that you use a combination of upper case and lower case
        letters as well as numbers.
      </p>
      <label class="form-group inputField text-center">
        <input
          class="form-control form-input"
          placeholder=" "
          formControlName="Email"
          maxlength="20"
        />
        <span>New Password</span>
      </label>
      <label class="form-group inputField text-center">
        <input
          class="form-control form-input"
          placeholder=" "
          formControlName="Email"
          maxlength="20"
        />
        <span>RE-enter New Password</span>
      </label>
      <button
        type="submit"
        class="btn btn-primary inputField buttonStyle"
        style="width: 180px; margin-right: 25px;"
      >
        Reset Password
      </button>
      <button type="button" class="bg-white" style="border: none;">
        Cancel
      </button>
    </div>
  </div>
  <!-- Main content -->
</div>
<app-auth-footer></app-auth-footer>
