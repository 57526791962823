import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
import { ManufactureOrder } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { DocumentType } from 'src/app/models/documentType.model';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { DocumentTypeGroup } from 'src/app/models/documentTypeGroup.model';

@Component({
  selector: 'app-document-type-group-settings',
  templateUrl: './document-type-group-settings.component.html',
  styleUrls: ['./document-type-group-settings.component.css']
})

export class DocumentTypeGroupSettingsComponent implements OnInit {

  DocumentTypeGroupSettingsForm: FormGroup;
  @Input() hasDocumentTypeGroup: boolean;
  @Input() parentData: any;
  @Input() operationType: iQuidiActionType;
  documentTypeLoading = false;
  documentTypeGroupLoading = false;

  allDocumentTypes: any;
  allDocumentTypeGroups: any
  userInfo: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private authService: AuthService,
    private settingsService: SettingsServiceService,
    private mainService: MainServiceService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.getAllDocumentTypes();
    this.getAllDocumentTypeGroups();
    this.initializeForm();
    if(!this.mainService.IsNull(this.parentData)) this.setParentData();
  }

  getAllDocumentTypes() {
    this.settingsService.GetAllDocumentTypes().subscribe((x) => {
      this.allDocumentTypes = x;
    });
  }

  getAllDocumentTypeGroups() {
    this.settingsService.GetAllDocumentTypeGroups().subscribe((x) => {
      this.allDocumentTypeGroups = x;
    });
  }

  initializeForm() {
    this.DocumentTypeGroupSettingsForm = this.fb.group({
      DocumentTypeId: ['', Validators.required],
      DocumentTypeGroupId: [''],
      DocumentTypeName: ['']
    });
  }

  setParentData() {
    this.DocumentTypeGroupSettingsForm.get("DocumentTypeId").patchValue(this.parentData.Id);
    this.DocumentTypeGroupSettingsForm.get("DocumentTypeName").patchValue(this.parentData.Name);
    this.DocumentTypeGroupSettingsForm.get("DocumentTypeGroupId").patchValue(this.parentData.DocumentTypeGroupId);
  }

  CreateNewDocumentType(documentTypeName: string) {
    let Data: DocumentType = {
      Id: 0,
      Name: documentTypeName,
      BusinessUnitRoleId: this.userInfo.RoleId,
      CompanyId: 0
    };
    this.documentTypeLoading = true;
    this.settingsService.SaveDocumentType(Data).subscribe((x) => {
        if (x.Success) {
          this.toaster.success("Document type has been successfully added!");
          this.documentTypeLoading = false;
          this.allDocumentTypes?.push(x["Data"]);
          let Obj = this.allDocumentTypes.filter(x => x.Name == documentTypeName);
          this.DocumentTypeGroupSettingsForm.get("DocumentTypeId").patchValue(Obj[0]["Id"]);
          this.getAllDocumentTypes();
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.documentTypeLoading = false;
      }
    );
  }

  CreateNewDocumentTypeGroup(documentTypeGroupName: string) {
    let Data: DocumentTypeGroup = {
      Id: 0,
      Name: documentTypeGroupName,
      BusinessUnitRoleId: this.userInfo.RoleId,
      CompanyId: 0
    };
    this.documentTypeGroupLoading = true;
    this.settingsService.SaveDocumentTypeGroup(Data).subscribe((x) => {
        if (x.Success) {
          this.toaster.success("Document type group has been successfully added!");
          this.documentTypeGroupLoading = false;
          this.allDocumentTypeGroups.push(x["Data"]);
          let Obj = this.allDocumentTypeGroups.filter(x => x.Name == documentTypeGroupName);
          this.DocumentTypeGroupSettingsForm.get("DocumentTypeGroupId").patchValue(Obj[0]["Id"]);
          this.getAllDocumentTypeGroups();
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.documentTypeGroupLoading = false;
      }
    );
  }

  save() {
    if(!this.DocumentTypeGroupSettingsForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    if(this.hasDocumentTypeGroup && this.mainService.IsNull(this.DocumentTypeGroupSettingsForm.value.DocumentTypeGroupId)){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    if(this.operationType == iQuidiActionType.Update && this.mainService.IsNull(this.DocumentTypeGroupSettingsForm.value.DocumentTypeName)){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    if(this.operationType == iQuidiActionType.Update){
      let availableDocument = this.allDocumentTypes.find(x => x.Name == this.DocumentTypeGroupSettingsForm.value.DocumentTypeName);
      if(!this.mainService.IsNull(availableDocument)){
        if(availableDocument.Id != this.DocumentTypeGroupSettingsForm.value.DocumentTypeId){
          this.toaster.warning('This document type name already exists!');
          return;
        }
      }
    }
    let data: DocumentType = this.formatData();
    this.settingsService.UpdateDocumentType(data).subscribe((x) => {
      if(x?.Success){
        this.toaster.success(x?.Message);
      }
      else{
        this.toaster.error(x?.Message);
      }
      this.activeModal.close('Success');
    })
  }

  formatData(): DocumentType{
    let availableDocument = this.allDocumentTypes.find(x => x.Id == this.DocumentTypeGroupSettingsForm.value.DocumentTypeId);
    let documentType: DocumentType = new DocumentType();
    documentType.Id = this.DocumentTypeGroupSettingsForm.value.DocumentTypeId;
    documentType.DocumentTypeGroupId = this.mainService.IsNull(this.DocumentTypeGroupSettingsForm.value.DocumentTypeGroupId) ? null : this.DocumentTypeGroupSettingsForm.value.DocumentTypeGroupId
    documentType.Name = this.operationType == iQuidiActionType.Save ? availableDocument.Name : this.DocumentTypeGroupSettingsForm.value.DocumentTypeName;
    documentType.BusinessUnitRoleId = availableDocument.BusinessUnitRoleId;
    documentType.CompanyId = availableDocument.CompanyId;
    return documentType;
  }

}

