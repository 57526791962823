import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-service-loader',
  templateUrl: './service-loader.component.html',
  styleUrls: ['./service-loader.component.css']
})
export class ServiceLoaderComponent implements OnInit {

  constructor(private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
  }

}
