import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewSalesOrderComponent } from '../view-sales-order/view-sales-order.component';

@Component({
  selector: 'app-booked-stock-view',
  templateUrl: './booked-stock-view.component.html',
  styleUrls: ['./booked-stock-view.component.css']
})
export class BookedStockViewComponent implements OnInit {

  itemId:number = 0;
  bookedStock:number = 0;
  canShowSalesOrder:boolean = false;
  agInit(params){
    this.itemId = params.data.Id;
    this.bookedStock = params.data.BookedStock;
    this.canShowSalesOrder = params.data.ItemTypeId == 3 ? true : false ;
  }

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }
  
  viewItem=()=>{
    const modalRef = this.modalService.open(ViewSalesOrderComponent, { size: 'lg', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.fromParent = {ItemId:this.itemId};
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

}
