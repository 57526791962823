<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">Adjust Advance</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="adjustAdvanceForm" class="f-ss-14px">

                    <div class="form-group  col-md-12">
                        <label for="TotalAdvanceAmount" style="font-weight: 600;">Advanced Amount</label>
                        <input type="number" readonly class="form-control form-input" formControlName="TotalAdvanceAmount">
                    </div>
                    <div class="form-group  col-md-12 mt-3">
                        <label for="Name" style="font-weight: 600;">Remaining Advance</label>
                        <input type="number" readonly class="form-control form-input" formControlName="RemainingBalance">
                    </div>
                    <div class="form-group  col-md-12 mt-3">
                        <label for="TotalAdvanceAmount" style="font-weight: 600;">Amount Due on Sales Order</label>
                        <input type="number" readonly class="form-control form-input" formControlName="AdjustableAmount">
                    </div>
                    <div class="form-group  col-md-12">
                        <label for="AdjustAmount" style="font-weight: 600;">Adjust Amount<span
                            class="required-sign">*</span> </label>
                        <input type="number" (keypress)="numberOnly($event)"  required (change)="CalculateDue()" class="form-control form-input" formControlName="AdjustAmount">
                    </div>
                    <div class="form-group  col-md-12">
                        <label for="RemainingDueAmount" style="font-weight: 600;">Remaining Due on Sales Order</label>
                        <input type="number" readonly required class="form-control form-input" formControlName="RemainingDueAmount">
                    </div>
                        
                </form>
            </div>
            <div class="col-md-12">
                <button type="button" [disabled]="adjustAdvanceForm.invalid" (click)="save()"
                    class="eCount-def-btn  ml-4 p-save-button">Confirm Adjust</button>
                    <button type="button" (click)="skipAdjustment()"
                class="eCount-def-btn  ml-4 p-save-button">Skip</button>
            </div>
        </div>
    </div>
</div>
