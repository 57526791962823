<app-dashboard>
    <app-page-header-title mainTitle="All Business Owner" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
            <button type="button" routerLink="/owner/business-owner-create"
                class="eCount-def-btn ml-4">Add new Owner</button>
            <button
                class="eCount-button3 ml-4" (click)="convetToPDF()">{{'Contacts.contactList.export'| translate}}</button>
        </div>
        
    </app-page-header-title>

    <div class="pt-3" id="owner">
        <ag-grid-angular class="ag-theme-alpine"  style="width: 100%;height: 420px;" #topGrid id="topGrid"  [rowData]="contacts"
        [columnDefs]="columnDefs" (gridReady)="onGridReady($event)"   (rowClicked)="onRowClicked($event)" [defaultColDef]="defaultColDef">
    </ag-grid-angular>
    </div>
    
</app-dashboard>

