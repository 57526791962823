<app-dashboard>
    <app-page-header-title mainTitle="{{'FIXEDASSET.fixedAssetSettings' |translate}}" subTitle="Back" backURL="/fixed-asset/setup/draft">
    </app-page-header-title>
    <div class="row" style="margin-bottom: 20px;">
            <button type="button" (click)="CreateNewAssetType()" class="eCount-def-btn  ml-4 p-save-button">
                {{'FIXEDASSET.newAssetType' |translate}}</button>
   
            <button type="button" (click)="ChangeStartDate()" class="eCount-def-btn  ml-4 p-save-button">
                 Change Start Date</button>
        
    </div>
    <div class="row">
        <div class="w-100 col">
            <table class="table  f-ss-14px">
                <thead>
                    <tr class="tableHeader">
                        <th scope="col" class="table-header-color text-align-left">Asset Type
                        </th>
                        <th scope="col" class="table-header-color text-align-center">Asset Account
                        </th>
                        <th scope="col" class="table-header-color text-align-center">Accumulated Depreciation Account
                        </th>
                        <th scope="col" class="table-header-color text-align-center">Depreciation Expense Account</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of assetTypeList">
                        <td class="text-align-left">
                            <span class="asset-type" (click)="editAssetType(item)">{{item.AssetType}}</span>
                        </td>
                        <td class="text-align-center">{{item.AssetAccount}}</td>
                        <td class="text-align-center">{{item.DepreciationAccount}}</td>
                        <td class="text-align-center">{{item.ExpenseAccount}}</td>
                       
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-dashboard>

