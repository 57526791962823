import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ReturnTimePolicy } from 'src/app/models/returnTimePolicy.model';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { ReturnTimePolicyOperationType } from 'src/app/enum/return-time-policy-operation-type';

@Component({
  selector: 'app-return-policy-settings',
  templateUrl: './return-policy-settings.component.html',
  styleUrls: ['./return-policy-settings.component.css']
})

export class ReturnPolicySettingsComponent implements OnInit {

  PolicySettingsForm: FormGroup;
  allDays: number[] = [0, 1, 2, 3, 4, 5, 6, 7];
  returnTimePolicyData: ReturnTimePolicy[];


  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private settingsService: SettingsServiceService,
  ) { }

  ngOnInit(): void {
    this.getReturnTimePolicy();
    this.initializeForm();
  }

  getReturnTimePolicy() {
    this.settingsService.GetReturnTimePolicy().subscribe(x => {
      this.returnTimePolicyData = x;
      this.setParentData(this.returnTimePolicyData);
    })
  }

  setParentData(returnTimePolicyData: ReturnTimePolicy[]) {
    for(let i = 0; i < returnTimePolicyData.length; i++) {
      if(returnTimePolicyData[i].OperationType == ReturnTimePolicyOperationType.SALESRETURN){
        this.PolicySettingsForm.controls['SalesReturnId'].patchValue(returnTimePolicyData[i].Id);
        this.PolicySettingsForm.controls['SalesReturnCompanyId'].patchValue(returnTimePolicyData[i].CompanyId);
        this.PolicySettingsForm.controls['SalesReturnDurationDays'].patchValue(returnTimePolicyData[i].ReturnDurationDays);
        this.PolicySettingsForm.controls['SalesOperationType'].patchValue(returnTimePolicyData[i].OperationType);
      }
      else{
        this.PolicySettingsForm.controls['PurchaseReturnId'].patchValue(returnTimePolicyData[i].Id);
        this.PolicySettingsForm.controls['PurchaseReturnCompanyId'].patchValue(returnTimePolicyData[i].CompanyId);
        this.PolicySettingsForm.controls['PurchaseReturnDurationDays'].patchValue(returnTimePolicyData[i].ReturnDurationDays);
        this.PolicySettingsForm.controls['PurchaseOperationType'].patchValue(returnTimePolicyData[i].OperationType);
      }
    }
  }

  initializeForm() {
    this.PolicySettingsForm = this.fb.group({
      SalesReturnId: [0],
      SalesReturnCompanyId: [0],
      SalesReturnDurationDays: [''],
      SalesOperationType:[ReturnTimePolicyOperationType.SALESRETURN],

      PurchaseReturnId: [0],
      PurchaseReturnCompanyId: [0],
      PurchaseReturnDurationDays: [''],
      PurchaseOperationType:[ReturnTimePolicyOperationType.PURCHASERETURN],
    });
  }

  Save() {
    this.PolicySettingsForm.value.PurchaseReturnDurationDays
    if((this.PolicySettingsForm.value.SalesReturnDurationDays == null || this.PolicySettingsForm.value.SalesReturnDurationDays == '') && (this.PolicySettingsForm.value.PurchaseReturnDurationDays == null || this.PolicySettingsForm.value.PurchaseReturnDurationDays == '')) {
      this.toaster.warning('Please select return days!');
      return;
    }
    const data: ReturnTimePolicy[] = this.MappedData();
    let saveData = data.filter(x => x.Id == 0);
    let updateData = data.filter(x => x.Id != 0);
    this.spinner.show();
    if(saveData.length > 0){
      this.settingsService.SaveReturnTimePolicy(saveData).subscribe(x => {
        if(x?.Success) {
          this.toaster.success(x?.Message);
          this.getReturnTimePolicy();
        }
        else{
          this.toaster.error(x?.Message)
        }
        this.spinner.hide();
      });
    }
    if(updateData.length > 0){
      this.settingsService.UpdateReturnTimePolicy(updateData).subscribe(x => {
        if(x?.Success) {
          this.toaster.success(x?.Message);
          this.getReturnTimePolicy();
        }
        else{
          this.toaster.error(x?.Message)
        }
        this.spinner.hide();
      });
    }
  }

  MappedData() {
    let returnTimePolicys: ReturnTimePolicy[] = [];
    for(let i = 0; i < 2; i++) {
      let returnTimePolicy = new ReturnTimePolicy();
      if(this.PolicySettingsForm.value.SalesReturnDurationDays != null && this.PolicySettingsForm.value.SalesReturnDurationDays != '' && i == 0) {
        returnTimePolicy.Id = this.PolicySettingsForm.value.SalesReturnId;
        returnTimePolicy.CompanyId = this.PolicySettingsForm.value.SalesReturnCompanyId;
        returnTimePolicy.ReturnDurationDays = this.PolicySettingsForm.value.SalesReturnDurationDays;
        returnTimePolicy.OperationType = this.PolicySettingsForm.value.SalesOperationType;
        returnTimePolicys.push(returnTimePolicy);
      }
      if(this.PolicySettingsForm.value.PurchaseReturnDurationDays != null && this.PolicySettingsForm.value.PurchaseReturnDurationDays != '' && i == 1) {
        returnTimePolicy.Id = this.PolicySettingsForm.value.PurchaseReturnId;
        returnTimePolicy.CompanyId = this.PolicySettingsForm.value.PurchaseReturnCompanyId;
        returnTimePolicy.ReturnDurationDays = this.PolicySettingsForm.value.PurchaseReturnDurationDays;
        returnTimePolicy.OperationType = this.PolicySettingsForm.value.PurchaseOperationType;
        returnTimePolicys.push(returnTimePolicy);
      }
    }
    return returnTimePolicys;
  }
}

