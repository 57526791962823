import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from 'src/environments/environment';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth/auth.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheckSquare, faBan, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OperationType } from 'src/app/enum/operation-type';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalHistoryModalService } from 'src/app/service/Approval History Modal/approval-history-modal.service';
import { ApprovalHistoryModalComponent } from 'src/app/components/approval-history-modal/approval-history-modal/approval-history-modal.component';
@Component({
  selector: 'app-file-showcase',
  templateUrl: './file-showcase.component.html',
  styleUrls: ['./file-showcase.component.css'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('1500ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})
export class FileShowcaseComponent implements OnInit{
  name: string = undefined;
  files: any;
  isPdf: boolean;
  zoom: number = 1;
  imagezooming: number;
  fileUrl = environment.fileBaseUrl;
  routes: string;
  users: [];
  searchText: string = "";
  imageSearch: string = "";
  url:string="";
  firstHalf:any;
  secondHalf:any;
  tempFiles:any;
  fileName: string;
  changeOnInventory = false;
  seeMoreOption:boolean = false;
  customerName:string;

  faCheckSquare = faCheckSquare;
  faBan = faBan;
  faInfoCircle = faInfoCircle;

  OperationType: OperationType;
  constructor(private authService:AuthService,
              private toastr: ToastrService,
              private mainService: MainServiceService, 
              private spinner: NgxSpinnerService, 
              private aroute: ActivatedRoute, 
              private location: Location, 
              private router: Router, 
              private ngxSmartModalService: NgxSmartModalService, 
              private attachmentService: AttachmentServiceService,
              private modalService: NgbModal,
              private approvalHistoryModalService: ApprovalHistoryModalService) {
    this.name = undefined;

    router.events.subscribe(val => {
      if (location.path() != " ") {
        this.routes = location.path();
        let route = aroute.snapshot.paramMap.get("name");
        if (this.name != route) {
          this.spinner.show();
          this.name = aroute.snapshot.paramMap.get("name");
          switch (this.name) {
            case 'SharedDocuments':
              this.fileName = 'Shared Documents';
              this.OperationType = OperationType.DOCUMENT;
              break;
            case 'PurchaseOrder':
              this.fileName = 'Purchase Order';
              this.OperationType = OperationType.PURCHASE_ORDER;
              break;
            case 'Bill':
              this.fileName = this.name;
              this.OperationType = OperationType.BILL;
              break;
            case 'Invoice':
              this.fileName = this.name;
              this.OperationType = OperationType.INVOICE;
              break;
            case 'Quotation':
              this.fileName = this.name;
              this.OperationType = OperationType.QUOTATION;
              break;
            default:
              this.fileName = this.name;
              break;
          }
          this.getAllFilesByType(this.name);
        }
      } else {
        this.routes = "Home";
      }
      if(this.fileName=='Purchase Order' || this.fileName=='Bill'){
        this.customerName = 'Supplier ';
      }
      else if(this.fileName=='Item' || this.fileName=='Inventory'){
        this.customerName = 'Item';
      }
      else{
        this.customerName = 'Customer ';
      }
      if(this.fileName=='Item'){
        this.fileName = 'Inventory';
        this.customerName = 'Item ';
        this.changeOnInventory = true;
      }
    });

  }
  ngOnInit(): void {
    //this.name = String(this.aroute.snapshot.paramMap.get("name"));
    this.configBaseUrl();
  }

  configBaseUrl(){
    this.url = location.origin+"/#/";
    console.log(location.origin)
  }

  getAllFilesByType(type: string) {
    this.imageSearch = '';
    localStorage.setItem('type',type);
    console.log(type);
    this.attachmentService.getAllFilesByType(type).subscribe((x: any) => {
      this.spinner.hide();
      console.log(x);
      this.tempFiles = x;
      this.seeMoreOption = this.tempFiles.length > 8 ? true : false;
      if (this.seeMoreOption){
        this.tempFiles.reverse();
        this.firstHalf = this.tempFiles.slice(0, 8);
        this.secondHalf = this.tempFiles.slice(8, this.tempFiles.length + 1);
        this.files = [...this.firstHalf];
      }else{
        this.files = [...this.tempFiles];
      }
      
    },(err)=>{
      this.files=[];
      this.spinner.hide();
    })
    console.log('here is the view');
    console.log(this.files);
  }

  more(){
    this.files = [...this.firstHalf,...this.secondHalf];
    this.seeMoreOption = false;
  }

  mouseOver(id: number) {
    let element;
    element = document.getElementById("hover" + id);
    let element1 = document.getElementById("demo" + id);
    element1.classList.add("mdivpaddinghover");
    element1.classList.remove("mdivpadding");
    element.classList.add("mystyle");
    let element2 = document.getElementById("imagesview" + id)
    //element2.classList.remove("images");
    element2.classList.add("imagediv-hover");
    document.getElementById("options" + id).classList.add("show");
    document.getElementById("options" + id).classList.remove("hide");
  }

  mouseOut(id: number) {
    let element;
    element = document.getElementById("hover" + id);
    let element1 = document.getElementById("demo" + id);
    element1.classList.remove("mdivpaddinghover");
    element1.classList.add("mdivpadding");
    element.classList.remove("mystyle");
    let element2 = document.getElementById("imagesview" + id);
    element2.classList.remove("imagediv-hover");
    document.getElementById("options" + id).classList.remove("show");
    document.getElementById("options" + id).classList.add("hide");
  }

  convertDateToDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  src: string;
  isPdfFile(file: any) {
    let ext = file.BlobName.split(".")[1];
    return ext == 'pdf' ? true : false;
  }
  selected: any;
  imagewidth: number;
  view(file: any) {
    this.selected = file;
    this.rotationRightAmount = 0;
    this.rotationLeftAmount = 0;
    let ext = this.name == 'SharedDocuments' ? file.BlobName.split(".")[1] : file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
    this.imagezooming = 100;
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
    let img = document.getElementById('image');
    img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
  }


  share(file: any) {
    this.selected = file;
    this.getAllUsers();
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
  }

  print(file: any, id: any, pdf: boolean){
    window.open(`${this.url}files/print/${file.Id}`, '_blank');
  }

  download() {
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
  }
  
  zoomOut() {
    if (this.isPdf) {
      this.zoom -= .1;
    } else {
      this.imagezooming -= 10;
      let element = document.getElementById("image")
      element.style.width = this.imagezooming + "%";
    }
  }

  zoomIn() {
    if (this.isPdf) {
      this.zoom += .1;
    } else {
      this.imagezooming += 10;
      let element = document.getElementById("image");
      element.style.width = this.imagezooming + "%";
    }
  }
  rotationLeftAmount: number;
  rotationRightAmount: number;
  rotationAmount: number;
  rotate(direction: string) {
    this.rotationAmount = +90;
    if (direction == 'left') {
      this.rotationRightAmount -= 90;
      this.rotationLeftAmount += 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    } else {
      this.rotationRightAmount += 90;
      this.rotationLeftAmount -= 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }
  }

  getAllUsers() {
    this.spinner.show()
    this.users = []
    this.mainService.GetSubscribedUserList().subscribe((x) => {
      this.spinner.hide()
      this.users = x;
    })
  }

  shareFile(id:number){
    var userInfo = this.authService.getUserInfo();
    let SelectedCompany = userInfo.SelectedCompany;
    let CompanyId = userInfo.SelectedCompany.CompanyId;
    let Name = userInfo.SelectedCompany.Name;
    this.attachmentService.shareFile({ BlobName: this.selected.BlobName, SharedToId: id, ReferenceNo: this.selected.ReferenceNo,FileName:this.selected.FileName,Parent: this.name }).subscribe((x)=>{
      console.log(x);
      this.toastr.success("Shared successful", 'Success!');
    },(err)=>{
      console.log(err);
    })
  }

  modalViewFullHistory(data:any){
    this.spinner.show();
    console.log('coming here', data);
    let modalData = {
      DocumentBlobName:data.BlobName,
      DocumentName: data.FileName,
      DocumentType: data.ReferenceNo,
      Number: data.Id,
      UploadedAt: data.CreatedDate,
      UploadedBy: data.UpdatedByName,
      openFrom: 'overview',
      ApprovalStatus: data.ApprovalStatus
    }
    this.approvalHistoryModalService.saveModalInfoData(modalData);
    const modalRef = this.modalService.open(ApprovalHistoryModalComponent,
    { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.action = this.fileName; //Important! Bill, Invoice, Purchase Order etc not actual file name
    modalRef.componentInstance.OperationType = this.OperationType;
  }
}
