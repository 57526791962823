export class ItemCreateViewModel {
    Id: number;
    ItemCode: string;
    ItemName: string;
    Description: string;
    ItemTypeId: number;
    IsSaleItem: Boolean;
    SaleUnitPrice: number;
    IsPurchaseItem: Boolean;
    PurchasePrice: number;
    SupplierId: number;
    CompanyId: number;
    ActionById: string;
    IsManufactureItem: Boolean;
}