<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle">Assignee</h4>
    </div>
    <div class="button-container">
        <button type="button" class="eCount-def-btn ml-4" (click)="SaveAssignee()">Save</button>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="AddAssigneeForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-12">
                                <label for="AssigneeName" class="formLabelFontWeight">Assignee Name <span class="required-sign text-red">*</span></label>
                                <input class="form-control form-input" type="text" formControlName="AssigneeName">
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group col-sm-12">
                                <label for="AssigneeRole" class="formLabelFontWeight">Assignee Role</label>
                                <input class="form-control form-input" type="text" formControlName="AssigneeRole">
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group col-sm-12">
                                <label for="AssigneeEmail" class="formLabelFontWeight">Assignee Email <span class="required-sign text-red">*</span></label>
                                <input class="form-control form-input" type="Email" formControlName="AssigneeEmail">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>