import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { CreateItem } from "src/app/models/product.model";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { InventoryOverviewService } from "src/app/service/InventoryOverview/inventory-overview.service";
import { ProductServiceService } from "src/app/service/Products/product-service.service";
import { PurchaseOrderService } from "src/app/service/PurchaseOrder/purchase-order.service";

@Component({
  selector: "app-inventory-overview-receipt",
  templateUrl: "./inventory-overview-receipt.component.html",
  styleUrls: ["./inventory-overview-receipt.component.css"],
})
export class InventoryOverviewReceiptComponent implements OnInit {
  itemListTitle: string = "Inventory Overview/Receipts";
  public aModel: CreateItem = new CreateItem();
  itemTypeId: number = 1;
  deliveryStatus: any = {
    Waiting: 1,
    Shipped: 2,
    Ready: 3,
  };
  @Input() fromParent: any;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private dataService: DataService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private productServiceService: ProductServiceService,
    private activeroute: ActivatedRoute,
    private deliveryOrderService: InventoryOverviewService,
    private purchaseOrderService: PurchaseOrderService
  ) {}
  planId = 0;
  OrderList: any = [];
  allData: any = [];
  purchaseOrderData: any;
  grilcol;
  private gridApi;
  domLayout = "autoHeight";
  isItemListUpdate = false;
  searchText: string;
  pageValue: number;
  PurchaseOrderNO: any;
  BackUrl: any;
  PurchaseOrderId: any;
  SubTitle: any;

  ngOnInit(): void {
    this.searchText = this.route.snapshot.paramMap.get("id");
    this.PurchaseOrderNO = this.route.snapshot.paramMap.get("purchaseOrderNo");
    if( this.PurchaseOrderNO != null ){
      this.GetPurchaseOrderDetailsByOrderNo(this.PurchaseOrderNO);
    }else{
      this.BackUrl = '/products/item-list/6';
      this.SubTitle = 'Home';
    }
    this.pageLoad(this.searchText);
  }
  public pageLoad(status: string) {
    if(this.PurchaseOrderNO != null){
      this.purchaseDeliveryOrderPageLoad(status);
    }
    else{
      this.deliveryOrderPageLoad(status);
    }
  }

  deliveryOrderPageLoad(status: string){
    this.deliveryOrderService.GetDeliveryOrder().subscribe((x) => {
      this.allData = x;
      this.allData = this.allData.filter(
        (x) => x.OperationType == 1 || x.OperationType == 2
      );
      // filter for status
      switch (status) {
        case "All":
          this.allData = this.allData.filter(
            (x) => x.OperationType == 1 || x.OperationType == 2
          );
          break;
        case "Waiting":
          this.allData = this.allData.filter((x) => x.StatusId == 1);
          break;
        case "Ready":
          this.allData = this.allData.filter((x) => x.StatusId == 3);
          break;
        case "Received":
          this.allData = this.allData.filter((x) => x.StatusId == 2);
          break;
      }
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].SerialNo = i + 1;
      }
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].WHNO = "WH-In-" + this.allData[i].WareHouseProductNo;
        this.allData[i].ReferenceNo = this.getReferenceNo(i);
      }
    });
  }

  GetPurchaseOrderDetailsByOrderNo(PurchaseOrderNO){
    this.purchaseOrderService.GetPurchaseOrderDetailsByOrderNo(PurchaseOrderNO).subscribe((x) => {
      this.purchaseOrderData = x;
      this.PurchaseOrderId = this.purchaseOrderData.Id;
      this.BackUrl = '/procurement/purchase-order-details/'+this.PurchaseOrderId;
      this.SubTitle = 'Back';
    })
  }

  purchaseDeliveryOrderPageLoad(status){
    this.deliveryOrderService.GetDeliveryOrderByPurchaseOrderNo(this.PurchaseOrderNO).subscribe((x) => {
      this.allData = x;
      this.allData = this.allData.filter(
        (x) => x.OperationType == 1 || x.OperationType == 2
      );
      // filter for status
      switch (status) {
        case "All":
          this.allData = this.allData.filter(
            (x) => x.OperationType == 1 || x.OperationType == 2
          );
          break;
        case "Waiting":
          this.allData = this.allData.filter((x) => x.StatusId == 1);
          break;
        case "Ready":
          this.allData = this.allData.filter((x) => x.StatusId == 3);
          break;
        case "Received":
          this.allData = this.allData.filter((x) => x.StatusId == 2);
          break;
      }
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].SerialNo = i + 1;
      }
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].WHNO = "WH-In-" + this.allData[i].WareHouseProductNo;
        this.allData[i].ReferenceNo = this.getReferenceNo(i);
      }
    });
  }

  public getReferenceNo(index: number)
  {
    switch(this.allData[index].OperationType)
    {
      case 1:
        return "PO-"+this.allData[index].ReferenceNo;
      case 2:
        return "Bill-"+this.allData[index].ReferenceNo;
      case 3:
        return "SO-"+this.allData[index].ReferenceNo;
      case 4:
        return "INV-"+this.allData[index].ReferenceNo;
    }
  }

  public getReferenceNoLink(index: number)
  {
    switch(this.allData[index].OperationType)
    {
      case 1:
        return "#/procurement/purchase-order-details/"+this.allData[index].Id;
      case 2:
        return "#/expense/bill/Bill-"+this.allData[index].ReferenceNo;
      case 3:
        return "#/sales/sales-order-details/"+this.allData[index].Id;
      case 4:
        return "#/income/invoice/INV-"+this.allData[index].ReferenceNo;
    }
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  };
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }
  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  columnDefs = [
    {
      headerName: "Serial No.",
      field: "SerialNo",
      cellStyle: { color: "blue", cursor: "pointer" },
    },
    {
      headerName: "WH No.",
      field: "WHNO",
      cellStyle: { color: "blue", cursor: "pointer" },

      cellRenderer: function (params) {
        console.log("Param", params);
        return `<a href="#/inventory/receipt-waiting/${params.data.Id}" >${params.data.WHNO}</a>`;
      },
    },
    { headerName: "To", field: "Contacts.CompanyName" },
    {
      headerName: "Date",
      field: "OperationDate",
      valueFormatter: this.convertGridDateIntoDisplay,
    },
    {
      headerName: "Reference",
      field: "ReferenceNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      cellRenderer: function (params) {
        //The reference no has been modied in the allData array. in the pageLoad function.
        if(params.data.OperationType == 1)
          return `<a href="#/procurement/purchase-order-details/${0}/${params.data.ReferenceNo.split("-")[1]}" >${params.data.ReferenceNo}</a>`;
        else
          return `<a href="#/expense/bill/${params.data.ReferenceNo}" >${params.data.ReferenceNo}</a>`; 
      },
    },
    {
      headerName: "Status",
      field: "Status",
      valueFormatter: this.setOrderStatus,
    },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };

  convertGridDateIntoDisplay(date) {
    console.log("Date", date);
    const dateString = date.value
      ? moment(date.value).format("Do MMM YYYY")
      : "";
    return dateString;
  }
  setOrderStatus(id) {
    console.log("Id=>", id);
    switch (id.data.StatusId) {
      case 1:
        return "Waiting";
      case 2:
        return "Received";
      case 3:
        return "Ready";
      default:
        return "unknown";
    }
  }

  //   rowData = [
  //     { SerialNo: '1', WHNO: 'WH-In-20202', To: 'Raddish', Date:'2022-12-12',Reference:'SO-1110245',Status:'Waiting' },

  // ];
}
