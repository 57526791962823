import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ResponseModel } from 'src/app/models/responseModel.model';
import { WareHouseSaveModel } from 'src/app/models/wareHouseSave.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryOverviewService {

  baseurl = environment.baseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private http: HttpClient) { }
  createBillData:any=null;
  wareHouseAllData:any=null;

  httpAttachmentOptions: any = {
    reportProgress: true, observe: 'events', headers: new HttpHeaders({
      'Authorization': `Bearer ${this.userInfo ? this.userInfo.Token : null}`
    })
}

  GetDeliveryOrder(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'InventoryOverview/GetAllList').pipe(retry(1));
  }
  GetDeliveryOrderByPurchaseOrderNo(orderNo: any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'InventoryOverview/GetDeliveryOrderByPurchaseOrderNo?orderNo='+orderNo).pipe(retry(1));
  }
  GetDeliveryOrderBySalesOrderNo(orderNo: any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'InventoryOverview/GetDeliveryOrderBySalesOrderNo?orderNo='+orderNo).pipe(retry(1));
  }
  GetReceiptOrderByBillNo(billNo: any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'InventoryOverview/GetReceiptOrderByBillNo?billNo='+billNo).pipe(retry(1));
  }
  GetDeliveryOrderByInvoiceNo(invoiceNo: any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'InventoryOverview/GetDeliveryOrderByInvoiceNo?invoiceNo='+invoiceNo).pipe(retry(1));
  }
  GetWareHouseData(id:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'InventoryOverview/GetWareHouseDataById?id='+id).pipe(retry(1));
  }
  sendReceiveData(data:any):Observable<any>
  {
    console.log("I am post service",data)
    return this.http.post<any>(this.baseurl + 'InventoryOverview/WareHouseShippedData', data).pipe(retry(1));
  }

  ShipWareHouseData(data:any):Observable<any>
  {
    console.log("I am post service",data)
    return this.http.post<any>(this.baseurl + 'InventoryOverview/ShipWareHouseData', data).pipe(retry(1));
  }

  shipItems(data:any): Observable<any>
  {
    return this.http.post<any>(this.baseurl + 'InventoryOverview/ShipItems', data).pipe(retry(1));
  }

  RecalculateStatus(): Observable<any>
  {
    return this.http.get<any>(this.baseurl + 'InventoryOverview/RecalculateStatus').pipe(retry(1));
  }

  passDataToCreateBill(data:any)
  {
    this.createBillData=data;

  }
  getDataForCreateBill()
  {
    return this.createBillData;
  }

  setWareHouseData(data:any)
  {
    this.wareHouseAllData=data;

  }
  getWareHouseData()
  {
    return this.wareHouseAllData;
  }

  GetDeliveryOrderList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'InventoryOverview/GetDeliveryOrderList').pipe(retry(1));
  }

  SoldItemReturned(data: WareHouseSaveModel):Observable<ResponseModel>
  {
    return this.http.post<ResponseModel>(this.baseurl + 'InventoryOverview/SoldItemReturned', data);
  }

  GetWareHouseReturnList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'InventoryOverview/GetWareHouseReturnList').pipe(retry(1));
  }

  CancelItemReturned(data: any):Observable<ResponseModel>
  {
    return this.http.post<ResponseModel>(this.baseurl + 'InventoryOverview/CancelItemReturned', data);
  }

}
