<app-dashboard>
    <app-page-header-title mainTitle="Project Overview" subTitle="Back" backURL="project/overview">

    </app-page-header-title>
    <div class="row">
        <div class="w-100 col">
            <div class="project-title ml-3 mb-2"  style="color: #0067B5;">{{projectViewModel.Title}}</div>
        </div>
    </div>
    <div class="row project-summary-row">
       <div class="col-md-3 col-sm-6">
           <div>{{'Project.deadline' | translate}}</div>
           <div class="large-font mb-4">{{projectViewModel.DeadlineVw}}</div>
          <div class="ml-4"><ngb-progressbar type="success" [value]="projectViewModel.Percentage" height=".3rem"></ngb-progressbar></div> 
       </div>
       <div class="col-md-3 col-sm-6">
        <div>{{'Project.winPrice' | translate}}</div>
        <div class="large-font">{{projectViewModel.WinPrice}}</div>
        <div><span style="font-size: 14px;">{{'Project.unInvoiced' | translate}}</span> <span class="ml-2">{{projectViewModel.UnInvoicedAmount}}</span></div>
       </div>
       <div class="col-md-3 col-sm-6">
        <div>{{'Project.expenses' | translate}}</div>
        <div class="large-font">{{projectViewModel.TotalBillAmount}}</div>
        <div><span style="font-size: 14px;">{{'Project.estimate' | translate}}</span> <span class="ml-2">{{projectViewModel.EstimatedCost}}</span></div>
       </div>
       <div class="col-md-3 col-sm-6">
        <div>{{'Project.invoiced' | translate}}</div>
        <div class="large-font">{{projectViewModel.TotalInvoiceAmount}}</div>
       </div>
    </div>
    
    <div class="row create-panel">
        <div class="col-md-3 col-sm-3">
            <span class="tab-panel-name" [ngClass]="{'tab-panel-name-active': activeTabId===1}"
            (click)="setActivePanel(1)">{{'Project.expenses' | translate}}</span>
            <span class="tab-panel-name" [ngClass]="{'tab-panel-name-active': activeTabId===2}"
            (click)="setActivePanel(2)">{{'Project.invoices' | translate}}</span>
        </div>
        <div class="col-md-6 col-sm-6"></div>
        <div class="col-md-3 col-sm-3">
            <button type="button" class="eCount-def-btn float-right" (click)="gotoCreateBillOrInvoice()">
               <span *ngIf="activeTabId===1">{{'Project.createBill' | translate}}</span> 
               <span *ngIf="activeTabId===2">{{'Project.createInvoice' | translate}}</span> 
            </button>
        </div>
    </div>

    <div *ngIf="activeTabId===1" style="width: 95%;" [hidden]="billList.length==0">
        <p class="ml-4 pb-2" style="color: #0067B5;">Bill history of this project</p>
        <table class="table  f-ss-14px ml-4">
            <thead class="header">
                <tr class="tableHeader br">

                    <th scope="col" class="table-header-color borRight text-align-left">{{'Project.billNo' | translate}}
                    </th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.billDate' | translate}}
                    </th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.dueDate' | translate}}
                    </th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.billAmount' | translate}}</th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.paid' | translate}}</th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.due' | translate}}</th>
                    <th scope="col" class="table-header-color text-align-center">{{'Project.status' | translate}}</th>
                </tr>
            </thead>
            <tbody style="border-bottom: 1px solid #e8e8e8;">
                <tr *ngFor="let item of billList">
                    <td class="text-align-left text-link" (click)="EditBill(item.BillNo)">{{item.BillNo}}</td>
                    <td class="text-align-center">{{item.BillDate}}</td>
                    <td class="text-align-center">{{item.DueDate}}</td>
                    <td class="text-align-center">{{item.BillAmount}}</td>
                    <td class="text-align-center">{{item.PaidAmount}}</td>
                    <td class="text-align-center">{{item.DueAmount}}</td>
                    <td class="text-align-center">{{item.StatusName}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="activeTabId===2" style="width: 95%;">
        <p class="ml-4 pb-2" style="color: #0067B5;">Invoice history of this project</p>
        <table class="table  f-ss-14px ml-4">
            <thead class="header">
                <tr class="tableHeader br">

                    <th scope="col" class="table-header-color borRight text-align-left">{{'Project.invoiceNo' | translate}}
                    </th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.invoiceDate' | translate}}
                    </th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.dueDate' | translate}}
                    </th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.invoiceAmount' | translate}}</th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.paid' | translate}}</th>
                    <th scope="col" class="table-header-color borRight text-align-center">{{'Project.due' | translate}}</th>
                    <th scope="col" class="table-header-color text-align-center">{{'Project.status' | translate}}</th>
                </tr>
            </thead>
            <tbody style="border-bottom: 1px solid #e8e8e8;">
                <tr *ngFor="let item of invoiceList">
                    <td class="text-align-left text-link" (click)="EditInvoice(item.InvoicceNo)">{{item.InvoicceNo}}</td>
                    <td class="text-align-center">{{item.InvoiceDate}}</td>
                    <td class="text-align-center">{{item.DueDate}}</td>
                    <td class="text-align-center">{{item.InvoiceAmount}}</td>
                    <td class="text-align-center">{{item.PaidAmount}}</td>
                    <td class="text-align-center">{{item.DueAmount}}</td>
                    <td class="text-align-center">{{item.StatusName}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</app-dashboard>