import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DocumentLockDetails } from 'src/app/models/documentLockDetails.model';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-versioning-modal',
  templateUrl: './document-versioning-modal.component.html',
  styleUrls: ['./document-versioning-modal.component.css']
})

export class DocumentVersioningModalComponent implements OnInit {
  @Input() file;

  // versions: any[] = [
  //   {No: 1.0, Modified: '2/1/2024 8:25 AM', ModifiedBy: 'Labib', Size: '15.1 KB', Status: 'Previous'},
  //   {No: 1.1, Modified: '2/3/2024 10:25 AM', ModifiedBy: 'Tufayel', Size: '15.5 KB', Status: 'Previous'},
  //   {No: 1.2, Modified: '2/5/2024 12:25 PM', ModifiedBy: 'Labib', Size: '13.2 KB', Status: 'Previous'},
  //   {No: 1.3, Modified: '2/7/2024 2:25 PM', ModifiedBy: 'Tufayel', Size: '14.6 KB', Status: 'Current'},
  //   {No: 1.4, Modified: '2/9/2024 4:25 PM', ModifiedBy: 'Labib', Size: '11.1 KB', Status: 'Previous'},
  // ]

  versions: any;

  selected;
  rotationRightAmount;
  rotationLeftAmount;
  isPdf;
  src;
  imagezooming;
  zoom = 1;
  rotationAmount;
  selectedBlobName;
  fileUrl = environment.fileBaseUrl;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private pService: SettingsServiceService,
    private attachmentService: AttachmentServiceService,
    private ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit(): void {
    this.getAllTagsAttachmentVersion();
  }

  getAllTagsAttachmentVersion(){
    this.attachmentService.getAllTagsAttachmentVersion(this.file.Id).subscribe((x) =>{
      this.versions = x;
    });
  }

  viewVersion(item: any){
    this.selectedBlobName = item.TagsAttachmentVersions.BlobName;
    this.view(item);
  }

  restoreVersion(attachment: any){
    this.spinner.show();
    this.attachmentService.RestoreVersion(attachment).subscribe((x) => {
      if(x.Success){
        this.toaster.success(x?.Message);
        this.spinner.hide();
        this.activeModal.close();
        location.reload();
      }
      else{
        this.toaster.warning(x?.Message);
        this.spinner.hide();
      }
    }, (err) => {});
  }

  deleteVersion(attachment: any){
    this.spinner.show();
    this.attachmentService.DeleteVersion(attachment).subscribe((x) => {
      this.toaster.success("Version deleted Successfully.");
      this.spinner.hide();
      this.activeModal.close();
    }, (err) => {});
  }

  convertDateIntoDisplay(date) {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  

  setStatus(statusNo: number) {
    if(statusNo == 0)
      return 'No Active'
    else
      return 'Currently Selected'
  }


    hoveredIndex: number = -1;
    setHoveredIndex(index: number) {
        this.hoveredIndex = index;
    }

    resetHoveredIndex() {
        this.hoveredIndex = -1;
    }

    isHovered(index: number): boolean {
        return this.hoveredIndex === index;
    }

    view(item: any) {
      this.selected = item.TagsAttachmentVersions.TagsAttachment;
      this.rotationRightAmount = 0;
      this.rotationLeftAmount = 0;
      let ext = this.selected.FileName.split(".")[1];
      this.isPdf = ext == 'pdf' ? true : false;
      this.src = environment.fileBaseUrl + item.TagsAttachmentVersions.BlobName;
      this.imagezooming = 100;
      this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
      let img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }

    convertDateToDisplay = (date) => {
      let dateString = moment(date).format("Do MMM YYYY")
      return dateString;
    }

    // share(file: any) {
    //   this.selected = file;
    //   this.getAllUsers();
    //   let ext = file.FileName.split(".")[1];
    //   this.isPdf = ext == 'pdf' ? true : false;
    //   this.src = this.fileUrl + file.BlobName;
    // }
  
    // print(file: any, id: any, pdf: boolean){
    //   window.open(`${this.url}files/print/${file.Id}`, '_blank');
    // }
  
    download() {
      this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
    }
  

    zoomOut() {
      if (this.isPdf) {
        this.zoom -= .1;
      } else {
        this.imagezooming -= 10;
        let element = document.getElementById("image")
        element.style.width = this.imagezooming + "%";
      }
    }
  
    zoomIn() {
      if (this.isPdf) {
        this.zoom += .1;
      } else {
        this.imagezooming += 10;
        let element = document.getElementById("image");
        element.style.width = this.imagezooming + "%";
      }
    }
  
    rotate(direction: string) {
      this.rotationAmount = +90;
      if (direction == 'left') {
        this.rotationRightAmount -= 90;
        this.rotationLeftAmount += 90;
        var img = document.getElementById('image');
        img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
      } else {
        this.rotationRightAmount += 90;
        this.rotationLeftAmount -= 90;
        var img = document.getElementById('image');
        img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
      }
    }

}


