import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SalesOrderService {
  baseurl = environment.baseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private http: HttpClient) {}

  httpAttachmentOptions: any = {
    reportProgress: true,
    observe: "events",
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.userInfo ? this.userInfo.Token : null}`,
    }),
  };

  CreateSalesOrder(quotationId: number): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "SalesOrder/Create?quotationId=" + quotationId)
      .pipe(retry(1));
  }

  GetSalesOrderList(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "SalesOrder/GetAll")
      .pipe(retry(1));
  }

  DeleteSalesOrder(orderId): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/DeleteSalesOrder?id=${orderId}`)
      .pipe(retry(1));
  }
  GetShipmentDetails(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/GetShipmentDetails?id=${data}`)
      .pipe(retry(1));
  }
  GetSalesOrderByItemId(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/GetSalesOrderByItemId?id=${data}`)
      .pipe(retry(1));
  }
  GetOrderDetails(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/GetOrderDetails?id=${data}`)
      .pipe(retry(1));
  }
  GetOrderInvoDetails(data): Observable<any> {
    return this.http
      .get<any>(
        this.baseurl + `SalesOrder/GetOrderDetailsForInvoice?id=${data}`
      )
      .pipe(retry(1));
  }
  GetOrderDetailsByRef(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/GetOrderDetailsByRef?id=${data}`)
      .pipe(retry(1));
  }
  SalesOrderManufacture(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/SalesOrderManufacture?id=${data}`)
      .pipe(retry(1));
  }
  GetSalesItemExpense(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/GetSalesItemExpense?id=${data}`)
      .pipe(retry(1));
  }
  GetShipment(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/GetShipment?id=${data}`)
      .pipe(retry(1));
  }
  GetInitShipment(data): Observable<any> {
    return this.http
      .get<any>(this.baseurl + `SalesOrder/GetInitShipment?id=${data}`)
      .pipe(retry(1));
  }
  GetItems(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "item/GetRawAndFinishedItems")
      .pipe(retry(1));
  }
  Approve(data): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "SalesOrder/Approve", data)
      .pipe(retry(1));
  }
  ApproveSalesOrderFromQuotation(data): Observable<any> {
    return this.http
      .post<any>(
        this.baseurl + "SalesOrder/ApproveSalesOrderFromQuotation",
        data
      )
      .pipe(retry(1));
  }

  ApproveSalesOrderFromSalesOrder(data, whStatusId): Observable<any> {
    return this.http
      .post<any>(
        this.baseurl +
          `SalesOrder/ApproveSalesOrderFromSalesOrder/?whStatusId=${whStatusId}`,
        data
      )
      .pipe(retry(1));
  }

  Arrived(data): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "SalesOrder/Arrived", data)
      .pipe(retry(1));
  }
  Cancelled(data): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "SalesOrder/Cancelled", data)
      .pipe(retry(1));
  }
  OnHold(data): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "SalesOrder/OnHold", data)
      .pipe(retry(1));
  }
  OnGoing(data): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "SalesOrder/OnGoing", data)
      .pipe(retry(1));
  }
  Close(data): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "SalesOrder/Close", data)
      .pipe(retry(1));
  }
  GetSupplierList(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "Contact/GetAllCustomer")
      .pipe(retry(1));
  }
  SaveSalesOrder(data): Observable<any> {
    console.log(data);
    return this.http
      .post<any>(this.baseurl + "SalesOrder/SaveSalesOrder", data)
      .pipe(retry(1));
  }
  SaveShipment(data): Observable<any> {
    console.log(data);
    return this.http
      .post<any>(this.baseurl + "SalesOrder/SaveShipment", data)
      .pipe(retry(1));
  }
  public SendMailWithAttachment(data: any): Observable<any> {
    return this.http
      .post(
        this.baseurl + "SalesOrder/SendMailWithAttachment",
        data,
        this.httpAttachmentOptions
      )
      .pipe(retry(1));
  }
  public SendMail(data: any): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "SalesOrder/SendMail", data)
      .pipe(retry(1));
  }

  public GetMailDetails(id: any): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "SalesOrder/GetMailDetails?id=" + id)
      .pipe(retry(1));
  }
  GetAdvanceBySalesId(data): Observable<any> {
    return this.http
      .get<any>(
        this.baseurl + `AdvanceTransaction/GetAdvanceListBySalesId?id=${data}`
      )
      .pipe(retry(1));
  }
  getCustomerBalance(data): Observable<any> {
    return this.http
      .post<any>(this.baseurl + "SalesOrder/GetCustomerBalance", data)
      .pipe(retry(1));
  }
  GenerateSalesNo(): Observable<any> {
    return this.http
      .get<any>(this.baseurl + "SalesOrder/GenerateSalesNo")
      .pipe(retry(1));
  }

  MarkAsComplete(data): Observable<any> {
    return this.http.post<any>(this.baseurl + `SalesOrder/MarkAsComplete`, data).pipe(retry(1));
  }

  GetSalesOrderWithWareHouseItemsByItemId(productId): Observable<any> {
    return this.http.get<any>(this.baseurl + `SalesOrder/GetSalesOrderWithWareHouseItemsByItemId?productId=${productId}`).pipe(retry(1));
  }

  GetSalesOrderBySalesOrderNo(orderNo): Observable<any> {
    return this.http.get<any>(this.baseurl + `SalesOrder/GetSalesOrderBySalesOrderNo?orderNo=${orderNo}`).pipe(retry(1));
  }

  Save(data): Observable<any> {
    return this.http.post<any>(this.baseurl + "SalesOrder/Save", data);
  }

  UpdateStatus(data): Observable<any> {
    return this.http.post<any>(this.baseurl + "SalesOrder/UpdateStatus", data)
  }

  Update(data): Observable<any> {
    return this.http.post<any>(this.baseurl + "SalesOrder/Update", data)
  }
}
