<app-dashboard>
    <app-page-header-title
        mainTitle="{{'Owner.editOwner'| translate}}"
        subTitle="{{'Owner.allBusinessOwner'| translate}}"
        backURL="owner/business-owner-list">
        <div class="full-width text-align-right">
        <button type="button" (click)="SaveOwnerInfo()" class="eCount-def-btn mr-4">Save</button>
</div>
    </app-page-header-title>
    <div class="pt-4 row">
        <form [formGroup]="ownerForm" class="d-flex w-100">
            <div class="col-6 f-ss-14px">
                
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.name'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="Name" placeholder="Name"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.phone'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="Phone" placeholder="Phone"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.email'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="Email" placeholder="Email"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.role'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <select required class="form-control" style="width: 100%;" formControlName="RoleId">
                            <option value="1" >Admin</option>
                            <option value="2" >Accountant</option>
                            <option value="3" >Auditor</option>
                        </select>
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.capital'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="ShareCapital"  class="form-control inputSection" readonly />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.isActive'| translate }}</span>
                    </div>
                    <div class="col-8">
                        <label class="switch">
                            <input name="IsCustomer" formControlName="IsActive" class="switch-input"
                                type="checkbox" />
                            <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-6 f-ss-14px">
                
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.address'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <textarea type="text"   formControlName="Address" style="height: 145px;" rows="3" placeholder="Address"  class="form-control inputSection"></textarea>
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.ownerShip'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <div>
                            <input type="text"  formControlName="OwnerShipPercentage" placeholder="%" appDecimaNumericValidator (change)="validateOwnerShip()"  class="form-control inputSection" />
                        </div>
                    </div>
                </div>                
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.loan'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <div>
                            <input type="text"  formControlName="GrantLoan"   class="form-control inputSection"  readonly/>
                        </div>
                    </div>
                </div>    
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Owner.CreateOwner.receiveLoan'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <div>
                            <input type="text"  formControlName="ReceiveLoan"   class="form-control inputSection" readonly/>
                        </div>
                    </div>
                </div>   
            </div>
        </form>
    </div>
    <div class="contianer mt-4">
        <div  class="row nav nav-tabs" id="myTab" role="tablist">
            <div class="col-md-2 nav-item nav-link active" id="shipment-tab" data-toggle="tab" (click)="openShareTab(true)" role="tab" aria-controls="shipment" aria-selected="true">
              <a class="" >Share Capital</a>
            </div>
            <div class="col-md-2 nav-item nav-link" id="expense-tab" data-toggle="tab" (click)="openLoanTab(true)" role="tab" aria-controls="expense" aria-selected="false">
              <a class="">Loan</a>
            </div>
       
        <div class="col-md-8 text-align-right" *ngIf= "isShareActive">
            <button class="eCount-def-btn mb-2" (click)="createNewItem()">Add Share Capital</button>
        </div>
        <div class="col-md-8 text-align-right" *ngIf= "isLoanActive">
            <button class="eCount-def-btn mb-2" (click)="createNewItems()">Add Loan</button>
        </div>
    </div> 
</div> 
<div class="headSection" *ngIf = "isShareActive">
    <div class="row">
        <ag-grid-angular
            style="font-size: 14px;"
            class="ag-theme-balham col"
            [rowData]="shareCapitalList"
            [columnDefs]="shareGridOptions.columnDefs"
            [domLayout] = "shareGridOptions.domLayout"
            (gridReady)="onGridReady($event)"
            [defaultColDef]="shareGridOptions.defaultColDef"
        ></ag-grid-angular>
    </div>
</div>
<div class="headSection" *ngIf = "isLoanActive">
    <div class="row">
        <ag-grid-angular
            style="font-size: 14px;"
            class="ag-theme-balham col"
            [rowData]="ownerLoanList"
            (rowClicked)="onRowClicked($event)"
            [columnDefs]="loanGridOptions.columnDefs1"
            [domLayout] = "loanGridOptions.domLayout"
            (gridReady)="onGridReady2($event)"
            [defaultColDef]="loanGridOptions.defaultColDef"
        ></ag-grid-angular>
    </div>
</div>
</app-dashboard>

