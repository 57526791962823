<app-dashboard>
    <app-page-header-title mainTitle="Edit Manufacture Order" subTitle="Manufacture Order List" backURL="/manufacture/manufacutring-order-list/All">
        <div class="full-width text-align-right">
            <button class="eCount-def-btn ml-4" type="button" (click)="saveManufactureOrder()">Save</button>
            <button class="eCount-def-btn ml-4" type="button" (click)="approveManufactureOrder()" style="background: #5cac00;">Approve</button>
        </div>
    </app-page-header-title>

    <div class="headSection">
        <form [formGroup]="ManufactureOrderForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-2">
                    <label for="">Product Name<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="FinishedProductId" readonly>
                </div>
                <div class="col-md-2">
                    <label for="">Formula Name<span class="clr">*</span></label>
                    <ng-select [disabled]="selectedProductFormulaList.length == 0" [items]="selectedProductFormulaList" [clearable]="false" bindLabel="FormulaName" bindValue="Id" formControlName="FormulaId" (change)="updateFormulaItems()" required></ng-select>
                </div>
                <div class="col-md-2">
                    <label for="">Quantity To Produce<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="Quantity" (change)="updateItemsConsumeData()" appDecimaNumericValidator>
                </div>
                <div class="col-md-2">
                    <label for="">Date<span class="clr">*</span></label>
                    <input class="form-control" id="createdDateId" type="date" formControlName="CreatedDate" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)">
                </div>
                <div class="col-md-2">
                    <label for="">Reference</label>
                    <input class="form-control" type="text" formControlName="Reference">
                </div>
                <div class="col-md-2">
                    <label for="">Batch No<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="BatchNo">
                </div>
                <div class="col-md-2 mt-3">
                    <label for="startDateId">Start Date<span class="clr">*</span></label>
                    <input class="form-control" id="startDateId" type="date" formControlName="StartDate" data-date="" data-date-format="D MMM YYYY" (change)="showStartSelectedDate($event)">
                </div>
                <div class="col-md-2 mt-3">
                    <label for="endDateId">End Date<span class="clr">*</span></label>
                    <input class="form-control" id="endDateId" type="date" formControlName="EndDate" data-date="" data-date-format="D MMM YYYY" (change)="showEndSelectedDate($event)">
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px">
                <div class="d-flex">
                    <div class="col-md-3">
                        Item
                    </div>
                    <div class="col-md-3">
                        Recipe Quantity
                    </div>
                    <div class="col-md-3">
                        Balance
                    </div>
                    <div class="col-md-3">
                        To Consume
                    </div>
                </div>
                <hr class="pb-0 mb-0">
                <div>
                    <div formArrayName="OrderItems" *ngFor="let item of ManufactureOrderForm.get('OrderItems')['controls']; let i = index;">
                        <div class="row f-ss-14px" [formGroupName]="i">
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control inputField" type="text" formControlName="RawMaterialId" disabled>
                            </div>
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control inputField" type="text" formControlName="RecipeQuantity" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control inputField" type="text" formControlName="Balance" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-1  d-flex">
                                <input class="form-control inputField" type="text" formControlName="ToConsume" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2">
                                <div class="d-flex align-items-center">
                                    <span id="dangerBtn" class="deletebtn" *ngIf="canShowOrderItemsStockIcon(item, i) && item.value.ToConsume != 0">
                                        <img style="height: 33px;" src="../../assets/icons/successImg.jpg" />
                                    </span>
                                    <span id="dangerBtn" class="deletebtn" *ngIf="!canShowOrderItemsStockIcon(item, i) && item.value.ToConsume != 0">
                                        <img style="height: 33px;" src="../../assets/icons/danger2.jpg" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr class="pb-0 mb-0 pt-0 mt-0">
                    </div>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px">
                <div class="font-weight-bold mb-1">
                    Miscellaneous Item
                </div>
                <div class="d-flex">
                    <div class="col-md-3">
                        Item
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        To Consume
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <hr class="pb-0 mb-0">
                <div>
                    <div formArrayName="MiscellaneousItems" *ngFor="let item of ManufactureOrderForm.get('MiscellaneousItems')['controls']; let j = index;">
                        <div class="row f-ss-14px" [formGroupName]="j">
                            <div class="col-md-3" style="padding-top: 3px;">
                                <ng-select [items]="rawMaterialList" [clearable]="false" placeholder="Select Item" bindLabel="ItemName" bindValue="Id" formControlName="MiscellaneousRawMaterialId" (change)="changeMiscellaneousItem(item, j)" required></ng-select>
                            </div>
                            <div class="col-md-3"></div>
                            <div class="col-md-1" style="padding-top: 3px;">
                                <input class="form-control" type="text" appDecimaNumericValidator formControlName="ToConsume" (change)="changeMiscellaneousItem(item, j)">
                            </div>
                            <div class="col-md-1">
                                <div class="d-flex align-items-center">
                                    <span id="dangerBtn" class="deletebtn" *ngIf="item.value.MiscellaneousItemStockAvailabeSign && item.value.ToConsume != 0">
                                        <img style="height: 33px;" src="../../assets/icons/successImg.jpg" />
                                    </span>
                                    <span id="dangerBtn" class="deletebtn" *ngIf="!item.value.MiscellaneousItemStockAvailabeSign && item.value.ToConsume != 0">
                                        <img style="height: 33px;" src="../../assets/icons/danger2.jpg" />
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-2">
                                <div class="dropdown mr-4" style="padding-top: 13px;" style="padding: 7px 10px; color: #c7c2c2;">
                                    <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                        <i class="icon-more float-right"></i>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%;">
                                        <div class="productRow pb-3" (click)="removeMiscellaneousItems(j)">
                                            <span class="lead1">Remove Item</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row addItem">
                    <div class="BottomAddItem w-100">
                        <div class="dropdown mr-4">
                            <a class="nav-link" style="background-color:white;" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" (click)="addMiscellaneousItem()">
                                <img class="languageImg" src="../../../assets/image/addIcon.png" alt="Add Item"> Add New Line
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>