import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup,FormArray, Validators, FormControl } from '@angular/forms';
import { PurchaseOrderService } from '../../../service/PurchaseOrder/purchase-order.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ItemCreateComponent } from '../../Products/item-create/item-create.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateItem } from 'src/app/models/product.model';
import { CreatePopupItemComponent } from '../../Products/create-popup-item/create-popup-item.component';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';

@Component({
  selector: 'app-purchase-order-create',
  templateUrl: './purchase-order-create.component.html',
  styleUrls: ['./purchase-order-create.component.css'],
  host: {
    '(document:click)': 'onWindowClick($event)',
  },
})
export class PurchaseOrderCreateComponent implements OnInit {

  subTotalAmount:number=0;
  AdvanceTaxAmount: number = 0.00;
  TaxAmount: number = 0.00;
  totalPrice: number = 0.00;
  totalFile: number=0;
  costAccountId: number=0;
  public purchaseOrderForm: FormGroup;
  public PurchaseOrderItems: FormArray;
  productList=[];
  taxTypeList=[];
  supplierList=[];
  accountList=[];
  taxRateList=[];
  ContactId: number;
  isVatAitDisabled:boolean = true;
  contactIdRequired:boolean = true;
  unitPriceRequired:boolean = true;
  deliveryDateRequired:boolean = true;
  descriptionRequired:boolean = true;
  qtyRequired:boolean = true;
  loading = false;
  OrderNo: number;
  public aModel : CreateItem= new CreateItem();
  ShowOrderNo: string;

  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private settingService:SettingsServiceService,
    private mainService: MainServiceService,
    private accountingService: AcountingServiceService,
    private pService: PurchaseOrderService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getMasterDataList();
    this.forMatDate();
  }
  public setTotalFile(tf:number) {
    this.totalFile=tf;
  }
  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id =="Preview")) {

    }
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown()
    }
    else {
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }
  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }
  getMasterDataList = () => {
    this.generatePoNo();
    this.getAllSupplier();
    this.getTaxTypeList();
    this.getTaxRateList();
    this.getPurchaseableItems();
    this.accountingService.GetChartOfAccountsforPo().subscribe((x) => {
      this.accountList =x.ExpenseList;
      this.costAccountId = this.accountList.filter(x=>x.AccountName == "429 - General Expenses")[0].Id;
      const myForm = (<FormArray>this.purchaseOrderForm.get("PurchaseOrderItems")).at(0);
        myForm.patchValue({
          AccountId:this.costAccountId
        });
    });
  }
  generatePoNo = () => {
    this.spinner.show();
    this.pService.GeneratePurchaseNo().subscribe((x: any) => {
      let val = x.toString(); let pre = "", post = "";
      for(let i=0;i<6;i++) pre+=val[i].toString();
      for(let i=6;i<10;i++) post+=val[i].toString();
      console.log(pre, 'ok ', post);
      this.purchaseOrderForm.get('OrderNo').patchValue(post);
      this.OrderNo = Number(post);
      this.ShowOrderNo = pre;
      this.spinner.hide();
    });
  }
  private getPurchaseableItems = () => {
    this.pService.GetPurchaseableItems().subscribe((x) => {
      this.productList = x;
    });
  }
  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
      if (this.taxTypeList.length >= 2){
        this.purchaseOrderForm.controls['TaxTypeId'].patchValue(this.taxTypeList[2].Id);
      }
    });
  }
 private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({"Id":"","Name":"Select"});
    });
  }


  initializeForm() {
    this.purchaseOrderForm = this.fb.group({
      Id:[0],
      ContactId:['',Validators.required],
      OrderNo:[''],
      OrderDate: ['',Validators.required],
      DeliveryDate: ['',Validators.required],
      ReferenceNo: [''],
      TaxTypeId: [''],
      TaxAmount: [''],
      AdvanceTaxAmount: [''],
      OrderAmount:[''],
      DeliveryContactNo: [''],
      DeliveryAttentions: [''],
      DeliveryAddress: [''],
      DeliveryInstructions: [''],
      Notes: [''],
      PurchaseOrderItems: this.fb.array([ this.createItem() ])
    });
  }
 public createItem(): FormGroup {
    return this.fb.group({
      Id: 0,
      ItemId: [''],
      Description:['',Validators.required],
      AccountId:[this.costAccountId],
      Quantity: ['',Validators.required],
      UnitPrice: ['',Validators.required],
      TaxRateId: [null],
      AdvanceTaxRateId: [null],
      LineTotal: [0]

    });
  }

  public addItem(): void {
    this.PurchaseOrderItems = this.purchaseOrderForm.get('PurchaseOrderItems') as FormArray;
    this.PurchaseOrderItems.push(this.createItem());
  }

  public deleteItem(i:number){
    this.PurchaseOrderItems.removeAt(i);
    this.updateTotalPrice();
  }

  /**
   * updatePrice
   */

   public updatePrice(i:number,item:any){
    this.purchaseOrderForm.value.PurchaseOrderItems.map((x) => {
      if (x.ItemId == item.value.ItemId)
      {
        item.controls['LineTotal'].patchValue((item.value.Quantity * item.value.UnitPrice).toFixed(2));
      }
    })
     this.updateTotalPrice()
  }

  updateTotalPrice = () => {
    this.subTotalAmount = 0.00;
    this.TaxAmount=0.00;
    this.AdvanceTaxAmount=0.00;
    this.isVatAitDisabled = this.purchaseOrderForm.value.TaxTypeId==3?true:false;
    this.purchaseOrderForm.value.PurchaseOrderItems.map((x) => {
      this.subTotalAmount = this.subTotalAmount + this.AdvanceTaxAmount + Number(x.LineTotal);
      this.TaxAmount+=this.getItemTaxAmount(x.LineTotal,x.TaxRateId);
      this.AdvanceTaxAmount+=this.getItemTaxAmount(x.LineTotal,x.AdvanceTaxRateId);
    });
    switch(this.purchaseOrderForm.value.TaxTypeId){
      case 1: //tax exclusive
        this.totalPrice = this.subTotalAmount+this.TaxAmount+this.AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        this.totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
       this.totalPrice = this.subTotalAmount;
        break;
    }
    if (this.purchaseOrderForm.value.TaxTypeId === 3)
    {
      this.checkTaxType();
    }

  }

  checkTaxType = () => {

    this.PurchaseOrderItems = this.purchaseOrderForm.get('PurchaseOrderItems') as FormArray;
    this.PurchaseOrderItems.value.map((x, i) => {

      const myForm = (<FormArray>this.purchaseOrderForm.get("PurchaseOrderItems")).at(i);
      myForm.patchValue({
        TaxRateId: null,
        AdvanceTaxRateId: null
      });
    });
  }
  private getItemTaxAmount(lineTotal:number,taxRateId:any) {
    let taxPrice=0.00;
    if(taxRateId=="" || taxRateId==null)
     return taxPrice;

     var taxPercent=this.getTaxRatePercent(taxRateId);
     switch(this.purchaseOrderForm.value.TaxTypeId){
      case 1: //tax exclusive
      taxPrice= lineTotal*taxPercent/100;
        break;
      case 2: //tax inclusive
      taxPrice= Number(((lineTotal*taxPercent)/(100+taxPercent)).toFixed(2));
        break;
      default: //no tax
      taxPrice=0
        break;
    }
    return taxPrice;
  }
  private getTaxRatePercent(taxRateId:any) {
    let taxRateObj = this.taxRateList.filter(x=>x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }



  async checkOrderNo(){
    var val = this.purchaseOrderForm.value.OrderNo.toString();
    if(val=='' || val==null){
      this.toaster.error('Please Input Bill No.!');
      return false;
    }

    if(val.length!=4){
      this.toaster.error('Purchase Order Number needs to be 4 digits.');
      return false;
    }

    return true;
  }

  /**
   * saveAsDraft
   */
  public async saveAsAwaitingApproval() {
    if(!await this.checkOrderNo()) return;
    if (this.purchaseOrderForm.invalid){
      this.contactIdRequired = false;
      this.deliveryDateRequired = false;
      this.descriptionRequired = false;
      this.qtyRequired = false;
      this.unitPriceRequired = false;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    else if (this.purchaseOrderForm.value.PurchaseOrderItems.length <= 0){
      this.toaster.warning('Please add at least one item to the purchase order.', 'No Item Added!');
      return;
    }
    this.purchaseOrderForm.controls['TaxAmount'].patchValue(this.TaxAmount);
    this.purchaseOrderForm.controls['AdvanceTaxAmount'].patchValue(this.AdvanceTaxAmount);
    this.purchaseOrderForm.controls['OrderAmount'].patchValue(this.totalPrice);
    let temp = this.purchaseOrderForm.value.OrderNo;
    this.purchaseOrderForm.value.OrderNo = this.ShowOrderNo.toString() + this.purchaseOrderForm.value.OrderNo.toString();
    this.spinner.show();
    const data = this.purchaseOrderForm.value;
    data.IQuidiActionType = iQuidiActionType.Save;
    this.pService.Create(data).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Saved successfully.');
        this.router.navigate(['procurement/purchase-order-list/All']);
      } else {
        this.toaster.error(x.Message);
        this.purchaseOrderForm.controls['OrderNo'].setValue(temp);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  /**
   * saveAsApproved
   */
  public async saveAsApproved() {
    if(!await this.checkOrderNo()) return;
    if (this.purchaseOrderForm.invalid){
      this.contactIdRequired = false;
      this.deliveryDateRequired = false;
      this.descriptionRequired = false;
      this.qtyRequired = false;
      this.unitPriceRequired = false;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    else if (this.purchaseOrderForm.value.PurchaseOrderItems.length <= 0){
      this.toaster.warning('Please add at least one item to the purchase order.', 'No Item Added!');
      return;
    }
    this.purchaseOrderForm.controls['TaxAmount'].patchValue(this.TaxAmount);
    this.purchaseOrderForm.controls['AdvanceTaxAmount'].patchValue(this.AdvanceTaxAmount);
    this.purchaseOrderForm.controls['OrderAmount'].patchValue(this.totalPrice);
    let temp = this.purchaseOrderForm.value.OrderNo;
    this.purchaseOrderForm.value.OrderNo = this.ShowOrderNo.toString() + this.purchaseOrderForm.value.OrderNo.toString();
    this.spinner.show();
    const data = this.purchaseOrderForm.value;
    data.IQuidiActionType = iQuidiActionType.Approve;
    this.pService.Create(data).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Approved successfully.');
        this.router.navigate(['procurement/purchase-order-list/Approved']);
      } else {
        this.toaster.error(x.Message);
        this.purchaseOrderForm.controls['OrderNo'].setValue(temp);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : false,
      IsSupplier : true,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {

        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.supplierList.push(x["Data"]);
        let customerObj = this.supplierList.filter(x=>x.PrimaryPersonFirstName == customerName);

        this.purchaseOrderForm.controls['ContactId'].patchValue(customerObj[0]['Id']);
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }
  getAllSupplier = () => {
    this.pService.GetSupplierList().subscribe((x) => {
      this.supplierList=x;
    });
  }
  show(e) {
    const value = this.purchaseOrderForm.value.OrderDate;
    $('#itest1').attr('min', moment.isDate(value) ? value.toISOString().split('T')[0] : value );
    $("#itest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  showSelectedDate(e) {
    const value = this.purchaseOrderForm.value.DeliveryDate;
    $("#itest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
  forMatDate() {
    var date = new Date();
    this.purchaseOrderForm.controls['OrderDate'].patchValue(date);
    this.show(date);
  }


  changespurchaseableItems = (index) => {

    const myForm = (<FormArray>this.purchaseOrderForm.get("PurchaseOrderItems")).at(index);

   for (let i = 0; i < this.productList.length; i++) {
     if (this.productList[i].Id == this.purchaseOrderForm.value.PurchaseOrderItems[index]['ItemId']) {
           myForm.patchValue({
             ItemId:this.productList[i]['Id'],
             Description:this.productList[i]['PurchaseDescription'],
             AccountId:this.productList[i]["IsTrackItem"] == true ? this.productList[i]['InventoryAccountId'] : this.productList[i]['PurchaseAccountId'],
             Quantity:1,
             UnitPrice:this.productList[i]['PurchasePrice'],
             LineTotal:this.productList[i]['PurchasePrice']
           })
     }
   }
   this.updateTotalPrice();
 }
 openItemModal(selectItem: NgSelectComponent){
   selectItem.close();
   const modalRef = this.modalService.open(CreatePopupItemComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
   modalRef.componentInstance.refId = 1;
   modalRef.result.then((result) => {
     this.getPurchaseableItems();
   }, (reason) => {
     console.log('Dismissed action: ' + reason);
   });
   }

}
