<app-dashboard>
    <app-page-header-title
    mainTitle="User Profile"
    subTitle="Home"
    backURL="/">
    <div class="full-width text-align-right">
        <button type="button" (click)="UpdateUserDetails()" class="eCount-def-btn ml-4">Save</button>
    </div>

  </app-page-header-title>
  <div class="col-sm-12">
    <form [formGroup]="userForm" class="d-flex">
    <div class="col-sm-6 row">

          <div class="col-sm-12">
            <div class="form-group col-sm-12">
              <label>Name<span class="clr">*</span> </label>
              <input type="text" class="form-control"  placeholder="Your Name" formControlName="Name">
            </div>
            <div class="form-group col-sm-12">
                <label>Contact No<span class="clr">*</span> </label>
                <input type="text" class="form-control" placeholder="Contact No" maxlength="11" (keypress)="numberOnly($event)" formControlName="PhoneNumber">
            </div>
            <div class="form-group col-sm-12">
                <label>Email <span class="clr">*</span> </label>
                <input type="text" class="form-control" placeholder="Email" formControlName="Email">
            </div>
            <div class="form-group col-sm-12">
                <label for="Address" >Address</label>
                <textarea class="form-control form-input" style="height: 90px;" formControlName="Address"></textarea>
            </div>

        </div>
    </div>
    <div class="col-sm-6">
        <div class="col-sm-12 form-group">
            <div class="form-group row col-sm-12">
                <label>Job Title</label>
                <input type="text" class="form-control" placeholder="Job Title" formControlName="JobTitle">
            </div>
        </div>

        <div class="form-group col-sm-11">
            <label for="Address" >Signature</label>
            <div>
              <form [formGroup]="myForm">
                <div class="form-group">
                  <div class="das mla row">
                      <div class="col-md-2 ml-0 pl-0">
                        <label for="file" class="file"></label>
                        <input formControlName="file" accept="image/*" id="file" type="file" class="form-control"
                        (change)="onFileChange($event)" class="hidden" style="outline: none;margin-left: 20px;">
                      </div>
                      <div class="col-md-9"></div>
                      <div class="col-md-1 mt-4 ml-0 pl-0"  *ngIf="imageURL && imageURL !== ''">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16" (click)="deleteSignature()">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                      </div>
                      <div class="imagePreview col-md-5" *ngIf="imageURL && imageURL !== ''">
                        <img [src]="imageURL" [alt]="myForm.value.name" style="height: 200px; width: 400px;">
                      </div>
                      <div class="col-md-1"></div>
                  </div>
                </div>
              </form>
            </div>
        </div>
    </div>
</form>
</div>




</app-dashboard>

