import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../../service/Project/project.service';
import { ProjectModel } from '../../../models/project.model';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {

  public projectId: number=0;
  public projectViewModel : ProjectModel= new ProjectModel();
  public billList=[];
  public invoiceList=[];
  public activeTabId: number =1;
  constructor(private pService:ProjectService,
    private toaster:ToastrService, 
    private spinner: NgxSpinnerService,
    private router:Router,
    private route: ActivatedRoute) {

     }

  ngOnInit(): void {
    this.projectId =Number(this.route.snapshot.paramMap.get("projectId"));
    this.getProjectDetails();
  }

  public setActivePanel=(v)=>{
    this.activeTabId=v;
  }

 private getProjectDetails = () => {
    this.spinner.show()
    this.pService.GetDetails(this.projectId).subscribe((x) => {
      this.projectViewModel = x.Details;
      console.log(this.projectViewModel);
      this.billList = x.BillList;
      this.invoiceList = x.InvoiceList;
      console.log(x);
      this.spinner.hide()
    },(err)=>{
      this.toaster.error("There is an error,please check");
      this.spinner.hide();
    })
  };

  public gotoCreateBillOrInvoice=()=>{
   this.activeTabId==1? this.router.navigate(['expense/create-new-bill'], { queryParams: { PostingModuleId:2,ExternalTransactionId:this.projectId } })
   :this.router.navigate(['income/create-invoice'], { queryParams: { PostingModuleId:2,ExternalTransactionId:this.projectId } });
  }

  /**
   * EditBill
id   */
  public EditBill(billNo:string) {
    this.router.navigate(['expense/bill/'+billNo],{queryParams:{ IsRedirect:true }});
  }
  /**
   * EditInvoice
id:number   */
  public EditInvoice(invoiceNo:string) {
    this.router.navigate(['income/invoice/'+invoiceNo],{queryParams:{ IsRedirect:true }});
  }

}
