import { Component, OnInit } from '@angular/core';
import { PurchaseOrderService } from '../../../service/PurchaseOrder/purchase-order.service';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/EventEmitter/data.service';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.css']
})

export class PurchaseOrderListComponent implements OnInit {
  allData: any = [];
  searchText:string;
  gridApi;
  status: any;
  filteredBills:any=[];
  draftCount:number=0;
  billedCount:number=0;
  allCount:number=0;
  approvedCount:number=0;
  orderStatus: any = {
    "All": 0,
    "Draft": 1,
    "Awaitingapproval": 2,
    "Approved": 3,
    "Billed": 4
  }
  constructor(
    private purchaseService: PurchaseOrderService,
    private dataService:DataService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router:ActivatedRoute) { }

  ngOnInit(): void {
    this.status = this.router.snapshot.paramMap.get('status');
    this.dataService.changeSelectedOrganization.subscribe(x => {
      this.getAllDataList();
    });
    this.getAllDataList();
  }
  domLayout= 'autoHeight'
  rowHeight= 275
  columnDefs = [
    { headerName: 'Order No', field: 'OrderNo',
      cellRenderer: function(params) {
        console.log("Param",params)

        return `<a href="#/procurement/purchase-order-details/${params.data.Id}" >${params.value}</a>`;
      }
    },
    { headerName: 'Ref', field: 'ReferenceNo' },
    { headerName: 'To', field: 'Contacts.CompanyName' },
    { headerName: 'Order Date', field: 'OrderDate', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Delivery Date', field: 'DeliveryDate', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Modified Date', field: 'UpdatedAt', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Order Amount', field: 'OrderAmount', valueFormatter: this.convertPaidAmount },
    { headerName: 'Status', field: 'StatusId', valueFormatter: this.setOrderStatus }
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };

  getAllDataList=()=>{

    this.spinner.show()
    this.purchaseService.GetOrderList().subscribe((x)=>{
      console.log("All Data Check===>",x)
      this.allData=x;
      console.log(this.allData)
      for(let i=0;i<this.allData.length;i++){
        this.allData[i].OrderNo = 'PO-' + this.allData[i].OrderNo;
      }
      this.allCount = x.length;
      this.allData.forEach(x => {
        x.StatusId == this.orderStatus.Awaitingapproval ? this.draftCount += 1 : x.StatusId == this.orderStatus.Approved ? this.approvedCount += 1 : this.billedCount+=1 ;
      });
      this.filteredBills = this.orderStatus[this.status] == this.orderStatus.All ? x : x.filter(y=>y.StatusId == this.orderStatus[this.status]);
      this.spinner.hide()
    }, err=>{
      this.toaster.error("There is an error,please check");
      this.spinner.hide()
    })
    console.log("Hello filter Bill",this.filteredBills)
  }
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }
  getParams() {
    return {
    suppressQuotes: '',
    columnSeparator: '',
    customHeader: '',
    customFooter: '',};
  }
  convertPaidAmount(value){
    let valueData = Number(value.data.OrderAmount.toFixed(2))
    return valueData.toLocaleString();
  }
  setOrderStatus(id){
    switch (id.data.StatusId) {
      case 1:
        return 'All'
      case 2:
        return 'Awaiting Approval'
      case 3:
        return 'Approved'
      case 4:
        return 'Billed'
      default:
        return 'unknown'
    }
  }
  convertGridDateIntoDisplay(date) {
    
    const dateString = date.value ? moment(date.value).format("Do MMM YYYY") : '';
    return dateString;
  }
  onGridReady(event){
    this.gridApi = event.api;
  }

  search = () => {
    this.gridApi.setFilterModel(null);
    const searchText = this.searchText.toLowerCase();
    if (searchText.includes(',')) {
      const amount = parseInt(searchText.replace(',', ''), 10);
      this.gridApi.setQuickFilter(null);
      this.gridApi.setRowData(this.filteredBills.filter(row => {
        const orderAmount = parseFloat(row.OrderAmount);
        return ( Math.floor(orderAmount) === amount );
      }));
    } else {
      this.gridApi.setQuickFilter(searchText);
    }
  }

  reLoadRowData = (status: string) => {
    this.filteredBills = this.orderStatus[status] == this.orderStatus.All ? this.allData : this.allData.filter(x => x.StatusId == this.orderStatus[status]);
  }

}
