import { Component, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import { ReportServiceService } from '../../../../service/Report/report-service.service';
import { ToastrService } from 'ngx-toastr';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profit-loss',
  templateUrl: './profit-loss.component.html',
  styleUrls: ['./profit-loss.component.css']
})

export class ProfitLossComponent implements OnInit {
  fromDate: string="" ; //'2020-03-01';
  toDate: string=""; // = '2020-03-12';

  reportData:any={};

  companyName: string ="";

  constructor(private spinner: NgxSpinnerService, 
    private reportService: ReportServiceService, 
    private router:Router,
    private toaster: ToastrService) { }

  ngOnInit(): void {

    this.initialiseOrganization();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.fromDate = firstDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + firstDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + firstDay.toLocaleDateString('en-GB', { day: '2-digit'});
    this.toDate = lastDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + lastDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + lastDay.toLocaleDateString('en-GB', { day: '2-digit'});
    this.show(this.fromDate);
    this.showSelectedDate(this.toDate);
  }


  initialiseOrganization(){
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.companyName = userInfo.SelectedCompany.Name;
    }
  }

      /**
   * openAccountTransaction
id:number   */
public openAccountTransaction(id:number) {
  this.router.navigate(['reports/account-transactions'], 
  { 
    queryParams: 
    { 
      accountId:id,
      fromDate:this.fromDate,
      toDate:this.toDate,
      returnUrl:"profit-loss" 
  } 
});
}

  reportGenerate = () => {
   
    if (!this.toDate || !this.fromDate) {
      this.toaster.warning('To Date and From date are required !');
      return;
    }

    let data = {
      fromDate: this.fromDate,
      toDate: this.toDate
    }

    this.spinner.show();

    this.reportService.GetProfitAndLoss(data).subscribe((x) => {
      
      this.spinner.hide();
      this.reportData=x;
    })
  }

  filterItemsOfType(type, itemList){
    return itemList.filter(x => x.IsOpeningBalance == type);
  }
  
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  convetToPDF = () => {
    var data = document.getElementById('printProfitAndLossReport');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      let fromDate=this.convertDateIntoDisplay(this.fromDate);
      let toDate=this.convertDateIntoDisplay(this.toDate);
      var DocumentName = `Profit and Loss report (${fromDate} to ${toDate}).pdf)`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    });
  }

  show(e) {
    const value = this.fromDate;
    $("#pltest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  showSelectedDate(e) {
    const value = this.toDate;
    $("#pltest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
}
