import { Component, ElementRef, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectComponent } from "@ng-select/ng-select";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { SalesOrderService } from "src/app/service/SalesOrder/sales-order.service";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { environment } from "src/environments/environment";
import { CreateItem } from "../../../models/product.model";
import { AcountingServiceService } from "../../../service/Accounting/acounting-service.service";
import { DataService } from "../../../service/EventEmitter/data.service";
import { IncomeServiceService } from "../../../service/Income/income-service.service";
import { CreatePopupItemComponent } from "../../Products/create-popup-item/create-popup-item.component";
import { InvoiceAdvanceAdjustmentComponent } from "../invoice-advance-adjustment/invoice-advance-adjustment.component";
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { forkJoin } from "rxjs";
import { ItemType } from "src/app/enum/item-type";
import { iQuidiActionType } from "src/app/enum/iquidiActionType";
import { AuthService } from "src/app/service/auth/auth.service";
@Component({
  selector: "app-create-invoice-page",
  templateUrl: "./create-invoice-page.component.html",
  styleUrls: ["./create-invoice-page.component.css"],
  host: {
    "(document:click)": "onWindowClick($event)",
  },
})
export class CreateInvoicePageComponent implements OnInit {
  baseurl = environment.baseUrl;
  baseImageUrl = environment.fileBaseUrl;
  public aModel: CreateItem = new CreateItem();
  allCustomer: any = [];
  taxTypeList: any = [];
  taxRateList: any = [];
  allSaleableItem: any = [];
  allChartOfAccount: any = [];
  costAccountId: number = 0;
  isItemListUpdate = false;
  activeStatusId: number = 0;
  subTotalAmount: number = 0.0;
  ReferenceNo: string = "";
  salesOrderId: number = 0;
  allDeliveryPartner: any;
  InvoicceNo: string = "";
  isUploaded: boolean = false;
  fromSalesOrder: boolean = false;
  uploadCounter: number;
  filesToUpload: number = 0;
  uploadSuccess: boolean;
  Isconversion: boolean;
  loading = false;
  totalFile: number = 0;
  deliveryCharge = 0;
  ifClicked: boolean;
  isPreView: boolean = false;
  PostingModuleId: number = 1;
  ifSubmit: boolean;
  ExternalTransactionId: number = null;
  public invoiceForm: FormGroup;
  public InvoiceItems: FormArray;
  public DeliveryPartnerItems: FormArray;
  ShowInvoiceNo: any;
  accountName: any;
  repeatingTypeList: any = [
    { Id: "1", Name: "Daily" },
    { Id: "2", Name: "Weekly" },
    { Id: "3", Name: "Monthly" },
    { Id: "4", Name: "Yearly" },
  ];
  canApprove: boolean = false;
  productList = [];
  successLogo: boolean = false;
  dangerLogo: boolean = false;
  quantityValue: any;
  salesOrderNo: string;
  bankAccounts: any;
  isReverseTransaction: boolean = false;
  mainTitle: string;
  userInfo: any;

  constructor(
    private mainService: MainServiceService,
    private dataService: DataService,
    private incomeService: IncomeServiceService,
    private accountingService: AcountingServiceService,
    private toaster: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private salesService: SalesOrderService,
    private activeroute: ActivatedRoute,
    public fb: FormBuilder,
    private settingService: SettingsServiceService,
    private fileEmitterService:FileEmitterService,
    private _eref: ElementRef,
    private pService: SalesOrderService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.activeroute.queryParams.subscribe((params) => {
      this.Isconversion = params.Isconversion == undefined ? false : true;
      this.isReverseTransaction = params.IsInvoiceReverseTransaction;
      this.mainTitle = this.setMainTitle();
      if (params.PostingModuleId != undefined) {
        this.PostingModuleId = params.PostingModuleId;
      }
      if (params.ExternalTransactionId != undefined) {
        this.ExternalTransactionId = params.ExternalTransactionId;
      }
      if (params.ExternalReferenceNo != undefined) {
        this.salesOrderId = params.ExternalReferenceNo;
        this.fromSalesOrder = true;
        this.salesService.GetOrderInvoDetails(this.salesOrderId).subscribe((x) => {
            if (x) {
              this.salesOrderNo = x.OrderNo;
              this.invoiceForm.controls["CustomerId"].patchValue(x.Contacts.Id);
              this.invoiceForm.controls["DueDate"].patchValue(x.DeliveryDate);
              this.invoiceForm.controls["TaxTypeId"].patchValue(x.TaxTypeId);
              this.invoiceForm.controls["ReferenceNo"].patchValue(x.OrderNo);
              this.invoiceForm.controls["Notes"].patchValue(x.Notes);
              x.SalesOrderItem.forEach((item) => {
                  this.addSalesItem(item);
              });
              this.updateTotalPrice();
              this.showfromSalesDate(x.DeliveryDate);
            }
          });
        }
        this.initializeForm();
        this.getFormData();
      this.mainService.GetCanApproveForCreateInvoice().subscribe((x) => {
        this.canApprove = x.canApprove;
      });
    });

    this.dataService.change.subscribe((x) => {
      this.isItemListUpdate = x;
      this.getAllSaleableItem();
    });

    this.incomeService.GetMaxInvoiceId().subscribe( (x) => {
      this.fileEmitterService.emitFileRefIdUpdate({id: x.MaxInvoiceId+1});
    });
    this.GetBankAccounts();
  }

  setMainTitle() {
    let isReverseTransaction = '' + this.isReverseTransaction;
    let title = isReverseTransaction == 'true' ? 'Create New Reverse Invoice' : 'Create New Invoice';
    return title;
  }

  forMatDate() {
    var date = new Date();
    var firstDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.showSelectedDate(date);
    //this.selectedDate = firstDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + firstDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + firstDay.toLocaleDateString('en-GB', { day: '2-digit'});
  }
  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }

  getFormData = async () => {
    var getAllChartOfAccount = this.getAllChartOfAccount();
    var generateInvoieNo = this.generateInvoiceNo();
    var getAllCustomer = this.getAllCustomer(0);
    var getAllSaleableItem = this.getAllSaleableItem();
    var getTaxTypeList = this.getTaxTypeList();
    var getTaxRateList = this.getTaxRateList();
    var getItemList = this.getItems();
    await Promise.all([generateInvoieNo, getAllCustomer, getAllSaleableItem, getAllChartOfAccount, getTaxTypeList, getTaxRateList, getItemList])
  };
  public getItems = () => {
    this.incomeService.getSaleableItemDetails().subscribe((x) => {
      this.productList = x;
    });
  };
  public addSalesItem(x: any): void {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.push(this.createSalesItem(x));
  }
  public createSalesItem(x: any): FormGroup {
    let quantity = x.Quantity < 0 ? ((-1) * x.Quantity) : x.Quantity;
    return this.fb.group({
      Id: [0],
      InvoiceId: [0],
      ItemId: [x.ItemId],
      Description: [x.Description],
      Quantity: [quantity],
      UnitPrice: [x.UnitPrice],
      LineTotal: [quantity * x.UnitPrice],
      AccountId: [x.AccountId],
      TaxRateId: [x.TaxRateId],
      AdvanceTaxRateId: [x.AdvanceTaxRateId],
    });
  }
  public addItem(): void {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.push(this.createItem());
  }
  initializeForm() {
    this.invoiceForm = this.fb.group({
      Id: [0],
      InvoicceNo: [""],
      CustomerId: [null, Validators.required],
      ReferenceNo: [this.ReferenceNo],
      InvoiceDate: [""],
      DueDate: [""],
      InvoiceAmount: [0],
      IsConversion: [this.Isconversion],
      TaxTypeId: [3],
      PostingModuleId: [this.PostingModuleId],
      TaxAmount: [""],
      IsActiveDeliveryPartner: [''],
      AdvanceTaxAmount: [""],
      ExternalTransactionId: [this.ExternalTransactionId],
      Notes: [""],
      IsRepeating: [""],
      StatusId: [''],
      BusinessUnitRoleId: [0],
      RepeatingTypeId: [null],
      DeliveryPartnerItems: this.fb.array([]),
      InvoiceItems: this.fromSalesOrder
        ? this.fb.array([])
        : this.fb.array([this.createItem()]),
    });
    this.invoiceForm.get("InvoiceDate").patchValue(new Date());
    this.showSelectedDate(this.invoiceForm.value.InvoiceDate);
  }
  public createItem(): FormGroup {
    this.ifClicked = false;
    return this.fb.group({
      ItemId: [null],
      Description: ["", Validators.required],
      Quantity: [0, Validators.required],
      UnitPrice: [0, Validators.required],
      DangerLogo: [""],
      SuccessLogo: [""],
      LineTotal: [0, Validators.required],
      AccountId: [this.costAccountId, Validators.required],
      selected: [false],
      TaxRateId: [null],
      DiscountPercent: [0],
      DiscountAmount: [null],
      AdvanceTaxRateId: [null],
    });
  }
  public createDeliveryItem(): FormGroup {
    this.ifClicked = false;
    return this.fb.group({
      DeliveryPartnerId: [null],
      Description: ["Delivery Charge", Validators.required],
      UnitPrice: ['', Validators.required],
      LineTotal: [0, Validators.required],
    });
  }
  private getTaxTypeList = async () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
    });
  };
  private getTaxRateList = async () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({ Id: "", Name: "Select" });
      this.updateTotalPrice();
    });
  };
  changesellableItems = (index) => {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    for (let i = 0; i < this.allSaleableItem.length; i++) {
      if (
        this.allSaleableItem[i].Id ==
        this.InvoiceItems.controls[index].value.ItemId
      ) {
        this.InvoiceItems.controls[index]
          .get("ItemId")
          .patchValue(this.allSaleableItem[i]["Id"]);
        this.InvoiceItems.controls[index]
          .get("AccountId")
          .patchValue(this.allSaleableItem[i]["SaleAccountId"] == null ? this.costAccountId : this.allSaleableItem[i]["SaleAccountId"]);
        this.InvoiceItems.controls[index].get("Quantity").patchValue(1);
        this.InvoiceItems.controls[index]
          .get("UnitPrice")
          .patchValue(this.allSaleableItem[i]["SaleUnitPrice"]);
        this.InvoiceItems.controls[index]
          .get("Description")
          .patchValue(this.allSaleableItem[i]["SalesDescription"]);
        this.updateDataInvoiceItem(this.InvoiceItems.controls[index].value.ItemId, this.InvoiceItems.controls[index].value, index);
      }
    }
  };
  showfromSalesDate(date) {
    const value = date;
    $("#itest")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  show(e) {
    const value = this.invoiceForm.value.DueDate;
    $("#itest")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  showSelectedDate(e) {
    const value = this.invoiceForm.value.InvoiceDate;
    $("#itest").attr(
      "min",
      moment.isDate(value) ? value.toISOString().split("T")[0] : value
    );
    $("#itest1")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  openItemModal(selectItem: NgSelectComponent) {
    selectItem.close();
    const modalRef = this.modalService.open(CreatePopupItemComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.refId = 2;
    modalRef.componentInstance.type = "sales";
    modalRef.result.then(
      (result) => {
        this.getAllSaleableItem();
        this.getItems();
      },
      (reason) => {
        console.log("Dismissed action: " + reason);
      }
    );
  }

  getAllCustomer = async (type: number) => {
    this.incomeService.GetAllContactWithDeliveryPartner().subscribe((x: any) => {
      this.allCustomer = x.filter(xy => xy.IsCustomer == true);
      this.allCustomer.forEach(element => {
        if (element.ContactNo != null) {
          element.CompanyName = element.CompanyName + ' ' + element.ContactNo;
        }
      });
      this.allDeliveryPartner = x.filter(xy => xy.IsDeliveryPartner == true);
      if (this.allDeliveryPartner.length > 0) {
        if(type == 0)
          this.invoiceForm.get('IsActiveDeliveryPartner').patchValue(false);
        else
          this.invoiceForm.get('IsActiveDeliveryPartner').patchValue(true);
        this.toogleDeliveryPartner(type);
      }
    });
  }
  generateInvoiceNo = async () => {
    this.incomeService.GenerateInvoiceNo().subscribe((x: any) => {
      this.ShowInvoiceNo = '';
      let data = x.toString();
      let post = '';
      for (let i = 0; i < 6; i++) this.ShowInvoiceNo += data[i];
      for (let i = 6; i < data.length; i++) post += data[i];
      this.invoiceForm.get("InvoicceNo").patchValue(post);
      this.InvoicceNo = x;
    });
  }
  getAllSaleableItem = async () => {
    this.incomeService.GetAllSaleableItem().subscribe((x: []) => {
      this.allSaleableItem = x;
      if (this.allSaleableItem.length == 0) {
        this.allSaleableItem.push({
          ItemId: 0,
          Description: "",
          Quantity: 1,
          UnitPrice: "",
          LineTotal: "",
          AccountId: "",
          selected: true,
        });
      }
    });
  };
  getAllChartOfAccount = async () => {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
        this.allChartOfAccount = x.RevenueList;
        this.costAccountId = this.allChartOfAccount?.filter((xy) => xy.AccountName == "200 - Sales")[0]?.Id;
        const myForm = (<FormArray>this.invoiceForm.get("InvoiceItems")).at(0);
        myForm?.patchValue({AccountId: this.costAccountId,});
        let len = (<FormArray>this.invoiceForm.get("InvoiceItems")).length;
        for( var i = 0; i < len; i++ ){
          const form = (<FormArray>this.invoiceForm.get("InvoiceItems")).at(i);
          form.patchValue({
            AccountId: this.costAccountId,
          });
        }
    });
  };

  updateTotalPrice = () => {
    this.subTotalAmount = 0.0;
    let TaxAmount: number = 0.0;
    let AdvanceTaxAmount: number = 0.0;
    let totalPrice = 0.0;
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, i) => {
      this.subTotalAmount = (this.subTotalAmount + Number(x.LineTotal));
      console.log(this.subTotalAmount);
      console.log(x.LineTotal);
      TaxAmount += this.getItemTaxAmount(x.LineTotal, x.TaxRateId);
      AdvanceTaxAmount += this.getItemTaxAmount(
        x.LineTotal,
        x.AdvanceTaxRateId
      );
    });
    switch (this.invoiceForm.value.TaxTypeId) {
      case 1: //tax exclusive
        totalPrice = this.subTotalAmount + TaxAmount + AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
        totalPrice = this.subTotalAmount;
        break;
    }
    if (this.invoiceForm.value.TaxTypeId === 3) {
      this.checkTaxType();
    }
    this.invoiceForm.get("TaxAmount").patchValue(TaxAmount.toFixed(2));
    this.invoiceForm
      .get("AdvanceTaxAmount")
      .patchValue(AdvanceTaxAmount.toFixed(2));
    this.invoiceForm.get("InvoiceAmount").patchValue(totalPrice);
  }

  checkTaxType = () => {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, i) => {
      const myForm = (<FormArray>this.invoiceForm.get("InvoiceItems")).at(i);
      myForm.patchValue({
        TaxRateId: null,
        AdvanceTaxRateId: null,
      });
    });
  };

  private getItemTaxAmount(lineTotal: number, taxRateId: any) {
    let taxPrice: number = 0.0;
    if (taxRateId == "" || taxRateId == null) return taxPrice;

    var taxPercent = this.getTaxRatePercent(taxRateId);

    switch (this.invoiceForm.value.TaxTypeId) {
      case 1: //tax exclusive
        taxPrice = Number(((lineTotal * taxPercent) / 100).toFixed(2));
        break;
      case 2: //tax inclusive
        taxPrice = Number(
          ((lineTotal * taxPercent) / (100 + taxPercent)).toFixed(2)
        );
        break;
      default:
        //no tax
        taxPrice = 0;
        break;
    }
    return taxPrice;
  }

  private getTaxRatePercent(taxRateId: any) {
    let taxRateObj = this.taxRateList.filter((x) => x.Id == taxRateId);
    console.log(this.taxRateList);
    return taxRateObj[0].Parcent;
  }

  onFocusEvent(event: any) {
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }
  updateDataInvoiceItem = (itemId, item, index) => {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    if(!itemId) {
      this.InvoiceItems.controls[index].get("DangerLogo").patchValue(false);
      this.InvoiceItems.controls[index].get("SuccessLogo").patchValue(false);
      this.InvoiceItems.controls[index].get("LineTotal").patchValue(item.Quantity * item.UnitPrice);
    }
    else{
       let findProduct = this.productList.find((x) => x.Id == itemId);
      if(findProduct.ItemTypeId == ItemType.Service) {
        this.InvoiceItems.controls[index].get("DangerLogo").patchValue(false);
        this.InvoiceItems.controls[index].get("SuccessLogo").patchValue(false);
        this.InvoiceItems.controls[index].get("LineTotal").patchValue(item.Quantity * item.UnitPrice);
      }
      else {
        this.quantityValue = this.InvoiceItems.value[index].Quantity;
        if (findProduct.StockQuantity < Number(this.quantityValue) || findProduct.StockQuantity <= 0) {
          this.dangerLogo = true;
          this.successLogo = false;
          this.invoiceForm.value.InvoiceItems.map((x) => {
            if (x.ItemId == itemId) {
              this.InvoiceItems.controls[index].get("DangerLogo").patchValue(true);
              this.InvoiceItems.controls[index].get("SuccessLogo").patchValue(false);
            }
          });
        } else if (findProduct.StockQuantity >= Number(this.quantityValue)) {
          this.successLogo = true;
          this.dangerLogo = false;
          this.invoiceForm.value.InvoiceItems.map((x) => {
            if (x.ItemId == itemId) {
              this.InvoiceItems.controls[index].get("DangerLogo").patchValue(false);
              this.InvoiceItems.controls[index].get("SuccessLogo").patchValue(true);
            }
          });
        }
      }
      this.InvoiceItems.value.map((x, i) => {
        if (x.ItemId == itemId && i == index) {
          x.LineTotal = x.Quantity * x.UnitPrice;
          this.InvoiceItems.controls[i].get("LineTotal").patchValue(x.LineTotal.toFixed(2));
          this.updateDiscountInvoiceItem(itemId, i);
        }
      });
    }
    this.updateTotalPrice();
  }
  updateDiscountInvoiceItem = (itemId, index) => {
    console.log(itemId);
    let amount = 0.0;
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, i) => {
      if (x.ItemId == itemId && i == index) {
        x.LineTotal = x.UnitPrice * x.Quantity;
        amount = x.LineTotal - (x.DiscountPercent * x.LineTotal) / 100;
        this.InvoiceItems.controls[i].get("LineTotal").patchValue(amount.toFixed(2));
        this.InvoiceItems.controls[i].get('DiscountAmount').patchValue(((x.DiscountPercent * x.LineTotal) / 100).toFixed(2));
        this.updateTotalPrice();
      }

    });
  }
  updateDataInvoiceItemLineTotal = (itemId) => {
    // this.invoiceItem.map((x) => {
    //   if (x.ItemId == itemId) {
    //     x.UnitPrice = x.LineTotal / x.Quantity
    //   }
    // })
    this.updateTotalPrice();
  };

  defaultAccountSettings = (item) => {
    const account = this.allChartOfAccount.filter(
      (x) => x.AccountCode === "200"
    );
    item.map((x) => {
      if (x.AccountId == null) {
        x.AccountId = account[0].Id;
      }
    });
  };
  removeInvoiceItem = (itemId, i) => {
    let data = [];
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, index) => {
      if (index === i) {
        this.InvoiceItems.removeAt(index);
      }
    });
    this.updateTotalPrice();
  }

  setInvoiceData = () => {
    let InvoiceNo = Math.floor(Math.random() * 90000) + 10000;
    this.InvoicceNo =
      this.InvoicceNo != "" ? this.InvoicceNo : InvoiceNo.toString();
    this.invoiceForm.get("InvoicceNo").patchValue(this.InvoicceNo);
  };

  checkValidation = (invoiceItem) => {
    var b = true;
    console.log(invoiceItem);
    invoiceItem.forEach((element) => {
      if (
        element.Description == "" ||
        element.AccountId == null ||
        element.LineTotal < 0 ||
        (this.invoiceForm.value.TaxTypeId != 3 &&
          element.TaxRateId == null &&
          element.AdvanceTaxRateId == null)
      ) {
        b = false;
      }
    });
    return b;
  };
  private isValidData() {
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    let check = this.checkValidation(this.InvoiceItems.value);
    if (this.invoiceForm.invalid) {
      this.toaster.warning("Required field can not be empty!", "Warning!");
      return false;
    } else if (
      this.invoiceForm.value.IsRepeating == true &&
      this.invoiceForm.value.RepeatingTypeId == null
    ) {
      this.toaster.warning("Required field can not be empty!", "Warning!");
      return false;
    } else if (this.InvoiceItems.value.length === 0) {
      this.toaster.warning(
        "Please add at least one item to the invoice.",
        "No Item Added!"
      );
      return false;
    } else if (!check) {
      this.toaster.warning("Required field can not be empty!");
      return false;
    }
    return true;
  }

  async checkInvoiceNo() {
    var val = this.ShowInvoiceNo + this.invoiceForm.get('InvoicceNo')?.value;
    if (val == '' || val == null) {
      this.toaster.error('Please Input Invoice No.!');
      return false;
    }

    let invoiceNo = this.InvoicceNo.toString();
    let concat = "";
    for (let i = 0; i < 6; i++) concat += invoiceNo[i];

    if (val.length != 10) {
      this.toaster.error("Invoice number needs to be four-digit numbers");
      return false;
    }

    for (let i = 0; i < concat.length; i++) {
      if (val[i] != concat[i]) {
        this.toaster.error("Invoice number must start with " + concat + " and ends with four-digit numbers");
        return false;
      }
    }

    return true;
  }

  saveAsSubmitInvoice = async () => {
    if (!await this.checkInvoiceNo()) return;
    //this.invoiceForm.value.IsActiveDeliveryPartner === true ? this.invoiceForm.get('StatusId').patchValue(3) :
    this.invoiceForm.get('StatusId').patchValue(2);
    this.invoiceForm.get('BusinessUnitRoleId').patchValue(this.userInfo.RoleId);
    this.ifSubmit = true;
    if (!this.isValidData())
      return;
    this.Isconversion ? this.saveConverionInvoiceData()
      : this.saveAsDraftInvoice();
  }
  saveAsDraftCreateInvoice = async () => {
    if (!await this.checkInvoiceNo()) return;
    this.ifSubmit = true;
    this.invoiceForm.get('StatusId').patchValue(1);
    this.invoiceForm.get('BusinessUnitRoleId').patchValue(this.userInfo.RoleId);
    if (!this.isValidData()) return;

    this.Isconversion
      ? this.saveConverionInvoiceData()
      : this.saveAsDraftInvoice();
  }

  isStockAvailability(){
    var size = this.InvoiceItems.value.length;
    var isStockAvailable = true;
    for(var i = 0; i < size; i++){
      if(this.InvoiceItems.value[i].DangerLogo == true){
        isStockAvailable = false;
        this.toaster.error("Stock availability 0. Need to purchase product or order from sales order.");
        break;
      }
    }
    return isStockAvailable;
  }

  saveCreateInvoice = async () => {
    if(this.isStockAvailability() == false) return;
    if (!await this.checkInvoiceNo()) return;
    this.ifSubmit = true;
    this.invoiceForm.get('StatusId').patchValue(3);
    this.invoiceForm.get('BusinessUnitRoleId').patchValue(this.userInfo.RoleId);
    if (!this.isValidData()) return;

    if( this.fromSalesOrder ){
      this.Isconversion ? this.saveConverionInvoiceData() : this.saveDefaultInvoiceDataForSalesOrder();
    }else{
      this.Isconversion ? this.saveConverionInvoiceData() : this.saveDefaultInvoiceData();
    }

  };

  CreateNewCustomer = (customerName) => {
    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: true,
      IsSupplier: false,
    };
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("Contact has been successfully added !");
          this.loading = false;
          this.allCustomer.push(x["Data"]);
          console.log(this.allCustomer);
          let customerObj = this.allCustomer.filter(
            (x) => x.PrimaryPersonFirstName == customerName
          );
          console.log(customerObj);
          this.invoiceForm.get("CustomerId").patchValue(customerObj[0]["Id"]);
          this.getAllCustomer(0);
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.loading = false;
      }
    );
  };

  private displaySuccessResponse(x: any, sMessage: string) {
    console.log(x);
    if (x.Success) {
      this.toaster.success(sMessage, "Success!");
      this.redirectToListPage();
      this.spinner.hide();
    } else {
      this.toaster.warning(x.Message);
      this.spinner.hide();
    }
  }

  private redirectToListPage() {
    let rUrl = "income/invoice-overview/All";
    if (this.Isconversion) {
      rUrl = "accounting/historic-conversionbalancesInvoice";
    }

    if (this.PostingModuleId == 2) {
      //here 2 means project module
      rUrl = "project/details/" + this.ExternalTransactionId;
    }
    this.router.navigate([rUrl]);
  }

  saveDefaultInvoiceData() {
    this.spinner.show();
    this.invoiceForm.value.InvoicceNo = this.ShowInvoiceNo + this.invoiceForm.value.InvoicceNo;
    const data = this.invoiceForm.value
    data.IQuidiActionType = iQuidiActionType.Approve;
    this.incomeService.Create(data).subscribe((x) => {
        if (x.Success) {
          this.toaster.success(`Invoice ${this.invoiceForm.value.InvoicceNo} has been successfully Approved`,"success");
          if (x.HasAdvance) {
            this.openAdvanceAdjustmentPopup(x.BalanceData);
          } else {
            this.router.navigate(["/income/invoice/",'INV-' + this.invoiceForm.value.InvoicceNo,]);
          }
          this.spinner.hide();
        } else {
          this.toaster.warning("Invoice No already exists");
          this.spinner.hide();
        }
      });
  }

  saveDefaultInvoiceDataForSalesOrder() {
    this.spinner.show();
    this.invoiceForm.value.InvoicceNo = this.ShowInvoiceNo + this.invoiceForm.value.InvoicceNo;
    let data = this.invoiceForm.value;
    data.IsReverseTransaction = this.isReverseTransaction;
    data.IQuidiActionType = iQuidiActionType.Approve;
    this.incomeService.Create(data).subscribe((x) => {
        if (x.Success) {
          this.toaster.success(`Invoice ${this.invoiceForm.value.InvoicceNo} has been successfully Approved`,"success");
          if (x.HasAdvance) {
            this.openAdvanceAdjustmentPopup(x.BalanceData);
          } else {
            this.router.navigate(['sales/sales-order-invoice-details/'+this.salesOrderId+'/'+this.salesOrderNo]);
          }
          this.spinner.hide();
        } else {
          this.toaster.warning("Invoice No already exists");
          this.spinner.hide();
        }
      });
  }

  GetBankAccounts(){
    this.accountingService.GetBankAccounts().subscribe((s) => {
      this.bankAccounts = s;
    });
  }

  private openAdvanceAdjustmentPopup(x: any) {
    const modalRef = this.modalService.open(InvoiceAdvanceAdjustmentComponent, {
      size: "md",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.fromParent = x;
    modalRef.componentInstance.bankAccounts = this.bankAccounts;
    modalRef.result.then(
      (result) => {
        this.router.navigate([
          "/income/invoice",
          "INV-"+this.invoiceForm.value.InvoicceNo,
        ]);
      },
      (reason) => {
        console.log("Dismissed action: " + reason);
      }
    );
  }

  saveAsDraftInvoice() {
    this.spinner.show();
    this.invoiceForm.value.InvoicceNo = this.ShowInvoiceNo + this.invoiceForm.value.InvoicceNo;
    const data = this.invoiceForm.value;
    data.IsReverseTransaction = this.isReverseTransaction;
    if(data.StatusId == 2) data.IQuidiActionType = iQuidiActionType.Submit;
    else data.IQuidiActionType = iQuidiActionType.Save;
    data.IsReverseTransaction = this.isReverseTransaction;
    this.incomeService.Create(data).subscribe((x) => {
      this.displaySuccessResponse(x,`Invoice ${this.invoiceForm.value.InvoicceNo} has been successfully saved`);
    });
  }

  saveConverionInvoiceData() {
    this.spinner.show();
    this.invoiceForm.value.InvoicceNo = this.ShowInvoiceNo + this.invoiceForm.value.InvoicceNo;
    this.incomeService
      .saveConverionInvoiceData(this.invoiceForm.value)
      .subscribe((x) => {
        this.displaySuccessResponse(
          x,
          `Invoice ${this.invoiceForm.value.InvoicceNo} has been successfully saved`
        );
      });
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  fileName: string;
  fileType: string;

  previewOfInvoice() {
    this.isPreView = this.isPreView ? false : true;
  }

  onWindowClick(event) {
    if (
      event.target.id == "attachmentDropdown" ||
      event.target.id == "attachment" ||
      event.target.id == "files" ||
      event.target.id == "Preview"
    ) {
    } else if (event.target.id == "attachment_btn") {
      this.openAttachmentDropdown();
    } else {
      if (
        document.getElementById("attachmentDropdown").classList.contains("show")
      ) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }
  changeSelectRowInItem = (itemId, index) => {
    console.log(index);
    this.InvoiceItems = this.invoiceForm.get("InvoiceItems") as FormArray;
    this.InvoiceItems.value.map((x, i) => {
      if (i === index) {
        x.selected = true;
        this.InvoiceItems.controls[i].get("selected").setValue(true);
      }
    });
  };
  changeType = () => {
    if (this.invoiceForm.value.IsRepeating === false) {
      this.invoiceForm.get("RepeatingTypeId").patchValue(null);
    }
  };
  getContactNo = () => {
    const customer = this.allCustomer.filter(
      (x) => x.Id == this.invoiceForm.value.CustomerId
    );
    console.log(customer);
    if (customer.length > 0) {
      this.invoiceForm.get("ContactNo").patchValue(customer[0].ContactNo);
    }
  }
  updateTotalAfterDeliveryCharge = () => {
    console.log(this.invoiceForm.value);
    this.DeliveryPartnerItems = this.invoiceForm.get('DeliveryPartnerItems') as FormArray;
    this.DeliveryPartnerItems.value.map((x, i) => {

      x.LineTotal = x.UnitPrice;
      this.deliveryCharge += x.UnitPrice;
      this.DeliveryPartnerItems.controls[i].get('LineTotal').patchValue(x.LineTotal);
    });
    this.invoiceForm.get('InvoiceAmount').patchValue(this.invoiceForm.value.InvoiceAmount);
  }
  removeDeliveryItem = (i) => {

    this.DeliveryPartnerItems = this.invoiceForm.get('DeliveryPartnerItems') as FormArray;
    this.DeliveryPartnerItems.value.map((x, index) => {
      if (index === i) {
        this.DeliveryPartnerItems.removeAt(index);
      }
    });
    this.invoiceForm.get('InvoiceAmount').patchValue(this.invoiceForm.value.InvoiceAmount);
    this.invoiceForm.get('IsActiveDeliveryPartner').patchValue(false);
    this.deliveryCharge = 0;
  }
  toogleDeliveryPartner = (type: number) => {
    const value = this.invoiceForm.value.IsActiveDeliveryPartner;
    console.log(value);
    if (value == true) {
      this.DeliveryPartnerItems = this.invoiceForm.get("DeliveryPartnerItems") as FormArray;
      if(type == 0)
        this.DeliveryPartnerItems.push(this.createDeliveryItem());

    }
    else {
      this.removeDeliveryItem(0);
    }
  }

  CreateNewDeliveryPartner = (customerName) => {
    let deliveryPartnerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: false,
      IsSupplier: false,
      IsDeliveryPartner: true,
    };
    this.loading = true;

    this.mainService.saveContact(deliveryPartnerData).subscribe((x) => {
        if (x.Success) {
          this.toaster.success("Delivery partner has been successfully added !");
          this.loading = false;
          this.allDeliveryPartner.push(x["Data"]);
          let deliveryPartnerObj = this.allDeliveryPartner.filter((x) => x.PrimaryPersonFirstName == customerName);
          let DeliveryPartnerItems = this.invoiceForm.get("DeliveryPartnerItems") as FormArray;
          DeliveryPartnerItems.controls[0].get("DeliveryPartnerId").patchValue(deliveryPartnerObj[0]["Id"]);
          this.getAllCustomer(1);
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.loading = false;
      }
    );
  };

}

