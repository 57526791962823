import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-user-role-set-up',
  templateUrl: './user-role-set-up.component.html',
  styleUrls: ['./user-role-set-up.component.css']
})
export class UserRoleSetUpComponent implements OnInit {

  constructor(
    private router: ActivatedRoute,
    private toastr: ToastrService,
    private settingService: SettingsServiceService,
    private spinner: NgxSpinnerService,
    private mainService: MainServiceService) { }

  module: any;
  RoleId = 1;
  businessUnitList: any[];

  roleOptions = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Accountant' },
    { id: 3, name: 'Auditor' }
  ];

  ngOnInit(): void {
    this.getAllBusinessUnit();
    this.getAllCompanyUserRole(1);
  }

  getAllBusinessUnit() {
    this.mainService.GetAllBusinessUnit().subscribe((x) => {
      this.businessUnitList = x;
      this.addNewRole(this.businessUnitList);
    });
  }

  addNewRole(businessUnitList) {
    for(let i = 0; i < businessUnitList.length; i++) {
      const newRoleId = businessUnitList[i].RoleId;
      const newRoleName = businessUnitList[i].Name;
      const newRole = { id: newRoleId, name: newRoleName };
      this.roleOptions.push(newRole);
    }
  }

  getAllCompanyUserRole = (roleId) =>
  {
    this.spinner.show();
    this.settingService.GetAllMenuByUserRole(roleId).subscribe((x) => {
      this.module = x;
      this.spinner.hide();
    });
  }

  addOrRemoveMenuPermission = (id) => {
    this.spinner.show();
    this.settingService.AddOrRemoveMenuPermission(id).subscribe((x) => {
      if (x.Success)
      {
        this.toastr.success('Menu permission updated Successfully.');
        this.spinner.hide();
        this.getAllCompanyUserRole(this.RoleId);
      }
      else
      {
        this.toastr.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

}
