<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle" *ngIf="operationType == 3">Update Document Input Label Name</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="DynamicLabelNameForDocumentInputsForm" class="f-ss-14px">
                    <p class="mt-3 mb-3 font-weight-bold">Please set dynamic label name for document inputs</p>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group col-sm-6">
                                <label for="DocumentTypeId" style="font-weight: 600;">Field Name <span class="text-red">*</span></label>
                                <input type="text" class="form-control" formControlName="FieldName" disabled>
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="DocumentTypeId" style="font-weight: 600;">Label Name <span class="text-red">*</span></label>
                                <input type="text" class="form-control" formControlName="LabelName">
                            </div>
                        </div>
                    </div>
                    <div class="full-width text-align-right">
                        <button type="button" class="eCount-def-btn mt-4 mr-2" (click)="save()">Update</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>