import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ItemType } from 'src/app/enum/item-type';
import { ManufactureBackOrderFlowType } from 'src/app/enum/manufacture-backorder-flow';
import { ManufactureItemType } from 'src/app/enum/manufacture-order-item-type';
import { ManufactureOrderProductionPageViewModel, ManufactureOrderViewModel } from 'src/app/models-viewModels/manufactureViewModel.model';
import { Item, ItemDetails } from 'src/app/models/inventoryOverview.model';
import { ManufactureOrder, ManufactureOrderDetails, OperatingCostItem, OperationCostType } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';
import { ProductServiceService } from 'src/app/service/Products/product-service.service';
import { ManufactureBackOrderFlowTypeModalComponent } from '../manufacture-back-order-flow-type-modal/manufacture-back-order-flow-type-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManufactureOrderStatus } from 'src/app/enum/manufacture-order-status';

@Component({
  selector: 'app-produce-manufacutring-order',
  templateUrl: './produce-manufacutring-order.component.html',
  styleUrls: ['./produce-manufacutring-order.component.css']
})
export class ProduceManufacutringOrderComponent implements OnInit {

  ManufactureOrderForm: FormGroup;
  OrderItems: FormArray;
  MiscellaneousItems: FormArray;
  OperatingCostItems: FormArray;
  finishedProductList: Item[];
  rawMaterialList: Item[];
  operationCostTypeList: OperationCostType[];
  manufactureOrderData: ManufactureOrder[] = [];
  manufactureOrderProductionData: ManufactureOrderProductionPageViewModel;

  subTotalAmount: number = 0.0;
  materialCost: number = 0.0;
  miscellaneousItemCost: number = 0.0;
  operatingItemCost: number = 0.0;
  productionCost: number = 0.0;

  orderId: number;
  loading = false;
  itemMap: Map<number, ItemDetails> = new Map<number, ItemDetails>();
  selectedOperationCostType: number; 
  
  constructor(
    private fb: FormBuilder,
    private productServiceService: ProductServiceService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private manufactureService: ManufactureService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.orderId = +params['id'];
      this.initializeForm();
      this.getFormData();
   });
  }

  getFormData() {
    this.getManufactureOrderData();
    this.getFinishedProductList();
    this.getRawMaterialList();
    this.getOperationCostTypeList();
  }

  getFinishedProductList() {
    this.productServiceService.GetItemList(ItemType.FinishProduct).subscribe((x) => {
      this.finishedProductList = x;
    });
  }

  getRawMaterialList() {
    this.productServiceService.GetItemList(ItemType.RawMaterial).subscribe((x) => {
      this.rawMaterialList = x;
    });
  }

  getOperationCostTypeList() {
    this.manufactureService.getOperationCostTypeList().subscribe((x) => {
      this.operationCostTypeList = x;
    });
  }

  initializeForm() {
    this.ManufactureOrderForm = this.fb.group({
      Id: [0],
      FinishedProductId: [''],
      FormulaName: [''],
      Quantity: ['', Validators.required],
      CreatedDate: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      Reference: [''],
      BatchNo: [''],
      OperatingCostPerUnit: [0],
      MaterialCostPerUnit: [0],
      MiscellaneousItemCostPerUnit: [0],
      ProductionCostPerUnit: [0],
      TotalOperatingCost: [0],
      TotalMaterialCost: [0],
      TotalMiscellaneousItemCost: [0],
      TotalProductionCost: [0],
      OrderItems: this.fb.array([]),
      MiscellaneousItems: this.fb.array([]),
      OperatingCostItems: this.fb.array([])
    });
  }

  getManufactureOrderData() {
    this.manufactureService.getManufactureOrderProductionData(this.orderId).subscribe(x => {
      this.manufactureOrderProductionData = x;
      x.ItemDetails.forEach(obj => {
        let key = obj.Id;
        this.itemMap.set(key, obj);
      });
      this.setParentData(this.manufactureOrderProductionData);
      this.manufactureOrderProductionData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.NORMAL).forEach((item, i) => {
        this.addItem(item, i);
      });

      this.manufactureOrderProductionData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).forEach((item, i) => {
        this.addMiscellaneousItem(item, i);
      });
    });
  }

  setParentData(orderData) {
    this.ManufactureOrderForm.controls['Id'].patchValue(orderData.ManufactureOrder.Id);
    this.ManufactureOrderForm.controls['FinishedProductId'].patchValue(orderData.ManufactureOrder.Item.ItemCode);
    this.ManufactureOrderForm.controls['FormulaName'].patchValue(orderData.ManufactureFormula.FormulaName);
    this.ManufactureOrderForm.controls['Quantity'].patchValue(orderData.ManufactureOrder.ProductionQuantity);
    this.ManufactureOrderForm.controls['CreatedDate'].patchValue(orderData.ManufactureOrder.CreatedDate);
    this.ManufactureOrderForm.controls['StartDate'].patchValue(orderData.ManufactureOrder.StartDate);
    this.ManufactureOrderForm.controls['EndDate'].patchValue(orderData.ManufactureOrder.EndDate);
    this.ManufactureOrderForm.controls['Reference'].patchValue(orderData.ManufactureOrder.Reference);
    this.ManufactureOrderForm.controls['BatchNo'].patchValue(orderData.ManufactureOrder.BatchNo);
    this.ManufactureOrderForm.controls['OperatingCostPerUnit'].patchValue(orderData.ManufactureOrder.OperationTotalAmount);
    this.ManufactureOrderForm.controls['MaterialCostPerUnit'].patchValue(orderData.ManufactureOrder.ItemTotalAmount);
    this.ManufactureOrderForm.controls['TotalMiscellaneousItemCost'].patchValue(orderData.ManufactureOrder.ItemTotalAmount);
    this.ManufactureOrderForm.controls['ProductionCostPerUnit'].patchValue(orderData.ManufactureOrder.PerUnitCost);
    this.showSelectedDate(this.ManufactureOrderForm.value.CreatedDate);
    this.showStartSelectedDate(this.ManufactureOrderForm.value.StartDate);
    this.showEndSelectedDate(this.ManufactureOrderForm.value.EndDate);
  }

  addItem(item: ManufactureOrderDetails, i: number) {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.push(this.createItem(item, i));
    this.updateTotalPrice();
  }

  addMiscellaneousItem(item: ManufactureOrderDetails, i: number) {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.push(this.createMiscellaneousItem(item, i));
    this.updateTotalPrice();
  }

  createItem(item: ManufactureOrderDetails, i: number): FormGroup {
    let formulaQuantity = this.manufactureOrderProductionData.ManufactureFormula.Quantity;
    let formulaItem = this.manufactureOrderProductionData.ManufactureFormula.ManufactureFormulaDetails.filter(x => x.ItemId == item.ItemId)[0];
    let consume = ((formulaItem.Quantity/formulaQuantity) * this.manufactureOrderProductionData.ManufactureOrder.ProductionQuantity).toFixed(2);
    return this.fb.group({
      ItemId: [item.ItemId],
      RawMaterialId: [item.Item.ItemCode],
      ToConsume: [consume+'/'+consume],
      Reserved: [this.getItemReserved(item)],
      UsedItems: [item.UsedItem],
      BrokenItems: [item.BrokenItem],
      Consumed: [item.TotalConsumed],
      UnitPrice: [item.PricePerUnit],
      LineTotal: [item.LineTotal],
      ManufactureOrderDetailsId: [item.Id]
    });
  }

  getItemReserved(item: ManufactureOrderDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.TotalConsumed <= stockQuantity ? item.TotalConsumed : stockQuantity;
  }

  createMiscellaneousItem(item: ManufactureOrderDetails, i: number): FormGroup {
    return this.fb.group({
      ItemId: [item.ItemId],
      MiscellaneousRawMaterialId: [item.Item.ItemCode],
      MiscellaneousToConsume: [item.TotalConsumed],
      MiscellaneousReserved: [this.getMiscellaneousItemReserved(item)],
      MiscellaneousConsumed: [item.TotalConsumed],
      MiscellaneousUnitPrice: [item.PricePerUnit],
      MiscellaneousLineTotal: [item.LineTotal],
      ManufactureOrderDetailsId: [item.Id],
    });
  }

  getMiscellaneousItemReserved(item: ManufactureOrderDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.TotalConsumed <= stockQuantity ? item.TotalConsumed : stockQuantity;
  }

  changeItems(i: number) {

  }

  updateItemsData(itemId: number) {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.value.map((item, i) => {
      item.Consumed = Number(item.UsedItems) + Number(item.BrokenItems);
      item.LineTotal = (Number(item.UsedItems) + Number(item.BrokenItems)) * item.UnitPrice;
      this.OrderItems.controls[i].get('UsedItems').patchValue(item.UsedItems == '' ? 0 : item.UsedItems);
      this.OrderItems.controls[i].get('BrokenItems').patchValue(item.BrokenItems == '' ? 0 : item.BrokenItems);
      this.OrderItems.controls[i].get('Consumed').patchValue(item.Consumed);
      this.OrderItems.controls[i].get('LineTotal').patchValue(item.LineTotal);
    });

    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.value.map((item, i) => {
      item.MiscellaneousLineTotal = Number(item.MiscellaneousConsumed) * item.MiscellaneousUnitPrice;
      this.MiscellaneousItems.controls[i].get('MiscellaneousConsumed').patchValue(item.MiscellaneousConsumed == '' ? 0 : item.MiscellaneousConsumed);
      this.MiscellaneousItems.controls[i].get('MiscellaneousLineTotal').patchValue(item.MiscellaneousLineTotal);
    });
    this.updateTotalPrice();
  }

  updateOrderItemsData() {
    this.ManufactureOrderForm.get('Quantity').patchValue(this.ManufactureOrderForm.value.Quantity == '' ? 0 : this.ManufactureOrderForm.value.Quantity);
    let formulaQuantity = this.manufactureOrderProductionData.ManufactureFormula.Quantity;
    let productionQuantity = Number(this.ManufactureOrderForm.value.Quantity);
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.value.map((item, i) => {
      let formulaItem = this.manufactureOrderProductionData.ManufactureFormula.ManufactureFormulaDetails.filter(x => x.ItemId == item.ItemId)[0];
      let consume = ((formulaItem.Quantity/formulaQuantity) * productionQuantity).toFixed(2);
      let perviousConsume = ((formulaItem.Quantity/formulaQuantity) * this.manufactureOrderProductionData.ManufactureOrder.ProductionQuantity).toFixed(2);
      item.Consumed = consume + Number(item.BrokenItems);
      item.LineTotal = item.Consumed * item.UnitPrice;

      this.OrderItems.controls[i].get('ToConsume').patchValue(consume+'/'+perviousConsume);
      this.OrderItems.controls[i].get('UsedItems').patchValue(consume);
      this.OrderItems.controls[i].get('Consumed').patchValue(item.Consumed);
      this.OrderItems.controls[i].get('LineTotal').patchValue(item.LineTotal);
    });
    this.updateTotalPrice();
  }

  updateTotalPrice = () => {
    this.materialCost = 0.0;
    this.OrderItems = this.ManufactureOrderForm.get("OrderItems") as FormArray;
    this.OrderItems.value.map((x) => {
      this.materialCost = this.materialCost + x.LineTotal;
    });

    this.miscellaneousItemCost = 0.0;
    this.MiscellaneousItems = this.ManufactureOrderForm.get("MiscellaneousItems") as FormArray;
    this.MiscellaneousItems.value.map((y) => {
      this.miscellaneousItemCost = this.miscellaneousItemCost + y.MiscellaneousLineTotal;
    });

    this.operatingItemCost = 0.0;
    this.OperatingCostItems = this.ManufactureOrderForm.get("OperatingCostItems") as FormArray;
    this.OperatingCostItems.value.map((z) => {
      this.operatingItemCost = this.operatingItemCost + Number(z.OperatingCostAmount);
    });

    this.productionCost = this.materialCost + this.miscellaneousItemCost + this.operatingItemCost;

    let materialCostPerUnit = (this.materialCost / this.ManufactureOrderForm.value.Quantity).toFixed(2);
    let miscellaneousItemCostPerUnit = (this.miscellaneousItemCost / this.ManufactureOrderForm.value.Quantity).toFixed(2);
    let operatingItemCostPerUnit = (this.operatingItemCost / this.ManufactureOrderForm.value.Quantity).toFixed(2);
    let productionCostPerUnit = (this.productionCost / this.ManufactureOrderForm.value.Quantity).toFixed(2);

    this.ManufactureOrderForm.controls['MaterialCostPerUnit'].patchValue(materialCostPerUnit);
    this.ManufactureOrderForm.controls['MiscellaneousItemCostPerUnit'].patchValue(miscellaneousItemCostPerUnit);
    this.ManufactureOrderForm.controls['OperatingCostPerUnit'].patchValue(operatingItemCostPerUnit);
    this.ManufactureOrderForm.controls['ProductionCostPerUnit'].patchValue(productionCostPerUnit);

    this.ManufactureOrderForm.controls['TotalMaterialCost'].patchValue(this.materialCost);
    this.ManufactureOrderForm.controls['TotalMiscellaneousItemCost'].patchValue(this.miscellaneousItemCost);
    this.ManufactureOrderForm.controls['TotalOperatingCost'].patchValue(this.operatingItemCost);
    this.ManufactureOrderForm.controls['TotalProductionCost'].patchValue(this.productionCost);
  }

  addOperatingCostItems(): void {
    this.OperatingCostItems = this.ManufactureOrderForm.get('OperatingCostItems') as FormArray;
    this.OperatingCostItems.push(this.createOperatingCostItems());
  }

  onAddTag = (Name: string) => ({
    id: Name,
    Name: Name
  })

  addNewOperatingCost(event: any, i: number) {
    if(this.operationCostTypeList.filter(y => y.Name == event.Name).length == 0) {
      this.OperatingCostItems = this.ManufactureOrderForm.get('OperatingCostItems') as FormArray;
      const operationCostType: OperationCostType = {
        Id: 0,
        Name: event.Name,
        Price: this.OperatingCostItems.value[i].OperatingCostAmount,
        CompanyId: this.manufactureOrderProductionData.ManufactureOrder.CompanyId,
      }
      this.loading = true;

      this.manufactureService.addOperationCostType(operationCostType).subscribe((x) => {
        if(x.Success) {
          this.toaster.success(x.Message);
          this.loading = false;
          this.operationCostTypeList.push(x["Data"]);
          let operationCostTypeObj = this.operationCostTypeList.filter(x => x.Name == event.Name);
          this.selectedOperationCostType = operationCostTypeObj[0]['Id'];
          this.OperatingCostItems.value.map((x, index) => {
            if(index === i){
              this.OperatingCostItems.controls[i].get('OperatingCostId').patchValue(this.selectedOperationCostType);
            }
          });
          this.getOperationCostTypeList();
          this.spinner.hide();
        }
        else{
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
    }
  }

  createOperatingCostItems(): FormGroup {
    return this.fb.group({
      OperatingCostId: [null],
      OperatingCostAmount: [0]
    });
  }

  removeOperatingCostItems(i: number) {
    this.OperatingCostItems = this.ManufactureOrderForm.get('OperatingCostItems') as FormArray;
    this.OperatingCostItems.value.map((x, index) => {
      if(index === i){
        this.OperatingCostItems.removeAt(index);
      }
    });
  }

  produceManufactureOrder() {
    let data: ManufactureOrderViewModel = this.formatData();
    if(this.checkFormValidation(data)) {
      if(this.manufactureOrderProductionData.ManufactureOrder.ProductionQuantity > Number(this.ManufactureOrderForm.value.Quantity)){
        const modalRef = this.modalService.open(ManufactureBackOrderFlowTypeModalComponent, { size: "lg", backdrop: "static", keyboard: false, centered: true,});
        modalRef.componentInstance.manufactureOrderViewModel = data;
      }
      else{
        this.spinner.show();
        this.manufactureService.produceManufactureOrder(data).subscribe((x) => {
          if(x.Success) {
            this.toaster.success(x.Message);
            this.router.navigate(["manufacture/manufacutring-order-list/All"]);
            this.spinner.hide();
          }
          else{
            this.toaster.warning(x.Message);
            this.spinner.hide();
          }
        });
      }
    }
  }

  checkFormValidation(data: ManufactureOrderViewModel) {
    if(data.ManufactureOrder.ProductionQuantity == 0){
      this.toaster.warning('Quantity should not zero for production!');
      return false;
    }
    if(data.ManufactureOrder.ManufactureOrderDetails.every(item => item.TotalConsumed == 0)) {
      this.toaster.warning('All items should not have a zero value!');
      return;
    }
    const operatingCostItems = this.ManufactureOrderForm.get('OperatingCostItems').value;
    if(operatingCostItems.length != 0){
      const hasNoOperatingCostItems = operatingCostItems.some(item => item.OperatingCostId == null);
      if(hasNoOperatingCostItems) {
        this.toaster.warning('Please select operating cost!');
        return false;
      }
      const hasZeroOperatingCostAmount = operatingCostItems.some(item => item.OperatingCostAmount == 0);
      if(hasZeroOperatingCostAmount){
        this.toaster.warning('Operating cost should not zero for production!');
        return false;
      }
    }
    if(!this.hasStockAvailabile(data.ManufactureOrder.ManufactureOrderDetails)) {
      this.toaster.warning('Items stock is not available for the manufacture order produce!');
      return;
    }
    if(this.manufactureOrderProductionData.ManufactureOrder.ProductionQuantity < Number(this.ManufactureOrderForm.value.Quantity)) {
      this.toaster.warning('Production quantity can not larger than initial production quantity!');
      return;
    }
    if (!this.ManufactureOrderForm.valid) {
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    return true;
  }

  formatData(): ManufactureOrderViewModel {
    const manufactureOrderDetails: ManufactureOrderDetails[] = [];
    const processOrderItems = (items: any[]) => {
      items.forEach((element) => {
        let item;
        if(element.RawMaterialId != null){
          item = this.rawMaterialList.find((x) => x.ItemCode == element.RawMaterialId);
        }
        else{
          item = this.rawMaterialList.find((x) => x.ItemCode == element.MiscellaneousRawMaterialId);
        }
        if (item) {
          const orderDetails = new ManufactureOrderDetails();
          orderDetails.Id = element.ManufactureOrderDetailsId;
          orderDetails.ManufactureOrderId = this.manufactureOrderProductionData.ManufactureOrder.Id;
          orderDetails.ManufactureOrder = null;
          orderDetails.ItemId = item.Id;
          orderDetails.Item = item;
          orderDetails.UsedItem = element.RawMaterialId != null ? Number(element.UsedItems) : Number(element.MiscellaneousConsumed);
          orderDetails.BrokenItem = element.RawMaterialId != null ? Number(element.BrokenItems) : 0;
          orderDetails.TotalConsumed = element.RawMaterialId != null ? Number(element.Consumed) : Number(element.MiscellaneousConsumed);
          orderDetails.PricePerUnit = item.PurchasePrice;
          orderDetails.LineTotal = element.RawMaterialId != null ? (item.PurchasePrice * Number(element.Consumed)) : (item.PurchasePrice * Number(element.MiscellaneousConsumed));
          orderDetails.ItemType = element.RawMaterialId != null ? ManufactureItemType.NORMAL : ManufactureItemType.MISCELLANEOUS;
          manufactureOrderDetails.push(orderDetails);
        }
      });
    };
    processOrderItems(this.ManufactureOrderForm.value.OrderItems);
    processOrderItems(this.ManufactureOrderForm.value.MiscellaneousItems);
    
    const operatingCostItems: OperatingCostItem[] = [];
    const processOperatingCostItems = (items: any[]) => {
      items.forEach((element) => {
        let item = this.operationCostTypeList.find((x) => x.Id == element.OperatingCostId);
        if (item) {
          const operatingCostItem = new OperatingCostItem();
          operatingCostItem.Id = 0;
          operatingCostItem.ManufactureOrderId = this.manufactureOrderProductionData.ManufactureOrder.Id;
          operatingCostItem.ManufactureOrder = null;
          operatingCostItem.OperationCostTypeId = item.Id;
          operatingCostItem.OperationCostType = null;
          operatingCostItem.Amount = element.OperatingCostAmount;
          operatingCostItem.CompanyId = this.manufactureOrderProductionData.ManufactureOrder.CompanyId;
          operatingCostItems.push(operatingCostItem);
        }
      });
    };
    processOperatingCostItems(this.ManufactureOrderForm.value.OperatingCostItems);
    const itemTotalAmount = manufactureOrderDetails.reduce((acc, order) => acc + order.LineTotal, 0);
    const operationTotalAmount = this.ManufactureOrderForm.value.OperatingCostItems.reduce((acc, item) => acc + Number(item.OperatingCostAmount), 0);
    const finishedItem = this.manufactureOrderProductionData.Items.find(x => x.Id == this.manufactureOrderProductionData.ManufactureOrder.ItemId);
    const manufactureOrder: ManufactureOrder = {
      Id: this.manufactureOrderProductionData.ManufactureOrder.Id,
      ManufactureFormulaId: this.manufactureOrderProductionData.ManufactureOrder.ManufactureFormulaId,
      ManufactureFormula: this.manufactureOrderProductionData.ManufactureFormula,
      Reference: this.ManufactureOrderForm.value.Reference,
      ProductionQuantity: this.ManufactureOrderForm.value.Quantity,
      ParentId: this.manufactureOrderProductionData.ManufactureOrder.ParentId != null ? this.manufactureOrderProductionData.ManufactureOrder.ParentId : null,
      BatchNo: this.ManufactureOrderForm.value.BatchNo,
      ManufactureOrderNo: this.manufactureOrderProductionData.ManufactureOrder.ManufactureOrderNo,
      Status: 0,
      CompanyId: this.manufactureOrderProductionData.ManufactureOrder.CompanyId,
      ItemId: this.manufactureOrderProductionData.ManufactureOrder.ItemId,
      Item: finishedItem,
      PerUnitCost: (itemTotalAmount + operationTotalAmount)/Number(this.ManufactureOrderForm.value.Quantity),
      ItemTotalAmount: itemTotalAmount,
      OperationTotalAmount: operationTotalAmount,
      CreatedDate: this.ManufactureOrderForm.value.CreatedDate,
      StartDate: this.ManufactureOrderForm.value.StartDate,
      EndDate: this.ManufactureOrderForm.value.EndDate,
      CreatedBy: this.manufactureOrderProductionData.ManufactureOrder.CreatedBy,
      ManufactureOrderDetails: manufactureOrderDetails,
      OperatingCostItems: operatingCostItems,
    };

    const manufactureOrderViewModel: ManufactureOrderViewModel = {
      ManufactureOrder: manufactureOrder,
      ManufactureBackOrderFlowType: ManufactureBackOrderFlowType.PRODUCE_ONLY_MENTIONED,
      InitialProductionQuantity:   this.manufactureOrderProductionData.ManufactureOrder.ProductionQuantity,
      ManufactureBackOrderStatus: this.getManufactureBackOrderStatus()
    }

    return manufactureOrderViewModel;
  }

  getManufactureBackOrderStatus() {
    let status = ManufactureOrderStatus.TO_BE_PROCESSED;
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;

    let initialProductionQuantity = this.manufactureOrderProductionData.ManufactureOrder.ProductionQuantity;
    let currentProductionQuantity = this.ManufactureOrderForm.value.Quantity;
    let backOrderProductionQuantity = initialProductionQuantity - currentProductionQuantity;

    let formula =  this.manufactureOrderProductionData.ManufactureFormula;
    let formulaQuantity = formula.Quantity;

    for(let item of this.OrderItems.value) {
      let formulaItemQuantity = formula.ManufactureFormulaDetails.find(x => x.ItemId == item.ItemId).Quantity;
      let scaledFormulaItemQuantity = (backOrderProductionQuantity/formulaQuantity) * formulaItemQuantity;

      let currentStockQuantity = this.itemMap.get(item.ItemId).StockQuantity - item.Consumed;
      if(currentStockQuantity < scaledFormulaItemQuantity) {
        return ManufactureOrderStatus.WAITING_AFTER_APPROVED;
      }
    }

    return status;
  }

  showSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.CreatedDate;
    $('#createdDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showStartSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.StartDate;
    $('#startDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showEndSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.EndDate;
    $('#endDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  canShowOrderItemsStockIcon(item: any, i: number) {
    let stockQuantity = this.itemMap?.get(item.value.ItemId).StockQuantity;
    let consumed = item.value.Consumed;
    return consumed <= stockQuantity  ? true : false;
  }

  canShowMiscellaneousItemsStockIcon(item: any, i: number) {
    let stockQuantity = this.itemMap?.get(item.value.ItemId).StockQuantity;
    let consumed = item.value.MiscellaneousConsumed;
    return consumed <= stockQuantity  ? true : false;
  }

  canShowMiscellaneousItem() {
    let hasMiscellaneousItem = this.manufactureOrderProductionData?.ManufactureOrder?.ManufactureOrderDetails?.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).length != 0;
    return hasMiscellaneousItem;
  }

  hasStockAvailabile(ManufactureOrderDetails: ManufactureOrderDetails[]) {
    let counter = 0;
    ManufactureOrderDetails.forEach((element) => {
      let stockQuantity = this.itemMap.get(element.ItemId).StockQuantity;
      if(element.TotalConsumed > stockQuantity){
        counter = counter + 1;
      }
    });
    return counter == 0 ? true : false;
  } 

  setMainTitle() {
    let status = this.formatStatus();
    let mainTitle = 'MO-'+this.manufactureOrderProductionData?.ManufactureOrder?.ManufactureOrderNo+' / '+status;
    return mainTitle;
  }

  formatStatus() {
    if(this.manufactureOrderProductionData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING) {
      return 'Draft';
    }
    else if(this.manufactureOrderProductionData?.ManufactureOrder?.Status == ManufactureOrderStatus.WAITING_AFTER_APPROVED) {
      return 'Waiting';
    }
    else if(this.manufactureOrderProductionData?.ManufactureOrder?.Status == ManufactureOrderStatus.TO_BE_PROCESSED) {
      return 'To Process';
    }
    else if(this.manufactureOrderProductionData?.ManufactureOrder?.Status == ManufactureOrderStatus.PROCESSED) {
      return 'Produced';
    }
  }

}
