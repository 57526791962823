export class DocumentAdvanceSearchViewModel {
    FromDate: Date;
    ToDate: Date;
    selectedTags: number[];
    Reference1?: string;
    Reference2?: string;
    EmployeeId?: string;
    StatusList: number[];
    DocumentTypeId: number;
    IsCompanyHasOnlyDocumentFeature: boolean;
}