import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class DataService {
  isItemListUpdate = false; 
  isShipmentListUpdate = false; 
  isCheckDefaultSetting = false;
  isOrganizationUpdate = false;
  isFixedAssetItemUpdate = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() checkDefault: EventEmitter<boolean> = new EventEmitter();
  @Output() organizationUpdate: EventEmitter<boolean> = new EventEmitter();
  @Output() shipmentListUpdate: EventEmitter<boolean> = new EventEmitter();
  @Output() changeSelectedOrganization: EventEmitter<boolean> = new EventEmitter();
  @Output() fixedAssetItemUpdate: EventEmitter<boolean> = new EventEmitter();
  @Output() messageUpdate: EventEmitter<any> = new EventEmitter();
  @Output() setMessageUpdate: EventEmitter<any> = new EventEmitter();
  @Output() setsidebarUpdate: EventEmitter<any> = new EventEmitter();
  @Output() sidebarUpdate: EventEmitter<any> = new EventEmitter();
  @Output() sidebarHide: EventEmitter<any> = new EventEmitter();
  @Output() updateLastStep: EventEmitter<any> = new EventEmitter();
  @Output() setWarningPopup: EventEmitter<any> = new EventEmitter();
  constructor() {}

   emitItemListUpdate(values : boolean) {
    this.isItemListUpdate = values;
    this.change.emit(this.isItemListUpdate);
  }
  emitShipmentListUpdate(values : boolean) {
    this.isShipmentListUpdate = values;
    this.shipmentListUpdate.emit(this.isShipmentListUpdate);
  }
  emitDefaultNotificationUpdate(value: boolean){
    this.isCheckDefaultSetting = value;
    this.checkDefault.emit(this.isCheckDefaultSetting);
  }

  emitOrganizationUpdate(value:boolean){
    this.isOrganizationUpdate = value;
    this.organizationUpdate.emit(this.isOrganizationUpdate);
  }

  emitSelectedOrganizationChanged(value:boolean){
    this.changeSelectedOrganization.emit(value);
  }

  emitFixedAssetItemUpdate(value:boolean){
    this.isFixedAssetItemUpdate = value;
    this.fixedAssetItemUpdate.emit(this.isFixedAssetItemUpdate);
  }

  emitSetMessageUpdate(data:any){
    this.setMessageUpdate.emit(data);
  }
  emitMessageUpdate(v:boolean){
    this.messageUpdate.emit(v);
  }
  emitsetSidebarUpdate(v: boolean){
    this.setsidebarUpdate.emit(v);
  }
  emitSidebarUpdate(v: boolean){
    this.sidebarUpdate.emit(v);
  }
  emitSidebarHide(v: boolean){
    this.sidebarHide.emit(v);
  }
  emitUpdateLastStep(v: any)
  {
    this.updateLastStep.emit(v);
  }
  emitSetWarningPopup(v: any)
  {
    this.setWarningPopup.emit(v);
  }
}
