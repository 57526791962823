import { Component, OnInit } from '@angular/core';
import { MainServiceService } from '../../service/Main/main-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RejectDocumentModalComponent } from '../Document/reject-document-modal/reject-document-modal.component';
import { DocumentServiceService } from 'src/app/service/DocumentService/document-service.service';
import { CellClickedEvent } from 'ag-grid-community';
import { DocumentInfoModalComponent } from '../Document/document-info-modal/document-info-modal.component';
import { environment } from 'src/environments/environment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { faCheckSquare, faBan, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OperationType } from 'src/app/enum/operation-type';
import { ApprovalHistoryModalService } from 'src/app/service/Approval History Modal/approval-history-modal.service';
import { ApprovalHistoryModalComponent } from 'src/app/components/approval-history-modal/approval-history-modal/approval-history-modal.component';
import { RejectPopupModalComponent } from '../Reject-popup-modal/reject-popup-modal/reject-popup-modal.component';
import { IncomeServiceService } from 'src/app/service/Income/income-service.service';
import { BillsServiceService } from 'src/app/service/Bill/bills-service.service';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-pending-list-dashboard',
  templateUrl: './pending-list-dashboard.component.html',
  styleUrls: ['./pending-list-dashboard.component.css']
})

export class PendingListDashboardComponent implements OnInit {
  approvalUserData: any;
  finalUserLevel: any;
  hideData: any = true;
  InvoiceData: any = [];
  BillData: any = [];
  faCheckSquare = faCheckSquare;
  faBan = faBan;
  faInfoCircle = faInfoCircle;
  OperationType: OperationType;
  fileName: string;
  domLayout = 'autoHeight';
  rowSelection = 'multiple';
  rowSelection2 = 'single';
  rowHeight = 275;
  gridApi;
  billGridApi;
  invoiceGridApi;
  purchaseOrderGridApi;
  documentGridApi;
  canApprove = true;
  data: any[];
  searchText: string;
  rotationLeftAmount: number;
  rotationRightAmount: number;
  rotationAmount: number;
  isPdf: boolean;
  imagezooming: number;
  fileUrl = environment.fileBaseUrl;
  zoom: number = 1;
  url: string = "";
  invoiceCount: number = 0;
  billCount: number = 0;
  purchaseOrderCount: number = 0;
  documentCount: number = 0;
  pageType: number;
  pendingPurchaseOrders: any[];
  pendingInvoices: any[];
  pendingBills: any[];
  pendingDocuments: any[];
  selected: any;
  src: string;

  constructor(
    private mainService: MainServiceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private documentService: DocumentServiceService,
    private ngxSmartModalService: NgxSmartModalService,
    private approvalHistoryModalService: ApprovalHistoryModalService,
    private incomeService: IncomeServiceService,
    private billService: BillsServiceService,
    public authService: AuthService) {
  }

  async ngOnInit(): Promise<void> {
    this.url = location.origin + "/#/";
    if(!this.IsOnlyHasDocumentFeature()){
      this.getPendingBills();
      this.getPendingInvoices();
      this.getPendingPurchaseOrders();
    }
    this.getPendingDocument();
  }

  getPendingInvoices() {
    this.spinner.show();
    this.mainService.GetPendingInvoices().subscribe((x) => {
      this.pendingInvoices = x.data;
      for (let i = 0; i < this.pendingInvoices.length; i++) {
        this.pendingInvoices[i].Number = 'INV-' + this.pendingInvoices[i].Number;
      }
      this.canApprove = x.canApprove;
      this.invoiceCount = x.data.length;
      if (this.invoiceCount == 0) {
        this.canApprove = false;
      }
      this.spinner.hide();
    });
  }

  getPendingBills() {
    this.spinner.show();
    this.mainService.GetPendingBills().subscribe((x) => {
      this.pendingBills = x.data;
      for (let i = 0; i < this.pendingBills.length; i++) {
        this.pendingBills[i].Number = 'Bill-' + this.pendingBills[i].Number;
      }
      this.canApprove = x.canApprove;
      this.billCount = x.data.length;
      if (this.billCount == 0) {
        this.canApprove = false;
      }
      this.spinner.hide();
    });
  }

  getPendingPurchaseOrders() {
    this.spinner.show();
    this.mainService.GetPendingPurchaseOrders().subscribe((x) => {
      this.pendingPurchaseOrders = x;
      for (let i = 0; i < this.pendingPurchaseOrders.length; i++) {
        this.pendingPurchaseOrders[i].Number = 'PO-' + this.pendingPurchaseOrders[i].Number;
      }
      this.purchaseOrderCount = x?.length;
      //current there is no approval flow for purchase order, also the api is not returning canApprove data.
      if (this.purchaseOrderCount == 0) {
        this.canApprove = false;
      } else {
        this.canApprove = true;
      }
      this.spinner.hide();
    });
  }

  getPendingDocument() {
    this.spinner.show();
    this.mainService.getPendingDocument().subscribe((x) => {
      this.pendingDocuments = x.data;
      this.documentCount = x.data.length;
      this.canApprove = x.canApprove;
      this.approvalUserData = x.approvalUser;
      this.finalUserLevel = x.finalLevel;
      if (this.canApprove == true) this.hideData = false;
      if (this.documentCount == 0) {
        this.canApprove = false;
      }
      this.spinner.hide();
    });
  }

  approveBills() {
    const selectedNodes = this.billGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    this.spinner.show();
    this.mainService.ApproveBill(selectedData).subscribe((x) => {
      this.toastr.success('Bill successfully approved.');
      this.getPendingBills();
      this.spinner.hide();
    });
  }

  rejectBill() {
    const selectedNodes = this.billGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    if(selectedData.length > 1){
      this.toastr.warning('select only one item');
      return;
    }
    this.spinner.show();
    this.billService.getSingleBillDetails(selectedData[0].Id).subscribe((x) => {
      if (x) {
        this.spinner.hide();
        this.BillData = x;
        const modalRef = this.modalService.open(RejectPopupModalComponent, { size: "md", backdrop: "static", keyboard: false, centered: true });
        modalRef.componentInstance.data = this.BillData;
        modalRef.componentInstance.TypeId = 1;
        modalRef.componentInstance.isProcessDone.subscribe( ($event) => {
          if( $event == 'Success' ){
            this.getPendingBills();
          }
        });
      }
    })
  }

  approveInvoices() {
    const selectedNodes = this.invoiceGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    this.spinner.show();
    this.mainService.ApproveInvoice(selectedData).subscribe((x) => {
      this.toastr.success('Invoice successfully approved.');
      this.getPendingInvoices();
      this.spinner.hide();
    });
  }

  rejectInvoice() {
    const selectedNodes = this.invoiceGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    if(selectedData.length > 1){
      this.toastr.warning('select only one item');
      return;
    }
    this.spinner.show();
    this.incomeService.getSingleInvoiceDetails(selectedData[0].Id).subscribe((x) => {
      if (x) {
        this.spinner.hide();
        this.InvoiceData = x;
        const modalRef = this.modalService.open(RejectPopupModalComponent, { size: "md", backdrop: "static", keyboard: false, centered: true });
        modalRef.componentInstance.data = this.InvoiceData;
        modalRef.componentInstance.TypeId = 2;
        modalRef.componentInstance.isProcessDone.subscribe( ($event) => {
          if( $event == 'Success' ){
            this.getPendingInvoices();
          }
        });
      }
      
    })
  }

  approvePurchaseOrders() {
    const selectedNodes = this.purchaseOrderGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    this.spinner.show();
    this.mainService.ApprovePurchaseData(selectedData).subscribe((x) => {
      this.toastr.success('Purchase Order successfully approved.');
      this.getPendingPurchaseOrders();
      this.spinner.hide();
    });
  }

  approveDocument() {
    const selectedNodes = this.documentGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    if(selectedData.length > 1){
      this.toastr.warning('select only one item');
      return;
    }
    var data = {
      dataType: 'approve',
      selectedData: selectedData,
      approvalData: this.approvalUserData,
      finalUserLevel: this.finalUserLevel,
    }
    this.documentService.saveRejectDocumentModalData(data);
    const modalRef = this.modalService.open(RejectDocumentModalComponent, { size: 'lg', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.fromParent = 1;
    modalRef.result.then((result) => {
      if (result == 'Success') {
        this.getPendingDocument();
      }
    }, (reason) => {});
  }

  rejectDocument() {
    const selectedNodes = this.documentGridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('select at least one item');
      return;
    }
    if(selectedData.length > 1){
      this.toastr.warning('select only one item');
      return;
    }
    var data = {
      dataType: 'reject',
      selectedData: selectedData,
      approvalData: this.approvalUserData,
      finalUserLevel: this.finalUserLevel,
    }
    this.documentService.saveRejectDocumentModalData(data);
    const modalRef = this.modalService.open(RejectDocumentModalComponent, { size: 'lg', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.fromParent = 1;
    modalRef.result.then((result) => {
      if (result == 'Success') {
        this.getPendingDocument();
      }
    }, (reason) => {});
  }

  billColumnDefs = [
    {
      headerName: 'Number', field: 'Number',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellRenderer: function (params) {
        return `<a href="#expense/bill/${params.value}" >${params.value}</a>`;
      },
    },
    { headerName: 'Due Date', field: 'DueDate', valueFormatter: this.convertGridDateIntoDisplay, maxWidth: 130 },
    { headerName: 'Amount', field: 'DueAmount', maxWidth: 120 },
    {
      headerName: 'History', onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data, 'Bill', OperationType.BILL), maxWidth: 95,
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mt-2"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      }
    }
  ];

  invoiceColumnDefs = [
    {
      headerName: 'Number', field: 'Number',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellRenderer: function (params) {
        return `<a href="#income/invoice/${params.value}" >${params.value}</a>`;
      },
    },
    { headerName: 'Due Date', field: 'DueDate', valueFormatter: this.convertGridDateIntoDisplay, maxWidth: 130 },
    { headerName: 'Amount', field: 'DueAmount', maxWidth: 120 },
    {
      headerName: 'History', onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data, 'Invoice', OperationType.INVOICE), maxWidth: 95,
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mt-2"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      }
    }
  ];

  purchaseOrderColumnDefs = [
    {
      headerName: 'Number', field: 'Number',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellRenderer: function (params) {
        return `<a href="#procurement/purchase-order-details/${params.data.Id}" >${params.value}</a>`;
      },
    },
    { headerName: 'Date', field: 'Date', valueFormatter: this.convertGridDateIntoDisplay, maxWidth: 130 },
    { headerName: 'Due Date', field: 'DueDate', valueFormatter: this.convertGridDateIntoDisplay, maxWidth: 130 },
    { headerName: 'Amount', field: 'DueAmount', maxWidth: 120 }
  ];

  documentColumnDefs = [
    {
      headerName: 'Document Name', field: 'DocumentName', onCellClicked: (event: CellClickedEvent) => this.view(event),
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true, cellStyle: { 'color': 'blue', 'text-decoration': 'underline', 'cursor': 'pointer' }
    },
    { headerName: 'Uploaded At', field: 'UploadedAt', valueFormatter: this.convertGridDateIntoDisplay, maxWidth: 250 },
    // { headerName: 'Tag', field: 'DocumentType', maxWidth: 120 },
    {
      headerName: 'History', field: 'information', maxWidth: 120,
      onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data, 'Document', OperationType.DOCUMENT),
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="mt-2"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      },
    },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1
  };

  convertGridDateIntoDisplay(date) {
    if (date.value == null)
      return '';
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  onCellClicked(e) {}

  onGridReady(event) {
    this.gridApi = event.api;
  }

  onBillGridReady(event) {
    this.billGridApi = event.api;
  }

  onInvoiceGridReady(event) {
    this.invoiceGridApi = event.api;
  }

  onPurchaseOrderGridReady(event) {
    this.purchaseOrderGridApi = event.api;
  }

  onDocumentGridReady(event) {
    this.documentGridApi = event.api;
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }

  convetToPDF = () => {
    var data = document.getElementById('pl');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      var DocumentName = `Pending list.pdf)`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    });
  }

  modalMethod(event: any) {
    this.spinner.show();
    this.documentService.saveModalInfoData(event.data);
    this.documentService.setSeeFullHistory(true);
    const modalRef = this.modalService.open(DocumentInfoModalComponent,{ size: 'xl', backdrop: 'static', keyboard: false, centered: true }) 
  }

  isPdfFile(file: any) {
    let ext = file.BlobName.split(".")[1];
    return ext == 'pdf' ? true : false;
  }

  view(event: any) {
    let file = event.data;
    this.selected = file;
    this.rotationRightAmount = 0;
    this.rotationLeftAmount = 0;
    let ext = file.DocumentBlobName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.DocumentBlobName;
    this.imagezooming = 100;
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
    let img = document.getElementById('image');
    img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
  }

  convertDateToDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }


  print(file: any, id: any, pdf: boolean) {
    window.open(`${this.url}files/print/${file.Id}`, '_blank');
  }

  download() {
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
  }

  zoomOut() {
    if (this.isPdf) {
      this.zoom -= .1;
    } else {
      this.imagezooming -= 10;
      let element = document.getElementById("image")
      element.style.width = this.imagezooming + "%";
    }
  }

  zoomIn() {
    if (this.isPdf) {
      this.zoom += .1;
    } else {
      this.imagezooming += 10;
      let element = document.getElementById("image");
      element.style.width = this.imagezooming + "%";
    }
  }

  rotate(direction: string) {
    this.rotationAmount = +90;
    if (direction == 'left') {
      this.rotationRightAmount -= 90;
      this.rotationLeftAmount += 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    } else {
      this.rotationRightAmount += 90;
      this.rotationLeftAmount -= 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }
  }

  modalViewFullHistory(data:any, fileName: string, operationType: OperationType){
    this.spinner.show();
    let modalData = {
      ActionNo: data.Number,
      CreatedDate: data.Date,
      DueDate: data.DueDate,
      Number: operationType == OperationType.DOCUMENT ? data.Number : data.Id,
      CustomerName: data.ToName
    }
    this.approvalHistoryModalService.saveModalInfoData(modalData);
    const modalRef = this.modalService.open(ApprovalHistoryModalComponent, { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.action = fileName; //Important! Bill, Invoice, Purchase Order etc not actual file name
    modalRef.componentInstance.OperationType = operationType;
  }

  IsOnlyHasDocumentFeature() {
    const userInfo =this.authService.getUserInfo();
    if(userInfo?.IsCompanyHasOnlyDocumentFeature)
      return true;
    else
      return false;
  }

  IsServiceCompany() {
    const userInfo =this.authService.getUserInfo();
    if(userInfo?.CompanyBusinessTypeId == 1)
      return true;
    else
      return false;
  }
}

