<app-dashboard>
    <app-page-header-title 
        mainTitle="Assignee List" 
        subTitle="Home" 
        backURL="/">
        <div class="full-width text-align-right">
            <button type="button" class="eCount-def-btn ml-4" (click)=" AddAssignee()">Add Assignee</button>
            <button type="button" class="eCount-def-btn ml-4" (click)="AssignItem()">Assign Item</button>
            <button type="button" class="eCount-def-btn ml-4" (click)="TransferAssignedItem()">Transfer To New Assignee</button>
            <button type="button" class="eCount-def-btn ml-4" (click)="openReturnAssignedItemModal()">Return</button>
            <button type="button" class="eCount-button3 ml-4" (click)="onBtnExport()">Export</button>
        </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('All')" 
                [routerLinkActive]="['active']" 
                routerLink="/fixed-asset/assignee-list/All">
                    All
                </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Assigned')" 
                [routerLinkActive]="['active']" 
                routerLink="/fixed-asset/assignee-list/Assigned">
                    Assigned
                </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Unassigned')" 
                [routerLinkActive]="['active']" 
                routerLink="/fixed-asset/assignee-list/Unassigned">
                    Unassigned
                </a>
        </li>
    </ul>
    <div class="headSection">
        <div class="row" *ngIf="status == 'All'">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col" 
                [rowData]="AllItems" 
                [columnDefs]="allColumnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef" 
                [pagination]="true" 
                [paginationPageSize]="50" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>

        <div class="row" *ngIf="status == 'Assigned'">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col" 
                [rowData]="AssignedItems" 
                [columnDefs]="assignedColumnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef" 
                [pagination]="true" 
                [paginationPageSize]="50" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>

        <div class="row" *ngIf="status == 'Unassigned'">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col" 
                [rowData]="UnassignedItems" 
                [columnDefs]="unassignedColumnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef" 
                [pagination]="true" 
                [paginationPageSize]="50" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</app-dashboard>