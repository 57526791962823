import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccountTypes, AddChartOfAccount } from './../../models/accounting.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResponseModel } from 'src/app/models/responseModel.model';
import { PaymentHistory } from 'src/app/models/paymentHistory.model';

@Injectable({
  providedIn: 'root'
})
export class AcountingServiceService {
  ChartOfAccounts : AddChartOfAccount[] = [];
  baseurl = environment.baseUrl;
 
  constructor(private http: HttpClient) { }

  //UpdateLocal Data
  public updateLocalChartOfAccount(data: AddChartOfAccount[]){
    this.ChartOfAccounts = data
  }

  // GetAccountTypes
  public GetAccountTypes(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'ChartOfAccount/GetAccountTypes')
      .pipe(
        retry(1)
      )
  }
  public GetChartOfAccounts(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'ChartOfAccount/GetChartOfAccounts')
      .pipe(
        retry(1)
      )
  }
  public GetBankAccounts(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'BankAccount/GetBankAccounts')
      .pipe(
        retry(1)
      )
  }
  public GetChartOfAccountsforItems(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'ChartOfAccount/GetChartOfAccountsforItems')
      .pipe(
        retry(1)
      )
  }
  public GetChartOfAccountsforPo(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'ChartOfAccount/GetChartOfAccountsforPo')
      .pipe(
        retry(1)
      )
  }
  public GetChartOfAccountsList(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'ChartOfAccount/GetChartOfAccountsList')
      .pipe(
        retry(1)
      )
  }
  public AddChartOfAccount(data): Observable <any>{
    return this.http.post<AddChartOfAccount>(this.baseurl + 'ChartOfAccount/SaveChartOfAccount',data)
      .pipe(
        retry(1)
      )
  }

  public GetChartOfAccountById(Id:number){
    return this.http.get(this.baseurl +`ChartOfAccount/GetChartOfAccountById?Id=${Id}`)
    .pipe(
      retry(1)
    )
  }

  public DeleteAccounts(deletableAccounts: any): Observable <ResponseModel>{
    return this.http.post<ResponseModel>(this.baseurl + 'ChartOfAccount/DeleteAccounts', deletableAccounts);
  }

  public getPaymentList(): Observable<any[]> {
    const url = this.baseurl +'PaymentHistory/GetPaymentHistoryDetails';
    return this.http.get<any[]>(url);
  }

  public DeletedPaymentHistory(paymentHistories: any[]): Observable <ResponseModel>{
    return this.http.post<ResponseModel>(this.baseurl + 'PaymentHistory/DeletedPaymentHistory', paymentHistories);
  }
}
