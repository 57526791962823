export class createProduct{
    ItemCode: string;
    ItemName: string;
    IsSaleItem: boolean;
    SaleUnitPrice: number;
    SaleAccountId: number;
    IsPurchaseItem: boolean;
}


export class CreateItem {
    Id: number;
    ItemCode: string;
    ItemName: string;
    PurchasePrice: number;
    SaleUnitPrice: number;
    ItemTypeId: number;
    Description: string;
}