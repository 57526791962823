import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { customer } from './../../models/customer.model';
import { createInvoice, InvoiceItem } from '../../models/income.model'
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IncomeServiceService {
  baseurl = environment.baseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private http: HttpClient) { }

  httpAttachmentOptions: any = {
    reportProgress: true, observe: 'events', headers: new HttpHeaders({
      'Authorization': `Bearer ${this.userInfo ? this.userInfo.Token : null}`
    })
  }

  //getCustomer Details
  GetAllCustomerDetails(): Observable<customer[]> {
    return this.http.get<any>(this.baseurl + 'Customer/GetCustomers')
      .pipe(
        retry(1)
      )
  }

  //get saleable Item
  GetAllSaleableItem(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/getsaleableitems')
      .pipe(
        retry(1)
      )
  }

    //get saleable Item Details
    getSaleableItemDetails(): Observable<any> {
      return this.http.get<any>(this.baseurl + 'item/GetSaleableItemDetails').pipe(retry(1))
    }

  //save create invoice
  saveInvoiceData(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/Save', data)
      .pipe(
        retry(1)
      )
  }

  saveInvoiceDataForSalesOrder(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/SaveForSalesOrder', data)
      .pipe(
        retry(1)
      )
  }

  //update approved invoice
  updateApproveInvoiceData(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/UpdateApprove', data)
      .pipe(
        retry(1)
      )
  }

  updateApprovedData(data): Observable<any>{
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/UpdateApprovedInvoice', data)
      .pipe(
        retry(1)
      )
  }

  GetIsUserAtMaxApprovalLevel(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Invoice/IsUserAtMaxApprovalLevel').pipe(retry(1));
  }

  getCustomerBalance(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/GetCustomerBalance', data)
      .pipe(
        retry(1)
      )
  }

  //save as draft create invoice
  saveAsDraftInvoiceData(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/SaveAsDraftInvoice', data)
      .pipe(
        retry(1)
      )
  }
  //update as draft create invoice
  updateAsDraftInvoiceData(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/UpdateAsDraftInvoice', data)
      .pipe(
        retry(1)
      )
  }

  //Create ConverionBalance Invoice Data
  saveConverionInvoiceData(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'ConversionBalanceInvoice/Save', data)
      .pipe(
        retry(1)
      )
  }


  //Invoice
  GetInvoiceList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Invoice/GetInvoiceList')
      .pipe(
        retry(1)
      )
  }
  GenerateInvoiceNo(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Invoice/GenerateInvoiceNo')
      .pipe(
        retry(1)
      )
  }

  //single bill details
  getSingleInvoiceDetails(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/GetInvoice?id=${data}`)
      .pipe(
        retry(1)
      )
  }
  //single bill details
  getInvoiceByInvoiceNo(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/GetInvoiceByInvoiceNo?invoiceNo=${data}`)
      .pipe(
        retry(1)
      )
  }
  //single invoice with permission details
  getInvoicePermissionByInvoiceNo(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/GetInvoicePermissionByInvoiceNo?invoiceNo=${data}`)
      .pipe(
        retry(1)
      )
  }

  GetInvoicePermissionByInvoiceNoForInvoiceOverview(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/GetInvoicePermissionByInvoiceNoForInvoiceOverview?invoiceNo=${data}`)
      .pipe(
        retry(1)
      )
  }

  //save create invoice
  saveAcceptPayment(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'InvoicePaymentReceive/Save', data)
      .pipe(
        retry(1)
      )
  }
  // save delivery partner invoice payment
  savePayment(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'InvoicePaymentReceive/SaveDeliveryPartnerPayment', data)
      .pipe(
        retry(1)
      )
  }
  //InvoicePaymentReceive/GetListByInvoiceId?invoiceId=
  GetInvoicePaymentList(invoiceId): Observable<any> {
    return this.http.get<any>(this.baseurl + `InvoicePaymentReceive/GetListByInvoiceId?invoiceId=${invoiceId}`)
      .pipe(
        retry(1)
      );
  }
  // reject invoice
  rejectInvoiceData(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Invoice/RejectInvoice', data).pipe(retry(1));
  }
  //get all customer
  GetAllContact(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Contact/GetAllCustomer')
      .pipe(
        retry(1)
      )
  }
  GetAllContactWithDeliveryPartner(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Contact/GetAllCustomerWithDeliveryPartner')
      .pipe(
        retry(1)
      );
  }
  GetAllDeliveryPartner(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Contact/GetAllDeliveryPartner')
      .pipe(
        retry(1)
      );
  }
  GetAllAttachmentById(id: number) {
    return this.http.get<any>(this.baseurl + "Invoice/GetInvoiceFiles?invoiceId=" + id).pipe(retry(1));
  }
  DeleteAttachmentById(id: number) {
    return this.http.get(this.baseurl + "Invoice/DeleteFile?id=" + id).pipe(retry(1));
  }
  UploadAttachment(fff: FormData, id: number) {
    return this.http.post(this.baseurl + "Invoice/UploadInvoiceFiles?invoiceId=" + id, fff, this.httpAttachmentOptions).pipe(retry(1));
  }

  public SendMail(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Invoice/SendMail', data).pipe(retry(1));
  }

  public GetMailDetails(id: any): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Invoice/GetMailDetails?id=' + id).pipe(retry(1));
  }
  public SendMailWithAttachment(data: any): Observable<any> {
    return this.http.post(this.baseurl + 'Invoice/SendMailWithAttachment', data, this.httpAttachmentOptions).pipe(
      retry(1)
    );
  }
  SubmitDiscountAmount(invoiceId, amount): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/UpdateDiscountAmount?id=${invoiceId}&amount=${amount}`)
      .pipe(
        retry(1)
      );
  }
  deleteInvoice(invoiceId): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/DeleteInvoice?id=${invoiceId}`)
      .pipe(
        retry(1)
      );
  }

  deleteInvoiceForSalesOrder(invoiceId): Observable<any> {
    return this.http.get<any>(this.baseurl + `Invoice/DeleteInvoiceForSalesOrder?id=${invoiceId}`)
      .pipe(
        retry(1)
      );
  }

  public GetRepeatingInvoices(): Observable<any> {
    return this.http.get(this.baseurl + 'BasicInvoice/GetRepeatingInvoice').pipe(retry(1));
  }
  public CreateInvoiceFromRepeating(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BasicInvoice/CreateInvoiceFromRepeating', data).pipe(retry(1));
  }

  public GetMaxInvoiceId(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Invoice/GetMaxInvoiceId').pipe(retry(1));
  }

  public ApproveSingleInvoice(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/ApproveSingleInvoice', data)
      .pipe(
        retry(1)
      )
  }

  public SubmitInvoices(invoiceIds: number[]): Observable<any> {
    return this.http.post<any>(this.baseurl + "Invoice/SubmitInvoices", invoiceIds);
  }

  public ApproveInvoices(invoiceIds: number[]): Observable<any> {
    return this.http.post<any>(this.baseurl + "Invoice/ApproveInvoices", invoiceIds);
  }

  
  Create(invoice): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Invoice/Create', invoice);
  }

  StatusUpdate(invoice): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'Invoice/StatusUpdate', invoice);
  }

  Update(invoice): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Invoice/Update', invoice);
  }

  ResetToDraft(invoice): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Invoice/ResetToDraft', invoice);
  }

  ReversePayment(data): Observable<any> {
    return this.http.post<createInvoice>(this.baseurl + 'InvoicePaymentReceive/ReversePayment', data);
  }

  GetInvoicePaymentReturnList(invoiceId): Observable<any> {
    return this.http.get<any>(this.baseurl + `InvoicePaymentReceive/GetReturnedListByInvoiceId?invoiceId=${invoiceId}`).pipe(retry(1));
  }

}
