import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as html2canvas from "html2canvas";
import jspdf from "jspdf";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { RouterLinkRendereComponent } from "../../../components/router-link-rendere/router-link-rendere.component";
import { IncomeServiceService } from "../../../service/Income/income-service.service";
import { CellClickedEvent } from 'ag-grid-community';
import { OperationType } from 'src/app/enum/operation-type';
import { ApprovalHistoryModalService } from 'src/app/service/Approval History Modal/approval-history-modal.service';
import { ApprovalHistoryModalComponent } from 'src/app/components/approval-history-modal/approval-history-modal/approval-history-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: "app-invoice-overview",
  templateUrl: "./invoice-overview.component.html",
  styleUrls: ["./invoice-overview.component.css"],
})
export class InvoiceOverviewComponent implements OnInit {
  allInvoice: any = [];
  filteredInvoice: any = [];
  searchText: string;
  gridApi;
  status = 'All';
  rejectCount = 0;
  allCount: number = 0;
  paidCount: number = 0;
  awaitingCount: number = 0;
  submitCount = 0;
  draftCount: number = 0;
  domLayout = "autoHeight";
  isReceivable: boolean;
  contactId: number;
  totalInvoiceAmount: number;
  totalDueAmount: number;
  overdueCount = 0;
  totalDiscountAmount: number;
  fileName = 'Invoice';
  OperationType = OperationType.INVOICE;
  invoiceStatus: any = {
    "All": 0,
    "draft": 1,
    "Awaiting-approval": 2,
    "Awaiting-payment": 3,
    "Paid": 4,
    "Repeating": 5,
    "Overdue-payment": 6,
    "rejected": 7
  };

  rowSelection = 'multiple';
  rowHeight = 275;

  constructor(
    private incomeService: IncomeServiceService,
    private router: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private approvalHistoryModalService: ApprovalHistoryModalService, 
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.status = this.router.snapshot.paramMap.get("status");

    this.router.queryParams.subscribe((params) => {
      this.isReceivable = params.IsReceivable == undefined ? false : true;
      if (this.isReceivable) {
        this.contactId = params.contactId;
      }
    });
    this.dataService.changeSelectedOrganization.subscribe((x) => {
      this.getInvoiceList();
    });
    this.getInvoiceList();
  }

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
    scrollbarWidth: 0,
    resizable: true
  }

  columnDefs = [
    { headerName: 'Serial No.', field: 'SerialNo', maxWidth: 96.5 },
    {
      headerName: "Invoice No",
      field: "InvoicceNo",
      minWidth: 140,
      cellRendererFramework: RouterLinkRendereComponent,
      cellRendererParams: { inRouterLink: "/income/invoice/" }
    },
    { headerName: "Ref", field: "ReferenceNo", width: 110 },
    { headerName: "To", field: "CompanyName", width: 90 },
    { headerName: "Due Date", field: "DueDate", width: 110 },
    {
      headerName: "Invoice Amount",
      field: "InvoiceAmount",
      valueFormatter: this.InvoiceAmountFormatter,
      minwidth: 140
    },
    { headerName: "Discount", field: "DiscountAmount", width: 120 },
    { headerName: "Due", field: "DueAmount", width: 60 },
    { headerName: "Modified Date", field: "UpdatedAt", width: 145 },
    {
      headerName: "Status",
      field: "StatusId",
      valueFormatter: this.InvoiceStatus,
      width: 145
    },
    {
      headerName: 'History', maxwidth: 30, onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data),
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      }
    }
  ]

  columnDefsForDraft = [
    { headerName: 'Serial No.', 
      field: 'SerialNo', 
      maxWidth: 96.5,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true 
    },
    {
      headerName: "Invoice No",
      field: "InvoicceNo",
      minWidth: 140,
      cellRendererFramework: RouterLinkRendereComponent,
      cellRendererParams: { inRouterLink: "/income/invoice/" },
    },
    { headerName: "Ref", field: "ReferenceNo", width: 110 },
    { headerName: "To", field: "CompanyName", width: 90 },
    { headerName: "Due Date", field: "DueDate", width: 110 },
    {
      headerName: "Invoice Amount",
      field: "InvoiceAmount",
      valueFormatter: this.InvoiceAmountFormatter,
      minwidth: 140,
    },
    { headerName: "Discount", field: "DiscountAmount", width: 120 },
    { headerName: "Due", field: "DueAmount", width: 60 },
    { headerName: "Modified Date", field: "UpdatedAt", width: 145 },
    {
      headerName: "Status",
      field: "StatusId",
      valueFormatter: this.InvoiceStatus,
      width: 145,
    },
    {
      headerName: 'History', maxwidth: 30, onCellClicked: (event: CellClickedEvent) => this.modalViewFullHistory(event.data),
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>`
      }
    }
  ]

  onGridReady = (event) => {
    this.gridApi = event.api;
  }
  
  DueAmountFormatter(value) {
    let amount =
      value.data.InvoiceAmount -
      (value.data.PaidAmount + value.data.DiscountAmount);
    return amount.toLocaleString();
  }
  InvoiceAmountFormatter(value) {
    let valueData = Number(value.data.InvoiceAmount.toFixed(2));
    return valueData.toLocaleString();
  }
  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }
  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }
  getInvoiceList = () => {
    this.spinner.show();
    this.incomeService.GetInvoiceList().subscribe(
      (x) => {
        this.allInvoice = x.data;
        for(let i=0;i<this.allInvoice.length;i++) {
          this.allInvoice[i].InvoicceNo = 'INV-' + this.allInvoice[i].InvoicceNo;
          if( !!this.allInvoice[i].ReferenceNo && this.allInvoice[i].ReferenceNo.length == 10 && this.containsOnlyNumbers(this.allInvoice[i].ReferenceNo)){
            this.allInvoice[i].ReferenceNo = 'SO-' + this.allInvoice[i].ReferenceNo; //remove it when reference no issue solve
          }
        }
        this.allInvoice = this.isReceivable
          ? this.allInvoice.filter((xy) => xy.CustomerId == this.contactId)
          : this.allInvoice;
        this.allCount = this.allInvoice.length;
        this.allInvoice.forEach((x) => {
          x.StatusId == 1
            ? (this.draftCount += 1)
            : x.StatusId == this.invoiceStatus.Paid
            ? (this.paidCount += 1)
            : x.StatusId == 6 ? this.overdueCount += 1 : x.StatusId == 7 ? this.rejectCount += 1 : x.StatusId == 2 ? this.submitCount += 1 :
            (this.awaitingCount += 1);
        });
        this.reLoadRowData(this.status);
        this.spinner.hide();
      },
      (err) => {
        this.toastr.error("There is an error,please check");
        this.spinner.hide();
      }
    );
  }

  containsOnlyNumbers(referenceNO) {
    return /^[0-9]+$/.test(referenceNO);
  }

  InvoiceStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return "Draft";
      case 2:
        return "A. Approval";
      case 3:
        return "A. Payment";
      case 4:
        return "Paid";
      case 6:
        return "Overdue";
      case 7:
        return 'Rejected';
      default:
        return '';
    }
  }

  search = () => {
    this.gridApi.setFilterModel(null);
    const searchText = this.searchText.toLowerCase();
    if (searchText.includes(',')) {
      const amount = parseInt(searchText.replace(',', ''), 10);
      this.gridApi.setQuickFilter(null);
      this.gridApi.setRowData(this.filteredInvoice.filter(row => {
        const invoiceAmount = parseFloat(row.InvoiceAmount);
        const discountAmount = parseFloat(row.DiscountAmount);
        const dueAmount = parseFloat(row.DueAmount);
        return ( Math.floor(invoiceAmount) === amount || Math.floor(discountAmount) === amount ||Math.floor(dueAmount) === amount );
      }));
    } else {
      this.gridApi.setQuickFilter(searchText);
    }
  }

  reLoadRowData = (status: string) => {
    this.status = status;
    let data = this.allInvoice;

    if (status != "All") {
      data = this.allInvoice.filter((x) => x.StatusId == this.invoiceStatus[status]);
    }

    (this.totalDiscountAmount = 0),
      (this.totalInvoiceAmount = 0),
      (this.totalDueAmount = 0);
    data.forEach((element) => {
      this.totalInvoiceAmount += element.InvoiceAmount;
      this.totalDueAmount +=
        element.InvoiceAmount - (element.PaidAmount + element.DiscountAmount);
      this.totalDiscountAmount += element.DiscountAmount;
    });
    this.filteredInvoice =
      this.invoiceStatus[status] == this.invoiceStatus.All
        ? this.allInvoice
        : this.allInvoice.filter(
            (x) => x.StatusId == this.invoiceStatus[status]
          );
    for(var i = 0 ; i < this.filteredInvoice.length ; i++)
      {
            this.filteredInvoice[i].SerialNo = i + 1;
      }
  }
  convetToPDF = () => {
    var data = document.getElementById("invoOverview");
    (html2canvas as any)(data).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("invoiceOverview.pdf");
    });
  };

  modalViewFullHistory(data:any){
    this.spinner.show();
    let modalData = {
      ActionNo: data.InvoicceNo,
	    CreatedDate: data.InvoiceDate,
	    DueDate: data.DueDate,
	    Number: data.Id,
	    ApprovalStatus: data.StatusId,
      CustomerName: data.CompanyName
    }
    this.approvalHistoryModalService.saveModalInfoData(modalData);
    const modalRef = this.modalService.open(ApprovalHistoryModalComponent,
    { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.action = this.fileName; //Important! Bill, Invoice, Purchase Order etc not actual file name
    modalRef.componentInstance.OperationType = this.OperationType;
  }

  getSelectedInvoice(): void {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedInvoiceData = selectedNodes.map(node => node.data);
    if (selectedInvoiceData.length === 0) {
      this.toastr.warning('Please select at least one invoice!');
      return;
    }
    let invoiceIds = this.getInvoiceIds(selectedInvoiceData);
    if(this.status == 'draft') {
      this.submittedInvoicesData(invoiceIds);
    }
    else {
      this.approvedInvoicesData(invoiceIds);
    }
  }

  getInvoiceIds(selectedInvoiceData: any[]): number[] {
    const ids = [];
    for (const invoice of selectedInvoiceData) {
      ids.push(invoice.Id);
    }
    return ids;
  }

  submittedInvoicesData(invoiceIds: number[]): void {
    this.spinner.show();
    this.incomeService.SubmitInvoices(invoiceIds).subscribe((x) => {
      this.toastr.success('Invoice/s successfully submitted.');
      this.spinner.hide();
      location.reload();
    });
  }

  approvedInvoicesData(invoiceIds: number[]): void {
    this.spinner.show();
    this.incomeService.ApproveInvoices(invoiceIds).subscribe((x) => {
      this.toastr.success('Invoice/s successfully approved.');
      this.spinner.hide();
      location.reload();
    });
  }

  isShowingCheckbox(): boolean {
    if(this.status == 'draft' || this.status == 'Awaiting-approval')
      return true;
    else if(this.status != 'draft' && this.status != 'Awaiting-approval')
      return false;
  }

}
