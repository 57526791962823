<div class="modal-header">
    <h4 class="modal-title">Availability</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<div class="modal-body">
    <table class="table table-borderless">
        <thead >
          <tr>
            <th colspan="2"></th>
            <th>Ordered Stock</th>
            <th colspan="4"></th>
            <th>Received</th>
            <th colspan="2"></th>
            
           
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let bill of  this.AllDaTa.WareHouseItems">
            <td colspan="2"></td>
            <td>{{bill.ItemDetails.ItemName}}= {{bill.Demand}}</td>
            <td colspan="4"></td>
            <td>{{bill.ItemDetails.ItemName}}= {{bill.ProductInOut}}</td>
            <td colspan="2"></td>
          </tr>

        </tbody>
        <tfoot>
            <tr>
                <td colspan="2"></td>
                <td>Total= {{this.sumOfDemand}}</td>
                <td colspan="4"></td>
                <td>Total= {{this.sumOfShipped}}</td>
                <td colspan="2"></td>
              </tr>
        </tfoot>
      </table>



            <form [formGroup]="ReceiveProductForm">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="BtnOne" id="opt1" formControlName="BtnOne"  value="1">
                <label class="form-check-label" for="opt1">
                    Receive mentioned products in one order and the rest in seperate order.
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="BtnOne" id="opt2" formControlName="BtnOne" value="2">
                <label class="form-check-label" for="opt2">
                    Receive only mentioned products.
                </label>
              </div>

            </form>

    
</div>

<div class="modal-footer">
    <a><p><button class="btn btn-sm btn-success"  (click)="Received()">Received</button></p></a>
</div>


