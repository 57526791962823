<app-dashboard>
  <app-page-header-title
    mainTitle="Statement Of Cash Flows"
    subTitle="Home"
    backURL="/"
  >
  </app-page-header-title>

  <div class="row mr-4 col-md-12 mrl">
    <div class="form-group col-md-2">
      <input
        class="form-control"
        style="outline: none"
        type="date"
        placeholder="From Date"
        id="scftest" data-date="" data-date-format="D MMM YYYY" 
        (change)="show($event)"
        [(ngModel)]="fromDate"
      />
    </div>
    <div class="form-group col-md-2 ml">
      <input
        class="form-control"
        type="date"
        placeholder="To  Date"
        id="scftest1" data-date="" 
        data-date-format="D MMM YYYY" 
        (change)="showSelectedDate($event)"
        [(ngModel)]="toDate"
      />
    </div>
    <div class="f-ss-14px col-md-4">
      <button
        type="button"
        (click)="reportGenerate()"
        class="eCount-def-btn ml-4"
      >
        Generate Report
      </button>
    </div>
  </div>

  <div id="cashflow" class="customReportPage">
    <div class="report-header row" style="margin-bottom: 10px">
      <div class="col-md-8">
        <div>
          <p class="txtsize">Statement of cash flows</p>
        </div>
        <div class="row ml-0">
          <span class="f-ss-14px">{{ companyName }}</span>

          <p class="f-ss-14px">
            <span class="ml-2 mr-2 f-ss-14px">|</span>For the period
            {{ convertDateIntoDisplay(fromDate) }} to
            {{ convertDateIntoDisplay(toDate) }}
          </p>
        </div>
      </div>
      <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
        <span
          type="button"
          style="float: right"
          class="ut"
          (click)="convetToPDF()"
        ></span>
      </div>
    </div>
    <hr />
    <div
      class="OpearationSection"
      *ngIf="CashFlowData && CashFlowData.OperatingActivities.length > 0"
    >
      <div class="paddingBottom d-flex">
        <div class="tableHeaderColumn2 dateColumn">
          <span class="runningBalanceText font-weight-bold"
            >Operating Activities</span
          >
        </div>
      </div>

      <div
        class="d-flex normalRowSpaceing"
        *ngFor="let op of CashFlowData.OperatingActivities"
      >
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText ml-3">{{ op.Name }}</span>
        </div>
        <div class="tableHeaderColumn2">
          <span class="runningBalanceText text-link" (click)="movementDetails(op.MovementTypeId)">{{
            op.ValueView
          }}</span>
        </div>
      </div>

      <div class="d-flex normal padtop">
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText"
            >Net Cash Flows from Operating Activities</span
          >
        </div>
        <div>
          <p class="float-right totText">
            {{ CashFlowData.NetOperatingActivitiesView }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="FinancingSection"
      *ngIf="CashFlowData && CashFlowData.FinancingActivities.length > 0"
    >
      <div class="paddingBottom d-flex">
        <div class="tableHeaderColumn2 dateColumn">
          <span class="runningBalanceText font-weight-bold"
            >Financing Activities</span
          >
        </div>
      </div>

      <div
        class="d-flex normalRowSpaceing"
        *ngFor="let inv of CashFlowData.FinancingActivities"
      >
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText ml-3">{{ inv.Name }}</span>
        </div>
        <div class="tableHeaderColumn2">
          <span class="runningBalanceText text-link" (click)="movementDetails(inv.MovementTypeId)">{{
            inv.ValueView
          }}</span>
        </div>
      </div>

      <div class="d-flex normal padtop">
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText"
            >Net Cash Flows from Financing Activities</span
          >
        </div>
        <div>
          <p class="float-right totText">
            {{ CashFlowData.NetFinancingActivitiesView }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="InvestigateSection"
      *ngIf="CashFlowData && CashFlowData.InvestingActivities.length > 0"
    >
      <div class="paddingBottom d-flex">
        <div class="tableHeaderColumn2 dateColumn">
          <span class="runningBalanceText font-weight-bold"
            >Investing Activities</span
          >
        </div>
      </div>

      <div
        class="d-flex normalRowSpaceing"
        *ngFor="let inv of CashFlowData.InvestingActivities"
      >
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText ml-3">{{ inv.Name }}</span>
        </div>
        <div class="tableHeaderColumn2">
          <span class="runningBalanceText text-link" (click)="movementDetails(inv.MovementTypeId)">{{
            inv.ValueView
          }}</span>
        </div>
      </div>

      <div class="d-flex normal padtop">
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText"
            >Net Cash Flows from Investing Activities</span
          >
        </div>
        <div>
          <p class="float-right totText">
            {{ CashFlowData.NetInvestingActivitiesView }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="d-flex normal"
      *ngIf="CashFlowData && CashFlowData.OperatingActivities.length > 0"
    >
      <div class="tableHeaderColumn2" style="width: 100%">
        <span class="runningBalanceText mt-1">Net Cash Flows</span>
      </div>
      <div class="tableHeaderColumn2 mt-1">
        <p class="float-right totText">{{ CashFlowData.NetCashFlowAmountView }}</p>
      </div>
    </div>
    <div
      class="d-flex normal normalRow"
      *ngIf="CashFlowData && CashFlowData.OperatingActivities.length == 0"
    >
      <div class="tableHeaderColumn2" style="width: 100%">
        <span class="runningBalanceText mt-1">Net Cash Flows</span>
      </div>
      <div class="tableHeaderColumn2 mt-1">
        <p class="float-right totText">{{ CashFlowData.NetCashFlowAmountView }}</p>
      </div>
    </div>

    <div class="OpearationSection">
      <div class="paddingBottom d-flex">
        <div class="tableHeaderColumn2 dateColumn">
          <span class="runningBalanceText font-weight-bold"
            >Cash and Cash Equivalents</span
          >
        </div>
      </div>

      <div class="d-flex normalRowSpaceing">
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText ml-3"
            >Cash and Cash Equivalents at beginning of period</span
          >
        </div>
        <div class="tableHeaderColumn2">
          <span class="runningBalanceText">{{
            CashFlowData?.NetAmountAtBeginingOfPeriodView
          }}</span>
        </div>
      </div>

      <div class="d-flex normalRowSpaceing">
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText ml-3"
            >Net change in cash for period</span
          >
        </div>
        <div class="tableHeaderColumn2">
          <span class="runningBalanceText">{{
            CashFlowData?.NetCashFlowAmountView
          }}</span>
        </div>
      </div>

      <div class="d-flex normal padtop">
        <div class="tableHeaderColumn2" style="width: 100%">
          <span class="runningBalanceText"
            >Cash and Cash Equivalents at end of period</span
          >
        </div>
        <div>
          <p class="float-right totText">
            {{ CashFlowData?.NetAmountAtEndOfPeriodView }}
          </p>
        </div>
      </div>
    </div>
  </div>
</app-dashboard>
