<app-dashboard>
    <app-page-header-title mainTitle="{{'Contacts.contactList.customer'| translate}}" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
            <button type="button" routerLink="/contacts/create-contact"
                class="eCount-def-btn ml-4">{{'Contacts.contactList.createContact'| translate}}</button>
            <button (click)="onBtnExport()"
                class="eCount-button3 ml-4">{{'Contacts.contactList.export'| translate}}</button>
        </div>
        
    </app-page-header-title>
    <div class="row">
        <ag-grid-angular style="width: 100wh; height: 70vh;" class="ag-theme-balham col" [rowData]="contactList"
            [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" (rowClicked)="onRowClicked($event)" [defaultColDef]="defaultColDef">
        </ag-grid-angular>
    </div>
</app-dashboard>
