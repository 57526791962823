export class ProjectModel{
    Id: number;
    Title: string;
    ShortName: string;
    DeadlineVw: string;
    UnInvoicedAmount: number;
    TotalInvoiceAmount: number;
    TotalBillAmount:number;
    WinPrice:number;
    EstimatedCost:number;
    Percentage: number;
}