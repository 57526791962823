
import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvanceService } from '../../../../service/Advance/advance.service';
import {MainServiceService} from '../../../../service/Main/main-service.service';
import * as moment from 'moment';
@Component({
  selector: 'app-advance-create',
  templateUrl: './advance-create.component.html',
  styleUrls: ['./advance-create.component.css']
})
export class AdvanceCreateComponent implements OnInit {

  public advanceForm: FormGroup;
  advanceTypeList:[];
  contactList: any[];
  accountList:[];
  loading = false;

  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    public mainService: MainServiceService,
    private advanceService: AdvanceService) { }

  ngOnInit(): void {
   this.initializeForm();
   this.getMasterData();
  }

  initializeForm() {
    this.advanceForm = this.fb.group({
      Id:0,
      TransactionTypeId:[1],
      TransactionDate: [new Date()],
      ContactId: [null, Validators.required],
      AccountId: ['', Validators.required],
      Description:[''],
      Amount: ['', Validators.required]
    });
    this.showDefaultDate(new Date());
  }

  getMasterData(){
    this.advanceService.GetAdvanceTypeList().subscribe((x: any) => {
      this.advanceTypeList = x.filter(xy => xy.Id <= 2 );
      console.log(x);
    });
    this.advanceService.GetAllCustomer().subscribe((x: []) => {
      this.contactList = x;
    });
    this.advanceService.GetBankAccounts().subscribe((x: []) => {
      this.accountList = x;
    });
  }


  /**
   * getContacts
   */
  public getContacts() {
    if(this.advanceForm.value.TransactionTypeId==1){
      this.advanceService.GetAllCustomer().subscribe((x: []) => {
        this.contactList = x;
        this.advanceForm.controls['ContactId'].patchValue(null);
      });
    }
    else{
      this.advanceService.GetAllSupplier().subscribe((x: []) => {
        this.contactList = x;
        this.advanceForm.controls['ContactId'].patchValue(null);
      });
    }
    
  }
  CreateNewContact = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : this.advanceForm.value.TransactionTypeId ==1 ? true : false,
      IsSupplier : this.advanceForm.value.TransactionTypeId ==2 ? true : false,
    };
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Contact has been successfully added.');
        this.loading = false;
        this.contactList.push(x["Data"]);
        let customerObj = this.contactList.filter(y => y.PrimaryPersonFirstName === customerName);
        console.log(customerName);
        this.advanceForm.controls['ContactId'].patchValue(customerObj[0]['Id']);
        this.getContacts();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    });
  }

  public save() {
    if (this.advanceForm.invalid){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    this.spinner.show();
    this.advanceService.save(this.advanceForm.value).subscribe((x)=>{
      if(x.Success){
        this.toaster.success('Advance has been successfully saved.');
        this.activeModal.close(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err=>{
        this.toaster.error('ooh, something went wrong !');
    });
  }
  showDefaultDate(e) {
    const value = this.advanceForm.value.TransactionDate;
    $("#adtest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

}
