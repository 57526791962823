<app-dashboard>
  <app-page-header-title
    mainTitle="Create New Organization"
    subTitle="Home"
    backURL="/"
  >
    <div class="full-width text-align-right">
    <button type="button" (click)="onSaveAndBuyOrganization()" class="eCount-def-btn ml-4" > Buy Now </button>
    <button type="button" (click)="onSaveOrganization()" class="eCount-def-btn ml-4" > Start Free Trial </button>
    </div>
  </app-page-header-title>
  <div class="col-sm-12">
    <form
      [formGroup]="organizationForm"
      [ngClass]="{ submitted: ifSubmit === true }"
      class="d-flex"
    >
      <div class="col-sm-6 row">
        <div class="row col-sm-12">
          <div class="form-group col-sm-12 row">
            <label class="required"
              >What is your organization name ?<span class="clr"
                >*<i
                  style="color: #0078c8; font-size: 17px"
                  class="float-right material-icons text-color-black"
                  tooltip="Put Your Organization Name Here"
                  tooltip-class="tooltip-feedback"
                  >help</i
                ></span
              ></label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Organization Name"
              formControlName="Name"
              required
            />
    
          </div>
          <div class="col-sm-12 form-group row">
            <label
              >What is your organization type ?<i
                style="color: #0078c8; font-size: 17px"
                class="float-right material-icons"
                tooltip="There are three types of organization type here. Service, Manufacture and Retail/Trading. 
                The chart of account will be different for different type of organization.You can change setting until you make any transaction.<br> <br>
                <span class='font-weight-bold'>
                  Service: 
                </span>
                It is suitable for business owner who provide services to serve their valuable customers.Service type business are Startup,
                Non-profit,It, Creative,Legal Business,BPO.<br><span class='font-weight-bold ml-2'>Manufacture:  </span>
                It is suitable for organizations that creates good themselves form raw material.Manufacture type businesses are Construction,
                Real-estate, Apparel, Woods CraftsMan, Glass Fabricator, Paper Producer.
                <br>
                <span class='font-weight-bold'>
                  Retail/Trading: 
                </span>
                It is suitable for retailers. Buying good from suppliers and selling it to the customer.that creates good themselves form raw material.Trading type businesses are Tourism,
                E-Commerce, Small Business.
                "
                tooltip-class="tooltip-feedback"
                >help</i
              ></label
            >
            <select
              data-placeholder="Name"
              class="form-control"
              formControlName="OrganizationTypeId"
            >
            <optgroup *ngFor = 'let grp of organizationTypeName' label="{{grp.GroupName}}">
              <option
                [ngValue]="organization?.Id"
                class="defaultText"
                *ngFor="let organization of grp.TypeList"
              >
                {{ organization.Name }}
              </option>
            </optgroup>
            </select>
          </div>
        </div>

        <div class="row col-sm-12">
          <label class=""
            >When is your financial year end ?
            <i
              style="color: #0078c8; font-size: 17px"
              class="float-right material-icons"
              tooltip="In acording procedures carried out at the end of the financial year. These procedures are key to creating company's financial statements such as balance sheets profit and loss statements. By default it will select as 31st December.You can change setting until you make any transaction."
              tooltip-class="tooltip-feedback"
              >help</i
            ></label
          >
        </div>
        <div class="row col-sm-6">
          <div class="col-sm-12 form-group row">
            <input
              type="text"
              maxlength="2"
              (keypress)="numberOnly($event)"
              class="form-control"
              placeholder="Month"
              formControlName="FinancialYearEndDay"
            />
          </div>
        </div>
        <div class="col-sm-6 form-group row">
          <select
            data-placeholder="Currency name"
            class="form-control"
            formControlName="FinancialYearEndMonth"
            (change)="monthNameChange()"
          >
            <option
              [ngValue]="month.Value"
              class="defaultText"
              *ngFor="let month of monthList"
            >
              {{ month.Text }}
            </option>
          </select>
        </div>

        <div class="row col-sm-12">
          <div class="form-group col-sm-12 row">
            <label>Address<span class="clr">*</span> </label>
            <textarea
              rows="2"
              type="text"
              class="form-control"
              placeholder="your address"
              formControlName="Address"
              required
            ></textarea>
          </div>
        </div>
        <span class="font-weight-bold">Primary Contact</span>
        <div class="row col-sm-12">
          <div class="form-group row col-sm-12">
            <label>Contact No<span class="clr">*</span> </label>
            <input
              type="text"
              class="form-control"
              placeholder="Contact No"
              (ngModelChange)="modelChanged($event)"
              (keypress)="numberOnly($event)"
              formControlName="PhoneNo"
              maxlength="11"
              required
            />
          </div>
          <div class="form-group row col-sm-12">
            <label>Email <span class="clr">*</span> </label>
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              formControlName="BillToEmail"
              required
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="col-sm-12 row">
          <div class="form-group" [ngClass]="logo?.logoUrl ? 'col-sm-5' : 'col-sm-4'">
            <label>Organization logo</label>
            <div class="row col-sm-12">
              <div class="logo-container">
                <!--  -->
                <div
                  [hidden]="logo?.logoUrl"
                  (click)="openLogoUploader()"
                  class="dummy-logo"
                >
                  <p style="width: 100%; margin-top: -15px">65x65</p>
                  <span style="width: 100%; color: #9facba"
                    ><i class="material-icons">insert_photo</i></span
                  >
                  <p style="color: #34adee; font: bold; width: 100%">
                    Upload logo
                  </p>
                </div>
                <div [hidden]="!logo?.logoUrl" class="logo-image">
                  <img
                    style="width: 100%; height: 100%; border-radius: 5px"
                    src="{{ logo?.logoUrl }}"
                  />
                </div>
              </div>
              <div [hidden]="!logo?.logoUrl" class="logo-update">
                <button
                  (click)="openLogoUploader()"
                  class="eCount-def-btn"
                  style="margin-bottom: 13px; width: 95px; height: 34px"
                >
                  Change
                </button>
                <button (click)="removeLogo()" class="btn-logo-update btn">
                  Remove
                </button>
        
              </div>
       
            </div>
          </div>
            <div class="inputMargin" [ngClass]="logo?.logoUrl ? 'col-sm-7' : 'col-sm-8'">
              <div class="form-group marl col-sm-12 inputMargin">
                <label>VAT Registration No.</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="VAT Registration No."
                  formControlName="VatRegNo"
                />
              </div>
                     <div class="form-group marl col-sm-12 inputMargin">
              <label>Organization Registration No.</label>
              <input
                type="text"
                class="form-control"
                placeholder="Organization Registration No."
                formControlName="OrganizationRegNo"
              />
            </div>
            </div>
          </div>
   
        <div class="form-group ml-3 mt-3">
          <label>Website</label>
          <input
            type="text"
            class="form-control"
            placeholder="website"
            formControlName="Website"
          />
        </div>

        <span class="font-weight-bold ml-2">Person To Bill</span>
        <div class="form-group ml-3">
          <label>Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Name"
            formControlName="BillToName"
          />
        </div>
        <div class="form-group ml-3">
          <label>Contact No</label>
          <input
            type="text"
            class="form-control"
            placeholder="Contact No"
            (keypress)="numberOnly($event)"
            formControlName="BillingContact"
            maxlength="11"
          />
        </div>
        <div class="form-group ml-3">
          <label>Email </label>
          <input
            type="text"
            class="form-control"
            placeholder="Email"
            formControlName="BillingEmail"
          />
        </div>
      </div>
    </form>
  </div>

  <!-- Modal -->

  <div
    class="modal fade"
    id="logoUploader"
    tabindex="-1"
    role="dialog"
    aria-labelledby="logoUploader"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content custom-width">
        <div class="modal-header bbp-1px">
          <h5 class="modal-title logoUploaderTigle" id="logoUploaderTigle">
            Upload logo
          </h5>
          <button
            type="button"
            (click)="closeLogoUploaderPopUp()"
            (click)="fileUploader.value = null"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body bbp-1px" id="filecon">
          <p style="font-size: 12px; color: #333333">
            The maximum file size is 1MB. For best results, upload the largest
            logo you have, at least 140 pixels on its shortest side.
          </p>
          <label class="file-uploader-label" style="width: 100%"
            >Choose a file to upload</label
          >
          <!-- <input class="inputfile" type="file" name="fileToUpload" id="fileToUpload"> -->
          <label
            style="width: 30%; text-align: center"
            for="files"
            class="eCount-def-btn"
            >Select File</label
          ><span style="font-size: 14px" [hidden]="!selectedFile">{{
            selectedFile
          }}</span>
          <span
            style="font-size: 14px; padding-left: 10px"
            [hidden]="selectedFile"
            >No file selected</span
          >
          <input
            #fileUploader
            id="files"
            type="file"
            (change)="upload($event)"
            style="visibility: hidden; margin-top: -40px"
          />
        </div>
        <div class="modal-footer">
          <button
            [hidden]="selectedFile"
            type="button"
            disabled
            (click)="uploadFile(selectedLogo)"
            (click)="fileUploader.value = null"
            class="btn btn-upload"
          >
            Upload image
          </button>
          <button
            [hidden]="!selectedFile"
            type="button"
            (click)="uploadFile(selectedLogo)"
            class="eCount-def-btn ml-4"
          >
            Upload image
          </button>
          <!-- <button type="button" class="btn eCount-button3 ml-4" (click)="closeLogoUploaderPopUp()" (click)="fileUploader.value = null" data-dismiss="modal">Close</button> -->
        </div>
      </div>
    </div>
  </div>
</app-dashboard>
