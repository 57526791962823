<div class="headSection">
  <form [formGroup]="manufactureBackOrderFlowTypeForm">
      <div class="modal-header">
          <h4 class="modal-title">Availability</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
              <span aria-hidden="true" style="font-size: 25px">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <table class="table table-borderless">
              <thead>
                  <tr>
                      <th colspan="2"></th>
                      <th>Initial Demand</th>
                      <th colspan="4"></th>
                      <th>Produced</th>
                      <th colspan="2"></th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td colspan="2"></td>
                      <td>{{ manufactureOrderViewModel.ManufactureOrder.Item.ItemName }} = {{ manufactureOrderViewModel.InitialProductionQuantity }}</td>
                      <td colspan="4"></td>
                      <td>{{ manufactureOrderViewModel.ManufactureOrder.Item.ItemName }} = {{ manufactureOrderViewModel.ManufactureOrder.ProductionQuantity }}</td>
                      <td colspan="2"></td>
                  </tr>
              </tbody>
              <tfoot>
                  <tr>
                      <td colspan="2"></td>
                      <td>Total = {{ manufactureOrderViewModel.InitialProductionQuantity }}</td>
                      <td colspan="4"></td>
                      <td>Total = {{ manufactureOrderViewModel.ManufactureOrder.ProductionQuantity }}</td>
                      <td colspan="2"></td>
                  </tr>
              </tfoot>
          </table>
          <div class="form-check">
              <input class="form-check-input" type="radio" name="MentionedProductSelected" id="opt1" formControlName="MentionedProductSelected" value="1">
              <label class="form-check-label" for="opt1"> Produce mentioned products in one production and the rest in seperate production. </label>
          </div>
          <div class="form-check">
              <input class="form-check-input" type="radio" name="MentionedProductSelected" id="opt2" formControlName="MentionedProductSelected" value="0">
              <label class="form-check-label" for="opt2"> Produce only mentioned products. </label>
          </div>
      </div>
      <div class="modal-footer">
          <a>
              <p>
                  <button class="btn btn-sm btn-success" (click)="produced()">Produced</button>
              </p>
          </a>
      </div>
  </form>
</div>