<div class="modal-header" style="background-color: #F0F8FF; padding-bottom: 10px;">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<div class="modal-body modalBody">
    <div class="pb-4 m-2">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="CustomDocumentApprovalFlowForm" class="f-ss-14px">
                    <div class="row f-ss-14px">
                        <div class="col-md-12">
                            <p class="font-weight-bold">Please select the approval flow for this document</p>
                        </div>
                    </div>
                    <div class="row f-ss-14px">
                        <div class="col-md-12 form-check ml-5">
                            <input class="form-check-input" type="radio" name="SelectedApprovalFlow" id="opt1" formControlName="SelectedApprovalFlow" value="0">
                            <label class="form-check-label" for="opt1">Matrix</label>
                        </div>
                        <div class="col-md-12 form-check ml-5">
                            <input class="form-check-input" type="radio" name="SelectedApprovalFlow" id="opt2" formControlName="SelectedApprovalFlow" value="1">
                            <label class="form-check-label" for="opt2">Run Time</label>
                        </div>
                    </div>
                </form>
                <div class="row mm-5px pbp-20px" *ngIf="CustomDocumentApprovalFlowForm.value.SelectedApprovalFlow == 1">
                    <div class="col-sm-12" style="margin-top: 10px">
                        <form [formGroup]="documentApprovalForm">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="full-width text-align-right">
                                        <button type="button" (click)="addBlankDocumentLevel()" class="eCount-def-btn ml-3"> Add Level </button>
                                    </div>
                                </div>
                            </div>
                            <div formArrayName="DocumentApprovalLevel">
                                <div *ngFor="let level of DocumentApprovalLevel().controls; let levelIndex = index">
                                    <div [formGroupName]="levelIndex">
                                        <input style="font-weight: bold; color: black; margin-left: 5px" readonly class="form-control noBorder" type="text" disabled formControlName="LevelName" />
                                        <div class="d-flex">
                                            <div class="col-md-4">Approvers</div>
                                            <div class="col-md-6">Email</div>
                                        </div>
                                        <div formArrayName="CustomApprovalLevelItem">
                                            <div *ngFor=" let item of documentLevelItems(levelIndex).controls;let itemIndex = index">
                                                <div class="invoiceGridItemRow d-block pb-1">
                                                    <div style="margin-left: 0px; margin-top: 2px" class="row" [formGroupName]="itemIndex">
                                                        <div class="col-md-4 margin-top-5">
                                                            <ng-select [items]="approverRoleList" [clearable]="false" bindLabel="Name" bindValue="Id" formControlName="ApproverId" (change)="ChangeEmailByDocumentRole (item,itemIndex,levelIndex)"></ng-select>
                                                        </div>
                                                        <div class="col-md-6 margin-top-5">
                                                            <ng-select class="auto-grow" [items]="approverEmailList" [clearable]="false" bindLabel="Name" [searchable]="false" bindValue="Id" (change)="changeApproveIfDocumentRoleChange(item,itemIndex,levelIndex)" formControlName="ApproverEmailId"></ng-select>
                                                        </div>
                                                        <div class="col-md-2 margin-top-5 d-flex" *ngIf="levelIndex > 0 || (levelIndex == 0 && itemIndex > 0)">
                                                            <div style="padding: 7px 10px; color: #c7c2c2">
                                                                <div class="dropdown mr-4">
                                                                    <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                                        <i class="icon-more float-right"></i>
                                                                    </div>
                                                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="cursor: pointer">
                                                                        <div class="productRow pb-2" (click)="removeLevelDocumentItem(levelIndex, itemIndex, item)">
                                                                            <span class="lead1 ml-4">Delete</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row addItem">
                                            <div class="BottomAddItem">
                                                <div class="dropdown mr-4">
                                                    <a class="nav-link" style="background-color: white" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" (click)="addBlankLevelDocumentItem(levelIndex)">
                                                        <img style="height: 30px" class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Approver </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="full-width text-align-right">
                    <button type="button" class="eCount-def-btn mt-4 blueBg" (click)="submit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>