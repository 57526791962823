import { Component, ViewChild, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConversionbalancesService } from '../../../../service/ConversionBalance/conversionbalances.service';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridAngular } from 'ag-grid-angular';
import { from } from 'rxjs';
import * as moment from 'moment';
import { DataService } from 'src/app/service/EventEmitter/data.service';

@Component({
  selector: 'app-conversion-balances',
  templateUrl: './conversion-balances.component.html',
  styleUrls: ['./conversion-balances.component.css']
})
export class ConversionBalancesComponent implements OnInit {

  //#region Properties
  private gridApi;
  private gridColumnApi;
  showStrartDateDiv: boolean = false;
  TotalDebit: any;
  TotalCredit: any;
  AdjustmentAmount: any;
  conversionMonth: any;
  conversionYear: any;
  conversionId: Number = 0;
  previousConversionDate: any;
  previousConversionMonth: Number = 0;
  showHideWarningMessage: boolean = false;
  showHideDateIcon: boolean = false;
  yearList: any = [];
  rowData: any;
  masterRowData: any;
  lastConversionDate: string = null;

  @ViewChild("agGrid", { static: false }) agGrid: AgGridAngular;

  gridOptions = {
    columnDefs: [
      { headerName: 'Account Name', field: 'AccountFullName', width: 50, },
      {
        headerName: 'Debit', field: 'Debit', editable: true, width: 50, cellEditor: 'numericCellEditor',
        valueSetter: (params) => {
          params.data.Debit = Number(params.newValue);
          this.TotalDebit = this.generateDebitTotalData();
          this.AdjustmentAmount = this.TotalDebit - this.TotalCredit;
          return true;
        },
      },
      {
        headerName: 'Credit', field: 'Credit', editable: true, width: 50, cellEditor: 'numericCellEditor',
        valueSetter: (params) => {
          params.data.Credit = Number(params.newValue);
          this.TotalCredit = this.generateCreditTotalData();
          this.AdjustmentAmount = this.TotalDebit - this.TotalCredit;
          return true;
        },
      },
      { headerName: "Action", width: 30, suppressMenu: true, suppressSorting: true, template: `<span  (click)="deleteRows()"><img class="icon-Trash" src="../../../../assets/image/icon-Trash.png"></span>` }
    ],

    defaultColDef: {
      flex: 1,
    },
    domLayout: 'autoHeight',
    rowHeight: 275,
    components: {
      numericCellEditor: this.getNumericCellEditor()
    },
    getRowStyle: this.changeRowColor,
  }
  changeRowColor(params) {
    if (params.data.Debit > 0 && params.data.Credit > 0) {
      return { background: '#F89406' };
    }
    return { background: '#FFFFFF' };
  }
  //#endregion

  constructor(public ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private router: Router,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private conversionBalanceService: ConversionbalancesService) {

  }

  ngOnInit(): void {
    this.dataService.changeSelectedOrganization.subscribe(x=>{
      this.getCurrentYear();
      this.getConversionDate();
      this.getInitialConversionBalance();
    })
    this.getCurrentYear();
    this.getConversionDate();
  }

  //#region ConversionBalance

  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getInitialConversionBalance();
  }

  onRowClicked(e) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Action") {
      this.deleteRows(e);
    }
  }

  getInitialConversionBalance() {
    this.spinner.show();
    this.conversionBalanceService.getConversionBalance().subscribe((res) => {
      if (res != null) {
        this.spinner.hide();
        this.masterRowData = res["AccountList"];
        this.TotalDebit = res["TotalDebit"];
        this.TotalCredit = res["TotalCredit"];
        this.AdjustmentAmount = res["AdjustmentAmount"];

        this.rowData = res["DefaultAccountList"];
      } else {
        this.spinner.hide();
        this.toaster.error("Something went wrong. Please check.", "Error");
      }
    }, (err) => { 
      this.toaster.error("Something went wrong. Please check.", "Error");
      this.spinner.hide();
    })
  }

  showAllRows() {

    for (let i = 0; i < this.masterRowData.length; i++) {
      for (let j = 0; j < this.rowData.length; j++) {
        if ((this.masterRowData[i]['AccountFullName'] == this.rowData[j]['AccountFullName']) &&
          (this.rowData[j]['Debit'] > 0 || this.rowData[j]['Credit'] > 0)) {
          this.masterRowData[i] = this.rowData[j];
        }
      }
    }
    this.rowData = this.masterRowData;
  }

  deleteRows(e) {

    this.gridApi.updateRowData({ remove: [e.data] });
    this.gridApi.refreshView();
    for (let i = 0; i < this.rowData.length; i++) {
      if (this.rowData[i]['AccountFullName'] == e.data.AccountFullName) {
        this.rowData[i]["Credit"] = 0;
        this.rowData[i]["Debit"] = 0;
        console.log('yes');
      }
    }
    console.log(this.rowData);
    this.TotalDebit = this.generateDebitTotalData();
    this.TotalCredit = this.generateCreditTotalData();
    this.AdjustmentAmount = this.TotalDebit - this.TotalCredit;
  }

  removeAllZeroRows() {

    let removeRowData = [];

    for (let index = 0; index < this.rowData.length; index++) {
      if (this.rowData[index]['Debit'] > 0 || this.rowData[index]['Credit'] > 0) {
        removeRowData.push(this.rowData[index]);
      }
    }
    this.rowData = removeRowData;
  }

  generateCreditTotalData() {
    let result = {};
    this.gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculateCreditTotalData(result);
  }

  calculateCreditTotalData(target: any) {
    let columnsWithAggregation = ['Credit'];
    let result = null;
    columnsWithAggregation.forEach(element => {
      console.log('element', element);

      this.gridApi.forEachNodeAfterFilter(function (rowNode, index) {
        if (rowNode.data[element])
          target[element] += Number(rowNode.data[element].toFixed(2));
      });
      if (target[element])
        result = parseFloat(target[element]);
    })
    return result;
  }

  generateDebitTotalData() {
    console.log(this.gridColumnApi.getAllGridColumns);
    let result = {};
    this.gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculateDebitTotalData(result);
  }

  calculateDebitTotalData(target: any) {
    console.log(target);
    let columnsWithAggregation = ['Debit'];
    let result = null;
    columnsWithAggregation.forEach(element => {
      console.log('element', element);

      this.gridApi.forEachNodeAfterFilter(function (rowNode, index) {
        if (rowNode.data[element])
          target[element] += Number(rowNode.data[element].toFixed(2));
      });
      if (target[element])
        result = parseFloat(target[element]);
    })
    return result;
  }

  saveConversionBalance = () => {

    this.spinner.show();

    if (this.masterRowData.length != this.rowData.length) {
      for (let i = 0; i < this.masterRowData.length; i++) {
        for (let j = 0; j < this.rowData.length; j++) {
          if (this.masterRowData[i]['AccountFullName'] == this.rowData[j]['AccountFullName']) {
            this.masterRowData[i] = this.rowData[j];
          }
        }
      }
    }

    let data = {
      AccountList: this.masterRowData,
      TotalDebit: this.TotalDebit,
      TotalCredit: this.TotalCredit,
      AdjustmentAmount: this.AdjustmentAmount
    }

    this.conversionBalanceService.saveConversionBalance(data).subscribe((x) => {
      if (x.Success) {
        this.spinner.hide();
        this.toaster.success('Conversion Balance Successfully Save !', 'Success!');

        if (x.Data.InvoiceAdjustmentDue == true) {
          this.router.navigate(['accounting/historic-conversionbalancesInvoice']);
        }
        else if (x.Data.BillAdjustmentDue == true) {
          this.router.navigate(['accounting/historic-conversionbalancesBill']);
        }
      } else {
        this.spinner.hide()
        this.toaster.warning(x.Message, 'Warning!');
      }
    }, (err) => { this.spinner.hide() })

  }
  //#endregion

  //#region NumericInput

  private getNumericCellEditor() {

    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }

    function isCharDecimal(charStr) {
       return !!/\./.test(charStr);
    }

    function isKeyPressedNumeric(event) {
      var charCode = getCharCodeFromEvent(event);
      var charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr) || isCharDecimal(charStr);
    }

    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    }

    function NumericCellEditor() { }

    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement('input');
      this.eInput.style.width = '100%';
      this.eInput.style.height = '100%';
      this.eInput.value = isCharNumeric(params.charPress) ? params.charPress : params.value;
      var that = this;
      this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };

    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };

    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };

    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };

    NumericCellEditor.prototype.isCancelAfterEnd = function () { };
   
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
   
    NumericCellEditor.prototype.focusIn = function () {
      var eInput = this.getGui();
      eInput.focus();
      eInput.select();
      console.log('NumericCellEditor.focusIn()');
    };
  
    NumericCellEditor.prototype.focusOut = function () {
      console.log('NumericCellEditor.focusOut()');
    };
    return NumericCellEditor;
  }
  //#endregion

  //#region ConversionDate

  openConversionDatePopup = () => {
    this.getCurrentYear();

    this.conversionBalanceService.getConversionConfig().subscribe((res) => {

      if (res != null) {
        this.conversionId = res['Id']
        this.conversionMonth = res['ConversionMonth'];
        this.conversionYear = res['ConversionYear'];

        this.showHideDateIcon = this.conversionMonth == null ? false : true;
        this.showStrartDateDiv = this.conversionMonth == null ? false : true;
        var currentDate = new Date();
        currentDate.setMonth(this.conversionMonth - 1);
        currentDate.setDate(1);
        currentDate.setHours(-1);

        this.previousConversionMonth = this.conversionMonth;
        this.previousConversionDate = currentDate.toString()
        this.lastConversionDate = currentDate.toString();
        this.showHideWarningMessage = false;
        this.ngxSmartModalService.create('conversionDate', 'content').open();

      }
      else {
        this.ngxSmartModalService.create('conversionDate', 'content').open();
      }
    },(err) =>{
       this.toaster.error("Something went wrong. Please check.", "Error");
      this.spinner.hide();
    })

    //this.ngxSmartModalService.create('conversionDate', 'content').open();
  }

  getConversionDate() {
    this.conversionBalanceService.getConversionConfig().subscribe((res) => {

      if (res != null) {
        this.conversionId = res['Id']
        this.conversionMonth = res['ConversionMonth'];
        this.conversionYear = res['ConversionYear'];

        this.showHideDateIcon = this.conversionMonth == null ? false : true;
        this.showStrartDateDiv = this.conversionMonth == null ? false : true;
        var currentDate = new Date();
        currentDate.setMonth(this.conversionMonth - 1);
        currentDate.setDate(1);
        currentDate.setHours(-1);

        this.previousConversionMonth = this.conversionMonth;
        this.previousConversionDate = currentDate.toString()
        this.lastConversionDate = currentDate.toString();

      }
    }, (err) => { this.toaster.error("Something went wrong. Please check.", "Error"); })
  }

  getCurrentYear() {
    this.conversionYear = new Date().getFullYear();
  }

  monthList = [
    { Value: 1, Text: 'January' },
    { Value: 2, Text: 'February' },
    { Value: 3, Text: 'March' },
    { Value: 4, Text: 'April' },
    { Value: 5, Text: 'May' },
    { Value: 6, Text: 'June' },
    { Value: 7, Text: 'July' },
    { Value: 8, Text: 'August' },
    { Value: 9, Text: 'September' },
    { Value: 10, Text: 'October' },
    { Value: 11, Text: 'November' },
    { Value: 12, Text: 'December' }
  ];

  selectionMonthChanged = (event) => {

    this.showHideWarningMessage = this.previousConversionMonth != 0 ? this.previousConversionMonth != event.Value ? true : false : false;

    var currentDate = new Date();
    currentDate.setMonth(event.Value - 1);
    currentDate.setDate(1);
    currentDate.setHours(-1);
    this.lastConversionDate = currentDate.toString();
  }

  convertDateIntoDisplay = (date) => {
    if (date == null)
      return date;

    let dateString = moment(date).format("D MMM YYYY");
    return dateString;
  }

  saveConversionDate = () => {
    if (!this.conversionMonth) {
      this.toaster.warning('Month  cannot be empty !', 'Warning!');
      return;
    } else if (!this.conversionYear) {
      this.toaster.warning('Year cannot be empty !', 'Warning!');
      return;
    }

    this.conversionBalanceService.saveConversionConfig(this.conversionId, this.conversionMonth, this.conversionYear).subscribe((x) => {
      this.spinner.hide();
      if (x.Id > 0) {

        this.toaster.success('Conversion Date Successfully Added !', 'Success!');

        this.conversionMonth = x['ConversionMonth'];
        this.conversionYear = x['ConversionYear'];

        this.showHideDateIcon = this.conversionMonth == null ? false : true;
        this.showStrartDateDiv= this.conversionMonth == null ? false : true;
        var currentDate = new Date();
        currentDate.setMonth(this.conversionMonth - 1);
        currentDate.setDate(1);
        currentDate.setHours(-1);

        this.previousConversionMonth = this.conversionMonth;
        this.previousConversionDate = currentDate.toString();

        this.showHideWarningMessage = false;
        this.getCurrentYear();
        this.getConversionDate();

        this.ngxSmartModalService.closeAll(); //.close();

      } else {
        this.spinner.hide()
        this.toaster.warning('Please fill all the required fields!', 'Warning!');
      }
    }, (err) => { this.spinner.hide() })
  }
  //#endregion
}
