import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { RouterLinkRendereComponent } from '../../../components/router-link-rendere/router-link-rendere.component';
import { RepeatingInvoiceCount } from '../../../models/income.model';
import { AlertService } from '../../../service/Alert/alert.service';
import { IncomeServiceService } from '../../../service/Income/income-service.service';
@Component({
  selector: 'app-repeating-invoice-list',
  templateUrl: './repeating-invoice-list.component.html',
  styleUrls: ['./repeating-invoice-list.component.css']
})
export class RepeatingInvoiceListComponent implements OnInit {
  invoiceList: any = [];
  gridApi;
  name: string;
  invoiceId: number;
  Id: number;
  filteredInvoice: any = [];
  domLayout = 'autoHeight';
  rowHeight = 275;
  categoryCount: RepeatingInvoiceCount = new RepeatingInvoiceCount();
  constructor(private incomeService: IncomeServiceService,
              private dataService: DataService,
              private toastr: ToastrService,
              private alertService: AlertService,
              private spinner: NgxSpinnerService,
              private router: ActivatedRoute) { }

  columnDefs = [
    { headerName: 'Invoice No', field: 'InvoiceNo', flex: 1,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    cellRendererFramework: RouterLinkRendereComponent,
    cellRendererParams: {
        inRouterLink: '/income/invoice/',
      }
  },
    { headerName: 'Description', flex: 1, field: 'Description'},
    { headerName: 'Date', field: 'InvoiceDate', flex: 1, valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Invoice Amount', field: 'InvoiceAmount', flex: 1, valueFormatter: this.formatInvoiceAmount },
    { headerName: 'Last Created At', field: 'LastCreatedAt' , flex: 1, valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: ' ', field: 'IsInvoiceCreated', width: 40, cellRenderer: function(params) {
        return params.value ? "<image src='../../../../assets/image/basic_bill/check.png' style='height:15px;margin-top:-4px;'></image>":'';
      }
  },
  { headerName: '', width: 30, suppressMenu: true, suppressSorting: true, template: `<span  (click)="deleteRows()"><img class="icon-Trash" src="../../../../assets/image/icon-Trash.png"></span>` }
];
defaultColDef = {
  sortable: true,
  filter: true
};
rowSelection = 'multiple';
  ngOnInit(): void {
    this.Id = Number(this.router.snapshot.paramMap.get('catId'));
    this.dataService.changeSelectedOrganization.subscribe(x => {
      this.getInvoiceList();
    });
    this.getInvoiceList();
  }

 onGridReady(event){
    this.gridApi = event.api;
  }
  getSelectedRowData() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData);
    if (selectedData.length === 0){
      this.toastr.warning('select at least one bill');
      return;
    }
    this.spinner.show();
    this.incomeService.CreateInvoiceFromRepeating(selectedData).subscribe((x) => {
      this.toastr.success('Invoice successfully created.');
      this.getInvoiceList();
      this.spinner.hide();
    });
  }
  private getInvoiceList = () => {
    this.spinner.show();
    this.incomeService.GetRepeatingInvoices().subscribe((x) => {
      this.invoiceList = x;
      for(let i=0;i<this.invoiceList.length;i++) this.invoiceList[i].InvoiceNo = 'INV-' + this.invoiceList[i].InvoiceNo;
      this.reLoadRowData(this.Id);
      this.setCategoryCount();
      this.spinner.hide();
    }, err => {
      this.toastr.error('There is an error,please check');
      this.spinner.hide();
    });
  }
  formatInvoiceAmount(value){
    const valueData = Number(value.data.InvoiceAmount.toFixed(2));
    return valueData.toLocaleString();
  }
  private setCategoryCount() {
    this.categoryCount.all = this.invoiceList.length;
    this.categoryCount.daily = this.invoiceList.filter(x => x.RepeatingTypeId === 1 ).length;
    this.categoryCount.weekly = this.invoiceList.filter(x => x.RepeatingTypeId === 2 ).length;
    this.categoryCount.monthly = this.invoiceList.filter(x => x.RepeatingTypeId === 3 ).length;
    this.categoryCount.yearly = this.invoiceList.filter(x => x.RepeatingTypeId === 4).length;
   }
   public reLoadRowData = (catId: number) => {
      this.Id = catId;
      this.filteredInvoice = catId === 0 ? this.invoiceList : this.invoiceList.filter(y => y.RepeatingTypeId === catId);
    }

  convertGridDateIntoDisplay(date) {
      if (date.value == null){
       return '';
      }
      const dateString = moment(date.value).format('Do MMM YYYY');
      return dateString;
    }
}
