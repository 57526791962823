import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';

@Component({
  selector: 'app-sales-order-expense-details',
  templateUrl: './sales-order-expense-details.component.html',
  styleUrls: ['./sales-order-expense-details.component.css']
})
export class SalesOrderExpenseDetailsComponent implements OnInit {
  expenseGridApi;
  SalesOrderId: string;
  SalesOrderNo: string;
  totalExpense: number = 0;
  salesExpenses: any = [];
  SalesOrderData: any = [];
  
  constructor(
    private route: ActivatedRoute,
    private salesService: SalesOrderService,
    public fileEmitterService: FileEmitterService
  ) { }

  ngOnInit(): void {
    this.SalesOrderId = this.route.snapshot.paramMap.get("id");
    this.getOrderDetails(this.SalesOrderId);
  }

  getOrderDetails = (id: any) => {
    this.salesService.GetOrderDetails(id).subscribe((x) => {
      if (x) {
        this.fileEmitterService.emitFileRefIdUpdate({id: this.SalesOrderId,});
        this.SalesOrderData = x.data;
        this.SalesOrderId = this.SalesOrderData.Id;
        this.SalesOrderNo = x.data.OrderNo;
        this.getExpenses(this.SalesOrderId);
      }
    });
  }

  getExpenses = (id) => {
    this.salesService.GetSalesItemExpense(id).subscribe(
      (x) => {
        this.salesExpenses = x;
        this.salesExpenses = this.salesExpenses.filter((x) => x.ItemId != null);
        this.totalExpense = x.reduce(function (prev, cur) {
          return prev + cur.TotalAmount;
        }, 0);
      },
      (err) => {}
    );
  }

  expenseGridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
    columnDefs: [
      { headerName: "Item Code", field: "ItemCode", width: 130 },
      { headerName: "Item Name", field: "ItemName", width: 160 },
      { headerName: "Sold Quantity", field: "Quantity", width: 150 },
      { headerName: "Cost Price", field: "PurchasePrice", width: 150 },
      { headerName: "Amount", field: "TotalAmount", width: 150 },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
  }
  onExpenseGridReady = (event) => {
    this.expenseGridApi = event.api;
  }
}
