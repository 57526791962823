<app-dashboard>
    <div>
        <app-page-header-title mainTitle="{{ setMainTitle() }}"
            subTitle="Manufacture Formula List" 
            backURL="/manufacture/finished-product-material-formula-list/All">
            <div class="full-width text-align-right">
                <button type="button" (click)="editManufactureFormula()" *ngIf="canEditManufactureFormula()" class="eCount-def-btn ml-2">Edit</button>
                <button type="button" (click)="approveManufactureFormula()" *ngIf="canApproveManufactureFormula()" class="eCount-def-btn ml-4" style="background: #5cac00;">Approve</button>
                <button type="button" (click)="deleteManufactureFormula()" *ngIf="canDeleteManufactureFormula()" style="background: red;" class="eCount-def-btn ml-4">Delete</button>
            </div>
        </app-page-header-title>
    </div>
    <div class="headSection">
        <form [formGroup]="ProductFormulaForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-3">
                    <label for="">Product Name<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="FinishedProductId" disabled>
                </div>
                <div class="col-md-3">
                    <label for="">Fromula Name<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="FormulaId" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Quantity<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="Quantity" appDecimaNumericValidator disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Date<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="CreatedDate" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Reference</label>
                    <input class="form-control" type="text" formControlName="Reference" disabled>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px mt-5">
                <div class="d-flex">
                    <div class="col-md-3">
                        Item
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        Item Quantity
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="FormulaItems" *ngFor="let item of ProductFormulaForm.get('FormulaItems')['controls']; let i = index;">
                        <div class="row f-ss-14px" [formGroupName]="i">
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="RawMaterialId" disabled>
                            </div>
                            <div class="col-md-3"></div>
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="ItemQuantity" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <hr class="pb-0 mb-0 pt-0 mt-1">
                </div>
            </div>
        </form>
    </div>
</app-dashboard>