import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-monthly-tab',
  templateUrl: './monthly-tab.component.html',
  styleUrls: ['./monthly-tab.component.css']
})
export class MonthlyTabComponent implements OnInit {

  constructor(private router: Router) { }
@Input() message;
  ngOnInit(): void {
    console.log(this.message);
  }
  setTab(tabname: string) {
    this.router.navigate([`/${tabname}`]);
  }
}
