
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsServiceService } from '../../../service/Settings/settings-service.service';
import { TaxRateCreateComponent } from '../tax-rate-create/tax-rate-create.component';
import { TaxRateModel } from '../../../models/settings.model';

@Component({
  selector: 'app-tax-rate-list',
  templateUrl: './tax-rate-list.component.html',
  styleUrls: ['./tax-rate-list.component.css']
})
export class TaxRateListComponent implements OnInit {
  public aModel : TaxRateModel= new TaxRateModel();
  public dataList: any = [];
  constructor(
    private settingsService:SettingsServiceService,
    private toaster:ToastrService, 
    private spinner: NgxSpinnerService,
    private modalService:NgbModal) { }

  ngOnInit(): void {
    this.getDataList();
  }

  private getDataList = () => {
    this.spinner.show()
    this.settingsService.GetTaxDisplayList().subscribe((x) => {
      this.dataList = x;
      this.spinner.hide()
    },(err)=>{
      this.toaster.error("There is an error,please check");
      this.spinner.hide();
    })
  };


  public CreateNewTaxRate=()=>{
    const modalRef = this.modalService.open(TaxRateCreateComponent,{ size: 'md',backdrop: 'static', centered: true, keyboard : false});
    modalRef.componentInstance.fromParent = this.aModel;
    modalRef.result.then((result) => {
                   this.AddItemToList(result);
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  };

  /**
   * editAssetType
item:any   */
  public editTaxRate(item:TaxRateModel) {
    const modalRef = this.modalService.open(TaxRateCreateComponent,{ size: 'md', centered: true, backdrop: 'static', keyboard : false});
    modalRef.componentInstance.fromParent = item;
    modalRef.result.then((result) => {
                  this.UpdateItemInList(result);
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }

  private AddItemToList(x:any) {
    if(x.Success){
      this.dataList.push(x.Data);
     }
  }

  private UpdateItemInList=function(result: any) {
    var d=result.Data;
    if(result.Success){
      for(let i=0;i<this.dataList.length;i++){
        if(this.dataList[i].Id==d.Id){
          this.dataList[i]=d;
        }
      }
    }
  }

}
