import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ManufactureFormulaStatus } from 'src/app/enum/manufacture-formula-status';
import { ManufactureFormula } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';

@Component({
  selector: 'app-finished-product-material-formula-list',
  templateUrl: './finished-product-material-formula-list.component.html',
  styleUrls: ['./finished-product-material-formula-list.component.css']
})
export class FinishedProductMaterialFormulaListComponent implements OnInit {

  gridApi;
  FormulaList: ManufactureFormula[];
  draftFormulaList: ManufactureFormula[];
  approveFormulaList: ManufactureFormula[];
  filteredFormulaList: ManufactureFormula[];
  status: string;
  searchText: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private manufactureService: ManufactureService
  ) { }

  ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get("status");
    this.getFormulaList();
  }

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  search = () => {
    this.gridApi.setFilterModel(null);
    const searchText = this.searchText.toLowerCase();
    if (searchText.includes(',')) {
      const amount = parseInt(searchText.replace(',', ''), 10);
      this.gridApi.setQuickFilter(null);
      this.gridApi.setRowData(this.FormulaList?.filter(row => {
      }));
    } else {
      this.gridApi.setQuickFilter(searchText);
    }
  }

  getFormulaList() {
    this.manufactureService.getFormulaList().subscribe((x) => {
      this.FormulaList = x;
      this.draftFormulaList = this.FormulaList?.filter((x) => x.Status == ManufactureFormulaStatus.Draft);
      this.approveFormulaList = this.FormulaList?.filter((x) => x.Status == ManufactureFormulaStatus.Approved);
      this.pageLoad(this.status);
    });
  }

  public pageLoad(status: string) {
    this.status = status;
    switch (status) {
      case "All":
        this.filteredFormulaList = this.FormulaList;
        break;
      case "Draft":
        this.filteredFormulaList = this.draftFormulaList;
        break;
      case "Approve":
        this.filteredFormulaList = this.approveFormulaList;
        break;
    }
  }

  domLayout = "autoHeight";
  rowHeight = 275;
  ColumnDefs = [
    { headerName: "Formula Name",
      field: "FormulaName", 
      cellStyle: { color: "blue", cursor: "pointer" },
      cellRenderer: (params) => {
        return this.openManufactureFormulaDetails(params.data);
      }
    },
    { headerName: "Product Name", field: "Item.ItemName" },
    { headerName: "Quantity", field: "Quantity" },
    {
      headerName: "Date",
      field: "CreateDate",
      valueFormatter: this.convertDateIntoDisplay,
    },
    { headerName: "Reference No", field: "Reference" },
    {
      headerName: "Status", field: "Status",
      cellRenderer: (params) => { return this.formatStatus(params); }
    }
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
    scrollbarWidth: 0,
    resizable: true
  };

  onGridReady(event) {
    this.gridApi = event.api;
  }

  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }

  openManufactureFormulaDetails(formula: any){
    return `<a href="#/manufacture/manufacture-formula-details/${formula.Id}" >${formula.FormulaName}</a>`;
  }

  CreateFinishedProductMaterial() {
    this.router.navigate(['manufacture/create-finished-product-material-formula']);
  }

  formatStatus(data) {
    if(data.data.Status == ManufactureFormulaStatus.Draft) {
      return 'Draft';
    }
    else if(data.data.Status == ManufactureFormulaStatus.Approved) {
      return 'Approved';
    }
  }

}
