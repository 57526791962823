
<app-dashboard>
    <app-page-header-title
        mainTitle="Account Movement"
        subTitle="Back"
        backURL="/reports/statement-of-cashflow">

    </app-page-header-title>

    <div id="printGeneralReport" >
        <div class="customReportPage">
        <div class="report-header row" style="margin-bottom: 10px;">
            <div class="col-md-8">
            <div>
               <p class="txtsize">Account Movement</p>
           </div>
           <div class="row ml-0">
               <span>{{companyName}}</span>
               
               <p><span class="ml-2 mr-2 f-ss-14px">|</span>For the period {{convertDateIntoDisplay(fromDate)}} to {{convertDateIntoDisplay(toDate)}}</p>
               
           </div>
           
           </div>
           <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
               <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
           </div>
   
           </div>
        <div id="tableHeader" class="tableHeader d-flex">
            <div class="tableHeaderColumn col-md-2">
                <p class="tableHeaderText pt-1">Date</p>
            </div>
            <div class="tableHeaderColumn col-md-2">
                <p class="tableHeaderText pt-1">Source</p>
            </div>
            <div class="tableHeaderColumn col-md-3">
                <p class="tableHeaderText pt-1">Description</p>
            </div>
            <div class="tableHeaderColumn col-md-1 ">
                <p class="tableHeaderText pt-1" style="float: left;">Reference</p>
            </div>
            <div class="tableHeaderColumn col-md-1 ">
                <p class="tableHeaderText pt-1">Debit</p>
            </div>
            <div class="tableHeaderColumn col-md-1 ">
                <p class="tableHeaderText pt-1">Credit</p>
            </div>
            <div class="tableHeaderColumn col-md-1 ">
                <p class="tableHeaderText pt-1">Gross</p>
            </div>
            <div class="tableHeaderColumn col-md-1 ">
                <p class="tableHeaderText pt-1">Net</p>
            </div>
            
        </div>
        <div id="tableBody"  class="AccountSection" *ngFor="let item of dataList">
            <div class="x-grid-group-title  f-ssb-14px">
                <h1 style="border-bottom: 1px solid #999;margin-left: 20px;">{{item.Name}}</h1>
            </div>
            <div class="d-flex f-ss-14px normalRowSpaceing" *ngFor="let itemInner of item.Items">
                <div class="tableHeaderColumn2 col-md-2">
                    <span class="runningBalanceText">{{convertDateIntoDisplay(itemInner.TransactionDate)}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-2">
                    <span class="tableHeaderText">{{itemInner.Source}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-3">
                    <span class="tableHeaderText">{{itemInner.Description}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="tableHeaderText">{{itemInner.Reference}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1 ml-2">
                    <span class="float-right">{{itemInner.Debit ? itemInner.Debit : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.Credit ? itemInner.Credit : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.GrossAmount ? itemInner.GrossAmount : '-'}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{itemInner.NetAmount ? itemInner.NetAmount : '-'}}</span>
                </div>

            </div>

            <div class="d-flex normalRowSpaceing f-ss-14px">
                <div class="tableHeaderColumn2 col-md-8">
                    <span class="runningBalanceText font-weight-bold colorBlack">Total {{item.Name}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1 ml-2">
                    <span class="float-right">{{item.Debit}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right font-weight-bold colorBlack">{{item.Credit}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right font-weight-bold colorBlack">{{item.GrossAmount}}</span>
                </div>
                <div class="tableHeaderColumn2 col-md-1">
                    <span class="float-right">{{item.NetAmount}}</span>
                </div>
            </div>
           
        </div>

        <div class="paddingTopBottom mr-t-20px d-flex f-ss-14px" style="background-color: #f9f9f9;">
            <div class="tableHeaderColumn2 col-md-8">
                <span class="runningBalanceText">Total</span>
            </div>

            <div class="tableHeaderColumn2 col-md-1 ml-1">
                <span class="float-right">{{totalDebit}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalCredit}}</span>
            </div>

            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalGrossAmount}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="float-right">{{totalNetAmount}}</span>
            </div>
        </div>

    </div>
    </div>
</app-dashboard>

