<app-dashboard>
    <app-page-header-title subTitle="Home" mainTitle="Balance Sheet" backURL="/"> 
    </app-page-header-title>
    <div class="row d-block mrl">
        <div class="col-md-12 d-flex">
        <div class="form-group col-md-3">
            <input class="form-control" style="outline: none;" type="date" [(ngModel)]="toDate" id="bstest" data-date="" data-date-format="D MMM YYYY"  (change)="show($event)">
        </div>
        <button type="button" (click)="reportGenerate()" class="eCount-def-btn ml-4">Generate Report</button>
    </div>
    </div>
    <div id="balancesheet">
        <div class="customReportPage">
        <div class="report-header row" style="margin-bottom: 10px;">
            <div class="col-md-8">
            <div>
               <p class="txtsize">Balance Sheet</p>
           </div>
           <div class="row ml-0">
               <span class="f-ss-14px">{{companyName}}</span>
               
               <p class="f-ss-14px"><span class="ml-2 mr-2 f-ss-14px">|</span>{{convertDateIntoDisplay(toDate)}}</p>
               
           </div>
           
           </div>
           <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
               <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
           </div>
   
           </div>
       <div class="AssetSection">

        <span *ngIf="AssetList.length>0">Assets</span>
        <hr />
        <div  *ngFor="let asset of AssetList">
           
            <div class="paddingBottom d-flex">
                <div class="tableHeaderColumn2 dateColumn">
                    <span class="runningBalanceText font-weight-bold">{{asset.HeaderName}}</span>
                </div>
            </div>

            <div  *ngFor="let item of asset.Items">
                <div  class="d-flex normalRowSpaceing" *ngIf="!item.IsBold">
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText ml-3">{{item.Name}}</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="runningBalanceText text-link" (click)="openAccountTransaction(item.AccountId)">{{item.ValueView}}</span>
                </div>
            </div>
            <div *ngIf="item.IsBold" class="padtop d-flex paddingBottom">
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText ml-3 font-weight-bold colorBlack">{{item.Name}}</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="totText">{{item.ValueView}}</span>
                </div>
            </div>
            </div>

          </div>
            <div class="d-flex normal" *ngIf="AssetList.length>0">
            <div class="tableHeaderColumn2" style="width: 100%;">
                <span class="runningBalanceText">Total Asset</span>
            </div>
            <div>
                <p class="float-right totText">{{TotalAssets}}</p>
            </div>
           </div>
                   
        </div>
        <div class="AssetSection mt-4">

            <span *ngIf="Liabilities.length>0">Liabilities</span>
            <hr />
        <div *ngFor="let lib of Liabilities">
            <div class="paddingBottom d-flex">
                <div class="tableHeaderColumn2 dateColumn">
                    <span class="runningBalanceText font-weight-bold">{{lib.HeaderName}}</span>
                </div>
            </div>
           <div  *ngFor="let item of lib.Items">
            <div  class="d-flex normalRowSpaceing" *ngIf="!item.IsBold" >
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText ml-3">{{item.Name}}</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="runningBalanceText text-link" (click)="openAccountTransaction(item.AccountId)">{{item.ValueView}}</span>
                </div>
            </div>
            <div  class="d-flex padtop paddingBottom" *ngIf="item.IsBold" >
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText ml-3 font-weight-bold colorBlack">{{item.Name}}</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="totText">{{item.ValueView}}</span>
                </div>
            </div>
        </div>
    </div>
            <div class="d-flex normal" *ngIf="Liabilities.length>0">
            <div class="tableHeaderColumn2" style="width: 100%;">
                <span class="runningBalanceText font-weight-bold">Total Liabilities</span>
            </div>
            <div>
                <p class="float-right totText">{{totalLiabilities}}</p>
            </div>
           </div>
           
            <div class="d-flex normal ">
                
                <div class="tableHeaderColumn2" style="width: 100%;">
                    <span class="runningBalanceText">Net Assets</span>
                </div>
                <div class="tableHeaderColumn2">
                    <p class="float-right totText">{{NetAssets}}</p>
                </div>
            
           
           </div>

        </div>

        <div class="AssetSection mt-4">

            <span *ngIf="EquityList.length>0">Equity</span>
            <hr />
        <div *ngFor="let eq of EquityList">
            <div class="normalRowSpaceing d-flex ml-3" *ngIf="!eq.IsBold">
                <div class="tableHeaderColumn2 dateColumn"  style="width: 100%;">
                    <span class="runningBalanceText">{{eq.Name}}</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="runningBalanceText" style="color:blue!important">{{eq.ValueView}}</span>
                </div>
            </div>
            <div class="padtop d-flex" *ngIf="eq.IsBold">
                <div class="tableHeaderColumn2 dateColumn"  style="width: 100%;">
                    <span class="runningBalanceText font-weight-bold colorBlack">{{eq.Name}}</span>
                </div>
                <div class="tableHeaderColumn2">
                    <span class="totText">{{eq.ValueView}}</span>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
</app-dashboard>