import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Coupons } from 'src/app/models/coupons.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CouponsserviceService {

  baseurl: string = environment.baseUrl;

  constructor(
    private http: HttpClient
  ) {}

  public verifyAndGetCouponInformation(data: Coupons): Observable<any>{
    return this.http.post<Coupons>(this.baseurl + 'Coupons/VerifyAndGetCouponInformation', data);
  }

  public deductCouponUseLimit(data: Coupons): Observable<any>{
    return this.http.post<Coupons>(this.baseurl + 'Coupons/DeductCouponUseLimit', data);
  }

  public getCouponInformationsByName(couponName: string): Observable<any>{
    return this.http.get<Coupons>(this.baseurl + `Coupons/GetCouponInformationsByName?couponName=${couponName}`);
  }

  public updateCouponTotalUsed(data: Coupons): Observable<any>{
    return this.http.post<Coupons>(this.baseurl + 'Coupons/UpdateCouponTotalUsed', data);
  }

}
