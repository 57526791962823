import { Component, OnInit } from '@angular/core';
import {BillsServiceService} from '../../../service/Bill/bills-service.service';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BudgetItem } from 'src/app/models/income.model';
@Component({
  selector: 'app-monthly-budget',
  templateUrl: './monthly-budget.component.html',
  styleUrls: ['./monthly-budget.component.css']
})
export class MonthlyBudgetComponent implements OnInit {

  constructor(private billService: BillsServiceService,
              private toastr: ToastrService,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService) { }
  currentYear: any;
  Month: any;
  Year: any;
  ifSubmit = false;
  message: string;
  paidFromBank: any;
  paidFromCash: any;
  accounts: any = [];
  AccountId: any;
  BudgetId: number;
  Expense: any = [];
  isApprove: boolean = false;
  budgetData: any;
  public BudgetForm: FormGroup;
  public MonthlyBudgetItems: any = [];
  BudgetList: any = [];
  currentMonth: number;
  loading = false;
  chartOfAccounts: any = [];
  monthlyExpense: any = [];
  date = new Date().getFullYear();
  totalAmount = 0;
  yearList = [
   {Value: this.date - 1 , year: this.date - 1},
   {Value: this.date , year: this.date},
  ];
  monthList = [
   { Value: 1, Text: 'January' },
   { Value: 2, Text: 'February' },
   { Value: 3, Text: 'March' },
   { Value: 4, Text: 'April' },
   { Value: 5, Text: 'May' },
   { Value: 6, Text: 'June' },
   { Value: 7, Text: 'July' },
   { Value: 8, Text: 'August' },
   { Value: 9, Text: 'September' },
   { Value: 10, Text: 'October' },
   { Value: 11, Text: 'November' },
   { Value: 12, Text: 'December' }
 ];
  ngOnInit(): void {
    this.Year = this.date;
    this.Month = new Date().getMonth() + 1;
    this.currentMonth = this.Month;
    this.currentYear = this.Year;
    this.getData();
    this.getTransactionDetails();
  }

   getData = () => {
     this.billService.GetBudgetChartOfAccounts().subscribe((x: any) => {
      this.chartOfAccounts = x;
      console.log(this.chartOfAccounts);
      this.chartOfAccounts = this.chartOfAccounts.filter(x => x.GroupName === 'EXPENSES' || x.GroupName === 'ASSETS');
     });
   }

  //  selectAccount = (item, it) => {
  //    var data = [];
  //    console.log(item);
  //    for (let i = 0; i < this.BudgetList.length ; i++){
  //       if (i === it)
  //       {
  //         this.BudgetList[i] = item;

  //       }
  //    }
  //  }
   public async deleteItem(i: number){
        this.MonthlyBudgetItems.splice(i, 1);
        this.updateTotal();

  }
  checkValidation(items){
    for (const i in items)
    {
      if (items[i].AccountId === null){
        return false;
      }
    }
    return true;
  }

  setData(id)
  {
      const data = {
        Id: this.BudgetId,
        Month: this.Month,
        Year: this.Year,
        StatusId: id,
        MonthlyBudgetItems: this.MonthlyBudgetItems
      };
      return data;
  }

saveBudget = (statusId) => {
  const data = this.setData(statusId);
  this.ifSubmit = true;
  //console.log("this is my monthly budget items=> ",this.MonthlyBudgetItems)
  const res = this.checkValidation(data.MonthlyBudgetItems);
  console.log(res);
  if (!res)
  {
    this.toastr.warning('Please select an account');
    return;
  }
  this.spinner.show();
  this.billService.saveMonthlyBudget(data).subscribe((x) => {

      if (x.Success){
        this.spinner.hide();
        this.toastr.success(x.Message);
        this.getTransactionDetails();
      }
      else
      {
        this.spinner.hide();
      }
     });
  }

  public createItem(x){

    return this.fb.group({
      Id: [0],
      BudgetId: [0],
      BudgetAmount: ['', Validators.required],
      AccountId: [x.AccountId, Validators.required],
    });
  }

  public addItems(x: any): void {
      this.MonthlyBudgetItems = this.BudgetForm.get('MonthlyBudgetItems') as FormArray;
      this.MonthlyBudgetItems.push(this.viewItem(x));
    }
  public viewItem(x: any): FormGroup {
    console.log(x.AccountId);
    return this.fb.group({
        Id: [x.Id],
        AccountId: [x.AccountId],
        BudgetId: [x.BudgetId],
        BudgetAmount: [x.BudgetAmount]
      });
    }

    async findThisMonthData(){
      console.log("first");
      this.billService.GetMonthlyBudget(this.Month, this.Year).pipe().toPromise().then(x=> {
        console.log('this is my data', x)
        if (x.Id > 0) {
          this.isApprove = x.StatusId === (1 || 0 ) ? false : true;
          this.MonthlyBudgetItems = x.MonthlyBudgetItems;
          this.BudgetId = x.Id;
          this.updateTotal();
          this.spinner.hide();
        }
        else{
          let previousMonth = Number(this.Month)==1? 12:Number(this.Month)-1;
          let previousYear = Number(this.Month)==1? Number(this.Year)-1:Number(this.Year);
          console.log(previousMonth,' ', previousYear);
          this.billService.GetMonthlyBudget(previousMonth, previousYear).pipe().subscribe((x) => {
            if (x.Id > 0) {
            this.isApprove = false;
            this.MonthlyBudgetItems = x.MonthlyBudgetItems;
            for(let i=0;i<this.MonthlyBudgetItems.length;i++) this.MonthlyBudgetItems[i].Id = 0;
            this.BudgetId = 0;
            this.updateTotal();
            this.spinner.hide();
            }
            else{
              this.isApprove = false;
              this.MonthlyBudgetItems = [];
              this.BudgetId = 0;
              const date = new Date().getFullYear();
              this.currentMonth = new Date().getMonth() + 1;
              this.currentYear = date;
              this.updateTotal();
              this.spinner.hide();
            }
          });
        }
      });
    }

    getTransactionDetails = async() => {
      console.log(this.currentMonth);
      this.getData();
      this.spinner.show();
      await this.findThisMonthData();
    }

showAllRows() {
  if (this.MonthlyBudgetItems.length === 0){
    console.log("Coming here \n", this.chartOfAccounts)
    for(let i=0;i< this.chartOfAccounts.length;i++){
          const v = this.add(this.chartOfAccounts[i]);
          console.log(v);
          this.MonthlyBudgetItems.push(v);
    }
  }
  else{
    for(let i=0; i < this.chartOfAccounts.length; i++){
        const value = this.MonthlyBudgetItems.find(x => x.AccountId === this.chartOfAccounts[i].Id);
        if (value === undefined){
          const v = this.add(this.chartOfAccounts[i]);
          this.MonthlyBudgetItems.push(v);
        }
    }
  }
}

add(x)
{
      const item = {Id: 0, BudgetId: this.BudgetId, AccountId: x.Id , BudgetAmount: 0};

      return item;
  }

  removeZeroBalance()
  {
    const list = [];
    for (let i = 0; i < this.MonthlyBudgetItems.length; i++)
    {
        if (this.MonthlyBudgetItems[i].BudgetAmount > 0)
        {
          list.push(this.MonthlyBudgetItems[i]);
        }
    }
    this.MonthlyBudgetItems = list;
  }

  async addItem()
  {

      const item = {Id: 0, BudgetId: this.BudgetId, AccountId: null, BudgetAmount: 0};

      this.MonthlyBudgetItems.push(item);
  }

updateTotal()
{
  this.totalAmount = 0;
  for(let i=0; i< this.MonthlyBudgetItems.length; i++)
  {
    this.totalAmount += this.MonthlyBudgetItems[i].BudgetAmount;
  }
}


updateTotalAmount(i)
{
  this.totalAmount += Number(this.MonthlyBudgetItems[i].BudgetAmount);
}
onFocusEvent(event: any, i){
  event.target.value = event.target.value == 0 ? null : event.target.value;
}

  async duplicateCheck(i:number){
    let existing = this.MonthlyBudgetItems.filter(x=>x.AccountId==this.MonthlyBudgetItems[i].AccountId);
    console.log(existing, "\n", i);
    if(existing.length>1){
      this.toastr.error('Already in the list.');
      await this.deleteItem(i);
      await this.addItem();
      return;
    }
  }

  removeAllBalance(){
    while(this.MonthlyBudgetItems.length>0){
      this.deleteItem(0);
    }
  }

  currencyFormatter(currency, sign) {
    var sansDec = currency.toFixed(2);
    var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return sign + `${formatted}`;
  }

}
