import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service';
import { AccountTypes } from '../../../models/accounting.model'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-add-account-component',
  templateUrl: './add-account-component.component.html',
  styleUrls: ['./add-account-component.component.css']
})
export class AddAccountComponentComponent implements OnInit {
  addAccountForm: FormGroup;
  accountTypes: AccountTypes[];

  constructor(private accountingService: AcountingServiceService, public fb: FormBuilder, private toastr: ToastrService, public ngxSmartModalService: NgxSmartModalService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.getAccountTypes();
    this.addAccountForm = this.fb.group({
      AccountTypeId: [1],
      AccountCode: [''],
      AccountName: [''],
      AccountGroupId: [],
      ShowInExpenseClaim:[false]
    });
  }

  getAccountTypes=()=>{
    this.accountingService.GetAccountTypes().subscribe((x: AccountTypes[])=>{
      this.accountTypes = x
    });
  }
  
  changeAccountType=(event)=>{
    let AccountGroupId = event.ParentId
    this.addAccountForm.patchValue({ AccountGroupId });
  }

  submitAddAccountForm=()=>{
    this.spinner.show();
    console.log(this.addAccountForm.value);
    this.accountingService.AddChartOfAccount(this.addAccountForm.value).subscribe((x)=>{
      if (x.Data != null){
        this.toastr.success('Account Successfully Added !', 'Success!');
        this.router.navigate(['accounting/chart-of-accounts']);
      }else{
        this.toastr.error('Account code already exists or something else went wrong!');
      }
      this.spinner.hide();
    },(err)=>{ this.spinner.hide() })
  }

  closeModal=()=>{
    this.ngxSmartModalService.getModal('AddAccountComponent').close();
  }
}
