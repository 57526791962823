import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DocumentStatus } from 'src/app/enum/document-status';
import { DocumentAdvanceSearchViewModel } from 'src/app/models/documentAdvanceSearch.model';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { DocumentServiceService } from 'src/app/service/DocumentService/document-service.service';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { SubscriptionService } from 'src/app/service/Subscription/subscription.service';
import { environment } from 'src/environments/environment';
import { DocumentInfoModalComponent } from '../document-info-modal/document-info-modal.component';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/service/auth/auth.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-document-advance-search',
  templateUrl: './document-advance-search.component.html',
  styleUrls: ['./document-advance-search.component.css']
})
export class DocumentAdvanceSearchComponent implements OnInit {

  SearchForm: FormGroup;
  allTags: any[] = [];
  allDocumentTypes: any[] = [];
  allDocumentTypeGroups: any[] = [];
  countList: any[] = [];
  selectedCheckBoxList = [];
  statusList: any = [
    { Id: DocumentStatus.DRAFT, StatusName: 'Draft'},
    { Id: DocumentStatus.AWAITINGAPPROVAL, StatusName: 'Awaiting Approval'},
    { Id: DocumentStatus.APPROVED, StatusName: 'Approved'},
    { Id: DocumentStatus.REJECTED, StatusName: 'Rejected'},
    { Id: DocumentStatus.ARCHIVE, StatusName: 'Archive'}
  ];

  files: any;
  filteredFiles: any;
  submittedAttachments: any;
  changeOnInventory = false;
  selected: any;
  rotationRightAmount: number;
  rotationLeftAmount: number;
  isPdf: boolean;
  src: string;
  fileUrl = environment.fileBaseUrl;
  imagezooming: number;
  url: string="";
  users: [];
  faInfoCircle = faInfoCircle;
  name: string = undefined;
  searchText: string = "";
  zoom: number = 1;
  rotationAmount: number;

  status: any;
  tempFiles: any;
  seeMoreOption: boolean;
  seeGroups: boolean = false;

  dynamicLabelNameForDocumentInputsList: any[];

  documentLabel1Name: string;
  documentLabel2Name: string;
  documentLabel3Name: string;
  documentLabel4Name: string;
  documentLabel5Name: string;
  documentLabel6Name: string;

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private attachmentService: AttachmentServiceService,
    private ngxSmartModalService: NgxSmartModalService,
    private mainService: MainServiceService,
    private documentService: DocumentServiceService,
    private modalService: NgbModal,
    private authService: AuthService,
    private settingsService: SettingsServiceService
  ) { }

  ngOnInit(): void {
    this.getAllTags();
    this.getAllDocumentTypes();
    this.getAllDocumentTypeGroups();
    this.initializeForm();
    this.getAllDynamicLabelNameForDocumentInputs();
  }

  getAllTags() {
    this.subscriptionService.GetAllTags().subscribe((x) => {
      this.allTags = x;
    });
  }

  getAllDocumentTypes() {
    this.settingsService.GetAllDocumentTypes().subscribe((x) => {
      this.allDocumentTypes = x;
    });
  }

  getAllDocumentTypeGroups() {
    this.settingsService.GetAllDocumentTypeGroups().subscribe((x) => {
      this.allDocumentTypeGroups = x;
    });
  }

  initializeForm() {
    this.SearchForm = this.fb.group({
      FromDate: ['', Validators.required],
      ToDate: ['', Validators.required],
      selectedTags:[],
      Reference1: [''],
      Reference2: [''],
      DocumentTypeId: [],
      EmployeeId: [],
      StatusList: this.fb.array([])
    });
    const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    this.SearchForm.get('FromDate').patchValue(thirtyDaysAgo);
    this.showSelectedFromDate(this.SearchForm.value.FromDate);

    this.SearchForm.get('ToDate').patchValue(new Date());
    this.showSelectedToDate(this.SearchForm.value.ToDate);
  }

  reLoadRowData(data: any){
    let groupId = this.allDocumentTypeGroups.find(x => x.Name == data).Id;
    this.filteredFiles = this.files.filter(x => x.DocumentTypeGroupId == groupId);
  }

  controlOnChange(e) {
    const statusList: FormArray = this.SearchForm.get('StatusList') as FormArray;
  
    if (e.target.checked) {
      statusList.push(new FormControl(e.target.value));
      this.selectedCheckBoxList.push(e.target.value);
    }
    else {
       const index = statusList.controls.findIndex(status => status.value == e.target.value);
       statusList.removeAt(index);
    }
  }

  Search() {
    if(!this.SearchForm.valid) {
      this.toaster.warning('Please fill up the required field!');
      return;
    }
    const data: DocumentAdvanceSearchViewModel = this.MappedData();
    this.spinner.show();
    this.filteredFiles = [];
    this.attachmentService.getAllTagsAttachmentByAdavnceSearch(data).subscribe(x => {
      this.files = x;
      this.seeGroups = true;
      if(this.allDocumentTypeGroups.length != 0) {
        this.allDocumentTypeGroups.map((item) => {
          let groupLength = x.filter(d => d.DocumentTypeGroupId == item.Id).length;
          this.countList.push(groupLength);
        });
      }
      else{
        this.filteredFiles = x;
      }
      if(x?.length > 0)
        this.toaster.success('Documents search successfully');
      else
        this.toaster.success('Documents not found');
      this.spinner.hide();
    });
  }

  MappedData() {
    const documentAdvanceSearch = new DocumentAdvanceSearchViewModel();
    documentAdvanceSearch.FromDate = this.SearchForm.value.FromDate;
    documentAdvanceSearch.ToDate = this.SearchForm.value.ToDate;
    documentAdvanceSearch.selectedTags = this.SearchForm.value.selectedTags;
    documentAdvanceSearch.Reference1 = this.SearchForm.value.Reference1 == '' ? null : this.SearchForm.value.Reference1;
    documentAdvanceSearch.Reference2 = this.SearchForm.value.Reference2 == '' ? null : this.SearchForm.value.Reference2;
    documentAdvanceSearch.EmployeeId = this.SearchForm.value.EmployeeId == '' ? null : this.SearchForm.value.EmployeeId;
    documentAdvanceSearch.StatusList = this.SearchForm.value.StatusList;
    documentAdvanceSearch.DocumentTypeId = this.SearchForm.value.DocumentTypeId == null ? 0 : this.SearchForm.value.DocumentTypeId;
    documentAdvanceSearch.IsCompanyHasOnlyDocumentFeature = this.authService.getUserInfo()?.IsCompanyHasOnlyDocumentFeature;
    return documentAdvanceSearch;
  }

  showSelectedFromDate(e: Event) {
    const value = this.SearchForm.value.FromDate;
    $('#fromDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showSelectedToDate(e: Event) {
    const value = this.SearchForm.value.ToDate;
    $('#ToDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  mouseOver(id: number) {
    let element;
    element = document.getElementById("hover" + id);
    let element1 = document.getElementById("demo" + id);
    element1.classList.add("mdivpaddinghover");
    element1.classList.remove("mdivpadding");
    element.classList.add("mystyle");
    let element2 = document.getElementById("imagesview" + id)
    element2.classList.add("imagediv-hover");
    document.getElementById("options" + id).classList.add("show");
    document.getElementById("options" + id).classList.remove("hide");
  }

  mouseOut(id: number) {
    if(this.submittedAttachments.find(x=>x.BlobName==id)) return;
    let element;
    element = document.getElementById("hover" + id);
    let element1 = document.getElementById("demo" + id);
    element1.classList.remove("mdivpaddinghover");
    element1.classList.add("mdivpadding");
    element.classList.remove("mystyle");
    let element2 = document.getElementById("imagesview" + id);
    element2.classList.remove("imagediv-hover");
    document.getElementById("options" + id).classList.remove("show");
    document.getElementById("options" + id).classList.add("hide");
  }

  isPdfFile(file: any) {
    let ext = file.BlobName.split(".")[1];
    return ext == 'pdf' ? true : false;
  }

  convertDateToDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  pressDocument(item:any){
    console.log(item);
    if(this.submittedAttachments.find(x=>x.BlobName==item.BlobName)){
      this.submittedAttachments = this.submittedAttachments.filter(x=>x.BlobName!=item.BlobName);
      this.mouseOut(item.BlobName);
    }
    else{
      this.submittedAttachments.push(item);
      this.mouseOver(item.BlobName);
    }
  }

  view(file: any) {
    this.selected = file;
    this.rotationRightAmount = 0;
    this.rotationLeftAmount = 0;
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
    this.imagezooming = 100;
    this.ngxSmartModalService.create('AttachmentViewer', 'content').open();
    let img = document.getElementById('image');
    img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
  }

  print(file: any, id: any, pdf: boolean){
    window.open(`${this.url}files/print/${file.Id}`, '_blank');
  }

  share(file: any) {
    this.selected = file;
    this.getAllUsers();
    let ext = file.FileName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
  }

  getAllUsers() {
    this.spinner.show();
    this.users = [];
    this.mainService.GetSubscribedUserList().subscribe((x) => {
      this.spinner.hide();
      this.users = x;
    });
  }

  documentModalView(data:any){
    this.spinner.show();
    console.log('coming here', data);
    let modalData = {
      DocumentBlobName:data.BlobName,
      DocumentName: data.FileName,
      DocumentType: data.ReferenceNo,
      Number: data.Id,
      UploadedAt: data.CreatedDate,
      UploadedBy: data.UpdatedByName,
      openFrom: 'overview',
      ApprovalStatus: data.ApprovalStatus
    }
    this.documentService.saveModalInfoData(modalData);
    if(data.ApprovalStatus==2) this.documentService.setSeeFullHistory(true);
    else this.documentService.setSeeFullHistory(false);
    const modalRef = this.modalService.open(DocumentInfoModalComponent,
    { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
  }

  deleteCurrDocFile(file:any){
    this.attachmentService.deleteCurrDocFile(file).subscribe((x) => {
      console.log(x);
      this.toaster.success("Updated successfully", 'Success!');
      location.reload();
    }, (err) => {
      console.log(err);
    });
  }

  documentModalViewFullHistory(data:any){
    this.spinner.show();
    console.log('coming here', data);
    let modalData = {
      DocumentBlobName:data.BlobName,
      DocumentName: data.FileName,
      DocumentType: data.ReferenceNo,
      Number: data.Id,
      UploadedAt: data.CreatedDate,
      UploadedBy: data.UpdatedByName,
      openFrom: 'overview',
      ApprovalStatus: data.ApprovalStatus
    }
    this.documentService.saveModalInfoData(modalData);
    this.documentService.setSeeFullHistory(true);
    const modalRef = this.modalService.open(DocumentInfoModalComponent,
    { size: 'xl', backdrop: 'static', keyboard : false, centered: true });
  }

  shareFile(id:number){
    var userInfo = this.authService.getUserInfo();
    let SelectedCompany = userInfo.SelectedCompany;
    let CompanyId = userInfo.SelectedCompany.CompanyId;
    let Name = userInfo.SelectedCompany.Name;
    this.attachmentService.shareFile({ BlobName: this.selected.BlobName, SharedToId: id,
      ReferenceNo: this.selected.ReferenceNo, FileName: this.selected.FileName, Parent: this.name }).subscribe((x) => {
      console.log(x);
      this.toaster.success("Shared successful", 'Success!');
    }, (err) => {
      console.log(err);
    });
  }

  zoomOut() {
    if (this.isPdf) {
      this.zoom -= .1;
    } else {
      this.imagezooming -= 10;
      let element = document.getElementById("image")
      element.style.width = this.imagezooming + "%";
    }
  }

  zoomIn() {
    if (this.isPdf) {
      this.zoom += .1;
    } else {
      this.imagezooming += 10;
      let element = document.getElementById("image");
      element.style.width = this.imagezooming + "%";
    }
  }

  rotate(direction: string) {
    this.rotationAmount = +90;
    if (direction == 'left') {
      this.rotationRightAmount -= 90;
      this.rotationLeftAmount += 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    } else {
      this.rotationRightAmount += 90;
      this.rotationLeftAmount -= 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }
  }

  IsNull(data: any){
    return this.mainService.IsNull(data);
  }

  getAllDynamicLabelNameForDocumentInputs() {
    this.settingsService.GetAllDocumentInputFieldDynamicLabelName().subscribe((x) => {
      this.dynamicLabelNameForDocumentInputsList = x;
      this.documentLabel1Name = this.dynamicLabelNameForDocumentInputsList[0]?.LabelName;
      this.documentLabel2Name = this.dynamicLabelNameForDocumentInputsList[1]?.LabelName;
      this.documentLabel3Name = this.dynamicLabelNameForDocumentInputsList[2]?.LabelName;
      this.documentLabel4Name = this.dynamicLabelNameForDocumentInputsList[3]?.LabelName;
      this.documentLabel5Name = this.dynamicLabelNameForDocumentInputsList[4]?.LabelName;
      this.documentLabel6Name = this.dynamicLabelNameForDocumentInputsList[5]?.LabelName;
    });
  }

}
