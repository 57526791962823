import { Component, OnInit ,OnDestroy,NgZone} from '@angular/core';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-factory-out-list',
  templateUrl: './factory-out-list.component.html',
  styleUrls: ['./factory-out-list.component.css']
})
export class FactoryOutListComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private pService: FactoryProductionService) { }

private gridApi;
private gridColumnApi;
domLayout='autoHeight';
isItemListUpdate = false;
searchText:string;
TransactionList: any = [];
status: any;
data: any;
ngOnInit(): void {
this.status = this.route.snapshot.paramMap.get('status');
this.getTransactions();
}

columnDefs = [
{ headerName: 'Transaction No', field: 'TransactionNo',
  cellRenderer: function(params) {
    return `<a href="#/inventory/factory-out-view/${params.value}" >${params.value}</a>`;
  }
},
{ headerName: 'Out Date', field: 'OutDate', valueFormatter: this.convertGridDateIntoDisplay },
{ headerName: 'Batch No', field: 'BatchNo' },
{ headerName: 'Out Qty', field: 'ProductOutQuantity' },
{ headerName: 'Material Cost/Unit', field: 'PerUnitMaterialCost' },
{ headerName: 'Total Material Cost', field: 'TotalMaterialCost' },
{ headerName: 'Operating Cost/Unit', field: 'PerUnitOperatingCost' },
{ headerName: 'Production Cost/Unit', field: 'PerUnitProductionCost' },
{ headerName: 'Total Production Cost', field: 'TotalProductionCost' }
];

defaultColDef = {
sortable: true,
filter: true,
flex: 1
};
convertGridDateIntoDisplay(date) {
let dateString = moment(date.value).format("Do MMM YYYY")
return dateString;
}

getTransactions = () => {

this.spinner.show();
this.TransactionList = [];
this.pService.GetProductionOutTransactions().subscribe((x) => {
this.spinner.hide();
this.TransactionList = x;
this.data = x;
this.TransactionList = this.status == 'Draft' ? x.filter(xy => xy.StatusId === 1) : this.status == 'Approved' ?
x.filter(xy => xy.StatusId === 2) :  x ;
});
}
onGridReady(event) {
this.gridApi = event.api;
this.gridColumnApi = event.columnApi;
this.getTransactions();
}

onBtnExport() {
var params = this.getParams();
this.gridApi.exportDataAsCsv(params);
}

getParams() {
return {
suppressQuotes: '',
columnSeparator: '',
customHeader: '',
customFooter: ''
};
}

onRowClicked(event){

}

search = () => {
this.gridApi.setQuickFilter(this.searchText);
}
reLoadRowData = (status: string) => {
  this.status = status;
  this.TransactionList = this.status == 'Draft' ? this.data.filter(xy => xy.StatusId == 1) : this.status == 'Approved' ?  
  this.data.filter(xy => xy.StatusId == 2) :  this.data ;
}

}

