<!-- Main sidebar -->
<div class="pb-4 sidebar sidebar-light sidebar-main sidebar-expand-md sideBarHeight"
  [ngClass]="{ 'd-none': !showSidebar }">
  <!-- Sidebar mobile toggler -->
  <div class="text-center sidebar-mobile-toggler">
    <a href="#" class="sidebar-mobile-main-toggle">
      <i class="icon-arrow-left8"></i>
    </a>
    Navigation
    <a href="#" class="sidebar-mobile-expand">
      <i class="icon-screen-full"></i>
      <i class="icon-screen-normal"></i>
    </a>
  </div>
  <!-- /sidebar mobile toggler -->

  <!-- Sidebar content -->
  <div class="pb-4 sidebar-content">
    <!-- Main navigation -->
    <div class="card card-sidebar-mobile">
      <div id="sidebar" class="sidebar-scroll">
        <ul class="nav f-ss-14px nav-sidebar" data-nav-type="accordion">
          <li class="nav-item liBoarder">
            <a class="nav-link navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
              <i class="icon-paragraph-justify3"></i>
            </a>
          </li>
          <li class="nav-item liBoarder" *ngIf="!IsOnlyHasDocumentFeature()">
            <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              class="nav-link">
              <i class="icon-home4"></i>
              <span>
                {{ 'SIDEBAR.dashboard' | translate }}
              </span>
            </a>
          </li>
          <li class="nav-item liBoarder" *ngIf="isAdmin()">
            <a [routerLink]="['/my-pending-list-dashboard']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              class="nav-link">
              <i class="icon-table"></i>
              <span>
                My Pending Approval
              </span>
            </a>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: myItemsList?.length == 0 }">
            <a href="#" class="nav-link">
              <i class="icon-list2"></i>
              <span>My Items</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Expense" style="display: none;">
              <div *ngFor="let item of myItemsList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ IsOnlyHasDocumentFeature() ? '/pending-list/document' : item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: businessPartnerList?.length == 0 }">
            <a href="#" class="nav-link">
              <i class="icon-user"></i>
              <span>{{ 'SIDEBAR.businessPartner' | translate }}</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Expense" style="display: none;">
              <div *ngFor="let item of businessPartnerList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: billList?.length == 0 }">
            <a href="#" class="nav-link">
              <i class="icon-credit-card"></i>
              <span>{{ 'SIDEBAR.procurement' | translate }}</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Expense" style="display: none;">
              <div *ngFor="let item of billList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: invoiceList?.length == 0 }">
            <a href="#" class="nav-link">
              <i class="icon-archive"></i>
              <span>{{ 'SIDEBAR.sales' | translate }}</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Income" style="display: none;">
              <div *ngFor="let item of invoiceList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: advanceList?.length == 0 }">
            <a href="#" class="nav-link">
              <i class="icon-calculator3"></i>
              <span>Advance/Loan</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Advance" style="display: none;">
              <div *ngFor="let item of advanceList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: InventoryList?.length == 0 }">
            <a href="#" class="nav-link">
              <i class="inven"></i>
              <span>{{ 'SIDEBAR.inventory' | translate }}</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Project" style="display: none;">
              <div *ngFor="let item of InventoryList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: manufactureMenuList?.length == 0 }">
            <a href="#" class="nav-link">
              <i class="icon-office"></i>
              <span>{{ 'SIDEBAR.manufacture' | translate }}</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Project" style="display: none;">
              <div *ngFor="let item of manufactureMenuList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: fixedAssetList?.length == 0 }">
            <a href="#" class="nav-link">
              <i><img src="../../../assets/image/fixed-asset.png" alt="fixed-asset" class="fixedasset"></i>
              <span>Fixed Asset</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Project" style="display: none;">
              <div *ngFor="let item of fixedAssetList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: FileList?.length == 0 }">
            <a href="#" class="nav-link">
              <span class="iconify" style="
                  color: #02aced;
                  margin-top: 0.12502rem;
                  margin-bottom: 0.12502rem;
                  top: 0;
                " data-icon="icomoon-free:file-text2" data-inline="false"></span>
              <span style="margin-left: 1.25rem;">
                {{ 'SIDEBAR.files' | translate }}
              </span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Project" style="display: none;">
              <div *ngFor="let item of FileList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu" [ngClass]="{ displayNone: reportsList?.length == 0 }">
            <a href="#" class="nav-link">
              <i class="icon-file-eye"></i>
              <span>{{ 'SIDEBAR.reports' | translate }}</span>
            </a>
            <ul class="nav nav-group-sub" data-submenu-title="Expense" style="display: none;">
              <div *ngFor="let item of reportsList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item liBoarder nav-item-submenu bottomItem" [ngClass]="{ displayNone: accountSettingsList?.length == 0 }" (click)="clickV()" >
            <ul class="nav nav-group-sub" data-submenu-title="Expense" style="display: none;">
              <div *ngFor="let item of accountSettingsList" class="nav nav-group-sub full-width">
                <li class="nav-item full-width">
                  <a routerLink="{{ item.ActionUrl }}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }" class="nav-link full-width">
                    {{ item.Name }}
                  </a>
                </li>
              </div>
            </ul>
            <a class="nav-link">
              <i class="icon-coins"></i>
              <span>{{ 'SIDEBAR.accounting' | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- /main navigation -->
  </div>
  <!-- /sidebar content -->
</div>
<!-- /main sidebar -->