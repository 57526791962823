<app-dashboard>
    <app-page-header-title mainTitle="Factory In Create" subTitle="Back"  backURL="/inventory/factory-in-list/All">
      <div class="full-width text-align-right">
        <div class="dropdown">
          <div class="x-tip-tl" style="height: 0px">
            <i id="custom_notch" class="material-icons custom_notch"
              >arrow_drop_up</i
            >
          </div>
        </div>
        <button type="button" (click)="Save()" class="eCount-def-btn ml-4 " *ngIf="factoryInForm.value.StatusId == 1">Save</button>
        <button type="button" (click)="Approve()" style="background: #5cac00;" class="eCount-def-btn ml-4 " *ngIf="factoryInForm.value.StatusId == 1">Approve</button>
      </div>
    </app-page-header-title>
    <div class="headSection">
      <form [formGroup]="factoryInForm" class="f-ss-14px">
        <div class="row f-ss-14px">
          <div class="col-md-3 col-sm-12">
            <label for="InDate">Date<span class="required-sign">*</span></label>
            <input
              class="form-control"
              type="date"
              formControlName="InDate"
              id="efitest" data-date="" 
              data-date-format="D MMM YYYY" 
              (change)="show($event)"
              required
            />
          </div>
  
          <div class="col-md-2 col-sm-12">
            <label for="ReferrenceNo">Reference</label>
            <input
              class="form-controlinputBox"
              type="text"
              formControlName="ReferrenceNo"
            />
          </div>
          <div class="col-md-4 col-sm-12">
            <label for="InDate">Description<span class="required-sign">*</span></label>
            <input
              class="form-controlinputBox"
              type="text"
              formControlName="Description"
              [ngClass]="{'submitted':ifSubmit===true}" required
            />
          </div>
        </div>
  
        <div class="row d-block topSection f-ss-14px">
          <div class="d-flex">
            <div class="col-md-2">Item</div>
            <div class="col-md-2">Product Name</div>
            <div class="col-md-2">Sales Order</div>
            <div class="col-md-2">Stock Qty</div>
            <div class="col-md-1">Item Qty</div>
            <div class="col-md-1">Price/Unit</div>
            <div class="col-md-2">Amount</div>
          </div>
          <hr class="pb-0 mb-0" />
          <div formArrayName="ProductionInItem" *ngFor="let item of factoryInForm.get('ProductionInItem')['controls']; let i = index;">
             
             <div class="invoiceGridItemRow d-block">
              <div class="row" [formGroupName]="i">
  
                  <div class="col-md-2 margin-top-5">
                    <ng-select [items]="materialList"   bindLabel="ItemName" bindValue="Id" (change)="updatePrice(i,item)"
                     formControlName="MaterialId" >
                </ng-select>
                  </div>
                  <div class="col-md-2 margin-top-5">
                      <ng-select [items]="productList" (change)="getSalesOrder(i,item)" bindLabel="ItemName" bindValue="Id"  formControlName="ProductId">
 
                      </ng-select>
                  </div>
                  <div class="col-md-2 margin-top-5">
                      <select class="form-control customSaleItem" style="width: 100%;" formControlName="SalesOrderId">
                          <option *ngFor="let s of salesOrders[i]" [ngValue]="s.Id">{{s.Name}}</option>
                      </select>
                  </div>
                  <div class="col-md-2 margin-top-5">
                      <input  class="form-control" readonly appDecimaNumericValidator formControlName="StockQuantity">
                  </div>
                  <div class="col-md-1 margin-top-5">
                      <input  class="form-control" (change)="updatePrice(i,item)" appDecimaNumericValidator formControlName="InQuantity" placeholder="Quantity">
                  </div>
                  <div class="col-md-1 margin-top-5">
                      <input  class="form-control" readonly appDecimaNumericValidator formControlName="PricePerUnit" placeholder="Price/Unit">
                  </div>
                  <div class="col-md-2 margin-top-5 d-flex">
                      <div>
                          <input  class="form-control" readonly formControlName="LineTotal" placeholder="Total">
                      </div>
  
                      <div style="padding: 7px 10px; color: #c7c2c2;">
                          <div class="dropdown mr-4">
                              <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                  aria-expanded="false">
                                  <i class="icon-more float-right"></i>
                              </div>
                              <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                  style="width: 100%;cursor: pointer;">
                                  <div class="productRow pb-3" (click)="deleteItem(i)">
                                      <span class="lead1">Remove Item </span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                </div>
          </div>
          <div class="row addItem">
            <div class="BottomAddItem w-100">
              <div class="dropdown mr-4">
                <a
                  class="nav-link"
                  style="background-color: white"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  (click)="addBlankItem()"
                >
                  <img
                    class="languageImg"
                    src="../../../assets/image/addIcon.png"
                  />
                  Add New Line
                </a>
              </div>
            </div>
          </div>
  
        </div>
      </form>
    </div>
  </app-dashboard>
  