
<div style="width: 100vw;" [hidden]="!isPdf"> 
    <!-- <iframe id="iframepdf" src={{src}}></iframe> -->
    <!-- <embed width="100%" height="1000px" src={{src}}/> -->
    <pdf-viewer [src]="src"
        [render-text]="true"
        [fit-to-page]="true"
        [zoom]="1.3"
        style="display: block;">
    </pdf-viewer>
</div> 
<div  [hidden]="isPdf">
    <div style="width: 100vw;" id="print">
        <img id="image" style="width: 100%;" src={{src}} />
    </div>
</div>