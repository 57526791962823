import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';
@Component({
  selector: 'app-modal-for-create-bill',
  templateUrl: './modal-for-create-bill.component.html',
  styleUrls: ['./modal-for-create-bill.component.css']
})
export class ModalForCreateBillComponent implements OnInit {

  @Input() fromParent: any;
  getModalData:string=''
  wareHouseRef:any
  allData:any
  constructor(
    public activeModal: NgbActiveModal,
    private deliveryOrderService: InventoryOverviewService,
  ) { }

  ngOnInit(): void {
    this.getModalData=this.fromParent.wareHouseNo;
    this.wareHouseRef=this.fromParent.wareHouseRef;
    this.allData=this.fromParent.allData
    console.log("From Parent Testing==>",this.wareHouseRef);

    let dataForCreateBill=
    {
      modalData:this.getModalData,
      wareHouseRef:this.wareHouseRef,
      allData:this.allData

    }
    this.deliveryOrderService.passDataToCreateBill(dataForCreateBill);

    
   
  }

  proceed=()=>
  {
    

  }

}
