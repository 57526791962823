<app-dashboard>
    <app-page-header-title mainTitle="Factory Out View" subTitle="Back" backURL="/inventory/factory-out-list/All">
      <div class="full-width text-align-right">
        <div class="dropdown">
          <div class="x-tip-tl" style="height: 0px">
            <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
          </div>
        </div>
        <button type="button"  class="eCount-def-btn ml-4" [routerLink]="['/inventory/edit-factory-out',TransNo]" *ngIf="this.factoryOutForm.value.StatusId == 1"> Edit </button>
        <button type="button" style="background: #5cac00;" (click)="approveFactoryOut()" class="eCount-def-btn ml-4" *ngIf="this.factoryOutForm.value.StatusId == 1"> Approve</button>
      </div>
    </app-page-header-title>
    
    <div class="headSection">
      <form [formGroup]="factoryOutForm" class="f-ss-14px">
        <div class="row f-ss-14px">
          <div class="col-md-3 col-sm-12">
            <label for="OutDate">Date</label>
            <input
              class="form-control"
              formControlName="OutDate"
              readonly
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="ProductName">Product</label>
            <input
              class="form-control"
              formControlName="ProductName"
              readonly
            />
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="ProductOutQuantity">Quantity</label>
            <input
              class="form-control"
              appDecimaNumericValidator
              formControlName="ProductOutQuantity"
              readonly
            />
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="BatchNo">Batch No</label>
            <input 
              class="form-control inputBox"
              formControlName="BatchNo"
              readonly
            />
          </div>
        </div>
  
        <div class="row d-block topSection f-ss-14px">
            <div class="d-flex ml">
                <div class="col-md-2">Item Code</div>
                <div class="col-md-2">Item Name</div>
                <div class="col-md-2">Stock Qty</div>
                <div class="col-md-2">Used Qty</div>
                <div class="col-md-2">Price/Unit</div>
                <div class="col-md-2">Amount</div>
              </div>
              <hr class="pb-0 mb-0" />
              <div formArrayName="ProductionOutItem" *ngFor="let item of factoryOutForm.get('ProductionOutItem')['controls']; let i = index;">
                 
                 <div class="invoiceGridItemRow d-block">
                  <div class="row" [formGroupName]="i">
      
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        type="text"
                        formControlName="ItemCode"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        type="text"
                        formControlName="ItemName"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        formControlName="StockQty"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        appDecimaNumericValidator

                        formControlName="UsedQty"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        formControlName="MaterialCost"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5 d-flex">
                        <div>
                        <input
                        readonly
                        class="form-control"
                        formControlName="LineTotal"
                      /> </div>
                      
                      </div>
                     
                  </div>
                    </div>
              </div>
              <hr class="pb-0 mb-0" />
              <div class="d-flex">
                <div class="col-md-10 item-total">Total</div>
                <div class="col-md-2 item-total">{{totalMaterialCost}}</div>
            </div>
        </div>
        <div class="col-md-12 row nml">
          <div class="f-ss-14px col-md-7 fml">
            <h3 style="margin-top: 40px;" class="ml-3">Finished Product Costs</h3>
          <div class="row col-md-12">
        
            <div class="col-md-5">
              <label for="PerUnitOperatingCost">Operating Cost/Unit<span class="required-sign">*</span></label>
              <input
              class="form-control"
              appDecimaNumericValidator
              formControlName="PerUnitOperatingCost"
              readonly
              required
            />
            <div formArrayName="OperatingCostItem"   *ngFor="let items of factoryOutForm.get('OperatingCostItem')['controls']; let ix = index;">
                 
              <div class="invoiceGridItemRow d-block ml-3">
               <div class="row mr" [formGroupName]="ix">
   
                   <div class="col-md-12 row mt-1">
                      <input type="text" formControlName="Name" class="form-control col-md-6" placeholder="Name" readonly>
                       <input type="number" class="form-control col-md-6" appDecimaNumericValidator formControlName="Amount" readonly>
                   </div>
          
               </div>
                 </div>
           </div>
           <div>
          </div>
            </div>
            <div class="col-md-3 col-sm-12">
              <label for="PerUnitMaterialCost">Material Cost/Unit</label>
              <input
              readonly
              type="number"
              class="form-control"
              formControlName="PerUnitMaterialCost"
              required
            />
            </div>
              <div class="col-md-4 col-sm-12">
                <label for="PerUnitProductionCost">Production Cost/Unit</label>
                <input
                readonly
                class="form-control"
                formControlName="PerUnitProductionCost"
              />
              
              </div>
          </div>
        </div>
        <div class="col-md-5">
          <h3 style="margin-top: 40px;" class="ml-4">Sales Order List</h3>
          <div class="row col-md-12 d-block f-ss-14px">
            <div class="d-flex pb-2 ml-2" style="border-bottom: 1px solid rgba(0,0,0,.1)">
              <div class="col-md-6">Sales Order No</div>
              <div class="col-md-6">Booked Quantity</div>
            </div>
            <div formArrayName="FactoryOutSalesOrder" *ngFor="let item of factoryOutForm.get('FactoryOutSalesOrder')['controls']; let i = index;">
               
               <div class="invoiceGridItemRow d-block">
                <div class="row" [formGroupName]="i" style="margin-left: 4px;">
    
                    <div class="col-md-6 margin-top-5">
                        <ng-select [items]="salesOrders" [clearable]="false" bindLabel="Name" bindValue="Id" formControlName="OrderId"></ng-select>
                    </div>
                    <div class="col-md-6 margin-top-5">
                        <input  class="form-control" (change)="checkQuantity(item)" readonly appDecimaNumericValidator formControlName="BookedQuantity">
                    </div>
                    <!-- <div class="col-md-2 margin-top-5 d-flex">
                        <div style="padding: 7px 10px; color: #c7c2c2;">
                            <div class="dropdown mr-4">
                                <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                    aria-expanded="false">
                                    <i class="icon-more float-right"></i>
                                </div>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                    style="width: 100%;cursor: pointer;">
                                    <div class="productRow pb-3" (click)="deleteItem(i,item)">
                                        <span class="lead1">Delete</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                  </div>
            </div>
          </div>
        </div>
            <!-- <hr class="pb-0 mb-0" style="width: 45%;margin-left: 2%" /> -->
              <!-- <div class="w-100">
                <div class="dropdown mr-4">
                  <a
                    class="nav-link"
                    style="background-color: white"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    (click)="addSalesOrderBlankItem()"
                    data-toggle="dropdown"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    <img
                      class="languageImg"
                      src="../../../assets/image/addIcon.png"
                    />
                    Add New Line
                  </a>
                </div>
              </div> -->
            <!-- <hr style="width: 45%;margin-left: 2%;margin-top: 2px;" /> -->
            <!-- <button
              type="button"
              (click)="SaveFactoryOutSalesOrder()"
              class="eCount-def-btn ml-4">
              Save
            </button> -->
          </div>
      </form>
    </div>
  </app-dashboard>
  