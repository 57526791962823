<app-dashboard>
  <app-page-header-title
    mainTitle="All Spend Money List"
    subTitle="Home"
    backURL="/"
  >
    <div class="full-width text-align-right">
      <button type="button" (click)="gotoUrl()" class="eCount-def-btn ml-4">
        Add Spend Money
      </button>
      <button type="button" class="eCount-button3 ml-4" (click)="convetToPDF()">
        Export
      </button>
    </div>
  </app-page-header-title>

  <div class="headSection" id="head">
    <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('All')"
          [routerLinkActive]="['active']"
          routerLink="/spendmoney/spend-money-list/all"
          >All
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Draft')"
          [routerLinkActive]="['active']"
          routerLink="/spendmoney/spend-money-list/Draft"
          >Draft({{ draftCount }})
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Submitted')"
          [routerLinkActive]="['active']"
          routerLink="/spendmoney/spend-money-list/Submitted"
          >Submitted({{ submitCount }})
        </a>
      </li>
    </ul>
    <ul class="nav nav-tabs justify-content-center" *ngIf="isMonthTabOpen">
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Current_Month')"
          [routerLinkActive]="['active']"
          routerLink="/spendmoney/spend-money-list/Current_Month"
          >Current Month ({{ currentMonthCount }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Last_Month')"
          [routerLinkActive]="['active']"
          routerLink="/spendmoney/spend-money-list/Last_Month"
          >Last Month ({{ lastMonthCount }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Last_Two_Months')"
          [routerLinkActive]="['active']"
          routerLink="/spendmoney/spend-money-list/Last_Two_Months"
          >Last Two Months ({{ lastTwoMonthCount }})</a
        >
      </li>
    </ul>

    <div class="row">
      <ag-grid-angular
        style="font-size: 14px"
        class="ag-theme-balham col"
        [rowData]="SpendMoneyFilterList"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        (rowClicked)="onRowClicked($event)"
        (gridReady)="onGridReady($event)"
        [defaultColDef]="defaultColDef"
      ></ag-grid-angular>
    </div>
  </div>
</app-dashboard>
