import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../../service/auth/auth.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule,Validators  } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { MainServiceService} from '../../../service/Main/main-service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(public fb: FormBuilder,private router: Router,private mainService: MainServiceService, public authService: AuthService, private toaster: ToastrService, private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
  }

}
