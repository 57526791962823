import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ItemType } from 'src/app/enum/item-type';
import { Item, ItemDetails } from 'src/app/models/inventoryOverview.model';
import { ManufactureFormula, ManufactureFormulaDetails } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';
import { ProductServiceService } from 'src/app/service/Products/product-service.service';

@Component({
  selector: 'app-create-finished-product-material-formula',
  templateUrl: './create-finished-product-material-formula.component.html',
  styleUrls: ['./create-finished-product-material-formula.component.css']
})
export class CreateFinishedProductMaterialFormulaComponent implements OnInit {

  ProductFormulaForm: FormGroup;
  FormulaItems: FormArray;
  finishedProductList: ItemDetails[];
  rawMaterialList: Item[];

  constructor(
    private fb: FormBuilder,
    private productServiceService: ProductServiceService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private manufactureService: ManufactureService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getFormData();
  }

  getFormData() {
    this.getFinishedProductList();
    this.getRawMaterialList();
  }

  getFinishedProductList() {
    this.productServiceService.getManufactureFinishedProducts().subscribe((x) => {
      this.finishedProductList = x;
    });
  }

  getRawMaterialList() {
    this.productServiceService.GetItemList(ItemType.RawMaterial).subscribe((x) => {
      this.rawMaterialList = x;
    });
  }

  initializeForm() {
    this.ProductFormulaForm = this.fb.group({
      Id: [0],
      FinishedProductId: ['', Validators.required],
      FormulaName: ['', Validators.required],
      Quantity: ['', Validators.required],
      CreatedDate: ['', Validators.required],
      Reference: [''],
      FormulaItems: this.fb.array([this.createItem()])
    });
    this.ProductFormulaForm.get('CreatedDate').patchValue(new Date());
    this.showSelectedDate(this.ProductFormulaForm.value.CreatedDate);
  }

  createItem(): FormGroup {
    return this.fb.group({
      RawMaterialId: [null],
      ItemQuantity: [0]
    });
  }

  addItem(): void {
    this.FormulaItems = this.ProductFormulaForm.get('FormulaItems') as FormArray;
    this.FormulaItems.push(this.createItem());
  }

  removeItems(i: number) {
    this.FormulaItems = this.ProductFormulaForm.get('FormulaItems') as FormArray;
    this.FormulaItems.value.map((x, index) => {
      if(index === i){
        this.FormulaItems.removeAt(index);
      }
    });
  }

  saveManufactureFormula() {
    if(this.checkFormValidation()){
      this.spinner.show();
      let data: ManufactureFormula = this.formatData();
      this.manufactureService.saveManufactureFormula(data).subscribe((x) => {
        if(x.Success) {
          this.toaster.success(x.Message);
          this.router.navigate(["manufacture/finished-product-material-formula-list/All"]);
          this.spinner.hide();
        }
        else{
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
    }
  }

  approveManufactureFormula() {
    if(this.checkFormValidation()){
      this.spinner.show();
      let data: ManufactureFormula = this.formatData();
      this.manufactureService.approveManufactureFormula(data).subscribe((x) => {
        if(x.Success) {
          this.toaster.success(x.Message);
          this.router.navigate(["manufacture/finished-product-material-formula-list/All"]);
          this.spinner.hide();
        }
        else{
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
    }
  }

  checkFormValidation() {
    if(this.ProductFormulaForm.value.FinishedProductId == ''){
      this.toaster.warning('Please select a product name!');
      return false;
    }
    if(this.ProductFormulaForm.value.FormulaName == ''){
      this.toaster.warning('Please add a formula name!');
      return false;
    }
    if(this.ProductFormulaForm.value.Quantity == '' || Number(this.ProductFormulaForm.value.Quantity) == 0){
      this.toaster.warning('Formula quantity should not zero!');
      return false;
    }
    const formulaItems = this.ProductFormulaForm.get('FormulaItems').value;
    if(formulaItems.length != 0){
      const hasNoFormulaItems = formulaItems.some(item => item.RawMaterialId == null);
      if(hasNoFormulaItems) {
        this.toaster.warning('Please select item!');
        return false;
      }
      const hasZeroItemQuantity = formulaItems.some(item => item.ItemQuantity == 0);
      if(hasZeroItemQuantity){
        this.toaster.warning('Item quantity should not zero!');
        return false;
      }
    }
    const itemIds = new Set();
    for(const item of formulaItems){
      if(itemIds.has(item.RawMaterialId)) {
        this.toaster.warning('Same item could not add in multiple time!');
        return false;
      }
      else {
        itemIds.add(item.RawMaterialId);
      }
    }
    if(!this.ProductFormulaForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return false;
    }
    return true;
  }

  formatData(): ManufactureFormula {
    let manufactureFormulaDetails: ManufactureFormulaDetails[] = [];
    this.ProductFormulaForm.value.FormulaItems.forEach(element => {
      let item = this.rawMaterialList.filter(x => x.Id === element.RawMaterialId)
      let formulaDetails = new ManufactureFormulaDetails();
      formulaDetails.Id = 0;
      formulaDetails.ItemId = element.RawMaterialId;
      //formulaDetails.Item = item[0];
      formulaDetails.Quantity = element.ItemQuantity;
      formulaDetails.ManufactureFormulaId = 0;
      manufactureFormulaDetails.push(formulaDetails);
    });

    let finishedProduct = this.finishedProductList.filter(x => x.Id === this.ProductFormulaForm.value.FinishedProductId);
    let manufactureFormula: ManufactureFormula = {
      Id: 0,
      FormulaName: this.ProductFormulaForm.value.FormulaName,
      FinishedProductId: this.ProductFormulaForm.value.FinishedProductId,
      //Item: finishedProduct[0],
      Quantity: this.ProductFormulaForm.value.Quantity,
      CreateDate: this.ProductFormulaForm.value.CreatedDate,
      Reference: this.ProductFormulaForm.value.Reference,
      Status: 0,
      CompanyId: 0,
      ManufactureFormulaDetails: manufactureFormulaDetails
    };
    return manufactureFormula;
  }

  showSelectedDate(e: Event) {
    const value = this.ProductFormulaForm.value.CreatedDate;
    $('#createdDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

}
