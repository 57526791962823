<div class="modal-header" style="top: 20px;">
    <h4  class="modal-title" style="font-size: 17px;">Add Share Capital</h4>
    <button type="button" class="eCount-def-btn mlb mb-2" (click)="save()">
        Save
    </button>
    <button type="button" class="close" aria-label="Close"(click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
        
    <div class="headSection modal-body">
        <form [formGroup]="shareCapitalForm" class="f-ss-14px" >
        <div class="row f-ss-14px col-sm-12">
            <div class="col-sm-4">
                <label>{{'Invoice.date' |translate}}</label>
                <input class="form-control" type="date" formControlName="Date" id="itest1" data-date="" data-date-format="D MMM YYYY" [ngClass]="{'submitted':ifSubmit===true}" (change)="showSelectedDate($event)" required>
            </div>
            <div class="col-sm-4">
                <label>To<span class="clr">*</span></label>
                <ng-select [items]="bankList" [clearable]="false" bindLabel="AccountName"  bindValue="GLAccountId"  formControlName="AccountId">
                    <ng-template ng-option-tmp let-item="item" let-index="index" style="display: inline-grid;">     
                        <span>{{item.BankAccount}}</span>
                    </ng-template>
                </ng-select>
            </div>
          
            <div class="col-sm-3">
                <label>{{'Invoice.reference' |translate}}</label>
                <input class="form-controlinputBox" type="text" formControlName="ReferenceNo">
            </div>
        </div>
        <div class="row d-block topSection f-ss-14px ml-2">
            <div class="d-flex">
                <div class="col-md-4">
                    {{'Item.description' |translate}}
                </div>
                <div class="col-md-2 ml-2">
                   {{'Item.quantity' |translate}}
                </div>
                <div class="col-md-2 ml-1">
                    {{'Item.price' |translate}}
                </div>
                <div class="col-md-3">
                    {{'Item.amount' |translate}}
                </div>
            </div>
            <hr class="pb-0 mb-0" />
            <div>
                <div  formArrayName="ShareCapitalItems" *ngFor="let invoice of shareCapitalForm.get('ShareCapitalItems')['controls']; let i = index;" >
                    
                    <div class="invoiceGridItemRow d-block"
                        [ngClass]="[invoice.selected ? 'selectedRow' : 'notSelectedRow']">
                        <div class="d-flex " [formGroupName]="i" [ngClass]="[invoice.selected ? 'selectedRow' : 'notSelectedRow']">
                            <div class="col-md-4 borderLeft rowSpaceing">
                                <textarea class="form-control descriptionTextArea" type="text"  formControlName="Description" [ngClass]="{'submitted':ifSubmit===true}"> </textarea>
                            </div>
                            <div class="col-md-2 borderLeft rowSpaceing">
                                <input class="form-control" type="text" appDecimaNumericValidator
                                formControlName="Quantity"
                                    (focus)="onFocusEvent($event)"
                                    (change)="updateDataItem(invoice.ItemId)" >
                            </div>
                            <div class="col-md-2 borderLeft rowSpaceing">
                                <input class="form-control" type="text" appDecimaNumericValidator
                                formControlName="Price"
                                    (focus)="onFocusEvent($event)"
                                    (change)="updateDataItem(invoice.ItemId)">
                            </div>
          
                            <div class="col-md-3 rowSpaceing d-flex">
                                <div>
                                    <input class="form-control" type="text" appDecimaNumericValidator
                                    formControlName="LineTotal">
                                </div>
                                <div style="padding: 7px 0px 7px 10px; color: #c7c2c2;">
                                    <div class="dropdown">
                                        <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                            aria-expanded="false">
                                            <!-- <i class="icon-more float-right"></i> -->
                                            <img src="../../../../assets/image/icons/more.png" class="float-right moreIcon" />
                                        </div>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                            style="width: 30px;">
                                            <div class="productRow pb-3" (click)="removeInvoiceItem(invoice.ItemId, i)">
                                                <span class="lead1">Remove Item </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row addItem">
                <div class="BottomAddItem w-100">
                    <div class="dropdown mr-4">
                        <a class="nav-link" href="#" id="navbarDropdown" style="background-color: white;" role="button" data-toggle="dropdown"
                            aria-haspopup="false" aria-expanded="false" (click)="addItem()">
                            <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line
                        </a>
                    </div>
                </div>
            </div>
            <div class="container-bottom d-flex">
                <div class="col-sm-5"></div>
                <div class="col-md-4 col-sm-12 p-0 d-block">
                    <div class="d-flex w-100">
                        <div class="col-md-8 ml-4">
                            <label>{{'Invoice.subTotal' |translate}}</label>
                        </div>
                        <div class="col-md-4">{{ shareCapitalForm.value.TotalAmount | currency:'':''}}</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </div>
   

