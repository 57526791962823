<app-dashboard>
    <app-page-header-title mainTitle="Return Policy Settings" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <button class="eCount-def-btn ml-4" type="button" (click)="Save()">Save</button>
        </div>
    </app-page-header-title>

    <div class="headSection">
        <form [formGroup]="PolicySettingsForm" class="f-ss-14px">
            <div class="row f-ss-14px mb-5">
                <div class="col-md-3 pt-2">
                    <label for="ReturnDurationDays" class="font-weight-bold"> Purchase Return Close After </label>
                </div>
                <div class="col-md-2">
                    <ng-select [items]="allDays" [clearable]="true" bindLabel="days" bindValue="days" formControlName="PurchaseReturnDurationDays"></ng-select>
                </div>
                <div class="col-md-2 pt-2">
                    <label for="ReturnDurationDays" class="font-weight-bold"> Days </label>
                </div>
            </div>
            <div class="row f-ss-14px mt-5">
                <div class="col-md-3 pt-2">
                    <label for="ReturnDurationDays" class="font-weight-bold"> Sales Return Close After </label>
                </div>
                <div class="col-md-2">
                    <ng-select [items]="allDays" [clearable]="true" bindLabel="days" bindValue="days" formControlName="SalesReturnDurationDays"></ng-select>
                </div>
                <div class="col-md-2 pt-2">
                    <label for="ReturnDurationDays" class="font-weight-bold"> Days </label>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>
