<app-dashboard>
  <div class="row ntmargin-10">
      <div class="col-md-12 col-sm-12">
          <p class="font-weight-bold">My Pending List</p>
      </div>
      <div class="col-md-6" *ngIf="!IsOnlyHasDocumentFeature()">
          <div class="list-border list-min-height">
              <div class="row p-2">
                  <div class="col-md-6 col-sm-7  pt-1">
                      <p class="pb-2 mb-0 list-title">Pending Bill ({{ billCount }})</p>
                      <hr class="pb-0 pt-0 pl-2 m-0">
                  </div>
                  <div class="col-md-6 col-sm-5">
                      <div class="full-width text-align-right">
                          <button type="button" (click)="approveBills()" class="eCount-def-btn">Approve</button>
                          <button type="button" (click)="rejectBill()" class="eCount-def-btn ml-2 background-red">Reject</button>
                      </div>
                  </div>
              </div>
              <div class="row p-2" id="pl">
                  <ag-grid-angular class="col ag-theme-alpine font-size-14" [rowData]="pendingBills" [columnDefs]="billColumnDefs" [domLayout]="domLayout" (cellClicked)="onCellClicked($event)" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true" (gridReady)="onBillGridReady($event)"></ag-grid-angular>
              </div>
          </div>
      </div>
      <div class="col-md-6 mt2" *ngIf="!IsOnlyHasDocumentFeature()">
          <div class="mb-2 list-border list-min-height">
              <div class="row p-2">
                  <div class="col-md-6 col-sm-7 pt-1">
                      <p class="pb-2 mb-0 list-title">Pending Invoice ({{ invoiceCount }})</p>
                      <hr class="pb-0 pt-0 pl-2 m-0">
                  </div>
                  <div class="col-md-6 col-sm-5">
                      <div class="full-width text-align-right">
                          <button type="button" (click)="approveInvoices()" class="eCount-def-btn">Approve</button>
                          <button type="button" (click)="rejectInvoice()" class="eCount-def-btn ml-2 background-red">Reject</button>
                      </div>
                  </div>
              </div>
              <div class="row p-2" id="pl">
                  <ag-grid-angular class="ag-theme-alpine col font-size-14" [rowData]="pendingInvoices" [columnDefs]="invoiceColumnDefs" [domLayout]="domLayout" (cellClicked)="onCellClicked($event)" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true" (gridReady)="onInvoiceGridReady($event)"></ag-grid-angular>
              </div>
          </div>
      </div>
      <div class="col-md-6 mt-2" *ngIf="!IsOnlyHasDocumentFeature() && !IsServiceCompany()">
          <div class="mb-2 list-border list-min-height">
              <div class="row p-2">
                  <div class="col-md-9 col-sm-9 pt-1">
                      <p class="pb-2 mb-0 list-title">Pending Purchase Order ({{ purchaseOrderCount }})</p>
                      <hr class="pb-0 pt-0 pl-2 m-0">
                  </div>
                  <div class="col-md-3 col-sm-3">
                      <div class="full-width text-align-right">
                          <button type="button" (click)="approvePurchaseOrders()" class="eCount-def-btn">Approve</button>
                      </div>
                  </div>
              </div>
              <div class="row p-2" id="pl">
                  <ag-grid-angular class="ag-theme-alpine col font-size-14" [rowData]="pendingPurchaseOrders" [columnDefs]="purchaseOrderColumnDefs" [domLayout]="domLayout" (cellClicked)="onCellClicked($event)" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true" (gridReady)="onPurchaseOrderGridReady($event)"></ag-grid-angular>
              </div>
          </div>
      </div>
      <div class="col-md-6 mt-2" [ngClass]="{'col-md-12': IsOnlyHasDocumentFeature()}">
          <div class="mb-2 list-border list-min-height" [ngClass]="{'list-full-height': IsOnlyHasDocumentFeature()}">
              <div class="row p-2">
                  <div class="col-md-6 col-sm-7 pt-1">
                      <p class="pb-2 mb-0 list-title">Pending Document ({{ documentCount }})</p>
                      <hr class="pb-0 pt-0 pl-2 m-0">
                  </div>
                  <div class="col-md-6 col-sm-5">
                      <div class="full-width text-align-right">
                          <button type="button" (click)="approveDocument()" class="eCount-def-btn">Approve</button>
                          <button type="button" (click)="rejectDocument()" class="eCount-def-btn ml-2 background-red">Reject</button>
                      </div>
                  </div>
              </div>
              <div class="row p-2" id="pl">
                  <ag-grid-angular class="ag-theme-alpine col font-size-14" [ngClass]="{'ag-grid-full-height': IsOnlyHasDocumentFeature()}" [rowData]="pendingDocuments" [columnDefs]="documentColumnDefs" [domLayout]="domLayout" (cellClicked)="onCellClicked($event)" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection2" [suppressRowClickSelection]="true" (gridReady)="onDocumentGridReady($event)"></ag-grid-angular>
              </div>
          </div>
      </div>
  </div>

  <ngx-smart-modal #AttachmentViewer identifier="AttachmentViewer">
    <div class="pb-4">
        <span class="modalHeaderText pl-2" class="attachment-header">{{ selected?.FileName }}</span>
        <hr class="notShow"/>
        <div class="row pr-2 pl-2 mr-2">
            <div class="col-md-12 notShow">
                <div class="row">
                    <div class="col-md-4">
                        <table style="color: #7f7f7f; font-size: 13px">
                            <tr>
                                <td>Document Name :</td>
                                <td>{{ selected?.DocumentName }}</td>
                            </tr>
                            <tr>
                                <td>Tag :</td>
                                <td>{{ selected?.DocumentType }}</td>
                            </tr>
                            <tr>
                                <td>Created Date :</td>
                                <td>{{ convertDateToDisplay(selected?.UploadedAt) }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-8" style="text-align: right">
                        <a><span class="option-icon-color" (click)="zoomIn()"><i class="material-icons borderradiusleft attachmenticon">zoom_in</i></span></a>
                        <a><span class="option-icon-color" (click)="zoomOut()"><i class="material-icons attachmenticon">zoom_out</i></span></a>
                        <a [hidden]="isPdf"><span class="option-icon-color" (click)="rotate('left')"><i class="material-icons attachmenticon">rotate_left</i></span></a>
                        <a [hidden]="isPdf"><span class="option-icon-color" (click)="rotate('right')"><i class="material-icons attachmenticon borderradiusright">rotate_right</i></span></a>
                    </div>
                </div>
            </div>
            <div id="contentToPrint" class="col-md-12">
                <div [hidden]="!isPdf">
                    <pdf-viewer [src]="src" [render-text]="true" [fit-to-page]="true" [zoom]="zoom" style="display: block"></pdf-viewer>
                </div>
                <div [hidden]="isPdf">
                    <div style="overflow: overlay">
                        <img id="image" style="width: 100%" src="{{ src }}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ngx-smart-modal>
</app-dashboard>