import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { customer } from './../../models/customer.model';
import { createSpendMoney } from '../../models/spendMoney.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpendmoneyServiceService {

  baseurl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  public GetPurchaseableItems(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetPurchaseableItems').pipe(retry(1));
  }

  public GetSpendMoneyTypeList(): Observable<customer[]> {
    return this.http.get<any>(this.baseurl + 'SpendMoney/GetSpendMoneyTypeList').pipe(retry(1));
  }

  //save create SpendMoney 
  public saveSpendMoneyData(data:any): Observable<any> {
    return this.http.post<createSpendMoney>(this.baseurl + 'SpendMoney/Save', data).pipe(retry(1));
  }
  //get spend money list
  public GetSpendMoneyList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'SpendMoney/GetList').pipe(retry(1));
  }
    //get spend money details
    public GetSpendMoneyDetails(id): Observable<any> {
      return this.http.get<any>(this.baseurl + `SpendMoney/GetSpendMoney?id=${id}`).pipe(retry(1));
    }

    public submitSpendMoney(id): Observable<any> {
      return this.http.get<any>(this.baseurl + `SpendMoney/SubmitSpendMoney?id=${id}`).pipe(retry(1));
    }
  
    public deleteSpendMoney(id): Observable<any> {
      return this.http.get<any>(this.baseurl + `SpendMoney/DeleteSpendMoney?id=${id}`).pipe(retry(1));
    }

    public updateSpendMoneyDataDraft(data:any): Observable<any> {
      return this.http.post<createSpendMoney>(this.baseurl + 'SpendMoney/UpdateDraft', data).pipe(retry(1));
    }

    updateAndSaveEditedData(data): Observable<any> {
      return this.http.post<createSpendMoney>(this.baseurl + 'SpendMoney/UpdateAndSaveSpendMoney', data).pipe(retry(1));
    }

}
