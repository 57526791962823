import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { WareHouseOperationType } from 'src/app/enum/wareHouseOperationType';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';


@Component({
  selector: 'app-sales-return-overview',
  templateUrl: './sales-return-overview.component.html',
  styleUrls: ['./sales-return-overview.component.css']
})

export class SalesReturnOverviewComponent implements OnInit {
  subTitle: any;
  backUrl: any;
  searchText: string;
  private gridApi;
  allData: any = [];
  domLayout = "autoHeight";

  constructor(
    private route: ActivatedRoute,
    private inventoryOverviewService: InventoryOverviewService
  ) { }

  ngOnInit(): void {
    this.searchText = this.route.snapshot.paramMap.get("status");
    this.pageLoad(this.searchText);
  }

  search() {
    this.gridApi.setQuickFilter(this.searchText);
  }

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  pageLoad(status: string){
    this.inventoryOverviewService.GetWareHouseReturnList().subscribe((x) => {
      this.allData = x;
      switch (status) {
        case "All":
          this.allData = this.allData.filter(x => x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice || x.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder);
          break;
        case "Waiting":
          this.allData = this.allData.filter((x) => x.StatusId == 1 && (x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice || x.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder));
          break;
        case "Ready":
          this.allData = this.allData.filter((x) => x.StatusId == 3 && (x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice || x.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder));
          break;
        case "Received":
          this.allData = this.allData.filter((x) => x.StatusId == 2 && (x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice || x.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder));
          break;
      }
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].SerialNo = i + 1;
        this.allData[i].WHNO = "WH-RET-" + this.allData[i].WareHouseProductNo;
        if(this.allData[i].ParentWareHouse.OperationType == WareHouseOperationType.Invoice || this.allData[i].ParentWareHouse.OperationType == WareHouseOperationType.SalesOrder)
          this.allData[i].ReferenceNo = "WH-Out-"+this.allData[i].ParentId;
        else
          this.allData[i].ReferenceNo = "WH-RET-"+this.allData[i].ParentId;
      }
    });
  }

  columnDefs = [
    {
      headerName: "Serial No.",
      field: "SerialNo",
      maxWidth: 50,
      cellStyle: { color: "blue", cursor: "pointer" },
    },
    {
      headerName: "WH No.",
      field: "WHNO",
      cellStyle: { color: "blue", cursor: "pointer" },
      cellRenderer: function (params) {
        return `<a href="#/inventory/receipt-waiting/${params.data.Id}" >${params.data.WHNO}</a>`;

      },
    },
    { headerName: "To", field: "Contacts.CompanyName" },
    {
      headerName: "Date",
      field: "OperationDate",
      valueFormatter: this.convertGridDateIntoDisplay,
    },
    {
      headerName: "Reference",
      field: "ReferenceNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      cellRenderer: function (params) {
        if(params.data.ParentWareHouse.OperationType == WareHouseOperationType.Invoice || params.data.ParentWareHouse.OperationType == WareHouseOperationType.SalesOrder)
          return `<a href="#/inventory/delivery-order-waiting/${params.data.ParentWareHouse.Id}" >Return of ${params.data.ReferenceNo}</a>`;
        else
          return `<a href="#/inventory/receipt-waiting/${params.data.ParentWareHouse.Id}" >Back Order of ${params.data.ReferenceNo}</a>`;
      },
    },
    {
      headerName: "Status",
      field: "Status",
      valueFormatter: this.setOrderStatus,
    },
  ]

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  }

  convertGridDateIntoDisplay(date) {
    const dateString = date.value ? moment(date.value).format("Do MMM YYYY") : "";
    return dateString;
  }

  setOrderStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return "Waiting";
      case 2:
        return "Received";
      case 3:
        return "Ready";
      default:
        return "unknown";
    }
  }
}
