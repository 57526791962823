import { Component, OnInit } from '@angular/core';
import { MainServiceService } from '../../../service/Main/main-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-contact',
  templateUrl: './customer-contact.component.html',
  styleUrls: ['./customer-contact.component.css']
})
export class CustomerContactComponent implements OnInit {

  constructor(private mainService : MainServiceService,
             private router: Router, 
             private spinner: NgxSpinnerService) { }
  
  private gridApi;
  private gridColumnApi;
  contactList: any = []
  searchText:string;

  ngOnInit(): void {
    
  }

  columnDefs = [
    
    { headerName: 'Company Name', field: 'CompanyName' },
    { headerName: 'First Name', field: 'PrimaryPersonFirstName' },
    { headerName: 'Last Name', field: 'PrimaryPersonLastName' },
    { headerName: 'Email', field: 'Email' },
    { headerName: 'SkypeNo', field: 'SkypeNo' },
    { headerName: 'Postal Address', field: 'PostalAddress' },
    { headerName: 'Street Address', field: 'StreetAddress' },
    { headerName: "Action", suppressMenu: true, suppressSorting: true, template: `<span  (click)="editItems()"><img style="height: 20px;" src="../../assets/image/edit-pen-pencil.png"></span>` },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };
  
 
  getData=()=>{
    this.spinner.show()
    this.contactList = []
    this.mainService.GetAllCustomer().subscribe((x)=>{
      this.spinner.hide()
      this.contactList = [...x, ...this.contactList]
    },(err)=>{
      this.spinner.hide();
    })
  }

  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getData();
  }

  onRowClicked(e) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Action") {
      this.editItems(e);
    }
  }

  editItems(e) {
    this.router.navigate(['contacts/details-contact',e.data.Id]);
  }
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
    suppressQuotes: '', 
    columnSeparator: '',
    customHeader: '',
    customFooter: '',};
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }

}