<app-dashboard>
    <app-page-header-title
      mainTitle="Purchase Asset Overview"
      subTitle="Home"
      backURL="/"
    >
      <div class="full-width text-align-right">
        <input
          type="text"
          [(ngModel)]="searchText"
          (ngModelChange)="search()"
          placeholder="search"
          class="searchBox"
        />
        <button
          type="button"
          routerLink="/fixed-asset/fixed-asset-create-bill"
          class="eCount-def-btn ml-4"
        >
          {{ "billOverview.createNewBill" | translate }}
        </button>
        <button
          type="button"
          (click)="convertToPDF()"
          class="eCount-button3 ml-4"
        >
          {{ "billOverview.export" | translate }}
        </button>
      </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('All')"
          [routerLinkActive]="['active']"
          routerLink="/fixed-asset/fixed-asset-bill-overview/All"
          >{{ "billOverview.all" | translate }}</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('draft')"
          [routerLinkActive]="['active']"
          routerLink="/fixed-asset/fixed-asset-bill-overview/draft"
          >{{ "billOverview.draft" | translate }} ({{ draftCount }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Awaiting-approval')"
          [routerLinkActive]="['active']"
          routerLink="/fixed-asset/fixed-asset-bill-overview/Awaiting-approval"
          >{{ "billOverview.awaitingApproval" | translate }} ({{ awaitCount }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Awaiting-payment')"
          [routerLinkActive]="['active']"
          routerLink="/fixed-asset/fixed-asset-bill-overview/Awaiting-payment"
          >{{ "billOverview.awaitingPayment" | translate }} ({{
            awaitingCount
          }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('rejected')"
          [routerLinkActive]="['active']"
          routerLink="/fixed-asset/fixed-asset-bill-overview/rejected"
          >{{ "billOverview.rejected" | translate }} ({{
            rejectCount
          }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Overdue-payment')"
          [routerLinkActive]="['active']"
          routerLink="/fixed-asset/fixed-asset-bill-overview/Overdue-payment"
          >{{ "billOverview.billOverdue" | translate }} ({{
            overDueCount
          }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Paid')"
          [routerLinkActive]="['active']"
          routerLink="/fixed-asset/fixed-asset-bill-overview/Paid"
          >{{ "billOverview.paid" | translate }} ({{ paidCount }})</a
        >
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" style="padding: 7px 1rem;" (click)="reLoadRowData('Repeating')" [routerLinkActive]="['active']" routerLink="/expense/bill-overview/Repeating">Repeating</a>
      </li> -->
    </ul>
    <div class="row pt-3 agwd" id="printBills">
      <ag-grid-angular
        style="font-size: 14px"
        class="ag-theme-balham col"
        [rowData]="filteredBills"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        [defaultColDef]="defaultColDef"
        [pagination]="true"
        [paginationPageSize]="50"
        (gridReady)="onGridReady($event)"
      >
      </ag-grid-angular>
    </div>
    <!-- <div class="row col-sm-12 grid ml-0">
      <div class="col-sm-1 ml">{{ totalBillAmount | currency:'':''}}</div>
      <div class="col-sm-1 ml-4">{{ totalDiscountAmount | currency:'':''}}</div>
      <div class="col-sm-1">{{ totalDueAmount | currency:'':''}}</div>
    </div> -->
  </app-dashboard>
  