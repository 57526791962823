import { Component, OnInit, NgZone, ElementRef } from '@angular/core';
import { SubscriptionService } from '../../service/Subscription/subscription.service';
import { DataService } from '../../service/EventEmitter/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router , ActivatedRoute} from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MessageManagerService} from '../../service/MessageManager/messagemanager.service';
import { AuthService} from '../../service/auth/auth.service';
import { MainServiceService } from '../../service/Main/main-service.service';
import { FormGroup, FormControl, Validators , FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import Stepper from 'bs-stepper';
import { Coupons } from 'src/app/models/coupons.model';
import { CouponsserviceService } from 'src/app/service/Coupons/couponsservice.service';
@Component({
  selector: 'app-subscription-renew',
  templateUrl: './subscription-renew.component.html',
  styleUrls: ['./subscription-renew.component.css']
})
export class SubscriptionRenewComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService,
    private router: Router,
    private dataService: DataService,
    private modalService: NgbModal,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private subscriptionService: SubscriptionService,
    private _formBuilder: FormBuilder,
    private messageManagerService: MessageManagerService,
    private authService: AuthService,
    private toaster: ToastrService,
    private mainServiceService: MainServiceService,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private couponService: CouponsserviceService
    ) {}
  
  currentUsers :Number;
  currentActiveSubscriptionDetails :any;
  currentBusinessTypeDetailsList: any[] = [];
  currentSelectedPackagePrice :number = 0;
  current_Selected_Package_Type :any;
  radioPackageSelected :any = 0; // 0 means default or previous package value
  currentCompanyPrice :number = 0;
  
  PackageList: any = [];
  BankInfo: any = [];
  isSamePackage = false;
  subscriptionInfo: any;
  BusinessTypeId = 0;
  BillDetails: any={};
  selectdValue: number = 0;
  ShowBank: boolean = false;
  ShowTransferDetails: boolean = true;
  showPayment: boolean = false;
  showPersonDetails: boolean = false;
  fileToUpload: File = null;
  formData: any;
  private stepper: Stepper;
  Today: Date = new Date();
  ExpiryDate: number;
  planId: number;
  showDefaultPackage = true;
  activeStatus: number = 1;
  Data: any = {};
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isOptional = false;
  activeStatusId: number=1;
  contentFirst: boolean =true;
  contentScnd: boolean =true;
  filterType = 0;
  userInfo = false;
  companyName: string;
  packageName: string;
  packageId: number;
  TotalUser: number;
  packagePrice: number;
  TotalAmount = 0;
  businessTypeList: any[];
  basicPriceList: any[] = [];
  professionalPriceList: any[] = [];
  enterPrisePriceList: any[] = [];
  steps: number;
  featurelist: any[];

  AddedTotalUser :number = 0;
  AddedTotalAmount: number = 0;
  IsPackagedRadioButtonChecked = false;
  defaultPackageUsers :number = 0;

  startDate_Formatted :any;
  expiryDate_Formatted : any;

  packageFormGroup: FormGroup;
  userInformation: any;

  serviceUser: number;
  professionalUser: number;
  enterpriseUser: number;

  currentAdditionalUser: any;
  packageStartDate: string;
  packageExpiryDate: string;

  couponData: Coupons;

  ngOnInit(): void {
    this.userInformation = this.authService.getUserInfo();
    
    this.route.queryParams.subscribe(params => {
      this.steps = params.step ===  undefined ? 0 : 1;
      this.hideSidebar(this.steps);
    });

    this.spinner.show();
    this.subscriptionService.GetCompanySubscriptionByUser().subscribe( (x) => {
      this.getAllPackageList();
      this.getDepositBank();
      this.initialiseOrganization();
      this.currentActiveSubscriptionDetails = x;
      this.currentAdditionalUser = x?.AdditionalUser;
      this.currentCompanyPrice = x?.PackagePrice;
      this.startDate_Formatted = x?.ExpiryDate;
      this.getBusinessTypeList(this.currentActiveSubscriptionDetails?.BusinessTypeId);
      this.currentSelectedPackagePrice = x?.PackagePrice;
      this.current_Selected_Package_Type = x?.PackageTypeName;
      this.initializeForms();
      this.spinner.hide();
    });
    this.mainServiceService.GetCompanyTotalUser().subscribe( (x) => {
      this.currentUsers = x;
    });
  }

  initializeForms(){
    this.packageFormGroup = new FormGroup({
      packageStartDate: new FormControl(''),
      packageExpiryDate: new FormControl('')
    });

    var startDate = new Date(this.currentActiveSubscriptionDetails?.ExpiryDate);
    startDate = new Date(startDate.setDate( startDate.getDate() + 1 ));
    if(startDate < new Date()){
      startDate = new Date();
      startDate = new Date(startDate.setDate( startDate.getDate() ));
    }
    this.startDate_Formatted = this.datePipe.transform(startDate, "yyyy-MM-dd" );
    this.packageStartDate = moment(startDate).format("Do MMM YYYY");

    var expireDate = new Date(startDate);
    this.expiryDate_Formatted = this.getNewExpiryDateByPackageType(expireDate)

    this.packageFormGroup.controls['packageStartDate'].patchValue(this.startDate_Formatted);
    this.packageFormGroup.controls['packageExpiryDate'].patchValue(this.expiryDate_Formatted);
  }

  getNewExpiryDateByPackageType(startDate: Date){
    var expireDate;
    switch(this.currentActiveSubscriptionDetails.PackageTypeId){
      case 1:
        expireDate = startDate.setDate( startDate.getDate() + 30 );
        break;
      case 2:
        expireDate = startDate.setDate( startDate.getDate() + 90 );
        break;
      case 3:
        expireDate = startDate.setDate( startDate.getDate() + 365 );
        break
    }
    this.packageExpiryDate = moment(expireDate).format("Do MMM YYYY");
    return this.datePipe.transform( expireDate, "yyyy-MM-dd" );
  }

  updateNewPackageExpiryDate(){
    this.expiryDate_Formatted = this.getNewExpiryDateByPackageType( new Date( this.packageFormGroup.get('packageStartDate').value ) );
    this.packageFormGroup.controls['packageExpiryDate'].patchValue(this.expiryDate_Formatted);
  }

  convertDateToDays(date :Date){
    return date.getTime()/(1000 * 3600 * 24);
  }

  getAllPackageList = () => {

    this.spinner.show();
    this.PackageList = [];
    this.subscriptionService.GetSubscriptionDetails().subscribe((x) => {
      if (x.data.length > 0){
      this.PackageList = x.data;
      this.basicPriceList = x.data.filter(xy => xy.BusinessTypeId === 1);
      this.professionalPriceList = x.data.filter(xy => xy.BusinessTypeId === 2);
      this.enterPrisePriceList = x.data.filter(xy => xy.BusinessTypeId === 3);
      this.serviceUser = this.basicPriceList[0].NumberOfUsers;
      this.professionalUser = this.professionalPriceList[0].NumberOfUsers;
      this.enterpriseUser = this.enterPrisePriceList[0].NumberOfUsers;
      this.businessTypeList = x.BusinessTypeList;
      this.featurelist = x.BusinessFeatureList;
      this.getHistory();
      this.spinner.hide();
      }
    });
  }

  getBusinessTypeList = (businessType) => {
    let body = {
      BusinessTypeId: businessType
    }
    this.subscriptionService.GetPackagesByBusinessType(body).subscribe( (x) => {
      this.currentBusinessTypeDetailsList = x?.data
      let index = this.currentActiveSubscriptionDetails?.PackageTypeId-1;
      this.currentSelectedPackagePrice = this.currentBusinessTypeDetailsList[index]?.PackagePrice;
      console.log(this.currentBusinessTypeDetailsList)
    } );
  }

  setNewPackageType(event, type){
    console.log("TARGET: ", event);
    this.current_Selected_Package_Type = type;
    this.IsPackagedRadioButtonChecked = true;
    
    switch(this.currentActiveSubscriptionDetails.BusinessTypeId){
      case 1:
        this.defaultPackageUsers = this.serviceUser;
        break;
      case 2:
        this.defaultPackageUsers = this.professionalUser;
        break;
      case 3:
        this.defaultPackageUsers = this.enterpriseUser;
        break;
    }

    switch(type){
      case 'Monthly':
        this.currentCompanyPrice = this.getPackagePriceForMonthlyUser();
        this.currentSelectedPackagePrice = this.currentBusinessTypeDetailsList[0].PackagePrice;
        this.currentActiveSubscriptionDetails.PackageTypeId = 1;
        break;
      case 'Quarterly':
        this.currentCompanyPrice = this.getPackagePriceForQuarterlyUser();
        this.currentSelectedPackagePrice = this.currentBusinessTypeDetailsList[1].PackagePrice;
        this.currentActiveSubscriptionDetails.PackageTypeId = 2;
        break;
      case 'Yearly':
        this.currentCompanyPrice = this.getPackagePriceForYearlyUser();
        this.currentSelectedPackagePrice = this.currentBusinessTypeDetailsList[2].PackagePrice;
        this.currentActiveSubscriptionDetails.PackageTypeId = 3;
        break;
    }
    this.AddedTotalAmount = this.AddedTotalUser*this.currentSelectedPackagePrice;
    this.updateNewPackageExpiryDate();
  }

  getPackagePriceForMonthlyUser(){
    var originalPackagePrice = this.currentBusinessTypeDetailsList[0].PackagePrice;
    var additionalUsers = Math.abs( this.currentActiveSubscriptionDetails.AdditionalUser - this.defaultPackageUsers );
    return  originalPackagePrice + (originalPackagePrice * additionalUsers);
  }

  getPackagePriceForQuarterlyUser(){
    var originalPackagePrice = this.currentBusinessTypeDetailsList[1].PackagePrice;
    var additionalUsers = Math.abs( this.currentActiveSubscriptionDetails.AdditionalUser - this.defaultPackageUsers );
    return  originalPackagePrice + (originalPackagePrice * additionalUsers);
  }

  getPackagePriceForYearlyUser(){
    var originalPackagePrice = this.currentBusinessTypeDetailsList[2].PackagePrice;
    var additionalUsers = Math.abs( this.currentActiveSubscriptionDetails.AdditionalUser - this.defaultPackageUsers );
    return  originalPackagePrice + (originalPackagePrice * additionalUsers);
  }

  onAdditionalUserInputChange(AddedTotalUser){
    this.AddedTotalUser = AddedTotalUser;
    this.AddedTotalAmount = this.AddedTotalUser*this.currentSelectedPackagePrice;
  }

  hideSidebar = (type) => {
    if (type == 1)
    {
      this.dataService.emitSidebarHide(false);
    }
  }
  backToPreviousPage = () => {

    this.steps === 0 ?  this.router.navigate(['/settings/subscription-list']) :
     this.router.navigate(['/settings/organization'], { queryParams: { step: 2 }  });
  }
  initialiseOrganization(){
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userInfo);
    if (userInfo) {
      this.companyName = userInfo.SelectedCompany.Name;
    }
  }
  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  couponForm = new FormGroup({
    Name: new FormControl('', [Validators.required]),
  });

  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
    this.formData = new FormData();
    this.formData.append('file', this.myForm.get('fileSource').value);
  }

  get f() {
    return this.myForm.controls;
  }

  getHistory = () => {
    this.spinner.show();
    this.subscriptionService.GetBillDetails().subscribe((x) => {
      if (x){
      this.BillDetails = x.data;
      this.subscriptionInfo = x.subscription;
      console.log(this.subscriptionInfo);
      this.BillDetails.OrganizationTypeId  = this.BillDetails.OrganizationTypeId <= 6 ? 1 :
      this.BillDetails.OrganizationTypeId <= 9 ? 2 : 3;
      this.filterType = this.subscriptionInfo.ExpiredOrInactive == false && this.subscriptionInfo.PackageTypeName != 'Trial'
      ? this.subscriptionInfo.SubscriptionPlanId :  this.BillDetails.OrganizationTypeId  * 3;
      this.showDefaultPackage = this.subscriptionInfo.ExpiredOrInactive == false && this.subscriptionInfo.PackageTypeName != 'Trial' ?
      true : false;
      const value = this.PackageList.filter(xy => xy.Id === this.filterType);
      this.onItemChange(value[0]);
      this.spinner.hide();
      }
      else
      {
        this.spinner.hide();
      }
    });

  }
  addNewUser = () =>
  {
    this.userInfo = true;

  }
  getDepositBank = () => {
    this.spinner.show();
    this.subscriptionService.GetSubscriptionDepositBankAccount().subscribe((x) => {
      this.BankInfo = x;
      this.spinner.hide();

    });
  }

  showPaymentDetails = (model) => {
    this.BillDetails = model;
    this.showPayment = true;
    this.showPersonDetails = false;
    this.contentScnd=false;
    this.activeStatusId=3;
  }
  onItemChange(value) {
    console.log(value.Id);
    console.log(this.subscriptionInfo.SubscriptionPlanId);
    this.isSamePackage = value.Id != this.subscriptionInfo.SubscriptionPlanId ?
    false : true;
    this.selectdValue = value.Id;
    this.packageId = value.Id;
    this.packageName = value.PackageName;
    this.packagePrice = value.PackagePrice;
    this.BusinessTypeId = value.BusinessTypeId;
    var date = new Date();
    if (value.PackageTypeId == 1) {
      this.ExpiryDate = date.setDate(date.getDate() + 30);

    }
    else if (value.PackageTypeId == 2){
      this.ExpiryDate = date.setDate(date.getDate() + 120);
    }
    else {
      this.ExpiryDate = date.setDate(date.getDate() + 365);
    }

  }
  backToPlan()
  {
     this.setFilterData(1);
  }
  public setFilterData(a){
       this.myForm.reset();
       if(a==2)
       {
          this.activeStatusId=a;
          this.contentScnd=true;
       }
       else if(a==1)
       {
        this.activeStatusId=a;
        this.contentFirst=true;
        this.contentScnd=true;
        this.filterType=this.selectdValue;
       }
       else
       {
          this.activeStatusId=3;
          this.contentFirst=false;
       }
   }
  selectedValue(a) {
    this.filterType = a;
    if (this.filterType == 0) {
      this.toaster.warning('Please select a Plan');
      return;
    }
    if(this.mainServiceService.IsNull(this.currentSelectedPackagePrice)){
      this.toaster.warning('Please select a Plan');
      return;
    }
    else {
      this.filterType = this.selectdValue;
      this.ShowBank = true;
      this.showPersonDetails = true;
      this.contentFirst = false;
      this.activeStatusId = 2;
      this.getPackageName(this.selectdValue);
    }
  }


  onShowTransferMoneyDetails(value) {
    if (value == 3) {

      this.ShowTransferDetails = true;
    }
    else
    {
      this.ShowTransferDetails=false;
    }
  }
  convertDateIntoDisplay(date) {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  getPackageName(id)
  {
     var list = this.PackageList.find(x => x.Id == this.packageId);
     this.packageName = list.PackageName;
     this.packagePrice = this.isSamePackage === false ? list.PackagePrice + this.TotalAmount : this.TotalAmount;
  }

  setData = () => {
    const data = {
      //SubscriptionPlanId: this.selectdValue,
      SubscriptionPlanId: this.currentBusinessTypeDetailsList[this.currentActiveSubscriptionDetails.PackageTypeId-1].Id,
      BillToName: this.BillDetails.BillToName,
      BillToEmail: this.BillDetails.BillToEmail,
      PhoneNo: this.BillDetails.PhoneNo,
      AdditionalUser: this.AddedTotalUser + this.currentActiveSubscriptionDetails.AdditionalUser,
      StartDate: this.getUTC(new Date(this.packageFormGroup.get('packageStartDate').value)),
      ExpiryDate: this.getUTC(new Date(this.packageFormGroup.get('packageExpiryDate').value)),
      Coupons: this.couponData
    };
    return data;
  }

  getUTC(date: Date){
    var momentUTC = moment.utc(date).format();
    return new Date(momentUTC);
  }


  confirmPayment = () => {

    const BillDetails = this.setData();

    if (this.myForm.invalid)
    {
      this.toaster.warning('Please attach receipt document !');
      return ;
    }
    this.spinner.show();

    const companyInfo = {
      CompanyName: this.userInformation.SelectedCompany.Name.toString(),
      CompanyId: this.userInformation.SelectedCompany.CompanyId
    }
    this.subscriptionService.renewSubscription(BillDetails).subscribe((x) => {
      if (x.Success) {
        this.subscriptionService.saveAttachment(this.formData).subscribe((xy) => {
          this.mainServiceService.ReActivateSubscription().subscribe((w) => {
            this.subscriptionService.GetSubscriberByCompany(companyInfo).subscribe((z) => {
              if (xy.Success) {
                this.spinner.hide();
                this.userInformation.IsCompanySubscriptionExpired = z.filter(ui => ui.IsActive == true).length == 0 ? true : false;
                this.authService.setUserInfo(JSON.stringify(this.userInformation));
                this.toaster.success('Subscription payment has been done successfully.');
                if (this.steps === 1 )
                {
                  this.UpdateStep();
                }
                else
                {
                  this.getMessage();
                  this.router.navigate(['/']);
                }
              }
            });
          });
        });
      }

    });

  }
  public UpdateStep() {
    this.spinner.show();
    this.messageManagerService.StepDone().subscribe(x => {
      this.spinner.hide();
      this.getMessage();
      this.router.navigate(['/accounting/bank-accounts'], { queryParams: { step: 1 }  });
      if (x.AllDone){
        const userInfo = this.authService.getUserInfo();
        userInfo.CompanyList = x.Data;
        this.authService.setUserInfo(JSON.stringify(userInfo));
      }
    });
  }
  private getMessage(){
    this.messageManagerService.GetMessage().subscribe(x => {

      this.dataService.emitSetMessageUpdate(x);
  });
  }
  getTotalAmount(value: number) {
    if(value<0){
      this.toaster.error("User number can't be negative.");
      this.TotalUser = 0;
      return;
    }
    this.selectdValue = this.packageId;
    const data = this.PackageList.filter(x => x.Id === this.packageId);
    if (data && this.isSamePackage == false)
    {
      this.TotalAmount = value * data[0].PackagePrice;
      this.packagePrice += this.TotalAmount;
    }
    else if (data && this.isSamePackage == true)
    {
      this.TotalAmount = value * data[0].PackagePrice;
      this.packagePrice = this.TotalAmount;
    }
  }

  removeItem(item: HTMLElement) {
    item.remove();
  }

  applyCoupon(){
    if(this.couponForm.invalid){
      this.toaster.warning("Please provide a code!");
      return;
    }
    var couponData: Coupons = {
      Id: 0,
      Name: this.couponForm.value.Name,
      UseLimit: 0,
      ExpiryDate: new Date(),
      DiscountAmount: 0,
      CreatedDate: new Date(),
      TotalUsed: 0
    }
    this.spinner.show();
    this.couponService.verifyAndGetCouponInformation(couponData).subscribe(x => {
      this.spinner.hide();
      if(x.Result.Success){
        this.toaster.success("Coupon Applied Successfully!");
        this.couponData = x.CouponData;
      }else{
        this.toaster.error(x.Result.Message);
      }
    });
  }

}
