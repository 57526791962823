import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { SettingsServiceService } from '../../../service/Settings/settings-service.service';

@Component({
  selector: 'app-invoice-setting',
  templateUrl: './invoice-setting.component.html',
  styleUrls: ['./invoice-setting.component.css']
})
export class InvoiceSettingComponent implements OnInit {
  
  templateSettings:boolean=true;
  dateSettings:boolean=false;
  dateSettingsList:any=[];

  constructor(private ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private pService: SettingsServiceService
    ) { }

  ob;

  ngOnInit(): void {
    this.ob = JSON.parse(localStorage.getItem('tem'));
    if(!this.ob){
        this.ob={
        t1:true,
        t2:false
      }
    }
    this.getDataSettings();
  }

  getDataSettings = () => {
    this.pService.GetDataSettings().subscribe((x) => {
      this.dateSettingsList=x;
      console.log(x);
    });
  }

  selected:string;
  imageSource:string;
  viewTemplate(name:string){
    this.selected = name;
    this.imageSource = name == 't1' ? "../../../../assets/main/images/templates/template1.png" : "../../../../assets/main/images/templates/template2.png";
    this.ngxSmartModalService.create('templatePreview', 'content').open();
  }

  templateSelcetor(id:string){
    this.ob['t1'] = id == 't1' ? this.ob[id] == true ? true: !this.ob[id] : false;
    this.ob['t2'] = id == 't2' ? this.ob[id] == true ? true : !this.ob[id] : false;
    this.ob;
    this.save();
  }


  save(){
    localStorage.setItem('tem', JSON.stringify(this.ob))
    this.toaster.success('successfully saved !');
  }
  toggleTemplate = (status: string) => {
    if(status=="templateSettings"){
      this.templateSettings=true;
      this.dateSettings=false;
    }
    else if(status=="dateSettings"){
      this.templateSettings=false;
      this.dateSettings=true;
    }
  }
  toggleDataSettingsCheckBox = (item:any) => {
    this.pService.SaveDataSettingsMaintenance(item).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('successfully saved !');
      } else {
        this.toaster.error(x.Message);
      }
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }
}
