import { Item, WareHouse } from "./inventoryOverview.model";


export class WareHouseItems {
    public Id: number;
    public WareHouseId: number;
    public ProductId: number;
    public Demand: number;
    public ProductInOut: number;
    public ItemDetails?: Item;
}




