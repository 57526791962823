import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { createBill } from '../../models/income.model'
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AccountTypes } from 'src/app/models/accounting.model';

@Injectable({
  providedIn: 'root'
})
export class BillsServiceService {
  baseurl = environment.baseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private http: HttpClient) { }

  httpAttachmentOptions: any = {
    reportProgress: true, observe: 'events', headers: new HttpHeaders({
      'Authorization': `Bearer ${this.userInfo ? this.userInfo.Token : null}`
    })
  }

  GetAllSupplierDetails(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'supplier/getsuppliers').pipe(retry(1));
  }
  //get saleable Item
  GetPurchaseableItems(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetPurchaseableItems').pipe(retry(1));
  }

  GetIsUserAtMaxApprovalLevel(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Bill/IsUserAtMaxApprovalLevel').pipe(retry(1));
  }

  //save create Bill
  saveAsBillData(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/Save', data).pipe(retry(1));
  }

  saveAsBillDataForPurchaseOrder(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/SaveForPurchaseOrder', data).pipe(retry(1));
  }

  //update approved bill
  updateApproveBillData(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/UpdateApproveBill', data).pipe(retry(1));
  }

  //update bill
  Update(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/Update', data);
  }

  //update bill status
  UpdateBillStatus(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/UpdateStatus', data);
  }

  getSupplierBalance(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/GetSupplierBalance', data).pipe(retry(1));
  }

  //save as draft create Bill
  saveAsDraftBillData(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/SaveAsDraftBill', data).pipe(retry(1));
  }
  //update as draft create Bill
  updateAsDraftBillData(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/UpdateAsDraftBill', data).pipe(retry(1));
  }
  //Create Conversion Balance Bill
  saveConversionBalanceBillData(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'ConversionBalanceBill/Save', data).pipe(retry(1));
  }

  UpdateAsDraftBillForSave(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/UpdateAsDraftBillForSave', data).pipe(retry(1));
  }

  UpdateApprovedBill(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'Bill/UpdateApprovedBill', data).pipe(retry(1));
  }

  //get all bill list

  //get saleable Item
  getAllBillList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Bill/GetBillList').pipe(retry(1));
  }

  //single bill details
  getBillsByBillNo(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `Bill/GetBillsByBillNo?billNo=${data}`).pipe(retry(1));
  }
  //single bill details with Permission
  getBillsPermissionByBillNo(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `Bill/GetBillsPermissionByBillNo?billNo=${data}`).pipe(retry(1));
  }
  //single bill details
  getSingleBillDetails(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `Bill/GetBill?id=${data}`).pipe(retry(1));
  }
  //BillPayment/GetListByBillId?billId=
  GetPaymentsByBillId(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `BillPayment/GetListByBillId?billId=${data}`).pipe(retry(1));
  }

  GetBillPaymentReturnList(billId): Observable<any> {
    return this.http.get<any>(this.baseurl + `BillPayment/GetReturnedListByBillId?billId=${billId}`).pipe(retry(1));
  }

  //save create invoice
  saveMakePayment(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'BillPayment/Save', data).pipe(retry(1));
  }

  ReversePayment(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'BillPayment/ReversePayment', data);
  }

  //save create invoice
  editMakePayment(data): Observable<any> {
    return this.http.post<createBill>(this.baseurl + 'BillPayment/Update', data).pipe(retry(1));
  }
  GetAllBankAccountBalanceList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Dashboard/GetBankAccountBalanceList').pipe(retry(1));
  }
  //get all customer
  GetAllContact(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Contact/GetAllSupplier').pipe(retry(1));
  }

  UploadBillAttachmentById(ff: FormData, id: number) {
    return this.http.post(this.baseurl + 'Bill/UploadBillFiles?billId=' + id, ff, this.httpAttachmentOptions).pipe(
      retry(1)
    )
  }
  GetAllAttachmentById(id: number) {
    return this.http.get<any>(this.baseurl + "Bill/GetBillFiles?billId=" + id).pipe(retry(1));
  }

  DeleteAttachmentById(id: number) {
    return this.http.get(this.baseurl + "Bill/DeleteFile?id=" + id).pipe(retry(1));
  }

  public SendMail(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Bill/SendMail', data).pipe(retry(1));
  }

  public GetMailDetails(id: any): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Bill/GetMailDetails?id=' + id).pipe(retry(1));
  }
  public SendMailWithAttachment(data: any): Observable<any> {
    return this.http.post(this.baseurl + 'Bill/SendMailWithAttachment', data, this.httpAttachmentOptions).pipe(
      retry(1)
    )
  }

  public SaveBasicBill(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BasicBill/Save', data).pipe(retry(1));
  }

  public GetBasicBills(): Observable<any> {
    return this.http.get(this.baseurl + "BasicBill/GetBasicBills").pipe(retry(1));
  }
  public GetRepeatingBills(): Observable<any> {
    return this.http.get(this.baseurl + "BasicBill/GetRepeatingBills").pipe(retry(1));
  }
  public CreateBillFromRepeating(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BasicBill/CreateBillFromRepeating', data).pipe(retry(1));
  }
  SubmitDiscountAmount(billId, amount): Observable<any> {
    return this.http.get<any>(this.baseurl + `Bill/UpdateDiscountAmount?id=${billId}&amount=${amount}`)
      .pipe(
        retry(1)
      );
  }
  // stop repeating bill
  StopRepeating(id): Observable<any> {
    return this.http.get<any>(this.baseurl + `BasicBill/StopRepeatingTypeById?id=${id}`)
      .pipe(
        retry(1)
      );
  }
  //delete bill

  deleteBillData(billId): Observable<any> {
    return this.http.get<any>(this.baseurl + `Bill/DeleteBill?id=${billId}`).pipe(retry(1));
  }

  deleteBillDataForPurchaseOrder(billId): Observable<any> {
    return this.http.get<any>(this.baseurl + `Bill/DeleteBillForPurchaseOrder?id=${billId}`).pipe(retry(1));
  }

  // reject bill
  rejectBillData(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Bill/RejectBill', data).pipe(retry(1));
  }
  getMonthlyExpense(month, year) {
    return this.http.get(this.baseurl + `Report/GetMonthlyExpense?month=${month} &year=${year}`).pipe(retry(1));
  }
  public GetChartOfAccounts(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'ChartOfAccount/GetChartOfAccounts')
      .pipe(
        retry(1)
      );
  }
  public GetBudgetChartOfAccounts(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'ChartOfAccount/GetChartOfAccountsForBudget')
      .pipe(
        retry(1)
      );
  }
  public saveMonthlyBudget(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'MonthlyBudget/Save', data)
      .pipe(
        retry(1)
      );
  }
  GetMonthlyBudget(month, year): Observable<any> {
    return this.http.get<any>(this.baseurl + `MonthlyBudget/GetMonthlyBudget?month=${month}&year=${year}`)
      .pipe(
        retry(1)
      );
  }
  GenerateBillNo(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Bill/GenerateBillNo')
      .pipe(
        retry(1)
      );
  }
  CheckApprover(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Bill/CheckApproverPermission')
      .pipe(
        retry(1)
      );
  }

  GetDataForCreateBillFromWareHouseOut(wareHouseId): Observable<any> {
    return this.http.get<any>(this.baseurl + `Bill/GetDataForCreateBillFromWareHouseOut?wareHouseId=${wareHouseId}`).pipe(retry(1));
  }

  getAllFixedAssetBillList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'FixedAssetBill/GetFixedAssetBillList').pipe(retry(1));
  }

  deleteBillDataForFixedAssetItem(billId): Observable<any> {
    return this.http.get<any>(this.baseurl + `FixedAssetBill/DeleteBill?id=${billId}`).pipe(retry(1));
  }

  public SubmitBills(billIds: number[]): Observable<any> {
    return this.http.post<any>(this.baseurl + "Bill/SubmitBills", billIds);
  }
  public ApproveBills(billIds: number[]): Observable<any> {
    return this.http.post<any>(this.baseurl + "Bill/ApproveBills", billIds);
  }

  public GetCurrentMonthBillList(month, year, accountId) {
    return this.http.get(this.baseurl + `Bill/GetCurrentMonthBillList?month=${month} &year=${year} &accountId=${accountId}`).pipe(retry(1));
  }

  ResetToDraft(bill): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Bill/ResetToDraft', bill);
  }
}
