import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal,NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { AssetTypeCreateComponent} from '../asset-type-create/asset-type-create.component';
import { AssetTypeModel } from 'src/app/models/fixedAsset.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-asset-item-create',
  templateUrl: './asset-item-create.component.html',
  styleUrls: ['./asset-item-create.component.css']
})
export class AssetItemCreateComponent implements OnInit {

 public assetItemForm: FormGroup;
 public assetTypeList: any =[];
 public inputHelp: any = {};
 public val: any={};
 //public showRegisterButton: boolean=false;
 selectItem: NgSelectComponent;
  @Input() fromParent: any;
  @Input() lastFixedAssetItemNumber: any;
  public aModel : AssetTypeModel= new AssetTypeModel();
  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private dataService:DataService,
    private modalService: NgbModal, 
    private pService: FixedAssetService,
    private router:Router) { }

  ngOnInit(): void {
    this.getTypeList();
    this.getInputHelp();
    this.initializeAssetItemForm();
    console.log(this.fromParent);
  }

  private getInputHelp = () => {
    this.pService.GetAssetTypeInputHelp().subscribe((x) => {
      this.inputHelp = x;
      this.val=this.inputHelp.AveragingMethods[0];
    },(err)=>{});
  };

  private getTypeList = () => {
    this.pService.GetFixedAssetTypeList().subscribe((x) => {
      this.assetTypeList = x;
      //this.assetTypeList.unshift({"Id":"","AssetType":""});
    },(err)=>{
      this.toaster.error("There is an error,please check");
    })
  };

  /**
   * getTypeDetails
id:number   */
  public getTypeDetails() {
    var fValue=this.assetItemForm.value;
    this.pService.GetAssetTypeDetails(fValue.AssetTypeId).subscribe((x) => {
      this.fromParent.TypeDetails=x;
      //this.showRegisterButton=x.DepreciationMethodId==2;
      this.assetItemForm.controls['DepreciationMethodId'].setValue(x.DepreciationMethodId);
      this.assetItemForm.controls['AveragingMethodId'].setValue(x.AveragingMethodId);
      this.assetItemForm.controls['RatePercent'].setValue(x.RatePercent);
      this.assetItemForm.controls['EffectiveLifeYears'].setValue(x.EffectiveLifeYears);
      this.assetItemForm.controls['IsRatePercent'].setValue(x.IsRatePercent);
    },(err)=>{
      this.toaster.error("There is an error,please check");
    });
  }

  /**
   * setDepreciationType
   */
  public setDepreciationType() {
    var fValue=this.assetItemForm.value;
    //this.showRegisterButton=fValue.DepreciationMethodId==2;
  }

  /**
   * setDepreciationValueType
   */
  public setDepreciationValueType() {
    if(this.assetItemForm.value.IsRatePercent){
      this.assetItemForm.controls['EffectiveLifeYears'].setValue(null);
    }
    else{
      this.assetItemForm.controls['RatePercent'].setValue(null);
    }
  }
  openItemModal(selectItem: NgSelectComponent){
    selectItem.close();
    const modalRefs = this.modalService.open(AssetTypeCreateComponent,{ size: 'md',backdrop: 'static', keyboard : false,});
    modalRefs.componentInstance.fromParent = this.aModel;
    modalRefs.result.then((result) => {
      this.getTypeList();
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }
  
  initializeAssetItemForm() {
    this.assetItemForm = this.fb.group({
      Id:[this.fromParent.Id],
      AssetTypeId:[this.fromParent.AssetTypeId],
      AssetName: [this.fromParent.AssetName, Validators.required],
      AssetNumber: [this.fromParent.AssetNumber != null ? this.fromParent.AssetNumber : this.lastFixedAssetItemNumber],
      PurchaseDate: [this.fromParent.PurchaseDate],
      PurchasePrice: [this.fromParent.PurchasePrice,Validators.required],
      WarrantyExpiryDate: [this.fromParent.WarrantyExpiryDate],
      SerialNo: [this.fromParent.SerialNo],
      Description: [this.fromParent.Description],
      DepreciationStartDate: [this.fromParent.DepreciationStartDate == null ? (this.fromParent.PurchaseDate ?? new Date()) : this.fromParent.DepreciationStartDate],
      //DepreciationMethodId: [this.fromParent.DepreciationMethodId == null ? 2 : this.fromParent.DepreciationMethodId],
      //AveragingMethodId: [this.fromParent.AveragingMethodId==null?1:this.fromParent.AveragingMethodId],
      DepreciationMethodId: [this.fromParent.DepreciationMethodId],
      AveragingMethodId: [this.fromParent.AveragingMethodId],
      RatePercent: [this.fromParent.RatePercent],
      EffectiveLifeYears: [this.fromParent.EffectiveLifeYears],
      IsRatePercent: [this.fromParent.IsRatePercent],
      StatusId: [0],
      BillId: [this.fromParent.BillId]
    });
    this.showpurchaseDate(this.fromParent.PurchaseDate);
    this.showStartDate(this.fromParent.DepreciationStartDate);
    this.fromParent.WarrantyExpiryDate !=null ? this.showWarrentyDate(this.fromParent.WarrantyExpiryDate) : '';
  }

  convertCalenderDate(d:any) {
    if(d==null)
     return '';

    let dateString = d.split("T")[0];
    return dateString;
  }

  dateFormat(d) {
    const date = new Date(d);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const formattedDate = new Date(year, month, day);
    return formattedDate;
  }

  public saveAssetItem(s:number) {
    this.spinner.show();
    this.assetItemForm.controls["StatusId"].patchValue(s);
    if (this.assetItemForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      this.spinner.hide();
      return;
    }
    if(this.assetItemForm.value.PurchaseDate == null) {
      this.assetItemForm.value.PurchaseDate = new Date();
    }
    const depreciationStartDate = this.dateFormat(this.assetItemForm.value.DepreciationStartDate);
    const purchaseDate = this.dateFormat(this.assetItemForm.value.PurchaseDate);
    if(depreciationStartDate <  purchaseDate) {
      this.spinner.hide();
      this.toaster.warning('Please choose a depreciation start date that is either on or after the purchase date!');
      return;
    }

    if( s == 2 && this.assetItemForm.value.AssetTypeId == null) {
      this.spinner.hide();
      this.toaster.warning('Please select an asset type!');
      return;
    }
    if( this.fromParent.Id != 0 )
    {
      this.pService.ReverseDepreciation(this.assetItemForm.value).subscribe((z) => {
        this.pService.UpdateDepreciation(this.assetItemForm.value).subscribe((y) => {
          //this.toaster.success(this.fromParent.Id==0? 'Asset item has been successfully added.':"Asset item updated successfully.");
          this.toaster.success('Asset item has been successfully added.');
          this.activeModal.close(y);
          this.dataService.emitFixedAssetItemUpdate(true);
          this.spinner.hide();
        });
      });
    }else{
      this.pService.SaveFixedAssetItem(this.assetItemForm.value,s).subscribe((x)=>{
        if(x.Success){
          this.pService.UpdateDepreciation(x.Data).subscribe((y) => {
            //this.toaster.success(this.fromParent.Id==0? 'Asset item has been successfully added.':"Asset item updated successfully.");
            this.toaster.success('Asset item has been successfully added.');
            if(s == 1) {
              this.router.navigate(['fixed-asset/setup/draft']);
            }
            else {
              this.router.navigate(['fixed-asset/setup/registered']);
            }
            this.activeModal.close(y);
            this.dataService.emitFixedAssetItemUpdate(true);
            this.spinner.hide();
          });
        }
        else{
          this.toaster.error(x.Message);
          this.spinner.hide();
        }
      }, err=>{
          this.toaster.error('ooh, something went wrong !');
          this.spinner.hide();
      });
    }

    
  }
  showpurchaseDate(e) {
    const value = this.assetItemForm.value.PurchaseDate !=null ? this.assetItemForm.value.PurchaseDate : new Date();
    $("#fatest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  showStartDate(e) {
    const value = this.assetItemForm.value.DepreciationStartDate  !=null ? this.assetItemForm.value.DepreciationStartDate : new Date();
    $("#fatest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
  showWarrentyDate(e) {
    const value = this.assetItemForm.value.WarrantyExpiryDate !=null ? this.assetItemForm.value.WarrantyExpiryDate : '';
    $("#fatest2").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }

  canShowDepreciationDetails(): boolean {
    const depreciationMethodId = this.assetItemForm.get('DepreciationMethodId').value;
    const hasDepreciation = depreciationMethodId === 2;
  
    if (hasDepreciation) {
      const depreciationStartDate = this.fromParent.DepreciationStartDate;
      const purchaseDate = this.fromParent.PurchaseDate;
      const startDate = depreciationStartDate ?? purchaseDate;
      this.showStartDate(startDate);
      return true;
    } else {
      return false;
    }
  }

  DeleteFixedAssetItem() {
    this.spinner.show();
    this.pService.DeleteDraftFixedAssetItem(this.fromParent.Id).subscribe((x) => {
      if(x){
        this.toaster.success('Asset item has been successfully deleted.');
        this.activeModal.close();
        this.spinner.hide();
        location.reload();
      }
    });
  }

}
