import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewItemComponent } from '../view-item/view-item.component';

@Component({
  selector: 'app-shipment-item-view',
  templateUrl: './shipment-item-view.component.html',
  styleUrls: ['./shipment-item-view.component.css']
})
export class ShipmentItemViewComponent implements OnInit {

  ShipmentId: string;
  ShipmentNo: string;

  agInit(params){
    this.ShipmentNo = params.value;
    this.ShipmentId = params.data.Id;
  }

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {}
  
  viewItem=()=>{
    const modalRef = this.modalService.open(ViewItemComponent, { size: 'lg', backdrop: 'static', keyboard: false, centered: true });
    modalRef.componentInstance.fromParent = {ShipmentId:this.ShipmentId};
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

}
