<app-dashboard>
    <app-page-header-title
        mainTitle="Transfer Money"
        subTitle="Bank Transaction"
        backURL="/accounting/bank-accounts">
        <div class="full-width text-align-right">
            <button type="button" (click)="saveCreateTransferMoney()" class="eCount-def-btn ml-4">Transfer</button>    
        </div>
    
    </app-page-header-title>
    <div class="bodySection">
        <form [formGroup]="TransferMoneyForm">
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-2 f-ss-14px col-form-label">From<span class="clr">*</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="bankList" [clearable]="false" bindLabel="AccountName"  bindValue="GLAccountId"  [(ngModel)]="selectedBankFrom" formControlName="SendAccountId">
                        <ng-template ng-option-tmp let-item="item" let-index="index" style="display: inline-grid;">     
                            <span>{{item.BankAccount}}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-2 col-form-label">To<span class="clr">*</span></label>
                <div class="col-sm-6">
                    <ng-select [items]="bankList" [clearable]="false" bindLabel="AccountName"  bindValue="GLAccountId"  [(ngModel)]="selectedBankTo" formControlName="ReceivedAccountId" >
                        <ng-template ng-option-tmp let-item="item" let-index="index" style="display: inline-grid;">     
                            <span>{{item.BankAccount}}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabel " class="col-sm-2 f-ss-14px col-form-label">Date</label>
                <div class="col-sm-6">
                    <input class="form-control" type="date"  formControlName="TransferDate" >
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-2 f-ss-14px col-form-label">Amount<span class="clr">*</span></label>
                <div class="col-sm-6">
                    <input type="text" appDecimaNumericValidator  [(ngModel)]="Amount" placeholder="Amount"  class="form-control" formControlName="Amount" />
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-2 f-ss-14px col-form-label">Reference<span class="clr">*</span></label>
                <div class="col-sm-6">
                    <input type="text" [(ngModel)]="ReferenceNo" placeholder="ReferenceNo"  class="form-control" formControlName="ReferenceNo" />
                </div>
            </div>
        </form>
    </div>
</app-dashboard>
