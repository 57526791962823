<app-dashboard>
    <app-page-header-title mainTitle="{{itemListTitle}}" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">           
            <button (click)="onBtnExport()"
                class="eCount-button3 ml-4">{{'Contacts.contactList.export'| translate}}</button>
        </div>
        
    </app-page-header-title>   
    <div class="row pt-3">
        <ag-grid-angular style="font-size: 14px;" class="ag-theme-balham col" [rowData]="contacts"
            [columnDefs]="columnDefs" (gridReady)="onGridReady($event)" [domLayout]="domLayout" (rowClicked)="onRowClicked($event)" [defaultColDef]="defaultColDef">
        </ag-grid-angular>
    </div>
</app-dashboard>