import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductServiceService } from '../../../service/Products/product-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { stringify } from '@angular/compiler/src/util';
import { BillsServiceService } from 'src/app/service/Bill/bills-service.service';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { PurchaseOrderService } from 'src/app/service/PurchaseOrder/purchase-order.service';
import { ItemCreateViewModel } from 'src/app/models-viewModels/itemCreateViewModel';

@Component({
  selector: 'app-item-create',
  templateUrl: './item-create.component.html',
  styleUrls: ['./item-create.component.css']
})
export class ItemCreateComponent implements OnInit {
  @Input() fromParent: any;
  itemForm: FormGroup;
  public itemId:number=0;
  itemPageTitle:string="Trading Items";
  itemTypeList:any=[
    {Id: 1, Name:"Trading Items"},
    {Id: 2, Name:"Raw Materials"},
    {Id: 4, Name:"Storage"},
    {Id: 5, Name:"Service"}
  ];

  supplierList: any = [];
  loading = false;
  selectedSupplier: number;

  isBuyChecked: boolean = false;
  isSaleChecked: boolean = false;
  isManufactureChecked: boolean = false;

  constructor(private spinner: NgxSpinnerService, 
             public fb: FormBuilder, 
             private toaster: ToastrService,
             public activeModal: NgbActiveModal,
             private productServiceService: ProductServiceService,
             private billService: BillsServiceService,
             private mainService: MainServiceService,
             private pService: PurchaseOrderService) { }


  ngOnInit(): void {
    this.getAllSupplier();
    this.initializeItemForm();
    this.itemId = this.fromParent.Id;
    this.isBuyChecked = this.fromParent.IsPurchaseItem;
    this.isSaleChecked = this.fromParent.IsSaleItem;
    this.isManufactureChecked = this.fromParent.IsManufactureItem;
    switch(this.itemForm.value.ItemTypeId){
      case 1:
        this.itemPageTitle="Trading Items"
        break;
      case 2:
        this.itemPageTitle="Raw Materials"
        break;
      case 3:
        this.itemPageTitle="Finished Products"
        break;
      case 4:
        this.itemPageTitle="Storage Items"
        break;
      case 5:
        this.itemPageTitle="Services"
        break;       
      default:
        this.itemPageTitle="Trading Items"
        break;
    }
  }

  getAllSupplier = () => {
    this.pService.GetSupplierList().subscribe((x) => {
      this.supplierList = x;
    });
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: false,
      IsSupplier: true,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.supplierList.push(x["Data"]);
        let customerObj = this.supplierList.filter(x=>x.PrimaryPersonFirstName == customerName);
        this.itemForm.controls['SupplierId'].patchValue(customerObj[0]['Id']);
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }

  initializeItemForm() {
    this.itemForm = this.fb.group({
      Id: [this.fromParent.Id],
      ItemCode: [this.fromParent.ItemCode, Validators.required],
      ItemName: [this.fromParent.ItemName, Validators.required],
      PurchasePrice: [this.fromParent.PurchasePrice],
      SaleUnitPrice: [this.fromParent.SaleUnitPrice],
      ItemTypeId: [this.fromParent.ItemTypeId],
      Description: [this.fromParent.PurchaseDescription],
      SupplierId: [this.fromParent.SupplierId]
    });
  }

 public saveOrUpdate() {
    if(this.checkFormValidation()) {
      this.spinner.show();
      let data = this.formatData();
      this.productServiceService.saveProductData(data).subscribe((x)=>{
        if(x.Success){
          this.toaster.success(this.fromParent.Id == 0 ? 'Item has been successfully added.' : 'Item updated successfully.');
          this.activeModal.close(x);
        }
        else{
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      });
    }
  }

  checkFormValidation() {
    if(this.itemForm.value.ItemCode == null || this.itemForm.value.ItemCode == '') {
      this.toaster.warning('Please fill up item code field!');
      return false;
    }
    if(this.itemForm.value.ItemName == null || this.itemForm.value.ItemName == '') {
      this.toaster.warning('Please fill up item name field!');
      return false;
    }
    if(this.itemForm.value.Description == null || this.itemForm.value.Description == '') {
      this.toaster.warning('Please fill up description field!');
      return false;
    }
    if((this.itemForm.value.ItemTypeId == 1 || this.isBuyChecked) && (this.itemForm.value.PurchasePrice == null || this.itemForm.value.PurchasePrice == '')) {
      this.toaster.warning('Please fill up purchase price per unit field!');
      return false;
    }
    if((this.itemForm.value.ItemTypeId == 1 || this.itemForm.value.ItemTypeId == 5 || this.isSaleChecked) && (this.itemForm.value.SaleUnitPrice == null || this.itemForm.value.SaleUnitPrice == '')) {
      this.toaster.warning('Please fill up sale price per unit field!');
      return;
    }
    if((this.itemForm.value.ItemTypeId == 1 || this.isBuyChecked) && (this.itemForm.value.SupplierId == null || this.itemForm.value.SupplierId == '')) {
      this.toaster.warning('Please select a supplier!');
      return false;
    }
    if(this.itemForm.value.ItemTypeId != 1 && this.itemForm.value.ItemTypeId != 5 && !this.isSaleChecked && !this.isBuyChecked && !this.isManufactureChecked) {
      this.toaster.warning('Please select buy or sale or manufacture!');
      return false;
    }
    if (this.itemForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      return false;
    }
    return true;
  }

  formatData(): ItemCreateViewModel {
    let isPurchaseItem = (this.isBuyChecked || this.itemForm.value.ItemTypeId == 1) ? true : false;
    let isSaleItem = (this.isSaleChecked || this.itemForm.value.ItemTypeId == 1 || this.itemForm.value.ItemTypeId == 5) ? true : false;
    let item: ItemCreateViewModel = {
      Id: this.itemForm.value.Id,
      ItemCode: this.itemForm.value.ItemCode,
      ItemName: this.itemForm.value.ItemName,
      Description: this.itemForm.value.Description,
      ItemTypeId: this.itemForm.value.ItemTypeId,
      IsSaleItem: isSaleItem,
      SaleUnitPrice: isSaleItem == true ? this.itemForm.value.SaleUnitPrice : null,
      IsPurchaseItem: isPurchaseItem,
      PurchasePrice: isPurchaseItem == true ? this.itemForm.value.PurchasePrice : null,
      SupplierId: isPurchaseItem == true ? this.itemForm.value.SupplierId : null,
      CompanyId: 0,
      ActionById: null,
      IsManufactureItem: this.isManufactureChecked == null ? false : true
    };
    return item;
  }

  copyItemCode = (e) => {
   this.itemForm.patchValue({ItemName: (this.itemForm.value.ItemName == null  || this.itemForm.value.ItemName === "")
   ? e.target.value : this.itemForm.value.ItemName  });
   this.itemForm.patchValue({Description: this.itemForm.value.Description == null || this.itemForm.value.Description === "" ?
    e.target.value : this.itemForm.value.Description });
  }
}
