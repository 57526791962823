import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WareHouseOperationType } from 'src/app/enum/wareHouseOperationType';
import { WareHouseStatusType } from 'src/app/enum/wareHouseStatusType';
import { WareHouse } from 'src/app/models/inventoryOverview.model';
import { WareHouseSaveModel } from 'src/app/models/wareHouseSave.model';
import { WareHouseItems } from 'src/app/models/warehouseitems.model';
import { BillsServiceService } from 'src/app/service/Bill/bills-service.service';
import { IncomeServiceService } from 'src/app/service/Income/income-service.service';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';
import { PurchaseOrderService } from 'src/app/service/PurchaseOrder/purchase-order.service';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';

@Component({
  selector: 'app-return-modal',
  templateUrl: './return-modal.component.html',
  styleUrls: ['./return-modal.component.css']
})
export class ReturnModalComponent implements OnInit {

  ReturnForm: FormGroup;
  ReturnItems: any;
  wareHouseId: number;
  WareHouseOutData: any;
  removedItems: any[] = [];
  ReferenceData: any;
  ReferenceShippedItemsData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private deliveryOrderService:InventoryOverviewService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private salesService: SalesOrderService,
    private IncomeService: IncomeServiceService,
    private billService: BillsServiceService,
    private purchaseService: PurchaseOrderService
  ) { }

  ngOnInit(): void {
    this.getReferenceData();
    this.initializeForm()
  }

  getReferenceData() {
    switch(this.WareHouseOutData.allData.WareHouseData.OperationType) {
      case WareHouseOperationType.Invoice:
        this.IncomeService.GetInvoicePermissionByInvoiceNoForInvoiceOverview(this.WareHouseOutData.allData.InvoiceData.InvoicceNo).subscribe((x) => {
          this.ReferenceData = x.data;
          this.ReferenceShippedItemsData = x.data.ShippedItems;
          this.setWareHouseData();
          });
        break;
      case WareHouseOperationType.SalesOrder:
        this.salesService.GetOrderDetails(this.WareHouseOutData.allData.SalesOrderData.Id).subscribe((x) => {
          this.ReferenceData = x.data;
          this.ReferenceShippedItemsData = x.data.ShippedItems;
          this.setWareHouseData();
        });
        break;
      case WareHouseOperationType.PurchaseOrder:
        this.purchaseService.GetOrderDetails(this.WareHouseOutData.allData.PurchaseOrderData.Id).subscribe((x) => {
          this.ReferenceData = x.Details;
          this.ReferenceShippedItemsData = x.Details.ReceivedItems;
          this.setWareHouseData();
          });
        break;
      case WareHouseOperationType.Bill:
        this.billService.getBillsPermissionByBillNo(this.WareHouseOutData.allData.BillData.BillNo).subscribe((x) => {
          this.ReferenceData = x.data;
          this.ReferenceShippedItemsData = x.data.ReceivedItems;
          this.setWareHouseData();
        });
        break;
    }
  }

  initializeForm() {
    this.ReturnForm = this.fb.group({
      ReturnItems: this.fb.array([])
    });
  }

  setWareHouseData(){
    this.WareHouseOutData.allData.WareHouseItems.forEach((item, i) => {
      if(this.ReferenceShippedItemsData[i] > 0)
        this.addItem(item, i);
    });
  }

  public addItem(item: any, i: number): void {
    this.ReturnItems = this.ReturnForm.get('ReturnItems') as FormArray;
    this.ReturnItems.push(this.createItem(item, i));
  }

  public createItem(item: any, i: number): FormGroup {
    return this.fb.group({
      ProductName: [item.ItemDetails.ItemName],
      ProductId: [item.ItemDetails.Id],
      ReturnedQuantity:[this.ReferenceShippedItemsData[i]]
    });
  }

  updateReturnedQuantity() {
    this.ReturnItems = this.ReturnForm.get('ReturnItems') as FormArray;
    this.ReturnItems.value.map((item, i) => {
      this.ReturnItems.controls[i].get('ReturnedQuantity').patchValue(item.ReturnedQuantity);
    });
  }

  removeItems(i: number) {
    this.ReturnItems = this.ReturnForm.get('ReturnItems') as FormArray;
    this.ReturnItems.value.map((x, index) => {
      if(index === i){
        this.ReturnItems.removeAt(index);
      }
    });
  }

  addRemovedItem() {
    const allWareHouseItems = this.WareHouseOutData.allData.WareHouseItems;
    const itemsInModal = this.ReturnItems.value;
    this.removedItems = this.getRemoveItems(allWareHouseItems, itemsInModal);
    let indexOfItem = this.getIndexOfItem();
    this.addItem(this.removedItems[0], indexOfItem);
  }

  getRemoveItems(allWareHouseItems: any[], itemsInModal: any[]) {
    const removedItems = [];
    for (const wareHouseItem of allWareHouseItems) {
      if(itemsInModal.filter(x => x.ProductId == wareHouseItem.ProductId).length == 0)
        removedItems.push(wareHouseItem);
    }
    return removedItems;
  }

  getIndexOfItem() {
    let indexOfItem = 0;
    switch(this.WareHouseOutData.allData.WareHouseData.OperationType) {
      case WareHouseOperationType.Invoice:
        indexOfItem = this.ReferenceData.InvoiceItems.findIndex(x => x.ItemId == this.removedItems[0].ProductId);
        break;
      case WareHouseOperationType.SalesOrder:
        indexOfItem = this.ReferenceData.SalesOrderItem.findIndex(x => x.ItemId == this.removedItems[0].ProductId);
        break;
      case WareHouseOperationType.PurchaseOrder:
        indexOfItem = this.ReferenceData.PurchaseOrderItems.findIndex(x => x.ItemId == this.removedItems[0].ProductId);
        break;
      case WareHouseOperationType.Bill:
        indexOfItem = this.ReferenceData.BillItems.findIndex(x => x.ItemId == this.removedItems[0].ProductId);
        break;
    }
    return indexOfItem;
  }
  

  return() {
    const data = this.formatData();
    if(this.checkFormValidation(data)) {
      this.spinner.show();
      this.deliveryOrderService.SoldItemReturned(data).subscribe((x) => {
        if (x.Success) {
          this.toaster.success('Returned Successfully');
          this.spinner.hide();
          this.activeModal.close();
        } else {
          this.toaster.warning(x.Message);
          this.spinner.hide();
          this.activeModal.close();
        }
      });
    }
  }

  formatData(): WareHouseSaveModel {
    let wareHouseItems: WareHouseItems[] = [];
    this.ReturnForm.value.ReturnItems.forEach(element => {
      let wareHouseItem = new WareHouseItems();
      wareHouseItem.Id = 0;
      wareHouseItem.WareHouseId = 0;
      wareHouseItem.ProductId = Number(element.ProductId);
      wareHouseItem.Demand = Number(element.ReturnedQuantity);
      wareHouseItem.ProductInOut = 0;
      wareHouseItems.push(wareHouseItem);
    });

    let wareHouse: WareHouse = {
      Id: 0,
      ReferenceNo: this.WareHouseOutData.allData.WareHouseData.ReferenceNo,
      StatusId: WareHouseStatusType.Ready,
      OperationType: this.setOperationType(),
      ParentId: this.WareHouseOutData.allData.WareHouseData.WareHouseProductNo,
      ContactId: this.WareHouseOutData.allData.WareHouseData.ContactId,
      OperationDate: new Date(),
      ModifiedDate: new Date(),
      ModifiedBy: '',
      WareHouseProductNo: 0,
      CompanyId: 0
    };

    let wareHouseSaveModel: WareHouseSaveModel = {
      WareHouseData: wareHouse,
      WareHouseItemsData: wareHouseItems,
    }
    return wareHouseSaveModel;
  }

  setOperationType() {
    let operationType = 0;
    switch(this.WareHouseOutData.allData.WareHouseData.OperationType) {
      case WareHouseOperationType.Invoice:
        operationType = WareHouseOperationType.SoldItemReturnedForInvoice;
        break;
      case WareHouseOperationType.SalesOrder:
        operationType = WareHouseOperationType.SoldItemReturnedForSalesOrder;
        break;
      case WareHouseOperationType.PurchaseOrder:
        operationType = WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder;
        break;
      case WareHouseOperationType.Bill:
        operationType = WareHouseOperationType.PurchaseItemReturnedForBill;
        break;
    }
    return operationType;
  }

  checkFormValidation(data: WareHouseSaveModel) {
    if(data.WareHouseItemsData.some(item => item.Demand == 0)) {
      this.toaster.warning('You can not return zero quantity!');
      return false;
    }

    if(data.WareHouseItemsData.some(item => item.Demand > this.getPreviousShippedQuantity(item.ProductId))) {
      this.toaster.warning('You can not return excess quantity!');
      return false;
    }
    return true;
  }

  getPreviousShippedQuantity(productId: number) {
    let indexOfItem = 0;
    switch(this.WareHouseOutData.allData.WareHouseData.OperationType) {
      case WareHouseOperationType.Invoice:
        indexOfItem = this.ReferenceData.InvoiceItems.findIndex(x => x.ItemId == productId);
        break;
      case WareHouseOperationType.SalesOrder:
        indexOfItem = this.ReferenceData.SalesOrderItem.findIndex(x => x.ItemId == productId);
        break;
      case WareHouseOperationType.Bill:
        indexOfItem = this.ReferenceData.BillItems.findIndex(x => x.ItemId == productId);
        break;
      case WareHouseOperationType.PurchaseOrder:
        indexOfItem = this.ReferenceData.PurchaseOrderItems.findIndex(x => x.ItemId == productId);
        break;
    }
    return this.ReferenceShippedItemsData[indexOfItem];
  }

}
