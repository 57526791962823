
    <div class="modal-header">
        <h4 class="modal-title f-ss-14px" >Edit Chart Of Account</h4>
        
        <button type="button" class="close" aria-label="Close"
            (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
    </div>
<hr>
    <div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
        <div class="pb-4">
            <div class="row">
                <!-- <div class="col-md-12 ">
                    <button _ngcontent-hci-c162="" (click)="submitAddAccountForm()" type="button" class="btn eCount-button ml-4" style="float: right; margin-right: 14px;">Save</button>
                </div> -->
                <div class="col-md-6 f-ss-14px">
                    <form [formGroup]="addAccountForm" (ngSubmit)="submitAddAccountForm()">
                        <div class="form-group">
                            <span class="formTitle">Account Type:</span>
                            <ng-select [items]="accountTypes" formControlName="AccountTypeId" class="custom"   [clearable]="false"  bindLabel="Name" bindValue="Id"  groupBy="GroupName" (change)="changeAccountType($event)" ></ng-select>
                        </div>
                        <div class="form-group">
                            <span class="formTitle">Code:</span>
                            <span class="formSubTitle">A unique code/number for this account ( Limited to 10 character)</span>
                            <input type="text" formControlName="AccountCode"  class="form-control mt-1" placeholder="Code">
                        </div>
                        <div class="form-group">
                            <span class="formTitle">Name:</span>
                            <span class="formSubTitle"> A short title for this account ( Limited to 50 character)</span>
                            <input type="text" formControlName="AccountName" class="form-control mt-1"  placeholder="Name">
                        </div>
                        <div class="form-group">
                            <span class="formTitle">Description (Optional):</span>
                            <span class="formSubTitle">A description of how this account should be used</span>
                            <textarea formControlName="Description" class="form-control" rows="3"></textarea>
                        </div>
                        <!-- <div class="form-group">
                            <input type="checkbox" id="ds" name="ShowInExpenseClaim" formControlName="ShowInExpenseClaim" value="Bike">
                            <label for="ShowInExpenseClaim" class="ml-2"> Show In Expense Claim</label><br>
                        </div> -->

                    </form>
                </div>
                <div class="col-md-6 f-ss-14px">
                    <p style="margin-left: 5px;">How account types affects your reports</p>
                    <img src="../../../../assets/image/page_content/accounting_create_account.PNG" />
                    
                </div>
                <div class="col-md-12">
                    <div class="col-md-6"></div>
                    <div class="col-md-6" style="float: right;">
                        <div style="float: right;">
                            <div class="btn-group mr-4">
                                <button type="submit" (click)="submitAddAccountForm()" class="eCount-def-btn">Save</button>
                              </div>
                            <!-- <button type="button" (click)="cancel()"  class="btn eCount-button3 eCount-button-cancel">Cancel</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>