import { Component, OnInit ,NgZone,Input} from '@angular/core';
import { ProductServiceService } from '../../../../service/Products/product-service.service';
import { DataService } from '../../../../service/EventEmitter/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { IncomeServiceService } from '../../../../service/Income/income-service.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { AcountingServiceService } from '../../../../service/Accounting/acounting-service.service';
import { SpendmoneyServiceService } from '../../../../service/SpendMoney/spendmoney-service.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-spend-money',
  templateUrl: './view-spend-money.component.html',
  styleUrls: ['./view-spend-money.component.css']
})
export class ViewSpendMoneyComponent implements OnInit {

  total: any;

  constructor(private route: ActivatedRoute, private IncomeService: IncomeServiceService,private accountingService: AcountingServiceService,private spendmoneyService:SpendmoneyServiceService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.getAllSaleableItem();
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.getDetails();
   });
  }




 
   private sub:any;
   id:any;
   chartOfAccount: any = [];
   Found: boolean=false;
   SpendMoney:any;
   allSaleableItem: any = [];
  
  formatItem(id) {
    let value = this.allSaleableItem.find(e => e.Id == id);
    if (!value) {
    } else {
      //return value.ItemCode + "-" + value.ItemName;
      return value.ItemName;
    }
  }
  getAllSaleableItem = () => {
    this.IncomeService.GetAllSaleableItem().subscribe((x) => {
      this.allSaleableItem = x;
    })
  }
  getAccountName=(item)=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

        item.SpendMoneyItems.forEach(element => 
        {
          console.log(element);
           var x=this.chartOfAccount.find(p=> p.Id===element.AccountId);
           element.Item=x.AccountName;
           
        });
        
        
      })
      return item;
  }
  getDetails()
  {
    this.spendmoneyService.GetSpendMoneyDetails(this.id).subscribe((x)=>{
          if(x){
               this.Found=true;
               this.SpendMoney=x;
               console.log("sadi molla" +x);
               this.getAccountName(this.SpendMoney);
               this.total = this.SpendMoney.SubTotalAmount.toLocaleString();
          }
    });
  }

  convertToLocaleString(num: number) {
    return num.toLocaleString();
  }

  twoDecimalPlace = (num: number) => {
    const total = num.toFixed(2);
    return parseFloat(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  convertDateIntoDisplay = (date: Date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  submitSpendMoneyData(){
    this.spinner.show();
    this.spendmoneyService.submitSpendMoney(this.id).subscribe((x)=>{
      this.spinner.hide();
      if(x.Success){
        this.toaster.success('Spend money successfully submitted.')
        this.router.navigate(['spendmoney/spend-money-list/all']);
      }
      else{
        this.toaster.error('Something went wrong!', 'Error!');
      }
    });
  }

  confirmSpendMoneyRejection(){
    this.spinner.show();
    this.spendmoneyService.deleteSpendMoney(this.id).subscribe((x)=>{
      this.spinner.hide();
      if(x.Success){
        this.toaster.success('Spend money successfully deleted.')
        this.router.navigate(['spendmoney/spend-money-list/all']);
      }
      else{
        this.toaster.error('Something went wrong!', 'Error!');
      }
    });
  }
}
