<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">
       <span>Return Raw Material</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
  <label style="margin-left: 55%;">Return Note<span class="required-sign">*</span></label>
      <input required
        class="form-controlinputBox"
        style="margin-left: 1%;width:30%;display: inline-block;"
        type="text"
        [(ngModel)]="ReturnNote"
      />
<form [formGroup]="factoryReturnForm" class="f-ss-14px">
    <div class="row d-block topSection f-ss-14px">
      <div class="d-flex">
        <div class="col-md-3">Item Code</div>
        <div class="col-md-3">Item Name</div>
        <div class="col-md-3">Factory Quantity</div>
        <div class="col-md-3">Return Quantity</div>
      </div>
      <hr class="pb-0 mb-0" />
        <hr class="pb-0 mb-0" />
      <div formArrayName="RawMaterialItem" *ngFor="let item of factoryReturnForm.get('RawMaterialItem')['controls']; let i = index;">
         
         <div class="invoiceGridItemRow d-block">
          <div class="row" [formGroupName]="i">

              <div class="col-md-3 margin-top-5">
                <input  class="form-control" readonly formControlName="ItemCode">
              </div>
              <div class="col-md-3 margin-top-5">
                <input  class="form-control" readonly formControlName="ItemName">
              </div>
              <div class="col-md-3 margin-top-5">
                  <input  class="form-control" readonly formControlName="FactoryQuantity">
              </div>
              <div class="col-md-3 margin-top-5">
                  <input  class="form-control" type="number" (change)="checkQuantity(item)" formControlName="ReturnQty">
              </div>
          </div>
            </div>
      </div>
    </div>
  </form>
  <div class="col-md-12">
        <button type="button" style="float: right;margin-top: 20px;margin-bottom: 20px;" (click)="saveReturnMaterial()" class="eCount-def-btn  ml-4 p-save-button">Save</button>
</div>
</div>