import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgItemLabelDirective } from '@ng-select/ng-select/lib/ng-templates.directive';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ItemType } from 'src/app/enum/item-type';
import { Item } from 'src/app/models/inventoryOverview.model';
import { CreateItem } from 'src/app/models/product.model';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';
import { ProductServiceService } from 'src/app/service/Products/product-service.service';
import { ModalForCreateBillComponent } from '../modal-for-create-bill/modal-for-create-bill.component';
import { ModalForReceiveProductComponent } from '../modal-for-receive-product/modal-for-receive-product.component';
import { ModalShippedProductComponent } from '../modal-shipped-product/modal-shipped-product.component';
import { ReturnModalComponent } from '../return-modal/return-modal.component';
import { WareHouseOperationType } from 'src/app/enum/wareHouseOperationType';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';
import { IncomeServiceService } from 'src/app/service/Income/income-service.service';
import { BillsServiceService } from 'src/app/service/Bill/bills-service.service';
import { PurchaseOrderService } from 'src/app/service/PurchaseOrder/purchase-order.service';


@Component({
  selector: 'app-delivery-order-waiting',
  templateUrl: './delivery-order-waiting.component.html',
  styleUrls: ['./delivery-order-waiting.component.css']
})
export class DeliveryOrderWaitingComponent implements OnInit {
  itemListTitle:string="WH-Out-2022100001/waiting";
  public aModel : CreateItem= new CreateItem();
  itemTypeId:number=1;
  totalFile: number=0;
  isEditable: boolean = false;
  canCreateBill: boolean=true;
  isBillCreated:boolean=false;
  isavailable:boolean=true;
  wareHouseProductNo:any;
  shipped:any;
  public deliveryOrderWaitingForm: FormGroup;

  ReturnOperationTypes = [
    WareHouseOperationType.SoldItemReturnedForSalesOrder, 
    WareHouseOperationType.SoldItemReturnedForInvoice, 
    WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder, 
    WareHouseOperationType.PurchaseItemReturnedForBill
  ];

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private modalService:NgbModal,
    private route: ActivatedRoute,
    private deliveryOrderService:InventoryOverviewService,
    public fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private productServiceService: ProductServiceService,
    private router: Router,
    private salesService: SalesOrderService,
    private IncomeService: IncomeServiceService,
    private billService: BillsServiceService,
    private purchaseService: PurchaseOrderService
  ) { }
  planId = 0;
  OrderList: any = [];
  grilcol;
  private gridApi;
  domLayout='autoHeight';
  isItemListUpdate = false;
   searchText:string;
   pageValue:number;
   allData:any=[];
   WareHouseItems:any=[];
   ShippedFormArray:FormArray
   billForm: FormGroup;
   ReceiveBtnFlag:boolean=false;
   ProductList: any = [];
   isProductAvailable: boolean = true;
   statusId: any;
   reservedItem: any;
   redirectTo: string;
   ReferenceShippedItemsData: any[];

  ngOnInit(): void {
    //this.pageValue=Number(this.route.snapshot.paramMap.get("id"));
    this.route.paramMap.subscribe( x => {
      this.pageValue = +x.get("id"); //here + sign means converting string to number
      this.initializeForm();
      this.pageLoad();
    });
  }
  private initializeForm() {
    this.billForm =  this.fb.group({
      Product: new FormControl(''),
      Demand: new FormControl(''),
      Reserved: new FormControl(''),
      Shipped: new FormControl(['', Validators.required]),
      ShippedFormArray:this.fb.array([])

    });

  }

  public getWareHouseStatus(status){
    switch(status){
      case 1:
        return "Waiting";
      case 2:
        return "Shipped";
      case 3:
        return "Ready";
    }
  }

  isNotShipped(){
    return this.statusId != 2 ? true : false;
  }

  // getAllProduct = () => {

  //   this.spinner.show();
  //   this.ProductList = [];
  //   let itemTypeId=1;
  //   console.log("ItemTypeId==>",itemTypeId)
  //   this.productServiceService.GetItemList(this.itemTypeId).subscribe((x) => {
  //     this.spinner.hide();
  //     console.log("GetAllProduct==>",x)
  //     this.ProductList = [...x, ...this.ProductList];
  //     console.log(this.planId);
  //     console.log(this.itemTypeId);

  //   });
  // }


  public addItem(x: any): void {
    this.ShippedFormArray = this.billForm.get('ShippedFormArray') as FormArray;
    console.log("coming")
    let data = this.createItem(x);
    console.log("this is my data => ", data);
    this.ShippedFormArray.push(data);
  }

  public createItem(x: any): FormGroup {
    console.log("this is my set data => ", x)
    return this.fb.group({
      Product: [x.ItemDetails.ItemName],
      Demand: [x.Demand],
      Reserved: [x.StockQuantity < 0 ? 0 : x.StockQuantity],
      Shipped:[x.ProductInOut]

    });
  }


  public pageLoad()
  {
      this.deliveryOrderService.GetWareHouseData(this.pageValue).subscribe((x)=>
      {
        this.allData = x;
        this.getReferenceData();
        this.statusId = this.allData.WareHouseData.StatusId;
        this.wareHouseProductNo=this.allData.WareHouseData.WareHouseProductNo;
        this.itemListTitle = this.setMainTitle();
        this.redirectTo = this.generateBackUrl();
        this.WareHouseItems = x.WareHouseItems
        this.WareHouseItems.forEach(item => {
          this.GetProductList(item);
        });
        this.deliveryOrderService.setWareHouseData(this.allData);
      });
  }

  getReferenceData() {
    switch(this.allData.WareHouseData.OperationType) {
      case WareHouseOperationType.Invoice:
        this.IncomeService.GetInvoicePermissionByInvoiceNoForInvoiceOverview(this.allData.InvoiceData.InvoicceNo).subscribe((x) => {
          this.ReferenceShippedItemsData = x.data.ShippedItems;
          });
        break;
      case WareHouseOperationType.SalesOrder:
        this.salesService.GetOrderDetails(this.allData.SalesOrderData.Id).subscribe((x) => {
          this.ReferenceShippedItemsData = x.data.ShippedItems;
        });
        break;
      case WareHouseOperationType.PurchaseOrder:
        this.purchaseService.GetOrderDetails(this.allData.PurchaseOrderData.Id).subscribe((x) => {
          this.ReferenceShippedItemsData = x.Details.ReceivedItems;
          });
        break;
      case WareHouseOperationType.Bill:
        this.billService.getBillsPermissionByBillNo(this.allData.BillData.BillNo).subscribe((x) => {
          this.ReferenceShippedItemsData = x.data.ReceivedItems;
        });
        break;
    }
  }

  setMainTitle() {
    let title = '';
    switch(this.allData?.WareHouseData?.OperationType) {
      case WareHouseOperationType.SalesOrder:
        title = "WH-Out-"+(this.allData.WareHouseData.WareHouseProductNo)+"/"+this.getWareHouseStatus(this.statusId);
        break;
      case WareHouseOperationType.Invoice:
        title = "WH-Out-"+(this.allData.WareHouseData.WareHouseProductNo)+"/"+this.getWareHouseStatus(this.statusId);
        break;
      case WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder:
        title = "WH-RET-"+(this.allData.WareHouseData.WareHouseProductNo)+"/"+this.getWareHouseStatus(this.statusId);
        break;
      case WareHouseOperationType.PurchaseItemReturnedForBill:
        title = "WH-RET-"+(this.allData.WareHouseData.WareHouseProductNo)+"/"+this.getWareHouseStatus(this.statusId);
        break;
    }
    return title;
  }

  GetProductList(item) {
    switch(item.ItemDetails.ItemTypeId) {
      case ItemType.TradingItem:
        this.GetItemList(item, ItemType.TradingItem);
        break;
      case ItemType.FinishProduct:
        this.GetItemList(item, ItemType.FinishProduct);
        break;
      case ItemType.Service:
        this.GetItemList(item, ItemType.Service);
        break;
      case ItemType.RawMaterial:
        this.GetItemList(item, ItemType.RawMaterial);
        break;
      case ItemType.StorageItem:
        this.GetItemList(item, ItemType.StorageItem);
        break;
    }
  }

  GetItemList(item, itemType) {
    this.productServiceService.GetItemList(itemType).subscribe((x) => {
      this.ProductList = x;
      this.CheckStock(item);
    });
  }

  CheckStock(item) {
      this.reservedItem = this.ProductList.filter(x => x.Id == item.ItemDetails.Id)[0];
      item.StockQuantity = this.reservedItem.StockQuantity;

      if( item.Demand > item.StockQuantity || item.StockQuantity <= 0 )
      {
        this.isProductAvailable = false; 
      }else{
        item.StockQuantity = item.Demand;
      }
      this.addItem(item)
  }

  search = ()=>{
    this.gridApi.setQuickFilter(this.searchText);
  }
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }
  getParams() {
    return {
    suppressQuotes: '',
    columnSeparator: '',
    customHeader: '',
    customFooter: '',};
  }
  public setTotalFile(tf:number) {

    this.totalFile=tf;

  }

  public openCreateBasicBill() {
    const modalRef = this.modalService.open(ModalForCreateBillComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered: true});

    let data={
      wareHouseNo:'WH No. '+this.wareHouseProductNo+' as billed',
      wareHouseRef:this.wareHouseProductNo,
      allData:this.allData
    }
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
          console.log("Modal Result",result);
        }, (reason) => {
        });
  }




  // public ReceiveProduct()
  // {
  //   console.log("Hello")
  //   this.isavailable=true;
  //   this.itemListTitle="WH-Out-"+(this.allData.WareHouseData.WareHouseProductNo)+"/Received";

  // }

  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }


  // public onFousOutShippemnt(e:any)
  // {
  //   console.log("Event",e.target.value)
  //   this.shipped=e.target.value;
  //   const modalRef = this.modalService.open(ModalShippedProductComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered: true});

  //   let data=
  //   {
  //     product: this.WareHouseItems,
  //     shipped:this.shipped,
  //     allData:this.allData

  //   }
  //   modalRef.componentInstance.fromParent = data;
  //   modalRef.result.then((result) => {
  //     console.log("Modal Result",result);
  //   }, (reason) => {
  //   });

  // }

  public getReferenceNo()
  {
    switch(this.allData?.WareHouseData?.OperationType)
    {
      case WareHouseOperationType.PurchaseOrder:
        return "PO-"+this.allData?.WareHouseData?.ReferenceNo;

      case WareHouseOperationType.Bill:
        return "Bill-"+this.allData?.WareHouseData?.ReferenceNo;

      case WareHouseOperationType.SalesOrder:
        return "SO-"+this.allData?.WareHouseData?.ReferenceNo;

      case WareHouseOperationType.Invoice:
        return "INV-"+this.allData?.WareHouseData?.ReferenceNo;

      case WareHouseOperationType.SoldItemReturnedForSalesOrder:
        if(this.allData?.WareHouseParentData.OperationType != WareHouseOperationType.SoldItemReturnedForSalesOrder)
          return "WH-Out-"+this.allData?.WareHouseData?.ParentId;
        else
          return "WH-RET-"+this.allData?.WareHouseData?.ParentId;

      case WareHouseOperationType.SoldItemReturnedForInvoice:
        if(this.allData?.WareHouseParentData.OperationType != WareHouseOperationType.SoldItemReturnedForInvoice)
          return "WH-Out-"+this.allData?.WareHouseData?.ParentId;
        else
          return "WH-RET-"+this.allData?.WareHouseData?.ParentId;

      case WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder:
        if(this.allData?.WareHouseParentData.OperationType == WareHouseOperationType.PurchaseOrder)
          return "WH-In-"+this.allData?.WareHouseData?.ParentId;
        else
          return "WH-RET-"+this.allData?.WareHouseData?.ParentId;

      case WareHouseOperationType.PurchaseItemReturnedForBill:
        if(this.allData?.WareHouseParentData.OperationType == WareHouseOperationType.Bill)
          return "WH-In-"+this.allData?.WareHouseData?.ParentId;
        else
          return "WH-RET-"+this.allData?.WareHouseData?.ParentId;
    }
  }

  public getReferenceNoLink()
  {
    switch(this.allData?.WareHouseData?.OperationType)
    {
      case WareHouseOperationType.PurchaseOrder:
        return "#/procurement/purchase-order-details/"+this.allData?.PurchaseOrderData?.Id;

      case WareHouseOperationType.Bill:
        return "#/expense/bill/Bill-"+this.allData?.WareHouseData?.ReferenceNo;

      case WareHouseOperationType.SalesOrder:
        return "#/sales/sales-order-details/"+this.allData?.SalesOrderData?.Id;

      case WareHouseOperationType.Invoice:
        return "#/income/invoice/INV-"+this.allData?.WareHouseData?.ReferenceNo;

      case WareHouseOperationType.SoldItemReturnedForSalesOrder:
        if(this.allData?.WareHouseParentData.OperationType != WareHouseOperationType.SoldItemReturnedForSalesOrder)
          return "#/inventory/delivery-order-waiting/"+this.allData?.WareHouseParentData?.Id;
        else
          return "#/inventory/receipt-waiting/"+this.allData?.WareHouseParentData?.Id;

      case WareHouseOperationType.SoldItemReturnedForInvoice:
        if(this.allData?.WareHouseParentData.OperationType != WareHouseOperationType.SoldItemReturnedForInvoice)
          return "#/inventory/delivery-order-waiting/"+this.allData?.WareHouseParentData?.Id;
        else
          return "#/inventory/receipt-waiting/"+this.allData?.WareHouseParentData?.Id;

      case WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder:
        if(this.allData?.WareHouseParentData.OperationType == WareHouseOperationType.PurchaseOrder)
          return "#/inventory/receipt-waiting/"+this.allData?.WareHouseParentData?.Id;
        else
          return "#/inventory/delivery-order-waiting/"+this.allData?.WareHouseParentData?.Id;

      case WareHouseOperationType.PurchaseItemReturnedForBill:
        if(this.allData?.WareHouseParentData.OperationType == WareHouseOperationType.Bill)
          return "#/inventory/receipt-waiting/"+this.allData?.WareHouseParentData?.Id;
        else
          return "#/inventory/delivery-order-waiting/"+this.allData?.WareHouseParentData?.Id;
    }
  }

  public getWareHouseBackOrderReferenceNo()
  {
    switch(this.allData.WareHouseData.OperationType)
    {
      case 1:
        return "WH-In-"+this.allData.WareHouseData.ParentId;
      case 2:
        return "WH-In-"+this.allData.WareHouseData.ParentId;
      case 3:
        return "WH-Out-"+this.allData.WareHouseData.ParentId;
      case 4:
        return "WH-Out-"+this.allData.WareHouseData.ParentId;
    }
  }

  public getWareHouseBackOrderReferenceNoLink()
  {
    switch(this.allData.WareHouseParentData.OperationType)
    {
      case 1:
        return "#/inventory/receipt-waiting/"+this.allData.WareHouseParentData.Id;
      case 2:
        return "#/inventory/receipt-waiting/"+this.allData.WareHouseParentData.Id;
      case 3:
        return "#/inventory/delivery-order-waiting/"+this.allData.WareHouseParentData.Id;
      case 4:
        return "#/inventory/delivery-order-waiting/"+this.allData.WareHouseParentData.Id;
    }
  }

  public isAllProductsAvailableInReserved(){
    let modalFlagArray: any[] = [];
    //modalFlagArray.length = this.ShippedFormArray.length;
    modalFlagArray.length = (this.ShippedFormArray?.length) ?? 0;
    modalFlagArray.fill(false);
    (this.ShippedFormArray?.controls)?.forEach( (item, index) =>{
      if( item.value.Reserved >= item.value.Demand )
        modalFlagArray[index] = true;
    });
    
    return modalFlagArray.every( x => x == true );
  }

  public isAllInputZeroOrLessThanZero(){
    let modalFlagArray: any[] = [];
    modalFlagArray.length = this.ShippedFormArray.length;
    modalFlagArray.fill(false);
    this.ShippedFormArray.controls.forEach( (item, index) =>{
      if( item.value.Shipped <= 0 )
        modalFlagArray[index] = true;
    });
    return modalFlagArray.every( x => x == true );
  }

  public canShowModalForSingleItem(){
    let canShowModal = false;
    //The ShippedFormArray has only one item.
    this.ShippedFormArray.controls.forEach((item, i)=>
    {
      if( item.value.Shipped < item.value.Reserved && item.value.Reserved > 0 ){
        canShowModal = true;
      } else if(item.value.Shipped == item.value.Reserved && item.value.Reserved < item.value.Demand && item.value.Reserved > 0) {
        canShowModal = true;
      } else if( item.value.Shipped == 0 || item.value.Shipped > item.value.Reserved ){
        canShowModal = false;
      }else{
        canShowModal = false;
      }
      this.allData.WareHouseItems[i].ProductInOut=Number(item.value.Shipped);
    })
    return canShowModal;
  }

  public canShowModalForMultipleItems(){
    let takingSomeItems = false;
    let notTakingSomeItems = false;
    let takingAnyPartialItems = false;
    this.ShippedFormArray.controls.forEach((item, i)=>
    {
      if( item.value.Shipped > 0 ){
        takingSomeItems = true;
      }else if( item.value.Shipped == 0 ){
        notTakingSomeItems = true;
      }
      if( item.value.Shipped < item.value.Reserved && item.value.Reserved > 0 )
      {
        takingAnyPartialItems = true;
      } else if( item.value.Shipped == item.value.Reserved && item.value.Reserved < item.value.Demand && item.value.Reserved > 0 ){
        takingAnyPartialItems = true;
      }
      this.allData.WareHouseItems[i].ProductInOut=Number(item.value.Shipped);
    })

    if( takingAnyPartialItems )
      return true;
    else if( takingSomeItems && notTakingSomeItems )
      return true;
    else
      return false;
  }

  public canShowNotInStockError(){
    let canShowError = false;
    this.ShippedFormArray.controls.forEach((item, i)=>
    {
      if( 
        (item.value.Shipped > 0 && item.value.Reserved == 0) 
         || 
        ( item.value.Reserved > 0 && (item.value.Shipped > item.value.Reserved) && (item.value.Reserved != item.value.Demand) ) 
      )
      {
        canShowError = true;
      }
    })
    return canShowError;
  }

  public canShowNoInputGivenError(){
    let canShowError = true;
    this.ShippedFormArray.controls.forEach((item, i)=>
    {
      if( !canShowError )
        return;

      if( item.value.Shipped != 0 )
      {
        canShowError = false;
      }
    })
    return canShowError;
  }

  public shippedProduct()
  {
    if( this.canShowNoInputGivenError() )
    {
      this.toaster.error("Please enter some value.");
      return;
    }

    if( this.canShowNotInStockError() ){
      this.toaster.error("Oops! some items are not in stock!");
      return;
    }
    this.isavailable=true;
    this.itemListTitle="WH-In-"+(this.allData.WareHouseData.WareHouseProductNo)+"/Received";
    this.ShippedFormArray = this.billForm.get('ShippedFormArray') as FormArray;

    if(this.allData.WareHouseData.OperationType == WareHouseOperationType.PurchaseItemReturnedForBill || this.allData.WareHouseData.OperationType == WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder) {
      let isExcessReturn = this.ShippedFormArray.value.some(x => x.Demand < Number(x.Shipped))
      if(isExcessReturn) {
        this.toaster.warning("You can not shipped more.");
        return;
      }
    }

    let i=0,modalFlag=false;
    
    if( this.ShippedFormArray.controls.length == 1 ){
      modalFlag = this.canShowModalForSingleItem();
    }else{
      modalFlag = this.canShowModalForMultipleItems();
    }
    
    if(modalFlag){
      console.log("coming here")
      let data = { allDATA: this.allData }
      console.log(data);
      const modalRef = this.modalService.open(ModalShippedProductComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered: true});
      modalRef.componentInstance.fromParent = data;
      modalRef.result.then((result) => {
        this.initializeForm();
        this.pageLoad();
      }, (reason) => {
      });
    }
    else
    {
      this.activeModal.close()
      this.allData.WareHouseShippedType=0;
      console.log("Helo ReceiveFunOutOfModal=>",this.allData)
      this.spinner.show();
      var data = {
        WareHouseData : this.allData.WareHouseData,
        WareHouseItemsData:this.allData.WareHouseItems,
        WareHouseShippedType:this.allData.WareHouseShippedType

      }
      this.deliveryOrderService.shipItems(data).subscribe((x)=>
      {
        console.log("Hello Response",x)
        if (x.Success) {
          this.ReceiveBtnFlag=true;
          this.toaster.success('Product Received Successfully!');
          this.spinner.hide();
          this.initializeForm();
          this.pageLoad();
        }
        else{
          this.toaster.error(x.Message);
          this.spinner.hide();
          //this.toaster.warning('Something Went Wrong!');
          //location.reload();
        }

      });
    }
  }

  generateBackUrl() {
    switch(this.allData?.WareHouseData?.OperationType) {
      case WareHouseOperationType.SalesOrder:
        return 'sales/sales-order-delivery-list/' + this.allData?.SalesOrderData?.Id + '/All';
      case WareHouseOperationType.Invoice:
        return 'income/invoice-delivery-order-list/' + this.allData.InvoiceData.InvoicceNo + '/All';
      case WareHouseOperationType.PurchaseItemReturnedForPurchaseOrder:
        return "procurement/purchase-order-receipt-list/" + this.allData?.PurchaseOrderData?.Id + "/" + this.allData?.PurchaseOrderData?.OrderNo + "/All";
      case WareHouseOperationType.PurchaseItemReturnedForBill:
        return "expense/bill-receipt-list/" + this.allData?.BillData?.BillNo + "/All";
      default:
        return "inventory/delivery-order/All";
    }
  }

  returnProduct() {
    let isAllItemsReturn = this.ReferenceShippedItemsData?.every(x => x < 0);
    if(isAllItemsReturn){
      this.toaster.warning('You are unable to return since all items have already been returned!');
      return;
    }
    const modalRef = this.modalService.open(ReturnModalComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered: true});

    let data={
      wareHouseNo:'WH No. '+this.wareHouseProductNo,
      wareHouseRef:this.wareHouseProductNo,
      allData:this.allData
    }
        modalRef.componentInstance.WareHouseOutData = data;
        modalRef.result.then((result) => {
          if(result != 'Cross click') {
            let url = '';
            if(this.allData?.BillData != null)
              url = "expense/bill-receipt-list/" + this.allData?.BillData?.BillNo + "/All";
            if(this.allData?.PurchaseOrderData != null)
              url = "procurement/purchase-order-receipt-list/" + this.allData?.PurchaseOrderData?.Id + "/" + this.allData?.PurchaseOrderData?.OrderNo + "/All";
            if(this.allData?.InvoiceData != null)
              url = 'income/invoice-delivery-order-list/' + this.allData.InvoiceData.InvoicceNo + '/All';
            if(this.allData?.SalesOrderData != null)
              url = 'sales/sales-order-delivery-list/' + this.allData?.SalesOrderData?.Id + '/All';
            this.router.navigate([url]);
          }
         }, (reason) => {  });
  }

  CancelItemReturned() {
      this.spinner.show();
      this.deliveryOrderService.CancelItemReturned(this.allData.WareHouseData).subscribe((x) => {
        if (x.Success) {
          this.toaster.success('Cancel Successfully');
          this.spinner.hide();
          let url = '';
        if(this.allData.BillData != null)
          url = "expense/bill-receipt-list/" + this.allData?.BillData?.BillNo + "/All";
        if(this.allData.PurchaseOrderData != null)
          url = "procurement/purchase-order-receipt-list/" + this.allData?.PurchaseOrderData?.Id + "/" + this.allData?.PurchaseOrderData?.OrderNo + "/All";
        this.router.navigate([url]);
        } else {
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
  }

  onFocusEvent(event: any) {
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }

  canShowDropdownButton() {
    return this.allData?.WareHouseData?.StatusId != 2 && (this.allData?.WareHouseData?.OperationType == 5 || this.allData?.WareHouseData?.OperationType == 6 || this.allData?.WareHouseData?.OperationType == 7 || this.allData?.WareHouseData?.OperationType == 8)
  }

  canShowReturnButton() {
    return this.allData?.WareHouseData?.StatusId == 2 && this.allData?.CanSalesReturn && this.allData?.WareHouseData?.OperationType != 5 && this.allData?.WareHouseData?.OperationType != 6 && this.allData?.WareHouseData?.OperationType != 7 && this.allData?.WareHouseData?.OperationType != 8;
  }

  isReturnWareHouse() {
    return this.ReturnOperationTypes.includes(this.allData.WareHouseData.OperationType);
  }

}
