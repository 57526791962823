import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { WareHouseOperationType } from 'src/app/enum/wareHouseOperationType';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';

@Component({
  selector: 'app-invoice-delivery-order-list',
  templateUrl: './invoice-delivery-order-list.component.html',
  styleUrls: ['./invoice-delivery-order-list.component.css']
})
export class InvoiceDeliveryOrderListComponent implements OnInit {
  listTitle: string = "Inventory Overview/Receipts";
  subTitle: any;
  backUrl: any;
  searchText: string;
  invoiceNo: string;
  private gridApi;
  allData: any = [];
  domLayout = "autoHeight";

  constructor(
    private route: ActivatedRoute,
    private inventoryOverviewService: InventoryOverviewService
  ) { }

  ngOnInit(): void {
    this.searchText = this.route.snapshot.paramMap.get("id");
    this.invoiceNo = this.route.snapshot.paramMap.get("invoiceNo");
    this.pageLoad(this.searchText);
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  };

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  pageLoad(status: string){
    this.inventoryOverviewService.GetDeliveryOrderByInvoiceNo(this.invoiceNo).subscribe((x) => {
      this.allData = x;
      this.allData = this.allData.filter((x) => x.OperationType == WareHouseOperationType.Invoice || x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice);
      switch (status) {
        case "All":
          this.allData = this.allData.filter((x) => x.OperationType == WareHouseOperationType.Invoice || x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice);
          break;
        case "Waiting":
          this.allData = this.allData.filter((x) => x.StatusId == 1);
          break;
        case "Ready":
          this.allData = this.allData.filter((x) => x.StatusId == 3);
          break;
        case "Received":
          this.allData = this.allData.filter((x) => x.StatusId == 2 && x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice);
          break;
        case "Shipped":
          this.allData = this.allData.filter((x) => x.StatusId == 2 && x.OperationType != WareHouseOperationType.SoldItemReturnedForInvoice);
          break;
      }

      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].SerialNo = i + 1;
        if(this.allData[i].OperationType == WareHouseOperationType.SoldItemReturnedForInvoice) {
          this.allData[i].WHNO = "WH-RET-" + this.allData[i].WareHouseProductNo;
          if(this.allData[i].ParentWareHouse.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice) {
            this.allData[i].ReferenceNo = "WH-RET-"+this.allData[i].ParentId;
          }
          else{
            this.allData[i].ReferenceNo = "WH-Out-"+this.allData[i].ParentId;
          }
        }
        else{
          this.allData[i].WHNO = "WH-Out-" + this.allData[i].WareHouseProductNo;
          this.allData[i].ReferenceNo = "INV-"+this.allData[i].ReferenceNo;
        }
      }
    });
  }

  columnDefs = [
    {
      headerName: "Serial No.",
      field: "SerialNo",
      cellStyle: { color: "blue", cursor: "pointer" },
    },
    {
      headerName: "WH No.",
      field: "WHNO",
      cellStyle: { color: "blue", cursor: "pointer" },

      cellRenderer: function (params) {
        if(params.data.OperationType == WareHouseOperationType.Invoice)
          return `<a href="#/inventory/delivery-order-waiting/${params.data.Id}" >${params.data.WHNO}</a>`;
        if(params.data.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice)
          return `<a href="#/inventory/receipt-waiting/${params.data.Id}" >${params.data.WHNO}</a>`;
      },
    },
    { headerName: "To", field: "Contacts.CompanyName" },
    {
      headerName: "Date",
      field: "OperationDate",
      valueFormatter: this.convertGridDateIntoDisplay,
    },
    {
      headerName: "Reference",
      field: "ReferenceNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      cellRenderer: function (params) {
        //The reference no has been modied in the allData array. in the pageLoad function.
        if(params.data.OperationType == WareHouseOperationType.Invoice)
          return `<a href="#/income/invoice/${params.data.ReferenceNo}" >${params.data.ReferenceNo}</a>`; 
        if(params.data.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice)
          if(params.data.ParentWareHouse.OperationType != WareHouseOperationType.SoldItemReturnedForInvoice)
            return `<a href="#/inventory/delivery-order-waiting/${params.data.ParentWareHouse.Id}">${params.data.ReferenceNo}</a>`;
          else
            return `<a href="#/inventory/receipt-waiting/${params.data.ParentWareHouse.Id}">${params.data.ReferenceNo}</a>`;
      },
    },
    {
      headerName: "Status",
      field: "Status",
      valueFormatter: this.setOrderStatus,
    },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };

  convertGridDateIntoDisplay(date) {
    const dateString = date.value ? moment(date.value).format("Do MMM YYYY") : "";
    return dateString;
  }
  
  setOrderStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return "Waiting";
      case 2:
        if(id.data.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice)
          return "Received";
        else
          return 'Shipped';
      case 3:
        return "Ready";
      default:
        return "unknown";
    }
  }
}
