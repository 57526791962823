<app-dashboard>
    <app-page-header-title
    mainTitle="Template Settings"
    subTitle="Settings"
    backURL="/settings">
  </app-page-header-title>
  <ul class="nav nav-tabs justify-content-center">
    <li class="nav-item ">
      <a class="nav-link f-ssb-14px nav-option" (click)="toggleTemplate('templateSettings')" [routerLinkActive]="['active']">Templates</a>
    </li>
    <li class="nav-item">
      <a class="nav-link f-ssb-14px nav-option" (click)="toggleTemplate('dateSettings')" [routerLinkActive]="['active']">Data Settings</a>
    </li>
</ul>
  
  <div *ngIf="templateSettings" class="row mm-5px pbp-20px">
    <div class="col-md-3 pp-5px">
  </div>
    <div class="col-md-3 pp-5px">
        <div class="pp-10px bg-grey br-5px">
            <div class="pbp-10px image-container">
                <img class="image" width="100%" src="../../../../assets/main/images/templates/template1.png"/>
                <div class="middle">
                    <a (click)="viewTemplate('t1')"  class="btn-hover"><i class="material-icons-outlined" style="color: #444444;line-height:unset;">remove_red_eye</i></a>
                </div>
            </div>
            <div class="content-center" >
                <label style="cursor: pointer;">
                    <span *ngIf="!ob.t1" (click)="templateSelcetor('t1')" ><i style="color: #0061C9;font-size: 19px;margin-top: 5px;" class="material-icons">radio_button_unchecked</i></span>
                    <span *ngIf="ob.t1" (click)="templateSelcetor('t1')" ><i style="color: #0061C9;font-size: 19px;margin-top: 5px;" class="material-icons">lens</i></span>
                    <span (click)="templateSelcetor('t1')" style="color: #555555;">Template 1</span>
                  </label>
            </div>
            
        </div>
    </div>
    <div class="col-md-3 pp-5px">
        <div class="pp-10px bg-grey br-5px">
            <div class="pbp-10px image-container">
                <img class="image" width="100%" src="../../../../assets/main/images/templates/template2.png"/>
                <div class="middle">
                    <a (click)="viewTemplate('t2')"  class="btn-hover"><i class="material-icons-outlined" style="color: #444444;line-height:unset;">remove_red_eye</i></a>
                </div>
            </div>
            <div class="content-center" >
                <label style="cursor: pointer;">
                    <span *ngIf="!ob.t2" (click)="templateSelcetor('t2')" ><i style="color: #0061C9;font-size: 19px;margin-top: 5px;" class="material-icons">radio_button_unchecked</i></span>
                    <span *ngIf="ob.t2" (click)="templateSelcetor('t2')" ><i style="color: #0061C9;font-size: 19px;margin-top: 5px;" class="material-icons">lens</i></span>
                    <span (click)="templateSelcetor('t2')" style="color: #555555;">Template 2</span>
                  </label>
            </div>
            
        </div>
    </div>
    <div class="col-md-3 pp-5px">
    </div>
    <ngx-smart-modal #templatePreview identifier="templatePreview">
        <div class="pb-4">
            <span class="modalHeaderText pl-2"></span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                <div>
                    <img id="image" style="width: 100%;" src={{imageSource}} />
                </div>
            </div>
        </div>
        
        </div>
    </ngx-smart-modal>

     <!-- <button style="margin-top: 20px;" class="btn eCount-button" (click)="save()">
        <i style="font-size: 14px;" class="material-icons">check</i> Save Changes
      </button>
      <button type="button"  [routerLink]="['/quotation/edit-quotation',QuotationData.Id]"  class="eCount-def-btn ml-4">Save</button> -->
  </div>
  <div style="margin-left: 5%;" *ngIf="dateSettings" class="row mm-5px pbp-20px">
    
    <div class="col-md-12 pp-5px">
        <b style="margin-top: 10px;">Select below fields to hide from the templates</b>
    </div>
    <div class="col-md-12 pp-5px">
        <div class="row" style="margin-left: 0px;">
            <div class="col-md-3 pp-5px">
                <div style="margin-top: 20px;padding-bottom: 10px;">
                    <b>Header</b>
                </div>
                <div *ngFor="let item of dateSettingsList" style="margin-top: 5px;">
                    <div *ngIf="item.TypeName=='Header'">
                        <input type="checkbox" (click)="toggleDataSettingsCheckBox(item)" [(ngModel)]="item.IsChecked" />
                        <span style="margin-left: 10px;">{{item.Name}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 pp-5px">
                <div style="margin-top: 20px;padding-bottom: 10px;">
                    <b>Footer</b>
                </div>
                <div *ngFor="let item of dateSettingsList" style="margin-top: 5px;">
                    <div *ngIf="item.TypeName=='Footer'">
                        <input type="checkbox" (click)="toggleDataSettingsCheckBox(item)" [(ngModel)]="item.IsChecked" />
                        <span style="margin-left: 10px;">{{item.Name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 pp-5px">
        <div class="row" style="margin-left: 0px;">
            <div class="col-md-3 pp-5px">
                <div style="margin-top: 20px;padding-bottom: 10px;">
                    <b>To</b>
                </div>
                <div *ngFor="let item of dateSettingsList" style="margin-top: 5px;">
                    <div *ngIf="item.TypeName=='To'">
                        <input type="checkbox" (click)="toggleDataSettingsCheckBox(item)" [(ngModel)]="item.IsChecked" />
                        <span style="margin-left: 10px;">{{item.Name}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 pp-5px">
                <div style="margin-top: 20px;padding-bottom: 10px;">
                    <b>Details</b>
                </div>
                <div *ngFor="let item of dateSettingsList" style="margin-top: 5px;">
                    <div *ngIf="item.TypeName=='Details'">
                        <input type="checkbox" (click)="toggleDataSettingsCheckBox(item)" [(ngModel)]="item.IsChecked" />
                        <span style="margin-left: 10px;">{{item.Name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 pp-5px">
        <div class="row" style="margin-left: 0px;">
            <div class="col-md-3 pp-5px">
                <div style="margin-top: 20px;padding-bottom: 10px;">
                    <b>From</b>
                </div>
                <div *ngFor="let item of dateSettingsList" style="margin-top: 5px;">
                    <div *ngIf="item.TypeName=='From'">
                        <input type="checkbox" (click)="toggleDataSettingsCheckBox(item)" [(ngModel)]="item.IsChecked" />
                        <span style="margin-left: 10px;">{{item.Name}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 pp-5px">
                <div style="margin-top: 20px;padding-bottom: 10px;">
                    <b>Others</b>
                </div>
                <div *ngFor="let item of dateSettingsList" style="margin-top: 5px;">
                    <div *ngIf="item.TypeName=='Others'">
                        <input type="checkbox" (click)="toggleDataSettingsCheckBox(item)" [(ngModel)]="item.IsChecked" />
                        <span style="margin-left: 10px;">{{item.Name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-left: 0px;">
            <!-- <div class="col-md-3 pp-5px"></div> -->
            <div class="col-md-9 pp-5px">
                <div class="col-md-10" style="border: 1px solid;padding: 30px;margin-top: 50px;">
                    If you have any questions about this policy or your interaction with ImpleVista by means of this site,
                    please feel free to contact us.
                </div>
            </div>
        </div>
    </div>
  </div>
</app-dashboard>