<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;" *ngIf="fromParent.Id == 0">{{'FIXEDASSET.assetTypePopupTitle' | translate}}</h4>
    <h4 class="modal-title" style="font-size: 17px;" *ngIf="fromParent.Id != 0">Edit Asset Type</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="assetTypeForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="AssetType" style="font-weight: 600;">{{'FIXEDASSET.assetType' | translate}} <span
                                        class="required-sign">*</span></label>
                                <input type="text" class="form-control form-input" formControlName="AssetType">
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="AssetAccountId" style="font-weight: 600;">Asset Account</label>
                                <ng-select [items]="inputHelp.AssetAccounts" placeholder="Select Account"
                                class="customSaleItem" [clearable]="false" bindLabel="AccountName"
                                bindValue="Id" formControlName="AssetAccountId">
                            </ng-select>
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="DepreciationAccountId" style="font-weight: 600;">Accumulated Depreciation Account</label>
                                <ng-select [items]="inputHelp.AssetAccounts" placeholder="Select Account"
                                    class="customSaleItem" [clearable]="false" bindLabel="AccountName"
                                    bindValue="Id" formControlName="DepreciationAccountId">
                                </ng-select>
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="DepreciationExpenseAccountId" style="font-weight: 600;">Depreciation Expense Account</label>
                                <ng-select [items]="expenseAccounts" placeholder="Select Account"
                                        class="customSaleItem" [clearable]="false" bindLabel="AccountName"
                                        bindValue="Id" formControlName="DepreciationExpenseAccountId" groupBy="GroupName">
                                </ng-select>
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="DepreciationMethodId" style="font-weight: 600;">Depreciation Method</label>
                                <ng-select [items]="inputHelp.DepreciationMethods" placeholder="Select Account"
                                         [clearable]="false" bindLabel="Name"
                                        bindValue="Id" formControlName="DepreciationMethodId">
                                </ng-select>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="assetTypeForm.value.DepreciationMethodId != 1">
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group  col-sm-12">
                                    <label for="AveragingMethodId" style="font-weight: 600;">Averaging Method</label>
                                    <ng-select [items]="inputHelp.AveragingMethods" placeholder="Select Account"
                                             [clearable]="false" bindLabel="Name"
                                            bindValue="Id" formControlName="AveragingMethodId">
                                    </ng-select>
                                </div>
    
                            </div>
                        </div>
    
                        <div class="row rowBottomBorder">
                            <div class="col-sm-12" style="display: flex;">
                                <div class="form-group  col-sm-4">
                                    <label style="font-weight: 600;">&nbsp;</label>
                                    <label class="switch">
                                        <input name="IsRatePercent" formControlName="IsRatePercent" class="switch-input"
                                            type="checkbox"  (change)="setDepreciationValueType()"/>
                                        <span class="switch-label" data-on="Rate" data-off="LifeYear"></span>
                                        <span class="switch-handle"></span>
                                    </label>
                                </div>
    
                                <div class="form-group  col-sm-4">
                                    <label for="RatePercent" style="font-weight: 600;">Rate % </label>
                                    <input type="text" appDecimaNumericValidator [readonly]="!assetTypeForm.value.IsRatePercent" class="form-control form-input" formControlName="RatePercent">
                                </div>
                                <div class="form-group  col-sm-4">
                                    <label for="EffectiveLifeYears" style="font-weight: 600;">Effective Live(Yrs)</label>
                                    <input type="text" appDecimaNumericValidator [readonly]="assetTypeForm.value.IsRatePercent" class="form-control form-input" formControlName="EffectiveLifeYears">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12">

                <button type="button" [disabled]="assetTypeForm.invalid" (click)="saveAssetType()"
                    class="eCount-def-btn  ml-4 p-save-button">Save</button>

            </div>
        </div>
    </div>
</div>