<app-dashboard>
  <app-page-header-title
    mainTitle="Create New Sales Order"
    subTitle="Sales Order"
    backURL="/sales/sales-order-list/All"
  >
    <div class="full-width text-align-right">
      <button
        type="button"
        (click)="saveAsAwaitingApproval()"
        class="eCount-def-btn ml-4"
      >
        Save
      </button>
      <button
        type="button"
        style="background: #5cac00"
        (click)="saveAsApproved()"
        class="eCount-def-btn ml-4"
      >
        Approve
      </button>
    </div>
  </app-page-header-title>
  <div class="headSection">
    <form [formGroup]="salesOrderForm" class="f-ss-14px">
      <div class="row f-ss-14px">
        <div class="col-md-2 col-sm-12">
          <label for="ContactId">To<span class="required-sign">*</span></label>
          <ng-select
            [items]="supplierList"
            bindLabel="CompanyName"
            bindValue="Id"
            [addTag]="CreateNewSupplier"
            [loading]="loading"
            formControlName="ContactId"
            [ngClass]="{ submitted: contactIdRequired === false }"
            required
          >
            <ng-template ng-tag-tmp let-search="searchTerm">
              <b>+ Add New Customer</b>: {{ search }}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-md-2 col-sm-12">
          <label for="OrderDate"
            >Order Date<span class="required-sign">*</span></label
          >
          <input
            class="form-control"
            type="date"
            formControlName="OrderDate"
            id="itest"
            data-date=""
            (change)="show($event)"
            data-date-format="D MMM YYYY"
          />
        </div>

        <div class="col-md-2 col-sm-12">
          <label for="DeliveryDate"
            >Delivery Date<span class="required-sign">*</span></label
          >
          <input
            class="form-control"
            [ngClass]="{ submitted: deliveryDateRequired === false }"
            type="date"
            formControlName="DeliveryDate"
            id="itest1"
            data-date=""
            (change)="showSelectedDate($event)"
            data-date-format="D MMM YYYY"
            required
          />
        </div>

        <div class="col-md-2 col-sm-12">
          <label for="ReferenceNo">Reference</label>
          <input
            class="form-controlinputBox"
            type="text"
            formControlName="ReferenceNo"
          />
        </div>
        <!--<div class="col-md-2 col-sm-12">
            <label for="InDate">Sales No</label>
            <input class="form-controlinputBox" type="text" formControlName="OrderNo"/>
          </div>-->
        <div class="col-md-2 col-sm-12">
          <label>Sales No</label>
          <span class="form-controlinputBox d-flex">
            <label style="background-color: #dddddd; padding-bottom: 10%">{{
              ShowSalesOrderNo
            }}</label>
            <input
              class="form-controlinputBox2"
              formControlName="OrderNo"
              type="text"
            />
          </span>
        </div>
        <div class="col-md-2 col-sm-12">
          <label for="InDate">Amounts Are</label>
          <ng-select
            [items]="taxTypeList"
            (change)="updateTotalPrice()"
            [clearable]="false"
            bindLabel="Name"
            bindValue="Id"
            formControlName="TaxTypeId"
          ></ng-select>
        </div>
      </div>
      <!-- <div class="row f-ss-14px">
        <div style="margin-top: 10px;" class="col-md-2 col-sm-12">
          <label for="SalesRepresentative">Sales Representative</label>
          <input
            class="form-controlinputBox"
            type="text"
            formControlName="SalesRepresentative"
          />
        </div>
      </div> -->
      <div class="row d-block topSection f-ss-14px">
        <div class="d-flex">
          <div class="col-md-1">Item</div>
          <div class="col-md-2">Description</div>
          <div class="col-md-2">Account</div>
          <div class="col-md-1">Qty</div>
          <div class="col-md-1"></div>
          <div class="col-md-1">Price</div>
          <div class="col-md-1">VAT</div>
          <div class="col-md-1">AIT</div>
          <div class="col-md-2">Amount</div>
        </div>
        <hr class="pb-0 mb-0" />
        <div
          formArrayName="SalesOrderItem"
          *ngFor="
            let item of salesOrderForm.get('SalesOrderItem')['controls'];
            let i = index
          "
        >
          <div class="invoiceGridItemRow d-block">
            <div class="row pt-1" [formGroupName]="i">
              <div class="col-md-1 margin-top-5">
                <ng-select
                  [items]="productList"
                  placeholder="Select Items"
                  #selectItem
                  class="customSaleItem"
                  [clearable]="false"
                  bindLabel="ItemName"
                  bindValue="Id"
                  (change)="changespurchaseableItems(i)"
                  formControlName="ItemId"
                  style="width: 110px;"
                >
                  <ng-template ng-header-tmp>
                    <a
                      (click)="openItemModal(selectItem)"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                      ><span style="color: #007bff">+ Add Item</span></a
                    >
                  </ng-template>
                </ng-select>
              </div>
              <div class="col-md-2 margin-top-5">
                <input
                  class="form-control"
                  formControlName="Description"
                  [ngClass]="{ submitted: descriptionRequired === false }"
                />
              </div>
              <div class="col-md-2 margin-top-5">
                <ng-select
                  groupBy="GroupName"
                  [items]="accountList"
                  [clearable]="false"
                  bindLabel="AccountName"
                  bindValue="Id"
                  formControlName="AccountId"
                ></ng-select>
              </div>
              <div class="col-md-1 margin-top-5">
                <input
                  class="form-control"
                  (change)="updatePrice(i, item)"
                  appDecimaNumericValidator
                  formControlName="Quantity"
                  [ngClass]="{ submitted: qtyRequired === false }"
                />
              </div>
              <div class="col-md-1 margin-top-5 pl-5 pr-5">
                <span id="successBtn" *ngIf="item.value.SuccessLogo == true && item.value.Quantity>0 && item.value.Quantity!=''" class="deletebtn">
                  <img style="height: 33px" src="../../assets/icons/successImg.jpg"/>
                </span>
                <span id="dangerBtn" (click)="toggleStockAvailability(i)" *ngIf="item.value.DangerLogo == true && item.value.Quantity>0 && item.value.Quantity!=''" class="deletebtn">
                  <img style="height: 33px" src="../../assets/icons/danger2.jpg"/>
                </span>
                <div>
                  <div *ngIf="itemStockAvailable[i]" class="popup">
                    <div class="bg-color">
                      <p class="font-weight-bold" style="color: #000;">Availability</p>
                    </div>
                    Stock availability 0.<br>Need to purchase product.
                  </div>
                </div>
              </div>
              <div class="col-md-1 margin-top-5">
                <input
                  class="form-control"
                  (change)="updatePrice(i, item)"
                  appDecimaNumericValidator
                  formControlName="UnitPrice"
                  [ngClass]="{ submitted: unitPriceRequired === false }"
                />
              </div>
              <div class="col-md-1 margin-top-5">
                <ng-select
                  [items]="taxRateList"
                  [clearable]="false"
                  (change)="updateTotalPrice()"
                  [readonly]="isVatAitDisabled"
                  bindLabel="Name"
                  bindValue="Id"
                  formControlName="TaxRateId"
                ></ng-select>
              </div>
              <div class="col-md-1 margin-top-5">
                <ng-select
                  [items]="taxRateList"
                  [clearable]="false"
                  (change)="updateTotalPrice()"
                  [readonly]="isVatAitDisabled"
                  bindLabel="Name"
                  bindValue="Id"
                  formControlName="AdvanceTaxRateId"
                ></ng-select>
              </div>

              <div class="col-md-2 margin-top-5 d-flex">
                <div>
                  <input
                    class="form-control"
                    readonly
                    formControlName="LineTotal"
                  />
                </div>

                <div style="padding: 7px 10px; color: #c7c2c2">
                  <div class="dropdown mr-4">
                    <div
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="false"
                      aria-expanded="false"
                    >
                      <i class="icon-more float-right"></i>
                    </div>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      style="width: 100%; cursor: pointer"
                    >
                      <div class="productRow pb-3" (click)="deleteItem(i)">
                        <span class="lead1">Remove Item </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row addItem">
          <div class="BottomAddItem w-100">
            <div class="dropdown mr-4">
              <a
                class="nav-link"
                style="background-color: white"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
                (click)="addItem()"
              >
                <img
                  class="languageImg"
                  src="../../../assets/image/addIcon.png"
                />
                Add New Line
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container-bottom d-flex">
        <div class="col-md-8 col-sm-12">
          <div class="form-group col-sm-5 row">
            <label>Notes </label>
            <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea"
                  placeholder="Write down some important notes using only 250 characters" formControlName="Notes"></textarea>
            <span class="remaning" >{{myInput.value.length}}/250</span>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 p-0 d-block">
          <div class="d-flex w-100">
            <div class="col-md-8">
              <label>Subtotal</label>
            </div>
            <div class="col-md-4">
              {{ subTotalAmount.toFixed(2) | currency : "" : "" }}
            </div>
          </div>
          <div
            class="d-flex w-100 pb-1"
            *ngIf="salesOrderForm.value.TaxTypeId != 3"
          >
            <div class="col-md-8">
              <label>VAT</label>
            </div>
            <div class="col-md-4">
              {{ TaxAmount.toFixed(2) | currency : "" : "" }}
            </div>
          </div>
          <div
            class="d-flex w-100 pb-1"
            *ngIf="salesOrderForm.value.TaxTypeId != 3"
          >
            <div class="col-md-8">
              <label>AIT</label>
            </div>
            <div class="col-md-4">
              {{ AdvanceTaxAmount.toFixed(2) | currency : "" : "" }}
            </div>
          </div>
          <div class="d-flex w-100 equalLine">
            <span></span>
          </div>
          <div class="d-flex w-100 pt-3">
            <div class="col-md-8">
              <label>TOTAL</label>
            </div>
            <div class="col-md-4 font-weight-bold">
              {{ totalPrice.toFixed(2) | currency : "" : "" }}
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-dashboard>
