import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ItemType } from 'src/app/enum/item-type';
import { ManufactureFormulaStatus } from 'src/app/enum/manufacture-formula-status';
import { ManufactureFormulaDetailsPageViewModel } from 'src/app/models-viewModels/manufactureFormulaDetailsPageViewModel';
import { Item } from 'src/app/models/inventoryOverview.model';
import { ManufactureFormula, ManufactureFormulaDetails } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';
import { ProductServiceService } from 'src/app/service/Products/product-service.service';

@Component({
  selector: 'app-edit-manufacture-formula',
  templateUrl: './edit-manufacture-formula.component.html',
  styleUrls: ['./edit-manufacture-formula.component.css']
})

export class EditManufactureFormulaComponent implements OnInit {

  ProductFormulaForm: FormGroup;
  FormulaItems: FormArray;
  finishedProductList: Item[];
  rawMaterialList: Item[];
  manufactureFormulaDetailsData: ManufactureFormulaDetailsPageViewModel;

  formulaId: number;

  constructor(
    private fb: FormBuilder,
    private productServiceService: ProductServiceService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private manufactureService: ManufactureService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.formulaId = +params['id'];
      this.getFormula();
   });
    this.initializeForm();
    this.getFormData();
  }

  initializeForm() {
    this.ProductFormulaForm = this.fb.group({
      Id: [0],
      FinishedProductId: ['', Validators.required],
      FormulaName: ['', Validators.required],
      Quantity: ['', Validators.required],
      CreatedDate: ['', Validators.required],
      Reference: [''],
      FormulaItems: this.fb.array([])
    });
    this.ProductFormulaForm.get('CreatedDate').patchValue(new Date());
    this.showSelectedDate(this.ProductFormulaForm.value.CreatedDate);
  }

  getFormData() {
    this.getFinishedProductList();
    this.getRawMaterialList();
  }

  getFinishedProductList() {
    this.productServiceService.GetItemList(ItemType.FinishProduct).subscribe((x) => {
      this.finishedProductList = x;
    });
  }

  getRawMaterialList() {
    this.productServiceService.GetItemList(ItemType.RawMaterial).subscribe((x) => {
      this.rawMaterialList = x;
    });
  }

  getFormula(){
    this.manufactureService.getFormula(this.formulaId).subscribe(x => {
      this.manufactureFormulaDetailsData = x;
      this.setParentData(this.manufactureFormulaDetailsData.ManufactureFormula);
      x.ManufactureFormula.ManufactureFormulaDetails.forEach((item, i) => {
        this.setItemData(item, i);
      });
    });
  }

  setParentData(formulaData) {
    this.ProductFormulaForm.controls['Id'].patchValue(formulaData.Id);
    this.ProductFormulaForm.controls['FinishedProductId'].patchValue(formulaData.Item.ItemName);
    this.ProductFormulaForm.controls['FormulaName'].patchValue(formulaData.FormulaName);
    this.ProductFormulaForm.controls['Quantity'].patchValue(formulaData.Quantity);
    this.ProductFormulaForm.controls['CreatedDate'].patchValue(formulaData.CreateDate);
    this.ProductFormulaForm.controls['Reference'].patchValue(formulaData.Reference);
  }

  setItemData(item: ManufactureFormulaDetails, i: number) {
    this.FormulaItems = this.ProductFormulaForm.get('FormulaItems') as FormArray;
    this.FormulaItems.push(this.setItem(item, i));
  }

  setItem(item: ManufactureFormulaDetails, i: number): FormGroup {
    return this.fb.group({
      RawMaterialId: [item.Item.Id],
      ItemQuantity: [item.Quantity]
    });
  }

  addItem(): void {
    this.FormulaItems = this.ProductFormulaForm.get('FormulaItems') as FormArray;
    this.FormulaItems.push(this.createItem());
  }

  createItem(): FormGroup {
    return this.fb.group({
      RawMaterialId: [null],
      ItemQuantity: [0]
    });
  }

  removeItems(i: number) {
    this.FormulaItems = this.ProductFormulaForm.get('FormulaItems') as FormArray;
    this.FormulaItems.value.map((x, index) => {
      if(index === i){
        this.FormulaItems.removeAt(index);
      }
    });
  }

  saveManufactureFormula() {
    if(this.checkFormValidation()){
      this.spinner.show();
      let data: ManufactureFormula = this.formatData();
      this.manufactureService.updateManufactureFormula(data).subscribe((x) => {
        if(x.Success) {
          this.toaster.success(x.Message);
          this.router.navigate(["manufacture/finished-product-material-formula-list/All"]);
          this.spinner.hide();
        }
        else{
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
    }
  }

  approveManufactureFormula() {
    if(this.checkFormValidation()){
      this.spinner.show();
      let data: ManufactureFormula = this.formatData();
      data.Status = ManufactureFormulaStatus.Approved;
      this.manufactureService.updateManufactureFormula(data).subscribe((x) => {
        if(x.Success) {
          this.toaster.success(x.Message);
          this.router.navigate(["manufacture/finished-product-material-formula-list/All"]);
          this.spinner.hide();
        }
        else{
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
    }
  }

  checkFormValidation() {
    if(this.ProductFormulaForm.value.Quantity == '' || Number(this.ProductFormulaForm.value.Quantity) == 0){
      this.toaster.warning('Formula quantity should not zero!');
      return false;
    }
    const formulaItems = this.ProductFormulaForm.get('FormulaItems').value;
    if(formulaItems.length != 0){
      const hasNoFormulaItems = formulaItems.some(item => item.RawMaterialId == null);
      if(hasNoFormulaItems) {
        this.toaster.warning('Please select item!');
        return false;
      }
      const hasZeroItemQuantity = formulaItems.some(item => item.ItemQuantity == 0);
      if(hasZeroItemQuantity){
        this.toaster.warning('Item quantity should not zero!');
        return false;
      }
    }
    const itemIds = new Set();
    for(const item of formulaItems){
      if(itemIds.has(item.RawMaterialId)) {
        this.toaster.warning('Same item could not add in multiple time!');
        return false;
      }
      else {
        itemIds.add(item.RawMaterialId);
      }
    }
    if(!this.ProductFormulaForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return false;
    }
    return true;
  }

  formatData(): ManufactureFormula {
    let manufactureFormulaDetails: ManufactureFormulaDetails[] = [];
    this.ProductFormulaForm.value.FormulaItems.forEach(element => {
      let formula = this.manufactureFormulaDetailsData.ManufactureFormula.ManufactureFormulaDetails.find(x => x.ItemId === element.RawMaterialId);
      let formulaDetails = new ManufactureFormulaDetails();
      formulaDetails.Id = formula != null ? formula.Id : 0;
      formulaDetails.ManufactureFormulaId = this.manufactureFormulaDetailsData.ManufactureFormula.Id;
      formulaDetails.ItemId = element.RawMaterialId;
      formulaDetails.Quantity = element.ItemQuantity;
      manufactureFormulaDetails.push(formulaDetails);
    });

    let manufactureFormula: ManufactureFormula = {
      Id: this.manufactureFormulaDetailsData.ManufactureFormula.Id,
      FormulaName: this.manufactureFormulaDetailsData.ManufactureFormula.FormulaName,
      FinishedProductId: this.manufactureFormulaDetailsData.ManufactureFormula.FinishedProductId,
      Quantity: this.ProductFormulaForm.value.Quantity,
      CreateDate: this.ProductFormulaForm.value.CreatedDate,
      Reference: this.ProductFormulaForm.value.Reference,
      Status: ManufactureFormulaStatus.Draft,
      CompanyId: this.manufactureFormulaDetailsData.ManufactureFormula.CompanyId,
      ManufactureFormulaDetails: manufactureFormulaDetails
    };
    return manufactureFormula;
  }

  showSelectedDate(e: Event) {
    const value = this.ProductFormulaForm.value.CreatedDate;
    $('#createdDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  setMainTitle() {
    let status = this.formatStatus();
    let mainTitle = this.manufactureFormulaDetailsData?.ManufactureFormula?.FormulaName+' / '+status;
    return mainTitle;
  }

  formatStatus() {
    if(this.manufactureFormulaDetailsData?.ManufactureFormula?.Status == ManufactureFormulaStatus.Draft) {
      return 'Draft';
    }
    else if(this.manufactureFormulaDetailsData?.ManufactureFormula?.Status == ManufactureFormulaStatus.Approved) {
      return 'Approved';
    }
  }

}
