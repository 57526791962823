import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
    template: '<a *ngIf="!params.orderNo" [routerLink]="[params.inRouterLink,params.value]"  (click)="navigate(params.inRouterLink)">{{params.value}}</a><a *ngIf="params.orderNo" [routerLink]="[params.inRouterLink,params.value,params.orderNo]"  (click)="navigate(params.inRouterLink)">{{params.value}}</a>'
})
export class RouterLinkRendereComponent implements AgRendererComponent {
    params: any;

    constructor(
        private ngZone: NgZone,
        private router: Router) { }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(params: any): boolean {
        return false;
    }

    // This was needed to make the link work correctly
    navigate(link) {
        if( !!this.params.orderNo ){
            if(this.params.data.IsReverseTransaction != true) {
                this.ngZone.run(() => {
                    this.router.navigate([link, this.params.value, this.params.orderNo]);
                });
            }
            else {
                this.ngZone.run(() => {
                    if(this.params.data.InvoicceNo != undefined)
                        this.router.navigate([link, 'INV-'+this.params.data.InvoicceNo, this.params.orderNo]);
                    else
                        this.router.navigate([link, 'Bill-'+this.params.data.BillNo, this.params.orderNo]);
                });
            }
        }else{
            this.ngZone.run(() => {
                this.router.navigate([link, this.params.value]);
            });
        }
    }
}