import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessOwnerService } from '../../../service/Business Owner/business-owner.service';
import { MessageManagerService} from '../../../service/MessageManager/messagemanager.service';
import { AuthService} from '../../../service/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShareCapitalCreateComponent } from '../share-capital-create/share-capital-create.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OwnerLoanCreateComponent } from '../owner-loan-create/owner-loan-create.component';
import { DataService } from 'src/app/service/EventEmitter/data.service';
@Component({
  selector: 'app-business-owner-create',
  templateUrl: './business-owner-create.component.html',
  styleUrls: ['./business-owner-create.component.css']
})
export class BusinessOwnerCreateComponent implements OnInit {
  private gridApi;
  private gridColumnApi;

  //     columnDefs = [
  //                 { headerName: 'Date', field: 'Date' },
  //                 { headerName: 'From', field: 'ChartOfAccount.AccountName' },
  //                 { headerName: 'Reference', field: 'Email' },
  //                 { headerName: 'Received Amount', field: 'TotalAmount'}
  //               ];
  // domLayout = 'autoHeight';
  //  defaultColDef = {
  //                 flex: 1,
  //               };
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private messageManagerService: MessageManagerService,
    private toaster: ToastrService,
    private ownerService: BusinessOwnerService,
    private modalService: NgbModal,
    private dataService: DataService,
    private authService: AuthService,
    private spinner: NgxSpinnerService) { }
    ownerForm: FormGroup;
    shareCapitalList: any = [];
    isShareActive = true;
    steps: number;
    ownerList: any = [];
    isOwnerCreated = false;
    isLoanActive = false;
    columnDefs = [
      { headerName: 'Name', field: 'Name' },
      { headerName: 'Mobile No', field: 'Phone' },
      { headerName: 'Email', field: 'Email' },
      { headerName: 'Role', field: 'RoleName' },
      { headerName: 'Share Capital', field: 'ShareCapital'},
      { headerName: 'Ownership', field: 'OwnerShipPercentage'},
      { headerName: 'Grant Loan', field: 'GrantLoan'},
      { headerName: 'Receive Loan', field: 'ReceiveLoan'}
    ];

domLayout = 'autoHeight';
defaultColDef = {
      flex: 1,

    };
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.steps = params.step ===  undefined ? 0 : 1;
      this.hideSidebar(this.steps);
    });

    this.initOwnerForm();
  }
  backToPreviousPage = () => {

    this.steps === 0 ?  this.router.navigate(['/owner/business-owner-list']) :
     this.router.navigate(['/settings/organization'], { queryParams: { step: 1 }  });
  }

  hideSidebar = (type) => {
    if (type == 1)
    {
      this.dataService.emitSidebarHide(false);
    }
  }
  initOwnerForm = () => {
    this.ownerForm = this.fb.group({
      Name : ['', Validators.required],
      CompanyId: [0],
      Email : ['', Validators.required],
      Phone : ['', Validators.required],
      RoleId : [ '', Validators.required],
      Address : ['', Validators.required],
      ShareCapital: [0],
      GrantLoan : [0],
      ReceiveLoan : [0],
      OwnerShipPercentage : [''],
      IsActive: [true],
      Id: [0]
    });
  }
  SaveOwnerInfo = () => {
     console.log(this.ownerForm.value.OwnerShipPercentage);
     if (this.ownerForm.invalid)
     {
       this.toaster.warning('Please fill all the required field !');
       return;
     }
     else if ((this.steps == 0) && ((this.ownerForm.value.OwnerShipPercentage == '') || (this.ownerForm.value.OwnerShipPercentage == null)))
     {
       this.toaster.warning('Please fill all the required field !');
       return;
     }
     this.ownerForm.value.OwnerShipPercentage ?
     this.ownerForm.get('OwnerShipPercentage').patchValue(this.ownerForm.value.OwnerShipPercentage) :
     this.ownerForm.get('OwnerShipPercentage').patchValue(0);
     this.spinner.show();
     this.ownerService.SaveBusinessOwner(this.ownerForm.value).subscribe((x) => {
      if (x.Success)
      {
        this.isOwnerCreated = true;
        this.spinner.hide();
        this.toaster.success('Business Owner created Suceessfully');
        this.getOwnerList();
        this.initOwnerForm();
      }
      else{
        this.toaster.error(x.Message);
      }
     }, err => {
       this.spinner.hide();
       this.toaster.error('ooh, something went wrong !');
  });
  }
  SaveAndContinue = () => {

    if (this.ownerForm.invalid)
    {
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    else if ((this.steps == 0) && ((this.ownerForm.value.OwnerShipPercentage == '') || (this.ownerForm.value.OwnerShipPercentage == null)))
    {
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    this.ownerForm.value.OwnerShipPercentage ?
    this.ownerForm.get('OwnerShipPercentage').patchValue(this.ownerForm.value.OwnerShipPercentage) :
    this.ownerForm.get('OwnerShipPercentage').patchValue(0);
    this.spinner.show();
    this.ownerService.SaveBusinessOwner(this.ownerForm.value).subscribe((x) => {
     if (x.Success)
     {
       this.spinner.hide();
       this.toaster.success('Business Owner created Suceessfully');
       this.router.navigate(['/owner/business-owner-list']);
       //this.UpdateStep();
     }
     else{
       this.toaster.error(x.Message);
     }
    }, err => {
      this.spinner.hide();
      this.toaster.error('ooh, something went wrong !');
 });
 }
 CancelAndContinue = () => {
  this.UpdateStep();
 }


  openShareTab = (status) => {
    this.isShareActive = status;
    this.isLoanActive = false;
  }
  openLoanTab = (status) => {
    this.isLoanActive = status;
    this.isShareActive = false;
  }

  public createNewItem = () => {
    const modalRef = this.modalService.open(ShareCapitalCreateComponent,
      { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.fromParent =  this.ownerForm.value.Id;
    modalRef.result.then((result) => {
      if (result.Success){
        this.getShareCapitalList();
      }
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }
  public createNewItems = () => {
    const modalRef = this.modalService.open(OwnerLoanCreateComponent,
      { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.fromParent =  this.ownerForm.value.Id;
    modalRef.result.then((result) => {
      if (result.Success){
        this.getShareCapitalList();
      }
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }
  getShareCapitalList = () => {
    this.ownerService.GetShareCapitalList(this.ownerForm.value.Id).subscribe((x) => {
      this.shareCapitalList = [...x, ...this.shareCapitalList];
    });
  }

// onGridReady(event)
// {
//     this.gridApi = event.api;
//     this.gridColumnApi = event.columnApi;
//     this.getShareCapitalList();
// }
onGridReady(event)
{
    this.gridApi = event.api;
    this.gridApi.setHeaderHeight(35);
    this.gridColumnApi = event.columnApi;
    this.getOwnerList();
}
getOwnerList(){
  this.spinner.show();
  this.ownerList = [];
  this.ownerService.GetOwnerList().subscribe((x) =>
   {
      this.spinner.hide();
      this.ownerList = [...x, ...this.ownerList];
      this.isOwnerCreated = this.ownerList.length > 0 ? true : false;
    },
    (err) =>
    {
      this.spinner.hide();
     });
    }
validateOwnerShip = () => {
  const value = this.ownerForm.value.OwnerShipPercentage;
  if (value > 100)
  {
    this.toaster.warning('Owenership can not exceed 100 % ');
    this.ownerForm.get('OwnerShipPercentage').patchValue(0);
    return;
  }
}
public UpdateStep() {
  this.spinner.show();
  this.messageManagerService.StepDone().subscribe(x => {
    this.spinner.hide();
    this.getMessage();
    this.router.navigate(['/accounting/bank-accounts'], { queryParams: { step: 1 }  });

  });
}
private getMessage(){
  this.spinner.show();
  this.messageManagerService.GetMessage().subscribe(x => {

    this.dataService.emitSetMessageUpdate(x);
    this.spinner.hide();
});
}

disallowSpace = (e) => {
  if (e.which === 32){
    console.log('Space Detected');
    return false;
}
}

}
