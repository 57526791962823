
<app-dashboard>
    <app-page-header-title
        mainTitle="{{itemListTitle}}"
        subTitle="Home"
        backURL="products/item-list/6">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
            <button type="button"  class="eCount-button3 ml-4" (click)="onBtnExport()">{{'Contacts.contactList.export'| translate}}</button>

        </div>
        
    </app-page-header-title>

    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('All')"
            [routerLinkActive]="['active']"
            routerLink="/inventory/delivery-order/All"
          >
            All
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('Waiting')"
            [routerLinkActive]="['active']"
            routerLink="/inventory/delivery-order/Waiting"
            >Waiting</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('Ready')"
            [routerLinkActive]="['active']"
            routerLink="/inventory/delivery-order/Ready"
            >Ready</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('Shipped')"
            [routerLinkActive]="['active']"
            routerLink="/inventory/delivery-order/Shipped"
            >Shipped</a
          >
        </li>
      </ul>

    <div class="headSection">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="allData"
                [columnDefs]="columnDefs"
                [domLayout] = "domLayout"
                [defaultColDef]="defaultColDef"
            ></ag-grid-angular>
        </div>
    </div>


    </app-dashboard>
