<app-dashboard>
    <app-page-header-title
        mainTitle="Transfer Money"
        subTitle="All Transfer Money"
        backURL="/transfermoney/transfermoney-list">
    
    </app-page-header-title>
    <div class="bodySection">
      
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-2 f-ss-14px col-form-label">From</label>
                <div class="col-sm-6">
                    <input class="form-control" type="text" [(ngModel)]="cashAccountName" disabled>
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-2 col-form-label">To</label>
                <div class="col-sm-6">
                    <input class="form-control" type="text" [(ngModel)]="bankAccountName" disabled>
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabel " class="col-sm-2 f-ss-14px col-form-label">Date</label>
                <div class="col-sm-6">
                    <input class="form-control" type="text" [(ngModel)]="date" disabled>
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-2 f-ss-14px col-form-label">Amount</label>
                <div class="col-sm-6">
                    <input type="text" appDecimaNumericValidator  [(ngModel)]="TransferMoneyForm.Amount" placeholder="Amount"  class="form-control" disabled/>
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabel" class="col-sm-2 f-ss-14px col-form-label">Reference</label>
                <div class="col-sm-6">
                    <input type="text" [(ngModel)]="TransferMoneyForm.ReferenceNo"  class="form-control" disabled/>
                </div>
            </div>
    </div>
</app-dashboard>
