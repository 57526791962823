import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssignedItem, Assignee } from 'src/app/models/assignee';
import { retry } from 'rxjs/operators';
import { ResponseModel } from 'src/app/models/responseModel.model';
import { ManufactureFormula, ManufactureOrder, OperationCostType } from 'src/app/models/manufacture.model';
import { ManufactureOrderProductionPageViewModel, ManufactureOrderViewModel } from 'src/app/models-viewModels/manufactureViewModel.model';
import { ManufactureOrderCreatePageViewModel } from 'src/app/models-viewModels/manufactureOrderCreatePageViewModel.model';
import { ManufactureFormulaDetailsPageViewModel } from 'src/app/models-viewModels/manufactureFormulaDetailsPageViewModel';
import { ManufactureOrderDetailsPageViewModel } from 'src/app/models-viewModels/manufactureOrderDetailsPageViewModel';
import { ManufactureOrderEditPageViewModel } from 'src/app/models-viewModels/manufactureOrderEditPageViewModel';

@Injectable({
  providedIn: 'root'
})
export class ManufactureService {

  baseurl = environment.baseUrl;
  constructor(
    private http: HttpClient
  ) { }

  public saveManufactureFormula(manufactureFormula: ManufactureFormula): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureFormula/SaveManufactureFormula';
    return this.http.post<ResponseModel>(url, manufactureFormula);
  }

  public approveManufactureFormula(manufactureFormula: ManufactureFormula): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureFormula/ApproveManufactureFormula';
    return this.http.post<ResponseModel>(url, manufactureFormula);
  }

  public updateManufactureFormula(manufactureFormula: ManufactureFormula): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureFormula/UpdateManufactureFormula';
    return this.http.post<ResponseModel>(url, manufactureFormula);
  }

  public deleteManufactureFormula(manufactureFormula: ManufactureFormula): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureFormula/DeleteManufactureFormula';
    return this.http.post<ResponseModel>(url, manufactureFormula);
  }

  public getFormulaList(): Observable<ManufactureFormula[]> {
    const url = this.baseurl +'ManufactureFormula/GetManufactureFormulas';
    return this.http.get<ManufactureFormula[]>(url);
  }

  public getFormula(formulaId: number): Observable<ManufactureFormulaDetailsPageViewModel> {
    return this.http.get<ManufactureFormulaDetailsPageViewModel>(this.baseurl + "ManufactureFormula/GetManufactureFormula?formulaId=" + formulaId).pipe(retry(1));
  }

  public getManufactureOrderList(): Observable<ManufactureOrder[]> {
    const url = this.baseurl +'ManufactureOrder/GetManufactureOrders';
    return this.http.get<ManufactureOrder[]>(url);
  }

  public getManufactureOrderProductionData(manufactureOrderId: number): Observable<ManufactureOrderProductionPageViewModel> {
    return this.http.get<ManufactureOrderProductionPageViewModel>(this.baseurl + "ManufactureOrder/GetManufactureOrderProductionData?manufactureOrderId=" + manufactureOrderId).pipe(retry(1));
  }

  public saveManufactureOrder(manufactureOrder: ManufactureOrder): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureOrder/SaveManufactureOrder';
    return this.http.post<ResponseModel>(url, manufactureOrder);
  }

  public approveManufactureOrder(manufactureOrder: ManufactureOrder): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureOrder/ApproveManufactureOrder';
    return this.http.post<ResponseModel>(url, manufactureOrder);
  }

  public updateManufactureOrder(manufactureOrder: ManufactureOrder): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureOrder/UpdateManufactureOrder';
    return this.http.post<ResponseModel>(url, manufactureOrder);
  }

  public deleteManufactureOrder(manufactureOrder: ManufactureOrder): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureOrder/DeleteManufactureOrder';
    return this.http.post<ResponseModel>(url, manufactureOrder);
  }

  public produceManufactureOrder(manufactureOrderViewModel: ManufactureOrderViewModel): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureOrder/ProduceManufactureOrder';
    return this.http.post<ResponseModel>(url, manufactureOrderViewModel);
  }

  public getManufactureData(): Observable<ManufactureOrderCreatePageViewModel> {
    const url = this.baseurl +'ManufactureOrder/GetManufactureData';
    return this.http.get<ManufactureOrderCreatePageViewModel>(url);
  }

  public getOrder(manufactureOrderId: number): Observable<ManufactureOrderDetailsPageViewModel> {
    return this.http.get<ManufactureOrderDetailsPageViewModel>(this.baseurl + "ManufactureOrder/GetManufactureOrder?manufactureOrderId=" + manufactureOrderId).pipe(retry(1));
  }

  public getOrderForEdit(manufactureOrderId: number): Observable<ManufactureOrderEditPageViewModel> {
    return this.http.get<ManufactureOrderEditPageViewModel>(this.baseurl + "ManufactureOrder/GetManufactureOrderForEdit?manufactureOrderId=" + manufactureOrderId).pipe(retry(1));
  }

  public addOperationCostType(operationCostType: OperationCostType): Observable<ResponseModel> {
    const url = this.baseurl +'ManufactureOrder/AddOperationCostType';
    return this.http.post<ResponseModel>(url, operationCostType);
  }

  public getOperationCostTypeList(): Observable<OperationCostType[]> {
    const url = this.baseurl +'ManufactureOrder/GetOperationCostTypes';
    return this.http.get<OperationCostType[]>(url);
  }

  public recalculateStatus(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(this.baseurl + "ManufactureOrder/RecalculateStatus");
  }

  public getManufactureOrderCounts(): Observable<any> {
    return this.http.get<any>(this.baseurl + "ManufactureOrder/GetManufactureOrderCounts");
  }

  getManufactureOrderByItemId(productId: number): Observable<any> {
    return this.http.get<any>(this.baseurl + `ManufactureOrder/GetManufactureOrderByItemId?productId=${productId}`).pipe(retry(1));
  }

}
