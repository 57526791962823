import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { BusinessOwnerService } from '../../../service/Business Owner/business-owner.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import moment = require('moment');
@Component({
  selector: 'app-share-capital-create',
  templateUrl: './share-capital-create.component.html',
  styleUrls: ['./share-capital-create.component.css']
})
export class ShareCapitalCreateComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    private router: Router,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    private ownerService: BusinessOwnerService,
    private spinner: NgxSpinnerService) { }
  public shareCapitalForm: FormGroup;
  public ShareCapitalItems: FormArray;
  bankList: any;
  subTotalAmount = 0;
  ifSubmit = false;
  @Input() fromParent;
  ngOnInit(): void {
    this.initializeForm();
    this.getBankList();
  }
  public addItem(): void {
    this.ShareCapitalItems = this.shareCapitalForm.get('ShareCapitalItems') as FormArray;
    this.ShareCapitalItems.push(this.createItem());
  }
  initializeForm() {
    this.shareCapitalForm = this.fb.group({
      Id: [0],
      OwnerId: [''],
      ReferenceNo: [],
      AccountId: ['', Validators.required],
      Date: [''],
      TotalAmount: [0],
      ShareCapitalItems:  this.fb.array([])
    });
    this.shareCapitalForm.get('Date').patchValue(new Date());
    this.shareCapitalForm.get('OwnerId').patchValue(this.fromParent);
    this.showSelectedDate(new Date());
    this.addItem();
  }
  public createItem(): FormGroup {
    return this.fb.group({
      Id: [0],
      SharedCapitalId: [ 0],
      Description: [''],
      Quantity: [0, Validators.required],
      Price: [0, Validators.required],
      LineTotal: [0, Validators.required],
      selected: [false]

    });
  }
  showSelectedDate(e) {
    const value = this.shareCapitalForm.value.Date;
    $('#itest1').on('change', function() {
      this.setAttribute(
          'data-date',
          moment(value, 'YYYY-MM-DD')
          .format( this.getAttribute('data-date-format') )
      );
  }).trigger('change');
  }
  updateTotalPrice = () => {
    let total = 0;
    this.ShareCapitalItems = this.shareCapitalForm.get('ShareCapitalItems') as FormArray;
    this.ShareCapitalItems.value.map((x, i) => {
      total = total + x.LineTotal;
    });
    this.shareCapitalForm.get('TotalAmount').patchValue(total.toFixed(2));
  }
  onFocusEvent(event: any){
    console.log('sadi');
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }
  removeInvoiceItem = (itemId, i) => {
    this.ShareCapitalItems = this.shareCapitalForm.get('ShareCapitalItems') as FormArray;
    this.ShareCapitalItems.value.map((x, index) => {
      if (index === i) {
        this.ShareCapitalItems.removeAt(index);
      }
    });
    this.updateTotalPrice();
  }
  updateDataItem = (itemId) => {
    this.ShareCapitalItems = this.shareCapitalForm.get('ShareCapitalItems') as FormArray;
    this.ShareCapitalItems.value.map((x, i) => {
        x.LineTotal = x.Quantity * x.Price ;
        this.ShareCapitalItems.controls[i].get('LineTotal').patchValue(x.LineTotal);
    });
    this.updateTotalPrice();
  }
  getBankList()
  {
    this.bankList = [];
    this.mainService.GetAllBank().subscribe((x) => {
      this.bankList = x;
    });
  }

  save = () => {

    this.shareCapitalForm.value.ShareCapitalItems = this.shareCapitalForm.value.ShareCapitalItems.filter(x => x.LineTotal > 0);
    if (this.shareCapitalForm.invalid)
    {
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    else if (this.shareCapitalForm.value.TotalAmount == 0)
    {
      this.toaster.warning('Total amount should be greater than 0 !');
      return;
    }
    this.spinner.show();
    this.ownerService.SaveShareCapital(this.shareCapitalForm.value).subscribe((x) => {
      if (x.Success)
      {
        this.spinner.hide();
        this.toaster.success('Share Capital added Successfully');
        this.activeModal.close(x);
      }
      else
      {
        this.spinner.hide();
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.spinner.hide();
      this.toaster.error('ooh, something went wrong !');
 });
  }
}

