<app-dashboard>
    <app-page-header-title
        mainTitle="Business unit"
        subTitle="Home"
        backURL="/">
    </app-page-header-title>

    <div>
        <div class="row">
            <p class="header ml-4">Add business unit</p><span class="fnt ml-2">*</span>
        </div>

        <form [formGroup]="buForm" class="d-flex w-100">
            <div class="col-sm-8 row">
                <div class="col-sm-4">
                    <input type="text" class="form-control" formControlName="Name" placeholder="Name" required>
                </div>
                <div class="col-sm-5">
                    <input type="text" class="form-control" formControlName="Address" placeholder="Address" required>
                </div>
                <div class="col-sm-3">
                    <button type="button" class="eCount-def-btn" (click)="save()">Save</button>
                </div>
            </div>
        </form>

        <div class="pt-3 ml-3">
            <ag-grid-angular
                class="ag-theme-alpine"
                style="width: 100%; height: 420px;"
                #topGrid id="topGrid"
                [rowData]="businessUnitList"
                [columnDefs]="columnDefs"
                [editType]="editType"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
                (cellClicked)="onCellClicked($event)">
            </ag-grid-angular>
        </div>
    </div>
    

</app-dashboard>