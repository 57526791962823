import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class BusinessPartnerLoanService {
  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  GetGrantLoan(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'BPLoan/GetGrantLoanList?typeId=' + id).pipe(retry(1));
  }

  // save  grant loan
  Save(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BPLoan/Save', data).pipe(retry(1));
  }
  SaveGrantSettleMent(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BPLoan/SaveGrantSettlement', data).pipe(retry(1));
  }
  SaveReceiveSettleMent(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BPLoan/SaveReceiveSettlement', data).pipe(retry(1));
  }
  GetAllContact(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Contact/GetAll').pipe(retry(1));
  }
  GetGrantLoanbyId(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'BPLoan/GetGrantLoanById?id=' + id).pipe(retry(1));
  }

  Update(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BPLoan/Update', data);
  }
  UpdateStatus(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BPLoan/UpdateStatus', data);
  }
}
