import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "./service/auth/auth.service";
import { DataService } from "./service/EventEmitter/data.service";
import { IncomeServiceService } from "./service/Income/income-service.service";
import { MainServiceService } from "./service/Main/main-service.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  isDefaultSetting: boolean = false;
  emailorPhone: string = null;
  regPath: string = null;
  isMobileNo: boolean = false;
  constructor(
    private dataService: DataService,
    private mainService: MainServiceService,
    private incomeService: IncomeServiceService,
    public translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
    translate.addLangs(["bn", "en"]);
    translate.setDefaultLang("bn");
    if (localStorage) {
      let lang = localStorage["language"];
      translate.use(lang ? lang : "bn");
    }
    this.dataService.checkDefault.subscribe(() => {
      this.findDefaultSettingAndLoggedIn();
    });
    if (this.authService.IsLoggedIn()) {
      this.findDefaultSettingAndLoggedIn();
    } else {
      this.isDefaultSetting = false;
    }
  }
  ngOnInit(): void {
    //QueryString Tafsir 25.11.21
    this.route.queryParams.subscribe((params) => {
      if (params.email) {
        this.emailorPhone = params.email;
      } else {
        this.emailorPhone = params.phone;
      }
      this.getQueryString(this.emailorPhone);
    });
  }
  getQueryString = (emailorPhone) => {
    if (emailorPhone) {
      this.validateEmailOrPhoneNo(emailorPhone);
      if (!this.isMobileNo) {
        this.regPath = `/auth/register?email=${emailorPhone}`;
      } else {
        this.regPath = `/auth/register?phone=${emailorPhone}`;
      }
      console.log(this.regPath);
    }
  };
  validateEmailOrPhoneNo = (emailorPhone) => {
    var emailPattern =
      /^[a-z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/;
    this.isMobileNo = emailorPhone.match(emailPattern) ? false : true;
  };
  checkAuthRoute = () => {
    if (
      this.router.url == "/auth/login" ||
      this.router.url == "/auth/reset-password" ||
      this.router.url == "/auth/forgot" ||
      this.router.url == "/auth/register" ||
      this.router.url == this.regPath ||
      this.removeParam(this.router.url) == "/accept-invitation" ||
      this.findSubRoute(this.router.url) == "preview" ||
      this.findSubRoute(this.router.url) == "print"
    ) {
      return true;
    } else {
      return false;
    }
  };

  previewInvoiceBill() {
    return false;
  }

  removeParam = (parameter) => {
    return parameter.split("?")[0];
  };

  findSubRoute = (param) => {
    return param.split("/")[2];
  };

  findDefaultSettingAndLoggedIn() {
    // var userInfo = this.authService.getUserInfo();
    // if(userInfo != (undefined || null)) {
    //   this.mainService.getSelectedOrganizationDetails(userInfo.SelectedCompany.CompanyId).subscribe((x) => {
    //     this.isDefaultSetting = x.HasTransaction == true ? false : true;
    //   });
    // }else{
    //   this.isDefaultSetting = false;
    // }
  }
}
