<div class="modal-header">
    <div>
        <h4 class="modal-title" style="font-size: 17px;">View Shipment Item</h4>
        <h4 style="font-size: 17px;" *ngIf="statusId==1">Status: <span style="color: #d2d3d1;">Draft</span></h4>
        <h4 style="font-size: 17px;" *ngIf="statusId==2">Status: <span style="color: #5cac00;">Shipped</span></h4>
        <h4 style="font-size: 17px;" *ngIf="statusId==3">Status: <span style="color: #898989">Cancelled</span></h4>
    </div>
    <div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
    </div>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <form [formGroup]="shipmentForm" class="f-ss-14px">
        <div class="pb-4">
            <div class="col-md-12">
                <div style="margin-top: 25px;" class="row">
                    <div class="invoice-grid col-md-12" id="my" >
                        <div class="d-flex">
                            <div class="col-md-3">Item</div>
                            <div class="col-md-3">Description</div>
                            <div class="col-md-3">Quantity</div>
                            <div class="col-md-3">Amount</div>
                        </div>
                        <hr class="pb-0 mb-0" />
                        <div formArrayName="OrderShipmentItem" *ngFor="let item of shipmentForm.get('OrderShipmentItem')['controls']; let i = index;">
                            
                            <div class="invoiceGridItemRow d-block">

                            <div class="row" [formGroupName]="i" style="margin-top: 10px;">
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control"
                                        type="text"
                                        formControlName="ItemName"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control"
                                        type="text"
                                        formControlName="Description"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control"
                                        formControlName="Quantity"
                                        />
                                    </div>
                                    <div class="col-md-3">
                                        <input
                                        readonly
                                        class="form-control"
                                        formControlName="TotalAmount"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </form>
</div>
<hr>
<div class="modal-footer">
</div>