import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup,FormArray, Validators, FormControl } from '@angular/forms';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ItemCreateComponent } from '../../Products/item-create/item-create.component';
import { CreateItem } from 'src/app/models/product.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';
import { CreatePopupItemComponent } from '../../Products/create-popup-item/create-popup-item.component';
import { IncomeServiceService } from 'src/app/service/Income/income-service.service';
import { ItemType } from 'src/app/enum/item-type';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';

@Component({
  selector: 'app-sales-order-edit',
  templateUrl: './sales-order-edit.component.html',
  styleUrls: ['./sales-order-edit.component.css'],
  host: {
    '(document:click)': 'onWindowClick($event)',
  },
})
export class SalesOrderEditComponent implements OnInit {

  subTotalAmount:number=0;
  orderDate:Date;
  deliveryDate:Date;
  AdvanceTaxAmount: number = 0.00;
  TaxAmount: number = 0.00;
  totalPrice: number = 0.00;
  costAccountId: number=0;
  totalFile: number=0;
  public salesOrderForm: FormGroup;
  public SalesOrderItem: FormArray;
  productList=[];
  taxTypeList=[];
  supplierList=[];
  accountList=[];
  taxRateList=[];
  SalesOrderId: string;
  totalCost:number=0;
  contactIdRequired:boolean = true;
  loading = false;
  unitPriceRequired:boolean = true;
  deliveryDateRequired:boolean = true;
  descriptionRequired:boolean = true;
  qtyRequired:boolean = true;
  ShowSalesOrderNo: string;
  OrderNo: string = "";
  SalesOrderNo: any;
  SalesOrderItems = [];
  public aModel : CreateItem= new CreateItem();
  salesOrderData: any;
  
  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder,
    private fileEmitterService:FileEmitterService,
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainServiceService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private settingService:SettingsServiceService,
    private accountingService: AcountingServiceService,
    private pService: SalesOrderService,
    private incomeService: IncomeServiceService
    ) { }

  ngOnInit(): void {
    this.SalesOrderId = this.route.snapshot.paramMap.get("id");
    this.initializeForm();
    this.getMasterDataList();
  }
  getOrderDetails = (id:any) => {
    this.pService.GetOrderDetails(id).subscribe((x) => {
      if (x) {
        this.salesOrderData = x.data;
        this.SalesOrderItems = x.data.SalesOrderItem;
        this.setRawItem(x.data);
        this.SalesOrderItems.forEach((element, i) => {
          let item = x.item.find(x => x.Id == element.ItemId);
          element.ItemTypeId = item != null ? item.ItemTypeId : null;
          element.IsDescriptiveOrServiceItem = this.IsDescriptiveOrServiceTypeItem(element);
          this.addItem(x, element, i);
          this.totalCost += (element.UnitPrice * element.Quantity);
        });
        this.IsAllItemsDescriptiveOrService();
      }
    })
  }

  isDescriptiveSalesOrder() {
    return this.SalesOrderItems.every( x => x.ItemId == null );
  }

  initializeForm() {
    this.salesOrderForm = this.fb.group({
      Id: [0],
      CompanyId:[''],
      OrderNo: [''],
      OrderDate: ['', Validators.required],
      QuotationId:[''],
      ReferenceNo: [''],
      SalesRepresentative: [''],
      ContactId: [null, Validators.required],
      DeliveryDate: ['', Validators.required],
      OrderAmount: [''],
      OrderQty: [''],
      CreatedAt: [''],
      UpdatedAt: [''],
      CreatedById: [''],
      UpdatedById: [''],
      StatusId: [''],
      TaxTypeId: [''],
      TaxAmount: [''],
      AdvanceTaxAmount: [''],
      Notes: [''],
      SalesOrderItem: this.fb.array([])
    });
    this.getOrderDetails(this.SalesOrderId);
  }
  public setRawItem(x:any): void {console.log(x)
    this.fileEmitterService.emitFileRefIdUpdate({id:x.Id});
      this.subTotalAmount=x.OrderAmount-x.TaxAmount-x.AdvanceTaxAmount;
      this.TaxAmount = x.TaxAmount;
      this.AdvanceTaxAmount = x.AdvanceTaxAmount;
      this.totalPrice = x.OrderAmount;
      this.orderDate = x.OrderDate;
      this.deliveryDate = x.DeliveryDate;
      this.SalesOrderNo = x.OrderNo;
      this.generateSalesNo();
      this.forMatDate();
      this.salesOrderForm.controls['Id'].patchValue(x.Id);
      this.salesOrderForm.controls['CompanyId'].patchValue(x.CompanyId);
      //this.salesOrderForm.controls['OrderNo'].patchValue(this.OrderNo);
      this.salesOrderForm.controls['OrderDate'].patchValue(x.OrderDate);
      this.salesOrderForm.controls['QuotationId'].patchValue(x.QuotationId);
      this.salesOrderForm.controls['ReferenceNo'].patchValue(x.ReferenceNo);
      this.salesOrderForm.controls['SalesRepresentative'].patchValue(x.SalesRepresentative);
      this.salesOrderForm.controls['ContactId'].patchValue(x.Contacts.Id);
      this.salesOrderForm.controls['DeliveryDate'].patchValue(x.DeliveryDate);
      this.salesOrderForm.controls['OrderAmount'].patchValue(x.OrderAmount);
      this.salesOrderForm.controls['OrderQty'].patchValue(x.OrderQty);
      this.salesOrderForm.controls['CreatedAt'].patchValue(x.CreatedAt);
      this.salesOrderForm.controls['UpdatedAt'].patchValue(x.UpdatedAt);
      this.salesOrderForm.controls['CreatedById'].patchValue(x.CreatedById);
      this.salesOrderForm.controls['UpdatedById'].patchValue(x.UpdatedById);
      this.salesOrderForm.controls['StatusId'].patchValue(x.StatusId);
      this.salesOrderForm.controls['TaxTypeId'].patchValue(x.TaxTypeId);
      this.salesOrderForm.controls['TaxAmount'].patchValue(x.TaxAmount);
      this.salesOrderForm.controls['AdvanceTaxAmount'].patchValue(x.AdvanceTaxAmount);
      this.salesOrderForm.controls['Notes'].patchValue(x.Notes);
    }

    generateSalesNo = () => {
        let val = this.SalesOrderNo.toString(); let pre = "", post = "";
        for (let i = 0; i < 6; i++) pre += val[i].toString();
        for (let i = 6; i < 10; i++) post += val[i].toString();
        this.salesOrderForm.get('OrderNo').patchValue(post);
        this.OrderNo = post;
        this.ShowSalesOrderNo = 'SO-' + pre;
    }

  public setTotalFile(tf:number) {
    this.totalFile=tf;
  }
  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id =="Preview")) {

    }
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown()
    }
    else {
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }
  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }
  getMasterDataList = () => { 
    this.getAllSupplier();
    this.getTaxTypeList();
    this.getTaxRateList();
    this.getItems();
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      this.accountList = x.ExpenseList;
      this.costAccountId = this.accountList?.filter(x => x.AccountName == "200 - Sales")[0]?.Id;
      const myForm = (<FormArray>this.salesOrderForm?.get("SalesOrderItem"))?.at(0);
        myForm?.patchValue({ AccountId: this.costAccountId });
    });
  }
  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
      if(this.taxTypeList.length>=2){
        this.salesOrderForm.controls['TaxTypeId'].patchValue(this.taxTypeList[2].Id);
      }
    });
  }
 private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({"Id":"","Name":"Select"});
    });
  }
  public createItem(fullData:any, x:any, i:number): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      OrderId: [x.OrderId],
      ItemId: [x.ItemId],
      ItemTypeId: [x.ItemTypeId],
      IsDescriptiveOrServiceItem: [x.IsDescriptiveOrServiceItem],
      Description:[x.Description],
      Quantity: [x.Quantity],
      UomId: [x.UomId],
      UnitPrice: [x.UnitPrice],
      LineTotal: [x.Quantity*x.UnitPrice],
      TaxRateId: [x.TaxRateId],
      TaxRatePercent: [x.TaxRatePercent],
      AdvanceTaxRateId: [x.AdvanceTaxRateId],
      AdvanceTaxPercent: [x.AdvanceTaxPercent],
      AccountId: [x.AccountId],
      ShippedItems: [x.IsDescriptiveOrServiceItem == true ? "-" : fullData.data.ShippedItems[i]]
    });
  }

  public addItem(fullData:any, x:any, i:number): void {
    this.SalesOrderItem = this.salesOrderForm.get('SalesOrderItem') as FormArray;
    this.SalesOrderItem.push(this.createItem(fullData, x, i));
  }
  public addBlankItem(): void {
    this.SalesOrderItem = this.salesOrderForm.get('SalesOrderItem') as FormArray;
    this.SalesOrderItem.push(this.createBlankItem());
  }
  public createBlankItem(): FormGroup {
    return this.fb.group({
      Id: 0,
      OrderId: [this.SalesOrderId],
      ItemId: [''],
      ItemTypeId: [null],
      IsDescriptiveOrServiceItem: [null],
      Description:['',Validators.required],
      Quantity: ['',Validators.required],
      UomId: [''],
      UnitPrice: ['',Validators.required],
      LineTotal: [0],
      TaxRateId: [''],
      TaxRatePercent: [''],
      AdvanceTaxRateId: [''],
      AdvanceTaxPercent: [''],
      AccountId: [this.costAccountId],
      ShippedItems: [null]
    });
  }
  public deleteItem(i:number){
    this.SalesOrderItem.removeAt(i);
    this.updateTotalPrice();
  }

  /**
   * updatePrice
   */

   public updatePrice(i:number,item:any){
    this.salesOrderForm.value.SalesOrderItem.map((x) => {
      if (this.IsDescriptiveOrServiceTypeItem(x) == true)
      {
        item.controls['ShippedItems'].patchValue('-');
      }
      else if (x.ItemId == item.value.ItemId)
      {
        item.controls['ShippedItems'].patchValue(x.ShippedItems);
        item.controls['LineTotal'].patchValue(item.value.Quantity * item.value.UnitPrice);
      }
    })
     this.updateTotalPrice()
  }

  updateTotalPrice = () => {
    this.subTotalAmount = 0.00;
    this.TaxAmount=0.00;
    this.AdvanceTaxAmount=0.00;
    this.salesOrderForm.value.SalesOrderItem.map((x) => {
      this.subTotalAmount = this.subTotalAmount +this.AdvanceTaxAmount + x.LineTotal;
      this.TaxAmount+=this.getItemTaxAmount(x.LineTotal,x.TaxRateId);
      this.AdvanceTaxAmount+=this.getItemTaxAmount(x.LineTotal,x.AdvanceTaxRateId);
    });
    switch(this.salesOrderForm.value.TaxTypeId){
      case 1: //tax exclusive
        this.totalPrice = this.subTotalAmount+this.TaxAmount+this.AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        this.totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
       this.totalPrice = this.subTotalAmount;
        break;
    }
    if (this.salesOrderForm.value.TaxTypeId === 3)
    {
      this.checkTaxType();
    }

  }

  checkTaxType = () => {

    this.SalesOrderItem = this.salesOrderForm.get('SalesOrderItem') as FormArray;
    this.SalesOrderItem.value.map((x, i) => {

      const myForm = (<FormArray>this.salesOrderForm.get("SalesOrderItem")).at(i);
      myForm.patchValue({
        TaxRateId: null,
        AdvanceTaxRateId: null
      });
    });
  }
  private getItemTaxAmount(lineTotal:number,taxRateId:any) {
    let taxPrice=0.00;
    if(taxRateId=="" || taxRateId==null)
     return taxPrice;

     var taxPercent=this.getTaxRatePercent(taxRateId);
     switch(this.salesOrderForm.value.TaxTypeId){
      case 1: //tax exclusive
      taxPrice= lineTotal*taxPercent/100;
        break;
      case 2: //tax inclusive
      taxPrice= Number(((lineTotal*taxPercent)/(100+taxPercent)).toFixed(2));
        break;
      default: //no tax
      taxPrice=0
        break;
    }
    return taxPrice;
  }
  private getTaxRatePercent(taxRateId:any) {
    let taxRateObj = this.taxRateList.filter(x=>x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }

  async checkSalesOrderNo(){
    var val = this.salesOrderForm.value.OrderNo.toString();
    if(val=='' || val==null){
      this.toaster.error('Please Input Sales Order No.!');
      return false;
    }

    if(val.length!=4){
      this.toaster.error('Sales Oder Number needs to be 4 digits.');
      return false;
    }

    return true;
  }

  /**
   * saveAsDraft
   */

  public async saveAsAwaitingApproval() {
    if(! await this.checkSalesOrderNo()) return;
    if (this.salesOrderForm.invalid){
      this.contactIdRequired = false;
      this.deliveryDateRequired = false;
      this.descriptionRequired = false;
      this.qtyRequired = false;
      this.unitPriceRequired = false;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }

    this.salesOrderForm.controls['TaxAmount'].patchValue(this.TaxAmount);
    this.salesOrderForm.controls['AdvanceTaxAmount'].patchValue(this.AdvanceTaxAmount);
    this.salesOrderForm.controls['OrderAmount'].patchValue(this.totalPrice);

    this.spinner.show();
    let preValue = "";
    let temp = this.salesOrderForm.value.OrderNo;
    for (let i = 3; i < this.ShowSalesOrderNo.length; i++) preValue += this.ShowSalesOrderNo[i].toString();
    this.salesOrderForm.value.OrderNo = preValue + this.salesOrderForm.value.OrderNo.toString();
    const data = this.salesOrderForm.value;
    data.IQuidiActionType= iQuidiActionType.Save;
    this.pService.Update(data).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Saved successfully.');
        this.router.navigate(['sales/sales-order-list/All']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  /**
   * saveAsApproved
   */
  public async saveAsApproved(operationType: number) {
    if(! await this.checkSalesOrderNo()) return;
    if (this.salesOrderForm.invalid){
      this.contactIdRequired = false;
      this.deliveryDateRequired = false;
      this.descriptionRequired = false;
      this.qtyRequired = false;
      this.unitPriceRequired = false;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    this.salesOrderForm.controls['TaxAmount'].patchValue(this.TaxAmount);
    this.salesOrderForm.controls['AdvanceTaxAmount'].patchValue(this.AdvanceTaxAmount);
    this.salesOrderForm.controls['OrderAmount'].patchValue(this.totalPrice);
    
    this.spinner.show();
    let preValue = "";
    let temp = this.salesOrderForm.value.OrderNo;
    for (let i = 3; i < this.ShowSalesOrderNo.length; i++) preValue += this.ShowSalesOrderNo[i].toString();
    this.salesOrderForm.value.OrderNo = preValue + this.salesOrderForm.value.OrderNo.toString();
    const data = this.salesOrderForm.value;
    if(operationType == iQuidiActionType.Approve) data.IQuidiActionType= iQuidiActionType.Approve;
    else data.IQuidiActionType= iQuidiActionType.Update;
    this.pService.Update(data).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Approved successfully.');
        this.router.navigate(['sales/sales-order-list/OnGoing']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : true,
      IsSupplier : false,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
  
        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.supplierList.push(x["Data"]);
        let customerObj = this.supplierList.filter(x=>x.PrimaryPersonFirstName == customerName);
        
        this.salesOrderForm.controls['ContactId'].patchValue(customerObj[0]['Id']);
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }
  getAllSupplier = () => {
    this.pService.GetSupplierList().subscribe((x) => {
      this.supplierList=x;
    });
  }
  show(e) {
    const value = this.salesOrderForm.value.OrderDate;
    $('#itest1').attr('min', value.split('T')[0]);
    $("#itest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  showSelectedDate(e) {
    const value = this.salesOrderForm.value.DeliveryDate;
    $("#itest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
  forMatDate() {
    this.salesOrderForm.controls['OrderDate'].patchValue(this.orderDate);
    this.show(this.orderDate);
    this.salesOrderForm.controls['DeliveryDate'].patchValue(this.deliveryDate);
    this.showSelectedDate(this.deliveryDate);
  }


  changespurchaseableItems = (index) => {

    const myForm = (<FormArray>this.salesOrderForm.get("SalesOrderItem")).at(index);
   
   for (let i = 0; i < this.productList.length; i++) {
     if (this.productList[i].Id == this.salesOrderForm.value.SalesOrderItem[index]['ItemId']) {
           myForm.patchValue({
             ItemId: this.productList[i]['Id'],
             ItemTypeId: this.productList[i]['ItemTypeId'],
             IsDescriptiveOrServiceItem: this.IsDescriptiveOrServiceTypeItem(this.productList[i]),
             ShippedItems: this.IsDescriptiveOrServiceTypeItem(this.productList[i])  == true ? '-' : 0,
             Description:this.productList[i]['PurchaseDescription'],
             AccountId:this.productList[i]["IsTrackItem"] == true ? this.productList[i]['InventoryAccountId'] : this.productList[i]['PurchaseAccountId'],
             Quantity:1,
             UnitPrice:this.productList[i]['SaleUnitPrice'],
             LineTotal:this.productList[i]['SaleUnitPrice']
           })
     }
   }
   this.updateTotalPrice();
 }
 openItemModal(selectItem: NgSelectComponent){
   selectItem.close();
   const modalRef = this.modalService.open(CreatePopupItemComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
   modalRef.componentInstance.refId = 2;
   modalRef.result.then((result) => {
     this.getItems();
   }, (reason) => {
     console.log('Dismissed action: ' + reason);
   });
   }
   private getItems =()=>{
    this.incomeService.GetAllSaleableItem().subscribe((x) => {
      this.productList=x;
    });
  }

  isShippedMaxOrMin(i){
    let Quantity = this.SalesOrderItem?.controls[i]?.get("Quantity")?.value;
    let ShippedItem = this.SalesOrderItem?.controls[i]?.get("ShippedItems")?.value;
    if(Quantity != ShippedItem && ShippedItem != 0){
      return true;
    }
    else{
      return false;
    }
  }

  IsAllItemsDescriptiveOrService() {
    let salesItems = this.salesOrderForm.get("SalesOrderItem") as FormArray;
    let items = salesItems.value;
    let isAllAreDescriptiveAndServiceItems: boolean = items.filter( x => x.ItemId == null || x.ItemId == '' || x.ItemTypeId == ItemType.Service).length == items.length ? true : false;
    return isAllAreDescriptiveAndServiceItems;
  }

  IsDescriptiveOrServiceTypeItem(item) {
    let itemId = item.ItemId == undefined ? item.Id : item.ItemId
    let isDescriptiveItem: boolean = itemId == null || itemId == '' ? true : false;
    let isServiceItem: boolean = item.ItemTypeId == ItemType.Service ? true : false;
    return isDescriptiveItem || isServiceItem ? true : false;
  }

}

