export class AssetTypeModel{
    Id: number =0;
    AssetType: string;
    AssetAccountId: number;
    DepreciationAccountId: number;
    DepreciationExpenseAccountId: number;
    DepreciationMethodId: number;
    AveragingMethodId:number;
    RatePercent:number;
    EffectiveLifeYears:number;
    IsRatePercent:boolean= true;
}

export class AssetItemModel{
    Id: number = 0;
    AssetTypeId: number;
    AssetTypeName: string;
    AssetName: string;
    AssetNumber: string;
    PurchaseDate: Date = null;
    PurchasePrice:number;
    WarrantyExpiryDate:Date = null;
    SerialNo:string;
    Description:string;
    DepreciationStartDate:Date = null;
    DepreciationMethodId:number;
    AveragingMethodId:number;
    RatePercent:number;
    EffectiveLifeYears:number;
    StatusId:number;
    StatusName:string;
    PurchaseDateVw:string;
    WarrantyExpiryDateVw:string;
    AssetAccountName:string;
    DepreciationAccountName:string;
    ExpenseAccountName:string;
    IsRatePercent:boolean= true;
    TypeDetails: AssetTypeModel = new AssetTypeModel()
}

export class FixedAssetItem{
    Id: number = 0;
    AssetTypeId: number;
    AssetTypeName: string;
    AssetName: string;
    AssetNumber: string;
    PurchaseDate: Date = null;
    PurchasePrice:number;
    WarrantyExpiryDate:Date = null;
    SerialNo:string;
    Description:string;
    DepreciationStartDate:Date = null;
    DepreciationMethodId:number;
    AveragingMethodId:number;
    RatePercent:number;
    EffectiveLifeYears:number;
    StatusId:number;
    StatusName:string;
    PurchaseDateVw:string;
    WarrantyExpiryDateVw:string;
    AssetAccountName:string;
    DepreciationAccountName:string;
    ExpenseAccountName:string;
    IsRatePercent:boolean= true;
    TypeDetails: AssetTypeModel = new AssetTypeModel()
}

export class FixedAssetSetting {
    Id: number;
    CompanyId: number;
    StartDate: Date;
    LastUpdatedAt: Date;
    LastUpdatedById?: string;
}