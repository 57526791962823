<app-dashboard>
    <app-page-header-title mainTitle="Pending Factory List" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <input  type="text"  placeholder="search" class="searchBox"  >
            <button type="button" (click)="getSelectedRowData()"
                class="eCount-def-btn ml-4" >Approve</button>
            <button 
                class="eCount-button3 ml-4">Export</button>
        </div>
        
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item ">
          <a class="nav-link f-ssb-14px nav-option" (click)="reloadRowData('factory-in')" [routerLinkActive]="['active']" routerLink="/inventory/pending-list/factory-in">Factory In</a>
        </li>
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reloadRowData('factory-out')" [routerLinkActive]="['active']" routerLink="/inventory/pending-list/factory-out" >Factory Out</a>
        </li>
    </ul>
    <div class="row pt-3">
        <ag-grid-angular class="ag-theme-balham col"  style="font-size: 14px;"
        class="ag-theme-balham col"
        [rowData]="data"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        (cellClicked)="onCellClicked($event)"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</app-dashboard>
