<div class="headSection">
    <form [formGroup]="modalForShiftForm" >
    <div class="modal-header">
        <h4 class="modal-title">Availability</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-borderless">
            <thead >
              <tr>
                <th colspan="2"></th>
                <th>Availbale Stock</th>
                <th colspan="4"></th>
                <th>Out Of Stock</th>
                <th colspan="2"></th>


              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of  modalShowData">
                <td colspan="2"></td>
                <td>{{data.itemName}} = {{data.itemStock}}</td>
                <td colspan="4"></td>
                <td>{{data.itemName}} = {{data.itemDemand}}</td>
                <td colspan="2"></td>
              </tr>

            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2"></td>
                    <td>Total = {{totalStock}}</td>
                    <td colspan="4"></td>
                    <td>Total = {{totalDemand}}</td>
                    <td colspan="2"></td>
                  </tr>
            </tfoot>
          </table>

        <div class="form-check">
          <input class="form-check-input" type="radio" name="BtnOne" id="opt1" formControlName="BtnOne"  value="1">
          <label class="form-check-label" for="opt1">
                Deliver available products in one shipment and out of stock products in separate shipment.
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="BtnOne" id="opt2" formControlName="BtnOne" value="2">
            <label class="form-check-label" for="opt2">
                Deliver only available products.
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="BtnOne" id="opt3" formControlName="BtnOne" value="3">
            <label class="form-check-label" for="opt3">
                Deliver all products in same shipment.
            </label>
          </div>

    </div>

    <div class="modal-footer">
        <a><p><button class="btn btn-sm btn-success" [disabled]="setDisable" (click)="approve()">Approve</button></p></a>
    </div>
    </form>
    </div>
