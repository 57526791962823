<div>
   <button type="button" class="close mt-2 mr-2" aria-label="Close" (click)="dismiss()">
     <span aria-hidden="true">&times;</span>
   </button>
 </div>
 <div class="modal-header mrt mt-0">
     <h4 class="modal-title txt" *ngIf="TypeId == 1">Do You Want to reject this bill ?</h4>
     <h4 class="modal-title txt" *ngIf="TypeId == 2">Do You Want to reject this Invoice ?</h4>
     </div>
     <div class="modal-body mrt">
      <p>
           <span *ngIf="TypeId == 1"> Bill No: {{ this.data.BillNo }} </span>
           <span *ngIf="TypeId == 2"> Invoice No: {{ this.data.InvoicceNo }} </span>
      </p>
      <div>
          <label for="" class="font-weight-bold">Note</label><span class="text-red rf">*</span>
          <input type="text" [(ngModel)]="RejectReason" class="form-control input-width">
      </div>
      <div>
        <div class="sub_section_content_2 mt-1" >
          <label>Signature <span style="color: red;">*</span></label>
          <div class="das mla row m-1" *ngIf="imageURL">
            <div class="col-md-3 ml-0 pl-0"> </div>
            <div class="imagePreview col-md-6" *ngIf="imageURL">
              <img [src]="imageURL" [alt]="" class="mt-2" style="height: 120px; width: 250px;">
            </div>
          </div>
          <div *ngIf="!imageURL" >
            <p class="red" >Please provide an image of your signature in the profile page.</p>
          </div>
        </div>
      </div>
  
     </div>
     <div class="modal-footer">
       
       <button type="button" class="eCount-def-btn" (click)="accept()">Confirm</button>
       <button type="button" class="cancel" (click)="decline()">Cancel</button>
     </div>  