<div class="modal-header">
    <h4  class="modal-title" style="font-size: 17px;">Edit Make Payment</h4>
   
    <button type="button" class="close" aria-label="Close"(click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
<div class="row pr-2 pl-2">
    <div class="col-md-12">
        <div>
            <form [formGroup]="EditMakePaymentForm" class="f-ss-14px" (ngSubmit)="EditMakePayment()" [ngClass]="{'submitted':ifSubmit===true}" required>
                <div class="form-group">
                    <span class="formTitle">Date Paid</span>
                    <input class="form-control" type="date" formControlName="PaidDate" id="ebttest1" data-date="" data-date-format="D MMM YYYY" (change)="show($event)">
                </div>
                <div class="form-group">
                    <span class="formTitle">Amount Paid</span>
                    <input type="number" formControlName="PaidAmount" class="form-control mt-1"  placeholder="Amount Paid" disabled>
                </div>
                <div class="form-group">
                    <span class="formTitle">Paid Form</span>
                    <ng-select [items]="bankAccounts" formControlName="AccountId" [clearable]="false" (change)="getEditPaymentModeList(EditMakePaymentForm.value.AccountId)" bindLabel="AccountName" bindValue="GLAccountId"></ng-select>
                </div>
                <div class="form-group" *ngIf="accountTypeId==0">
                    <span class="formTitle">Payment Mode</span>
                    <ng-select [items]="PaymentModeList" formControlName="PaymentModeId"  bindLabel="Name" bindValue="Id" [clearable]="false"></ng-select>
                </div>
                <div class="form-group">
                    <span class="formTitle">Reference No</span>
                    <input type="text" formControlName="ReferenceNo" class="form-control mt-1"  placeholder="Reference No :  eg #reference" disabled>
                </div>
                <div class="form-group" *ngIf="accountTypeId==0">
                    <span class="formTitle">Account Name</span>
                    <input type="text" formControlName="SourceAccountName" class="form-control mt-1"  placeholder="" disabled>
                </div>
                <div class="form-group" *ngIf="accountTypeId==0">
                    <span class="formTitle"> Account No.</span>
                    <input type="text" formControlName="SourceAccountNo" class="form-control mt-1"  placeholder="" disabled>
                </div>
                <div class="form-group" *ngIf="accountTypeId==0">
                    <span class="formTitle">Bank Name</span>
                    <input type="text" formControlName="SourceBankName" class="form-control mt-1"  placeholder="" disabled>
                </div>
                <div class="form-group pt-4" style="float: right;">
                    <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4">Payment</button>
                </div>
            </form>

        </div>
    </div>
</div>

</div>
