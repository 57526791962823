<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">Add New Advance</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="advanceForm" class="f-ss-14px">

                    <div class="row">                    
                        <div class="form-group  col-md-6">
                        <label for="Name" style="font-weight: 600;">Advance Type<span
                            class="required-sign">*</span></label>
                            <select required class="form-control"  style="width: 100%;" formControlName="TypeId">
                                <option *ngFor="let s of advanceTypeList" [ngValue]="s.Id">{{s.Name}}</option>
                            </select>
                    </div>
                    <div class="form-group  col-md-6">
                        <label for="Name" style="font-weight: 600;">Date<span
                            class="required-sign">*</span></label>
                        <input type="date" class="form-control form-input" formControlName="TransactionDate" id="adstest" data-date="" data-date-format="D MMM YYYY" (change)="showDefaultDate($event)" required>
                    </div>
                </div>
                <div class="row">                    
                  
             
                <div class="form-group  col-md-6">
                    <label for="Name" style="font-weight: 600;">Amount<span
                        class="required-sign">*</span></label>
                        <input type="text"  appDecimaNumericValidator class="form-control form-input"  formControlName="Amount" required>
                </div>
                <div class="form-group  col-md-6">

                    <label for="Name" style="font-weight: 600;">Account<span
                        class="required-sign">*</span></label>
                        <select required class="form-control" style="width: 100%;" formControlName="AccountId">
                            <option *ngFor="let s of accountList" [ngValue]="s.GLAccountId">{{s.AccountName}}</option>
                        </select>
                </div>
                
            </div>

        <div class="row">                    
            <div class="form-group  col-md-6">
                <label for="Name" style="font-weight: 600;">Description<span
                    class="required-sign">*</span></label>
                   <textarea class="form-control form-input" style="height: 38px;" formControlName="Description" required></textarea>
            </div>
        
        </div>

        </form>
    </div>
            <div class="col-md-12 text-align-right">

                <button type="button" (click)="save()"
                    class="eCount-def-btn  ml-4 p-save-button">Save</button>

            </div>
        </div>
    </div>
</div>
