import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { RouterLinkRendereComponent } from "src/app/components/router-link-rendere/router-link-rendere.component";
import { FileEmitterService } from "src/app/service/EventEmitter/file.emitter.service";
import { IncomeServiceService } from "src/app/service/Income/income-service.service";
import { SalesOrderService } from "src/app/service/SalesOrder/sales-order.service";

@Component({
  selector: "app-sales-order-invoice-details",
  templateUrl: "./sales-order-invoice-details.component.html",
  styleUrls: ["./sales-order-invoice-details.component.css"],
})
export class SalesOrderInvoiceDetailsComponent implements OnInit {
  gridApi;
  salesInvoices: any = [];
  SalesOrderData: any = [];
  totalInvoice: number = 0;
  totalNormalInvoiceAmount: number = 0;
  totalReverseInvoiceAmount: number = 0;
  totalUnInvoice: number = 0;
  SalesOrderId: string;
  orderNo: any;
  SalesOrderNo: string;
  showInvoice = false;
  isClosed: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private salesService: SalesOrderService,
    public fileEmitterService: FileEmitterService,
    private incomeService: IncomeServiceService
  ) {}

  ngOnInit(): void {
    this.SalesOrderId = this.route.snapshot.paramMap.get("id");
    this.orderNo = this.route?.snapshot?.paramMap?.get("orderNo");
    this.getOrderDetails(this.SalesOrderId);
  }

  getOrderDetails = (id: any) => {
    this.salesService.GetOrderDetails(id).subscribe((x) => {
      if (x) {
        this.fileEmitterService.emitFileRefIdUpdate({id: this.SalesOrderId,});
        this.SalesOrderData = x.data;
        this.SalesOrderId = this.SalesOrderData.Id;
        this.SalesOrderNo = x.data.OrderNo;
        this.isClosed = this.SalesOrderData.StatusId == 4 ? true : false;
        this.getInvoices(x.data.OrderNo, x.data.OrderAmount, x.data.TaxAmount, x.data.AdvanceTaxAmount);
      }
    });
  }

  getInvoices = (orderNo, amount, taxAmount, advanceTaxAmount) => {
    this.incomeService.GetInvoiceList().subscribe((x) => {
        this.salesInvoices = x.data.filter((y) => y.ReferenceNo == orderNo);
        if (this.salesInvoices.length > 0) {
          this.showInvoice = true;
        }
        for(var i = 0; i < this.salesInvoices.length; i++){
          this.salesInvoices[i].ReferenceNo = 'SO-'+ this.salesInvoices[i].ReferenceNo;
          if(this.salesInvoices[i].IsReverseTransaction == true) {
            this.salesInvoices[i].FormattedInvoicceNo = 'RINV-'+ this.salesInvoices[i].InvoicceNo;
            this.totalReverseInvoiceAmount += this.salesInvoices[i].InvoiceAmount;
          }
          else {
            this.salesInvoices[i].FormattedInvoicceNo = 'INV-'+ this.salesInvoices[i].InvoicceNo;
            this.totalNormalInvoiceAmount += this.salesInvoices[i].InvoiceAmount;
          }
        }
        this.totalInvoice = this.totalNormalInvoiceAmount - this.totalReverseInvoiceAmount;
        //this.totalUnInvoice = amount - (taxAmount + advanceTaxAmount + this.totalInvoice);
        this.totalUnInvoice = amount - this.totalInvoice;
        // if (this.totalUnInvoice < 0) {
        //   this.totalUnInvoice = 0;
        // }
      },
      (err) => {}
    );
  }

  twoDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.0;
  };

  invoiceGridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
    columnDefs: [
      {
        headerName: "Invoice No",
        field: "FormattedInvoicceNo",
        width: 150,
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: { inRouterLink: '/income/invoice/', orderNo: this.route.snapshot.paramMap.get("orderNo") }
      },
      { headerName: "Ref", field: "ReferenceNo", width: 130 },
      {
        headerName: "Date",
        field: "InvoiceDate",
        valueFormatter: this.convertInvoiceGridDateIntoDisplay,
        width: 140,
      },
      {
        headerName: "Due Date",
        field: "DueDate",
        valueFormatter: this.convertInvoiceGridDateIntoDisplay,
        width: 135,
      },
      {
        headerName: "Invoice Amount",
        field: "InvoiceAmount",
        valueFormatter: this.InvoiceAmountFormatter,
        width: 150,
      },
      {
        headerName: "Due",
        field: "DueAmount",
        valueFormatter: this.InvoiceDueAmountFormatter,
        width: 150,
      },
      {
        headerName: "Status",
        field: "StatusId",
        valueFormatter: this.InvoiceStatus,
        width: 160,
      },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
  }

  onInvoiceGridReady = (event) => {
    this.gridApi = event.api;
  }

  convertInvoiceGridDateIntoDisplay(date) {
    if (date.value == null) return null;
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }

  InvoiceAmountFormatter(value) {
    let valueData = Number(value.data.InvoiceAmount.toFixed(2));
    //let amount = value.data.IsReverseTransaction == true ? '-' + valueData.toLocaleString() : valueData.toLocaleString();
    return valueData.toLocaleString();
  }

  InvoiceDueAmountFormatter(value) {
    let amount = value.data.InvoiceAmount - (value.data.PaidAmount + value.data.DiscountAmount);
    return amount.toLocaleString();
  }

  InvoiceStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return "Payment Approval";
      case 2:
        return "Awaiting Approval";
      case 3:
        return "Awaiting Payment";
      case 4:
        return "Paid";
      default:
        return "unknown";
    }
  }

  public createNewInvoice = () => {
    this.router.navigate(["income/create-invoice"], {
      queryParams: { ExternalReferenceNo: this.SalesOrderData.Id, IsInvoiceReverseTransaction: this.totalUnInvoice < 0 ? true : false },
    });
  };
}
