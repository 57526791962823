import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MainServiceService } from '../../../service/Main/main-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageManagerService} from '../../../service/MessageManager/messagemanager.service';
import { AuthService} from '../../../service/auth/auth.service';
@Component({
  selector: 'app-bank-account-create',
  templateUrl: './bank-account-create.component.html',
  styleUrls: ['./bank-account-create.component.css']
})
export class BankAccountCreateComponent implements OnInit {
  BankAcountForm: FormGroup;
  ifSubmit = false;
  constructor(public fb: FormBuilder,
              private router: Router,
              private toaster: ToastrService,
              private mainService: MainServiceService,
              private messageManagerService: MessageManagerService,
              private authService: AuthService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.BankAcountForm = this.fb.group({
      BankName : [''],
      AccountName  : ['', Validators.required],
      AccountCode  : ['', Validators.required],
      IsBankRemittance: [true],
      IsCash: [true],
      IsBankTransfer: [true],
      IsCheque: [true],
      AccountTypeId  : ['0']
    });
  }
  bankInfo(v)
  {
      this.ChnageBankInfoByDropdown(v.value.AccountTypeId);
  }

  SaveBankInfo = () => {
    this.ifSubmit = true;
    if (this.BankAcountForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    this.spinner.show();

    this.mainService.saveBankAccount(this.BankAcountForm.value).subscribe((x)=>{
      this.spinner.hide();
      if(x.Success){
        this.toaster.success('Bank Account has been successfully added !');
        this.router.navigate(['/accounting/bank-accounts']);
      }
      else{
        this.toaster.error(x.Message);
      }

    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });

  }
  ChnageBankInfoByDropdown(value)
  {
    if (value === '3'){
    this.BankAcountForm.patchValue({
      IsCash: false,
      IsBankTransfer: false,
      IsCheque: false
    });
  } else if (value === '0'){
    this.BankAcountForm.patchValue({
      IsBankRemittance: true,
      IsCash: true,
      IsBankTransfer: true,
      IsCheque: true
    });
}else{
  this.BankAcountForm.patchValue({
    IsBankRemittance: false,
    IsCash: false,
    IsBankTransfer: false,
    IsCheque: false
  });
}

}
public UpdateStep() {
  this.messageManagerService.StepDone().subscribe(x => {
    this.router.navigate(['/accounting/existing-balances']);
    if (x.AllDone){
      const userInfo = this.authService.getUserInfo();
      userInfo.CompanyList = x.Data;
      this.authService.setUserInfo(JSON.stringify(userInfo));
    }
  });
}
}
