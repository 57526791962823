import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { SalesOrderService } from '../../../service/SalesOrder/sales-order.service';
import { SalesOrderProgressComponent } from '../sales-order-progress/sales-order-progress.component';

@Component({
  selector: 'app-sales-order-list',
  templateUrl: './sales-order-list.component.html',
  styleUrls: ['./sales-order-list.component.css']
})

export class SalesOrderListComponent implements OnInit {
  allData: any = [];
  searchText:string;
  gridApi;
  gridColumnApi;
  status: any;
  event = new EventEmitter<any>();
  filteredLists:any=[];
  onGoingCount:number=0;
  draftCount:number=0;
  onHoldCount:number=0;
  closedCount:number=0;
  completedCount: number = 0;
  orderStatus: any = {
    "All": 0,
    "Draft": 1,
    "OnGoing": 2,
    "OnHold": 3,
    "Closed": 4,
    "SOCompleted": 5
  }
  constructor(
    private salesService: SalesOrderService,
    private dataService:DataService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router:ActivatedRoute) { }

  ngOnInit(): void {
    this.status = this.router.snapshot.paramMap.get('status');
    this.dataService.changeSelectedOrganization.subscribe(x => {
      this.getAllDataList();
    });
    this.getAllDataList();
  }
  domLayout= 'autoHeight'
  rowHeight= 275
  columnDefs = [
    { headerName: 'Order No', field: 'OrderNo',
      cellRenderer: function(params) {
        return `<a href="#/sales/sales-order-details/${params.data.Id}" >${params.value}</a>`;
      }
    },
    { headerName: 'Reference', field: 'ReferenceNo' },
    { headerName: 'Order Date', field: 'OrderDate', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Delivery Date', field: 'DeliveryDate', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Total', field: 'OrderAmount', valueFormatter: this.convertOrderAmount },
    { headerName: 'Invoiced Amount', field: 'InvoicedAmount', valueFormatter: this.convertInvoiceAmount },
    { headerName: 'Uninvoiced Amount', field: 'UnInvoicedAmount', valueFormatter: this.convertUnInvoiceAmount },
    { headerName: 'Status', field: 'StatusId', valueFormatter: this.setOrderStatus },
    { headerName: 'Progress', field: 'Progress', cellRendererFramework: SalesOrderProgressComponent },
    // { headerName: '', field: 'Id', cellRendererFramework: StatusUpdateComponent }
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };

  getAllDataList=()=>{

    this.spinner.show()
    this.salesService.GetSalesOrderList().subscribe((x)=>{
      this.allData = x;

      console.log("Hello Iam Sales Order List==>",this.allData)
      for(let i=0;i<this.allData.length;i++){
        this.allData[i].OrderNo = 'SO-' + this.allData[i].OrderNo;
        if(this.allData[i].QuotationNo != null){
          this.allData[i].ReferenceNo = 'QU-'+this.allData[i].QuotationNo;
        }
      }
      this.allData.forEach( x => {
        x.StatusId == this.orderStatus.Draft ? this.draftCount += 1 : x.StatusId == this.orderStatus.OnGoing ?
        this.onGoingCount += 1 : x.StatusId == this.orderStatus.OnHold ? 
        this.onHoldCount += 1 : x.StatusId == this.orderStatus.Closed ? this.closedCount += 1 : this.completedCount += 1;
      });
      this.filteredLists = this.orderStatus[this.status] == this.orderStatus.All ? x :
      x.filter(y => y.StatusId == this.orderStatus[this.status]);
      this.status == 'OnGoing' ? this.HideRawColumn(true) : this.HideRawColumn(false);
      this.spinner.hide();
    }, err => {
      this.toaster.error("There is an error,please check");
      this.spinner.hide();
    });
  }
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }
  getParams() {
    return {
    suppressQuotes: '',
    columnSeparator: '',
    customHeader: '',
    customFooter: '',};
  }
  HideRawColumn(b)
  {
    console.log(b);
    const columns = this.gridColumnApi.getAllColumns();
    const valueColumn = columns.filter(column => column.getColDef().headerName === 'Progress')[0];
    this.gridColumnApi.setColumnsVisible([valueColumn], b);
    console.log(valueColumn);
    this.gridApi.sizeColumnsToFit();

  }
  convertOrderAmount(value){
    if(value.data.OrderAmount==null)
    return 0;
    let valueData = Number(value.data.OrderAmount.toFixed(2))
    return valueData.toLocaleString();
  }
  convertInvoiceAmount(value){
    if(value.data.InvoicedAmount==null)
    return 0;
    let valueData = Number(value.data.InvoicedAmount.toFixed(2))
    return valueData.toLocaleString();
  }
  convertUnInvoiceAmount(value){
    if(value.data.UnInvoicedAmount==null)
    return 0;
    let valueData = Number(value.data.UnInvoicedAmount.toFixed(2))
    return valueData.toLocaleString();
  }
  setOrderStatus(id){
    switch (id.data.StatusId) {
      case 1:
        return 'Draft'
      case 2:
        return 'On Going'
      case 3:
        return 'On Hold'
      case 4:
        return 'Closed'
      case 5:
        return 'SO Completed'
      default:
        return 'unknown'
    }
  }
  convertGridDateIntoDisplay(date) {
    if(date.value==null)
    return null;
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }
  onGridReady(event){
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
  }

  search = () => {
    this.gridApi.setFilterModel(null);
    const searchText = this.searchText.toLowerCase();
    if (searchText.includes(',')) {
      const amount = parseInt(searchText.replace(',', ''), 10);
      this.gridApi.setQuickFilter(null);
      this.gridApi.setRowData(this.filteredLists.filter(row => {
        const orderAmount = parseFloat(row.OrderAmount);
        const invoicedAmount = parseFloat(row.InvoicedAmount);
        const unInvoicedAmount = parseFloat(row.UnInvoicedAmount);
        return ( Math.floor(orderAmount) === amount || Math.floor(invoicedAmount) === amount ||Math.floor(unInvoicedAmount) === amount );
      }));
    } else {
      this.gridApi.setQuickFilter(searchText);
    }
  }

  reLoadRowData = (status: string) => {
    status == 'OnGoing' ? this.HideRawColumn(true) : this.HideRawColumn(false);
    this.filteredLists = this.orderStatus[status] == this.orderStatus.All ? this.allData : this.allData.filter(x => x.StatusId == this.orderStatus[status]);
  }

}
