import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssignedItem, Assignee } from 'src/app/models/assignee';
import { retry } from 'rxjs/operators';
import { ResponseModel } from 'src/app/models/responseModel.model';

@Injectable({
  providedIn: 'root'
})
export class AssigneeService {

  baseurl = environment.baseUrl;
  constructor(
    private http: HttpClient
  ) { }

  public getAssigneeList(): Observable<Assignee[]> {
    const url = this.baseurl +'Assignee/GetAssigneeList';
    return this.http.get<Assignee[]>(url).pipe(retry(1));
  }

  public getAssignedItemList(): Observable<any> {
    const url = this.baseurl +'Assignee/GetAssignedItemList';
    return this.http.get<any>(url).pipe(retry(1));
  }

  public getAssignedItemById(assignedItemId: number): Observable<AssignedItem> {
    return this.http.get<AssignedItem>(this.baseurl + `Assignee/GetAssignedItemById?assignedItemId=${assignedItemId}`).pipe(retry(1));
  }

  public getAssignedItemByFixedAssetItemId(fixedAssetItemId: number): Observable<AssignedItem> {
    return this.http.get<AssignedItem>(this.baseurl + `Assignee/GetAssignedItemByFixedAssetItemId?fixedAssetItemId=${fixedAssetItemId}`).pipe(retry(1));
  }

  public getUnassignedItemByFixedAssetItemId(fixedAssetItemId: number): Observable<AssignedItem> {
    return this.http.get<AssignedItem>(this.baseurl + `Assignee/GetUnassignedItemByFixedAssetItemId?fixedAssetItemId=${fixedAssetItemId}`).pipe(retry(1));
  }

  public getUassignedItemListByFixedAssetItemId(fixedAssetItemId: number): Observable<AssignedItem[]> {
    return this.http.get<AssignedItem[]>(this.baseurl + `Assignee/GetUassignedItemListByFixedAssetItemId?fixedAssetItemId=${fixedAssetItemId}`).pipe(retry(1));
  }

  public saveAssignee(assignee: Assignee): Observable<ResponseModel> {
    const url = this.baseurl +'Assignee/SaveAssignee';
    return this.http.post<ResponseModel>(url, assignee);
  }

  public AssignItem(assignedItem: AssignedItem): Observable<ResponseModel> {
    const url = this.baseurl +'Assignee/SaveAssignedItem';
    return this.http.post<ResponseModel>(url, assignedItem);
  }

  public ReturnAssignedItem(assignedItem: AssignedItem): Observable<ResponseModel> {
    const url = this.baseurl +'Assignee/ReturnAssignedItem';
    return this.http.post<ResponseModel>(url, assignedItem);
  }

  public TransferAssignedItem(assignedItem: AssignedItem): Observable<ResponseModel> {
    const url = this.baseurl +'Assignee/TransferAssignedItem';
    return this.http.post<ResponseModel>(url, assignedItem);
  }

  public GetAllItemList(): Observable<any> {
    const url = this.baseurl +'Assignee/GetAllItemList';
    return this.http.get<any>(url).pipe(retry(1));
  }

}
