<app-dashboard>
  <app-page-header-title
    mainTitle="Approval Settings"
    subTitle="Settings"
    backURL="/settings"
  >
  </app-page-header-title>
  <ul class="nav nav-tabs justify-content-center">
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="toggleTemplate('bill')"
        [routerLinkActive]="['active']"
        [ngClass]="{ borderless: billSettings == true }"
        >Bill</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="toggleTemplate('invoice')"
        [routerLinkActive]="['active']"
        [ngClass]="{ borderless: invoiceSettings == true }"
        >Invoice</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="toggleTemplate('document')"
        [routerLinkActive]="['active']"
        [ngClass]="{ borderless: documentSettings == true }"
        >Document</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link f-ssb-14px nav-option"
        (click)="toggleTemplate('grant loan')"
        [routerLinkActive]="['active']"
        [ngClass]="{ borderless: grantLoanSettings == true }"
        >Grant Loan</a
      >
    </li>
  </ul>

  <div *ngIf="billSettings" class="row mm-5px pbp-20px">
    <div class="col-sm-12" style="margin-top: 10px">
      <form [formGroup]="approvalForm">
        <div class="row">
          <div class="col-sm-9">
            <label
              style="font-weight: bold; margin-top: 20px; margin-left: 14px"
              >Bill Approval Settings</label
            >
          </div>
          <div class="col-sm-3">
            <button
              type="button"
              (click)="addBlankLevel()"
              class="eCount-def-btn ml-3"
            >
              Add Level
            </button>
            <button type="button" (click)="save()" class="eCount-def-btn ml-3">
              Save
            </button>
          </div>
        </div>
        <div formArrayName="ApprovalLevel">
          <div
            *ngFor="
              let level of ApprovalLevel().controls;
              let levelIndex = index
            "
          >
            <div [formGroupName]="levelIndex">
              <input
                style="font-weight: bold; color: black; margin-left: 5px"
                readonly
                class="form-control noBorder"
                type="text"
                disabled
                formControlName="LevelName"
              />

              <!-- this code is just commented out do not remove this
              <button (click)="removeLevel(levelIndex)">Remove</button> -->

              <div class="d-flex">
                <div class="col-md-2">Approvers</div>
                <div class="col-md-3">Email</div>
                <div style="margin-left: 5px" class="col-md-2">Modified By</div>
                <div style="margin-left: 5px" class="col-md-2">Modified At</div>
              </div>
              <div formArrayName="ApprovalLevelItem">
                <div
                  *ngFor="
                    let item of levelItems(levelIndex).controls;
                    let itemIndex = index
                  "
                >
                  <div class="invoiceGridItemRow d-block pb-1">
                    <div
                      style="margin-left: 0px; margin-top: 2px"
                      class="row"
                      [formGroupName]="itemIndex"
                    >
                      <div class="col-md-2 margin-top-5">
                        <ng-select
                          [items]="approverRoleList"
                          [clearable]="false"
                          bindLabel="Name"
                          bindValue="Id"
                          (change)="ChangeEmailByRole (item,itemIndex,levelIndex)"
                          formControlName="ApproverId"
                        ></ng-select>
                      </div>
                      <div class="col-md-3 margin-top-5">
                        <ng-select
                          [items]="approverEmailList"
                          [clearable]="false"
                          bindLabel="Name"
                          [searchable]="false"
                          bindValue="Id"
                          (change)="changeApproveIfRoleChange(item,itemIndex,levelIndex)"
                          formControlName="ApproverEmailId"
                        ></ng-select>
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                          class="form-control"
                          readonly
                          formControlName="ModifiedById"
                        />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                          class="form-control"
                          readonly
                          formControlName="ModifiedAt"
                        />
                      </div>

                      <div
                        class="col-md-2 margin-top-5 d-flex"
                        *ngIf="
                          levelIndex > 0 || (levelIndex == 0 && itemIndex > 0)
                        "
                      >
                        <div style="padding: 7px 10px; color: #c7c2c2">
                          <div class="dropdown mr-4">
                            <div
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="false"
                              aria-expanded="false"
                            >
                              <i class="icon-more float-right"></i>
                            </div>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                              style="width: 100%; cursor: pointer"
                            >
                              <div
                                class="productRow pb-2"
                                (click)="
                                  removeLevelItem(levelIndex, itemIndex, item)
                                "
                              >
                                <span class="lead1 ml-4">Delete</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row addItem">
                <div class="BottomAddItem">
                  <div class="dropdown mr-4">
                    <a
                      class="nav-link"
                      style="background-color: white"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="false"
                      aria-expanded="false"
                      (click)="addBlankLevelItem(levelIndex)"
                    >
                      <img
                        style="height: 30px"
                        class="languageImg"
                        src="../../../assets/image/addIcon.png"
                      />
                      Add New Approver
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="invoiceSettings" class="row mm-5px pbp-20px">
    <div class="col-sm-12" style="margin-top: 10px">
      <form [formGroup]="invoiceApprovalForm">
        <div class="row">
          <div class="col-sm-9">
            <label
              style="font-weight: bold; margin-top: 20px; margin-left: 14px"
              >Invoice Approval Settings</label
            >
          </div>
          <div class="col-sm-3">
            <button
              type="button"
              (click)="addBlankInvoiceLevel()"
              class="eCount-def-btn ml-3"
            >
              Add Level
            </button>
            <button
              type="button"
              (click)="saveInvoice()"
              class="eCount-def-btn ml-3"
            >
              Save
            </button>
          </div>
        </div>
        <div formArrayName="InvoiceApprovalLevel">
          <div
            *ngFor="
              let level of InvoiceApprovalLevel().controls;
              let levelIndex = index
            "
          >
            <div [formGroupName]="levelIndex">
              <input
                style="font-weight: bold; color: black; margin-left: 5px"
                readonly
                class="form-control noBorder"
                type="text"
                disabled
                formControlName="LevelName"
              />

              <!-- this code is just commented out do not remove this
            <button (click)="removeLevel(levelIndex)">Remove</button> -->

              <div class="d-flex">
                <div class="col-md-2">Approvers</div>
                <div class="col-md-3">Email</div>
                <div style="margin-left: 5px" class="col-md-2">Modified By</div>
                <div style="margin-left: 5px" class="col-md-2">Modified At</div>
              </div>
              <div formArrayName="ApprovalLevelItem">
                <div
                  *ngFor="
                    let item of invoiceLevelItems(levelIndex).controls;
                    let itemIndex = index
                  "
                >
                  <div class="invoiceGridItemRow d-block pb-1">
                    <div
                      style="margin-left: 0px; margin-top: 2px"
                      class="row"
                      [formGroupName]="itemIndex"
                    >
                      <div class="col-md-2 margin-top-5">
                        <ng-select
                          [items]="approverRoleList"
                          [clearable]="false"
                          bindLabel="Name"
                          bindValue="Id"
                          formControlName="ApproverId"
                          (change)="ChangeEmailByInvoiceRole (item,itemIndex,levelIndex)"
                        ></ng-select>
                      </div>
                      <div class="col-md-3 margin-top-5">
                        <ng-select
                          [items]="approverEmailList"
                          [clearable]="false"
                          bindLabel="Name"
                          [searchable]="false"
                          bindValue="Id"
                          (change)="changeApproveIfInvoiceRoleChange(item,itemIndex,levelIndex)"
                          formControlName="ApproverEmailId"
                        ></ng-select>
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                          class="form-control"
                          readonly
                          formControlName="ModifiedById"
                        />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                          class="form-control"
                          readonly
                          formControlName="ModifiedAt"
                        />
                      </div>

                      <div
                        class="col-md-2 margin-top-5 d-flex"
                        *ngIf="
                          levelIndex > 0 || (levelIndex == 0 && itemIndex > 0)
                        "
                      >
                        <div style="padding: 7px 10px; color: #c7c2c2">
                          <div class="dropdown mr-4">
                            <div
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="false"
                              aria-expanded="false"
                            >
                              <i class="icon-more float-right"></i>
                            </div>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                              style="width: 100%; cursor: pointer"
                            >
                              <div
                                class="productRow pb-2"
                                (click)="
                                  removeLevelInvoiceItem(
                                    levelIndex,
                                    itemIndex,
                                    item
                                  )
                                "
                              >
                                <span class="lead1 ml-4">Delete</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row addItem">
                <div class="BottomAddItem">
                  <div class="dropdown mr-4">
                    <a
                      class="nav-link"
                      style="background-color: white"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="false"
                      aria-expanded="false"
                      (click)="addBlankLevelInvoiceItem(levelIndex)"
                    >
                      <img
                        style="height: 30px"
                        class="languageImg"
                        src="../../../assets/image/addIcon.png"
                      />
                      Add New Approver
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="documentSettings" class="row mm-5px pbp-20px">
    <div class="col-sm-12" style="margin-top: 10px">
      <form [formGroup]="documentApprovalForm">
        <div class="row">
          <div class="col-sm-9">
            <label
              style="font-weight: bold; margin-top: 20px; margin-left: 14px"
              >Document Approval Settings</label
            >
          </div>
          <div class="col-sm-3">
            <button
              type="button"
              (click)="addBlankDocumentLevel()"
              class="eCount-def-btn ml-3"
            >
              Add Level
            </button>
            <button
              type="button"
              (click)="saveDocument()"
              class="eCount-def-btn ml-3"
            >
              Save
            </button>
          </div>
        </div>
        <div formArrayName="DocumentApprovalLevel">
          <div
            *ngFor="
              let level of DocumentApprovalLevel().controls;
              let levelIndex = index
            "
          >
            <div [formGroupName]="levelIndex">
              <input
                style="font-weight: bold; color: black; margin-left: 5px"
                readonly
                class="form-control noBorder"
                type="text"
                disabled
                formControlName="LevelName"
              />

              <!-- this code is just commented out do not remove this
            <button (click)="removeLevel(levelIndex)">Remove</button> -->

              <div class="d-flex">
                <div class="col-md-2">Approvers</div>
                <div class="col-md-3">Email</div>
                <div style="margin-left: 5px" class="col-md-2">Modified By</div>
                <div style="margin-left: 5px" class="col-md-2">Modified At</div>
              </div>
              <div formArrayName="ApprovalLevelItem">
                <div
                  *ngFor="
                    let item of documentLevelItems(levelIndex).controls;
                    let itemIndex = index
                  "
                >
                  <div class="invoiceGridItemRow d-block pb-1">
                    <div
                      style="margin-left: 0px; margin-top: 2px"
                      class="row"
                      [formGroupName]="itemIndex"
                    >
                      <div class="col-md-2 margin-top-5">
                        <ng-select
                          [items]="approverRoleList"
                          [clearable]="false"
                          bindLabel="Name"
                          bindValue="Id"
                          formControlName="ApproverId"
                          (change)="ChangeEmailByDocumentRole (item,itemIndex,levelIndex)"
                        ></ng-select>
                      </div>
                      <div class="col-md-3 margin-top-5">
                        <ng-select
                          [items]="approverEmailList"
                          [clearable]="false"
                          bindLabel="Name"
                          [searchable]="false"
                          bindValue="Id"
                          (change)="changeApproveIfDocumentRoleChange(item,itemIndex,levelIndex)"
                          formControlName="ApproverEmailId"
                        ></ng-select>
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                          class="form-control"
                          readonly
                          formControlName="ModifiedById"
                        />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                          class="form-control"
                          readonly
                          formControlName="ModifiedAt"
                        />
                      </div>

                      <div
                        class="col-md-2 margin-top-5 d-flex"
                        *ngIf="
                          levelIndex > 0 || (levelIndex == 0 && itemIndex > 0)
                        "
                      >
                        <div style="padding: 7px 10px; color: #c7c2c2">
                          <div class="dropdown mr-4">
                            <div
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="false"
                              aria-expanded="false"
                            >
                              <i class="icon-more float-right"></i>
                            </div>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                              style="width: 100%; cursor: pointer"
                            >
                              <div
                                class="productRow pb-2"
                                (click)="
                                  removeLevelDocumentItem(
                                    levelIndex,
                                    itemIndex,
                                    item
                                  )
                                "
                              >
                                <span class="lead1 ml-4">Delete</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row addItem">
                <div class="BottomAddItem">
                  <div class="dropdown mr-4">
                    <a
                      class="nav-link"
                      style="background-color: white"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="false"
                      aria-expanded="false"
                      (click)="addBlankLevelDocumentItem(levelIndex)"
                    >
                      <img
                        style="height: 30px"
                        class="languageImg"
                        src="../../../assets/image/addIcon.png"
                      />
                      Add New Approver
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="grantLoanSettings" class="row mm-5px pbp-20px">
    <div class="col-sm-12" style="margin-top: 10px">
      <form [formGroup]="grantLoanApprovalForm">
        <div class="row">
          <div class="col-sm-9">
            <label style="font-weight: bold; margin-top: 20px; margin-left: 14px">Grant Loan Approval Settings</label>
          </div>
          <div class="col-sm-3">
            <button type="button" (click)="addBlankGrantLoanLevel()" class="eCount-def-btn ml-3">Add Level</button>
            <button type="button" (click)="saveGrantLoan()" class="eCount-def-btn ml-3">Save</button>
          </div>
        </div>
        <div formArrayName="GrantLoanApprovalLevel">
          <div
            *ngFor="let level of GrantLoanApprovalLevel().controls; let levelIndex = index">
            <div [formGroupName]="levelIndex">
              <input style="font-weight: bold; color: black; margin-left: 5px" readonly class="form-control noBorder" type="text" [disabled]="true" formControlName="LevelName"/>
              <div class="d-flex">
                <div class="col-md-2">Approvers</div>
                <div class="col-md-3">Email</div>
                <div style="margin-left: 5px" class="col-md-2">Modified By</div>
                <div style="margin-left: 5px" class="col-md-2">Modified At</div>
              </div>
              <div formArrayName="ApprovalLevelItem">
                <div *ngFor="let item of grantLoanLevelItems(levelIndex).controls; let itemIndex = index">
                  <div class="invoiceGridItemRow d-block pb-1">
                    <div style="margin-left: 0px; margin-top: 2px" class="row" [formGroupName]="itemIndex">
                      <div class="col-md-2 margin-top-5">
                        <ng-select [items]="approverRoleList" [clearable]="false"
                          bindLabel="Name" bindValue="Id" formControlName="ApproverId"
                          (change)="ChangeEmailByGrantLoanRole (item,itemIndex,levelIndex)">
                        </ng-select>
                      </div>
                      <div class="col-md-3 margin-top-5">
                        <ng-select [items]="approverEmailList" [clearable]="false"
                          bindLabel="Name" [searchable]="false" bindValue="Id"
                          (change)="changeApproveIfGrantLoanRoleChange(item,itemIndex,levelIndex)"
                          formControlName="ApproverEmailId">
                        </ng-select>
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input class="form-control" readonly formControlName="ModifiedById"/>
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input class="form-control" readonly formControlName="ModifiedAt"/>
                      </div>
                      <div class="col-md-2 margin-top-5 d-flex" *ngIf=" levelIndex > 0 || (levelIndex == 0 && itemIndex > 0)">
                        <div style="padding: 7px 10px; color: #c7c2c2">
                          <div class="dropdown mr-4">
                            <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                              <i class="icon-more float-right"></i>
                            </div>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%; cursor: pointer">
                              <div class="productRow pb-2" (click)="removeLevelGrantLoanItem(levelIndex, itemIndex, item)">
                                <span class="lead1 ml-4">Delete</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row addItem">
                <div class="BottomAddItem">
                  <div class="dropdown mr-4">
                    <a class="nav-link" style="background-color: white" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false"
                      (click)="addBlankLevelGrantLoanItem(levelIndex)">
                      <img style="height: 30px" class="languageImg" src="../../../assets/image/addIcon.png"/>
                      Add New Approver
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</app-dashboard>
