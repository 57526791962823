<app-dashboard>
    <app-page-header-title 
        mainTitle="{{ mainTitle }}" 
        subTitle="Home" 
        backURL="/">
        <div class="full-width text-align-right">
            <input type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox"/>
            <button type="button" class="eCount-def-btn ml-4" style="background: red;" (click)=" getSelectedPaymentHistory()">Delete</button>
            <button type="button" class="eCount-button3 ml-4" (click)="onBtnExport()">Export</button>
        </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Bill')" 
                [routerLinkActive]="['active']" 
                routerLink="/acounting/return-payment-history/Bill/{{paymentType}}">
                Bill
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Invoice')" 
                [routerLinkActive]="['active']" 
                routerLink="/acounting/return-payment-history/Invoice/{{paymentType}}">
                Invoice
            </a>
        </li>
    </ul>
    <div class="headSection">
        <div class="row">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col"
                [rowData]="filteredPaymentList" 
                [columnDefs]="ColumnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef"
                [rowSelection]="rowSelection"
                [suppressRowClickSelection]="true"
                [pagination]="true" 
                [paginationPageSize]="50" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</app-dashboard>