import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { NgxSpinnerService } from "ngx-spinner";
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { environment } from 'src/environments/environment';

import { faCheckCircle, faTimesCircle, faExclamationCircle  } from '@fortawesome/free-solid-svg-icons';
import { ApprovalHistoryModalService } from 'src/app/service/Approval History Modal/approval-history-modal.service';
import { OperationType } from 'src/app/enum/operation-type';

import { AppConfig } from "../../../app.config";
import * as firebase from "firebase";
import { ToastrService } from 'ngx-toastr';
import { WindowService } from "../../../service/Windows/window.service";

@Component({
  selector: 'app-approval-history-modal',
  templateUrl: './approval-history-modal.component.html',
  styleUrls: ['./approval-history-modal.component.css']
})

export class ApprovalHistoryModalComponent implements OnInit {

  @Input() OperationType: OperationType;
  @Input() action: string;

  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faExclamationCircle = faExclamationCircle;

  index: number = 0;
  fileUrl = environment.fileBaseUrl;

  userList: any = [];
  modalHistory: any = [];

  approveLevelForm: FormGroup;
  modalInfoData: any;
  currentUserRoleName: any;
  currentUserEmail: any;

  downloadLink: any;

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private approvalHistoryModalService: ApprovalHistoryModalService,
    private mainService: MainServiceService,
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    this.initializeForm();
  }

  initializeForm(){
    this.approveLevelForm = new FormGroup({
      Approvers: new FormControl(''),
      Email: new FormControl(''),
      ApprovedBy: new FormControl(''),
      ApprovedAt: new FormControl(''),
      Comments: new FormControl('')
    });
    this.modalInfoData = this.approvalHistoryModalService.getModalInfoData();
    debugger
    let fileName = this.modalInfoData.DocumentBlobName?.split('.')[0];
    this.downloadLink = this.fileUrl + fileName  + ".pdf";
    this.getAllHistory(this.OperationType);
  }

  getAllHistory(type: OperationType){
    this.approvalHistoryModalService.getAllHistory(type, this.modalInfoData.Number).subscribe((x) => {
      this.modalHistory = x;
      for(let i=0;i<this.modalHistory.length;i++){
        if( !this.isNull(this.modalHistory[i].Signature) ){
          this.modalHistory[i].Signature = this.fileUrl + this.modalHistory[i].Signature;
        }
        if( !this.isNull(this.modalHistory[i].ApprovedBy) ){
          this.modalHistory[i].ApprovedBy = this.modalHistory[i].ApprovedBy.split(" ")[0];
        }
      }
      this.getUserDetails();
    })
  }

  getUserDetails(){
    let userMail = localStorage.getItem('user');
    this.mainService.GetUserList().subscribe((x) => {
      this.spinner.hide();
      for(let i=0;i<x.userList.length;i++){
        if(x.userList[i].Email==userMail){
          this.currentUserRoleName = x.userList[i].CompanyWiseUserList[0].RoleName;
          this.currentUserEmail = userMail;
          break;
        }
      }
    });
  }
  
  isApproved(data, i){
    let rejectStatus = this.OperationType == OperationType.DOCUMENT ? 4 : 7;
    if(data.ApprovalStatus != rejectStatus && !this.isNull(data.ApprovedBy) ){
      return true;
    }
    else if(data.ApprovalStatus == rejectStatus && !this.isNull(data.ApprovedBy) && (i+1) < this.modalHistory.length ){
      return true;
    }
    else{
      return false;
    }
  }

  isRejected(data, i){
    let rejectStatus = this.OperationType == OperationType.DOCUMENT ? 4 : 7;
    if(data.ApprovalStatus == rejectStatus && !this.isNull(data.ApprovedBy) && (i+1) == this.modalHistory.length ){
      return true;
    }
    else{
      return false;
    }
  }

  isWaiting(data, i){
    if( (data.ApprovalStatus == 2 || data.ApprovalStatus == 1) &&  this.isNull(data.ApprovedBy) ){
      return true;
    }
    else{
      return false;
    }
  }

  isNull(data){
    if( data == null || data == undefined || data == "" || !data ){
      return true;
    }
    else{
      return false;
    }
  }

  isNotSameLevel(item, prev_index){
    if( this.isNot_Array_Index_OutOfBounds(this.modalHistory, prev_index) ){
      if( this.modalHistory[prev_index].LevelId != item.LevelId ){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return true;
    }
  }

  isNot_Array_Index_OutOfBounds(array, index){
    if( index < array.length && index >= 0 ){
      return true;
    }
    else{
      return false;
    }
  }

  isLastItem(index){
    if( this.modalHistory.length == (index+1) ){
      return true;
    }
    else{
      return false;
    }
  }
  
  


}

