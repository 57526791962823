<app-dashboard>
    <app-page-header-title mainTitle="Fixed Asset" subTitle="Home" backURL="/">

    </app-page-header-title>
    <div class="mt-4 mr-4 pb-4">
        <button type="button" routerLink="/fixed-asset/setup/draft"
        class="eCount-def-btn ml-4 float-right">Manage Asset</button>
    </div>
<!-- <ul class="nav nav-tabs mt-4">
    <li class="nav-item ml-3">
      <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('draft')" [routerLinkActive]="['active']" routerLink="/fixed-asset/draft">{{'FIXEDASSET.draft' |translate}} ({{totalDraft}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('registered')" [routerLinkActive]="['active']" routerLink="/fixed-asset/registered">{{'FIXEDASSET.registered' |translate}} ({{totalRegistered}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('solddisposed')" [routerLinkActive]="['active']"  routerLink="/fixed-asset/solddisposed">{{'FIXEDASSET.soldDisposed' |translate}} ({{totalSold}})</a>
    </li>
  </ul> -->

  <ul class="nav nav-tabs">
    <li class="nav-item ml-3">
      <a class="nav-link active" [routerLink]="['/fixed-asset/setup/draft']">{{'FIXEDASSET.draft' |translate}} ({{totalDraft}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/fixed-asset/setup/registered']">{{'FIXEDASSET.registered' |translate}} ({{totalRegistered}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/fixed-asset/setup/solddisposed']">{{'FIXEDASSET.soldDisposed' |translate}} ({{totalSold}})</a>
    </li>
  </ul>

  <div class="row">
    <div class="col">
        <table class="table  f-ss-14px">
            <thead>
                <tr class="tableHeader">
                    <th scope="col" class="table-header-color text-align-left">Asset Name</th>
                    <th scope="col" class="table-header-color text-align-center">Asset Number</th>
                    <th scope="col" class="table-header-color text-align-center">Asset Type</th>
                    <th scope="col" class="table-header-color text-align-center">Purchase Date</th>
                    <th scope="col" class="table-header-color text-align-center">Purchase Price</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of assetList">
                    <td class="text-align-left">{{item.AssetName}}</td>
                    <td class="text-align-center">
                     <span class="item-link text-blue cursor-pointer" (click)="EditAssetItem(item)">{{item.AssetNumber}}</span> 
                    </td>
                    <td class="text-align-center">{{item.AssetTypeName}}</td>
                    <td class="text-align-center">{{item.PurchaseDate}}</td>
                    <td class="text-align-center">{{item.PurchasePrice}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
</app-dashboard>