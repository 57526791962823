<app-dashboard>
    <app-page-header-title mainTitle="{{ 'Procurement.monthlyOptionalExpense' | translate }}" subTitle="Home" backURL="/">
    </app-page-header-title>
    <app-monthly-tab [message]="1"></app-monthly-tab>
<div class="col-sm-12 pl-0 ml-0" style="display: inline-flex;">
    <div class="col-sm-3 mt-4 pl-0 ml-0" >
        <ng-select [items]="monthList" [clearable]="false" bindLabel="Text" bindValue="Value"
            [(ngModel)]="currentMonth" (change)="getData()">
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span>{{item.Text}}</span>
            </ng-template>
        </ng-select>
    </div>
    <div class="col-sm-3 mt-4">
            <ng-select [items]="yearList" [clearable]="false" bindLabel="year" bindValue="Value"
            [(ngModel)]="currentYear" (change)="getData()">
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span>{{item.year}}</span>
            </ng-template>
        </ng-select>
    </div>
    <div class="col-sm-1 ml-0 mt-4 mr-3">
        <button class="eCount-def-btn" (click)="onBtnExport()">Export</button>
    </div>

    <div class="full-width mt-4 bb2 row ml-3 pr-0 pl-5">
        <div class="col-sm-12">
            <p class="title-h mt-2 float-right">{{'monthlyOptionalExpense.openingBalance' | translate}}: {{openingBalance | currency:'':''}}</p>
        </div>
    </div>
</div>
<div class="col-sm-12 pl-0 ml-0 pr-0 mr-0" style="display: inline-flex;">
    <div class="mt-3 col-sm-7 mrl pl-0 ml-0 pr-0 mr-0">
        <ag-grid-angular style="font-size: 14px" class="ag-theme-balham col ml-0 pl-0 pr-0 mr-0" [rowData]="monthlyExpense"
            [columnDefs]="columnDefs"  [domLayout]="domLayout" (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef">
        </ag-grid-angular>
        <div class="row d-flex grid ml-0 mr-0">
            <div class="col-sm-3">Total</div>
            <div class="col-sm-2">{{ currencyFormatter(totalExpense, '') }}</div>
            <div class="col-sm-2 ml-4">{{ currencyFormatter(totalBudget, '') }}</div>
            <div class="col-sm-2 ml-5" *ngIf="totalRemainingBudget >= 0">{{ currencyFormatter(totalRemainingBudget, '') }}</div>
            <div class="col-sm-2 ml-5" style="color: red;" *ngIf="totalRemainingBudget < 0">{{ formatTotalRemainingBudget() }}</div>
        </div>
    </div>
    <div class="col-sm-5 mr-0 pr-0 ml-0">
        <div class="mt-4 mrl mr-0 pr-0">
            <p class="ml-3">{{'monthlyOptionalExpense.cashReceived' | translate}}</p>
            <ag-grid-angular style="font-size: 14px" class="ag-theme-balham col-sm-12 mr-0 pr-0" [rowData]="cashReceievedMonthly"
                [columnDefs]="columnDefsm"  [domLayout]="domLayout" (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef">
            </ag-grid-angular>
        </div>
        <div class="ml-1 mr-0 mrt">
            <table class="conversion-total">
                <tbody class="f-ss-14px">
                    <tr>
                        <td class="col-sm-6">{{'monthlyOptionalExpense.totalDepositAmount' | translate}}: </td>
                        <td class="text-align-right pr-2 mr-5">{{TotalDeposit | currency:'':'' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="ml-1 mr-0">
            <table class="conversion-total">
                <tbody class="f-ss-14px">
                    <tr>
                        <td class="col-sm-6">{{'monthlyOptionalExpense.totalAmount' | translate}}: </td>
                        <td class="text-align-right pr-2 mr-5">{{TotalDeposit + openingBalance | currency:'':''}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="container-fluid ml-1 mr-0 pr-0 pl-0 full-width">
            <div class="mt-4 row mr-0 pr-0 pl-0 ml-0 bb2 full-width" style="width: 100%;">
                <div class="col-md-12 pl-2 pr-0 mr-0 pl-0 ml-0 float-right">
                    <p class="title-h mt-2 float-right mr-2 pr-1 float-right">{{'monthlyOptionalExpense.paidFromCash' | translate}}: {{(paidFromCash)| currency:'':''}}</p>
                </div>
            </div>
        </div>
        <div class="container-fluid ml-1 mr-0 pr-0 pl-0 full-width">
            <div class="mt-1 row mr-0 pr-0 pl-0 ml-0 bb2 full-width" style="width: 100%;">
                <div class="col-md-12 pl-2 pr-0 mr-0 pl-0 ml-0 float-right">
                    <p class="title-h mt-2 float-right mr-2 pr-1 float-right">{{'monthlyOptionalExpense.paidFromDigitalCash' | translate}}: {{(paidFromDigitalCash)| currency:'':''}}</p>
                </div>
            </div>
        </div>
        <div class="container-fluid ml-1 mr-0 pr-0 pl-0 full-width">
            <div class="mt-1 row mr-0 pr-0 pl-0 ml-0 bb2 full-width" style="width: 100%;">
                <div class="col-md-12 pl-2 pr-0 mr-0 pl-0 ml-0 float-right">
                    <p class="title-h mt-2 float-right mr-2 pr-1 float-right">{{'monthlyOptionalExpense.paidFromBank' | translate}}: {{(paidFromBank)| currency:'':''}}</p>
                </div>
            </div>
        </div>
        <div class="container-fluid ml-1 mr-0 pr-0 pl-0 full-width">
            <div class="mt-1 row mr-0 pr-0 pl-0 ml-0 bb2 full-width" style="width: 100%;">
                <div class="col-md-12 pl-2 pr-0 mr-0 pl-0 ml-0 float-right">
                    <p class="title-h mt-2 float-right mr-2 pr-1 float-right">{{'monthlyOptionalExpense.totalPaidAmount' | translate}}:   {{ totalPaidAmount | currency:'':''}}</p>
                </div>
            </div>
        </div>
        <div class="container-fluid ml-1 mr-0 pr-0 pl-0 full-width">
            <div class="mt-1 row mr-0 pr-0 pl-0 ml-0 bb2 full-width" style="width: 100%;">
                <div class="col-md-12 pl-2 pr-0 mr-0 pl-0 ml-0 float-right">
                    <p class="title-h mt-2 float-right mr-2 pr-1 float-right">{{'monthlyOptionalExpense.remainingAmount' | translate}}: {{remainingAmount| currency:'':''}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</app-dashboard>
