
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReportServiceService {
  baseurl = environment.baseUrl;

  constructor(private http: HttpClient,private router:Router) { }

  public GetGeneralLedger(data:any): Observable<any> {
    return this.http.get<any>(this.baseurl + `report/GetGeneralLedger?fromDate=${data.fromDate}&toDate=${data.toDate}`)
      .pipe(
        retry(1)
      )
  }

  public GetAccountTransaction(data:any): Observable<any> {
    return this.http.get<any>(this.baseurl + `report/GetAccountTransactions?fromDate=${data.fromDate}&toDate=${data.toDate}&accountIdList=${data.accountIdList}`)
      .pipe(
        retry(1)
      )
  }

  public GetTodaysCashInOutTrans(): Observable<any> {
    return this.http.get<any>(this.baseurl + `Dashboard/GetTodaysCashInOutTrans`)
      .pipe(
        retry(1)
      );
  }

  public  GetTrialBalance(data:any): Observable<any> {
    return this.http.get<any>(this.baseurl + `report/GetTrialBalance?tillDate=${data.toDate}`)
      .pipe(
        retry(1)
      )
  }

  public GetBalanceSheet(tillDate:any):Observable<any>{
    return this.http.get<any>(this.baseurl + `report/GetBalanceSheet?tillDate=${tillDate}`)
      .pipe(
        retry(1)
      )
  }

  public GetCashFlowStatement(data:any):Observable<any>{
    return this.http.get<any>(this.baseurl + `report/GetCashFlowStatement?fromDate=${data.fromDate}&toDate=${data.toDate}`)
      .pipe(
        retry(1)
      )
  }

  public GetCashFlowMovements(data:any):Observable<any>{
    return this.http.get<any>(this.baseurl + `report/GetCashFlowMovements?fromDate=${data.fromDate}&toDate=${data.toDate}&movementType=${data.movementType}`)
      .pipe(
        retry(1)
      )
  }

  public GetProfitAndLoss(data:any): Observable<any> {
    return this.http.get<any>(this.baseurl + `report/GetProfitAndLose?fromDate=${data.fromDate}&toDate=${data.toDate}`)
      .pipe(
        retry(1)
      )
  }

 public GetAccountNameList(): Observable<any> {
    return this.http.get<any>(this.baseurl + `report/GeAccountListFromTransaction`)
      .pipe(
        retry(1)
      )
  }
}