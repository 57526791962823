import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { TransfermoneyServiceService } from '../../../service/TransferMoney/transfermoney-service.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { MainServiceService } from '../../../service/Main/main-service.service'

@Component({
  selector: 'app-create-transfer-money',
  templateUrl: './create-transfer-money.component.html',
  styleUrls: ['./create-transfer-money.component.css']
})
export class TransferMoneyComponent implements OnInit {

  TransferMoneyForm: FormGroup;
  bankList: any = [];
  selectedBankTo: number;
  selectedBankFrom: number;
  selectedDate: Date;
  ReferenceNo: string = "";
  Amount: string = "";

  constructor(public fb: FormBuilder,
    private transfermoneyService: TransfermoneyServiceService,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    private router: Router,
    private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.TransferMoneyForm = this.fb.group({
      Amount: ['', Validators.required],
      ReferenceNo: ['', Validators.required],
      TransferDate: ['', Validators.required],
      ReceivedAccountId: ['', Validators.required],
      SendAccountId: ['', Validators.required],
    })
    this.TransferMoneyForm.get('TransferDate').patchValue(this.formatDate(new Date()));
    this.getFormData();
  }

  getFormData = () => {
    this.getBankList();
  }

  getBankList() {
    this.bankList = [];
    this.mainService.GetAllBank().subscribe((x) => {
      this.spinner.hide()
      this.bankList = x;
    })
  }
  
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  saveCreateTransferMoney() {

    if (this.TransferMoneyForm.invalid) {
      this.toaster.warning('Please fill all the required fields!');
      return;
    }

   else if (this.TransferMoneyForm.value.ReceivedAccountId == this.TransferMoneyForm.value.SendAccountId) {
      this.toaster.warning('From and To Account cannot be the same Account!');
      return;
    }
   else if (this.TransferMoneyForm.value.Amount <= 0) {
      this.toaster.warning('Amount can not be less or equal to zero!');
      return;
    }
    this.spinner.show();
    this.transfermoneyService.saveTransferMoney(this.TransferMoneyForm.value).subscribe((x) => {
      console.log(x);
      this.toaster.success('Transaction has been successul.');
      this.spinner.hide();
      this.router.navigate(['/transfermoney/transfermoney-list']);
    }, err => {
      this.spinner.hide();
      this.toaster.error('ooh, something went wrong !');
    });
  }
}

