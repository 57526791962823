import { Component, OnInit, Input } from "@angular/core";
import { BillsServiceService } from "../../../service/Bill/bills-service.service";
import { AcountingServiceService } from "../../../service/Accounting/acounting-service.service";
import { EditBill } from "../../../models/income.model";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgSelectComponent } from "@ng-select/ng-select";
import { DataService } from "../../../service/EventEmitter/data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateItem } from "../../../models/product.model";
import { environment } from "src/environments/environment";
import { FileEmitterService } from "src/app/service/EventEmitter/file.emitter.service";
import { FormBuilder, FormGroup, FormArray, Validators,FormControl } from "@angular/forms";
import * as moment from "moment";
import { CreatePopupItemComponent } from "../../Products/create-popup-item/create-popup-item.component";
import { iQuidiActionType } from "src/app/enum/iquidiActionType";

@Component({
  selector: 'app-fixed-asset-edit-bill',
  templateUrl: './fixed-asset-edit-bill.component.html',
  styleUrls: ['./fixed-asset-edit-bill.component.css']
})

export class FixedAssetEditBillComponent implements OnInit {
  public aModel: CreateItem = new CreateItem();
  allCustomer: any = [];
  PurchaseableItems: any = [];
  allChartOfAccount: any = [];
  taxTypeList: any = [];
  taxRateList: any = [];
  billData: EditBill;
  isItemListUpdate = false;
  ReferenceNo: string = "";
  BillId: any;
  billNo: string = "";
  expenseAccountId: any;
  BillNo: number;
  billFound: boolean = false;
  chartOfAccount: any = [];
  paidBill: any = [];
  itemsTotalPaidAmount: number = 0;
  isPaymentBtnVisible: boolean = false;
  isUploaded: boolean = false;
  uploadCounter: number;
  filesToUpload: number = 0;
  baseurl = environment.baseUrl;
  baseFileUrl = environment.fileBaseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  percentDone: number;
  subTotalAmount: number = 0.0;
  Isconversion: boolean;
  loading = false;
  oldTypeId: number;
  forms;
  StatusId = 0;
  PostingModuleId: number = null;
  ExternalTransactionId: number = null;
  IsRedirect: boolean = false;
  canApprove: boolean = false;
  IsBasicBill: boolean;
  RepeatingTypeId: any;
  IsRepeating: boolean;
  totalFile: number = 0;
  accountName: any;
  repeatingTypeList: any = [
    { Id: 1, Name: "Daily" },
    { Id: 2, Name: "Weekly" },
    { Id: 3, Name: "Monthly" },
    { Id: 4, Name: "Yearly" },
  ];
  @Input() defaultAccountId;
  public billForm: FormGroup;
  public BillItems: FormArray;
  ifSubmit: boolean;
  ShowBillNo: string;
  StatusName: string;
  BillData: any = [];
  constructor(
    private mainService: MainServiceService,
    private billService: BillsServiceService,
    private dataService: DataService,
    private accountingService: AcountingServiceService,
    public ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private activeroute: ActivatedRoute,
    private settingService: SettingsServiceService,
    private fileEmitterService: FileEmitterService,
    public fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.BillId = this.activeroute.snapshot.paramMap.get("billID");
    this.initializeForm();
    this.getBillData();
    this.getFormData();
    this.activeroute.queryParams.subscribe((params) => {
      this.Isconversion = params.Isconversion == undefined ? false : true;
      this.IsRedirect = params.IsRedirect == undefined ? false : true;
    });
    this.dataService.change.subscribe((x) => {
      this.isItemListUpdate = x;
      this.getAllPurchaseableItem();
    });
  }
  public addItem(fullData:any, x: any, i: number): void {
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    this.BillItems.push(this.createItem(fullData, x, i));
  }
  public addBlankItem(): void {
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    this.BillItems.push(this.createBlankItem());
  }
  initializeForm() {
    this.billForm = this.fb.group({
      Id: [0],
      BillNo: [""],
      SupplierId: [null, Validators.required],
      ReferenceNo: [this.ReferenceNo],
      BillDate: [""],
      DueDate: [""],
      BillAmount: [0],
      IsConversion: [this.Isconversion],
      TaxTypeId: [""],
      PostingModuleId: [this.PostingModuleId],
      TaxAmount: [""],
      AdvanceTaxAmount: [""],
      ExternalTransactionId: [this.ExternalTransactionId],
      Notes: [""],
      CreatedAt: [""],
      CreatedById: [""],
      StatusId: [""],
      IsRepeating: [""],
      RepeatingTypeId: [""],
      IsBasicBill: [""],
      BillItems: this.fb.array([]),
      BillType: [1] // 0 = for normal bill, 1 = for fixed asset bill
    });
  }
  public createItem(fullData: any, x: any, i: number): FormGroup {
    if(x.ItemId == null) {
      return this.fb.group({
        Id: [x.Id],
        BillId: [x.BillId],
        ItemId: [x.ItemId],
        Description: [x.Description],
        Quantity: [x.Quantity],
        ReceivedItems: ["-"],
        UnitPrice: [x.UnitPrice],
        LineTotal: [x.LineTotal],
        AccountId: [x.AccountId],
        TaxRateId: [x.TaxRateId],
        AdvanceTaxRateId: [x.AdvanceTaxRateId],
      });
    }
    else{
      return this.fb.group({
        Id: [x.Id],
        BillId: [x.BillId],
        ItemId: [x.ItemId],
        Description: [x.Description],
        Quantity: [x.Quantity],
        ReceivedItems: [fullData.data.ReceivedItems[i]],
        UnitPrice: [x.UnitPrice],
        LineTotal: [x.LineTotal],
        AccountId: [x.AccountId],
        TaxRateId: [x.TaxRateId],
        AdvanceTaxRateId: [x.AdvanceTaxRateId],
      });
    }
  }
  public createBlankItem(): FormGroup {
    return this.fb.group({
      ItemId: [null],
      Description: ["", Validators.required],
      Quantity: [0, Validators.required],
      ReceivedItems: [0, Validators.required],
      UnitPrice: [0, Validators.required],
      LineTotal: [0, Validators.required],
      AccountId: [this.expenseAccountId, Validators.required],
      TaxRateId: [null],
      AdvanceTaxRateId: [null],
    });
  }
  getBillData = () => {
    this.billService.getBillsPermissionByBillNo(this.BillId).subscribe((x) => {
      if (x) {
        console.log("HellogetBillData", x);
        this.BillData = x.data;
        this.canApprove = x.canApprove;
        this.fileEmitterService.emitFileRefIdUpdate({ id: x.data.Id });
        this.billFound = true;
        this.StatusId = x.data.StatusId;
        this.StatusName = x.data.StatusName;
        this.subTotalAmount =
          x.data.BillAmount - x.data.TaxAmount - x.data.AdvanceTaxAmount;
        this.oldTypeId = x.data.RepeatingTypeId;
        this.GetListByBillId(this.BillNo, x.data);
        this.setParentData(x.data);
        console.log("All GetBillData", x);
        x.data.BillItems.forEach((item, i) => {
          this.addItem(x, item, i);
          console.log("Loop Is Working", item);
        });
        this.isDescriptiveBill();
      }
    });
  };

  isDescriptiveBill() {
    return this.BillData?.BillItems?.every( x => x.ItemId == null );
  }

  setParentData(x) {
    this.billForm.controls["Id"].patchValue(x.Id);
    this.billForm.controls["BillDate"].patchValue(x.BillDate);
    this.billForm.controls["DueDate"].patchValue(x.DueDate);
    // this.billForm.controls["ReferenceNo"].patchValue(
    //   x.ReferenceNo == "" ? "WH-Out-" + this.BillId : x.ReferenceNo
    // );
    this.billForm.controls["ReferenceNo"].patchValue(
      x.ReferenceNo
    );
    this.billForm.controls["SupplierId"].patchValue(x.SupplierId);
    //this.billForm.controls['BillNo'].patchValue(x.BillNo);
    this.billForm.controls["TaxTypeId"].patchValue(x.TaxTypeId);
    this.billForm.controls["Notes"].patchValue(x.Notes);
    this.billForm.controls["TaxAmount"].patchValue(x.TaxAmount);
    this.billForm.controls["AdvanceTaxAmount"].patchValue(x.AdvanceTaxAmount);
    this.billForm.controls["BillAmount"].patchValue(x.BillAmount);
    this.billForm.controls["IsRepeating"].patchValue(x.IsRepeating);
    this.billForm.controls["RepeatingTypeId"].patchValue(x.RepeatingTypeId);
    this.billForm.controls["IsConversion"].patchValue(x.IsConversion);
    this.billForm.controls["PostingModuleId"].patchValue(x.PostingModuleId);
    this.billForm.controls["ExternalTransactionId"].patchValue(
      x.ExternalTransactionId
    );
    this.billForm.controls["IsBasicBill"].patchValue(x.IsBasicBill);
    this.billForm.controls["CreatedAt"].patchValue(x.CreatedAt);
    this.billForm.controls["CreatedById"].patchValue(x.CreatedById);
    this.billForm.controls["StatusId"].patchValue(this.StatusId);
    this.show(x.BillDate);
    this.showSelectedDate(x.DueDate);
    let val = x.BillNo.toString();
    let pre = "",
      post = "";
    for (let i = 0; i < 6; i++) pre += val[i].toString();
    for (let i = 6; i < 10; i++) post += val[i].toString();
    console.log(pre, "ok ", post);
    this.billForm.get("BillNo").patchValue(post);
    this.BillNo = Number(post);
    this.ShowBillNo = "Bill-" + pre;
  }
  show(e) {
    let value = this.billForm.value.BillDate;
    $("#ebtest1").attr("min", value.split("T")[0]);
    $("#ebtest")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  showSelectedDate(e) {
    const value = this.billForm.value.DueDate;
    $("#ebtest1")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  getFormData = () => {
    this.getAllCustomer();
    this.getAllPurchaseableItem();
    this.getAllChartOfAccount();
    this.getTaxTypeList();
    this.getTaxRateList();
  };

  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
    });
  };
  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({ Id: "", Name: "Select" });
    });
  };

  GetListByBillId = (data, bill) => {
    this.itemsTotalPaidAmount = 0;
    this.spinner.show();
    this.billService.GetPaymentsByBillId(data).subscribe((x) => {
      this.spinner.hide();
      this.paidBill = x;
      for (let j = 0; j < this.paidBill.length; j++) {
        this.itemsTotalPaidAmount += this.paidBill[j].PaidAmount;
      }
      if (bill.BillAmount === this.itemsTotalPaidAmount) {
        this.isPaymentBtnVisible = false;
      }
      console.log(x);
    });
  };

  getAllPurchaseableItem = () => {
    this.billService
      .GetPurchaseableItems()
      .subscribe((x) => (this.PurchaseableItems = x));
  };

  getAllCustomer = () => {
    this.billService.GetAllContact().subscribe((x: []) => {
      this.allCustomer = x;
      console.log(this.allCustomer);
    });
  };

  getAllChartOfAccount = () => {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      this.allChartOfAccount = x.ExpenseList.filter(y => y.AccountTypeId == 2);
      this.accountName = x.AccountName
      this.expenseAccountId = this.allChartOfAccount.filter(x => x.AccountName == this.accountName)[0]?.Id;
      const myForm = (<FormArray>this.billForm.get("BillItems")).at(0);
      myForm?.patchValue({
        AccountId: this.expenseAccountId,
      });
    });
  };
  CreateNewCustomer = (customerName) => {
    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: false,
      IsSupplier: true,
    };
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("Contact has been successfully added !");
          this.loading = false;
          this.allCustomer.push(x["Data"]);
          let customerObj = this.allCustomer.filter(
            (x) => x.PrimaryPersonFirstName == customerName
          );
          this.billForm.controls["SupplierId"].patchValue(customerObj[0]["Id"]);
          this.getAllCustomer();
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.loading = false;
      }
    );
  };
  defaultAccountSettings = (item) => {
    const account = this.allChartOfAccount.filter(
      (x) => x.AccountCode === "429"
    );
    item.map((x) => {
      if (x.AccountId == null) {
        x.AccountId = account[0].Id;
      }
    });
  };

  saveAsSubmitBill = async () => {
    if (!(await this.checkBillNo())) return;
    this.billForm.get("StatusId").patchValue(2);
    this.ifSubmit = true;
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    let check = this.checkValidation(this.BillItems.value);
    if (this.billForm.invalid) {
      this.toaster.warning("Required field can not be empty!", "Warning!");
      return;
    } else if (this.BillItems.value.length === 0) {
      this.toaster.warning(
        "Please add at least one item to the Bill",
        "No Item Added!"
      );
      return false;
    } else if (!check) {
      this.toaster.warning("Required field can not be empty !");
      return false;
    }
    let preValue = "";
    for (let i = 5; i < this.ShowBillNo.length; i++)
      preValue += this.ShowBillNo[i].toString();
    this.billForm.value.BillNo =
      preValue + this.billForm.value.BillNo.toString();
    if (this.Isconversion == true) {
      this.saveConversionBalanceBillData(this.billForm.value);
    } else {
      this.updateAsDraftBillData(this.billForm.value);
    }
  };

  async checkBillNo() {
    var val = this.billForm.value.BillNo.toString();
    if (val == "" || val == null) {
      this.toaster.error("Please Input Bill No.!");
      return false;
    }
    console.log("this is bill no", val);
    if (val.length != 4) {
      this.toaster.error("Bill Number needs to be 4 digits.");
      return false;
    }

    return true;
  }

  updateBill = async () => {
    if (!(await this.checkBillNo())) return;
    this.ifSubmit = true;
    this.StatusId == 7
      ? this.billForm.get("StatusId").patchValue(7)
      : this.billForm.get("StatusId").patchValue(1);
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    let check = this.checkValidation(this.BillItems.value);
    if (this.billForm.invalid) {
      this.toaster.warning("Required field can not be empty!", "Warning!");
      return;
    } else if (this.BillItems.value.length === 0) {
      this.toaster.warning(
        "Please add at least one item to the Bill",
        "No Item Added!"
      );
      return false;
    } else if (!check) {
      this.toaster.warning("Required field can not be empty !");
      return false;
    }
    let preValue = "";
    for (let i = 5; i < this.ShowBillNo.length; i++)
      preValue += this.ShowBillNo[i].toString();
    console.log(this.ShowBillNo, "ok", preValue);
    this.billForm.value.BillNo =
      preValue + this.billForm.value.BillNo.toString();
    this.billForm.controls["BillNo"].patchValue(
      this.billForm.value.BillNo.toString()
    );
    //console.log(this.Isconversion)
    if (this.Isconversion == true) {
      this.saveConversionBalanceBillData(this.billForm.value);
    } else if (this.StatusId == 3) {
      this.billForm.get("StatusId").patchValue(3);
      this.UpdateApprovedBill(this.billForm.value);
    } else {
      this.updateAsDraftBillDataForSave(this.billForm.value);
    }
  };

  updateAsDraftBillData(billData) {
    this.spinner.show();
    console.log(billData);
    billData.IQuidiActionType = iQuidiActionType.Submit;
    this.billService.Update(billData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(
          `Bill ${this.billForm.value.BillNo} has been successfully Update`,
          "Success!"
        );
        this.redirectToListPage();
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
        if (this.billForm.value.BillNo.toString().length > 4)
          this.billForm.value.BillNo =
            this.billForm.value.BillNo.toString().substr(6, 4);
      }
    });
  }

  updateAsDraftBillDataForSave(billData) {
    this.spinner.show();
    console.log(billData);
    billData.IQuidiActionType = iQuidiActionType.Save;
    this.billService.Update(billData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(
          `Bill ${this.billForm.value.BillNo} has been successfully Update`,
          "Success!"
        );
        this.redirectToListPage();
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
        if (this.billForm.value.BillNo.toString().length > 4)
          this.billForm.value.BillNo =
            this.billForm.value.BillNo.toString().substr(6, 4);
      }
    });
  }

  UpdateApprovedBill(billData) {
    this.spinner.show();
    console.log(billData);
    billData.IQuidiActionType = iQuidiActionType.Update;
    this.billService.Update(billData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(
          `Bill ${this.billForm.value.BillNo} has been successfully Update`,
          "Success!"
        );
        this.redirectToListPage();
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
        if (this.billForm.value.BillNo.toString().length > 4)
          this.billForm.value.BillNo =
            this.billForm.value.BillNo.toString().substr(6, 4);
      }
    });
  }

  saveConversionBalanceBillData(billData) {
    this.spinner.show();
    this.billService.saveConversionBalanceBillData(billData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(
          `Bill ${this.billForm.value.BillNo} has been successfully send`,
          "Success!"
        );
        this.redirectToListPage();
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
        if (this.billForm.value.BillNo.toString().length > 4)
          this.billForm.value.BillNo =
            this.billForm.value.BillNo.toString().substr(6, 4);
      }
    });
  }
  checkValidation = (billItem) => {
    var b = true;
    billItem.forEach((element) => {
      if (
        element.Description == "" ||
        element.AccountId == null ||
        element.LineTotal <= 0 ||
        (this.billForm.value.TaxTypeId != 3 &&
          element.TaxRateId == null &&
          element.AdvanceTaxRateId == null)
      ) {
        b = false;
      }
    });
    return b;
  };
  saveBillData = async () => {
    if (!(await this.checkBillNo())) return;
    this.ifSubmit = true;
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    let check = this.checkValidation(this.BillItems.value);

    if (!this.billForm.value.SupplierId) {
      this.toaster.warning("Customer cannot be empty !", "Warning!");
      return;
    } else if (!this.billForm.value.BillDate) {
      this.toaster.warning("Bill date cannot be empty !", "Warning!");
      return;
    } else if (
      this.billForm.value.IsRepeating === true &&
      this.billForm.value.RepeatingTypeId == ""
    ) {
      this.toaster.warning("Repeating type cannot be empty !", "Warning!");
      return false;
    } else if (!this.billForm.value.DueDate) {
      this.toaster.warning("Due date cannot be empty !", "Warning!");
      return;
    } else if (this.BillItems.value.length === 0) {
      this.toaster.warning(
        "Please add at least one item to the Bill",
        "No Item Added!"
      );
      return false;
    } else if (!check) {
      this.toaster.warning("Please fill all the required fileds!");
      return false;
    }
    if (this.Isconversion == true) {
      this.saveConversionBalanceBillData(this.billForm.value);
      console.log(this.billData);
    } else {
      this.saveDefaultBillData(this.billForm.value);
    }
  };

  saveDefaultBillData(billData) {
    this.spinner.show();
    console.log(billData);
    let preValue = "";
    let temp = this.billForm.value.BillNo;
    for (let i = 5; i < this.ShowBillNo.length; i++)
      preValue += this.ShowBillNo[i].toString();
    this.billForm.value.BillNo =
      preValue + this.billForm.value.BillNo.toString();
      billData.IQuidiActionType = iQuidiActionType.Approve;
    this.billService.Update(billData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(
          `Bill ${this.billForm.value.BillNo} has been successfully Approved`,
          "Success!"
        );
        this.redirectToListPage();
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.billForm.controls["BillNo"].setValue(temp);
        this.spinner.hide();
      }
    });
  }

  private redirectToListPage() {
    let rUrl = this.getRedirectUrl();
    this.router.navigate([rUrl]);
  }

  public getRedirectUrl() {
    let rUrl = "fixed-asset/fixed-asset-bill-details/Bill-"+this.billForm.value.BillNo;

    if (this.PostingModuleId == 2 && this.IsRedirect) {
      //here 2 means project module
      rUrl = "project/details/" + this.ExternalTransactionId;
    }
    if (this.Isconversion) {
      rUrl = "accounting/historic-conversionbalancesBill";
    }
    return rUrl;
  }

  updateTotalPrice = () => {
    this.subTotalAmount = 0.0;
    let TaxAmount: number = 0.0;
    let AdvanceTaxAmount: number = 0.0;
    let totalPrice = 0.0;
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    this.BillItems.value.map((x) => {
      this.subTotalAmount = this.subTotalAmount + x.LineTotal;
      TaxAmount += this.getItemTaxAmount(x.LineTotal, x.TaxRateId);
      AdvanceTaxAmount += this.getItemTaxAmount(
        x.LineTotal,
        x.AdvanceTaxRateId
      );
    });
    switch (this.billForm.value.TaxTypeId) {
      case 1: //tax exclusive
        totalPrice = this.subTotalAmount + TaxAmount + AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
        totalPrice = this.subTotalAmount;
        break;
    }
    this.billForm.get("TaxAmount").patchValue(TaxAmount.toFixed(2));
    this.billForm
      .get("AdvanceTaxAmount")
      .patchValue(AdvanceTaxAmount.toFixed(2));
    this.billForm.get("BillAmount").patchValue(totalPrice.toFixed(2));

    if (this.billForm.value.TaxTypeId === 3) {
      this.checkTaxType();
    }
  };

  checkTaxType = () => {
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    this.BillItems.value.map((x, i) => {
      const myForm = (<FormArray>this.billForm.get("BillItems")).at(i);
      myForm.patchValue({
        TaxRateId: null,
        AdvanceTaxRateId: null,
      });
    });
  };

  private getItemTaxAmount(lineTotal: number, taxRateId: any) {
    let taxPrice = 0.0;
    if (taxRateId == "" || taxRateId == null) return taxPrice;

    var taxPercent = this.getTaxRatePercent(taxRateId);

    switch (this.billForm.value.TaxTypeId) {
      case 1: //tax exclusive
        taxPrice = (lineTotal * taxPercent) / 100;
        break;
      case 2: //tax inclusive
        taxPrice = Number(
          ((lineTotal * taxPercent) / (100 + taxPercent)).toFixed(2)
        );
        break;
      default: //no tax
        taxPrice = 0;
        break;
    }
    return taxPrice;
  }

  private getTaxRatePercent(taxRateId: any) {
    let taxRateObj = this.taxRateList.filter((x) => x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }

  updateDataBillItem = (itemId) => {
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    this.BillItems.value.map((x, i) => {
      x.LineTotal = x.Quantity * x.UnitPrice;
      this.BillItems.controls[i].get("LineTotal").patchValue(x.LineTotal);
    });
    this.updateTotalPrice();
  };

  changespurchaseableItems = (index) => {
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    for (let i = 0; i < this.PurchaseableItems.length; i++) {
      if (
        this.PurchaseableItems[i].Id ==
        this.BillItems.controls[index].value.ItemId
      ) {
        this.BillItems.controls[index]
          .get("ItemId")
          .patchValue(this.PurchaseableItems[i]["Id"]);
        this.BillItems.controls[index]
          .get("AccountId")
          .patchValue(
            this.PurchaseableItems[i]["IsTrackItem"] === true
              ? this.PurchaseableItems[i]["InventoryAccountId"]
              : this.PurchaseableItems[i]["PurchaseAccountId"]
          );
        this.BillItems.controls[index].get("Quantity").patchValue(1);
        this.BillItems.controls[index]
          .get("UnitPrice")
          .patchValue(this.PurchaseableItems[i]["PurchasePrice"]);
        this.BillItems.controls[index]
          .get("Description")
          .patchValue(this.PurchaseableItems[i]["PurchaseDescription"]);
        this.updateDataBillItem(this.BillItems.controls[index].value.ItemId);
      }
    }
  };

  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }

  openItemModal(selectItem: NgSelectComponent) {
    selectItem.close();
    const modalRef = this.modalService.open(CreatePopupItemComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.refId = 1;
    modalRef.result.then(
      (result) => {
        this.getAllPurchaseableItem();
      },
      (reason) => {
        console.log("Dismissed action: " + reason);
      }
    );
  }

  removeInvoiceItem = (itemId, i) => {
    this.BillItems = this.billForm.get("BillItems") as FormArray;
    this.BillItems.value.map((x, index) => {
      if (index === i) {
        this.BillItems.removeAt(index);
      }
    });
    this.updateTotalPrice();
  };

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  fileName: string;
  fileType: string;
  changeType = () => {
    if (this.billForm.value.IsRepeating === false) {
      this.billForm.get("RepeatingTypeId").patchValue(null);
    } else {
      this.billForm.get("RepeatingTypeId").patchValue(this.oldTypeId);
    }
  };

  isReceivedMax(i){
    let Quantity = this.BillItems.controls[i].get("Quantity").value;
    let ReceivedItem = this.BillItems.controls[i].get("ReceivedItems").value
    if(Quantity != ReceivedItem && ReceivedItem != 0){
      return true;
    }
    else{
      return false;
    }
  }
}

