<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">Edit Product</h4>
    <button type="button" class="close" aria-label="Close"
        (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">

    <div class="pb-4">
        <div class="row">
            
            
            <div class="col-md-12 f-ss-14px">
                <form [formGroup]="itemForm">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-3"> </div>
                        <div class="col-sm-9" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="exampleInputEmail1" style="font-weight: 600;">Item code</label>
                                <input type="text" class="form-control form-input" placeholder="Item Code"
                                    formControlName="ItemCode">
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="exampleInputEmail1" style="font-weight: 600;">Item Name</label>
                                <input type="email" class="form-control" style="width: 99%;" formControlName="ItemName"
                                    placeholder="Item Name">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <input type="checkbox" [disabled]="isTrackItem" [checked]="chckedboxpurchase"
                                    formControlName="IsPurchaseItem" (change)="showpurchaseDiv = !showpurchaseDiv"
                                    ng-model="showpurchaseDiv">
                                <label for="exampleInputEmail1" style="margin-left: 10px;">I purchase this
                                    item</label>
                            </div>
                        </div>
                        <div class="col-sm-9" style="display: flex;" [hidden]="showpurchaseDiv">
                            <div class="form-group col-sm-6">
                                <label for="exampleInputEmail1"style="font-weight: 600;" >Unit Price</label>

                                <input type="text" appDecimaNumericValidator style="width: 99%;"
                                    class="form-control form-input" placeholder="Purchase Unit Price"
                                    formControlName="PurchasePrice">
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="exampleInputEmail1"style="font-weight: 600;" >Cost of Goods Sale Account</label>
                                <ng-select required [items]="purchaseChartOfAccounts"
                                    formControlName="PurchaseAccountId" class="custom" [clearable]="false"
                                    bindLabel="AccountName" bindValue="Id" groupBy="GroupName">
                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                        style="display: inline-grid;">
                                        <span>{{item.AccountName}}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-md-12" style="display: contents;" [hidden]="showpurchaseDiv">
                            <div class="col-sm-3">
                                <div class="form-group"></div>
                            </div>
                            <div class="col-sm-9">
                                <div class="form-group col-sm-12">
                                    <label for="exampleInputEmail1" style="font-weight: 600;">Purchase Description</label>
                                    <textarea class="form-control form-input" placeholder="purchase description"
                                        formControlName="PurchaseDescription"> </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <input type="checkbox" [disabled]="isTrackItem" [checked]="chckedboxsell"
                                    formControlName="IsSaleItem" (change)="showsellDiv = !showsellDiv"
                                    ng-model="showsellDiv">
                                <label for="exampleInputEmail1" style="margin-left: 10px;">I sell this item</label>
                            </div>
                        </div>
                        <div class="col-sm-9" style="display: flex;" [hidden]="showsellDiv">
                            <div class="form-group col-sm-6">
                                <label for="exampleInputEmail1" style="font-weight: 600;">Unit Price</label>
                                <input type="text" style="width: 99%;" class="form-control form-input"
                                    placeholder="Sale Unit Price" formControlName="SaleUnitPrice"
                                    appDecimaNumericValidator>
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="exampleInputEmail1" style="font-weight: 600;">Sales Account</label>
                                <ng-select required [items]="sellChartOfAccounts" formControlName="SaleAccountId"
                                    class="custom" [clearable]="false" bindLabel="AccountName" bindValue="Id"
                                    groupBy="GroupName">
                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                        style="display: inline-grid;">
                                        <span>{{item.AccountName}}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-md-12" style="display: contents;" [hidden]="showsellDiv">
                            <div class="col-sm-3">
                                <div class="form-group"></div>
                            </div>
                            <div class="col-sm-9">
                                <div class="form-group col-sm-12">
                                    <label for="exampleInputEmail1"style="font-weight: 600;" >Sales Description</label>
                                    <textarea class="form-control form-input" placeholder="sale description"
                                        formControlName="SalesDescription"> </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <input type="checkbox" (change)="changeTrackDiv()" ng-model="showTrackDiv"
                                    formControlName="IsTrackItem" [attr.disabled]="data.IsTrackItem ? true : null">
                                <label for="exampleInputEmail1" style="margin-left: 10px;">I track {{data.IsTrackItem}} this item</label>
                            </div>
                        </div>
                        <div class="col-sm-9" style="display: flex;" [hidden]="showTrackDiv">
                            <div class="form-group col-sm-6">
                                <label for="exampleInputEmail1" style="font-weight: 600;">Inventory Asset Account</label>
                                <ng-select required [items]="inverntoryChartOfAccounts"
                                    formControlName="InventoryAccountId" class="custom" [clearable]="false"
                                    bindLabel="AccountName" bindValue="Id" groupBy="GroupName">
                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                        style="display: inline-grid;">
                                        <span>{{item.AccountName}}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12">
                <button type="button" style="float: right;margin-right: 14px;" (click)="saveCreateItem()"
                    class="eCount-def-btn ml-4">Save</button>
            </div>
        </div>
    </div>
</div>
