import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup,FormArray, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';
import { DataService } from '../../../service/EventEmitter/data.service';

@Component({
  selector: 'app-create-shipment',
  templateUrl: './create-shipment.component.html',
  styleUrls: ['./create-shipment.component.css']
})
export class CreateShipmentComponent implements OnInit {

  @Input() fromParent: any;
  public validate:boolean = false;
  public shipmentForm: FormGroup;
  public OrderShipmentItem: FormArray;
  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private salesService: SalesOrderService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm() {
    this.shipmentForm = this.fb.group({
      Id: [''],
      OrderId: [''],
      ShipmentNo: [''],
      DeliveryBy: [''],
      DeliveryTo: [''],
      DeliveryAddress: [''],
      DeliveryDate: [''],
      ArrivalDate: [''],
      StatusId: [''],
      CreatedAt: [''],
      CreatedById: [''],
      OrderShipmentItem: this.fb.array([])
    });
    this.getInitShipment(this.fromParent.SalesOrderId);
  }
  getInitShipment = (id:any) => {
    this.spinner.show();
    this.salesService.GetInitShipment(id).subscribe((x) => {
      if (x) {
          this.SetItem(x);
          x.OrderShipmentItem.forEach(item => {
          this.addItem(item);
        });
        this.spinner.hide();
      }
    })
  }
  SetItem(x:any) {
    this.shipmentForm = this.fb.group({
      Id: [x.Id],
      OrderId: [x.OrderId],
      ShipmentNo: [x.ShipmentNo],
      DeliveryBy: [x.DeliveryBy],
      DeliveryTo: [x.DeliveryTo],
      DeliveryAddress: [x.DeliveryAddress],
      DeliveryDate: [x.DeliveryDate],
      ArrivalDate: [x.ArrivalDate],
      StatusId: [x.StatusId],
      CreatedAt: [x.CreatedAt],
      CreatedById: [x.CreatedById],
      OrderShipmentItem: this.fb.array([])
    });
    this.showSelectedDate();
  }
    getControlLabel(type: string){
      return this.shipmentForm.controls[type].value;
     }
    public addItem(x:any): void {
      this.OrderShipmentItem = this.shipmentForm.get('OrderShipmentItem') as FormArray;
      this.OrderShipmentItem.push(this.createItem(x));
    }
    public createItem(x:any): FormGroup {
      return this.fb.group({
        Id: [x.Id],
        ShipmentId: [x.ShipmentId],
        OrderItemId:[x.OrderItemId],
        ItemName:[x.ItemName],
        Quantity:[x.Quantity],
        RemainingQuantity:[x.RemainingQuantity]
      });
    }
  public save() {
    if (this.shipmentForm.invalid){
      return;
    }
    if(!this.validate)
    {
      this.spinner.show();
      this.salesService.SaveShipment(this.shipmentForm.value).subscribe((x)=>{
        if (x.Success) {
          this.toaster.success('Saved successfully.');
          this.dataService.emitShipmentListUpdate(true);
          this.activeModal.close();
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      }, err => {
          this.toaster.error('ooh, something went wrong !');
      });
    }
  }
  showSelectedDate() {
    const value = this.shipmentForm.value.DeliveryDate;
    $("#itest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
  public checkQuantity(item){
    this.validate = false;
    this.shipmentForm.value.OrderShipmentItem.forEach(element => {
      if(element.OrderItemId==item.value.OrderItemId && element.RemainingQuantity<item.value.Quantity)
      {
        item.controls['Quantity'].patchValue(0);
        this.toaster.error("Quantity not available");
        this.validate = true;
        return;
      }
    });
  }
  public arrive() {
    if(!this.validate)
    {
      this.spinner.show();
      this.salesService.Arrived(this.shipmentForm.value).subscribe((x)=>{
        if (x.Success) {
          this.toaster.success('Successfully Shipped.');
          this.dataService.emitShipmentListUpdate(true);
          this.activeModal.close();
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      }, err => {
          this.toaster.error('ooh, something went wrong !');
      });
    }
  }
}
