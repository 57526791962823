<app-dashboard>
    <app-page-header-title mainTitle="Edit Factory Out" subTitle="Back" backURL="/inventory/factory-out-list/All">
      <div class="full-width text-align-right">
        <div class="dropdown">
          <div class="x-tip-tl" style="height: 0px">
            <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
          </div>
        </div>
        <button type="button"(click)="saveFactoryOut()" class="eCount-def-btn ml-4"> Save </button>
        <button type="button" style="background: #5cac00;" (click)="approveFactoryOut()" class="eCount-def-btn ml-4"> Approve</button>
      </div>
    </app-page-header-title>
    <div class="headSection">
      <form [formGroup]="factoryOutForm" class="f-ss-14px">
        <div class="row f-ss-14px">
          <div class="col-md-3 col-sm-12">
            <label for="OutDate">Date<span class="required-sign">*</span></label>
            <input
              class="form-control"
              type="date"
              formControlName="OutDate"
              id="fotest1"
              data-date="" data-date-format="D MMM YYYY"
              (change)="showSelectedDate($event)"
              required
            />
          </div>
  
          <div class="col-md-3 col-sm-12">
            <label for="ReferrenceNo">Product<span class="required-sign">*</span></label>
            <select required  class="form-control customSaleItem" (change)="loadRawMaterials()" style="width: 100%;" formControlName="ProductId">
                <option value="">Select</option>
                <option *ngFor="let s of productList" [ngValue]="s.Id">{{s.ItemName}}</option>
            </select>
          </div>
          <div class="col-md-3 col-sm-12">
            <label for="ProductOutQuantity">Quantity<span class="required-sign">*</span></label>
            <input
              class="form-control"
              appDecimaNumericValidator
              (change)="updateOperatingPrice()"
              formControlName="ProductOutQuantity"
              required
            />
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="InDate">Batch No<span class="required-sign">*</span></label>
            <input required
              class="form-controlinputBox"
              type="text"
              formControlName="BatchNo"
            />
          </div>
        </div>
  
        <div class="row d-block topSection f-ss-14px">
            <div class="d-flex">
                <div class="col-md-2">Item Code</div>
                <div class="col-md-2">Item Name</div>
                <div class="col-md-2">Stock Qty</div>
                <div class="col-md-2">Used Qty<span class="required-sign">*</span></div>
                <div class="col-md-2">Price/Unit</div>
                <div class="col-md-2">Amount</div>
              </div>
              <hr class="pb-0 mb-0" />
              <div formArrayName="ProductionOutItem" *ngFor="let item of factoryOutForm.get('ProductionOutItem')['controls']; let i = index;">
                 
                 <div class="invoiceGridItemRow d-block">
                  <div class="row" [formGroupName]="i">
      
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        type="text"
                        formControlName="ItemCode"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        type="text"
                        formControlName="ItemName"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        formControlName="StockQty"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                        class="form-control"
                        appDecimaNumericValidator
                        (change)="updateItemPrice(item,i)"
                        formControlName="UsedQty"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5">
                        <input
                        readonly
                        class="form-control"
                        formControlName="MaterialCost"
                      />
                      </div>
                      <div class="col-md-2 margin-top-5 d-flex">
                        <div>
                        <input
                        readonly
                        class="form-control"
                        formControlName="LineTotal"
                      /> </div>
                      <div style="padding: 7px 10px; color: #c7c2c2;">
                        <div class="dropdown mr-4">
                            <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                aria-expanded="false">
                                <i class="icon-more float-right"></i>
                            </div>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                style="width: 100%;cursor: pointer;">
                                <div class="productRow pb-3" (click)="deleteItem(i)">
                                    <span class="lead1">Remove Item </span>
                                </div>
                            </div>
                        </div>
                    </div>
                      </div>
                     
                  </div>
                    </div>
              </div>

        </div>

       
        <div class="col-md-12 row nml">
          <div class="f-ss-14px col-md-7">
          <h3 style="margin-top: 40px;" class="ml-3">Finished Product Costs</h3>
  
          <div class="col-md-12 row">
            <div class="col-md-5">
              <label for="PerUnitOperatingCost">Operating Cost/Unit<span class="required-sign">*</span></label>
              <input
              class="form-control"
              appDecimaNumericValidator
              (change)="updateProductPrice()"
              formControlName="PerUnitOperatingCost"
              readonly
              required
            />
            <div formArrayName="OperatingCostItem"   *ngFor="let items of factoryOutForm.get('OperatingCostItem')['controls']; let ix = index;">
                 
              <div class="invoiceGridItemRows d-block ml-3">
               <div class="row" [formGroupName]="ix">
   
                   <div class="col-md-12 row mt-1">
                      <input type="text" formControlName="Name" class="form-control col-md-6" placeholder="Name">
                       <input type="number" class="form-control col-md-6" (change)="updateOperatingPrice()" appDecimaNumericValidator formControlName="Amount">
                   </div>
                   <div class="col-md-1 d-flex">
                       <div style="margin-top: 15px; color: #c7c2c2;">
                           <div class="dropdown">
                               <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                   aria-expanded="false">
                                   <i class="icon-more float-right"></i>
                               </div>
                               <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                   style="width: 100%;cursor: pointer;">
                                   <div class="productRow pb-3" (click)="deleteCostItem(ix)">
                                       <span class="lead1">Delete</span>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
                 </div>
           </div>
           <div>
            <div class="dropdown mr-4">
              <a
                class="nav-link"
                style="background-color: white"
                href="#"
                id="navbarDropdown"
                role="button"
                (click)="addCostItem()"
                data-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <img
                  class="languageImg"
                  src="../../../assets/image/addIcon.png"
                />
                Add Operating Cost
              </a>
            </div>
          </div>
            </div>
      
              <div class="col-md-3 col-sm-12">
              <label for="PerUnitMaterialCost">Material Cost/Unit</label>
              <input
              readonly
              type="number"
              class="form-control"
              formControlName="PerUnitMaterialCost"
              required
            />
            </div>
              <div class="col-md-4 col-sm-12">
                <label for="PerUnitProductionCost">Production Cost/Unit</label>
                <input
                readonly
                class="form-control"
                formControlName="PerUnitProductionCost"
                required
              />
              
              </div>
            </div>
          </div>
          <div class="col-md-5">
          <h3 style="margin-top: 40px;" class="ml-4">Sales Order List</h3>
          <div class="row col-md-12 d-block f-ss-14px">
            <div class="d-flex pb-2 ml-2" style="border-bottom: 1px solid rgba(0,0,0,.1)">
              <div class="col-md-6">Sales Order No</div>
              <div class="col-md-6">Booked Quantity</div>
            </div>
            <div formArrayName="FactoryOutSalesOrder" *ngFor="let item of factoryOutForm.get('FactoryOutSalesOrder')['controls']; let i = index;">
               
               <div class="invoiceGridItemRow d-block">
                <div class="row" [formGroupName]="i" style="margin-left: 4px;">
    
                    <div class="col-md-6 margin-top-5">
                        <ng-select [items]="salesOrders" [clearable]="false" bindLabel="Name" bindValue="Id" formControlName="OrderId"></ng-select>
                    </div>
                    <div class="col-md-4 margin-top-5">
                        <input  class="form-control" (change)="checkSalesQuantity(item)" appDecimaNumericValidator formControlName="BookedQuantity">
                    </div>
                    <div class="col-md-2 margin-top-5 d-flex">
                        <div style="padding: 7px 10px; color: #c7c2c2;">
                            <div class="dropdown mr-4">
                                <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                    aria-expanded="false">
                                    <i class="icon-more float-right"></i>
                                </div>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                    style="width: 100%;cursor: pointer;">
                                    <div class="productRow pb-3" (click)="deleteSalesItem(i)">
                                        <span class="lead1">Delete</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                  </div>
                </div>
            </div>
            <hr class="pb-0 mb-0" style="width: 92%;margin-left: 2%" />
              <div class="w-100">
                <div class="dropdown mr-4">
                  <a
                    class="nav-link"
                    style="background-color: white"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    (click)="addSalesOrderBlankItem()"
                    data-toggle="dropdown"
                    aria-haspopup="false"
                    aria-expanded="false"
                  >
                    <img
                      class="languageImg"
                      src="../../../assets/image/addIcon.png"
                    />
                    Add New Line
                  </a>
                </div>
              </div>
            </div>
            <!-- <hr style="width: 45%;margin-left: 2%;margin-top: 2px;" /> -->
            <!-- <button
              type="button"
              (click)="SaveFactoryOutSalesOrder()"
              class="eCount-def-btn ml-4">
              Save
            </button> -->
          </div>
      </form>
    </div>
  </app-dashboard>
  
