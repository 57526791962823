import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BillInvoiceStatus } from 'src/app/enum/bill-invoice-status';
import { PaymentHistoryPaymentType } from 'src/app/enum/payment-history-payment-type';
import { WareHouseOperationType } from 'src/app/enum/wareHouseOperationType';
import { PaymentHistory } from 'src/app/models/paymentHistory.model';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';

@Component({
  selector: 'app-return-payment-history',
  templateUrl: './return-payment-history.component.html',
  styleUrls: ['./return-payment-history.component.css']
})

export class ReturnPaymentHistoryComponent implements OnInit {

  paymentList: any[];
  billPaymentList: any[];
  invoicePaymentList: any[];
  filteredPaymentList: any[];

  paymentType: number;
  operationType: string;
  searchText: string;
  mainTitle: string;

  gridApi;
  rowSelection = 'multiple';
  domLayout = "autoHeight";
  rowHeight = 275;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private acountingServiceService: AcountingServiceService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.operationType = this.route.snapshot.paramMap.get("operationType");
    this.paymentType = Number(this.route.snapshot.paramMap.get("paymentType"));
    this.mainTitle = this.setMainTitle();
    this.getPaymentList();
  }

  getPaymentList() {
    this.acountingServiceService.getPaymentList().subscribe((x) => {
      this.paymentList = x;
      for(var i = 0; i < this.paymentList.length; i++){
        this.paymentList[i].FormattedPaymentNo = 'BankP-'+ this.paymentList[i].PaymentHistoryData.PaymentNo;
        this.paymentList[i].Supplier = this.paymentList[i].Contacts.CompanyName;
        this.paymentList[i].Amount =  this.paymentList[i].PaymentHistoryData.Amount;

        if(this.paymentList[i].PaymentHistoryData.OperationType == WareHouseOperationType.Invoice) {
          this.paymentList[i].FormattedReferenceNo = 'INV-'+ this.paymentList[i].InvoiceData.InvoicceNo;
          this.paymentList[i].Status =  this.formatStatus(this.paymentList[i].InvoiceData.StatusId);
        }
        else {
          this.paymentList[i].FormattedReferenceNo = 'Bill-'+ this.paymentList[i].BillData.BillNo;
          this.paymentList[i].Status =  this.formatStatus(this.paymentList[i].BillData.StatusId);
        }
      }

      this.billPaymentList = this.paymentList?.filter((x) => x.PaymentHistoryData.OperationType == WareHouseOperationType.Bill && x.PaymentHistoryData.PaymentType == this.paymentType);
      this.invoicePaymentList = this.paymentList?.filter((x) => x.PaymentHistoryData.OperationType == WareHouseOperationType.Invoice && x.PaymentHistoryData.PaymentType == this.paymentType);
      this.pageLoad(this.operationType);
    });
  }

  formatStatus(statusId: number) {
    let status = '';
    switch(statusId) {
      case BillInvoiceStatus.DRAFT:
        status = 'Draft';
        break;
      case BillInvoiceStatus.AWAITINGAPPROVAL:
        status = 'Awaiting Approval';
        break;
      case BillInvoiceStatus.AWAITINGPAYMENT:
        status = 'Awaiting Payment';
        break;
      case BillInvoiceStatus.PAID:
        status = 'Paid';
        break;
      case BillInvoiceStatus.OVERDUE:
        status = 'Overdue';
        break;
      case BillInvoiceStatus.REJECTED:
        status = 'Rejected';
        break;
    }
    return status;
  }

  public pageLoad(operationType: string) {
    this.operationType = operationType;
    switch (operationType) {
      case 'Bill':
        this.filteredPaymentList = this.billPaymentList;
        break;
      case 'Invoice':
        this.filteredPaymentList = this.invoicePaymentList;
        break;
    }
  }

  ColumnDefs = [
    { 
      headerName: "Number",
      field: "FormattedPaymentNo",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true
    },
    { 
      headerName: "Reference No", 
      field: "FormattedReferenceNo",
      cellRenderer: function (params) {
        if(params.data.PaymentHistoryData.OperationType == WareHouseOperationType.Bill)
          return `<a href="#/expense/bill/${params.data.FormattedReferenceNo}" >${params.data.FormattedReferenceNo}</a>`;
        if(params.data.PaymentHistoryData.OperationType == WareHouseOperationType.Invoice)
          return `<a href="#/income/invoice/${params.data.FormattedReferenceNo}" >${params.data.FormattedReferenceNo}</a>`;
      }
    },
    { headerName: "Date", field: "CreatedAt", valueFormatter: this.convertDateIntoDisplay },
    { headerName: "Supplier", field: "Supplier" },
    { headerName: "Amount", field: "Amount" },
    { headerName: "Status", field: "Status" },
    { 
      headerName: "Deletable", 
      cellRenderer: function (params) {
        if(params.data.PaymentHistoryData.IsDeletable == true)
          return `<img class="text-center mb-1" src="../../../assets/icons/delete.png" width="20px" height="20px">`;
        else
          return '';
      }
    }
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
    scrollbarWidth: 0,
    resizable: true
  };

  onGridReady(event) {
    this.gridApi = event.api;
  }

  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }

  openReferenceDetails(data: any){
    if(data.OperationType == WareHouseOperationType.Bill)
      return `<a href="#/expense/bill/${data.RefrenceNo}" >${data.RefrenceNo}</a>`;
    if(data.OperationType == WareHouseOperationType.Invoice)
      return `<a href="#/income/invoice/${data.RefrenceNo}" >${data.RefrenceNo}</a>`;
  }

  getSelectedPaymentHistory() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    if (selectedData.length === 0) {
      this.toastr.warning('Please select at least one invoice!');
      return;
    }
    if (selectedData.some(x => x.PaymentHistoryData.IsDeletable == false)) {
      this.toastr.warning('You can not delete payment history which has not deletable!');
      return;
    }
    let paymentHistories = this.getPaymentHistories(selectedData);
    this.deletedPaymentHistory(paymentHistories);
  }

  getPaymentHistories(selectedData: any[]): PaymentHistory[] {
    const paymentHistories = [];
    for (const paymentHistory of selectedData) {
      paymentHistories.push(paymentHistory.PaymentHistoryData);
    }
    return paymentHistories;
  }

  deletedPaymentHistory(paymentHistories: PaymentHistory[]): void {
    this.spinner.show();
    this.acountingServiceService.DeletedPaymentHistory(paymentHistories).subscribe((x) => {
      this.toastr.success('Payment history successfully deleted.');
      this.spinner.hide();
      location.reload();
    });
  }

  setMainTitle() {
    switch(this.paymentType) {
      case PaymentHistoryPaymentType.BANK:
        return 'Bank Payments';
      case PaymentHistoryPaymentType.CASH:
        return 'Cash Payments';
      case PaymentHistoryPaymentType.DIGITALCASH:
        return 'Digital Wallet Payments';
    }
  }

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  search = () => {

  }

}

