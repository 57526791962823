
<app-dashboard>
    <app-page-header-title
        mainTitle="{{'Accounting.chartofAccounts'|translate}}"
        subTitle="Home"
        backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" placeholder="search"   class="searchBox form">
            <button type="button" routerLink="/accounting/create-chart-of-accounts" class="eCount-def-btn ml-4">{{'Accounting.chartOfAccounts.addAccounts' | translate}}</button>
            <button type="button" routerLink="/accounting/bank-accounts-create" class="eCount-def-btn ml-4">{{'Accounting.chartOfAccounts.addBankAccount' | translate}}</button>
        </div>
            </app-page-header-title>
         
    <div class="bodySection">
        <div class="row noPrint">
            <div class="col-md-9">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active f-ss-14px" data-toggle="tab" href="#ALL">{{'Accounting.chartOfAccounts.allAccounts' | translate}}</a>
                    </li>
                    <li class="nav-item" *ngFor="let item of chartOfAccountsMenu">
                        <a class="nav-link  f-ss-14px" data-toggle="tab" [href]="'#'+item.AccountName">{{item.AccountName}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-3 text-align-right">
                <span *ngIf="enableDelete">
                    <button (click)="deleteAccounts()"  class="eCount-def-btn" > Delete</button>
                </span>
                <span *ngIf="!enableDelete">
                    <button (click)="deleteAccounts()" disabled class="eCount-def-btn" > Delete</button>
                </span>
                <button (click)="convetToPDF()" class="p-color ml-4 pl-4 pr-4 eCount-button3 font-weight-normal float-right">{{'Contacts.contactList.export'| translate}}</button>
            </div>
        </div>
        <div class="row" id="chartOfAccount">            
            <div class="tab-content w-100 col">
                    <div class="tab-pane" [id]="item.AccountName" *ngFor="let item of chartOfAccountsMenu">
                        <table class="table  f-ss-14px">
                            <thead>
                                <tr class="tableHeader">
                                    <th>
                                        
                                    </th>
                                    <th scope="col">{{'Accounting.chartOfAccounts.code' | translate}}</th>
                                    <th scope="col">{{'Accounting.chartOfAccounts.name' | translate}}</th>
                                    <th scope="col">{{'Accounting.chartOfAccounts.type' | translate}}</th>
                                    <th scope="col">{{'Accounting.chartOfAccounts.amount' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of getFilteredData(item.Id) |filter:searchText">
                                    <td>
                                        <span style="margin-left: -4px;" *ngIf="!item.CanDelete"><i style="font-size: 21px;" class="material-icons">lock_outline</i></span>
                                        <span *ngIf="item.CanDelete"><input type="checkbox" (click) ="selectToDelete(item)"></span>    
                                    </td>
                                    <td>{{item.AccountCode}}</td>
                                    <td (click)="editAccount(item.Id)" style="cursor: pointer;" >
                                        <span class="titleText d-block">{{item.AccountName}}</span>
                                    </td>
                                    <td>{{item.AccountTypeName}}</td>
                                    <td>{{formatAmount(item.Amount)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id="ALL" class="tab-pane active  f-ss-14px">
                            <table class="table">
                                <thead>
                                    <tr class="tableHeader">
                                        <th>
                                            
                                        </th>
                                        
                                        <th scope="col">{{'Accounting.chartOfAccounts.code' | translate}}</th>
                                        <th scope="col">{{'Accounting.chartOfAccounts.name' | translate}}</th>
                                        <th scope="col">{{'Accounting.chartOfAccounts.type' | translate}}</th>
                                        <th scope="col">{{'Accounting.chartOfAccounts.amount' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of chartOfAccountsData | filter:searchText">
                                        <td>
                                            <span style="margin-left: -4px;" *ngIf="!item.CanDelete"><i style="font-size: 21px;" class="material-icons">lock_outline</i></span>
                                            <span *ngIf="item.CanDelete"> <input type="checkbox" (click) ="selectToDelete(item)"></span>
                                        </td> 
                                        <td>{{item.AccountCode}}</td>
                                        <td (click)="editAccount(item.Id)" style="cursor: pointer;">
                                            <span class="titleText d-block">{{item.AccountName}}</span>
                                        </td>
                                        <td>{{item.AccountTypeName}}</td>
                                        <td>{{formatAmount(item.Amount)}}</td>
                                    </tr>
                                </tbody>
                            </table>

                    </div>
                </div>
            </div>
    </div>
</app-dashboard>