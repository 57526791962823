<app-dashboard>
<header id="page_title" class="title-c">
    <div>
        <h1>
            My Ecount
        </h1>
        
<div class="x-partner-menu">
<h2></h2>
</div>

</div>
</header>
<section class="x-welcome border">
	<div class="x-content">
		<h2>
			Hi <span class="mr-2">Sadik,</span> 
			    <span class="normal">you last logged into
			        <a style="color: blue;">reza</a> today at 11:35 AM
			    </span>
		</h2>
	</div>
</section>
<div class="col-sm-12 row">
<div class="col-sm-8">
<h2 class="mt-4 mb-2">Organizations</h2>
<div class="tableHeader d-flex col-sm-12" >
    <div class="x-column-header-inner col-sm-3">
         <p class="headerText ml-2">Name</p>
         
    </div>
    <div class="x-column-header-inner col-sm-3" >
        <p class="headerText ml-2">Created At</p>
   </div>
   <div class="x-column-header-inner col-sm-3" >
    <p class="x-column-header-text ml-2">Expiry At</p>
  </div>
  <div class="x-column-header-inner col-sm-3" >
    <p class="x-column-header-text ml-2">Subscription</p>
  </div>
</div>
<div>
    <tbody *ngFor="let item of SubscriptionList" class="col-sm-12">
    <tr style="height: 53px;">
        <td  class="pl-2 pt-2 tb font-weight-bold col-sm-3">
            {{item.CompanyName}}
        </td>
        <td  class="pl-2 bb col-sm-3">{{item.StartDateVw}}</td>
        <td  class="pl-2 bb col-sm-3">{{item.ExpiryDateVw}}</td>
        <td  class="pl-2 br col-sm-3">
            <div class="x-grid-cell-inner" style="text-align:left;">
                <div class="top"><span class="ref cursor-pointer" (click)="goUrl()">Pay Now</span> </div>
                <div class="extras" *ngIf="item.ExpiryStatus=='Expired'">{{item.PackageTypeName}} Expired</div>
                <div class="extras" *ngIf="item.ExpiryStatus=='Live'">In {{item.PackageTypeName}}
                </div>  
            </div>
        </td>
    </tr>
    </tbody>
</div>
</div>
<div class="col-sm-4">
    <section class="x-column" style="width: 206px; margin-top: 63px; margin-right: 0;">

        <div class="x-fields x-white x-callout">
            <h3>
                Run another business?
            </h3>
            <p>
                
            </p>
            <button class="eCount-def-btn" style="width: 164px;" (click)="goCompanyUrl()">Add an organisation</button>
        </div>
    
    </section>
</div>
</div>
</app-dashboard>