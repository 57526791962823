import { Component, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import { ToastrService } from 'ngx-toastr';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ReportServiceService } from 'src/app/service/Report/report-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-statement-of-cashflow',
  templateUrl: './statement-of-cashflow.component.html',
  styleUrls: ['./statement-of-cashflow.component.css']
})
export class StatementOfCashflowComponent implements OnInit {
  fromDate: string="" ;
  toDate: string="";
  CashFlowData:any;
 
  len:number = 0;
  
  companyName: string ="";

  constructor(private spinner: NgxSpinnerService,
    private reportService: ReportServiceService,
    private router: Router, 
    private toaster: ToastrService) { }

  ngOnInit(): void {
    this.initialiseOrganization();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.fromDate = firstDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + firstDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + firstDay.toLocaleDateString('en-GB', { day: '2-digit'});
    this.toDate = lastDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + lastDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + lastDay.toLocaleDateString('en-GB', { day: '2-digit'});
    this.show(this.fromDate);
    this.showSelectedDate(this.toDate);
  }
  initialiseOrganization(){
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.companyName = userInfo.SelectedCompany.Name;
    }
  }
  reportGenerate = () => {
    if (!this.fromDate) {
      this.toaster.warning('To Date and From date are required !');
      return;
    }

    let data = {
      fromDate: this.fromDate,
      toDate: this.toDate
    }

    this.spinner.show();
    this.reportService.GetCashFlowStatement(data).subscribe((x) => {
      this.spinner.hide();
      this.CashFlowData=x;
    })
  }

  /**
   * movementDetails
   */
  public movementDetails(movementId:number) {
    this.router.navigate(['/reports/cashflow-movement-details/'+movementId +'/'+this.fromDate+'/'+this.toDate]);
  }

  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  convetToPDF = () => {
    var data = document.getElementById('cashflow');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      let fromDate=this.convertDateIntoDisplay(this.fromDate);
      let toDate=this.convertDateIntoDisplay(this.toDate);
      var DocumentName = `Statement of cash flows report (${fromDate} to ${toDate}).pdf)`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    })
  }
  show(e) {
    const value = this.fromDate;
    $("#scftest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  showSelectedDate(e) {
    const value = this.toDate;
    $("#scftest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
}
