import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class BusinessOwnerService {

  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }
  public SendMail(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Bill/SendMail', data).pipe(retry(1));
  }

  // business owner
  public GetOwnerList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'BusinessOwner/GetBusinessOwnerList').pipe(retry(1));
  }
  public SaveBusinessOwner(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BusinessOwner/Save', data).pipe(retry(1));
  }

  public UpdateBusinessOwner(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BusinessOwner/Update', data).pipe(retry(1));
  }
  public GetBusinessOwner(id): Observable<any>{
    return this.http.get<any>(this.baseurl + 'BusinessOwner/GetBusinessOwner?Id=' + id).pipe(retry(1));
  }

  // share capital
  public GetShareCapitalList(id): Observable<any>{
    return this.http.get<any>(this.baseurl + 'BusinessOwner/GetShareCapitalList?ownerId=' + id).pipe(retry(1));
  }
  public SaveShareCapital(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BusinessOwner/SaveShareCapital', data).pipe(retry(1));
  }

  // owner loan
  public SaveLoan(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BusinessOwner/SaveLoan', data).pipe(retry(1));
  }
  public GetLoanList(id): Observable<any>{
    return this.http.get<any>(this.baseurl + 'BusinessOwner/GetOwnerLoanList?ownerId=' + id).pipe(retry(1));
  }

  // loan settlement

  GetOwnerLoanbyId(id): Observable<any> {
    return this.http.get<any>(this.baseurl + 'BusinessOwner/GetOwnerLoan?id=' + id).pipe(retry(1));
  }
  SaveGrantSettleMent(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BusinessOwner/SaveGrantSettlement', data).pipe(retry(1));
  }
  SaveReceiveSettleMent(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'BusinessOwner/SaveReceiveSettlement', data).pipe(retry(1));
  }
}
