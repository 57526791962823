import { Component, ViewChild, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ConversionbalancesService } from '../../../../service/ConversionBalance/conversionbalances.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridAngular } from 'ag-grid-angular';
import { RouterLinkRendereComponent } from '../../../../components/router-link-rendere/router-link-rendere.component';

@Component({
  selector: 'app-historic-conversionbalances-bill',
  templateUrl: './historic-conversionbalances-bill.component.html',
  styleUrls: ['./historic-conversionbalances-bill.component.css']
})
export class HistoricConversionbalancesBillComponent implements OnInit {

  constructor(public ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private conversionBalanceService: ConversionbalancesService,
    private spinner: NgxSpinnerService) { }

  //#region Properties

  private gridApiBill;
  private gridColumnApiBill;
  allBills: any = [];
  TotalBillAmount: any;
  PayableCalculateBalance: any;
  TotalCredit: any;
  btndisbaled: boolean = true;
  previousConversionDate: any;
  IsBillMatch: boolean = false;
  BillAdjustmentDue: boolean;
  InvoiceAdjustmentDue: boolean;

  @ViewChild("agGridbill", { static: false }) agGridbill: AgGridAngular;

  //#endregion

  //#region CommonMethod

  ngOnInit(): void {
    this.getConversionDate();
  }

  getConversionDate() {
    this.conversionBalanceService.getConversionConfig().subscribe((res) => {
      if (res != null) {
        let conversionMonth = res['ConversionMonth'];
        var currentDate = new Date();
        currentDate.setMonth(conversionMonth - 1);
        currentDate.setDate(1);
        currentDate.setHours(-1);
        this.previousConversionDate = currentDate.toString()
      }
    }, (err) => { this.toaster.error("Something went wrong. Please check.", "Error"); })
  }

  convertDateIntoDisplay(date) {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }
  openBillPopup() {
    this.router.navigate(['expense/create-new-bill'], { queryParams: { Isconversion: true } });
  }

  NextBtnClick() {

    if (this.BillAdjustmentDue == false && this.InvoiceAdjustmentDue == false) {
      this.router.navigate(['accounting/existing-balances']);
    }
    else if (this.InvoiceAdjustmentDue == true) {
      this.router.navigate(['accounting/historic-conversionbalancesInvoice']);
    }

  }

  //#endregion

  //#region BillGrid
  gridOptionsBill = {
    columnDefs: [
      {
        headerName: 'Number', field: 'BillNo',
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: {
          inRouterLink: '/expense/bill/',
        }
      },
      { headerName: 'Ref', field: 'ReferenceNo' },
      { headerName: 'To', field: 'Contacts.PrimaryPersonFullName' },
      { headerName: 'Date', field: 'BillDate',valueFormatter: this.convertGridDateIntoDisplay },
      { headerName: 'Due Date', field: 'DueDate',valueFormatter: this.convertGridDateIntoDisplay },
      { headerName: 'Amount Due', field: 'PaidAmount', cellStyle: { textAlign: 'right' }, valueFormatter: this.PaidBillAmount },
      { headerName: '', width: 25, minWidth: 20, maxWidth: 30, suppressMenu: true, suppressSorting: true, template: `<span  (click)="deleteBillRows()"><img class="icon-Trash" src="../../../../assets/image/icon-Trash.png"></span>` }
    ],

    defaultColDef: {
      flex: 1,
    },
    domLayout: 'autoHeight',
    rowHeight: 275,
  }

  PaidBillAmount(value) {
    let valueData = Number(value.data.BillAmount.toFixed(2))
    return valueData.toLocaleString();
  }

  onGridReadyBill(event) {
    this.gridApiBill = event.api;
    this.gridColumnApiBill = event.columnApi;
    this.getAllBill();
  }

  getAllBill() {
    this.spinner.show();
    this.conversionBalanceService.getConversionBalance().subscribe((res) => {
      if (res != null) {
        this.TotalCredit = res["BillAccount"].Credit;
        this.allBills = res["BillList"];
        this.TotalBillAmount = res["TotalBillAmount"];
        this.BillAdjustmentDue = res["BillAdjustmentDue"];
        this.InvoiceAdjustmentDue = res["InvoiceAdjustmentDue"];
        this.PayableCalculateBalance = this.TotalCredit - this.TotalBillAmount;
        this.btndisbaled = this.TotalCredit == this.TotalBillAmount ? false : true;
        this.IsBillMatch = this.TotalCredit == this.TotalBillAmount ? true : false;
        this.spinner.hide();
      }
      else {
        this.toaster.error("Something went wrong. Please check.", "Error");
        this.spinner.hide();
      }
    }, (err) => {
      this.toaster.error("Something went wrong. Please check.", "Error");
      this.spinner.hide();
    })
  }

  onBillRowClicked(e) {
    let colHeaderName = this.gridApiBill.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "") {
      this.deleteBillRows(e);
    }
  }

  deleteBillRows(e) {
    this.conversionBalanceService.deleteConversionBalanceBill(e.data.Id).subscribe((res) => {
      if (res["Success"]) {
        this.toaster.success('Bill Successfully Deleted !', 'Success!');
        this.getAllBill();
      }
      else {
        this.toaster.error("Something went wrong. Please check.", "Error");
      }
    }, (err) => {
      this.toaster.error("Something went wrong. Please check.", "Error");
    })
    // this.gridApiBill.updateRowData({ remove: [e.data] });
    // this.gridApiBill.refreshView();
    // this.getAllBill();
  }

  //#endregion

}

