<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">{{'MAILPOPUP.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
               
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="To" style="font-weight: 600;">{{'MAILPOPUP.to' | translate}}</label>
                              
                                <ng-select [items]="users"
                                    bindLabel="name"
                                    bindValue="id"
                                    [addTag]="addCustomUser"
                                    [multiple]="true"
                                    placeholder="Select email or add new email"
                                    [(ngModel)]="selectedUserIds">
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="Subject" style="font-weight: 600;">{{'MAILPOPUP.subject' | translate}}</label>
                                <input type="text" class="form-control form-input" [(ngModel)]="mailSubject">
                            </div>
                        </div>

                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="Body" style="font-weight: 600;">{{'MAILPOPUP.body' | translate}}</label>
                                <textarea class="form-control form-input" style="height: 250px;" [(ngModel)]="mailBody"></textarea>
                            </div>
                        </div>

                    </div>

              
            </div>
           
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group  col-md-4">
                    <input type="checkbox" [(ngModel)]="SendMeCopy">
                    <label for="SendMeCopy" class="ml-2"> Send me a copy</label><br>
                </div>
                <div class="form-group  col-md-4">
                    <input type="checkbox" [(ngModel)]="IsAttachPdf">
                    <label for="IsAttachPdf" class="ml-2"> Attach PDF</label><br>
                </div>
                <div class="form-group  col-md-4">
                    <button type="button" (click)="SendToPerson()"
                    class="eCount-def-btn  p-save-button">{{'MAILPOPUP.send' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>