import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';
import { NgxSpinnerService } from "ngx-spinner";
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SalesOrderAdvanceComponent } from "../sales-order-advance/sales-order-advance.component";
import { IncomeServiceService } from 'src/app/service/Income/income-service.service';
import { AdvanceViewComponent } from '../../Accounting/advance/advance_view/advance-view/advance-view.component';

@Component({
  selector: 'app-sales-order-advance-details',
  templateUrl: './sales-order-advance-details.component.html',
  styleUrls: ['./sales-order-advance-details.component.css']
})
export class SalesOrderAdvanceDetailsComponent implements OnInit {
  advanceGridApi;
  SalesOrderId: string;
  SalesOrderNo: string;
  isAdvanceShow: boolean = false;
  totalInvoice: number = 0;
  totalUnInvoice: number = 0;
  SalesOrderData: any = [];
  advanceList: any = [];
  salesInvoices: any = [];
  dataList: any = [];

  constructor(
    private route: ActivatedRoute,
    private salesService: SalesOrderService,
    private spinner: NgxSpinnerService,
    public fileEmitterService: FileEmitterService,
    private modalService: NgbModal,
    private incomeService: IncomeServiceService
  ) {}

  ngOnInit(): void {
    this.SalesOrderId = this.route.snapshot.paramMap.get("id");
    this.getOrderDetails(this.SalesOrderId);
  }

  getOrderDetails = (id: any) => {
    this.salesService.GetOrderDetails(id).subscribe((x) => {
      if (x) {
        this.fileEmitterService.emitFileRefIdUpdate({id: this.SalesOrderId,});
        this.SalesOrderData = x.data;
        this.SalesOrderId = this.SalesOrderData.Id;
        this.SalesOrderNo = x.data.OrderNo;
        this.getAdvance(this.SalesOrderData.Id);
        this.getInvoices(x.data.OrderNo, x.data.OrderAmount, x.data.TaxAmount, x.data.AdvanceTaxAmount);
      }
    });
  }

  getAdvance = (id) => {
    this.spinner.show();
    this.salesService.GetAdvanceBySalesId(id).subscribe(
      (x) => {
        this.advanceList = x;
        if (this.advanceList.length > 0) {
          this.isAdvanceShow = true;
        }
        this.spinner.hide();
      },
      (err) => {}
    );
  }

  getInvoices = (orderNo, amount, taxAmount, advanceTaxAmount) => {
    this.incomeService.GetInvoiceList().subscribe((x) => {
        this.salesInvoices = x.data.filter((y) => y.ReferenceNo == orderNo);
        this.totalInvoice = this.salesInvoices.reduce(function (prev, cur) {
          return prev + cur.InvoiceAmount;
        }, 0);
        this.totalUnInvoice = amount - (taxAmount + advanceTaxAmount + this.totalInvoice);
        if (this.totalUnInvoice < 0) {
          this.totalUnInvoice = 0;
        }
      },
      (err) => {}
    );
  }

  advGridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
    advanceColumnDefs: [
      { headerName: "Advance No.", field: "AdvNo", cellStyle: {color: 'blue',cursor: 'pointer'} },
      { headerName: "Date", field: "TransactionDateVw" },
      { headerName: "Account", field: "AccountName" },
      { headerName: "Amount", field: "Amount" },
      { headerName: "Settlement", field: "Settlement" },
      { headerName: "Balance", field: "Balance" },
    ],
    domLayout: "autoHeight",
  };

  onAdvanceGridReady = (event) => {
    this.advanceGridApi = event.api;
  };

  createNewAdvance = () => {
    const modalRef = this.modalService.open(SalesOrderAdvanceComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.fromparent = {
      contactId: this.SalesOrderData.ContactId,
      salesOrderId: this.SalesOrderData.Id,
    };
    modalRef.result.then(
      (result) => {
        if (result) {
          this.getAdvance(this.SalesOrderData.Id);
        }
      },
      (reason) => {}
    );
  };
  onRowClicked(e) {
    const value = this.setChildData(e);
    const data = this.advanceList.filter(x => x.ContactId == e.data.ContactId && x.ParentId != null);
    const modalRef = this.modalService.open(AdvanceViewComponent, { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.fromParent = value;
    modalRef.componentInstance.childData = data;
    modalRef.componentInstance.salesOrderAdvanceHistory = true;
    modalRef.result.then((result) => {
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }

  setChildData(e)
{
   const obj = {
    AccountId: e.data.AccountId,
    Amount: e.data.Amount,
    Name: e.AccountName,
    ContactId : e.data.ContactId,
    Description: e.data.Description,
    Id:  e.data.Id,
    TransactionDate: e.data.TransactionDate,
    TransactionTypeId: e.data.TransactionTypeId,
    AdvNo : e.data.AdvNo
   };
   return obj;
}

}
