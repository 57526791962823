<div>
   <!-- <img src="../../../assets/icons/warning.png" alt="" class="ml-3 url"><span style="font-size: 16px;" class="ml-1 mt-2">Warning</span> -->
  <button type="button" class="close mt-2 mr-2" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-header mrt mt-0">
    <h4 class="modal-title txt">{{ title }}</h4>
      
    </div>
    <div class="modal-body mrt">
     <p> <span> {{ billNo }} </span></p>
    <p><span> {{Type}}</span></p>
    </div>
    <div class="modal-footer">
      
      <button type="button" class="eCount-def-btn" (click)="accept()">{{ btnOkText }}</button>
      <button type="button" class="cancel" (click)="decline()">{{ btnCancelText }}</button>
    </div>