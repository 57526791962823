import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormArray } from '@angular/forms';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';
import {Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-factory-in-view',
  templateUrl: './factory-in-view.component.html',
  styleUrls: ['./factory-in-view.component.css']
})
export class FactoryInViewComponent implements OnInit {

  public TransNo="";
  public factoryInForm: FormGroup;
  public ProductionInItem: FormArray;
  public salesOrders:any = [];
  totalCost = 0;
  totalQuantity = 0;
  data: any;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private pService: FactoryProductionService) { }
    transNo: any;
    ngOnInit(): void {
      this.getSalesOrder();
      this.TransNo = this.route.snapshot.paramMap.get("transNo");
      this.initializeForm();
      this.getTransactionDetails(this.TransNo);
    }
    public getSalesOrder(){
      this.pService.GetAllFactoryOutSalesOrder().subscribe((x) => {
        this.salesOrders = x.salesOrders;
      });
    }
      initializeForm() {
        this.factoryInForm = this.fb.group({
          InDate: [''],
          ReferrenceNo: [''],
          Description: [''],
          Id: [0],
          TransactionNo: [''],
          StatusId: [''],
          CreatedById: [''],
          CreatedAt: [''],
          ProductionInItem: this.fb.array([])
        });
      }
    private getTransactionDetails(transNo: string){
      this.pService.GetProductionIn(transNo).subscribe((x) => {
        this.data = x;
        this.setRawItem(this.data);
        for (const item of this.data.ProductionItemView){
          this.addItem(item);
          this.totalCost += (item.PricePerUnit * item.InQuantity);
          this.totalQuantity += item.InQuantity;
        }

      });
    }

  public addItem(x:any): void {
    this.ProductionInItem = this.factoryInForm.get('ProductionInItem') as FormArray;
    this.ProductionInItem.push(this.createItem(x));
  }

  public createItem(x:any): FormGroup {
    return this.fb.group({
      ItemName: [x.ItemName],
      ProductName: [x.ProductName],
      SalesOrderId: [{value : x.SalesOrderId, disabled: true}],
      InQuantity: [x.InQuantity],
      PricePerUnit: [x.PricePerUnit],
      LineTotal: [x.InQuantity*x.PricePerUnit],
      MaterialId: [x.MaterialId],
      ProductId: [x.ProductId],
      Id: [x.Id],
      MovementId: [x.MovementId]
    });
  }
  public ApproveFactoryIn() {
    this.factoryInForm.get('StatusId').patchValue(2);
    if (this.totalQuantity === 0)
    {
      this.toaster.warning('Total item qunatity can not be 0');
      return;
    }
    this.spinner.show();
    this.pService.UpdateApproveProdIn(this.factoryInForm.value).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Factory In has been successfully approved.');
        this.router.navigate(['inventory/factory-in-list/All']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }
  public setRawItem(x:any): void {
    this.factoryInForm.controls['Description'].patchValue(x.Description);
    this.factoryInForm.controls['InDate'].patchValue(moment(x.InDate).format("MM/DD/YYYY"));
    this.factoryInForm.controls['ReferrenceNo'].patchValue(x.ReferrenceNo);
    this.factoryInForm.controls['TransactionNo'].patchValue(x.TransactionNo);
    this.factoryInForm.controls['Id'].patchValue(x.Id);
    this.factoryInForm.controls['StatusId'].patchValue(x.StatusId);
    this.factoryInForm.controls['CreatedById'].patchValue(x.CreatedById);
    this.factoryInForm.controls['CreatedAt'].patchValue(x.CreatedAt);
    }
}
