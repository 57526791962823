<app-dashboard>
    <app-page-header-title
        mainTitle="New Spend Money"
        subTitle="Spend Money List"
        backURL="/spendmoney/spend-money-list/all">
        <div class="full-width text-align-right">
            <button type="button" (click)="saveCreateSpendMoney()" class="eCount-def-btn ml-4">Save</button>
        </div>
        
    </app-page-header-title>
    <div class="headSection">
        <div class="row" [hidden]="showBankWarningDiv" style="margin: 0px;">
            <div class="col-md-12 alert alert-danger" role="alert">
                For Spend money, first you need to create a Bank Account.<a [routerLink]="'/accounting/bank-accounts-create'"><span style="font-size: 13px;" >Change settings</span></a>
              </div>
        </div>
        <div class="row">
            <div class="col">
                <label>Spent as</label>
                <ng-select [items]="spendMoneyTypeList" [clearable]="false" bindLabel="Name" bindValue="Id" (change) = "onSpendMoneyTypeChange()" [(ngModel)]="selectedSpendMoneyTypeId"></ng-select>
            </div>
            <div class="col">
                <label>To <span class="clr">*</span></label>
                <ng-select [items]="allSupplier" bindLabel="PrimaryPersonFirstName" bindValue="Id"
                [addTag]="CreateNewSupplier" [loading]="loading" [(ngModel)]="selectedSupplier">
                <ng-template ng-tag-tmp let-search="searchTerm">
                    <b>+ Add New Supplier</b>
                </ng-template>
            </ng-select>
                 </div>
            <div class="col">
                <label>From<span class="clr">*</span></label>
                <ng-select [items]="bankList" [clearable]="false" bindLabel="AccountName"  bindValue="GLAccountId"  [(ngModel)]="selectedBank">
                    <ng-template ng-option-tmp let-item="item" let-index="index" style="display: inline-grid;">     
                        <span>{{item.BankAccount}}</span>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col">
                <label>Date</label>
                <input class="form-controlDate" type="date" [(ngModel)]="selectedDate" id="smtest" data-date="" data-date-format="D MMM YYYY" (change)="show($event)">
            </div> 
            <div class="col">
                <label>Reference</label>
                <input class="form-controlinputBox" type="text" [(ngModel)]="ReferenceNo">
            </div>
            <div class="col">
                <label>Amounts are</label>
                <ng-select [(ngModel)]="selectedTaxType" [items]="taxTypeList" [clearable]="false" (change)="updateTotalPrice()" bindLabel="Name" bindValue="Id" name="TaxTypeId"></ng-select>
            </div>     
        </div>
        <div class="row d-block topSection">
            <div class="d-flex">
                <div class="col-md-2">
                    Description<span class="clr">*</span>
                </div>
                <div class="col-md-2">
                    Account
                </div>
                <div class="col-md-2">
                    Quantity
                </div>
                <div class="col-md-2">
                    Price
                </div>
                <div class="col-md-1">
                    VAT%
                </div>
                <div class="col-md-1">
                    AIT%
                </div>
                <div class="col-md-2">
                    Amount
                </div>
            </div>
            <hr class="pb-0 mb-0"/>
            <div>
                <div *ngFor="let bill of spendMoneyItem; let i=index">
                    <div class="invoiceGridItemRow d-block" [ngClass]="[bill.selected ? 'selectedRow' : 'notSelectedRow']">
                        <div class="d-flex">

                            <div class="col-md-2 borderLeft ">
                                <!-- <input class="form-control" type="text"  [(ngModel)]="spendMoneyItem[i].ItemDescription" (click)="changeSelectRowInItem(spendMoneyItem[i].ItemId, i)" (change)="updateDescriptionSpendItem(i)" > -->
                                <textarea class="form-control"
                                    style="resize: none;margin: 5px; min-height: 20px; max-height: 340px;"
                                    type="text" [(ngModel)]="spendMoneyItem[i].ItemDescription"
                                    (click)="changeSelectRowInItem(spendMoneyItem[i].ItemId, i)"> </textarea>
                            </div>
                            <div class="col-md-2 borderLeft ">
                                <div style="width: 100%;">
                                    <ng-select [items]="allChartOfAccount" placeholder="Select Account"
                                        class="customSaleItem" [clearable]="false" bindLabel="AccountName"
                                        bindValue="Id" [(ngModel)]="spendMoneyItem[i].AccountId"
                                        (change)="selectionChanged($event,bill.ItemId, i)" groupBy="GroupName">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-md-2 borderLeft rowSpaceing" >
                                <input class="form-control" type="text" appDecimaNumericValidator [(ngModel)]="spendMoneyItem[i].Quantity" (focus)="onFocusEvent($event)" (click)="changeSelectRowInItem(bill.ItemId, i)" (change)="updateDataInvoiceItem(bill.ItemId)" >
                            </div>
                            <div class="col-md-2 borderLeft rowSpaceing">
                                <input class="form-control" type="text"  [(ngModel)]="spendMoneyItem[i].UnitPrice" (click)="changeSelectRowInItem(bill.ItemId, i)" (focus)="onFocusEvent($event)" (change)="updateDataInvoiceItem(bill.ItemId)" appDecimaNumericValidator>
                            </div>
                            <div class="col-md-1 borderLeft rowSpaceing">
                                <ng-select [(ngModel)]="bill.TaxRateId" [items]="taxRateList" class="customSaleItem" [clearable]="false" bindLabel="Parcent" bindValue="Id" (change)="updateTotalPrice()" (click)="changeSelectRowInItem(bill.ItemId, i)" [readonly]="selectedTaxType === 3"></ng-select>
                            </div>
                            <div class="col-md-1 borderLeft rowSpaceing">
                                <ng-select [(ngModel)]="bill.AdvanceTaxRateId" [items]="taxRateList" class="customSaleItem" [clearable]="false" bindLabel="Parcent" bindValue="Id" (change)="updateTotalPrice()" (click)="changeSelectRowInItem(bill.ItemId, i)" [readonly]="selectedTaxType === 3"></ng-select>
                            </div>
                            <div class="col-md-2 rowSpaceing d-flex">
                                <div>
                                    <input class="form-control" type="text" appDecimaNumericValidator [(ngModel)]="spendMoneyItem[i].LineTotal" (click)="changeSelectRowInItem(bill.ItemId, i)" (change)="updateDataInvoiceItemLineTotal(bill.ItemId)">
                                </div>
                                <div style="padding: 7px 10px; color: #c7c2c2;">
                                    <div class="dropdown mr-4">
                                        <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                            <i class="icon-more float-right"></i>
                                        </div>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%;">
                                            <div class="productRow pb-3" (click)="removeInvoiceItem(bill.ItemId, i)">
                                                <span class="lead1">Remove Item </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
            <div class="row addItem">
                <div class="BottomAddItem w-100">
                    <div class="dropdown mr-4">
                        <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="false" aria-expanded="false" (click)="createBlankItem()">
                            <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line
                        </a>
                    </div>
                </div>
            </div>
            
            <div class="container-bottom d-flex"(click)="changeSelectRowInItem(0,0)">
                <div class="col-md-8 col-sm-12"><span></span></div>
                <div class="col-md-4 col-sm-12 p-0 d-block">
                    <div class="d-flex w-100">
                        <div class="col-md-6">
                            <label>Subtotal</label>
                        </div>
                        <div class="col-md-4 ml-4">{{subTotalAmount.toFixed(2)| currency:'':''}}</div>
                    </div>
                    <div class="d-flex w-100" *ngIf="selectedTaxType !== 3">
                        <div class="col-md-6">
                            <label>VAT</label>
                        </div>
                        <div class="col-md-4 ml-4">{{taxAmount.toFixed(2)| currency:'':''}}</div>
                    </div>
                    <div class="d-flex w-100" *ngIf="selectedTaxType !== 3">
                        <div class="col-md-6">
                            <label>AIT</label>
                        </div>
                        <div class="col-md-4 ml-4">{{advanceTaxAmount.toFixed(2)| currency:'':''}}</div>
                    </div>
                    <div class="d-flex w-100 equalLine">
                        <span></span>
                    </div>
                    <div class="d-flex w-100 pt-3">
                        <div class="col-md-6">
                            <label>Total</label>
                        </div>
                        <div class="col-md-4 font-weight-bold ml-4">{{totalPrice.toFixed(2)| currency:'':''}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</app-dashboard>