import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectComponent } from "@ng-select/ng-select";
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AcountingServiceService } from "src/app/service/Accounting/acounting-service.service";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { ReceivemoneyServiceService } from "src/app/service/ReceiveMoney/receivemoney-service.service";
import { PurchaseProductComponent } from "../../Products/purchase-product/purchase-product.component";
import { SpendmoneyServiceService } from "src/app/service/SpendMoney/spendmoney-service.service";
import { SpendMoneyItem, UpdateSpendMoney } from "src/app/models/spendMoney.model";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";

@Component({
  selector: "app-update-spend-money",
  templateUrl: "./update-spend-money.component.html",
  styleUrls: ["./update-spend-money.component.css"],
})
export class UpdateSpendMoneyComponent implements OnInit {
  allSupplier: any = [];
  PurchaseableItems: any = [];
  allChartOfAccount: any = [];
  bankList: any = [];
  spendMoneyTypeList: any = [];
  spendMoneyItems: SpendMoneyItem[] = [];
  Bills: any = [];
  selectedSupplier: number;
  selectedBank: number;
  selectedSpendMoneyTypeId: number;
  showBankWarningDiv: boolean = true;
  showItemRow: boolean = true;
  showHideInvoiceNo: boolean = true;
  spendMoneyData: UpdateSpendMoney;
  selectedDate: any = new Date();
  selectedDueDate: Date;
  totalPrice: number = 0.0;
  ReferenceNo: string = "";
  InvoiceNo: string = "";
  loading = false;
  draftId: string;
  isItemListUpdate = false;

  selectedTaxType: number;
  taxTypeList: any = [];
  taxRateList: any = [];
  TaxTypeId: null;

  subTotalAmount: number = 0.00;
  taxAmount: number = 0.00;
  advanceTaxAmount: number = 0.00;

  Id: number;

  constructor(
    private spendMoneyService: SpendmoneyServiceService,
    private accountingService: AcountingServiceService,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    private dataService: DataService,
    private router: Router,
    private modalService: NgbModal,
    public ngxSmartModalService: NgxSmartModalService,
    private activeroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private settingService: SettingsServiceService
  ) {}

  ngOnInit(): void {
    this.draftId = this.activeroute.snapshot.paramMap.get("draftId");
    this.getFormData();
    this.show(this.selectedDate);
    this.dataService.change.subscribe((x) => {
      this.isItemListUpdate = x;
      this.getAllSaleableItem();
    });
  }

  forMatDate() {
    var date = new Date();
    var firstDay = new Date( date.getFullYear(), date.getMonth(), date.getDate() );
    this.selectedDate = firstDay.toLocaleDateString("en-GB", { year: "numeric" }) + "-" + firstDay.toLocaleDateString("en-GB", { month: "2-digit" }) + "-" + firstDay.toLocaleDateString("en-GB", { day: "2-digit" });
  }

  defaultAccountSettings = (item) => {
    const account = this.allChartOfAccount.filter((x) => x.AccountCode === "429");
    item.map((x) => {
      if (x.AccountId == null) {
        x.AccountId = account[0].Id;
      }
    });
  }

  getFormData = async () => {
    this.getSpendMoneyData();
    await this.getAllSupplier();
    await this.getAllSaleableItem();
    await this.getAllChartOfAccount();
    await this.getBankList();
    await this.getSpendMoneyTypeList();
    await this.getTaxTypeList();
    await this.getTaxRateList();
  }

  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
      if (this.taxTypeList.length >= 2) {
        this.TaxTypeId = this.taxTypeList[2].Id;
      }
    });
  }

  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({ "Id": "", "Name": "Select" });
    });
  }

  CreateNewSupplier = (customerName) => {
    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: true,
      IsSupplier: true,
    };

    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
        if (x.Success) {
          this.selectedSupplier = x.Data.Id;
          this.toaster.success("Contact has been successfully added !");
          this.loading = false;
          this.allSupplier.push(x["Data"]);
          this.getAllSupplier();
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.loading = false;
      }
    );
  }

  show(e) {
    const value = this.selectedDate;
    $("#rmtest").on("change", function () {
        this.setAttribute( "data-date", moment(value, "YYYY-MM-DD").format( this.getAttribute("data-date-format")));
      }).trigger("change");
  }

  getBankList = async () => {
    this.bankList = [];
    this.mainService.GetAllBank().subscribe((x) => {
      this.spinner.hide();
      this.bankList = x;
      if (this.bankList.length == 0) {
        this.showBankWarningDiv = false;
      }
    });
  }

  getSpendMoneyTypeList = async () => {
    this.spendMoneyTypeList = [];
    this.spendMoneyService.GetSpendMoneyTypeList().subscribe((x) => {
      this.spinner.hide();
      this.spendMoneyTypeList = x;
      if (this.spendMoneyTypeList != null) {
        this.selectedSpendMoneyTypeId = this.spendMoneyTypeList[0].Id;
      }
    });
  }

  onSpendMoneyTypeChange() {
    if (this.selectedSpendMoneyTypeId === 1) {
      this.showItemRow = false;
      this.showHideInvoiceNo = true;
    } else if (this.selectedSpendMoneyTypeId === 2) {
      this.showHideInvoiceNo = false;
      this.showItemRow = true;
    } else {
      this.showHideInvoiceNo = true;
      this.showItemRow = true;
    }
  }

  getAllSupplier = async () => {
    this.mainService.GetAllSupplier().subscribe((x: []) => {
      this.allSupplier = x;
    });
  }

  getAllSaleableItem = async () => {
    this.spendMoneyService.GetPurchaseableItems().subscribe((x) => {
      this.PurchaseableItems = x
    });
  }

  getAllChartOfAccount = async () => {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
      this.allChartOfAccount = x.RevenueList.filter((xy) => xy.AccountGroupId !== 4 && xy.AccountGroupId !== 2 && xy.AccountCode !== "900");
    });
  }

  openItemModal(selectItem: NgSelectComponent) {
    selectItem.close();
    const modalRefs = this.modalService.open(PurchaseProductComponent, { size: "lg", backdrop: "static", keyboard: false });
  }

  changespurchaseableItems = (index) => {
    for (let i = 0; i < this.PurchaseableItems.length; i++) {
      if ( this.PurchaseableItems[i].Id == this.spendMoneyItems[index]["ItemId"]) {
        this.spendMoneyItems[index]["ItemId"] = this.PurchaseableItems[i]["Id"];
        this.spendMoneyItems[index]["AccountId"] =
        this.PurchaseableItems[i]["PurchaseAccountId"];
        this.spendMoneyItems[index]["Quantity"] = 1;
        this.spendMoneyItems[index]["ItemDescription"] =
        this.PurchaseableItems[i]["PurchaseDescription"];
        this.spendMoneyItems[index]["UnitPrice"] =
        this.PurchaseableItems[i]["PurchasePrice"];
        this.updateDataInvoiceItem(this.spendMoneyItems[index]["ItemId"]);
      }
    }
  }

  changeItemPosition(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.Bills, event.previousIndex, event.currentIndex);
  }

  changeSelectRowInItem = (itemId, index) => {
    let data = [];
    this.spendMoneyItems.map((x, i) => {
      x.ItemId == itemId && i == index
        ? (x.selected = true)
        : (x.selected = false);
      data.push(x);
    });
    this.spendMoneyItems = data;
  }

  selectionChanged = (event, itemId, index) => {
    this.spendMoneyItems.map((x, i) => {
      if (x.ItemId == itemId && i == index) {
        x.AccountId = event.Id;
      }
    });
  }

  onFocusEvent(event: any) {
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }

  updateTotalPrice = () => {
    let SubTotalAmount = 0.00;
    let TaxAmount: number = 0.00;
    let AdvanceTaxAmount: number = 0.00;
    let TotalPrice: number = 0.00;
    let price = 0.00;
    this.spendMoneyItems.map((x) => {
      SubTotalAmount = SubTotalAmount + x.LineTotal;
      TaxAmount += this.getItemTaxAmount(x.LineTotal, x.TaxRateId);
      AdvanceTaxAmount += this.getItemTaxAmount(x.LineTotal, x.AdvanceTaxRateId);
    });

    this.taxAmount = TaxAmount;
    this.advanceTaxAmount = AdvanceTaxAmount;
    this.subTotalAmount = SubTotalAmount;

    switch (this.selectedTaxType) {
      case 1: //tax exclusive
        TotalPrice = this.subTotalAmount + TaxAmount + AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        TotalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
        TotalPrice = this.subTotalAmount;
        break;
    }
    this.totalPrice = TotalPrice;
    if (this.selectedTaxType === 3) {
      this.checkTaxType();
    }
  }

  private getItemTaxAmount(lineTotal: number, taxRateId: any) {
    let taxPrice = 0.00;
    if (taxRateId == "" || taxRateId == null)
      return taxPrice;

    var taxPercent = this.getTaxRatePercent(taxRateId);

    switch (this.selectedTaxType) {
      case 1: //tax exclusive
        taxPrice = lineTotal * taxPercent / 100;
        break;
      case 2: //tax inclusive
        taxPrice = Number(((lineTotal * taxPercent) / (100 + taxPercent)).toFixed(2));
        break;
      default: //no tax
        taxPrice = 0
        break;
    }
    return taxPrice;
  }

  private getTaxRatePercent(taxRateId: any) {
    let taxRateObj = this.taxRateList.filter(x => x.Id == taxRateId);
    return taxRateObj[0]?.Parcent;
  }

  checkTaxType = () => {
    this.spendMoneyItems.map((x, i) => {
      x.TaxRateId = null;
      x.AdvanceTaxRateId = null;
    });
  }

  updateDataInvoiceItem = async (itemId) => {
    this.spendMoneyItems.map((x) => {
      if (x.ItemId == itemId) {
        x.LineTotal = x.Quantity * x.UnitPrice;
      }
    });
    await this.updateTotalPrice();
  }

  updateDataInvoiceItemLineTotal = async (itemId) => {
    this.spendMoneyItems.map((x) => {
      if (x.ItemId == itemId) {
        x.UnitPrice = x.LineTotal / x.Quantity;
      }
    });
    await this.updateTotalPrice();
  }

  createBlankItem = () => {
    this.changeSelectRowInItem(0, 0);
    let item = {
      ItemId: null,
      ItemDescription: "",
      Quantity: 0,
      UnitPrice: 0,
      LineTotal: 0,
      AccountId: null,
      selected: false,
      TaxRateId: null,
      AdvanceTaxRateId: null
    };
    this.spendMoneyItems.push(item);
    this.defaultAccountSettings(this.spendMoneyItems);
  }

  createInvoiceItem = async (data) => {
    this.changeSelectRowInItem(0, 0);
    let item = {
      ItemId: data.Id,
      ItemDescription: data.ItemName,
      Quantity: 1,
      UnitPrice: data.SaleUnitPrice,
      LineTotal: data.SaleUnitPrice,
      AccountId: data.SaleAccountId,
      selected: true,
      TaxRateId: data.TaxRateId,
      AdvanceTaxRateId: data.TaxRateId
    };
    this.spendMoneyItems.push(item);
    await this.updateTotalPrice();
  }

  removeInvoiceItem = async (itemId, i) => {
    let data = [];
    this.spendMoneyItems.map((x, index) => {
      if (index != i) {
        data.push(x);
      }
    });
    this.spendMoneyItems = data;
    await this.updateTotalPrice();
  }

  checkValidation = (receiveMoneyItem) => {
    var b = true;
    receiveMoneyItem.forEach((element) => {
      if (
        element.ItemDescription == "" ||
        element.AccountId == null ||
        element.LineTotal <= 0
      ) {
        b = false;
      }
    });
    return b;
  }

  validationData = async () => {
    let selectedCustomer = this.allSupplier.find(
      ({ Id }) => Id === this.selectedSupplier
    );
    let fullName = selectedCustomer ? selectedCustomer["PrimaryPersonLastName"] != null ? selectedCustomer["PrimaryPersonFirstName"] + " " + selectedCustomer["PrimaryPersonLastName"] : selectedCustomer["PrimaryPersonFirstName"] : null;

    this.spendMoneyData = {
      Id: Number(this.draftId),
      SpendTypeId: this.selectedSpendMoneyTypeId,
      SpendAccountId: this.selectedBank,
      SpendDate: this.selectedDate,
      FromId: this.selectedSupplier,
      FromName: fullName,
      ReferenceNo: this.ReferenceNo.toString(),
      SubTotalAmount: this.totalPrice,
      SpendMoneyItems: this.spendMoneyItems,
      TaxId: this.selectedTaxType,
      TaxAmount: this.taxAmount,
      AdvanceTaxAmount: this.advanceTaxAmount
    };

    let check = this.checkValidation(this.spendMoneyData.SpendMoneyItems);
    if (!this.spendMoneyData.FromId) {
      this.toaster.warning("From  cannot be empty !", "Warning!");
      return;
    } else if (!this.spendMoneyData.SpendAccountId) {
      this.toaster.warning("To cannot be empty !", "Warning!");
      return;
    } else if (!this.spendMoneyData.SpendDate) {
      this.toaster.warning("Date cannot be empty !", "Warning!");
      return;
    } else if (this.spendMoneyItems.length == 0) {
      this.toaster.warning(
        "Please add at least one item in the receive money.",
        "Warning!"
      );
      return;
    } else if (!check) {
      this.toaster.warning("Please fill all the required fields!", "Warning!");
      return;
    }
  }

  updateSpendMoneyDraft = async () => {
    await this.validationData();
    this.spinner.show();
    this.spendMoneyService.updateSpendMoneyDataDraft(this.spendMoneyData).subscribe((x) => {
        if (x.Success) {
          this.toaster.success("Transaction has been Successful.", "Success!");
          this.router.navigate(["spendmoney/spend-money-list/all"]);
          this.spinner.hide();
        } else {
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
  }

  getSpendMoneyData = () => {
    this.spinner.show();
    this.spendMoneyService.GetSpendMoneyDetails(Number(this.draftId)).subscribe(async (x) => {
        this.Id =  x.Id;
        this.spendMoneyItems = x.SpendMoneyItems;
        await this.updateTotalPrice();
        this.selectedSpendMoneyTypeId = x.SpendTypeId;
        this.selectedSupplier = x.FromId;
        this.selectedBank = x.SpendAccountId;
        this.selectedTaxType = x.TaxId;
        this.ReferenceNo = x.ReferenceNo.toString();
        this.taxAmount = x.TaxAmount;
        this.advanceTaxAmount = x.AdvanceTaxAmount;
        this.subTotalAmount = x.SubTotalAmount - (x.TaxAmount + x.AdvanceTaxAmount);
        this.totalPrice = x.SubTotalAmount;
        this.spinner.hide();
      });
  }

  updateAndSubmitEditedData = async () => {
    await this.validationData();
    this.spinner.show();
    this.spendMoneyService.updateAndSaveEditedData(this.spendMoneyData).subscribe((x) => {
        if (x.Success) {
          this.toaster.success("Transaction has been Successful.", "Success!");
          this.router.navigate(["spendmoney/spend-money-list/all"]);
          this.spinner.hide();
        } else {
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
  };
}
