<app-dashboard>
    <div>
        <app-page-header-title
            mainTitle="Invoice"
            subTitle="Back" 
            backURL="/sales/sales-order-details/{{SalesOrderId}}">
        </app-page-header-title>
    </div>
    <div style="margin-top: 15px;">
        <div class="col-md-12 d-flex f-ss-14px" id="my1">
            <div class="col-md-3 d-block pl-1">
                <b style="font-weight: bold;">{{twoDecimalPlace(totalUnInvoice)}}</b>
                <p>Uninvoiced</p>
            </div>
            <div class="col-md-6 pl-0 ml-0">
                <b style="font-weight: bold;">{{twoDecimalPlace(totalInvoice)}}</b>
                <p>Invoiced</p>
            </div>
            <div *ngIf="!isClosed && totalUnInvoice != 0" class="col-md-3">
                <div class="full-width text-align-right">
                    <button type="button" class="eCount-def-btn ml-4" (click)="createNewInvoice()">Create Invoice</button>
                </div>
            </div>
        </div>
        <div class="row pt-3 mt-2 mr-2 mb-1 ml-2">
            <ag-grid-angular style="font-size: 14px;" 
                class="ag-theme-balham col" 
                [rowData]="salesInvoices" 
                [columnDefs]="invoiceGridOptions.columnDefs" 
                [domLayout]="invoiceGridOptions.domLayout" 
                [defaultColDef]="invoiceGridOptions.defaultColDef" 
                (gridReady)="onInvoiceGridReady($event)">
            </ag-grid-angular>
        </div>
        <div class="row d-flex grid ml-4 mr-4 mt-0">
            <div class="col-sm-6">Total</div>
            <div class="col-sm-1 ml-5 pl-5">{{ twoDecimalPlace(totalInvoice) | currency:'':'' }}</div>
        </div>
    </div>
</app-dashboard>