<div class="modal-header" style="background-color: #F0F8FF; padding-bottom: 10px;">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="ManufactureOrderTimeLineForm" class="f-ss-14px">
                    <div class="row f-ss-14px">
                        <div class="col-md-12 ptb" style="padding-top: 10px;">
                            <label class="font-weight-bold">Please confirm MO Start Date and End Date</label>
                        </div>
                    </div>
                    <div class="row f-ss-14px">
                        <div class="col-md-6 ptb">
                            <label for="updateStartDateId" class="font-weight-bold">Start Date<span class="clr">*</span></label>
                            <input class="form-control" id="updateStartDateId" type="date" formControlName="UpdateStartDate" data-date="" data-date-format="D MMM YYYY" (change)="showStartSelectedDate($event)">
                        </div>
                        <div class="col-md-6 ptb">
                            <label for="UpdateEndDateId" class="font-weight-bold">End Date<span class="clr">*</span></label>
                            <input class="form-control" id="UpdateEndDateId" type="date" formControlName="UpdateEndDate" data-date="" data-date-format="D MMM YYYY" (change)="showEndSelectedDate($event)">
                        </div>
                    </div>
                    <div class="full-width text-align-right">
                        <button type="button" class="eCount-def-btn mt-4 blueBg" (click)="save()">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>