import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BillsServiceService } from '../../../service/Bill/bills-service.service'
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service';
import { ToastrService } from 'ngx-toastr';
import * as jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { SendBillMailComponent } from '../send-bill-mail/send-bill-mail.component';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { BillAdvanceAdjustmentComponent } from '../bill-advance-adjustment/bill-advance-adjustment.component';
import {AlertService} from '../../../service/Alert/alert.service';
import { BankAccountsComponent } from '../../Accounting/bank-accounts/bank-accounts.component';

@Component({
  selector: 'app-edit-bill-transaction',
  templateUrl: './edit-bill-transaction.component.html',
  styleUrls: ['./edit-bill-transaction.component.css']
})
export class EditBillTransactionComponent implements OnInit {
  EditMakePaymentForm: any;
  @Input() bankAccounts;
  PaymentModeList: any[];
  @Input() item;
  accountTypeId = 0;
  ifSubmit = false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private billService: BillsServiceService,
              private ngxSmartModalService: NgxSmartModalService,
              public fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private accountingService: AcountingServiceService,
              private toaster: ToastrService,
              private modalService: NgbModal,
              private settingService: SettingsServiceService,
              private fileEmitterService: FileEmitterService,
              private alertService: AlertService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.initMakePayment();
    this.getEditPaymentModeList(this.item.AccountId);
    this.initializeTransactionValue(this.item);
  }


  getEditPaymentModeList(id)
  {
    this.EditMakePaymentForm.get('PaymentModeId').patchValue(null);
    const list = this.bankAccounts.filter(x => x.GLAccountId === id);
    this.PaymentModeList = list.length >= 1 ? list[0].PaymentModeList  : [];
    this.accountTypeId = list.length >= 1 ? list[0].AccountTypeId : 1;
  }
  initializeTransactionValue(item)
  {
    this.EditMakePaymentForm.get('PaidAmount').patchValue(item.PaidAmount);
    this.EditMakePaymentForm.get('PaymentModeId').patchValue(item.PaymentModeId);
    this.EditMakePaymentForm.get('AccountId').patchValue(item.AccountId);
    this.EditMakePaymentForm.get('PaidDate').patchValue(item.PaidDate);
    this.EditMakePaymentForm.get('SourceAccountName').patchValue(item.SourceAccountName);
    this.EditMakePaymentForm.get('SourceAccountNo').patchValue(item.SourceAccountNo);
    this.EditMakePaymentForm.get('SourceBankName').patchValue(item.SourceBankName);
    this.EditMakePaymentForm.get('ReferenceNo').patchValue(item.ReferenceNo);
    this.EditMakePaymentForm.get('BillId').patchValue(item.BillId);
    this.EditMakePaymentForm.get('DueAmount').patchValue(item.DueAmount);
    this.EditMakePaymentForm.get('Id').patchValue(item.Id);
    this.show(item.PaidDate);
  }



  EditMakePayment = () => {
    this.ifSubmit = true;
    if (!this.EditMakePaymentForm.value.PaidDate  || !this.EditMakePaymentForm.value.AccountId || !this.EditMakePaymentForm.value.PaymentModeId) {
      this.toaster.warning('All the fields are required !');
      return;
    }
    this.spinner.show();
    this.billService.editMakePayment(this.EditMakePaymentForm.value).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Payment has been edited successfully.');
        this.activeModal.close(x);
        this.spinner.hide();

      } else {
        this.toaster.error('Something went wrong !');
        this.spinner.hide();
      }
    }, (err) => {
      this.toaster.error('Something went wrong !');
      this.spinner.hide();
    });
  }
  show(e) {
    const value = this.EditMakePaymentForm.value.PaidDate;
    $("#ebttest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

  initMakePayment = () => {
    this.EditMakePaymentForm = this.fb.group({
      BillId: [null],
      PaidDate: ['', Validators.required],
      ReferenceNo: [''],
      PaidAmount: [''],
      AccountId: [null, Validators.required],
      PaymentModeId: ['', Validators.required],
      SourceBankName: [''],
      SourceAccountName: [''],
      SourceAccountNo: [''],
      Id: [''],
      DueAmount: [0]
    });
    this.EditMakePaymentForm.get('PaidDate').patchValue(this.formatDate(new Date()));

  }

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
}
