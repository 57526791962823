import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';

@Component({
  selector: 'app-single-document-view',
  templateUrl: './single-document-view.component.html',
  styleUrls: ['./single-document-view.component.css']
})
export class SingleDocumentViewComponent implements OnInit {
  rotationLeftAmount: number;
  rotationRightAmount: number;
  rotationAmount: number;
  isPdf: boolean;
  imagezooming: number;
  fileUrl = environment.fileBaseUrl;
  zoom: number = 1;
  url: string="";
  files:any;
  show: boolean;
  constructor(
    private ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private attachmentService: AttachmentServiceService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    let tempData = this.route.snapshot.paramMap.get("documentId");
    console.log(tempData);
    this.getAllFilesByType(tempData);
  }

  getAllFilesByType(id:any) {
    let type = localStorage.getItem('type');
    console.log(type);
    this.attachmentService.getAllFilesByType(type).subscribe((x) => {
      this.files = x;
      console.log(x);
      this.getSelectFile(id);
    });
  }

  getSelectFile=(id:any)=>{
    this.selected = this.files.find(x => x.Id == id);
    this.view(this.files.find(x => x.Id == id));
  }

  selected: any;
  src: string;
  isPdfFile(file: any) {
    let ext = file.BlobName.split(".")[1];
    return ext == 'pdf' ? true : false;
  }
  view(data:any) {
    console.log(data);
    let file = data
    this.selected = file;
    this.show = this.selected==undefined?true:false;
    this.rotationRightAmount = 0;
    this.rotationLeftAmount = 0;
    let ext = file.BlobName.split(".")[1];
    this.isPdf = ext == 'pdf' ? true : false;
    this.src = this.fileUrl + file.BlobName;
    this.imagezooming = 100;
    this.ngxSmartModalService.create('AttachmentViewerSingle', 'content').open();
    let img = document.getElementById('image');
    img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
  }
  convertDateToDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  print(file: any, id: any, pdf: boolean){
    window.open(`${this.url}files/print/${file.Id}`, '_blank');
  }

  download() {
    this.ngxSmartModalService.create('AttachmentViewerSingle', 'content').open();
  }

  zoomOut() {
    if (this.isPdf) {
      this.zoom -= .1;
    } else {
      this.imagezooming -= 10;
      let element = document.getElementById("image")
      element.style.width = this.imagezooming + "%";
    }
  }

  zoomIn() {
    if (this.isPdf) {
      this.zoom += .1;
    } else {
      this.imagezooming += 10;
      let element = document.getElementById("image");
      element.style.width = this.imagezooming + "%";
    }
  }

  rotate(direction: string) {
    this.rotationAmount = +90;
    if (direction == 'left') {
      this.rotationRightAmount -= 90;
      this.rotationLeftAmount += 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    } else {
      this.rotationRightAmount += 90;
      this.rotationLeftAmount -= 90;
      var img = document.getElementById('image');
      img.style.transform = `rotate(${this.rotationRightAmount}deg)`;
    }
  }

  modalClose(){
    this.ngxSmartModalService.getModal('AttachmentViewerSingle').close();
    this.router.navigate(['']);

  }

}
