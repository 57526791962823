
import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsServiceService } from '../../../service/Settings/settings-service.service';

@Component({
  selector: 'app-tax-rate-create',
  templateUrl: './tax-rate-create.component.html',
  styleUrls: ['./tax-rate-create.component.css']
})
export class TaxRateCreateComponent implements OnInit {

 public taxRateForm: FormGroup;
  @Input() fromParent: any;
  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private settingService: SettingsServiceService) { }

  ngOnInit(): void {
   this.initializeForm();
  }

  initializeForm() {
    this.taxRateForm = this.fb.group({
      Id:[this.fromParent.Id],
      Name: [this.fromParent.Name, Validators.required],
      RatePercent: [this.fromParent.RatePercent, Validators.required]
    });
  }

  /**
   * saveAssetType
   */
  public saveTaxRate() {
    if (this.taxRateForm.invalid){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    this.spinner.show();
    this.settingService.SaveTaxRate(this.taxRateForm.value).subscribe((x)=>{
      if(x.Success){
        this.toaster.success(this.fromParent.Id==0? 'Tax Rate has been successfully added.':"Tax Rate updated successfully.");
        this.activeModal.close(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err=>{
        this.toaster.error('ooh, something went wrong !');
    });
  }

}
