<div>
    <div class="float-right col-sm-12">
        <div style="float: left;" *ngIf="fromParent.CategoryId !=1">
            <h4 class="modal-title" style="font-size: 19px;margin-top: 20px;">View {{fromParent.Description}} Bill</h4>
            <span class="pb-2">BillNo: <span style="color:blue;"> {{BillNo}} </span></span>
            <span class="pb-2 ml-4">Status: <span style="color: #135faf;">{{getStatus(statusId)}}</span> </span>
        </div>
        <div style="float: left;" *ngIf="fromParent.CategoryId ==1">
            <h4 class="modal-title" style="font-size: 19px;margin-top: 20px;">View Basic Bill</h4>
            <span>BillNo: <span style="color:blue"> {{BillNo}} </span></span>
            <span class="pb-2 ml-4">Status: <span style="color: #135faf;">{{getStatus(statusId)}}</span></span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
        
      </div>
      <!-- <div class="mrl">
        <button class="eCount-def-btn" [disabled]="billCreationForm.invalid" (click)="saveBasicBill()">Save</button>
        <button class="eCount-def-btn ml-4 approve_bg" [disabled]="billCreationForm.invalid" (click)="approveBasicBill()">Approve</button>
    </div> -->
    <div>
        
 
    </div>
    
</div>


<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">
        <hr style="margin-top: 100px;">
        <form [formGroup]="billCreationForm">
            <div class="row">
                <div class="col-md-6">
                
                    <div class="form-group">
                        <label for="Description" style="font-weight: 600;">Description <span
                                class="required-sign">*</span></label>
                        <textarea class="form-control form-input" style="height: 60px;" readonly
                            formControlName="Description"></textarea>
                    </div>
                    <div class="form-group pe" *ngIf="fromParent.CategoryId==1">
                        <label for="To" style="font-weight: 600;">To</label>

                            <ng-select [items]="supplierList" bindLabel="CompanyName" bindValue="Id"
                                [addTag]="CreateNewSupplier" [loading]="loading" formControlName="SupplierId">
                                <ng-template ng-tag-tmp let-search="searchTerm">
                                    <b>+ Add New Customer</b>: {{search}}
                                </ng-template>
                            </ng-select>
    
                    </div>
                    <div class="form-group">
                        <label for="BillDate" style="font-weight: 600;">Date <span
                                class="required-sign">*</span></label>
                        <input type="date" class="form-control form-input" readonly formControlName="BillDate" id="ebbtest" data-date="" data-date-format="D MMM YYYY"  (change)="show($event)">
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Amount" style="font-weight: 600;">Amount <span
                                class="required-sign">*</span></label>
                        <input type="text" class="form-control form-input" appDecimaNumericValidator
                            style="font-size: 26px;font-weight: bold;" formControlName="Amount" readonly>
                    </div>
                    <div class="form-group" *ngIf="fromParent.CategoryId==1">
                        <label for="AccountId" style="font-weight: 600;">Accounts</label>
                        <ng-select [items]="accountList" placeholder="Select Account" class="customSaleItem"
                            [clearable]="false" bindLabel="AccountName" bindValue="Id" formControlName="AccountId"
                            groupBy="GroupName">
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label style="font-weight: 600;">Repeating</label>
                        <label class="switch pe" readonly>
                            <input class="switch-input pe" type="checkbox" formControlName="IsRepeating">
                            <span class="switch-label pe" data-on="Yes" data-off="No"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                    <div class="form-group pe" *ngIf="billCreationForm.value.IsRepeating">
                        <label for="RepeatingTypeId" style="font-weight: 600;">Repeating Types</label>
                        <ng-select [items]="repeatingTypeList" bindLabel="Name" bindValue="Id"
                            formControlName="RepeatingTypeId">
                        </ng-select>
                    </div>

                </div>
            </div>
        </form>
    </div>
</div>
