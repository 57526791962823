<app-dashboard>
    <app-page-header-title mainTitle="Edit Quotation" subTitle="Back" backURL="/quotation/quotation-list/All">
        <div class="full-width text-align-right">
            <div class="dropdown">
                <button type="button" style="z-index: 1;" class="btn attachment-button" (click)="openAttachmentDropdown()"><i
                        style="font-size: 20px !important;" class="material-icons">insert_drive_file</i><span
                        style="color:darkgrey; font-size: 12px; "
                        *ngIf="totalFile>=1">{{totalFile}}
                    </span>
                </button>
                <div class="x-tip-tl" style="height: 0px;">
                    <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
                </div>
                <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                    <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="4"></app-attachment-uploader>
                </div>
            </div>
            <button type="button" (click)="updateQuotation()" class="eCount-def-btn ml-4">Update</button>
            
        </div>
        
        
    </app-page-header-title>
    <div class="headSection">
    
        <div class="row f-ss-14px" >
            <div class="col-md-2 col-sm-12">
                <label>To<span class="clr">*</span> </label> 
                <ng-select  [items]="allCustomer" bindLabel="CompanyName" bindValue="Id"
                    [addTag]="CreateNewCustomer" [loading]="loading" name="name" [(ngModel)]="PrimaryPerson" [ngClass]="{'submitted':ifSubmit===true}" required>
                    <ng-template ng-tag-tmp let-search="searchTerm">
                        <b>+ Add New Customer</b>: {{search}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-2 col-sm-12">
                <form [formGroup]="forms" >
                   
                    <label>Quotation Date</label>
                    <input class="form-control" type="date" formControlName="selectedDate"  [(ngModel)]="sDate" id="qitest" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)" >
           
                </form>
            </div>
            <div class="col-md-2 col-sm-12">
                <form [formGroup]="forms" >
                   
                    <label>Delivery Date<span class="clr">*</span> </label>
                    <input class="form-control" [ngClass]="{'submitted':ifSubmit===true}" type="date" formControlName="selectedDueDate"  [(ngModel)]="sDueDate" id="qitest1" data-date="" data-date-format="D MMM YYYY" (change)="showSelecteDuedDate($event)" required>
           
                </form>
            </div>
            <div class="col-md-2 col-sm-12">
                <label>Reference</label>
                <input class="form-controlinputBox" type="text" [(ngModel)]="ReferenceNo">
            </div>
            <div class="col-md-2 col-sm-12">
                <label>Quotation No</label>
                <span class="form-controlinputBox d-flex">
                    <label style="background-color: #dddddd;padding-bottom:10%;">QU-{{ShowQuotationNo}}</label>
                    <input class="form-controlinputBox2"  type="text" [(ngModel)]="postQuotation">
                </span>
            </div>
            <div class="col-md-2 col-sm-12">
                <label>Amounts are</label>
                <ng-select [items]="taxTypeList" (change)="updateTotalPrice()" [clearable]="false" bindLabel="Name" bindValue="Id" [(ngModel)]="TaxTypeId"></ng-select>
            </div>
            <div class="col-md-2 col-sm-12">
                <label>Active?</label>
                <label class="switch">
                    <input class="switch-input"  type="checkbox" [(ngModel)]="IsActive">
                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                    <span class="switch-handle"></span>
                </label>
            </div>
        </div>
        <div class="row d-block topSection f-ss-14px">
            <div class="d-flex">
                <div class="col-md-2">
                    Item
                </div>
                <div class="col-md-2">
                    Description<span class="clr">*</span> 
                </div>
                <div class="col-md-1">
                    Quantity
                </div>
                <div class="col-md-2">
                    Price
                </div>
                <div class="col-md-1">
                    VAT%
                </div>
                <div class="col-md-1">
                    AIT%
                </div>
                <div class="col-md-3">
                    Amount
                </div>
            </div>
            <hr class="pb-0 mb-0" />
            <div>
                <div *ngFor="let item of quotationItem; let i=index">
                    <div class="invoiceGridItemRow d-block"
                        [ngClass]="[item.selected ? 'selectedRow' : 'notSelectedRow']">
                        <div class="d-flex">
                            <div class="col-md-2 borderLeft ">
                                <ng-select [items]="allSaleableItem" placeholder="Select Items" #selectItem
                                    class="customSaleItem" [clearable]="false" bindLabel="ItemName" bindValue="Id"
                                    (change)="changesellableItems(i)" [(ngModel)]="quotationItem[i].ItemId" >
                                   
                                    <ng-template ng-header-tmp>
                                        <a (click)="openItemCreateModal(selectItem)" role="button" aria-haspopup="false"
                                            aria-expanded="false"><span style="color: #007bff;">+ Add
                                                Item</span></a>
                                    </ng-template>            
                                </ng-select>
                            </div>
                            <div class="col-md-2 borderLeft ">
                                <textarea class="form-control"
                                    style="resize: none;overflow: hidden;margin: 5px; min-height: 20px; max-height: 34px;"
                                    type="text" [(ngModel)]="quotationItem[i].Description"
                                    (change)="updateDescriptionItem(i)"
                                    (click)="changeSelectRowInItem(quotationItem[i].ItemId, i)" [ngClass]="{'submitted':ifSubmit===true}" required> </textarea>
                            </div>
                            
                            <div class="col-md-1 borderLeft ">
                                <input class="form-control" type="text" appDecimaNumericValidator
                                    [(ngModel)]="quotationItem[i].Quantity" (click)="changeSelectRowInItem(quotationItem[i].ItemId, i)"
                                    (change)="updateDataItem(item.ItemId)">
                            </div>
                            <div class="col-md-2 borderLeft ">
                                <input class="form-control" type="text" appDecimaNumericValidator
                                    [(ngModel)]="quotationItem[i].UnitPrice" (click)="changeSelectRowInItem(item.ItemId, i)"
                                    (change)="updateDataItem(item.ItemId)">
                            </div>
                            <div class="col-md-1 borderLeft ">
                                <div style="width: 100%;">
                                    <ng-select [items]="taxRateList"  [disabled]="TaxTypeId==3" placeholder=""
                                    class="customSaleItem" [clearable]="false" bindLabel="Parcent"
                                        bindValue="Id" (change)="updateTotalPrice()" [(ngModel)]="quotationItem[i].TaxRateId">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-1 borderLeft ">
                                <div style="width: 100%;">
                                    <ng-select [items]="taxRateList"  [disabled]="TaxTypeId==3"
                                    class="customSaleItem" [clearable]="false" bindLabel="Parcent"
                                        bindValue="Id" (change)="updateTotalPrice()" [(ngModel)]="quotationItem[i].AdvanceTaxRateId">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-3  d-flex">
                                <div>
                                    <input class="form-control" type="text" appDecimaNumericValidator
                                        [(ngModel)]="quotationItem[i].LineTotal"
                                        (click)="changeSelectRowInItem(item.ItemId, i)"
                                        (change)="updateDataItemLineTotal(item.ItemId)">
                                </div>
                                <div style="padding: 7px 10px; color: #c7c2c2;">
                                    <div class="dropdown mr-4">
                                        <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                            aria-expanded="false">
                                            <i class="icon-more float-right"></i>
                                        </div>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                            style="width: 100%;">
                                            <div class="productRow pb-3" (click)="removeItem(item.ItemId, i)">
                                                <span class="lead1">Remove Item </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row addItem">
                <div class="BottomAddItem w-100">
                    <div class="dropdown mr-4">
                        <a class="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="false" aria-expanded="false" (click)="createBlankItem()">
                            <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line
                        </a>
                    </div>
                </div>
            </div>

            <div class="container-bottom d-flex" (click)="changeSelectRowInItem(0,0)">
                <div class="col-md-8 col-sm-12">
                    <div class="form-group col-sm-5 row">
                        <label>Notes</label>
                         <!-- <textarea maxlength="250" rows="5" type="text" class="form-notes"  placeholder="Write down some important notes using 250 characters" [(ngModel)]="quotationNotes"></textarea> -->
                         <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea"
                                placeholder="Write down some important notes using only 250 characters" [(ngModel)]="quotationNotes"></textarea>
                            <span class="remaning" >{{myInput.value.length}}/250</span>
                    </div>
                </div>
                <div class="col-md-4 col-sm-12 p-0 d-block">
                    <div class="d-flex w-100">
                        <div class="col-md-8">
                            <label>Subtotal</label>
                        </div>
                        <div class="col-md-4 mr-4">{{subTotalAmount.toFixed(2)}}</div>
                    </div>
                    <div class="d-flex w-100 pb-1" *ngIf="TaxTypeId!=3">
                        <div class="col-md-8">
                            <label>VAT</label>
                        </div>
                        <div class="col-md-4 mr-4">{{TaxAmount}}</div>
                    </div>
                    <div class="d-flex w-100 pb-1" *ngIf="TaxTypeId!=3">
                        <div class="col-md-8">
                            <label>AIT</label>
                        </div>
                        <div class="col-md-4 mr-4">{{AdvanceTaxAmount}}</div>
                    </div>
                    <div class="d-flex w-100 equalLine">
                        <span></span>
                    </div>
                    <div class="d-flex w-100 pt-3">
                        <div class="col-md-8">
                            <label>TOTAL</label>
                        </div>
                        <div class="col-md-4 font-weight-bold mr-4">{{totalPrice.toFixed(2)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
</app-dashboard>