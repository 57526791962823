import { Component, OnInit,OnDestroy } from '@angular/core';
import { MainServiceService } from '../../../service/Main/main-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-balance-list',
  templateUrl: './contact-balance-list.component.html',
  styleUrls: ['./contact-balance-list.component.css']
})
export class ContactBalanceListComponent implements OnInit,OnDestroy {
  itemTypeId:number=1;
  dataSubscription: Subscription;
  itemListTitle:string="Receivable Business Partner";
  constructor(private mainService : MainServiceService,
    private router: Router, 
    private activeRouter : ActivatedRoute,
    private spinner: NgxSpinnerService) { }

private gridApi;
private gridColumnApi;
contactList: any = []
contacts:any=[];
searchText:string;
status:string;
ngOnInit(): void {
  this.dataSubscription= this.activeRouter.params.subscribe(params => {
    this.itemTypeId=Number(this.activeRouter.snapshot.paramMap.get("typeId"));
    this.getData(this.itemTypeId);
  });
}


columnDefs = [

{ headerName: 'Company Name', field: 'CompanyName' },
{ headerName: 'Contact Name', field: 'ContactName' },
{ headerName: 'Contact No', field: 'ContactPhoneNumber' },
{ headerName: 'Email', field: 'ContactEmail' },
{ headerName: "Balance", field: 'Balance' }
];

domLayout ='autoHeight';

defaultColDef = {
sortable: true,
filter: true,
flex: 1,
};

ngOnDestroy() {
  this.dataSubscription.unsubscribe();
}


getData=(status:number)=>{
this.contactList = []
if(status == null){
this.contacts =[];
this.spinner.hide();
return;
}
if(status==1)
{
  console.log('sadi');
  this.itemListTitle ="Receivable Business Partner";
  this.GetReceiveableContactlist();
}
else if(status==2)
{
  this.itemListTitle ="Payable Business Partner";
  this.GetPayableContactList();
}
}

  private GetPayableContactList() {
    this.mainService.GetPayableContactsList().subscribe((x) => {
      console.log(x);
      this.spinner.hide();
      this.contactList = [...x, ...this.contactList];
      this.contacts = this.contactList;
    }, (err) => {
      this.spinner.hide();
    });
  }

  private GetReceiveableContactlist() {
    this.mainService.GetReceiveableContactsList().subscribe((x) => {

      this.spinner.hide();
      this.contactList = [...x, ...this.contactList];
      this.contacts = this.contactList;
    }, (err) => {
      this.spinner.hide();
    });
  }

onGridReady(event) {
this.spinner.show()
this.gridApi = event.api;
this.gridColumnApi = event.columnApi;
let type = this.activeRouter.snapshot.paramMap.get("type");
//this.reLoadRowData(this.itemTypeId);
}

onRowClicked(e) {
let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
if (colHeaderName == "Action") {
this.editItems(e);
}
}

editItems(e) {
this.router.navigate(['contacts/details-contact',e.data.Id]);
}
onBtnExport() {
var params = this.getParams();
this.gridApi.exportDataAsCsv(params);
}

getParams() {
return {
suppressQuotes: '', 
columnSeparator: '',
customHeader: '',
customFooter: '',};
}

search = () => {
this.gridApi.setQuickFilter(this.searchText);
}
reLoadRowData = (status: number) => {
   this.itemTypeId=Number(this.activeRouter.snapshot.paramMap.get("typeId"));
 this.getData(this.itemTypeId);
}
}
