import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AssetTypeModel } from 'src/app/models/fixedAsset.model';
import { FixedAssetDisposalAccountMapping } from 'src/app/models/fixedAssetDisposalAccountMapping.model';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { FixedAssetDisposalService } from 'src/app/service/FixedAsset/fixed-asset-disposal.service';
import { FixedAssetService } from 'src/app/service/FixedAsset/fixedasset.service';
import { AssetTypeCreateComponent } from '../asset-type-create/asset-type-create.component';
import { ChangeStartDateComponent } from '../change-start-date/change-start-date.component';

@Component({
  selector: 'app-fixed-asset-settings',
  templateUrl: './fixed-asset-settings.component.html',
  styleUrls: ['./fixed-asset-settings.component.css']
})
export class FixedAssetSettingsComponent implements OnInit {

  public disposalAccountForm: FormGroup;

  activeTabId: number = 1;
  AssetTypeList = [];
  allChartOfAccount: any = [];
  accountId: number;
  disposalAccount: FixedAssetDisposalAccountMapping[] = [];

  constructor(
    public fixedAssetService: FixedAssetService,
    public fixedAssetDisposalService: FixedAssetDisposalService,
    public fb: FormBuilder,
    private modalService:NgbModal,
    private toaster: ToastrService,
    private accountingService: AcountingServiceService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getAllAssetTypes();
    this.getAllChartOfAccount();
    //this.GetDisposalAccountMappings();
  }

  getAllAssetTypes() {
    this.fixedAssetService.GetAllAssetTypes().subscribe((x) => {
      if(x) {
        this.AssetTypeList = x;
      }
    });
  }

  public setActivePanel(v){
    this.activeTabId = v;
  }

  ChangeStartDate() {
    const modalRef = this.modalService.open(ChangeStartDateComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered : true});
    modalRef.result.then((result) => 
    {
      console.log(result.Success);
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }

  AddAssetType(){
    const modalRef = this.modalService.open(AssetTypeCreateComponent,{ size: 'md',backdrop: 'static', keyboard : false,});
    modalRef.componentInstance.fromParent = new AssetTypeModel();
    modalRef.result.then((result) => {
      this.getAllAssetTypes();
      console.log(result.Success);
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }

  initializeForm() {
    this.disposalAccountForm = this.fb.group({
      CapitalGainAccountTypeId: ["", Validators.required],
      GainAccountTypeId: ["", Validators.required],
      LossAccountTypeId: ["", Validators.required]
    });
  }

  getAllChartOfAccount() {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
        this.allChartOfAccount = x.RevenueList;
        this.disposalAccountForm.patchValue({CapitalGainAccountTypeId: this.allChartOfAccount.filter((xy) => xy.AccountName == "290 - Capital Gain on Disposal of Fixed Assets")[0].Id});
        this.disposalAccountForm.patchValue({GainAccountTypeId: this.allChartOfAccount.filter((xy) => xy.AccountName == "291 - Gain on Disposal of Fixed Assets")[0].Id});
        this.disposalAccountForm.patchValue({LossAccountTypeId: this.allChartOfAccount.filter((xy) => xy.AccountName == "592 - Loss on Disposal of Fixed Assets")[0].Id});
    });
  }

  /*GetDisposalAccountMappings() {
    this.fixedAssetDisposalService.GetDisposalAccountMappings().subscribe((x) => {
      if(x) {
        this.disposalAccount = x;
        this.setDataInForm();
      }
    });
  }

  setDataInForm() {
    this.disposalAccountForm.patchValue({CapitalGainAccountTypeId: this.disposalAccount[0].AccountId});
    this.disposalAccountForm.patchValue({GainAccountTypeId: this.disposalAccount[1].AccountId});
    this.disposalAccountForm.patchValue({LossAccountTypeId: this.disposalAccount[2].AccountId});
  }*/

  SaveAccountMappings() {
    if (this.disposalAccountForm.invalid){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    this.spinner.show();
    let fixedAssetData = this.getFormData();
    this.fixedAssetDisposalService.SaveAccountMappings(fixedAssetData).subscribe((x) => {
      if(x.Success){
        this.toaster.success(x.Message);
        this.spinner.hide();
        location.reload();
      }
      else{
        this.toaster.error(x.Message);
      }
    });
  }

  getFormData() : FixedAssetDisposalAccountMapping[] {
    let fixedAssetData: FixedAssetDisposalAccountMapping[] = [];
    let capitalGainAccountData = this.allChartOfAccount.filter((x) => x.Id == this.disposalAccountForm.controls.CapitalGainAccountTypeId.value)[0];
    let gainAccountData = this.allChartOfAccount.filter((x) => x.Id == this.disposalAccountForm.controls.GainAccountTypeId.value)[0];
    let lossAccountData = this.allChartOfAccount.filter((x) => x.Id == this.disposalAccountForm.controls.LossAccountTypeId.value)[0];

    let capitalGain: FixedAssetDisposalAccountMapping = {
      Id: 0,
      AccountName: capitalGainAccountData.AccountName,
      AccountId: capitalGainAccountData.Id,
      CompanyId: capitalGainAccountData.CompanyId
    }
    let gain: FixedAssetDisposalAccountMapping = {
      Id: 0,
      AccountName: gainAccountData.AccountName,
      AccountId: gainAccountData.Id,
      CompanyId: gainAccountData.CompanyId
    }
    let loss: FixedAssetDisposalAccountMapping = {
      Id: 0,
      AccountName: lossAccountData.AccountName,
      AccountId: lossAccountData.Id,
      CompanyId: lossAccountData.CompanyId
    }
    fixedAssetData.push(capitalGain);
    fixedAssetData.push(gain);
    fixedAssetData.push(loss);
    return fixedAssetData;
  }

  public EditAssetType(item: AssetTypeModel): void {
    this.spinner.show();
  
    this.fixedAssetService
      .GetAssetTypeDetails(item.Id)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: (assetType) => {
          const modalRef = this.modalService.open(AssetTypeCreateComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
  
          modalRef.componentInstance.fromParent = assetType;
  
          modalRef.result.then(() => {}, (reason) => {
            console.log(`Dismissed action: ${reason}`);
          });
        },
        error: () => {
          // Handle the error case here
        },
      });
  }
  

}
