<div class="threeSide">
    <span style="font-size: 16px;" class="ml-1 mt-2" *ngIf="isTrialPacage">Warning!</span>
    <span style="font-size: 16px;" class="ml-1 mt-2" *ngIf="!hasRenewablePackage && isGracePeriodExpired && !isTrialPacage">Warning! Subscription Expired</span>
    <span style="font-size: 16px;" class="ml-1 mt-2" *ngIf="hasRenewablePackage && !isTrialPacage">Warning! Previous Subscription Package is Expired</span>
</div>
<div>
    <div class="mrt mt-2">
        <p class="col-sm-12 txt" *ngIf="isTrialPacage">Your trial period has expired! Please purchase a plan to continue using your company without interruption.</p>
        <p class="col-sm-12 txt" *ngIf="!hasRenewablePackage && isGracePeriodExpired && !isTrialPacage">Ooop! It seems your subscription has been expired, to get access again for the premium package, please renew the subscription package.</p>
        <p class="col-sm-12 txt" *ngIf="hasRenewablePackage && !isTrialPacage">Ooop! It seems your previous subscription package has been expired.</p>
        <p class="col-sm-12 txt" *ngIf="hasRenewablePackage && !isTrialPacage">You already have a renewed package. To get access again for the premium features, please activate your renewed package.</p>
    </div>
    <div class="mt-0">
        <p class="txt pb-4 ml-3" *ngIf="isTrialPacage">to buy subscription Click here <Span class="text-blue cursor-pointer" (click)="activeModal.close()">Click here</Span></p>
        <p class="txt pb-4 ml-3" *ngIf="!hasRenewablePackage  && isGracePeriodExpired && !isTrialPacage">To renew subscription <Span class="text-blue cursor-pointer" (click)="activeModal.close()">Click here</Span></p>
        <p class="txt pb-4 ml-3" *ngIf="hasRenewablePackage && !isTrialPacage">To activate your renewed package <Span class="text-blue cursor-pointer" (click)="activeModal.close()">Click here</Span></p>
    </div>
</div>