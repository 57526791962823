import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  public GetStatusList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Project/GetStatusList').pipe(retry(1));
  }

  public GetContactList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Contact/GetAll').pipe(retry(1));
  }
  
 public GetProjectList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Project/GetAll').pipe(retry(1));
  }

  public GetDetails(projectId: number): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Project/GetDetails?projectId='+projectId).pipe(retry(1));
  }

  public DeleteProject(projectId: Int32Array): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Project/DeleteProject?projectId='+projectId).pipe(retry(1));
  }

  public CopyProject(projectId: Int32Array): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Project/CopyProject?projectId='+projectId).pipe(retry(1));
  }

 public SaveProject(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Project/save', data).pipe(retry(1));
  }

}
