<div>
  <!-- <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
        <div  style="width: 256px;">
            <a routerlinkactive="active" href="#/">
                <p class="logoText">iquidi</p>
            </a>
        </div>
        <div>
        </div>
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
        </div>
    </nav> -->
</div>
<div class="page-content">
  <div class="content-wrapper" style="width: 100vw !important;">
    <div [hidden]="validInvalidToken">
      <div class="mt-5 p-5 border rounded f-ss-14px" [hidden]="showHideNewUser">
        <p>In valid requests, please contact with your iquidi administrator.</p>
      </div>
    </div>

    <div [hidden]="!validInvalidToken">
      <div class="login mt-5 p-5 border rounded" [hidden]="!showHideNewUser">
        <h5 style="color: #245b9f; font-family: Segoe UI semibold;">
          Please set your account password to activated your account
        </h5>
        <form
          [formGroup]="passwordSetForm"
          class="f-ss-14px"
          (ngSubmit)="submitChangePassword()"
        >
          <div class="form-group">
            <label class="formTitle">Password</label>
            <input
              type="password"
              formControlName="Password"
              class="form-control mt-1"
              placeholder="Password"
            />
          </div>
          <div class="form-group">
            <label class="formTitle">Confirm Password</label>
            <input
              type="password"
              formControlName="rePassword"
              class="form-control mt-1"
              placeholder="Confirm Password"
            />
          </div>

          <button
            type="submit"
            style="float: right; background: #245b9f;"
            class="eCount-def-btn"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
