import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FactoryProductionService {
  baseurl = environment.baseUrl;
 
  constructor(private http: HttpClient) { }

  public approvePendingInList(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ProductionMovement/ApproveFromPendingInList', data).pipe(retry(1));
  }
  public approvePendingOutList(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ProductionMovement/ApproveFromPendingOutList', data).pipe(retry(1));
  }
  public GetPendingFactoryList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'ProductionMovement/GetPendingFactoryList').pipe(retry(1));
  }
 public GetRawMaterials(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetRawMaterials').pipe(retry(1));
  }
  public GetFactoryStockReport(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'ProductionMovement/GetFactoryStockReport').pipe(retry(1));
  }
  public GetFinishProducts(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetFinishProducts').pipe(retry(1));
  }

  public GetProductionInTransactions(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'ProductionMovement/GetProductionInTransactions').pipe(retry(1));
  }

  public SaveProdInAsDraft(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ProductionMovement/SaveProdIn', data).pipe(retry(1));
  }
  public UpdateProdInAsDraft(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ProductionMovement/UpdateProdIn', data).pipe(retry(1));
  }
  public ApproveProdIn(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ProductionMovement/ApproveProdIn', data).pipe(retry(1));
  }
  public UpdateApproveProdIn(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ProductionMovement/UpdateApproveProdIn', data).pipe(retry(1));
  }
  public GetRawMaterialStock(pId: number): Observable<any> {
    return this.http.get<any>(this.baseurl + 'ProductionMovement/GetRawMaterialStock?pId='+pId).pipe(retry(1));
  }
 

  public SaveOrUpdateProdOut(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ProductionMovement/SaveOrUpdateProdOut', data).pipe(retry(1));
  }

  public ApproveProdOut(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ProductionMovement/ApproveProdOut', data).pipe(retry(1));
  }

  public GetProductionOutTransactions(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'ProductionMovement/GetProductionOutTransactions').pipe(retry(1));
  }
  
  public GetOutDetails(transNo: string): Observable<any> {
    return this.http.get<any>(this.baseurl + 'ProductionMovement/GetOutDetails?transNo='+transNo).pipe(retry(1));
  }
  public GetFactoryOutSalesOrder(productId: number): Observable<any> {
    return this.http.get<any>(this.baseurl + 'SalesOrder/GetFactoryOutSalesOrder?productId='+productId).pipe(retry(1));
  }
  public GetAllFactoryOutSalesOrder(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'SalesOrder/GetAllFactoryOutSalesOrder').pipe(retry(1));
  }
  public GetProductionIn(transNo: string): Observable<any> {
    return this.http.get<any>(this.baseurl + 'ProductionMovement/GetProductionIn?transNo='+transNo).pipe(retry(1));
  }

public UpdateRawMaterialQty(data: any, returnNote: string): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Item/UpdateRawMaterialQty?returnNote='+returnNote, data).pipe(retry(1));
  }
  public SaveFactoryOutSalesOrder(data:any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'SalesOrder/SaveFactoryOutSalesOrder', data).pipe(retry(1));
  }
  DeleteFactoryOutSalesOrder(id): Observable<any> {
    return this.http.get<any>(this.baseurl + `SalesOrder/DeleteFactoryOutSalesOrder?id=${id}`).pipe(retry(1));
  }
}
