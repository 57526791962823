import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as html2canvas from "html2canvas";
import jspdf from "jspdf";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { IncomeServiceService } from "src/app/service/Income/income-service.service";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { environment } from "src/environments/environment";
import { SendInvoiceMailComponent } from "../Income/send-invoice-mail/send-invoice-mail.component";
@Component({
  selector: "app-invoice-preview",
  templateUrl: "./invoice-preview.component.html",
  styleUrls: ["./invoice-preview.component.css"],
})
export class InvoicePreviewComponent implements OnInit {
  @ViewChild("content") content: ElementRef;
  fileUrl: string = environment.fileBaseUrl;
  constructor(
    private route: ActivatedRoute,
    private mainService: MainServiceService,
    private settingsService: SettingsServiceService,
    private IncomeService: IncomeServiceService,
    private modalService: NgbModal,
    public domSanitizer: DomSanitizer,
    private spinner: NgxSpinnerService
  ) {}
  isDataReady: boolean = false;
  templates: any;
  invoiceId: number;
  invoiceFound: boolean;
  totalDiscountParcent = 0;
  totalItemDiscountAmount = 0;
  toPhone: boolean = true;
  deliveryPartnerPaymentList = [];
  totalVat = 0.00;
  toAddress: boolean = true;
  fromPhone: boolean = true;
  totalDeliveryAmount = 0;
  itemFullAmount = 0;
  fromAddress: boolean = true;
  detailsReference: boolean = true;
  detailsVat: boolean = true;
  otherNotes: boolean = true;
  otherTermsConditions: boolean = true;
  itemsTotalAmount;
  acceptPayment;
  allSaleableItem;
  dataSettings;
  invoiceData: any;
  taxRateList: any = [];
  InvoiceNo: string;
  ReferenceNo: number;
  companyList: number;
  company: any;
  logo  = true;
  headCompany = true;
  website = true;
  footCompany = true;
  userName: any;
  logoUrl: string = "";
  base64Data: string =
    "iVBORw0KGgoAAAANSUhEUgAAAGMAAABQCAIAAACCt1zBAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACuSURBVHhe7dCBAAAADASh+Uv/FAK4ELrFNKWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlNkeEXB4lahgIQEAAAAASUVORK5CYII=";
  stringUrl: string = "";
  ngOnInit(): void {
    this.spinner.show();
    this.InvoiceNo = this.route.snapshot.paramMap.get("invoiceId");
    this.getBillData(this.InvoiceNo);
    this.getAllSaleableItem();
    this.getDataSettings();
    this.getTaxRateList();
    this.userName = localStorage.getItem('LoggedInUser');
    console.log(this.userName);
    this.templates = JSON.parse(localStorage.getItem("tem"));
    if (!this.templates) {
      this.templates = {
        t1: true,
        t2: false,
      };
    }
  }

  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY");
    return dateString;
  };
  private getTaxRateList = () => {
    this.settingsService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  };
  formatTaxRate(id: number) {
    let value = this.taxRateList.find((e) => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }
  getBillData = (id) => {
    this.IncomeService.getInvoiceByInvoiceNo(id).subscribe((x) => {
      if (x) {
        this.invoiceData = x;
        this.totalDiscountParcent = this.invoiceData.InvoiceItems.reduce((prev, next) => prev + next.DiscountPercent, 0);
        this.totalItemDiscountAmount = this.invoiceData.InvoiceItems.reduce((prev, next) => prev +
        ((next.UnitPrice * next.Quantity ) - next.LineTotal), 0);
        this.totalDeliveryAmount = this.invoiceData.DeliveryPartnerItems.reduce((prev, next) => prev + next.UnitPrice, 0);
        this.itemFullAmount = this.invoiceData.InvoiceItems.reduce((prev, next) => prev + (next.UnitPrice * next.Quantity), 0);
        this.totalVat = this.invoiceData.TaxTypeId == 1 ? this.invoiceData.TaxAmount + this.invoiceData.AdvanceTaxAmount : 0.00;
        console.log(this.itemFullAmount);
        this.invoiceFound = true;
        this.InvoiceNo = this.invoiceData.Id;
        this.GetListByInvoiceId(this.invoiceData.Id);
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.getOrganizationData(x.CompanyId);
      }
    });
  };

  GetListByInvoiceId(id: string) {
    this.itemsTotalAmount = 0;
    this.IncomeService.GetInvoicePaymentList(id).subscribe((x) => {
      this.acceptPayment = x.filter(xy => xy.IsDeliveryPartner != true);
      this.spinner.hide();
      this.deliveryPartnerPaymentList = x.filter(xy => xy.IsDeliveryPartner == true);
      this.spinner.hide();

      for (let j = 0; j < this.acceptPayment.length; j++) {
        this.itemsTotalAmount += this.acceptPayment[j].Amount;

    }

    });
  }

  getAllSaleableItem = () => {
    this.IncomeService.GetAllSaleableItem().subscribe((x) => {
      this.allSaleableItem = x;
    });
  };
  getDataSettings = () => {
    this.settingsService.GetDataSettings().subscribe((x) => {
      this.dataSettings = x;
      console.log(x);
      for (let j = 0; j < this.dataSettings.length; j++) {
        if (
          this.dataSettings[j].TypeName == "To" &&
          this.dataSettings[j].Name == "Phone" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.toPhone = false;
        }
        if (
          this.dataSettings[j].TypeName == "To" &&
          this.dataSettings[j].Name == "Address" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.toAddress = false;
        }
        if (
          this.dataSettings[j].TypeName == "Footer" &&
          this.dataSettings[j].Name == "Phone" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.fromPhone = false;
        }
        if (
          this.dataSettings[j].TypeName == "From" &&
          this.dataSettings[j].Name == "Address" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.fromAddress = false;
        }
        if (
          this.dataSettings[j].TypeName == "Details" &&
          this.dataSettings[j].Name == "Reference No" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.detailsReference = false;
        }
        if (
          this.dataSettings[j].TypeName == "Details" &&
          this.dataSettings[j].Name == "VAT/AIT" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.detailsVat = false;
        }
        if (
          this.dataSettings[j].TypeName == "Others" &&
          this.dataSettings[j].Name == "Notes" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.otherNotes = false;
        }
        if (
          this.dataSettings[j].TypeName == "Others" &&
          this.dataSettings[j].Name == "Terms & Conditions" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.otherTermsConditions = false;
        }
        if (
          this.dataSettings[j].TypeName == "Header" &&
          this.dataSettings[j].Name == "Logo" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.logo = false;
        }
        if (
          this.dataSettings[j].TypeName == "Header" &&
          this.dataSettings[j].Name == "Company Name" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.headCompany = false;
        }
        if (
          this.dataSettings[j].TypeName == "Footer" &&
          this.dataSettings[j].Name == "Website" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.website = false;
        }
        if (
          this.dataSettings[j].TypeName == "Footer" &&
          this.dataSettings[j].Name == "Company Name" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.footCompany = false;
        }
      }
    });
  }
  formatItem(id: string) {
    let value = this.allSaleableItem?.find((e) => e.Id == id);
    if (!value) {
    } else {
      return value.ItemName;
    }
  }

  toDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.0;
  };
  print() {
    var originalTitle = document.title;
    document.title = "";
    window.print();
    document.title = originalTitle;
  }
  public sendMail() {
    var data = document.getElementById("bill");
    (html2canvas as any)(data, { useCORS: true }).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;

      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      var blob = pdf.output("blob");
      const modalRef = this.modalService.open(SendInvoiceMailComponent, {
        size: "md",
        backdrop: "static",
        keyboard: false,
      });
      modalRef.componentInstance.fromParent = {
        Id: this.invoiceData.Id,
        RefNo: this.invoiceData.InvoicceNo,
        PdfData: blob,
      };
      modalRef.result.then(
        (result) => {},
        (reason) => {
          console.log("Dismissed action: " + reason);
        }
      );
    });
  }
  download() {
    document.getElementById("printBill").classList.remove("box--shadow");
    var data = document.getElementById("printBill");
    (html2canvas as any)(data, { useCORS: true }).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      console.log(contentDataURL);
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;
      var DocumentName = `Invoice No # ${this.invoiceData.InvoicceNo}.pdf`;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    });
    document.getElementById("printBill").classList.add("box--shadow");
  }

  getOrganizationData = (id: string) => {
    this.mainService.getSelectedOrganizationDetails(id).subscribe((x) => {
      this.company = x.Details;
      this.spinner.hide();
      this.logoUrl =
        this.company.BlobName != "" && this.company.BlobName != null
          ? this.fileUrl + this.company.BlobName
          : "";
      console.log("logo url ---------->", this.logoUrl, this.fileUrl);
      this.getBase64ImageFromUrl(this.logoUrl)
        .then((result) => {
          this.setValue(result);
        })
        .catch((err) => console.error(err));
    });
  };

  setValue(value) {
    this.base64Data = value;
  }

  getBase64ImageFromUrl = async (imageUrl) => {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  };
}
