import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AssignStatus } from 'src/app/enum/assign-status';
import { AssignedItem } from 'src/app/models/assignee';
import { AssigneeService } from 'src/app/service/Assignee/assignee.service';
import { FixedAssetService } from 'src/app/service/FixedAsset/fixedasset.service';

@Component({
  selector: 'app-return-assigned-item-modal',
  templateUrl: './return-assigned-item-modal.component.html',
  styleUrls: ['./return-assigned-item-modal.component.css']
})
export class ReturnAssignedItemModalComponent implements OnInit {

  noteForm: FormGroup;
  
  @Input() fromParent;


  constructor(
    public fb: FormBuilder,
    public fixedAssetService: FixedAssetService,
    public assigneeService: AssigneeService,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.InitializeForm();
  }

  InitializeForm() {    
    this.noteForm = this.fb.group({
      DamageReason: ['']
    });
  }

  confirm() {    
    this.spinner.show();
    this.fromParent[0].DamageReason = this.noteForm.value.DamageReason;
    let data: AssignedItem = this.formatReturnData();

    this.assigneeService.ReturnAssignedItem(data).subscribe((x) => {
      if (x) {
        this.toaster.success("Item returned successfully");
        this.router.navigate(["/fixed-asset/assignee-list/Unassigned"]);
        this.activeModal.close();
        this.spinner.hide();
      } else {
        this.toaster.error("Something error");
        this.spinner.hide();
      }
    });
  }

  formatReturnData(): AssignedItem {
    let returnedItem: AssignedItem = {
      Id: this.fromParent[0].Id,
      AssigneeId: this.fromParent[0].AssigneeId,
      FixedAssetItem: this.fromParent[0].FixedAssetItem,
      FixedAssetItemId: this.fromParent[0].FixedAssetItemId,
      AssignedDate: this.fromParent[0].AssignedDate,
      ReturnDate: new Date(),
      EstimatedReturnDate: this.fromParent[0].EstimatedReturnDate,
      AssetRank: this.fromParent[0].AssetRank,
      DamageReason: this.fromParent[0].DamageReason,
      AssignStatus: AssignStatus.Unassigned,
      IsActive: 0,
      CompanyId: this.fromParent[0].CompanyId,
    };
    return returnedItem;
  }

}
