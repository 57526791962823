import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { data } from 'jquery';

@Component({
  selector: 'app-pending-list-view',
  templateUrl: './pending-list-view.component.html',
  styleUrls: ['./pending-list-view.component.css']
})
export class PendingListViewComponent implements OnInit {

  number = '';
  status = '';
  purchaseOrderNumber = '';
  agInit(params){
     this.status = localStorage.getItem('pendingListStatus');
     this.number = (this.status === 'factory-in' || this.status === 'factory-out') ? params.data.TransactionNo : params.data.Number;
     if(this.status === 'purchase-order')
     {
        this.purchaseOrderNumber = params.data.Id;
     }
  }

  constructor(
    private route: Router
  ) { }

  ngOnInit(): void {
  }
  viewItem = () => {
    switch (this.status)
    {
      case 'invoices':
        this.route.navigate([`/income/invoice/${this.number}`]);
        break;
      case 'bill':
        this.route.navigate([`/expense/bill/${this.number}`]);
        break;
      case 'purchase-order':
        this.route.navigate([`/procurement/purchase-order-details/${this.purchaseOrderNumber}`]);
        break;
      case 'factory-in':
        this.route.navigate([`/inventory/factory-in-view/${this.number}`]);
        break;
      case 'factory-out':
        this.route.navigate([`/inventory/factory-out-view/${this.number}`]);
        break;
    }
  }

}
