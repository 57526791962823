<app-dashboard>
    <app-page-header-title
      mainTitle="{{ 'Procurement.purchaseOrderOverview' | translate}}"
      subTitle="Home"
      backURL="/">
      <div class="full-width text-align-right">
        <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
        <button type="button" routerLink="/procurement/purchase-order-create" class="eCount-def-btn ml-4">{{ 'purchaseOrderOverview.createPurchaseOrder' | translate}}</button>
        <button type="button"  class="eCount-button3 ml-4" (click)="onBtnExport()">{{ 'purchaseOrderOverview.export' | translate}}</button>
      </div>
      
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center ">
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('All')" [routerLinkActive]="['active']" routerLink="/procurement/purchase-order-list/All"> {{'purchaseOrderOverview.all' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData('Awaitingapproval')" [routerLinkActive]="['active']" routerLink="/procurement/purchase-order-list/Awaitingapproval">{{'purchaseOrderOverview.awaitingApproval' | translate}} ({{draftCount}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData('Approved')" [routerLinkActive]="['active']" routerLink="/procurement/purchase-order-list/Approved">{{'purchaseOrderOverview.approved' | translate}} ({{approvedCount}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('Billed')" [routerLinkActive]="['active']" routerLink="/procurement/purchase-order-list/Billed">{{'purchaseOrderOverview.PO_Settled' | translate}} ({{billedCount}})</a>
      </li>
    </ul>
    <div class="row pt-3" id="printBills">
        <ag-grid-angular
            style="font-size: 14px;"
            class="ag-theme-balham col"
            [rowData]="filteredBills"
            [columnDefs]="columnDefs"
            [domLayout]="domLayout"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
    </div>
    
    </app-dashboard>
    
    