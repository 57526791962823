<div class="modal-header" style="top: 20px;">
    <h4 *ngIf="itemId == 0 && itemForm.value.ItemTypeId === 4" class="modal-title" style="font-size: 17px; font-weight: 500;">Add New Storage Item</h4>
    <h4 *ngIf="itemId > 0 && itemForm.value.ItemTypeId === 4" class="modal-title" style="font-size: 17px; font-weight: 500;">Edit Storage Item</h4>
    <h4 *ngIf="itemId == 0 && itemForm.value.ItemTypeId === 3" class="modal-title" style="font-size: 17px; font-weight: 500;">Add New Finished Products</h4>
    <h4 *ngIf="itemId > 0 && itemForm.value.ItemTypeId === 3" class="modal-title" style="font-size: 17px; font-weight: 500;">Edit Finished Products</h4>
    <h4 *ngIf="itemId == 0 && itemForm.value.ItemTypeId === 5" class="modal-title" style="font-size: 17px; font-weight: 500;">Add New Services</h4>
    <h4 *ngIf="itemId > 0 && itemForm.value.ItemTypeId === 5" class="modal-title" style="font-size: 17px; font-weight: 500;">Edit Services</h4>
    <button type="button" class="close" aria-label="Close"(click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="itemForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">

                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="ItemCode" style="font-weight: 600;">Item code<span
                                    class="required-sign">*</span></label>
                                <input type="text" class="form-control form-input"  formControlName="ItemCode"  (change)="copyItemCode($event)"
                                    required>
                            </div>
                            <div class="form-group  col-sm-6" *ngIf="itemForm.value.ItemTypeId != 5">
                                <label for="PurchasePrice" style="font-weight: 600;">Purchase Price/Unit<span
                                    class="required-sign">*</span></label>
                                <input type="text" class="form-control form-input" appDecimaNumericValidator
                                    formControlName="PurchasePrice">
                            </div> 
                            <div class="form-group col-sm-6" *ngIf="itemForm.value.ItemTypeId == 5">
                                <label for="SaleUnitPrice" style="font-weight: 600;">Sales Price/Unit<span
                                    class="required-sign">*</span></label>
                                <input type="text" class="form-control" appDecimaNumericValidator  style="width: 99%;" formControlName="SaleUnitPrice">
                            </div>
                                      
                        </div>
                    </div>
                    <div class="row rowBottomBorder">

                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group col-sm-6">
                                <label for="ItemName" style="font-weight: 600;">Item Name<span
                                    class="required-sign">*</span></label>
                                <input type="text" class="form-control"  style="width: 99%;" formControlName="ItemName"
                                   required>
                            </div> 
                            <div class="form-group  col-sm-6 " *ngIf="itemForm.value.ItemTypeId !== 3">
                                    <label for="Description" style="font-weight: 600;">Description<span class="required-sign">*</span></label>
                                    <input type="text" class="form-control form-input"formControlName="Description" required>
                            </div>
                            <div class="form-group  col-sm-6 " *ngIf="itemForm.value.ItemTypeId === 3">
                                <label for="SaleUnitPrice" style="font-weight: 600;">Sales Price/Unit<span class="required-sign">*</span></label>
                                <input type="text" class="form-control form-input"formControlName="SaleUnitPrice" appDecimaNumericValidator required>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6 " *ngIf="itemForm.value.ItemTypeId === 3">
                                    <label for="Description" style="font-weight: 600;">Description<span class="required-sign">*</span></label>
                                    <input type="text" class="form-control form-input"formControlName="Description" required>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6 ">
                                <label for="SupplierId" style="font-weight: 600;">Select Supplier <span class="required-sign">*</span></label>
                                <ng-select [items]="supplierList" [clearable]="false" bindLabel="CompanyName" bindValue="Id" [addTag]="CreateNewSupplier" [loading]="loading" formControlName="SupplierId">
                                    <ng-template ng-tag-tmp let-search="searchTerm">
                                        <b>+ Add New Supplier</b>: {{search}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12">
                <button type="button" style="float: right;margin-right: 14px;" (click)="saveOrUpdate()"
                    class="eCount-def-btn  ml-4"  >Save</button>
                    
            </div>
        </div>
    </div>
</div>
