<app-dashboard>
    <div [hidden]="dataFound">
        <p></p>
    </div>
    <div [hidden]="!dataFound">
        <app-page-header-title
            mainTitle="Quotation / {{QuotationData.QuotationNo}}"
            subTitle="Back"
            backURL="/quotation/quotation-list/All">

            <div class="full-width text-align-right">
                <div class="row">
                    <div class="col-sm-11" [ngClass]="{'col-sm-12': isActive}">
                        <div class="dropdown">
                            <button id="attachment_btn" type="button" style="z-index: 1;" class="btn attachment-button"><i id="attachment_btn"
                                style="font-size: 20px !important;" class="material-icons">insert_drive_file</i><span id="attachment_btn"
                                style="color:darkgrey; font-size: 12px; "
                                *ngIf="totalFile>=1">{{totalFile}}
                            </span>
                        </button>
                            <div class="x-tip-tl" style="height: 0px;">
                                <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
                            </div>
                            <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                                <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="4"></app-attachment-uploader>
                            </div>
                        </div>
                        <!-- <button type="button" *ngIf="!QuotationData.IsConfirmed && QuotationData.IsActive" (click)="ConfirmQuotation()"  class="eCount-def-btn ml-4">Confirm</button> -->
                        <!-- <button type="button" *ngIf="QuotationData.IsConfirmed && !QuotationData.IsSalesOrderCreated" (click)="CreateSalesOrder()"  class="eCount-def-btn ml-4">Create Sales Order</button> -->
        
                        <button type="button"  *ngIf="!QuotationData.IsConfirmed" [routerLink]="['/quotation/edit-quotation',QuotationData.Id]"  class="eCount-def-btn ml-4">Edit</button>
                        <button type="button" *ngIf="!QuotationData.IsConfirmed && QuotationData.IsActive" (click)="ConfirmQuotation()"  class="eCount-def-btn ml-4">Confirm</button>
                        <button type="button" *ngIf="QuotationData.IsConfirmed && !QuotationData.IsSalesOrderCreated" (click)="CreateSalesOrder()"  class="eCount-def-btn ml-4">Create Sales Order</button>
                        <button type="button" (click)="print(QuotationData.QuotationNo)" class="eCount-button3 ml-4">Print</button>
                    </div>
                    <div  style="padding: 7px 10px; color: #c7c2c2;" *ngIf="!isActive">
                        <div class="dropdown">
                            <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                                aria-expanded="false">
                                <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
                            </div>
                            <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                                style="width: 100%; height: 40px;">
                                <div class="context-menu-item pb-3" *ngIf="!isActive">
                                    <span class="context-menu-text text-center" (click)="deleteQuotation()">Delete</span>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-page-header-title>
        <div class="m" id="printQuotation">
            <div class="row">
                <div class="col-md-12 d-flex f-ss-14px">
                    <div class="col-md-3 d-block pl-1">
                        <p class="ToHeading">To</p>
                        <div>Name : {{QuotationData.Contacts ? QuotationData.Contacts.CompanyName : ''}}</div>
                        <div>Email : {{QuotationData.Contacts ? QuotationData.Contacts.Email : ''}}</div>
                        <div>Address : {{QuotationData.Contacts ? QuotationData.Contacts.StreetAddress : ''}}</div>
                    </div>
                    <div class="col-md-3">
                        <p class="ToHeading">Date</p>
                        <div>Created Date : {{convertDateIntoDisplay(QuotationData.CreatedAt)}}</div>
                        <div>Invoice Date : {{convertDateIntoDisplay(QuotationData.QuotationDate)}}</div>
                        <div>Delivery Date : {{convertDateIntoDisplay(QuotationData.DueDate)}}</div>
                        <div>Modified By : {{QuotationData.ModifiedByName ? QuotationData.ModifiedByName : QuotationData.CreatedByName}}</div>
                        <div>Delivery Date :  {{convertDateIntoDisplay(QuotationData.UpdatedAt ? QuotationData.UpdatedAt : QuotationData.CreatedAt)}} </div>
                    </div>
                    <div class="col-md-3">
                        <p class="ToHeading">Quotation Details</p>
                        <div>Quotation No # {{QuotationData.QuotationNo}}</div>
                        <div>Reference No # {{QuotationData.ReferenceNo}}</div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row f-ss-14px" >
                <div class="col-md-12 tax exchange">
                    <div class="float-right">
                        <label> Amounts are  </label>
                        <strong *ngIf="QuotationData.TaxTypeId==1"> VAT and AIT Exclusive  </strong>
                        <strong *ngIf="QuotationData.TaxTypeId==2"> VAT and AIT Inclusive  </strong>
                        <strong *ngIf="QuotationData.TaxTypeId==3"> No VAT and AIT  </strong>
                    </div>
                </div>
                <div class="col-md-12 invoice-grid">
                    <table class="w-100" border="1|0">
                        <thead>
                            <tr>
                            <td>Item</td>
                            <td>Description</td>
                            <td>Quantity</td>
                            <td>Unit Price</td>
                            <td>VAT Rate</td>
                            <td>AIT Rate</td>
                            <td class="amount"> <div class="float-right">Amount BDT</div></td>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of QuotationData.QuotationItem">
                                <td>{{formatItem(item.ItemId)}}</td>
                                <td>{{item.Description}}</td>
                                <td>{{item.Quantity | currency:'':''}}</td>
                                <td>{{twoDecimalPlace(item.UnitPrice) | currency:'':''}}</td>
                                <td>{{formatTaxRate(item.TaxRateId)}}</td>
                                <td>{{formatTaxRate(item.AdvanceTaxRateId)}}</td>
                                <td><div class="float-right">{{twoDecimalPlace(item.LineTotal)}}</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row f-ss-14px">
                <div class="container-bottom d-flex w-100">
                    <div class="col-md-8 col-sm-12">
                        <div class="form-group col-sm-5 row">
                            <label>Notes</label>
                             <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea"
                                placeholder="Write down some important notes using only 250 characters">{{QuotationData.Notes}}</textarea>
                            <span class="remaning" >{{myInput.value.length}}/250</span>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label>Subtotal</label>
                            </div>
                            <div class="col-md-4 float-right"><p class="float-right pr-3">{{twoDecimalPlace(QuotationData.Amount-QuotationData.TaxAmount-QuotationData.AdvanceTaxAmount) | currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100 pb-1">
                            <div class="col-md-8">
                                <label>Total VAT</label>
                            </div>
                            <div class="col-md-4"> <p class="float-right pr-3">{{QuotationData.TaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100 pb-1">
                            <div class="col-md-8">
                                <label>Total AIT</label>
                            </div>
                            <div class="col-md-4"> <p class="float-right pr-3">{{QuotationData.AdvanceTaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100 singleLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label class="totalTextValue">Total</label>
                            </div>
                            <div class="col-md-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(QuotationData.Amount)}}</p></div>
                        </div>
                        <div class="d-flex w-100 equalLine">
                            <span></span>
                        </div>
          
                        <div class="d-flex w-100 singleLine">
                            <span></span>
                        </div>
                       
                    </div>
                </div>
            </div>

        </div>
    </div>

</app-dashboard>