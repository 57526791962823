import { Component, OnInit,OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {MainServiceService} from 'src/app/service/Main/main-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { IfStmt } from '@angular/compiler';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { stringify } from '@angular/compiler/src/util';
@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.css']
})
export class ChangeUserPasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  email: string;
  isEmail: boolean;
  selectedLanguage = 'en';
  constructor(
    private attachmentService: AttachmentServiceService,
    private authService: AuthService,
    private dataService: DataService,
    public fb: FormBuilder,
    private settingsService: SettingsServiceService,
    private router: Router, private toaster: ToastrService,
    private mainService: MainServiceService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getLogInUserEmail();
    this.organizationData();
    this.getSelectedLanguage();
  }

  organizationData() {
    this.changePasswordForm = this.fb.group({
      CurrentPassword: ['', Validators.required],
      NewPassword: ['', Validators.required],
      RetypePassword: ['', Validators.required],
    });
  }

  // onSaveOrganization() {
  // }
  // numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }

  getLogInUserEmail()
  {
    this.email = localStorage.getItem('user');
    this.isEmail = this.email.includes('@');
  }

  getSelectedLanguage = () => {
    this.selectedLanguage = localStorage ? localStorage['language'] : null;
  }

  logout = () => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('language');
    localStorage.setItem('language', this.selectedLanguage);
    this.dataService.emitDefaultNotificationUpdate(true);
    this.router.navigate(['/auth/login']);
  }

  validatePassword = (password) => {
    console.log(password);
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{5,}$/;
    return re.test(password);
  };

  UpdateUserPasswordDetails(){
    if (
      this.changePasswordForm.invalid &&
      (this.changePasswordForm.value.RetypePassword.length == 0 ||
        this.changePasswordForm.value.NewPassword.length == 0 || 
        this.changePasswordForm.value.CurrentPassword.length == 0
        )
    ) {
      console.log(this.changePasswordForm.value.RetypePassword);
      this.toaster.warning("All the fields are required");
      return;
    }
    if (!this.validatePassword(this.changePasswordForm.value.NewPassword)) {
      this.toaster.warning(
        "Password should be minimum 6 characters long and must have one Capital letter and small letter"
      );
      return;
    }
    if (
      this.changePasswordForm.value.NewPassword !=
      this.changePasswordForm.value.RetypePassword
    ) {
      this.toaster.warning("New Password and Re-typed Password must match!");
      return;
    }
    this.spinner.show();
    const formData = {
      Email: this.email.toString(),
      CurrentPassword: this.changePasswordForm.get("CurrentPassword").value,
      NewPassword: this.changePasswordForm.get("NewPassword").value
    };
    this.mainService.UpdateUserPasswordData(formData).subscribe((x) => {
      if (x.Success)
      {
        console.log(x);
        // const userInfo = this.authService.getUserInfo();
        // userInfo.UserName = x.data.FirstName[0].toString();
        // localStorage.removeItem('userInfo');
        // this.authService.setUserInfo(JSON.stringify(userInfo));
        // console.log(userInfo);
        this.spinner.hide();
        this.toaster.success('Password updated successfully.')
        //location.reload();
        this.logout();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

}
