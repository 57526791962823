<app-dashboard>
    <app-page-header-title mainTitle="Projects" subTitle="Home" backURL="/">
        <div class="col-md-6 text-align-right">
            <span class="status-count" [ngClass]="{'status-count-active': activeStatusId===2}"
                (click)="SetFilterData(2)"> {{'Project.onGoing' |
                translate}}({{statusCountModel.TotalInProgress}})</span>
            <span class="status-count" [ngClass]="{'status-count-active': activeStatusId===1}"
                (click)="SetFilterData(1)"> {{'Project.draft' | translate}}({{statusCountModel.TotalDraft}})</span>
            <span class="status-count" [ngClass]="{'status-count-active': activeStatusId===4}"
                (click)="SetFilterData(4)"> {{'Project.onHold' | translate}}({{statusCountModel.TotalOnHold}})</span>
            <span class="status-count" [ngClass]="{'status-count-active': activeStatusId===3}"
                (click)="SetFilterData(3)"> {{'Project.closed' | translate}}({{statusCountModel.TotalClosed}})</span>
        </div>
        <div class="col-md-4 text-align-right">
            <button type="button" class="eCount-def-btn ml-4" (click)="CreateNewProject()">{{'Project.createNew' |
                translate}}</button>
        </div>

    </app-page-header-title>
    <div class="row" id="project-list" [hidden]="statusWiseProjectList.length==0">
        <div class="w-100 col">
            <table class="table  f-ss-14px">
                <thead>
                    <tr class="tableHeader">
                        <th>

                        </th>
                        <th scope="col" class="table-header-color text-align-left">{{'Project.projectName' | translate}}
                        </th>
                        <th scope="col" class="table-header-color text-align-center">{{'Project.deadline' | translate}}
                        </th>
                        <th scope="col" class="table-header-color text-align-center">{{'Project.winPrice' | translate}}
                        </th>
                        <th scope="col" class="table-header-color text-align-center">{{'Project.unInvoicedExpenses' |
                            translate}}</th>
                        <th scope="col" class="table-header-color text-align-center">{{'Project.progressBar' |
                            translate}}</th>
                        <th scope="col" class="table-header-color text-align-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of statusWiseProjectList">
                        <td style="width: 60px;">
                            <div class="short-name-highlight">{{item.ShortName}}</div>
                        </td>
                        <td class="text-align-left cursor-pointer" (click)="DetailsProject(item)">{{item.Title}}</td>
                        <td class="text-align-center">{{item.DeadlineVw}}</td>
                        <td class="text-align-center">{{item.WinPrice}}</td>
                        <td class="text-align-center">{{item.UnInvoicedAmount}}</td>
                        <td class="text-align-center">
                                <ngb-progressbar type="success" [value]="item.Percentage" height=".5rem"></ngb-progressbar>
                                
                        </td>
                        <td class="text-align-center">
                            <div style="padding: 7px 10px; color: #c7c2c2;">
                                <div class="dropdown mr-4">
                                    <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                                        aria-expanded="false">
                                        <i class="icon-more float-right"></i>
                                    </div>
                                    <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                                        style="width: 100%;">

                                        <div class="context-menu-item pb-3" (click)="DetailsProject(item)">
                                            <i class="icon-book"></i> <span class="context-menu-text">{{'Project.details' |
                                                translate}} </span>
                                        </div>
                                        <div class="context-menu-item pb-3" (click)="EditProject(item)">
                                            <i class="icon-pencil"></i> <span class="context-menu-text">{{'Project.edit' |
                                                translate}} </span>
                                        </div>
                                        <div class="context-menu-item pb-3" (click)="CopyProject(item)">
                                            <i class="icon-copy"></i><span class="context-menu-text">{{'Project.copyProject' |
                                                translate}}
                                            </span>
                                        </div>
                                        <div class="context-menu-item pb-3" (click)="confirmDeletion(item)">
                                            <i class="icon-trash"></i><span class="context-menu-text">{{'Project.delete' |
                                                translate}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-dashboard>