<app-dashboard>
    <app-page-header-title
        mainTitle="Add Chart of Account"
        subTitle="Chart of Account"
        backURL="/accounting/chart-of-accounts">
        <div class="full-width text-align-right">
            <button type="button" (click)="submitAddAccountForm()" class="eCount-def-btn ml-4">{{'Contacts.CreateContact.save' | translate}}</button>
        </div>
    </app-page-header-title>
    <div class="bodySection">
        <div class="pb-4">
        <div class="row pr-2 pl-2">
            <div class="col-md-6">
                <div>
                    <form [formGroup]="addAccountForm" class="f-ss-14px" (ngSubmit)="submitAddAccountForm()">
                        <div class="form-group">
                            <span class="formTitle">Account Type</span>
                            <ng-select [items]="accountTypes" formControlName="AccountTypeId" class="custom"   [clearable]="false"  bindLabel="Name" bindValue="Id"  groupBy="GroupName" (change)="changeAccountType($event)" ></ng-select>
                        </div>
                        <div class="form-group">
                            <span class="formTitle">Code</span>
                            <span class="formSubTitle">A unique code/number for this account ( Limited to 10 character)</span>
                            <input type="text" formControlName="AccountCode" class="form-control mt-1" placeholder="Code">
                        </div>
                        <div class="form-group">
                            <span class="formTitle">Name</span>
                            <span class="formSubTitle">A short title for this account ( Limited to 50 character)</span>
                            <input type="text" formControlName="AccountName" class="form-control mt-1"  placeholder="Name">
                        </div>
                        <div class="form-group">
                            <span class="formTitle">Description (Optional)</span>
                            <span class="formSubTitle">A description of how this account should be used</span>
                            <textarea class="form-control" rows="3"></textarea>
                        </div>
                        <!-- <div class="form-group">
                            <input type="checkbox" id="ds" name="ShowInExpenseClaim" formControlName="ShowInExpenseClaim" value="Bike">
                            <label for="ShowInExpenseClaim"> Show In Expense Claim</label><br>
                        </div> -->

                    </form>
        
                </div>
            </div>
            <div class="col-md-6 f-ss-14px">
                <p>How account types affects your reports</p>
                <img src="../../../../assets/image/page_content/accounting_create_account.PNG" />
                <!-- <div>
                    <div class="btn-group mr-4">
                        <button type="submit" (click)="submitAddAccountForm()" class="btn btn-primary eCount-button">Save and Close</button>
                      </div>
                    <button type="button" (click)="closeModal()"  class="btn btn-secondary w-45 eCount-button-cancel">Cancel</button>
                </div> -->
            </div>
        </div>
        
        </div>
    </div>
</app-dashboard>




