import { Component, OnInit, OnDestroy , Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuotationService } from '../../../service/Quotation/quotation.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.css']
})
export class SendMailComponent implements OnInit, OnDestroy  {
  @Input() fromParent: any;
  quotationSubscription: Subscription;
  users:any= [];
  selectedUserIds: any=[];
  mailSubject:string ='';
  mailBody:string ='';
  IsAttachPdf:boolean=false;
  SendMeCopy:boolean=false;

  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private quotationService: QuotationService) { 
    }
    

  ngOnInit(): void {
    this.getDetails();
  }

  private getDetails = () => {
    this.spinner.show();
   this.quotationSubscription= this.quotationService.GetMailDetails(this.fromParent.Id).subscribe((x) => {
      this.users=[{id:x.ContactEmail,name:x.ContactEmail}];
      this.selectedUserIds=[x.ContactEmail];
      this.mailSubject=x.MailSubject;
      this.mailBody=x.MailBody;
      this.spinner.hide()
    },(err)=>{
      this.spinner.hide()
    })
  };

  addCustomUser = (term) => ({id: term, name: term});

  /**
   * SendToPerson
   */
  public SendToPerson() {
    if (this.mailSubject=='' || this.mailBody==''){
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    this.spinner.show();
    if(this.IsAttachPdf){
      this.SendMailWithAttachment();
    }
    else{
      this.SendMailWihoutAttachment();
    }
  }

  private SendMailWihoutAttachment() {
    var data={Id:this.fromParent.Id,Subject:this.mailSubject,Body:this.mailBody,To: this.selectedUserIds,RefNo: this.fromParent.RefNo,SendMeCopy:this.SendMeCopy};
    this.quotationService.SendMail(data).subscribe((x)=>{
      if(x.Success){
        this.toaster.success("Mail send successfully.");
        this.activeModal.close(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide()
    }, err=>{
        this.toaster.error('ooh, something went wrong !');
        this.spinner.hide()
    });
  }

  private SendMailWithAttachment() {
    var data={Id:this.fromParent.Id,Subject:this.mailSubject,Body:this.mailBody,To: this.selectedUserIds,RefNo: this.fromParent.RefNo,SendMeCopy:this.SendMeCopy};
    let formData = new FormData();
    formData.append('my-file', this.fromParent.PdfData);
    formData.append('dataModel',JSON.stringify(data));
    this.quotationService.SendMailWithAttachment(formData).subscribe((x)=>{
      this.activeModal.close();
      this.spinner.hide()
    }, err=>{
        this.toaster.error('ooh, something went wrong !');
        this.spinner.hide()
    });
    this.toaster.success("Mail send successfully.");
  }

  ngOnDestroy() {
   this.quotationSubscription.unsubscribe();
}

}
