import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillsServiceService } from '../../../service/Bill/bills-service.service';
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { css } from 'jquery';
@Component({
  selector: 'app-basic-bill-create',
  templateUrl: './basic-bill-create.component.html',
  styleUrls: ['./basic-bill-create.component.css']
})
export class BasicBillCreateComponent implements OnInit {
  public billCreationForm: FormGroup;
  @Input() fromParent: any;
  accountList:any=[];
  loading = false;
  selectedSupplier: number;
  supplierList:any=[];
  repeatingTypeList:any=[
    {Id:"",Name:""},
    {Id:"1",Name:"Daily"},
    {Id:"2",Name:"Weekly"},
    {Id:"3",Name:"Monthly"},
    {Id:"4",Name:"Yearly"}
  ];

   constructor(
    private spinner: NgxSpinnerService,
    private mainService: MainServiceService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private acountingService:AcountingServiceService,
    private billService: BillsServiceService) { }

  ngOnInit(): void {
    this.initializeForm();
    console.log(this.fromParent);
    if(this.fromParent.CategoryId==1){
      this.acountingService.GetChartOfAccountsforItems().subscribe((x)=>{
        this.accountList=x.ExpenseList.filter(x=>x.ParentId ==3 && x.AccountCode != '310');

    });

    }
    this.getAllSupplier();
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : false,
      IsSupplier : true,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {

        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.supplierList.push(x["Data"]);
        let customerObj = this.supplierList.filter(x=>x.PrimaryPersonFirstName == customerName);
        this.selectedSupplier = customerObj[0]['Id'];
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }

  getAllSupplier = () => {
    this.billService.GetAllContact().subscribe((x: []) => {
      this.supplierList = x;
    });
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  show(e) {
    const value = this.billCreationForm.value.BillDate;
    $("#bbtest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  initializeForm() {
    this.billCreationForm = this.fb.group({
      Id:[this.fromParent.Id],
      BillDate: [this.fromParent.BillDate, Validators.required],
      Description: [this.fromParent.Description, Validators.required],
      Amount: [this.fromParent.Amount, Validators.required],
      CategoryId: [this.fromParent.CategoryId],
      AccountId: [this.fromParent.AccountId],
      SupplierId: [this.fromParent.SupplierId],
      IsRepeating: [false],
      RepeatingTypeId: [this.fromParent.RepeatingTypeId],
      StatusId: []
    });
    this.billCreationForm.get('BillDate').patchValue(new Date());
    this.show(this.billCreationForm.value.BillDate);
    this.billCreationForm.get('CategoryId').patchValue(this.fromParent.CategoryId);
  }

  public saveBasicBill() {
    if(this.billCreationForm.value.Amount<=0){
      this.toaster.warning('Please enter an amount!');
      return;
    }
    this.billCreationForm.get('StatusId').patchValue(1);
    this.SaveBill();
  }
  public approveBasicBill() {
    if(this.billCreationForm.value.Amount<=0){
      this.toaster.warning('Please enter an amount!');
      return;
    }
    this.billCreationForm.get('StatusId').patchValue(2);
    this.SaveBill();
  }

  public SaveBill() {
    if (this.billCreationForm.invalid || (this.billCreationForm.value.CategoryId==1 && this.billCreationForm.value.SupplierId==null)|| (this.billCreationForm.value.IsRepeating==true && this.billCreationForm.value.RepeatingTypeId==null )){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    console.log(this.billCreationForm.value);
    this.spinner.show();
    this.billService.SaveBasicBill(this.billCreationForm.value).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success(this.billCreationForm.value.StatusId === 1? 'Bill saved successfully':"Bill approved successfully");
        this.activeModal.close(x);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }
  validateType(value) {
   console.log(value);
  }

}
