import { Component, OnInit ,OnDestroy,NgZone} from '@angular/core';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-factory-in-list',
  templateUrl: './factory-in-list.component.html',
  styleUrls: ['./factory-in-list.component.css']
})
export class FactoryInListComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
              private router: ActivatedRoute,
              private pService: FactoryProductionService) { }

      private gridApi;
      private gridColumnApi;
      domLayout='autoHeight';
      isItemListUpdate = false;
      searchText: string;
      draftCount: number;
      approvedCount: number;
      TransactionList: any = [];
      status: any;
      data: any;
    ngOnInit(): void {
      this.status = this.router.snapshot.paramMap.get('status');
      console.log(this.status);
      this.getTransactions();
    }
  
      columnDefs = [
        {
          headerName: 'Transaction No', field: 'TransactionNo',
            cellRenderer: function(params) {
              return `<a href="#/inventory/factory-in-view/${params.value}" >${params.value}</a>`;
            }
        },
        { headerName: 'In Date', field: 'InDate', valueFormatter: this.convertGridDateIntoDisplay },
        { headerName: 'Reference No', field: 'ReferrenceNo' },
        { headerName: 'Description', field: 'Description' },
        { headerName: 'Total In Qty', field: 'TotalInQty' },
        { headerName: 'Total Amount', field: 'TotalAmount' }
        ];
    
      defaultColDef = {
        sortable: true,
        filter: true,
        flex: 1
      };
      convertGridDateIntoDisplay(date) {
        let dateString = moment(date.value).format("Do MMM YYYY")
        return dateString;
      }

      getTransactions = () => {
        this.spinner.show();
        this.TransactionList = [];
        this.pService.GetProductionInTransactions().subscribe((x) => {
          this.spinner.hide();
          this.data = x;
          this.TransactionList = this.status == 'Draft' ? x.filter(xy => xy.StatusId === 1) : this.status == 'Approved' ?  
          x.filter(xy => xy.StatusId === 2) :  x ;
        });
      }
      onGridReady(event) {
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
        this.getTransactions();
      }

      onBtnExport() {
        var params = this.getParams();
        this.gridApi.exportDataAsCsv(params);
      }
    
      getParams() {
        return {
        suppressQuotes: '', 
        columnSeparator: '',
        customHeader: '',
        customFooter: '',};
      }
    
      onRowClicked(event){
       
      }
    
      search = () => {
        this.gridApi.setQuickFilter(this.searchText);
      }
      reLoadRowData = (status: string) => {
        this.status = status;
        this.TransactionList = this.status == 'Draft' ? this.data.filter(xy => xy.StatusId == 1) : this.status == 'Approved' ?  
        this.data.filter(xy => xy.StatusId == 2) :  this.data ;
      }
}
