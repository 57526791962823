<app-dashboard>

    <div  *ngIf="Found">
        <app-page-header-title
            mainTitle="Receive Money / {{ReceiveMoney.IsDraft?'Draft':'Submitted'}}"
            subTitle="Receive Money Details"
            backURL="/receivemoney/receive-money-list/all">

            <div class="full-width text-align-right">

              <button *ngIf="ReceiveMoney.IsDraft" type="button" [routerLink]="['/receivemoney/update/',ReceiveMoney.Id]"  class="eCount-def-btn ml-2">Edit</button>
              <button *ngIf="ReceiveMoney.IsDraft" type="button"  (click)="submitReceiveMoneyData()"  class="eCount-def-btn ml-4">Submit</button>
              <button *ngIf="ReceiveMoney.IsDraft" type="button" style="background: red;" (click)="confirmReceiveMoneyRejection()" class="eCount-def-btn ml-4">Delete</button>
          </div>


        </app-page-header-title>
    <div class="row">
        <div class="col-md-12 d-flex f-ss-14px">
            <div class="col-md-2 d-block pl-1">
                <p class="ToHeading">From</p>
                <div>{{ReceiveMoney.FromName}}</div>
            </div>
            <div class="col-md-2">
                <p class="ToHeading">Date</p>
                <div>{{convertDateIntoDisplay(ReceiveMoney.ReceivedDate)}}</div>
            </div>
            <div class="col-md-2">
                <p class="ToHeading">Account Name</p>
                <div>{{ReceiveMoney.ChartOfAccount.AccountName}}</div>
            </div>
            <div class="col-md-2">
                <p class="ToHeading">Reference</p>
                <div>{{ReceiveMoney.ReferenceNo}}</div>
            </div>
            <div class="col-md-2">
                <p class="ToHeading">Total</p>
                <div>{{ convertToLocaleString(ReceiveMoney.SubTotalAmount) }}</div>
            </div>
        </div>
    </div>
    <hr/>
    <div class="row f-ss-14px" >
        <div class="col-md-12 tax exchange">
            <div class="float-right">
                <label> Amounts are  </label>
                <strong *ngIf="ReceiveMoney.TaxId == 1"> VAT and AIT Exclusive  </strong>
                <strong *ngIf="ReceiveMoney.TaxId == 2"> VAT and AIT Inclusive  </strong>
                <strong *ngIf="ReceiveMoney.TaxId == 3"> No VAT and AIT  </strong>
            </div>
        </div>
        <div class="col-md-12 invoice-grid">
            <table class="w-100" border="1|0">
                <thead>
                    <tr>
                    <td>Description</td>
                    <td> Quantity </td>
                    <td>Unit Price</td>
                    <td>Account</td>
                    <td>VAT Rate</td>
                    <td>AIT Rate</td>
                    <td class="amount"> <div class="float-right">Amount BDT</div></td>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of ReceiveMoney.ReceiveMoneyItems">
                        <td>{{item.ItemDescription}}</td>
                        <td>{{item.Quantity}}</td>
                        <td>{{twoDecimalPlace(item.UnitPrice)}}</td>
                        <td class="editable-cell">{{item.Item}}</td>
                        <td *ngIf="item.TaxRateId != null">{{item.TaxRatePercent?.toFixed(2)}}%</td>
                        <td *ngIf="item.TaxRateId == null"></td>
                        <td *ngIf="item.AdvanceTaxRateId != null">{{item.AdvanceTaxPercent?.toFixed(2)}}%</td>
                        <td *ngIf="item.AdvanceTaxRateId == null"></td>
                        <td><div class="float-right">{{twoDecimalPlace(item.LineTotal)}}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row f-ss-14px">
        <div class="container-bottom d-flex w-100">
            <div class="col-md-8 col-sm-12"><span></span></div>
            <div class="col-md-4 col-sm-12 p-0 d-block">
                <div class="d-flex w-100">
                    <div class="col-md-8">
                        <label>Subtotal</label>
                    </div>
                    <div class="col-md-4 float-right"><p class="float-right pr-3">{{twoDecimalPlace(ReceiveMoney.SubTotalAmount - (ReceiveMoney.TaxAmount + ReceiveMoney.AdvanceTaxAmount))}}</p></div>
                </div>
                <div class="d-flex w-100 pb-1" *ngIf="ReceiveMoney.TaxId != 3">
                    <div class="col-md-8">
                        <label>Total VAT</label>
                    </div>
                    <div class="col-md-4"> <p class="float-right pr-3">{{twoDecimalPlace(ReceiveMoney.TaxAmount)}}</p></div>
                </div>
                <div class="d-flex w-100 pb-1" *ngIf="ReceiveMoney?.TaxId != 3">
                    <div class="col-md-8">
                        <label>Total AIT</label>
                    </div>
                    <div class="col-md-4"> <p class="float-right pr-3">{{twoDecimalPlace(ReceiveMoney?.AdvanceTaxAmount)}}</p></div>
                </div>
                <div class="d-flex w-100 singleLine">
                    <span></span>
                </div>
                <div class="d-flex w-100 pt-2">
                    <div class="col-md-8">
                        <label class="totalTextValue">Total</label>
                    </div>
                    <div class="col-md-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(ReceiveMoney.SubTotalAmount)}}</p></div>
                </div>
            </div>
        </div>
    </div>
</div>
</app-dashboard>
