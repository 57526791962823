<app-dashboard>
    <app-page-header-title
    mainTitle="Advance"
    subTitle="Back" 
    backURL="/sales/sales-order-details/{{SalesOrderId}}">
    </app-page-header-title>
    <div style="margin-top: 15px;">
        <div class="col-md-12 d-flex f-ss-14px" id="my1">
            <div class="col-md-9"></div>
            <div *ngIf="totalUnInvoice > 0" class="col-md-3">
                <div style="margin-left: 25px;" class="full-width text-align-right">
                    <button type="button" class="eCount-def-btn ml-4" (click)="createNewAdvance()">Add Advance</button>
                </div>
            </div>
        </div>
        <div class="row pt-3 mt-4">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="advanceList"
                [columnDefs]="advGridOptions.advanceColumnDefs"
                [domLayout]="advGridOptions.domLayout"
                [defaultColDef]="advGridOptions.defaultColDef"
                (gridReady)="onAdvanceGridReady($event)"
                (rowClicked)="onRowClicked($event)"
                >
            </ag-grid-angular>
        </div>
    </div>
</app-dashboard>