import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
import { ManufactureOrder } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';

@Component({
  selector: 'app-manufacture-timeline-popup',
  templateUrl: './manufacture-timeline-popup.component.html',
  styleUrls: ['./manufacture-timeline-popup.component.css']
})

export class ManufactureTimelinePopupComponent implements OnInit {

  ManufactureOrderTimeLineForm: FormGroup;
  @Input() manufactureOrder: ManufactureOrder;
  @Input() actionType: number;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private manufactureService: ManufactureService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    if(this.manufactureOrder != null || this.manufactureOrder != undefined)
      this.setParentData();
  }

  initializeForm() {
    this.ManufactureOrderTimeLineForm = this.fb.group({
      UpdateStartDate: ['', Validators.required],
      UpdateEndDate: ['', Validators.required]
    });
    this.ManufactureOrderTimeLineForm.get('UpdateStartDate').patchValue(new Date()); 
    this.showStartSelectedDate(this.ManufactureOrderTimeLineForm.value.UpdateStartDate);
    this.ManufactureOrderTimeLineForm.get('UpdateEndDate').patchValue(new Date());
    this.showEndSelectedDate(this.ManufactureOrderTimeLineForm.value.UpdateEndDate);
  }

  setParentData() {
    this.ManufactureOrderTimeLineForm.controls["UpdateStartDate"].patchValue(this.manufactureOrder.StartDate);
    this.showStartSelectedDate(this.ManufactureOrderTimeLineForm.value.UpdateStartDate);
    this.ManufactureOrderTimeLineForm.controls["UpdateEndDate"].patchValue(this.manufactureOrder.EndDate);
    this.showEndSelectedDate(this.ManufactureOrderTimeLineForm.value.UpdateEndDate);
  }

  showStartSelectedDate(e) {
    const value = this.ManufactureOrderTimeLineForm.value.UpdateStartDate;
    $('#updateStartDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showEndSelectedDate(e) {
    const value = this.ManufactureOrderTimeLineForm.value.UpdateEndDate;
    $('#UpdateEndDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  save() {
    if(!this.ManufactureOrderTimeLineForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    this.manufactureOrder.StartDate = this.ManufactureOrderTimeLineForm.value.UpdateStartDate;
    this.manufactureOrder.EndDate = this.ManufactureOrderTimeLineForm.value.UpdateEndDate;
    if(this.actionType == iQuidiActionType.Approve)
      this.approveManufactureOrder();
    else
      this.updateManufactureOrder();
  }

  approveManufactureOrder() {
    this.spinner.show();
    this.manufactureService.approveManufactureOrder(this.manufactureOrder).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.activeModal.close();
        this.router.navigate(["manufacture/manufacutring-order-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.activeModal.close();
        this.spinner.hide();
      }
    });
  }

  updateManufactureOrder() {
    this.spinner.show();
    this.manufactureService.updateManufactureOrder(this.manufactureOrder).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.activeModal.close();
        this.router.navigate(["manufacture/manufacutring-order-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.activeModal.close();
        this.spinner.hide();
      }
    });
  }

}


