<app-dashboard>
    <app-page-header-title mainTitle="{{itemListTitle}}" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
            <button *ngIf="itemTypeId != 6" type="button" class="eCount-def-btn ml-4" (click)="createNewItem()">Create Item</button>
            <button type="button"  class="eCount-button3 ml-4" (click)="onBtnExport()">{{'Contacts.contactList.export'| translate}}</button>
        </div>
    </app-page-header-title>

  <div class="headSection" *ngIf="gridHideForOverview">
    <div class="row">
      <ag-grid-angular
        style="font-size: 14px"
        class="ag-theme-balham col"
        [rowData]="ProductList"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        (rowClicked)="onRowClicked($event)"
        (gridReady)="onGridReady($event)"
        [defaultColDef]="defaultColDef"
      ></ag-grid-angular>
    </div>
  </div>

  <div class="container">
    <div class="headSection2" *ngIf="!gridHideForOverview">
      <div class="row">
        <div class="card create-card col-md-4">
          <h2 class="card-header head1">
            <a [routerLink]="['/inventory/inventory-overview-receipt/All/']" type="button">Receipts</a>
          </h2>
          <div class="card-body" style="margin-top: 45px;">
            <a class="btn btn-outline-secondary waitBtn borderNoneButton" [routerLink]="['/inventory/inventory-overview-receipt/Waiting/']" type="button">{{ receiptsWaitingCount }} Back Order</a>
            <a class="btn btn-primary processBtn" [routerLink]="['/inventory/inventory-overview-receipt/Ready/']" type="button">{{ receiptsReadyCount }} To Process</a>
          </div>
        </div>
        <div class="card create-card head2 col-md-4 secondCard" [hidden]="userInfo?.CompanyBusinessTypeId == 1">
          <div class="container2">
            <h2 class="card-header"><a [routerLink]="['/inventory/delivery-order/All']" type="button">Delivery Orders</a></h2>
            <button *ngIf="itemTypeId == 6" type="button" class="eCount-button3 ml-4 mt-4" (click)="Refresh()">Refresh</button>
          </div>
          <div class="card-body">
            <div style="margin-bottom: 10px;">
              <a class="btn btn-outline-secondary waitBtn borderNoneButton" [routerLink]="['/inventory/delivery-order/BackOrder']" type="button">{{ deliveryBackOrderCount }} Back Order</a>
            </div>
            <div>
              <a class="btn btn-outline-secondary waitBtn borderNoneButton" [routerLink]="['/inventory/delivery-order/Waiting']" type="button">{{ deliveryWaitingCount }} Waiting </a>
            <a class="btn btn-primary processBtn" [routerLink]="['/inventory/delivery-order/Ready']" type="button">{{ deliveryReadyCount }} To Process</a>
            </div>
          </div>
        </div>
        <div class="card create-card head2 col-md-4 secondCard" [hidden]="userInfo?.CompanyBusinessTypeId == 1 || userInfo?.CompanyBusinessTypeId == 2">
          <div class="container2">
            <h2 class="card-header"><a [routerLink]="['/manufacture/manufacutring-order-list/InventoryAll']" type="button">Manufacturing Orders</a></h2>
            <button type="button" class="eCount-button3 ml-4 mt-4" (click)="ManufacturingOrdersRefresh()">Refresh</button>
          </div>          
          <div class="card-body">
            <div style="margin-bottom: 10px;">
              <a class="btn btn-outline-secondary waitBtn borderNoneButton" [routerLink]="['/manufacture/manufacutring-order-list/BackOrder']" type="button">{{ manufacturingOrderBackOrderCount }} Back Order</a>
            </div>
            <div>
              <a class="btn btn-outline-secondary waitBtn borderNoneButton" [routerLink]="['/manufacture/manufacutring-order-list/InventoryWaiting']" type="button">{{ manufacturingOrderWaitingCount }} Waiting </a>
            <a class="btn btn-primary processBtn" [routerLink]="['/manufacture/manufacutring-order-list/InventoryToProcess']" type="button">{{ manufacturingOrderToProcessCount }} To Process</a>
            </div>
          </div>
        </div>
        <div class="card create-card head2 col-md-4 secondCard" [hidden]="userInfo?.CompanyBusinessTypeId == 1">
          <div class="container2">
            <h2 class="card-header"><a [routerLink]="['/inventory/sales-return/All']" type="button">Sales Return</a></h2>
          </div>          
          <div class="card-body">
            <div>
              <a class="btn btn-outline-secondary waitBtn borderNoneButton" [routerLink]="['/inventory/sales-return/Waiting']" type="button">{{ returnWaitingCount }} Waiting </a>
              <a class="btn btn-primary processBtn" [routerLink]="['/inventory/sales-return/Ready']" type="button">{{ returnReadyCount }} To Process</a>
            </div>
          </div>
        </div>
        <div class="card create-card head2 col-md-4 secondCard">
          <div class="container2">
            <h2 class="card-header"><a [routerLink]="['/inventory/purchase-return/All']" type="button">Purchase Return</a></h2>
          </div>          
          <div class="card-body">
            <div>
              <a class="btn btn-outline-secondary waitBtn borderNoneButton" [routerLink]="['/inventory/purchase-return/Waiting']" type="button">{{ purchaseReturnWaitingCount }} Waiting </a>
              <a class="btn btn-primary processBtn" [routerLink]="['/inventory/purchase-return/Ready']" type="button">{{ purchaseReturnReadyCount }} To Process</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-dashboard>
