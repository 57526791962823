import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AssetItemModel } from '../../../models/fixedAsset.model';
import { AssetItemCreateComponent } from '../../fixed-asset/asset-item-create/asset-item-create.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-fixed-asset-list',
  templateUrl: './fixed-asset-list.component.html',
  styleUrls: ['./fixed-asset-list.component.css']
})
export class FixedAssetListComponent implements OnInit {

  public aModel : AssetItemModel= new AssetItemModel();
  public assetList: any = [];
  public totalDraft:number =0;
  public totalRegistered:number =0;
  public totalSold:number =0;
  private draftStatusId: number =1;
  status: string;
  constructor(
    private pService: FixedAssetService,
    private dataService: DataService,
    private activeRouter: ActivatedRoute,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.dataService.fixedAssetItemUpdate.subscribe(x => {
      this.getAssetList(1);
    });
    this.status = this.activeRouter.snapshot.paramMap.get("status");
    console.log(this.status);
    this.reLoadRowData(this.status);
  }

  private getAssetList = (Id) => {
    this.spinner.show();
    this.pService.GetFixedAssetItemList(Id).subscribe((x) => {
      this.assetList = x.Data;
      this.assetList.forEach(element => {
        element.PurchaseDate = this.convertDateIntoDisplay(element.PurchaseDate);
      });
      this.totalDraft=x.TotalDraft;
      this.totalRegistered=x.TotalRegistered;
      this.totalSold=x.TotalSold;
      this.spinner.hide()
    },(err)=>{
      this.spinner.hide();
    })
  };

  convertDateIntoDisplay(date) {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  public EditAssetItem=(item: any)=>{
    this.spinner.show();
    this.pService.GetAssetItemDetails(item.Id).subscribe((x) => {
      const modalRef = this.modalService.open(AssetItemCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = x;
      this.spinner.hide();
      modalRef.result.then((result) => {
      }, (reason) => {
        console.log('Dismissed action: ' + reason);
      });
    },(err)=>{
      this.spinner.hide();
    })
  };
  reLoadRowData = (status: string) => {
    console.log(status);
    switch (status)
    {
      case 'draft':
        this.getAssetList(1);
        break;
      case 'registered':
        this.getAssetList(2);
        break;
      case 'solddisposed':
        this.getAssetList(3);
        break;
    }
  }
}
