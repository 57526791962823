import { Injectable } from '@angular/core';
import {ConfirmationDialogComponentComponent} from '../../components/confirmation-dialog-component/confirmation-dialog-component.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Injectable()
export class AlertService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    billNo: string,
    Type: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm'|'md' = 'md'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponentComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.billNo = billNo;
    modalRef.componentInstance.Type = Type;
    return modalRef.result;
  }

}
