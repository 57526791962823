import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ManufactureFormulaStatus } from 'src/app/enum/manufacture-formula-status';
import { ManufactureFormulaDetailsPageViewModel } from 'src/app/models-viewModels/manufactureFormulaDetailsPageViewModel';
import { Item } from 'src/app/models/inventoryOverview.model';
import { ManufactureFormula, ManufactureFormulaDetails } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';

@Component({
  selector: 'app-manufacture-formula-details',
  templateUrl: './manufacture-formula-details.component.html',
  styleUrls: ['./manufacture-formula-details.component.css']
})
export class ManufactureFormulaDetailsComponent implements OnInit {

  formulaId: number;
  manufactureFormulaDetailsData: ManufactureFormulaDetailsPageViewModel;

  ProductFormulaForm: FormGroup;
  FormulaItems: FormArray;
  finishedProductList: Item[];
  rawMaterialList: Item[];

  constructor(
    private route: ActivatedRoute,
    private manufactureService: ManufactureService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.formulaId = +params['id'];
      this.getFormula();
   });
   this.initializeForm();
  }

  initializeForm() {
    this.ProductFormulaForm = this.fb.group({
      Id: [0],
      FinishedProductId: [''],
      FormulaId:[''],
      Quantity: [''],
      CreatedDate: [''],
      Reference: [''],
      FormulaItems: this.fb.array([])
    });
  }

  getFormula(){
    this.manufactureService.getFormula(this.formulaId).subscribe(x => {
      this.manufactureFormulaDetailsData = x;
      this.setParentData(this.manufactureFormulaDetailsData.ManufactureFormula);
      x.ManufactureFormula.ManufactureFormulaDetails.forEach((item, i) => {
        this.addItem(item, i);
      });
    });
  }

  setParentData(formulaData) {
    let createdDate = moment(formulaData.CreateDate).format("Do MMM YYYY");
    this.ProductFormulaForm.controls['Id'].patchValue(formulaData.Id);
    this.ProductFormulaForm.controls['FinishedProductId'].patchValue(formulaData.Item.ItemName);
    this.ProductFormulaForm.controls['FormulaId'].patchValue(formulaData.FormulaName);
    this.ProductFormulaForm.controls['Quantity'].patchValue(formulaData.Quantity);
    this.ProductFormulaForm.controls['CreatedDate'].patchValue(createdDate);
    this.ProductFormulaForm.controls['Reference'].patchValue(formulaData.Reference);
  }

  addItem(item: ManufactureFormulaDetails, i: number) {
    this.FormulaItems = this.ProductFormulaForm.get('FormulaItems') as FormArray;
    this.FormulaItems.push(this.createItem(item, i));
  }

  createItem(item: ManufactureFormulaDetails, i: number): FormGroup {
    return this.fb.group({
      RawMaterialId: [item.Item.ItemName],
      ItemQuantity: [item.Quantity]
    });
  }

  saveManufactureFormula() {
    if(!this.ProductFormulaForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    this.spinner.show();
    let data: ManufactureFormula = this.formatData();
    this.manufactureService.saveManufactureFormula(data).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manufacture/finished-product-material-formula-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  formatData(): ManufactureFormula {
    let manufactureFormulaDetails: ManufactureFormulaDetails[] = [];
    this.ProductFormulaForm.value.FormulaItems.forEach(element => {
      let item = this.rawMaterialList.filter(x => x.Id === element.RawMaterialId)
      let formulaDetails = new ManufactureFormulaDetails();
      formulaDetails.Id = 0;
      formulaDetails.ItemId = element.RawMaterialId;
      formulaDetails.Quantity = element.ItemQuantity;
      formulaDetails.ManufactureFormulaId = 0;
      manufactureFormulaDetails.push(formulaDetails);
    });

    let finishedProduct = this.finishedProductList.filter(x => x.Id === this.ProductFormulaForm.value.FinishedProductId);
    let manufactureFormula: ManufactureFormula = {
      Id: 0,
      FormulaName: finishedProduct[0].ItemName,
      FinishedProductId: this.ProductFormulaForm.value.FinishedProductId,
      Quantity: this.ProductFormulaForm.value.Quantity,
      CreateDate: this.ProductFormulaForm.value.CreatedDate,
      Reference: this.ProductFormulaForm.value.Reference,
      Status: 0,
      CompanyId: 0,
      ManufactureFormulaDetails: manufactureFormulaDetails
    };
    return manufactureFormula;
  }

  editManufactureFormula(){
    this.router.navigate(["manufacture/edit-manufacture-formula", this.manufactureFormulaDetailsData.ManufactureFormula.Id]);
  }

  approveManufactureFormula() {
    this.spinner.show();
    this.manufactureService.approveManufactureFormula(this.manufactureFormulaDetailsData.ManufactureFormula).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manufacture/finished-product-material-formula-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  deleteManufactureFormula(){
    this.spinner.show();
    this.manufactureService.deleteManufactureFormula(this.manufactureFormulaDetailsData.ManufactureFormula).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["manufacture/finished-product-material-formula-list/All"]);
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  canEditManufactureFormula() {
    let canEdit = this.manufactureFormulaDetailsData?.ManufactureFormula?.Status == ManufactureFormulaStatus.Draft;
    return canEdit;
  }

  canApproveManufactureFormula() {
    let canApprove = this.manufactureFormulaDetailsData?.ManufactureFormula?.Status == ManufactureFormulaStatus.Draft;
    return canApprove;
  }

  canDeleteManufactureFormula() {
    let canDelete = this.manufactureFormulaDetailsData?.ManufactureFormula?.Status == ManufactureFormulaStatus.Draft;
    return canDelete;
  }

  setMainTitle() {
    let status = this.formatStatus();
    let mainTitle = this.manufactureFormulaDetailsData?.ManufactureFormula?.FormulaName+' / '+status;
    return mainTitle;
  }

  formatStatus() {
    if(this.manufactureFormulaDetailsData?.ManufactureFormula?.Status == ManufactureFormulaStatus.Draft) {
      return 'Draft';
    }
    else if(this.manufactureFormulaDetailsData?.ManufactureFormula?.Status == ManufactureFormulaStatus.Approved) {
      return 'Approved';
    }
  }

}
