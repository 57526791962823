import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { FixedAssetSetting } from 'src/app/models/fixedAsset.model';
@Component({
  selector: 'app-change-start-date',
  templateUrl: './change-start-date.component.html',
  styleUrls: ['./change-start-date.component.css']
})
export class ChangeStartDateComponent implements OnInit {

  public changeStartDateForm: FormGroup;
  date: any;
  constructor(
    public fb: FormBuilder, 
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private pService: FixedAssetService) { }
  ngOnInit(): void {

    let currentDate = new Date();
    currentDate.setUTCMonth(0);
    currentDate.setUTCDate(1);
    currentDate.setUTCHours(0);
    currentDate.setUTCMinutes(0);
    currentDate.setUTCSeconds(0);

    this.initializeForm();
    this.pService.GetStartDate().subscribe((x) => {
      this.date = x != null ? x.StartDate : currentDate;
      console.log(x);
      this.initializeForm();
    },(err)=>{});
    
  }

  initializeForm() {
    this.changeStartDateForm = this.fb.group({
      Id:[0],
      StartDate: [this.date, Validators.required]
    });
    this.showStartDate(this.date);
  }

  /**
   * saveChangeStartDate
   */
  public saveChangeStartDate() {
    console.log(this.changeStartDateForm.value.StartDate);
    if (this.changeStartDateForm.invalid){
      this.toaster.warning('Please set start date!');
      return;
    }
    let data: FixedAssetSetting = {
      Id: 0,
      StartDate: this.changeStartDateForm.value.StartDate,
      CompanyId: 0,
      LastUpdatedAt: new Date()
    }
    this.spinner.show();
    this.pService.SaveFixedAssetStartDate(data).subscribe((x)=>{
      if(x.Success){
        this.spinner.hide();
        this.toaster.success("Start date updated successfully.");
        this.activeModal.close(x);
        console.log(x.Data);
      }
      else{
        this.toaster.error(x.Message);
      }
    }, err=>{
        this.toaster.error('ooh, something went wrong !');
    });
  }
  showStartDate(e) {
    const value = this.changeStartDateForm.value.StartDate;
    console.log(value);
    $("#cstest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

}
