import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AccountTypes } from 'src/app/models/accounting.model';
import { DocumentOTPViewModel } from 'src/app/models-viewModels/documentOTPViewModel';

@Injectable({
  providedIn: 'root'
})
export class DocumentServiceService {
  baseurl = environment.baseUrl;

  RejectDocumentModalData: any;

  modalInfoData: any;

  seeFullHistory: boolean = false;

  constructor(private http: HttpClient) { }

  public saveRejectDocumentModalData(data: any){
    this.RejectDocumentModalData = data;
    console.log(data);
  }

  public getRejectDocumentModalData(): Observable<any>{
    return this.RejectDocumentModalData;
  }

  public saveModalInfoData(data:any){
    this.modalInfoData = data;
    console.log(data);
  }

  public getModalInfoData(){
    return this.modalInfoData;
  }

  // documnet history and status update
  public documentDecision(data:any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/DocumentDecision', data)
      .pipe(
        retry(1)
      );
  }

  // get document history
  public getDocumentHistory(data:any){
    return this.http.get<any>(this.baseurl + 'Settings/DocumentHistory?tagId='+data)
      .pipe(
        retry(1)
      );
  }

  public getDocumentHistoryAll(data:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetDocumentHistoryAll?tagId='+data)
      .pipe(
        retry(1)
      );
  }

  public setSeeFullHistory(data: boolean){
    this.seeFullHistory = data;
  }

  // GET EXAMPLE (JUST EDIT)
  // public GetAllProductSaleAble(): Observable<any> {
  //   return this.http.get<any>(this.baseurl + 'item/getsaleableitems')
  //     .pipe(
  //       retry(1)
  //     )
  // }

  public GenerateApprovalHistoryPdfDocument(data: any): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/pdf', // Set the expected response type
      }),
      responseType: 'arraybuffer' as 'json', // Specify the expected response type
    };
    return this.http.post<any>(this.baseurl + 'Settings/GenerateApprovalHistoryPdfDocument', data, requestOptions);
  }

  public generateEmailOTP_ForDocumentApproval(data: DocumentOTPViewModel): Observable<any>{
    return this.http.post<any>(this.baseurl + 'Settings/GenerateEmailOTP', data);
  }

  public verifyOTP_ForDocumentApproval(data: DocumentOTPViewModel): Observable<any>{
    return this.http.post<any>(this.baseurl + 'Settings/VerifyOTP', data);
  }

}
