import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup,FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';

@Component({
  selector: 'app-view-sales-order',
  templateUrl: './view-sales-order.component.html',
  styleUrls: ['./view-sales-order.component.css']
})
export class ViewSalesOrderComponent implements OnInit {

  @Input() fromParent: any;
  public salesForm: FormGroup;
  public SalesOrderItem: FormArray;

  constructor(
    public fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService, 
    private salesService: SalesOrderService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm() {
    this.salesForm = this.fb.group({
      SalesOrderItem: this.fb.array([])
    });
    this.getSalesOrderByItemId(this.fromParent.ItemId);
  }
  getSalesOrderByItemId = (id:any) => {
    this.spinner.show();
    this.salesService.GetSalesOrderByItemId(id).subscribe((x) => {
      if (x) {
          x.forEach(item => {
          this.addItem(item);
        });
        this.spinner.hide();
      }
    })
  }
  public addItem(x:any): void {
    this.SalesOrderItem = this.salesForm.get('SalesOrderItem') as FormArray;
    this.SalesOrderItem.push(this.createItem(x));
  }
  public createItem(x:any): FormGroup {
    return this.fb.group({
      Id:[x.Id],
      OrderNo:[x.OrderNo],
      OrderQty:[x.OrderQty],
      OrderAmount:[x.OrderAmount],
      DeliveryDate:[moment(x.DeliveryDate).format("MM/DD/YYYY")]
    });
  }
}
