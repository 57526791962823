
<ng-container *ngIf="!seeFullHistory" >
  <div class="modal-lg bg_gray">
    <div class="row ml-2">
      <div class="col-md-10 mt-2">
        <h4>Document Details</h4>
      </div>
      <div class="col-md-1 mt-2 text-align-right">
        <a [href]="downloadLink">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-download download-Button" viewBox="0 0 20 20" style="cursor: pointer;">
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
          </svg>
        </a>
      </div>
      <div class="col-md-1 mt-2">
        <button type="button" class="btn-close border-0" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true" class="visually-hidden border-0 modalCloseCross">&times;</span>
        </button>
      </div>
    </div>

    <div class="modal-body">
  
      <table class="table table-borderless br_black bg_white">
          <!-- <thead>
            <tr>
              <th scope="col bold"><b>To</b></th>
              <th scope="col bold"><b>Date</b></th>
              <th scope="col bold"><b>Bill Details</b></th>
            </tr>
          </thead> -->
          <tbody>
            <tr>
              <td>
                  <p>Document Name:  {{modalInfoData.DocumentName}}</p>
                  <p>Tag: {{modalInfoData.DocumentType}}</p>
                  <!-- <p>Email: Sania74@gmail.com</p>
                  <p>Address: Dhaka</p> -->
              </td>
  
              <td>
                  <p>Created Date: {{modalInfoData.UploadedAt | date: 'MMM-d-y'}}</p>
                  <!-- <p>Bill Date: 22nd Aug 2022</p>
                  <p>Due Date: 25th Aug 2022</p> -->
                  <p>Uploaded By: {{modalInfoData.UploadedBy}}</p>
                  <!-- <p>Uploaded At: 25th Aug 2022</p> -->
              </td>
  
              <td>
                  <!-- <p>Bill No # 20220515</p>
                  <p>Reference No #</p> -->
              </td>
            </tr>
          </tbody>
        </table>
  
      <label class="mt-1" >Approval History</label>
      <div *ngFor="let item of modalHistory; let i = index" class="bg_white br_black">
          <label class="bold mt-1" >Level {{ i + 1 }}</label>
      <table class="table table-borderless  table-responsive" [formGroup]="approveLevelForm" >
          <thead>
            <tr>
              <th scope="col">Approver Role</th>
              <th scope="col">Email</th>
              <th scope="col" style="visibility: hidden;" >....................................................</th> 
              <th scope="col" *ngIf="modalInfoData.ApprovalStatus!=4 || (i+1!=modalHistory.length)">Approved By</th>
              <th scope="col" *ngIf="modalInfoData.ApprovalStatus!=4 || (i+1!=modalHistory.length)">Approved At</th>
              <th scope="col" *ngIf="modalInfoData.ApprovalStatus==4 && (i+1==modalHistory.length)">Rejected By</th>
              <th scope="col" *ngIf="modalInfoData.ApprovalStatus==4 && (i+1==modalHistory.length)">Rejected At</th>
              <th scope="col" style="visibility: hidden;" >.</th> 
              <th scope="col">Comments</th>
              <th scope="col" style="visibility: hidden;" >....................</th> 
              <th scope="col" style="text-align: center" >Signature</th>
              <th scope="col" style="text-align: center" >Approval Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                  <input [value]="item.ApprovalRole==1?'Admin':item.ApprovalRole==2?'Accountant':'Auditor'" class="form-control" type="text"  readonly />
              </td>
              <td colspan="2" > 
                  <input [value]="item.ApprovalEmail" class="form-control" type="email"  readonly />
              </td>
              <td>
                  <input [value]="item.ApprovedBy" class="form-control" type="text"  readonly />
              </td>
              <td colspan=2 >
                  <input [value]="item.ApprovedBy != '' ? (item.ApprovedAt | date: 'MMM-d-y') : ''" class="form-control"  readonly />
              </td>
              <td class="textarea-td" colspan="2">
                  <textarea  class="form-control form-control-textarea" rows="3" type="text"  readonly>{{item.Comments}}</textarea>
              </td>
              <td style="text-align: right">
                  <img alt=""
                  style="outline-style: dashed; outline-color:#E9ECEF"
                  src= {{item.User.Signature}}
                      class="signature_img bg_white"
                      />
              </td>
              <td class="img-center"  >
                <fa-icon 
                  *ngIf="item.ApprovedBy != '' && (modalInfoData.ApprovalStatus!=4 || (i+1!=modalHistory.length))"
                  [icon]="faCheckCircle" class="approvalStatusIcon green" >
                </fa-icon>
                <fa-icon 
                  *ngIf="modalInfoData.ApprovalStatus==4 && (i+1==modalHistory.length)"
                  [icon]="faTimesCircle" class="approvalStatusIcon red">
                </fa-icon>
                <fa-icon 
                  *ngIf="item.ApprovedBy == '' && (modalInfoData.ApprovalStatus!=4 || (i+1!=modalHistory.length))"
                  [icon]="faExclamationCircle" class="approvalStatusIcon yellow">
                </fa-icon>
              </td>
            </tr>
          </tbody>
  
        </table>
        <label *ngIf="index==modalHistory.length-1 && modalInfoData.openFrom!='overview'" class="bold" >Level {{ i + 2 }}</label>
        <table *ngIf="index==modalHistory.length-1 && modalInfoData.openFrom!='overview'" class="table table-borderless" [formGroup]="approveLevelForm" >
          <thead>
            <tr>
              <th scope="col">Approvers</th>
              <th scope="col">Email</th>
              <th scope="col" style="visibility: hidden;" >....................................................</th> 
              <th scope="col" *ngIf="modalInfoData.ApprovalStatus!=4 || (i+1!=modalHistory.length)">Approved By</th>
              <th scope="col" *ngIf="modalInfoData.ApprovalStatus!=4 || (i+1!=modalHistory.length)">Approved At</th>
              <th scope="col" *ngIf="modalInfoData.ApprovalStatus==4 && (i+1==modalHistory.length)">Rejected By</th>
              <th scope="col" *ngIf="modalInfoData.ApprovalStatus==4 && (i+1==modalHistory.length)">Rejected At</th>
              <th scope="col" style="visibility: hidden;" >.</th> 
              <th scope="col">Comments</th>
              <th scope="col" style="visibility: hidden;" >....................</th> 
              <th scope="col" style="text-align: center" >Signature</th>
              <th scope="col" style="text-align: center" >Approval Status</th>
            </tr>
          </thead>
  
          <tbody *ngIf="index==modalHistory.length-1">
            <tr>
              <td>
                  <input class="form-control" type="text" value={{currentUserRoleName}} readonly />
              </td>
              <td colspan="2" >
                  <input class="form-control" type="email" value={{currentUserEmail}} readonly />
              </td>
              <td>
                  <input class="form-control" type="text" value="" readonly />
              </td>
              <td colspan="2" >
                  <input class="form-control" type="text" readonly />
              </td>
              <td class="textarea-td" colspan="2" >
                <textarea  class="form-control form-control-textarea" rows="3" type="text"  readonly></textarea>
              </td>
              <td style="text-align: right">
                  <img  style="outline-style: dashed; outline-color:#E9ECEF" 
                  class="signature_img bg_white" />
              </td>
              <td class="img-center" >
                <fa-icon 
                  [icon]="faExclamationCircle" class="approvalStatusIcon yellow">
                </fa-icon>              
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
  
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="activeModal.close('Close click')">Close</button>
    </div> -->
  </div>
  
  
  <!-- <button class="btn btn-outline-primary mb-2 me-2" (click)="openXl(content)">Extra large modal</button> -->
  
</ng-container>






<ng-container *ngIf="seeFullHistory" >
  <div class="modal-lg bg_gray">
    <div class="row ml-2">
      <div class="col-md-11 mt-2">
        <h4>Document Details</h4>
      </div>
      <!-- <div class="col-md-1 mt-2 text-align-right">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-download download-Button" viewBox="0 0 20 20" style="cursor: pointer;" (click)="downloadPdf()">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
        </svg>
      </div> -->
      <div class="col-md-1 mt-2">
        <button type="button" class="btn-close border-0" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true" class="visually-hidden border-0 modalCloseCross">&times;</span>
        </button>
      </div>
    </div>
  
    <div class="modal-body">
  
      <table class="table table-borderless br_black bg_white">
          <!-- <thead>
            <tr>
              <th scope="col bold"><b>To</b></th>
              <th scope="col bold"><b>Date</b></th>
              <th scope="col bold"><b>Bill Details</b></th>
            </tr>
          </thead> -->
          <tbody>
            <tr>
              <td>
                  <p>Document Name:  {{modalInfoData.DocumentName}}</p>
                  <p>Tag: {{modalInfoData.DocumentType}}</p>
                  <!-- <p>Email: Sania74@gmail.com</p>
                  <p>Address: Dhaka</p> -->
              </td>
  
              <td>
                  <p>Created Date: {{modalInfoData.UploadedAt | date: 'MMM-d-y'}}</p>
                  <!-- <p>Bill Date: 22nd Aug 2022</p>
                  <p>Due Date: 25th Aug 2022</p> -->
                  <p>Uploaded By: {{modalInfoData.UploadedBy}}</p>
                  <!-- <p>Uploaded At: 25th Aug 2022</p> -->
              </td>
  
              <td>
                  <!-- <p>Bill No # 20220515</p>
                  <p>Reference No #</p> -->
              </td>
            </tr>
          </tbody>
        </table>
  
      <label class="mt-1" >Approval History</label>
      <div *ngFor="let item of modalHistory; let i = index" class="bg_white pd-1" 
            [ngClass]="{'br_black_no_top': !isNotSameLevel(item, i-1) || isLastItem(i), 'br_black_no_bottom': isNotSameLevel(item, i-1)}"
          >
          <label class="bold" *ngIf="isNotSameLevel(item, i-1)" >Level {{item.LevelId}}</label>
      <table class="table table-borderless table-responsive" [formGroup]="approveLevelForm" 
        [ngClass]="{ hidden_table_header: !isNotSameLevel(item, i-1) }"
      >
          <thead *ngIf="isNotSameLevel(item, i-1)" >
            <tr>
              <th scope="col">Approver Role</th>
              <th scope="col">Email</th>
              <th scope="col" style="visibility: hidden;" >..................................................</th> 
              <th scope="col" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved By</th>
              <th scope="col" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved At</th>
              <th scope="col" *ngIf="isRejected(item, i)">Rejected By</th>
              <th scope="col" *ngIf="isRejected(item, i)">Rejected At</th>
              <th scope="col" style="visibility: hidden;" >..</th> 
              <th scope="col">Comments</th>
              <th scope="col" style="visibility: hidden;" >....................</th> 
              <th scope="col" style="text-align: center" >Signature</th>
              <th scope="col" style="text-align: center" >Approval Status</th>
            </tr>
          </thead>
          <thead *ngIf="!isNotSameLevel(item, i-1)" style="visibility: hidden;" >
            <tr>
              <th scope="col">Approver Role</th>
              <th scope="col">Email</th>
              <th scope="col" style="visibility: hidden;" >..................................................</th> 
              <th scope="col" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved By</th>
              <th scope="col" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved At</th>
              <th scope="col" *ngIf="isRejected(item, i)">Rejected By</th>
              <th scope="col" *ngIf="isRejected(item, i)">Rejected At</th>
              <th scope="col" style="visibility: hidden;" >..</th> 
              <th scope="col">Comments</th>
              <th scope="col" style="visibility: hidden;" >....................</th> 
              <th scope="col" style="text-align: center" >Signature</th>
              <th scope="col" style="text-align: center" >Approval Status</th>
            </tr>
          </thead>
          <!-- <thead *ngIf="!isNotSameLevel(item, i-1)" >
            <tr>
              <th scope="col" style="visibility: hidden;">Approver Role</th>
              <th scope="col" style="visibility: hidden;">Email</th>
              <th scope="col" style="visibility: hidden;" >..................................................</th> 
              <th scope="col" style="visibility: hidden;" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved By</th>
              <th scope="col" style="visibility: hidden;" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved At</th>
              <th scope="col" style="visibility: hidden;" *ngIf="isRejected(item, i)">Rejected By</th>
              <th scope="col" style="visibility: hidden;" *ngIf="isRejected(item, i)">Rejected At</th>
              <th scope="col" style="visibility: hidden;" >.</th> 
              <th scope="col" style="visibility: hidden;" >Comments</th>
              <th scope="col" style="visibility: hidden;" >....................</th> 
              <th scope="col" style="text-align: center; visibility: hidden;" >Signature</th>
              <th scope="col" style="text-align: center; visibility: hidden;" >Approval Status</th>
            </tr>
          </thead> -->
          <tbody>
            <tr>
              <td>
                  <input [value]="item.RoleId==1?'Admin':item.RoleId==2?'Accountant':'Auditor'" class="form-control" type="text"  readonly />
              </td>
              <td colspan="2" > 
                  <input [value]="item.Email" class="form-control" type="email"  readonly />
              </td>
              <td>
                  <input [value]="item.ApprovedBy" class="form-control" type="text"  readonly />
              </td>
              <td colspan="2">
                  <input [value]="item.ApprovedAt | date: 'MMM-d-y'" class="form-control"  readonly />
              </td>
              <td class="textarea-td" colspan="2">
                  <textarea  class="form-control form-control-textarea" rows="3" type="text"  readonly>{{item.Comments}}</textarea>
              </td>
              <td style="text-align: right">
                  <img *ngIf="item.Signature  && !isWaiting(item, i)"
                    alt=""
                    style="outline-style: dashed; outline-color:#E9ECEF"
                    src= {{item.Signature}}
                        class="signature_img bg_white"
                      />
                  <img *ngIf="!item.Signature || isWaiting(item, i)"
                    style="outline-style: dashed; outline-color:#E9ECEF; background-color: white;"
                      class="signature_img bg_white"
                    />
              </td>
              <td class="img-center"  >
                <fa-icon 
                  *ngIf="isApproved(item, i)"
                  [icon]="faCheckCircle" class="approvalStatusIcon green" >
                </fa-icon>
                <fa-icon 
                  *ngIf="isRejected(item, i)"
                  [icon]="faTimesCircle" class="approvalStatusIcon red">
                </fa-icon>
                <fa-icon 
                  *ngIf="isWaiting(item, i)"
                  [icon]="faExclamationCircle" class="approvalStatusIcon yellow">
                </fa-icon>
              </td>
            </tr>
          </tbody>
  
        </table>
      </div>
  
  
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="activeModal.close('Close click')">Close</button>
    </div> -->
  </div>
  
  
  <!-- <button class="btn btn-outline-primary mb-2 me-2" (click)="openXl(content)">Extra large modal</button> -->
</ng-container>







