<app-dashboard>
    <app-page-header-title
      mainTitle="Sales Order"
      subTitle="Home"
      backURL="/">
      <div class="full-width text-align-right">
        <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
        <button type="button" routerLink="/sales/sales-order-create" class="eCount-def-btn ml-4">Create New</button>
        <button type="button"  class="eCount-button3 ml-4" (click)="onBtnExport()">Export</button>
      </div>
      
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center ">
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('All')" [routerLinkActive]="['active']" routerLink="/sales/sales-order-list/All">All</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData('Draft')" [routerLinkActive]="['active']" routerLink="/sales/sales-order-list/Draft">Draft ({{draftCount}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData('OnGoing')" [routerLinkActive]="['active']" routerLink="/sales/sales-order-list/OnGoing">On Going ({{onGoingCount}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('OnHold')" [routerLinkActive]="['active']" routerLink="/sales/sales-order-list/OnHold">On Hold ({{onHoldCount}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('Closed')" [routerLinkActive]="['active']" routerLink="/sales/sales-order-list/Closed">Closed ({{closedCount}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('SOCompleted')" [routerLinkActive]="['active']" routerLink="/sales/sales-order-list/SOCompleted">Completed ({{completedCount}})</a>
      </li>
    </ul>
    <div class="row pt-3">
        <ag-grid-angular
            style="font-size: 14px;"
            class="ag-theme-balham col"
            [rowData]="filteredLists"
            [columnDefs]="columnDefs"
            [domLayout]="domLayout"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
    </div>
    
    </app-dashboard>
    
    