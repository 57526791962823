import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FixedAssetDisposalViewModel } from 'src/app/models-viewModels/fixedAssetDisposalViewModel.model';
import { Contacts } from 'src/app/models/contacts.model';
import { FixedAssetDisposalAccountMapping } from 'src/app/models/fixedAssetDisposalAccountMapping.model';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { FixedAssetDisposalService } from 'src/app/service/FixedAsset/fixed-asset-disposal.service';

@Component({
  selector: 'app-review-disposal-journals',
  templateUrl: './review-disposal-journals.component.html',
  styleUrls: ['./review-disposal-journals.component.css']
})
export class ReviewDisposalJournalsComponent implements OnInit {

  @Input() fromParent;
  @Input() DepreciationType;

  disposedDate: string;
  allChartOfAccount: any = [];

  CapitalGainAccount: any = [];
  GainAccount: any = [];
  LossAccount: any = [];
  saleProceedsAccount: any = [];
  assetAcccount: any = [];
  depreciationAcccount: any = [];

  DisposalAccounts: FixedAssetDisposalAccountMapping[];

  totalDebit: number = 0;
  totalCredit: number = 0;

  CapitalGainAccountName: string;
  GainAccountName: string;
  LossAccountName: string;

  constructor(
    public activeModal: NgbActiveModal,
    private accountingService: AcountingServiceService,
    public fixedAssetDisposalService: FixedAssetDisposalService,
    private toaster: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.disposedDate = moment(this.fromParent?.ActionDate).format("Do MMM YYYY");
    this.GetDisposalAccountMappings();
    this.getAllChartOfAccount();
    this.CalculateDisposalJournal();
  }

  CalculateDisposalJournal() {
    this.totalCredit = this.fromParent?.FixedAssetItem?.PurchasePrice + this.fromParent?.GainAmount + this.fromParent?.CapitalGainAmount;
    if(this.DepreciationType === "No Depreciation") {
      this.totalDebit = this.fromParent?.SoldPrice + this.fromParent?.LossAmount;
    }
    else{
      this.totalDebit = this.fromParent?.AccumulatedDepreciation + this.fromParent?.SoldPrice + this.fromParent?.LossAmount;
    }
  }

  getAllChartOfAccount() {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
        this.allChartOfAccount = x.RevenueList;
        this.depreciationAcccount = this.allChartOfAccount.filter((x) => x.Id == this.fromParent?.DepreciationAccountId)[0];
        this.assetAcccount = this.allChartOfAccount.filter((x) => x.Id == this.fromParent?.AssetAccountId)[0];
        this.saleProceedsAccount = this.allChartOfAccount.filter((x) => x.Id == this.fromParent?.SaleProceedsAccountId)[0];
    });
  }

  GetDisposalAccountMappings() {
    this.fixedAssetDisposalService.GetDisposalAccountMappings().subscribe((x) => {
      this.DisposalAccounts = x;
      this.CapitalGainAccount = this.DisposalAccounts[0];
      this.GainAccount = this.DisposalAccounts[1];
      this.LossAccount = this.DisposalAccounts[2];
      this.CapitalGainAccountName = this.CapitalGainAccount.AccountName;
      this.GainAccountName = this.GainAccount.AccountName;
      this.LossAccountName = this.LossAccount.AccountName;
    });
  }

  CreateInvoice() {
    this.spinner.show();
    let data = this.getFormData();
    this.fixedAssetDisposalService.Dispose(data).subscribe((x) => {
      if(x.Success){
        this.toaster.success(x.Message);
        this.router.navigate(["/fixed-asset/setup/solddisposed"]);
        this.activeModal.close();
        this.spinner.hide();
        location.reload();
      }
      else{
        this.toaster.error(x.Message);
      }
    });
  }

  getFormData() {
    let fixedAssetData: FixedAssetDisposalViewModel = {
      ActionDate: this.fromParent.ActionDate,
      SoldPrice:this.fromParent.SoldPrice,
      Customer: this.fromParent.Customer,
      FixedAssetItem: this.fromParent.FixedAssetItem,
      AssetAccountId: this.fromParent.AssetAccountId,
      DepreciationAccountId: this.fromParent.DepreciationAccountId,
      SaleProceedsAccountId: this.fromParent.SaleProceedsAccountId,
      CapitalGainAccountId: this.CapitalGainAccount.AccountId,
      GainAccountId: this.GainAccount.AccountId,
      LossAccountId: this.LossAccount.AccountId,
      CapitalGainAmount: this.fromParent.CapitalGainAmount,
      GainAmount: this.fromParent.GainAmount,
      LossAmount: this.fromParent.LossAmount,
      Invoice: null,
      ReversalOfDepreciation: this.fromParent.ReversalOfDepreciation,
      PostedOfDepreciation: this.fromParent.PostedOfDepreciation,
      AccumulatedDepreciation: this.fromParent.AccumulatedDepreciation,
      SelectedDepreciationDate: this.fromParent.SelectedDepreciationDate,
      CaseNo: 0

    }
    return fixedAssetData;
  }

}
