<app-dashboard>
    <div>
        <app-page-header-title
            mainTitle="Bill"
            subTitle="Back" 
            backURL="/procurement/purchase-order-details/{{PurchaseOrderId}}">
        </app-page-header-title>
    </div>
    <div>
        <div class="col-md-12 d-flex f-ss-14px" id="my1">
            <div class="col-md-2 d-block pl-1">
                <b style="font-weight: bold;">{{billDetailsOfPurchaseOrder?.unbilled | number}} </b>
                    <p>Unbilled</p>
            </div>
            <div class="col-md-7 pl-0 ml-0">
                <div class="col-md-4">
                    <b style="font-weight: bold;">{{billDetailsOfPurchaseOrder?.billed | number}}</b>
                    <p>Billed</p>
                </div>
            </div>
            <div class="col-md-3" *ngIf="billDetailsOfPurchaseOrder?.unbilled != 0">
                <div style="margin-left: 25px;" class="full-width text-align-right">
                    <button type="button" (click)="createNewBillPopup()" class="eCount-def-btn ml-4" style="background: #5cac00;">Create Bill</button>
                </div>
            </div>
        </div>
          <div class="row pt-3 agwd">
            <ag-grid-angular
              style="font-size: 14px"
              class="ag-theme-balham col"
              [rowData]="filteredBills"
              [columnDefs]="columnDefs"
              [domLayout]="domLayout"
              [defaultColDef]="defaultColDef"
              [pagination]="true"
              [paginationPageSize]="50"
              (gridReady)="onGridReady($event)"
            >
            </ag-grid-angular>
          </div>
          <div class="row d-flex grid ml-0 mr-0 mt-0">
            <div class="col-sm-6">Total</div>
            <div class="col-sm-1 ml-5 pl-5">{{ billDetailsOfPurchaseOrder?.billed | currency:'':'' }}</div>
        </div>
    </div>

    <div class="modal fade" id="logoUploader" tabindex="-1" role="dialog" aria-labelledby="logoUploader" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content custom-width">
            <div class="modal-header bbp-1px ">
                <b class="modal-title" style="font-size: 17px;">Mark PO No. {{PurchaseOrderData.OrderNo}} as billed</b>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true" style="font-size: 25px">&times;</span>
                </button>
                <hr>
            </div>
            <div class="modal-body bbp-1px" id="filecon" >
                <div class="pb-4">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group  col-md-4">
                                <p>Proceed to Draft Bill</p>
                            </div>
                            <div style="margin-top: 10px;" class="form-group  col-md-4">
                                <button type="button" (click)="createNewBill()"
                                class="eCount-def-btn  p-save-button">Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
          </div>
        </div>
      </div>
</app-dashboard>