<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">Advance/ {{advNo}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<div class="ml-3">
    <span *ngIf="advanceHistory == false || advanceHistory == undefined" class="cursor-pointer" [ngClass]="{'blue': ifDetails === true}" (click)="changeStatus(0)">Details |</span> <span *ngIf="advanceHistory == false || advanceHistory == undefined" class="ml-2 cursor-pointer" (click)="changeStatus(1)" [ngClass]="{'blue': ifHistory === true}">History</span>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;" [hidden] = "ifDetails == false">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="advanceForm" class="f-ss-14px">

                    <div class="row">                    
                        <div class="form-group  col-md-6">
                        <label for="Name" style="font-weight: 600;">Advance Type</label>
                            <select required class="form-control" style="width: 100%;" formControlName="TransactionTypeId" disabled>
                                <option *ngFor="let s of advanceTypeList" [ngValue]="s.Id">{{s.Name}}</option>
                            </select>
                    </div>
                    <div class="form-group  col-md-6">
                        <label for="Name" style="font-weight: 600;">Date</label>
                        <input type="date" class="form-control form-input" formControlName="TransactionDate" id="advtest" data-date="" data-date-format="D MMM YYYY" (change)="showDefaultDate($event)" disabled>
                    </div>
                </div>
                <div class="row">                    
                    <div class="form-group  col-md-6">
                    <label for="Name" style="font-weight: 600;">Business Partner</label>
                        <select required class="form-control" style="width: 100%;" formControlName="ContactId" disabled>
                            <option value="">Select</option>
                            <option *ngFor="let s of contactList" [ngValue]="s.Id">{{s.PrimaryPersonFullName}}</option>
                        </select>
                </div>
                <div class="form-group  col-md-6">
                    <label for="Name" style="font-weight: 600;">Account</label>
                        <select required class="form-control" style="width: 100%;" formControlName="AccountId" disabled>
                            <option value="">Select</option>
                            <option *ngFor="let s of accountList" [ngValue]="s.GLAccountId">{{s.AccountName}}</option>
                        </select>
                </div>
            </div>

        <div class="row">                    
            <!-- <div class="form-group  col-md-6">
                <label for="Name" style="font-weight: 600;">Description</label>
                    <input type="text" class="form-control form-input" formControlName="Description" required>
            </div> -->
            <div class="form-group  col-md-6">
                <label for="Name" style="font-weight: 600;">Amount</label>
                    <input type="text" class="form-control form-input" formControlName="Amount" required disabled>
            </div>
        </div>

                </form>
            </div>

        </div>
    </div>
</div>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;" [hidden] = "ifHistory == false">
    <div class="pb-2 row col-md-12">
        <div class="col-md-3 font-weight-bold">Advance No</div>
        <div class="col-md-3">Advance Type</div>
        <div class="col-md-2">Date</div>
        <div class="col-md-3">Account</div>
        <div class="col-md-1">Amount</div>
    </div>
    <div class="row col-md-12 mt-2" *ngFor = "let item of childData">
        <div class="col-md-3">{{item.AdvanceNo}}</div>
        <div class="col-md-3">{{item.TransactionTypeName}}</div>
        <div class="col-md-2">{{item.TransactionDateVw}}</div>
        <div class="col-md-3">{{item.AccountName}}</div>
        <div class="col-md-1">{{item.Amount}}</div>
    </div>
 </div>

