<app-dashboard>
    <div [hidden]="billFound">
        <p></p>
    </div>
    <div [hidden]="!billFound">
        <app-page-header-title
            mainTitle="Purchase Asset / {{BillData.BillNo}}/ {{BillData.StatusName}}"
            subTitle="Purchase Asset Overview"
            backURL="{{redirectUrl}}">

        <div class="full-width text-align-right">
            <div class="row">
                <div class="col-sm-11" [ngClass]="{'col-sm-12': isPaymentBtnVisible==false && !isEditable}">
                <div class="dropdown">
                    <button id="attachment_btn " type="button" style="z-index: 1;" class="btn attachment-button"><i id="attachment_btn"
                        style="font-size: 20px !important;" class="material-icons">insert_drive_file</i><span id="attachment_btn"
                        style="color:darkgrey; font-size: 12px; "
                        *ngIf="totalFile>=1">{{totalFile}}
                    </span>
                </button>
                    <div class="x-tip-tl" style="height: 0px;">
                        <i id="custom_notch" class="material-icons custom_notch" >arrow_drop_up</i>
                    </div>
                    <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                        <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="2"></app-attachment-uploader>
                    </div>
                </div>

                    <button *ngIf="(isInDraftingPhase || isInRejectPhase) && !PurchaseOrderNo" type="button"  [routerLink]="['/fixed-asset/fixed-asset-edit-bill',BillId]"  class="eCount-def-btn ml-4">Edit</button>
                    <button *ngIf="IsSubmitable" type="button"  (click)="submitBillData()"  class="eCount-def-btn ml-4">Submit</button>
                    <button  *ngIf="canApprove && IsSubmitable == false" type="button" style="background: red;" (click)="confirmBillRejection()" class="eCount-def-btn ml-4">Reject</button>
                    <button  *ngIf="canApprove" type="button" style="background: #5cac00;" (click)="approvedtBillData()" class="eCount-def-btn ml-4">Approve</button>
                    <!-- <button type="button" (click)="convetToPDF(BillData.BillNo)" class="eCount-button3 ml-4">{{'Contacts.contactList.export'| translate}}</button> -->
                    <button *ngIf="isPaymentBtnVisible" type="button" (click)="openMakePayment()" class="eCount-def-btn ml-4">Make a payment</button>
                    <button type="button" (click)="print(BillData.BillNo)" class="eCount-button3 ml-4">Print</button>
            </div>
                <div style="padding: 7px 10px; color: #c7c2c2;"  *ngIf="isPaymentBtnVisible || isEditable" >
                    <div class="dropdown">
                        <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                            aria-expanded="false">
                            <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
                        </div>
                        <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                            style="width: 100%;">

                            <!-- <div class="context-menu-item pb-3"  (click)="sendMail()">
                                <span class="context-menu-text text-center">Send</span>
                            </div> -->
                            <!-- <div class="context-menu-item pb-2" *ngIf="isInAwaitingPaymentPhase && IsUserAtMaxApprovalLevel && isFromPurchaseOrder == false && !PurchaseOrderNo" (click)="onEditableRoute()">
                              <span class="context-menu-text text-center">Edit</span>
                            </div> -->
                            <div class="context-menu-item pb-2" *ngIf="(isEditable || PurchaseOrderNo == null) && !isPartiallyPaid"  (click)="confirmBillDeletion()">
                              <span class="context-menu-text text-center">Delete</span>
                            </div>
                            <div class="context-menu-item pb-2"  *ngIf="isPaymentBtnVisible"  (click)="openDiscountPopup()">
                                <span class="context-menu-text text-center">Discount</span>
                            </div>
                            <div class="context-menu-item pb-2"  *ngIf="isPaymentBtnVisible"  (click)="makeAdjustment()">
                                <span class="context-menu-text text-center">Advance Adjustment</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </app-page-header-title>
        <div *ngIf="canShowReceiptsTab()">
            <div class="contianer full-width" style="margin-bottom: 15px; margin-top: 0px;">
                <div class="row nav nav-tabs d-flex justify-content-end" id="myTab" role="tablist">
                    <div class="col-md-10 nav-item nav-bar"></div>
                    <div class="col-md-2 nav-item nav-link nav-bar" [routerLink]="['/expense/bill-receipt-list', billNumber, 'All']">
                        <a>Receipts({{receiptData}})</a>
                    </div>
                </div>
            </div>    
        </div>
        <div class="m" id="printBill">
            <div class="row">
                <div class="col-md-12 d-flex f-ss-14px" id="my1">
                    <div class="col-md-4 d-block pl-1">
                        <p class="ToHeading">To</p>
                        <div>Name : {{BillData.Contacts ? BillData.Contacts.CompanyName : ''}}</div>
                        <div>Email : {{BillData.Contacts ? BillData.Contacts.Email : ''}}</div>
                        <div>Address : {{BillData.Contacts ? BillData.Contacts.StreetAddress : ''}}</div>
                    </div>
                    <div class="col-md-3">
                        <p class="ToHeading">Date</p>
                        <div>Created Date : {{convertDateIntoDisplay(BillData.CreatedAt)}}</div>
                        <div>Bill Date : {{convertDateIntoDisplay(BillData.BillDate)}}</div>
                        <div>Due Date : {{convertDateIntoDisplay(BillData.DueDate)}}</div>
                        <div>Modified By: {{BillData.ModifiedByName ? BillData.ModifiedByName : BillData.CreatedByName }}</div>
                        <div>Modified Date: {{convertDateIntoDisplay(BillData.UpdatedAt ? BillData.UpdatedAt : BillData.CreatedAt)}}</div>
                    </div>
                    <div class="col-md-6">
                        <p class="ToHeading">Bill Details</p>
                        <div>Bill No # {{BillData.BillNo}}</div>
                        <div *ngIf ="BillData.InvoiceNo == null && !PurchaseOrderNo && !isPurchaseOrder">Reference No # {{BillData.ReferenceNo}}</div>
                        <div *ngIf ="isPurchaseOrder && !PurchaseOrderNo">Reference No # <a [routerLink]="['/procurement/purchase-order-details',0,BillData.ReferenceNo]">{{"PO-"+BillData.ReferenceNo}}</a></div>
                        <div *ngIf = "BillData.InvoiceNo != null && BillData.ReferenceNo[0]=='P'">Reference No # <a [routerLink]="['/procurement/purchase-order-details',BillData.InvoiceNo]">{{BillData.ReferenceNo}}</a></div>
                        <div *ngIf = "BillData.InvoiceNo != null && BillData.ReferenceNo[0]=='I'">Reference No # <a [routerLink]="['/income/invoice',BillData.ReferenceNo]">{{BillData.ReferenceNo}}</a></div>
                        <div *ngIf="PurchaseOrderNo" >Reference No # <a [routerLink]="['/procurement/purchase-order-details',0,PurchaseOrderNo]">{{"PO-"+PurchaseOrderNo}}</a></div>
                        <div *ngIf = "BillData.StatusId == 7">Reject Reason : <span class="text-red">{{BillData.RejectReason}} </span> </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row f-ss-14px" >
                <div class="col-md-12 tax exchange" >
                    <div class="float-right" id='my2'>
                        <label> Amounts are  </label>
                        <strong *ngIf="BillData.TaxTypeId==1"> VAT/AIT Exclusive  </strong>
                        <strong *ngIf="BillData.TaxTypeId==2"> VAT/AIT Inclusive  </strong>
                        <strong *ngIf="BillData.TaxTypeId==3"> No VAT/AIT  </strong>
                    </div>
                </div>
                <div class="invoice-grid col-md-12" id="my" >
                    <table class="w-100" border="1|0">
                        <thead>
                            <tr><td>Item</td>
                            <td>Description</td>
                            <td> Quantity </td>
                            <td *ngIf="!PurchaseOrderNo && !isPurchaseOrder && isBasicBill != true && isDescriptiveBill() == false" >Received</td>
                            <td>Unit Price</td>
                            <td>Account</td>
                            <td>VAT Rate</td>
                            <td>AIT Rate</td>
                            <td class="amount"> <div class="float-right mr-1">Amount BDT</div></td>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of BillData.BillItems; let i = index;">
                                <td>{{formatItem(item.ItemId)}}</td>
                                <td>{{item.Description}}</td>
                                <td>{{item.Quantity}}</td>
                                <td *ngIf="!PurchaseOrderNo && !isPurchaseOrder && isBasicBill != true && isDescriptiveBill() == false && BillData.BillItems[i].ItemId != null" [ngStyle]="{'color': isReceivedMax(item.Quantity, this.BillData.ReceivedItems[i])? 'red' : '#444'}">{{this.BillData.ReceivedItems[i]}}</td>
                                <td *ngIf="!PurchaseOrderNo && !isPurchaseOrder && isDescriptiveBill() == false && BillData.BillItems[i].ItemId == null">-</td>
                                <td>{{twoDecimalPlace(item.UnitPrice)}}</td>
                                <td class="editable-cell">{{item.AccountName}}</td>
                                <td>{{formatTaxRate(item.TaxRateId)}}</td>
                                <td>{{formatTaxRate(item.AdvanceTaxRateId)}}</td>
                                <td><div class="float-right mr-1">{{twoDecimalPlace(item.LineTotal)}}</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row f-ss-14px">
                <div class="container-bottom d-flex w-100">
                    <div class="col-md-8 col-sm-12 row">
                        <div class="form-group col-sm-5">
                            <label>Notes</label>
                             <!-- <textarea maxlength="250" rows="5" type="text" class="form-control">{{BillData.Notes}}</textarea> -->
                             <div class="textarea-wrapper">
                                <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea"
                                    placeholder="Write down some important notes using only 250 characters">{{BillData.Notes}}</textarea>
                                <span class="remaning" >{{myInput.value.length}}/250</span>
                            </div>
                        </div>
                        <div class="col-sm-7 row" [hidden]="BillData.BillNo ==undefined">
                            <div class="col-sm-3" *ngIf="BillData.IsRepeating==true">
                                <label style="font-weight: 600;">Repeating</label>
                                <label class="switch">
                                    <input class="switch-input" type="checkbox" [(ngModel)]="BillData.IsRepeating">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                            <div class="col-sm-7 ml-4" *ngIf="BillData.IsRepeating==true">
                                <label for="RepeatingTypeId" style="font-weight: 600;">Repeating Types</label>
                                <ng-select [items]="repeatingTypeList" bindLabel="Name" bindValue="Id"
                                [(ngModel)]="BillData.RepeatingTypeId">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label>Subtotal</label>
                            </div>
                            <div class="col-md-4 float-right"><p class="float-right pr-3">{{twoDecimalPlace(BillData.BillAmount- (BillData.TaxAmount + BillData.AdvanceTaxAmount))}}</p></div>
                        </div>
                        <div class="d-flex w-100 pb-1" *ngIf="BillData?.TaxAmount > 0">
                            <div class="col-md-8">
                                <label>Total VAT</label>
                            </div>
                            <div class="col-md-4"> <p class="float-right pr-3">{{BillData?.TaxAmount  ? BillData.TaxAmount : 0.00 | currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100 pb-1" *ngIf="BillData?.AdvanceTaxAmount > 0">
                            <div class="col-md-8">
                                <label>Total AIT</label>
                            </div>
                            <div class="col-md-4"> <p class="float-right pr-3">{{BillData?.AdvanceTaxAmount ? BillData.AdvanceTaxAmount : 0.00 | currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100 singleLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label class="totalTextValue">Total</label>
                            </div>
                            <div class="col-md-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(BillData.BillAmount)| currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100 pt-2" *ngIf ="BillData?.DiscountAmount > 0">
                            <div class="col-md-8">
                                <label class="totalTextValue">Discount</label>
                            </div>
                            <div class="col-md-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(BillData?.DiscountAmount)| currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100 pt-2" *ngFor="let item of paidBill">
                            <div class="col-md-7" >
                                <label *ngIf="!item.IsAdjustAdvance">Less payment - {{convertDateIntoDisplay(item.PaidDate)}}</label>
                                <label *ngIf="item.IsAdjustAdvance">Less Prepayment - {{convertDateIntoDisplay(item.PaidDate)}}</label>
                            </div>
                            <div class="col-md-5 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(item.PaidAmount)| currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100 singleLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label >Amount due</label>
                            </div>
                            <div class="col-md-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(BillData.BillAmount-(itemsTotalPaidAmount+BillData?.DiscountAmount))| currency:'':''}}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-5" [hidden]="!paidBill.length">
                <div class="col-md-12 invoice-grid f-ss-14px">
                    <h3>Payment Statements</h3>
                    <table class="w-100" border="1|0">
                        <thead>
                          <tr>
                            <td> Paid Date </td>
                            <td>Paid To</td>
                            <td>Payment Mode</td>
                            <td>Reference No</td>
                            <td>Account Name</td>
                            <td>Account No</td>
                            <td>Bank Name</td>
                            <td class="amount"> Amount</td>

                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paidBill">
                                <td>{{convertDateIntoDisplay(item.PaidDate)}}</td>
                                <td *ngIf="item.Account==null">Adjustment</td>
                                <td *ngIf="item.Account!=null && item.Account.AccountTypeId==18">Cash: {{item.Account.AccountName}}</td>
                                <td *ngIf="item.Account!=null && item.Account.AccountTypeId==16">Bank: {{item.Account.AccountName}}</td>
                                <td *ngIf="item.Account!=null && item.Account.AccountTypeId==19">Digital Cash: {{item.Account.AccountName}}</td>
                                <td>{{item.PaymentModeName}}</td>
                                <td>{{item.ReferenceNo}}</td>
                                <td>{{item.SourceAccountName}}</td>
                                <td>{{item.SourceAccountNo}}</td>
                                <td>{{item.SourceBankName}}</td>
                                <td>{{twoDecimalPlace(item.PaidAmount) | currency:'':''}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <ngx-smart-modal #MakePayment identifier="MakePayment" >
        <div>
            <span class="modalHeaderText pl-2">Make Payment</span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                <div>
                    <form [formGroup]="makePaymentForm" class="f-ss-14px" (ngSubmit)="submitMakePayment()" >
                    <div class="row col-md-12">
                        <div class="form-group col-md-6">
                            <span class="formTitle">Date Paid</span>
                            <input class="form-control" type="date" formControlName="PaidDate" id="bdtest1" data-date="" data-date-format="D MMM YYYY" (change)="show($event)" required>
                        </div>
                        <div class="form-group col-md-6">
                            <span class="formTitle">Amount Paid</span>
                            <input type="number" formControlName="PaidAmount" class="form-control"  placeholder="Amount Paid" [ngClass]="{'submitted':ifSubmit == true}" required>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="form-group col-md-6">
                            <span class="formTitle">Paid Form <span class="clr">*</span> </span>
                            <ng-select [items]="bankAccounts" formControlName="AccountId" [ngClass]="{'submitted':ifSubmit == true}" (change)="getPaymentModeList(makePaymentForm.value.AccountId)" bindLabel="AccountName" bindValue="GLAccountId" required></ng-select>
                        </div>
                        <div class="form-group col-md-6" *ngIf="accountTypeId==0">
                            <span class="formTitle">Payment Mode</span>
                            <ng-select [items]="PaymentModeList" formControlName="PaymentModeId"  bindLabel="Name" bindValue="Id"></ng-select>
                        </div>
                        <div class="form-group col-md-6" *ngIf="accountTypeId !=0 ">
                            <span class="formTitle">Reference No</span>
                            <input type="text" formControlName="ReferenceNo" class="form-control mt-1"  placeholder="Reference No :  eg #reference">
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="form-group col-md-6" *ngIf="accountTypeId ==0 ">
                            <span class="formTitle">Reference No</span>
                            <input type="text" formControlName="ReferenceNo" class="form-control mt-1"  placeholder="Reference No :  eg #reference">
                        </div>
                        <div class="form-group col-md-6" *ngIf="accountTypeId==0">
                            <span class="formTitle">Account Name</span>
                            <input type="text" formControlName="SourceAccountName" class="form-control mt-1"  placeholder="">
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="form-group col-md-6" *ngIf="accountTypeId==0">
                            <span class="formTitle">Account No.</span>
                            <input type="text" formControlName="SourceAccountNo" class="form-control mt-1"  placeholder="">
                        </div>

                        <div class="form-group col-md-6" *ngIf="accountTypeId==0">
                            <span class="formTitle">Bank Name</span>
                            <input type="text" formControlName="SourceBankName" class="form-control mt-1"  placeholder="">
                        </div>
                    </div>
                        <div class="form-group pt-4 col-md-12 text-align-right" >
                            <button type="submit" class="eCount-def-btn ml-4 pl-4 mr-4">Payment</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

        </div>
    </ngx-smart-modal>

    <ngx-smart-modal #AddDiscount identifier="AddDiscount">
        <div class="pb-4">
            <span class="modalHeaderText pl-2" style="font-size: 17px; font-weight: 600;">Add Discount</span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                <div>
                    <form [formGroup]="AddDiscountFrom" (ngSubmit)="submitDiscountAmount()">

                        <div class="form-group">
                            <span class="formTitle">Amount Due</span>
                            <input type="number" formControlName="AmountDue" class="form-control mt-1"  placeholder="Amount Due" disabled>
                        </div>
                        <div class="form-group" >
                            <span class="formTitle">Discount Amount</span>
                            <input type="number" formControlName="AdditionalDiscountAmount"  (change)="DecreaseTotalAmount()" class="form-control mt-1"  placeholder="Discount Amount" appDecimaNumericValidator>
                        </div>
                        <div class="form-group" *ngIf="BillData.DiscountAmount!==null">
                            <span class="formTitle">Additional Discount Amount</span>
                            <input type="number" formControlName="DiscountAmount" class="form-control mt-1" (change)="DecreaseAddTotalAmount()"  placeholder="Discount Amount" appDecimaNumericValidator>
                        </div>
                        <div class="form-group">
                            <span class="formTitle">Total Amount After Discount</span>
                            <input type="text" formControlName="TotalAmount" class="form-control mt-1"  placeholder="" appDecimaNumericValidator>
                        </div>
                        <div class="form-group pt-4" style="float: right;">
                            <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4">Save</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

        </div>
    </ngx-smart-modal>

</app-dashboard>
