<app-dashboard>
    <app-page-header-title mainTitle="Document Settings" subTitle="Home" backURL="/">
        <div class="full-width text-align-right">
            <button class="eCount-def-btn ml-4" type="button" (click)="Save()">Save</button>
        </div>
    </app-page-header-title>

    <div class="headSection">
        <form [formGroup]="TagsAttachmentVersionSettingForm" class="f-ss-14px">
            <div class="col-md-12 col-sm-12 ml-0 mt-4 d-flex mb-2 ">
                <div class="col-md-3">
                    <label class="font-weight-bold">Author Document Version History</label>
                </div>
                <div class="col-md-6">
                    <label class="switch ml-2">
                        <input class="switch-input" type="checkbox" formControlName="IsActive">
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 ml-0 mt-4 d-flex mb-2 ">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="font-weight-bold">Create a version everytime you edit an author document</label>
                        </div>
                        <div class="col-md-12">
                            <input class="pt-1" type="radio" [value]="0" for="incrementType" id="incrementType" formControlName="VersionIncrementType">
                            <label class="pl-2">Decimal Increment (e.g. 1,2,3..)</label>
                        </div>
                        <div class="col-md-12">
                            <input class="pt-1" type="radio" [value]="1" for="incrementType" formControlName="VersionIncrementType">
                            <label class="pl-2" for="incrementType">Fractional Increment (e.g. 1.0,1.1,1.2)</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-sm-12 ml-0 mt-4 mb-2 ">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="font-weight-bold">Max Version No:</label>
                        </div>
                        <div class="col-md-3">
                            <input class="form-control" type="text" formControlName="MaxVersionNo">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>
