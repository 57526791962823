import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ManufactureBackOrderFlowType } from "src/app/enum/manufacture-backorder-flow";
import { ManufactureOrderViewModel } from "src/app/models-viewModels/manufactureViewModel.model";
import { ManufactureService } from "src/app/service/Manufacture/manufacture.service";

@Component({
  selector: "app-manufacture-back-order-flow-type-modal",
  templateUrl: "./manufacture-back-order-flow-type-modal.component.html",
  styleUrls: ["./manufacture-back-order-flow-type-modal.component.css"],
})

export class ManufactureBackOrderFlowTypeModalComponent implements OnInit {
  manufactureBackOrderFlowTypeForm: FormGroup;
  @Input() manufactureOrderViewModel: ManufactureOrderViewModel;

  constructor(
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private manufactureService: ManufactureService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.manufactureBackOrderFlowTypeForm = this.fb.group({
      MentionedProductSelected: ["1"],
    });
  }

  produced() {
    if (Number(this.manufactureBackOrderFlowTypeForm.value.MentionedProductSelected) == 0) {
      this.manufactureOrderViewModel.ManufactureBackOrderFlowType = ManufactureBackOrderFlowType.PRODUCE_ONLY_MENTIONED;
    } else {
      this.manufactureOrderViewModel.ManufactureBackOrderFlowType = ManufactureBackOrderFlowType.GENERATE_BACKORDER;
    }
    this.spinner.show();
    this.manufactureService
      .produceManufactureOrder(this.manufactureOrderViewModel)
      .subscribe((x) => {
        if (x.Success) {
          this.toaster.success(x.Message);
          this.activeModal.close();
          this.router.navigate(["manufacture/manufacutring-order-list/All"]);
          this.spinner.hide();
        } else {
          this.toaster.warning(x.Message);
          this.activeModal.close();
          this.spinner.hide();
        }
      });
  }
}
