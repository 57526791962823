import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Assignee } from 'src/app/models/assignee';
import { FixedAssetItem } from 'src/app/models/fixedAsset.model';
import { AssigneeService } from 'src/app/service/Assignee/assignee.service';
import { FixedAssetService } from 'src/app/service/FixedAsset/fixedasset.service';

@Component({
  selector: 'app-add-assignee',
  templateUrl: './add-assignee.component.html',
  styleUrls: ['./add-assignee.component.css']
})
export class AddAssigneeComponent implements OnInit {

  AddAssigneeForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    public fixedAssetService: FixedAssetService,
    public assigneeService: AssigneeService,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.InitializeForm();
  }
  
  InitializeForm() {
    this.AddAssigneeForm = this.fb.group({
      AssigneeName: ['', Validators.required],
      AssigneeRole: [''],
      AssigneeEmail: ['', Validators.required],
    });
  }

  SaveAssignee() {
    if(!this.AddAssigneeForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    this.spinner.show();
    let data: Assignee = this.getFormData();
    this.assigneeService.saveAssignee(data).subscribe((x) => {
      if(x.Success) {
        this.toaster.success("Assignee added successfully");
        this.router.navigate(["/fixed-asset/assignee-list/All"]);
        this.activeModal.close();
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  getFormData(): Assignee {
    let assigneeData: Assignee = {
      Id: 0,
      Name: this.AddAssigneeForm.value.AssigneeName,
      Role: this.AddAssigneeForm.value.AssigneeRole,
      Email: this.AddAssigneeForm.value.AssigneeEmail,
    }
    return assigneeData;
  }

}
