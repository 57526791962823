import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/service/EventEmitter/data.service";
import {
  faBars,
  faCalendarPlus,
  faClipboardList,
  faCogs,
  faCoins,
  faCreditCard,
  faExchangeAlt,
  faFileInvoice,
  faHeart,
  faHome,
  faInfoCircle,
  faSignOutAlt,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { AuthService } from "src/app/service/auth/auth.service";
import { MainServiceService } from "src/app/service/Main/main-service.service";
declare var $: any;
@Component({
  selector: "app-sidebar-component",
  templateUrl: "./sidebar-component.component.html",
  styleUrls: ["./sidebar-component.component.css"],
})
export class SidebarComponentComponent implements OnInit {
  HomeIcon = faHome;
  Icons = {
    faBars,
    faUsers,
    faHome,
    faCreditCard,
    faCoins,
    faClipboardList,
    faInfoCircle,
    faFileInvoice,
    faCogs,
    faHeart,
    faSignOutAlt,
    faUserPlus,
    faCalendarPlus,
    faExchangeAlt,
  };

  constructor(
    private mainService: MainServiceService,
    public authService: AuthService,
    private dataService: DataService
  ) { }
  showLi = false;
  menu: any;
  groupData: any[];
  billList: any[];
  invoiceList: any[];
  InventoryList: any[];
  FileList: any[];
  factoryList: any[];
  fixedAssetList: any[];
  manufactureMenuList: any[];
  myItemsList: any[];
  businessPartnerList: any[];
  reportsList: any[];
  accountSettingsList: any[];
  showSidebar = true;
  advanceList: any[];
  showPopUp = false;

  //class name
  navClass: string = "nav-sidebar";
  navItemClass: string = "nav-item";
  navItemOpenClass: string = "nav-item-open";
  navLinkClass: string = "nav-link";
  navSubmenuClass: string = "nav-group-sub";
  navSlidingSpeed: number = 250;
  showOnlyDocument: boolean = false;
  //status: string = 'document'; //default first tab select for pending list page is document.

  ngOnInit() {
    var userInfo = this.authService.getUserInfo();
    this.dataService.sidebarHide.subscribe((x) => {
      this.showSidebar = x;
    });
    this.dataService.setsidebarUpdate.subscribe((x) => {
      if (x) {
        this.getMenuList();
      }
    });
    this.dataService.sidebarUpdate.subscribe((x) => { });
    if (userInfo) {
      this.getMenuList();
    }

    this.dataService.setWarningPopup.subscribe((x) => {
      this.showPopUp = x.Message == null ? true : false;

      this.sidebarScrollComponentToggle();
    });

    // var navClass = 'nav-sidebar',
    //   navItemClass = 'nav-item',
    //   navItemOpenClass = 'nav-item-open',
    //   navLinkClass = 'nav-link',
    //   navSubmenuClass = 'nav-group-sub',

    //   navSlidingSpeed = 250;
    // $('.' + navClass).each(function () {
    //   $(this).find('.' + navItemClass).has('.' + navSubmenuClass).children('.' + navItemClass + ' > ' + '.' + navLinkClass).not('.disabled').on('click', function (e) {
    //     e.preventDefault();

    //     // Simplify stuff
    //     var $target = $(this),
    //       $navSidebarMini = $('.sidebar-xs').not('.sidebar-mobile-main').find('.sidebar-main .' + navClass).children('.' + navItemClass);

    //     // Collapsible
    //     if ($target.parent('.' + navItemClass).hasClass(navItemOpenClass)) {
    //       $target.parent('.' + navItemClass).not($navSidebarMini).removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
    //     }
    //     else {
    //       $target.parent('.' + navItemClass).not($navSidebarMini).addClass(navItemOpenClass).children('.' + navSubmenuClass).slideDown(navSlidingSpeed);
    //     }

    //     // Accordion
    //     if ($target.parents('.' + navClass).data('nav-type') == 'accordion') {
    //       $target.parent('.' + navItemClass).not($navSidebarMini).siblings(':has(.' + navSubmenuClass + ')').removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
    //     }
    //   });

    // })
    //   $('.sidebar-main').find('.nav-sidebar').children('.nav-item-submenu').hover(function() {
    //     var totalHeight = 0,
    //         $this = $(this),
    //         navSubmenuClass = 'nav-group-sub',
    //         navSubmenuReversedClass = 'nav-item-submenu-reversed';

    //     totalHeight += $this.find('.' + navSubmenuClass).filter(':visible').outerHeight();
    //     if($this.children('.' + navSubmenuClass).length) {
    //         if(($this.children('.' + navSubmenuClass).offset().top + $this.find('.' + navSubmenuClass).filter(':visible').outerHeight()) > document.body.clientHeight) {
    //             $this.addClass(navSubmenuReversedClass)
    //         }
    //         else {
    //             $this.removeClass(navSubmenuReversedClass)
    //         }
    //     }
    // });
    this.sidebarScrollComponentToggle();
    //this.status = localStorage.getItem('pendingListStatus') ?? this.status; //default is document
  }

  sidebarScrollComponentToggle = () => {
    var infoShow = document.getElementById("infoShow");
    var navMenu = document.getElementById("navMenu");
    const value = this.showPopUp == false ? 50 : 0;
    console.log(value);
    let totalHeight = value + navMenu.clientHeight;
    var sidebar = document.getElementById("sidebar");
    sidebar.style.height = `calc( 100vh - ${totalHeight}px)`;
    sidebar.style.scrollBehavior = "smooth";
    sidebar.style.paddingBottom = "24px";
    console.log("sidebar------------>");
  };

  getMenuList() {
    this.mainService.GetMenu().subscribe((x) => {
      console.log("AllMenu===>",x)
      this.menu = x;
      this.billList = x.BillMenuList;
      this.invoiceList = x.InvoiceMenuList;
      this.InventoryList = this.removeDuplicates(x.InvenotryList);
      this.factoryList = x.FactoryMenuList;
      this.FileList = this.setDocumentOverviewInTop(x.FileMenuList);
      this.advanceList = x.AdvanceMenuList;
      this.fixedAssetList = x.FixedAssetMenuList;
      this.manufactureMenuList = x.ManufactureMenuList;
      this.myItemsList = x.MyItemsList;
      this.businessPartnerList = x.BusinessPartnerList;
      this.reportsList = x.ReportsList;
      this.accountSettingsList = x.AccountSettingsList;
      if(this.billList.length == 0 && this.invoiceList.length == 0 && this.InventoryList.length == 0 && this.advanceList.length == 0 && this.fixedAssetList.length == 0 && this.manufactureMenuList.length == 0) {
        this.showOnlyDocument = true;
      }
    });
  }

  setDocumentOverviewInTop(fileMenuList: any[]) {
    const index = fileMenuList.findIndex(menu => menu.Id === 34);
    const updatedList = [...fileMenuList];
    if (index != -1) {
      const movedItem = updatedList.splice(index, 1)[0];
      updatedList.unshift(movedItem);
    }
    return updatedList;
  }

  initSidebar = () => {
    console.log("sub menu clicked");
  };

  removeDuplicates(arr) {
    var filteredList = [];
    arr.forEach( (item) => {
      if( filteredList.filter( x => x.Name == item.Name ).length == 0 ){
        filteredList.push(item);
      }
    });
    return filteredList;
  }

  clickV() {
    if (this.showLi === false) {
      this.showLi = !this.showLi;
      $("#sidebar").css("paddingBottom", "200px");
    } else {
      this.showLi = !this.showLi;
      $("#sidebar").css("paddingBottom", "26px");
    }
  }
  sidebarItemAction() {
    // if(this.showLi){
    //   this.showLi = false;
    //   var sidebar = document.getElementById("sidebar");
    //   sidebar.style.height = `calc( 110vh - 32vh)`;
    //   var sidebarBottom = document.getElementById("sidebar-bottom");
    // }
  }
  closeAllMainSidebar = (sidebar) => {
    let selectedNav = $(sidebar)
      .find("." + this.navItemClass)
      .has("." + this.navSubmenuClass)
      .children("." + this.navItemClass + " > " + "." + this.navLinkClass);

    let navSidebarMini = $(".sidebar-xs")
      .not(".sidebar-mobile-main")
      .find(".sidebar-main ." + this.navClass)
      .children("." + this.navItemClass);
    if (
      selectedNav
        .parent("." + this.navItemClass)
        .hasClass(this.navItemOpenClass)
    ) {
      selectedNav
        .parent("." + this.navItemClass)
        .not(navSidebarMini)
        .removeClass(this.navItemOpenClass)
        .children("." + this.navSubmenuClass)
        .slideUp(this.navSlidingSpeed);
    }
  };

  IsOnlyHasDocumentFeature() {
    const userInfo =this.authService.getUserInfo();
    if(userInfo?.IsCompanyHasOnlyDocumentFeature)
      return true;
    else
      return false;
  }

  isAdmin() {
    const userInfo =this.authService.getUserInfo();
    if(userInfo?.RoleId == 1)
      return true;
    else
      return false;
  }

}
