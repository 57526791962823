import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QuotationService } from '../../../service/Quotation/quotation.service'
import jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SendMailComponent } from '../send-mail/send-mail.component';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';
import { ToastrService } from 'ngx-toastr';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';


@Component({
  selector: 'app-quotation-details',
  templateUrl: './quotation-details.component.html',
  styleUrls: ['./quotation-details.component.css'],
  host: {
    '(document:click)': 'onWindowClick($event)',
  },
})
export class QuotationDetailsComponent implements OnInit {
  dataFound: boolean = false
  QuotationNo: string;
  quotationId:number;
  QuotationData: any = []
  isUploaded: boolean = false;
  uploadCounter: number;
  filesToUpload: number = 0;
  baseurl = environment.baseUrl;
  baseFileUrl = environment.fileBaseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  percentDone: number;
  allAttachment: any = [];
  isEditable: boolean = false;
  isActive: boolean = false;
  allSaleableItem: any = [];
  taxRateList:any=[];
  subTotalAmount: number = 0.00;
  TaxAmount: number = 0.00;
  totalFile: number=0;

  constructor(private route: ActivatedRoute,
    private quotationService: QuotationService,
    private settingService:SettingsServiceService,
    private fileEmitterService:FileEmitterService,
    private salesOrderService:SalesOrderService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private modalService:NgbModal) { }

  ngOnInit(): void {
    let tempData = this.route.snapshot.paramMap.get("quotationId");
    this.QuotationNo = '';
    for(let i=3;i<tempData.length;i++) this.QuotationNo+=tempData[i];
    this.getAllSaleableItem();
    this.getTaxRateList();
    this.getQuotationData();

  }

  deleteQuotation() {
    this.spinner.show();
    this.QuotationData.IQuidiActionType = iQuidiActionType.Delete;
    this.quotationService.StatusUpdate(this.QuotationData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Quotation ${this.QuotationNo} has been successfully deleted`, 'Success!');
        this.router.navigate(['quotation/quotation-list/All']);
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  }

  public setTotalFile(tf:number) {
    this.totalFile=tf;
  }

  public ConfirmQuotation(){
    this.spinner.show();
    this.QuotationData.IQuidiActionType = iQuidiActionType.Confirm;
    this.quotationService.StatusUpdate(this.QuotationData).subscribe((x) => {
      if(x.Success){
        this.toaster.success("Quotation has been confirmed");
        this.getQuotationData();
      }
      this.spinner.hide();
    });
  }

  /**
   * CreateSalesOrder
   */
  public CreateSalesOrder() {
    this.QuotationData.IsSalesOrderCreated = true;
    this.spinner.show();
    this.salesOrderService.CreateSalesOrder(this.QuotationData.Id).subscribe((x) => {
      if(x.Success){
        this.toaster.success("Sales order created successfully");
        this.router.navigate(['sales/sales-order-list/All']);
      }
      this.spinner.hide();
    });
  }

  getQuotationData = () => {
    this.quotationService.GetQuotationByNo(this.QuotationNo).subscribe((x) => {
      if (x) {
        this.QuotationData = x;
        this.fileEmitterService.emitFileRefIdUpdate({id:this.QuotationData.Id});
        this.dataFound = true;
        this.quotationId = this.QuotationData.Id;
        this.isActive = this.QuotationData.IsActive;
      }
    })
  }
  getAllSaleableItem = () => {
    this.quotationService.GetAllSaleableItem().subscribe((x) =>
      this.allSaleableItem = x)
  }

  formatItem(id: string) {

    let value = this.allSaleableItem.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.ItemName;
    }
  }
  formatTaxRate(id: number) {

    let value = this.taxRateList.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }

  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  twoDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.00;
  }
  print = (qNo) => {
    console.log(qNo);
    let hostUrl = location.origin + "/#/";
    window.open(`${hostUrl}quotation/preview/${qNo}`, '_blank');
  }
  convetToPDF = (qNo) => {
    var data = document.getElementById('printQuotation');
    data.classList.add("margin-left");
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 212;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      var DocumentName = `Quotation-${qNo}.pdf`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    })
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id == "Preview")) {

    }
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown()
    }
    else {
      let val = "do something";
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }

  /**
   * sendMail
   */
  public sendMail() {
    var data = document.getElementById('printQuotation');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      var blob = pdf.output('blob');
      const modalRef = this.modalService.open(SendMailComponent,{ size: 'md',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = {Id:this.quotationId,RefNo:this.QuotationNo,PdfData:blob};
      modalRef.result.then((result) => {

                   }, (reason) => {
                     console.log('Dismissed action: ' + reason);
                   });
    })
  }
}

