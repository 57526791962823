<app-dashboard>
    <app-page-header-title
        mainTitle="Third party delivery partner"
        subTitle="Home"
        backURL="/">
    </app-page-header-title>

<div>
    
    <div class="row">
        <p class="header ml-4">Add delivery Partner</p><span class="fnt ml-2">*</span>
    </div>
    <form [formGroup]="dpForm" class="d-flex w-100">
    <div class="col-sm-4 row">
        <div class="col-sm-6">
            <input type="text" class="form-control" formControlName="CompanyName" required>
        </div>
         <div class="col-sm-6">
            <button type="button" class="eCount-def-btn" (click)="save()">Save</button>
         </div>
    </div>
    </form>
    <div class="pt-3 ml-3">
        <ag-grid-angular
          class="ag-theme-alpine"
          style="width: 100%; height: 420px;"
          #topGrid
          id="topGrid"
          [rowData]="contactList"
          [columnDefs]="columnDefs"
          (gridReady)="onGridReady($event)"
          (rowClicked)="onRowClicked($event)"
          [defaultColDef]="defaultColDef"
        ></ag-grid-angular>
      </div>
</div>
</app-dashboard>
