<app-dashboard>
    <div>
        <app-page-header-title mainTitle="{{ setMainTitle() }}"
            subTitle="Manufacture Order List" 
            backURL="/manufacture/manufacutring-order-list/All">
            <div class="full-width text-align-right">
                <button type="button" (click)="editManufactureOrder()" *ngIf="canEditManufactureOrder()" class="eCount-def-btn ml-2">Edit</button>
                <button type="button" (click)="approveManufactureOrder()" *ngIf="canApproveManufactureOrder()" class="eCount-def-btn ml-4" style="background: #5cac00;">Approve</button>
                <button type="button" (click)="deleteManufactureOrder()" *ngIf="canDeleteManufactureOrder()" style="background: red;" class="eCount-def-btn ml-4">Delete</button>
                <button type="button" (click)="produceManufactureOrder()" *ngIf="canProduceManufactureOrder()" class="eCount-def-btn ml-4" style="background: #5cac00;">Produce</button>
            </div>
        </app-page-header-title>
    </div>
    <div class="headSection">
        <form [formGroup]="ManufactureOrderForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-2">
                    <label for="">Product Name</label>
                    <input class="form-control" type="text" formControlName="FinishedProductId" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Formula Name</label>
                    <input class="form-control" type="text" formControlName="FormulaName" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Quantity To Produce</label>
                    <input class="form-control" type="text" formControlName="Quantity" appDecimaNumericValidator disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Created Date</label>
                    <input class="form-control" type="text" formControlName="CreatedDate" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Reference</label>
                    <input class="form-control" type="text" formControlName="Reference" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Batch No</label>
                    <input class="form-control" type="text" formControlName="BatchNo" disabled>
                </div>
                <div class="col-md-2 pt-3">
                    <label for="">Start Date</label>
                    <input class="form-control" type="text" formControlName="StartDate" disabled>
                </div>
                <div class="col-md-2 pt-3">
                    <label for="">End Date</label>
                    <input class="form-control" type="text" formControlName="EndDate" disabled>
                </div>
            </div>

            <div class="row d-block topSection f-ss-14px mt-5" *ngIf="canShowOrderItems()">
                <div class="d-flex">
                    <div class="col-md-3">
                        Item
                    </div>
                    <div class="col-md-3">
                        Recipe Quantity
                    </div>
                    <div class="col-md-3">
                        Balance
                    </div>
                    <div class="col-md-3">
                        To Consume
                    </div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="OrderItems" *ngFor="let item of ManufactureOrderForm.get('OrderItems')['controls']; let i = index;">
                        <div class="row f-ss-14px" [formGroupName]="i">
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="RawMaterialId" disabled>
                            </div>
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="RecipeQuantity" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="Balance" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="ToConsume" appDecimaNumericValidator disabled>
                            </div>
                        </div>
                    </div>
                    <hr class="pb-0 mb-0 pt-0 mt-1">
                    <div class="row d-flex">
                        <div class="col-md-9 font-weight-bold">
                            Total
                        </div>
                        <div class="col-md-2 font-weight-bold ml-3">
                            {{ totalOrderItemsConsume.toFixed(2) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px mt-5" *ngIf="canShowMiscellaneousItems()">
                <div class="font-weight-bold mb-1">
                    Miscellaneous Item
                </div>
                <div class="d-flex">
                    <div class="col-md-3">
                        Item
                    </div>
                    <div class="col-md-3">
                        Balance
                    </div>
                    <div class="col-md-3">
                        To Consume
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="MiscellaneousItems" *ngFor="let item of ManufactureOrderForm.get('MiscellaneousItems')['controls']; let j = index;">
                        <div class="row f-ss-14px" [formGroupName]="j">
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="MiscellaneousRawMaterialId" disabled>
                            </div>
                            <div class="col-md-3">
                                <input class="form-control" type="text" formControlName="Balance" disabled>
                            </div>
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" appDecimaNumericValidator formControlName="ToConsume" disabled>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <hr class="pb-0 mb-0 pt-0 mt-1">
                    <div class="row d-flex">
                        <div class="col-md-6 font-weight-bold">
                            Total
                        </div>
                        <div class="col-md-5 font-weight-bold ml-3">
                            {{ totalMiscellaneousItemsConsume.toFixed(2) }}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>