<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">VAT/Tax Rate Setup</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="taxRateForm" class="f-ss-14px">

                    <div class="form-group  col-md-12">
                        <label for="Name" style="font-weight: 600;">Rate Name<span
                            class="required-sign">*</span></label>
                        <input type="text" class="form-control form-input" formControlName="Name" required>
                    </div>

                        <div class="form-group  col-md-12">
                            <label for="RatePercent" style="font-weight: 600;">Rate %<span
                                class="required-sign">*</span> </label>
                            <input type="text" appDecimaNumericValidator required class="form-control form-input" formControlName="RatePercent">
                        </div>
                </form>
            </div>
            <div class="col-md-12">

                <button type="button" [disabled]="taxRateForm.invalid" (click)="saveTaxRate()"
                    class="eCount-def-btn  ml-4 p-save-button">Save</button>

            </div>
        </div>
    </div>
</div>