<app-auth-header></app-auth-header>
<!-- Page content -->
<div class="page-content">
  <!-- Main content -->

  <div class="content-wrapper">
    <div class="login">
      <h1
        style="
          text-align: center;
          margin-bottom: 20px;
          color: #2f353e;
          font-family: Rubik, sans-serif;
          font-weight: bold;
        "
      >
        Welcome To
      </h1>
      <img
        src="../../../../assets//image/IQuidi.png"
        alt=""
        class="logoSignIn"
      />

      <form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()">
        <label class="form-group inputField">
          <input
            class="form-control form-input"
            placeholder=" "
            formControlName="Email"
            class="form-control"
          />
          <span>{{ 'AUTH.SIGNIN.emailorPhone' | translate }}</span>
        </label>
        <label class="form-group inputField">
          <input
            class="form-control form-input"
            placeholder=" "
            type="password"
            formControlName="Password"
            class="form-control"
            maxlength="20"
          />
          <span style="margin-left: 4px;">
            {{ 'AUTH.SIGNIN.password' | translate }}
          </span>
        </label>
        <button
          type="submit"
          class="btn btn-primary inputField buttonStyle"
          style="margin-top: 20px;"
        >
          {{ 'AUTH.signIn' | translate }}
        </button>
        <div style="padding-top: 10px;">
          <div>
            <a [routerLink]="['/auth/forgot']" class="newStyle pl-4 mgl">
              {{ 'AUTH.SIGNIN.forgotPassword' | translate }}
            </a>

            <a
              [routerLink]="['/auth/register']"
              style="padding-left: 35px;"
              class="newStyle"
            >
              {{ 'AUTH.createNew' | translate }}
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Main content -->
</div>
<app-service-loader></app-service-loader>
<app-auth-footer></app-auth-footer>
