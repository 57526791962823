import { Component, OnInit, NgZone } from '@angular/core';
import { SubscriptionService } from '../../../service/Subscription/subscription.service';
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router , ActivatedRoute} from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MessageManagerService} from '../../../service/MessageManager/messagemanager.service';
import { AuthService} from '../../../service/auth/auth.service';
import { FormGroup, FormControl, Validators , FormBuilder} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Stepper from 'bs-stepper';
import { SubscriptionPlanBusinessTypeModalComponent } from '../subscription-plan-business-type-modal/subscription-plan-business-type-modal.component';
import { CouponsserviceService } from 'src/app/service/Coupons/couponsservice.service';
import { Coupons } from 'src/app/models/coupons.model';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,
    private router: Router,
    private dataService: DataService,
    private modalService: NgbModal,
    private _ngZone: NgZone,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private subscriptionService: SubscriptionService,
    private _formBuilder: FormBuilder,
    private messageManagerService: MessageManagerService,
    private authService: AuthService,
    private toaster: ToastrService,  
    private couponService: CouponsserviceService
    ) { }
  PackageList: any = [];
  BankInfo: any = [];
  isSamePackage = false;
  subscriptionInfo: any;
  BusinessTypeId = 0;
  BillDetails: any={};
  selectdValue: number = 0;
  ShowBank: boolean = false;
  ShowTransferDetails: boolean = true;
  showPayment: boolean = false;
  showPersonDetails: boolean = false;
  fileToUpload: File = null;
  formData: any;
  private stepper: Stepper;
  Today: Date = new Date();
  ExpiryDate: number;
  planId: number;
  showDefaultPackage = true;
  activeStatus: number = 1;
  Data: any = {};
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isOptional = false;
  activeStatusId: number=1;
  contentFirst: boolean =true;
  contentScnd: boolean =true;
  filterType = 0;
  userInfo = false;
  companyName: string;
  packageName: string;
  packageId: number;
  TotalUser: number;
  packagePrice: number;
  TotalAmount = 0;
  businessTypeList: any[];
  basicPriceList: any[] = [];
  professionalPriceList: any[] = [];
  enterPrisePriceList: any[] = [];
  steps: number;
  featurelist: any[];
  businessTypeId: any;

  serviceUser: number;
  professionalUser: number;
  enterpriseUser: number;
  couponData: Coupons;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.steps = params.step ===  undefined ? 0 : 1;
      this.hideSidebar(this.steps);

    });
    this.getAllPackageList();
    this.getDepositBank();
    this.initialiseOrganization();
  }

  hideSidebar = (type) => {
    if (type == 1)
    {
      this.dataService.emitSidebarHide(false);
    }
  }
  backToPreviousPage = () => {

    this.steps === 0 ?  this.router.navigate(['/settings/subscription-list']) :
     this.router.navigate(['/settings/organization'], { queryParams: { step: 2 }  });
  }
  initialiseOrganization(){
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userInfo);
    if (userInfo) {
      this.companyName = userInfo.SelectedCompany.Name;
    }
  }
  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  couponForm = new FormGroup({
    Name: new FormControl('', [Validators.required]),
  });

  onFileChange(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
    this.formData = new FormData();
    this.formData.append('file', this.myForm.get('fileSource').value);
  }

  get f() {
    return this.myForm.controls;
  }

  getAllPackageList = () => {

    this.spinner.show();
    this.PackageList = [];
    this.subscriptionService.GetSubscriptionDetails().subscribe((x) => {
      if (x.data.length > 0){
      this.PackageList = x.data;
      this.basicPriceList = x.data.filter(xy => xy.BusinessTypeId === 1);
      this.professionalPriceList = x.data.filter(xy => xy.BusinessTypeId === 2);
      this.enterPrisePriceList = x.data.filter(xy => xy.BusinessTypeId === 3);
      this.serviceUser = this.basicPriceList[0].NumberOfUsers;
      this.professionalUser = this.professionalPriceList[0].NumberOfUsers;
      this.enterpriseUser = this.enterPrisePriceList[0].NumberOfUsers;
      this.businessTypeList = x.BusinessTypeList;
      this.featurelist = x.BusinessFeatureList;
      this.getHistory();
      this.spinner.hide();
      }
    });
  }

  getHistory = () => {
    this.spinner.show();
    this.subscriptionService.GetBillDetails().subscribe((x) => {
      if (x){
      this.BillDetails = x.data;
      this.subscriptionInfo = x.subscription;
      console.log(this.subscriptionInfo);
      this.BillDetails.OrganizationTypeId  = this.BillDetails.OrganizationTypeId <= 6 ? 1 :
      this.BillDetails.OrganizationTypeId <= 9 ? 2 : 3;
      this.filterType = this.subscriptionInfo.ExpiredOrInactive == false && this.subscriptionInfo.PackageTypeName != 'Trial'
      ? this.subscriptionInfo.SubscriptionPlanId :  this.BillDetails.OrganizationTypeId  * 3;
      this.showDefaultPackage = this.subscriptionInfo.ExpiredOrInactive == false && this.subscriptionInfo.PackageTypeName != 'Trial' ?
      true : false;
      const value = this.PackageList.filter(xy => xy.Id === this.filterType);
      this.businessTypeId = this.PackageList.filter(pl => pl.Id === this.filterType)[0].BusinessTypeId;
      this.onItemChange(value[0]);
      this.spinner.hide();
      }
      else
      {
        this.spinner.hide();
      }
    });

  }
  addNewUser = () =>
  {
    this.userInfo = true;

  }
  getDepositBank = () => {
    this.spinner.show();
    this.subscriptionService.GetSubscriptionDepositBankAccount().subscribe((x) => {
      this.BankInfo = x;
      this.spinner.hide();

    });
  }

  showPaymentDetails = (model) => {
    this.BillDetails = model;
    this.showPayment = true;
    this.showPersonDetails = false;
    this.contentScnd=false;
    this.activeStatusId=3;
  }
  onItemChange(value) {
    console.log(value.Id);
    console.log(this.subscriptionInfo.SubscriptionPlanId);
    this.isSamePackage = value.Id != this.subscriptionInfo.SubscriptionPlanId ?
    false : true;
    this.selectdValue = value.Id;
    this.packageId = value.Id;
    this.packageName = value.PackageName;
    this.packagePrice = value.PackagePrice;
    this.BusinessTypeId = value.BusinessTypeId;
    var date = new Date();
    if (value.PackageTypeId == 1) {
      this.ExpiryDate = date.setDate(date.getDate() + 30);

    }
    else if (value.PackageTypeId == 2){
      this.ExpiryDate = date.setDate(date.getDate() + 120);
    }
    else {
      this.ExpiryDate = date.setDate(date.getDate() + 365);
    }

  }
  backToPlan()
  {
     this.setFilterData(1);
  }
  public setFilterData(a){
       this.myForm.reset();
       if(a==2)
       {
          this.activeStatusId=a;
          this.contentScnd=true;
       }
       else if(a==1)
       {
        this.activeStatusId=a;
        this.contentFirst=true;
        this.contentScnd=true;
        this.filterType=this.selectdValue;
       }
       else
       {
          this.activeStatusId=3;
          this.contentFirst=false;
       }
   }
  selectedValue(a) {
    this.filterType = a;
    if (this.filterType == 0) {
      this.toaster.warning('Please select a Plan');
      return;
    }
    else {
      this.filterType = this.selectdValue;
      this.ShowBank = true;
      this.showPersonDetails = true;
      this.contentFirst = false;
      this.activeStatusId = 2;
      this.getPackageName(this.selectdValue);
    }
  }


  onShowTransferMoneyDetails(value) {
    if (value == 3) {

      this.ShowTransferDetails = true;
    }
    else
    {
      this.ShowTransferDetails=false;
    }
  }
  convertDateIntoDisplay(date) {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  getPackageName(id)
  {
     var list = this.PackageList.find(x => x.Id == this.packageId);
     this.packageName = list.PackageName;
     this.packagePrice = this.isSamePackage === false ? list.PackagePrice + this.TotalAmount : this.TotalAmount;
  }

  setData = () => {

    const data = {
      SubscriptionPlanId: this.selectdValue,
      BillToName: this.BillDetails.BillToName,
      BillToEmail: this.BillDetails.BillToEmail,
      PhoneNo: this.BillDetails.PhoneNo,
      Coupons: this.couponData,
      AdditionalUser: this.BusinessTypeId === 2  ?  (this.TotalUser ?? 0) + this.professionalUser : this.BusinessTypeId === 3 ?
       (this.TotalUser ?? 0) + this.enterpriseUser : (this.TotalUser ?? 0) + this.serviceUser
    };
    return data;
  }
  confirmPayment = () => {
    const BillDetails = this.setData();
    if (this.myForm.invalid)
    {
      this.toaster.warning('Please attach receipt document !');
      return ;
    }
    this.spinner.show();
    this.subscriptionService.saveSubscription(BillDetails).subscribe((x) => {
      if (x.Success) {
        this.subscriptionService.saveAttachment(this.formData).subscribe((xy) => {
          if (xy.Success) {
            this.spinner.hide();
            this.toaster.success('Subscription payment has been done successfully.');
            if (this.steps === 1 )
            {
              this.UpdateStep();
            }
            else
            {
              this.getMessage();
              this.router.navigate(['/']);
            }
          }
        });
      }

    });
  }

  public DeductCouponUseLimit(){
    if(this.couponForm.valid){
      this.couponService.deductCouponUseLimit(this.couponForm.value).subscribe(p => {
        if(p.Success){
          this.spinner.hide();
        }else{

        }
      })
    }
  }

  public UpdateStep() {
    this.spinner.show();
    this.messageManagerService.StepDone().subscribe(x => {
      this.spinner.hide();
      this.getMessage();
      this.router.navigate(['/accounting/bank-accounts'], { queryParams: { step: 1 }  });
      if (x.AllDone){
        const userInfo = this.authService.getUserInfo();
        userInfo.CompanyList = x.Data;
        this.authService.setUserInfo(JSON.stringify(userInfo));
      }
    });
  }
  private getMessage(){
    this.messageManagerService.GetMessage().subscribe(x => {

      this.dataService.emitSetMessageUpdate(x);
  });
  }
  getTotalAmount(value: number) {
    if(value<0){
      this.toaster.error("User number can't be negative.");
      this.TotalUser = 0;
      return;
    }
    this.selectdValue = this.packageId;
    const data = this.PackageList.filter(x => x.Id === this.packageId);
    if (data && this.isSamePackage == false)
    {
      this.TotalAmount = value * data[0].PackagePrice;
      this.packagePrice += this.TotalAmount;
    }
    else if (data && this.isSamePackage == true)
    {
      this.TotalAmount = value * data[0].PackagePrice;
      this.packagePrice = this.TotalAmount;
    }
  }

  isBasicPlanDisabled() {
    if(this.businessTypeId != 1) {
      return true;
    }
    else{
      return false;
    }
  }

  isProfessionalPlanDisabled() {
    if(this.businessTypeId != 2) {
      return true;
    }
    else{
      return false;
    }
  }

  isEnterPrisePlanDisabled() {
    if(this.businessTypeId != 3) {
      return true;
    }
    else{
      return false;
    }
  }

  gotoBusinessTypeDetailsModal(type) {
    const modalRef = this.modalService.open(SubscriptionPlanBusinessTypeModalComponent, { size: "sm", backdrop: "static", keyboard: false, centered: true });
    modalRef.componentInstance.subscriptionPlanBusinessType = type;
    modalRef.result.then((result) => {}, (reason) => {console.log(reason)});
  }

  applyCoupon(){
    if(this.couponForm.invalid){
      this.toaster.warning("Please provide a code!");
      return;
    }
    var couponData: Coupons = {
      Id: 0,
      Name: this.couponForm.value.Name,
      UseLimit: 0,
      ExpiryDate: new Date(),
      DiscountAmount: 0,
      CreatedDate: new Date(),
      TotalUsed: 0
    }
    this.spinner.show();
    this.couponService.verifyAndGetCouponInformation(couponData).subscribe(x => {
      this.spinner.hide();
      if(x.Result.Success){
        this.toaster.success("Coupon Applied Successfully!");
        this.couponData = x.CouponData;
      }else{
        this.toaster.error(x.Result.Message);
      }
    });
  }

}
