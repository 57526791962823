import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { WareHouse, WareHouseItems, WareHouseShippedData } from "src/app/models/inventoryOverview.model";
import { CreateItem } from "src/app/models/product.model";
import { InventoryOverviewService } from "src/app/service/InventoryOverview/inventory-overview.service";
import { ModalForCreateBillComponent } from "../modal-for-create-bill/modal-for-create-bill.component";
import { ModalForReceiveProductComponent } from "../modal-for-receive-product/modal-for-receive-product.component";
import { WareHouseOperationType } from "src/app/enum/wareHouseOperationType";
import { ReturnModalComponent } from "../return-modal/return-modal.component";

@Component({
  selector: "app-receipt-waiting",
  templateUrl: "./receipt-waiting.component.html",
  styleUrls: ["./receipt-waiting.component.css"],
})
export class ReceiptWaitingComponent implements OnInit {

  itemListTitle: string = "";
  public aModel: CreateItem = new CreateItem();
  itemTypeId: number = 1;
  totalFile: number = 0;
  isEditable: boolean = false;
  canCreateBill: boolean = true;
  isBillCreated: boolean = false;
  isavailable: boolean = false;
  WareHouseData: any = [];
  WareHouseItem: any = [];
  PurchaseOrderData: any = [];
  BillData: any = [];
  SalesOrderData: any = [];
  InvoiceData: any = [];
  allData: any = [];
  WareHouseItems: any = [];
  billForm: FormGroup;
  wareHouseShippedData: WareHouseShippedData;
  wareHouseItems: WareHouseItems;
  wareHouse: WareHouse;
  modalFlag: boolean = false;
  ReceiveBtnFlag: boolean = false;
  RedirectTo: string;
  planId = 0;
  OrderList: any = [];
  grilcol;
  private gridApi;
  domLayout = "autoHeight";
  isItemListUpdate = false;
  searchText: string;
  pageValue: number;
  WareHouseNo: any;
  receiveValue: any;
  DemandValue: any;
  ShippedFormArray: FormArray;
  wareHouseProductNo: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private deliveryOrderService: InventoryOverviewService,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((x) => {
      this.pageValue = +x.get("id");
      this.initializeForm();
      this.pageLoad();
    });
  }

  public initializeForm() {
    this.billForm = this.fb.group({
      Product: new FormControl(""),
      Quantity: new FormControl(""),
      Received: new FormControl(""),
      ShippedFormArray: this.fb.array([]),
    });
  }

  public addItem(x: any): void {
    this.ShippedFormArray = this.billForm.get("ShippedFormArray") as FormArray;
    let data = this.createItem(x);
    this.ShippedFormArray.push(data);
  }

  public createItem(x: any): FormGroup {
    return this.fb.group({
      Product: [x.ItemDetails.ItemName],
      ProductId: [x.ItemDetails.Id],
      Quantity: [x.Demand],
      Received: [x.ProductInOut],
    });
  }

  public pageLoad() {
    this.deliveryOrderService.GetWareHouseData(this.pageValue).subscribe((x) => {
        this.allData = x;
        this.wareHouseProductNo = this.allData.WareHouseData.WareHouseProductNo;;
        let status = this.getWareHouseStatus(this.allData.WareHouseData.StatusId);
        this.itemListTitle = this.setPageTitle(this.allData.WareHouseData.OperationType,status);
        this.WareHouseData = x.WareHouseData;
        this.WareHouseItem = x.WareHouseItem;
        this.WareHouseItems = x.WareHouseItems;
        this.PurchaseOrderData = x.PurchaseOrderData;
        this.BillData = x.BillData;
        this.SalesOrderData = x.SalesOrderData;
        this.InvoiceData = x.InvoiceData;
        this.reDirectLink();
        this.WareHouseItems.forEach((item) => {
          this.addItem(item);
        });
      });
  }

  setPageTitle(operationType: number, status: string) {
    if (operationType == WareHouseOperationType.SoldItemReturnedForSalesOrder || operationType == WareHouseOperationType.SoldItemReturnedForInvoice
    )
      return "WH-RET-" + this.allData.WareHouseData.WareHouseProductNo + status;
    else
      return "WH-IN-" + this.allData.WareHouseData.WareHouseProductNo + status;
  }

  public getWareHouseStatus(statusId) {
    switch (statusId) {
      case 1:
        return "/Waiting";
      case 2:
        return "/Received";
      case 3:
        return "/Ready";
    }
  }

  public getReferenceNo() {
    switch (this.allData?.WareHouseData?.OperationType) {
      case WareHouseOperationType.PurchaseOrder:
        return "PO-" + this.allData?.WareHouseData?.ReferenceNo;
      case WareHouseOperationType.Bill:
        return "Bill-" + this.allData?.WareHouseData?.ReferenceNo;
      case WareHouseOperationType.SalesOrder:
        return "SO-" + this.allData?.WareHouseData?.ReferenceNo;
      case WareHouseOperationType.Invoice:
        return "INV-" + this.allData?.WareHouseData?.ReferenceNo;
      case WareHouseOperationType.SoldItemReturnedForSalesOrder:
        if(this.allData?.WareHouseParentData.OperationType != WareHouseOperationType.SoldItemReturnedForSalesOrder)
          return "WH-Out-"+this.allData?.WareHouseData?.ParentId;
        else
          return "WH-RET-"+this.allData?.WareHouseData?.ParentId;
      case WareHouseOperationType.SoldItemReturnedForInvoice:
        if(this.allData?.WareHouseParentData.OperationType != WareHouseOperationType.SoldItemReturnedForInvoice)
          return "WH-Out-"+this.allData?.WareHouseData?.ParentId;
        else
          return "WH-RET-"+this.allData?.WareHouseData?.ParentId;
    }
  }

  public getWareHouseBackOrderReferenceNo() {
    switch (this.allData?.WareHouseData?.OperationType) {
      case WareHouseOperationType.PurchaseOrder:
        return "WH-In-" + this.allData?.WareHouseData?.ParentId;
      case WareHouseOperationType.Bill:
        return "WH-In-" + this.allData?.WareHouseData?.ParentId;
      case WareHouseOperationType.SalesOrder:
        return "WH-Out-" + this.allData?.WareHouseData?.ParentId;
      case WareHouseOperationType.Invoice:
        return "WH-Out-" + this.allData?.WareHouseData?.ParentId;
    }
  }

  public getReferenceNoLink() {
    switch (this.allData?.WareHouseData?.OperationType) {
      case WareHouseOperationType.PurchaseOrder:
        return ("#/procurement/purchase-order-details/" +this.allData?.PurchaseOrderData?.Id);
      case WareHouseOperationType.Bill:
        return ("#/expense/bill/Bill-" + this.allData?.WareHouseData?.ReferenceNo);
      case WareHouseOperationType.SalesOrder:
        return ("#/sales/sales-order-details/" + this.allData?.SalesOrderData?.Id);
      case WareHouseOperationType.Invoice:
        return ("#/income/invoice/INV-" + this.allData?.WareHouseData?.ReferenceNo);
      case WareHouseOperationType.SoldItemReturnedForSalesOrder:
        if(this.allData?.WareHouseParentData.OperationType != WareHouseOperationType.SoldItemReturnedForSalesOrder)
          return "#/inventory/delivery-order-waiting/"+this.allData?.WareHouseParentData?.Id;
        else
          return "#/inventory/receipt-waiting/"+this.allData?.WareHouseParentData?.Id;
      case WareHouseOperationType.SoldItemReturnedForInvoice:
        if(this.allData?.WareHouseParentData.OperationType != WareHouseOperationType.SoldItemReturnedForInvoice)
          return "#/inventory/delivery-order-waiting/"+this.allData?.WareHouseParentData?.Id;
        else
          return "#/inventory/receipt-waiting/"+this.allData?.WareHouseParentData?.Id;
    }
  }

  public getWareHouseBackOrderReferenceNoLink() {
    switch (this.allData?.WareHouseParentData?.OperationType) {
      case WareHouseOperationType.PurchaseOrder:
        return ("#/inventory/receipt-waiting/" + this.allData?.WareHouseParentData?.Id);
      case WareHouseOperationType.Bill:
        return ("#/inventory/receipt-waiting/" + this.allData?.WareHouseParentData?.Id);
      case WareHouseOperationType.SalesOrder:
        return ("#/inventory/delivery-order-waiting/" +this.allData?.WareHouseParentData?.Id);
      case WareHouseOperationType.Invoice:
        return ("#/inventory/delivery-order-waiting/" +this.allData?.WareHouseParentData?.Id);
    }
  }

  reDirectLink() {
    switch(this.allData?.WareHouseData?.OperationType) {
      case WareHouseOperationType.PurchaseOrder:
        this.RedirectTo ="procurement/purchase-order-receipt-list/" + this.allData?.PurchaseOrderData?.Id + "/" + this.allData?.PurchaseOrderData?.OrderNo + "/All";
        break;
      case WareHouseOperationType.Bill:
        this.RedirectTo ="expense/bill-receipt-list/" + this.allData?.BillData?.BillNo + "/All";
        break;
      case WareHouseOperationType.SoldItemReturnedForSalesOrder:
        this.RedirectTo ="sales/sales-order-delivery-list/" + this.allData?.SalesOrderData?.Id + "/All";
        break;
      case WareHouseOperationType.SoldItemReturnedForInvoice:
        this.RedirectTo ="income/invoice-delivery-order-list/" + this.allData?.InvoiceData?.InvoicceNo + "/All";
        break;
      default:
        this.RedirectTo = "inventory/inventory-overview-receipt/All";
        break;
    }
  }

  public openCreateBasicBill() {
    const modalRef = this.modalService.open(ModalForCreateBillComponent, {
      size: "md",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });

    modalRef.componentInstance.fromParent = 1;
  }
  public ReceiveProduct() {
    this.isavailable = true;
    //this.itemListTitle = "WH-In-" + this.allData.WareHouseData.WareHouseProductNo + "/Received";
    this.ShippedFormArray = this.billForm.get("ShippedFormArray") as FormArray;
    let modalFlag = false,
    i = 0;

    if(this.allData.WareHouseData.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice || this.allData.WareHouseData.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder) {
      let isExcessReturn = this.ShippedFormArray.value.some(x => x.Quantity < Number(x.Received))
      if(isExcessReturn) {
        this.toaster.warning("You can not received more.");
        return;
      }
    }
    
    let totalReceived = 0;
    this.ShippedFormArray.controls.forEach((item) => {
      totalReceived = totalReceived + Number(item.value.Received);
      this.allData.WareHouseItems[i].ProductInOut = item.value.Received;
      if (item.value.Received < item.value.Quantity) {
        modalFlag = true;
      }
      i++;
    });

    if (totalReceived > 0) {
      if (modalFlag) {
        const modalRef = this.modalService.open( ModalForReceiveProductComponent, { size: "lg", backdrop: "static", keyboard: false, centered: true });
        let data = { allDATA: this.allData };
        modalRef.componentInstance.fromParent = data;
        modalRef.result.then(
          (result) => {console.log("Modal Result", result);},
          (reason) => {}
        );
      } else {
        this.activeModal.close();
        this.allData.WareHouseShippedType = 0;
        this.spinner.show();
        var data = {
          WareHouseData: this.allData.WareHouseData,
          WareHouseItemsData: this.allData.WareHouseItems,
          WareHouseShippedType: this.allData.WareHouseShippedType,
        };
        this.deliveryOrderService.sendReceiveData(data).subscribe((x) => {
          if (x.Success) {
            this.ReceiveBtnFlag = true;
            this.toaster.success("Product Received Successfully!");
            this.spinner.hide();
            this.initializeForm();
            this.pageLoad();
          } else {
            this.toaster.warning("Something Went Wrong!");
            this.spinner.hide();
          }
        });
      }
    } else {
      this.toaster.warning("Please fill up at least one item received field");
    }
  }

  convertDateIntoDisplay = (date) => {
    if (date == undefined || date == null) {
      return "";
    } else {
      let dateString = moment(date).format("Do MMM YYYY");
      return dateString;
    }
  };

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  };
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }
  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }
  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }

  setMainTitle(status: string) {
    if (this.allData.WareHouseData.OperationType == 1) {
      return ("PO/Receipts/WH-In" +this.allData.WareHouseData.WareHouseProductNo + status);
    } else {
      return "WH-In-" + this.allData.WareHouseData.WareHouseProductNo + status;
    }
  }

  returnProduct() {
    const modalRef = this.modalService.open(ReturnModalComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered: true});

    let data={
      wareHouseNo:'WH No. '+this.wareHouseProductNo,
      wareHouseRef:this.wareHouseProductNo,
      allData:this.allData,
      WareHouseData : this.allData.WareHouseData,
      WareHouseItemsData:this.allData.WareHouseItems,
      WareHouseShippedType:this.allData.WareHouseShippedType
    }
        modalRef.componentInstance.WareHouseOutData = data;
        modalRef.result.then((result) => {
          if(result != 'Cross click') {
            let url = '';
            if(this.allData?.SalesOrderData != null)
              url = 'sales/sales-order-delivery-list/' + this.allData?.SalesOrderData?.Id + '/All';
            else
              url = 'income/invoice-delivery-order-list/' + this.allData.InvoiceData.InvoicceNo + '/All';
            this.router.navigate([url]);
          }
         }, (reason) => {  });
  }

  CancelItemReturned() {
    this.spinner.show();
    this.deliveryOrderService.CancelItemReturned(this.allData.WareHouseData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Cancel Successfully');
        this.spinner.hide();
        let url = '';
        if(this.allData.InvoiceData != null)
          url = 'income/invoice-delivery-order-list/' + this.allData.InvoiceData.InvoicceNo + '/All';
        if(this.allData.SalesOrderData != null)
          url = 'sales/sales-order-delivery-list/' + this.allData?.SalesOrderData?.Id + '/All';
        this.router.navigate([url]);
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
}

  onFocusEvent(event: any) {
    event.target.value = event.target.value == 0 ? null : event.target.value;
  }

  canShowDropdownButton() {
    return this.allData?.WareHouseData?.StatusId != 2 && (this.allData?.WareHouseData?.OperationType == 5 || this.allData?.WareHouseData?.OperationType == 6 || this.allData?.WareHouseData?.OperationType == 7 || this.allData?.WareHouseData?.OperationType == 8)
  }

}
