import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FixedAssetItem } from 'src/app/models/fixedAsset.model';
import { FixedAssetDisposalViewModel } from 'src/app/models-viewModels/fixedAssetDisposalViewModel.model';
import { ResponseModel } from 'src/app/models/responseModel.model';

@Injectable({
  providedIn: 'root'
})
export class FixedAssetService {
  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  public GetAssetTypeInputHelp(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetType/GetInputHelp').pipe(retry(1));
  }

  public GetExpenseAccounts(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'ChartOfAccount/GetChartOfAccounts').pipe(retry(1));
  }

  public SaveFixedAssetType(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'FixedAssetType/save', data).pipe(retry(1));
  }

  public GetFixedAssetTypeList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetType/GetAll').pipe(retry(1));
  }

  public GetAssetTypeDetails(id:number): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetType/GetDetails?id='+id).pipe(retry(1));
  }

  public SaveFixedAssetStartDate(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'FixedAssetSetting/Save', data).pipe(retry(1));
  }

  public GetStartDate(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetSetting/Get').pipe(retry(1));
  }

  public SaveFixedAssetItem(data:any,s:number): Observable<any> {
    return this.http.post<any>(this.baseurl + 'FixedAssetItem/save?assetStatus='+s, data).pipe(retry(1));
  }

  public GetFixedAssetItemList(assetStatus:number): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItem/GetAll?assetStatus='+assetStatus).pipe(retry(1));
  }

  public GetAssetItemDetails(id:number): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItem/GetDetails?id='+id).pipe(retry(1));
  }

  public GetDepreciationDate(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/GetDepreciationDate').pipe(retry(1));
  }

  public GetDepreciationDateList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/GetDepreciationDateList').pipe(retry(1));
  }

  public GetDepreciateableItems(fromDate:string,toDate:string): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/GetDepreciateableItems?fromDate='+fromDate+"&toDate="+toDate).pipe(retry(1));
  }

  public RunDepreciation(fromDate:string,toDate:string): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/RunDepreciation?fromDate='+fromDate+"&toDate="+toDate).pipe(retry(1));
  }

  public GetDepreciationDetails(assetId:number): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/GetDepreciationDetails?assetId='+assetId).pipe(retry(1));
  }

  public GetAssetAssigneeData(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/GetAssetAssigneeData').pipe(retry(1));
  }

  public GetDisposalAccount(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/GetDisposalAccount').pipe(retry(1));
  }

  public GetAllAssetTypes(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetSetting/GetAllAssetTypes').pipe(retry(1));
  }

  public GetFixedAssetItemsList(): Observable<FixedAssetItem[]> {
    return this.http.get<FixedAssetItem[]>(this.baseurl + 'FixedAssetItem/GetFixedAssetItemsList').pipe(retry(1));
  }

  public GetDisposeCalculations(data: FixedAssetDisposalViewModel): Observable<FixedAssetDisposalViewModel> {
    return this.http.post<FixedAssetDisposalViewModel>(this.baseurl + 'FixedAssetDisposal/GetDisposeCalculations', data).pipe(retry(1));
  }

  public GetSingleFixedAssetDepreciationDate(fixedAssetItemId: number): Observable<any> {
    return this.http.get<any>(this.baseurl + `FixedAssetItems/GetSingleFixedAssetDepreciationDate?fixedAssetItemId=${fixedAssetItemId}`).pipe(retry(1));
  }

  public UpdateDepreciation(data: FixedAssetItem): Observable<ResponseModel> {
    return this.http.post<any>(this.baseurl + `FixedAssetItems/UpdateDepreciation`, data).pipe(retry(1));
  }

  public ReverseDepreciation(data: FixedAssetItem): Observable<ResponseModel> {
    return this.http.post<any>(this.baseurl + `FixedAssetItems/ReverseDepreciation`, data).pipe(retry(1));
  }

  public GetRollBackDepreciationDate(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/GetRollBackDepreciationDate').pipe(retry(1));
  }

  public ReverseDepreciationAll(fromDate:string): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItems/ReverseDepreciationAll?fromDate='+fromDate).pipe(retry(1));
  }

  public DeleteRegisteredFixedAssetItem(fixedAssetItemId: number): Observable<any>{
    return this.http.delete<any>(this.baseurl + 'FixedAssetItem/DeleteRegisteredFixedAssetItem?fixedAssetItemId='+fixedAssetItemId).pipe(retry(1));
  }

  public DeleteDraftFixedAssetItem(fixedAssetItemId: number): Observable<any>{
    return this.http.delete<any>(this.baseurl + 'FixedAssetItem/DeleteDraftFixedAssetItem?fixedAssetItemId='+fixedAssetItemId).pipe(retry(1));
  }

  public GetLastFixedAssetItemNumber(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'FixedAssetItem/GetLastFixedAssetItemNumber').pipe(retry(1));
  }

}
