import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  baseurl = environment.baseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private http: HttpClient) { }

  httpAttachmentOptions: any = {
    reportProgress: true, observe: 'events', headers: new HttpHeaders({
      'Authorization': `Bearer ${this.userInfo ? this.userInfo.Token : null}`
    })
  }
  
  GetSupplierList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Contact/GetAllSupplier').pipe(retry(1));
  }

  GetPurchaseableItems(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetPurchaseableItems').pipe(retry(1));
  }

  Save(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'PurchaseOrder/Save', data).pipe(retry(1));
  }
  SaveAsBilled(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'PurchaseOrder/SaveAsBilled', data).pipe(retry(1));
  }
  Approve(data): Observable<any> {
    return this.http.post<any>(this.baseurl + 'PurchaseOrder/Approve', data).pipe(retry(1));
  }
  Update(data): Observable<any>{
    return this.http.post<any>(this.baseurl + 'PurchaseOrder/Update', data).pipe(retry(1));
  }
  GetOrderList(): Observable < any > {
    return this.http.get<any>(this.baseurl + 'PurchaseOrder/GetOrderList').pipe(retry(1));
  }
  GeneratePurchaseNo(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'PurchaseOrder/GeneratePurchaseNo')
      .pipe(
        retry(1)
      );
  }
  GetOrderDetails(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `PurchaseOrder/GetOrderDetails?id=${data}`).pipe(retry(1));
  }

  GetOrderDetailsByOrderNo(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `PurchaseOrder/GetOrderDetailsByOrderNo?orderNo=${data}`).pipe(retry(1));
  }


  GetPurchaseOrderDetailsByOrderNo(OrderNo): Observable<any> {
    return this.http.get<any>(this.baseurl + `PurchaseOrder/GetPurchaseOrderDetails_By_OrderNo?OrderNo=${OrderNo}`).pipe(retry(1));
  }
  deletePurchaseOrder(orderId): Observable<any> {console.log(orderId)
    return this.http.get<any>(this.baseurl + `PurchaseOrder/DeletePurchaseOrder?id=${orderId}`).pipe(retry(1));
  }

  MarkAsComplete(data): Observable<any> {
    return this.http.post<any>(this.baseurl + `PurchaseOrder/MarkAsComplete`, data).pipe(retry(1));
  }

  public SendMailWithAttachment(data:any): Observable<any> {
    return this.http.post(this.baseurl+'PurchaseOrder/SendMailWithAttachment', data, this.httpAttachmentOptions).pipe(
      retry(1)
    )
  }
  public SendMail(data:any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'PurchaseOrder/SendMail', data).pipe(retry(1));
  }

  public GetMailDetails(id:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'PurchaseOrder/GetMailDetails?id='+id).pipe(retry(1));
  }

  public GetReceiptsForPurchaseOrder(id:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'PurchaseOrder/GetReceiptsForPurchaseOrder?id='+id).pipe(retry(1));
  }

  public GetBillDetailsForPurchaseOrder(id:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'PurchaseOrder/GetBillDetailsForPurchaseOrder?id='+id).pipe(retry(1));
  }

  public GetOrderDetails_ForCreateBill_FromPurchaseOrder(id:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'PurchaseOrder/GetOrderDetails_ForCreateBill_FromPurchaseOrder?id='+id).pipe(retry(1));
  }

  /* Major Refactor Code */ 
  Create(purchaseOrder): Observable<any> {
    return this.http.post<any>(this.baseurl + 'PurchaseOrder/Create', purchaseOrder);
  }

  StatusUpdate(purchaseOrder): Observable<any> {
    return this.http.post<any>(this.baseurl + 'PurchaseOrder/StatusUpdate', purchaseOrder);
  }

  UpdatePurchaseOrder(purchaseOrder): Observable<any> {
    return this.http.post<any>(this.baseurl + 'PurchaseOrder/UpdatePurchaseOrder', purchaseOrder);
  }
  

}
