
<app-dashboard>
    <app-page-header-title
        mainTitle="Advance"
        subTitle="Home"
        backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox searchWidth">
            <button type="button" (click)="openAdvancePopup()" class="eCount-def-btn ml-4">New Advance</button>
            <button (click)="onBtnExport()" class="eCount-button3 ml-4">Export</button>
        </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item ">
            <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('All')" [routerLinkActive]="['active']" routerLink="/accounting/advance/advance-list/All">All</a>
          </li>
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('Active')" [routerLinkActive]="['active']" routerLink="/accounting/advance/advance-list/Active">Active</a>
        </li>
        <li class="nav-item">
          <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData('Old')"   [routerLinkActive]="['active']" routerLink="/accounting/advance/advance-list/Old">Old</a>
        </li>
    </ul>
    <div class="headSection mt-4">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                [domLayout]="domLayout"
                class="ag-theme-balham col"
                [rowData]="datas"
                [columnDefs]="columnDefs"
                (rowClicked)="onRowClicked($event)"
                (gridReady)="onGridReady($event)"
                [defaultColDef]="defaultColDef"
            ></ag-grid-angular>
        </div>
    </div>
</app-dashboard>