import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MainServiceService } from '../../../service/Main/main-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
@Component({
  selector: 'app-delivery-partner-list',
  templateUrl: './delivery-partner-list.component.html',
  styleUrls: ['./delivery-partner-list.component.css']
})
export class DeliveryPartnerListComponent implements OnInit {

  constructor(public fb: FormBuilder, private router: Router, private toaster: ToastrService,
              private mainService: MainServiceService, private spinner: NgxSpinnerService) { }
              columnDefs = [

                { headerName: 'Delivery Partner', field: 'CompanyName', cellStyle: {color: 'blue', cursor: 'pointer'}},
                { headerName: 'Created Date', field: 'CreatedAt', valueFormatter: this.convertGridDateIntoDisplay },
                { headerName: 'Email', field: 'Email' },
                { headerName: 'Contact No', field: 'ContactNo' },

                { headerName: 'Action', suppressMenu: true, suppressSorting: true, template: `<span  (click)="editItems()" class=""><img style="height: 20px;" src="../../assets/icons/download.png"></span>` },
              ];

              domLayout = 'autoHeight';
              defaultColDef = {
                resizable: true,
                flex: 1,
                cellClass: 'cell-wrap-text',
              };
  dpForm: FormGroup;
  private gridApi;
  private gridColumnApi;
  contactList: any = [];
  ngOnInit(): void {
    this.initDpForm();
  }
  initDpForm = () => {
    this.dpForm = this.fb.group({
      CompanyName: ['', Validators.required],
      Email : [''],
      Website : [''],
      ContactNo : [''],
      IsDeliveryPartner : [true],
      PrimaryPersonFirstName: []
    });
  }

  save = () => {
    this.spinner.show();
    if (this.dpForm.invalid)
    {
      this.toaster.warning('please fill all the required field!');
      return;
    }
    this.dpForm.get('PrimaryPersonFirstName').patchValue(this.dpForm.value.CompanyName);
    this.mainService.saveContact(this.dpForm.value).subscribe((x) => {
      if (x.Success) {
        this.spinner.hide();

        this.getData();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.spinner.hide();
    });
  }
  onGridReady(event) {
    this.gridApi = event.api;
    this.gridApi.setHeaderHeight(35);
    this.gridColumnApi = event.columnApi;
    this.getData();
  }
  getData = () => {
    this.contactList = [];

    this.mainService.GetAllDeliveryPartner().subscribe((x) => {

      this.spinner.hide();
      this.contactList = [...x, ...this.contactList];
    }, (err) => {
      this.spinner.hide();
    });
  }
  onRowClicked(e) {
    const colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;

  }
  convertGridDateIntoDisplay(date) {
    if (date.value != null){
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
    }
  }
}
