<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle">Update Quantity</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="UpdateForm" class="f-ss-14px">
                    <div class="row f-ss-14px">
                        <div class="col-md-12 ptb">
                            <label for="">Update Quantity On Hand<span class="required-sign text-red">*</span></label>
                            <input class="form-control" type="number" formControlName="Quantity">
                        </div>
                    </div>
                    <div class="full-width text-align-right">
                        <button type="button" class="eCount-def-btn mt-4 blueBg" (click)="save()">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>