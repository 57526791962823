import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductServiceService } from '../../../service/Products/product-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderService } from 'src/app/service/PurchaseOrder/purchase-order.service';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
@Component({
  selector: 'app-storage-item-create',
  templateUrl: './storage-item-create.component.html',
  styleUrls: ['./storage-item-create.component.css']
})
export class StorageItemCreateComponent implements OnInit {
  itemForm: FormGroup;
  @Input() fromParent: any;
  itemId: number;
  supplierList: any = [];
  loading = false;

  constructor(private spinner: NgxSpinnerService,
              public fb: FormBuilder,
              private toaster: ToastrService,
              public activeModal: NgbActiveModal,
              private productServiceService: ProductServiceService,
              private purchaseOrderService: PurchaseOrderService,
              private mainService: MainServiceService) { }

  ngOnInit(): void {
    this.itemId = this.fromParent.Id;
    this.getAllSupplier();
    this.initializeItemForm();
  }

  initializeItemForm() {
    this.itemForm = this.fb.group({
      Id: [this.fromParent.Id],
      ItemCode: [this.fromParent.ItemCode, Validators.required],
      ItemName: [this.fromParent.ItemName, Validators.required],
      PurchasePrice: [this.fromParent.PurchasePrice],
      SaleUnitPrice: [this.fromParent.SaleUnitPrice],
      ItemTypeId: [this.fromParent.ItemTypeId],
      Description: [this.fromParent.PurchaseDescription],
      SupplierId: [this.fromParent.SupplierId]
    });
  }

  public saveOrUpdate() {
    console.log(this.itemForm.value);
    if (this.itemForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    this.spinner.show();
    this.productServiceService.saveStorageItemData(this.itemForm.value).subscribe((x) => {
      if (x.Success){
        this.toaster.success(this.fromParent.Id === 0 ? 'Item has been successfully added.' : 'Item updated successfully.');
        this.activeModal.close(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
    }
    copyItemCode = (e) => {
     this.itemForm.patchValue({ItemName: e.target.value });
     this.itemForm.patchValue({Description: e.target.value });
    }

    getAllSupplier = () => {
      this.purchaseOrderService.GetSupplierList().subscribe((x) => {
        this.supplierList = x;
      });
    }

    CreateNewSupplier = (customerName) => {

      let customerData = {
        PrimaryPersonFirstName: customerName,
        CompanyName: customerName,
        IsCustomer: false,
        IsSupplier: true,
      }
      this.loading = true;
  
      this.mainService.saveContact(customerData).subscribe((x) => {
        if (x.Success) {
          this.toaster.success('Contact has been successfully added !');
          this.loading = false;
          this.supplierList.push(x["Data"]);
          let customerObj = this.supplierList.filter(x=>x.PrimaryPersonFirstName == customerName);
          this.itemForm.controls['SupplierId'].patchValue(customerObj[0]['Id']);
          this.getAllSupplier();
        } else {
          this.toaster.warning(x.Message);
        }
      }, err => {
        this.toaster.error('ooh, something went wrong !');
        this.loading = false;
      })
    }
}
