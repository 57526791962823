<app-dashboard>
    <div>
        <app-page-header-title
            mainTitle="PO/ {{PurchaseOrderNo}} / {{billStatus}}"
            subTitle="Purchase Order Overview"
            backURL="/procurement/purchase-order-list/All">

            <div class="full-width text-align-right row ml-5">
                <div class="dropdown col-sm-7 mal ml-5" [ngClass]="{'col-sm-9 col-md-9': isEditable === false}">
                    <button id="attachment_btn" style="z-index: 1;" type="button" class="btn attachment-button"><i id="attachment_btn"
                      style="font-size: 20px !important;" class="material-icons">insert_drive_file</i><span id="attachment_btn"
                      style="color:darkgrey; font-size: 12px;"
                      *ngIf="totalFile>=1">{{totalFile}}</span></button>
                    <div class="x-tip-tl" style="height: 0px">
                      <i id="custom_notch" class="material-icons custom_notch"
                        >arrow_drop_up</i
                      >
                    </div>
                    <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                      <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="5"></app-attachment-uploader>
                  </div>
                  </div>
                    <button *ngIf="isEditable" type="button"  [routerLink]="['/procurement/purchase-order-edit',PurchaseOrderId]"  class="eCount-def-btn ml-2 col-sm-1">Edit</button>
                    <button *ngIf="isEditable" type="button" style="background: #5cac00;" (click)="saveAsApproved()" class="eCount-def-btn ml-2 col-sm-1">Approve</button>
                    <!-- <button *ngIf="canCreateBill && !isBillCreated" type="button" style="background: #5cac00;" (click)="createBill()" class="eCount-def-btn ml-2 col-sm-1">Create Bill</button> -->
                    <button type="button" (click)="print()" class="eCount-button3 ml-2 col-sm-1">Print</button>

                <div *ngIf="PurchaseOrderStatusId != 4" style="padding: 7px 1px; color: #c7c2c2;" >
                    <div class="dropdown">
                        <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                            aria-expanded="false">
                            <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
                        </div>
                        <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                            style="width: 100%;">
                            <div *ngIf="!isEditable && !isBilled()" class="context-menu-item pb-3"  [routerLink]="['/procurement/purchase-order-edit',PurchaseOrderId]">
                                <span class="context-menu-text text-center">Edit</span>
                            </div>
                            <div *ngIf="isDeleted()" class="context-menu-item pb-3"  (click)="deletePurchaseOrder()">
                                <span class="context-menu-text text-center">Delete</span>
                            </div>
                            <!-- <div *ngIf="PurchaseOrderData.canMarkAsComplete && !isBilled() && !(totalPaidAmount > 0)" class="context-menu-item pb-3"  (click)="MarkAsComplete()">
                                <span class="context-menu-text text-center">Mark as complete</span>
                            </div> -->
                            <div *ngIf="canMarkAsCompleted()" class="context-menu-item pb-3"  (click)="MarkAsComplete()">
                                <span class="context-menu-text text-center">Mark as complete</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-page-header-title>
        <div *ngIf="(isApproved() || isBilled())">
            <div class="contianer full-width" style="margin-bottom: 15px; margin-top: 0px;">
                <div class="row nav nav-tabs d-flex justify-content-end" id="myTab" role="tablist">
                    <div class="col-md-8 nav-item nav-bar" [ngClass]="{'col-md-10': isDescriptivePurchaseOrder() != false}"></div>
                    <div *ngIf="isDescriptivePurchaseOrder() == false" class="col-md-2 nav-item nav-link nav-bar" [routerLink]="['/procurement/purchase-order-receipt-list', PurchaseOrderId, PurchaseOrderNo, 'All']">
                        <a>Receipts({{PurchaseOrderReceipts.length}})</a>
                    </div>
                    <div *ngIf="PurchaseOrderNo" class="col-md-2 nav-item nav-link nav-bar" [routerLink]="['/procurement/purchase-order-bill-details', PurchaseOrderId, PurchaseOrderNo]">
                        <a>Bills({{PurchaseOrderBills.length}})</a>
                    </div>
                    <div *ngIf="!PurchaseOrderNo" class="col-md-2 nav-item nav-link nav-bar" [routerLink]="['/procurement/purchase-order-bill-details', PurchaseOrderId]">
                        <a>Bills({{PurchaseOrderBills.length}})</a>
                    </div>
                </div>
            </div>    
        </div>
        <div class="m" id="printBill">
            <div class="row">
                <div class="col-md-12 d-flex f-ss-14px" id="my1">
                    <div class="col-md-4 d-block pl-1">
                        <p class="ToHeading">To</p>
                        <div>{{PurchaseOrderData.Contacts ? PurchaseOrderData.Contacts.CompanyName : ''}}</div>
                        <div>Email : {{PurchaseOrderData.Contacts ? PurchaseOrderData.Contacts.Email : ''}}</div>
                        <div>Address : {{PurchaseOrderData.Contacts ? PurchaseOrderData.Contacts.StreetAddress : ''}}</div>
                    </div>
                    <div class="col-md-4">
                        <p class="ToHeading">Date</p>
                        <!-- <div>Created Date : {{convertDateIntoDisplay(PurchaseOrderData.OrderDate)}}</div> -->
                        <div>Order Date : {{convertDateIntoDisplay(PurchaseOrderData.OrderDate)}}</div>
                        <div>Delivery Date : {{convertDateIntoDisplay(PurchaseOrderData.DeliveryDate)}}</div>
                        <!-- <div>Modified By : {{PurchaseOrderData.ModifiedByName ? PurchaseOrderData.ModifiedByName : PurchaseOrderData.CreatedByName}}</div>
                        <div>Modified Date :  {{convertDateIntoDisplay(PurchaseOrderData.UpdatedAt ? PurchaseOrderData.UpdatedAt : PurchaseOrderData.CreatedAt)}} </div> -->
                    </div>
                    <div class="col-md-4">
                        <p class="ToHeading">Order Details</p>
                        <div>Order No # {{PurchaseOrderData.OrderNo}}</div>
                        <div>Reference No # {{PurchaseOrderData.ReferenceNo}}</div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row f-ss-14px" >
                <div class="col-md-12 tax exchange" >
                    <div class="float-right" id='my2'>
                        <label> Amounts are  </label>
                        <strong *ngIf="PurchaseOrderData.TaxTypeId==1"> VAT/AIT Exclusive  </strong>
                        <strong *ngIf="PurchaseOrderData.TaxTypeId==2"> VAT/AIT Inclusive  </strong>
                        <strong *ngIf="PurchaseOrderData.TaxTypeId==3"> No VAT/AIT  </strong>
                    </div>
                </div>
                <div class="invoice-grid col-md-12" id="my" >
                    <table class="w-100" border="1|0">
                        <thead>
                            <tr><td>Item</td>
                            <td>Description</td>
                            <td> Quantity </td>
                            <td *ngIf="isDescriptivePurchaseOrder() == false">Received</td>
                            <td>Unit Price</td>
                            <td>Account</td>
                            <td>VAT Rate</td>
                            <td>AIT Rate</td>
                            <td class="amount"> <div>Amount BDT</div></td>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of PurchaseOrderData.PurchaseOrderItems; let i = index">
                                <td>{{formatItem(item.ItemId)}}</td>
                                <td>{{item.Description}}</td>
                                <td>{{item.Quantity}}</td>
                                <td *ngIf="isDescriptivePurchaseOrder() == false && PurchaseOrderData.PurchaseOrderItems[i].ItemId != null" [ngStyle]="{'color': isReceivedMax(item.Quantity, PurchaseOrderData.ReceivedItems[i])? 'red' : '#444'}">{{PurchaseOrderData.ReceivedItems[i]}}</td>
                                <td *ngIf="isDescriptivePurchaseOrder() == false && PurchaseOrderData.PurchaseOrderItems[i].ItemId == null">-</td>
                                <td>{{twoDecimalPlace(item.UnitPrice)}}</td>
                                <td class="editable-cell">{{item.AccountName}}</td>
                                <td>{{formatTaxRate(item.TaxRateId)}}</td>
                                <td>{{formatTaxRate(item.AdvanceTaxRateId)}}</td>
                                <td><div>{{twoDecimalPlace(item.LineTotal)}}</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row f-ss-14px">
                <div class="container-bottom d-flex w-100">
                    <div class="col-md-8 col-sm-12 p-0 d-block"></div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label>Subtotal</label>
                            </div>
                            <div class="col-md-4 float-right"><p class="float-right pr-3">{{twoDecimalPlace(PurchaseOrderData.OrderAmount-PurchaseOrderData.TaxAmount-PurchaseOrderData.AdvanceTaxAmount)}}</p></div>
                        </div>
                        <div class="d-flex w-100 pb-1">
                            <div class="col-md-8">
                                <label>Total VAT</label>
                            </div>
                            <div class="col-md-4"> <p class="float-right pr-3">{{PurchaseOrderData.TaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100 pb-1">
                            <div class="col-md-8">
                                <label>Total AIT</label>
                            </div>
                            <div class="col-md-4"> <p class="float-right pr-3">{{PurchaseOrderData.AdvanceTaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100 singleLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label class="totalTextValue">Total</label>
                            </div>
                            <div class="col-md-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(PurchaseOrderData.OrderAmount)}}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-left: 35px;" class="row">
                <div class="container-bottom d-flex w-100">
                    <div class="col-md-8 col-sm-12 p-0 d-block">
                        <div class="row">
                            <b style="font-weight: bold;margin-left: 15px;">Delivery Details</b>
                        </div>
                        <div style="margin-top: 15px;" class="row">
                            <div style="float: left;" class="col-md-4 col-sm-6">
                                <div>
                                    <p>Contact No: {{PurchaseOrderData.DeliveryContactNo}}</p>
                                </div>
                                <div style="margin-top: 15px;">
                                    <p>Address: {{PurchaseOrderData.DeliveryAddress}}</p>
                                </div>
                                <div style="margin-top: 30px;">
                                    <p>Notes: {{PurchaseOrderData.Notes}}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div>
                                    <p>Attention: {{PurchaseOrderData.DeliveryAttentions}}</p>
                                </div>
                                <div style="margin-top: 15px;">
                                    <p>Instruction: {{PurchaseOrderData.DeliveryInstructions}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="logoUploader" tabindex="-1" role="dialog" aria-labelledby="logoUploader" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content custom-width">
            <div class="modal-header bbp-1px ">
                <b class="modal-title" style="font-size: 17px;">Mark PO No. {{PurchaseOrderData.OrderNo}} as billed</b>
                <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true" style="font-size: 25px">&times;</span>
                </button>
            </div>
            <div class="modal-body bbp-1px" id="filecon" >
                <div class="pb-4">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group  col-md-4">
                                <p>Proceed to Draft Bill</p>
                            </div>
                            <div style="margin-top: 10px;" class="form-group  col-md-4">
                                <button type="button" (click)="CreateDraftBill()"
                                class="eCount-def-btn  p-save-button">Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
      </div>

</app-dashboard>
