
import { Component, OnInit, AfterViewInit, Input, ElementRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { QuotationService } from '../../../service/Quotation/quotation.service';
import { QuotationItem, CreateQuotation } from '../../../models/quotation.model';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgbModal, NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseProductComponent } from '../../Products/purchase-product/purchase-product.component';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import * as moment from 'moment';
import { ItemCreateComponent } from '../../Products/item-create/item-create.component';
import { CreateItem } from 'src/app/models/product.model';
import { CreatePopupItemComponent } from '../../Products/create-popup-item/create-popup-item.component';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';

@Component({
  selector: 'app-quotation-create',
  templateUrl: './quotation-create.component.html',
  styleUrls: ['./quotation-create.component.css'],
  host: {
    '(document:click)': 'onWindowClick($event)',
  },
})
export class QuotationCreateComponent implements OnInit {

  public aModel : CreateItem= new CreateItem();
  allSupplier: any = [];
  PurchaseableItems: any = [];
  allSaleableItem: any = [];
  percentDone: number;
  @Input() itemCode;
  uploadSuccess: boolean;
  quotationNotes: string;
  selectedSupplier: number;
  quotationData: CreateQuotation;
  IsActive: boolean =true;
  quotationItem: QuotationItem[] = [];
  selectedDate: any;
  selectedDueDate: Date;
  totalPrice: number = 0.00;
  ReferenceNo: string = "";
  isUploaded: boolean = false;
  uploadCounter: number;
  filesToUpload: number = 0;
  quotationNo : string ="";
  loading = false;
  isItemListUpdate = false;
  taxTypeList:any=[];
  taxRateList:any=[];
  TaxTypeId:null;
  subTotalAmount: number = 0.00;
  TaxAmount: number = 0.00;
  AdvanceTaxAmount:number =0.00;
  totalFile: number=0;
  ifSubmit: boolean;
  prevQuotation:number;
  postQuotation:string;
  ShowQuotationNo: string;
  constructor(private mainService: MainServiceService,
    private quotationService: QuotationService,
    private dataService: DataService,
    public ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private settingService:SettingsServiceService) { }

  ngOnInit(): void {
    this.getFormData();
    this.forMatDate();
    this.createBlankItem();
    this.dataService.change.subscribe(x => {
      this.isItemListUpdate = x;
      this.getItems();
      this.getAllSaleableItem();
    });
  }

  openItemModal(selectItem: NgSelectComponent){
    selectItem.close();
     const modalRefs = this.modalService.open(PurchaseProductComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
      modalRefs.componentInstance.ItemCode =this.itemCode;
      modalRefs.componentInstance.refId = 2;
      modalRefs.result.then((result) => {
        console.log(result);
      }, (reason) => {
      });
    }
  getFormData = () => {
    this.generateQuotNo();
    this.getAllSupplier();
    this.getItems();
    this.getAllSaleableItem();
    this.getTaxTypeList();
    this.getTaxRateList();
  }
  /*generateQuotNo = () => {
    this.quotationService.GenerateQuotNo().subscribe((x: any) => {
      this.quotationNo = x;
      this.prevQuotation = x;
    });
  }*/
  generateQuotNo = () => {
    this.quotationService.GenerateQuotNo().subscribe((x: any) => {
      let val = x.toString(); let pre = "", post = "";
      for(let i = 0; i < 6; i++) pre += val[i].toString();
      for(let i = 6; i < 10; i++) post += val[i].toString();
      this.quotationNo = pre+post; //check quotation no is valid using this variable
      this.ShowQuotationNo = pre; // this is used only UI
      this.prevQuotation = Number(pre);  //check quotation no is valid using this variable
      this.postQuotation = post; // this is used only UI
    });
  }
  changesellableItems = (index) => {
    for (let i = 0; i < this.allSaleableItem.length; i++) {
      if (this.allSaleableItem[i].Id == this.quotationItem[index]['ItemId']) {console.log(this.allSaleableItem[i])
        this.quotationItem[index]['ItemId'] = this.allSaleableItem[i]['Id'];
        this.quotationItem[index]['Description'] = this.allSaleableItem[i]['SalesDescription'];
        this.quotationItem[index]['AccountId'] = this.allSaleableItem[i]['SaleAccountId'];
        this.quotationItem[index]['Quantity'] = 1;
        this.quotationItem[index]['UnitPrice'] = this.allSaleableItem[i]['SaleUnitPrice'];
        this.updateDataInvoiceItem(this.quotationItem[index]['ItemId']);
      }
    }
  }
  openItemCreateModal(selectItem: NgSelectComponent){
    selectItem.close();
    const modalRef = this.modalService.open(CreatePopupItemComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
    modalRef.componentInstance.refId = 2;
    modalRef.componentInstance.type = "sales";
    modalRef.result.then((result) => {
      this.getAllSaleableItem();
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });

  }
  getAllSaleableItem = () => {
    this.quotationService.GetAllSaleableItem().subscribe((x: []) => {
      this.allSaleableItem = x;
      if (this.allSaleableItem.length == 0) {
        this.allSaleableItem.push({ ItemId: 0, Description: '', Quantity: 1, UnitPrice: '', LineTotal: '', AccountId: '', selected: true });
      }
    })
  }
  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
      if(this.taxTypeList.length > 2) {
        this.TaxTypeId = this.taxTypeList[2].Id;
      }
    });
  }
 private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({"Id":"","Name":"Select"});
    });
  }
  getAllSupplier = () => {
    this.quotationService.GetAllContact().subscribe((x: []) => {
      this.allSupplier = x;
    });
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : true,
      IsSupplier : false,
    };
    this.loading = true;
    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.allSupplier.push(x["Data"]);
        let customerObj = this.allSupplier.filter(x=>x.PrimaryPersonFirstName == customerName);
        this.selectedSupplier = customerObj[0]['Id'];
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }

  changespurchaseableItems = (index) => {
    for (let i = 0; i < this.PurchaseableItems.length; i++) {
      if (this.PurchaseableItems[i].Id == this.quotationItem[index]['ItemId']) {
        this.quotationItem[index]['ItemId'] = this.PurchaseableItems[i]['Id'];
        this.quotationItem[index]['Quantity'] = 1;
        this.quotationItem[index]['UnitPrice'] = this.PurchaseableItems[i]['PurchasePrice'];
        this.quotationItem[index]['Description']=this.PurchaseableItems[i]['PurchaseDescription'];
        this.updateDataInvoiceItem(this.quotationItem[index]['ItemId']);
      }
    }
  }
  getItems = () => { this.quotationService.GetPurchaseableItems().subscribe((x) =>  this.PurchaseableItems = x)}


  Bills = []
  onFocusEvent(event: any){
    event.target.value=event.target.value==0?null:event.target.value;
  }

  changePosition(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.Bills, event.previousIndex, event.currentIndex);
  }

  changeSelectRowInItem = (itemId, index) => {
    let data = []
    this.quotationItem.map((x, i) => {
      x.ItemId == itemId && i == index ? x.selected = true : x.selected = false
      data.push(x)
    })
    this.quotationItem = data;
  }

  updateTotalPrice = () => {
    this.subTotalAmount = 0.00;
    this.TaxAmount=0.00;
    this.AdvanceTaxAmount=0.00;
    this.quotationItem.map((x) => {
      this.subTotalAmount = this.subTotalAmount + x.LineTotal;
      this.TaxAmount+=this.getItemTaxAmount(x.LineTotal,x.TaxRateId);
      this.AdvanceTaxAmount+=this.getItemTaxAmount(x.LineTotal,x.AdvanceTaxRateId);
    });
    switch(this.TaxTypeId){
      case 1: //tax exclusive
        this.totalPrice = this.subTotalAmount+this.TaxAmount+this.AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        this.totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
       this.totalPrice = this.subTotalAmount;
        break;
    }

    if (this.TaxTypeId === 3)
    {
      this.checkTaxType();
    }

  }

  checkTaxType = () => {

    this.quotationItem.map((x, i) => {

       this.quotationItem[i].TaxRateId = null;
       this.quotationItem[i].AdvanceTaxRateId =  null;
    });
  }

  private getItemTaxAmount(lineTotal:number,taxRateId:any) {
    let taxPrice=0.00;
    if(taxRateId=="" || taxRateId==null)
     return taxPrice;

     var taxPercent=this.getTaxRatePercent(taxRateId);

     switch(this.TaxTypeId){
      case 1: //tax exclusive
      taxPrice= lineTotal*taxPercent/100;
        break;
      case 2: //tax inclusive
      taxPrice= Number(((lineTotal*taxPercent)/(100+taxPercent)).toFixed(2));
        break;
      default: //no tax
      taxPrice=0
        break;
    }
    return taxPrice;
  }

  private getTaxRatePercent(taxRateId:any) {
    let taxRateObj = this.taxRateList.filter(x=>x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }
  forMatDate() {
    this.selectedDate = new Date();
    this.showSelectedDate(new Date());
  }
  updateDataInvoiceItem = (itemId) => {
    this.quotationItem.map((x) => {
      if (x.ItemId == itemId) { x.LineTotal = Number((x.Quantity * x.UnitPrice).toFixed(2)) }
    })
    this.updateTotalPrice()
  }
  updateLineTotal = (itemId) => {
    this.quotationItem.map((x) => {
      if (x.ItemId == itemId) {
        x.UnitPrice = x.LineTotal / x.Quantity
      }
    })
    this.updateTotalPrice()
  }
  createBlankItem = () => {
    this.changeSelectRowInItem(0, 0)
    let item = { ItemId: null, Description: "", Quantity: null, UnitPrice: null, LineTotal: 0, AccountId: null, selected: false,TaxRateId:null,AdvanceTaxRateId:null }
    this.quotationItem.push(item);
  }

  createInvoiceItem = (data) => {
    this.changeSelectRowInItem(0, 0);
    let item = {
      ItemId: data.Id,
      Description: data.ItemName,
      Quantity: 1,
      UnitPrice: data.SaleUnitPrice,
      LineTotal: data.SaleUnitPrice,
      AccountId: data.SaleAccountId,
      selected: true,
      TaxRateId:null,
      AdvanceTaxRateId:null
    }
    this.quotationItem.push(item);
    this.updateTotalPrice()
  }
  removeInvoiceItem = (itemId, i) => {
    let data = []
    this.quotationItem.map((x, index) => {
      if (index != i) {
        data.push(x);
      }
    })
    this.quotationItem = data;
    this.updateTotalPrice();
  }

  public setTotalFile(tf:number) {
    this.totalFile=tf;
  }

setQuotationData = () => {
  this.quotationNo = this.prevQuotation + this.postQuotation;
    this.quotationData = {
      Id: 0,
      QuotationNo: this.quotationNo,
      ContactId: this.selectedSupplier,
      QuotationDate: this.selectedDate,
      DueDate: this.selectedDueDate,
      ReferenceNo: this.ReferenceNo.toString(),
      Amount: this.totalPrice,
      IsActive: this.IsActive,
      TaxTypeId:this.TaxTypeId,
      TaxAmount:this.TaxAmount,
      AdvanceTaxAmount:this.AdvanceTaxAmount,
      QuotationItem: this.quotationItem,
      Notes: this.quotationNotes
    }

}
checkValidation=(item)=>{
  var b=true;
  item.forEach(element => {
      if(element.Description=="" || element.LineTotal<=0 || (this.TaxTypeId!=3 && (element.TaxRateId==null && element.AdvanceTaxRateId==null)))
      {
          b=false;

      }
    });
    return b;
}
private isValidData() {
  let check= this.checkValidation(this.quotationData.QuotationItem);
  if (!this.quotationData.ContactId) {
    this.toaster.warning('Required field can not be empty !', 'Warning!');
    return false;
  } else if (!this.quotationData.QuotationDate) {
    this.toaster.warning('Required field can not be empty !', 'Warning!');
    return false;
  } else if (this.quotationData.QuotationItem.length==0) {
    this.toaster.warning('Please add at least one item to the quotation', 'No Item Added!');
    return false;
  }
  else if (!this.quotationData.DueDate) {
    this.toaster.warning('Required field can not be empty !', 'Warning!');
    return;
  }
  else if (!check) {
    this.toaster.warning('Required field can not be empty!');
    return false;
  }
  return true;
}

async checkQuotationNo(){
  //var val = this.quotationNo.toString();
  var val = this.ShowQuotationNo + this.postQuotation;
  if(val=='' || val==null){
    this.toaster.error('Please Input Quotation No.!');
    return false;
  }

  let quotation = this.prevQuotation.toString();
  let concat = "";
  for(let i=0;i<6;i++) concat+=quotation[i];

  if(val.length!=10){
    this.toaster.error("Quotation number must start with " + concat + " and ends with four-digit numbers");
    return false;
  }

  for(let i=0;i<concat.length;i++){
    if(val[i]!=concat[i]){
      this.toaster.error("Quotation number must start with " + concat + " and ends with four-digit numbers");
      return false;
    }
  }

  return true;
}

 public SaveQuotationData = async() => {
  if(!await this.checkQuotationNo()) return;
   this.ifSubmit = true;
    this.setQuotationData();
    if(!this.isValidData())
      return;

      this.spinner.show();
      this.quotationData.IQuidiActionType = iQuidiActionType.Save;
     this.quotationService.Create(this.quotationData).subscribe((x) => {
      this.displaySuccessResponse(x,'Quotation has been successfully saved');
    })
  }

  private displaySuccessResponse(x:any,sMessage:string) {
    if (x.Success) {
      this.toaster.success(sMessage, 'Success!');
      this.router.navigate(['quotation/quotation-list/All']);
      this.spinner.hide();
    } else {
      this.toaster.warning(x.Message);
      this.spinner.hide();
    }
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  fileName: string;
  fileType: string;

  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id == "Preview")) {

    }
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown()
    }
    else {
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showSelectedDate(e) {
    const value = this.selectedDate;
    console.log(value);
    $('#qt1').attr('min', moment.isDate(value) ? value.toISOString().split('T')[0] : value );
    $("#qt").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
  show(e) {
    const value = this.selectedDueDate;
    $("#qt1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
}

