import { Item } from "./inventoryOverview.model";

export class ManufactureOrder{
    Id: number;
    Reference: string;
    ManufactureFormulaId: number;
    ManufactureFormula: ManufactureFormula;
    ProductionQuantity: number;
    ParentId: string;
    BatchNo: string;
    ManufactureOrderNo: string;
    Status: number;
    CompanyId: number;
    ItemId: number;
    Item: Item;
    PerUnitCost: number;
    ItemTotalAmount: number;
    OperationTotalAmount: number;
    CreatedDate: Date;
    CreatedBy: string;
    StartDate: Date;
    EndDate: Date;
    ManufactureOrderDetails: ManufactureOrderDetails[];
    OperatingCostItems: OperatingCostItem[];
}

export class ManufactureOrderDetails{
    Id: number;
    ManufactureOrderId: number;
    ManufactureOrder: 0;
    ItemId: number;
    Item?: Item;
    UsedItem: number;
    BrokenItem: number;
    TotalConsumed: number;
    PricePerUnit: number;
    LineTotal: number;
    ItemType: number;
}

export class ManufactureFormula{
    Id: number;
    FormulaName: string;
    FinishedProductId: number;
    Item?: Item;
    Quantity: number;
    CreateDate: Date;
    Status: number;
    Reference: string;
    CompanyId: number;
    ManufactureFormulaDetails: ManufactureFormulaDetails[];
}

export class ManufactureFormulaDetails{
    Id: number;
    ItemId: number;
    Item: Item;
    Quantity: number;
    ManufactureFormulaId: number;
}

export class OperatingCostItem{
    Id: number;
    ManufactureOrderId: number;
    ManufactureOrder: ManufactureOrder;
    OperationCostTypeId: number;
    OperationCostType: OperationCostType;
    Amount: number;
    CompanyId: number;
}

export class OperationCostType{
    Id: number;
    Name: string;
    Price: number;
    CompanyId: number;
}