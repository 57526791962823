import { Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.css']
})
export class WarningPopupComponent implements OnInit {

  constructor( public activeModal: NgbActiveModal,
               private route: Router ) { }
 @Input() fromParent: any;
  ngOnInit(): void {
    console.log(this.fromParent);
  }
  public dismiss() {
    this.activeModal.dismiss();
  }

  goToUrl(){
    this.activeModal.dismiss();
    this.route.navigate(['/subscription-renew/subscription-renew']);
  }
}
