import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
@Component({
  selector: 'app-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.css']
})
export class SettingPageComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private settingService: SettingsServiceService) { }
  isAdmin = false;
  ngOnInit(): void {
    this.getUserPermission();
  }


  getUserPermission = () => {
    this.settingService.CheckCompanyAdmin().subscribe((x) =>
    {
      this.isAdmin = x;
    });
  }
}
