<div class="threeSide">

    <span style="font-size: 16px;" class="ml-1 mt-2">Warning !!</span> 
   <button type="button" class="close  mr-2"  (click)="dismiss()">
     <span aria-hidden="true">&times;</span>
   </button>
   <span class="bor"></span>
 </div>
 <div>
 <div class="mrt mt-2">
     <p class="col-sm-12 txt">Your {{fromParent.Plan}} has expired ! Please {{fromParent.Message}} to continue using your company without interruption.</p>
  
       
 </div>
 <div class="mt-0">
     <p class="txt pb-4 ml-3">To {{fromParent.purchaseType}} subscription <Span class="text-blue cursor-pointer" (click)="goToUrl()" >Click here</Span></p>
 </div> 
</div>
