<div class="modal-header" style="top: 20px;">
    <h4  class="modal-title" style="font-size: 17px;">Consumption History</h4>
    <button type="button" class="close" aria-label="Close"(click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<div class="ml-3 mt-2">
    <span class="text-Bold fs">{{masterData?.Id}}</span> <span class="ml-3">created on {{convertDateIntoDisplay(masterData?.CreatedAt)}} by {{masterData?.UserName}}</span>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">
    

    <div class="headSection">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="ItemList"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [domLayout] = "domLayout"
                (gridReady)="onGridReady($event)"
            ></ag-grid-angular>
        </div>
    </div>
</div>

<div class="form-group col-sm-6 row">
    <label>Notes</label>
     <textarea  rows="5" type="text" class="form-control"  >{{masterData?.Notes}}</textarea>
</div>
</div>
