import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessOwnerService } from '../../../service/Business Owner/business-owner.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShareCapitalCreateComponent } from '../share-capital-create/share-capital-create.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OwnerLoanCreateComponent } from '../owner-loan-create/owner-loan-create.component';
import moment = require('moment');
import { bufferToggle } from 'rxjs/operators';

@Component({
  selector: 'app-edit-business-owner',
  templateUrl: './edit-business-owner.component.html',
  styleUrls: ['./edit-business-owner.component.css']
})
export class EditBusinessOwnerComponent implements OnInit {

  private gridApi;
  private gridColumnApi;
  shareGridOptions = {
    defaultColDef : {
      sortable: true,
      filter: true,
      flex: 1
    },
      columnDefs : [
                  { headerName: 'Date', field: 'Date' , valueFormatter: this.convertGridDateIntoDisplay},
                  { headerName: 'Account', field: 'ChartOfAccount.AccountName' },
                  { headerName: 'Reference', field: 'ReferenceNo' },
                  { headerName: 'Received Amount', field: 'TotalAmount'}
                ],
      domLayout : 'autoHeight'
              };
    loanGridOptions = {
                defaultColDef : {
                  sortable: true,
                  filter: true,
                  flex: 1
                },
                columnDefs1 : [
                  { headerName: 'Loan No', field: 'LoanNo' , cellStyle: {color: 'blue', cursor: 'pointer'}},
                  { headerName: 'Date', field: 'Date' , valueFormatter: this.convertGridDateIntoDisplay},
                  { headerName: 'Account', field: 'BankName' },
                  { headerName: 'Loan Type', field: 'LoanType' },
                  { headerName: 'Amount', field: 'TotalAmount'},
                  { headerName: 'Interest Amount', field: 'InterestAmount'},
                  { headerName: 'Total', field: 'TotalValue' },
                  { headerName: 'Settlement', field: 'SettleAmount'},
                  { headerName: 'Loan Terms', field: 'LoanPeriod' },
                  { headerName: 'Next Settlement', field: 'NextSettleDate'},
                  { headerName: 'Balance', field: 'Balance'},
                ],
     domLayout : 'autoHeight',
              };

  constructor(
    public fb: FormBuilder,
    private router: ActivatedRoute,
    public route: Router,
    private toaster: ToastrService,
    private ownerService: BusinessOwnerService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService) { }
    ownerForm: FormGroup;
    shareCapitalList: any = [];
    ownerLoanList: any = [];
    isShareActive = true;
    isLoanActive = false;
    Id: any;
  ngOnInit(): void {
    this.Id = this.router.snapshot.paramMap.get('Id');
    this.getShareCapitalList();
    this.getOwnerLoanList();
    this.getOwnerDetails();
    this.initOwnerForm();
  }

  getOwnerDetails = () => {
    this.spinner.show();
    this.ownerService.GetBusinessOwner(this.Id).subscribe((x) => {
      if (x)
      {
        this.setOwnerData(x);
        this.spinner.hide();
      }
    });
  }
  initOwnerForm = () => {
    this.ownerForm = this.fb.group({
      Name : ['', Validators.required],
      CompanyId: [0],
      Email : ['', Validators.required],
      Phone : ['', Validators.required],
      RoleId : [ '', Validators.required],
      Address : ['', Validators.required],
      ShareCapital: [0],
      GrantLoan : [0],
      ReceiveLoan : [0],
      OwnerShipPercentage : ['', Validators.required],
      IsActive: [false],
      Id: [0],
      CreatedAt: [''],
      CreatedById: [''],
    });
  }
  SaveOwnerInfo = () => {
     if (this.ownerForm.invalid)
     {
       this.toaster.warning('Please fill all the required field !');
       return;
     }
     this.spinner.show();
     this.ownerService.UpdateBusinessOwner(this.ownerForm.value).subscribe((x) => {
      if (x.Success)
      {
        this.spinner.hide();
        this.toaster.success('Owner updated Suceessfully');
      }
      else
      {
        this.spinner.hide();
      }
     }, err => {
       this.spinner.hide();
       this.toaster.error('ooh, something went wrong !');
  });
  }
  openShareTab = (status) => {
    this.isShareActive = status;
    this.isLoanActive = false;
  }
  openLoanTab = (status) => {
    this.isLoanActive = status;
    this.isShareActive = false;
  }

  public createNewItem = () => {
    const modalRef = this.modalService.open(ShareCapitalCreateComponent,
      { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.fromParent =  this.ownerForm.value.Id;
    modalRef.result.then((result) => {
      if (result.Success){
        this.route.navigate(['/owner/business-owner-list']);
      }
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }
  public createNewItems = () => {
    const modalRef = this.modalService.open(OwnerLoanCreateComponent,
      { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.fromParent =  this.ownerForm.value.Id;
    modalRef.result.then((result) => {
      if (result.Success){
        this.route.navigate(['/owner/business-owner-list']);
      }
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }
  getShareCapitalList = () => {
    this.shareCapitalList = [];
    this.spinner.show();
    this.ownerService.GetShareCapitalList(this.Id).subscribe((x) => {
      this.shareCapitalList = [...x, ...this.shareCapitalList];
      this.spinner.hide();
    });
  }
  getOwnerLoanList = () => {
    this.ownerLoanList = [];
    this.spinner.show();
    this.ownerService.GetLoanList(this.Id).subscribe((x) => {
      this.ownerLoanList = [...x, ...this.ownerLoanList];
      this.spinner.hide();
    });
  }
  convertGridDateIntoDisplay(date) {
    const dateString = moment(date.value).format('Do MMM YYYY');
    return dateString;
  }
onGridReady(event)
{
    this.gridApi = event.api;

}
onGridReady2(event)
{
    this.gridApi = event.api;
}
setOwnerData(x)
{
   this.ownerForm.patchValue({
     Id: x.Id,
     Name: x.Name,
     Phone : x.Phone,
     Email: x.Email,
     IsActive: x.IsActive,
     CompanyId: x.CompanyId,
     RoleId : x.RoleId,
     Address : x.Address,
     ShareCapital: x.ShareCapital,
     GrantLoan : x.GrantLoan,
     ReceiveLoan : x.ReceiveLoan,
     OwnerShipPercentage : x.OwnerShipPercentage,
     CreatedAt: x.CreatedAt,
     CreatedById: x.CreatedById
});
}
validateOwnerShip = () => {
  const value = this.ownerForm.value.OwnerShipPercentage;
  if (value > 100)
  {
    this.toaster.warning('Owenership can not exceed 100 % ');
    this.ownerForm.get('OwnerShipPercentage').patchValue(0);
    return;
  }
}
onRowClicked(e) {
  const colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
  if (colHeaderName === 'Loan No') {
     this.route.navigate([`business-owner/view-owner-loan/${e.data.Id}`]);
  }

}
}

