import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { customer } from './../../models/customer.model';
import { createSpendMoney } from '../../models/spendMoney.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConversionbalancesService {

  baseurl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  //Get ConversionConfig
  getConversionConfig(): Observable<customer[]> {
    return this.http.get<any>(this.baseurl + 'ConversionConfig/get')
      .pipe(
        retry(1)
      )
  }

  //save ConversionConfig 
  saveConversionConfig(conversionId, conversionMonth,conversionYear): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ConversionConfig/Save', {Id:conversionId, ConversionYear:conversionYear, ConversionMonth: conversionMonth })
      .pipe(
        retry(1)
      )
  }
  
  //Get ConversionBalance
  getConversionBalance(): Observable<customer[]> {
    return this.http.get<any>(this.baseurl + 'ConversionBalance/get')
      .pipe(
        retry(1)
      )
  }

  //save ConversionBalance 
  saveConversionBalance(data): Observable<any> {
    return this.http.post<createSpendMoney>(this.baseurl + 'ConversionBalance/Save', data)
      .pipe(
        retry(1)
      )
  }

  //delete ConversionBalance Invoice
  deleteConversionBalanceInvoice(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `ConversionBalanceInvoice/Delete?id=${data}`)
      .pipe(
        retry(1)
      )
  }
 
  //delete Conversion Balance Bill
  deleteConversionBalanceBill(data): Observable<any> {
    return this.http.get<any>(this.baseurl + `ConversionBalanceBill/Delete?id=${data}`)
      .pipe(
        retry(1)
      )
  }

}
