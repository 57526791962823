import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { DocumentAdvanceSearchViewModel } from 'src/app/models/documentAdvanceSearch.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AttachmentServiceService {

  baseurl = environment.baseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  constructor(private http: HttpClient) { }

  httpAttachmentOptions: any = {
    reportProgress: true, observe: 'events', headers: new HttpHeaders({
      'Authorization': `Bearer ${this.userInfo ? this.userInfo.Token : null}`
    })
  }

  GetAllAttachmentById(fileModule:string, id: number) {
    return this.http.get<any>(this.baseurl + "FileUpload/GetFiles?refId=" + id+"&fileModule="+fileModule).pipe(retry(1));
  }

  DeleteAttachmentById(fileModule:string, id: number) {
    return this.http.get(this.baseurl + "FileUpload/DeleteFile?id=" + id+"&fileModule="+fileModule).pipe(retry(1));
  }

  UploadAttachment(fileModule:string, fff: FormData, id: number) {
    return this.http.post(this.baseurl + "FileUpload/Upload?refId=" + id +"&fileModule="+fileModule, fff, this.httpAttachmentOptions).pipe(retry(1));
  }

  getAllFilesByType(type: string) {
    return this.http.get(this.baseurl + type + "/GetAllFile").pipe(retry(1));
  }
  getAllTagsAttachment() {
    return this.http.get(this.baseurl  + "Settings/GetAllTagsAttachment").pipe(retry(1));
  }
  uploadLogo(ff:FormData){
    return this.http.post(this.baseurl + "AzureFileStorageApi/UploadFile", ff, this.httpAttachmentOptions).pipe(
      retry(1)
    );
  }

  shareFile(data:any){
    return this.http.post(this.baseurl + "SharedDocuments/Save", data).pipe(
      retry(1)
    )
  }

  getSharedFile(){
    return this.http.get(this.baseurl+"SharedDocuments/GetMySharedFile").pipe(
      retry(1)
    )
  }

  convertToPDF = () => {
    var data = document.getElementById('printBills');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      var DocumentName = `billsreport.pdf`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    })
  }

  // update draft document to awaiting approval
  docDraftToApproval(data:any){
    return this.http.post(this.baseurl + "Settings/DocDraftToApproval", data).pipe(
      retry(1)
    )
  }

  // delte a draft document
  deleteCurrDocFile(data:any){
    return this.http.post(this.baseurl + "Settings/DocDraftDelete", data).pipe(
      retry(1)
    )
  }

  getAllTagsAttachmentByAdavnceSearch(data: DocumentAdvanceSearchViewModel) : any {
    return this.http.post<any>(this.baseurl + "Settings/GetAllTagsAttachmentByAdavnceSearch", data);
  }

  GetAllTagsAttachmentDocumentRelatedOnly(pageNumber, pageSize): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/GetAllTagsAttachmentDocumentRelatedOnly?pageNumber=${pageNumber}&pageSize=${pageSize}`).pipe(retry(1));
  }

  GetOnlyApprovedAndArchiveAttachments() {
    return this.http.get(this.baseurl  + "Settings/GetOnlyApprovedAndArchiveAttachments").pipe(retry(1));
  }

  GetOnlyApprovedAndArchiveTagsAttachmentDocumentRelatedOnly() {
    return this.http.get(this.baseurl  + "Settings/GetOnlyApprovedAndArchiveTagsAttachmentDocumentRelatedOnly").pipe(retry(1));
  }

  public LockDocuments(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/LockDocuments', data);
  }

  public GetDocumentLockDetails(tagId: number): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/GetDocumentLockDetails?tagId=${tagId}`).pipe(retry(1));
  }

  public UpdateDocumentLockDetails(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/UpdateDocumentLockDetails', data);
  }

  UnlockDocuments(documents: any){
    return this.http.post(this.baseurl + "Settings/UnlockDocuments", documents);
  }

  DeactivateDocuments(documents: any){
    return this.http.post(this.baseurl + "Settings/DeactivateDocuments", documents);
  }

  ActivateDocuments(documents: any){
    return this.http.post(this.baseurl + "Settings/ActivateDocuments", documents);
  }

  getAllTagsAttachmentVersion(tagId: number){
    return this.http.get(this.baseurl + `Settings/GetAllTagAttachmentVersionsByTagId?tagId=${tagId}`).pipe(retry(1));
  }

  RestoreVersion(version: any): Observable<any>{
    return this.http.post<any>(this.baseurl + "Settings/RestoreTagsAttachmentVersion", version);
  }

  DeleteVersion(version: any): Observable<any>{
    return this.http.post(this.baseurl + "Settings/DeleteTagAttachmentVersion", version);
  }
}
