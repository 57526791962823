<app-dashboard>
    <app-page-header-title mainTitle="{{ mainTitle }}" subTitle="Bill OverView" backURL="/expense/bill-overview/All">
        <div class="full-width text-align-right">
            <div class="dropdown">
            <!-- <button id="attachment_btn" type="button" style="z-index: 1;" class="btn attachment-button"><i id="attachment_btn"
                    style="font-size: 20px !important;" class="material-icons">insert_drive_file</i><span id="attachment_btn"
                    style="color:darkgrey; font-size: 12px; "
                    *ngIf="totalFile>1">{{totalFile}}
                </span>
            </button> -->
            <div class="x-tip-tl" style="height: 0px;">
                <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
            </div>
            <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="2"></app-attachment-uploader>
            </div>
            </div>
            <button id="bill_save_button_pressed" type="button" (click)="saveAsDraftCreateBill()" class="eCount-def-btn ml-4">Save</button>
            <button id="bill_submit_button_pressed" type="button" (click)="saveAsSubmitBill()" class="eCount-def-btn ml-4">Submit</button>
            <button id="bill_approve_button_pressed" type="button" style="background: #5cac00;" *ngIf="canApprove" (click)="saveBillData()" class="eCount-def-btn ml-4">Approve</button>
        </div>
    </app-page-header-title>
    <div class="headSection">
        <form [formGroup]="billForm" class="f-ss-14px" >
        <div class="row f-ss-14px">
            <div class="col-md-2 col-sm-12">
                <label>To<span class="clr">*</span> </label>
                <ng-select [items]="allSupplier" bindLabel="CompanyName" bindValue="Id"
                    [addTag]="CreateNewSupplier" [loading]="loading" formControlName="SupplierId" [ngClass]="{'submitted':ifSubmit===true}" required>
                    <ng-template ng-tag-tmp let-search="searchTerm">
                        <b>+ Add New Supplier</b>: {{search}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-2 col-sm-12">
                <label>Date</label>
                <input class="form-control"  type="date" formControlName="BillDate"  id="btest1" data-date="" data-date-format="D MMM YYYY" [ngClass]="{'submitted':ifSubmit===true}" (change)="showSelectedDate($event)">
            </div>
            <div class="col-md-2 col-sm-12">
                <label>Due Date<span class="clr">*</span> </label>
                <input class="form-control"  type="date" formControlName="DueDate" [ngClass]="{'submitted':ifSubmit===true}" min="2021-06-22" id="btest" data-date="" data-date-format="D MMM YYYY" [ngClass]="{'submitted':ifSubmit===true}" (change)="show($event)" required>
            </div>
            <div class="col-md-2 col-sm-12">
                <label>Reference</label>
                <input class="form-controlinputBox" type="text" formControlName="ReferenceNo" [readonly]="wareHouseGlag==1">
            </div>
            <div class="col-md-2 col-sm-12">
                <label>Bill No</label>
                <span class="form-controlinputBox d-flex">
                  <label style="background-color: #dddddd;padding-bottom:10%;">{{ShowBillNo}}</label>
                  <input class="form-controlinputBox2" formControlName="BillNo" type="text">
                </span>

            </div>
            <div class="col-md-2 col-sm-12">
                <label>Amounts are</label>
                <ng-select [items]="taxTypeList" (change)="updateTotalPrice()" [clearable]="false" bindLabel="Name" bindValue="Id" formControlName="TaxTypeId"></ng-select>
            </div>
        </div>
        <div class="row d-block topSection f-ss-14px">
            <div class="d-flex">
                <div class="col-md-2">
                    Item
                </div>
                <div class="col-md-2">
                    Description<span class="clr">*</span>
                </div>
                <div class="col-md-2">
                    Account
                </div>
                <div class="col-md-1">
                    Quantity
                </div>
                <div class="col-md-1">
                    Price
                </div>
                <div class="col-md-1">
                   VAT%
                </div>
                <div class="col-md-1">
                    AIT%
                 </div>
                <div class="col-md-2">
                    Amount
                </div>
            </div>
            <hr class="pb-0 mb-0" />
            <div >
                <div formArrayName="BillItems" *ngFor="let bill of billForm.get('BillItems')['controls']; let i = index;" >
                    <div class="invoiceGridItemRow d-block"
                        [ngClass]="[bill.selected ? 'selectedRow' : 'notSelectedRow']">
                        <div class="d-flex"  [formGroupName]="i">

                            <div class="col-md-2 borderLeft d-flex  pl-0">
                                <div style="width: 100%;margin-left: 5px; ">
                                    <ng-select [items]="PurchaseableItems" placeholder="Select Items" #selectItem
                                        class="customSaleItem" [clearable]="false" bindLabel="ItemName" bindValue="Id"
                                        (change)="changespurchaseableItems(i)" formControlName="ItemId">

                                        <ng-template ng-header-tmp>
                                            <a (click)="openItemModal(selectItem)" role="button" aria-haspopup="false"
                                                aria-expanded="false"><span style="color: #007bff;" >+ Add
                                                    Item</span></a>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2 borderLeft ">
                                <textarea class="form-control"
                                    style="resize: none;margin: 5px; min-height: 20px; max-height: 25px;"
                                    type="text" formControlName="Description"
                                     [ngClass]="{'submitted':ifSubmit===true}" required> </textarea >
                            </div>
                            <div class="col-md-2 borderLeft ">
                                <div style="width: 100%;">
                                    <ng-select [items]="allChartOfAccount" placeholder="Select Account"
                                        class="customSaleItem" [clearable]="false" bindLabel="AccountName"
                                        bindValue="Id" formControlName="AccountId" [ngClass]="{'submitted':ifSubmit===true}"
                                         groupBy="GroupName">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-md-1 borderLeft ">
                                <input #quantityInput class="form-control" type="text" appDecimaNumericValidator
                                    formControlName="Quantity"
                                    (focus)="onFocusEvent($event)"
                                    (change)="updateDataInvoiceItem(bill.ItemId)" 
                                    [ngClass]="{'submitted':ifSubmit===true}"  
                                    required
                                    (focus)="onFocusEvent($event)">
                            </div>
                            <div class="col-md-1 borderLeft ">
                                <input class="form-control" type="text" appDecimaNumericValidator
                                formControlName="UnitPrice"
                                    (focus)="onFocusEvent($event)"
                                    (change)="updateDataInvoiceItem(bill.ItemId)" [ngClass]="{'submitted':ifSubmit===true}" required>
                            </div>
                            <div class="col-md-1 borderLeft mt-1">
                                <div style="width: 100%;">
                                    <ng-select [items]="taxRateList" [ngClass]="{'disable': billForm.value.TaxTypeId===3}"
                                    class="customSaleItem" [clearable]="false" bindLabel="Parcent"
                                        bindValue="Id"  (change)="updateTotalPrice()" formControlName="TaxRateId">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-1 borderLeft mt-1">
                                <div style="width: 100%;">
                                    <ng-select [items]="taxRateList" [ngClass]="{'disable': billForm.value.TaxTypeId===3}"
                                    class="customSaleItem" [clearable]="false" bindLabel="Parcent"
                                        bindValue="Id"  (change)="updateTotalPrice()" formControlName="AdvanceTaxRateId">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-2  d-flex">
                                <div>
                                    <input class="form-control" style="background-color:white; color:black" type="text" appDecimaNumericValidator
                                    formControlName="LineTotal" disabled>
                                </div>
                                <div style="padding: 7px 10px; color: #c7c2c2;">
                                    <div class="dropdown mr-4">
                                        <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                            aria-expanded="false">
                                            <i class="icon-more float-right"></i>
                                        </div>
                                        <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                            style="width: 100%;">
                                            <div class="productRow pb-3" (click)="removeInvoiceItem(bill.ItemId, i)">
                                                <span class="lead1">Remove Item </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row addItem">
                <div class="BottomAddItem w-100">
                    <div class="dropdown mr-4">
                        <a class="nav-link" style="background-color:white ;" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                            aria-haspopup="false" aria-expanded="false" (click)="addItem()">
                            <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line
                        </a>
                    </div>
                </div>
            </div>

            <div class="container-bottom d-flex">
                <div class="col-md-8 col-sm-12 row">


                        <div class="form-group col-sm-5">
                            <label>Notes</label>
                             <!-- <textarea maxlength="250"  rows="5" type="text" class="form-control" placeholder="Write down some important notes using 250 characters"  formControlName="Notes"></textarea> -->
                             <div class="textarea-wrapper">
                                <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea"
                                    placeholder="Write down some important notes using only 250 characters" formControlName="Notes"></textarea>
                                <span class="remaning" >{{myInput.value.length}}/250</span>
                            </div>
                        </div>
                        <div class="col-sm-7 row">
                            <div class="col-sm-3">
                                <label style="font-weight: 600;">Repeating</label>
                                <label class="switch">
                                    <input class="switch-input" type="checkbox" formControlName="IsRepeating" (change)="changeType()">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                            <div class="col-sm-7 ml-4" *ngIf="billForm.value.IsRepeating === true">
                                <label for="RepeatingTypeId" style="font-weight: 600;" >Repeating Types<span
                                    class="required-sign"></span></label>
                                <ng-select [items]="repeatingTypeList"  bindLabel="Name" bindValue="Id" [ngClass]="{'redb':ifSubmit===true && billForm.value.IsRepeating === true && billForm.value.RepeatingTypeId == null}"
                                formControlName="RepeatingTypeId" [clearable]="false">
                                </ng-select>
                            </div>
                        </div>
                </div>
                <div class="col-md-4 col-sm-12 p-0 d-block">
                    <div class="d-flex w-100">
                        <div class="col-md-4">
                            <label>Subtotal</label>
                        </div>
                        <div class="col-md-4"><span class="float-right">{{subTotalAmount.toFixed(2) | currency:'':''}}</span></div>
                    </div>
                    <div class="d-flex w-100 pb-1" *ngIf="billForm.value.TaxTypeId!=3">
                        <div class="col-md-4">
                            <label>VAT</label>
                        </div>
                        <div class="col-md-4"><span class="float-right">{{billForm.value.TaxAmount | currency:'':''}}</span></div>
                    </div>
                    <div class="d-flex w-100 pb-1" *ngIf="billForm.value.TaxTypeId!=3">
                        <div class="col-md-4">
                            <label>AIT</label>
                        </div>
                        <div class="col-md-4"><span class="float-right">{{billForm.value.AdvanceTaxAmount | currency:'':''}}</span></div>
                    </div>
                    <div class="d-flex w-100 equalLine">
                        <span></span>
                    </div>
                    <div class="d-flex w-100 pt-3">
                        <div class="col-md-4">
                            <label>TOTAL</label>
                        </div>
                        <div class="col-md-4 font-weight-bold"><span class="float-right">{{billForm.value.BillAmount | currency:'':''}}</span></div>
                    </div>
                </div>
            </div>

        </div>
    </form>
    </div>

</app-dashboard>
