import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { data } from 'jquery';
import { OperationType } from 'src/app/enum/operation-type';

@Injectable({
  providedIn: 'root'
})
export class ApprovalHistoryModalService {

  baseurl = environment.baseUrl;
  modalInfoData: any;

  constructor(private http: HttpClient) { }

  public saveModalInfoData(data:any){
    this.modalInfoData = data;
  }

  public getModalInfoData(){
    return this.modalInfoData;
  }
  getAllHistory(type: OperationType, data: any){
    switch(type){
      case OperationType.BILL:
        return this.getAllBillHistory(data);
      case OperationType.INVOICE:
        return this.getAllInvoiceHistory(data);
      case OperationType.DOCUMENT:
        return this.getAllTagHistory(data);
      case OperationType.GRANTLOAN:
        return this.getAllGrantLoanApprovalHistory(data);
    }
  }

  // get all bill history
  public getAllBillHistory(data:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetAllBillHistory?id='+data).pipe(retry(1));
  }
  // get all invoice history
  public getAllInvoiceHistory(data:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetAllInvoiceHistory?id='+data).pipe(retry(1));
  }
  // get all invoice history
  public getAllTagHistory(data:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetAllTagHistory?id='+data);
  }
  // get all grant loan history
  public getAllGrantLoanApprovalHistory(data:any): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetAllGrantLoanApprovalHistory?id='+data).pipe(retry(1));
  }
  
}
