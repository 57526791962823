import { Component, OnInit } from '@angular/core';
import { Refresh } from '@ngrx/store-devtools/src/actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ReplenishmentViewModel } from 'src/app/models-viewModels/replenishmentViewModel';
import { ReplenishmentService } from 'src/app/service/Replenishment/replenishment.service';

@Component({
  selector: 'app-replenishment',
  templateUrl: './replenishment.component.html',
  styleUrls: ['./replenishment.component.css']
})
export class ReplenishmentComponent implements OnInit {

  searchText: string;
  gridApi;
  domLayout = "autoHeight";
  rowHeight = 275;
  rowSelection = 'multiple';
  AllItems: ReplenishmentViewModel[];

  constructor(
    private toastr: ToastrService,
    private replenishmentService: ReplenishmentService,
    private spinner: NgxSpinnerService 
  ) { }

  ngOnInit(): void {
    this.getReplenishmentList();
  }

  getReplenishmentList() {
    this.replenishmentService.getReplenishmentList().subscribe((x) => {
      if(x){
        this.AllItems = x;
      }
    });
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
    scrollbarWidth: 0,
    resizable: true,
  };

  ColumnDefs = [
    {
      headerName: "Item Name",
      field: "ItemName",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true
    },
    { headerName: "Quantity", field: "StockQuantity" },
    { headerName: "Low in Stock", field: "ShortageDemand" },
    { headerName: "Supplier Name", field: "SupplierName" },
    { headerName: "Route", field: "Route" },
    //{ headerName: "Status", field: "Status" }
  ];

  onGridReady(event) {
    this.gridApi = event.api;
  }

  confirmOrder() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);

    if (selectedData.length === 0) {
      this.toastr.warning('Select at least one item');
      return;
    }

    this.spinner.show();
    this.replenishmentService.replenish(selectedData).subscribe((x) => {
      if(x.Success){
        this.toastr.success("Replenished Successfully");
        this.spinner.hide()
        location.reload();
      }else{
        this.toastr.error(x.Message);
      }
      this.spinner.hide();
    });
  }

}
