<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle" *ngIf="hasDocumentTypeGroup && operationType == 0">Create Document Groups</h4>
        <h4 class="modal-title modalTitle" *ngIf="!hasDocumentTypeGroup && operationType == 0">Create Document Types</h4>
        <h4 class="modal-title modalTitle" *ngIf="operationType == 3">Update Document Types</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="DocumentTypeGroupSettingsForm" class="f-ss-14px">
                    <p class="mt-3 mb-3 font-weight-bold" *ngIf="hasDocumentTypeGroup">Please set the document types for document groups</p>
                    <p class="mt-3 mb-3 font-weight-bold" *ngIf="!hasDocumentTypeGroup">Please set the document types</p>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group col-sm-6" [ngClass]="{ 'col-sm-12' : !hasDocumentTypeGroup}">
                                <label for="DocumentTypeId" style="font-weight: 600;">Document Type Name <span class="text-red">*</span></label>
                                <ng-select [items]="allDocumentTypes" 
                                    bindLabel="Name" 
                                    bindValue="Id"
                                    formControlName="DocumentTypeId"
                                    [addTag]="CreateNewDocumentType.bind(this)"
                                    [loading]="documentTypeLoading"
                                    *ngIf="operationType == 0">
                                    <ng-template ng-tag-tmp let-search="searchTerm">
                                        <b>+ Add New Document Type</b>: {{search}}
                                    </ng-template>
                                </ng-select>
                                <input *ngIf="operationType == 3" type="text" class="form-control" formControlName="DocumentTypeName">
                            </div>
                            <div class="form-group col-sm-6" *ngIf="hasDocumentTypeGroup">
                                <label for="DocumentTypeId" style="font-weight: 600;">Group Name <span class="text-red">*</span></label>
                                <ng-select [items]="allDocumentTypeGroups" 
                                    bindLabel="Name" 
                                    bindValue="Id"
                                    formControlName="DocumentTypeGroupId"
                                    [addTag]="CreateNewDocumentTypeGroup.bind(this)"
                                    [loading]="documentTypeGroupLoading">
                                    <ng-template ng-tag-tmp let-search="searchTerm">
                                        <b>+ Add New Document Group</b>: {{search}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="full-width text-align-right">
                        <button type="button" class="eCount-def-btn mt-4 mr-2" (click)="save()"><span *ngIf="operationType == 0">Save</span><span *ngIf="operationType == 3">Update</span></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>