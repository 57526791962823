<app-dashboard>
    <div>
        <app-page-header-title mainTitle="{{ setMainTitle() }}"
            subTitle="Manufacture Order List" 
            backURL="/manufacture/manufacutring-order-list/All">
            <div class="full-width text-align-right"></div>
        </app-page-header-title>
    </div>
    <div class="headSection">
        <form [formGroup]="ManufactureOrderForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-2">
                    <label for="">Product Name</label>
                    <input class="form-control" type="text" formControlName="FinishedProductId" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Formula Name</label>
                    <input class="form-control" type="text" formControlName="FormulaName" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Quantity To Produce</label>
                    <input class="form-control" type="text" formControlName="Quantity" appDecimaNumericValidator disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Created Date</label>
                    <input class="form-control" type="text" formControlName="CreatedDate" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Reference</label>
                    <input class="form-control" type="text" formControlName="Reference" disabled>
                </div>
                <div class="col-md-2">
                    <label for="">Batch No</label>
                    <input class="form-control" type="text" formControlName="BatchNo" disabled>
                </div>
                <div class="col-md-2 pt-3">
                    <label for="">Start Date</label>
                    <input class="form-control" type="text" formControlName="StartDate" disabled>
                </div>
                <div class="col-md-2 pt-3">
                    <label for="">End Date</label>
                    <input class="form-control" type="text" formControlName="EndDate" disabled>
                </div>
            </div>

            <div class="row d-block topSection f-ss-14px mt-5">
                <div class="font-weight-bold mb-1">
                    Formula Items
                </div>
                <div class="d-flex">
                    <div class="col-md-2">
                        Item
                    </div>
                    <div class="col-md-1">
                        To Consume
                    </div>
                    <div class="col-md-2">
                        Used Items
                    </div>
                    <div class="col-md-2">
                        Broken Items
                    </div>
                    <div class="col-md-1">
                        Consumed
                    </div>
                    <div class="col-md-2">
                        Price/Unit
                    </div>
                    <div class="col-md-2">
                        Amount
                    </div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="OrderItems" *ngFor="let item of ManufactureOrderForm.get('OrderItems')['controls']; let i = index;">
                        <div class="row f-ss-14px" [formGroupName]="i">
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="RawMaterialId" disabled>
                            </div>
                            <div class="col-md-1" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="ToConsume" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="UsedItems" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="BrokenItems" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-1" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="Consumed" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="UnitPrice" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="LineTotal" appDecimaNumericValidator disabled>
                            </div>
                        </div>
                    </div>
                    <hr class="pb-0 mb-0 pt-1 mt-1">
                    <div class="row d-flex">
                        <div class="col-md-7 font-weight-bold">
                            Total
                        </div>
                        <div class="col-md-3 font-weight-bold ml-3">
                            {{ totalOrderItemsConsume.toFixed(2) }}
                        </div>
                        <div class="col-md-1 font-weight-bold">
                            {{ totalOrderItemsAmount.toFixed(2) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px mt-5" *ngIf="canShowMiscellaneousItem()">
                <div class="font-weight-bold mb-1">
                    Miscellaneous Item
                </div>
                <div class="d-flex">
                    <div class="col-md-2">
                        Item
                    </div>
                    <div class="col-md-1">
                        
                    </div>
                    <div class="col-md-2">
                        To Consume
                    </div>
                    <div class="col-md-2">
                        Consumed Items
                    </div>
                    <div class="col-md-1">
                        
                    </div>
                    <div class="col-md-2">
                        Price/Unit
                    </div>
                    <div class="col-md-2">
                        Amount
                    </div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="MiscellaneousItems" *ngFor="let item of ManufactureOrderForm.get('MiscellaneousItems')['controls']; let j = index;">
                        <div class="row f-ss-14px" [formGroupName]="j">
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="MiscellaneousRawMaterialId" disabled>
                            </div>
                            <div class="col-md-1" style="padding-top: 3px;">
                                
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="MiscellaneousToConsume" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="MiscellaneousConsumed" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-1" style="padding-top: 3px;">
                               
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="MiscellaneousUnitPrice" appDecimaNumericValidator disabled>
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="MiscellaneousLineTotal" appDecimaNumericValidator disabled>
                            </div>
                        </div>
                    </div>
                    <hr class="pb-0 mb-0 mt-1">
                    <div class="row d-flex">
                        <div class="col-md-5 font-weight-bold">
                            Total
                        </div>
                        <div class="col-md-5 font-weight-bold ml-3">
                            {{ totalMiscellaneousItemsConsume.toFixed(2) }}
                        </div>
                        <div class="col-md-1 font-weight-bold">
                            {{ totalMiscellaneousItemsAmount.toFixed(2) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px mt-5" *ngIf="canShowOperatingCosts()">
                <div class="font-weight-bold mb-1">
                    Operating Costs
                </div>
                <div class="d-flex">
                    <div class="col-md-2">
                        Cost Type
                    </div>
                    <div class="col-md-2">
                        Amount
                    </div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="OperatingCostItems" *ngFor="let item of ManufactureOrderForm.get('OperatingCostItems')['controls']; let k = index;">
                        <div class="row f-ss-14px" [formGroupName]="k">
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="OperatingCostId" disabled>
                            </div>
                            <div class="col-md-2" style="padding-top: 3px;">
                                <input class="form-control" type="text" formControlName="OperatingCostAmount" appDecimaNumericValidator disabled>
                            </div>
                        </div>
                    </div>
                    <hr class="pb-0 mb-0 mt-1">
                    <div class="row d-flex">
                        <div class="col-md-2 font-weight-bold">
                            Total
                        </div>
                        <div class="col-md-2 font-weight-bold ml-3">
                            {{ manufactureOrderData?.ManufactureOrder?.OperationTotalAmount }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px mt-5">
                <div>
                    <div class="font-weight-bold">
                        Finished Product Costs
                    </div>
                    <hr class="pb-0 mb-0">
                    <div class="row f-ss-14px">
                        <div class="col-md-2" style="padding-top: 3px;">
                            <label>Operating Cost/Unit</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="OperatingCostPerUnit" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <label>Total Operating Cost</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="TotalOperatingCost" disabled>
                        </div>
                    </div>
                    <div class="row f-ss-14px">
                        <div class="col-md-2" style="padding-top: 3px;">
                            <label>Material Cost/Unit</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="MaterialCostPerUnit" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <label>Total Material Cost</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="TotalMaterialCost" disabled>
                        </div>
                    </div>
                    <div class="row f-ss-14px">
                        <div class="col-md-2" style="padding-top: 3px;">
                            <label> Miscellaneous Item Cost/Unit</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="MiscellaneousItemCostPerUnit" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <label>Total Miscellaneous Item Cost</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 3px;">
                            <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="TotalMiscellaneousItemCost" disabled>
                        </div>
                    </div>
                    <div class="row f-ss-14px">
                        <div class="col-md-2" style="padding-top: 3px; padding-bottom: 3px;">
                            <label>Production Cost/Unit</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 3px; padding-bottom: 3px;">
                            <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="ProductionCostPerUnit" disabled>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-2" style="padding-top: 3px; padding-bottom: 3px;">
                            <label>Total Production Cost</label>
                        </div>
                        <div class="col-md-2" style="padding-top: 3px; padding-bottom: 3px;">
                            <input class="form-control text-center" type="text" appDecimaNumericValidator formControlName="TotalProductionCost" disabled>
                        </div>
                    </div>
                    <hr class="pt-0 mt-0 pb-0 mb-0">
                </div>
            </div>
        </form>
    </div>
</app-dashboard>