<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">{{'Project.newProject' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="projectForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="title" style="font-weight: 600;">{{'Project.projectName' | translate}} <span
                                        class="required-sign">*</span></label>
                                <input type="text" class="form-control form-input" formControlName="Title">
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="title" style="font-weight: 600;">{{'Project.status' | translate}} <span
                                        class="required-sign">*</span></label>
                                <select required class="form-control" style="width: 100%;" formControlName="StatusId">
                                    <option *ngFor="let s of statusList" [ngValue]="s.Id">{{s.Name}}</option>
                                </select>
                            </div>

                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="title" style="font-weight: 600;">{{'Project.contact' | translate}}</label>
                                    <ng-select [items]="contactList" bindLabel="PrimaryPersonFirstName" bindValue="Id"
                                        [addTag]="CreateNewCustomer" formControlName="ContactId" [loading]="loading" placeholder="+ create new">
                                            <ng-template ng-tag-tmp let-search="searchTerm">
                                                  <b>+ Add New Customer</b>: {{search}}
                                            </ng-template>
                                    </ng-select>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="Deadline" style="font-weight: 600;">{{'Project.deadline' |
                                    translate}}</label>
                                <input type="date" class="form-control form-input" formControlName="Deadline"  id="ptest" data-date="" data-date-format="D MMM YYYY"  (change)="showSelectedDate($event)">
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="EstimatedCost" style="font-weight: 600;">{{'Project.estimatedCost' |
                                    translate}}</label>
                                <input type="text" class="form-control form-input" formControlName="EstimatedCost"
                                    appDecimaNumericValidator>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="WinPrice" style="font-weight: 600;">{{'Project.winPrice' |
                                    translate}}</label>
                                <input type="text" class="form-control form-input" formControlName="WinPrice"
                                    appDecimaNumericValidator>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div class="col-md-12">

                <button type="button" [disabled]="projectForm.invalid" (click)="saveProject()"
                    class="eCount-def-btn  ml-4 p-save-button">Save</button>

            </div>
        </div>
    </div>
</div>