<app-dashboard>
    <app-page-header-title 
        mainTitle="Finished Product Materials" 
        subTitle="Home" 
        backURL="/">
        <div class="full-width text-align-right">
            <input type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox"
      />
            <button type="button" class="eCount-def-btn ml-4" (click)=" CreateFinishedProductMaterial()">Create FP Materials</button>
            <button type="button" class="eCount-button3 ml-4" (click)="onBtnExport()">Export</button>
        </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('All')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/finished-product-material-formula-list/All">
                    All
                </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Draft')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/finished-product-material-formula-list/Draft">
                    Draft
                </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Approve')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/finished-product-material-formula-list/Approve">
                    Approve
                </a>
        </li>
    </ul>
    <div class="headSection">
        <div class="row">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col" 
                [rowData]="filteredFormulaList" 
                [columnDefs]="ColumnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef" 
                [pagination]="true" 
                [paginationPageSize]="50" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</app-dashboard>