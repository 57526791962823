import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { ActivatedRoute } from "@angular/router";
//Page Import
import { HomePageComponent } from "./pages/home-page/home-page.component";

//Authentication
import { AuthPageComponent } from "./pages/Authentication/Login-page/auth-page.component";
import { RegistrationPageComponent } from "./pages/Authentication/Registration-page/registration-page.component";
import { ForgetPasswordComponent } from "./pages/Authentication/Forgot-Password-page/forget-password.component";
import { ResetPasswordComponent } from "./pages/Authentication/reset-password/reset-password.component";
//contacts
import { ContactPageComponent } from "./pages/contact/contact-page/details-contact.component";
import { CreateContactsPageComponent } from "./pages/contact/create-contacts-page/create-contacts-page.component";
import { AllContactListPageComponent } from "./pages/contact/all-contact-list-page/all-contact-list-page.component";
import { CustomerContactComponent } from "./pages/contact/customer-contact/customer-contact.component";
import { SupplierContactComponent } from "./pages/contact/supplier-contact/supplier-contact.component";
import { ContactBalanceListComponent } from "./pages/contact/contact-balance-list/contact-balance-list.component";
// Busines Owner

import { BusinessOwnerListComponent } from "./pages/Business Owner/business-owner-list/business-owner-list.component";

//accounting
import { BankAccountsComponent } from "./pages/Accounting/bank-accounts/bank-accounts.component";
import { ChartOfAccountsComponent } from "./pages/Accounting/chart-of-accounts/chart-of-accounts.component";
import { FixedAssetComponent } from "./pages/Accounting/fixed-asset/fixed-asset.component";
import { BankAccountCreateComponent } from "./pages/Accounting/bank-account-create/bank-account-create.component";
import { AddAccountComponentComponent } from "./pages/Accounting/add-account-component/add-account-component.component";
import { DetailsBankAccountsComponent } from "./pages/Accounting/details-bank-accounts/details-bank-accounts.component";

//ConversionBanlance
import { ConversionBalancesComponent } from "./pages/Accounting/ConversionBalance/conversion-balances/conversion-balances.component";
import { HistoricConversionbalancesInvoiceComponent } from "./pages/Accounting/ConversionBalance/historic-conversionbalances-invoice/historic-conversionbalances-invoice.component";
import { HistoricConversionbalancesBillComponent } from "./pages/Accounting/ConversionBalance/historic-conversionbalances-bill/historic-conversionbalances-bill.component";

//Income
import { CreateInvoicePageComponent } from "./pages/Income/create-invoice-page/create-invoice-page.component";
import { EditInvoicePageComponent } from "./pages/Income/edit-invoice-page/edit-invoice-page.component";
import { InvoiceOverviewComponent } from "./pages/Income/invoice-overview/invoice-overview.component";
import { InvoiceDetailsComponent } from "./pages/Income/invoice-details/invoice-details.component";
import { RepeatingInvoiceListComponent } from "./pages/Income/repeating-invoice-list/repeating-invoice-list.component";
//Organization
import { CreateOrganizationComponent } from "./pages/Organizations/create-organization/create-organization.component";

//Products
import { PurchaseProductComponent } from "./pages/Products/purchase-product/purchase-product.component";
import { EditPurchaseProductComponent } from "./pages/Products/edit-purchase-product/edit-purchase-product.component";
import { ProductListComponent } from "./pages/Products/product-list/product-list.component";
import { ViewStorageItemComponent } from "./pages/Products/view-storage-item/view-storage-item.component";
import { ItemListComponent } from "./pages/Products/item-list/item-list.component";
import { FixedAssetListComponent } from "./pages/Inventory/fixed-asset-list/fixed-asset-list.component";
//settings
import { SettingPageComponent } from "./pages/Settings/setting-page/setting-page.component";
import { OrganizationSettingComponent } from "./pages/Settings/organization-setting/organization-setting.component";
import { UserSettingComponent } from "./pages/Settings/user-setting/user-setting.component";
import { SubscriptionComponent } from "./pages/Settings/subscription/subscription.component";
import { SubscriptionListComponent } from "./pages/subscription-List/subscription-list/subscription-list.component";
//Expense
import { CreateNewBillComponent } from "./pages/Expense/create-new-bill/create-new-bill.component";
import { EditBillComponent } from "./pages/Expense/edit-bill/edit-bill.component";
import { BillOverviewComponent } from "./pages/Expense/bill-overview/bill-overview.component";
import { BillDetailsComponent } from "./pages/Expense/bill-details/bill-details.component";
import { BasicBillListComponent } from "./pages/Expense/basic-bill-list/basic-bill-list.component";
import { RepeatingBillListComponent } from "./pages/Expense/repeating-bill-list/repeating-bill-list.component";
import { MonthlyExpenseComponent } from "./pages/Expense/monthly-expense/monthly-expense.component";
import { MonthlyTabComponent } from "./pages/monthly-tab/monthly-tab.component";
//Report
import { GeneralLedgerComponent } from "./pages/Reports/general-ledger/general-ledger.component";
import { AccountTransactionsComponent } from "./pages/Reports/account-transactions/account-transactions.component";
import { TodaysTransactionComponent } from "./pages/Reports/todays-transaction/todays-transaction.component";
import { TrailBalanceComponent } from "./pages/Reports/trail-balance/trail-balance.component";
import { ProfitLossComponent } from "./pages/Reports/profit-loss/profit-loss/profit-loss.component";
import { BalanceSheetComponent } from "./pages/Reports/balance-sheet/balance-sheet.component";
import { StatementOfCashflowComponent } from "./pages/Reports/statement-of-cashflow/statement-of-cashflow.component";
import { CashflowMovementDetailsComponent } from "./pages/Reports/cashflow-movement-details/cashflow-movement-details.component";
//monthly Budget
import { MonthlyBudgetComponent } from "./pages/Budget/monthly-budget/monthly-budget.component";

//Inventory
import { InventoryListComponent } from "./pages/Inventory/inventory-list/inventory-list.component";
import { InventoryBillCreateComponent } from "./pages/Inventory/inventory-bill-create/inventory-bill-create.component";

//Receive Money
import { ReceiveMoneyComponent } from "./pages/ReceiveMoney/create-receive-money/receive-money.component";
import { ViewReceiveMoneyComponent } from "./pages/view-receive-money/view-receive-money/view-receive-money.component";

//Spend Money
import { SpendMoneyComponent } from "./pages/SpendMoney/create-spend-money/spend-money.component";
import { SpendMoneyListComponent } from "./pages/spend-money-list/spend-money-list/spend-money-list.component";
import { ViewSpendMoneyComponent } from "./pages/spend-money-list/view-spend-money/view-spend-money/view-spend-money.component";
//Transfer Money
import { TransferMoneyComponent } from "./pages/TransferMoney/create-transfer-money/create-transfer-money.component";
import { TransferMoneyListComponent } from "./pages/transfer-money/transfer-money-list/transfer-money-list.component";
import { ViewTransferMoneyComponent } from "./pages/transfer-money/view-transfer-money/view-transfer-money.component";
import { AcceptInvitationComponent } from "./pages/accept-invitation/accept-invitation.component";
import { FileShowcaseComponent } from "./pages/file-showcase/file-showcase.component";
import { InvoiceSettingComponent } from "./pages/Settings/invoice-setting/invoice-setting.component";
import { InvoicePreviewComponent } from "./pages/invoice-preview/invoice-preview.component";
import { PrintFileComponent } from "./pages/print-file/print-file.component";
import { ViewPurchaseProductComponent } from "./pages/Products/view-purchase-product/view-purchase-product.component";
import { ReceiveMoneyListComponent } from "./pages/receive-money-list/receive-money-list/receive-money-list.component";

import { ProjectOverviewComponent } from "./pages/Projects/project-overview/project-overview.component";
import { CreateProjectComponent } from "./pages/Projects/create-project/create-project.component";
import { ProjectDetailsComponent } from "./pages/Projects/project-details/project-details.component";

import { FixedAssetSetupComponent } from "./pages/fixed-asset/fixed-asset-setup/fixed-asset-setup.component";
import { FixedAssetDraftComponent } from "./pages/fixed-asset/fixed-asset-draft/fixed-asset-draft.component";
import { FixedAssetRegisteredComponent } from "./pages/fixed-asset/fixed-asset-registered/fixed-asset-registered.component";
import { FixedAssetSolddisposedComponent } from "./pages/fixed-asset/fixed-asset-solddisposed/fixed-asset-solddisposed.component";
import { AssetTypeListComponent } from "./pages/fixed-asset/asset-type-list/asset-type-list.component";
import { FixedAssetDepreciateComponent } from "./pages/fixed-asset/fixed-asset-depreciate/fixed-asset-depreciate.component";
import { AssigneeListComponent } from "./pages/fixed-asset/assignee-list/assignee-list.component";

import { QuotationListComponent } from "./pages/Quotation/quotation-list/quotation-list.component";
import { QuotationCreateComponent } from "./pages/Quotation/quotation-create/quotation-create.component";
import { QuotationDetailsComponent } from "./pages/Quotation/quotation-details/quotation-details.component";
import { EditQuotationComponent } from "./pages/Quotation/edit-quotation/edit-quotation.component";

import { TaxRateListComponent } from "./pages/Settings/tax-rate-list/tax-rate-list.component";
import { CompanyHistoryComponent } from "./pages/user-company-history/company-history/company-history.component";

import { UserProfileComponent } from "./pages/User-Profile/user-profile/user-profile.component";
import { ChangeUserPasswordComponent } from "./pages/change-user-password/change-user-password.component";
import { AddMoreUsersInSubscriptionComponent } from "./pages/add-more-users-in-subscription/add-more-users-in-subscription.component";
import { SubscriptionRenewComponent } from "./pages/subscription-renew/subscription-renew.component";

import { PendingFactoryListComponent } from "./pages/Inventory/pending-factory-list/pending-factory-list.component";
import { FactoryInEntryComponent } from "./pages/Inventory/factory-in-entry/factory-in-entry.component";
import { FactoryInListComponent } from "./pages/Inventory/factory-in-list/factory-in-list.component";
import { FactoryOutEntryComponent } from "./pages/Inventory/factory-out-entry/factory-out-entry.component";
import { FactoryOutListComponent } from "./pages/Inventory/factory-out-list/factory-out-list.component";
import { FactoryOutViewComponent } from "./pages/Inventory/factory-out-view/factory-out-view.component";
import { FactoryStockReportComponent } from "./pages/Inventory/factory-stock-report/factory-stock-report.component";
import { FactoryInViewComponent } from "./pages/Inventory/factory-in-view/factory-in-view.component";
import { EditFactoryInComponent } from "./pages/Inventory/edit-factory-in/edit-factory-in.component";
import { EditFactoryOutComponent } from "./pages/Inventory/edit-factory-out/edit-factory-out.component";
import { AdvanceListComponent } from "./pages/Accounting/advance/advance-list/advance-list.component";
import { QuotationPreviewComponent } from "./pages/Quotation/quotation-preview/quotation-preview.component";
import { BillPreviewComponent } from "./pages/Expense/bill-preview/bill-preview.component";
import { EditBusinessOwnerComponent } from "./pages/Business Owner/edit-business-owner/edit-business-owner.component";
import { PurchaseOrderListComponent } from "./pages/Procurement/purchase-order-list/purchase-order-list.component";
import { PurchaseOrderCreateComponent } from "./pages/Procurement/purchase-order-create/purchase-order-create.component";
// bp loan serction
import { GrantLoanListComponent } from "./components/business-partner-loan/grant-loan-list/grant-loan-list.component";

import { SalesOrderListComponent } from "./pages/sales/sales-order-list/sales-order-list.component";
import { PurchaseOrderDetailsComponent } from "./pages/Procurement/purchase-order-details/purchase-order-details.component";
import { PurchaseOrderPreviewComponent } from "./pages/Procurement/purchase-order-preview/purchase-order-preview.component";
import { PurchaseOrderEditComponent } from "./pages/Procurement/purchase-order-edit/purchase-order-edit.component";
import { SalesOrderDetailsComponent } from "./pages/sales/sales-order-details/sales-order-details.component";
import { ExistingBalanceComponent } from "./pages/Accounting/ConversionBalance/existing-balance/existing-balance.component";
import { SalesOrderCreateComponent } from "./pages/sales/sales-order-create/sales-order-create.component";
import { SalesOrderEditComponent } from "./pages/sales/sales-order-edit/sales-order-edit.component";
import { SalesOrderPreviewComponent } from "./pages/sales/sales-order-preview/sales-order-preview.component";
import { ShipmentEditComponent } from "./pages/sales/shipment-edit/shipment-edit.component";
import { PendingListComponent } from "./pages/pending-list/pending-list.component";
import { StorageItemListComponent } from "./pages/Products/storage-item-list/storage-item-list.component";
import { ApprovalSettingsComponent } from "./pages/Settings/approval-settings/approval-settings.component";
import { BusinessOwnerCreateComponent } from "./pages/Business Owner/business-owner-create/business-owner-create.component";
import { ViewBpLoanComponent } from "./components/business-partner-loan/view-bp-loan/view-bp-loan/view-bp-loan.component";
import { ReceiveLoanListComponent } from "./components/business-partner-loan/Receive-loan-list/receive-loan-list/receive-loan-list.component";
import { ViewOwnerLoanComponent } from "./pages/Business Owner/view-owner-loan/view-owner-loan.component";
import { BusinessPartnerReportComponent } from  './pages/contact/business-partner-report/business-partner-report.component';
import { DocumentOverviewComponent } from "./pages/Document/document-overview/document-overview.component";
import { DeliveryPartnerListComponent } from "./pages/contact/delivery-partner-list/delivery-partner-list.component";
import { BusinessUnitComponent } from "./pages/Busines Unit/business-unit/business-unit.component";
// user - role-set-up

import { CompanyUserRoleListComponent } from './pages/Rule-Set-Up/company-user-role-list/company-user-role-list.component';
import { UserRoleSetUpComponent } from './pages/Rule-Set-Up/user-role-set-up/user-role-set-up.component';
import { UpdateReceiveMoneyComponent } from "./pages/ReceiveMoney/update-receive-money/update-receive-money.component";
import { SingleDocumentViewComponent } from "./pages/Document/single-document-view/single-document-view.component";
import { DeliveryOrderComponent } from "./pages/Inventory/delivery-order/delivery-order.component";
import { DeliveryOrderWaitingComponent } from "./pages/Inventory/delivery-order-waiting/delivery-order-waiting.component";
import { ModalForCreateBillComponent } from "./pages/Inventory/modal-for-create-bill/modal-for-create-bill.component";
import { InventoryOverviewReceiptComponent } from "./pages/Inventory/inventory-overview-receipt/inventory-overview-receipt.component";
import { ReceiptWaitingComponent } from "./pages/Inventory/receipt-waiting/receipt-waiting.component";
import { ModalForReceiveProductComponent } from "./pages/Inventory/modal-for-receive-product/modal-for-receive-product.component";
import { ModalShippedProductComponent } from "./pages/Inventory/modal-shipped-product/modal-shipped-product.component";
import { PurchaseOrderBillDetailsComponent } from "./pages/Procurement/purchase-order-bill-details/purchase-order-bill-details.component";
import { SalesOrderInvoiceDetailsComponent } from "./pages/sales/sales-order-invoice-details/sales-order-invoice-details.component";
import { SalesOrderAdvanceDetailsComponent } from "./pages/sales/sales-order-advance-details/sales-order-advance-details.component";
import { SalesOrderExpenseDetailsComponent } from "./pages/sales/sales-order-expense-details/sales-order-expense-details.component";
import { SalesOrderDeliveryListComponent } from "./pages/sales/sales-order-delivery-list/sales-order-delivery-list.component";
import { BillReceiptListComponent } from "./pages/Expense/bill-receipt-list/bill-receipt-list.component";
import { InvoiceDeliveryOrderListComponent } from "./pages/Income/invoice-delivery-order-list/invoice-delivery-order-list.component";
import { FixedAssetSettingsComponent } from "./pages/fixed-asset/fixed-asset-settings/fixed-asset-settings.component";
import { FixedAssetCreateBillComponent } from "./pages/fixed-asset/fixed-asset-create-bill/fixed-asset-create-bill.component";
import { FixedAssetBillOverviewComponent } from "./pages/fixed-asset/fixed-asset-bill-overview/fixed-asset-bill-overview.component";
import { FixedAssetBillDetailsComponent } from "./pages/fixed-asset/fixed-asset-bill-details/fixed-asset-bill-details.component";
import { FixedAssetEditBillComponent } from "./pages/fixed-asset/fixed-asset-edit-bill/fixed-asset-edit-bill.component";
import { FixedAssetAssignedUnassignedListComponent } from "./pages/fixed-asset/fixed-asset-assigned-unassigned-list/fixed-asset-assigned-unassigned-list.component";
import { ReplenishmentComponent } from "./pages/Products/replenishment/replenishment.component";
import { UpdateSpendMoneyComponent } from "./pages/SpendMoney/update-spend-money/update-spend-money.component";
import { CreateFinishedProductMaterialFormulaComponent } from "./pages/Manufacture/create-finished-product-material-formula/create-finished-product-material-formula.component";
import { FinishedProductMaterialFormulaListComponent } from "./pages/Manufacture/finished-product-material-formula-list/finished-product-material-formula-list.component";
import { ProduceManufacutringOrderComponent } from "./pages/Manufacture/produce-manufacutring-order/produce-manufacutring-order.component";
import { CreateManufacutringOrderComponent } from "./pages/Manufacture/create-manufacutring-order/create-manufacutring-order.component";
import { ManufactureFormulaDetailsComponent } from "./pages/Manufacture/manufacture-formula-details/manufacture-formula-details.component";
import { EditManufactureFormulaComponent } from "./pages/Manufacture/edit-manufacture-formula/edit-manufacture-formula.component";
import { ManufactureOrderListComponent } from "./pages/Manufacture/manufacture-order-list/manufacture-order-list.component";
import { ManufactureOrderDetails } from "./models/manufacture.model";
import { ManufacutreOrderDetailsComponent } from "./pages/Manufacture/manufacutre-order-details/manufacutre-order-details.component";
import { ManufactureOrderProduceDetailsComponent } from "./pages/Manufacture/manufacture-order-produce-details/manufacture-order-produce-details.component";
import { EditManufactureOrderComponent } from "./pages/Manufacture/edit-manufacture-order/edit-manufacture-order.component";
import { DocumentAdvanceSearchComponent } from "./pages/Document/document-advance-search/document-advance-search.component";
import { ReturnPaymentHistoryComponent } from "./pages/Accounting/return-payment-history/return-payment-history.component";
import { SalesReturnOverviewComponent } from "./pages/Inventory/sales-return-overview/sales-return-overview.component";
import { ReturnPolicySettingsComponent } from "./pages/Settings/return-policy-settings/return-policy-settings.component";
import { PurchaseOrderReceiptListComponent } from "./pages/Procurement/purchase-order-receipt-list/purchase-order-receipt-list.component";
import { PurchaseReturnOverviewComponent } from "./pages/Inventory/purchase-return-overview/purchase-return-overview.component";
import { PendingListDashboardComponent } from "./pages/pending-list-dashboard/pending-list-dashboard.component";
import { DocumentSettingsComponent } from "./pages/Settings/document-settings/document-settings.component";
import { DocumentLockUnlockOverviewPageComponent } from "./pages/Document/document-lock-unlock-overview-page/document-lock-unlock-overview-page.component";
import { DocumentActiveInactiveOverviewComponent } from "./pages/Document/document-active-inactive-overview/document-active-inactive-overview.component";
import { DocumentVersionSettingsComponent } from "./pages/Settings/document-version-settings/document-version-settings.component";
const routes: Routes = [
  { path: "auth/login", component: AuthPageComponent },
  { path: "auth/register", component: RegistrationPageComponent },
  { path: "auth/forgot", component: ForgetPasswordComponent },
  { path: "auth/reset-password", component: ResetPasswordComponent },
  { path: "accept-invitation", component: AcceptInvitationComponent },
  {path: 'document/document-overview/:status', component: DocumentOverviewComponent},
  { path: "", component: HomePageComponent, canActivate: [AuthGuard] },
  { path: "document-view/:documentId", component: SingleDocumentViewComponent , canActivate: [AuthGuard] },
  {
    path: "contacts/list/:type",
    component: AllContactListPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contacts/customerlist",
    component: CustomerContactComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contacts/supplierlist",
    component: SupplierContactComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contacts/create-contact",
    component: CreateContactsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contacts/details-contact/:contactId",
    component: ContactPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contacts/contact-balance-list/:typeId",
    component: ContactBalanceListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "accounting/bank-accounts",
    component: BankAccountsComponent,
    canActivate: [AuthGuard],
    data: { canAccessWithoutSubscription: true }
  },
  {
    path: "accounting/bank-accounts-create",
    component: BankAccountCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "accounting/bank-accounts-details/:bankId",
    component: DetailsBankAccountsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "accounting/chart-of-accounts",
    component: ChartOfAccountsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "accounting/create-chart-of-accounts",
    component: AddAccountComponentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "accounting/fixed-asset",
    component: FixedAssetComponent,
    canActivate: [AuthGuard],
  },
  // { path: 'accounting/existing-balances', component: ConversionBalancesComponent, canActivate: [AuthGuard] },
  {
    path: "accounting/existing-balances",
    component: ExistingBalanceComponent,
    canActivate: [AuthGuard],
    data: { canAccessWithoutSubscription: true }
  },
  {
    path: "accounting/historic-conversionbalancesInvoice",
    component: HistoricConversionbalancesInvoiceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "accounting/historic-conversionbalancesBill",
    component: HistoricConversionbalancesBillComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "income/create-invoice",
    component: CreateInvoicePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "income/edit-invoice/:invoiceId",
    component: EditInvoicePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "income/edit-invoice/:invoiceId/:actionType",
    component: EditInvoicePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "income/invoice-overview/:status",
    component: InvoiceOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "income/invoice/:invoiceId",
    component: InvoiceDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "income/invoice/:invoiceId/:orderNo",
    component: InvoiceDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "income/invoice-delivery-order-list/:invoiceNo/:status",
    component: InvoiceDeliveryOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "income/repeating-invoice/:id",
    component: RepeatingInvoiceListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/monthly-expense",
    component: MonthlyExpenseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "budget/monthly-budget",
    component: MonthlyBudgetComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/create-new-bill",
    component: CreateNewBillComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/edit-bill/:billID",
    component: EditBillComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/bill-overview/:status",
    component: BillOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/bill/:billID",
    component: BillDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/bill/:billID/:orderNo",
    component: BillDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/basic-bill-list",
    component: BasicBillListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/repeating-bills/:catId",
    component: RepeatingBillListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/bill-receipt-list/:billNo/:status",
    component: BillReceiptListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "organization/create",
    component: CreateOrganizationComponent,
    canActivate: [AuthGuard],
    data: { canAccessWithoutSubscription: true }
  },
  {
    path: "products/purchase-products",
    component: PurchaseProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "products/edit-purchase-products",
    component: EditPurchaseProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings",
    component: SettingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings/organization",
    component: OrganizationSettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "subscription/manage-subscription",
    component: SubscriptionComponent,
    canActivate: [AuthGuard],
    data: { canAccessWithoutSubscription: true }
  },
  {
    path: "settings/subscription-list",
    component: SubscriptionListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings/user",
    component: UserSettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings/invoice",
    component: InvoiceSettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "products/purchase-products",
    component: PurchaseProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "products/product-list",
    component: ProductListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "products/item-list/:typeId",
    component: ItemListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "products/:id",
    component: ViewPurchaseProductComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "products/replenishment/All",
    component: ReplenishmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "receive-money/:id",
    component: ViewReceiveMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports/general-ledger",
    component: GeneralLedgerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports/todays-transactions",
    component: TodaysTransactionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports/account-transactions",
    component: AccountTransactionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports/trail-balance",
    component: TrailBalanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports/profit-loss",
    component: ProfitLossComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports/balance-sheet",
    component: BalanceSheetComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports/statement-of-cashflow",
    component: StatementOfCashflowComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports/cashflow-movement-details/:movementId/:fromDate/:toDate",
    component: CashflowMovementDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/list",
    component: InventoryListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/create",
    component: InventoryBillCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "receivemoney/create",
    component: ReceiveMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "receivemoney/update/:draftId",
    component: UpdateReceiveMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "receivemoney/receive-money-list/:status",
    component: ReceiveMoneyListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "spendmoney/spend-money-list/:status",
    component: SpendMoneyListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "spendmoney/create",
    component: SpendMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "spendmoney/view-spendmoney/:id",
    component: ViewSpendMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "spendmoney/update/:draftId",
    component: UpdateSpendMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "transfermoney/create",
    component: TransferMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "transfermoney/transfermoney-list",
    component: TransferMoneyListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "transfermoney/view-transfermoney/:id",
    component: ViewTransferMoneyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "files/:name",
    component: FileShowcaseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "files/print/:id",
    component: PrintFileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "invoice/preview/:invoiceId",
    component: InvoicePreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "quotation/preview/:quotationId",
    component: QuotationPreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "bill/preview/:billId",
    component: BillPreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "project/overview",
    component: ProjectOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "project/create",
    component: CreateProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "project/details/:projectId",
    component: ProjectDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/fixed-asset-create-bill",
    component: FixedAssetCreateBillComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/fixed-asset-bill-overview/:status",
    component: FixedAssetBillOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/fixed-asset-bill-details/:billID",
    component: FixedAssetBillDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/fixed-asset-edit-bill/:billID",
    component: FixedAssetEditBillComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/fixed-asset-assigned-unassigned-list/:status",
    component: FixedAssetAssignedUnassignedListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/setup",
    component: FixedAssetSetupComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "draft",
        component: FixedAssetDraftComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "registered",
        component: FixedAssetRegisteredComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "solddisposed",
        component: FixedAssetSolddisposedComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "fixed-asset/:status",
    component: FixedAssetListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-assets/depreciate",
    component: FixedAssetDepreciateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/asset-type-list",
    component: AssetTypeListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/fixed-asset-settings/:status",
    component: FixedAssetSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fixed-asset/assignee-list/:status",
    component: AssigneeListComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "quotation/create-new-quotation",
    component: QuotationCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "quotation/quotation-list/:status",
    component: QuotationListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "quotation/details/:quotationId",
    component: QuotationDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "quotation/edit-quotation/:quotationId",
    component: EditQuotationComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "settings/tax-rate-list",
    component: TaxRateListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings/approval-settings",
    component: ApprovalSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-company-history",
    component: CompanyHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "profile/user-profile",
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "change-password/change-password",
    component: ChangeUserPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-more-users-in-subscription/add-more-users-in-subscription",
    component: AddMoreUsersInSubscriptionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "subscription-renew/subscription-renew",
    component: SubscriptionRenewComponent,
    canActivate: [AuthGuard],
    data: { canAccessWithoutSubscription: true }
  },
  {
    path: "inventory/factory-in-entry",
    component: FactoryInEntryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/factory-in-list/:status",
    component: FactoryInListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/factory-stock-report",
    component: FactoryStockReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/factory-out-entry",
    component: FactoryOutEntryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/factory-out-list/:status",
    component: FactoryOutListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/factory-in-view/:transNo",
    component: FactoryInViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/edit-factory-in/:transNo",
    component: EditFactoryInComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/edit-factory-out/:transNo",
    component: EditFactoryOutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/factory-out-view/:transNo",
    component: FactoryOutViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/pending-list/:status",
    component: PendingFactoryListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "inventory/stock-report",
    component: FactoryStockReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "accounting/advance/advance-list/:status",
    component: AdvanceListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "expense/tab",
    component: MonthlyTabComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-list/:status",
    component: PurchaseOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-create",
    component: PurchaseOrderCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-view/:id",
    component: PurchaseOrderCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-details/:id",
    component: PurchaseOrderDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-details/:id/:orderNo",
    component: PurchaseOrderDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/preview/:id",
    component: PurchaseOrderPreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-edit/:id",
    component: PurchaseOrderEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-bill-details/:id",
    component: PurchaseOrderBillDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-bill-details/:id/:orderNo",
    component: PurchaseOrderBillDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "procurement/purchase-order-receipt-list/:id/:orderNo/:status",
    component: PurchaseOrderReceiptListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-list/:status",
    component: SalesOrderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-details/:id",
    component: SalesOrderDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-details/:id/:orderNo",
    component: SalesOrderDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-create",
    component: SalesOrderCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-edit/:id",
    component: SalesOrderEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-invoice-details/:id",
    component: SalesOrderInvoiceDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-invoice-details/:id/:orderNo",
    component: SalesOrderInvoiceDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-advance-details/:id",
    component: SalesOrderAdvanceDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-expense-details/:id",
    component: SalesOrderExpenseDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/sales-order-delivery-list/:id/:status",
    component: SalesOrderDeliveryListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/shipment-edit/:id",
    component: ShipmentEditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sales/preview/:id",
    component: SalesOrderPreviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "pending-list/:id",
    component: PendingListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "product/storage-item-list",
    component: StorageItemListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "storage-item/:id",
    component: ViewStorageItemComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "owner/business-owner-list",
    component: BusinessOwnerListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "delivery-partner-list",
    component: DeliveryPartnerListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "business-unit",
    component: BusinessUnitComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "owner/business-owner-create",
    component: BusinessOwnerCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "owner/edit-business-owner/:Id",
    component: EditBusinessOwnerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "business-partner/grant-loan-list/:type",
    component: GrantLoanListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "business-partner/view-bp-loan/:id",
    component: ViewBpLoanComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "business-partner/receive-loan-list/:type",
    component: ReceiveLoanListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "business-owner/view-owner-loan/:id",
    component: ViewOwnerLoanComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "business-partner/business-partner-report/:contactId",
    component: BusinessPartnerReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user/company-user-role-list',
    component: CompanyUserRoleListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user/user-role-set-up',
    component: UserRoleSetUpComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'inventory/delivery-order',
    component:DeliveryOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'inventory/delivery-order/:id',
    component:DeliveryOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'inventory/delivery-order-waiting/:id',
    component:DeliveryOrderWaitingComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'inventory/modal-for-create-bill',
    component:ModalForCreateBillComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'inventory/inventory-overview-receipt/:id',
    component:InventoryOverviewReceiptComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'inventory/inventory-overview-receipt/:id/:purchaseOrderNo',
    component:InventoryOverviewReceiptComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'inventory/receipt-waiting/:id',
    component:ReceiptWaitingComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'inventory/modal-for-receive-product',
    component:ModalForReceiveProductComponent,
    canActivate:[AuthGuard],
  },
  {
    path:'inventory/modal-shipped-product',
    component:ModalShippedProductComponent,
    canActivate:[AuthGuard],
  },
  { 
    path: 'manufacture/create-finished-product-material-formula',
    component: CreateFinishedProductMaterialFormulaComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/finished-product-material-formula-list/:status',
    component: FinishedProductMaterialFormulaListComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/manufacture-formula-details/:id',
    component: ManufactureFormulaDetailsComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/edit-manufacture-formula/:id',
    component: EditManufactureFormulaComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/manufacutring-order-list/:status',
    component: ManufactureOrderListComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/produce-manufacutring-order/:id',
    component: ProduceManufacutringOrderComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/create-manufacutring-order',
    component: CreateManufacutringOrderComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/manufacutring-order-details/:id',
    component: ManufacutreOrderDetailsComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/manufacutring-order-produce-details/:id',
    component: ManufactureOrderProduceDetailsComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'manufacture/edit-manufacture-order/:id',
    component: EditManufactureOrderComponent,
    canActivate: [AuthGuard]
  }, 
  { 
    path: 'document/document-advance-search/:status',
    component: DocumentAdvanceSearchComponent,
    canActivate: [AuthGuard]
  },    
  { 
    path: 'acounting/return-payment-history/:operationType/:paymentType',
    component: ReturnPaymentHistoryComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'inventory/sales-return/:status',
    component: SalesReturnOverviewComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'settings/return-policy-settings',
    component: ReturnPolicySettingsComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'inventory/purchase-return/:status',
    component: PurchaseReturnOverviewComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'my-pending-list-dashboard',
    component: PendingListDashboardComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'settings/document-settings',
    component: DocumentSettingsComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'settings/document-version-settings',
    component: DocumentVersionSettingsComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'document/document-lock-unlock-overview/:status',
    component: DocumentLockUnlockOverviewPageComponent,
    canActivate: [AuthGuard]
  },
  { 
    path: 'document/document-active-inactive-overview/:status',
    component: DocumentActiveInactiveOverviewComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  AuthPageComponent,
  ViewOwnerLoanComponent,
  GrantLoanListComponent,
  DocumentOverviewComponent,
  HomePageComponent,
  FixedAssetListComponent,
  RegistrationPageComponent,
  ForgetPasswordComponent,
  ContactPageComponent,
  AllContactListPageComponent,
  CustomerContactComponent,
  SupplierContactComponent,
  CompanyUserRoleListComponent,
  CreateContactsPageComponent,
  MonthlyBudgetComponent,
  BankAccountsComponent,
  MonthlyExpenseComponent,
  FixedAssetComponent,
  ChartOfAccountsComponent,
  ConversionBalancesComponent,
  TodaysTransactionComponent,
  HistoricConversionbalancesInvoiceComponent,
  HistoricConversionbalancesBillComponent,
  CreateInvoicePageComponent,
  EditInvoicePageComponent,
  InvoiceOverviewComponent,
  ReceiveLoanListComponent,
  ResetPasswordComponent,
  CreateOrganizationComponent,
  PurchaseProductComponent,
  EditPurchaseProductComponent,
  SettingPageComponent,
  OrganizationSettingComponent,
  SubscriptionComponent,
  ProductListComponent,
  AcceptInvitationComponent,
  UserSettingComponent,
  CreateNewBillComponent,
  DeliveryPartnerListComponent,
  EditBillComponent,
  BillOverviewComponent,
  BillDetailsComponent,
  InvoiceDetailsComponent,
  AccountTransactionsComponent,
  GeneralLedgerComponent,
  TrailBalanceComponent,
  ProfitLossComponent,
  BalanceSheetComponent,
  StatementOfCashflowComponent,
  InventoryBillCreateComponent,
  InventoryListComponent,
  BankAccountCreateComponent,
  DetailsBankAccountsComponent,
  AddAccountComponentComponent,
  ReceiveMoneyComponent,
  ReceiveMoneyListComponent,
  SpendMoneyComponent,
  TransferMoneyComponent,
  SpendMoneyListComponent,
  ViewReceiveMoneyComponent,
  SubscriptionListComponent,
  TransferMoneyListComponent,
  ViewTransferMoneyComponent,
  ViewSpendMoneyComponent,
  CompanyHistoryComponent,
  UserProfileComponent,
  ChangeUserPasswordComponent,
  AddMoreUsersInSubscriptionComponent,
  SubscriptionRenewComponent,
  FactoryInEntryComponent,
  RepeatingInvoiceListComponent,
  FactoryOutEntryComponent,
  FactoryStockReportComponent,
  PurchaseOrderListComponent,
  PurchaseOrderCreateComponent,
  PurchaseOrderDetailsComponent,
  PurchaseOrderEditComponent,
  ExistingBalanceComponent,
  MonthlyTabComponent,
  PendingListComponent,
  StorageItemListComponent,
  ViewStorageItemComponent,
  EditFactoryInComponent,
  EditFactoryOutComponent,
  PendingFactoryListComponent,
  FixedAssetDepreciateComponent,
  BusinessOwnerListComponent,
  BusinessOwnerCreateComponent,
  EditBusinessOwnerComponent,
  ViewBpLoanComponent,
  BusinessPartnerReportComponent,
  UserRoleSetUpComponent,
  BusinessUnitComponent
];
