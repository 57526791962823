import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { AuthService } from 'src/app/service/auth/auth.service';
import { DocumentType } from 'src/app/models/documentType.model';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { DocumentTypeGroup } from 'src/app/models/documentTypeGroup.model';

@Component({
  selector: 'app-dynamic-label-name-settings-for-document-inputs',
  templateUrl: './dynamic-label-name-settings-for-document-inputs.component.html',
  styleUrls: ['./dynamic-label-name-settings-for-document-inputs.component.css']
})

export class DynamicLabelNameSettingsForDocumentInputsComponent implements OnInit {

  DynamicLabelNameForDocumentInputsForm: FormGroup;
  @Input() parentData: any;
  @Input() operationType: iQuidiActionType;
  dynamicLabelNameLoading = false;

  dynamicLabelNameForDocumentInputsList: any;
  userInfo: any;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private authService: AuthService,
    private settingsService: SettingsServiceService,
    private mainService: MainServiceService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.authService.getUserInfo();
    this.getAllDynamicLabelNameForDocumentInputs();
    this.initializeForm();
    if(!this.mainService.IsNull(this.parentData)) this.setParentData();
  }

  getAllDynamicLabelNameForDocumentInputs() {
    this.settingsService.GetAllDocumentInputFieldDynamicLabelName().subscribe((x) => {
      this.dynamicLabelNameForDocumentInputsList = x;
    });
  }

  initializeForm() {
    this.DynamicLabelNameForDocumentInputsForm = this.fb.group({
      Id: [0],
      FieldName: ['', Validators.required],
      LabelName: ['', Validators.required],
      CompanyId: [0]
    });
  }

  setParentData() {
    this.DynamicLabelNameForDocumentInputsForm.get("Id").patchValue(this.parentData.Id);
    this.DynamicLabelNameForDocumentInputsForm.get("FieldName").patchValue(this.parentData.FieldName);
    this.DynamicLabelNameForDocumentInputsForm.get("LabelName").patchValue(this.parentData.LabelName);
    this.DynamicLabelNameForDocumentInputsForm.get("CompanyId").patchValue(this.parentData.CompanyId);
  }

  // CreateNewDocumentType(fieldName: string) {
  //   let Data: any = {
  //     Id: 0,
  //     FieldName: fieldName,
  //     LabelName: null,
  //     CompanyId: 0
  //   };
  //   this.dynamicLabelNameLoading = true;
  //   this.settingsService.SaveDocumentType(Data).subscribe((x) => {
  //       if (x.Success) {
  //         this.toaster.success("Document input field has been successfully added!");
  //         this.dynamicLabelNameLoading = false;
  //         this.dynamicLabelNameForDocumentInputsList?.push(x["Data"]);
  //         let Obj = this.dynamicLabelNameForDocumentInputsList.filter(x => x.FieldName == fieldName);
  //         this.DynamicLabelNameForDocumentInputsForm.get("Id").patchValue(Obj[0]["Id"]);
  //         this.getAllDynamicLabelNameForDocumentInputs();
  //       } else {
  //         this.toaster.warning(x.Message);
  //       }
  //     },
  //     (err) => {
  //       this.toaster.error("ooh, something went wrong !");
  //       this.dynamicLabelNameLoading = false;
  //     }
  //   );
  // }

  save() {
    if(!this.DynamicLabelNameForDocumentInputsForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    if(this.operationType == iQuidiActionType.Update && this.mainService.IsNull(this.DynamicLabelNameForDocumentInputsForm.value.LabelName)){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    if(this.operationType == iQuidiActionType.Update){
      let availableLabel = this.dynamicLabelNameForDocumentInputsList.find(x => x.LabelName == this.DynamicLabelNameForDocumentInputsForm.value.LabelName);
      if(!this.mainService.IsNull(availableLabel)){
        if(availableLabel.Id != this.DynamicLabelNameForDocumentInputsForm.value.Id){
          this.toaster.warning('This label name already exists!');
          return;
        }
      }
    }
    this.settingsService.UpdateDocumentInputFieldDynamicLabelName(this.DynamicLabelNameForDocumentInputsForm.value).subscribe((x) => {
      if(x?.Success){
        this.toaster.success(x?.Message);
      }
      else{
        this.toaster.error(x?.Message);
      }
      this.activeModal.close('Success');
    })
  }

}

