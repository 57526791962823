import { Component, Input, OnInit, HostListener } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { RouterLinkRendereComponent } from "src/app/components/router-link-rendere/router-link-rendere.component";
import { BillsServiceService } from "src/app/service/Bill/bills-service.service";

@Component({
  selector: 'app-monthly-expense-details',
  templateUrl: './monthly-expense-details.component.html',
  styleUrls: ['./monthly-expense-details.component.css']
})


export class MonthlyExpenseDetailsComponent implements OnInit {

  gridApi;
  @Input() month: number;
  @Input() year: number;
  @Input() accountId: number;
  billList: any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private billService: BillsServiceService
  ) {}

  ngOnInit(): void {
    this.getCurrentMonthBillList();
  }

  @HostListener('click')
  onClick() {
    this.activeModal.close();
  }

  getCurrentMonthBillList() {
    this.billService.GetCurrentMonthBillList(this.month, this.year, this.accountId).subscribe((p) => {
        this.billList = p;
        for(let i=0; i < this.billList.length; i++) {
          this.billList[i].SerialNo = i + 1;
          this.billList[i].BillNo = 'Bill-' + this.billList[i].BillNo;
          if( this.billList[i].ReferenceNo != null ){
            if(this.billList[i].ReferenceNo.length == 10 && this.containsOnlyNumbers(this.billList[i].ReferenceNo)){
              this.billList[i].ReferenceNo = 'PO-' + this.billList[i].ReferenceNo; //remove it when reference no issue solve
            }
          }
        }
      });
  }

  containsOnlyNumbers(referenceNO) {
    return /^[0-9]+$/.test(referenceNO);
  }

  gridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
    },

    columnDefs: [
      { headerName: 'No.',
        field: 'SerialNo',
        maxWidth: 60
      },
      {
        headerName: 'Bill No', field: 'BillNo',
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: {
          inRouterLink: '/expense/bill/',
        }
      },
      { headerName: 'Modified Date', field: 'UpdatedAt', valueFormatter: this.convertDateIntoDisplay },
      { headerName: 'Bill Amount', field: 'BillAmount', valueFormatter: this.convertPaidAmount },
      { headerName: 'Till Total Amount',
        cellRenderer: (params) => {
          return this.getTillTotalAmount(params.data);
        }
      },
      { headerName: 'Due', field: 'DueAmount', valueFormatter: this.convertPaidDueAmount, maxWidth: 80 },
      { headerName: 'Status', field: 'StatusId',valueFormatter: this.setBillStatus, maxWidth: 90 },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
  };

  getTillTotalAmount(data){
    let tillTotal = 0;
    for(let i = 0; i < data.SerialNo; i++) {
      tillTotal += this.billList[i].BillAmount;
    }
    let tillTotalValue = Number(tillTotal.toFixed(2))
    return tillTotalValue.toLocaleString();
  }

  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  valueFormatter(data) {
    if(data.vlue != undefined){
      return data.value;
    }
    else if(data.vlue == undefined){
      return '';
    }
  }

  closeModal() {
    this.activeModal.close("Success");
  }

  onGridReady = (event) => {
    this.gridApi = event.api;
  };

  convertPaidAmount(value) {
    if(value == undefined){
      return ''.toLocaleString();
    }
    else{
      let valueData = Number(value?.data?.BillAmount.toFixed(2))
      return valueData.toLocaleString();
    }
  }

  convertPaidDueAmount(value) {
    if(value == undefined){
      return ''.toLocaleString();
    }
    else{
      let amount = value?.data?.BillAmount - (value?.data?.PaidAmount + value?.data?.DiscountAmount);
      return amount.toLocaleString();
    }
  }

  setBillStatus(id) {
    switch (id?.data?.StatusId) {
      case 1:
        return 'Draft';
      case 2:
        return 'A. Approval';
      case 3:
        return 'A. Payment';
      case 4:
        return 'Paid';
      case 6:
        return 'Overdue';
      case 7:
        return 'Rejected';
      default:
        return '';
    }
  }

}

