import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { BusinessPartnerLoanService } from 'src/app/service/Business-partner-loan/business-partner-loan.service'; 
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BpLoanCreateComponent } from '../../bsuiness-partner-loan-create/bp-loan-create/bp-loan-create.component';

@Component({
  selector: 'app-receive-loan-list',
  templateUrl: './receive-loan-list.component.html',
  styleUrls: ['./receive-loan-list.component.css']
})
export class ReceiveLoanListComponent implements OnInit {

  constructor(
    private mainService: BusinessPartnerLoanService,
    public fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private ngxSmartModalService: NgxSmartModalService,
    private spinner: NgxSpinnerService
  ) { }

  activeCount = 0;
  closeCount = 0;
  grantDataList: any;
  grantData: any;
  private gridApi;
  private gridColumnApi;
  contactStatus: any = {
    "All": 0,
    "active": 1,
    "closed": 2
  };

  columnDefs = [
    { headerName: 'Loan No', field: 'LoanNo', cellStyle : {color: 'blue', cursor: 'pointer'} },
    { headerName: 'Business Partner', field: 'BPName' },
    { headerName: 'Date', field: 'FormattedDate' },
    { headerName: 'Amount', field: 'Amount' },
    { headerName: 'Interest Amount', field: 'InterestAmount'},
    { headerName: 'Loan Terms', field: 'LoanPeriod'},
    { headerName: 'Total', field: 'TotalAmount' },
    { headerName: 'Settlement', field: 'SettleAmount'},
    { headerName: 'Next Settlement', field: 'NextSettleDate'},
    { headerName: 'Balance', field: 'Balance'},
  ];


  domLayout = 'autoHeight';

  defaultColDef = {
    resizable: true,
    flex: 1,
    cellClass: 'cell-wrap-text',
  };
  ngOnInit(): void {
    const type = this.activeRouter.snapshot.paramMap.get('type');
    this.getData(type);
  }

  getData = (status: string) => {

    this.grantData = [] ;

    this.mainService.GetGrantLoan(2).subscribe((x) => {

      this.spinner.hide();
      this.grantDataList = x;
      this.grantData = [...x, ...this.grantData];
      this.grantData.map(c => {
        c.IsSettle === true ? this.closeCount += 1 : this.activeCount += 1;

      });
      this.reLoadRowData(status);
    }, (err) => {
      this.spinner.hide();
    });
  }

  onGridReady(event) {
    this.gridApi = event.api;
    this.gridApi.setHeaderHeight(35);
    this.gridColumnApi = event.columnApi;

  }

  reLoadRowData(status)
  {
    this.grantData = this.contactStatus[status] === this.contactStatus.All ?
    this.grantDataList : this.contactStatus[status] === this.contactStatus.active ?
    this.grantDataList.filter(sup => sup.IsSettle === false) : this.grantDataList.filter(con => con.IsSettle === true);

  }
  onRowClicked(e) {
    const colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName === 'Loan No') {
       this.router.navigate([`business-partner/view-bp-loan/${e.data.Id}`]);
    }

  }

  public createNewItem = () => {
    const modalRef = this.modalService.open(BpLoanCreateComponent,
      { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.fromParent =  2;
    modalRef.result.then((result) => {
      if (result.Success){
        this.getData('All');
      }
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }
}
