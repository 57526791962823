<app-dashboard>
    <div>
        <app-page-header-title
            mainTitle="Sales / {{SalesOrderNo}}"
            subTitle="Back" 
            backURL="/sales/sales-order-list/All">

            <div class="full-width text-align-right">
                <div class="container row col-md-12 mr-0 pr-0 pl-5 ml-5 float-right">
                    <div class="col-md-11 float-right pr-0 mr-0 pl-5 ml-3" [ngClass]="{'col-sm-11': !isEditable}">
                        <div class="dropdown">
                            <button id="attachment_btn" type="button" style="z-index: 1;" class="btn attachment-button"><i id="attachment_btn"
                                style="font-size: 20px !important;" class="material-icons">insert_drive_file</i><span id="attachment_btn"
                                style="color:darkgrey; font-size: 12px; "
                                *ngIf="totalFile>=1">{{totalFile}}
                            </span>
                            </button>
                            <div class="x-tip-tl" style="height: 0px;">
                                <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
                            </div>
                            <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                                <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="6"></app-attachment-uploader>
                            </div>
                        </div>
                        <button *ngIf="isEditable" type="button"  [routerLink]="['/sales/sales-order-edit',SalesOrderId]"  class="eCount-def-btn ml-3">Edit</button>
                        <button *ngIf="isEditable" type="button" style="background: #5cac00;" (click)="saveAsApproved()" class="eCount-def-btn ml-3">Approve</button>
                        <button type="button" (click)="print()" class="eCount-button3 ml-3">Print</button>
                    </div>
                        <!-- <div *ngIf="!isComplete"  style="padding: 7px 10px; color: #c7c2c2;">
                            <div class="dropdown">
                                <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                                    aria-expanded="false">
                                    <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
                                </div>
                                <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                                    style="width: 100%;">
                                    <div *ngIf="isOnGoing && !isOnHold && !isClosed" class="context-menu-item pb-3"  (click)="saveAsOnHold()">
                                        <span class="context-menu-text text-center">On Hold</span>  
                                    </div>
                                    <div *ngIf="!isOnGoing && isOnHold && !isClosed" class="context-menu-item pb-3"  (click)="saveAsOnGoing()">
                                        <span class="context-menu-text text-center">On Going</span>  
                                    </div>
                                    <div *ngIf="!isClosed" class="context-menu-item pb-3"  (click)="saveAsClosed()">
                                        <span class="context-menu-text text-center">Close</span>  
                                    </div>
                                    <div *ngIf="isOnGoing" class="context-menu-item pb-3"  (click)="editSalesOrder()">
                                        <span class="context-menu-text text-center">Edit</span>  
                                    </div>
                                    <div *ngIf="isOnGoing && !isFullOrPartialShipped && totalPaidAmount == 0" class="context-menu-item pb-3"  (click)="deleteSalesOrder()">
                                        <span class="context-menu-text text-center">Delete</span>  
                                    </div>
                                    <div *ngIf="canMarkAsCompleted()" class="context-menu-item pb-3"  (click)="MarkAsComplete()">
                                        <span class="context-menu-text text-center">Mark as complete</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div *ngIf="!isComplete"  style="padding: 7px 10px; color: #c7c2c2;">
                            <div class="dropdown">
                                <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                                    aria-expanded="false">
                                    <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
                                </div>
                                <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                                    style="width: 100%;">
                                    <div *ngIf="canEdit()" class="context-menu-item pb-3"  (click)="editSalesOrder()">
                                        <span class="context-menu-text text-center">Edit</span>  
                                    </div>
                                    <div *ngIf="canDelete()" class="context-menu-item pb-3"  (click)="deleteSalesOrder()">
                                        <span class="context-menu-text text-center">Delete</span>  
                                    </div>
                                    <div *ngIf="canOnHold()" class="context-menu-item pb-3"  (click)="saveAsOnHold()">
                                        <span class="context-menu-text text-center">On Hold</span>  
                                    </div>
                                    <div *ngIf="canOnGoing()" class="context-menu-item pb-3"  (click)="saveAsOnGoing()">
                                        <span class="context-menu-text text-center">On Going</span>  
                                    </div>
                                    <div *ngIf="canClose()" class="context-menu-item pb-3"  (click)="saveAsClosed()">
                                        <span class="context-menu-text text-center">Close</span>  
                                    </div>
                                    <div *ngIf="canMarkAsCompleted()" class="context-menu-item pb-3"  (click)="MarkAsComplete()">
                                        <span class="context-menu-text text-center">Mark as Complete</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </app-page-header-title>
        <div>
            <div *ngIf="isOnGoing" class="contianer full-width" style="margin-bottom: 15px; margin-top: 0px;">
                <div class="row nav nav-tabs d-flex justify-content-end nav-border" id="myTab" role="tablist">
                    <div class="col-md-4 nav-item nav-bar nav-bar-border"></div>
                    <div class="col-md-2 nav-item nav-link nav-bar-border text-center" [routerLink]="['/sales/sales-order-advance-details', SalesOrderId]">
                        <a>Advance({{advanceData}})</a>
                    </div>
                    <div *ngIf="!isAllItemsDescriptiveOrService" class="col-md-2 nav-item nav-link nav-bar nav-bar-border text-center" [routerLink]="['/sales/sales-order-delivery-list', SalesOrderId, 'All']">
                        <a>Delivery Orders({{wareHousedata}})</a>
                    </div>
                    <div class="col-md-2 nav-item nav-link nav-bar nav-bar-border text-center" [routerLink]="['/sales/sales-order-invoice-details', SalesOrderId, SalesOrderNo]">
                        <a>Invoices({{invoiceData}})</a>
                    </div>
                    <div *ngIf="!isAllItemsDescriptiveOrService" class="col-md-2 nav-item nav-link nav-bar text-center" [routerLink]="['/sales/sales-order-expense-details', SalesOrderId]">
                        <a>Expense({{expenseData}})</a>
                    </div>
                </div>
            </div>    
        </div>
        <div class="m">
            <div class="row">
                <div class="col-md-12 d-flex f-ss-14px" id="my1">
                    <div class="col-md-3 d-block pl-1">
                        <p class="ToHeading">To</p>
                        <div>Name : {{SalesOrderData.Contacts ? SalesOrderData.Contacts.CompanyName : ''}}</div>
                        <div>Email : {{SalesOrderData.Contacts ? SalesOrderData.Contacts.Email : ''}}</div>
                        <div>{{SalesOrderData.Contacts ? SalesOrderData.Contacts.StreetAddress : ''}}</div>
                        <div>{{SalesOrderData.Contacts ? SalesOrderData.Contacts.ContactNo : ''}}</div>
                        <!-- <div style="margin-top: 10px;">
                            <label style="font-weight: bold;">Sales Representative: <span style="margin-left: 5px;">{{SalesOrderData.SalesRepresentative}}</span></label>
                        </div> -->
                    </div>
                    <div class="col-md-3">
                        <p class="ToHeading">Date</p>
                        <!-- <div>Created Date : {{convertDateIntoDisplay(SalesOrderData.CreatedAt)}}</div> -->
                        <div>Order Date : {{convertDateIntoDisplay(SalesOrderData.OrderDate)}}</div>
                        <div>Delivery Date : {{convertDateIntoDisplay(SalesOrderData.DeliveryDate)}}</div>
                        <!-- <div>Modified By : {{SalesOrderData.ModifiedByName ? SalesOrderData.ModifiedByName : SalesOrderData.CreatedByName}}</div>
                        <div>Modified Date :  {{convertDateIntoDisplay(SalesOrderData.UpdatedAt ? SalesOrderData.UpdatedAt : SalesOrderData.CreatedAt)}} </div> -->
                    </div>
                    <div class="col-md-3">
                        <p class="ToHeading">Order Details</p>
                        <!-- <div *ngIf="canQuotationShow">Quotation No # {{SalesOrderData.Quotation ? SalesOrderData.Quotation.QuotationNo:''}}</div> -->
                        <div *ngIf = "canQuotationShow">Quotation No # <a [routerLink]="['/quotation/details', 'QU-'+SalesOrderData.Quotation.QuotationNo]">{{SalesOrderData.Quotation ? SalesOrderData.Quotation.QuotationNo:''}}</a></div>
                        <div>Reference No # {{SalesOrderData.ReferenceNo}}</div>
                        <div>Sales No # {{SalesOrderData.OrderNo}}</div>
                    </div>
                    <div class="col-md-1">
                        <div class="col-md-4">
                            <b style="font-weight: bold;">{{twoDecimalPlace(totalUnInvoice)}}</b>
                            <p>Uninvoiced</p>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="col-md-4">
                            <b style="font-weight: bold;">{{twoDecimalPlace(totalInvoice)}}</b>
                            <p>Invoiced</p>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="col-md-4">
                            <b style="font-weight: bold;">{{twoDecimalPlace(totalExpense)}}</b>
                            <p>Expenses</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row f-ss-14px" >
                <div class="col-md-12 tax exchange" >
                    <div class="float-right" id='my2'>
                        <label> Amounts are  </label>
                        <strong *ngIf="SalesOrderData.TaxTypeId==1"> VAT/AIT Exclusive  </strong>
                        <strong *ngIf="SalesOrderData.TaxTypeId==2"> VAT/AIT Inclusive  </strong>
                        <strong *ngIf="SalesOrderData.TaxTypeId==3"> No VAT/AIT  </strong>
                    </div>
                </div>
                <div class="invoice-grid col-md-12" id="my" >
                    <table class="w-100" border="1|0">
                        <thead>
                            <tr>
                            <td>Item</td>
                            <td>Description</td>
                            <td *ngIf="!isAllItemsDescriptiveOrService">Balance</td>
                            <td>Quantity</td>
                            <td *ngIf="!isAllItemsDescriptiveOrService">Shipped</td>
                            <td>Unit Price</td>
                            <td>VAT Rate</td>
                            <td>AIT Rate</td>
                            <td class="amount"> <div class="float-right">Amount BDT</div></td>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of SalesOrderData.SalesOrderItem; let i = index">
                                <td>{{formatItem(item.ItemId)}}</td>
                                <td>{{item.Description}}</td>
                                <td *ngIf="!item.isDescriptiveOrServiceItem">{{item.Balance}}</td>
                                <!-- <td *ngIf="item.HasItem && isDescriptiveSalesOrder() == false">{{item.Balance}}</td>
                                <td *ngIf="!item.HasItem && isDescriptiveSalesOrder() == false && item.ItemId != null"style="background-color: #FFE4B5">{{item.Balance}}</td>
                                <td *ngIf="isDescriptiveSalesOrder() == false && item.ItemId == null">-</td> -->
                                <td *ngIf="!isAllItemsDescriptiveOrService && item.isDescriptiveOrServiceItem">-</td>
                                <td>
                                    {{item.Quantity}}
                                    <span *ngIf="canShowDangerIcon(item, i)" id="dangerBtn" class="deletebtn">
                                        <img style="height: 33px;" src="../../assets/icons/danger2.jpg" />
                                    </span>
                                    <span *ngIf="canShowSuccessIcon(item, i)" id="dangerBtn" class="deletebtn">
                                        <img style="height: 33px" src="../../assets/icons/successImg.jpg"/>
                                    </span>
                                </td>
                                <td *ngIf="!item.isDescriptiveOrServiceItem" [ngStyle]="{'color': isShippedMaxOrMin(item.Quantity, SalesOrderData.ShippedItems[i])? 'red' : '#444'}">{{SalesOrderData.ShippedItems[i]}}</td>
                                <td *ngIf="!isAllItemsDescriptiveOrService && item.isDescriptiveOrServiceItem">-</td>
                                <td>{{twoDecimalPlace(item.UnitPrice)}}</td>
                                <td>{{formatTaxRate(item.TaxRateId)}}</td>
                                <td>{{formatTaxRate(item.AdvanceTaxRateId)}}</td>
                                <td><div class="float-right">{{twoDecimalPlace(item.LineTotal)}}</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row f-ss-14px">
                <!-- <div style="margin-top: 10px;margin-left: 30px;">
                    <div class="row">
                        <div>
                            <label style="background-color: rgba(255, 255, 0, 0.5);height: 15px;width: 15px;margin-top: 5px;"></label>
                        </div>
                        <div style="margin-left: 10px;margin-top: 2px;">
                            <label>Stock Out</label>
                        </div>
                    </div>
                    <div class="row">
                        <div>
                            <label style="background-color: rgba(255, 174, 0, 0.712);height: 15px;width: 15px;margin-top: 5px;"></label>
                        </div>
                        <div style="margin-left: 10px;margin-top: 2px;">
                            <label>Manufacture Stock Out</label>
                        </div>
                    </div>
                </div> -->
                <div class="container-bottom d-flex w-100">
                    <div class="col-md-8 col-sm-12 p-0 d-block">
                        <div style="margin-left: 30px;">
                            <div class="form-group col-sm-5 row">
                                <label>Notes</label>
                                <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea">{{SalesOrderData.Notes}}</textarea>
                                <span class="remaning" >{{myInput.value.length}}/250</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label>Subtotal</label>
                            </div>
                            <div class="col-md-4 float-right"><p class="float-right pr-3">{{twoDecimalPlace(subTotalAmount)}}</p></div>
                        </div>
                        <div class="d-flex w-100 pb-1">
                            <div class="col-md-8">
                                <label>Total VAT</label>
                            </div>
                            <div class="col-md-4"> <p class="float-right pr-3">{{SalesOrderData.TaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100 pb-1">
                            <div class="col-md-8">
                                <label>Total AIT</label>
                            </div>
                            <div class="col-md-4"> <p class="float-right pr-3">{{SalesOrderData.AdvanceTaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100 singleLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label class="totalTextValue">Total</label>
                            </div>
                            <div class="col-md-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{twoDecimalPlace(SalesOrderData.OrderAmount)}}</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div>
        <div class="contianer full-width">
        <div *ngIf="hasManufacturerItem" class="row nav nav-tabs" id="myTab" role="tablist">
            <div class="col-md-2 pl-3 ml-3 nav-item nav-link {{one}}" id="home-tab" data-toggle="tab" (click)="openAdvanceTab()" role="tab" aria-controls="home" aria-selected="true">
                <a>Advance</a>
              </div>
            <div class="col-md-2 nav-item nav-link {{two}}" id="home-tab" data-toggle="tab" (click)="openManufacturingTab()" role="tab" aria-controls="home" aria-selected="true">
              <a>Manufacturing Products</a>
            </div>
            <div class="col-md-2 nav-item nav-link {{three}}" id="shipment-tab" data-toggle="tab" (click)="openShipmentTab()" role="tab" aria-controls="shipment" aria-selected="true">
              <a class="" >Shipment & Invoices</a>
            </div>
            <div class="col-md-2 nav-item nav-link {{four}}" id="expense-tab" data-toggle="tab" (click)="openExpenseTab()" role="tab" aria-controls="expense" aria-selected="true">
              <a class="">Expense</a>
            </div>
            <div class="col-md-3 ml text-align-right" *ngIf="isAdvanceShow && totalUnInvoice > 0">
                <button class="eCount-def-btn mb-2 btnWidth" (click)="createNewAdvance()">Add Advance</button>
            </div>
            <div class="col-md-6" data-toggle="tab"></div>
        </div>
          <div *ngIf="!hasManufacturerItem" class="row nav nav-tabs" id="myTab" role="tablist">
            <div class="col-md-2 pl-3 ml-3 nav-item nav-link {{one}}" id="home-tab" data-toggle="tab" (click)="openAdvanceTab()" role="tab" aria-controls="home" aria-selected="true">
                <a>Advance</a>
              </div>
            <div class="col-md-2  nav-item nav-link {{three}}" id="shipment-tab" data-toggle="tab" (click)="openShipmentTab()" role="tab" aria-controls="shipment" aria-selected="true">
                <a class="">Shipment & Invoices</a>
            </div>
            <div class="col-md-2 nav-item nav-link {{four}}" id="expense-tab" data-toggle="tab" (click)="openExpenseTab()" role="tab" aria-controls="expense" aria-selected="true">
              <a class="">Expense</a>
            </div>
            <div class="col-md-4 ml"></div>
            <div class="col-md-1 text-align-right" *ngIf="isAdvanceShow && totalUnInvoice > 0">
                <button class="eCount-def-btn mb-2 btnWidth" (click)="createNewAdvance()">Add Advance</button>
            </div>
        </div>
    </div>    
    <div *ngIf="isAdvanceShow" style="margin-top: 15px;">

        <div class="row pt-3 mt-4" *ngIf="isAdvanceShow">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="advanceList"
                [columnDefs]="advGridOptions.advanceColumnDefs"
                [domLayout]="advGridOptions.domLayout"
                [defaultColDef]="advGridOptions.defaultColDef"
                (gridReady)="onAdvanceGridReady($event)"
                >
            </ag-grid-angular>
        </div>
    </div>
          <div *ngIf="isShipmentShow" style="margin-top: 15px;">
            <div class="col-md-12 d-flex f-ss-14px" id="my1">
                <div class="col-md-2 d-block pl-1">
                    <b style="font-weight: bold;">{{twoDecimalPlace(totalUnInvoice)}}</b>
                        <p>Uninvoiced</p>
                </div>
                <div class="col-md-7 pl-0 ml-0">
                    <div class="col-md-4">
                        <b style="font-weight: bold;">{{twoDecimalPlace(totalInvoice)}}</b>
                        <p>Invoiced</p>
                    </div>
                </div>
                <div *ngIf="!isClosed && totalUnInvoice > 0" class="col-md-3">
                    <div style="margin-left: 25px;" class="full-width text-align-right">
                        <button type="button" (click)="createNewInvoice()" class="eCount-def-btn ml-4">Create Invoice</button>
                    </div>
                </div>
            </div>

            <div class="row pt-3" *ngIf="showInvoice">
                <ag-grid-angular
                    style="font-size: 14px;"
                    class="ag-theme-balham col"
                    [rowData]="salesInvoices"
                    [columnDefs]="invoiceGridOptions.columnDefs"
                    [domLayout]="invoiceGridOptions.domLayout"
                    [defaultColDef]="invoiceGridOptions.defaultColDef"
                    (gridReady)="onInvoiceGridReady($event)"
                    >
                </ag-grid-angular>
            </div>
            <div style="margin-top: 15px;" class="col-md-12 d-flex" id="my1">
                <div class="col-lg-2 col-md-4 col-sm-5 pl-1 d-block">
                    <b style="font-weight: bold;">{{twoDecimalPlace(shippedAmount)}}</b>
                        <p>Shipped Amount</p>
                </div>
                <div class="col-md-7 ml-0 pl-0">
                    <div class="col-md-4 col-lg-4 ml-0">
                        <b style="font-weight: bold;">{{twoDecimalPlace(raminingAmount)}}</b>
                        <p>Remaining Amount</p>
                    </div>
                </div>
                <div *ngIf="!isClosed" class="col-md-3">
                    <div style="margin-left: 25px;" class="full-width text-align-right">
                        <button type="button" (click)="createNewShipment()" class="eCount-def-btn ml-4">Create Shipment</button>
                    </div>
                </div>
            </div>
            <div class="row pt-3" *ngIf="showShipment">
                <ag-grid-angular
                    style="font-size: 14px;"
                    class="ag-theme-balham col"
                    [rowData]="shipmentList"
                    [columnDefs]="shipmentGridOptions.columnDefs"
                    [domLayout]="shipmentGridOptions.domLayout"
                    [defaultColDef]="shipmentGridOptions.defaultColDef"
                    (gridReady)="onShipmentGridReady($event)"
                    >
                </ag-grid-angular>
            </div>
        </div>
        
        <div *ngIf="isExpenseShow" style="margin-top: 15px;">
            <div class="row pt-3">
                <ag-grid-angular
                    style="font-size: 14px;"
                    class="ag-theme-balham col"
                    [rowData]="salesExpenses"
                    [columnDefs]="expenseGridOptions.columnDefs"
                    [domLayout]="expenseGridOptions.domLayout"
                    [defaultColDef]="expenseGridOptions.defaultColDef"
                    (gridReady)="onExpenseGridReady($event)"
                    >
                </ag-grid-angular>
            </div>
        </div>
        <div *ngIf="isManufactureShow" class="col-md-12" style="margin-top: 15px;">
            <div *ngFor="let s of salesManufacturer">
                <div class="col-md-8" style="margin-top: 30px;">
                    <div class="row">
                        <div class="col-md-3 pl-0 ml-1">
                            <label>Item:<span style="padding-left: 10px;">{{s.ItemCode}}</span></label>
                        </div>
                        <div class="col-md-3 ml-2 pl-0">
                            <label>Description:<span style="padding-left: 10px;">{{s.ItemName}}</span></label>
                        </div>
                    </div>
                </div>
                <div class="row pt-3">
                    <ag-grid-angular
                        style="font-size: 14px;"
                        class="ag-theme-balham col"
                        [rowData]="s.ItemTransactions"
                        [columnDefs]="manufactureGridOptions.columnDefs"
                        [domLayout]="manufactureGridOptions.domLayout"
                        [defaultColDef]="manufactureGridOptions.defaultColDef"
                        (gridReady)="onManufactureGridReady($event)"
                        >
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div> -->
</app-dashboard>