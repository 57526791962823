<app-dashboard>
    <app-page-header-title mainTitle="{{itemListTitle}}" subTitle="Back" backURL="{{RedirectTo}}">
        <div class="full-width text-align-right">
            <div class="row">
                <div class="col-md-11" [ngClass]="{ 'col-md-12' : !canShowDropdownButton()}">
                    <button type="button" class="eCount-def-btn ml-2" style="background: #5cac00;" (click)="ReceiveProduct()" *ngIf="allData?.WareHouseData?.StatusId != 2">Receive Product</button>
                    <button type="button" class="eCount-def-btn ml-4 blueBg" (click)="returnProduct()" *ngIf="allData?.WareHouseData?.StatusId == 2 && allData?.CanPurchaseReturn && allData?.WareHouseData?.OperationType != 5 && allData?.WareHouseData?.OperationType != 6 && allData?.WareHouseData?.OperationType != 7 && allData?.WareHouseData?.OperationType != 8">Return</button>
                </div>
                <div class="col-md-1">
                    <div style="padding-top: 3px; padding-bottom: 5px; color: #c7c2c2;" *ngIf="canShowDropdownButton()">
                        <div class="dropdown">
                            <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                <img src="../../../../assets/image/icons/more.png" alt="" class="moreIcon">
                            </div>
                            <div class="dropdown-menu context-menu-wrapper mt-3" aria-labelledby="navbarDropdown" style="width: 100%;">
                                <div class="context-menu-item" style="cursor: pointer;" (click)="CancelItemReturned()">
                                  <span class="context-menu-text text-center pl-2">Cancel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </app-page-header-title>
    
    <div class="m" id="printBill">
        <div class="row">
            <div class="col-md-12 d-flex f-ss-14px" id="my1">
                <div class="col-md-4 d-block pl-1">
                    <p class="ToHeading">To</p>
                    <div>Name : {{allData?.WareHouseData?.Contacts ? allData?.WareHouseData?.Contacts?.CompanyName : ' ' }}</div>
                    <div>Email : {{allData?.WareHouseData?.Contacts ? allData?.WareHouseData?.Contacts?.Email : ' ' }}</div>
                    <div>Address : {{allData?.WareHouseData?.Contacts ? allData?.WareHouseData?.Contacts?.StreetAddress : ' ' }}</div>
                    <div *ngIf="allData?.WareHouseData?.ParentId && allData?.WareHouseData?.OperationType != 5 && allData?.WareHouseData?.OperationType != 6"> BackOrder Of : <a [href]="getWareHouseBackOrderReferenceNoLink()">{{getWareHouseBackOrderReferenceNo()}}</a>
                    </div>
                </div>
                <div class="col-md-4">
                    <p class="ToHeading">Date</p>
                    <div *ngIf="PurchaseOrderData != null">Order Date : {{ convertDateIntoDisplay( PurchaseOrderData?.OrderDate) }}</div>
                    <div *ngIf="PurchaseOrderData != null">Delivery Date : {{ convertDateIntoDisplay( PurchaseOrderData?.DeliveryDate) }}</div>
                    <div *ngIf="BillData != null">Approved Date : {{ convertDateIntoDisplay( BillData?.UpdatedAt) }}</div>
                    <div *ngIf="BillData != null">Due Date : {{convertDateIntoDisplay(BillData?.DueDate) }}</div>
                    <div *ngIf="SalesOrderData != null">Order Date : {{ convertDateIntoDisplay( SalesOrderData?.OrderDate) }}</div>
                    <div *ngIf="SalesOrderData != null">Delivery Date : {{ convertDateIntoDisplay( SalesOrderData?.DeliveryDate) }}</div>
                    <div *ngIf="InvoiceData != null">Approved Date : {{ convertDateIntoDisplay( InvoiceData?.UpdatedAt) }}</div>
                    <div *ngIf="InvoiceData != null">Due Date : {{convertDateIntoDisplay(InvoiceData?.DueDate) }}</div>
                </div>
                <div class="col-md-4">
                    <p class="ToHeading" *ngIf="BillData != null">Bill Details</p>
                    <p class="ToHeading" *ngIf="PurchaseOrderData != null">Purchase Order Details</p>
                    <p class="ToHeading" *ngIf="this.allData?.WareHouseData?.OperationType == 5 || this.allData?.WareHouseData?.OperationType == 6">Return Details</p>
                    <div>Reference No # <span *ngIf="allData?.WareHouseData?.OperationType == 5 || allData?.WareHouseData?.OperationType == 6" style="font-weight: bold;">Return Of </span> <a href={{getReferenceNoLink()}}>{{getReferenceNo()}}</a>
                    </div>
                    <div *ngIf="isavailable">Product Availability <span class="text-success" style="margin-left: 5px;">Available</span>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="headSection">
            <form [formGroup]="billForm">
                <div class="row d-block topSection f-ss-14px">
                    <div class="d-flex">
                        <div class="col-md-2">Product</div>
                        <div class="col-md-3"></div>
                        <div class="col-md-2">Quantity</div>
                        <div class="col-md-3"></div>
                        <div class="col-md-2 ml-1">Received</div>
                    </div>
                    <hr />
                    <div>
                        <div class="invoiceGridItemRow d-block" formArrayName="ShippedFormArray" *ngFor="let bill of billForm.get('ShippedFormArray')['controls']; let i = index;">
                            <div class="row pb-1 pt-2" [formGroupName]="i">
                                <div class="col-md-2 margin-top-5">
                                    <input class="form-control" formControlName="Product" readonly>
                                </div>
                                <div class="col-md-3"></div>
                                <div class="col-md-2 margin-top-5">
                                    <input class="form-control" formControlName="Quantity" readonly>
                                </div>
                                <div class="col-md-3"></div>
                                <div class="col-md-2 margin-top-5">
                                    <input class="form-control" formControlName="Received" autofocus='' value='0' [readonly]="allData?.WareHouseData?.StatusId == 2" appDecimaNumericValidator (focus)="onFocusEvent($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-dashboard>