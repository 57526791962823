import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MainServiceService} from '../../../service/Main/main-service.service';
@Component({
  selector: 'app-company-history',
  templateUrl: './company-history.component.html',
  styleUrls: ['./company-history.component.css']
})
export class CompanyHistoryComponent implements OnInit {

  constructor(private mainService: MainServiceService, private router: Router) { }
  SubscriptionList: any;
  subscription = "subscription/manage-subscription";
  ngOnInit(): void {
    this.getSubscribeCompanyListByUser();
  }

  getSubscribeCompanyListByUser(){

    this.mainService.GetSubscribedCompanyListByUser().subscribe((x) => {
       this.SubscriptionList = x;
       console.log(this.SubscriptionList);
    })
  }
  public goUrl() {
    this.router.navigate([this.subscription]);
  }
  public goCompanyUrl() {
    this.router.navigate(['organization/create']);
  }
}
