
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { QuotationService } from '../../../service/Quotation/quotation.service';
import { CreateQuotation,QuotationItem } from '../../../models/quotation.model';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DataService } from '../../../service/EventEmitter/data.service'; 
import { NgbModal, NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseProductComponent } from '../../Products/purchase-product/purchase-product.component';
import { environment } from 'src/environments/environment';
import { FormBuilder } from '@angular/forms';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import * as moment from 'moment';
import { ItemCreateComponent } from '../../Products/item-create/item-create.component';
import { CreateItem } from 'src/app/models/product.model';
import { CreatePopupItemComponent } from '../../Products/create-popup-item/create-popup-item.component';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
@Component({
  selector: 'app-edit-quotation',
  templateUrl: './edit-quotation.component.html',
  styleUrls: ['./edit-quotation.component.css']
})
export class EditQuotationComponent implements OnInit {

  public aModel : CreateItem= new CreateItem();
  allCustomer: any = [];
  allSaleableItem: any = [];
  PurchaseableItems: any = [];
  quotationData: CreateQuotation;
  isItemListUpdate = false;
  selectedSupplier: number;
  selectedDate: Date;
  selectedDueDate: Date;
  ReferenceNo: string = "";
  quotationItem: QuotationItem[] = [];
  quotationId: number;
  quotationNo : any;
  quotationFound: boolean = false;
  isUploaded: boolean = false;
  uploadCounter: number;
  quotationNotes: string;
  filesToUpload: number = 0;
  baseurl = environment.baseUrl;
  baseFileUrl = environment.fileBaseUrl;
  totalPrice: number = 0.00;
  ifSubmit: boolean;
  loading = false;
  PrimaryPerson;
  PrimaryPersonFirstName;
  sDate:string;
  sDueDate:string;
  forms;
  IsActive: boolean=true;
  taxTypeList:any=[];
  taxRateList:any=[];
  TaxTypeId:null;
  subTotalAmount: number = 0.00;
  TaxAmount: number = 0.00;
  AdvanceTaxAmount: number = 0.00;
  totalFile: number=0;
  ShowQuotationNo: string;
  postQuotation:string;
  prevQuotation:number;

  constructor(private mainService: MainServiceService,
    private quotationService: QuotationService,
    private dataService: DataService,
    public ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private router: Router,
    private modalService: NgbModal, 
    private spinner: NgxSpinnerService,
    private activeroute: ActivatedRoute,
    private settingService:SettingsServiceService,
    private fileEmitterService:FileEmitterService,
    public fb: FormBuilder) { }

  ngOnInit(): void {
    this.quotationId  =Number(this.activeroute.snapshot.paramMap.get("quotationId"));
    this.getQuotationData();
    this.getFormData();

    this.dataService.change.subscribe(x => {
      this.isItemListUpdate = x;
      this.getAllPurchaseableItem();
      this.getAllSaleableItem();
    });

    this.forms = this.fb.group({
      selectedDate:[],
      selectedDueDate:[],
     });
  }
  showSelectedDate(e) {
    const value = this.sDate;
    $('#qitest1').attr('min', value.split('T')[0]);
    $("#qitest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
  showSelecteDuedDate(e) {
    const value = this.sDueDate;
    $("#qitest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }

  generateQuotationNo = (quotationNumber) => {
    let val = quotationNumber.toString(); let pre = "", post = "";
    for(let i = 0; i < 6; i++) pre += val[i].toString();
    for(let i = 6; i < 10; i++) post += val[i].toString();
    this.quotationNo = pre+post; //check quotation no is valid using this variable
    this.ShowQuotationNo = pre; // this is used only UI
    this.prevQuotation = Number(pre);  //check quotation no is valid using this variable
    this.postQuotation = post; // this is used only UI
}

  getQuotationData=()=>{
    this.quotationService.GetQuotation(this.quotationId).subscribe((x) => {
      if (x) {
        this.quotationData = x;      
        this.fileEmitterService.emitFileRefIdUpdate({id:this.quotationData.Id});
        this.PrimaryPerson = x.Contacts.Id;
        this.PrimaryPersonFirstName = x.Contacts.PrimaryPersonFirstName;
        this.quotationNo = x.QuotationNo;
        this.generateQuotationNo(this.quotationNo);
        this.ReferenceNo = x.ReferenceNo;
        this.IsActive=x.IsActive;
        this.quotationFound = true;
        this.totalPrice=x.Amount;    
        this.TaxAmount=x.TaxAmount;
        this.AdvanceTaxAmount=x.AdvanceTaxAmount;
        this.subTotalAmount=x.Amount-x.TaxAmount-x.AdvanceTaxAmount;
        this.TaxTypeId=x.TaxTypeId,
        this.sDate = x.QuotationDate;
        this.sDueDate = x.DueDate;
        this.quotationNotes= x.Notes;
        this.showSelectedDate(this.sDate);
        this.showSelecteDuedDate(this.sDueDate);
        this.quotationItem = x.QuotationItem;
        this.itemGenerator();
      }
    })
  }

  public setTotalFile(tf:number) {
    this.totalFile=tf;
  }

  getFormData = () => {
    this.getAllCustomer();
    this.getAllPurchaseableItem();
    this.getTaxTypeList();
    this.getTaxRateList();
    this.getAllSaleableItem();
  }
  changesellableItems = (index) => {
    for (let i = 0; i < this.allSaleableItem.length; i++) {
      if (this.allSaleableItem[i].Id == this.quotationItem[index]['ItemId']) {
        this.quotationItem[index]['ItemId'] = this.allSaleableItem[i]['Id'];
        this.quotationItem[index]['Description'] = this.allSaleableItem[i]['SalesDescription'];
        this.quotationItem[index]['AccountId'] = this.allSaleableItem[i]['SaleAccountId'];
        this.quotationItem[index]['Quantity'] = 1;
        this.quotationItem[index]['UnitPrice'] = this.allSaleableItem[i]['SaleUnitPrice'];
        this.updateDataInvoiceItem(this.quotationItem[index]['ItemId']);
      }
    }
  }
  updateDataInvoiceItem = (itemId) => {
    this.quotationItem.map((x) => {
      if (x.ItemId == itemId) { x.LineTotal = x.Quantity * x.UnitPrice }
    })
    this.updateTotalPrice()
  }
  openItemCreateModal(selectItem: NgSelectComponent){
    selectItem.close();
    const modalRef = this.modalService.open(CreatePopupItemComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
    modalRef.componentInstance.refId = 2;
    modalRef.result.then((result) => {
      this.getAllSaleableItem();
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });

  }
  getAllSaleableItem = () => {
    this.quotationService.GetAllSaleableItem().subscribe((x: []) => {
      this.allSaleableItem = x;
      if (this.allSaleableItem.length == 0) {
        this.allSaleableItem.push({ ItemId: 0, Description: '', Quantity: 1, UnitPrice: '', LineTotal: '', AccountId: '', selected: true });
      }
    })  
  }
  private getTaxTypeList = () => {
    this.settingService.GetTaxTypeList().subscribe((x: []) => {
      this.taxTypeList = x;
    })
  }
 private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
      this.taxRateList.unshift({"Id":"","Name":"Select"});
    })
  }

  changed(){
    this.forms.value;
  }

  itemGenerator(){
    this.selectedDate = this.quotationData.QuotationDate;
    this.selectedDueDate = this.quotationData.DueDate;
  }

  convertCalenderDate(date) {
    let dateString = date.split("T")[0];
    return dateString;
  }

  getAllPurchaseableItem = () => {
    this.quotationService.GetPurchaseableItems().subscribe((x) =>
      this.PurchaseableItems = x)
  }

  getAllCustomer = () => {
    this.quotationService.GetAllContact().subscribe((x: []) => {
      this.allCustomer= x;
    })
  }

  CreateNewCustomer = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : true,
      IsSupplier : false,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
  
        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.allCustomer.push(x["Data"]);
        let customerObj = this.allCustomer.filter(x=>x.PrimaryPersonFirstName == customerName);
        this.selectedSupplier = customerObj[0]['Id'];
        this.getAllCustomer();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }
  
  createBlankItem = () => {
    this.changeSelectRowInItem(0, 0)
    let item = { ItemId: null, Description: "", Quantity: 0, UnitPrice: 0, LineTotal: 0, selected: false,TaxRateId:null,AdvanceTaxRateId:null }
    this.quotationItem.push(item);
  }

  changePosition(event: CdkDragDrop<string[]>) {
    
  }

  updateQuotation = () => {
    this.ifSubmit = true;
    this.quotationData = {
      Id: this.quotationId,
      QuotationNo: this.ShowQuotationNo + this.postQuotation,
      ContactId: this.PrimaryPerson,
      QuotationDate: this.forms.value.selectedDate,
      DueDate: this.forms.value.selectedDueDate,
      ReferenceNo: this.ReferenceNo.toString(),
      Amount: this.totalPrice,
      QuotationItem: this.quotationItem,
      TaxTypeId:this.TaxTypeId,
      TaxAmount:this.TaxAmount,
      AdvanceTaxAmount:this.AdvanceTaxAmount,
      IsActive:this.IsActive,
      Notes: this.quotationNotes
    }
    let check= this.checkValidation(this.quotationData.QuotationItem);
    if (!this.quotationData.ContactId) {
      this.toaster.warning('Required field can not be empty !', 'Warning!');
      return;
    } else if (!this.quotationData.QuotationDate) {
      this.toaster.warning('Required field can not be empty !', 'Warning!');
      return;
    }else if (this.quotationData.QuotationItem.length < 1) {
      this.toaster.warning('Required field can not be empty !', 'Warning!');
      return;
    }else if (!this.quotationData.DueDate) {
      this.toaster.warning('Required field can not be empty !', 'Warning!');
      return;
    }
    else if (!check) {
      this.toaster.warning('Required field can not be empty!');
      return false;
    }
    this.PostQuotationData(this.quotationData);
  }
  checkValidation=(item)=>{
    var b=true;
    item.forEach(element => {
        if(element.Description=="" || element.LineTotal<=0 || (this.TaxTypeId!=3 && element.TaxRateId==null))
        {
            b=false;
            
        }
      });
      return b;
  }

  async checkQuotationNo(){
    //var val = this.quotationNo.toString();
    var val = this.ShowQuotationNo + this.postQuotation;
    if(val=='' || val==null){
      this.toaster.error('Please Input Quotation No.!');
      return false;
    }
  
    let quotation = this.prevQuotation.toString();
    let concat = "";
    for(let i=0;i<6;i++) concat+=quotation[i];
  
    if(val.length!=10){
      this.toaster.error("Quotation number must start with " + concat + " and ends with four-digit numbers");
      return false;
    }
  
    for(let i=0;i<concat.length;i++){
      if(val[i]!=concat[i]){
        this.toaster.error("Quotation number must start with " + concat + " and ends with four-digit numbers");
        return false;
      }
    }
  
    return true;
  }

  async PostQuotationData(billData:any){
    if(!await this.checkQuotationNo()) return;
    this.spinner.show();
    billData.IQuidiActionType = iQuidiActionType.Update;
    this.quotationService.Update(billData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Quotation has been successfully updated', 'Success!');
        this.redirectToListPage();
        this.spinner.hide()
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide()
      }
    })
  }

  /**
   * updateDescriptionItem
i   */
  public updateDescriptionItem(i) {
    
  }
  
  private redirectToListPage(){
    let rUrl='quotation/details/'+('QU-'+ this.ShowQuotationNo + this.postQuotation);
    this.router.navigate([rUrl]);
  }

  changeSelectRowInItem = (itemId, index) => {
    let data = []
    this.quotationItem.map((x, i) => {
      x.ItemId == itemId && i == index ? x.selected = true : x.selected = false
      data.push(x)
    })
    this.quotationItem = data;
  }

  updateTotalPrice = () => {
    this.subTotalAmount = 0.00;
    this.TaxAmount=0.00;
    this.AdvanceTaxAmount=0.00;
    this.quotationItem.map((x) => {
      this.subTotalAmount = this.subTotalAmount + x.LineTotal;
      this.TaxAmount+=this.getItemTaxAmount(x.LineTotal,x.TaxRateId);
      this.AdvanceTaxAmount+=this.getItemTaxAmount(x.LineTotal,x.AdvanceTaxRateId);
    });
    switch(this.TaxTypeId){
      case 1: //tax exclusive
        this.totalPrice = this.subTotalAmount+this.TaxAmount+this.AdvanceTaxAmount;
        break;
      case 2: //tax inclusive
        this.totalPrice = this.subTotalAmount;
        break;
      case 3: //no tax
       this.totalPrice = this.subTotalAmount;
        break;
    }
    if (this.TaxTypeId === 3)
    {
      this.checkTaxType();
    }

  }

  checkTaxType = () => {

    this.quotationItem.map((x, i) => {

       this.quotationItem[i].TaxRateId = null;
       this.quotationItem[i].AdvanceTaxRateId =  null;
    });
  }
  private getItemTaxAmount(lineTotal:number,taxRateId:any) {
    let taxPrice=0.00;
    if(taxRateId=="" || taxRateId==null)
     return taxPrice;

     var taxPercent=this.getTaxRatePercent(taxRateId);

     switch(this.TaxTypeId){
      case 1: //tax exclusive
      taxPrice= lineTotal*taxPercent/100;
        break;
      case 2: //tax inclusive
      taxPrice= Number(((lineTotal*taxPercent)/(100+taxPercent)).toFixed(2));
        break;
      default: //no tax
      taxPrice=0
        break;
    }
    return taxPrice;
  }
  
  private getTaxRatePercent(taxRateId:any) {
    let taxRateObj = this.taxRateList.filter(x=>x.Id == taxRateId);
    return taxRateObj[0].Parcent;
  }


  updateDataItem = (itemId) => {
    this.quotationItem.map((x) => {
      if (x.ItemId == itemId) { x.LineTotal = x.Quantity * x.UnitPrice }
    })
    this.updateTotalPrice()
  }

  updateDataItemLineTotal = (itemId) => {
    this.quotationItem.map((x) => {
      if (x.ItemId == itemId) {
        x.UnitPrice = x.LineTotal / x.Quantity
      }
    })
    this.updateTotalPrice()
  }

  changespurchaseableItems = (index) => {
    for (let i = 0; i < this.PurchaseableItems.length; i++) {
      if (this.PurchaseableItems[i].Id == this.quotationItem[index]['ItemId']) {
        this.quotationItem[index]['ItemId'] = this.PurchaseableItems[i]['Id'];
        this.quotationItem[index]['Quantity'] = 1;
        this.quotationItem[index]['UnitPrice'] = this.PurchaseableItems[i]['PurchasePrice'];
        this.quotationItem[index]['Description']=this.PurchaseableItems[i]['PurchaseDescription'];
        this.updateDataItem(this.quotationItem[index]['ItemId']);
      }
    }
  }
  
  openItemModal(selectItem: NgSelectComponent){
    selectItem.close();
     const modalRefs = this.modalService.open(PurchaseProductComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
  }

  removeItem = (itemId, i) => {
    let data = []
    this.quotationItem.map((x, index) => {
      if (index != i) {
        data.push(x);
      }
    })
    this.quotationItem = data;
    this.updateTotalPrice();
  }

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    let open = document.getElementById("attachmentDropdown").classList.contains("show");
    open ? document.getElementById("custom_notch").classList.add("show") : document.getElementById("custom_notch").classList.remove("show")
  }

  fileName: string;
  fileType: string;
  
}
