import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { DocumentTypeGroupSettingsComponent } from '../document-type-group-settings/document-type-group-settings.component';
import { CellClickedEvent } from 'ag-grid-community';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
import { DynamicLabelNameSettingsForDocumentInputsComponent } from '../dynamic-label-name-settings-for-document-inputs/dynamic-label-name-settings-for-document-inputs.component';

@Component({
  selector: 'app-document-settings',
  templateUrl: './document-settings.component.html',
  styleUrls: ['./document-settings.component.css']
})

export class DocumentSettingsComponent implements OnInit {

  DocumentSettingsForm: FormGroup;
  DocumentDynamicLabelForm: FormGroup;
  documentCustomApprovalSettingsData: any;
  documentTypeGroupSettingsData: any;
  oTPVerificationSettingsData: any;
  documentApproval: boolean = false;
  hasDocumentTypeGroup: boolean = false;
  isMobileOTPRequiredForApproval: boolean = false;
  isEmailOTPRequiredForApproval: boolean = false;

  gridApi;
  gridColumnApi;
  documentTypeList: any[];
  dynamicLabelNameForDocumentInputsList: any[];

  domLayout = 'autoHeight';
  rowHeight = 275;
  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
    scrollbarWidth: 0,
    resizable: true
  };

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private settingsService: SettingsServiceService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getAllDocumentTypes();
    this.getCustomApprovalSettings();
    this.getDocumentTypeGroupSettings();
    this.getOTPVerificationSettings();
    this.getAllDynamicLabelNameForDocumentInputs();
    this.initializeForm();
    this.initializeDocumentDynamicLabelForm();
  }

  getAllDocumentTypes() {
    this.settingsService.GetAllDocumentTypes().subscribe((x) => {
      this.documentTypeList = x;
    });
  }

  getCustomApprovalSettings() {
    this.settingsService.GetCustomApprovalSettings().subscribe(x => {
      this.documentCustomApprovalSettingsData = x;
      this.documentApproval = x.DocumentApproval;
      this.setCustomApprovalParentData();
    });
  }

  setCustomApprovalParentData() {
    this.DocumentSettingsForm.controls['CustomApprovalSettingsId'].patchValue(this.documentCustomApprovalSettingsData.Id);
    this.DocumentSettingsForm.controls['CustomApprovalSettingsCompanyId'].patchValue(this.documentCustomApprovalSettingsData.CompanyId);
    this.DocumentSettingsForm.controls['DocumentApproval'].patchValue(this.documentCustomApprovalSettingsData.DocumentApproval);
  }

  getDocumentTypeGroupSettings() {
    this.settingsService.GetDocumentTypeGroupSettings().subscribe(x => {
      this.documentTypeGroupSettingsData = x;
      this.hasDocumentTypeGroup = x.HasDocumentTypeGroup;
      this.setDocumentTypeGroupParentData();
    });
  }

  setDocumentTypeGroupParentData() {
    this.DocumentSettingsForm.controls['DocumentTypeGroupSettingsId'].patchValue(this.documentTypeGroupSettingsData.Id);
    this.DocumentSettingsForm.controls['DocumentTypeGroupSettingsCompanyId'].patchValue(this.documentTypeGroupSettingsData.CompanyId);
    this.DocumentSettingsForm.controls['HasDocumentTypeGroup'].patchValue(this.documentTypeGroupSettingsData.HasDocumentTypeGroup);
  }

  getOTPVerificationSettings() {
    this.settingsService.GetOTPVerificationSettings().subscribe(x => {
      this.oTPVerificationSettingsData = x;
      this.isMobileOTPRequiredForApproval = x.IsMobileOTPRequiredForApproval;
      this.isEmailOTPRequiredForApproval = x.IsEmailOTPRequiredForApproval;
      this.setOTPVerificationParentData();
    });
  }

  setOTPVerificationParentData() {
    this.DocumentSettingsForm.controls['OTPVerificationSettingsId'].patchValue(this.oTPVerificationSettingsData.Id);
    this.DocumentSettingsForm.controls['OTPVerificationSettingsCompanyId'].patchValue(this.oTPVerificationSettingsData.CompnayId);
    this.DocumentSettingsForm.controls['IsMobileOTPRequiredForApproval'].patchValue(this.oTPVerificationSettingsData.IsMobileOTPRequiredForApproval);
    this.DocumentSettingsForm.controls['IsEmailOTPRequiredForApproval'].patchValue(this.oTPVerificationSettingsData.IsEmailOTPRequiredForApproval);
  }

  getAllDynamicLabelNameForDocumentInputs() {
    this.settingsService.GetAllDocumentInputFieldDynamicLabelName().subscribe((x) => {
      this.dynamicLabelNameForDocumentInputsList = x;
      this.setDocumentDynamicLabelParentData();
    });
  }

  setDocumentDynamicLabelParentData() {
    this.DocumentDynamicLabelForm.controls['LabelName00'].patchValue(this.dynamicLabelNameForDocumentInputsList[0].LabelName);
    this.DocumentDynamicLabelForm.controls['LabelName01'].patchValue(this.dynamicLabelNameForDocumentInputsList[1].LabelName);
    this.DocumentDynamicLabelForm.controls['LabelName10'].patchValue(this.dynamicLabelNameForDocumentInputsList[2].LabelName);
    this.DocumentDynamicLabelForm.controls['LabelName11'].patchValue(this.dynamicLabelNameForDocumentInputsList[3].LabelName);
    this.DocumentDynamicLabelForm.controls['LabelName20'].patchValue(this.dynamicLabelNameForDocumentInputsList[4].LabelName);
    this.DocumentDynamicLabelForm.controls['LabelName21'].patchValue(this.dynamicLabelNameForDocumentInputsList[5].LabelName);
  }

  initializeForm() {
    this.DocumentSettingsForm = this.fb.group({
      CustomApprovalSettingsId: [0],
      CustomApprovalSettingsCompanyId: [0],
      DocumentApproval: [false],

      DocumentTypeGroupSettingsId: [0],
      DocumentTypeGroupSettingsCompanyId: [0],
      HasDocumentTypeGroup: [false],

      OTPVerificationSettingsId: [0],
      OTPVerificationSettingsCompanyId: [0],
      IsMobileOTPRequiredForApproval: [false],
      IsEmailOTPRequiredForApproval: [false]
    });
  }

  initializeDocumentDynamicLabelForm() {
    this.DocumentDynamicLabelForm = this.fb.group({
      LabelName00: [],
      LabelName01: [],
      LabelName10: [],
      LabelName11: [],
      LabelName20: [],
      LabelName21: []
    });
  }

  Save() {
    this.spinner.show();
    let CustomApprovalSettingsData = {
      Id: this.DocumentSettingsForm.value.CustomApprovalSettingsId,
      CompanyId: this.DocumentSettingsForm.value.CustomApprovalSettingsCompanyId,
      DocumentApproval: this.DocumentSettingsForm.value.DocumentApproval
    }
    if(this.DocumentSettingsForm.value.CustomApprovalSettingsId == 0){
      this.settingsService.SaveCustomApprovalSettings(CustomApprovalSettingsData).subscribe(x => {
        if(x?.Success) {
          this.getCustomApprovalSettings();
          this.saveDocumentTypeGroupSettings();
        }
        else{
          this.toaster.error(x?.Message)
        }
        this.spinner.hide();
      });
    }
    else{
      this.settingsService.UpdateCustomApprovalSettings(CustomApprovalSettingsData).subscribe(x => {
        if(x?.Success) {
          this.getCustomApprovalSettings();
          this.saveDocumentTypeGroupSettings();
        }
        else{
          this.toaster.error(x?.Message)
        }
        this.spinner.hide();
      });
    }
  }

  saveDocumentTypeGroupSettings() {
    this.spinner.show();
    let DocumentTypeGroupSettingsData = {
      Id: this.DocumentSettingsForm.value.DocumentTypeGroupSettingsId,
      CompanyId: this.DocumentSettingsForm.value.DocumentTypeGroupSettingsCompanyId,
      HasDocumentTypeGroup: this.DocumentSettingsForm.value.HasDocumentTypeGroup
    }
    if(this.DocumentSettingsForm.value.DocumentTypeGroupSettingsId == 0){
      this.settingsService.SaveDocumentTypeGroupSettings(DocumentTypeGroupSettingsData).subscribe(x => {
        if(x?.Success) {
          this.getDocumentTypeGroupSettings();
          this.saveOTPVerificationSettings();
        }
        else{
          this.toaster.error(x?.Message)
        }
        this.spinner.hide();
      });
    }
    else{
      this.settingsService.UpdateDocumentTypeGroupSettings(DocumentTypeGroupSettingsData).subscribe(x => {
        if(x?.Success) {
          this.getDocumentTypeGroupSettings();
          this.saveOTPVerificationSettings();
        }
        else{
          this.toaster.error(x?.Message)
        }
        this.spinner.hide();
      });
    }
  }

  saveOTPVerificationSettings() {
    this.spinner.show();
    let OTPVerificationSettingsData = {
      Id: this.DocumentSettingsForm.value.OTPVerificationSettingsId,
      CompanyId: this.DocumentSettingsForm.value.OTPVerificationSettingsCompanyId,
      IsMobileOTPRequiredForApproval: this.DocumentSettingsForm.value.IsMobileOTPRequiredForApproval,
      IsEmailOTPRequiredForApproval: this.DocumentSettingsForm.value.IsEmailOTPRequiredForApproval
    }
    if(this.DocumentSettingsForm.value.OTPVerificationSettingsId == 0){
      this.settingsService.SaveOTPVerificationSettings(OTPVerificationSettingsData).subscribe(x => {
        if(x?.Success) {
          this.toaster.success('Document Settings Saved Successfully.');
          this.getOTPVerificationSettings();
        }
        else{
          this.toaster.error(x?.Message)
        }
        this.spinner.hide();
      });
    }
    else{
      this.settingsService.UpdateOTPVerificationSettings(OTPVerificationSettingsData).subscribe(x => {
        if(x?.Success) {
          this.toaster.success('Document Settings Updated Successfully.');
          this.getOTPVerificationSettings();
        }
        else{
          this.toaster.error(x?.Message)
        }
        this.spinner.hide();
      });
    }
  }

  documentApprovalSettingsChange() {
    if (this.documentApproval == false || this.documentApproval == undefined){
      this.documentApproval = true;
      this.DocumentSettingsForm.controls['DocumentApproval'].patchValue(true);
    }
    else{
      this.documentApproval = false;
      this.DocumentSettingsForm.controls['DocumentApproval'].patchValue(false);
    }
  }

  documentTypeGroupsSettingsChange() {
    if(this.DocumentSettingsForm.value.DocumentTypeGroupSettingsId != 0 && this.DocumentSettingsForm.value.HasDocumentTypeGroup == false){
      this.toaster.warning('You cannot unable Document Type Groups Settings!');
      this.DocumentSettingsForm.controls['HasDocumentTypeGroup'].patchValue(true);
      return;
    }
    if (this.hasDocumentTypeGroup == false || this.hasDocumentTypeGroup == undefined){
      this.hasDocumentTypeGroup = true;
      this.DocumentSettingsForm.controls['HasDocumentTypeGroup'].patchValue(true);
    }
    else{
      this.hasDocumentTypeGroup = false;
      this.DocumentSettingsForm.controls['HasDocumentTypeGroup'].patchValue(false);
    }
  }

  mobileOTPVerificationSettingsChange() {
    if (this.isMobileOTPRequiredForApproval == false || this.isMobileOTPRequiredForApproval == undefined){
      this.isMobileOTPRequiredForApproval = true;
      this.DocumentSettingsForm.controls['IsMobileOTPRequiredForApproval'].patchValue(true);
    }
    else{
      this.isMobileOTPRequiredForApproval = false;
      this.DocumentSettingsForm.controls['IsMobileOTPRequiredForApproval'].patchValue(false);
    }
  }

  emailOTPVerificationSettingsChange() {
    if (this.isEmailOTPRequiredForApproval == false || this.isEmailOTPRequiredForApproval == undefined){
      this.isEmailOTPRequiredForApproval = true;
      this.DocumentSettingsForm.controls['IsEmailOTPRequiredForApproval'].patchValue(true);
    }
    else{
      this.documentApproval = false;
      this.DocumentSettingsForm.controls['IsEmailOTPRequiredForApproval'].patchValue(false);
    }
  }

  columnDefs = [
    { headerName: 'Document Type', field: 'Name', minWidth: 600 },
    { headerName: 'Document Type Group', field: 'DocumentTypeGroup.Name', minWidth: 300,
      cellRenderer: function (params) {
        if(params.data.DocumentTypeGroup == null)
          return '-'
        else
          return params.data.DocumentTypeGroup.Name;
      }
    },
    { headerName: 'Action', field: 'Action', minWidth: 100,
      onCellClicked: (event: CellClickedEvent) => this.documentTypeDetails(event.data),
      cellRenderer: function (params) {
        return '<i class="material-icons editBtnIcon" style="color: gray; height: 5px; width: 5px">edit</i>'
      }
    }
  ];

  // dynamicLabelNameForDocumentInputsColumnDefs = [
  //   { headerName: 'Input Field Name', field: 'FieldName', minWidth: 600 },
  //   { headerName: 'Label Name', field: 'LabelName', minWidth: 300 },
  //   { headerName: 'Action', field: 'Action', minWidth: 100,
  //     onCellClicked: (event: CellClickedEvent) => this.EditDynamicLabelNameForDocumentInputs(event.data),
  //     cellRenderer: function (params) {
  //       return '<i class="material-icons editBtnIcon" style="color: gray; height: 5px; width: 5px">edit</i>'
  //     }
  //   }
  // ];

  onGridReady(event) {
    this.gridApi = event.api;
  }

  documentTypeDetails(data: any) {
    const modalRef = this.modalService.open(DocumentTypeGroupSettingsComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered : true});
    modalRef.componentInstance.hasDocumentTypeGroup = this.DocumentSettingsForm.value.HasDocumentTypeGroup;
    modalRef.componentInstance.parentData = data;
    modalRef.componentInstance.operationType = iQuidiActionType.Update;
    modalRef.result.then((result) => {
      if (result == 'Success') {
        this.getAllDocumentTypes();
      }
    }, (reason) => {});
}

  addDocumentTypeGroups() {
      const modalRef = this.modalService.open(DocumentTypeGroupSettingsComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered : true});
      modalRef.componentInstance.hasDocumentTypeGroup = this.DocumentSettingsForm.value.HasDocumentTypeGroup;
      modalRef.componentInstance.operationType = iQuidiActionType.Save;
      modalRef.result.then((result) => {
        if (result == 'Success') {
          this.getAllDocumentTypes();
        }
      }, (reason) => {});
  }

  addDocumentTypes() {
    const modalRef = this.modalService.open(DocumentTypeGroupSettingsComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered : true});
    modalRef.componentInstance.hasDocumentTypeGroup = this.DocumentSettingsForm.value.HasDocumentTypeGroup;
    modalRef.componentInstance.operationType = iQuidiActionType.Save;
    modalRef.result.then((result) => {
      if (result == 'Success') {
        this.getAllDocumentTypes();
      }
    }, (reason) => {});
}

  // AddDynamicLabelNameForDocumentInputs() {
  //   const modalRef = this.modalService.open(DynamicLabelNameSettingsForDocumentInputsComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered : true});
  //   modalRef.componentInstance.operationType = iQuidiActionType.Save;
  //   modalRef.result.then((result) => {
  //     if (result == 'Success') {
  //       this.getAllDynamicLabelNameForDocumentInputs();
  //     }
  //   }, (reason) => {});
  // }

  // EditDynamicLabelNameForDocumentInputs(data: any) {
  //   const modalRef = this.modalService.open(DynamicLabelNameSettingsForDocumentInputsComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered : true});
  //   modalRef.componentInstance.parentData = data;
  //   modalRef.componentInstance.operationType = iQuidiActionType.Update;
  //   modalRef.result.then((result) => {
  //     if (result == 'Success') {
  //       this.getAllDynamicLabelNameForDocumentInputs();
  //     }
  //   }, (reason) => {});
  // }

  saveDocumentDynamicLabelName() {
    let data = this.formatData();
    this.settingsService.UpdateDocumentInputFieldDynamicLabelName(data).subscribe((x) => {
      if(x?.Success){
        this.toaster.success(x?.Message);
        this.getAllDynamicLabelNameForDocumentInputs();
      }
      else{
        this.toaster.error(x?.Message);
        this.getAllDynamicLabelNameForDocumentInputs();
      }
    })
  }

  formatData(){
    let dynamicLabelList: any[] = [];
    let label1 = {
      Id: this.dynamicLabelNameForDocumentInputsList[0].Id,
      FieldName: this.dynamicLabelNameForDocumentInputsList[0].FieldName,
      LabelName:this.DocumentDynamicLabelForm.value.LabelName00,
      CompanyId:this.dynamicLabelNameForDocumentInputsList[0].CompanyId
    };
    dynamicLabelList.push(label1);
    let label2 = {
      Id: this.dynamicLabelNameForDocumentInputsList[1].Id,
      FieldName: this.dynamicLabelNameForDocumentInputsList[1].FieldName,
      LabelName:this.DocumentDynamicLabelForm.value.LabelName01,
      CompanyId:this.dynamicLabelNameForDocumentInputsList[1].CompanyId
    };
    dynamicLabelList.push(label2);
    let label3 = {
      Id: this.dynamicLabelNameForDocumentInputsList[2].Id,
      FieldName: this.dynamicLabelNameForDocumentInputsList[2].FieldName,
      LabelName:this.DocumentDynamicLabelForm.value.LabelName10,
      CompanyId:this.dynamicLabelNameForDocumentInputsList[1].CompanyId
    };
    dynamicLabelList.push(label3);
    let label4 = {
      Id: this.dynamicLabelNameForDocumentInputsList[3].Id,
      FieldName: this.dynamicLabelNameForDocumentInputsList[3].FieldName,
      LabelName:this.DocumentDynamicLabelForm.value.LabelName11,
      CompanyId:this.dynamicLabelNameForDocumentInputsList[3].CompanyId
    };
    dynamicLabelList.push(label4);
    let label5 = {
      Id: this.dynamicLabelNameForDocumentInputsList[4].Id,
      FieldName: this.dynamicLabelNameForDocumentInputsList[4].FieldName,
      LabelName:this.DocumentDynamicLabelForm.value.LabelName20,
      CompanyId:this.dynamicLabelNameForDocumentInputsList[4].CompanyId
    };
    dynamicLabelList.push(label5);
    let label6 = {
      Id: this.dynamicLabelNameForDocumentInputsList[5].Id,
      FieldName: this.dynamicLabelNameForDocumentInputsList[5].FieldName,
      LabelName:this.DocumentDynamicLabelForm.value.LabelName21,
      CompanyId:this.dynamicLabelNameForDocumentInputsList[5].CompanyId
    };
    dynamicLabelList.push(label6);
    return dynamicLabelList;
  }

}


