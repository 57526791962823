import { Component,ViewChild, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { IncomeServiceService } from '../../../../service/Income/income-service.service';
import { ConversionbalancesService } from '../../../../service/ConversionBalance/conversionbalances.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridAngular } from 'ag-grid-angular';
import {RouterLinkRendereComponent} from '../../../../components/router-link-rendere/router-link-rendere.component';

@Component({
  selector: 'app-historic-conversionbalances-invoice',
  templateUrl: './historic-conversionbalances-invoice.component.html',
  styleUrls: ['./historic-conversionbalances-invoice.component.css']
})
export class HistoricConversionbalancesInvoiceComponent implements OnInit {

  constructor(public ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private router: Router,
    private activeroute: ActivatedRoute,
    private incomeService: IncomeServiceService,
    private conversionBalanceService: ConversionbalancesService,
    private spinner: NgxSpinnerService) { }

  //#region Properties
    private gridApiInvoice;
    private gridColumnApiInvoice;
    allInvoice: any = [];
    TotalInvoiceAmount:any;
    ReceivableCalculateBalance:any;
    TotalDebit: any;
    btndisbaled:boolean = true;
    previousConversionDate: any;
    IsInvoiceMatch :boolean =false;
    BillAdjustmentDue: boolean;
    InvoiceAdjustmentDue: boolean;

    @ViewChild("agGridinvoice", { static: false }) agGridinvoice: AgGridAngular;

  //#endregion

  //#region InvoiceGrid
  gridOptionsInvoice = {
    columnDefs: [
      { headerName: 'Number', field: 'InvoicceNo', 
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: {
          inRouterLink: '/income/invoice/',
        }
      },
      { headerName: 'Ref', field: 'ReferenceNo' },
      { headerName: 'To', field: 'Contacts.PrimaryPersonFullName' },
      { headerName: 'Date', field: 'InvoiceDate', valueFormatter: this.convertGridDateIntoDisplay },
      { headerName: 'Due Date', field: 'DueDate', valueFormatter: this.convertGridDateIntoDisplay },
      { headerName: 'Amount Due', ColId:'invoice_Name', field: 'PaidAmount', cellStyle: { textAlign: 'right' } ,valueFormatter: this.PaidInvoiceAmount },
      { headerName: '', width: 25, minWidth: 20, maxWidth: 30, suppressMenu: true, suppressSorting: true, template: `<span  (click)="deleteInvoiceRows()"><img class="icon-Trash" src="../../../../assets/image/icon-Trash.png"></span>` }
    ],

    defaultColDef: {
      flex: 1,
    },
    domLayout: 'autoHeight',
    rowHeight: 275,
  }
  
  getAllInvoice() {
    this.spinner.show();
    this.conversionBalanceService.getConversionBalance().subscribe((res) => {
      if (res != null) {
        this.TotalDebit = res["InvoiceAccount"].Debit;
        this.allInvoice = res["InvoiceList"];
        this.TotalInvoiceAmount =res["TotalInvoiceAmount"];
        this.BillAdjustmentDue= res["BillAdjustmentDue"];
        this.InvoiceAdjustmentDue= res["InvoiceAdjustmentDue"];
        this.ReceivableCalculateBalance =this.TotalDebit-this.TotalInvoiceAmount;
        this.btndisbaled = this.TotalDebit == this.TotalInvoiceAmount ? false : true;
        this.IsInvoiceMatch = this.TotalDebit == this.TotalInvoiceAmount ? true : false;
        this.spinner.hide();
      } 
      else {
        this.toaster.error("Something went wrong. Please check.", "Error");
        this.spinner.hide();
      }
    }, (err) => { 
      this.toaster.error("Something went wrong. Please check.", "Error");
      this.spinner.hide(); })
  }

  onGridReadyInvoice(event) {
    this.gridApiInvoice = event.api;
    this.gridColumnApiInvoice = event.columnApi;
    this.getAllInvoice();
  }

  PaidInvoiceAmount(value){
    let valueData = Number(value.data.InvoiceAmount.toFixed(2))
    return valueData.toLocaleString();
  }

  onInvoiceRowClicked(e) {
    let colHeaderName = this.gridApiInvoice.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "") {
      this.deleteInvoiceRows(e);
    }
  }

  deleteInvoiceRows(e) {
    this.conversionBalanceService.deleteConversionBalanceInvoice(e.data.Id).subscribe((res) => {
      if (res["Success"]) {
        this.toaster.success('Bill Successfully Deleted !', 'Success!');
        this.getAllInvoice();
      } 
      else {
        this.toaster.error("Something went wrong. Please check.", "Error");
      }
    }, (err) => {
      this.toaster.error("Something went wrong. Please check.", "Error");
    })

    // this.gridApiInvoice.updateRowData({ remove: [e.data] });
    // this.gridApiInvoice.refreshView();
    // this.getAllInvoice();
  }
  //#endregion

  //#region CommonMethod
  ngOnInit(): void {
    this.getConversionDate();
  }

  getConversionDate() {
    this.conversionBalanceService.getConversionConfig().subscribe((res) => {
      if (res != null) {

        let conversionMonth = res['ConversionMonth'];
        var currentDate = new Date();
        currentDate.setMonth(conversionMonth - 1);
        currentDate.setDate(1);
        currentDate.setHours(-1);
        this.previousConversionDate = currentDate.toString()
      }
    }, (err) => { this.toaster.error("Something went wrong. Please check.", "Error"); })
  }

  convertDateIntoDisplay(date){
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  convertGridDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }
  openInvoicePopup(){
    this.router.navigate(['income/create-invoice'], { queryParams: { Isconversion: true }});
  }

  NextBtnClick(){
    
    if(this.BillAdjustmentDue == false && this.InvoiceAdjustmentDue == false){
      this.router.navigate(['accounting/existing-balances']);
    }
    else if(this.BillAdjustmentDue == true){
      this.router.navigate(['accounting/historic-conversionbalancesBill']);
    }
  }
//#endregion

}
