<ng-container>
    <div class="modal-lg bg_gray">
        <div class="row ml-2">
            <div [ngClass]="{'col-md-11': modalInfoData?.ApprovalStatus != 3}" class="col-md-10 mt-2">
              <h4>{{action}} Details</h4>
            </div>
            <div *ngIf="modalInfoData?.ApprovalStatus == 3" class="col-md-1 mt-2 text-align-right">
              <a [href]="downloadLink">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-download download-Button" viewBox="0 0 20 20" style="cursor: pointer;">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
              </a>
            </div>
            <div class="col-md-1 mt-2">
              <button type="button" class="btn-close border-0" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true" class="visually-hidden border-0 modalCloseCross">&times;</span>
              </button>
            </div>
          </div>
        <div class="modal-body">
            <table class="table table-borderless br_black bg_white">
                <tbody>
                    <tr>
                        <td>
                            <p>{{action}} No: {{modalInfoData.ActionNo}}</p>
                            <p>Customer Name: {{modalInfoData.CustomerName}}</p>
                        </td>
                        <td>
                            <p>Created Date: {{modalInfoData.CreatedDate | date: 'MMM-d-y'}}</p>
                            <p>Due Date: {{modalInfoData.DueDate | date: 'MMM-d-y'}}</p>
                        </td>
                        <td>
                        </td>
                    </tr>
                </tbody>
            </table>
            <label class="mt-1">Approval History</label>
            <div *ngFor="let item of modalHistory; let i = index" class="bg_white pd-1" [ngClass]="{'br_black_no_top': !isNotSameLevel(item, i-1) || isLastItem(i), 'br_black_no_bottom': isNotSameLevel(item, i-1)}">
                <label class="bold" *ngIf="isNotSameLevel(item, i-1)">Level {{item.LevelId}}</label>
                <table class="table table-borderless table-responsive" [formGroup]="approveLevelForm" [ngClass]="{ hidden_table_header: !isNotSameLevel(item, i-1) }">
                    <thead *ngIf="isNotSameLevel(item, i-1)">
                        <tr>
                            <th scope="col">Approver Role</th>
                            <th scope="col">Email</th>
                            <th scope="col" style="visibility: hidden;">..................................................</th>
                            <th scope="col" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved By</th>
                            <th scope="col" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved At</th>
                            <th scope="col" *ngIf="isRejected(item, i)">Rejected By</th>
                            <th scope="col" *ngIf="isRejected(item, i)">Rejected At</th>
                            <th scope="col" style="visibility: hidden;">..</th>
                            <th scope="col">Comments</th>
                            <th scope="col" style="visibility: hidden;">....................</th>
                            <th scope="col" style="text-align: center">Signature</th>
                            <th scope="col" style="text-align: center">Approval Status</th>
                        </tr>
                    </thead>
                    <thead *ngIf="!isNotSameLevel(item, i-1)" style="visibility: hidden;">
                        <tr>
                            <th scope="col">Approver Role</th>
                            <th scope="col">Email</th>
                            <th scope="col" style="visibility: hidden;">..................................................</th>
                            <th scope="col" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved By</th>
                            <th scope="col" *ngIf="isApproved(item, i) || isWaiting(item, i)">Approved At</th>
                            <th scope="col" *ngIf="isRejected(item, i)">Rejected By</th>
                            <th scope="col" *ngIf="isRejected(item, i)">Rejected At</th>
                            <th scope="col" style="visibility: hidden;">..</th>
                            <th scope="col">Comments</th>
                            <th scope="col" style="visibility: hidden;">....................</th>
                            <th scope="col" style="text-align: center">Signature</th>
                            <th scope="col" style="text-align: center">Approval Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input [value]="item.RoleId==1?'Admin':item.RoleId==2?'Accountant':'Auditor'" class="form-control" type="text" readonly />
                            </td>
                            <td colspan="2">
                                <input [value]="item.Email" class="form-control" type="email" readonly />
                            </td>
                            <td>
                                <input [value]="item.ApprovedBy" class="form-control" type="text" readonly />
                            </td>
                            <td colspan="2">
                                <input [value]="item.ApprovedAt | date: 'MMM-d-y'" class="form-control" readonly />
                            </td>
                            <td class="textarea-td" colspan="2">
                                <textarea *ngIf="isRejected(item, i) || isApproved(item, i)" class="form-control form-control-textarea" rows="3" type="text" readonly>{{item.Comments}}</textarea>
                                <textarea *ngIf="!isRejected(item, i) && !isApproved(item, i)" class="form-control form-control-textarea" rows="3" type="text" readonly></textarea>
                            </td>
                            <td style="text-align: right">
                                <img *ngIf="item.Signature  && !isWaiting(item, i)" alt="" style="outline-style: dashed; outline-color:#E9ECEF" src={{item.Signature}} class="signature_img bg_white" />
                                <img *ngIf="!item.Signature || isWaiting(item, i)" style="outline-style: dashed; outline-color:#E9ECEF; background-color: white;" class="signature_img bg_white" />
                            </td>
                            <td class="img-center">
                                <fa-icon *ngIf="isApproved(item, i)" [icon]="faCheckCircle" class="approvalStatusIcon green"></fa-icon>
                                <fa-icon *ngIf="isRejected(item, i)" [icon]="faTimesCircle" class="approvalStatusIcon red"></fa-icon>
                                <fa-icon *ngIf="isWaiting(item, i)" [icon]="faExclamationCircle" class="approvalStatusIcon yellow"></fa-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-container>