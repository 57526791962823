import { Injectable } from '@angular/core';
import { FixedAssetDisposalAccountMapping } from 'src/app/models/fixedAssetDisposalAccountMapping.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';
import { ResponseModel } from 'src/app/models/responseModel.model';
import { FixedAssetDisposalViewModel } from 'src/app/models-viewModels/fixedAssetDisposalViewModel.model';

@Injectable({
  providedIn: 'root'
})
export class FixedAssetDisposalService {

  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  public SaveAccountMappings(data: FixedAssetDisposalAccountMapping[]): Observable<ResponseModel>{
    return this.http.post<ResponseModel>(this.baseurl + 'FixedAssetDisposal/SaveAccountMappings', data);
  }

  public Dispose(data: FixedAssetDisposalViewModel): Observable<ResponseModel>{
    return this.http.post<ResponseModel>(this.baseurl + 'FixedAssetDisposal/Dispose', data);
  }

  public GetDisposalAccountMappings(): Observable<FixedAssetDisposalAccountMapping[]>{
    return this.http.get<FixedAssetDisposalAccountMapping[]>(this.baseurl + 'FixedAssetDisposal/GetDisposalAccountMappings');
  }
}
