import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as html2canvas from "html2canvas";
import jspdf from "jspdf";
import { NgxSmartModalService } from "ngx-smart-modal";
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { MainServiceService } from "../../../service/Main/main-service.service";
import { SubscriptionService } from "src/app/service/Subscription/subscription.service";
import { AuthService } from "src/app/service/auth/auth.service";
import { UserRole } from "src/app/enum/user-role";

@Component({
  selector: "app-user-setting",
  templateUrl: "./user-setting.component.html",
  styleUrls: ["./user-setting.component.css"],
})

export class UserSettingComponent implements OnInit {
  inviteUserForm: FormGroup;
  editInviteUserForm: FormGroup;
  allUserList: any = [];
  status = "Revoked";
  isRevoked = true;
  CompanyUserRoleForm: FormGroup;
  isDisable: boolean;
  ifUserClick = true;
  ifRoleClick = false;
  ifEditable = false;
  userList: any;
  allUserRole: any;
  TotalUser: any;
  loggedInUserRule: any;
  totalAdmin = 0;
  totalRevoked = 0;
  subscription = "subscription/manage-subscription";
  totalCancel = 0;
  RemainingUser = 0;
  ifSubmit: boolean = false;
  myItem:any;
  userRoleList = [
    {Id: 1, Name: 'Admin'},
    {Id: 2, Name: 'Accountant'},
    {Id: 3, Name: 'Auditor'}
  ];
  userInfo :any;
  totalActiveUsers: number = 0;
  totalInActiveUsers: number = 0;
  totalRemainingActiveUsers: number = 0;
  SubscriptionList : any[] = [];
  isTrialActive: boolean = false;

  businessUnitList: any[] = [];
  roleList: any[];

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private ngxSmartModalService: NgxSmartModalService,
    public fb: FormBuilder,
    public settingService: SettingsServiceService,
    private mainService: MainServiceService,
    private toaster: ToastrService,
    private subscriptionService: SubscriptionService,
    private authService : AuthService
  ) {}

  ngOnInit(): void {
    this.getAllBusinessUnit();
    this.initInviteUserForm();
    this.editInitInviteUserForm();
    this.initUserRoleForm();
    this.getAllCompanyUserRole();
    this.getAllUser();
    this.userInfo = this.authService.getUserInfo();
    this.getCompanySubscriptionList();
  }

  getAllBusinessUnit() {
    this.mainService.GetAllBusinessUnit().subscribe((x) => {
      this.businessUnitList = x;
      this.addNewRole(this.businessUnitList)
    });
  }

  addNewRole(businessUnitList) {
    for(let i = 0; i < businessUnitList.length; i++) {
      const newRoleId = businessUnitList[i].RoleId;
      const newRoleName = businessUnitList[i].Name;
      const newRole = { Id: newRoleId, Name: newRoleName };
      this.userRoleList.push(newRole);
    }
  }

  initInviteUserForm = () => {
    this.inviteUserForm = this.fb.group({
      Id: [0],
      FirstName: [""],
      LastName: [""],
      Email: ['', [Validators.required, Validators.pattern(/^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/)]],
      RoleId: [2],
      RevokeMe: [{ value: "" }],
    });
  }

  InviteUser = (item) => {
    this.setInviteUserForm(item);
    this.ngxSmartModalService.create("inviteUser", "content").open();
  }

  setInviteUserForm = (item) => {
    this.status = item.Id > 0 ? item.Status : this.status;
    this.inviteUserForm.controls["Id"].patchValue(item.Id);
    this.inviteUserForm.controls["FirstName"].patchValue(item.FirstName);
    this.inviteUserForm.controls["LastName"].patchValue(item.LastName);
    this.inviteUserForm.controls["Email"].patchValue(item.Email);
    this.inviteUserForm.controls["RoleId"].patchValue(item.RoleId);
    this.inviteUserForm.controls["RevokeMe"].patchValue(false);
    this.inviteUserForm.value.StatusId = item.StatusId;
    this.totalAdmin > 0  && item.RoleId == 1
      ? this.inviteUserForm.controls.RevokeMe.enable()
      : this.inviteUserForm.controls.RevokeMe.disable();
  }

  getAllUser = () => {
    this.spinner.show();
    this.mainService.GetUserList().subscribe((x) => {
      x.userList.map(user => { user.RoleName = this.setRoleName(user) });
      x.userList.map(user => {
        if (user.CompanyWiseUserList) {
          user.CompanyWiseUserList.map(companyUser => {
            companyUser.RoleName = this.setCompanyWiseUserRoleName(companyUser);
            return companyUser;
          });
        }
      });
      
      this.allUserList = x.userList;
      this.RemainingUser = x.remainingUser;
      this.totalAdmin = x.totalAdmin;
      this.loggedInUserRule = x.loggedInUserRule;
      this.ifUserClick = true;
      this.ifRoleClick = false;
      this.getTotalUserByCompany();
      this.spinner.hide();
    });
  }

  setRoleName(user) {
    let roleName: string;
    switch(user.RoleId) {
      case UserRole.Admin:
        roleName = 'Admin';
        break;
      case UserRole.Accountant:
        roleName = 'Accountant';
        break;
      case UserRole.Auditor:
        roleName = 'Auditor';
        break;
      case UserRole.PortalUser:
        roleName = 'PortalUser';
        break;
      default:
        roleName = this.businessUnitList.find(x => x.RoleId == user.RoleId).Name;
        break;
    }
    return roleName;
  }

  setCompanyWiseUserRoleName(user) {
    let roleName: string;
    switch(user.RoleId) {
      case UserRole.Admin:
        roleName = 'Admin';
        break;
      case UserRole.Accountant:
        roleName = 'Accountant';
        break;
      case UserRole.Auditor:
        roleName = 'Auditor';
        break;
      default:
        roleName = this.businessUnitList.find(x => x.RoleId == user.RoleId).Name;
        break;
    }
    return roleName;
  }

  getTotalUserByCompany = () => {
    this.mainService.GetCompanyTotalUser().subscribe((x) => {  
      //this.TotalUser = x + 1;
      if(x == 0){       // For Trial package 
        this.TotalUser = x + 1
      }
      else{
        this.TotalUser = x;
      }
      this.RemainingUser = this.TotalUser - this.RemainingUser;
    });
  }

  submitInviteUser = () => {
    this.ifSubmit = true;
    if(this.inviteUserForm.value.FirstName==null || this.inviteUserForm.value.FirstName==''){
      this.toaster.warning('Please fill up the required field.');
      return;
    }
    if (this.inviteUserForm.value.Email === this.loggedInUserRule.Email) {
      this.toaster.warning("Email already in used.");
      return;
    }
    if (this.inviteUserForm.invalid) {
      this.toaster.warning("Wrong/Invalid mail address");
      return;
    }
    if(this.totalAdmin<=0 && this.inviteUserForm.value.RoleId!=1){
      this.toaster.warning('There has to at least one admin availabe after revoke.'); return;
    }
    this.spinner.show();
    this.sendInvite(this.inviteUserForm.value);
  }

  rule = (e) => {
    if (e.target.value == 1) {
      this.inviteUserForm.controls.RevokeMe.enable();
    } else {
      this.inviteUserForm.get("RevokeMe").patchValue(false);
      this.totalAdmin > 1 && e.target.value == 1
        ? this.inviteUserForm.controls.RevokeMe.enable()
        : this.inviteUserForm.controls.RevokeMe.disable();
    }
  }

  sendInvite = (item) => {
    this.spinner.show();
    this.mainService.inviteNewUserData(item).subscribe((x) => {
      if (x.Success) {
        this.ngxSmartModalService.getModal("inviteUser").close();
        this.toaster.success("Invitation has been send!");
        this.inviteUserForm.reset(), this.initInviteUserForm();
        this.ifSubmit = false;
        this.status = "Revoked";
        this.spinner.hide();
        this.getAllUser();
      } else {
        this.toaster.warning(x.Message);
        this.getAllUser();
        this.spinner.hide();
        this.ngxSmartModalService.getModal("inviteUser").close();
      }
    },
    (err) => {
      this.spinner.hide();
      this.toaster.error("Something went wrong, please try again !");
    });
  };

  revokeUser = (item) => {
    this.spinner.show();
    this.mainService.RevokeUser(item).subscribe(
      (x) => {
        this.spinner.hide();
        if (x.result.Success) {
          this.toaster.success("Revoked user Successfully");
          this.getAllUser();
          this.getAllCompanyUserRole();
        } else {
          this.toaster.warning(x.result.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, please try again !");
      }
    );
  }

  revokeUserFromRole = (item) => {
    this.spinner.show();
    this.mainService.RevokeUserRole(item.Id).subscribe((x) => {
      this.spinner.hide();
      if (x.Success) {
        this.toaster.success("Role revoked Successfully");
        this.getAllCompanyUserRole();
      } else {
        this.toaster.warning(x.result.Message);
      }
    },
    (err) => {
      this.spinner.hide();
      this.toaster.error("Something went wrong, please try again !");
    });
  }

  editInitInviteUserForm = () => {
    this.editInviteUserForm = this.fb.group({
      Id: "",
      FirstName: [""],
      LastName: [""],
      Email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(\d{11}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)
        ]),
      ],
      RoleId: [2],
      RevokeMe: [{ value: "" }],
    });
  }

  openeditInvitePopUp = (item) => {
    this.roleList = this.userRoleList;
    this.ifEditable = true;
    this.getAllUserRuleList();
    this.initUserRoleForm();
    this.setInviteUserFormValue(item);
    this.ngxSmartModalService.create("AddRole", "content").open();
  }

  setInviteUserFormValue(items) {
    let list = [];
    let item = items.CompanyWiseUserList;
    this.myItem = items;
    for (let i = 0; i < item.length; i++)
    {
      this.CompanyUserRoleForm.get('Id').patchValue(item[i].Id);
      this.CompanyUserRoleForm.get('UserId').patchValue(item[i].UserId);
      this.CompanyUserRoleForm.get('SubscriberId').patchValue(item[i].SubscriberId);
      this.CompanyUserRoleForm.get('CompanyId').patchValue(item[i].CompanyId);
      list.push(item[i].RoleId);
    }
    this.CompanyUserRoleForm.get('RoleId').patchValue(list);
  }

  updateInviteUser = (item) => {
    this.spinner.show();
    this.mainService.EditUserData(item).subscribe((x) => {
      if (x) {
        this.ngxSmartModalService.getModal("editInviteUser").close();
        this.getAllUser();
        this.spinner.hide();
      } else {
        this.ngxSmartModalService.getModal("editInviteUser").close();
        this.spinner.hide();
      }
    });
  }

  ruleForEditInvite = (e) => {
    if (e.target.value == 1) {
      this.editInviteUserForm.controls.RevokeMe.enable();
    } else {
      this.editInviteUserForm.get("RevokeMe").patchValue(false);
      this.totalAdmin > 1
        ? this.editInviteUserForm.controls.RevokeMe.enable()
        : this.editInviteUserForm.controls.RevokeMe.disable();
    }
  }

  cancelInvitation = (item) => {
    this.spinner.show();
    this.mainService.CancelInvitationUser(item).subscribe(
      (x) => {
        this.spinner.hide();
        if (x) {
          this.toaster.success("Cancelled user Successfully");
          this.getAllUser();
        } else {
          this.toaster.warning(x.result.Message);
        }
      },
      (err) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, please try again !");
      }
    );
  };

  log = (msg) => {
    this.status = "Revoked";
    this.inviteUserForm.reset();
    this.initInviteUserForm();
  }

  public goUrl() {
    this.router.navigate([this.subscription]);
  }

  convetToPDF = () => {
    var data = document.getElementById("user");
    (html2canvas as any)(data).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;
      var DocumentName = `user_list.pdf)`;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    });
  }

  goToUserProfile = () =>
  {
    this.router.navigate(['/profile/user-profile']);
  }

  getAllCompanyUserRole = () =>
  {
    this.spinner.show();
    this.settingService.GetAllCompanyUserRole().subscribe((x) => {
      this.allUserRole = x;
      this.spinner.hide();
    });
  }

  saveComapnyWiseUserRole = () =>
  {
    if (this.ifEditable == false){
      this.settingService.SaveCompanyWiseUserRole(this.CompanyUserRoleForm.value).subscribe((x) =>{
        if (x.Success){
          this.getAllUser();
          this.ngxSmartModalService.getModal('AddRole').close();
          this.initUserRoleForm();
          this.toaster.success('User Wise Role created successfully.');
        }
        else{
          this.spinner.hide();
          this.toaster.warning('The same user role combination already exists.');
        }
      });
    }
    else
    {
      if (this.CompanyUserRoleForm.invalid){
        this.toaster.warning('Please fill all the required Field');
        return ;
      }
      this.editComapnyWiseUserRole();
    }
  }

  editComapnyWiseUserRole = () =>
  {
    let checkAdmin = 0;
    let searchData = [];
    for(let i=0;i<this.allUserList.length;i++){
      if(this.allUserList[i].StatusId==2){
        checkAdmin += this.allUserList[i].CompanyWiseUserList.filter(x=>x.RoleId==1).length;
      }
    }
    searchData = this.allUserList.filter(x=>x.Id == this.myItem.Id); console.log(searchData)
    if(searchData[0].CompanyWiseUserList.filter(x=>x.RoleId==1).length>0 && this.CompanyUserRoleForm.value.RoleId.filter(x=>x==1).length==0) checkAdmin--;
    if(checkAdmin<=0){
      this.toaster.warning('At least one person needs to be an Admin.'); return;
    }
    this.spinner.show();
    this.settingService.AddOrRemoveCompanyWiseUserRole(this.CompanyUserRoleForm.value).subscribe((x) =>
    {
      if (x.Success){
        this.spinner.hide();
        this.getAllUser();
        this.ngxSmartModalService.getModal('AddRole').close();
        this.initUserRoleForm();
        this.toaster.success('User Wise Role created successfully.');
      }
      else
      {
        this.spinner.hide();
        this.toaster.warning('The same user role combination already exists.');
      }
    });
  }

  initUserRoleForm = () => {
    this.CompanyUserRoleForm = this.fb.group({
      UserId: [ {disabled: true}],
      Id: [0],
      RoleId: ['', Validators.required],
      CompanyId: [0],
      SubscriberId : [0]
    });
  }

  openUserRolePopup = () => {
    this.initUserRoleForm();
    this.getAllUserRuleList();
    this.ngxSmartModalService.create('AddRole', 'content').open();
  }
  
  getAllUserRuleList = () => {
    this.settingService.GetAllRuleWiseUserList().subscribe((x) =>
    {
      this.userList = x;
    });
  }

  getCompanySubscriptionList() {
    const data = {
      CompanyName: this.userInfo.SelectedCompany.Name.toString(),
      CompanyId: this.userInfo.SelectedCompany.CompanyId
    }
    this.subscriptionService.GetSubscriberByCompany(data).subscribe(x => {
      this.SubscriptionList = x;
      this.isTrialActive = this.IsTrialActive();
    }) 
  }

  IsTrialActive(){
    return this.SubscriptionList.some( x => x.IsActive == true && x.PackageTypeId == 4 );  
  }

}
