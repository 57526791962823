import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as html2canvas from "html2canvas";
import jspdf from 'jspdf';
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { TransfermoneyServiceService } from "src/app/service/TransferMoney/transfermoney-service.service";
@Component({
  selector: "app-transfer-money-list",
  templateUrl: "./transfer-money-list.component.html",
  styleUrls: ["./transfer-money-list.component.css"],
})
export class TransferMoneyListComponent implements OnInit {
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private dataService: DataService,
    private modalService: NgbModal,
    private _ngZone: NgZone,
    public ngxSmartModalService: NgxSmartModalService,
    private toaster: ToastrService,
    private transfermoneyService: TransfermoneyServiceService
  ) {}

  private gridApi;
  private gridColumnApi;
  domLayout = "autoHeight";
  isItemListUpdate = false;
  searchText: string;
  TransferMoneyList: any = [];
  ngOnInit(): void {}

numberFormatter(params) {
  const subTotalAmount = parseFloat(params.value).toFixed(2);
  return parseFloat(subTotalAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}
  columnDefs = [
    {
      headerName: "Date",
      field: "TarnsferDate",
      valueFormatter: this.convertGridDateIntoDisplay,
    },
    { headerName: "From", field: "AccountName" },
    { headerName: "Reference", field: "ReferenceNo" },
    { headerName: "Transferred Amount", field: "Amount", valueFormatter: this.numberFormatter,},
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    cellStyle: function (params) {
      if (params.column.colId == "TarnsferDate") {
        return { color: "blue", cursor: "pointer" };
      }
    },
  };

  getAllProduct = () => {
    this.spinner.show();
    this.TransferMoneyList = [];
    this.transfermoneyService.GetTransferMoneyList().subscribe((x) => {
      this.spinner.hide();
      this.TransferMoneyList = [...x, ...this.TransferMoneyList];
      console.log(this.TransferMoneyList);
    });
  };
  convertGridDateIntoDisplay(date) {
    console.log(date.data);
    let dateString = moment(date.data.TransferDate).format("Do MMM YYYY");
    return dateString;
  }
  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getAllProduct();
  }
  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  onRowClicked(event) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Date") {
      let id = event.data.Id;
      this.router.navigate([`/transfermoney/view-transfermoney/${id}`]);
    }
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  };
  gotoUrl = () => {
    this.router.navigate([`/transfermoney/create`]);
  };
  convetToPDF = () => {
    var data = document.getElementById("transfer");
    (html2canvas as any)(data).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save("TransferMoneyList.pdf");
    });
  };
}
