import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { transferMoney } from './../../models/main.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransfermoneyServiceService {

  baseurl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  //save create TransferMoney 
  public saveTransferMoney(data:any): Observable<any> {
    return this.http.post<transferMoney>(this.baseurl + 'TransferMoney/Save', data).pipe(retry(1));
  }

  //get spend money list
  public GetTransferMoneyList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'TransferMoney/GetTrnasferList').pipe(retry(1));
  }

  public GetTransferMoney(id): Observable<any> {
    return this.http.get<any>(this.baseurl + `TransferMoney/GetTransferMoney?id=${id}`).pipe(retry(1));
  }

}