<app-dashboard>
  <div *ngIf="show">
    <h2>
      Please login to the specific company to view the rejected document.
    </h2>
  </div>
  <ngx-smart-modal #AttachmentViewerSingle identifier="AttachmentViewerSingle" (onClose)="modalClose()">
    <div class="pb-4">
      <span class="modalHeaderText pl-2" class="attachment-header">{{
        selected?.FileName
      }}</span>
      <hr class="notShow" />
      <div class="row pr-2 pl-2 mr-2">
        <div class="col-md-12 notShow">
          <div class="row">
            <div class="col-md-4">
              <table style="color: #7f7f7f; font-size: 13px">
                <tr>
                  <td>Document Name :</td>
                  <td>
                    {{ selected?.DocumentName }}
                  </td>
                </tr>
                <tr>
                  <td>Document Type :</td>
                  <td>
                    {{ selected?.DocumentType }}
                  </td>
                </tr>
                <tr>
                  <td>Created Date :</td>
                  <td>
                    {{ convertDateToDisplay(selected?.UploadedAt) }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-8" style="text-align: right">
              <a
                ><span class="option-icon-color" (click)="zoomIn()"
                  ><i class="material-icons borderradiusleft attachmenticon"
                    >zoom_in</i
                  ></span
                ></a
              >
              <a
                ><span class="option-icon-color" (click)="zoomOut()"
                  ><i class="material-icons attachmenticon">zoom_out</i></span
                ></a
              >
              <a [hidden]="isPdf"
                ><span class="option-icon-color" (click)="rotate('left')">
                  <i class="material-icons attachmenticon">rotate_left</i>
                </span></a
              >
              <a [hidden]="isPdf"
                ><span class="option-icon-color" (click)="rotate('right')">
                  <i class="material-icons attachmenticon borderradiusright">rotate_right</i>
                </span></a
              >

            </div>
          </div>
        </div>
        <div id="contentToPrint" class="col-md-12">
          <div [hidden]="!isPdf">
            <!-- <iframe id="iframepdf" src={{src}}></iframe> -->
            <!-- <embed width="100%" height="1000px" src={{src}}/> -->
            <pdf-viewer
              [src]="src"
              [render-text]="true"
              [fit-to-page]="true"
              [zoom]="zoom"
              style="display: block"
            >
            </pdf-viewer>
          </div>
          <div [hidden]="isPdf">
            <div style="overflow: overlay">
              <img id="image" style="width: 100%" src="{{ src }}" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
</app-dashboard>
