import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { RouterLinkRendereComponent } from "src/app/components/router-link-rendere/router-link-rendere.component";
import { AcountingServiceService } from "src/app/service/Accounting/acounting-service.service";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { FileEmitterService } from "src/app/service/EventEmitter/file.emitter.service";
import { IncomeServiceService } from "src/app/service/Income/income-service.service";
import { InventoryOverviewService } from "src/app/service/InventoryOverview/inventory-overview.service";
import { SalesOrderService } from "src/app/service/SalesOrder/sales-order.service";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { CreateShipmentComponent } from "../create-shipment/create-shipment.component";
import { SalesOrderAdjustmentComponent } from "../sales-order-adjustment/sales-order-adjustment.component";
import { SalesOrderAdvanceComponent } from "../sales-order-advance/sales-order-advance.component";
import { ShipmentEditComponent } from "../shipment-edit/shipment-edit.component";
import { ShipmentItemViewComponent } from "../shipment-item-view/shipment-item-view.component";
import { SalesOrderModalComponent } from "../../Inventory/sales-order-modal/sales-order-modal.component";
import { ItemType } from "src/app/enum/item-type";
import { ProductServiceService } from "src/app/service/Products/product-service.service";
import { Item } from "src/app/models/inventoryOverview.model";
import { iQuidiActionType } from "src/app/enum/iquidiActionType";
import { WareHouseOperationType } from "src/app/enum/wareHouseOperationType";

@Component({
  selector: "app-sales-order-details",
  templateUrl: "./sales-order-details.component.html",
  styleUrls: ["./sales-order-details.component.css"],
  host: {
    "(document:click)": "onWindowClick($event)",
  },
})
export class SalesOrderDetailsComponent implements OnInit {
  salesInvoices: any = [];
  shipmentList: any = [];
  salesExpenses: any = [];
  advanceList: any = [];
  salesManufacturer: any = [];
  shippedAmount: number = 0;
  raminingAmount: number = 0;
  totalInvoice: number = 0;
  totalUnInvoice: number = 0;
  totalExpense: number = 0;
  searchText: string;

  gridApi;
  expenseGridApi;
  manufactureGridApi;
  hasManufacturerItem = false;
  isAdvanceShow = true;
  isInvoiceShow: boolean = false;
  isExpenseShow: boolean = false;
  isManufactureShow: boolean = false;
  isShipmentShow: boolean = false;
  subTotalAmount: number = 0;
  isEditable: boolean = false;
  canQuotationShow: boolean = false;
  isOnHold: boolean = false;
  isOnGoing: boolean = false;
  isClosed: boolean = false;
  isComplete: boolean = false;
  totalFile: number = 0;
  SalesOrderData: any = [];
  advanceGridApi;
  allSaleableItem: any = [];
  chartOfAccount: any = [];
  taxRateList: any = [];
  SalesOrderId: string;
  SalesOrderNo: string;
  showInvoice = false;
  showShipment = false;
  one = "active";
  two = "";
  three = "";
  four = "";
  wareHouseStatus: number = 1;
  wareHouseReadybool: boolean = true;
  productList: any = [];
  SalesOrderWareHouseData: any = [];
  wareHouseId: number;
  wareHousedata: number;
  invoiceData: number;
  expenseData: number;
  advanceData: number;
  isFullOrPartialShipped: boolean = false;
  totalPaidAmount: number = 0;
  totalDueAmount: number  = 0;
  totalShipped: number = 0;
  totalItemsQuantity: number = 0;
  statusId: number;
  ProductList: any;

  isAllItemsDescriptiveOrService: boolean = false;
  hasReturnWareHouseData: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public fb: FormBuilder,
    public fileEmitterService: FileEmitterService,
    private salesService: SalesOrderService,
    private settingService: SettingsServiceService,
    private accountingService: AcountingServiceService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private incomeService: IncomeServiceService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private dataService: DataService,
    public inventoryOverviewService: InventoryOverviewService,
    public productServiceService: ProductServiceService
  ) {}
  advGridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
    advanceColumnDefs: [
      { headerName: "Advance No.", field: "AdvNo" },
      { headerName: "Date", field: "TransactionDateVw" },
      { headerName: "Account", field: "AccountName" },
      { headerName: "Amount", field: "Amount" },
      { headerName: "Settlement", field: "Settlement" },
      { headerName: "Balance", field: "Balance" },
    ],
    domLayout: "autoHeight",
  };
  ngOnInit(): void {
    this.SalesOrderId = this.route.snapshot.paramMap.get("id");
    this.getOrderDetails(this.SalesOrderId);
    this.getAllSaleableItem();
    this.getTaxRateList();
    this.getChartOfAccount();
    this.getItemDetails();
    this.dataService.shipmentListUpdate.subscribe(() => {
      this.getShipment(this.SalesOrderId);
      this.isShipmentShow = true;
      this.isManufactureShow = false;
      this.isInvoiceShow = false;
      this.isExpenseShow = false;
    });
  }

  getItemDetails(){
    let itemTypeId = ItemType.TradingItem;
    this.incomeService.getSaleableItemDetails().subscribe((x) => {
      this.spinner.hide();
      this.ProductList = x;
    });
  }

  deleteSalesOrder() {
    if (this.invoiceData > 0) {
      this.toaster.warning("A sales order can only be deleted after the corresponding invoice for the same order has been deleted!");
      return;
    }
    this.spinner.show();
    this.SalesOrderData.IQuidiActionType = iQuidiActionType.Delete;
    this.salesService.UpdateStatus(this.SalesOrderData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Sales Order ${this.SalesOrderNo} has been successfully deleted`,"Success!");
        this.router.navigate(["sales/sales-order-list/All"]);
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }
  getChartOfAccount = () => {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;
    });
  };
  getAccountName = (item) => {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;
      item.forEach((element) => {
        var x = this.chartOfAccount.find((p) => p.Id === element.AccountId);
        element.AccountName = x.AccountName;
      });
    });
    return item;
  };
  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  };
  getOrderDetails = (id: any) => {
    if (id[0] == "S") {
      this.salesService.GetOrderDetailsByRef(id).subscribe((x) => {
        if (x) {
          this.fileEmitterService.emitFileRefIdUpdate({
            id: this.SalesOrderId,
          });
          this.SalesOrderData = x.data;
          this.productList = x.item;
          this.IsStockAvailable();
          console.log(this.SalesOrderData);
          this.SalesOrderId = this.SalesOrderData.Id;
          this.getExpenses(this.SalesOrderId);
          this.getShipment(this.SalesOrderId);
          this.getSalesOrderManufacture(this.SalesOrderId);
          this.getAdvance(this.SalesOrderData.Id);
          this.SalesOrderNo = x.data.OrderNo;
          this.getDeliveryOrderBySalesOrderNo(this.SalesOrderNo);
          this.subTotalAmount =
            this.SalesOrderData.OrderAmount -
            this.SalesOrderData.TaxAmount -
            this.SalesOrderData.AdvanceTaxAmount;
          this.canQuotationShow =
            this.SalesOrderData.QuotationId > 0 ? true : false;
          this.isEditable = this.SalesOrderData.StatusId == 1 ? true : false;
          this.isOnGoing = this.SalesOrderData.StatusId == 2 ? true : false;
          this.isOnHold = this.SalesOrderData.StatusId == 3 ? true : false;
          this.isClosed = this.SalesOrderData.StatusId == 4 ? true : false;
          this.getInvoices(
            x.data.OrderNo,
            x.data.OrderAmount,
            x.data.TaxAmount,
            x.data.AdvanceTaxAmount
          );
          this.SalesOrderData.SalesOrderItem.forEach((element) => {
            let value = x.item.find((e) => e.Id == element.ItemId);
            element.Balance = value.Balance;
            element.ItemTypeId = value.ItemTypeId;
            if (element.Balance > 0 && element.ItemId > 0)
              element.HasItem = true;
            else element.HasItem = false;
          });
        }
      });
    } else {
      this.salesService.GetOrderDetails(id).subscribe((x) => {
        console.log("This is sales order data ==>", x.data);
        if (x) {
          this.fileEmitterService.emitFileRefIdUpdate({
            id: this.SalesOrderId,
          });
          this.SalesOrderData = x.data;
          this.statusId = this.SalesOrderData.StatusId;
          this.productList = x.item;
          this.IsStockAvailable();
          this.isDescriptiveSalesOrder();
          this.SalesOrderId = this.SalesOrderData.Id;
          console.log(this.SalesOrderData);
          this.getExpenses(this.SalesOrderId);
          this.getShipment(this.SalesOrderId);
          this.getSalesOrderManufacture(this.SalesOrderId);
          this.getAdvance(this.SalesOrderData.Id);
          this.SalesOrderNo = x.data.OrderNo;
          this.getDeliveryOrderBySalesOrderNo(this.SalesOrderNo);
          this.subTotalAmount =
            this.SalesOrderData.OrderAmount -
            this.SalesOrderData.TaxAmount -
            this.SalesOrderData.AdvanceTaxAmount;
          this.canQuotationShow =
            this.SalesOrderData.QuotationId > 0 ? true : false;
          this.isEditable = this.SalesOrderData.StatusId == 1 ? true : false;
          this.isOnGoing = this.SalesOrderData.StatusId == 2 ? true : false;
          this.isOnHold = this.SalesOrderData.StatusId == 3 ? true : false;
          this.isClosed = this.SalesOrderData.StatusId == 4 ? true : false;
          this.isComplete = this.SalesOrderData.StatusId == 5 ? true : false;
          this.getInvoices(
            x.data.OrderNo,
            x.data.OrderAmount,
            x.data.TaxAmount,
            x.data.AdvanceTaxAmount
          );
          this.SalesOrderData.SalesOrderItem.forEach((element) => {
            this.totalItemsQuantity = this.totalItemsQuantity + element.Quantity;
            let value = x.item.find((e) => e.Id == element.ItemId);
            element.Balance = value != null ? value.Balance : 0;
            element.ItemTypeId = value != null ? value.ItemTypeId : 0;
            if (element.Balance > 0 && element.ItemId > 0) {
              element.HasItem = true;
              if (this.wareHouseReadybool) {
                this.wareHouseStatus = 3;
              }
            } else {
              element.HasItem = false;
              this.wareHouseReadybool = false;
              this.wareHouseStatus = 1;
            }
            element.isDescriptiveOrServiceItem = this.IsDescriptiveOrServiceItem(element);
          });
          this.SalesOrderData.ShippedItems.forEach((item) => {
            this.totalShipped = this.totalShipped + item;
            if(item > 0){
              this.isFullOrPartialShipped = true;
            }
          });
          this.isAllItemsDescriptiveOrService = this.IsAllItemsDescriptiveOrService();
        }
      });
    }
  };

  isDescriptiveSalesOrder() {
    let isDescriptive = !this.SalesOrderData?.SalesOrderItem?.some( x => x.ItemId != null );
    return isDescriptive;
  }

  getDeliveryOrderBySalesOrderNo(OrderNo) {
    this.inventoryOverviewService.GetDeliveryOrderBySalesOrderNo(OrderNo).subscribe((x) => {
      if (x) {
        this.wareHouseId = x[0]?.Id;
        this.wareHousedata = x.length;
        this.hasReturnWareHouseData = x?.some(x => x.OperationType == WareHouseOperationType.SoldItemReturnedForSalesOrder);
      }
    });
  }

  IsStockAvailable(){
    this.SalesOrderData.SalesOrderItem.forEach((element, i) => {
      let value = this.productList.find((e) => e.Id == element.ItemId);
      if(value == null || value == undefined) {
        element.isStockAvailable = true;
      }
      else{
        if(value.StockQuantity < (element.Quantity - this.SalesOrderData.ShippedItems[i])){
          element.isStockAvailable = false;
        }
        else{
          element.isStockAvailable = true;
        }
      }
    });
  }

  print = () => {
    let hostUrl = location.origin + "/#/";
    window.open(`${hostUrl}sales/preview/${this.SalesOrderId}`, "_blank");
  };
  formatTaxRate(id: number) {
    let value = this.taxRateList.find((e) => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }
  twoDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.0;
  };
  getAllSaleableItem = () => {
    this.incomeService.GetAllSaleableItem().subscribe((x) => (this.allSaleableItem = x));
  };
  formatItem(id: string) {
    let value = this.allSaleableItem.find((e) => e.Id == id);
    if (!value) {
    } else {
      // return value.ItemCode + "-" + value.ItemName;
      return value.ItemName;
    }
  }
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY");
    return dateString;
  };
  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }
  onWindowClick(event) {
    if (
      event.target.id == "attachmentDropdown" ||
      event.target.id == "attachment" ||
      event.target.id == "files" ||
      event.target.id == "Preview"
    ) {
    } else if (event.target.id == "attachment_btn") {
      this.openAttachmentDropdown();
    } else {
      if (
        document.getElementById("attachmentDropdown").classList.contains("show")
      ) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }
  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  public async saveAsApproved() {
    let SalesOrderItem = this.SalesOrderData.SalesOrderItem;

    let modalFlag = false,
    i = 0;
    SalesOrderItem = SalesOrderItem.filter( x => x.ItemId != null && x.isDescriptiveOrServiceItem == false );
    let notInStockCount = SalesOrderItem.filter( (x, i) => x.isStockAvailable == false && x.Quantity != this.SalesOrderData.ShippedItems[i] ).length;
    let inStockCount = SalesOrderItem.filter( (x, i) => x.isStockAvailable == true && x.Quantity != this.SalesOrderData.ShippedItems[i] ).length; 

    if( notInStockCount > 0 && inStockCount > 0 ){
      const modalRef = this.modalService.open(SalesOrderModalComponent, {
        size: "lg",
        backdrop: "static",
        keyboard: false,
        centered: true,
      });

      let wareHouseData = {
        ReferenceNo: this.SalesOrderData.OrderNo, //Reference No
        statusId: 1,
        OperationType: 3,
        ParentId: "",
        ContactId: this.SalesOrderData.ContactId,
        OperationDate: this.SalesOrderData.OrderDate,
        allDATA: this.SalesOrderData,
        wareHouseItems: this.ProductList,
      };
      let data = {
        WareHouseData: wareHouseData,
      };
      console.log(data);
      modalRef.componentInstance.fromParent = data;
      modalRef.componentInstance.SalesOrderItemsList = SalesOrderItem;

        modalRef.result.then(
        (result) => {
          console.log("Modal Result", result);
          if(result=='Success'){
            this.spinner.show();
            this.SalesOrderData.IQuidiActionType = iQuidiActionType.Approve;
            this.salesService.UpdateStatus(this.SalesOrderData).subscribe(
              (x) => {
                if (x.Success) {
                  this.toaster.success("Approved successfully.");
                  this.router.navigate(["sales/sales-order-list/OnGoing"]);
                } else {
                  this.toaster.error(x.Message);
                }
                this.spinner.hide();
              },
              (err) => {
                this.toaster.error("ooh, something went wrong !");
              }
            );
          }
          else{
            return;
          }
        },
        (reason) => {}
      );
      return;
    }
    this.spinner.show();
    this.SalesOrderData.IQuidiActionType = iQuidiActionType.Approve;
    this.salesService.UpdateStatus(this.SalesOrderData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("Approved successfully.");
          this.router.navigate(["sales/sales-order-list/OnGoing"]);
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      }
    );
  }

  // public saveAsApproved() {
  //   console.log("This is sales order data ==>", this.SalesOrderData);
  //   console.log("This is wareHouse status ==>", this.wareHouseStatus);
  //   this.spinner.show();
  //   this.salesService
  //     .ApproveSalesOrderFromSalesOrder(
  //       this.SalesOrderData,
  //       this.wareHouseStatus
  //     )
  //     .subscribe(
  //       (x) => {
  //         if (x.Success) {
  //           this.toaster.success("Approved successfully.");
  //           this.router.navigate(["sales/sales-order-list/OnGoing"]);
  //         } else {
  //           this.toaster.error(x.Message);
  //         }
  //         this.spinner.hide();
  //       },
  //       (err) => {
  //         this.toaster.error("ooh, something went wrong !");
  //       }
  //     );
  // }
  public saveAsOnHold() {
    this.spinner.show();
    this.salesService.OnHold(this.SalesOrderData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("On Hold successfully.");
          this.router.navigate(["sales/sales-order-list/OnHold"]);
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      }
    );
  }
  public saveAsOnGoing() {
    this.spinner.show();
    this.salesService.OnGoing(this.SalesOrderData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("On Hold successfully.");
          this.router.navigate(["sales/sales-order-list/OnGoing"]);
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      }
    );
  }
  public saveAsClosed() {
    this.spinner.show();
    this.salesService.Close(this.SalesOrderData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("Closed successfully.");
          this.router.navigate(["sales/sales-order-list/Closed"]);
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      }
    );
  }

  getManufacturingProducts = () => {
    // this.spinner.show()
    // this.salesService.GetSalesOrderList().subscribe((x)=>{
    //   this.allData=x;
    //   this.allData.forEach(x => {
    //     x.StatusId == this.orderStatus.Draft ? this.draftCount += 1 : x.StatusId == this.orderStatus.OnGoing ? this.onGoingCount += 1 : x.StatusId == this.orderStatus.OnHold ? this.onHoldCount += 1 : this.closedCount+=1 ;
    //   });
    //   this.filteredLists = this.orderStatus[this.status] == this.orderStatus.All ? x : x.filter(y=>y.StatusId == this.orderStatus[this.status]);
    //   this.spinner.hide()
    // }, err=>{
    //   this.toaster.error("There is an error,please check");
    //   this.spinner.hide()
    // })
  };
  openManufacturingTab = () => {
    this.one = "";
    this.two = "active";
    this.three = "";
    this.four = "";
    this.isManufactureShow = true;
    this.isInvoiceShow = false;
    this.isExpenseShow = false;
    this.isShipmentShow = false;
    this.isAdvanceShow = false;
  };
  openAdvanceTab = () => {
    this.one = "active";
    this.two = "";
    this.three = "";
    this.four = "";
    this.isAdvanceShow = true;
    this.isManufactureShow = false;
    this.isInvoiceShow = false;
    this.isExpenseShow = false;
    this.isShipmentShow = false;
  };
  getShipment = (id) => {
    this.salesService.GetShipment(id).subscribe(
      (x) => {
        this.shipmentList = x.data;
        this.shippedAmount = x.shippedAmount;
        this.raminingAmount = this.subTotalAmount - x.shippedAmount;
      },
      (err) => {}
    );
  };
  openShipmentTab = () => {
    this.one = "";
    this.two = "";
    this.three = "active";
    this.four = "";
    this.isManufactureShow = false;
    this.isInvoiceShow = false;
    this.isExpenseShow = false;
    this.isShipmentShow = true;
    this.isAdvanceShow = false;
  };
  getInvoices = (orderNo, amount, taxAmount, advanceTaxAmount) => {
    this.incomeService.GetInvoiceList().subscribe(
      (x) => {
        this.salesInvoices = x.data.filter((y) => y.ReferenceNo == orderNo);
        this.salesInvoices.forEach((invoice) => {
          this.totalPaidAmount = this.totalPaidAmount + invoice.PaidAmount;
          this.totalDueAmount = this.totalDueAmount + invoice.DueAmount;
        });
        this.invoiceData = this.salesInvoices.length;
        if (this.salesInvoices.length > 0) {
          this.showInvoice = true;
        }
        this.totalInvoice = this.salesInvoices.reduce(function (prev, cur) {
          return prev + cur.InvoiceAmount;
        }, 0);
        console.log(this.totalInvoice);
        console.log(amount);
        //this.totalUnInvoice = amount - (taxAmount + advanceTaxAmount + this.totalInvoice);
        this.totalUnInvoice = amount -  this.totalInvoice;
        if (this.totalUnInvoice < 0) this.totalUnInvoice = 0;
      },
      (err) => {}
    );
  };
  openInvoiceTab = () => {
    this.isInvoiceShow = true;
    this.isExpenseShow = false;
    this.isManufactureShow = false;
    this.isShipmentShow = false;
    this.isAdvanceShow = false;
  };
  getSalesOrderManufacture = (id) => {
    this.salesService.SalesOrderManufacture(id).subscribe(
      (x) => {
        this.salesManufacturer = x.data;
        this.hasManufacturerItem = x.canShowManufacturerTab;
      },
      (err) => {}
    );
  };
  getExpenses = (id) => {
    this.salesService.GetSalesItemExpense(id).subscribe(
      (x) => {
        this.salesExpenses = x;
        this.expenseData = this.salesExpenses.filter((x) => x.ItemId != null).length;
        this.totalExpense = x.reduce(function (prev, cur) {
          return prev + cur.TotalAmount;
        }, 0);
      },
      (err) => {}
    );
  };
  openExpenseTab = () => {
    this.one = "";
    this.two = "";
    this.three = "";
    this.four = "active";
    this.isExpenseShow = true;
    this.isInvoiceShow = false;
    this.isManufactureShow = false;
    this.isShipmentShow = false;
    this.isAdvanceShow = false;
  };
  shipmentGridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
    columnDefs: [
      {
        headerName: "Shipment No",
        field: "ShipmentNo",
        width: 150,
        cellRendererFramework: ShipmentItemViewComponent,
      },
      { headerName: "Delivery By", field: "DeliveryBy", width: 130 },
      { headerName: "Delivery To", field: "DeliveryTo", width: 160 },
      { headerName: "Delivery Address", field: "DeliveryAddress", width: 160 },
      {
        headerName: "Delivery Date",
        field: "DeliveryDate",
        valueFormatter: this.convertInvoiceGridDateIntoDisplay,
        width: 140,
      },
      {
        headerName: "Shipped Date",
        field: "ArrivalDate",
        valueFormatter: this.convertInvoiceGridDateIntoDisplay,
        width: 135,
      },
      {
        headerName: "Status",
        field: "StatusId",
        valueFormatter: this.ShipmentStatus,
        width: 160,
      },
      {
        headerName: "",
        field: "Id",
        cellRendererFramework: ShipmentEditComponent,
      },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
  };
  invoiceGridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
    columnDefs: [
      {
        headerName: "Invoice No",
        field: "InvoicceNo",
        width: 150,
        cellRendererFramework: RouterLinkRendereComponent,
        cellRendererParams: {
          inRouterLink: "/income/invoice/",
        },
      },
      { headerName: "Ref", field: "ReferenceNo", width: 130 },
      {
        headerName: "Date",
        field: "InvoiceDate",
        valueFormatter: this.convertInvoiceGridDateIntoDisplay,
        width: 140,
      },
      {
        headerName: "Due Date",
        field: "DueDate",
        valueFormatter: this.convertInvoiceGridDateIntoDisplay,
        width: 135,
      },
      {
        headerName: "Invoice Amount",
        field: "InvoiceAmount",
        valueFormatter: this.InvoiceInvoiceAmountFormatter,
        width: 150,
      },
      // { headerName: 'Discount', field: 'DiscountAmount',width:150 },
      {
        headerName: "Due",
        field: "DueAmount",
        valueFormatter: this.InvoiceDueAmountFormatter,
        width: 150,
      },
      {
        headerName: "Status",
        field: "StatusId",
        valueFormatter: this.InvoiceStatus,
        width: 160,
      },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
  };
  onInvoiceGridReady = (event) => {
    this.gridApi = event.api;
  };
  onShipmentGridReady = (event) => {
    this.gridApi = event.api;
  };
  convertInvoiceGridDateIntoDisplay(date) {
    if (date.value == null) return null;
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }
  InvoiceDueAmountFormatter(value) {
    let amount =
      value.data.InvoiceAmount -
      (value.data.PaidAmount + value.data.DiscountAmount);
    return amount.toLocaleString();
  }
  InvoiceInvoiceAmountFormatter(value) {
    let valueData = Number(value.data.InvoiceAmount.toFixed(2));
    return valueData.toLocaleString();
  }
  InvoiceStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return "Payment Approval";
      case 2:
        return "Awaiting Approval";
      case 3:
        return "Awaiting Payment";
      case 4:
        return "Paid";
      default:
        return "unknown";
    }
  }
  ShipmentStatus(id) {
    switch (id.data.StatusId) {
      case 1:
        return "Draft";
      case 2:
        return "Shipped";
      case 3:
        return "Cancelled";
      default:
        return "unknown";
    }
  }
  public createNewInvoice = () => {
    this.router.navigate(["income/create-invoice"], {
      queryParams: { ExternalReferenceNo: this.SalesOrderData.Id },
    });
  };
  createNewShipment = () => {
    const modalRef = this.modalService.open(CreateShipmentComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.fromParent = { SalesOrderId: this.SalesOrderId };
    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {}
    );
  };
  closeModal = () => {
    this.activeModal.close("Cross click");
  };
  expenseGridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
    columnDefs: [
      { headerName: "Item Code", field: "ItemCode", width: 130 },
      { headerName: "Item Name", field: "ItemName", width: 160 },
      { headerName: "Sold Quantity", field: "Quantity", width: 150 },
      { headerName: "Cost Price", field: "PurchasePrice", width: 150 },
      { headerName: "Amount", field: "TotalAmount", width: 150 },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
  };
  onExpenseGridReady = (event) => {
    this.expenseGridApi = event.api;
  };
  manufactureGridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      flex: 1,
    },
    columnDefs: [
      { headerName: "Transaction No", field: "TransactionNo", width: 130 },
      {
        headerName: "Date",
        field: "TransDate",
        width: 50,
        valueFormatter: this.convertInvoiceGridDateIntoDisplay,
      },
      { headerName: "Type", field: "TransType", width: 150 },
      { headerName: "Reference", field: "ReferenceNo", width: 150 },
      { headerName: "Quantity", field: "Quantity", width: 150 },
      { headerName: "Unit Price", field: "UnitPrice", width: 150 },
      { headerName: "Total", field: "LineTotal", width: 150 },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
  };
  onManufactureGridReady = (event) => {
    this.manufactureGridApi = event.api;
    this.gridApi.sizeColumnsToFit();
  };

  createNewAdvance = () => {
    const modalRef = this.modalService.open(SalesOrderAdvanceComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.fromparent = {
      contactId: this.SalesOrderData.ContactId,
      salesOrderId: this.SalesOrderData.Id,
    };
    modalRef.result.then(
      (result) => {
        if (result) {
          this.openAdvanceTab();
          this.getAdvance(this.SalesOrderData.Id);
        }
      },
      (reason) => {}
    );
  };

  onAdvanceGridReady = (event) => {
    this.advanceGridApi = event.api;
  };
  getAdvance = (id) => {
    this.spinner.show();
    this.salesService.GetAdvanceBySalesId(id).subscribe(
      (x) => {
        this.advanceList = x;
        this.advanceData = this.advanceList.length;
        this.spinner.hide();
      },
      (err) => {}
    );
  };
  public makeAdjustment() {
    this.spinner.show();
    this.salesService.getCustomerBalance(this.SalesOrderData).subscribe((x) => {
      this.createAdvanceAdjustment(x.BalanceData);
      this.spinner.hide();
    });
  }
  createAdvanceAdjustment = (x: any) => {
    const modalRef = this.modalService.open(SalesOrderAdjustmentComponent, {
      size: "md",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.fromParent = x;
    modalRef.result.then(
      (result) => {
        if (result == true) {
          this.getOrderDetails(this.SalesOrderData.Id);
          this.openAdvanceTab();
        } else {
          this.openAdvanceTab();
        }
      },
      (reason) => {}
    );
  };

  editSalesOrder(){
    this.router.navigate(['/sales/sales-order-edit',this.SalesOrderId]);
  }

  isShippedMaxOrMin(Quantity, ShippedItems){
    if(ShippedItems != Quantity && ShippedItems != 0){
      return true;
    }
    else{
      return false;
    }
  }
  
  MarkAsComplete(){
    this.spinner.show();
    this.salesService.MarkAsComplete(this.SalesOrderData).subscribe( (x) => {
      if (x.Success) {
        this.toaster.success(`Purchase Order ${this.SalesOrderNo} has been successfully marked as complete`, 'Success!');
        location.reload();
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  canOnGoing() {
    if(this.isOnHold || this.isClosed){
      return true;
    }
    else{
      return false;
    }
  }

  canOnHold() {
    if(this.isOnGoing || this.isClosed) {
      return true;
    }
    else{
      return false;
    }
  }
  canClose() {
    if(this.isOnGoing || this.isOnHold) {
      return true;
    }
    else{
      return false;
    }
  }

  canEdit() {
    if(this.isOnGoing){
      return true;
    }
    else{
      return false;
    }
  }
  canDelete() {
    if((this.isEditable || this.isOnGoing || this.isClosed) && !this.isFullOrPartialShipped && this.totalPaidAmount == 0){
      return true;
    }
    else{
      return false;
    }
  }

  canMarkAsCompleted(){
    if(this.totalDueAmount == 0 && this.totalItemsQuantity == this.totalShipped && this.salesInvoices.length > 0 && !this.isComplete) {
      return true;
    }
    else{
      return false;
    }
  }

  IsAllItemsDescriptiveOrService() {
    let isAllAreDescriptiveItems: boolean = this.SalesOrderData?.SalesOrderItem?.every( x => x.ItemId == null );
    let isAllAreServiceItems: boolean = this.SalesOrderData?.SalesOrderItem?.every( x => this.productList?.find( p => p.Id == x.ItemId)?.ItemTypeId == 5);
    let isAllAreDescriptiveAndServiceItems: boolean = this.SalesOrderData?.SalesOrderItem?.filter( x => x.ItemId == null || this.productList?.find( p => p.Id == x.ItemId)?.ItemTypeId == 5)?.length == this.SalesOrderData?.SalesOrderItem?.length ? true : false;
    return isAllAreDescriptiveItems || isAllAreServiceItems || isAllAreDescriptiveAndServiceItems ? true : false;
  }

  IsDescriptiveOrServiceItem(item) {
    let isDescriptiveItem: boolean = item.ItemId == null ? true : false;
    let isServiceItem: boolean = item.ItemTypeId == ItemType.Service ? true : false;
    return isDescriptiveItem || isServiceItem ? true : false;
  }
 
  canShowDangerIcon(item, i) {
    if(item.isStockAvailable == false && item.Quantity != this.SalesOrderData?.ShippedItems[i] && !item.isDescriptiveOrServiceItem && !this.hasReturnWareHouseData)
      return true;
    else
      return false;
  }

  canShowSuccessIcon(item, i) {
    if(item.isStockAvailable == true && item.Quantity != this.SalesOrderData.ShippedItems[i] && !item.isDescriptiveOrServiceItem && !this.hasReturnWareHouseData)
      return true;
    else
      return false;
  }

}
