<div class="headSection">
    <form [formGroup]="modalForShiftForm" >
    <div class="modal-header">
        <h4 class="modal-title">Availability</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-borderless">
            <thead >
              <tr>
                <th colspan="2"></th>
                <th>Demand Stock</th>
                <th colspan="4"></th>
                <th>Shipped</th>
                <th colspan="2"></th>
                
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bill of  this.AllDaTa.WareHouseItems">
                <td colspan="2"></td>
                <td>{{bill.ItemDetails.ItemName}}= {{bill.Demand}}</td>
                <td colspan="4"></td>
                <td>{{bill.ItemDetails.ItemName}}= {{bill.ProductInOut}}</td>
                <td colspan="2"></td>
              </tr>
    
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="2"></td>
                    <td>Total= {{sumOfDemand}}</td>
                    <td colspan="4"></td>
                    <td>Total= {{sumOfShipped}}</td>
                    <td colspan="2"></td>
                  </tr>
            </tfoot>
          </table>
    
        <div class="form-check">
          <input class="form-check-input" type="radio" name="BtnOne" id="opt1" formControlName="BtnOne"  value="1">
          <label class="form-check-label" for="opt1">
                Deliver mentioned products in one shipment and the rest in seperate shipment.
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" name="BtnOne" id="opt2" formControlName="BtnOne" value="2">
            <label class="form-check-label" for="opt2">
                Deliver only mentioned products.
            </label>
          </div>
        
    </div>
    
    <div class="modal-footer">
        <a><p><button class="btn btn-sm btn-success"  (click)="Shipped()">Shipped</button></p></a>
    </div>
    </form>
    </div>
    