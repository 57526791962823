<app-dashboard>
    <app-page-header-title mainTitle="{{ 'Procurement.monthlyOptionalExpense' |translate }}" subTitle="Home" backURL="/">
    </app-page-header-title>
    <app-monthly-tab [message]="2"></app-monthly-tab>
    <div class="col-sm-6" style="display: inline-flex;">
        <div class="col-sm-3 mt-4 ml-1">
            <ng-select [items]="monthList" [clearable]="false" bindLabel="Text" bindValue="Value"
            [(ngModel)]="Month" (change)="getTransactionDetails()">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <span>{{item.Text}}</span>
                </ng-template>
            </ng-select>
        </div>
    <div class="col-sm-3 mt-4">
            <ng-select [items]="yearList" [clearable]="false" bindLabel="year" bindValue="Value"
            [(ngModel)]="Year" (change)="getTransactionDetails()">
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span>{{item.year}}</span>
            </ng-template>
        </ng-select>
    </div>
<div class="col-sm-6 col-md-7">
    <!-- <button class="eCount-def-btn mt-4 ml-2 wd" >{{ 'monthlyOptionalBudget.generate' |translate }}</button> -->
    <button type="button" (click)="saveBudget(1)" class="eCount-def-btn ml-2 mt-4 wd" *ngIf="isApprove==false && (Month >= currentMonth && Year === currentYear) ">{{ 'monthlyOptionalBudget.save' |translate }}</button>
    <button type="button" style="background: #5cac00;" (click)="saveBudget(2)" class="eCount-def-btn ml-2 mt-4 wd" *ngIf="isApprove==false && (Month >= currentMonth && Year === currentYear)">{{ 'monthlyOptionalBudget.approve' |translate }}</button>
</div>
</div>

    <div class="ml-4 mt-4 col-sm-6">
        <table class="table table-bordered">
            <thead>
              <tr>
                <th>{{ 'monthlyOptionalBudget.account' |translate }}</th>
                <th>{{ 'monthlyOptionalBudget.budget' |translate }}</th>
                <th></th>
              </tr>
            </thead>

            <tbody *ngFor="let item of MonthlyBudgetItems;let i=index">

            <tr>

            <td class="grid-cell">
                <div class="bd">
                <ng-select [items]="chartOfAccounts" placeholder="Select Account"
                     [clearable]="false" bindLabel="AccountName"
                    bindValue="Id"  [(ngModel)]="MonthlyBudgetItems[i].AccountId" [ngClass]="{'submitted':ifSubmit===true}"
                    groupBy="GroupName" (ngModelChange)="duplicateCheck(i)" required>
                </ng-select>

            </div>
            </td>
                <td class="grid-cell">
                    <input type="text" class="form-control" [(ngModel)]="MonthlyBudgetItems[i].BudgetAmount"  (focus)="onFocusEvent($event,i)" (change)="updateTotalAmount(i)"  appDecimaNumericValidator >
                </td>
                <td class="grid-cell" *ngIf="isApprove==false && (Month >= currentMonth && Year === currentYear)">
                  <span (click)="deleteItem(i)"><img  class="dlt" src="../../../../assets/icons/x-button.png"></span>
                </td>
              </tr>
            </tbody>


          </table>
    </div>
    <div class="col-sm-7 row">
        <div class="addItem col-sm-6" *ngIf="isApprove==false && (Month >= currentMonth && Year === currentYear)">
            <div class="w-100">
                <div class="dropdown mr-4 ml-2">
                    <a class="nav-link" href="#" id="navbarDropdown" style="background-color: white;" role="button" data-toggle="dropdown"
                        aria-haspopup="false" aria-expanded="false" (click)="addItem()">
                        <img class="languageImg" style="height: 35px;" src="../../../assets/image/addIcon.png" /> {{'monthlyOptionalBudget.addNewLine' | translate}}
                    </a>
                </div>
            </div>
        </div>
    <div class="col-sm-8 mt-2 ml-3 pl-4" >
         <a class="anchorBlue" (click)="showAllRows()" *ngIf="isApprove==false && (Month >= currentMonth && Year === currentYear) ">{{ 'monthlyOptionalBudget.showAllAccounts' |translate }}</a>
        <a class="anchorBlue" (click)="removeZeroBalance()" *ngIf="isApprove==false && (Month >= currentMonth && Year === currentYear) ">{{ 'monthlyOptionalBudget.removeZeroBalances' |translate }}</a>
        <a class="anchorBlue" (click)="removeAllBalance()" *ngIf="isApprove==false && (Month >= currentMonth && Year === currentYear) ">Clear All Balance</a>

    </div>
    <div class="col-sm-11 ml-4">
        <table class="conversion-total">
            <tbody class="f-ss-14px">
                <tr>
                    <td class="col-sm-7">{{ 'monthlyOptionalBudget.totalBudgetAmount' |translate }}</td>
                    <td>{{ currencyFormatter(totalAmount, '') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>


    </app-dashboard>
