import { Component, OnInit, ViewChild, AfterViewInit, } from '@angular/core';
import { BusinessOwnerService } from 'src/app/service/Business Owner/business-owner.service';
import  jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-business-owner-list',
  templateUrl: './business-owner-list.component.html',
  styleUrls: ['./business-owner-list.component.css']
})
export class BusinessOwnerListComponent implements OnInit {

  constructor(private ownerService: BusinessOwnerService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router) { }

private gridApi;
private gridColumnApi;
contactList: any = [];
contacts: any = [];
searchText: string;


    columnDefs = [
                { headerName: 'Name', field: 'Name' },
                { headerName: 'Mobile No', field: 'Phone' },
                { headerName: 'Email', field: 'Email' },
                { headerName: 'Role', field: 'RoleName' },
                { headerName: 'Share Capital', field: 'ShareCapital'},
                { headerName: 'Ownership', field: 'OwnerShipPercentage'},
                { headerName: 'Grant Loan', field: 'GrantLoan'},
                { headerName: 'Receive Loan', field: 'ReceiveLoan'},
                { headerName: 'Action', suppressMenu: true, suppressSorting: true, template: `<span  (click)="editItems()" class=""><img style="height: 20px;" src="../../assets/icons/download.png"></span>` },
              ];

domLayout = 'autoHeight';
 defaultColDef = {
                flex: 1,

              };
 ngOnInit(): void {

 }

getOwnerList(){
  this.spinner.show();
  this.ownerService.GetOwnerList().subscribe((x) =>
   {
      this.spinner.hide();
      this.contacts = [...x, ...this.contacts];
    },
    (err) =>
    {
      this.spinner.hide();
     });
    }

onGridReady(event)
{
    this.gridApi = event.api;
    this.gridApi.setHeaderHeight(35);
    this.gridColumnApi = event.columnApi;
    this.getOwnerList();
}

onRowClicked(e)
{
  const colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;

  if (colHeaderName  === 'Action')
  {
    this.editItems(e);
  }
}
 editItems(e)
 {
    this.router.navigate(['owner/edit-business-owner', e.data.Id]);
}

search = () =>
{
  this.gridApi.setQuickFilter(this.searchText);
}
convetToPDF = () => {
  const data = document.getElementById('owner');
  (html2canvas as any)(data).then(canvas => {
    const imgWidth = 208;
    const imgHeight = canvas.height * imgWidth / canvas.width;
    const contentDataURL = canvas.toDataURL('image/png');
    const pdf = new jspdf('p', 'mm', 'a4');
    const position = 0;
    pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    pdf.save('BusinessOwnerList.pdf');
  });
}

}

