import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { ToastrService } from 'ngx-toastr';
import { IncomeServiceService } from 'src/app/service/Income/income-service.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { ReviewDisposalJournalsComponent } from '../review-disposal-journals/review-disposal-journals.component';
import { FixedAssetDisposalType } from 'src/app/enum/fixed-asset-disposal-type';
import { FixedAssetService } from 'src/app/service/FixedAsset/fixedasset.service';
import { FixedAssetDisposalViewModel } from 'src/app/models-viewModels/fixedAssetDisposalViewModel.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-asset-dispose-details',
  templateUrl: './asset-dispose-details.component.html',
  styleUrls: ['./asset-dispose-details.component.css']
})
export class AssetDisposeDetailsComponent implements OnInit {

  @Input() fromParent: any;
  disposeForm: FormGroup;
  allCustomer: any = [];
  loading = false;
  allChartOfAccount: any = [];
  saleProceedsAccountId: number = 0;
  DepreciationTypeList: any = ["No Depreciation", "All depreciation up to and including"];
  today: string;

  Cost: number = 0;
  SaleProceeds: number = 0;
  CurrentAccumulatedDepreciation: number = 0;
  CapitalGainOnDisposal: number = 0;
  BookValue: number = 0;
  GainOnDisposal: number = 0;
  LossOnDisposal: number = 0;
  CaseNo: number = 0;
  isShowSummary: boolean = true;

  StartDate: string;
  EndingDate: string;
  toDateList: [] = [];
  isDisabled: boolean = true;
  disposalDetails: FixedAssetDisposalViewModel;
  disposedDate: any;
  depreciatedToDate: string;
  depreciationStartDate: string;
  selectedDepreciationDate: any;



  constructor(
    private mainService: MainServiceService,
    private toaster: ToastrService,
    private incomeService: IncomeServiceService,
    public activeModal: NgbActiveModal,
    private accountingService: AcountingServiceService,
    public fb: FormBuilder,
    private modalService:NgbModal,
    private pService: FixedAssetService
  ) { }

  ngOnInit(): void {
    this.formatDate();
    this.initializeForm();
    this.getFormData();
    this.GetSingleFixedAssetDepreciationDate();
  }

  formatDate() {
    this.disposedDate = moment(this.fromParent.PurchaseDate).format("YYYY-MM-DD");
    this.depreciationStartDate = moment(this.fromParent.DepreciationStartDate).format("Do MMM YYYY");
    this.depreciatedToDate = moment(this.fromParent.DepreciationToDate).format("Do MMM YYYY");
  }

  initializeForm() {
    this.disposeForm = this.fb.group({
      DisposedDate: [moment().format("YYYY-MM-DD"), Validators.required],
      SaleProceeds: ["", Validators.required],
      CustomerId: ["", Validators.required],
      SaleProceedsAccountId: ["", Validators.required],
      DepreciationTypeId: ["", Validators.required],
      SelectedDepreciationDate: [""]
    });
  }

  getFormData() {
    this.getAllCustomer();
    this.getAllChartOfAccount();
  }

  CreateNewCustomer = (customerName) => {
    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: true,
      IsSupplier: false,
    };
    this.loading = true;
    this.mainService.saveContact(customerData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success("Contact has been successfully added!");
          this.loading = false;
          this.allCustomer.push(x["Data"]);
          let customerObj = this.allCustomer.filter((x) => x.PrimaryPersonFirstName == customerName);
          this.disposeForm.get("CustomerId").patchValue(customerObj[0]["Id"]);
          this.getAllCustomer();
        } else {
          this.toaster.warning(x.Message);
        }
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
        this.loading = false;
      }
    );
  }

  getAllCustomer(){
    this.incomeService.GetAllContactWithDeliveryPartner().subscribe((x: any) => {
      this.allCustomer = x.filter(xy => xy.IsCustomer == true);
    });
  }

  getCustomerFormData() {
    let customer = this.allCustomer.filter(x => x.Id == this.disposeForm.value.CustomerId)[0];
    return customer;
  }

  getAllChartOfAccount() {
    this.accountingService.GetChartOfAccountsforItems().subscribe((x) => {
        this.allChartOfAccount = x.RevenueList;
        this.saleProceedsAccountId = this.allChartOfAccount.filter((xy) => xy.AccountName == "280 - Gain/Loss on sale of Fixed Assets")[0].Id;
        this.disposeForm.patchValue({SaleProceedsAccountId: this.saleProceedsAccountId});
    });
  }

  canShowDate() {
    if(this.disposeForm.value.DepreciationTypeId === "No Depreciation") {
      return false;
    }
    else{
      return true;
    }
  }

  GetSingleFixedAssetDepreciationDate() {
    this.pService.GetSingleFixedAssetDepreciationDate(this.fromParent.Id).subscribe((x) => {
      if(x) {
        this.toDateList = x.toDateList;
        this.StartDate = x.fromDate;
        this.EndingDate = x.toDateList[0].Value;
        this.disposeForm.patchValue({LastDepreciationDate: x.lastDepreciationDate});
      }
    });
  }

  ShowSummary() {
    if (this.disposeForm.invalid){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    this.isShowSummary = false;
    let data = this.formatData();

    if(this.disposeForm.value.DepreciationTypeId === "No Depreciation") {
      data.SelectedDepreciationDate = null;
    }

    const date = new Date(this.disposeForm.value.SelectedDepreciationDate);
    date.setDate(date.getDate() + 1);
    this.selectedDepreciationDate = moment(date).format("Do MMM YYYY");

    this.pService.GetDisposeCalculations(data).subscribe((x) => {
      this.disposalDetails = x;
    });
  }

  formatData() {
    let disposalData: FixedAssetDisposalViewModel = {
      ActionDate: this.disposeForm.value.DisposedDate,
      SoldPrice: this.disposeForm.value.SaleProceeds,
      Customer: this.getCustomerFormData(),
      FixedAssetItem: this.fromParent,
      AssetAccountId: this.fromParent.TypeDetails.AssetAccountId,
      DepreciationAccountId: this.fromParent.TypeDetails.DepreciationAccountId,
      SaleProceedsAccountId: this.disposeForm.value.SaleProceedsAccountId,
      CapitalGainAccountId: 0,
      GainAccountId: 0,
      LossAccountId: 0,
      CapitalGainAmount: 0,
      GainAmount: 0,
      LossAmount: 0,
      Invoice: null,
      ReversalOfDepreciation: 0,
      PostedOfDepreciation: 0,
      AccumulatedDepreciation: 0,
      SelectedDepreciationDate: this.disposeForm.value.SelectedDepreciationDate,
      CaseNo: 0

    }
    return disposalData;
  }

  openReviewDisposalJournalModal(){
    if (this.disposeForm.invalid){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    const modalRef = this.modalService.open(ReviewDisposalJournalsComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
    modalRef.componentInstance.fromParent = this.disposalDetails;
    modalRef.componentInstance.DepreciationType = this.disposeForm.value.DepreciationTypeId;
    
    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }

}

