import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as html2canvas from "html2canvas";
import jspdf from "jspdf";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { AcountingServiceService } from "src/app/service/Accounting/acounting-service.service";
import { BillsServiceService } from "src/app/service/Bill/bills-service.service";
import { FileEmitterService } from "src/app/service/EventEmitter/file.emitter.service";
import { MainServiceService } from "src/app/service/Main/main-service.service";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { environment } from "src/environments/environment";
import { SendBillMailComponent } from "../send-bill-mail/send-bill-mail.component";

@Component({
  selector: "app-bill-preview",
  templateUrl: "./bill-preview.component.html",
  styleUrls: ["./bill-preview.component.css"],
})
export class BillPreviewComponent implements OnInit {
  @ViewChild("content") content: ElementRef;
  fileUrl: string = environment.fileBaseUrl;
  constructor(
    private route: ActivatedRoute,
    private mainService: MainServiceService,
    private billService: BillsServiceService,
    private fileEmitterService: FileEmitterService,
    private accountingService: AcountingServiceService,
    private settingsService: SettingsServiceService,
    private modalService: NgbModal,
    public domSanitizer: DomSanitizer,
    private spinner: NgxSpinnerService
  ) {}

  BillId: any;
  BillData: any = [];
  billFound: boolean = false;
  BillNo: number;
  itemsTotalPaidAmount: number = 0;
  PostingModuleId: number = null;
  ExternalTransactionId: number = null;
  chartOfAccount: any = [];
  paidBill: any = [];
  bankAccounts: any;
  templates: any;
  toPhone: boolean = true;
  toAddress: boolean = true;
  fromPhone: boolean = true;
  fromAddress: boolean = true;
  detailsReference: boolean = true;
  detailsVat: boolean = true;
  otherNotes: boolean = true;
  otherTermsConditions: boolean = true;
  allSaleableItem;
  logo  = true;
  headCompany = true;
  website = true;
  footCompany = true;
  dataSettings;
  taxRateList: any = [];
  company: any;
  userName: any;
  logoUrl: string = "";
  base64Data: string =
    "iVBORw0KGgoAAAANSUhEUgAAAGMAAABQCAIAAACCt1zBAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACuSURBVHhe7dCBAAAADASh+Uv/FAK4ELrFNKWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlNkeEXB4lahgIQEAAAAASUVORK5CYII=";
  ngOnInit(): void {
    this.spinner.show();
    this.BillId = this.route.snapshot.paramMap.get("billId");
    this.getDataSettings();
    this.getBillData();
    this.getAllSaleableItem();
    this.getTaxRateList();
    this.getChartOfAccount();
    this.getBanks();
    this.userName = localStorage.getItem('LoggedInUser');
    console.log(this.userName);
    this.templates = JSON.parse(localStorage.getItem("tem"));
    if (!this.templates) {
      this.templates = {
        t1: true,
        t2: false,
      };
    }
  }
  setValue(value) {
    this.base64Data = value;
  }
  getBase64ImageFromUrl = async (imageUrl) => {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  };
  getBanks = () => {
    this.accountingService.GetBankAccounts().subscribe((s) => {
      this.bankAccounts = s;
    });
  };
  getChartOfAccount = () => {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;
    });
  };
  getBillData = () => {
    this.billService.getBillsByBillNo(this.BillId).subscribe((x) => {
      if (x) {
        this.BillData = x;
        console.log(this.BillData);
        this.fileEmitterService.emitFileRefIdUpdate({ id: this.BillData.Id });
        this.billFound = true;
        this.BillNo = this.BillData.Id;
        this.GetPaymentsByBillId(this.BillNo);
        this.PostingModuleId = this.BillData.PostingModuleId;
        this.ExternalTransactionId = this.BillData.ExternalTransactionId;
        this.getAccountName(this.BillData.BillItems);
        this.getOrganizationData(x.CompanyId);
      }
    });
  };
  getAccountName = (item) => {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

      item.forEach((element) => {
        console.log(element.AccountId);
        var x = this.chartOfAccount.find((p) => p.Id === element.AccountId);
        element.AccountName = x.AccountName;
      });
    });
    return item;
  };
  GetPaymentsByBillId = (data) => {
    this.itemsTotalPaidAmount = 0;
    this.spinner.show();
    this.billService.GetPaymentsByBillId(data).subscribe((x) => {
      this.spinner.hide();
      this.paidBill = x;
      for (let j = 0; j < this.paidBill.length; j++) {
        this.itemsTotalPaidAmount += this.paidBill[j].PaidAmount;
      }
    });
  };
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY");
    return dateString;
  };
  private getTaxRateList = () => {
    this.settingsService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  };
  formatTaxRate(id: number) {
    let value = this.taxRateList.find((e) => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }

  getAllSaleableItem = () => {
    this.billService
      .GetPurchaseableItems()
      .subscribe((x) => (this.allSaleableItem = x));
  };
  getDataSettings = () => {
    this.settingsService.GetDataSettings().subscribe((x) => {
      this.dataSettings = x;
      for (let j = 0; j < this.dataSettings.length; j++) {
        if (
          this.dataSettings[j].TypeName == "To" &&
          this.dataSettings[j].Name == "Phone" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.toPhone = false;
        }
        if (
          this.dataSettings[j].TypeName == "To" &&
          this.dataSettings[j].Name == "Address" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.toAddress = false;
        }
        if (
          this.dataSettings[j].TypeName == "Footer" &&
          this.dataSettings[j].Name == "Phone" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.fromPhone = false;
        }
        if (
          this.dataSettings[j].TypeName == "From" &&
          this.dataSettings[j].Name == "Address" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.fromAddress = false;
        }
        if (
          this.dataSettings[j].TypeName == "Details" &&
          this.dataSettings[j].Name == "Reference No" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.detailsReference = false;
        }
        if (
          this.dataSettings[j].TypeName == "Details" &&
          this.dataSettings[j].Name == "VAT/AIT" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.detailsVat = false;
        }
        if (
          this.dataSettings[j].TypeName == "Others" &&
          this.dataSettings[j].Name == "Notes" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.otherNotes = false;
        }
        if (
          this.dataSettings[j].TypeName == "Others" &&
          this.dataSettings[j].Name == "Terms & Conditions" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.otherTermsConditions = false;
        }
        if (
          this.dataSettings[j].TypeName == "Header" &&
          this.dataSettings[j].Name == "Logo" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.logo = false;
        }
        if (
          this.dataSettings[j].TypeName == "Header" &&
          this.dataSettings[j].Name == "Company Name" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.headCompany = false;
        }
        if (
          this.dataSettings[j].TypeName == "Footer" &&
          this.dataSettings[j].Name == "Website" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.website = false;
        }
        if (
          this.dataSettings[j].TypeName == "Footer" &&
          this.dataSettings[j].Name == "Company Name" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.footCompany = false;
        }
      }
    });
  };
  formatItem(id: string) {
    let value = this.allSaleableItem?.find((e) => e.Id == id);
    if (!value) {
    } else {
      return value.ItemName;
    }
  }

  toDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.0;
  };
  print() {
    var originalTitle = document.title;
    document.title = "";
    window.print();
    document.title = originalTitle;
  }

  download() {
    document.getElementById("printBill").classList.remove("box--shadow");
    var data = document.getElementById("printBill");
    (html2canvas as any)(data, { useCORS: true }).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;
      var DocumentName = `Bill No # ${this.BillData.BillNo}.pdf`;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      pdf.save(DocumentName);
    });
    document.getElementById("printBill").classList.add("box--shadow");
  }

  getOrganizationData(id: string) {
    this.mainService.getSelectedOrganizationDetails(id).subscribe((x) => {
      this.company = x.Details;
      console.log(this.company);
      this.spinner.hide();
      this.logoUrl =
        this.company.BlobName != "" && this.company.BlobName != null
          ? this.fileUrl + this.company.BlobName
          : "";
      this.getBase64ImageFromUrl(this.logoUrl)
        .then((result) => {
          this.setValue(result);
        })
        .catch((err) => console.error(err));
    });
  }
  public sendMail() {
    var data = document.getElementById("bill");
    (html2canvas as any)(data, { useCORS: true }).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;

      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      var blob = pdf.output("blob");
      const modalRef = this.modalService.open(SendBillMailComponent, {
        size: "md",
        backdrop: "static",
        keyboard: false,
      });
      modalRef.componentInstance.fromParent = {
        Id: this.BillData.Id,
        RefNo: this.BillData.BillNo,
        PdfData: blob,
      };
      modalRef.result.then(
        (result) => {},
        (reason) => {
          console.log("Dismissed action: " + reason);
        }
      );
    });
  }
}
