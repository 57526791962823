<app-dashboard>
    <app-page-header-title 
        mainTitle="Replenishment" 
        subTitle="Home" 
        backURL="/">
        <div class="full-width text-align-right">
            <input type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox"/>
            <button type="button" class="eCount-def-btn ml-4" (click)="confirmOrder()">Confirm Order</button>
            <button type="button" class="eCount-button3 ml-4" (click)="onBtnExport()">Export</button>
        </div>
    </app-page-header-title>
    <div class="headSection">
        <div class="row">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col" 
                [rowData]="AllItems" 
                [columnDefs]="ColumnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef"
                [rowSelection]="rowSelection"
                [suppressRowClickSelection]="true" 
                [pagination]="true" 
                [paginationPageSize]="50" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</app-dashboard>