<app-dashboard>

  <div class="headSection marginNegative5">
    <div class="row borderBottom1Px pb-2">
        <div class="col-md-12 row" style="padding: 0px 15px;">
            <div class="d-block col-md-3">
                <div class="pointer" (click)="backToPreviousPage()">
                    <i class="icon-arrow-left52 mr-2 subTextTitle"></i>
                    <span class="subTextTitle f-ss-14px" *ngIf="this.steps == 0">Settings</span>
                    <span class="subTextTitle f-ss-14px" *ngIf="this.steps > 0">Back</span>
                </div>
                <span class="mainTextTitle f-ssb-14px" style="width: 220px;">Subscription</span>

                    
            </div>

        </div>

</div>
</div>
 
  <div class="area_for_tab" style="margin-left: 70px;">
    <div class="col-md-12 row">
      <div class="col-md-5">
        <button (click)="setFilterData(1)" class="bt">
          <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===1}">1</span>
          <span class="bb" [ngClass]="{'active-tab': activeStatusId===1}">Select plan</span>
        </button>
        <span class="middleLine col-md-8" style="margin-left: 150px;"></span>

      </div>
      <div class="col-md-5">
        <button (click)="setFilterData(2)" class="bt" [ngClass]="{'disabledClass':contentFirst===true}">
          <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===2}">2</span>
          <span class="bb" [ngClass]="{'active-tab': activeStatusId===2}">Select billing account</span>
        </button>
        <span class="middleLine2 col-md-7" style="margin-left:215px"></span>
      </div>
      <div class="col-md-2">
        <button (click)="setFilterData(3)" class="bt" [ngClass]="{'disabledClass':contentScnd===true}">
          <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===3}">3</span>
          <span class="bb" [ngClass]="{'active-tab': activeStatusId===3}">Review & pay</span>
        </button>

      </div>

    </div>

  </div>
  <div class="markedText"  *ngIf="activeStatusId===1 && showDefaultPackage == false" >
    <p class="txtDgn mt-4">
    According to organization type selection "Quarterly Package" is selected here by default. To make any changes
     in Organization type or pricing, select any other price package.
    </p>
  </div>
  <div class="row ml-4 mt-4" *ngIf="activeStatusId===1">

    <div class="row col-sm-10 marginLeft mt">
      <div class="col-sm-3 br" style="padding-top: 5px;">
        <p style="font-size: 16px; font-weight: bold;">Help<br><span style="color: #007bff;">info@iquidi.com<br>+880 17 1776 0924</span></p>
      </div>
      <div class="col-sm-3 br pr-1 mr-0 pl-1 ml-0" *ngFor="let entry of businessTypeList">
        <td class="mt-2 pl-0 pr-0 ml-0 mr-0">
          <label class="font-weight-bold mt-2 fnt pl-0 pr-0 ml-0 mr-0" style="display: flex;" for="entry.Id" *ngIf="entry.Id == 1">{{entry.Name}} <span style="padding-left: 5px; cursor: pointer;" (click)="gotoBusinessTypeDetailsModal(1)">
            <svg style="width: 18px; height: 18px; margin-top: -5px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#007bff"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
          </span></label>
          <label class="font-weight-bold mt-2 fnt pl-0 pr-0 ml-0 mr-0" style="display: flex;" for="entry.Id" *ngIf="entry.Id == 2">{{entry.Name}} <span style="padding-left: 5px; cursor: pointer;" (click)="gotoBusinessTypeDetailsModal(2)">
            <svg style="width: 18px; height: 18px; margin-top: -5px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#007bff"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
          </span></label>
          <label class="font-weight-bold mt-2 fnt pl-0 pr-0 ml-0 mr-0" style="display: flex;" for="entry.Id" *ngIf="entry.Id == 3">Enterprise/{{entry.Name}} <span style="padding-left: 5px; cursor: pointer;" (click)="gotoBusinessTypeDetailsModal(3)">
            <svg style="width: 18px; height: 18px; margin-top: -5px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#007bff"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
          </span></label>
          <div class="row">
          <p class="ml-3 fntSmall" *ngIf="entry.Id==1">Number of users: </p> <span *ngIf="entry.Id==1" class="ml-3">{{serviceUser}} </span>
          <p class="ml-3 fntSmall" *ngIf="entry.Id==2">Number of users: </p> <span *ngIf="entry.Id==2" class="ml-3">{{professionalUser}} </span>
          <p class="ml-3 fntSmall" *ngIf="entry.Id==3">Number of users: </p> <span *ngIf="entry.Id==3" class="ml-3"> {{enterpriseUser}} </span>
        </div>
        </td>

      </div>
    </div>
    <div class="col-sm-10 row marginLeft">

      <table class="col-sm-3">
        <tr class="borderTag">
          <span class="ml-4"> Price/Monthly </span>
        </tr>
        <tr class="borderTag ">
          <span class="ml-4">Price/Quarterly</span>
        </tr>
        <tr class="borderTag">
          <span class="ml-4">Price/Yearly</span>
        </tr>
      </table>
      <table class="col-sm-3">
        <tr class="borderTag">
          <label class="mt-2" for="basicPriceList[0]?.Id">{{basicPriceList[0]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="basicPriceList[0]?.Id"
            (click)="onItemChange(basicPriceList[0])" [(ngModel)]="filterType" [disabled]="isBasicPlanDisabled()">
        </tr>
        <tr class="borderTag">
          <label class="mt-2" for="basicPriceList[1]?.Id">{{basicPriceList[1]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="basicPriceList[1]?.Id"
            (click)="onItemChange(basicPriceList[1])" [(ngModel)]="filterType" [disabled]="isBasicPlanDisabled()">
        </tr>
        <tr class="borderTag">
          <label class="mt-2" for="basicPriceList[2]?.Id">{{basicPriceList[2]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="basicPriceList[2]?.Id"
            (click)="onItemChange(basicPriceList[2])" [(ngModel)]="filterType" [disabled]="isBasicPlanDisabled()">
        </tr>
      </table>
      <table class="col-sm-3">
        <tr class="borderTag">
          <label class="mt-2" for="professionalPriceList[0]?.Id">{{professionalPriceList[0]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="professionalPriceList[0]?.Id"
            (click)="onItemChange(professionalPriceList[0])" [(ngModel)]="filterType" [disabled]="isProfessionalPlanDisabled()">
        </tr>
        <tr class="borderTag">
          <label class="mt-2" for="professionalPriceList[1]?.Id">{{professionalPriceList[1]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="professionalPriceList[1]?.Id"
            (click)="onItemChange(professionalPriceList[1])" [(ngModel)]="filterType" [disabled]="isProfessionalPlanDisabled()">
        </tr>
        <tr class="borderTag">
          <label class="mt-2" for="professionalPriceList[2]?.Id">{{professionalPriceList[2]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="professionalPriceList[2]?.Id"
            (click)="onItemChange(professionalPriceList[2])" [(ngModel)]="filterType" [disabled]="isProfessionalPlanDisabled()">
        </tr>
      </table>
      <table class="col-sm-3">
        <tr class="borderTag rightborder">
          <label class="mt-2" for="enterPrisePriceList[0]?.Id">{{enterPrisePriceList[0]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="enterPrisePriceList[0]?.Id"
            (click)="onItemChange(enterPrisePriceList[0])" [(ngModel)]="filterType" [disabled]="isEnterPrisePlanDisabled()">
        </tr>
        <tr class="borderTag rightborder">
          <label class="mt-2" for="enterPrisePriceList[1]?.Id">{{enterPrisePriceList[1]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="enterPrisePriceList[1]?.Id"
            (click)="onItemChange(enterPrisePriceList[1])" [(ngModel)]="filterType" [disabled]="isEnterPrisePlanDisabled()">
        </tr>
        <tr class="borderTag rightborder">
          <label class="mt-2" for="enterPrisePriceList[2]?.Id">{{enterPrisePriceList[2]?.PackagePrice}} ৳</label>
          <input class="mr-3" type="radio" name="radiogroup" [value]="enterPrisePriceList[2]?.Id"
            (click)="onItemChange(enterPrisePriceList[2])" [(ngModel)]="filterType" [disabled]="isEnterPrisePlanDisabled()">
        </tr>
      </table>

    </div>
    <div class="row col-sm-10 marginLeft mt-4">
      <div class="col-sm-3 brs">
        <td class="mt-2">
          <label class="mt-2 font-weight-bold">Features</label>
        </td>
      </div>
      <div class="col-sm-3 brs">
        <td class="mt-2">
        </td>
      </div>
      <div class="col-sm-3 brs">
        <td class="mt-2">
        </td>
      </div>
      <div class="col-sm-3 brs">
        <td class="mt-2">
        </td>
      </div>
    </div>
    <div class="row col-sm-10 marginLeft" *ngFor="let entry of featurelist">
      <div class="col-sm-3 brs">
        <td class="mt-2">
          <label class="mt-2 ml-3" for="entry.Id">{{entry.Name}}</label>
        </td>
      </div>
      <div class="col-sm-3 brs">
        <td class="mt-2">
          <label class="mt-2"
            [ngClass]="{'tik-mark': entry.IsBasic == true,'cross-mark': entry.IsBasic == false}"></label>
        </td>
      </div>
      <div class="col-sm-3 brs">
        <td class="mt-2">
          <label class="mt-2"
            [ngClass]="{'tik-mark': entry.IsProfessional == true,'cross-mark': entry.IsProfessional == false}"></label>
        </td>
      </div>
      <div class="col-sm-3 brs">
        <td class="mt-2">
          <label class="mt-2"
            [ngClass]="{'tik-mark': entry.IsEnterPrise == true,'cross-mark': entry.IsEnterPrise == false}"></label>
        </td>
      </div>
    </div>
    <div class="mt-4 text-center full-width">
      <button type="button" class="eCount-def-btn pb" (click)="addNewUser()">Continue to add-users</button>
    </div>
    <div class="row col-sm-10 marginLefts mt" *ngIf="userInfo == true">
      <div class="plancard__popular2 col-sm-6">Add Additonal User</div>
      <div class="col-sm-3 Userbr">
        <label class="mt-2 text-blue addUser" for="">Additional User</label>
        <input class="mr-3" type="radio" value="" checked="checked">
      </div>
      <div class="col-sm-3 Userbr">
        <div class="row">
          <input type="number" id="inputBottom" min="0" [(ngModel)]="TotalUser" class="form-control ml-2"
            appDecimaNumericValidator  (change)="getTotalAmount(TotalUser)">
          <span class="mt-3 text-blue ml-2">Users</span>
        </div>
        <div class="row mt-4">
          <span class="mt-3 tk ml-2">৳</span>
          <input type="text" id="inputBottom2" [(ngModel)]="TotalAmount" class="form-control ml-2"
            appDecimaNumericValidator readonly>

        </div>
      </div>
    </div>
    <div class="mt-4 text-center full-width">
      <button type="button" class="eCount-def-btn pb" (click)="selectedValue(filterType)">Continue To Select Billing
        Account</button>
    </div>
  </div>
  <div *ngIf="activeStatusId===2">
    <div class="card cad mt-4">
      <div class="card-body text-center ml-2">
        You're buying a <span class="font-weight-bold">{{packageName}} package </span> for <span
          class="font-weight-bold">{{companyName}}</span>
        <span class="float-right text-blue cursor-pointer" (click)="backToPlan()">Change</span>
      </div>
    </div>
    <p class="ToHeading text-center mt-4">Create a new billing account</p>
    <div class="bor mb-4">

      <div class="col-sm-6 mt-3">
        <span class="mls">Person To Bill</span>
        <input type="text" [(ngModel)]="BillDetails.BillToName" class="form-control mal">
      </div>
      <div class="col-sm-6 mt-4">
        <span class="mlo">Email</span>
        <input type="text" [(ngModel)]="BillDetails.BillingEmail" class="form-control mal">
      </div>
      <div class="col-sm-6 mt-4">
        <span class="mlo">Phone</span>
        <input type="text" appDecimaNumericValidator [(ngModel)]="BillDetails.BillingContact" class="form-control mal">
      </div>
    </div>
    <div class="text-align-right mt-4">
      <button type="button" class="eCount-def-btn mb-2 mrr" (click)="showPaymentDetails(BillDetails)">Continue
        to review & pay</button>
    </div>
  </div>

  <div *ngIf="activeStatusId===3">
    <p class="ToHeading text-center">Payment Details</p>
    <div class="col-sm-12 row">
      <div class="col-sm-6">
        <div class="col-sm-12">
          <div class="card">
            <h6 class="clr">{{packageName}} Package</h6>
            <div class="card-body">
              <p class="card-text fss-14px">Your Plans start immediately.You will be Billed on
                {{convertDateIntoDisplay(Today)}}.Your
                Subscription will be valid on {{convertDateIntoDisplay(Today)}} to
                {{convertDateIntoDisplay(ExpiryDate)}}.</p>
              <hr />
              <div>

                <span class="fss-14px">{{companyName}}</span>
                <span class="fss-14px right">BDT {{packagePrice}}</span>
                
                <p class="mb"></p>
                <span class="fss-14px">Add Coupon Code:</span>
                <div [formGroup]="couponForm" class="fss-14px right makeInline">
                  <input formControlName="Name" />
                  <button class="" (click)="applyCoupon()">Add</button>
                </div>

                <p class="mb"></p>
                <span class="fss-14px">Organization {{packageName}} Total</span>
                <span class="fss-14px right">BDT {{packagePrice}}</span>
                
                <p    *ngIf="couponData" class="mb"></p>
                <span *ngIf="couponData" class="fss-14px">Coupon Code: {{couponData.Name}}</span>
                <span *ngIf="couponData" class="fss-14px right">{{couponData.DiscountAmount}}% Discount</span>

                <p    *ngIf="couponData" class="mb"></p>
                <span *ngIf="couponData" class="fss-14px">Total</span>
                <span *ngIf="couponData" class="fss-14px right">BDT {{ (packagePrice*(100 - couponData.DiscountAmount))/100 }}</span>

              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="card mt">
            <h6 class="clr">Bill To</h6>
            <div class="card-body">

              <p class="ToHeading">{{BillDetails.BillToName}}</p>
              <p class="fss-14px">{{BillDetails.BillingEmail}}</p>
              <p class="fss-14px">{{BillDetails.BillingContact}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="3"
                (change)="onShowTransferMoneyDetails($event.target.value)" [checked]="true">
                <label class="mt-2" for="2">Transfer Money</label>
            </div>
            <div class="row">
              <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="1">
              <label class="mt-2" for="1">Bkash</label>
            </div>
            <div class="row">
              
              <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="2">
              <label class="mt-2" for="2">Card</label>
            </div>
            <div [hidden]="!ShowTransferDetails" class="mla">

              <p class="card-text fss-14px mt-2">(Make your payment directly into our bank account. Please use given
                account number for transfer money.)</p>
              <div class="row">
                <span class="col-sm-6">Account Holder Name</span>
                <span class="col-sm-6 txtColor">{{BankInfo.AccountHolderName}}</span>
              </div>
              <div class="row">
                <span class="col-sm-6">Transfer Account</span>
                <span class="col-sm-6 txtColor">{{BankInfo.AccountNo}}</span>
              </div>
              <div class="row">
                <span class="col-sm-6">Bank Name</span>
                <span class="col-sm-6 txtColor">{{BankInfo.BankName}}</span>
              </div>
              <div class="row">
                <span class="col-sm-6">Branch Name</span>
                <span class="col-sm-6 txtColor">{{BankInfo.BranchName}}</span>
              </div>
              <div class="row">
                <span class="col-sm-6">Swift Code</span>
                <span class="col-sm-6 txtColor">{{BankInfo.SwiftCode}}</span>
              </div>
            </div>
            <div class="mt-4">
              <span class="fss-14px red mla">After depositing the money, kindly attach the receipt document here</span>
              <form [formGroup]="myForm">
                <div class="form-group">
                  <div class="das mla">
                    <label for="file" class="file"></label>
                    <input formControlName="file" id="file" type="file" class="form-control"
                      (change)="onFileChange($event)" class="hidden" style="outline: none;margin-left: 50px;">
                  </div>
                </div>
              </form>
            </div>
            <div class="full-width text-center">
              <button type="button" class="btns ml-4 mt-4 col-md-6" (click)="confirmPayment()">Confirm Purchase</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-dashboard>