<app-dashboard>
    <app-page-header-title mainTitle="{{ mainTitle }}" subTitle="Invoice Overview"
        backURL="/income/invoice-overview/All">
        <div class="full-width text-align-right">
            <div class="dropdown">
                <!-- <button id="attachment_btn" style="z-index: 1;" type="button" class="btn attachment-button"><i
                        id="attachment_btn" style="font-size: 20px !important;"
                        class="material-icons">insert_drive_file</i><span id="attachment_btn"
                        style="color:darkgrey; font-size: 12px;" *ngIf="totalFile>1">{{totalFile}}</span></button> -->
                <div class="x-tip-tl" style="height: 0px;">
                    <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
                </div>
                <div id="attachmentDropdown" class="dropdown-content"
                    style="position: absolute;background-color: none;overflow: visible;">
                    <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="1"></app-attachment-uploader>
                </div>
            </div>
            <button type="button" (click)="saveAsDraftCreateInvoice()" class="eCount-def-btn ml-4">Save</button>
            <button type="button" (click)="saveAsSubmitInvoice()" class="eCount-def-btn ml-4">Submit</button>
            <button *ngIf="canApprove" type="button" style="background: #5cac00;" (click)="saveCreateInvoice()" class="eCount-def-btn ml-4">Approve</button>
            <!-- <button *ngIf="canApprove" type="button" [hidden]="invoiceForm.value.IsActiveDeliveryPartner == true" style="background: #5cac00;" (click)="saveCreateInvoice()" class="eCount-def-btn ml-4">Approve</button> -->

        </div>

    </app-page-header-title>
    <div class="headSection">
        <form [formGroup]="invoiceForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-2 col-sm-12">
                    <label>{{'Invoice.to' |translate}}<span class="clr">*</span> </label>
                    <ng-select [items]="allCustomer" bindLabel="CompanyName" bindValue="Id" [addTag]="CreateNewCustomer"
                        [loading]="loading" formControlName="CustomerId" required
                        [ngClass]="{'submitted':ifSubmit===true}">
                        <ng-template ng-tag-tmp let-search="searchTerm">
                            <b>+ Add New Customer</b>: {{search}}
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>{{'Invoice.date' |translate}}</label>
                    <input class="form-control" type="date" formControlName="InvoiceDate" id="itest1" data-date=""
                        data-date-format="D MMM YYYY" [ngClass]="{'submitted':ifSubmit===true}"
                        (change)="showSelectedDate($event)" required>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>{{'Invoice.dueDate' |translate}}<span class="clr">*</span> </label>
                    <input type="date" class="form-control" [ngClass]="{'submitted':ifSubmit===true}" data-date=""
                        id="itest" data-date-format="D MMM YYYY" formControlName="DueDate" value="2015-08-09"
                        (change)="show($event)" required>
                </div>

                <div class="col-md-2 col-sm-12">
                    <label>{{'Invoice.reference' |translate}}</label>
                    <input class="form-controlinputBox" type="text" formControlName="ReferenceNo">
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>{{'Invoice.invoiceNo' |translate}}</label>
                    <span class="form-controlinputBox d-flex">
                        <label style="background-color: #dddddd; padding-bottom:10%; font-size: 14px; margin-top: 3px;">INV-{{ShowInvoiceNo}}</label>
                        <input class="form-controlinputBox2" formControlName="InvoicceNo" type="text" style="font-size: 14px;">
                    </span>
                </div>
                <div class="col-md-2 col-sm-12">
                    <label>{{'Invoice.amountsAre' |translate}}</label>
                    <ng-select [items]="taxTypeList" (change)="updateTotalPrice()" [clearable]="false" bindLabel="Name"
                        bindValue="Id" formControlName="TaxTypeId"></ng-select>
                </div>
                <div class="col-md-4 col-sm-12 ml-0 mt-2">
                    <label>Active Third Party Delivery</label>
                    <label class="switch">
                        <input class="switch-input" type="checkbox" formControlName="IsActiveDeliveryPartner"
                            (change)="toogleDeliveryPartner(0)">
                        <span class="switch-label" data-on="Yes" data-off="No"></span>
                        <span class="switch-handle"></span>
                    </label>
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px">
                <div class="d-flex">
                    <div class="col-md-1">
                        {{'Item.item' |translate}}
                    </div>
                    <div class="col-md-2">
                        {{'Item.description' |translate}}<span class="clr">*</span>
                    </div>
                    <div class="col-md-2">
                        {{'Item.account' |translate}}
                    </div>
                    <div class="col-md-1">
                        {{'Item.quantity' |translate}}
                    </div>
                    <div class="col-md-1">

                    </div>
                    <div class="col-md-1">
                        {{'Item.price' |translate}}
                    </div>
                    <div class="col-md-1">
                        {{'Item.discount' |translate}}%
                    </div>
                    <div class="col-md-1">
                        VAT%
                    </div>
                    <div class="col-md-1">
                        AIT%
                    </div>
                    <div class="col-md-2 ml-0 pl-0">
                        {{'Item.amount' |translate}}
                    </div>
                </div>
                <hr class="pb-0 mb-0" />
                <div>
                    <div formArrayName="InvoiceItems"
                        *ngFor="let invoice of invoiceForm.get('InvoiceItems')['controls']; let i = index;">

                        <div class="invoiceGridItemRow d-block"
                            [ngClass]="[invoice.selected ? 'selectedRow' : 'notSelectedRow']">
                            <div class="d-flex " [formGroupName]="i"
                                [ngClass]="[invoice.selected ? 'selectedRow' : 'notSelectedRow']">
                                <div class="col-md-1 d-flex pl-0">
                                    <div class="dragIcon">
                                        <i class="icon-grid2"></i>
                                    </div>
                                    <div style="width: 150px;">
                                        <ng-select [items]="allSaleableItem" placeholder="Select Items" #selectItem
                                            class="customSaleItem" [clearable]="false" bindLabel="ItemName"
                                            bindValue="Id" (change)="changesellableItems(i)" formControlName="ItemId">

                                            <ng-template ng-header-tmp>
                                                <a (click)="openItemModal(selectItem)" role="button"
                                                    aria-haspopup="false" aria-expanded="false"><span
                                                        style="color: #007bff;">+ Add
                                                        Item</span></a>

                                            </ng-template>
                                        </ng-select>

                                        <!-- <input class="form-control" type="text" [(ngModel)]="invoiceItem[i].Description"
                                        (click)="changeSelectRowInItem(invoice.ItemId, i)"> -->
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <textarea class="form-control"
                                        style="resize: none; min-height: 20px; max-height: 25px;" type="text"
                                        formControlName="Description" [ngClass]="{'submitted':ifSubmit===true}"
                                        required> </textarea>
                                </div>
                                <div class="col-md-2">
                                    <div style="width: 100%;">
                                        <ng-select [items]="allChartOfAccount" placeholder="Select Account"
                                            class="customSaleItem" [clearable]="false" bindLabel="AccountName"
                                            bindValue="Id" formControlName="AccountId" groupBy="GroupName">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <input class="form-control" type="text" appDecimaNumericValidator
                                        formControlName="Quantity" (focus)="onFocusEvent($event)"
                                        (change)="updateDataInvoiceItem(invoice.value.ItemId,invoice.value,i)">
                                </div>
                                <div class="col-md-1">
                                    <span  *ngIf="invoice.value.SuccessLogo == true && invoice.value.Quantity > 0 && invoice.value.Quantity != ''" id="successBtn" class="deletebtn">
                                      <img style="height: 33px" src="../../assets/icons/successImg.jpg" />
                                    </span>
                                    <span *ngIf="invoice.value.DangerLogo == true && invoice.value.Quantity > 0 && invoice.value.Quantity != ''" id="dangerBtn" class="deletebtn">
                                      <img style="height: 33px" src="../../assets/icons/danger2.jpg" />
                                    </span>
                                </div>
                                <div class="col-md-1">
                                    <input class="form-control" type="text" appDecimaNumericValidator
                                        formControlName="UnitPrice" (focus)="onFocusEvent($event)"
                                        (change)="updateDataInvoiceItem(invoice.value.ItemId,invoice.value, i)">
                                </div>
                                <div class="col-md-1">
                                    <input class="form-control" type="text" appDecimaNumericValidator
                                        formControlName="DiscountPercent" (focus)="onFocusEvent($event)"
                                        (change)="updateDiscountInvoiceItem(invoice.value.ItemId, i)">
                                </div>
                                <div class="col-md-1 mt-1">
                                    <div style="width: 100%;" [ngClass]="{'disable': invoiceForm.value.TaxTypeId===3}">
                                        <ng-select [items]="taxRateList" class="customSaleItem" [clearable]="false"
                                            bindLabel="Parcent" bindValue="Id" (change)="updateTotalPrice()"
                                            formControlName="TaxRateId">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-1 mt-1">
                                    <div style="width: 100%;" [ngClass]="{'disable': invoiceForm.value.TaxTypeId===3}">
                                        <ng-select [items]="taxRateList" class="customSaleItem" [clearable]="false"
                                            bindLabel="Parcent" bindValue="Id" (change)="updateTotalPrice()"
                                            formControlName="AdvanceTaxRateId">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-md-1 ml-0 pl-0 d-flex">
                                    <div class="ml-0 pl-0">
                                        <input class="form-control ml-0 pl-0" type="number" appDecimaNumericValidator
                                            style="background-color:white; color:black" disabled
                                            formControlName="LineTotal"
                                            (change)="updateDataInvoiceItemLineTotal(invoice.ItemId)">
                                    </div>
                                    <div style="padding: 7px 0px 7px 0px; color: #c7c2c2;">
                                        <div class="dropdown">
                                            <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                                aria-expanded="false">
                                                <!-- <i class="icon-more float-right"></i> -->
                                                <img src="../../../../assets/image/icons/more.png"
                                                    class="float-right moreIcon" />
                                            </div>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div class="productRow pb-3"
                                                    (click)="removeInvoiceItem(invoice.ItemId, i)">
                                                    <span class="lead1">Remove Item </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row addItem">
                    <div class="BottomAddItem w-100">
                        <div class="dropdown mr-4">
                            <a class="nav-link" href="#" id="navbarDropdown" style="background-color: white;"
                                role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false"
                                (click)="addItem()">
                                <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line
                            </a>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <p class="font-weight-bold" [hidden]="this.invoiceForm.value.IsActiveDeliveryPartner == false">
                        Delivery Partner</p>
                    <div formArrayName="DeliveryPartnerItems"
                        *ngFor="let item of invoiceForm.get('DeliveryPartnerItems')['controls']; let i = index;">

                        <div class="invoiceGridItemRow d-block">
                            <div class="d-flex selectedRows" [formGroupName]="i">
                                <div class="col-md-2 borderLeft d-flex rowSpaceing pl-0">
                                    <div class="dragIcon">
                                        <i class="icon-grid2"></i>
                                    </div>
                                    <div style="width: 100%;margin-left: 5px; ">
                                        <ng-select [items]="allDeliveryPartner" placeholder="Select Partner" #selectItem
                                            class="customSaleItem mt-2" [clearable]="false" bindLabel="CompanyName"
                                            bindValue="Id" formControlName="DeliveryPartnerId"
                                            [ngClass]="{'submitted':ifSubmit===true}" required
                                            [addTag]="CreateNewDeliveryPartner"
                                            [loading]="loading" >
                                            <ng-template ng-tag-tmp let-search="searchTerm">
                                                <b>+ Add New Delivery Partner</b>: {{search}}
                                            </ng-template>
                                        </ng-select>
                                        <!-- <input class="form-control" type="text" [(ngModel)]="invoiceItem[i].Description"
                                        (click)="changeSelectRowInItem(invoice.ItemId, i)"> -->
                                    </div>
                                </div>
                                <div class="col-md-3 borderLeft rowSpaceing">
                                    <textarea class="form-control descriptionTextArea" type="text"
                                        formControlName="Description" [ngClass]="{'submitted':ifSubmit===true}"
                                        readonly> </textarea>
                                </div>

                                <div class="col-md-4 borderLeft rowSpaceing">
                                    <input class="form-control" type="number" appDecimaNumericValidator
                                        formControlName="UnitPrice" placeholder="price"
                                        (change)="updateTotalAfterDeliveryCharge()" (focus)="onFocusEvent($event)"
                                        [ngClass]="{'submitted':ifSubmit===true}">
                                </div>


                                <div class="col-md-3 rowSpaceing d-flex">
                                    <div>
                                        <input class="form-control" type="number" appDecimaNumericValidator
                                            formControlName="LineTotal">
                                    </div>
                                    <div style="padding: 7px 0px 7px 10px; color: #c7c2c2;">
                                        <div class="dropdown ml-4">
                                            <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                                aria-expanded="false">
                                                <!-- <i class="icon-more float-right"></i> -->
                                                <img src="../../../../assets/image/icons/more.png"
                                                    class="float-right moreIcon" />
                                            </div>
                                            <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                                style="width: 100%;">
                                                <div class="productRow pb-3" (click)="removeDeliveryItem(i)">
                                                    <span class="lead1">Remove Item </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-bottom d-flex">
                    <div class="col-md-8 col-sm-12 row">
                        <div class="form-group col-sm-5">
                            <label>Notes </label>
                            <div class="textarea-wrapper">
                                <textarea #myInput rows="5" maxlength="250" type="text" class="form-control brs custom-textarea" id="this-textarea"
                                    placeholder="Write down some important notes using only 250 characters" formControlName="Notes"></textarea>
                                <span class="remaning" >{{myInput.value.length}}/250</span>
                            </div>
                        </div>
                        <div class="col-sm-7 row">
                            <div class="col-sm-3">
                                <label style="font-weight: 600;">Repeating</label>
                                <label class="switch">
                                    <input class="switch-input" type="checkbox" formControlName="IsRepeating"
                                        (change)="changeType()">
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>
                            </div>
                            <div class="col-sm-7 ml-4" *ngIf="invoiceForm.value.IsRepeating === true">
                                <label for="RepeatingTypeId" style="font-weight: 600;">Repeating Types<span
                                        class="required-sign"></span></label>
                                <ng-select [items]="repeatingTypeList" bindLabel="Name" bindValue="Id"
                                    [ngClass]="{'redborder':ifSubmit===true && invoiceForm.value.IsRepeating === true && invoiceForm.value.RepeatingTypeId == null }"
                                    formControlName="RepeatingTypeId" [clearable]="false">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label>{{'Invoice.subTotal' |translate}}</label>
                            </div>
                            <div class="col-md-4">{{subTotalAmount | currency:'':''}}</div>
                        </div>
                        <div class="d-flex w-100 pb-1" *ngIf="invoiceForm.value.TaxTypeId !=3">
                            <div class="col-md-8">
                                <label>VAT</label>
                            </div>
                            <div class="col-md-4">{{invoiceForm.value.TaxAmount}}</div>
                        </div>
                        <div class="d-flex w-100 pb-1" *ngIf="invoiceForm.value.TaxTypeId !=3">
                            <div class="col-md-8">
                                <label>AIT</label>
                            </div>
                            <div class="col-md-4">{{invoiceForm.value.AdvanceTaxAmount}}</div>
                        </div>
                        <div class="d-flex w-100 equalLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-3">
                            <div class="col-md-8">
                                <label>{{'Invoice.total' |translate}}</label>
                            </div>
                            <div class="col-md-4 font-weight-bold">{{invoiceForm.value.InvoiceAmount | currency:'':''}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

</app-dashboard>