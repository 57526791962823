import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators , FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { BusinessOwnerService } from '../../../service/Business Owner/business-owner.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import moment = require('moment');

@Component({
  selector: 'app-owner-loan-create',
  templateUrl: './owner-loan-create.component.html',
  styleUrls: ['./owner-loan-create.component.css']
})
export class OwnerLoanCreateComponent implements OnInit {

  constructor(
    public fb: FormBuilder,
    private router: Router,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    private ownerService: BusinessOwnerService,
    private spinner: NgxSpinnerService) { }

    public loanForm: FormGroup;
    bankList: any;
    subTotalAmount = 0;
    ifSubmit = false;
    @Input() fromParent;
  ngOnInit(): void {
    this.initializeForm();
    this.getBankList();
  }

  initializeForm() {
    this.loanForm = this.fb.group({
      OwnerId: [''],
      Description: ['',  Validators.required],
      AccountId: ['', Validators.required],
      Date: [''],
      Amount: [0, Validators.required],
      TotalAmount: [''],
      LoanPeriod: [0, Validators.required],
      InterestAmount: [0],
      LoanTypeId: [1, Validators.required],
      Rate: [0],
      FixedRate: [0],
      IsFixed: [false]
    });
    this.loanForm.get('OwnerId').patchValue(this.fromParent);
    this.loanForm.get('Date').patchValue(new Date());
    this.showSelectedDate(new Date());
  }
    showSelectedDate(e) {
      const value = this.loanForm.value.Date;
      $('#oltest1').on('change', function() {
        this.setAttribute(
            'data-date',
            moment(value, 'YYYY-MM-DD')
            .format( this.getAttribute('data-date-format') )
        );
    }).trigger('change');
    }


  onFocusEvent(event: any)
  {
      event.target.value = event.target.value == 0 ? null : event.target.value;
  }
  changeFixedRateOrInterest = () =>
  {
    if (this.loanForm.value.IsFixed === true)
    {
      this.loanForm.get('Rate').patchValue('');
    }
    else
    {
      this.loanForm.get('FixedRate').patchValue('');
    }
    this.updateTotalPrice();
  }

  updateTotalPrice = () =>
  {
    if (this.loanForm.value.IsFixed === true)
    {
      const amount = this.loanForm.value.TotalAmount;
      console.log(amount);
      const value = parseFloat((amount === '' || isNaN(amount) || amount == null ) ? 0 : this.loanForm.value.TotalAmount);
      const fixedRate = this.loanForm.value.FixedRate ?? 0;
      const totalValue = fixedRate + value;
      this.loanForm.get('InterestAmount').patchValue(fixedRate);
      this.loanForm.get('Amount').patchValue(totalValue);
    }
    else
    {
      const amount = this.loanForm.value.TotalAmount;
      console.log(amount);
      const value = parseFloat((amount === '' || isNaN(amount) || amount == null) ? 0 : this.loanForm.value.TotalAmount );
      let percantCalculate =  (value * (this.loanForm.value.Rate ?? 0)) / 100;
      percantCalculate = isNaN(percantCalculate) ? 0 : percantCalculate;
      this.loanForm.get('InterestAmount').patchValue(percantCalculate);
      this.loanForm.get('Amount').patchValue(value + percantCalculate );
    }
  }
  getBankList()
  {
      this.bankList = [];
      this.mainService.GetAllBank().subscribe((x) => {
        this.bankList = x;
    });
  }
 save = () => {
  if (this.loanForm.invalid) {
    this.toaster.warning('Please fill all the required field !');
    return;
  }
  if (this.loanForm.value.InterestAmount === '' || this.loanForm.value.InterestAmount === undefined ||
   this.loanForm.value.InterestAmount == null) {
    this.toaster.warning('Interest Amount can not be empty !');
    return;
  }
  this.spinner.show();
  this.ownerService.SaveLoan(this.loanForm.value).subscribe((x) => {
    if (x.Success)
    {
      this.spinner.hide();
      this.toaster.success('Loan added Successfully');
      this.activeModal.close(x);
    }
    else
    {
      this.spinner.hide();
    }
  } , err => {
    this.spinner.hide();
    this.toaster.error('ooh, something went wrong !');
  });
 }
}
