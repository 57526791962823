import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WareHouseShippedData } from 'src/app/models/inventoryOverview.model';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';

@Component({
  selector: 'app-modal-shipped-product',
  templateUrl: './modal-shipped-product.component.html',
  styleUrls: ['./modal-shipped-product.component.css']
})
export class ModalShippedProductComponent implements OnInit {

  @Input() fromParent: any;
  AllDaTa:any=[]
  receiveProduct:any;
  modalForShiftForm:FormGroup
  wareHouseShippedData :WareHouseShippedData
  sumOfShipped:any=0
  sumOfDemand:any=0;
  constructor(
    public activeModal: NgbActiveModal,
    private deliveryOrderService:InventoryOverviewService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }
  ngOnInit(): void {
    this.initializeForm();
    this.pageLoad();

  }
  public pageLoad()
  {
    this.AllDaTa=this.fromParent.allDATA;
    this.receiveProduct=this.fromParent.receiveProduct;

    console.log("From Modal this.product",this.AllDaTa)
    this.sumOfProduct();
  }
  public initializeForm()
  {
    this.modalForShiftForm=new FormGroup({
      BtnOne: new FormControl('1'),


    })

  }

  sumOfProduct()
  {

    this.AllDaTa.WareHouseItems.forEach(item=>
      {
        this.sumOfDemand+=Number((item.Demand));
        this.sumOfShipped+=Number(item.ProductInOut);

      })

      console.log("ShippedSum",this.sumOfShipped)
      console.log("sumOfStockAvailabe",this.sumOfShipped)
  }

  public Shipped()
  {
    this.spinner.show();
    //this.AllDaTa.WareHouseItems[0].ProductInOut=this.receiveProduct;
    this.AllDaTa.WareHouseShippedType=this.modalForShiftForm.controls['BtnOne'].value;
    console.log("Helo ReceiveFun=>",this.AllDaTa)
    var data = {
      WareHouseData : this.AllDaTa.WareHouseData,
      WareHouseItemsData:this.AllDaTa.WareHouseItems,
      WareHouseShippedType:this.AllDaTa.WareHouseShippedType

    }
    console.log("Before Service==>",data)
    this.deliveryOrderService.shipItems(data).subscribe((x)=>
    {
      console.log("Hello Response",x)
      if (x.Success) {
        this.activeModal.close();
        this.toaster.success('Product Received successfully!');
        this.spinner.hide();
      }
      else{
        this.toaster.error(x.Message);
        //this.toaster.warning('Something Went Wrong!');
        //location.reload();
      }

    });

  }

  }

