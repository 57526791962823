import { Component, HostListener, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import { ReportServiceService} from '../../../service/Report/report-service.service'
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { registerLocaleData } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-cashflow-movement-details',
  templateUrl: './cashflow-movement-details.component.html',
  styleUrls: ['./cashflow-movement-details.component.css']
})
export class CashflowMovementDetailsComponent implements OnInit {

  companyName: string= "";
  fromDate: string = '';
  toDate: string = '';
  movementId:number=0;
  dataList=[];
  totalDebit:number=0;
  totalCredit:number=0;
  totalGrossAmount:number=0;
  totalNetAmount:number=0;

  constructor(private route: ActivatedRoute,
    private reportService:ReportServiceService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.setOrganization();
    this.route.queryParams.subscribe(params => {
      this.fromDate = this.route.snapshot.paramMap.get("fromDate");
      this.toDate = this.route.snapshot.paramMap.get("toDate");
      this.movementId =Number(this.route.snapshot.paramMap.get("movementId"));
      this.loadReportData();
    });
  }

  setOrganization(){
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo) {
        this.companyName = userInfo.SelectedCompany.Name;
      }
    }

    loadReportData=()=>{
      let data = {
        fromDate: this.fromDate,
        toDate: this.toDate,
        movementType:this.movementId
      }

       this.spinner.show();
      this.reportService.GetCashFlowMovements(data).subscribe((x)=>{
        this.dataList = x.Data;
        this.totalDebit=x.TotalDebit;
        this.totalCredit=x.TotalCredit;
        this.totalGrossAmount=x.TotalGrossAmount;
        this.totalNetAmount=x.TotalNetAmount;
        this.spinner.hide()
      },(err)=>{
        this.spinner.hide();
      })
    }
    

    convetToPDF = () => {
      var data = document.getElementById('printGeneralReport');
      (html2canvas as any)(data).then(canvas => {
        var imgWidth = 208;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('p', 'mm', 'a4');
        var position = 0;
        let fromDate=this.convertDateIntoDisplay(this.fromDate);
        let toDate=this.convertDateIntoDisplay(this.toDate);
        var DocumentName = `Account Movement (${fromDate} to ${toDate}).pdf)`;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        pdf.save(DocumentName);
      })
    }

    convertDateIntoDisplay=(date)=>{
      let dateString = moment(date).format("Do MMM YYYY")
      return dateString;
    }
}
