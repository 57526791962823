import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service'
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FactoryInventoryPopupComponent } from '../factory-inventory-popup/factory-inventory-popup.component';

@Component({
  selector: 'app-factory-stock-report',
  templateUrl: './factory-stock-report.component.html',
  styleUrls: ['./factory-stock-report.component.css']
})
export class FactoryStockReportComponent implements OnInit {

  constructor(private modalService:NgbModal,
    private factoryService: FactoryProductionService,
    private router: Router, 
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService) { }

private gridApi;
domLayout="autoHeight"
private gridColumnApi;
itemList: any = [];
searchText:string;
hasStep: boolean=false;

ngOnInit(): void {
}
columnDefs = [
  { headerName: '', field: '',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    flex: 1,
    checkboxSelection: true},
{ headerName: 'Item Code', field: 'ItemCode' , flex: 3},
{ headerName: 'Item Name', field: 'ItemName' , flex: 3 },
{ headerName: 'Factory Quantity', field: 'InQuantity', flex: 3 }
];

defaultColDef = {
sortable: true,
filter: true
};
rowSelection='multiple';

getData=()=>{
this.spinner.show()
this.itemList = []
this.factoryService.GetFactoryStockReport().subscribe((x)=>{
this.spinner.hide()
this.itemList = [...x, ...this.itemList]
},(err)=>{
this.spinner.hide();
this.toaster.error('Something went wrong please try again !');
});
}

onGridReady(event) {
this.gridApi = event.api;
this.gridColumnApi = event.columnApi;
this.getData();
}

search=()=>{
this.gridApi.setQuickFilter(this.searchText);
}
public OpenPopup=()=>{

    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    if(selectedData.length==0){
      this.toaster.warning("select at least one Item");
      return;
    }
  const modalRef = this.modalService.open(FactoryInventoryPopupComponent,{ size: 'lg',backdrop: 'static', keyboard : false});
  modalRef.componentInstance.fromParent = selectedData;
  modalRef.result.then((result) => {

               }, (reason) => {
                 console.log('Dismissed action: ' + reason);
               });
};
}
