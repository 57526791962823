   <app-dashboard>
    <app-page-header-title mainTitle="Factory In View" subTitle="Back" backURL="/inventory/factory-in-list/All">
        <div class="full-width text-align-right">
          <div class="dropdown">
            <div class="x-tip-tl" style="height: 0px">
              <i id="custom_notch" class="material-icons custom_notch">arrow_drop_up</i>
            </div>
          </div>
          <button type="button"  [routerLink]="['/inventory/edit-factory-in',TransNo]"  class="eCount-def-btn ml-4" *ngIf="this.factoryInForm.value.StatusId == 1">Edit</button>
          <button type="button" (click)="ApproveFactoryIn()"  class="eCount-def-btn ml-4" style="background: #5cac00;" *ngIf="this.factoryInForm.value.StatusId == 1">Approve</button>
        </div>
      </app-page-header-title>
    <div class="headSection">
      <form [formGroup]="factoryInForm" class="f-ss-14px">
        <div class="row f-ss-14px">
          <div class="col-md-3 col-sm-12">
            <label for="InDate">Date</label>
            <input
              class="form-control"
              formControlName="InDate"
              readonly
            />
          </div>
  
          <div class="col-md-2 col-sm-12">
            <label for="ReferrenceNo">Reference</label>
            <input
              class="form-control"
              formControlName="ReferrenceNo"
              readonly
            />
          </div>
          <div class="col-md-4 col-sm-12">
            <label for="InDate">Description</label>
            <input
              class="form-control"
              formControlName="Description"
              readonly
            />
          </div>
        </div>
  
        <div class="row d-block topSection f-ss-14px">
          <div class="d-flex">
            <div class="col-md-2">Item</div>
            <div class="col-md-2">Product Name</div>
            <div class="col-md-2">Sales Order</div>
            <div class="col-md-2">Item Quantity</div>
            <div class="col-md-2">Price/Unit</div>
            <div class="col-md-2">Amount</div>
          </div>
          <hr class="pb-0 mb-0" />
          <div formArrayName="ProductionInItem" *ngFor="let item of factoryInForm.get('ProductionInItem')['controls']; let i = index;">
             
             <div class="invoiceGridItemRow d-block">
              <div class="row" [formGroupName]="i">
  
                  <div class="col-md-2 margin-top-5">
                    <input  class="form-control" readonly formControlName="ItemName">
                  </div>
                  <div class="col-md-2 margin-top-5">
                    <input  class="form-control" readonly formControlName="ProductName">
                  </div>
                  <div class="col-md-2 margin-top-5">
                      <select class="form-control customSaleItem" style="width: 100%;color: black;" formControlName="SalesOrderId">
                          <option *ngFor="let s of salesOrders" [ngValue]="s.Id">{{s.Name}}</option>
                      </select>
                  </div>
                  <div class="col-md-2 margin-top-5">
                      <input  class="form-control" readonly formControlName="InQuantity">
                  </div>
                  <div class="col-md-2 margin-top-5">
                      <input  class="form-control" readonly formControlName="PricePerUnit">
                  </div>
                  <div class="col-md-2 margin-top-5 d-flex">
                      <div>
                          <input  class="form-control" readonly formControlName="LineTotal">
                      </div>
                  </div>
              </div>
                </div>
          </div>
          <hr class="pb-0 mb-0" />
        <div class="d-flex">
            <div style="font-weight: bold" class="col-md-6">Total</div>
            <div style="font-weight: bold" class="col-md-4 ml-3">{{totalQuantity}}</div>
            <div style="font-weight: bold" class="col-md-2 ml-2">{{totalCost}}</div>
        </div>
        </div>
      </form>
    </div>
  </app-dashboard>