<app-dashboard>
    <app-page-header-title mainTitle="Business Partner Report"
        subTitle="{{'Contacts.contactList.allContacts'| translate}}" backURL="contacts/list/all">
        <div class="full-width text-align-right">
        </div>
    </app-page-header-title>
    <div class="row">
        <div class="col-md-12 d-flex f-ss-14px" id="my1">
            <div class="col-md-4 d-block pl-1">

                <div class="HeaderText">Company Name : <span class="font-weight-normal">{{ contactData?.CompanyName
                        }}</span></div>
                <div class="HeaderText mt-4">Email : <span class="font-weight-normal">{{contactData?.Email}}</span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="HeaderText">Name : <span class="font-weight-normal">{{ contactData?.PrimaryPersonFullName }}
                    </span></div>

                <div class="HeaderText mt-4"> Contact No : <span class="font-weight-normal">{{ contactData?.ContactNo }}
                    </span></div>
                <!--               
                <div class="HeaderText">Category : <span class="font-weight-normal">{{  contactData?.IsCustomer &&  contactData?.IsSupplier ? "Customer/Supplier" : 
                    contactData?.IsCustomer ? "Customer" : "Supplier"  }}</span></div> -->
            </div>
            <div class="col-md-4">
                <div class="HeaderText">Website : <span class="font-weight-normal">{{ contactData?.Website }}</span>
                </div>
                <div class="HeaderText mt-4">Address : <span class="font-weight-normal"> {{ contactData?.StreetAddress
                        }} </span></div>
            </div>
        </div>
    </div>
    <div class="col-md-8 row mt">

        <div class="form-group p-all-0px col-md-3">
            <input class="form-control" type="date" placeholder="From Date" [(ngModel)]="fromDate"
                ddata-date-format='dd-MM-yyyy'>
        </div>

        <div class="form-group p-all-0px col-md-3 ml-2">
            <input class="form-control" type="date" [(ngModel)]="toDate">
        </div>
        <button type="button" class="eCount-def-btn ml-4" (click)="getBSReportByContactId()">Generate Report</button>
    </div>
    <div id="bsReport">
        <div class="report-header row" style="margin-bottom: 10px;">
            <div class="col-md-8">
                <div>
                    <p class="txtsize">Business Partner Reports</p>
                </div>
                <div class="row ml-0">
                    <span>{{contactData?.CompanyName}}</span>

                    <p><span class="ml-2 mr-2 f-ss-14px">|</span>For the period {{convertDateIntoDisplay(fromDate)}} to
                        {{convertDateIntoDisplay(toDate)}}</p>

                </div>

            </div>
            <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
                <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
            </div>
        </div>

        <div class="sticky">

            <div id="tableHeader" class="tableHeader d-flex">
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Date</p>
                </div>
                <div class="tableHeaderColumn col-md-3">
                    <p class="tableHeaderText pt-1">Type</p>
                </div>
                <div class="tableHeaderColumn col-md-1">
                    <p class="tableHeaderText pt-1">Description</p>
                </div>
                <div class="tableHeaderColumn col-md-2 ">
                    <p class="tableHeaderText pt-1">Reference</p>
                </div>
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Amount</p>
                </div>
                <div class="tableHeaderColumn col-md-2">
                    <p class="tableHeaderText pt-1">Balance</p>
                </div>
            </div>
        </div>
        <div class="d-flex f-ss-14px bottom pt-1 pb-1">
            <div class="tableHeaderColumn2 col-md-10">
                <span class="font-weight-bold">Opening Balance</span>
            </div>
            <div class="tableHeaderColumn2 col-md-2 ml-2">
                <span class="float-right runningBalanceText">{{openingBalance}}</span>
            </div>
        </div>
        <div class="d-flex f-ss-14px bottom pt-1 pb-1" *ngFor="let itemInner of bsData">
            <div class="tableHeaderColumn2 col-md-2">
                <span>{{itemInner.CreatedAt}}</span>
            </div>

            <div class="tableHeaderColumn2 col-md-3">
                <span class="tableHeaderText">{{itemInner.Type}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-1">
                <span class="tableHeaderText"></span>
            </div>
            <div class="tableHeaderColumn2 col-md-2 ml-2" [ngSwitch]="itemInner.TypeId">
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)"
                    *ngSwitchCase="9">{{'Bill-'+itemInner.ReferenceNo}}</span>
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)"
                    *ngSwitchCase="10">{{'INV-'+itemInner.ReferenceNo}}</span>
                <span class="float-right text-blue cursor-pointer" (click)="goToUrl(itemInner)"
                    *ngSwitchDefault>{{itemInner.ReferenceNo}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-2">
                <span class="float-right">{{itemInner.Amount ? (itemInner.Amount | currency: '':'') : '-'}}</span>
            </div>
            <div class="tableHeaderColumn2 col-md-2 text-blue">
                <span class="float-right runningBalanceText">{{itemInner.BalanceView }}</span>
            </div>
        </div>
    </div>
</app-dashboard>