<app-dashboard>
    <app-page-header-title
      mainTitle="{{ 'repeatingBill.repeatingBill' | translate    }}"
      subTitle="Home"
      backURL="/">
      <div class="full-width text-align-right">
        <button type="button" class="eCount-def-btn ml-4" (click)="getSelectedRowData()">{{ 'repeatingBill.createBill' | translate  }}</button>
      </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center ">
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData(0)" [routerLinkActive]="['active']" routerLink="/expense/repeating-bills/0">{{ 'repeatingBill.all' | translate    }} ({{categoryCount.all}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData(1)" [routerLinkActive]="['active']" routerLink="/expense/repeating-bills/1">{{ 'repeatingBill.daily' | translate    }} ({{categoryCount.daily}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option"  (click)="reLoadRowData(2)" [routerLinkActive]="['active']" routerLink="/expense/repeating-bills/2">{{ 'repeatingBill.weekly' | translate    }} ({{categoryCount.weekly}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData(3)" [routerLinkActive]="['active']" routerLink="/expense/repeating-bills/3">{{ 'repeatingBill.monthly' | translate    }} ({{categoryCount.monthly}})</a>
      </li>
      <li class="nav-item">
        <a class="nav-link f-ssb-14px nav-option" (click)="reLoadRowData(4)" [routerLinkActive]="['active']" routerLink="/expense/repeating-bills/4">{{ 'repeatingBill.yearly' | translate    }} ({{categoryCount.yearly}})</a>
      </li>
    </ul>

    <div class="row pt-3">
        <ag-grid-angular
            style="font-size: 14px;"
            class="ag-theme-balham col"
            [rowData]="filteredBills"
            [columnDefs]="columnDefs"
            [domLayout]="domLayout"
            (cellClicked)="onCellClicked($event)"
            [defaultColDef]="defaultColDef"
            [rowSelection]="rowSelection"
            [suppressRowClickSelection]="true"
            (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
    </div>
    
    </app-dashboard>
    
    