import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillsServiceService } from '../../../service/Bill/bills-service.service';
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { css } from 'jquery';
@Component({
  selector: 'app-edit-basic-bill',
  templateUrl: './edit-basic-bill.component.html',
  styleUrls: ['./edit-basic-bill.component.css']
})
export class EditBasicBillComponent implements OnInit {

  public billCreationForm: FormGroup;
  @Input() fromParent: any;
  accountList:any=[];
  loading = false;
  selectedSupplier: number;
  supplierList:any=[];
  BillNo: string;
  statusId: number;
  repeatingTypeList:any=[
    {Id: 1,Name:"Daily"},
    {Id: 2,Name:"Weekly"},
    {Id: 3,Name:"Monthly"},
    {Id: 4,Name:"Yearly"}
  ];

   constructor(
    private spinner: NgxSpinnerService,
    private mainService: MainServiceService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private acountingService:AcountingServiceService,
    private billService: BillsServiceService) { }

  ngOnInit(): void {
    this.initializeForm();
    console.log(this.fromParent);
    this.BillNo = this.fromParent.BillNo;
    this.statusId= this.fromParent.StatusId;
    if(this.fromParent.CategoryId==1){
      this.acountingService.GetChartOfAccountsforItems().subscribe((x)=>{
        this.accountList=x.ExpenseList.filter(x=>x.ParentId==3);

    });

    }
    this.getAllSupplier();
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : false,
      IsSupplier : true,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {

        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.supplierList.push(x["Data"]);
        let customerObj = this.supplierList.filter(x=>x.PrimaryPersonFirstName == customerName);
        this.selectedSupplier = customerObj[0]['Id'];
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }
  show(e) {
    const value = this.billCreationForm.value.BillDate;
    $("#ebbtest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }
  getAllSupplier = () => {
    this.billService.GetAllContact().subscribe((x: []) => {
      this.supplierList = x;
    });
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  initializeForm() {
    this.billCreationForm = this.fb.group({
      Id:[this.fromParent.Id],
      BillDate: [this.fromParent.BillDate, Validators.required],
      Description: [this.fromParent.Description, Validators.required],
      Amount: [this.fromParent.Amount, Validators.required],
      CategoryId: [this.fromParent.CategoryId],
      AccountId: [this.fromParent.AccountId],
      SupplierId: [this.fromParent.SupplierId],
      IsRepeating: [this.fromParent.IsRepeating],
      RepeatingTypeId: [''],
      StatusId: []
    });
    this.setBillData();
  }
  getStatus(id) {
    console.log('this is my ID', id)
    switch (id) {
      case 1:
        return 'Draft';
      case 2:
        return 'Awaiting Approval';
      case 3:
        return 'Approved';
      case 4:
        return 'Paid';
    }
  }
  setBillData(){
    this.billCreationForm.patchValue({
      Id: this.fromParent.Id,
      BillDate: this.show(this.fromParent.BillDate),
      Amount: this.fromParent.BillAmount,
      SupplierId: this.fromParent.SupplierId,
      AccountId: this.fromParent.AccountId,
      IsRepeating: this.fromParent.RepeatingTypeId != null ?  true : false,
      RepeatingTypeId: this.fromParent.RepeatingTypeId
    });
}


  public saveBasicBill() {
    this.billCreationForm.get('StatusId').patchValue(1);
    this.SaveBill();
  }
  public approveBasicBill() {
    this.billCreationForm.get('StatusId').patchValue(2);
    this.SaveBill();
  }

  public SaveBill() {
    if (this.billCreationForm.invalid){
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    this.spinner.show();
    this.billService.SaveBasicBill(this.billCreationForm.value).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success(this.fromParent.Id==0? 'Bill has been successfully added.':"Bill updated successfully.");
        this.activeModal.close(x);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

}
