<app-dashboard>
    <app-page-header-title
        [mainTitle]="title"
        subTitle="Back"
        backURL="{{redirectUrl}}">
        <div class="text-align-right col-md-12 row">
            <div class="dropdown col-md-9 ml-3">
                <button id="attachment_btn " type="button" style="z-index: 1;" class="btn attachment-button"><i id="attachment_btn"
                    style="font-size: 20px !important;" class="material-icons">insert_drive_file</i><span id="attachment_btn"
                    style="color:darkgrey; font-size: 12px; "
                    *ngIf="totalFile>=1">{{totalFile}}
                </span>
            </button>
                <div class="x-tip-tl" style="height: 0px;">
                    <i id="custom_notch" class="material-icons custom_notch" >arrow_drop_up</i>
                </div>
                <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
                    <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="3"></app-attachment-uploader>
                </div>
            </div>
        <div class="col-md-0">
           <span><button  type="button" (click)="editItem()" class="eCount-def-btn">Edit Item</button></span>
       </div>
        <div class="dropdown col-md-1">
            <button type="button" data-toggle="dropdown" class="eCount-button3"><span>Options</span><span style="display: inline;" class="iconify" data-icon="bi:chevron-down" data-inline="false"></span></button>
            <ul class="dropdown-menu">
                <li ><a class="dropdownLink" (click)="markAsInactive()">Mark as Inactive</a></li>
            </ul>
        </div>
        </div>
    </app-page-header-title>
    <div class="container">
        <div [hidden]="!item?.IsTrackItem || item?.ItemTypeId == 5" class="row f-ss-14px" style="padding:30px 10px 0px 10px"> 
            <div class="col-md-2 center-alignment" style="padding: 0px 10px;"> 
                <div class="center-alignment" >
                    <p class="tx-c-br mb-0px">Quantity on Hand </p>
                    <p class="mb-0px" style="font-weight: bold;">{{stockQuantity}}</p>
                </div>
                <hr class="custom-hr">
            </div>
            <div class="col-md-10 text-align-right" style="padding: 0px 10px;"> 
                <button  type="button" (click)="updateItemQuantity()" class="eCount-def-btn">Update Quantity</button>
            </div>
        </div>
        <div [hidden]="!item?.IsTrackItem" class="row"> 
            <div class="col-md-6 f-ss-14px " style="padding: 30px 30px 0px 30px;"> 
                
                    <table class="full-width">
                        <th class="borderbottom">Inventory Item</th>
                        <th class="borderbottom" ></th>
                        <tr class="borderbottom tx-c-br" >
                            <td>Inventory asset account</td>
                            <td>630 Inventory</td>
                        </tr>
                    </table>
            </div>
        </div>
        <div class="row f-ss-14px"> 
            <div class="col-md-6 f-ss-14px " style="padding: 30px 30px 0px 30px;" [hidden]="item?.ItemTypeId == 5"> 
                <table class="full-width">
                    <th class="borderbottom">Purchases</th>
                    <th class="borderbottom" ></th>
                    <tr class="tx-c-br borderbottom" >
                        <td>UnitPrice</td>
                        <td>{{item?.PurchasePrice}}</td>
                    </tr>
                    <tr class="tx-c-br borderbottom" >
                        <td>Account</td>
                        <td>{{PurchaseAccountName}}</td>
                    </tr>
                    <tr class="tx-c-br borderbottom" >
                        <td>Description</td>
                        <td>{{item?.PurchaseDescription}}</td>
                    </tr>
                    <tr class="tx-c-br borderbottom" >
                        <td>Total Purchase Quantity</td>
                        <td>{{item?.InQuantity ? item?.InQuantity : 0}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-md-6 f-ss-14px " style="padding: 30px 30px 0px 30px;" [hidden]="item?.ItemTypeId == 4 || item?.ItemTypeId==2"> 
                <table class="full-width">
                    <th class="borderbottom" >Sales</th>
                    <th class="borderbottom"></th>
                    <tr class="tx-c-br borderbottom">
                        <td>UnitPrice</td>
                        <td>{{item?.SaleUnitPrice}}</td>
                    </tr>
                    <tr class="tx-c-br borderbottom" >
                        <td>Account</td>
                        <td>{{SaleAccountName}}</td>
                    </tr>
                    <tr class="tx-c-br borderbottom">
                        <td>Description</td>
                        <td>{{item?.SalesDescription}}</td>
                    </tr>
                    <tr class="tx-c-br borderbottom" >
                        <td>Total Sales Quantity</td>
                        <td>{{item?.OutQuantity ? item?.OutQuantity : 0}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="row f-ss-14px" [hidden]="rowData.length == 0"> 
            <div class="col-md-12" style="padding-bottom: 30px;"> 
                <p class="f-ss-14px tx-c-lb mb-0px gird-table-header">Recent Bill Transactions</p>
                <ag-grid-angular #agGrid id="myGrid" class="ag-theme-balham col"
                                [columnDefs]="gridOptions.columnDefs" [defaultColDef]="gridOptions.defaultColDef"
                                [domLayout]="gridOptions.domLayout" [rowData]="rowData" (gridReady)="onGridReady($event)"
                                [getRowStyle]="gridOptions.getRowStyle" (rowClicked)="onRowClicked($event)">
                </ag-grid-angular>
            </div>
        </div>
        <div class="row f-ss-14px" [hidden]="rowInvoData.length == 0"> 
            <div class="col-md-12" style="padding-bottom: 30px;"> 
                <p class="f-ss-14px tx-c-lb mb-0px gird-table-header">Recent Invoice Transactions</p>
                <ag-grid-angular #agGrid id="myGrid2" class="ag-theme-balham col"
                                [columnDefs]="gridOptions2.columnDefs" [defaultColDef]="gridOptions2.defaultColDef"
                                [domLayout]="gridOptions2.domLayout" [rowData]="rowInvoData" (gridReady)="onGridReady2($event)"
                                [getRowStyle]="gridOptions2.getRowStyle">
                </ag-grid-angular>
            </div>
        </div>
        <div class="row f-ss-14px" [hidden]="soldItemReturnData.length == 0"> 
            <div class="col-md-12" style="padding-bottom: 30px;"> 
                <p class="f-ss-14px tx-c-lb mb-0px gird-table-header">Recent Sold Item Return Transactions</p>
                <ag-grid-angular #agGrid id="myGrid2" class="ag-theme-balham col"
                                [columnDefs]="gridOptions3.columnDefs" [defaultColDef]="gridOptions3.defaultColDef"
                                [domLayout]="gridOptions3.domLayout" [rowData]="soldItemReturnData" (gridReady)="onGridReady2($event)"
                                [getRowStyle]="gridOptions3.getRowStyle">
                </ag-grid-angular>
            </div>
        </div>
        <div class="row f-ss-14px" [hidden]="purchaseItemReturnData.length == 0"> 
            <div class="col-md-12" style="padding-bottom: 30px;"> 
                <p class="f-ss-14px tx-c-lb mb-0px gird-table-header">Recent Purchase Item Return Transactions</p>
                <ag-grid-angular #agGrid id="myGrid2" class="ag-theme-balham col"
                                [columnDefs]="gridOptions3.columnDefs" [defaultColDef]="gridOptions3.defaultColDef"
                                [domLayout]="gridOptions3.domLayout" [rowData]="purchaseItemReturnData" (gridReady)="onGridReady2($event)"
                                [getRowStyle]="gridOptions3.getRowStyle">
                </ag-grid-angular>
            </div>
        </div>
        <div class="row f-ss-14px" [hidden]="purchaseItemManuallyUpdatedTransactionList.length == 0"> 
            <div class="col-md-12" style="padding-bottom: 30px;"> 
                <p class="f-ss-14px tx-c-lb mb-0px gird-table-header">Recent Purchase Item Quantity Updated Transactions</p>
                <ag-grid-angular #agGrid id="myGrid2" class="ag-theme-balham col"
                                [columnDefs]="updateItemManuallyGridOptions.columnDefs" [defaultColDef]="updateItemManuallyGridOptions.defaultColDef"
                                [domLayout]="updateItemManuallyGridOptions.domLayout" [rowData]="purchaseItemManuallyUpdatedTransactionList" (gridReady)="onGridReady2($event)"
                                [getRowStyle]="updateItemManuallyGridOptions.getRowStyle">
                </ag-grid-angular>
            </div>
        </div>
        <div class="row f-ss-14px" [hidden]="soldItemManuallyUpdatedTransactionList.length == 0"> 
            <div class="col-md-12" style="padding-bottom: 30px;"> 
                <p class="f-ss-14px tx-c-lb mb-0px gird-table-header">Recent Sold Item Quantity Updated Transactions</p>
                <ag-grid-angular #agGrid id="myGrid2" class="ag-theme-balham col"
                                [columnDefs]="updateItemManuallyGridOptions.columnDefs" [defaultColDef]="updateItemManuallyGridOptions.defaultColDef"
                                [domLayout]="updateItemManuallyGridOptions.domLayout" [rowData]="soldItemManuallyUpdatedTransactionList" (gridReady)="onGridReady2($event)"
                                [getRowStyle]="updateItemManuallyGridOptions.getRowStyle">
                </ag-grid-angular>
            </div>
        </div>
    </div>

</app-dashboard>