import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,FormArray } from '@angular/forms';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-factory-out-view',
  templateUrl: './factory-out-view.component.html',
  styleUrls: ['./factory-out-view.component.css']
})
export class FactoryOutViewComponent implements OnInit {

  public TransNo="";
  public MovementId:number=0;
  public salesOrders:any = [];
  public salesOrderCount:any = [];
  public factoryOutForm: FormGroup;
  public ProductionOutItem: FormArray;
  public FactoryOutSalesOrder: FormArray;
  public OperatingCostItem: FormArray;
  totalMaterialCost:number=0;
  public salesQuantityValidation:boolean = false;

  constructor(
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private pService: FactoryProductionService) { }

  ngOnInit(): void {
    this.TransNo=this.route.snapshot.paramMap.get("transNo");
    this.initializeForm();
    this.getTransactionDetails(this.TransNo);
  }

  private getTransactionDetails(transNo:string){
    
    this.pService.GetOutDetails(transNo).subscribe((x) => {
      console.log(x);
      this.setRawItem(x);
      this.MovementId = x.Id;
      this.getSalesOrderList(x.ProductId);
      x.ProductionItemView.forEach(item => {
        this.addItem(item);
        this.totalMaterialCost+=(item.MaterialCost*item.UsedQty);
      });
      x.FactoryOutSalesOrder.forEach(item => {
        this.addSalesOrderItem(item);
      });
      x.OperatingCostItem.forEach(item => {
        this.addOperatingCostItem(item);
      });
    });
  }
  private getSalesOrderList(productId: number){
    this.pService.GetFactoryOutSalesOrder(productId).subscribe((x) => {
      this.salesOrders = x.salesOrders;
      this.salesOrderCount = x.salesOrderCount;
    });
  }
  initializeForm() {
    this.factoryOutForm = this.fb.group({
      Id: [0],
      ProductId: [''],
      ProductName: [''],
      TransactionNo: [''],
      OutDate: [''],
      BatchNo: [''],
      ProductOutQuantity: [0],
      PerUnitMaterialCost: [0],
      PerUnitOperatingCost: [0],
      PerUnitProductionCost: [0],
      StatusId: [''],
      CreatedAt: [''],
      CreatedById: [''],
      ProductionOutItem: this.fb.array([]),
      FactoryOutSalesOrder: this.fb.array([]),
      OperatingCostItem: this.fb.array([])
    });
  }
  public checkQuantity(item){
    var sum = 0 ;
    this.salesQuantityValidation = false;
    if (this.factoryOutForm.value.ProductOutQuantity < item.value.BookedQuantity){
      item.controls['BookedQuantity'].patchValue(0);
      this.salesQuantityValidation = true;
      this.toaster.error("Booked Qty must be less than or equal to Factory Qty");
      return;
    }
    this.salesOrderCount.forEach(child => {
      sum=0;
      if(child.OrderId==item.value.OrderId){
        this.factoryOutForm.value.SalesOrderItem.forEach(x => {
          if(x.OrderId == child.OrderId)
          {
            sum+=Number(x.BookedQuantity);
          }
        });
        if(sum>child.Quantity)
        {
          item.controls['BookedQuantity'].patchValue(0);
          this.salesQuantityValidation = true;
          this.toaster.error("Booked Qty must be less than or equal to Sales Qty");
          return;
        }
      }
    });
  }
  public createSalesOrderBlankItem(): FormGroup {
    return this.fb.group({
      Id: [0],
      MovementId: [this.MovementId],
      OrderId:[0],
      BookedQuantity: [0]
    });
  }
 public createSalesOrderItem(x:any): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      MovementId: [x.MovementId],
      OrderId: [ x.OrderId],
      BookedQuantity: [x.BookedQuantity],
      CreatedAt: [x.CreatedAt]
    });
  }
  public createOperatingCostItem(x): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      Name: [x.Name],
      Amount: [x.Amount],
      MovementId: [x.MovementId]
    });
  }
  public createItem(x: any): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      MaterialId: [x.MaterialId],
      ItemCode: [x.ItemCode],
      ItemName: [x.ItemName],
      StockQty: [x.StockQty],
      UsedQty: [x.UsedQty],
      MovementId: [x.MovementId],
      MaterialCost: [x.MaterialCost],
      LineTotal: [x.UsedQty * x.MaterialCost]
    });
  }
  public addItem(x: any): void {
    this.ProductionOutItem = this.factoryOutForm.get('ProductionOutItem') as FormArray;
    this.ProductionOutItem.push(this.createItem(x));
  }
  public addSalesOrderItem(x: any): void {
    this.FactoryOutSalesOrder = this.factoryOutForm.get('FactoryOutSalesOrder') as FormArray;
    this.FactoryOutSalesOrder.push(this.createSalesOrderItem(x));
  }
  public addOperatingCostItem(x: any): void {
    this.OperatingCostItem = this.factoryOutForm.get('OperatingCostItem') as FormArray;
    this.OperatingCostItem.push(this.createOperatingCostItem(x));
  }
  public addSalesOrderBlankItem(): void {
    this.FactoryOutSalesOrder = this.factoryOutForm.get('SalesOrderItem') as FormArray;
    this.FactoryOutSalesOrder.push(this.createSalesOrderBlankItem());
  }
  public setRawItem(x: any): void {
    this.factoryOutForm.controls['ProductName'].patchValue(x.ProductName);
    this.factoryOutForm.controls['OutDate'].patchValue(moment(x.OutDate).format("MM/DD/YYYY"));
    this.factoryOutForm.controls['BatchNo'].patchValue(x.BatchNo);
    this.factoryOutForm.controls['ProductOutQuantity'].patchValue(x.ProductOutQuantity);
    this.factoryOutForm.controls['PerUnitMaterialCost'].patchValue(x.PerUnitMaterialCost);
    this.factoryOutForm.controls['PerUnitOperatingCost'].patchValue(x.PerUnitOperatingCost);
    this.factoryOutForm.controls['PerUnitProductionCost'].patchValue(x.PerUnitProductionCost);
    this.factoryOutForm.controls['StatusId'].patchValue(x.StatusId);
    this.factoryOutForm.controls['CreatedAt'].patchValue(x.CreatedAt);
    this.factoryOutForm.controls['CreatedById'].patchValue(x.CreatedById);
    this.factoryOutForm.controls['Id'].patchValue(x.Id);
    this.factoryOutForm.controls['ProductId'].patchValue(x.ProductId);
    this.factoryOutForm.controls['TransactionNo'].patchValue(x.TransactionNo);
    }
    public deleteItem(i: number, item: any){
       this.pService.DeleteFactoryOutSalesOrder(item.value.Id).subscribe((x) => {
        if (x.Success) {
        } else {
        }
      }, err => {
      });
       this.FactoryOutSalesOrder.removeAt(i);
    }
    public SaveFactoryOutSalesOrder() {

      if (this.salesQuantityValidation){
        return;
      }
      this.spinner.show();
      this.pService.SaveFactoryOutSalesOrder(this.factoryOutForm.value.SalesOrderItem).subscribe((x)=>{
        if (x.Success) {
          this.toaster.success('Successfully saved.');
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      }, err => {
          this.toaster.error('ooh, something went wrong !');
      });
    }

    approveFactoryOut(){
      this.factoryOutForm.get('StatusId').patchValue(2);
      this.spinner.show();
      console.log(this.factoryOutForm.value);
      this.pService.ApproveProdOut(this.factoryOutForm.value).subscribe((x) => {
        if (x.Success) {
          this.toaster.success('Factory out Successfully Approved.');
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      }, err => {
          this.toaster.error('ooh, something went wrong !');
      });
    }

}

