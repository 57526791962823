import { DecimalPipe } from "@angular/common";

export class createInvoice{
    Id: number;
    CustomerId: number;
    InvoiceDate: Date;
    DueDate: Date;
    InvoicceNo: string;
    ReferenceNo: string;
    InvoiceAmount: number;
    IsConversion: boolean;
    PostingModuleId: number;
    ExternalTransactionId: number;
    TaxTypeId: number;
    TaxAmount: number;
    AdvanceTaxAmount: number;
    Notes: string;
    IsRepeating: boolean;
    RepeatingTypeId: any;
    InvoiceItems: InvoiceItem[];
}

export class InvoiceItem{
    ItemId?: number;
    Description: string;
    Quantity: number;
    UnitPrice: number;
    LineTotal: number;
    AccountId: number;
    TaxRateId:number;
    AdvanceTaxRateId:number;
    selected: boolean;
}

export class createBill{
    Id: number;
    SupplierId: number;
    BillDate: Date;
    DueDate: Date;
    BillNo: string;
    ReferenceNo: string;
    BillAmount: number;
    IsConversion:boolean;
    PostingModuleId: number;
    ExternalTransactionId: number;
    TaxTypeId:number;
    TaxAmount:number;
    StatusId: number;
    AdvanceTaxAmount:number;
    BillItems: BillItem[];
    Notes: string;
}
export class EditBill{
    Id: number;
    SupplierId: number;
    BillDate: Date;
    DueDate: Date;
    BillNo: string;
    ReferenceNo: string;
    BillAmount: number;
    IsConversion:boolean;
    PostingModuleId: number;
    ExternalTransactionId: number;
    TaxTypeId:number;
    TaxAmount:number;
    AdvanceTaxAmount:number;
    BillItems: BillItem[];
    IsBasicBill: boolean;
    IsRepeating: boolean;
    Notes: string;
    RepeatingTypeId: any;
}
export class BillItem{
    ItemId?: number;
    Description: string;
    Quantity: number;
    UnitPrice: number;
    LineTotal: number;
    AccountId: number;
    TaxRateId:number;
    AdvanceTaxRateId:number;
    selected: boolean;
}

export class CreateBasicBill{
    Id:number;
   BillDate:Date;
   Description:string;
   Amount:number;
   CategoryId:number;
   RepeatedBill:boolean;
   RepeatedTypeId:number;
   IsRepeating:boolean=false;
   RepeatingTypeId:number;
   AccountId:number;
   SupplierId:number;
   StatusId: number;
}

export class RepeatingBillCount{
    all:number=0;
    daily:number=0;
    weekly:number=0;
    monthly:number=0;
    yearly:number=0;
}
export class RepeatingInvoiceCount{
    all: number=0;
    daily: number=0;
    weekly: number=0;
    monthly: number=0;
    yearly: number=0;
}
export class CreatePurchaseOrder{
    Id: number;
    CustomerId: number;
    InvoiceDate: Date;
    DueDate: Date;
    InvoicceNo: string;
    ReferenceNo: string;
    InvoiceAmount: number;
    IsConversion:boolean;
    PostingModuleId: number;
    ExternalTransactionId: number;
    TaxTypeId:number;
    TaxAmount:number;
    InvoiceItems: PurchaseOrderItem[];
}

export class PurchaseOrderItem{
    ItemId?: number;
    Description: string;
    Quantity: number;
    UnitPrice: number;
    LineTotal: number;
    AccountId: number;
    TaxRateId:number;
    selected: boolean;
}

export class BudgetItem{
    AccountId: number;
    BudgetAmount: number;
}



export class Invoice{
    Id: number;
    CustomerId: number;
    InvoiceDate: Date;
    DueDate: Date;
    InvoicceNo: string;
    ReferenceNo: string;
    InvoiceAmount: number;
    IsConversion: boolean;
    PostingModuleId: number;
    ExternalTransactionId: number;
    TaxTypeId: number;
    TaxAmount: number;
    AdvanceTaxAmount: number;
    Notes: string;
    IsRepeating: boolean;
    RepeatingTypeId: any;
    InvoiceItems: InvoiceItems[];
}

export class InvoiceItems{
    ItemId?: number;
    Description: string;
    Quantity: number;
    UnitPrice: number;
    LineTotal: number;
    AccountId: number;
    TaxRateId:number;
    AdvanceTaxRateId:number;
    selected: boolean;
}
