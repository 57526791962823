import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './service/auth/auth.service'
import { MainServiceService } from './service/Main/main-service.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private route : Router, private mainService: MainServiceService){}
  canActivate(snapshot: ActivatedRouteSnapshot): boolean{
    if (this.authService.IsLoggedIn()) {
     // this.mainService.setDefaultUserInfo();
     if(snapshot.data.canAccessWithoutSubscription){
      return true;
     }
     if(this.authService.IsCompanySubscriptionExpired()){
      this.route.navigate(['/subscription-renew/subscription-renew']);
      this.authService.openSubscriptionExpiredWarningModal();
     }
     return true;
    }else{
      this.route.navigate(['/auth/login']);
      return false;
    }
  }
  
}
