import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductServiceService } from '../../../service/Products/product-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderService } from 'src/app/service/PurchaseOrder/purchase-order.service';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { ItemCreateViewModel } from 'src/app/models-viewModels/itemCreateViewModel';

@Component({
  selector: 'app-create-popup-item',
  templateUrl: './create-popup-item.component.html',
  styleUrls: ['./create-popup-item.component.css']
})
export class CreatePopupItemComponent implements OnInit {
  @Input() fromParent: any;
  itemForm: FormGroup;
  public itemId = 0;
  @Input() refId: number;
  itemPageTitle = 'Add New Items';
  @Input() type: string;
  supplierList: any = [];
  loading = false;

  constructor(private spinner: NgxSpinnerService,
              public fb: FormBuilder,
              private toaster: ToastrService,
              public activeModal: NgbActiveModal,
              private productServiceService: ProductServiceService,
              private pService: PurchaseOrderService,
              private mainService: MainServiceService) { }

  ngOnInit(): void {
    console.log(this.refId);
    this.getAllSupplier();
    this.initializeItemForm();
  }

  getAllSupplier = () => {
    this.pService.GetSupplierList().subscribe((x) => {
      this.supplierList = x;
    });
  }

  initializeItemForm() {
    this.itemForm = this.fb.group({
      Id: [0],
      itemId: [1],
      ItemCode: ['',  Validators.required],
      ItemName: ['',  Validators.required],
      PurchasePrice: [''],
      SaleUnitPrice: [''],
      ItemTypeId: [1],
      Description: ['', Validators.required],
      SupplierId: [0]
    });
    this.itemForm.patchValue({ItemTypeId: 1});
  }

  CreateNewSupplier = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer: false,
      IsSupplier: true,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.supplierList.push(x["Data"]);
        let customerObj = this.supplierList.filter(x=>x.PrimaryPersonFirstName == customerName);
        this.itemForm.controls['SupplierId'].patchValue(customerObj[0]['Id']);
        this.getAllSupplier();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }

 public saveOrUpdate() {
   if (this.itemForm.invalid || (this.itemForm.value.ItemTypeId == 2 && this.itemForm.value.PurchasePrice == '') 
  || (this.itemForm.value.ItemTypeId ==1 && (this.itemForm.value.PurchasePrice =='' || this.itemForm.value.SaleUnitPrice =='')) ||
  (this.itemForm.value.ItemTypeId ==3 && (this.itemForm.value.PurchasePrice =='' || this.itemForm.value.SaleUnitPrice =='')) ||
 (this.itemForm.value.ItemTypeId == 4 && this.itemForm.value.PurchasePrice == '') ||
   (this.itemForm.value.ItemTypeId == 5 && this.itemForm.value.SaleUnitPrice == '') || 
   ((this.itemForm.value.ItemTypeId == 1 || this.itemForm.value.ItemTypeId == 2) && this.itemForm.value.SupplierId == 0)
  ){
    this.toaster.warning('Please fill all the required fields!');
    return;
  }
  this.spinner.show();
  let data = this.formatData();
  this.productServiceService.saveProductData(data).subscribe((x) => {
    if (x.Success){
      this.toaster.success('Item has been successfully added.');
      this.activeModal.close(x);
    }
    else{
      this.toaster.error(x.Message);
    }
    this.spinner.hide();
  }, err => {
      this.toaster.error('ooh, something went wrong !');
  });
  }

  formatData(): ItemCreateViewModel {
    let isPurchaseItem = this.itemForm.value.ItemTypeId == 1 || this.itemForm.value.ItemTypeId == 2 || this.itemForm.value.ItemTypeId == 3 || this.itemForm.value.ItemTypeId == 4;
    let isSaleItem = this.itemForm.value.ItemTypeId == 1 || this.itemForm.value.ItemTypeId == 2 || this.itemForm.value.ItemTypeId == 3 || this.itemForm.value.ItemTypeId == 5;
    let item: ItemCreateViewModel = {
      Id: this.itemForm.value.Id,
      ItemCode: this.itemForm.value.ItemCode,
      ItemName: this.itemForm.value.ItemName,
      Description: this.itemForm.value.Description,
      ItemTypeId: this.itemForm.value.ItemTypeId,
      IsSaleItem: isSaleItem,
      SaleUnitPrice: isSaleItem == true ? this.itemForm.value.SaleUnitPrice : null,
      IsPurchaseItem: isPurchaseItem,
      PurchasePrice: isPurchaseItem == true ? this.itemForm.value.PurchasePrice : null,
      SupplierId: isPurchaseItem == true ? this.itemForm.value.SupplierId : null,
      CompanyId: 0,
      ActionById: null,
      IsManufactureItem: false
    };
    return item;
  }
  
  copyItemCode = (e) => {
    this.itemForm.patchValue({ItemName: (this.itemForm.value.ItemName == null  || this.itemForm.value.ItemName === "")
    ? e.target.value : this.itemForm.value.ItemName  });
    this.itemForm.patchValue({Description: this.itemForm.value.Description == null || this.itemForm.value.Description === "" ?
     e.target.value : this.itemForm.value.Description });
  }

  changeType()
  {
    this.itemForm.patchValue({ItemTypeId: this.itemForm.value.itemId});
  }
}
