<div id="attachment">
    <b id="attachment" class="border-notch notch right" id="ext-gen68"></b><b class="notch right" id="ext-gen69"></b>
    <div id="attachment" class="x-tip-bwrap main-popup" id="ext-gen61">
       <div id="attachment">
            <div id="attachment" class="bg-gray border__bottom padding__tb--p12px attachment__header padding__l--p10px" style="border-top-left-radius: 6px; border-top-right-radius: 6px; padding-right: 10px;">
               Attach file
            </div>
            <div id="attachment" class="border__bottom attachment-list" >
                <div id="attachment" *ngIf="fileList.length == 1" style="margin:0px;" class="row">
                    <div id="attachment" class="pall__p10px" style="width: 20%;">
                        <img id="attachment"  data-toggle="modal" data-target="#Preview" src="https://d29fhpw069ctt2.cloudfront.net/icon/image/37768/preview.svg">
                    </div>
                    <div  id="attachment" class="pall__p10px attachment__detail" style="width: 80%;">
                        <div id="attachment" >Upload your files to store them alongside all of your financial documents</div>
                        <p id="attachment" style="margin-bottom: 0;" class="padding__t--p10px">Added files will only be visible to users with access to your company</p>
                    </div>
                </div>
                <div id="attachment" class="row__hover" *ngFor="let file of fileList;index as i" >
                    <div id="attachment" *ngIf="i>0" class="row row__padding border__bottom" style="margin: 0px;" >
                        <div id="attachment" *ngIf="fileImage(file.fileName)=='doc'" style="width: 10%;padding:5px 10px;">
                            <img id="attachment" style="width: 95%;" src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8a/Icon-doc.svg/474px-Icon-doc.svg.png">
                        </div>
                        <div id="attachment" *ngIf="fileImage(file.fileName)=='image'" style="width: 10%;padding:5px 10px;">
                            <img id="attachment" style="width: 95%;" src="https://i.ya-webdesign.com/images/image-icon-png-8.png">
                        </div>
                        <div id="attachment" *ngIf="fileImage(file.fileName)=='other'" style="width: 10%;padding:5px 10px;">
                            <img id="attachment" style="width: 95%;" src="https://d29fhpw069ctt2.cloudfront.net/icon/image/37768/preview.svg">
                        </div>
                        <div id="attachment" style="width: 80%; padding: 0px 5px;">
                            <h4 id="attachment" (click)="openAttachmentViewer(file)" style="margin: 0px;" class="h4_fonts pointer_cursor"  data-toggle="modal" data-target="#Preview">{{file.fileName}}</h4>
                            <div id="attachment" (click)="openAttachmentViewer(file)" *ngIf="file.uploadSuccess"  data-toggle="modal" data-target="#Preview">
                                <p id="attachment" style="padding-top: 5px; margin: 0px;" class="attachment__detail pointer_cursor" >This file is uploaded by {{ file.Uploader }} </p>
                            </div>
                            <div id="attachment" *ngIf="whatToShow(file.percentDone,file.uploading)">
                                <div id="attachment" class="progress" style="height:6px; margin-top: 5px;">
                                    <div id="attachment" class="progress-bar bg-success" role="progressbar" [ngStyle]="{'width': file.percentDone+'%'}"  aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div id="attachment" *ngIf="finsihingUp(file.percentDone,file.uploading)">
                                <p id="attachment" style="padding-top: 5px; margin: 0px;" class="attachment__detail">finishing ........ </p>
                            </div>

                            <div id="attachment" *ngIf="file.deleting" style="padding: 5px 10px;">
                                <button id="attachment" (click)="deleteFile(file.Id)" style="padding:0px;font-size: 14px; width: 75px;margin-top: 14px;" class="btn btn-success " > Confirm </button>
                                <button id="attachment" (click)="cancelDelete(file.Id)" style="padding:0px;width: 75px;margin-top: 14px; background-color: #E54B45;font-size: 14px;" class="float-right ml-3 btn btn-danger">Cancel</button>
                            </div>
                        </div>
                        <div id="attachment" class="pointer_cursor" style="width: 10%; padding: 5px 5px;margin-top: 0px;">
                            <i id="attachment" (click)="selectForDelete(file.Id)" class="material-icons">delete</i>
                        </div>
                    </div>
                </div>
            </div>
       </div>
       <div id="attachment">
         <div id="attachment">
            <label id="attachment" style="width: 100%;" for="files" class="btn" >Select File</label>
            <input  (change)="upload($event.target.files)" id="files" style="visibility:hidden; margin-top:-40px;" type="file" multiple>
          </div>
       </div>
    </div>
 </div>
  
  <div class="modal fade" id="Preview" role="dialog">
    <div id="attachment" class="modal-dialog" style="min-width: 70%; max-width: 70%; width: 70%;">
      <div id="attachment" class="modal-content">
        <div id="attachment" class="modal-header" style="display: block; text-align:left;">
          <button id="attachment" type="button" class="close" data-dismiss="modal">&times;</button>
          <span class="modalHeaderText pl-2">Attachment</span>
          <hr>
        </div>
        
        <div id="attachment" class="modal-body" style="text-align: left;">
            <div id="attachment" class="row pr-2 pl-2">
                <div id="attachment" [hidden]="!isPdf" class="col-md-12 visibility">
                    <div id="attachment" class="float-right" >
                        <span id="attachment" (click)="zoomIn()" class="pointer"><i id="attachment" class="material-icons attachmenticon">zoom_in</i></span>
                        <span id="attachment" (click)="zoomOut()" class="pointer"><i id="attachment" class="material-icons attachmenticon">zoom_out</i></span>
                    </div>
                </div>
                <div id="attachment" class="col-md-12">
                     <div id="attachment" id="attachment" [hidden]="!isPdf"> 
                        
                        <pdf-viewer id="attachment" [src]="src"
                            [render-text]="true"
                            [fit-to-page]="true"
                            [zoom]="zoom"
                            style="display: block;">
                        </pdf-viewer>
                    </div> 
                    <div id="attachment" [hidden]="isPdf">
                        <div id="attachment">
                            <img id="attachment" style="width: 100%;" src={{baseImageUrl}}{{selectedItem?.BlobName}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="attachment" class="modal-footer">
          <button id="attachment" type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div> -->
      </div>
    </div>
  </div>
 