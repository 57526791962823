<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')"><span aria-hidden="true" class="crossIcon">&times;</span></button>
</div>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <div class="f-ss-14px">
                    <div class="row" *ngIf="subscriptionPlanBusinessType == 1">
                        <div class="col-sm-12 d-flex">
                            <div class="col-sm-12">
                                <ul>
                                    <li>Startup</li>
                                    <li>Non-Profit</li>
                                    <li>IT</li>
                                    <li>Tourism</li>
                                    <li>Creative</li>
                                    <li>Legal</li>
                                    <li>BPO</li>
                                </ul>
                                <p>Help<br>
                                    <span style="font-size: 16px; font-weight: bold; color: #007bff;">info@iquidi.com<br>+880 17 1776 0924</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="subscriptionPlanBusinessType == 2">
                        <div class="col-sm-12 d-flex">
                            <div class="col-sm-12">
                                <ul>
                                    <li>E-Com</li>
                                    <li>Small Business</li>
                                </ul>
                                <p>Help<br>
                                    <span style="font-size: 16px; font-weight: bold; color: #007bff;">info@iquidi.com<br>+880 17 1776 0924</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="subscriptionPlanBusinessType == 3">
                        <div class="col-sm-12 d-flex">
                            <div class="col-sm-12">
                                <ul>
                                    <li>Construction</li>
                                    <li>Real-Estate</li>
                                    <li>Apparel</li>
                                    <li>Woods CraftsMan</li>
                                    <li>Glass Fabricator</li>
                                    <li>Paper Producer</li>
                                </ul>
                                <p>Help<br>
                                    <span style="font-size: 16px; font-weight: bold; color: #007bff;">info@iquidi.com<br>+880 17 1776 0924</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>