import { Component, OnInit } from "@angular/core";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/service/auth/auth.service";
@Component({
  selector: "app-auth-header",
  templateUrl: "./auth-header.component.html",
  styleUrls: ["./auth-header.component.css"],
})
export class AuthHeaderComponent implements OnInit {
  faCoffee = faBars;
  selectedLanguage;
  constructor(
    public translate: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getSelectedLanguage();
  }
  changeLanguage = (lang) => {
    this.translate.use(lang);
    if (localStorage) {
      localStorage["language"] = lang;
    }
    this.getSelectedLanguage();
  };
  getSelectedLanguage = () => {
    const lang = localStorage.getItem("language");
    this.selectedLanguage = localStorage ? localStorage["language"] : null;
    this.translate.use(lang);
  };
}
