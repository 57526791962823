import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-attachment-uploader',
  templateUrl: './attachment-uploader.component.html',
  styleUrls: ['./attachment-uploader.component.css'],
  host: {
    '(document:drag)': 'onWindowClick($event)',
  },
})
export class AttachmentUploaderComponent implements OnInit {
  @Input('module') module: string;
  @Output() noOfFiles = new EventEmitter<number>();
  isUploaded: boolean = false;
  uploadCounter: number;
  filesToUpload: number = 0;
  baseurl = environment.baseUrl;
  baseImageUrl = environment.fileBaseUrl;
  percentDone: number;
  selectedItem:any;
  fileName: string;
  fileType: string;
  isPdf: boolean;
  src: string;
  zoom:number=1;
  refId:number=0;

  fileList = [
    { fileName: "", fileType: '', uploadSuccess: true, percentDone: null, uploading: false, deleting: false, BlobName: "", Uploader: "", Id: null, ReferenceId: null }
  ]

  constructor(private _eref: ElementRef,
    private attachmentService: AttachmentServiceService,
    private dataService:FileEmitterService,
    private toaster: ToastrService) {

     }

  ngOnInit(): void {
    this.dataService.fileRefIdUpdate.subscribe(x => {
      this.refId = x.id;
      this.GetAllAttachment();
    });
  }


  upload(files: File[]) {
    this.percentDone = null;
    this.uploadCounter = 0;
    this.filesToUpload = files.length;
    this.uploadAndProgressOneByOne(files);
    this.loadFileAttachment();
  }


  loadFileAttachment() {
    if (this.isUploaded) {
      this.GetAllAttachment();
      this.isUploaded = false;
    }
    setTimeout(() => {
      this.loadFileAttachment();
    }, 500);
  }

  uploadAndProgressOneByOne(files: File[]) {
    let totalFile = this.fileList.length;
    Array.from(files).forEach((f, i) => {
      this.fileList.push({ fileName: files[i].name, fileType: files[i].type, uploadSuccess: false, percentDone: 0, uploading: false, deleting: false, BlobName: "", Uploader: "", Id: null, ReferenceId: this.refId });
      let formData = new FormData();
      formData.append('ff', f);
      this.refId > 0 ? this.postFileToServer(formData, f.name, f.type, totalFile+i):0;
    });
  }

  postFileToServer(fff: FormData, name: string, type: string, i: number) {
    this.attachmentService.UploadAttachment(this.module, fff,this.refId).subscribe(event => {
      HttpEventType.UploadProgress;
      if (event["type"] === HttpEventType.UploadProgress) {
        this.fileList.splice(i, 1, { fileName: name, fileType: type, uploadSuccess: false, percentDone: Math.round(100 * event["loaded"] / event["total"]), uploading: true, deleting: false, BlobName: "", Uploader: "", Id: null, ReferenceId: this.refId })
      } else if (event instanceof HttpResponse) {
        this.fileList.splice(i, 1, { fileName: name, fileType: type, uploadSuccess: true, percentDone: 100, uploading: false, deleting: false, BlobName: "", Uploader: "", Id: null, ReferenceId: this.refId })
        this.uploadCounter += 1;
      }
      if (this.filesToUpload == this.uploadCounter) {
        this.isUploaded = true;
      }
    });
  }

  whatToShow(percentDone: number, checker: boolean) {
    return (percentDone >= 0) && (percentDone <= 95) && checker;
  }

  finsihingUp(percentDone: number, checker: boolean) {
    return (percentDone > 95) && checker;
  }

  fileImage(file: string) {
    let ext = file.split(".")[1];
    if (ext == "jpg" || ext == "JPEG" || ext == "jpeg" || ext == "png" || ext == "PNG") {
      return 'image';
    }
    else if (ext == "xlsx" || ext == "pdf" || ext == "txt" || ext == "xml" || ext == "rtf" || ext == "odt" || ext == "dotx" || ext == "docx" || ext == "doc") {
      return 'doc';
    } else {
      return 'other';
    }
  }
  selectForDelete(i: number) {
    this.fileList.map((f) => {
      if (i == f.Id) {
        f.deleting = true;
      }
    })
  }
  deleteFile(id: number) {
    this.attachmentService.DeleteAttachmentById(this.module,id).subscribe(x => {
      if (x["success"] = true) {
        this.GetAllAttachment();
        this.toaster.success('Delete file has done !');
      } else {
        this.toaster.warning('Error while deleting !');
      }
    })
  }

 public cancelDelete(id: number) {
    this.fileList.map((f) => {
      if (id == f.Id) {
        f.deleting = false;
      }
    })
  }

 private GetAllAttachment() {
    this.attachmentService.GetAllAttachmentById(this.module,this.refId).subscribe(res => {
      this.fileList = [
        { fileName: "", fileType: '', uploadSuccess: true, percentDone: null, uploading: false, deleting: false, BlobName: "", Uploader: "", Id: null, ReferenceId: this.refId }
      ]
      res.forEach(element => {
        this.fileList.push(this.buildFileEntity(element));
      });
      this.noOfFiles.emit(this.fileList.length-1);
    })
  }

 private buildFileEntity(e: any) {
    return {
      fileName: e.FileName,
      fileType: e.FileName.split(".")[1],
      uploadSuccess: true,
      percentDone: 100,
      uploading: false,
      deleting: false,
      BlobName: e.BlobName,
      Uploader: e.UpdatedByName,
      Id: e.Id,
      ReferenceId: this.refId
    }
  }

  zoomOut(){
    this.zoom -=.1;
  }
  zoomIn(){
    this.zoom +=.1;
  }
 public openAttachmentViewer = (file: any) => {
    this.selectedItem = file;
    this.src = this.baseImageUrl + file?.BlobName;
    let ext = file.fileName.split(".")[1];
    if (ext == 'pdf') {
      this.isPdf = true;
    } else {
      this.isPdf = false;
    }
  }

  //dragElement(document.getElementById("mydiv"));

 dragElement=(elmnt)=>{
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

}
