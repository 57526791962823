import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdvanceService {
  baseurl = environment.baseUrl;

  constructor(private http: HttpClient) { }
 
  public save(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'AdvanceTransaction/Save', data)
      .pipe(
        retry(1)
      );
  }
  public saveExistingAdvance(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'AdvanceTransaction/SaveExistingAdvance', data)
      .pipe(
        retry(1)
      );
  }
  public GetAdvanceList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'AdvanceTransaction/GetAll')
      .pipe(
        retry(1)
      );
  }

  public GetAllCustomer(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Contact/GetAllCustomer')
      .pipe(
        retry(1)
      );
  }

  public GetAllSupplier(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Contact/GetAllSupplier')
      .pipe(
        retry(1)
      );
  }

  public GetBankAccounts(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'BankAccount/GetBankAccounts')
      .pipe(
        retry(1)
      );
  }
  public GetExistingAdvance(id: number): Observable<any> {
    return this.http.get<any>(this.baseurl + 'AdvanceTransaction/GetExistingAdvanceBalance?id=' + id)
      .pipe(
        retry(1)
      );
  }
  public GetAdvanceTypeList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'AdvanceTransaction/GetAdvanceTypeList')
      .pipe(
        retry(1)
      );
  }

  public GetBalanceAsCustomer(cId: number): Observable<any> {
    return this.http.get<any>(this.baseurl + 'AdvanceTransaction/GetBalanceAsCustomer?contactId=' + cId)
      .pipe(
        retry(1)
      );
  }

  public GetBalanceAsSupplier(cId:number): Observable<any> {
    return this.http.get<any>(this.baseurl + 'AdvanceTransaction/GetBalanceAsSupplier?contactId='+cId)
      .pipe(
        retry(1)
      )
  }

  public InvoiceAdjustment(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'AdvanceTransaction/InvoiceAdjustment', data)
      .pipe(
        retry(1)
      );
  }
  public AdvanceAndInvoiceAdjustment(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'AdvanceTransaction/AdvanceAndInvoiceAdjustment', data)
      .pipe(
        retry(1)
      );
  }
  public AdvanceCreationAndBillAdjustment(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'AdvanceTransaction/AdvanceCreationAndBillAdjustment', data)
      .pipe(
        retry(1)
      );
  }
  public BillAdjustment(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'AdvanceTransaction/BillAdjustment', data)
      .pipe(
        retry(1)
      );
  }
  public SalesAdjustment(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'AdvanceTransaction/SalesAdjustment', data)
      .pipe(
        retry(1)
      );
  }

}
