import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AssignedItem } from 'src/app/models/assignee';
import { AssigneeService } from 'src/app/service/Assignee/assignee.service';

@Component({
  selector: 'app-assigned-item-details',
  templateUrl: './assigned-item-details.component.html',
  styleUrls: ['./assigned-item-details.component.css']
})
export class AssignedItemDetailsComponent implements OnInit {

  @Input() fromParent;
  @Input() parentStatus;

  public activeTabId: number = 1;
  itemsList: AssignedItem[];

  constructor(
    public activeModal: NgbActiveModal,
    public assigneeService: AssigneeService
  ) { }

  ngOnInit(): void {
    this.dateFormat();
    this.getUassignedItemListByFixedAssetItemId();
  }

  dateFormat() {
    this.fromParent.AssignedDate = moment(this.fromParent.AssignedDate).format("Do MMM YYYY");
    if(this.fromParent.EstimatedReturnDate == null) {
      this.fromParent.EstimatedReturnDate = '-';
    }
    else {
      this.fromParent.EstimatedReturnDate = moment(this.fromParent.EstimatedReturnDate).format("Do MMM YYYY");
    }
  }

  getUassignedItemListByFixedAssetItemId() {
    this.assigneeService.getUassignedItemListByFixedAssetItemId(this.fromParent.FixedAssetItemId).subscribe((x) => {
      this.itemsList = x;
    })
  }

  public setActivePanel=(v)=>{
    this.activeTabId=v;
  }

}
