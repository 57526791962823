<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/fixed-asset/setup/draft']">{{'FIXEDASSET.draft' |translate}} ({{totalDraft}})</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/fixed-asset/setup/registered']">{{'FIXEDASSET.registered' |translate}} ({{totalRegistered}})</a>
  </li>
  <li class="nav-item">
    <a class="nav-link active" [routerLink]="['/fixed-asset/setup/solddisposed']">{{'FIXEDASSET.soldDisposed' |translate}} ({{totalSold}})</a>
  </li>
</ul>

<div class="row">
      <table class="table" style="width: 100%;">
          <thead>
              <tr class="tableHeader">
                  <th scope="col-md-1" class="table-header-color text-align-left">Asset Name</th>
                  <th scope="col-md-1" class="table-header-color text-align-center">Asset Number</th>
                  <th scope="col-md-1" class="table-header-color text-align-center">Asset Type</th>
                  <th scope="col-md-2" class="table-header-color text-align-center">Invoice No</th>
                  <th scope="col-md-2" class="table-header-color text-align-center">Purchase Date</th>
                  <th scope="col-md-1" class="table-header-color text-align-center">Purchase Price</th>
                  <th scope="col-md-2" class="table-header-color text-align-center">Disposal Date</th>
                  <th scope="col-md-1" class="table-header-color text-align-center">Disposal Price</th>
                  <th scope="col-md-1" class="table-header-color text-align-center">Gain/Loss</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of disposalAssetList">
                  <td class="text-align-left">{{item.FixedAssetItem.AssetName}}</td>
                  <td class="text-align-center"><span class="item-link" style="color: #02aced; cursor: pointer;" (click)="DetailsAssetItem(item)">{{item.FixedAssetItem.AssetNumber}}</span> </td>
                  <td class="text-align-center">{{item.FixedAssetItem.FixedAssetType.AssetType}}</td>
                  <td class="text-align-center"><span class="item-link" style="color: #02aced; cursor: pointer;" (click)="GoToInvoice(item)">{{item.Invoice.InvoicceNo}}</span></td>
                  <td class="text-align-center">{{item.FixedAssetItem.PurchaseDate}}</td>
                  <td class="text-align-center">{{item.FixedAssetItem.PurchasePrice}}</td>
                  <td class="text-align-center">{{item.ActionDate}}</td>
                  <td class="text-align-center">{{item.SoldPrice}}</td>
                  <td class="text-align-center" *ngIf="item.GainOrLoss.toFixed(2) >= 0">{{item.GainOrLoss.toFixed(2)}}</td>
                  <td class="text-align-center" *ngIf="item.GainOrLoss.toFixed(2) < 0">({{ ((-1) * item.GainOrLoss.toFixed(2)) }})</td>
              </tr>
          </tbody>
      </table>
</div>