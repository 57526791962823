<div class="modal-header" style="top: 20px; display: flex; align-items: center;">
    <h4 *ngIf="itemId==0" class="modal-title" style="font-size: 17px; font-weight: 500; flex: 1;">Add New {{itemPageTitle}}</h4>
    <h4 *ngIf="itemId>0" class="modal-title" style="font-size: 17px; font-weight: 500;">Edit {{itemPageTitle}}</h4>
    <div style="display: flex; align-items: center;">
        <label class="checkboxstyle" *ngIf="itemForm.value.ItemTypeId === 2 || itemForm.value.ItemTypeId === 3">
            <input type="checkbox" [(ngModel)]="isBuyChecked"> Buy </label>
        <label class="checkboxstyle" *ngIf="itemForm.value.ItemTypeId === 2 || itemForm.value.ItemTypeId === 3">
            <input type="checkbox" [(ngModel)]="isSaleChecked"> Sale </label>
        <label class="checkboxstyle" *ngIf="itemForm.value.ItemTypeId === 3">
            <input type="checkbox" [(ngModel)]="isManufactureChecked"> Manufacture </label>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')" style="margin-left: 10px;">
            <span aria-hidden="true" style="font-size: 25px">&times;</span>
        </button>
    </div>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="itemForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6">
                                <label for="ItemCode" style="font-weight: 600;">Item code <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control form-input" formControlName="ItemCode" (change)="copyItemCode($event)" required>
                            </div>
                            <div class="form-group  col-sm-6" *ngIf="itemForm.value.ItemTypeId === 1 || isBuyChecked">
                                <label for="PurchasePrice" style="font-weight: 600;">Purchase Price/Unit <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control form-input" appDecimaNumericValidator formControlName="PurchasePrice">
                            </div>
                            <div class="form-group col-sm-6" *ngIf="(isSaleChecked && !isBuyChecked) || itemForm.value.ItemTypeId == 5">
                                <label for="SaleUnitPrice" style="font-weight: 600;">Sales Price/Unit <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control" appDecimaNumericValidator style="width: 99%;" formControlName="SaleUnitPrice">
                            </div>
                            <div class="form-group  col-sm-6" *ngIf="itemForm.value.ItemTypeId !== 1 && itemForm.value.ItemTypeId != 5 && !isSaleChecked && !isBuyChecked">
                                <label for="Description" style="font-weight: 600;">Description <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control form-input" formControlName="Description" required>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group col-sm-6">
                                <label for="ItemName" style="font-weight: 600;">Item Name <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control" style="width: 99%;" formControlName="ItemName" required>
                            </div>
                            <div class="form-group col-sm-6" *ngIf="itemForm.value.ItemTypeId === 1 || (isSaleChecked && isBuyChecked)">
                                <label for="SaleUnitPrice" style="font-weight: 600;">Sales Price/Unit <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control" appDecimaNumericValidator style="width: 99%;" formControlName="SaleUnitPrice">
                            </div>
                            <div class="form-group  col-sm-6 " *ngIf="(isSaleChecked && !isBuyChecked) || itemForm.value.ItemTypeId == 5">
                                <label for="Description" style="font-weight: 600;">Description <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control form-input" formControlName="Description" required>
                            </div>
                            <div class="form-group  col-sm-6" *ngIf="isBuyChecked && !isSaleChecked">
                                <label for="SupplierId" style="font-weight: 600;">Select Supplier <span class="required-sign">*</span>
                                </label>
                                <ng-select [items]="supplierList" [clearable]="false" bindLabel="CompanyName" bindValue="Id" [addTag]="CreateNewSupplier" [loading]="loading" formControlName="SupplierId">
                                    <ng-template ng-tag-tmp let-search="searchTerm">
                                        <b>+ Add New Supplier</b>: {{search}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-6" *ngIf="itemForm.value.ItemTypeId === 1 || (!isSaleChecked && isBuyChecked)">
                                <label for="Description" style="font-weight: 600;">Description <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control form-input" formControlName="Description" required>
                            </div>
                            <div class="form-group  col-sm-6" *ngIf="itemForm.value.ItemTypeId !== 1 && isSaleChecked && isBuyChecked">
                                <label for="Description" style="font-weight: 600;">Description <span class="required-sign">*</span>
                                </label>
                                <input type="text" class="form-control form-input" formControlName="Description" required>
                            </div>
                            <div class="form-group  col-sm-6" *ngIf="itemForm.value.ItemTypeId === 1 || (isBuyChecked && isSaleChecked)">
                                <label for="SupplierId" style="font-weight: 600;">Select Supplier <span class="required-sign">*</span>
                                </label>
                                <ng-select [items]="supplierList" [clearable]="false" bindLabel="CompanyName" bindValue="Id" [addTag]="CreateNewSupplier" [loading]="loading" formControlName="SupplierId">
                                    <ng-template ng-tag-tmp let-search="searchTerm">
                                        <b>+ Add New Supplier</b>: {{search}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-12">
                <button type="button" style="float: right;margin-right: 14px;" (click)="saveOrUpdate()" class="eCount-def-btn  ml-4">Save</button>
            </div>
        </div>
    </div>
</div>