<app-dashboard>
    <app-page-header-title
        mainTitle="{{contactForm.value.IsDeliveryPartner == true ? 'Edit Delivery Partner' : 'Contacts.editContact'| translate}}"
        subTitle="{{'Contacts.contactList.allContacts'| translate}}"
        backURL="contacts/list/all">
        <div class="full-width text-align-right">
        <button type="button" (click)="SaveContactInfo()" class="eCount-def-btn ml-4">{{'Contacts.CreateContact.save' | translate}}</button>
        </div>
    </app-page-header-title>
    <div class="pt-4 row">
        <form [formGroup]="contactForm" class="d-flex w-100">
            <div class="col-6 f-ss-14px">
                
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Contacts.CreateContact.companyName'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="CompanyName" placeholder="Company Name"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Contacts.contactList.name'| translate}}<span class="redstr">*</span></span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="PrimaryPersonFirstName" placeholder="Name"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Contacts.CreateContact.email'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="Email" placeholder="Email"  class="form-control inputSection" />
                    </div>
                </div>
               
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Contacts.CreateContact.website'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <input type="text" formControlName="Website" placeholder="Website"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Contacts.CreateContact.iscustomer'| translate }}</span>
                    </div>
                    <div class="col-8">
                        <label class="switch">
                            <input name="IsCustomer" formControlName="IsCustomer" class="switch-input"
                                type="checkbox" />
                            <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                </div> 

            </div>
            <div class="col-6 f-ss-14px">
            
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Contacts.CreateContact.phone'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <input type="text" (keypress)="numberOnly($event)" formControlName="ContactNo" placeholder="Phone"  class="form-control inputSection" />
                    </div>
                </div>
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Contacts.CreateContact.address'| translate}}</span>
                    </div>
                    <div class="col-8">
                        <div>
                            <textarea class="form-control" formControlName="StreetAddress" rows="2" style="height: 145px;"></textarea>
                        </div>
                    </div>
                </div>
 
                <div class="form-group d-flex">
                    <div class="col-4">
                        <span >{{'Contacts.CreateContact.issupplier'| translate }}</span>
                    </div>
                    <div class="col-8">
                        <label class="switch">
                            <input name="IsSupplier" formControlName="IsSupplier" class="switch-input"
                                type="checkbox" />
                            <span class="switch-label" data-on="Active" data-off="Inactive"></span>
                            <span class="switch-handle"></span>
                        </label>
                    </div>
                </div> 
            </div>
        </form>
    </div>
    <div>
        <p class="font-weight-bold ml-4">Additional Contact</p>
    <hr>
    <div class="row d-block topSection f-ss-14px">
        <div class="d-flex col-sm-10" style="border-bottom:1px solid rgba(0,0,0,.1);">
            
            <div class="col-sm-3">
                Contact no.
            </div>
            <div class="col-sm-3">
               Email
            </div>
            <div class="col-sm-6">
                Notes
             </div>
        </div>
        <div *ngFor="let item of ContactItem; let i=index" class="col-sm-10">
            <div class="invoiceGridItemRow d-block ml-1"
                [ngClass]="[selected ? 'selectedRow' : 'notSelectedRow']">
                <div class="d-flex">

                    <div class="col-sm-3 borderLeft ">
                        <textarea class="form-control"
                            style="resize: none;margin: 5px; min-height: 20px; max-height: 340px;" (keypress)="numberOnly($event)"
                            type="text" [(ngModel)]="ContactItem[i].Phone"> </textarea>
                    </div>
              

                    <div class="col-sm-3 borderLeft ">
                        <input class="form-control" type="text"
                            [(ngModel)]="ContactItem[i].Email">
                    </div>
                    <div class="col-sm-6 borderLeft ">
                        <input class="form-control" type="text"
                            [(ngModel)]="ContactItem[i].Notes">
                    </div>
                    <div style="padding: 7px 10px; color: #c7c2c2;">
                        <div class="dropdown mr-4">
                            <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                aria-expanded="false">
                                <i class="icon-more float-right mt-4"></i>
                            </div>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                style="width: 100%;">
                                <div class="productRow pb-3" (click)="removeContactItem(i)">
                                    <span class="lead1">Remove Item </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
   </div>
</div>
<div class="row addItem pb-4" >
    <div class="BottomAddItem ml-4 wd">
        <div class="dropdown">
            <a class="nav-link" href="#" id="navbarDropdown" style="background-color: white;" role="button" data-toggle="dropdown"
                aria-haspopup="false" aria-expanded="false" (click)="createBlankItem()">
                <img class="languageImg" src="../../../assets/image/addIcon.png" /> Add New Line
            </a>
        </div>
    </div>
</div>
</app-dashboard>
