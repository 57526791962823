import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { BusinessPartnerLoanService } from 'src/app/service/Business-partner-loan/business-partner-loan.service'; 
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BpLoanCreateComponent } from '../bsuiness-partner-loan-create/bp-loan-create/bp-loan-create.component';
import { bufferToggle } from 'rxjs/operators';
import { LoanStatus } from 'src/app/enum/loan-status';
@Component({
  selector: 'app-grant-loan-list',
  templateUrl: './grant-loan-list.component.html',
  styleUrls: ['./grant-loan-list.component.css']
})
export class GrantLoanListComponent implements OnInit {

  constructor(
    private mainService: BusinessPartnerLoanService,
    public fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private activeRouter: ActivatedRoute,
    private ngxSmartModalService: NgxSmartModalService,
    private spinner: NgxSpinnerService) { }
  activeCount = 0;
  closeCount = 0;
  draftCount = 0;
  awaitingApprovalCount = 0;
  rejectedCount = 0;
  grantDataList: any;
  grantData: any;
  private gridApi;
  private gridColumnApi;
  contactStatus: any = {
    "All": 0,
    "Draft":1,
    "AwaitingApproval":2,
    "active": 3,
    "closed": 4,
    "Rejected": 5
  };

  columnDefs = [
    { headerName: 'Loan No', field: 'LoanNo', cellStyle : {color: 'blue', cursor: 'pointer'} },
    { headerName: 'Business Partner', field: 'BPName' },
    { headerName: 'Date', field: 'FormattedDate' },
    { headerName: 'Amount', field: 'Amount' },
    { headerName: 'Interest Amount', field: 'InterestAmount'},
    { headerName: 'Loan Terms', field: 'LoanPeriod'},
    { headerName: 'Total', field: 'TotalAmount' },
    { headerName: 'Settlement', field: 'SettleAmount'},
    { headerName: 'Next Settlement', field: 'NextSettleDate'},
    { headerName: 'Balance', field: 'Balance'},
  ];


  domLayout = 'autoHeight';

  defaultColDef = {
    resizable: true,
    flex: 1,
    cellClass: 'cell-wrap-text',
  };
  ngOnInit(): void {
    //const type = this.activeRouter.snapshot.paramMap.get('type');
    this.activeRouter.params.subscribe(params => {
      this.getData(params.type);
    });
    //this.getData(type);
  }

  getData = (status: string) => {

    this.grantData = [] ;

    this.mainService.GetGrantLoan(1).subscribe((x) => {

      this.spinner.hide();
      this.grantDataList = x;
      //this.grantData = [...x, ...this.grantData];
      this.resetCount();
      this.grantData = x;
      this.grantData.map(c => {
        if(c.Status == LoanStatus.Draft) this.draftCount += 1;  
        if(c.Status == LoanStatus.AwaitingApproval) this.awaitingApprovalCount += 1;
        if(c.Status == LoanStatus.Rejected) this.rejectedCount += 1;  
        if(c.Status == LoanStatus.Active) this.activeCount += 1; 
        if(c.IsSettle) this.closeCount += 1;
      });
      this.reLoadRowData(status);
    }, (err) => {
      this.spinner.hide();
    });
  }

  onGridReady(event) {
    this.gridApi = event.api;
    this.gridApi.setHeaderHeight(35);
    this.gridColumnApi = event.columnApi;

  }

  resetCount(){
    this.activeCount = 0;
    this.closeCount = 0;
    this.draftCount = 0;
    this.awaitingApprovalCount = 0;
    this.rejectedCount = 0;
  }

  reLoadRowData(status)
  {
    //this.grantData = this.contactStatus[status] === this.contactStatus.All ?
    //this.grantDataList : this.contactStatus[status] === this.contactStatus.active ?
    //this.grantDataList.filter(sup => sup.IsSettle === false) : this.grantDataList.filter(con => con.IsSettle === true);
    
    let statusNo = this.contactStatus[status];
    switch(statusNo){
      case this.contactStatus.All:
        this.grantData = this.grantDataList;
        break;
      case this.contactStatus.Draft:
        this.grantData = this.grantDataList.filter( x => x.Status == LoanStatus.Draft );
        break;
      case this.contactStatus.AwaitingApproval:
        this.grantData = this.grantDataList.filter( x => x.Status == LoanStatus.AwaitingApproval );
        break;
      case this.contactStatus.Rejected:
        this.grantData = this.grantDataList.filter( x => x.Status == LoanStatus.Rejected );
        break;
      case this.contactStatus.active:
        this.grantData = this.grantDataList.filter( x => x.Status == LoanStatus.Active );
        break;
      case this.contactStatus.closed:
        this.grantData = this.grantDataList.filter( x => x.IsSettle == true );
        break;
    }
  }

  onRowClicked(e) {
    const colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName === 'Loan No') {
       this.router.navigate([`business-partner/view-bp-loan/${e.data.Id}`]);
    }

  }

  public createNewItem = () => {
    const modalRef = this.modalService.open(BpLoanCreateComponent,
      { size: 'lg', backdrop: 'static', keyboard : false, centered: true });
    modalRef.componentInstance.fromParent =  1;
    modalRef.result.then((result) => 
    {
      if (result.Success){
        this.getData('All');
      }
    }, (reason) => 
    {
      console.log('Dismissed action: ' + reason);
    });
  }
  
}
