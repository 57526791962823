import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditBasicBillComponent } from '../edit-basic-bill/edit-basic-bill.component';
import { BasicBillCreateComponent } from '../basic-bill-create/basic-bill-create.component';
import { CreateBasicBill } from '../../../models/income.model';
import { BillsServiceService } from '../../../service/Bill/bills-service.service';


@Component({
  selector: 'app-basic-bill-list',
  templateUrl: './basic-bill-list.component.html',
  styleUrls: ['./basic-bill-list.component.css']
})
export class BasicBillListComponent implements OnInit {
  public aModel : CreateBasicBill= new CreateBasicBill();
  categoryList: any = [];
  tempList: any = [];
  categoryId: number=0;
  show = true;
  hide = false;
  billList:any=[];
  repeatingTypeList:any=[
    {Id:"1",Name:"Daily"},
    {Id:"2",Name:"Weekly"},
    {Id:"3",Name:"Monthly"},
    {Id:"4",Name:"Yearly"}
  ];
  constructor(private modalService:NgbModal,
    private billService: BillsServiceService) { }

  ngOnInit(): void {
    this.tempList=[
      {id:1,title:"New",imagePath:"../../../../assets/image/basic_bill/image/new.png"},
      {id:13,title:"Office Expense",imagePath:"../../../../assets/image/basic_bill/image/officeexpenses.png"},
      {id:10,title:"Printing Stationary",imagePath:"../../../../assets/image/basic_bill/image/printingstationary.png"},
      {id:2,title:"Entertainment",imagePath:"../../../../assets/image/basic_bill/image/entertainment.png"},
      {id:6,title:"Motor Vehicle",imagePath:"../../../../assets/image/basic_bill/image/motorvehicles.png"},
      {id:7,title:"Travel National",imagePath:"../../../../assets/image/basic_bill/image/travelnational.png"},
      {id:16,title:"Repair Maintenance",imagePath:"../../../../assets/image/basic_bill/image/repairmaintenance.png"},
      {id:14,title:"Subscription",imagePath:"../../../../assets/image/basic_bill/image/subscription.png"},
      {id:3,title:"Salary",imagePath:"../../../../assets/image/basic_bill/image/salary.png"},
      {id:4,title:"Electricity",imagePath:"../../../../assets/image/basic_bill/image/electricity.png"},
      {id:5,title:"Internet",imagePath:"../../../../assets/image/basic_bill/image/internet.png"},
      {id:8,title:"Income Tax",imagePath:"../../../../assets/image/basic_bill/image/incometax.png"},
      {id:9,title:"Bank Fees",imagePath:"../../../../assets/image/basic_bill/image/bankfees.png"},
      {id:15,title:"Legal Expense",imagePath:"../../../../assets/image/basic_bill/image/legalexpense.png"},
      {id:12,title:"Insurance",imagePath:"../../../../assets/image/basic_bill/image/insurance.png"},
      {id:11,title:"Freight And Courier",imagePath:"../../../../assets/image/basic_bill/image/freight&courier.png"},
      {id:17,title:"Cleaning",imagePath:"../../../../assets/image/basic_bill/image/cleaning.png"},
      {id:18,title:"Rent",imagePath:"../../../../assets/image/basic_bill/image/rent.png"}
    ];

    this.categoryList = this.tempList.slice(0, 8);
    this.getBillList();
  }

  /**
   * getBillList
   */
  public getBillList() {
    this.billService.GetBasicBills().subscribe((x) => {
        this.billList = x;
        for(let i=0;i<this.billList.length;i++){
          this.billList[i].BillNo = 'Bill-' + this.billList[i].BillNo;
        }
        console.log(this.billList);
    });
  }

  /**
   * openCreateBasicBill
catId:number   */
  public openCreateBasicBill(item:any) {
    const modalRef = this.modalService.open(BasicBillCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered: true});
    console.log("Hello Modal==>",item)
   // this.aModel.BillDate=new Date(this.getFormattedDate());
    this.aModel=item;

    this.aModel.CategoryId=item.id;
    this.aModel.Description= item.title+" Bill"
    modalRef.componentInstance.fromParent = this.aModel;

    modalRef.result.then((result) => {
      if(result.Success){
        this.getBillList();
      }

                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }

   getFormattedDate() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var d=firstDay.toLocaleDateString('en-GB', {year: 'numeric'})+ "-" + firstDay.toLocaleDateString('en-GB', { month: '2-digit'}) + "-" + firstDay.toLocaleDateString('en-GB', { day: '2-digit'});
    return d;
  }

  openPopUp = (item) => {

    var list = this.tempList.filter(x=> x.title==item.Description);

    if (list.length > 0) {
      this.categoryId = list[0].id;
    } else {
      this.categoryId = 1;
    }

    this.openBasicBill(item);
  }
  showTemp(value)
  {
    if(value === 1)
    {
      this.categoryList = this.tempList;
      this.show = !this.show;
      this.hide = !this.hide;
    } else {
      this.categoryList = this.tempList.slice(0, 8);
      this.show = !this.show;
      this.hide = !this.hide;
    }

  }
  getStatus(id) {
    switch (id) {
      case 1:
        return 'Draft';
      case 2:
        return 'Awaiting Approval';
      case 3:
        return 'Approved';
      case 4:
        return 'Paid';
    }
  }
  public openBasicBill(item:any) {
    const modalRef = this.modalService.open(EditBasicBillComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered: true});
    this.aModel = item;
    this.aModel.CategoryId = this.categoryId;
    modalRef.componentInstance.fromParent = this.aModel;
    modalRef.result.then((result) => {
      if(result.Success){
        this.getBillList();
      }

                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }





  getName = (id) => {

    const name = this.repeatingTypeList.filter(x => x.Id == id);
    return name.length > 0 ? name[0].Name.toString() : '';

  }

}
