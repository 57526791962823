import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { customer } from './../../models/customer.model';
import { createReceiveMoney } from '../../models/receiveMoney.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReceivemoneyServiceService {

  baseurl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  //get saleable Item
  public GetPurchaseableItems(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetPurchaseableItems').pipe(retry(1));
  }

  //ReceiveMoney/Get Receive Money Type
  public GetReceiveMoneyTypeList(): Observable<customer[]> {
    return this.http.get<any>(this.baseurl + 'ReceiveMoney/GetReceiveMoneyTypeList').pipe(retry(1));
  }

  //save create invoice
  public saveReceiveMoneyData(data:any): Observable<any> {
    return this.http.post<createReceiveMoney>(this.baseurl + 'ReceiveMoney/SaveDraft', data).pipe(retry(1));
  }

  //update draft create invoice
  public updateReceiveMoneyDataDraft(data:any): Observable<any> {
    return this.http.post<createReceiveMoney>(this.baseurl + 'ReceiveMoney/UpdateDraft', data).pipe(retry(1));
  }

  //update and save edited data invoice
  updateAndSaveEditedData(data): Observable<any> {
    return this.http.post<createReceiveMoney>(this.baseurl + 'ReceiveMoney/UpdateAndSaveReceiveMoney', data)
      .pipe(
        retry(1)
      )
  }

 //get receive money list
 public GetReceiveMoneyList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'ReceiveMoney/GetList').pipe(retry(1));
  }

  //get receive money details
  public GetReceiveMoneyDetails(id): Observable<any> {
    return this.http.get<any>(this.baseurl + `ReceiveMoney/GetReceiveMoney?id=${id}`).pipe(retry(1));
  }

  //submit receive money invoice
  public submitReceiveMoney(id): Observable<any> {
    return this.http.get<any>(this.baseurl + `ReceiveMoney/Save?id=${id}`).pipe(retry(1));
  }

  //submit receive money invoice
  public deleteReceiveMoney(id): Observable<any> {
    return this.http.get<any>(this.baseurl + `ReceiveMoney/DeleteReceiveMoney?id=${id}`).pipe(retry(1));
  }
}
