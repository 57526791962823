import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { IncomeServiceService } from "../../../service/Income/income-service.service";
import { NgxSmartModalService } from "ngx-smart-modal";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  FormGroupName,
} from "@angular/forms";
import { AcountingServiceService } from "../../../service/Accounting/acounting-service.service";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import jspdf from "jspdf";
import * as html2canvas from "html2canvas";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SendInvoiceMailComponent } from "../send-invoice-mail/send-invoice-mail.component";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";
import { FileEmitterService } from "src/app/service/EventEmitter/file.emitter.service";
import { InvoiceAdvanceAdjustmentComponent } from "../invoice-advance-adjustment/invoice-advance-adjustment.component";
import { AlertService } from "../../../service/Alert/alert.service";
import { RejectPopupModalComponent } from "../../Reject-popup-modal/reject-popup-modal/reject-popup-modal.component";
import { InventoryOverviewService } from "src/app/service/InventoryOverview/inventory-overview.service";
import { ItemType } from "src/app/enum/item-type";
import { iQuidiActionType } from "src/app/enum/iquidiActionType";
import { WareHouseOperationType } from "src/app/enum/wareHouseOperationType";
@Component({
  selector: "app-invoice-details",
  templateUrl: "./invoice-details.component.html",
  styleUrls: ["./invoice-details.component.css"],
  host: {
    "(document:click)": "onWindowClick($event)",
  },
})
export class InvoiceDetailsComponent implements OnInit {
  allAttachment: any = [];
  invoiceFound = false;
  AcceptPaymentForm: FormGroup;
  AddDiscountFrom: FormGroup;
  AcceptDPPaymentForm: FormGroup;
  InvoiceNo: number;
  totalFile: number;
  itemFullAmount = 0;
  totalDeliveryAmount = 0;
  totalVat = 0;
  totalDiscountParcent = 0;
  InvoiceId: string;
  totalItemDiscountAmount = 0;
  accountTypeId = 0;
  deliveryPartnerPaymentList = [];
  isAcceptDeliveryPartner = false;
  InvoiceData: any = [];
  chartOfAccount: any = [];
  toBeAccept: number = 0;
  acceptPayment: any = [];
  totalPaidAmount: number = 0;
  isPaymentBtnVisible: boolean = false;
  isRejectBtnVisible: boolean = false;
  allSaleableItem: any = [];
  allInvoice: any = [];
  PaymentModeList: any[];
  baseurl = environment.baseUrl;
  baseImageUrl = environment.fileBaseUrl;
  selectedItem: any;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  percentDone: number;
  bankAccounts: any;
  isEditable: boolean = false;
  canApprove: boolean = false;
  discountShow: boolean = false;
  lessPaymentShow: boolean = false;
  PostingModuleId: number = null;
  ExternalTransactionId: number = null;
  IsRedirect: boolean = false;
  redirectUrl: string;
  taxRateList: any = [];
  IsSubmitable = false;
  totalDue: number;
  allDeliveryPartner: any;
  ifSubmit: boolean;
  RepeatingTypeId: any;
  IsRepeating: boolean;
  referenceNo: any;
  IsUserAtMaxApprovalLevel: boolean = false;
  isInAwaitingPaymentPhase: boolean = false;
  stockAvailable: boolean = true;
  canApproveBool: boolean = true;
  isStockAvailable: boolean = false;
  productList: any = [];
  invoiceNumber: string;
  deliveryData: number;
  salesOrderNo: string;
  isShipped: boolean = false;
  repeatingTypeList: any = [
    { Id: 1, Name: "Daily" },
    { Id: 2, Name: "Weekly" },
    { Id: 3, Name: "Monthly" },
    { Id: 4, Name: "Yearly" },
  ];

  isAllItemsDescriptiveOrService: boolean = false;
  returnPayment: any = [];
  totalReturnedAmount: number = 0;
  deliveryPartnerPaymentReturnList = [];
  lastPaymentHistory: any;
  hasReturnWareHouseData: boolean = false;

  constructor(
    private _eref: ElementRef,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private IncomeService: IncomeServiceService,
    private ngxSmartModalService: NgxSmartModalService,
    public fb: FormBuilder,
    private accountingService: AcountingServiceService,
    private toaster: ToastrService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private settingService: SettingsServiceService,
    private fileEmitterService: FileEmitterService,
    private router: Router,
    private inventoryOverviewService: InventoryOverviewService
  ) {}

  ngOnInit(): void {
    let tempData = this.route.snapshot.paramMap.get("invoiceId");
    this.salesOrderNo = this.route.snapshot.paramMap.get("orderNo");
    this.InvoiceId = "";
    for (let i = 4; i < tempData.length; i++) this.InvoiceId += tempData[i];
    this.getInvoiceData(this.InvoiceId);
    this.getAllSaleableItem();
    this.getTaxRateList();
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;
    });
    this.getBanks();
    this.initMakePayment();
    this.initDpMakePayment();
    this.initDiscountFrom();
    this.route.queryParams.subscribe((params) => {
      this.IsRedirect = params.IsRedirect == undefined ? false : true;
      this.setRedirectUrl();
    });
    this.IncomeService.GetIsUserAtMaxApprovalLevel().subscribe((x) => {
      this.IsUserAtMaxApprovalLevel = x.IsUserAtMaxApprovalLevel;
    });
  }

  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  };
  private getDeliveryPartner = () => {
    this.IncomeService.GetAllDeliveryPartner().subscribe((x: any) => {
      this.allDeliveryPartner = x.filter((xy) =>xy.Id == this.InvoiceData.DeliveryPartnerItems[0].DeliveryPartnerId);
      this.AcceptDPPaymentForm.get("ContactId").patchValue(this.allDeliveryPartner[0].Id);
    });
  };

  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }

  getAccountName = (item) => {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;
      item.forEach((element) => {
        console.log(element.AccountId);
        var x = this.chartOfAccount.find((p) => p.Id === element.AccountId);
        element.Account = x.AccountName;
      });
    });
    return item;
  };

  getInvoiceData = (id: any) => {
    this.spinner.show();
    this.IncomeService.GetInvoicePermissionByInvoiceNoForInvoiceOverview(id).subscribe((x) => {
      if (x) {
        this.InvoiceData = x.data;
        this.lastPaymentHistory = x.lastPaymentHistory;
        this.invoiceNumber = this.InvoiceData.InvoicceNo;
        this.salesOrderNo = this.InvoiceData.OrderNo;
        this.productList = x.itemListInfo;
        this.canApprove = x.canApprove;
        this.invoiceFound = true;
        this.InvoiceNo = this.InvoiceData.Id;
        this.referenceNo = this.InvoiceData.OrderNo;
        this.PostingModuleId = this.InvoiceData.PostingModuleId;
        this.ExternalTransactionId = this.InvoiceData.ExternalTransactionId;

        this.itemFullAmount = this.InvoiceData.InvoiceItems.reduce( (prev, next) => prev + next.UnitPrice * next.Quantity, 0);
        this.totalDiscountParcent = this.InvoiceData.InvoiceItems.reduce( (prev, next) => prev + next.DiscountPercent, 0);
        this.totalItemDiscountAmount = this.InvoiceData.InvoiceItems.reduce( (prev, next) => prev + (next.UnitPrice * next.Quantity - next.LineTotal), 0);
        this.totalDeliveryAmount = this.InvoiceData.DeliveryPartnerItems.reduce( (prev, next) => prev + next.UnitPrice, 0);

        this.totalVat = this.InvoiceData.TaxTypeId == 1 ? this.InvoiceData.TaxAmount + this.InvoiceData.AdvanceTaxAmount : 0.0;
        this.isPaymentBtnVisible = this.InvoiceData.StatusId == 3 ? true : false;
        this.isRejectBtnVisible = this.InvoiceData.StatusId == 4 ? false : true;
        this.InvoiceData.ShippedItems.forEach((item) => { this.getShippedData(item) });
        this.fileEmitterService.emitFileRefIdUpdate({ id: this.InvoiceData.Id });
        if(!this.InvoiceData.IsReverseTransaction){
          this.GetInvoicePaymentReturnList(this.InvoiceData.Id);
        }
        this.GetInvoicePaymentList(this.InvoiceData.Id);
        this.getAccountName(this.InvoiceData.InvoiceItems);
        this.getDeliveryData(this.invoiceNumber);

        this.formatInvoiceItems();
        this.IsInvoiceEditable();
        this.setRedirectUrl();
        this.isDescriptiveInvoice();
        this.getTotalDue();
        this.spinner.hide();
      }
    });
  }

  formatInvoiceItems() {
    this.InvoiceData.InvoiceItems.forEach((element) => {
      let value = this.productList.find((e) => e.Id == element.ItemId);
      if(value == null || value.ItemTypeId == ItemType.Service) {
        element.ItemTypeId = value == null ? null : value.ItemTypeId;
        element.isDescriptiveOrServiceItem = true;
        element.isStockAvailable = true;
      }
      else {
        element.ItemTypeId = value.ItemTypeId;
        element.isDescriptiveOrServiceItem = false;
        if (value.StockQuantity >= element.Quantity) 
        {
          element.isStockAvailable = true;
        } else {
          element.isStockAvailable = false;
        }
      }
    });
    this.isAllItemsDescriptiveOrService = this.IsAllItemsDescriptiveOrService();
  }

  isDescriptiveInvoice() {
    return !(this.InvoiceData?.InvoiceItems?.some( x => x.ItemId != null ) ?? false);
  }  

  getShippedData(ShippedItems){
    if(ShippedItems != 0) {
      this.isShipped = true;
    }
  }

  getDeliveryData(invoiceNumber){
    this.inventoryOverviewService.GetDeliveryOrderByInvoiceNo(invoiceNumber).subscribe((x) => {
      this.deliveryData = x.length;
      this.hasReturnWareHouseData = x?.some(x => x.OperationType == WareHouseOperationType.SoldItemReturnedForInvoice);

    });
  }

  public setRedirectUrl() {
    let rUrl = "income/invoice-overview/All";
    this.route.queryParams.subscribe((params) => {
      this.IsRedirect = params.IsRedirect == undefined ? false : true;
      if (this.PostingModuleId == 2 && this.IsRedirect) {
        //here 2 means project module
        rUrl = "project/details/" + this.ExternalTransactionId;
      }
      this.redirectUrl = rUrl;
    });
  }

  IsInvoiceEditable() {
    this.IsUserAtMaxApprovalLevel = this.InvoiceData.IsUserAtMaxApprovalLevel;
    this.isInAwaitingPaymentPhase = this.InvoiceData.StatusId == 3;
    this.isEditable =
      this.InvoiceData.PaidAmount == null &&
      (this.InvoiceData.StatusId === 1 ||
        this.InvoiceData.StatusId === 7 ||
        this.InvoiceData.StatusId === 3) &&
      this.InvoiceData.IsUserAtMaxApprovalLevel == true
        ? true
        : false;
    this.IsSubmitable =
      this.InvoiceData.StatusId === 1 || this.InvoiceData.StatusId === 7
        ? true
        : false;
    console.log("this is isSubmitable ", this.IsSubmitable);
  }
  submitInvoiceData = () => {
    //this.InvoiceData.StatusId = this.InvoiceData.IsActiveDeliveryPartner == true ? 3 : 2;
    this.InvoiceData.StatusId = 2;
    this.spinner.show();
    this.InvoiceData.IQuidiActionType = iQuidiActionType.Submit;
    this.IncomeService.StatusUpdate(this.InvoiceData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success(`Invoice ${this.InvoiceData.InvoicceNo} has been successfully submitted`,"Success!");
          this.router.navigate([this.redirectUrl]);
          this.spinner.hide();
        } else {
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      }
    );
  };
  approvedInvoiceData() {
    this.spinner.show();
    this.IncomeService.updateApproveInvoiceData(this.InvoiceData).subscribe(
      (x) => {
        if (x.Success) {
          this.toaster.success(
            `Invoice ${this.InvoiceData.InvoicceNo} has been successfully approved`,
            "Success!"
          );
          if (x.HasAdvance) {
            this.openAdvanceAdjustmentPopup(x);
          } else {
            this.router.navigate([this.redirectUrl]);
          }
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toaster.warning(x.Message);
        }
      }
    );
  }

  ApproveSingleInvoice() {
    let hasStockUnavailableItem: boolean = this.InvoiceData.InvoiceItems.filter(x => x.isStockAvailable == false).length != 0 ? true : false;
    if (!hasStockUnavailableItem) {
      this.spinner.show();
      this.InvoiceData.IQuidiActionType = iQuidiActionType.Approve;
      this.IncomeService.StatusUpdate(this.InvoiceData).subscribe((x) => {
        if (x.Success) {
          this.toaster.success(`Invoice ${this.InvoiceData.InvoicceNo} has been successfully approved`, "Success!");
          if (x.HasAdvance) {
            this.openAdvanceAdjustmentPopup(x);
          } else {
            this.router.navigate([this.redirectUrl]);
          }
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.toaster.warning(x.Message);
        }
      });
    } else {
      this.toaster.warning("Item out of stock. Please create Purchase Order first!!");
      return;
    } 
  }

  confirmInvoiceRejection() {
    const modalRef = this.modalService.open(RejectPopupModalComponent, {
      size: "md",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });

    modalRef.componentInstance.data = this.InvoiceData;
    modalRef.componentInstance.TypeId = 2;
    modalRef.result.then(
      (result) => {
        if (result == true) {
          this.getInvoiceData(this.InvoiceId);
        }
      },
      (reason) => {
        console.log("Dismissed action: " + reason);
      }
    );
  }
  private openAdvanceAdjustmentPopup(x: any) {
    const modalRef = this.modalService.open(InvoiceAdvanceAdjustmentComponent, {
      size: "md",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.fromParent = x;
    modalRef.componentInstance.bankAccounts = this.bankAccounts;
    modalRef.result.then(
      (result) => {
        if (result.Success) {
          this.getInvoiceData(this.InvoiceId);
        }
      },
      (reason) => {
        console.log("Dismissed action: " + reason);
      }
    );
  }

  /**
   * makeAdjustment
   */
  public makeAdjustment() {
    console.log(this.InvoiceData);
    this.spinner.show();
    this.IncomeService.getCustomerBalance(this.InvoiceData).subscribe((x) => {
      this.openAdvanceAdjustmentPopup(x.BalanceData);
      this.spinner.hide();
    });
  }

  initMakePayment = () => {
    this.AcceptPaymentForm = this.fb.group({
      InvoiceId: [null],
      PaidDate: ["", Validators.required],
      ReferenceNo: [""],
      Amount: [null, Validators.required],
      AccountId: [null, Validators.required],
      PaymentModeId: [""],
      SourceBankName: [""],
      SourceAccountName: [""],
      SourceAccountNo: [""],
      IsDeliveryPartner: [false],
    });
    this.AcceptPaymentForm.get("PaidDate").patchValue(new Date());
  };
  initDpMakePayment = () => {
    this.AcceptDPPaymentForm = this.fb.group({
      InvoiceId: [null],
      PaidDate: ["", Validators.required],
      ReferenceNo: [""],
      Amount: [null, Validators.required],
      ContactId: [null],
      IsDeliveryPartner: [true],
    });
    this.AcceptDPPaymentForm.get("PaidDate").patchValue(new Date());
    this.AcceptDPPaymentForm.get("ContactId").disable();
  };
  initDiscountFrom = () => {
    this.AddDiscountFrom = this.fb.group({
      AmountDue: [],
      DiscountAmount: ["", Validators.required],
      TotalAmount: [],
      AdditionalDiscountAmount: [""],
    });
  };
  DecreaseTotalAmount = () => {
    if (
      this.AddDiscountFrom.value.AdditionalDiscountAmount >
      this.AddDiscountFrom.value.AmountDue
    ) {
      this.toaster.warning("Discount Amount can not exceed from Amount due");
      this.AddDiscountFrom.get("AdditionalDiscountAmount").patchValue("");
      this.AddDiscountFrom.get("TotalAmount").patchValue("");
      return;
    }
    const value =
      this.InvoiceData.DiscountAmount === null
        ? this.AddDiscountFrom.value.AdditionalDiscountAmount
        : this.AddDiscountFrom.value.DiscountAmount;
    const result = this.AddDiscountFrom.value.AmountDue - value;
    this.AddDiscountFrom.get("TotalAmount").patchValue(result);
  };
  DecreaseAddTotalAmount = () => {
    if (
      this.AddDiscountFrom.value.DiscountAmount >
      this.AddDiscountFrom.value.AmountDue
    ) {
      this.toaster.warning("Discount Amount can not exceed from Amount due");
      this.AddDiscountFrom.get("DiscountAmount").patchValue("");
      this.AddDiscountFrom.get("TotalAmount").patchValue("");
      return;
    }
    const value =
      this.InvoiceData.DiscountAmount === null
        ? this.AddDiscountFrom.value.AdditionalDiscountAmount
        : this.AddDiscountFrom.value.DiscountAmount;
    const result = this.AddDiscountFrom.value.AmountDue - value;
    console.log(this.AddDiscountFrom.value.DiscountAmount);
    this.AddDiscountFrom.get("TotalAmount").patchValue(result);
  };
  private formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY");
    return dateString;
  };

  twoDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.0;
  };
  getBanks = () => {
    this.accountingService.GetBankAccounts().subscribe((s) => {
      this.bankAccounts = s;
      console.log(this.bankAccounts);
    });
  };
  getPaymentModeList(id) {
    const list = this.bankAccounts.filter((x) => x.GLAccountId === id);
    this.PaymentModeList = list[0].PaymentModeList;
    this.accountTypeId = list[0].AccountTypeId;
    this.AcceptPaymentForm.get("PaymentModeId").patchValue(null);
  }
  GetInvoicePaymentList = (id) => {
    if(this.InvoiceData.IsReverseTransaction){
      this.getReverseInvoicePayment(id);
    }
    else {
      this.getInvoicePayment(id);
    }
  }

  getInvoicePayment(invoiceId: number) {
    this.spinner.show();
    this.IncomeService.GetInvoicePaymentList(invoiceId).subscribe((x) => {
      this.spinner.hide();
      this.setUpPaymentData(x);
    });
  }

  getReverseInvoicePayment(invoiceId: number) {
    this.spinner.show();
    this.IncomeService.GetInvoicePaymentReturnList(invoiceId).subscribe((x) => {
      this.spinner.hide();
      this.setUpPaymentData(x);
    });
  }

  setUpPaymentData(x: any) {
    this.totalPaidAmount = 0;
    this.acceptPayment = x.filter((xy) => xy.IsDeliveryPartner != true);
      this.deliveryPartnerPaymentList = x.filter((xy) => xy.IsDeliveryPartner == true);
      this.isAcceptDeliveryPartner = this.deliveryPartnerPaymentList.length > 0 ? true : false;
      for (let j = 0; j < this.acceptPayment.length; j++) {
        this.totalPaidAmount += this.acceptPayment[j].Amount;
      }
      this.isPaymentBtnVisible = this.IsPaymentBtnVisible();
      if (this.InvoiceData.InvoiceAmount == undefined) {
        this.spinner.show();
        this.dueChecker(this.InvoiceData.InvoiceAmount);
      }
  }

  GetInvoicePaymentReturnList = (id) => {
    this.spinner.show();
    this.IncomeService.GetInvoicePaymentReturnList(id).subscribe((x) => {
      this.spinner.hide();
      this.deliveryPartnerPaymentReturnList = x.filter((xy) => xy.IsDeliveryPartner == true);
      this.returnPayment = x.filter((xy) => xy.IsDeliveryPartner != true);
      for (let j = 0; j < this.returnPayment.length; j++) {
        this.totalReturnedAmount += this.returnPayment[j].Amount;
      }
    });
  }

  IsPaymentBtnVisible() {
    let allPaidAmount = (this.totalPaidAmount + this.InvoiceData.DiscountAmount) - (this.totalReturnedAmount + this.InvoiceData.DiscountAmount);
    //let allPaidAmount = this.totalPaidAmount + this.InvoiceData.DiscountAmount;
    return this.InvoiceData.InvoiceAmount == allPaidAmount ? false : true;
  }

  dueChecker(value: number) {
    setTimeout(() => {
      if (this.InvoiceData.InvoiceAmount == undefined) {
        this.dueChecker(this.InvoiceData.InvoiceAmount);
      } else {
        this.isPaymentBtnVisible = false;
      }
    }, 500);
  }

  print = (BillNo) => {
    console.log(BillNo);
    let hostUrl = location.origin + "/#/";
    window.open(`${hostUrl}invoice/preview/${BillNo}`, "_blank");
  }

  getDueAmountWithDeliveryCharge() {
    let allAmount = this.InvoiceData.IsReverseTransaction ? this.InvoiceData.InvoiceAmount + this.totalReturnedAmount : this.InvoiceData.InvoiceAmount;
    let allPaidAmount = this.totalPaidAmount + this.InvoiceData.DiscountAmount;
    let totalDue = allAmount - allPaidAmount;
    totalDue = this.twoDecimalPlace(totalDue);
    return totalDue;
  }

  getDueAmountWithoutDeliveryCharge() {
    let allAmount = this.InvoiceData.IsReverseTransaction ? this.InvoiceData.InvoiceAmount + this.totalReturnedAmount : this.InvoiceData.InvoiceAmount;
    let allPaidAmount = this.totalPaidAmount + this.InvoiceData.DiscountAmount;
    let totalDue = allAmount - allPaidAmount;
    totalDue = this.twoDecimalPlace(totalDue);
    return totalDue;
  }

  openMakePayment = () => {
    //this.totalDue = this.getDueAmountWithDeliveryCharge();
    this.totalDue = this.getDueAmountWithoutDeliveryCharge();
    this.totalDue = this.totalDue < 0 ? ((-1) * this.totalDue) : this.totalDue;

    this.AcceptPaymentForm.get("Amount").patchValue(this.totalDue);
    this.ngxSmartModalService.create("MakePayment", "content").open();
    this.show(new Date());
  }

  openDPMakePayment = () => {
    this.getDeliveryPartner();

    this.totalDue = this.getDueAmountWithoutDeliveryCharge();
    this.totalDue = this.totalDue < 0 ? ((-1) * this.totalDue) : this.totalDue;

    this.AcceptDPPaymentForm.get("Amount").patchValue(this.totalDue);
    this.ngxSmartModalService.create("MakeDPPayment", "content").open();
    this.showDP(new Date());
  }

  openDiscountPopup = () => {
    this.totalDue = this.getDueAmountWithDeliveryCharge();

    this.AddDiscountFrom.get("AmountDue").patchValue(this.totalDue);
    this.AddDiscountFrom.get("AdditionalDiscountAmount").patchValue(this.InvoiceData.DiscountAmount);

    if (this.InvoiceData.DiscountAmount !== null) {
      this.AddDiscountFrom.controls["AdditionalDiscountAmount"].disable();
    }
    this.ngxSmartModalService.create("AddDiscount", "content").open();
  }

  submitMakePayment = () => {
    this.ifSubmit = true;
    let dueAmount = this.getDueAmountWithDeliveryCharge();
    dueAmount = dueAmount < 0 ? ((-1) * dueAmount) : dueAmount;

    this.AcceptPaymentForm.value.InvoiceId = this.InvoiceNo;
    if ( !this.AcceptPaymentForm.value.InvoiceId || !this.AcceptPaymentForm.value.PaidDate || !this.AcceptPaymentForm.value.Amount || !this.AcceptPaymentForm.value.AccountId ) {
      this.toaster.warning("All the fields are required !");
      return;
    }
    if (this.AcceptPaymentForm.value.Amount > dueAmount) {
      this.toaster.warning("Payment always less or equal than due amount. !");
      return;
    }
    
    if(this.IsReversePayment() || this.InvoiceData.IsReverseTransaction) {
      this.spinner.show();
      this.IncomeService.ReversePayment(this.AcceptPaymentForm.value).subscribe(
        (x) => {
          if (x.Success) {
            this.ifSubmit = false;
            this.spinner.hide();
            this.toaster.success("Payment returned successfully.");
            this.ngxSmartModalService.getModal("MakePayment").close();
            this.initMakePayment();
            this.getInvoiceData(this.InvoiceId);
          } else {
            this.toaster.error("Something went wrong !");
            this.spinner.hide();
          }
        },
        (err) => {
          this.toaster.error("Something went wrong !");
          this.spinner.hide();
        }
      )
    }
    else {
      this.spinner.show();
      this.IncomeService.saveAcceptPayment(this.AcceptPaymentForm.value).subscribe(
        (x) => {
          if (x.Success) {
            this.ifSubmit = false;
            this.spinner.hide();
            this.toaster.success("Payment received successfully.");
            if(this.InvoiceData.IsResetted == true){
              this.reversePayment();
            }
            this.ngxSmartModalService.getModal("MakePayment").close();
            this.initMakePayment();
            this.getInvoiceData(this.InvoiceId);
          } else {
            this.toaster.error("Something went wrong !");
            this.spinner.hide();
          }
        },
        (err) => {
          this.toaster.error("Something went wrong !");
          this.spinner.hide();
        }
      );
    }
  }

  reversePayment() {
    this.spinner.show();
    let data = this.AcceptPaymentForm.value;
    data.Amount = this.lastPaymentHistory.Amount - data.Amount;
      this.IncomeService.ReversePayment(data).subscribe(
        (x) => {
          if (x.Success) {
            this.ifSubmit = false;
            this.spinner.hide();
            this.ngxSmartModalService.getModal("MakePayment").close();
            this.initMakePayment();
            this.getInvoiceData(this.InvoiceId);
          } else {
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  IsReversePayment() {
    let allAmount = this.InvoiceData.InvoiceAmount + this.totalReturnedAmount;
    let allPaidAmount = this.totalPaidAmount + this.InvoiceData.DiscountAmount + this.InvoiceData.DeliveryCharge;
    return allAmount < allPaidAmount ? true : false;
  }

  submitDPMakePayment = () => {
    this.ifSubmit = true;

    let dueAmount = this.getDueAmountWithoutDeliveryCharge();

    this.AcceptDPPaymentForm.get("InvoiceId").patchValue(this.InvoiceNo);
    if ( !this.AcceptDPPaymentForm.value.InvoiceId || !this.AcceptDPPaymentForm.value.PaidDate || !this.AcceptDPPaymentForm.value.Amount ) {
      this.toaster.warning("All the fields are required !");
      return;
    }
    if (this.AcceptDPPaymentForm.value.Amount > dueAmount) {
      this.toaster.warning("Payment always less or equal than due amount. !");
      return;
    }

    this.spinner.show();
    this.IncomeService.savePayment(this.AcceptDPPaymentForm.value).subscribe(
      (x) => {
        if (x.Success) {
          this.ifSubmit = false;
          this.spinner.hide();
          this.toaster.success("Payment received successfully.");
          this.ngxSmartModalService.getModal("MakeDPPayment").close();
          this.initDpMakePayment();
          this.getInvoiceData(this.InvoiceId);
        } else {
          this.toaster.error("Something went wrong !");
          this.spinner.hide();
        }
      },
      (err) => {
        this.toaster.error("Something went wrong !");
        this.spinner.hide();
      }
    );
  }

  submitDiscountAmount = () => {
    this.AddDiscountFrom.value.DiscountAmount = this.InvoiceData.DiscountAmount !== null ? this.AddDiscountFrom.value.DiscountAmount : this.AddDiscountFrom.value.AdditionalDiscountAmount;
    this.spinner.show();
    this.IncomeService.SubmitDiscountAmount(this.InvoiceData.Id, this.AddDiscountFrom.value.DiscountAmount).subscribe(
      (x) => {
        if (x.Success) {
          this.spinner.hide();
          this.toaster.success("Discount added successfully.");
          this.ngxSmartModalService.getModal("AddDiscount").close();
          this.initDiscountFrom();
          this.getInvoiceData(this.InvoiceId);
        } else {
          this.toaster.error("Something went wrong !");
          this.spinner.hide();
        }
      },
      (err) => {
        this.toaster.error("Something went wrong !");
        this.spinner.hide();
      }
    );
  };

  formatItem(id: string) {
    let value = this.allSaleableItem.find((e) => e.Id == id);
    if (!value) {
    } else {
      return value.ItemName;
    }
  }
  formatTaxRate(id: number) {
    let value = this.taxRateList.find((e) => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }
  getAllSaleableItem = () => {
    this.IncomeService.GetAllSaleableItem().subscribe((x) => {
      this.allSaleableItem = x;
    });
  };

  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  onWindowClick(event) {
    if (
      event.target.id == "attachmentDropdown" ||
      event.target.id == "attachment" ||
      event.target.id == "files" ||
      event.target.id == "Preview"
    ) {
    } else if (event.target.id == "attachment_btn") {
      this.openAttachmentDropdown();
    } else {
      let val = "do something";
      if (
        document.getElementById("attachmentDropdown").classList.contains("show")
      ) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }

  public sendMail() {
    var data = document.getElementById("printInvoice");
    (html2canvas as any)(data).then((canvas) => {
      var imgWidth = 208;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      var position = 0;

      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      var blob = pdf.output("blob");
      const modalRef = this.modalService.open(SendInvoiceMailComponent, {
        size: "md",
        backdrop: "static",
        keyboard: false,
      });
      modalRef.componentInstance.fromParent = {
        Id: this.InvoiceData.Id,
        RefNo: this.InvoiceData.InvoicceNo,
        PdfData: blob,
      };
      modalRef.result.then(
        (result) => {},
        (reason) => {
          console.log("Dismissed action: " + reason);
        }
      );
    });
  }
  show(e) {
    const value = this.AcceptPaymentForm.value.PaidDate;
    $("#idtest1")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  showDP(e) {
    const value = this.AcceptDPPaymentForm.value.PaidDate;
    $("#idtest2")
      .on("change", function () {
        this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD").format(
            this.getAttribute("data-date-format")
          )
        );
      })
      .trigger("change");
  }
  
  confirmDeletion() {
    this.alertService.confirm("Do you want to Delete this invoice? This process can not be undone.","",`Invoice No: ${this.InvoiceId}`,"").then((confirmed) => {
      if(confirmed)
      {
        this.deleteInvoice();
      }
    }, (err) => {})
    .catch(() => {
      this.spinner.hide();
    });
  }

  deleteInvoice() {
    this.spinner.show();
    this.InvoiceData.IQuidiActionType = iQuidiActionType.Delete;
    this.IncomeService.StatusUpdate(this.InvoiceData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Invoice ${this.InvoiceData.InvoicceNo} has been successfully deleted`,"Success!");
        this.router.navigate([this.redirectUrl]);
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  navigate = () => {
    this.router.navigate([
      "expense/bill",
      "Bill-" + this.InvoiceData.DeliveryBillNo,
    ]);
  };

  onEditableRoute() {
    this.router.navigate(["/income/edit-invoice", this.InvoiceId]);
  }

  isShippedMaxOrMin(Quantity, ShippedItems){
    if(ShippedItems != Quantity && ShippedItems != 0){
      return true;
    }
    else{
      return false;
    }
  }

  canShowDangerIcon(item, i) {
    if(item.isStockAvailable == false && (this.salesOrderNo == null || this.salesOrderNo == '') && item.Quantity != this.InvoiceData.ShippedItems[i] && !item.isDescriptiveOrServiceItem && !this.hasReturnWareHouseData) {
      return true;
    }
    else {
      return false;
    }
  }
  canShowSuccessIcon(item, i) {
    if(item.isStockAvailable == true && (this.salesOrderNo == null || this.salesOrderNo == '') && this.InvoiceData.ShippedItems[i] != item.Quantity && !item.isDescriptiveOrServiceItem && !this.hasReturnWareHouseData) {
      return true;
    }
    else {
      return false;
    }
  }

  canShowShipped(item, i) {
    if((this.salesOrderNo == null || this.salesOrderNo == '') && !item.isDescriptiveOrServiceItem) {
      return true;
    }
    else {
      return false;
    }
  }

  IsAllItemsDescriptiveOrService() {
    let isAllAreDescriptiveItems: boolean = this.InvoiceData?.InvoiceItems?.every( x => x.ItemId == null );
    let isAllAreServiceItems: boolean = this.InvoiceData?.InvoiceItems?.every( x => this.productList?.find( p => p.Id == x.ItemId)?.ItemTypeId == 5);
    let isAllAreDescriptiveAndServiceItems: boolean = this.InvoiceData?.InvoiceItems?.filter( x => x.ItemId == null || this.productList?.find( p => p.Id == x.ItemId)?.ItemTypeId == 5)?.length == this.InvoiceData?.InvoiceItems?.length ? true : false;
    return isAllAreDescriptiveItems || isAllAreServiceItems || isAllAreDescriptiveAndServiceItems ? true : false;
  }

  IsDescriptiveOrServiceItem(item) {
    let isDescriptiveItem: boolean = item.ItemId == null ? true : false;
    let isServiceItem: boolean = item.ItemTypeId == ItemType.Service ? true : false;
    return isDescriptiveItem || isServiceItem ? true : false;
  }

  ResetToDraft() {
    this.alertService.confirm("Do you want to reset this invoice? This process can not be undone.", "", `Invoice No: ${this.InvoiceId}`, "").then((confirmed) => {
      if(confirmed)
      {
        this.ResetInvoice();
      }
    }, (err) => {})
    .catch(() => {
      this.spinner.hide();
    });
  }

  ResetInvoice() {
    this.spinner.show();
    this.IncomeService.ResetToDraft(this.InvoiceData).subscribe((x) => {
      if (x.Success) {
        this.spinner.hide();
        let redirectUrl = 'income/edit-invoice/'+ this.InvoiceId+'/'+ iQuidiActionType.ResetToDraft;
        this.router.navigate([redirectUrl]);
      } else {
        this.spinner.hide();
        this.toaster.warning(x.Message);
      }
    });
  }

  getTotalDue() {
    let totalDue = this.InvoiceData.InvoiceAmount - (this.totalPaidAmount + this.InvoiceData?.DiscountAmount);
    return totalDue.toFixed(2);
  }

  canShowEditDropdownButton() {
    return this.InvoiceData?.StatusId != 4 && this.isInAwaitingPaymentPhase && this.IsUserAtMaxApprovalLevel && this.InvoiceData?.OrderNo == null && this.InvoiceData?.DeliveryBillNo == null;
  }

  canShowDeleteDropdownButton() {
    return this.isEditable && this.IsUserAtMaxApprovalLevel && !this.isShipped && this.InvoiceData?.OrderNo == null && this.InvoiceData?.DeliveryBillNo == null;
  }

  canShowResetToDraftDropdownButton() {
    return this.InvoiceData?.StatusId == 4 && this.IsUserAtMaxApprovalLevel && this.InvoiceData?.IsReverseTransaction == false && this.InvoiceData?.OrderNo == null;
  }

  canShowDiscountDropdownButton() {
    return this.isPaymentBtnVisible && this.InvoiceData?.StatusId != 4;
  }

  canShowAdvanceAdjustmentDropdownButton() {
    return this.isPaymentBtnVisible && this.InvoiceData?.StatusId != 4;
  }

  canShowDropdown() {
    return this.canShowEditDropdownButton() || this.canShowDeleteDropdownButton() || this.canShowResetToDraftDropdownButton() || this.canShowDiscountDropdownButton() || this.canShowAdvanceAdjustmentDropdownButton();
  }
  
}
