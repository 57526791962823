
    <div class="invoice-option" >
       <a class="option-icon-color" (click)="download()" ><i class="material-icons borderradiusleft attachmenticon">file_download</i></a>
        <a><span (click)="print()" class="option-icon-color"><i class="material-icons-outlined borderradiusright border-right attachmenticon">print</i></span></a>
        <a><span (click)="sendMail()" class="option-icon-color"><i class="material-icons-outlined borderradiusright border-right attachmenticon">attach_email</i></span></a>
    </div>
    <div id="printBill" class="box--shadow sidebar-scroll">
        <div id="bill" *ngIf="templates.t1" class="m">
            <div class="row mp-0px pr-20px">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-2 pp-0px right-alignment">
                            <div style="float: left;"*ngIf="logoUrl != '' && logo == true">
                                <img id="companyLogo" style="height: 150px; width: 150px;" [src]="domSanitizer.bypassSecurityTrustUrl(base64Data)"/>
                            </div>
                        </div>
                        <div class="col-md-10 pp-0px right-alignment">
                            <div style="float: right;margin-top: 45px;">
                                <div class="mb-0px">
                                    <b class="mp-0px">Invoice</b>
                                 </div>
                                <div class="mb-0px f-ss-14px" *ngIf="headCompany">{{company?.Name}}</div>
                                <div *ngIf="fromAddress" class="mb-0px f-ss-14px">{{company?.Address}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row mp-0px pb-20px pt-20px pl-20px pr-20px">
                <div class="col-md-12 d-flex pp-0px">
                    <div class="col-md-6">
                        <div style="float: left;">
                            <div class="invoiceheading mb-4px">To</div>
                            <div class="mb-0px f-ss-14px"><span>Name: </span>{{invoiceData?.Contacts.PrimaryPersonFirstName}}</div>
                            <div *ngIf="toPhone" class="mb-0px f-ss-14px"><span>Phone: </span>{{invoiceData?.Contacts.ContactNo}}</div>
                            <div class="mb-0px f-ss-14px">{{invoiceData?.Contacts.Email}}</div>
                            <div *ngIf="toAddress" class="mb-0px f-ss-14px">{{invoiceData?.Contacts.PostalAddress}}</div>
                            <div *ngIf="toAddress" class="mb-0px f-ss-14px">{{invoiceData?.Contacts.StreetAddress}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div style="float: right;">
                            <div class="invoiceheading mb-4px">Details</div>
                            <div class="mb-0px f-ss-14px"><span>Invoice No # </span>{{invoiceData?.InvoicceNo}}</div>
                            <div *ngIf="detailsReference" class="mb-0px f-ss-14px"><span>Reference No # </span>{{invoiceData?.ReferenceNo}}</div>
                            <div *ngIf="detailsVat" class="mb-0px f-ss-14px"><span>VAT Reg: </span>{{company?.VatRegNo}}</div>
                            <div class="mb-0px f-ss-14px"><span>Issued Date: </span>{{convertDateIntoDisplay(invoiceData?.InvoiceDate)}}</div>
                            <div class="mb-0px f-ss-14px"><span>Due Date: </span>{{convertDateIntoDisplay(invoiceData?.DueDate)}}</div>
                            <div  class="mb-0px f-ss-14px"><span>Username: </span>{{userName}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
             <div class="row mp-0px pt-20px pl-20px pr-20px">
                <div class="col-md-12 invoice-grid pp-0px">
                    <table class="w-100">
                        <thead>
                            <tr>
                                <td class="TableHeading">Item</td>
                                <td class="TableHeading">Description</td>
                                <td class="TableHeading" style="text-align: right;">Quantity</td>
                                <td class="TableHeading" style="text-align: right;">Unit Price</td>
                                <td class="TableHeading" style="text-align: right;" *ngIf= "totalDiscountParcent > 0">Discount%</td>
                                <td class="TableHeading" style="text-align: right;" *ngIf= "invoiceData?.TaxAmount !=0">VAT Rate</td>
                                <td class="TableHeading" style="text-align: right;" *ngIf ="invoiceData?.AdvanceTaxAmount !=0">AIT Rate</td>
                                <td class="TableHeading" style="text-align: right;">Amount BDT</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of invoiceData?.InvoiceItems" >
                                <td style="width: 25%;" class="f-ss-14px">{{formatItem(item.ItemId)}}</td>
                                <td  class="f-ss-14px" [ngClass]="{'descriptionWidth' : totalDiscountParcent == 0 || invoiceData?.TaxAmount == 0,'descriptionWidths': totalDiscountParcent > 0 || invoiceData?.TaxAmount != 0}">{{item.Description}}</td>
                                <td style="width: 8%;" class="f-ss-14px" style="text-align: right;">{{item.Quantity}}</td>
                                <td style="width: 12%;" class="f-ss-14px" style="text-align: right;">{{toDecimalPlace(item.UnitPrice)}}</td>
                                <td style="width: 7%;" class="f-ss-14px" style="text-align: right;" *ngIf= "totalDiscountParcent > 0">{{toDecimalPlace(item.DiscountPercent)}}</td>
                                <td style="width: 12%;" class="f-ss-14px" style="text-align: right;"  *ngIf ="invoiceData?.TaxAmount !=0">{{formatTaxRate(item.TaxRateId)}}</td>
                                <td style="width: 11%;" class="f-ss-14px" style="text-align: right;"  *ngIf ="invoiceData?.AdvanceTaxAmount !=0">{{formatTaxRate(item.AdvanceTaxRateId)}}</td>
                                <td style="width: 12%;"><div class="TableHeading float-right">{{toDecimalPlace(item.Quantity * item.UnitPrice)}}</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mp-0px mb-40px pt-20px pr-20px">
                <div class="d-flex w-100">
                    <div class="col-md-8 col-sm-12"><span></span></div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label class="TableHeading">Subtotal</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(itemFullAmount) | currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100"  *ngIf="invoiceData?.DeliveryPartnerItems?.length > 0">
                            <div class="col-md-8">
                                <label class="TableHeading">Delivery Charge</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(invoiceData?.DeliveryPartnerItems[0]?.UnitPrice) | currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100" *ngIf="invoiceData?.TaxAmount != 0">
                            <div class="col-md-8">
                                <label class="TableHeading">VAT</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{invoiceData?.TaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100" *ngIf="invoiceData?.AdvanceTaxAmount != 0">
                            <div class="col-md-8">
                                <label class="TableHeading">AIT</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{invoiceData?.AdvanceTaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100 singleLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label class="TableHeading" style="font-size: 15px;">Total</label>
                            </div>
                            <div class="col-md-4 pp-0px "><p class="float-right prp-7px TableHeading">{{toDecimalPlace(itemFullAmount + totalDeliveryAmount + totalVat)| currency:'':''}}</p></div>
                        </div>
                        <div *ngIf="totalDiscountParcent" class="d-flex w-100">
                            <div class="col-md-8">
                                <label class="TableHeading">Discount</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(totalItemDiscountAmount)| currency:'':''}}</p></div>
                        </div>
                        <div *ngIf="invoiceData?.DiscountAmount>0" class="d-flex w-100">
                            <div class="col-md-8">
                                <label class="TableHeading">Additional Discount</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(invoiceData?.DiscountAmount)| currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100 pt-2" *ngIf="invoiceData?.DeliveryBillNo != null">
                            <div class="col-md-8">
                                <label class="text-blue cursor-pointer" >Delivery Bill</label>
                            </div>
                            <div class="col-md-4 ml-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{invoiceData.DeliveryCharge}}</p></div>
                        </div>
                        <div class="d-flex w-100 pt-2" *ngFor="let item of acceptPayment">
                            <div class="col-md-8" >
                                <label *ngIf="!item.IsAdjustAdvance" class="TableHeading">Less Payment - {{convertDateIntoDisplay(item.PaidDate)}}</label>
                                <label *ngIf="item.IsAdjustAdvance" class="TableHeading">Less PrePayment - {{convertDateIntoDisplay(item.PaidDate)}}</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(item.Amount)| currency:'':''}}</p></div>
                        </div>

                        <div class="d-flex w-100 equalLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label class="TableHeading" style="font-size: 15px;">Amount due</label>
                            </div>
                            <div class="col-md-4 pp-0px "><p class="float-right prp-7px TableHeading">{{toDecimalPlace(invoiceData?.InvoiceAmount-(itemsTotalAmount + invoiceData?.DiscountAmount))| currency:'':''}}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px;"></div>
            <div *ngIf="otherNotes" class="row mp-0px f-ss-14px footer pl-20px pr-20px">
                <div class="TableHeading" style="font-size: 15px;">Notes</div>
            </div>
            <div *ngIf="otherNotes" class="row mp-0px f-ss-14px footer pl-20px pr-20px">
                <div style="margin-left: 5px;margin-bottom: 10px;">{{invoiceData?.Notes}}</div>
            </div>
            <div *ngIf="otherTermsConditions" class="row mp-0px f-ss-14px footer pl-20px pr-20px">
                <div class="TableHeading" style="font-size: 15px;">Terms & Conditions</div>
            </div>
            <div *ngIf="otherTermsConditions" class="row mp-0px f-ss-14px footer pl-20px pr-20px">
                <div style="margin-left: 5px">If you have any questions about this policy or your interaction with Implevista by means of this site. Please feel free to contact us.</div>
            </div>
            <div style="margin-top: 5%;"></div>
            <hr/>
            <div style="padding-bottom: 30px;" class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <div style="float: left;color: #a9a9a9;" *ngIf="footCompany">{{company?.Name}}</div>
                    </div>
                    <div class="col-md-4">
                        <div style="float: inherit;color: #a9a9a9;" *ngIf="website">{{company?.Website}}</div>
                    </div>
                    <div *ngIf="fromPhone" class="col-md-4">
                        <div style="float: right;color: #a9a9a9;">Phone: {{company?.PhoneNo}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div id="bill" *ngIf="templates.t2" class="m">
            <div class="row mp-0px pr-20px">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 pp-0px right-alignment">
                            <div style="float: left;margin-top: 100px;">
                                <b style="font-size: 18px;" class="ml-3"><span>Invoice No # </span>{{invoiceData?.InvoicceNo}}</b>
                            </div>
                        </div>
                        <div class="col-md-6 pp-0px right-alignment">
                            <div style="float: right;" class="col-md-12" *ngIf="logoUrl != '' && logo == true">
                                <img style="height: 150px; width: 150px;" src={{logoUrl}} />
                            </div>
                            <div class="pp-0px right-alignment">
                                <div style="float: right;">
                                    <div *ngIf="fromAddress" class="mb-0px f-ss-14px">{{company?.Address}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row mp-0px pb-20px pt-20px pl-20px pr-20px">
                <div class="col-md-12 d-flex pp-0px">
                    <div class="col-md-6">
                        <div style="float: left;">
                            <div class="invoiceheading mb-4px">To</div>
                            <div class="mb-0px f-ss-14px">{{invoiceData?.Contacts.PrimaryPersonFirstName}}</div>
                            <div *ngIf="toPhone" class="mb-0px f-ss-14px"><span>Phone: </span>01718950674</div>
                            <div class="mb-0px f-ss-14px">{{invoiceData?.Contacts.Email}}</div>
                            <div *ngIf="toAddress" class="mb-0px f-ss-14px">{{invoiceData?.Contacts.PostalAddress}}</div>
                            <div *ngIf="toAddress" class="mb-0px f-ss-14px">{{invoiceData?.Contacts.StreetAddress}}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div style="float: right;">
                            <div class="invoiceheading mb-4px">Details</div>
                            <div class="mb-0px f-ss-14px"><span>Invoice No # </span>{{invoiceData?.InvoicceNo}}</div>
                            <div *ngIf="detailsReference" class="mb-0px f-ss-14px"><span>Reference No # </span>{{invoiceData?.ReferenceNo}}</div>
                            <div *ngIf="detailsVat" class="mb-0px f-ss-14px"><span>VAT Reg: </span>{{company?.VatRegNo}}</div>
                            <div class="mb-0px f-ss-14px"><span>Issued Date: </span>{{convertDateIntoDisplay(invoiceData?.InvoiceDate)}}</div>
                            <div class="mb-0px f-ss-14px"><span>Due Date: </span>{{convertDateIntoDisplay(invoiceData?.DueDate)}}</div>
                            <div  class="mb-0px f-ss-14px"><span>Username: </span>{{userName}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
             <div class="row mp-0px pt-20px pl-20px pr-20px">
                <div class="col-md-12 invoice-grid pp-0px">
                    <table class="w-100">
                        <thead>
                            <tr>
                                <td class="TableHeading">Item</td>
                                <td class="TableHeading">Description</td>
                                <td class="TableHeading" style="text-align: right;">Quantity</td>
                                <td class="TableHeading" style="text-align: right;">Unit Price</td>
                                <td class="TableHeading" style="text-align: right;" *ngIf= "totalDiscountParcent > 0">Discount%</td>
                                <td class="TableHeading" style="text-align: right;"  *ngIf ="invoiceData?.TaxAmount !=0">VAT Rate</td>
                                <td class="TableHeading" style="text-align: right;"  *ngIf ="invoiceData?.AdvanceTaxAmount !=0">AIT Rate</td>
                                <td class="amount"> <div class="float-right TableHeading">Amount BDT</div></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of invoiceData?.InvoiceItems" >
                                <td style="width: 25%;" class="f-ss-14px">{{formatItem(item.ItemId)}}</td>
                                <td class="f-ss-14px" [ngClass]="{'descriptionWidth' : totalDiscountParcent == 0 || invoiceData?.TaxAmount == 0,'descriptionWidths': totalDiscountParcent > 0 || invoiceData?.TaxAmount != 0}">{{item.Description}}</td>
                                <td style="width: 8%;" class="f-ss-14px" style="text-align: right;">{{item.Quantity}}</td>
                                <td style="width: 12%;" class="f-ss-14px" style="text-align: right;">{{toDecimalPlace(item.UnitPrice)}}</td>
                                <td style="width: 7%;" class="f-ss-14px" style="text-align: right;" *ngIf= "totalDiscountParcent > 0">{{toDecimalPlace(item.DiscountPercent)}}</td>
                                <td style="width: 12%;" class="f-ss-14px" style="text-align: right;" *ngIf ="invoiceData?.TaxAmount !=0">{{formatTaxRate(item.TaxRateId)}}</td>
                                <td style="width: 12%;" class="f-ss-14px" style="text-align: right;" *ngIf ="invoiceData?.AdvanceTaxAmount !=0">{{formatTaxRate(item.AdvanceTaxRateId)}}</td>
                                <td style="width: 11%;"><div class="TableHeading float-right">{{toDecimalPlace(item.Quantity * item.UnitPrice)}}</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row mp-0px mb-40px pt-20px pr-20px">
                <div class="d-flex w-100">
                    <div class="col-md-8 col-sm-12"><span></span></div>
                    <div class="col-md-4 col-sm-12 p-0 d-block">
                        <div class="d-flex w-100">
                            <div class="col-md-8">
                                <label class="TableHeading">Subtotal</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(itemFullAmount) | currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100"  *ngIf="invoiceData?.DeliveryPartnerItems?.length > 0">
                            <div class="col-md-8">
                                <label class="TableHeading">Delivery Charge</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(invoiceData?.DeliveryPartnerItems[0]?.UnitPrice) | currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100" *ngIf="invoiceData?.TaxAmount != 0">
                            <div class="col-md-8">
                                <label class="TableHeading">VAT</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(invoiceData?.TaxAmount) | currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100" *ngIf="invoiceData?.AdvanceTaxAmount != 0">
                            <div class="col-md-8">
                                <label class="TableHeading">AIT</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{invoiceData?.AdvanceTaxAmount}}</p></div>
                        </div>
                        <div class="d-flex w-100 singleLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label class="TableHeading" style="font-size: 15px;">Total</label>
                            </div>
                            <div class="col-md-4 pp-0px "><p class="float-right prp-7px TableHeading">{{toDecimalPlace(itemFullAmount + totalDeliveryAmount + totalVat)| currency:'':''}}</p></div>
                        </div>
                        <div *ngIf="totalDiscountParcent" class="d-flex w-100">
                            <div class="col-md-8">
                                <label class="TableHeading">Discount</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(totalItemDiscountAmount)| currency:'':''}}</p></div>
                        </div>
                        <div *ngIf="invoiceData?.DiscountAmount>0" class="d-flex w-100">
                            <div class="col-md-8">
                                <label class="TableHeading">Additional Discount</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(invoiceData?.DiscountAmount)| currency:'':''}}</p></div>
                        </div>
                        <div class="d-flex w-100 pt-2" *ngIf="invoiceData?.DeliveryBillNo != null">
                            <div class="col-md-8">
                                <label class="text-blue cursor-pointer" >Delivery Bill</label>
                            </div>
                            <div class="col-md-4 ml-4 font-weight-bold"><p class="float-right pr-3 totalTextValue">{{invoiceData.DeliveryCharge}}</p></div>
                        </div>
                        <div class="d-flex w-100 pt-2" *ngFor="let item of acceptPayment">
                            <div class="col-md-8" >
                                <label *ngIf="!item.IsAdjustAdvance" class="TableHeading">Less Payment - {{convertDateIntoDisplay(item.PaidDate)}}</label>
                                <label *ngIf="item.IsAdjustAdvance" class="TableHeading">Less PrePayment - {{convertDateIntoDisplay(item.PaidDate)}}</label>
                            </div>
                            <div class="col-md-4 pp-0px float-right"><p class="TableHeading float-right prp-7px">{{toDecimalPlace(item.Amount)| currency:'':''}}</p></div>
                        </div>

                        <div class="d-flex w-100 equalLine">
                            <span></span>
                        </div>
                        <div class="d-flex w-100 pt-2">
                            <div class="col-md-8">
                                <label class="TableHeading" style="font-size: 15px;">Amount due</label>
                            </div>
                            <div class="col-md-4 pp-0px "><p class="float-right prp-7px TableHeading">{{toDecimalPlace(invoiceData?.InvoiceAmount-(itemsTotalAmount + invoiceData?.DeliveryCharge + invoiceData?.DiscountAmount))| currency:'':''}}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px;"></div>
            <div *ngIf="otherNotes" class="row mp-0px f-ss-14px footer pl-20px pr-20px">
                <div class="TableHeading" style="font-size: 15px;">Notes</div>
            </div>
            <div *ngIf="otherNotes" class="row mp-0px f-ss-14px footer pl-20px pr-20px">
                <div style="margin-left: 5px;margin-bottom: 10px;">{{invoiceData?.Notes}}</div>
            </div>
            <div *ngIf="otherTermsConditions" class="row mp-0px f-ss-14px footer pl-20px pr-20px">
                <div class="TableHeading" style="font-size: 15px;">Terms & Conditions</div>
            </div>
            <div *ngIf="otherTermsConditions" class="row mp-0px f-ss-14px footer pl-20px pr-20px">
                <div style="margin-left: 5px">If you have any questions about this policy or your interaction with Implevista by means of this site. Please feel free to contact us.</div>
            </div>
            <div style="margin-top: 5%;"></div>
            <hr/>
            <div style="padding-bottom: 30px;" class="col-md-12">
                <div class="row">
                    <div  class="col-md-4">
                        <div style="float: left;color: #a9a9a9;"><span *ngIf="fromPhone">Phone: {{company?.PhoneNo}} <br/> </span><span *ngIf="footCompany">{{company?.Name}}</span></div>
                    </div>
                    <div class="col-md-4">
                        <div style="float: inherit;color: #a9a9a9;" *ngIf="website">{{company?.Website}}</div>
                    </div>
                    <div *ngIf="fromAddress" class="col-md-4">
                        <div style="float: right;color: #a9a9a9;">{{company?.Address}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
