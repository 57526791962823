<app-dashboard>
    <div class="headSection marginNegative5">
      <div class="row borderBottom1Px pb-2">
          <div class="col-md-12 row" style="padding: 0px 15px;">
              <div class="d-block col-md-3">
                  <div class="pointer" (click)="backToPreviousPage()">
                      <i class="icon-arrow-left52 mr-2 subTextTitle"></i>
                      <span class="subTextTitle f-ss-14px" *ngIf="this.steps == 0">Settings</span>
                      <span class="subTextTitle f-ss-14px" *ngIf="this.steps > 0">Back</span>
                  </div>
                  <span class="mainTextTitle f-ssb-14px" style="width: 220px;">Subscription</span>        
              </div>
          </div>
        </div>
    </div>
    <div class="area_for_tab" style="margin-left: 70px;">
      <div class="col-md-12 row">
        <div class="col-md-5">
          <button (click)="setFilterData(1)" class="bt">
            <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===1}">1</span>
            <span class="bb" [ngClass]="{'active-tab': activeStatusId===1}">Select plan</span>
          </button>
          <span class="middleLine col-md-8" style="margin-left: 150px;"></span>
        </div>
        <div class="col-md-5">
          <button (click)="setFilterData(2)" class="bt" [ngClass]="{'disabledClass':contentFirst===true}">
            <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===2}">2</span>
            <span class="bb" [ngClass]="{'active-tab': activeStatusId===2}">Select billing account</span>
          </button>
          <span class="middleLine2 col-md-7" style="margin-left:215px"></span>
        </div>
        <div class="col-md-2">
          <button (click)="setFilterData(3)" class="bt" [ngClass]="{'disabledClass':contentScnd===true}">
            <span class="test mr-2" [ngClass]="{'test-active': activeStatusId===3}">3</span>
            <span class="bb" [ngClass]="{'active-tab': activeStatusId===3}">Review & pay</span>
          </button>
        </div>
      </div>
<app-dashboard>

    <div class="addUserBlock" *ngIf="activeStatusId===1" >
        <div class="addUserBlock_col_1">
            
            <!-- <div class="mt-4 text-center full-width">
                <button type="button" class="eCount-def-btn pb" (click)="addNewUser()">Continue to add-users</button>
            </div>
            <div class="row col-sm-10 marginLefts mt" *ngIf="userInfo == true">
            <div class="plancard__popular2 col-sm-6 full-width">Add Additonal User</div>
            <div class="col-sm-3 Userbr">
                <label class="mt-2 text-blue addUser" for="">Additional User</label>
                <input class="mr-3" type="radio" value="" checked="checked">
            </div>
            <div class="col-sm-3 Userbr">
                <div class="row">
                    <input type="number" id="inputBottom" min="0" [(ngModel)]="TotalUser" class="form-control ml-2"
                        appDecimaNumericValidator  (change)="getTotalAmount(TotalUser)">
                    <span class="mt-3 text-blue ml-2">Users</span>
                </div>
                <div class="row mt-4">
                    <span class="mt-3 tk ml-2">৳</span>
                    <input type="text" id="inputBottom2" [(ngModel)]="TotalAmount" class="form-control ml-2"
                        appDecimaNumericValidator readonly>
                </div>
            </div>
            </div>
            <div class="mt-4 text-center full-width">
                <button type="button" class="eCount-def-btn pb" (click)="selectedValue(filterType)">Continue To Select Billing
                    Account</button>
            </div> -->
            
            <div class="addUserCart">
                <div class="addUserCart_Title">
                    <p>Add Additional User</p>
                </div>
                <div class="addUserCart_Content">
                    <div class="addUserCart_Content_1">
                        <ul style="list-style-type:none; margin: 10px;">
                            <li style="padding: 5px;">Current Users: {{ currentActiveSubscriptionDetails?.AdditionalUser }}</li>
                            <li style="padding: 5px;">Current Amount: {{ currentActiveSubscriptionDetails?.PackagePrice }}</li>
                            <li style="padding: 5px;">Package: {{ currentActiveSubscriptionDetails?.PackageTypeName }}</li>
                        </ul>
                    </div>
                    <div class="addUserCart_Content_2" >
                        <div class="addUserCart_Content_2_Inner_Content_1" style="margin-left: 30px;" >
                            <input type="number" id="inputBottom" min="0" [(ngModel)]="TotalUser" class="form-control ml-2"
                                appDecimaNumericValidator  (change)="getTotalAmount(TotalUser)">
                            <span class="text-blue ml-2">Users</span>
                        </div>
                        <div class="addUserCart_Content_2_Inner_Content_2" >
                            <span class="tk ml-2">৳</span>
                            <input type="text" id="inputBottom2" [(ngModel)]="TotalAmount" class="form-control ml-2"
                                appDecimaNumericValidator readonly>
                        </div>
                    </div>
                </div>
                <div class="mt-4 text-center full-width">
                    <button type="button" class="continueToSelectBillingButton eCount-def-btn pb" (click)="selectedValue(filterType)">Continue To Select Billing
                        Account</button>
                </div>
            </div>

        </div>

        <div class="addUserBlock_col_2">
            <div class="afterUpdateCart">
                <div class="afterUpdateCart_Title">
                    <p>After Update</p>
                </div>
                <div class="afterUpdateCart_Content">
                    <div class="afterUpdateCart_Content_1" >
                        <div class="afterUpdateCart_Content_1_Inner_Content_1" >
                            <div class="afterUpdateTotalUserCount">
                                <span>Total users: </span>
                                <span class="previousUsersNo"> {{currentActiveSubscriptionDetails?.AdditionalUser}} + </span>
                                <span class="newUsersNo">{{TotalUser}}</span>
                            </div>
                            
                            <div style="margin-left: 75px;">
                                <span class="tk"> </span>
                                <input type="text" readonly id="inputBottom2" [(ngModel)]="currentActiveSubscriptionDetails.AdditionalUser+TotalUser" class="form-control ml-2"
                                    appDecimaNumericValidator readonly>
                            </div>
                        </div>

                        <div class="afterUpdateCart_Content_1_Inner_Content_2" style="margin-top: 10px;">
                            <div class="afterUpdateTotalPrice">
                                <span>Total amount: </span>
                                <span class="previousPrice"> {{ currentActiveSubscriptionDetails.PackagePrice }} + </span>
                                <span class="newAddedPrice">{{TotalAmount}}</span>
                            </div>

                            <div style="margin-left: 60px;">
                                <span class="tk ml-2">৳</span>
                                <input type="text" readonly id="inputBottom2" [(ngModel)]="TotalAmount+currentActiveSubscriptionDetails.PackagePrice" class="form-control ml-2"
                                    appDecimaNumericValidator readonly>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>

    </div>


    <div *ngIf="activeStatusId===2">
        <div class="card cad mt-4">
          <div class="card-body text-center ml-2">
            You're buying a <span class="font-weight-bold">{{packageName}} package </span> for <span
              class="font-weight-bold">{{companyName}}</span>
            <span class="float-right text-blue cursor-pointer" (click)="backToPlan()">Change</span>
          </div>
        </div>
        <p class="ToHeading text-center mt-4">Create a new billing account</p>
        <div class="bor mb-4">
    
          <div class="col-sm-6 mt-3">
            <span class="mls">Person To Bill</span>
            <input type="text" [(ngModel)]="BillDetails.BillToName" class="form-control mal">
          </div>
          <div class="col-sm-6 mt-4">
            <span class="mlo">Email</span>
            <input type="text" [(ngModel)]="BillDetails.BillingEmail" class="form-control mal">
          </div>
          <div class="col-sm-6 mt-4">
            <span class="mlo">Phone</span>
            <input type="text" appDecimaNumericValidator [(ngModel)]="BillDetails.BillingContact" class="form-control mal">
          </div>
        </div>
        <div class="text-align-right mt-4">
          <button type="button" class="eCount-def-btn mb-2 mrr" (click)="showPaymentDetails(BillDetails)">Continue
            to review & pay</button>
        </div>
      </div>


      <div *ngIf="activeStatusId===3">
            <p class="ToHeading text-center">Payment Details</p>
            <div class="col-sm-12 row">
            <div class="col-sm-6">
                <div class="col-sm-12">
                <div class="card">
                    <h6 class="clr">{{packageName}} Package</h6>
                    <div class="card-body">
                    <p class="card-text fss-14px">Your Plans start immediately.You will be Billed on
                        {{convertDateIntoDisplay(Today)}}.Your
                        Subscription will be valid on {{convertDateIntoDisplay(Today)}} to
                        {{convertDateIntoDisplay(ExpiryDate)}}.</p>
                    <hr />
                    <div>
                        <span class="fss-14px">{{companyName}}</span>
                        <span class="fss-14px right">BDT {{TotalAmount}}</span>
                        
                        <p class="mb"></p>
                        <span class="fss-14px">Add Coupon Code:</span>
                        <div [formGroup]="couponForm" class="fss-14px right makeInline">
                        <input formControlName="Name" />
                        <button class="" (click)="applyCoupon()">Add</button>
                        </div>

                        <p class="mb"></p>
                        <span class="fss-14px">Organization {{packageName}} Total</span>
                        <span class="fss-14px right">BDT {{packagePrice}}</span>
                        
                        <p    *ngIf="couponData" class="mb"></p>
                        <span *ngIf="couponData" class="fss-14px">Coupon Code: {{couponData.Name}}</span>
                        <span *ngIf="couponData" class="fss-14px right">{{couponData.DiscountAmount}}% Discount</span>

                        <p    *ngIf="couponData" class="mb"></p>
                        <span *ngIf="couponData" class="fss-14px">Total</span>
                        <span *ngIf="couponData" class="fss-14px right">BDT {{ (packagePrice*(100 - couponData.DiscountAmount))/100 }}</span>

                    </div>
                    </div>
                </div>
                </div>
                <div class="col-sm-12">
                <div class="card mt">
                    <h6 class="clr">Bill To</h6>
                    <div class="card-body">
        
                    <p class="ToHeading">{{BillDetails.BillToName}}</p>
                    <p class="fss-14px">{{BillDetails.BillingEmail}}</p>
                    <p class="fss-14px">{{BillDetails.BillingContact}}</p>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                <div class="card-body">
                    <div class="row">
                    <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="3"
                        (change)="onShowTransferMoneyDetails($event.target.value)" [checked]="true">
                        <label class="mt-2" for="2">Transfer Money</label>
                    </div>
                    <div class="row">
                    <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="1">
                    <label class="mt-2" for="1">Bkash</label>
                    </div>
                    <div class="row">
                    
                    <input class="mr-3 ml-2" type="radio" name="radiogroup" [value]="2">
                    <label class="mt-2" for="2">Card</label>
                    </div>
                    <div [hidden]="!ShowTransferDetails" class="mla">
        
                    <p class="card-text fss-14px mt-2 mb-1">(Make your payment directly into our bank account. Please use given
                        account number for transfer money.)</p>
                    <div class="row ml-1">
                        <span class="col-sm-6">Account Holder Name</span>
                        <span class="col-sm-6 txtColor">{{BankInfo.AccountHolderName}}</span>
                    </div>
                    <div class="row ml-1">
                        <span class="col-sm-6">Transfer Account</span>
                        <span class="col-sm-6 txtColor">{{BankInfo.AccountNo}}</span>
                    </div>
                    <div class="row ml-1">
                        <span class="col-sm-6">Bank Name</span>
                        <span class="col-sm-6 txtColor">{{BankInfo.BankName}}</span>
                    </div>
                    <div class="row ml-1">
                        <span class="col-sm-6">Branch Name</span>
                        <span class="col-sm-6 txtColor">{{BankInfo.BranchName}}</span>
                    </div>
                    <div class="row ml-1">
                        <span class="col-sm-6">Swift Code</span>
                        <span class="col-sm-6 txtColor">{{BankInfo.SwiftCode}}</span>
                    </div>
                    </div>
                    <div class="mt-4">
                    <span class="fss-14px red mla">After depositing the money, kindly attach the receipt document here</span>
                    <form [formGroup]="myForm">
                        <div class="form-group">
                        <div class="das mla">
                            <label for="file" class="file"></label>
                            <input formControlName="file" id="file" type="file" class="form-control"
                            (change)="onFileChange($event)" class="hidden" style="outline: none;margin-left: 50px;">
                        </div>
                        </div>
                    </form>
                    </div>
                    <div class="full-width text-center">
                    <button type="button" class="btns ml-4 mt-4 col-md-6" (click)="confirmPayment()">Confirm Purchase</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>


        