<ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/fixed-asset/setup/draft']">{{'FIXEDASSET.draft' |translate}} ({{totalDraft}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link active" [routerLink]="['/fixed-asset/setup/registered']">{{'FIXEDASSET.registered' |translate}} ({{totalRegistered}})</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/fixed-asset/setup/solddisposed']">{{'FIXEDASSET.soldDisposed' |translate}} ({{totalSold}})</a>
    </li>
  </ul>

  <div class="row" style="width: 100%;">
    <div class="w-100 col">
        <table class="table  f-ss-14px">
            <thead>
                <tr class="tableHeader">
                    <th scope="col" class="table-header-color text-align-left">Asset Name</th>
                    <th scope="col" class="table-header-color text-align-center">Asset Number</th>
                    <th scope="col" class="table-header-color text-align-center">Asset Type</th>
                    <th scope="col" class="table-header-color text-align-center">Purchase Date</th>
                    <th scope="col" class="table-header-color text-align-center">Purchase Price</th>
                    <th scope="col" class="table-header-color text-align-center">Book Value</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of assetList">
                    <td class="text-align-left">{{item.AssetName}}</td>
                    <td class="text-align-center">
                      <span class="item-link" (click)="DetailsAssetItem(item)">{{item.AssetNumber}}</span> 
                    </td>
                    <td class="text-align-center">{{item.AssetTypeName}}</td>
                    <td class="text-align-center">{{item.PurchaseDate}}</td>
                    <td class="text-align-center">{{item.PurchasePrice}}</td>
                    <td class="text-align-center">{{item.BookValue}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>