<app-dashboard>
    <app-page-header-title mainTitle="{{ setMainTitle() }}" subTitle="Finished Product Material Formula List" backURL="/manufacture/finished-product-material-formula-list/All">
        <div class="full-width text-align-right">
            <button class="eCount-def-btn ml-4" type="button" (click)="saveManufactureFormula()">Save</button>
            <button class="eCount-def-btn ml-4" type="button" (click)="approveManufactureFormula()" style="background: #5cac00;">Approve</button>
        </div>
    </app-page-header-title>

    <div class="headSection">
        <form [formGroup]="ProductFormulaForm" class="f-ss-14px">
            <div class="row f-ss-14px">
                <div class="col-md-3">
                    <label for="">Product Name<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="FinishedProductId" readonly>
                </div>
                <div class="col-md-3">
                    <label for="">Formula Name<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="FormulaName" readonly>
                </div>
                <div class="col-md-2">
                    <label for="">Quantity<span class="clr">*</span></label>
                    <input class="form-control" type="text" formControlName="Quantity" appDecimaNumericValidator>
                </div>
                <div class="col-md-2">
                    <label for="">Date<span class="clr">*</span></label>
                    <input class="form-control" id="createdDateId" type="date" formControlName="CreatedDate" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)">
                </div>
                <div class="col-md-2">
                    <label for="">Reference</label>
                    <input class="form-control" type="text" formControlName="Reference">
                </div>
            </div>
            <div class="row d-block topSection f-ss-14px">
                <div class="d-flex">
                    <div class="col-md-3">
                        Item
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        Item Quantity
                    </div>
                    <div class="col-md-3"></div>
                </div>
                <hr class="pb-0 mb-0 pt-1 mt-1">
                <div>
                    <div formArrayName="FormulaItems" *ngFor="let item of ProductFormulaForm.get('FormulaItems')['controls']; let i = index;">
                        <div class="row f-ss-14px" [formGroupName]="i">
                            <div class="col-md-3" style="padding-top: 3px;">
                                <ng-select [items]="rawMaterialList" [clearable]="false" placeholder="Select Item" bindLabel="ItemName" bindValue="Id" formControlName="RawMaterialId" required></ng-select>
                            </div>
                            <div class="col-md-3"></div>
                            <div class="col-md-3" style="padding-top: 3px;">
                                <input class="form-control" type="text" appDecimaNumericValidator formControlName="ItemQuantity">
                            </div>
                            <div class="col-md-2"></div>
                            <div class="col-md-1">
                                <div class="dropdown mr-4" style="padding-top: 13px;">
                                    <div role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                        <i class="icon-more float-right"></i>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width: 100%;">
                                        <div class="productRow pb-3" (click)="removeItems(i)">
                                            <span class="lead1">Remove Item</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row addItem">
                    <div class="BottomAddItem w-100">
                        <div class="dropdown mr-4">
                            <a class="nav-link" style="background-color:white;" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" (click)="addItem()">
                                <img class="languageImg" src="../../../assets/image/addIcon.png" alt="Add Item"> Add New Line
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>
