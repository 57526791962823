

import { Component, OnInit } from '@angular/core';
import {QuotationService} from '../../../service/Quotation/quotation.service'
import {RouterLinkRendereComponent} from '../../../components/router-link-rendere/router-link-rendere.component'
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { stringify } from 'querystring';

@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.css']
})
export class QuotationListComponent implements OnInit {
  dataList: any = [];
  searchText:string;
  gridApi;
  status: any;
  filteredQuotations:any=[];
  activeCount:number=0;
  inactiveCount:number=0;
  allCount:number=0;
  constructor(private attachmentService: AttachmentServiceService,
    private quotationService: QuotationService,
    private dataService:DataService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router:ActivatedRoute) { }

  ngOnInit(): void {
    this.status = this.router.snapshot.paramMap.get('status');
    this.dataService.changeSelectedOrganization.subscribe(x => {
      this.getAllDataList();
    });
    this.getAllDataList();
  }
  domLayout= 'autoHeight'
  rowHeight= 275
  columnDefs = [
    { headerName: 'Quotation No', field: 'QuotationNo',
    cellRendererFramework: RouterLinkRendereComponent,
    cellRendererParams: {
        inRouterLink: '/quotation/details/',
      }
    },
    { headerName: 'Ref', field: 'ReferenceNo' },
    { headerName: 'To', field: 'Contacts.PrimaryPersonFullName' },
    { headerName: 'Date', field: 'QuotationDate', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Delivery Date', field: 'DueDate', valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Modified Date', field: 'UpdatedAt', valueFormatter: this.convertDateIntoDisplay },
     { headerName: 'Quotation Amount', field: 'Amount', valueFormatter: this.convertQuotationAmount },
    { headerName: 'Status', field: 'IsActive', valueFormatter: this.setStatus },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };

  getAllDataList=()=>{

    this.spinner.show()
    this.quotationService.GetQuotationList().subscribe((x)=>{
      this.dataList=x;
      for(let i=0;i<this.dataList.length;i++) this.dataList[i].QuotationNo = 'QU-' + this.dataList[i].QuotationNo;
      this.allCount = x.length;
      this.dataList.forEach(z => {
        z.IsActive == true ? this.activeCount += 1 :  this.inactiveCount += 1;
      });
      this.filteredQuotations = this.dataList;
      this.spinner.hide()
    }, err=>{
      this.toastr.error("There is an error,please check");
      this.spinner.hide()
    })
  }

  convertQuotationAmount(value){
    let amount = value.data.Amount;
    if(amount<=0)
    amount =0;
    let valueData = Number(amount.toFixed(2))
    return valueData.toLocaleString();
  }

  setStatus(id){
    if(id.data.IsActive)
     return 'Active';
     else
       return 'In Active';
  }

  convertDateIntoDisplay(date){
    console.log(date.data);
    let dateString = date.data.UpdatedAt == null ? moment(date.data.CreatedAt).format("Do MMM YYYY") :
    moment(date.data.UpdatedAt).format("Do MMM YYYY");
    return dateString;
  }
  convertGridDateIntoDisplay(date) {
    let dateString = date.value ? moment(date.value).format("Do MMM YYYY") : '';
    return dateString;
  }
  onGridReady(event){
    this.gridApi = event.api;
  }

  convertToPDF=()=>{
    this.attachmentService.convertToPDF();
  }

  search=()=>{
    this.gridApi.setQuickFilter(this.searchText);
  }

  reLoadRowData = (status: string) => {
    if(status=="All"){
      this.filteredQuotations=this.dataList;
    }
    else if(status=="Active"){
      this.filteredQuotations=this.dataList.filter(x => x.IsActive);
    }
    else{
      this.filteredQuotations=this.dataList.filter(x => !x.IsActive);
    }
  }

}
