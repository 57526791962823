import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

 
//get Receiveable Payable Summary Item
public GetReceiveablePayableSummary(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'Dashboard/GetReceiveablePayableSummary')
    .pipe(
      retry(1)
    )
}

  //get GetInvoice Summary Item
  public GetInvoiceSummaryList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Dashboard/GetInvoiceSummary')
      .pipe(
        retry(1)
      )
  }
  //get GetBill Summary Item

  public GetBillSummaryList(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Dashboard/GetBillSummary')
      .pipe(
        retry(1)
      )
  }
//get Last 7Dasy CashInOut Trans Item

public GetLast7DaysCashInOutTrans(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'Dashboard/GetLast7DasyCashInOutTrans')
    .pipe(
      retry(1)
    )
}
//get Last 6Month CashInOut Trans Item

public GetLast6MonthCashInOutTrans(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'Dashboard/GetLast6MonthCashInOutTrans')
    .pipe(
      retry(1)
    )
}
//get Invoice Chart Slot Values

public GetInvoiceChartSlotValues(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'Dashboard/GetInvoiceChartSlotValues')
    .pipe(
      retry(1)
    )
}
//get Bill Chart Slot Values

public GetBillChartSlotValues(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'Dashboard/GetBillChartSlotValues')
    .pipe(
      retry(1)
    )
}
  
}
