import { Component, OnInit ,NgZone} from '@angular/core';
import { ProductServiceService } from '../../../service/Products/product-service.service';
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditPurchaseProductComponent } from '../edit-purchase-product/edit-purchase-product.component';
import { RouterLinkRendereComponent } from 'src/app/components/router-link-rendere/router-link-rendere.component';

import { PurchaseProductComponent } from '../purchase-product/purchase-product.component';
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, 
              private router: Router, 
              private dataService: DataService,
              private modalService: NgbModal, 
              private _ngZone: NgZone,
              public ngxSmartModalService: NgxSmartModalService,
              private productServiceService: ProductServiceService) { }

 private gridApi;
 private gridColumnApi;
 domLayout='autoHeight';
 isItemListUpdate = false;
  searchText:string;
 ProductList: any = [];
  
  ngOnInit(): void {

    this.dataService.change.subscribe(x => {
      this.isItemListUpdate = x;
      this.getAllProduct();
    });

    this.dataService.changeSelectedOrganization.subscribe(x=>{
      this.isItemListUpdate = x;
      this.getAllProduct();
    })
   
  }

  columnDefs = [
    {
      headerName: 'Item Code', field: 'ItemCode', cellRendererFramework: RouterLinkRendereComponent},
    { headerName: 'Item Name', field: 'ItemName' },
    { headerName: 'Cost Price', field: 'PurchasePrice' },
    { headerName: 'Sale Price', field: 'SaleUnitPrice' },
    { headerName: 'Quanity', field: 'StockQuantity' }
    ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1
  };

  getAllProduct=()=>{

    this.spinner.show()
    this.ProductList = []
    this.productServiceService.GetTradingItems().subscribe((x)=>{
      this.spinner.hide()
      this.ProductList = [...x, ...this.ProductList]
      console.log(this.ProductList);
    })
  }
  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getAllProduct();
  }

  createNewItem(){
        const modalRefs = this.modalService.open(PurchaseProductComponent,{ size: 'lg',backdrop: 'static', keyboard : false});
      }
  editItems(e) {

    const modalRef = this.modalService.open(EditPurchaseProductComponent,{ size: 'lg',backdrop: 'static', keyboard : false,});
    modalRef.componentInstance.productId = e.data.Id;
    modalRef.result.then((result) => {
      this.getAllProduct();
    }, (reason) => {
    });
  }
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
    suppressQuotes: '', 
    columnSeparator: '',
    customHeader: '',
    customFooter: '',};
  }

  onRowClicked(event){
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Item Code") {
      let id = event.data.Id;
      this.router.navigate([`/products/${id}`])
    }
    
  }

  search = ()=>{
    this.gridApi.setQuickFilter(this.searchText);
  }

}
