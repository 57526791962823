import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup,FormArray, Validators, FormControl } from '@angular/forms';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-factory-in-entry',
  templateUrl: './factory-in-entry.component.html',
  styleUrls: ['./factory-in-entry.component.css']
})
export class FactoryInEntryComponent implements OnInit {

  ifSubmit: boolean;
  subTotalAmount:number=0;
  public salesOrderCount:any = [];
  public salesOrders:any = [{}];
  public factoryInForm: FormGroup;
  public ProductionInItem: FormArray;
  totInQuantity = 0;
  materialList=[];
  productList=[];
  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private router: Router,
    private toaster: ToastrService,
    private pService: FactoryProductionService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getProducts();
  }

  getProducts = () => { 
    this.pService.GetRawMaterials().subscribe((x) => {
      this.materialList = x;
    });
    this.pService.GetFinishProducts().subscribe((x) => {
      this.productList = x;
    });
  }

  initializeForm() {
    this.factoryInForm = this.fb.group({
      Id:[0],
      TransactionNo:[''],
      InDate: ['',Validators.required],
      ReferrenceNo: [''],
      Description: ['',Validators.required],
      StatusId: [''],
      ProductionInItem: this.fb.array([ this.createItem() ])
    });
    this.factoryInForm.get('InDate').patchValue(new Date());
    this.show(this.factoryInForm.value.InDate);
  }
 public createItem(): FormGroup {
    return this.fb.group({
      Id: 0,
      MaterialId: [null, Validators.required],
      ProductId: [''],
      SalesOrderId: [''],
      InQuantity: [0, Validators.required],
      PricePerUnit: [0, Validators.required],
      LineTotal: [0, Validators.required],
      StockQuantity: [0]
    });
  }

  public getSalesOrder(i:number,item:any){
    this.pService.GetFactoryOutSalesOrder(item.value.ProductId).subscribe((x) => {
      this.salesOrders[i] = x.salesOrders;
      this.salesOrderCount = x.salesOrderCount;
    });
  }
  public addItem(): void {
    this.ProductionInItem = this.factoryInForm.get('ProductionInItem') as FormArray;
    this.ProductionInItem.push(this.createItem());
  }

  public deleteItem(i:number){
    this.ProductionInItem.removeAt(i);
  }
  
  public updatePrice(i:number,item:any){
    let itemList = this.materialList.filter(x=>x.Id == item.value.MaterialId);console.log(itemList)
    if(itemList[0].StockQuantity<item.value.InQuantity){
      item.controls['InQuantity'].patchValue(0);
      item.controls['LineTotal'].patchValue(0);
      this.toaster.error("Used Qty must be less than or equal to Stock Qty");
    }
    else{
      var purchasePrice=0,stockQuantity = 0;
    if(itemList.length>0){
      purchasePrice=itemList[0].PurchasePrice;
      stockQuantity=itemList[0].StockQuantity;
    }
    item.controls['PricePerUnit'].patchValue(purchasePrice);
    item.controls['StockQuantity'].patchValue(stockQuantity);
    var total=purchasePrice* (isNaN(item.value.InQuantity)?0:item.value.InQuantity);
    item.controls['LineTotal'].patchValue(total);
    }
  }
   checkValidation = () =>
   {
    this.ProductionInItem = this.factoryInForm.get('ProductionInItem') as FormArray;
    for (const item of this.ProductionInItem.value)
    {

        this.totInQuantity = this.totInQuantity +  Number(item.InQuantity);

    }

   }
   public ApproveFactoryIn(){
    this.checkValidation();
    this.factoryInForm.get('StatusId').patchValue(2);
    if (this.factoryInForm.invalid){
      this.ifSubmit = true;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    if (this.totInQuantity <= 0)
    {
      this.toaster.warning('Total item qunatity can not be 0');
      return;
    }
    this.spinner.show();
    this.pService.ApproveProdIn(this.factoryInForm.value).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Factory In has been successfully approved.');
        this.router.navigate(['inventory/factory-in-list/All']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
   }
  /**
   * SaveFactoryIn
   */
  public SaveFactoryIn() {

    this.checkValidation();
    console.log(this.totInQuantity);
    this.factoryInForm.get('StatusId').patchValue(1);
    if (this.factoryInForm.invalid){
      this.ifSubmit = true;
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    if (this.totInQuantity === 0)
    {
      this.toaster.warning('Total item qunatity can not be 0');
      return;
    }
    this.spinner.show();
    this.pService.SaveProdInAsDraft(this.factoryInForm.value).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Factory In has been successfully added.');
        this.router.navigate(['inventory/factory-in-list/All']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  show(e) {
    const value = this.factoryInForm.value.InDate;
    $("#fitest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

}
