<app-dashboard>
    <app-page-header-title
    mainTitle="Change Password"
    subTitle="Home"
    backURL="/">
    <div class="full-width text-align-right">
        <button type="button" (click)="UpdateUserPasswordDetails()" class="eCount-def-btn ml-4">Save</button>
    </div>
    
  </app-page-header-title>
  <div class="col-sm-12">
    <form [formGroup]="changePasswordForm" class="d-flex">  
    <div class="col-sm-6 row">
 
            <div class="row col-sm-12">
            <div class="form-group col-sm-12 row">
                <label>Current Password<span class="clr"></span> </label>
                <input type="password" class="form-control"  placeholder="Current Password" formControlName="CurrentPassword">
            </div>
            </div>
            
            <div class="row col-sm-12">
            <div class="form-group row col-sm-12">
                <label>New<span class="clr"></span> </label>
                <input type="password" class="form-control" placeholder="New Password" formControlName="NewPassword">
                <span>At least 6 characters consisting of <b>small, capital and special characters.</b></span>
            </div>

            <div class="form-group row col-sm-12">
                <label>Re-type New<span class="clr"></span> </label>
                <input type="password" class="form-control" placeholder="Re-type Password" formControlName="RetypePassword">
            </div>
            
            <div class="form-group row col-sm-12">
                <!-- <a>Forgot your password?</a> -->
                <a [routerLink]="['/auth/forgot']" class="newStyle">
                    {{ 'AUTH.SIGNIN.forgotPassword' | translate }}
                  </a>
            </div>

        </div>
    </div>
    
</form>
</div>



 
</app-dashboard>

