import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }


  public GetSubscriptionDetails(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'SubscriptionPlan/GetPackages').pipe(retry(1));
  }

  public GetSubscriptionDepositBankAccount():Observable<any> {
    return this.http.get<any>(this.baseurl + 'SubscriptionPlan/GetDepositBank').pipe(retry(1));
  }

  public GetHistory():Observable<any> {
    return this.http.get<any>(this.baseurl + 'SubscriptionPlan/GetHistory').pipe(retry(1));
  }

public GetBillDetails():Observable<any> {
  return this.http.get<any>(this.baseurl + 'SubscriptionPlan/GetBillDetailsByCompany').pipe(retry(1));
}

public GetBillDetailsByCompanyAndActiveSubscription():Observable<any> {
  return this.http.get<any>(this.baseurl + 'SubscriptionPlan/GetBillDetailsByCompanyAndActiveSubscription').pipe(retry(1));
}

public saveSubscription(data:any): Observable<any> {
  return this.http.post<any>(this.baseurl + 'SubscriptionPlan/Save', data).pipe(retry(1));
}

public renewSubscription(data:any): Observable<any> {
  return this.http.post<any>(this.baseurl + 'SubscriptionPlan/Renew', data).pipe(retry(1));
}

public updateSubscription(data:any): Observable<any> {
  return this.http.post<any>(this.baseurl + 'SubscriptionPlan/Update', data).pipe(retry(1));
}

public saveAttachment(data: any): Observable<any> {
  return this.http.post<any>(this.baseurl + 'SubscriptionPlan/UploadFiles', data).pipe(retry(1));
}
public saveAttachmentWhenUpdate(data: any): Observable<any> {
  return this.http.post<any>(this.baseurl + 'SubscriptionPlan/UploadFilesWhenUpdate', data).pipe(retry(1));
}
public saveTagsAttachment(data: any): Observable<any> {
  return this.http.post<any>(this.baseurl + 'SubscriptionPlan/UploadTagFiles', data).pipe(retry(1));
}

public UpdateTagFiles(data: any): Observable<any> {
  return this.http.post<any>(this.baseurl + 'SubscriptionPlan/UpdateTagFiles', data);
}

public GetSubscriber(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'AdminPanelSubscription/GetSubscriptionByUser').pipe(retry(1));
}
public GetSubscriptionUser_ActionSubscription(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'AdminPanelSubscription/GetSubscriptionByUser_ActiveSubscription').pipe(retry(1));
}

public GetSubscriptionByUser_ActiveSubscriptionIsTrue(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'AdminPanelSubscription/GetSubscriptionByUser_ActiveSubscriptionIsTrue').pipe(retry(1));
}

public GetSubscriberByCompany(data: any): Observable<any> {
  console.log("Inside api -> "+data.CompanyName);
  return this.http.post<any>(this.baseurl + 'AdminPanelSubscription/GetSubscriptionByUserByCompany', data).pipe(retry(1));
}

public GetPackagesByBusinessType(data: any): Observable<any>{
  return this.http.post<any>(this.baseurl + 'SubscriptionPlan/GetPackagesByBusinessType', data).pipe(retry(1));
}

public saveTags(data: any): Observable<any> {
  return this.http.post<any>(this.baseurl + 'Settings/SaveCompanyTags', data).pipe(retry(1));
}
public GetAllTags(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'Settings/GetAllTags').pipe(retry(1));
}
public GetAllCustomer(): Observable<any> {
  return this.http.get<any>(this.baseurl + 'Contact/GetAll')
    .pipe(
      retry(1)
    );
}

  public GetCompanySubscriptionByUser(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'AdminPanelSubscription/GetCompanySubscriptionByUser').pipe(retry(1));
  }
}
