import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup,FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { SalesOrderService } from 'src/app/service/SalesOrder/sales-order.service';

@Component({
  selector: 'app-view-item',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.css']
})
export class ViewItemComponent implements OnInit {

  @Input() fromParent: any;
  public shipmentForm: FormGroup;
  itemName:string;
  itemDescription:string;
  itemPrice:number;
  public statusId:number=0;
  public OrderShipmentItem: FormArray;

  constructor(
    public fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService, 
    private salesService: SalesOrderService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm() {
    this.shipmentForm = this.fb.group({
      OrderShipmentItem: this.fb.array([])
    });
    this.getShipmentDetails(this.fromParent.ShipmentId);
  }

  getShipmentDetails = (id:any) => {
    this.spinner.show();
    this.salesService.GetShipmentDetails(id).subscribe((x) => {
      if (x) {
          this.statusId = x.StatusId;
          x.OrderShipmentItem.forEach(item => {
          this.addItem(item);
        });
        this.spinner.hide();
      }
    })
  }
  public addItem(x:any): void {
    this.OrderShipmentItem = this.shipmentForm.get('OrderShipmentItem') as FormArray;
    this.OrderShipmentItem.push(this.createItem(x));
  }
  public createItem(x:any): FormGroup {
    return this.fb.group({
      ItemName:[x.ItemName],
      Description:[x.Description],
      Quantity:[x.Quantity],
      TotalAmount:[x.TotalAmount]
    });
  }
}
