import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { AssetTypeCreateComponent } from '../asset-type-create/asset-type-create.component';
import { ChangeStartDateComponent } from '../change-start-date/change-start-date.component';
import { AssetTypeModel } from '../../../models/fixedAsset.model';

@Component({
  selector: 'app-asset-type-list',
  templateUrl: './asset-type-list.component.html',
  styleUrls: ['./asset-type-list.component.css']
})
export class AssetTypeListComponent implements OnInit {
  public aModel : AssetTypeModel= new AssetTypeModel();
  public assetTypeList: any = [];
  constructor(
    private pService:FixedAssetService,
    private toaster:ToastrService, 
    private spinner: NgxSpinnerService,
    private router:Router,
    private modalService:NgbModal) { }

  ngOnInit(): void {
    this.getTypeList();
  }

  private getTypeList = () => {
    this.spinner.show()
    this.pService.GetFixedAssetTypeList().subscribe((x) => {
      this.assetTypeList = x;
      this.spinner.hide()
    },(err)=>{
      this.toaster.error("There is an error,please check");
      this.spinner.hide();
    })
  };

  public ChangeStartDate=()=>{
    const modalRef = this.modalService.open(ChangeStartDateComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered : true});
    modalRef.result.then((result) => {
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  };


  public CreateNewAssetType=()=>{
    const modalRef = this.modalService.open(AssetTypeCreateComponent,{ size: 'md',backdrop: 'static', keyboard : false});
    modalRef.componentInstance.fromParent = this.aModel;
    modalRef.result.then((result) => {
                   this.AddItemToList(result);
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  };

  /**
   * editAssetType
item:any   */
  public editAssetType(item:AssetTypeModel) {
    const modalRef = this.modalService.open(AssetTypeCreateComponent,{ size: 'md',backdrop: 'static', keyboard : false});
    modalRef.componentInstance.fromParent = item;
    modalRef.result.then((result) => {
                  this.UpdateItemInList(result);
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }

  private AddItemToList(x:any) {
    if(x.Success){
      this.assetTypeList.push(x.Data);
     }
  }

  private UpdateItemInList=function(result: any) {
    var d=result.Data;
    if(result.Success){
      for(let i=0;i<this.assetTypeList.length;i++){
        if(this.assetTypeList[i].Id==d.Id){
          this.assetTypeList[i]=d;
        }
      }
    }
  }

}
