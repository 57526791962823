<app-dashboard>
    <app-page-header-title mainTitle="PO/ {{PurchaseOrderNo}} / {{StatusName}}" subTitle="Purchase Order Overview"  backURL="/procurement/purchase-order-list/All">
      <div class="full-width text-align-right">
        <div class="dropdown">
          <button id="attachment_btn" style="z-index: 1;" type="button" class="btn attachment-button"><i id="attachment_btn"
            style="font-size: 20px !important;" class="material-icons">insert_drive_file</i><span id="attachment_btn"
            style="color:darkgrey; font-size: 12px;"
            *ngIf="totalFile>=1">{{totalFile}}</span></button>
          <div class="x-tip-tl" style="height: 0px">
            <i id="custom_notch" class="material-icons custom_notch"
              >arrow_drop_up</i
            >
          </div>
          <div id="attachmentDropdown" class="dropdown-content" style="position: absolute;background-color: none;overflow: visible;">
            <app-attachment-uploader (noOfFiles)="setTotalFile($event)" [module]="5"></app-attachment-uploader>
        </div>
        </div>
        <button *ngIf="StatusId != 3" type="button" (click)="saveAsAwaitingApproval()" class="eCount-def-btn ml-4">Save</button>
        <button *ngIf="StatusId != 3" type="button" style="background: #5cac00;" (click)="saveAsApproved()" class="eCount-def-btn ml-4">Approve</button>
        <button *ngIf="StatusId == 3" type="button" (click)="update()" class="eCount-def-btn ml-4">Update</button>
      </div>
    </app-page-header-title>
    <div class="headSection">
      <form [formGroup]="purchaseOrderForm" class="f-ss-14px">
        <div class="row f-ss-14px">
            <div class="col-md-2 col-sm-12">
                <label for="ContactId">To<span class="required-sign">*</span></label>
                <ng-select [items]="supplierList" [clearable]="false" bindLabel="CompanyName" bindValue="Id"
                [addTag]="CreateNewSupplier" [loading]="loading" formControlName="ContactId" [ngClass]="{'submitted':contactIdRequired===false}" required>
                  <ng-template ng-tag-tmp let-search="searchTerm">
                      <b>+ Add New Supplier</b>: {{search}}
                  </ng-template>
                </ng-select>
              </div>
          <div class="col-md-2 col-sm-12">
            <label for="OrderDate">Order Date<span class="required-sign">*</span></label>
            <input
              class="form-control"
              type="date"
              formControlName="OrderDate"
              id="itest" data-date=""
              (change)="show($event)"
              data-date-format="D MMM YYYY"
              required
            />
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="DeliveryDate">Delivery Date</label>
            <input
              class="form-control"
              [ngClass]="{'submitted':deliveryDateRequired===false}"
              type="date"
              formControlName="DeliveryDate"
              id="itest1" data-date=""
              (change)="showSelectedDate($event)"
              data-date-format="D MMM YYYY"
              required
            />
          </div>

          <div class="col-md-2 col-sm-12">
            <label for="ReferenceNo">Reference</label>
            <input
              class="form-controlinputBox"
              type="text"
              formControlName="ReferenceNo"
            />
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="InDate">Order No</label>
            <span class="form-controlinputBox d-flex">
              <label style="background-color: #dddddd;padding-bottom:10%; margin: 0; padding: 0;">PO-{{ShowOrderNo}}</label>
              <input class="form-controlinputBox2" formControlName="OrderNo" type="text">
            </span>
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="InDate">Amounts Are</label>
            <ng-select [items]="taxTypeList" (change)="updateTotalPrice()" [clearable]="false" bindLabel="Name" bindValue="Id" formControlName="TaxTypeId"></ng-select>
          </div>
        </div>

        <div class="row d-block topSection f-ss-14px">
          <div class="d-flex">
            <div class="col-md-1" [ngClass]="{'col-md-2': isDescriptivePurchaseOrder()}">Item</div>
            <div class="col-md-2">Description</div>
            <div class="col-md-2">Account</div>
            <div class="col-md-1">Qty</div>
            <div *ngIf="isDescriptivePurchaseOrder() == false" class="col-md-1">Received</div>
            <div class="col-md-1">Price</div>
            <div class="col-md-1">VAT%</div>
            <div class="col-md-1">AIT%</div>
            <div class="col-md-2">Amount</div>
          </div>
          <hr class="pb-0 mb-0" />
          <div formArrayName="PurchaseOrderItems" *ngFor="let item of purchaseOrderForm.get('PurchaseOrderItems')['controls']; let i = index;">

             <div class="invoiceGridItemRow d-block">
              <div class="row pb-1 pt-2" [formGroupName]="i">
                  <div class="col-md-1 margin-top-5" [ngClass]="{'col-md-2': isDescriptivePurchaseOrder()}">
                    <ng-select [items]="productList" placeholder="Select Items" #selectItem
                      class="customSaleItem" [clearable]="false" bindLabel="ItemName" bindValue="Id"
                      (change)="changespurchaseableItems(i)" formControlName="ItemId">
                      <ng-template ng-header-tmp>
                          <a (click)="openItemModal(selectItem)" role="button" aria-haspopup="false"
                              aria-expanded="false"><span style="color: #007bff;" >+ Add
                                  Item</span></a>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="col-md-2 margin-top-5">
                    <input  class="form-control" formControlName="Description" [ngClass]="{'submitted':descriptionRequired===false}">
                </div>
                <div class="col-md-2 margin-top-5">
                    <ng-select [items]="accountList" [clearable]="false" bindLabel="AccountName" bindValue="Id" formControlName="AccountId"></ng-select>
                </div>
                  <div class="col-md-1 margin-top-5">
                    <input  class="form-control" (change)="updatePrice(i,item)" appDecimaNumericValidator formControlName="Quantity" [ngClass]="{'submitted':qtyRequired===false}">
                  </div>
                  <div *ngIf="isDescriptivePurchaseOrder() == false" class="col-md-1 margin-top-5">
                    <input class="form-control" type="text" appDecimaNumericValidator
                    formControlName="ReceivedItems"
                     [ngStyle]="{'color': isReceivedMax(i)? 'red' : '#444'}" required readonly>
                </div>
                  <div class="col-md-1 margin-top-5">
                    <input  class="form-control" (change)="updatePrice(i,item)" appDecimaNumericValidator formControlName="UnitPrice" [ngClass]="{'submitted':unitPriceRequired===false}">
                  </div>
                  <div class="col-md-1 margin-top-5">
                    <ng-select [items]="taxRateList" [clearable]="false" (change)="updateTotalPrice()" [readonly]="purchaseOrderForm.value.TaxTypeId==3" bindLabel="Parcent" bindValue="Id" formControlName="TaxRateId"></ng-select>
                </div>
                <div class="col-md-1 margin-top-5">
                    <ng-select [items]="taxRateList" [clearable]="false" (change)="updateTotalPrice()" [readonly]="purchaseOrderForm.value.TaxTypeId==3" bindLabel="Parcent" bindValue="Id" formControlName="AdvanceTaxRateId"></ng-select>
                </div>

                  <div class="col-md-2 margin-top-5 d-flex">
                      <div>
                          <input  class="form-control" readonly formControlName="LineTotal">
                      </div>

                      <div style="padding: 7px 10px; color: #c7c2c2;">
                          <div class="dropdown mr-4">
                              <div role="button" data-toggle="dropdown" aria-haspopup="false"
                                  aria-expanded="false">
                                  <i class="icon-more float-right"></i>
                              </div>
                              <div class="dropdown-menu" aria-labelledby="navbarDropdown"
                                  style="width: 100%;cursor: pointer;">
                                  <div class="productRow pb-3" (click)="deleteItem(i)">
                                      <span class="lead1">Remove Item </span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="row addItem">
            <div class="BottomAddItem w-100">
              <div class="dropdown mr-4">
                <a
                  class="nav-link"
                  style="background-color: white"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  (click)="addBlankItem()"
                >
                  <img
                    class="languageImg"
                    src="../../../assets/image/addIcon.png"
                  />
                  Add New Line
                </a>
              </div>
            </div>
          </div>

        </div>
        <div class="container-bottom d-flex">
          <div class="col-md-8 col-sm-12"></div>
            <div class="col-md-4 col-sm-12 p-0 d-block">
              <div class="d-flex w-100">
                  <div class="col-md-8">
                      <label>Subtotal</label>
                  </div>
                  <div class="col-md-4">{{subTotalAmount.toFixed(2) | currency:'':''}}</div>
              </div>
              <div class="d-flex w-100 pb-1" *ngIf="purchaseOrderForm.value.TaxTypeId!=3">
                  <div class="col-md-8">
                      <label>VAT</label>
                  </div>
                  <div class="col-md-4">{{TaxAmount.toFixed(2) | currency:'':''}}</div>
              </div>
              <div class="d-flex w-100 pb-1" *ngIf="purchaseOrderForm.value.TaxTypeId!=3">
                  <div class="col-md-8">
                      <label>AIT</label>
                  </div>
                  <div class="col-md-4">{{AdvanceTaxAmount.toFixed(2) | currency:'':''}}</div>
              </div>
              <div class="d-flex w-100 equalLine">
                  <span></span>
              </div>
              <div class="d-flex w-100 pt-3">
                  <div class="col-md-8">
                      <label>TOTAL</label>
                  </div>
                  <div class="col-md-4 font-weight-bold">{{totalPrice.toFixed(2) | currency:'':''}}</div>
              </div>
          </div>
        </div>
        <div class="container-bottom d-flex">
          <div class="col-md-9 col-sm-12">
            <p>Delivery Details</p>
            <div class="row">
              <div class="col-md-4 col-sm-5">
                <label for="InDate">Contact No.</label>
                <input
                  class="form-controlinputBox"
                  type="text"
                  appDecimaNumericValidator
                  formControlName="DeliveryContactNo"
                />
              </div>
              <div class="col-md-1 col-sm-2"></div>
              <div class="col-md-4 ml-2 col-sm-5">
                <label for="InDate">Attentions</label>
                <input
                  class="form-controlinputBox"
                  type="text"
                  formControlName="DeliveryAttentions"
                />
              </div>
            </div>
            <div style="margin-top: 20px;" class="row">
              <div class="col-md-4 col-sm-5">
                <label>Address</label>
                <textarea maxlength="350"  rows="3" type="text" class="form-control"   formControlName="DeliveryAddress"></textarea>
              </div>
              <div class="col-md-1 col-sm-2"></div>
              <div class="col-md-4 ml-2 col-sm-5">
                <label>Delivery Instructions</label>
                <textarea maxlength="250"  rows="3" type="text" class="form-control"   formControlName="DeliveryInstructions"></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 p-0 d-block"></div>
        </div>
        <div style="margin-top: 20px;width: 57%;" class="col-md-7 col-sm-12">
          <label>Notes</label>
          <textarea #myInput rows="5" maxlength="500" type="text" class="form-control brs custom-textarea" id="this-textarea"
                  placeholder="Write down some important notes using only 500 characters" formControlName="Notes"></textarea>
            <span class="remaning" >{{myInput.value.length}}/500</span>
        </div>
      </form>
    </div>
  </app-dashboard>
