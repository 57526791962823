import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvanceService } from '../../../../../service/Advance/advance.service'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
@Component({
  selector: 'app-advance-view',
  templateUrl: './advance-view.component.html',
  styleUrls: ['./advance-view.component.css']
})
export class AdvanceViewComponent implements OnInit {
  advanceTypeList: [];
  contactList: [];
  accountList: [];
  advNo: string;
  ifDetails = true;
  ifHistory = false;
  @Input() fromParent: any;
  @Input() childData: any;
  @Input() salesOrderAdvanceHistory: any;
  advanceHistory: boolean = false;
  public advanceForm: FormGroup;
  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private advanceService: AdvanceService,
    private modalservice: NgbModal
    ) { }

  ngOnInit(): void {
    this.advNo = this.fromParent.AdvNo;
    this.advanceHistory = this.salesOrderAdvanceHistory;
    this.initializeForm();
    this.getMasterData();
    console.log(this.childData);
  }


  getMasterData(){
    this.advanceService.GetAdvanceTypeList().subscribe((x: []) => {
      this.advanceTypeList = x;
    });
    if (this.fromParent.TransactionTypeId == 1){
    this.advanceService.GetAllCustomer().subscribe((x: []) => {
      this.contactList = x;
    });
  }
  else
  {
    this.advanceService.GetAllSupplier().subscribe((x: []) => {
      this.contactList = x;
    });
  }
    this.advanceService.GetBankAccounts().subscribe((x: []) => {
      this.accountList = x;
    });
  }
  initializeForm() {
    this.advanceForm = this.fb.group({
      Id:0,
      TransactionTypeId:[this.fromParent.TransactionTypeId],
      TransactionDate: [this.fromParent.TransactionDate],
      ContactId: [this.fromParent.ContactId],
      AccountId: [this.fromParent.AccountId],
      Description:[this.fromParent.Description],
      Amount: [this.fromParent.Amount]
    });
    this.showDefaultDate(this.fromParent.TransactionDate);
  }
  showDefaultDate(e) {
    const value = this.advanceForm.value.TransactionDate;
    $("#advtest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

  changeStatus = (value) => {

    if (value == 0)
    {
      this.ifDetails = true;
      this.ifHistory = false;
    }
    else
    {
      this.ifDetails = false;
      this.ifHistory = true;
    }
  }
}
