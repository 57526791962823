import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BillsServiceService } from '../../../service/Bill/bills-service.service'
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service'
import { ToastrService } from 'ngx-toastr';
import  jspdf from 'jspdf';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { SendBillMailComponent } from '../send-bill-mail/send-bill-mail.component';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { BillAdvanceAdjustmentComponent } from '../bill-advance-adjustment/bill-advance-adjustment.component';
import {AlertService} from '../../../service/Alert/alert.service';
import { EditBillTransactionComponent } from '../edit-bill-transaction/edit-bill-transaction.component';
import { RejectPopupModalComponent } from '../../Reject-popup-modal/reject-popup-modal/reject-popup-modal.component';
import { async } from '@angular/core/testing';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';
@Component({
  selector: 'app-bill-details',
  templateUrl: './bill-details.component.html',
  styleUrls: ['./bill-details.component.css'],
  host: {
    '(document:click)': 'onWindowClick($event)',
  },
})
export class BillDetailsComponent implements OnInit {
  billFound = false;
  makePaymentForm: FormGroup;
  AddDiscountFrom: FormGroup;
  BillNo: number
  BillId: any;
  BillData: any = []
  chartOfAccount: any = []
  paidBill: any = []
  itemsTotalPaidAmount: number = 0
  isPaymentBtnVisible: boolean = true;
  allSaleableItem: any = [];
  isUploaded: boolean = false;
  uploadCounter: number;
  filesToUpload: number = 0;
  allInvoice: any = []
  baseurl = environment.baseUrl;
  baseFileUrl = environment.fileBaseUrl;
  userInfo: any = JSON.parse(localStorage.getItem("userInfo"));
  percentDone: number;
  isEditable: boolean = false;
  canApprove: boolean = false;
  AccountList:string ;
  accountTypeId = 0;
  IsSubmitable = false;
  PostingModuleId: number=null;
  ExternalTransactionId: number=null;
  IsRedirect: boolean = false;
  redirectUrl: string;
  taxRateList:any=[];
  PaymentModeList: any[];
  totalFile: number=0;
  startDate = new Date();
  bankAccounts: any;
  bankBalanceList: any;
  ifSubmit: boolean;
  totalDue: number = 90;
  ifFinalApproval:boolean = false;
  IsUserAtMaxApprovalLevel: boolean = false;
  isInAwaitingPaymentPhase: boolean = false;
  isInDraftingPhase: boolean = false;
  isInRejectPhase: boolean = false;
  isPartiallyPaid: boolean = false;
  isPartiallyReceived: boolean = false;
  isFullyReceived: boolean = false;
  isExcessReceived: boolean = false;
  ReferenceNo: any;
  isFromPurchaseOrder: boolean = false;
  billNumber: string;
  receiptData: number;
  PurchaseOrderNo: string;
  isPurchaseOrder: boolean = false;
  isBasicBill: any;
  lastPaymentHistory: any;
  deliveryPartnerPaymentReturnList = [];
  returnPayment: any = [];
  totalReturnedAmount: number = 0;
  repeatingTypeList:any=[
    {Id: 1, Name:"Daily"},
    {Id: 2, Name:"Weekly"},
    {Id: 3, Name:"Monthly"},
    {Id: 4, Name:"Yearly"}
  ];
  constructor(private route: ActivatedRoute,
    private router: Router,
    private billService: BillsServiceService,
    private ngxSmartModalService: NgxSmartModalService,
    public fb: FormBuilder,
    private accountingService: AcountingServiceService,
     private toaster: ToastrService,
     private modalService:NgbModal,
     private settingService:SettingsServiceService,
     private fileEmitterService:FileEmitterService,
     private alertService: AlertService,
     private spinner: NgxSpinnerService,
     private inventoryOverviewService: InventoryOverviewService) { }

  ngOnInit(): void {
    this.BillId = this.route.snapshot.paramMap.get("billID");
    this.PurchaseOrderNo = this.route.snapshot.paramMap.get("orderNo");
    let temp = this.BillId;
    this.BillId = '';

    for(let i=5;i<temp.length;i++){
      this.BillId+= temp[i].toString();
    }
    this.getBooleanData();
    this.getAllSaleableItem();
    this.getTaxRateList();
    this.getChartOfAccount();
    this.getBanks();
    this.initMakePayment();
    this.initDiscountFrom();
    this.getFinalApprovalData();
    this.getBillData();
    this.route.queryParams.subscribe(params => {
      this.IsRedirect = params.IsRedirect == undefined ? false : true;
      this.setRedirectUrl();
    });
  }

  public getBooleanData = async()=>{
    this.billService.GetIsUserAtMaxApprovalLevel().subscribe( (x) => {
      this.IsUserAtMaxApprovalLevel = x.IsUserAtMaxApprovalLevel
    });
  }
  private getFinalApprovalData = () =>{
    this.settingService.getFinalApproval().subscribe(x=>{
      this.ifFinalApproval = x;
      console.log(this.ifFinalApproval);
    })
  }

  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  }
  print = (bNo) => {
    let hostUrl = location.origin + "/#/";
    window.open(`${hostUrl}bill/preview/${bNo}`, '_blank');
  }

  getChartOfAccount=()=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

    })
  }
  getBanks = () =>
  {
    this.accountingService.GetBankAccounts().subscribe((s) => {
      this.bankAccounts = s;
      console.log(this.bankAccounts);
    });
  }
  getPaymentModeList(id)
  {
    this.makePaymentForm.get('PaymentModeId').patchValue(null);
    const value = this.bankBalanceList.filter(x => x.AccountId === id);
    const list = this.bankAccounts.filter(x => x.GLAccountId === id);
    this.PaymentModeList = list.length >= 1 ? list[0].PaymentModeList  : [];
    this.accountTypeId = list.length >= 1 ? list[0].AccountTypeId : 1;
  }
  GetPaymentsByBillId = (data) => {
    if(this.BillData.IsReverseTransaction){
      this.getBillReversePayment(data);
    }
    else {
      this.getBillPayment(data);
    }
  }

  getBillPayment(data: any) {
    this.spinner.show();
    this.billService.GetPaymentsByBillId(data).subscribe((x) => {
      this.spinner.hide();
      this.setUpPaymentData(x);
    });
  }

  getBillReversePayment(data: any) {
    this.spinner.show();
    this.billService.GetBillPaymentReturnList(data).subscribe((x) => {
      this.spinner.hide();
      this.setUpPaymentData(x);
    });
  }

  setUpPaymentData(x: any) {
    this.itemsTotalPaidAmount = 0;
    this.paidBill = x ;
    for (let j = 0; j < this.paidBill.length; j++) {
      this.itemsTotalPaidAmount += this.paidBill[j].PaidAmount;
    }
    if (this.BillData.BillAmount === (this.itemsTotalPaidAmount + this.BillData.DiscountAmount )) {
      this.isPaymentBtnVisible = false;
    }
  }

  GetBillPaymentReturnList = (id) => {
    this.spinner.show();
    this.billService.GetBillPaymentReturnList(id).subscribe((x) => {
      this.spinner.hide();
      this.deliveryPartnerPaymentReturnList = x.filter((xy) => xy.IsDeliveryPartner == true);
      this.returnPayment = x.filter((xy) => xy.IsDeliveryPartner != true);
      for (let j = 0; j < this.returnPayment.length; j++) {
        this.totalReturnedAmount += this.returnPayment[j].Amount;
      }
    });
  }

  public setTotalFile(tf: number) {
    this.totalFile = tf;
  }

  getBillData = () => {
    this.billService.getBillsPermissionByBillNo(this.BillId).subscribe((x) => {
      if (x) {
        console.log('this is data=> ',x)       
        this.BillData = x.data;
        this.lastPaymentHistory = x.lastPaymentHistory;
        this.isBasicBill = this.BillData.IsBasicBill;
        this.billNumber = this.BillData.BillNo;
        this.canApprove = x.canApprove;
        this.isPartiallyPaid = x.isPartiallyPaid;
        this.isPartiallyReceived = x.isPartiallyReceived;
        this.isFullyReceived = x.isFullyReceived;
        this.isExcessReceived = x.isExcessReceived;
        this.ReferenceNo = x.ReferenceNo;
        this.fileEmitterService.emitFileRefIdUpdate({id:this.BillData.Id});
        this.billFound = true;
        this.BillNo = this.BillData.Id;
        this.IsBillEditable();
        this.GetPaymentsByBillId(this.BillNo);
        if(!this.BillData.IsReverseTransaction){
          this.GetBillPaymentReturnList(this.BillData.Id);
        }
        this.isPaymentBtnVisible = this.BillData.StatusId == 3 ? true : false;
        if(this.BillData.ReferenceNo != null) {
          this.isFromPurchaseOrder = this.BillData.ReferenceNo[0] == 'P' ? true : false;
        }
        if(this.BillData.ReferenceNo != null && this.BillData.ReferenceNo.length == 10 && this.containsOnlyNumbers(this.BillData.ReferenceNo)){
          this.isPurchaseOrder = true; //remove it when reference no issue solve
        }
        this.PostingModuleId=this.BillData.PostingModuleId;
        this.ExternalTransactionId=this.BillData.ExternalTransactionId;
        this.setRedirectUrl();
        this.getAccountName(this.BillData.BillItems);
        this.isDescriptiveBill();
        this.getReceiptData(this.billNumber);
      }
    })
  }

  containsOnlyNumbers(referenceNO) {
    return /^[0-9]+$/.test(referenceNO);
  }

  isDescriptiveBill() {
    return !this.BillData?.BillItems?.some( x => x.ItemId != null );
  }

  getReceiptData(billNumber){
    this.inventoryOverviewService.GetReceiptOrderByBillNo(billNumber).subscribe((x) => {
      this.receiptData = x.length;
    });
  }

  getAccountName=(item)=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

        item.forEach(element =>
        {
          console.log(element.AccountId);
           var x=this.chartOfAccount.find(p=> p.Id===element.AccountId);
           element.AccountName=x.AccountName;

        });


      })
      return item;
  }

  IsBillEditable() {
    // this.isEditable = (this.BillData.PaidAmount == null) && (this.BillData.StatusId == 1 || this.BillData.StatusId == 7 || (this.BillData.StatusId == 3 && this.IsUserAtMaxApprovalLevel)) ? true : false;
    let isNotPartiallyPaid = !this.isPartiallyPaid;
    let isNotPartiallyReceived = !this.isPartiallyReceived;
    let isNotFullyReceived = !this.isFullyReceived;
    let isNotExcessReceived = !this.isExcessReceived;
    
    this.isInDraftingPhase = this.BillData.StatusId == 1;
    this.isInRejectPhase = this.BillData.StatusId == 7;
    this.isInAwaitingPaymentPhase = this.BillData.StatusId == 3;
    this.isEditable = (isNotFullyReceived) && (isNotPartiallyReceived) && (isNotPartiallyPaid) && (this.isInDraftingPhase || this.isInRejectPhase || (this.isInAwaitingPaymentPhase && this.IsUserAtMaxApprovalLevel));
    this.IsSubmitable = this.BillData.StatusId == 1 || this.BillData.StatusId == 7  ? true : false;
  }

  initMakePayment = () => {
    this.makePaymentForm = this.fb.group({
      BillId: [null],
      PaidDate: ['', Validators.required],
      ReferenceNo: [''],
      PaidAmount: ['', Validators.required],
      AccountId: [null, Validators.required],
      PaymentModeId: [''],
      SourceBankName: [''],
      SourceAccountName: [''],
      SourceAccountNo: ['']
    });
    this.makePaymentForm.get('PaidDate').patchValue(this.formatDate(new Date()));

  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  twoDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.00;
  }
  convetToPDF = (BillNo) => {
    var data = document.getElementById('printBill');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      var DocumentName = `Bill-${BillNo}.pdf`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    })
  }
  openMakePayment = () => {
    this.billService.GetAllBankAccountBalanceList().subscribe((x) => {
      this.bankBalanceList = x;
    });
    this.totalDue = this.twoDecimalPlace(this.BillData.BillAmount - (this.itemsTotalPaidAmount + this.BillData.DiscountAmount));
    this.makePaymentForm.get('PaidAmount').patchValue(this.totalDue);
    this.ngxSmartModalService.create('MakePayment', 'content').open();
    this.show(new Date());
  }
  submitMakePayment = () => {
    this.ifSubmit = true;
    const dueAmount = this.twoDecimalPlace(this.BillData.BillAmount - (this.itemsTotalPaidAmount + this.BillData.DiscountAmount));

    this.makePaymentForm.value.BillId = this.BillNo;
    if (!this.makePaymentForm.value.BillId || !this.makePaymentForm.value.PaidDate || !this.makePaymentForm.value.PaidAmount || !this.makePaymentForm.value.AccountId) {
      this.toaster.warning('All the fields are required !');
      return;
    }

    if (this.makePaymentForm.value.PaidAmount > dueAmount) {
      this.toaster.warning('Payment always less or equal than due amount. !');
      return;
    }
    if(this.BillData.IsReverseTransaction) {
      this.spinner.show()
      this.billService.ReversePayment(this.makePaymentForm.value).subscribe((x) => {
        if (x.Success) {
          this.toaster.success('Payment has been done successfully.');
          this.ifSubmit = false;
          this.ngxSmartModalService.getModal('MakePayment').close();
          this.initMakePayment();
          this.spinner.hide();
          this.getBillData();
        } else {
          this.toaster.error('Something went wrong !');
          this.spinner.hide()
        }
      }, (err) => {
        this.toaster.error('Something went wrong !');
        this.spinner.hide()
      })
    }
    else {
      this.spinner.show()
      this.billService.saveMakePayment(this.makePaymentForm.value).subscribe((x) => {
        if (x.Success) {
          if(this.BillData.IsResetted != true){
            this.toaster.success('Payment has been done successfully.');
          }
          if(this.BillData.IsResetted == true){
            this.reversePayment();
          }
          this.ifSubmit = false;
          this.ngxSmartModalService.getModal('MakePayment').close();
          this.initMakePayment();
          this.spinner.hide();
          this.getBillData();
        } else {
          this.toaster.error('Something went wrong !');
          this.spinner.hide()
        }
      }, (err) => {
        this.toaster.error('Something went wrong !');
        this.spinner.hide()
      })
    }
  }

  reversePayment() {
    let data = this.makePaymentForm.value.value;
    data.Amount = this.lastPaymentHistory.Amount - data.Amount;
    this.spinner.show()
    this.billService.ReversePayment(data).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Payment has been done successfully.');
        this.ifSubmit = false;
        this.ngxSmartModalService.getModal('MakePayment').close();
        this.initMakePayment();
        this.spinner.hide();
        this.getBillData();
      } else {
        this.toaster.error('Something went wrong !');
        this.spinner.hide()
      }
    }, (err) => {
      this.toaster.error('Something went wrong !');
      this.spinner.hide()
    })
  }

  formatItem(id: string) {

    let value = this.allSaleableItem.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.ItemName;
    }
  }

  formatTaxRate(id: number) {

    let value = this.taxRateList.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }
  submitBillData = () => {
    this.BillData.StatusId = 2;
    this.BillData.IQuidiActionType = iQuidiActionType.Submit;
    this.spinner.show();
    this.billService.UpdateBillStatus(this.BillData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Bill ${this.BillData.BillNo} has been successfully submitted`, 'Success!');
        this.router.navigate([this.redirectUrl]);
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }
  approvedtBillData() {
    this.spinner.show();
    this.BillData.IQuidiActionType = iQuidiActionType.Approve;
    this.billService.UpdateBillStatus(this.BillData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Bill ${this.BillData.BillNo} has been successfully Approved`, 'Success!');
        if (x.Success)
        {
          this.router.navigate([this.redirectUrl]);
        }
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }


  private openAdvanceAdjustmentPopup(x:any) {
    const modalRef = this.modalService.open(BillAdvanceAdjustmentComponent,{ size: 'md',backdrop: 'static', keyboard : false,});
    modalRef.componentInstance.fromParent = x.BalanceData;
    modalRef.componentInstance.bankAccounts = this.bankAccounts;
    modalRef.result.then((result) => {
      if(result.Success){
        this.getBillData();
      }
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });
  }

  /**
   * makeAdjustment
   */
  public makeAdjustment() {
    this.spinner.show();
    this.billService.getSupplierBalance(this.BillData).subscribe((x) => {
      this.openAdvanceAdjustmentPopup(x);
      this.spinner.hide();
    });
  }

  confirmBillDeletion()
  {
    this.alertService.
    confirm('Do you want to delete this bill?This process can not be undone.', '', `Bill No: ${this.BillId}`, '')
    .then((confirmed) => {
      if (confirmed){
        if( !this.PurchaseOrderNo ) //if purchase_order_no is not set in the route.
          this.deleteBill();
        else
          this.deleteBillForPurchaseOrder();
      }
    },
      err => {
    }
    ).catch(() =>
    {this.spinner.hide();
    });
  }
   confirmBillRejection()
  {
    this.BillData.IQuidiActionType = iQuidiActionType.Reject;
    const modalRef =
    this.modalService.open(RejectPopupModalComponent, { size: 'md', backdrop: 'static', keyboard : false, centered: true });

    modalRef.componentInstance.data = this.BillData;
    modalRef.componentInstance.TypeId = 1;
    modalRef.result.then((result) => {
      if (result == true)
      {
        this.getBillData();
      }
      },
      (reason) =>
      {
        console.log('Dismissed action: ' + reason);
       });
  }

  deleteBill() {
    this.spinner.show();
    this.BillData.IQuidiActionType = iQuidiActionType.Delete;
    this.billService.UpdateBillStatus(this.BillData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Bill ${this.BillData.BillNo} has been successfully deleted`, 'Success!');
        this.router.navigate([this.redirectUrl]);
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  deleteBillForPurchaseOrder() {
    this.spinner.show();
    this.billService.deleteBillDataForPurchaseOrder(this.BillData.Id).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Bill ${this.BillData.BillNo} has been successfully deleted`, 'Success!');
        this.router.navigate([this.redirectUrl]);
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  public setRedirectUrl(){
    let rUrl='expense/bill-overview/All';
    this.route.queryParams.subscribe(params => {
      this.IsRedirect = params.IsRedirect == undefined ? false : true;
      if(this.PostingModuleId==2 && this.IsRedirect){ //here 2 means project module
        rUrl='project/details/'+this.ExternalTransactionId;
      }
      this.redirectUrl=rUrl;
    });
  }

  getAllSaleableItem = () => {
    this.billService.GetPurchaseableItems().subscribe((x) =>
      this.allSaleableItem = x)
  }
  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }

  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id == "Preview")) {

    }
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown()
    }
    else {
      let val = "do something";
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }

  public sendMail() {

    var v=document.getElementById('my').classList.add('mystyle');
    var v1=document.getElementById('my1').classList.add('mystyle');
    var v1=document.getElementById('my2').classList.add('mr');
    var data = document.getElementById('printBill');
    (html2canvas as any)(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 5;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      var blob = pdf.output('blob');

      const modalRef = this.modalService.open(SendBillMailComponent,{ size: 'md',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = {Id:this.BillData.Id,RefNo:this.BillData.BillNo,PdfData:blob};
      modalRef.result.then((result) => {
        document.getElementById('my').classList.remove('mystyle');
        document.getElementById('my1').classList.remove('mystyle');
        document.getElementById('my2').classList.remove('mr');
                   }, (reason) => {
                     console.log('Dismissed action: ' + reason);
                   });
    });
  }
  openDiscountPopup = () => {
    this.totalDue = this.twoDecimalPlace(this.BillData.BillAmount - (this.itemsTotalPaidAmount + this.BillData.DiscountAmount ));
    this.AddDiscountFrom.get('AmountDue').patchValue(this.totalDue);
    this.AddDiscountFrom.get('AdditionalDiscountAmount').patchValue(this.BillData.DiscountAmount);
    console.log(this.BillData.DiscountAmount);
    if (this.BillData.DiscountAmount !== null) {
      this.AddDiscountFrom.controls['AdditionalDiscountAmount'].disable();
  }
    this.ngxSmartModalService.create('AddDiscount', 'content').open();
  }
  initDiscountFrom = () => {
    this.AddDiscountFrom = this.fb.group({
      AmountDue: [],
      DiscountAmount: ['', Validators.required],
      TotalAmount: [],
      AdditionalDiscountAmount: ['']
    });
  }
  DecreaseAddTotalAmount = () => {
    if (this.AddDiscountFrom.value.DiscountAmount > this.AddDiscountFrom.value.AmountDue){
      this.toaster.warning('Discount Amount can not exceed from Amount due' );
      this.AddDiscountFrom.get('DiscountAmount').patchValue('');
      this.AddDiscountFrom.get('TotalAmount').patchValue('');
      return;

    }
    const value = this.BillData.DiscountAmount === null ?
                                        this.AddDiscountFrom.value.AdditionalDiscountAmount : this.AddDiscountFrom.value.DiscountAmount;
    const result = this.AddDiscountFrom.value.AmountDue - value;
    this.AddDiscountFrom.get('TotalAmount').patchValue(result);
  }
  DecreaseTotalAmount = () => {
    if (this.AddDiscountFrom.value.AdditionalDiscountAmount > this.AddDiscountFrom.value.AmountDue){
      this.toaster.warning('Discount Amount can not exceed from Amount due' );
      this.AddDiscountFrom.get('AdditionalDiscountAmount').patchValue('');
      this.AddDiscountFrom.get('TotalAmount').patchValue('');
      return;

    }
    const value = this.BillData.DiscountAmount === null ?
                                        this.AddDiscountFrom.value.AdditionalDiscountAmount : this.AddDiscountFrom.value.DiscountAmount;
    const result = this.AddDiscountFrom.value.AmountDue - value;
    this.AddDiscountFrom.get('TotalAmount').patchValue(result);
  }

  submitDiscountAmount = () => {
    this.AddDiscountFrom.value.DiscountAmount = this.BillData.DiscountAmount !== null ?
                         this.AddDiscountFrom.value.DiscountAmount : this.AddDiscountFrom.value.AdditionalDiscountAmount;
    this.spinner.show();
    this.billService.SubmitDiscountAmount(this.BillData.Id, this.AddDiscountFrom.value.DiscountAmount).subscribe((x) => {
      if (x.Success) {
        this.spinner.hide();
        this.toaster.success('Discount Added successfully.');
        this.ngxSmartModalService.getModal('AddDiscount').close();
        this.initDiscountFrom();
        this.getBillData();
      } else {
        this.toaster.error('Something went wrong !');
        this.spinner.hide();
      }
    }, (err) => {
      this.toaster.error('Something went wrong !');
      this.spinner.hide();
    });
  }
  show(e) {
    const value = this.makePaymentForm.value.PaidDate;
    $("#bdtest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change")
  }

  editBillTransaction(item){

    const modalRef = this.modalService.open(EditBillTransactionComponent, { size: 'md', backdrop: 'static', keyboard : false});
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.bankAccounts = this.bankAccounts;
    modalRef.result.then((result) => {
      if (result.Success){
        this.getBillData();
      }
    }, (reason) => {
      console.log('Dismissed action: ' + reason);
    });

  }

  onEditableRoute(){
    this.router.navigate(['/expense/edit-bill',this.BillId]);
  }

  isReceivedMax(Quantity, ReceivedItems){
    if(ReceivedItems != Quantity && ReceivedItems != 0){
      return true;
    }
    else{
      return false;
    }
  }
  
  canShowReceiptsTab() {
    if( (this.isInAwaitingPaymentPhase || this.BillData.StatusId == 4) && this.PurchaseOrderNo == null && !this.isPurchaseOrder && this.isBasicBill != true && this.isDescriptiveBill() == false) {
      return true;
    }
    else{
      return false;
    }
  }

  isReceived() {
    if(this.BillData?.ReceivedItems?.every(x => x != 0)) {
      return true;
    } else {
      return false;
    }
  }

  ResetToDraft() {
    this.alertService.confirm("Do you want to reset this bill? This process can not be undone.", "", `Bill No: ${this.BillId}`, "").then((confirmed) => {
      if(confirmed)
      {
        this.ResetBill();
      }
    }, (err) => {})
    .catch(() => {
      this.spinner.hide();
    });
  }

  ResetBill() {
    this.spinner.show();
    this.billService.ResetToDraft(this.BillData).subscribe((x) => {
      if (x.Success) {
        this.spinner.hide();
        this.router.navigate(['/expense/edit-bill', this.BillId], { queryParams: {ActionType: iQuidiActionType.ResetToDraft} });
      } else {
        this.spinner.hide();
        this.toaster.warning(x.Message);
      }
    });
  }

  canShowEditDropdownButton() {
    // return this.isInAwaitingPaymentPhase && this.IsUserAtMaxApprovalLevel && this.isFromPurchaseOrder == false && !this.PurchaseOrderNo && !this.isPartiallyPaid && !this.isReceived();
    return this.isInAwaitingPaymentPhase && this.IsUserAtMaxApprovalLevel && this.isFromPurchaseOrder == false && !this.PurchaseOrderNo;
  }

  canShowDeleteDropdownButton() {
    return this.isEditable && this.PurchaseOrderNo == null && this.isPurchaseOrder == false && !this.isPartiallyPaid && !this.isReceived();
  }

  canShowResetToDraftDropdownButton() {
    return this.BillData?.StatusId == 4 && this.IsUserAtMaxApprovalLevel && this.BillData?.IsReverseTransaction == false && this.isFromPurchaseOrder == false && !this.PurchaseOrderNo && this.isPurchaseOrder == false && (this.BillData?.ReferenceNo != null ? (this.BillData?.ReferenceNo[0] != 'I') : false);
  }

  canShowDiscountDropdownButton() {
    return this.isPaymentBtnVisible;
  }

  canShowAdvanceAdjustmentDropdownButton() {
    return this.isPaymentBtnVisible;
  }

  canShowDropdown() {
    return this.canShowEditDropdownButton() || this.canShowDeleteDropdownButton() || this.canShowResetToDraftDropdownButton() || this.canShowDiscountDropdownButton() || this.canShowAdvanceAdjustmentDropdownButton();
  }

}
