
<app-dashboard>
    <app-page-header-title
        mainTitle="Inventory List"
        subTitle="Home"
        backURL="/">
        <button type="button" routerLink="/products/purchase-orders-create" class="btn eCount-button ml-4">Create</button>
        <button type="button" routerLink="/products/purchase-orders-create" class="btn eCount-button3 ml-4">Export</button>
    </app-page-header-title>
    <div class="bodySection">
    </div>
</app-dashboard>