import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer  } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderService } from 'src/app/service/PurchaseOrder/purchase-order.service';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { SendPurchaseOrderMailComponent } from '../send-purchase-order-mail/send-purchase-order-mail.component';

@Component({
  selector: 'app-purchase-order-preview',
  templateUrl: './purchase-order-preview.component.html',
  styleUrls: ['./purchase-order-preview.component.css']
})
export class PurchaseOrderPreviewComponent implements OnInit {


  @ViewChild('content') content: ElementRef;
  fileUrl: string = environment.fileBaseUrl;
  constructor(private route: ActivatedRoute,
    private mainService:MainServiceService,
    private settingsService: SettingsServiceService,
    private purchaseService: PurchaseOrderService,
    private accountingService: AcountingServiceService,
    public domSanitizer: DomSanitizer,
    private modalService:NgbModal,
    private spinner: NgxSpinnerService ) { }

  
  templates:any;
  PurchaseOrderData: any = [];
  allSaleableItem: any = [];
  chartOfAccount: any = [];
  taxRateList:any=[];
  dataSettings:any=[];
  headCompany = true;
  purchaseOrderId: string;
  PurchaseOrderNo: string;
  companyList:number;
  company:any;
  logoUrl:string='';
  toPhone:boolean=true;
  toAddress:boolean=true;
  fromPhone:boolean=true;
  fromAddress:boolean=true;
  detailsReference:boolean=true;
  detailsVat:boolean=true;
  otherNotes:boolean=true;
  website = true;
  logo = true;
  footCompany = true;
  otherTermsConditions:boolean=true;
  base64Data:string= 'iVBORw0KGgoAAAANSUhEUgAAAGMAAABQCAIAAACCt1zBAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACuSURBVHhe7dCBAAAADASh+Uv/FAK4ELrFNKWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlGpKNaWaUk2pplRTqinVlNkeEXB4lahgIQEAAAAASUVORK5CYII=';
  
  ngOnInit(): void {
    this.spinner.show();
    this.purchaseOrderId = this.route.snapshot.paramMap.get("id");
    this.getOrderDetails(this.purchaseOrderId);
    this.getAllSaleableItem();
    this.getTaxRateList();
    this.getChartOfAccount();
    this.getDataSettings();
    this.templates = JSON.parse(localStorage.getItem('tem'));
    if (!this.templates) {
      this.templates = {
        t1: true,
        t2: false
      }
    }
  }
  getChartOfAccount=()=> 
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;
      
    })
  }
  setValue(value){
    this.base64Data=value;
  }
  getBase64ImageFromUrl =async  (imageUrl) => {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
  
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
  
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
  
  getOrderDetails = (id:any) => {
    this.purchaseService.GetOrderDetails(id).subscribe((x) => {
      if (x) {
          this.PurchaseOrderData = x.Details;
          this.PurchaseOrderNo = x.Details.OrderNo;
          this.getAccountName(this.PurchaseOrderData.PurchaseOrderItems);
          this.getOrganizationData(x.Details.CompanyId);
      }
    })
  }
  getAccountName=(item)=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

        item.forEach(element => 
        {
           var x=this.chartOfAccount.find(p=> p.Id===element.AccountId);
           element.AccountName=x.AccountName;
           
        });
        
        
      })
      return item;
  }
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  private getTaxRateList = () => {
    this.settingsService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  }
  formatTaxRate(id: number) {

    let value = this.taxRateList.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }

  getAllSaleableItem = () => {
    this.purchaseService.GetPurchaseableItems().subscribe((x) => {
      this.allSaleableItem = x;
    })
  }
  getDataSettings = () => {
    this.settingsService.GetDataSettings().subscribe((x) => {
      this.dataSettings = x;
      for (let j = 0; j < this.dataSettings.length; j++) {
        if(this.dataSettings[j].TypeName=="To" && this.dataSettings[j].Name=="Phone" && this.dataSettings[j].IsChecked==true)
        {
          this.toPhone=false;
        }
        if(this.dataSettings[j].TypeName=="To" && this.dataSettings[j].Name=="Address" && this.dataSettings[j].IsChecked==true)
        {
          this.toAddress=false;
        }
        if(this.dataSettings[j].TypeName=="Footer" && this.dataSettings[j].Name=="Phone" && this.dataSettings[j].IsChecked==true)
        {
          this.fromPhone=false;
        }
        if(this.dataSettings[j].TypeName=="From" && this.dataSettings[j].Name=="Address" && this.dataSettings[j].IsChecked==true)
        {
          this.fromAddress=false;
        }
        if(this.dataSettings[j].TypeName=="Details" && this.dataSettings[j].Name=="Reference No" && this.dataSettings[j].IsChecked==true)
        {
          this.detailsReference=false;
        }
        if(this.dataSettings[j].TypeName=="Details" && this.dataSettings[j].Name=="VAT/AIT" && this.dataSettings[j].IsChecked==true)
        {
          this.detailsVat=false;
        }
        if(this.dataSettings[j].TypeName=="Others" && this.dataSettings[j].Name=="Notes" && this.dataSettings[j].IsChecked==true)
        {
          this.otherNotes=false;
        }
        if(this.dataSettings[j].TypeName=="Others" && this.dataSettings[j].Name=="Terms & Conditions" && this.dataSettings[j].IsChecked==true)
        {
          this.otherTermsConditions=false;
        }
        if (
          this.dataSettings[j].TypeName == "Footer" &&
          this.dataSettings[j].Name == "Website" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.website = false;
        }
        if (
          this.dataSettings[j].TypeName == "Footer" &&
          this.dataSettings[j].Name == "Company Name" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.footCompany = false;
        }
        if (
          this.dataSettings[j].TypeName == "Header" &&
          this.dataSettings[j].Name == "Logo" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.logo = false;
        }
        if (
          this.dataSettings[j].TypeName == "Header" &&
          this.dataSettings[j].Name == "Company Name" &&
          this.dataSettings[j].IsChecked == true
        ) {
          this.headCompany = false;
        }
      }
    });
  }
  formatItem(id: string) {
    let value = this.allSaleableItem?.find(e => e.Id == id);
    if (!value) {
    } else {
      //return value.ItemCode + "-" + value.ItemName;
      return value.ItemName;
    }
  }

  toDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.00;
  }
  print(){
    var originalTitle = document.title;
    document.title = "Print page title";
    window.print();
    document.title = originalTitle;
  }
  public sendMail() {
    var data = document.getElementById('printBill');
    (html2canvas as any)(data,{useCORS: true}).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      var blob = pdf.output('blob');
      const modalRef = this.modalService.open(SendPurchaseOrderMailComponent,{ size: 'md',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = {Id:this.purchaseOrderId,RefNo:this.PurchaseOrderNo,PdfData:blob};
      modalRef.result.then((result) => {
                    
                   }, (reason) => {
                     console.log('Dismissed action: ' + reason);
                   });
    })
  }
  download(){
    document.getElementById("printBill").classList.remove('box--shadow');
    var data = document.getElementById('printBill');
    (html2canvas as any)(data,{useCORS: true}).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      var DocumentName = `Purchase_Order-${this.PurchaseOrderNo}.pdf`;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(DocumentName);
    })
    document.getElementById("printBill").classList.add('box--shadow');
  }

  getOrganizationData(id:string){
    this.mainService.getSelectedOrganizationDetails(id).subscribe((x) => {
      this.company = x.Details;console.log(this.company)
      this.spinner.hide();
      this.logoUrl = (this.company.BlobName != "")&&(this.company.BlobName !=null) ? this.fileUrl + this.company.BlobName: "";
      console.log(this.logoUrl)
      this.getBase64ImageFromUrl(this.logoUrl)
      .then((result) => {
        this.setValue(result);
      })
      .catch(err => console.error(err));
      });
    }

}
