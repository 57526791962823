import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { TransfermoneyServiceService } from '../../../service/TransferMoney/transfermoney-service.service'
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { MainServiceService } from '../../../service/Main/main-service.service'
import * as moment from 'moment';


@Component({
  selector: 'app-view-transfer-money',
  templateUrl: './view-transfer-money.component.html',
  styleUrls: ['./view-transfer-money.component.css']
})
export class ViewTransferMoneyComponent implements OnInit {

  TransferMoneyForm: any={};
  bankList: any = [];
  selectedBankTo: number;
  selectedBankFrom: number;
  selectedDate: Date;
  bankAccountName: string;
  cashAccountName: string;
  ReferenceNo: string = "";
  Amount: string = "";
  TransferId: number;
  date:string;
  constructor(public fb: FormBuilder,
    private transfermoneyService: TransfermoneyServiceService,
    private toaster: ToastrService,
    private mainService: MainServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }
    private sub:any;

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.TransferId = +params['id'];
      console.log(this.TransferId);
      this.getDetails();
      this.getBankList();
   });
  }

 

  getBankList() {
    this.bankList = [];
    this.mainService.GetAllBank().subscribe((x) => {
      this.spinner.hide()
      this.bankList = x;
    })
  }
  convertDateIntoDisplay(date) {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  getDetails() {
    this.spinner.show();
   this.transfermoneyService.GetTransferMoney(this.TransferId).subscribe(x=>{
      this.TransferMoneyForm=x;
      this.bankAccountName= this.TransferMoneyForm.ReceiverChartOfAccount.AccountName;
      this.cashAccountName= this.TransferMoneyForm.SenderChartOfAccount.AccountName;
      this.date=this.convertDateIntoDisplay(this.TransferMoneyForm.TarnsferDate);
      this.spinner.hide();
   })
  }
}
