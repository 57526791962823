
<app-dashboard>
    <app-page-header-title
        mainTitle="General Ledger Summary"
        subTitle="Home"
        backURL="/">

    </app-page-header-title>
    <div class="row mrl  mr-4 col-md-12">
        <div class="form-group col-md-2">
            
            <input class="form-control" style="outline: none;" type="date" placeholder="From Date" [(ngModel)]="fromDate" id="gtest" data-date="" data-date-format="D MMM YYYY"  (change)="show($event)">
        </div>
        <div class="form-group col-md-2 ml">
            
            <input class="form-control" type="date" placeholder="To  Date" [(ngModel)]="toDate"  id="gtest1" data-date="" data-date-format="D MMM YYYY" (change)="showSelectedDate($event)">
        </div>
        <div  class="f-ss-14px col-md-4" >
            <button type="button" (click)="reportGenerate()" class="eCount-def-btn">Generate Report</button>
            
        </div>
    </div>
        
    
    <div id="printGeneralReport" class="customReportPage">
        <div class="report-header row">
            <div class="col-md-8">
            <div >
               <p class="txtsize">General Ledger Summary</p>
           </div>
           <div class="row ml-0">
               <span>{{companyName}}</span>
               
               <p><span class="ml-2 mr-2 f-ss-14px">|</span>For the period {{convertDateIntoDisplay(fromDate)}} to {{convertDateIntoDisplay(toDate)}}</p>
               
           </div>
           
           </div>
           <div class="col-md-4 mt-0" data-html2canvas-ignore="true">
               <span type="button" style="float: right;" class="ut" (click)="convetToPDF()"></span>
           </div>
   
        </div>
        <div>
            <div class="tableHeader d-flex">
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText">Account</p>
                </div>
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText float-right">Debit</p>
                </div>
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText float-right">Credit</p>
                </div>
                <div class="tableHeaderColumn w25Column">
                    <p class="tableHeaderText float-right">Net Movement</p>
                </div>
            </div>
        </div>
        <div class="AccountSection f-ss-14px" *ngFor="let item of ledgerData.ledgerList; let i=index" [ngClass]="{'lastRow': ledgerData.ledgerList.length == i+1}">
            <div class="d-flex normalRowSpaceing">
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText">{{item.AccountName}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right text-link" (click)="openAccountTransaction(item.AccountId)">{{item.Debit | currency:'':''  }}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right text-link" (click)="openAccountTransaction(item.AccountId)">{{item.Credit | currency:'':''}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right">{{item.NetMovementValue}}</span>
                </div>
            </div>
        </div>
        <div class="AccountSection f-ss-14px">
            <div class="d-flex normalRowSpaceing">
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText">Total</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right">{{ledgerData.TotalDebit | currency:'':''}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right">{{ledgerData.TotalCredit | currency:'':''}}</span>
                </div>
                <div class="tableHeaderColumn2 w25Column">
                    <span class="runningBalanceText float-right">{{ledgerData.TotalNetMovement}}</span>
                </div>
            </div>
        </div>
    </div>
</app-dashboard>