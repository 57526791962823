<app-dashboard>
    <app-page-header-title
        mainTitle="Fixed Assets Settings"
        subTitle="Back" 
        backURL="/fixed-asset/setup/draft">
    </app-page-header-title>
    <div class="full-width text-align-left">
        <div class="row">
            <div class="col-sm-8">
                <p style="font-weight: bold;">Track business assets to manage their depreciation and disposals</p>
            </div>
            <div class="col-sm-6">
                <button type="button" class="eCount-def-btn" (click)="ChangeStartDate()">Change Start Date</button>
                <button type="button" class="eCount-def-btn ml-4" [routerLink]="['/fixed-asset/draft']">Fixed Asset</button>
            </div>
        </div>
    </div>
    <div class="settingsNavBar">
        <ul class="nav nav-tabs" style="margin-bottom: 30px;">
            <li class="nav-item">
              <a class="nav-link" (click)="setActivePanel(1)" [ngClass]="{'active': activeTabId === 1}">Asset Types</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" (click)="setActivePanel(2)" [ngClass]="{'active': activeTabId === 2}">Accounts</a>
            </li>
        </ul>
    </div>
    <div class="row ml-2" *ngIf="activeTabId == 1">
        <div class="row" style="width: 100%;">
            <div class="w-100 col">
                <button type="button" class=" btn btn-outline-primary" (click)="AddAssetType()">+Asset Type</button>
                <table class="table  f-ss-14px">
                    <thead>
                        <tr class="tableHeader">
                            <th scope="col" class="table-header-color text-align-left col-md-2">Asset Type</th>
                            <th scope="col" class="table-header-color text-align-left col-md-3">Asset Account</th>
                            <th scope="col" class="table-header-color text-align-left col-md-4">Accumulated Depreciation Account</th>
                            <th scope="col" class="table-header-color text-align-left col-md-3">Depreciation Expense Account</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let assetType of AssetTypeList">
                            <td class="text-align-center" style="color: #02aced; cursor: pointer;" (click)="EditAssetType(assetType)">{{assetType.AssetType}}</td>
                            <td class="text-align-center">{{assetType.AssetAccountCode + " - " + assetType.AssetAccountName}}</td>
                            <td class="text-align-center">{{assetType.DepreciationAccountCode + " - " +assetType.DepreciationAccountName}}</td>
                            <td class="text-align-center">{{assetType.DepreciationExpenseAccountCode + " - " + assetType.DepreciationExpenseAccountName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="activeTabId == 2">
        <div class="row accountInput">
            <p class="col-md-12">Set default Chart of accounts for disposals. All future disposal transactions will have these options pre-selected</p>
        </div>
        <form [formGroup]="disposalAccountForm">
            <div class="row accountInput">
                <label class="col-md-2">Capital Gain on Disposal<span class="required-sign">*</span></label>
                <ng-select [items]="allChartOfAccount" placeholder="Select Account"
                        class="customSaleItem col-md-6" [clearable]="false" bindLabel="AccountName"
                        bindValue="Id" formControlName="CapitalGainAccountTypeId" groupBy="GroupName" required>
                </ng-select>
            </div>
            <div class="row accountInput">
                <label class="col-md-2">Gain on Disposal<span class="required-sign">*</span></label>
                <ng-select [items]="allChartOfAccount" placeholder="Select Account"
                        class="customSaleItem col-md-6" [clearable]="false" bindLabel="AccountName"
                        bindValue="Id" formControlName="GainAccountTypeId" groupBy="GroupName" required>
                </ng-select>
            </div>
            <div class="row accountInput">
                <label class="col-md-2">Loss on Disposal<span class="required-sign">*</span></label>
                <ng-select [items]="allChartOfAccount" placeholder="Select Account"
                        class="customSaleItem col-md-6" [clearable]="false" bindLabel="AccountName"
                        bindValue="Id" formControlName="LossAccountTypeId" groupBy="GroupName" required>
                </ng-select>
            </div>
            <div class="full-width text-align-right">
                <div class="row">
                    <div class="col-md-8">
                        <!-- <button type="button" class="eCount-def-btn">Cancel</button> -->
                        <button type="button" class="eCount-def-btn ml-4" (click)="SaveAccountMappings()">Save</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-dashboard>
