import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { customer } from './../../models/customer.model';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ReturnTimePolicy } from 'src/app/models/returnTimePolicy.model';
import { ResponseModel } from 'src/app/models/responseModel.model';
import { TagsAttachmentTag } from 'src/app/models/tagsAttachmentTag .model';
import { TagsAttachmentVersionsSetting } from 'src/app/models/tagsAttachmentVersionsSetting.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsServiceService {
  baseurl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  public saveSettings(data:any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/Save', data).pipe(retry(1));
  }

  public  GetConfigSettings(): Observable<customer[]> {
    return this.http.get<any>(this.baseurl + 'Settings/GetConfig').pipe(retry(1));
  }

  public GetPostingTypeList(): Observable<customer[]> {
    return this.http.get<any>(this.baseurl + 'Settings/GetPostingTypeList').pipe(retry(1));
  }

  public  GetOrganizationType(): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/GetOrganizationTypes`).pipe(retry(1));
  }

 public GetTaxRateList(): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/GetTaxRateList`).pipe(retry(1));
  }

  public getFinalApproval(): Observable<any>{
    return this.http.get<any>(this.baseurl + `Settings/GetFinalApproval`).pipe(retry(1));
  }

  public GetTaxDisplayList(): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/GetTaxDisplayList`).pipe(retry(1));
  }

 public SaveTaxRate(data:any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/SaveTaxRate', data).pipe(retry(1));
  }

  public GetTaxTypeList(): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/GetTaxTypeList`).pipe(retry(1));
  }
  public GetDataSettings(): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/GetDataSettings`).pipe(retry(1));
  }
  public SaveDataSettingsMaintenance(data:any): Observable<any> {
     return this.http.post<any>(this.baseurl + 'Settings/SaveDataSettingsMaintenance', data).pipe(retry(1));
   }
  public GetCompanyUsers(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetCompanyUsers').pipe(retry(1));
  }
  public GetAllUsers(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetAllUsers').pipe(retry(1));
  }
  public GetBillApprovalLevel(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetBillApprovalLevel').pipe(retry(1));
  }
  public GetDocumentApprovalLevel(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetDocumentApprovalLevel').pipe(retry(1));
  }
  public GetInvoiceApprovalLevel(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetInvoiceApprovalLevel').pipe(retry(1));
  }
  public GetCompanyRoles(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetCompanyRoles').pipe(retry(1));
  }
  public GetCompanyRolesWithBusinessUnit(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetCompanyRolesWithBusinessUnit').pipe(retry(1));
  }
  public SaveApprovalLevel(data:any): Observable<any> {
     return this.http.post<any>(this.baseurl + 'Settings/SaveApprovalLevel', data).pipe(retry(1));
   }
   DeleteApprovalLevelItem(id): Observable<any> {
    return this.http.get<any>(this.baseurl + `Settings/DeleteApprovalLevelItem?id=${id}`).pipe(retry(1));
  }
  public GetAllCompanyUserRole(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Menu/GetAllUserRuleByCompany').pipe(retry(1));
  }
  public GetAllMenuByUserRole(id): Observable<any>{
    return this.http.get<any>(this.baseurl + `Menu/GetAllMenuByUserRole?roleId=${id}`).pipe(retry(1));
  }
  public AddOrRemoveMenuPermission(id): Observable<any>{
    return this.http.post<any>(this.baseurl + 'Menu/AddOrRemoveMenuPermission', id).pipe(retry(1));
  }
  public GetAllRuleWiseUserList(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Menu/GetAllRuleWiseUserList').pipe(retry(1));
  }
  public SaveCompanyWiseUserRole(id): Observable<any>{
    return this.http.post<any>(this.baseurl + 'Menu/SaveCompanyWiseUserRole', id).pipe(retry(1));
  }
  public AddOrRemoveCompanyWiseUserRole(id): Observable<any>{
    return this.http.post<any>(this.baseurl + 'Menu/AddOrRemoveCompanyWiseUserRole', id).pipe(retry(1));
  }
  public CheckCompanyAdmin(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Company/CheckCompanyAdmin').pipe(retry(1));
  }

  public SaveReturnTimePolicy(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ReturnTimePolicy/Save', data);
  }

  public UpdateReturnTimePolicy(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'ReturnTimePolicy/Update', data);
  }

  public GetReturnTimePolicy(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'ReturnTimePolicy/GetReturnTimePolicy').pipe(retry(1));
  }

  public SaveDocumentType(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/SaveDocumentType', data).pipe(retry(1));
  }

  public GetAllDocumentTypes(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Settings/GetAllDocumentTypes').pipe(retry(1));
  }

  public GetCustomApprovalSettings(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetCustomApprovalSettings').pipe(retry(1));
  }

  public SaveCustomApprovalSettings(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/SaveCustomApprovalSettings', data);
  }

  public UpdateCustomApprovalSettings(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/UpdateCustomApprovalSettings', data);
  }

  public GetGrantLoanApprovalLevel(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetGrantLoanApprovalLevel').pipe(retry(1));
  }
  public GetTagAttachmentVersionSettings(): Observable<TagsAttachmentVersionsSetting>{
    return this.http.get<TagsAttachmentVersionsSetting>(this.baseurl + 'Settings/GetTagAttachmentVersionSettings');
  }
  public UpdateTagAttachmentVersionSettings(data: TagsAttachmentVersionsSetting): Observable<any>{
    return this.http.post<any>(this.baseurl + 'Settings/UpdateTagAttachmentVersionSettings', data);
  }

  public GetDocumentTypeGroupSettings(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetDocumentTypeGroupSettings');
  }

  public SaveDocumentTypeGroupSettings(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/SaveDocumentTypeGroupSettings', data);
  }

  public UpdateDocumentTypeGroupSettings(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/UpdateDocumentTypeGroupSettings', data);
  }

  public GetAllDocumentTypeGroups(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Settings/GetAllDocumentTypeGroups');
  }

  public SaveDocumentTypeGroup(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/SaveDocumentTypeGroup', data);
  }

  public UpdateDocumentType(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/UpdateDocumentType', data);
  }

  public GetOTPVerificationSettings(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'Settings/GetOTPVerificationSettings');
  }

  public SaveOTPVerificationSettings(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/SaveOTPVerificationSettings', data);
  }

  public UpdateOTPVerificationSettings(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/UpdateOTPVerificationSettings', data);
  }

  public GetAllDocumentInputFieldDynamicLabelName(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'Settings/GetAllDocumentInputFieldDynamicLabelName');
  }

  public UpdateDocumentInputFieldDynamicLabelName(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Settings/UpdateDocumentInputFieldDynamicLabelName', data);
  }

}
