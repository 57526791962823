<app-dashboard>
    <app-page-header-title mainTitle="Bills" subTitle="Existing Balances" backURL="/accounting/existing-balances">
        
        <div class="full-width text-align-right pr"><button type="button" (click)="openBillPopup()" class="eCount-def-btn ml-4">Add Bill</button>
        </div>
    </app-page-header-title>

            <div class="ml">
                <p class="container">Enter bill received on or before
                    {{convertDateIntoDisplay(previousConversionDate)}} that have not been fully paid</p>
                <div class="col-sm-12 pl-0 pr-0">
                    <div>
                        <ag-grid-angular #agGridbill id="myGridbill" class="ag-theme-balham col"
                            [columnDefs]="gridOptionsBill.columnDefs" [defaultColDef]="gridOptionsBill.defaultColDef"
                            [domLayout]="gridOptionsBill.domLayout" [rowData]="allBills"
                            (gridReady)="onGridReadyBill($event)" (rowClicked)="onBillRowClicked($event)">
                        </ag-grid-angular>
                    </div>
                    <div class="col-sm-12">
                        <div class="total f-ss-14px" style="margin-top:10px;width: 35%;">
                            <div class="subtotal no-border">
                                <label> Total BDT</label>
                                <span>{{TotalBillAmount}}</span>
                            </div>
                            <div class="goal f-ss-14px">
                                <label>BDT Accounts Payable Balance</label>
                                <span>{{TotalCredit}}</span>
                            </div>
                            <div class="bottom-line green" [hidden]='!IsBillMatch'>
                                <label><img src="../../../../assets/image/green-tick-icon.jpg"
                                        style="height: 15px;width: 15px;margin-left: 60px;margin-top: 4px;" />
                                    Matched</label>
                            </div>
                            <div class="bottom-line red f-ss-14px">
                                <label> Balance out by</label>
                                <span>{{PayableCalculateBalance}}</span>
                            </div>
                            <div class="red joined">
                                <span class="f-ss-14px">
                                    Add bills or credit notes to bring this balance to zero.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" style="display: inline-block;">
                        <button type="button"  [disabled]="btndisbaled" [class]="btndisbaled ? 'disableds': 'enabled'" style="float: right;" (click)="NextBtnClick()" class="eCount-def-btn ml-4">Next</button>
                    </div>
                </div>
            </div>
</app-dashboard>
