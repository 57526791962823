import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DocumentLockDetails } from 'src/app/models/documentLockDetails.model';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-document-lock-modal',
  templateUrl: './document-lock-modal.component.html',
  styleUrls: ['./document-lock-modal.component.css']
})

export class DocumentLockModalComponent implements OnInit {

  @Input() submittedAttachments;

  documentApprovalForm: FormGroup;

  approverRoleList: any = [];
  approverEmailList: any = [];
  userList: any = [];
  EmailList: any = [];

  documentLevel: number = 0;
  documentIndex: number = 0;
  approvalMenuId: number = 0;

  documentLevelName: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private pService: SettingsServiceService,
    private attachmentService: AttachmentServiceService
  ) { }

  ngOnInit(): void {
    this.initializeDocumentForm();
    this.getMasterDataList();
    this.getDocumentApprovalLevel();
    this.getCompanyUsers();
    this.getCompanyRolesWithBusinessUnit();
    this.getAllUsers();
  }

  initializeDocumentForm() {
    this.documentApprovalForm = this.fb.group({
      DocumentApprovalLevel: this.fb.array([]),
    });
  }

  getCompanyUsers() {
    this.spinner.show();
    this.pService.GetCompanyUsers().subscribe((x) => {
      this.EmailList = x.filter(y => y.RoleId != 1);
      this.approverEmailList = x.filter(y => y.RoleId != 1);
      this.addLevelDocumentItem(this.approverEmailList?.filter(y => y.RoleId != 1)[0], this.documentIndex = 0);
      this.documentIndex = this.documentIndex + 1;
      this.spinner.hide();
    });
  }

  getCompanyRolesWithBusinessUnit() {
    this.pService.GetCompanyRolesWithBusinessUnit().subscribe((x) => {
      this.approverRoleList = x.Result.filter(y => y.Name != 'PortalUser' && y.Name != 'Admin');
    });
  }

  getAllUsers() {
    this.pService.GetAllUsers().subscribe((x) => {
      this.userList = x;
    });
  }

  getMasterDataList() {
    
  }

  getDocumentApprovalLevel = () => {
    this.spinner.show();
    this.pService.GetDocumentApprovalLevel().subscribe((x) => {
      this.spinner.hide();
      this.documentIndex = 0;
      this.documentLevel = 0;
      this.addDocumentLevel(x[0]);
      this.documentLevel = this.documentLevel + 1;
      //this.addLevelDocumentItem(x[0].ApprovalLevelItem[0], this.documentIndex);
      //this.documentIndex = this.documentIndex + 1;
    });
  }

  addDocumentLevel(x: any) {
    this.DocumentApprovalLevel().push(this.newDocumentLevel(x));
  }

  DocumentApprovalLevel(): FormArray {
    return this.documentApprovalForm.get("DocumentApprovalLevel") as FormArray;
  }

  newDocumentLevel(x: any): FormGroup {
    this.documentLevelName = "Level " + x.LevelId;
    return this.fb.group({
      Id: [0],
      ApprovalMenuId: [x.ApprovalMenuId],
      CompanyId: [x.CompanyId],
      LevelId: [x.LevelId],
      LevelName: [this.documentLevelName],
      CustomApprovalLevelItem: this.fb.array([]),
    });
  }

  addLevelDocumentItem(x: any, levelIndex: number) {
    this.documentLevelItems(levelIndex)?.push(this.newLevelItem(x));
  }

  documentLevelItems(levelIndex: number): FormArray {
    return this.DocumentApprovalLevel().at(levelIndex).get("CustomApprovalLevelItem") as FormArray;
  }

  newLevelItem(x: any): FormGroup {
    return this.fb.group({
      Id: [0],
      ApprovalLevelId: [0],
      ApproverId: [x.RoleId],
      ApproverEmailId: [x.Id],
      ModifiedById: [],
      ModifiedAt: [],
    });
  }

  addBlankLevelDocumentItem(levelIndex: number) {
    this.documentLevelItems(levelIndex).push(this.newBlankLevelDocumentItem());
  }

  newBlankLevelDocumentItem(): FormGroup {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == 2);
    return this.fb.group({
      Id: 0,
      ApprovalLevelId: [0],
      ApproverId: [1, Validators.required],
      ApproverEmailId: ["", Validators.required],
      ModifiedById: [""],
      ModifiedAt: [null],
    });
  }

  ChangeEmailByDocumentRole = (id, index, levelid) => {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == id.value.ApproverId);
    const p = this.DocumentApprovalLevel().at(levelid).get('CustomApprovalLevelItem') as FormArray;
    p.at(index).get('ApproverEmailId').patchValue(this.approverEmailList[0]?.Id);
  }

  changeApproveIfDocumentRoleChange = (id, index, levelid) => {
    const p = this.DocumentApprovalLevel().at(levelid).get("CustomApprovalLevelItem") as FormArray;
    const value = this.EmailList.filter(x => x.Id === p.value[index].ApproverEmailId);
    if (value.length > 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].RoleId == p.at(index).get('ApproverId')) {
          p.at(index).get('ApproverId').patchValue(value[i].RoleId); break;
        }
      }
    }
    else {
      p.at(index).get('ApproverId').patchValue(value[0].RoleId);
    }

  }

  removeLevelDocumentItem(levelIndex: number, itemIndex: number, item: any) {
    this.documentLevelItems(levelIndex).removeAt(itemIndex);
  }

  saveCustomDocumentApprovalFlow() {
      this.spinner.show();
      this.pService.SaveApprovalLevel(this.documentApprovalForm.value.DocumentApprovalLevel).subscribe((x) => {
        if (x.Success) {
          this.approverEmailList = this.EmailList;
          this.initializeDocumentForm();
          this.getDocumentApprovalLevel();
          this.toaster.success("Saved successfully.");
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      });
  }

  isDocumentFormValid() {
    var formData = this.documentApprovalForm.value.DocumentApprovalLevel;
    var len = formData.length;
    for (var i = 0; i < len; i++) {
      var app_len = formData[i].CustomApprovalLevelItem.length;
      var app_data = formData[i].CustomApprovalLevelItem;
      for (var j = 0; j < app_len; j++) {
        if (this.isNull(app_data[j].ApproverEmailId)) {
          return false;
        }
      }
    }
    return true;
  }

  isNull(data) {
    if (data == '' || data == null || data == undefined) {
      return true;
    } else {
      return false;
    }
  }

  lock() {
    const data = this.formatData();
    this.spinner.show();
    this.attachmentService.LockDocuments(data).subscribe((x) => {
      if (x.Success) {
        this.toaster.success("Saved successfully.");
        this.activeModal.close('Success');
      } else {
        this.toaster.error(x.Message);
        this.activeModal.close();
      }
      this.spinner.hide();
    },
    (err) => {
      this.toaster.error("ooh, something went wrong !");
    });
  }

  formatData(){
    let documentLockDetails: DocumentLockDetails[] = [];
    this.submittedAttachments.forEach(attachment => {
      this.documentApprovalForm.value.DocumentApprovalLevel[0].CustomApprovalLevelItem.forEach(lockDocument => {
        if(lockDocument.ApproverEmailId != null){
          let lockDocumentDetails = new DocumentLockDetails();
          lockDocumentDetails.Id = 0;
          lockDocumentDetails.CompanyId = 0;
          lockDocumentDetails.TagId = attachment.Id;
          lockDocumentDetails.UserId = lockDocument.ApproverEmailId
          documentLockDetails.push(lockDocumentDetails);
        }
        else{
          let userList = this.EmailList.filter(x => x.RoleId == lockDocument.ApproverId);
          userList.forEach(user => {
            let lockDocumentDetails = new DocumentLockDetails();
            lockDocumentDetails.Id = 0;
            lockDocumentDetails.CompanyId = 0;
            lockDocumentDetails.TagId = attachment.Id;
            lockDocumentDetails.UserId = user.Id
            documentLockDetails.push(lockDocumentDetails);
          });
        }
      });
    });
    return documentLockDetails;
  }

}

