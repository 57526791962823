import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from './../../../service/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ProductServiceService } from '../../../service/Products/product-service.service'
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service'
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../../service/EventEmitter/data.service'; 
import * as moment from 'moment';
@Component({
  selector: 'app-consumption-history-list',
  templateUrl: './consumption-history-list.component.html',
  styleUrls: ['./consumption-history-list.component.css']
})
export class ConsumptionHistoryListComponent implements OnInit {
@Input() ItemId: any;
@Input() ConsumptionId: any;
ItemList: any = [];
masterData: any;
private gridApi;
 private gridColumnApi;
 domLayout='autoHeight';
  constructor(private spinner: NgxSpinnerService,
              public activeModal: NgbActiveModal,
              private router: Router,
              public authService: AuthService,
              private toastr: ToastrService,
              private dataService: DataService,
              private productServiceService: ProductServiceService) { }
              columnDefs = [
                { headerName: 'Item Code', field: 'ItemCode' },
                { headerName: 'Item Name', field: 'ItemName' },
                { headerName: 'Quantity Before Consumption', field: 'StockQuantity', minWidth: 225, maxWidth: 225 },
                { headerName: 'Consumed Quantity', field: 'ConsumedQuantity' },
              ];
              defaultColDef = {
                sortable: true,
                filter: true,
                flex: 1
              };
  ngOnInit(): void {
    
  }


  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  getAllProduct = () => {
    this.spinner.show();
    this.productServiceService.GetConsumptionHistory(this.ItemId, this.ConsumptionId).subscribe((x: any) => {
      this.spinner.hide();
      this.masterData = x.parentData;
      this.ItemList = [...x.childData, ...this.ItemList];
    });
  }
  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    this.getAllProduct();
  }
}
