import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ItemType } from 'src/app/enum/item-type';
import { ManufactureItemType } from 'src/app/enum/manufacture-order-item-type';
import { ManufactureOrderStatus } from 'src/app/enum/manufacture-order-status';
import { ManufactureOrderEditPageViewModel } from 'src/app/models-viewModels/manufactureOrderEditPageViewModel';
import { Item, ItemDetails } from 'src/app/models/inventoryOverview.model';
import { ManufactureFormula, ManufactureFormulaDetails, ManufactureOrder, ManufactureOrderDetails } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';
import { ManufactureTimelinePopupComponent } from '../manufacture-timeline-popup/manufacture-timeline-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';

@Component({
  selector: 'app-edit-manufacture-order',
  templateUrl: './edit-manufacture-order.component.html',
  styleUrls: ['./edit-manufacture-order.component.css']
})

export class EditManufactureOrderComponent implements OnInit {

  ManufactureOrderForm: FormGroup;
  OrderItems: FormArray;
  MiscellaneousItems: FormArray;

  rawMaterialList: Item[];
  selectedProductFormulaList: ManufactureFormula[] = [];

  manufactureOrderData: ManufactureOrderEditPageViewModel;

  itemMap: Map<number, ItemDetails> = new Map<number, ItemDetails>(); 

  manufactureOrderId: number;
  totalOrderItemsConsume: number = 0;
  totalMiscellaneousItemsConsume: number = 0;

  isShowMiscellaneousItemsStockIcon: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private manufactureService: ManufactureService,
    private route: ActivatedRoute,
    private modalService: NgbModal

  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let no = +params['id'];
      this.manufactureOrderId = no;
      this.getOrder();
   });
    this.initializeForm();
  }

  initializeForm() {
    this.ManufactureOrderForm = this.fb.group({
      Id: [0],
      FinishedProductId: ['', Validators.required],
      FormulaId: ['', Validators.required],
      Quantity: ['', Validators.required],
      CreatedDate: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      Reference: [''],
      BatchNo: ['', Validators.required],
      OrderItems: this.fb.array([]),
      MiscellaneousItems: this.fb.array([])
    });
  }

  getOrder(){
    this.manufactureService.getOrderForEdit(this.manufactureOrderId).subscribe(x => {
      this.manufactureOrderData = x;
      x.ItemDetails.forEach(obj => {
        let key = obj.Id;
        this.itemMap.set(key, obj);
      });
      this.selectedProductFormulaList = this.manufactureOrderData.ManufactureFormula;
      this.rawMaterialList = this.manufactureOrderData.Items.filter(x => x.ItemTypeId == ItemType.RawMaterial);
      this.setParentData(this.manufactureOrderData);
      this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.NORMAL).forEach((item, i) => {
        this.totalOrderItemsConsume += item.TotalConsumed;
        this.setNormalItem(item, i);
      });
      if(this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).length != 0){
        this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.filter(x => x.ItemType == ManufactureItemType.MISCELLANEOUS).forEach((item, i) => {
          this.totalMiscellaneousItemsConsume += item.TotalConsumed;
          this.setMiscellaneousItem(item, i);
        });
      }
    });
  }

  setParentData(orderData) {
    this.ManufactureOrderForm.controls['Id'].patchValue(orderData.ManufactureOrder.Id);
    this.ManufactureOrderForm.controls['FinishedProductId'].patchValue(orderData.ManufactureOrder.Item.ItemName);
    this.ManufactureOrderForm.controls['FormulaId'].patchValue(orderData.ManufactureOrder.ManufactureFormulaId);
    this.ManufactureOrderForm.controls['Quantity'].patchValue(orderData.ManufactureOrder.ProductionQuantity);
    this.ManufactureOrderForm.controls['CreatedDate'].patchValue(orderData.ManufactureOrder.CreatedDate);
    this.ManufactureOrderForm.controls['StartDate'].patchValue(orderData.ManufactureOrder.StartDate);
    this.ManufactureOrderForm.controls['EndDate'].patchValue(orderData.ManufactureOrder.EndDate);
    this.ManufactureOrderForm.controls['Reference'].patchValue(orderData.ManufactureOrder.Reference);
    this.ManufactureOrderForm.controls['BatchNo'].patchValue(orderData.ManufactureOrder.BatchNo);
    this.showSelectedDate(this.ManufactureOrderForm.value.CreatedDate);
    this.showStartSelectedDate(this.ManufactureOrderForm.value.StartDate);
    this.showEndSelectedDate(this.ManufactureOrderForm.value.EndDate);
  }

  setNormalItem(item: ManufactureOrderDetails, i: number) {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.push(this.setItem(item, i));
  }

  setItem(item: ManufactureOrderDetails, i: number): FormGroup {
    return this.fb.group({
      ItemId: [item.ItemId],
      RawMaterialId: [item.Item.ItemName],
      RecipeQuantity: [this.setItemRecipeQuantity(item)],
      Balance: [this.setItemBalance(item)],
      ToConsume: [item.UsedItem],
    });
  }

  setItemRecipeQuantity(item: ManufactureOrderDetails) {
    let formulaQuantity = this.manufactureOrderData.ManufactureOrder.ManufactureFormula.Quantity;
    let formulaItemQuantity = this.manufactureOrderData.ManufactureOrder.ManufactureFormula.ManufactureFormulaDetails.find(x => x.ItemId == item.ItemId).Quantity;
    let recipeQuantity = formulaItemQuantity / formulaQuantity;
    return recipeQuantity;
  }

  setItemBalance(item: ManufactureOrderDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.UsedItem <= stockQuantity ? item.UsedItem : stockQuantity;
  }

  setMiscellaneousItem(item: ManufactureOrderDetails, i: number) {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.push(this.putMiscellaneousItem(item, i));
  }

  putMiscellaneousItem(item: ManufactureOrderDetails, i: number): FormGroup {
    return this.fb.group({
      ItemId: [item.ItemId],
      MiscellaneousRawMaterialId: [item.ItemId],
      ToConsume: [item.UsedItem],
      MiscellaneousItemStockAvailabeSign: [this.setMiscellaneousItemsStockIcon(item, i)]
    });
  }

  setMiscellaneousItemsStockIcon(item: any, i: number) {
    let stockQuantity = this.itemMap?.get(item.ItemId).StockQuantity;
    let  toConsume = item.TotalConsumed;
    return toConsume <= stockQuantity  ? true : false;
  }

  createMiscellaneousItems(): FormGroup {
    return this.fb.group({
      ItemId: [0],
      MiscellaneousRawMaterialId: [null],
      ToConsume: [1],
      MiscellaneousItemStockAvailabeSign: ['']
    });
  }

  addMiscellaneousItem(): void {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.push(this.createMiscellaneousItems());
  }

  removeMiscellaneousItems(i: number) {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.value.map((x, index) => {
      if(index === i){
        this.MiscellaneousItems.removeAt(index);
      }
    });
  }

  updateFormulaItems() {
    this.removeAllItems();
    let formula = this.manufactureOrderData.ManufactureFormula.find(x => x.Id == this.ManufactureOrderForm.value.FormulaId);
    this.ManufactureOrderForm.controls['Quantity'].patchValue(this.manufactureOrderData.ManufactureOrder.ProductionQuantity);
    formula.ManufactureFormulaDetails.forEach((item, i) => {
      this.addItem(item, i);
    });
    this.updateItemsConsumeData();
  }

  removeAllItems() {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.clear();
  }

  addItem(item: ManufactureFormulaDetails, i: number) {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.push(this.createItem(item, i));
  }

  createItem(item: ManufactureFormulaDetails, i: number): FormGroup {
    return this.fb.group({
      ItemId: [item.ItemId],
      RawMaterialId: [this.itemMap.get(item.ItemId).ItemName],
      RecipeQuantity: [item.Quantity],
      Balance: [this.getItemBalance(item)],
      ToConsume: [item.Quantity]
    });
  }

  getItemBalance(item: ManufactureFormulaDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.Quantity <= stockQuantity ? item.Quantity : stockQuantity;
  }

  updateItemsConsumeData() {
    this.ManufactureOrderForm.get('Quantity').patchValue(this.ManufactureOrderForm.value.Quantity == '' ? 0 : this.ManufactureOrderForm.value.Quantity);
    let formula = this.manufactureOrderData.ManufactureFormula.find(x => x.Id == this.ManufactureOrderForm.value.FormulaId);
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.value.map((item, i) => {
      this.OrderItems.controls[i].get('Balance').patchValue((this.updateItemBalance(item)).toFixed(2));
      this.OrderItems.controls[i].get('ToConsume').patchValue((item.RecipeQuantity * Number(this.ManufactureOrderForm.value.Quantity)).toFixed(2));
    });
  }

  updateItemBalance(item: any) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    let itemQuantity = item.RecipeQuantity * Number(this.ManufactureOrderForm.value.Quantity);
    return itemQuantity <= stockQuantity ? itemQuantity : stockQuantity;
  }

  saveManufactureOrder() {
    if(this.checkFormValidation()) {
      this.spinner.show();
      let data: ManufactureOrder = this.formatData();
      data.Status = ManufactureOrderStatus.WAITING;
      this.manufactureService.updateManufactureOrder(data).subscribe((x) => {
        if(x.Success) {
          this.toaster.success(x.Message);
          this.router.navigate(["manufacture/manufacutring-order-list/All"]);
          this.spinner.hide();
        }
        else{
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
    }
  }

  approveManufactureOrder() {
    if(this.checkFormValidation()) {
      let data: ManufactureOrder = this.formatData();
      const modalRef = this.modalService.open(ManufactureTimelinePopupComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered : true});
      modalRef.componentInstance.manufactureOrder = data;
      modalRef.componentInstance.actionType = iQuidiActionType.Update;
    }
  }

  checkFormValidation() {
    if (this.ManufactureOrderForm.value.FormulaId == null) {
      this.toaster.warning('Please select a formula name!');
      return false;
    }

    if(Number(this.ManufactureOrderForm.value.Quantity) == 0){
      this.toaster.warning('Quantity should not zero for production!');
      return false;
    }

    if (this.ManufactureOrderForm.value.BatchNo == null) {
      this.toaster.warning('Please add batch no!');
      return false;
    }

    const miscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems').value;
    if(miscellaneousItems.length != 0){
      const hasNoMiscellaneousItems = miscellaneousItems.some(item => Number(item.MiscellaneousRawMaterialId) == 0);
      if(hasNoMiscellaneousItems) {
        this.toaster.warning('Please select miscellaneous item!');
        return false;
      }
      const hasZeroToConsume = miscellaneousItems.some(item => Number(item.ToConsume) == 0);
      if(hasZeroToConsume){
        this.toaster.warning('Zero miscellaneous items should not consume!');
        return false;
      }
    }
    const itemIds = new Set();
    for(const item of miscellaneousItems){
      if(itemIds.has(item.MiscellaneousRawMaterialId)) {
        this.toaster.warning('Same miscellaneous item could not add in multiple time!');
        return false;
      }
      else {
        itemIds.add(item.MiscellaneousRawMaterialId);
      }
    }
    if (!this.ManufactureOrderForm.valid) {
      this.toaster.warning('Please fill all the required fields!');
      return false;
    }
    return true;
  }

  formatData(): ManufactureOrder {
    const manufactureOrderDetails: ManufactureOrderDetails[] = [];
  
    const processOrderItems = (items: any[]) => {
      items.forEach((element) => {
        let item = this.rawMaterialList.find((x) => x.Id == (element.ItemId != 0 ? element.ItemId : element.MiscellaneousRawMaterialId));
        let order = this.manufactureOrderData.ManufactureOrder.ManufactureOrderDetails.find(x => x.ItemId === element.ItemId);
        if (item) {
          const orderDetails = new ManufactureOrderDetails();
          orderDetails.Id = order != null ? order.Id : 0;
          orderDetails.ManufactureOrderId = this.manufactureOrderData.ManufactureOrder.Id;
          orderDetails.ManufactureOrder = null;
          orderDetails.ItemId = item.Id;
          orderDetails.Item = null;
          orderDetails.UsedItem = Number(element.ToConsume);
          orderDetails.BrokenItem = 0;
          orderDetails.TotalConsumed = Number(element.ToConsume);
          orderDetails.PricePerUnit = item.PurchasePrice;
          orderDetails.LineTotal = item.PurchasePrice * Number(element.ToConsume);
          orderDetails.ItemType = element.MiscellaneousRawMaterialId == null ? ManufactureItemType.NORMAL : ManufactureItemType.MISCELLANEOUS
          manufactureOrderDetails.push(orderDetails);
        }
      });
    };
    processOrderItems(this.ManufactureOrderForm.value.OrderItems);
    processOrderItems(this.ManufactureOrderForm.value.MiscellaneousItems);
  
    let formula = this.manufactureOrderData.ManufactureFormula.find(x => x.Id == this.ManufactureOrderForm.value.FormulaId);
    const itemTotalAmount = manufactureOrderDetails.reduce((acc, order) => acc + order.LineTotal, 0);
    const manufactureOrder: ManufactureOrder = {
      Id: this.manufactureOrderData.ManufactureOrder.Id,
      ManufactureFormulaId: formula.Id,
      ManufactureFormula: null,
      Reference: this.ManufactureOrderForm.value.Reference,
      ProductionQuantity: this.ManufactureOrderForm.value.Quantity,
      ParentId: null,
      BatchNo: this.ManufactureOrderForm.value.BatchNo,
      ManufactureOrderNo: this.manufactureOrderData.ManufactureOrder.ManufactureOrderNo,
      Status: this.getManufactureOrderStatus(manufactureOrderDetails),
      CompanyId: this.manufactureOrderData.ManufactureOrder.CompanyId,
      ItemId: this.manufactureOrderData.ManufactureOrder.ItemId,
      Item: null,
      PerUnitCost: itemTotalAmount/Number(this.ManufactureOrderForm.value.Quantity),
      ItemTotalAmount: itemTotalAmount,
      OperationTotalAmount: 0,
      CreatedDate: this.ManufactureOrderForm.value.CreatedDate,
      StartDate: this.ManufactureOrderForm.value.StartDate,
      EndDate: this.ManufactureOrderForm.value.EndDate,
      CreatedBy: null,
      ManufactureOrderDetails: manufactureOrderDetails,
      OperatingCostItems: null,
    };
    return manufactureOrder;
  }

  getManufactureOrderStatus(manufactureOrderDetails: ManufactureOrderDetails[]) {
    let status = ManufactureOrderStatus.TO_BE_PROCESSED;
    manufactureOrderDetails.forEach((element) => {
      let stockQuantity = this.itemMap.get(element.ItemId).StockQuantity;
      if(element.UsedItem > stockQuantity){
        status =  ManufactureOrderStatus.WAITING_AFTER_APPROVED;
        return status;
      }
    });
    return status;
  }

  showSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.CreatedDate;
    $('#createdDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showStartSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.StartDate;
    $('#startDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showEndSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.EndDate;
    $('#endDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  canShowOrderItemsStockIcon(item: any, i: number) {
    let stockQuantity = 0;
    let toConsume = 0;
    if(item.value.ItemId != 0) {
      stockQuantity = this.itemMap?.get(item.value.ItemId).StockQuantity;
      toConsume = item.value.ToConsume;
    }
    return toConsume <= stockQuantity  ? true : false;
  }

  changeMiscellaneousItem(item: any, i: number) {
    let canShowIcon = this.canShowMiscellaneousItemsStockIcon(item, i);
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.controls[i].get('ToConsume').patchValue(item.value.ToConsume == '' ? 0 : item.value.ToConsume);
    this.MiscellaneousItems.controls[i].get('MiscellaneousItemStockAvailabeSign').patchValue(canShowIcon);
  }

  canShowMiscellaneousItemsStockIcon(item: any, i: number) {
    let stockQuantity = 0;
    let toConsume = 0;
    if(item.value.MiscellaneousRawMaterialId != null) {
      stockQuantity = this.itemMap?.get(item.value.MiscellaneousRawMaterialId).StockQuantity;
      toConsume = Number(item.value.ToConsume);
    }
    return toConsume <= stockQuantity  ? true : false;
  }

}
