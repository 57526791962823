import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ItemType } from 'src/app/enum/item-type';
import { ManufactureItemType } from 'src/app/enum/manufacture-order-item-type';
import { ManufactureOrderStatus } from 'src/app/enum/manufacture-order-status';
import { ManufactureOrderCreatePageViewModel } from 'src/app/models-viewModels/manufactureOrderCreatePageViewModel.model';
import { ManufactureOrderProductionPageViewModel } from 'src/app/models-viewModels/manufactureViewModel.model';
import { Item, ItemDetails } from 'src/app/models/inventoryOverview.model';
import { ManufactureFormula, ManufactureFormulaDetails, ManufactureOrder, ManufactureOrderDetails } from 'src/app/models/manufacture.model';
import { ManufactureService } from 'src/app/service/Manufacture/manufacture.service';
import { ManufactureTimelinePopupComponent } from '../manufacture-timeline-popup/manufacture-timeline-popup.component';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';


@Component({
  selector: 'app-create-manufacutring-order',
  templateUrl: './create-manufacutring-order.component.html',
  styleUrls: ['./create-manufacutring-order.component.css']
})

export class CreateManufacutringOrderComponent implements OnInit {

  ManufactureOrderForm: FormGroup;
  OrderItems: FormArray;
  MiscellaneousItems: FormArray;
  finishedProductList: Item[];
  rawMaterialList: Item[];
  selectedProductFormulaList: ManufactureFormula[] = [];
  manufactureOrderViewModelData: ManufactureOrderCreatePageViewModel;
  itemMap: Map<number, ItemDetails> = new Map<number, ItemDetails>();
  itemStockAvailable: boolean[] = [];

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private manufactureService: ManufactureService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getFormData();
  }

  getFormData() {
    this.getManufactureData();
  }

  getManufactureData() {
    this.manufactureService.getManufactureData().subscribe(x => {
      this.manufactureOrderViewModelData = x;
      x.ItemDetails.forEach(obj => {
        let key = obj.Id;
        this.itemMap.set(key, obj);
      });
      this.finishedProductList = x.Items.filter(x => x.ItemTypeId == ItemType.FinishProduct);
      this.rawMaterialList = x.Items.filter(x => x.ItemTypeId == ItemType.RawMaterial);
    });
  }

  initializeForm() {
    this.ManufactureOrderForm = this.fb.group({
      Id: [0],
      FinishedProductId: ['', Validators.required],
      FormulaId: ['', Validators.required],
      Quantity: ['', Validators.required],
      CreatedDate: ['', Validators.required],
      Reference: [''],
      BatchNo: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      OrderItems: this.fb.array([]),
      MiscellaneousItems: this.fb.array([])
    });
    this.ManufactureOrderForm.get('CreatedDate').patchValue(new Date());
    this.showSelectedDate(this.ManufactureOrderForm.value.CreatedDate);
    this.ManufactureOrderForm.get('StartDate').patchValue(new Date()); 
    this.showStartSelectedDate(this.ManufactureOrderForm.value.StartDate);
    this.ManufactureOrderForm.get('EndDate').patchValue(new Date());
    this.showEndSelectedDate(this.ManufactureOrderForm.value.EndDate);
  }

  createMiscellaneousItems(): FormGroup {
    return this.fb.group({
      ItemId: [0],
      MiscellaneousRawMaterialId: [0],
      ToConsume: [0],
      MiscellaneousItemStockAvailabeSign: ['']
    });
  }

  updateformulasData() {
    this.removeAllItems();
    this.selectedProductFormulaList = [];
    this.ManufactureOrderForm.controls['FormulaId'].patchValue('');
    this.selectedProductFormulaList = this.manufactureOrderViewModelData.ManufactureFormula.filter(x => x.FinishedProductId == this.ManufactureOrderForm.value.FinishedProductId);
  }


  updateItemsData() {
    this.removeAllItems();
    let formula = this.manufactureOrderViewModelData.ManufactureFormula.find(x => x.Id == this.ManufactureOrderForm.value.FormulaId);
    this.ManufactureOrderForm.controls['Quantity'].patchValue(formula.Quantity);
    formula.ManufactureFormulaDetails.forEach((item, i) => {
      this.addItem(item, i);
    });
  }

  addItem(item: ManufactureFormulaDetails, i: number) {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.push(this.createItem(item, i));
  }

  createItem(item: ManufactureFormulaDetails, i: number): FormGroup {
    let formula = this.manufactureOrderViewModelData.ManufactureFormula.find(x => x.Id == this.ManufactureOrderForm.value.FormulaId);
    return this.fb.group({
      ItemId: [item.ItemId],
      RawMaterialId: [this.itemMap.get(item.ItemId).ItemName],
      RecipeQuantity: [(item.Quantity/formula.Quantity).toFixed(2)],
      Balance: [this.getItemBalance(item)],
      ToConsume: [item.Quantity]
    });
  }

  removeAllItems() {
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.clear();
  }

  getItemBalance(item: ManufactureFormulaDetails) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    return item.Quantity <= stockQuantity ? item.Quantity.toFixed(2) : stockQuantity;
  }

  updateItemBalance(item: any) {
    let stockQuantity = this.itemMap.get(item.ItemId).StockQuantity;
    let itemQuantity = item.RecipeQuantity * Number(this.ManufactureOrderForm.value.Quantity);
    return itemQuantity <= stockQuantity ? itemQuantity.toFixed(2) : stockQuantity;
  }

  updateItemsConsumeData() {
    let formula = this.manufactureOrderViewModelData.ManufactureFormula.find(x => x.Id == this.ManufactureOrderForm.value.FormulaId);
    this.OrderItems = this.ManufactureOrderForm.get('OrderItems') as FormArray;
    this.OrderItems.value.map((item, i) => {
      this.OrderItems.controls[i].get('Balance').patchValue(this.updateItemBalance(item));
      this.OrderItems.controls[i].get('ToConsume').patchValue((item.RecipeQuantity * Number(this.ManufactureOrderForm.value.Quantity)).toFixed(2));
    });
  }

  addMiscellaneousItem(): void {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.push(this.createMiscellaneousItems());
  }

  removeMiscellaneousItems(i: number) {
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.value.map((x, index) => {
      if(index === i){
        this.MiscellaneousItems.removeAt(index);
      }
    });
  }

  changeMiscellaneousItems(item: any, i: number) {
    let canShowIcon = this.canShowMiscellaneousItemsStockIcon(item, i);
    this.MiscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems') as FormArray;
    this.MiscellaneousItems.controls[i].get('MiscellaneousItemStockAvailabeSign').patchValue(canShowIcon);
  }

  canShowMiscellaneousItemsStockIcon(item: any, i: number) {
    let stockQuantity = 0;
    let toConsume = 0;
    if(item.value.MiscellaneousRawMaterialId != 0) {
      stockQuantity = this.itemMap?.get(item.value.MiscellaneousRawMaterialId).StockQuantity;
      toConsume = Number(item.value.ToConsume);
    }
    return toConsume <= stockQuantity  ? true : false;
  }

  saveManufactureOrder() {
    if(this.checkFormValidation()) {
      this.spinner.show();
      let data: ManufactureOrder = this.formatData();
      this.manufactureService.saveManufactureOrder(data).subscribe((x) => {
        if(x.Success) {
          this.toaster.success(x.Message);
          this.router.navigate(["manufacture/manufacutring-order-list/All"]);
          this.spinner.hide();
        }
        else{
          this.toaster.warning(x.Message);
          this.spinner.hide();
        }
      });
    }
  }

  approveManufactureOrder() {
    if(this.checkFormValidation()) {
      let data: ManufactureOrder = this.formatData();
      const modalRef = this.modalService.open(ManufactureTimelinePopupComponent,{ size: 'md',backdrop: 'static', keyboard : false, centered : true});
      modalRef.componentInstance.manufactureOrder = data;
      modalRef.componentInstance.actionType = iQuidiActionType.Approve;

    }
  }

  checkFormValidation() {
    if (this.ManufactureOrderForm.value.FinishedProductId == '') {
      this.toaster.warning('Please select a product name!');
      return false;
    }

    if (this.ManufactureOrderForm.value.FormulaId == '') {
      this.toaster.warning('Please select a formula name!');
      return false;
    }

    if(Number(this.ManufactureOrderForm.value.Quantity) == 0){
      this.toaster.warning('Quantity should not zero for production!');
      return false;
    }

    if (this.ManufactureOrderForm.value.BatchNo == '') {
      this.toaster.warning('Please add batch no!');
      return false;
    }

    const miscellaneousItems = this.ManufactureOrderForm.get('MiscellaneousItems').value;
    if(miscellaneousItems.length != 0){
      const hasNoMiscellaneousItems = miscellaneousItems.some(item => Number(item.MiscellaneousRawMaterialId) == 0);
      if(hasNoMiscellaneousItems) {
        this.toaster.warning('Please select miscellaneous item!');
        return false;
      }
      const hasZeroToConsume = miscellaneousItems.some(item => Number(item.ToConsume) == 0);
      if(hasZeroToConsume){
        this.toaster.warning('Zero miscellaneous items should not consume!');
        return false;
      }
    }
    const itemIds = new Set();
    for(const item of miscellaneousItems){
      if(itemIds.has(item.MiscellaneousRawMaterialId)) {
        this.toaster.warning('Same miscellaneous item could not add in multiple time!');
        return false;
      }
      else {
        itemIds.add(item.MiscellaneousRawMaterialId);
      }
    }
    if (!this.ManufactureOrderForm.valid) {
      this.toaster.warning('Please fill all the required fields!');
      return false;
    }
    return true;
  }

  formatData(): ManufactureOrder {
    const manufactureOrderDetails: ManufactureOrderDetails[] = [];
  
    const processOrderItems = (items: any[]) => {
      items.forEach((element) => {
        let item;
        if(element.RawMaterialId !== undefined){
          item = this.rawMaterialList.find((x) => x.Id === element.ItemId);
        }
        else{
          item = this.rawMaterialList.find((x) => x.Id === element.MiscellaneousRawMaterialId);
        }
        if (item) {
          const orderDetails = new ManufactureOrderDetails();
          orderDetails.Id = 0;
          orderDetails.ManufactureOrderId = 0;
          orderDetails.ManufactureOrder = null;
          orderDetails.ItemId = item.Id;
          orderDetails.Item = null;
          orderDetails.UsedItem = Number(element.ToConsume);
          orderDetails.BrokenItem = 0;
          orderDetails.TotalConsumed = Number(element.ToConsume);
          orderDetails.PricePerUnit = item.PurchasePrice != null ? item.PurchasePrice : 0;
          orderDetails.LineTotal = item.PurchasePrice * Number(element.ToConsume);
          orderDetails.ItemType = element.RawMaterialId !== undefined ? ManufactureItemType.NORMAL : ManufactureItemType.MISCELLANEOUS
          manufactureOrderDetails.push(orderDetails);
        }
      });
    };
    processOrderItems(this.ManufactureOrderForm.value.OrderItems);
    processOrderItems(this.ManufactureOrderForm.value.MiscellaneousItems);
  
    let formula = this.manufactureOrderViewModelData.ManufactureFormula.find(x => x.Id == this.ManufactureOrderForm.value.FormulaId);
    const itemTotalAmount = manufactureOrderDetails.reduce((acc, order) => acc + order.LineTotal, 0);
    const manufactureOrder: ManufactureOrder = {
      Id: 0,
      ManufactureFormulaId: formula.Id,
      ManufactureFormula: null,
      Reference: this.ManufactureOrderForm.value.Reference,
      ProductionQuantity: this.ManufactureOrderForm.value.Quantity,
      ParentId: null,
      BatchNo: this.ManufactureOrderForm.value.BatchNo,
      ManufactureOrderNo: null,
      Status: this.getManufactureOrderStatus(manufactureOrderDetails),
      CompanyId: 0,
      ItemId: this.ManufactureOrderForm.value.FinishedProductId,
      Item: null,
      PerUnitCost: itemTotalAmount/Number(this.ManufactureOrderForm.value.Quantity),
      ItemTotalAmount: itemTotalAmount,
      OperationTotalAmount: 0,
      CreatedDate: this.ManufactureOrderForm.value.CreatedDate,
      StartDate: this.ManufactureOrderForm.value.StartDate,
      EndDate: this.ManufactureOrderForm.value.EndDate,
      CreatedBy: null,
      ManufactureOrderDetails: manufactureOrderDetails,
      OperatingCostItems: null,
    };
    return manufactureOrder;
  }

  getManufactureOrderStatus(manufactureOrderDetails: ManufactureOrderDetails[]) {
    let status = ManufactureOrderStatus.TO_BE_PROCESSED;
    manufactureOrderDetails.forEach((element) => {
      let stockQuantity = this.itemMap.get(element.ItemId).StockQuantity;
      if(element.UsedItem > stockQuantity){
        status =  ManufactureOrderStatus.WAITING_AFTER_APPROVED;
        return status;
      }
    });
    return status;
  }

  showSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.CreatedDate;
    $('#createdDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showStartSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.StartDate;
    $('#startDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showEndSelectedDate(e: Event) {
    const value = this.ManufactureOrderForm.value.EndDate;
    $('#endDateId').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  canShowOrderItemsStockIcon(item: any, i: number) {
    let stockQuantity = 0;
    let toConsume = 0;
    if(item.value.ItemId != 0) {
      stockQuantity = this.itemMap?.get(item.value.ItemId).StockQuantity;
      toConsume = item.value.ToConsume;
    }
    return toConsume <= stockQuantity  ? true : false;
  }

  toggleStockAvailability(index: number) {
    let i;
    this.itemStockAvailable[index] = !this.itemStockAvailable[index];
    for(i = 0; i < this.itemStockAvailable.length; i++) {
      if(i != index && this.itemStockAvailable[i] == true) {
        this.itemStockAvailable[i] = !this.itemStockAvailable[i];
      }
    }
  }

}

