<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px;">Review Disposal Journals</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="card col-sm-6">
                                <div class="card-body">
                                  <p class="font-weight-bold">Date Disposed</p>
                                  <p>{{disposedDate}}</p>
                                </div>
                            </div>
                            <div class="card col-sm-6">
                                <div class="card-body">
                                    <p class="font-weight-bold">Sale Price</p>
                                    <p>{{fromParent?.SoldPrice}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <table class="table f-ss-14px">
                                <thead>
                                    <tr class="tableHeader">
                                        <th scope="col" class="table-header-color text-align-left col-md-8" style="color: #02aced;">Disposal Journal</th>
                                        <th scope="col" class="table-header-color text-align-right col-md-2" style="color: #02aced;">Debit</th>
                                        <th scope="col" class="table-header-color text-align-right col-md-2" style="color: #02aced;">Credit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-align-left col-md-8">{{assetAcccount.AccountName}}</td>
                                        <td class="text-align-right col-md-2"></td>
                                        <td class="text-align-right col-md-2">{{fromParent?.FixedAssetItem?.PurchasePrice}}</td>
                                    </tr>
                                    <tr *ngIf="fromParent.AccumulatedDepreciation != 0">
                                        <td class="text-align-left col-md-8">{{depreciationAcccount.AccountName}}</td>
                                        <td class="text-align-right col-md-2">{{fromParent.AccumulatedDepreciation}}</td>
                                        <td class="text-align-right col-md-2"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-align-left col-md-8">{{saleProceedsAccount.AccountName}}</td>
                                        <td class="text-align-right col-md-2">{{fromParent.SoldPrice}}</td>
                                        <td class="text-align-right col-md-2"></td>
                                    </tr>
                                    <tr *ngIf="fromParent.GainAmount != 0">
                                        <td class="text-align-left col-md-8">Gain on Disposal<span style="border: 1px solid #02aced; padding: 5px; margin-left: 35px;">{{GainAccountName}}</span></td>
                                        <td class="text-align-right col-md-2"></td>
                                        <td class="text-align-right col-md-2">{{fromParent.GainAmount.toFixed(2)}}</td>
                                    </tr>
                                    <tr *ngIf="fromParent.CapitalGainAmount != 0">
                                        <td class="text-align-left col-md-8">Capital Gain on Disposal<span style="border: 1px solid #02aced; padding: 5px; margin-left: 20px;">{{CapitalGainAccountName}}</span></td>
                                        <td class="text-align-right col-md-2"></td>
                                        <td class="text-align-right col-md-2">{{fromParent.CapitalGainAmount}}</td>
                                    </tr>
                                    <tr *ngIf="fromParent.LossAmount != 0">
                                        <td class="text-align-left col-md-8">Loss on Disposal<span style="border: 1px solid #02aced; padding: 5px; margin-left: 20px;">{{LossAccountName}}</span></td>
                                        <td class="text-align-right col-md-2">{{fromParent.LossAmount.toFixed(2)}}</td>
                                        <td class="text-align-right col-md-2"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-align-right col-md-8 font-weight-bold">Total</td>
                                        <td class="text-align-right col-md-2 font-weight-bold">{{totalDebit.toFixed(2)}}</td>
                                        <td class="text-align-right col-md-2 font-weight-bold">{{totalCredit.toFixed(2)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-4 text-align-left">
                                <button type="button" class="btn btn-outline-primary backBtn" (click)="activeModal.close('Cross click')">&lt;Back</button>
                            </div>
                            <div class="form-group  col-sm-8 text-align-right">
                                <button type="button" class="eCount-def-btn ml-4" style="background-color: #5cac00;" (click)="CreateInvoice()">Post and Create Invoice</button>
                                <button type="button" class="eCount-def-btn ml-4" style="background-color: gray;" (click)="activeModal.close('Cross click')">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

