<app-dashboard>
    <app-page-header-title
        mainTitle="All Users"
        subTitle="Settings"
        backURL="/settings">
        <div class="col-md-12 row">
            <div class="col-md-5 pt-2" [ngClass]= "{'col-md-8':(RemainingUser != 0)}">
                <span class="txtSize">Total user : {{TotalUser}}</span>
                <span class="txtSize ml-4">Remaining user : {{ RemainingUser}}</span>
            </div>
            <div class="col-md-3 pt-2" *ngIf="isTrialActive">
                <span style="color: red;font-size: 16px" class="text-red"> <span (click)="goUrl()" class="text-blue cursor-pointer">Update Subscription</span>
            </span>
            </div>
            <div class="col-md-2 pb-2">
                <!-- <button class="eCount-def-btn" (click)="openUserRolePopup()">Add Role</button> -->
                <button type="button" (click)="InviteUser(this.inviteUserForm.value)" *ngIf=" loggedInUserRule?.RoleId ==1 " class="eCount-def-btn ml-4">Invite User</button>
            </div>
            <div class="col-md-2 text-align-right pb-2">
                <button type="button" class="eCount-button3 ml-4" (click)="convetToPDF()">{{'Contacts.contactList.export'| translate}}</button>
            </div>
        </div>

    </app-page-header-title>

    <!-- <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            [routerLinkActive]="['active']"
            [ngClass]="{'click': ifUserClick}"
            (click)="toggleOnMneu(1)"
            >All User
          </a>
        </li>
        <li class="nav-item">
            <a
              class="nav-link f-ssb-14px nav-option"
              (click)="toggleOnMneu(2)"
              [ngClass]="{'click': ifRoleClick}"
              [routerLinkActive]="['active']"
              >Role
            </a>
          </li>
    </ul> -->



    <div id="user"  class="mt-4">
        <div class="table-container">
            <table class="table f-ss-14px">
                <thead>
                    <tr class="tableHeader">
                        <!-- <th scope="col">Company Name</th> -->
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of allUserList">
                        <!-- <td>{{item.CompanyName}}</td> -->
                        <td>{{item.FirstName}}</td>
                        <td>{{item.Email}}</td>
                        <td *ngIf="item.CompanyWiseUserList.length == 0">{{ item.RoleName }}</td>
                        <td *ngIf="item.CompanyWiseUserList.length != 0"> <span *ngFor="let i of item.CompanyWiseUserList; let first = first;"><span *ngIf="!first">, </span>{{i.RoleName}}</span> </td>

                        <td [ngStyle]="{'color': (item.Status =='Approved') ? 'green' : 'red'}" style="font-weight: bold;"> {{item.Status}}</td>
                   <td>
                    <div style="padding: 7px 10px; color: #c7c2c2;" *ngIf="(loggedInUserRule?.RoleId == 1)">
                        <div class="dropdown mr-4">
                            <div role="button" style="cursor: pointer;" data-toggle="dropdown" aria-haspopup="false"
                                aria-expanded="false">
                                <i class="icon-more float-right"></i>
                            </div>
                            <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                                style="width: 100%;height: auto;">
                                <div class="context-menu-item pb-1" *ngIf="item.Status =='Approved' " (click)="openeditInvitePopUp(item)">
                                    <span class="context-menu-text">Edit</span>
                               </div>
                                <div class="context-menu-item pb-1" *ngIf="item.Status !='Approved' && loggedInUserRule?.RoleId ==1" (click)="InviteUser(item)">
                                     <span class="context-menu-text">Resend</span>
                                </div>
                                <div class="context-menu-item pb-1"
                                *ngIf="((loggedInUserRule?.RoleId==1 && item.Id == loggedInUserRule.SubscriberId  && totalAdmin > 1) ||  (loggedInUserRule?.RoleId==1 && item.Id != loggedInUserRule.SubscriberId) && item.Status =='Approved')" (click)="revokeUser(item)">
                                    <span class="context-menu-text">Revoke</span>
                               </div>
                               <div class="context-menu-item" *ngIf="item.Status =='Pending' && loggedInUserRule?.RoleId == 1" (click)="cancelInvitation(item)">
                                <span class="context-menu-text">Cancel</span>
                           </div>
                            </div>
                        </div>
                    </div>
                   </td>
                    </tr>

                </tbody>
            </table>

        </div>
    </div>

    <div [hidden]="allUserList.length > 0" class="text-center col-md-12 row">
        <div class="col-md-4"></div>
        <div class="user">
            For adding user, you need to provide your email address in user profile.
            The link of your user profile is given below:
            <span class="text-blue text-underline cursor-pointer" (click)="goToUserProfile()"> <u> User Profile </u></span>
      </div>
    </div>
    <ngx-smart-modal #inviteUser identifier="inviteUser" (onClose)="log('cancel')">
        <div class="pb-4">
            <span class="f-ss-14px modalHeaderText pl-2" *ngIf = "this.inviteUserForm.value.Id == '' ">Add New User</span>
            <span class="f-ss-14px modalHeaderText pl-2" *ngIf = "this.inviteUserForm.value.Id > 0 ">Resend User</span>
            <span style="margin-left: 45px;color: red;" class="text-red" *ngIf="RemainingUser == 0 && (status=='Revoked') ">User limit exceeded. <span (click)="goUrl()" class="text-blue cursor-pointer">Update Subscription</span>
                for more user invitation.
            </span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                <div>
                    <form [formGroup]="inviteUserForm" class="f-ss-14px" (ngSubmit)="submitInviteUser()">
                        <div class="form-group">
                            <span class="formTitle">Name<span class="required-sign">*</span></span>
                            <input type="text" formControlName="FirstName" class="form-control mt-1" placeholder="Name">
                        </div>
                        <!-- <div class="form-group">
                            <span class="formTitle">Last Name</span>
                            <input type="text" formControlName="LastName" class="form-control mt-1"  placeholder="Last Name">
                        </div> -->
                        <div class="form-group" *ngIf="this.inviteUserForm.value.Id==''">
                            <label class="formTitle">Email<span class="required-sign">*</span></label>
                            <input [ngClass]="{'submitted':ifSubmit===true}" type="text" formControlName="Email" class="form-control mt-1"  placeholder="Email">
                        </div>
                        <div class="form-group" *ngIf="this.inviteUserForm.value.Id > 0">
                            <label class="formTitle">Email<span class="required-sign">*</span></label>
                            <input [ngClass]="{'submitted':ifSubmit===true}" type="text" formControlName="Email" class="form-control mt-1"  placeholder="Email" readonly>
                        </div>
                        <div class="form-group">
                            <span class="formTitle">User Role</span>
                            <select required class="form-control" style="width: 100%;" formControlName="RoleId" (change)=rule($event)>
                                <option *ngFor="let role of userRoleList" [value]="role.Id">{{ role.Name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Revoke Me?</label>
                            <label class="switch">
                                <input class="switch-input"  type="checkbox"  formControlName="RevokeMe">
                                <span class="switch-label" data-on="Yes" data-off="No"></span>
                                <span class="switch-handle"></span>
                            </label>
                        </div>

                        <div class="form-group pt-4" style="float: right;">
                            <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4" style="width: 140px;" [disabled]="RemainingUser == 0 && (status=='Revoked')">Invite User</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

        </div>
    </ngx-smart-modal>
    <ngx-smart-modal #editInviteUser identifier="editInviteUser">
        <div class="pb-4">
            <span class="f-ss-14px modalHeaderText pl-2">Edit User</span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                <div>
                    <form [formGroup]="editInviteUserForm" class="f-ss-14px" (ngSubmit)="updateInviteUser(this.editInviteUserForm.value)">
                        <div class="form-group">
                            <span class="formTitle">Name</span>
                            <input type="text" formControlName="FirstName" class="form-control mt-1" placeholder="Name">
                        </div>
                        <!-- <div class="form-group">
                            <span class="formTitle">Last Name</span>
                            <input type="text" formControlName="LastName" class="form-control mt-1"  placeholder="Last Name">
                        </div> -->
                        <div class="form-group">
                            <label class="formTitle">Email<span class="required-sign">*</span></label>
                            <input [ngClass]="{'submitted':ifSubmit===true}" type="text" formControlName="Email" class="form-control mt-1" readonly  placeholder="Email">
                        </div>
                        <div class="form-group" *ngIf="loggedInUserRule?.RoleId != 1">
                            <span class="formTitle">User Role</span>
                            <select required class="form-control" style="width: 100%;" formControlName="RoleId" (change)=ruleForEditInvite($event) disabled=" loggedInUserRule?.RoleId === 2 || totalAdmin == 1? true : false">
                                <option value="1"  >Admin</option>
                                <option value="2" >Accountant</option>
                                <option value="3" >Auditor</option>
                            </select>
                        </div>
                        <div class="form-group" *ngIf="loggedInUserRule?.RoleId == 1">
                            <span class="formTitle">User Role</span>
                            <select required class="form-control" style="width: 100%;" formControlName="RoleId" (change)=ruleForEditInvite($event) disabled=" totalAdmin == 1 ? true : false">
                                <option value="1"  >Admin</option>
                                <option value="2" >Accountant</option>
                                <option value="3" >Auditor</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Revoke Me?</label>
                            <label class="switch">
                                <input class="switch-input"  type="checkbox"  formControlName="RevokeMe">
                                <span class="switch-label" data-on="Yes" data-off="No"></span>
                                <span class="switch-handle"></span>
                            </label>
                        </div>

                        <div class="form-group pt-4" style="float: right;">
                            <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4" style="width: 140px;">Save</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

        </div>
    </ngx-smart-modal>
    <ngx-smart-modal #AddRole identifier="AddRole">
        <div class="pb-4">
            <span class="modalHeaderText pl-2" *ngIf = "ifEditable == false">Add User Role</span>
            <span class="modalHeaderText pl-2" *ngIf = "ifEditable == true">Edit User Role</span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                <div>
                    <form [formGroup]="CompanyUserRoleForm" (ngSubmit)="saveComapnyWiseUserRole()">

                        <div class="form-group col-sm-12">
                            <span class="formTitle">Role Name <span class="required-sign">*</span></span>
                            <ng-select  [items]="roleList" bindLabel="Name" bindValue="Id"  formControlName ="RoleId"  [multiple]="true" [clearable]="false" required>

                            </ng-select>
                         </div>
                         <div class="form-group col-sm-12">
                            <span class="formTitle">User Email</span>
                            <select  formControlName="UserId"  class="form-control" disabled="ifEditable === true ? true : false">
                                <option *ngFor="let item of userList" [value]="item.Id" style="width: 100%;" >
                                    {{item.Email}}
                                </option>
                            </select>
                            </div>
                        <div class="form-group pt-4" style="float: right;">
                            <button type="submit" class="eCount-def-btn ml-4 pl-4 pr-4">Save</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

        </div>
    </ngx-smart-modal>
</app-dashboard>
