
<app-dashboard>
    <app-page-header-title
        mainTitle="Trading Items"
        subTitle="Home"
        backURL="/">
        <div class="full-width text-align-right">
            <input  type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox">
            <button type="button" (click)="createNewItem()" class="eCount-def-btn ml-4">Create Product</button>
            <button type="button"  class="eCount-button3 ml-4" (click)="onBtnExport()">{{'Contacts.contactList.export'| translate}}</button>

        </div>
        
    </app-page-header-title>

    <div class="headSection">
        <div class="row">
            <ag-grid-angular
                style="font-size: 14px;"
                class="ag-theme-balham col"
                [rowData]="ProductList"
                [columnDefs]="columnDefs"
                [domLayout] = "domLayout"
                (rowClicked)="onRowClicked($event)"
                (gridReady)="onGridReady($event)"
                [defaultColDef]="defaultColDef"
            ></ag-grid-angular>
        </div>
    </div>
</app-dashboard>