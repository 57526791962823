import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageManagerService {
  baseurl = environment.baseUrl;
  constructor(private http: HttpClient) { }

  public GetMessage(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'MessageManager/GetMessage').pipe(retry(1));
  }
  
  public StepDone(): Observable<any>{
    return this.http.get<any>(this.baseurl + 'MessageManager/StepDone').pipe(retry(1));
  }

}
