<app-dashboard>
    <app-page-header-title mainTitle="Run Depreciation" subTitle="Back" backURL="/fixed-asset/setup/draft">
    </app-page-header-title>
    <div class="row" style="margin-bottom: 20px;">
        
    </div>
    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="itemForm" class="f-ss-14px">
                <div class="row rowBottomBorder">
                    <div class="col-sm-12 text-align-right">
                        <button type="button" class="eCount-def-btn  ml-4 p-save-button" (click)="gotoRollbackDepreciationModal()">Rollback Depreciation</button>
                    </div>
                </div>
                <div class="row rowBottomBorder">
                    <div class="col-sm-12" style="display: flex;">
                        <div class="form-group  col-sm-3">
                            <label for="fromDate" style="font-weight: 600;">From</label>
                            <input type="text" disabled class="form-control form-input" required formControlName="fromDate">
                        </div>
                        <div class="form-group  col-sm-3">
                            <label for="toDate" style="font-weight: 600;">To</label>
                            <ng-select [items]="toDateList"
                                         [clearable]="false" bindLabel="Text"
                                        bindValue="Value" formControlName="toDate" (change)="getDepreciateableItems()">
                                </ng-select>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="w-100 col">
                        <table class="table  f-ss-14px" *ngIf="depreciateableItems.length>0">
                            <thead>
                                <tr class="tableHeader">
                                    <th scope="col" class="table-header-color text-align-left">Asset Type</th>
                                    <th scope="col" class="table-header-color text-align-center">Accumulated Depreciation Account</th>
                                    <th scope="col" class="table-header-color text-align-center">Depreciation Expense Account</th>
                                    <th scope="col" class="table-header-color text-align-center">Book Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of items">
                                    <td class="text-align-left">
                                        {{item.AssetType}}
                                    </td>
                                    <td class="text-align-center">{{item.DepreciationAccount}}</td>
                                    <td class="text-align-center">{{item.ExpenseAccount}}</td>
                                    <td class="text-align-center">{{item.BookAmountVw}}</td>
                                   
                                </tr>
                            </tbody>
                        </table>

                        <div *ngIf="depreciateableItems.length==0" class="no-assets-found">
                            There are no depreciable assets for this date range
                        </div>
                    </div>
                </div>

                <div class="col-md-12 text-align-right" style="margin-top: 50px;">
                    <button type="button" *ngIf="depreciateableItems.length>0" [disabled]="itemForm.invalid" (click)="ConfirmDepreciation()"
                        class="eCount-def-btn  ml-4 p-save-button">Confirm</button>
                </div>

            </form>
        </div>
    </div>
</app-dashboard>

