import { Component, OnInit , OnDestroy, NgZone} from '@angular/core';
import { ProductServiceService } from '../../../service/Products/product-service.service';
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterLinkRendereComponent } from 'src/app/components/router-link-rendere/router-link-rendere.component';
import { CreateItem } from '../../../models/product.model';
import { Subscription } from 'rxjs';
import { StorageItemCreateComponent } from '../storage-item-create/storage-item-create.component';
import { StorageItemConsumptionListComponent} from '../storage-item-consumption-list/storage-item-consumption-list.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-storage-item-list',
  templateUrl: './storage-item-list.component.html',
  styleUrls: ['./storage-item-list.component.css']
})
export class StorageItemListComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  domLayout = 'autoHeight';
  isItemListUpdate = false;
   searchText: string;
  ProductList: any = [];
  public aModel: CreateItem = new CreateItem();
  itemTypeId = 4;
  constructor(private spinner: NgxSpinnerService,
              private router: Router,
              private dataService: DataService,
              private modalService: NgbModal,
              private route: ActivatedRoute,
              private toaster: ToastrService,
              public ngxSmartModalService: NgxSmartModalService,
              private productServiceService: ProductServiceService) { }
              columnDefs = [
                {
                  headerName: 'Item Code', field: 'ItemCode', cellStyle: {color: 'blue', cursor: 'pointer' }},
                { headerName: 'Item Name', field: 'ItemName' },
                { headerName: 'Cost Price', field: 'PurchasePrice' },
                { headerName: 'Quantity', field: 'StockQuantity' }
                ];

                defaultColDef = {
                  sortable: true,
                  filter: true,
                  flex: 1
                };
  ngOnInit(): void {
    this.getAllProduct();
  }
  getAllProduct = () => {

    this.spinner.show();
    this.ProductList = [];
    this.productServiceService.GetStorageItemList(this.itemTypeId).subscribe((x) => {
      this.spinner.hide();
      this.ProductList = [...x, ...this.ProductList];
    });
  }
  onGridReady(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
  //  this.getAllProduct();
  }

  public createNewItem = () => {
    const modalRef = this.modalService.open(StorageItemCreateComponent,{ size: 'lg',backdrop: 'static', keyboard : false, centered: true });
    this.aModel.Id = 0;
    this.aModel.ItemTypeId = this.itemTypeId;
    modalRef.componentInstance.fromParent = this.aModel;
    modalRef.result.then((result) => {
      if (result.Success){
        this.getAllProduct();
      }
                 }, (reason) => {
                   console.log('Dismissed action: ' + reason);
                 });
  }

 public consumptionPopup = () => {
  if (this.ProductList.length == 0)
   {
    this.toaster.warning('There is no storage product available. Add Product.');
    return;
   }
  const modalRef = this.modalService.open(StorageItemConsumptionListComponent,
    { size: 'lg', backdrop: 'static', keyboard : false, centered: true });

  modalRef.componentInstance.itemList = this.ProductList.filter(x => x.StockQuantity > 0);
  modalRef.result.then((result) => {
    if (result.Success){
      this.getAllProduct();
    }
               }, (reason) => {
                 console.log('Dismissed action: ' + reason);
               });
  }
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
    suppressQuotes: '', 
    columnSeparator: '',
    customHeader: '',
    customFooter: '',};
  }

  onRowClicked(event){
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "Item Code") {
      let id = event.data.Id;
      this.router.navigate([`/storage-item/${id}`]);
    }
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }

}
