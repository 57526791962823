import { Component, OnInit } from '@angular/core';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { PendingListViewComponent } from '../../pending-list-view/pending-list-view.component';
@Component({
  selector: 'app-pending-factory-list',
  templateUrl: './pending-factory-list.component.html',
  styleUrls: ['./pending-factory-list.component.css']
})
export class PendingFactoryListComponent implements OnInit {

  constructor(
    private mainService: FactoryProductionService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) { }
    columnDefs = [
      { headerName: 'Transaction No', field: 'TransactionNo',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      cellRendererFramework: PendingListViewComponent
    },
    { headerName: 'In Date', field: 'InDate', valueFormatter: this.convertGridDateIntoDisplay },
      { headerName: 'In Quantity', field: 'TotalInQty'},
      { headerName: 'In Amount', field: 'TotalAmount' },
      { headerName: 'Out Date', field: 'OutDate', valueFormatter: this.convertGridDateIntoDisplay },
      { headerName: 'Out Quantity', field: 'ProductOutQuantity'},
      { headerName: 'Out Amount', field: 'PerUnitProductionCost' }
  ];
  domLayout = 'autoHeight';
    rowHeight = 275;
    gridApi;
    tempdata: any;
    canApprove = false;
    data: any[];
    private gridColumnApi;
    searchText: string;
    status: string;
    defaultColDef = {
      sortable: true,
      filter: true,
      flex : 1
    };
    rowSelection = 'multiple';
  ngOnInit(): void {
    this.status = this.activeRouter.snapshot.paramMap.get("status");
    this.getPendingFactoryList(this.status);
  }
  convertGridDateIntoDisplay(date) {
    if (date.value == null){
     return '';
    }
    const dateString = moment(date.value).format("Do MMM YYYY")
    return dateString;
  }

  onCellClicked(e) {
    let colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
    if (colHeaderName == "") {
      console.log(e);

    }
  }
  onGridReady(event){
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
  }

  getPendingFactoryList(status)
  {
    localStorage.setItem('pendingListStatus', status);
    this.spinner.show();
    console.log(this.status);
    this.mainService.GetPendingFactoryList().subscribe((x) => {
      this.tempdata = x;
      this.data = this.status === 'factory-in' ? x.factoryIn : x.factoryOut;
      this.status === 'factory-in' ? this.HideOut(false) : this.HideOut(true);
      this.status === 'factory-out' ? this.HideIn(false) : this.HideIn(true);
      this.spinner.hide();
    });
  }


  getSelectedRowData() {
    const selectedNodes = this.gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData);
    if (selectedData.length === 0){
      this.toastr.warning('select at least one item');
      return;
    }
    switch (this.status)
    {
      case 'factory-in':
        this.approvePendingInData(selectedData);
        break;
      case 'factory-out':
        this.approvePendingOutData(selectedData);
        break;
    }
  }

  reloadRowData = (status) => {
   this.status = status;
   localStorage.setItem('pendingListStatus', status);
   this.data = status === 'factory-in' ? this.tempdata.factoryIn : this.tempdata.factoryOut;
   this.status === 'factory-in' ? this.HideOut(false) : this.HideOut(true);
   this.status === 'factory-out' ? this.HideIn(false) : this.HideIn(true);

  }
  approvePendingOutData(selectedData)
  {
    this.spinner.show();
    this.mainService.approvePendingOutList(selectedData).subscribe((x) => {
      this.toastr.success('Factory out successfully approved.');
      this.getPendingFactoryList(this.status);
      this.spinner.hide();
    });
  }
  approvePendingInData(selectedData)
  {
    this.spinner.show();
    this.mainService.approvePendingInList(selectedData).subscribe((x) => {
      this.toastr.success('Factory in successfully approved.');
      this.getPendingFactoryList(this.status);
      this.spinner.hide();
    });
  }
  HideIn(b)
  {
    const columns = this.gridColumnApi.getAllColumns();
    const valueColumn = columns.filter(column => column.getColDef().headerName === "In Date")[0];
    const valueColumn1 = columns.filter(column => column.getColDef().headerName === "In Quantity")[0];
    const valueColumn2 = columns.filter(column => column.getColDef().headerName === "In Amount")[0];
    console.log(columns);
    this.gridColumnApi.setColumnsVisible([valueColumn, valueColumn1, valueColumn2], b);
    this.gridApi.sizeColumnsToFit();

  }
  HideOut(b)
  {
    const columns = this.gridColumnApi.getAllColumns();
    const valueColumn = columns.filter(column => column.getColDef().headerName === "Out Date")[0];
    const valueColumn1 = columns.filter(column => column.getColDef().headerName === "Out Quantity")[0];
    const valueColumn2 = columns.filter(column => column.getColDef().headerName === "Out Amount")[0];
    this.gridColumnApi.setColumnsVisible([valueColumn, valueColumn1, valueColumn2], b);
    this.gridApi.sizeColumnsToFit();

  }


  }





