import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionService } from 'src/app/service/Subscription/subscription.service';

@Component({
  selector: 'app-subscription-expired-warning-modal',
  templateUrl: './subscription-expired-warning-modal.component.html',
  styleUrls: ['./subscription-expired-warning-modal.component.css']
})
export class SubscriptionExpiredWarningModalComponent implements OnInit {

  subscriptionList: any[] = [];
  hasRenewablePackage: Boolean;
  isGracePeriodExpired: Boolean;
  isTrialPacage: Boolean = false;
  @Input() userInformation: any;

  constructor(
    public activeModal: NgbActiveModal,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.getSubscriptionList();
  }

  getSubscriptionList(){
    const companyInfo = {
      CompanyName: this.userInformation.SelectedCompany.Name.toString(),
      CompanyId: this.userInformation.SelectedCompany.CompanyId
    }
    this.subscriptionService.GetSubscriberByCompany(companyInfo).subscribe((x) => {
      this.subscriptionList = x;
      if(x.length == 1){
        this.isTrialPacage = x[0].PackageTypeId == 4 ? true : false;
      }
      this.isGracePeriodExpired = this.subscriptionList.filter(sb => sb.IsActive == false)[0].IsGracePeriodExpired;
      this.hasRenewablePackage = this.subscriptionList.filter( y => y.IsActive == false && y.IsExpired == false).length > 0 ? true : false;
    })
  }

}
