import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FixedAssetService } from '../../../service/FixedAsset/fixedasset.service';
import { DataService } from 'src/app/service/EventEmitter/data.service';
import { AssetItemCreateComponent } from '../asset-item-create/asset-item-create.component';
import { AssetItemModel } from '../../../models/fixedAsset.model';
import { AssetItemDetailsComponent } from '../asset-item-details/asset-item-details.component';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fixed-asset-solddisposed',
  templateUrl: './fixed-asset-solddisposed.component.html',
  styleUrls: ['./fixed-asset-solddisposed.component.css']
})
export class FixedAssetSolddisposedComponent implements OnInit {

  public aModel : AssetItemModel= new AssetItemModel();
  public assetList: any = [];
  public disposalAssetList: any = [];
  public totalDraft:number =0;
  public totalRegistered:number =0;
  public totalSold:number =0;
  private soldStatusId: number =3;
  constructor(
    private pService:FixedAssetService,
    private dataService: DataService,
    private modalService:NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.dataService.fixedAssetItemUpdate.subscribe(x=>{
      this.getAssetList();
    })
    this.getAssetList();
  }

  private getAssetList = () => {
    this.spinner.show();
    this.pService.GetFixedAssetItemList(this.soldStatusId).subscribe((x) => {
      this.assetList = x.Data;
      this.disposalAssetList = x.FixedAssetDisposalViewModelList;
      this.disposalAssetList.forEach(element => {
        element.FixedAssetItem.PurchaseDate = this.convertDateIntoDisplay(element.FixedAssetItem.PurchaseDate);
        element.ActionDate = this.convertDateIntoDisplay(element.ActionDate);
        if(element.FixedAssetItem.AssetNumber.length < 2) {
          element.FixedAssetItem.AssetNumber = "FA-00"+element.FixedAssetItem.AssetNumber;
        }
        else{
          element.FixedAssetItem.AssetNumber = "FA-0"+element.FixedAssetItem.AssetNumber;
        }
      });
      this.totalDraft=x.TotalDraft;
      this.totalRegistered=x.TotalRegistered;
      this.totalSold=x.TotalSold;
      this.spinner.hide()
    },(err)=>{
      this.spinner.hide();
    })
  };

  convertDateIntoDisplay(date) {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  public DetailsAssetItem=(item: any)=>{
    this.spinner.show();
    this.pService.GetAssetItemDetails(item.FixedAssetItem.Id).subscribe((x) => {
      const modalRef = this.modalService.open(AssetItemDetailsComponent,{ size: 'xl',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = x;
      modalRef.componentInstance.disposalData = item;
      this.spinner.hide();
      modalRef.result.then((result) => {
      }, (reason) => {
        console.log('Dismissed action: ' + reason);
      });
    },(err)=>{
      this.spinner.hide();
    })
  };

  GoToInvoice(item) {
    const url = 'income/invoice/INV-'+item.Invoice.InvoicceNo;
    this.router.navigate([url]);
  }

}
