import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { FixedAssetService } from "src/app/service/FixedAsset/fixedasset.service";
import { AddAssigneeComponent } from "../add-assignee/add-assignee.component";
import { AssigneeService } from "src/app/service/Assignee/assignee.service";
import { AssignedItemComponent } from "../assigned-item/assigned-item.component";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { AssignedItem } from "src/app/models/assignee";
import { AssignStatus } from "src/app/enum/assign-status";
import { CellClickedEvent } from "ag-grid-community";
import { AssignedItemDetailsComponent } from "../assigned-item-details/assigned-item-details.component";
import { AssetItemDetailsComponent } from "../asset-item-details/asset-item-details.component";
import { ReturnAssignedItemModalComponent } from "../return-assigned-item-modal/return-assigned-item-modal.component";

@Component({
  selector: "app-assignee-list",
  templateUrl: "./assignee-list.component.html",
  styleUrls: ["./assignee-list.component.css"],
})
export class AssigneeListComponent implements OnInit {
  gridApi;
  status: string;
  AllItems: AssignedItem[];
  AssignedItems: AssignedItem[];
  UnassignedItems: AssignedItem[];

  constructor(
    private route: ActivatedRoute,
    private assigneeService: AssigneeService,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private pService:FixedAssetService
  ) {}

  ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get("status");
    this.getAssignedItemList();
  }

  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }

  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  getAssignedItemList() {
    this.assigneeService.getAssignedItemList().subscribe((x) => {
      this.AllItems = x.AllItems;
      this.UnassignedItems = x.UnassignedItems;
      this.pageLoad(this.status);
    });
  }

  public pageLoad(status: string) {
    this.status = status;
    switch (status) {
      case "All":
        this.AllItems = this.AllItems;
        break;
      case "Assigned":
        this.AssignedItems = this.AllItems.filter((x) => x.AssignStatus == 1);
        break;
      case "Unassigned":
        this.UnassignedItems = this.UnassignedItems;
        break;
    }
  }

  domLayout = "autoHeight";
  rowHeight = 275;
  assignedColumnDefs = [
    {
      headerName: "Assignee No",
      field: "AssignedNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      onCellClicked: (event: CellClickedEvent) => this.openAssignedItemDetailsModal(event.data)
    },
    { headerName: "Asset Name", field: "AssetName" },
    { headerName: "Assignee Name", field: "AssigneeName" },
    {
      headerName: "Assigned Date",
      field: "AssignedDate",
      valueFormatter: this.convertDateIntoDisplay,
    },
    { headerName: "Used Days", field: "UsedDays" },
    {
      headerName: "Estimated Return Date",
      field: "EstimatedReturnDate",
      cellRenderer: (params) => {
        return this.convertEstimatedReturnDateIntoDisplay(params.value);
      }
    },
    { headerName: "Asset Rank", field: "AssetRank" },
    { headerName: "Asset No", 
      field: "AssetNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      onCellClicked: (event: CellClickedEvent) => this.openAssetItemDetailsModal(event.data) },
  ];

  unassignedColumnDefs = [
    { headerName: "Asset No",
      field: "AssetNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      onCellClicked: (event: CellClickedEvent) => this.openAssetItemDetailsModal(event.data) },
    { headerName: "Asset Name", field: "AssetName" },
    { headerName: "Last Assignee Name", field: "AssigneeName" },
    {
      headerName: "Return Date",
      field: "ReturnDate",
      valueFormatter: this.convertDateIntoDisplay,
    },
    { headerName: "Used Days", field: "UsedDays" },
    { headerName: "Asset Rank", field: "AssetRank" },
    {
      headerName: 'History',
      onCellClicked: (event: CellClickedEvent) => this.openUnassignedItemDetailsModal(event.data),
      cellRenderer: function (params) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512">
                  <path  fill="#018CCC" d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                </svg>`
      }
    }
  ];

  convertDateIntoDisplay(date) {
    let dateString = moment(date.value).format("Do MMM YYYY");
    return dateString;
  }

  convertEstimatedReturnDateIntoDisplay(date) {
    if (date != null) {
      let dateString = moment(date).format("Do MMM YYYY");
      return dateString;
    } else {
      return '-';
    }
  }

  allColumnDefs = [
    { headerName: "Asset No",
      field: "AssetNo", 
      cellStyle: { color: "blue", cursor: "pointer" },
      onCellClicked: (event: CellClickedEvent) => this.openAssetItemDetailsModal(event.data) 
    },
    { headerName: "Assignee Name", field: "AssigneeName" },
    { headerName: "Used Days", field: "UsedDays" },
    { headerName: "Asset Rank", field: "AssetRank" },
    { headerName: "Status",
     field: "AssignStatus",
     cellRenderer: (params) => {
      return this.convertStatusIntoDisplay(params.value);
    }
     }
  ];

  convertStatusIntoDisplay(status: any) {
    if(status == AssignStatus.Assigned) {
      return 'Assigned'
    }
    else {
      return 'Unassigned'
    }
  }

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    suppressHorizontalScroll: true,
    scrollbarWidth: 0,
    resizable: true,
  };

  onGridReady(event) {
    this.gridApi = event.api;
  }

  openUnassignedItemDetailsModal(data: any) {
    this.spinner.show();
    this.assigneeService.getUnassignedItemByFixedAssetItemId(data.FixedAssetItemId).subscribe((x) => {
      if(x) {
        const modalRef = this.modalService.open(AssignedItemDetailsComponent, { size: "xl", backdrop: "static", keyboard: false, centered: true,});
        modalRef.componentInstance.fromParent = x;
        modalRef.componentInstance.parentStatus = this.status;
        this.spinner.hide();
        modalRef.result.then(
          (result) => {
          },
          (reason) => {
            console.log(reason);
          }
        );
      }
    });
  }

  openAssignedItemDetailsModal(data: any) {
    this.spinner.show();
    this.assigneeService.getAssignedItemById(data.Id).subscribe((x) => {
      if(x) {
        const modalRef = this.modalService.open(AssignedItemDetailsComponent, { size: "xl", backdrop: "static", keyboard: false, centered: true,});
        modalRef.componentInstance.fromParent = x;
        modalRef.componentInstance.parentStatus = this.status;
        this.spinner.hide();
        modalRef.result.then(
          (result) => {
          },
          (reason) => {
            console.log(reason);
          }
        );
      }
    });
  }

  openAssetItemDetailsModal(item: any) {
    this.spinner.show();
    this.pService.GetAssetItemDetails(item.FixedAssetItemId).subscribe((x) => {
      const modalRef = this.modalService.open(AssetItemDetailsComponent,{ size: 'lg',backdrop: 'static', keyboard : false});
      modalRef.componentInstance.fromParent = x;
      modalRef.componentInstance.fromAssignee = true;
      this.spinner.hide();
      modalRef.result.then((result) => {
        this.spinner.hide();
      }, (reason) => {
        console.log('Dismissed action: ' + reason);
      });
    },(err)=>{
      this.spinner.hide();
    })
  };

  AddAssignee() {
    const modalRef = this.modalService.open(AddAssigneeComponent, {
      size: "md",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.result.then(
      (result) => {
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  AssignItem() {
    const modalRef = this.modalService.open(AssignedItemComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.result.then(
      (result) => {
        this.pageLoad("Assigned");
        this.getAssignedItemList();
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  TransferAssignedItem() {
    this.spinner.show();
    const selectedNode = this.gridApi.getSelectedNodes();
    const selectedData = selectedNode.map((node) => node.data);
    
    if(selectedData.length == 0) {
      this.toaster.warning("Please select an item.");
      this.spinner.hide();
      return;
    }

    const modalRef = this.modalService.open(AssignedItemComponent, { size: "lg", backdrop: "static", keyboard: false, centered: true,});
    modalRef.componentInstance.fromParent = selectedData;
    this.spinner.hide();
    modalRef.result.then(
      (result) => {
        this.pageLoad("Assigned");
        this.getAssignedItemList();
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  openReturnAssignedItemModal() {
    this.spinner.show();
    const selectedNode = this.gridApi.getSelectedNodes();
    const selectedData = selectedNode.map((node) => node.data);

    if(selectedData.length == 0) {
      this.toaster.warning("Please select an item.");
      this.spinner.hide();
      return;
    }

    const modalRef = this.modalService.open(ReturnAssignedItemModalComponent, { size: "md", backdrop: "static", keyboard: false, centered: true });
    modalRef.componentInstance.fromParent = selectedData;
    this.spinner.hide();
    modalRef.result.then(
      (result) => {
        this.pageLoad("Unassigned");
        this.getAssignedItemList();
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

}

