import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup,FormArray, Validators, FormControl } from '@angular/forms';
import { PurchaseOrderService } from '../../../service/PurchaseOrder/purchase-order.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
import * as moment from 'moment';
import { AcountingServiceService } from 'src/app/service/Accounting/acounting-service.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FileEmitterService } from 'src/app/service/EventEmitter/file.emitter.service';
import { BillsServiceService } from 'src/app/service/Bill/bills-service.service';
import { BillItem, createBill } from 'src/app/models/income.model';
import { iQuidiActionType } from 'src/app/enum/iquidiActionType';

@Component({
  selector: 'app-purchase-order-details',
  templateUrl: './purchase-order-details.component.html',
  styleUrls: ['./purchase-order-details.component.css'],
  host: {
    '(document:click)': 'onWindowClick($event)',
  },
})
export class PurchaseOrderDetailsComponent implements OnInit {

  isEditable: boolean = false;
  canCreateBill: boolean = false;
  isBillCreated: boolean = false;
  totalFile: number=0;
  PurchaseOrderData: any = [];
  allSaleableItem: any = [];
  chartOfAccount: any = [];
  taxRateList:any=[];
  PurchaseOrderId: string;
  PurchaseOrderNo: string;
  billData: createBill;
  billItem: BillItem[] = [];
  billNo : any;
  billStatus: string;
  PurchaseOrderStatusId: any;
  PurchaseOrderReceipts: any = [];
  PurchaseOrderBills: any = [];
  totalPaidAmount: number = 0;
  totalBillAmount: number = 0;
  totalQuantity: number = 0;
  totalReceived: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public fb: FormBuilder,
    public fileEmitterService: FileEmitterService,
    private purchaseService: PurchaseOrderService,
    private settingService:SettingsServiceService,
    private billService: BillsServiceService,
    private accountingService: AcountingServiceService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {

    this.PurchaseOrderNo = this.route.snapshot.paramMap.get("orderNo");
    this.PurchaseOrderId = this.route.snapshot.paramMap.get("id");

    if( this.PurchaseOrderNo != null ){
      this.getOrderDetailsByOrderNo(this.PurchaseOrderNo);
    }else{
      this.getOrderDetails(this.PurchaseOrderId);
      this.getReceiptsForPurchaseOrder(this.PurchaseOrderId);
    }

    this.getAllSaleableItem();
    this.getTaxRateList();
    this.getChartOfAccount();
  }

  getReceiptsForPurchaseOrder(id){
    this.purchaseService.GetReceiptsForPurchaseOrder(id).subscribe((x) => {
      this.PurchaseOrderReceipts = x.data;
      this.PurchaseOrderBills = x.bills;
      this.PurchaseOrderBills.forEach((item) => {
        this.totalPaidAmount = this.totalPaidAmount + Number(item.PaidAmount);
        this.totalBillAmount = this.totalBillAmount + Number(item.BillAmount);
      });
    });
  }

  setBillStatus(id) {
    switch (id) {
      case 0:
        return 'All';
      case 1:
        return 'Draft';
      case 2:
        return 'Awaiting Approval';
      case 3:
        return 'Approved';
      case 4:
        return 'Billed';
      default:
        return '';
    }
  }

  deletePurchaseOrder() {
    if (this.PurchaseOrderBills.length > 0) {
      this.toaster.warning("A purchase order can only be deleted after the corresponding bill for the same order has been deleted!");
      return;
    }
    this.spinner.show();
    this.PurchaseOrderData.IQuidiActionType = iQuidiActionType.Delete;
    this.purchaseService.StatusUpdate(this.PurchaseOrderData).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(`Purchase Order ${this.PurchaseOrderNo} has been successfully deleted`, 'Success!');
        this.router.navigate(['procurement/purchase-order-list/All']);
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  MarkAsComplete(){
    this.spinner.show();
    this.purchaseService.MarkAsComplete(this.PurchaseOrderData).subscribe( (x) => {
      if (x.Success) {
        this.toaster.success(`Purchase Order ${this.PurchaseOrderNo} has been successfully marked as complete`, 'Success!');
        //this.router.navigate(['procurement/purchase-order-list/All']);
        location.reload();
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  getChartOfAccount=()=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

    })
  }
  getAccountName=(item)=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

        item.forEach(element =>
        {
           var x=this.chartOfAccount.find(p=> p.Id===element.AccountId);
           element.AccountName=x.AccountName;

        });


      })
      return item;
  }
  private getTaxRateList = () => {
    this.settingService.GetTaxRateList().subscribe((x: []) => {
      this.taxRateList = x;
    });
  }
  getOrderDetails = (id:any) => {
    this.spinner.show();
    this.purchaseService.GetOrderDetails(id).subscribe((x) => {
      console.log(x);
      if (x) {
        this.fileEmitterService.emitFileRefIdUpdate({id:this.PurchaseOrderId});
          this.PurchaseOrderData = x.Details;
          this.PurchaseOrderStatusId = x.Details.StatusId;
          this.PurchaseOrderNo = x.Details.OrderNo;
          this.PurchaseOrderData.PurchaseOrderItems.forEach((item) => {
            this.totalQuantity = this.totalQuantity + item.Quantity;
          });
          this.PurchaseOrderData.ReceivedItems.forEach((item) => {
            this.totalReceived = this.totalReceived + item;
          });
          this.getAccountName(this.PurchaseOrderData.PurchaseOrderItems);
          this.isDescriptivePurchaseOrder();
          this.isEditable = (this.PurchaseOrderData.StatusId == 2) ? true : false;
          this.canCreateBill = (this.PurchaseOrderData.StatusId == 3) ? true : false;
          this.isBillCreated = x.BillList.length>0?true:false;
          this.billStatus = this.setBillStatus(x.Details.StatusId);
          this.spinner.hide();
      }
    })
  }

  isDescriptivePurchaseOrder() {
    return !this.PurchaseOrderData?.PurchaseOrderItems?.some((x) => x.ItemId != null);
  }

  isDeleted(){
    if(this.totalPaidAmount > 0 || this.totalReceived > 0 || this.PurchaseOrderStatusId == 4){
      return false;
    }
    else{
      return true;
    }
  }

  getOrderDetailsByOrderNo = (orderNo:any) => {
    this.spinner.show();
    this.purchaseService.GetOrderDetailsByOrderNo(orderNo).subscribe((x) => {
      console.log(x);
      if (x) {
        this.PurchaseOrderId = x.Details.Id;
        this.PurchaseOrderNo = x.Details.OrderNo;
        this.fileEmitterService.emitFileRefIdUpdate({id:this.PurchaseOrderId});
        this.PurchaseOrderData = x.Details;
        this.PurchaseOrderStatusId = x.Details.StatusId;
        this.getAccountName(this.PurchaseOrderData.PurchaseOrderItems);
        this.isEditable = (this.PurchaseOrderData.StatusId == 2) ? true : false;
        this.canCreateBill = (this.PurchaseOrderData.StatusId == 3) ? true : false;
        this.isBillCreated = x.BillList.length>0?true:false;
        this.billStatus = this.setBillStatus(x.Details.StatusId);
        this.getReceiptsForPurchaseOrder(this.PurchaseOrderId);
      }
      this.spinner.hide();
    })
  }

  print = () => {
    let hostUrl = location.origin + "/#/";
    window.open(`${hostUrl}procurement/preview/${this.PurchaseOrderId}`, '_blank');
  }
  formatTaxRate(id: number) {

    let value = this.taxRateList.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.Name;
    }
  }
  twoDecimalPlace = (num) => {
    return num ? num.toFixed(2) : 0.00;
  }
  getAllSaleableItem = () => {
    this.purchaseService.GetPurchaseableItems().subscribe((x) =>
      this.allSaleableItem = x)
  }
  formatItem(id: string) {

    let value = this.allSaleableItem.find(e => e.Id == id);
    if (!value) {
    } else {
      return value.ItemName;
    }
  }
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }
  public setTotalFile(tf:number) {
    this.totalFile=tf;
  }
  onWindowClick(event) {
    if ((event.target.id == "attachmentDropdown") || (event.target.id == "attachment") || (event.target.id == "files") || (event.target.id =="Preview")) {

    }
    else if (event.target.id == 'attachment_btn') {
      this.openAttachmentDropdown()
    }
    else {
      if (document.getElementById("attachmentDropdown").classList.contains("show")) {
        document.getElementById("attachmentDropdown").classList.remove("show");
        document.getElementById("custom_notch").classList.remove("show");
      }
    }
  }
  openAttachmentDropdown() {
    document.getElementById("attachmentDropdown").classList.toggle("show");
    document.getElementById("custom_notch").classList.toggle("show");
  }
  public saveAsApproved() {

    this.spinner.show();
    this.PurchaseOrderData.IQuidiActionType = iQuidiActionType.Approve;
    this.purchaseService.StatusUpdate(this.PurchaseOrderData).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Approved successfully.');
        this.router.navigate(['procurement/purchase-order-list/Approved']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  closeModal() {
    document.getElementById("logoUploader").classList.remove("show")
    document.getElementById("logoUploader").classList.remove('shown');
  }
  public createBill = () => {
    this.generateBillNo();
    document.getElementById("logoUploader").classList.add("show")
    document.getElementById("logoUploader").classList.add('shown');
    // const modalRef = this.modalService.open(CreateBillPopupComponent,{ size: 'md',backdrop: 'static', keyboard : false});
    // modalRef.componentInstance.fromParent = this.PurchaseOrderData;
    // modalRef.result.then((result) => {
    //              }, (reason) => {
    //                console.log('Dismissed action: ' + reason);
    //              });
  }
  generateBillNo = () => {
    this.billService.GenerateBillNo().subscribe((x: any) => {
     this.billNo = x;
    });
  }
  setBillData = (x: any) => {

      this.billData = {
        Id: 0,
        BillNo: this.billNo,
        SupplierId: x.Contacts.Id,
        BillDate: x.OrderDate,
        DueDate: x.DeliveryDate,
        ReferenceNo: 'PO-'+ this.PurchaseOrderNo,
        BillAmount: x.OrderAmount,
        IsConversion: false, // by default false
        PostingModuleId: 2,  // 2 means project
        ExternalTransactionId: x.Id, // purchase order id
        TaxTypeId: x.TaxTypeId,
        TaxAmount: x.TaxAmount,
        StatusId: 1,
        AdvanceTaxAmount: x.AdvanceTaxAmount,
        Notes: x.Notes,
        BillItems: this.billItem
      }
    this.setBillItem(x.PurchaseOrderItems)
  }
  setBillItem = (x:any) => {

      x.forEach(data => {
        let item = {
          ItemId: data.ItemId,
          Description: data.Description,
          Quantity: data.Quantity,
          UnitPrice: data.UnitPrice,
          LineTotal: data.LineTotal,
          AccountId: data.AccountId,
          selected: true,
          TaxRateId:data.TaxRateId,
          TaxRatePercent: data.TaxRatePercent,
          AdvanceTaxRateId:data.AdvanceTaxRateId,
          AdvanceTaxPercent:data.AdvanceTaxPercent
      }
      this.billItem.push(item);
    });

    this.billData.BillItems = this.billItem;
  }
  public CreateDraftBill() {
    this.spinner.show();
    this.setBillData(this.PurchaseOrderData);
    this.billService.saveAsDraftBillData(this.billData).subscribe((x) => {
      this.displaySuccessResponse(x, `Bill ${this.billData.BillNo} has been successfully saved`);
    });
  }
  private displaySuccessResponse(x:any,sMessage:string) {
    if (x.Success) {
      this.toaster.success(sMessage, 'Success!');
      this.redirectToListPage();
      this.closeModal();
      this.spinner.hide();
    } else {
      this.toaster.warning(x.Message);
      this.spinner.hide();
    }
  }
  private redirectToListPage(){
    this.purchaseService.SaveAsBilled(this.PurchaseOrderData).subscribe((x) => {
      this.router.navigate(['expense/bill/'+'Bill-'+this.billData.BillNo]);
    });
  }
  isApproved(){
    if(this.PurchaseOrderStatusId == 3){
      return true;
    }
    else{
      return false;
    }
  }

  isBilled(){
    if(this.PurchaseOrderStatusId == 4){
      return true;
    }
    else{
      return false;
    }
  }

  isReceivedMax(Quantity, ReceivedItems){
    if(ReceivedItems != Quantity && ReceivedItems != 0){
      return true;
    }
    else{
      return false;
    }
  }

  canMarkAsCompleted(){
    if(this.PurchaseOrderData.canMarkAsComplete && this.totalBillAmount == this.totalPaidAmount && this.PurchaseOrderBills.length > 0 && this.PurchaseOrderStatusId != 4) {
      return true;
    }
    else{
      return false;
    }
  }
}
