import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AttachmentServiceService } from 'src/app/service/Attachment/attachment-service.service';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-custom-document-approval-flow-modal',
  templateUrl: './custom-document-approval-flow-modal.component.html',
  styleUrls: ['./custom-document-approval-flow-modal.component.css']
})

export class CustomDocumentApprovalFlowModalComponent implements OnInit {

  @Input() submittedAttachments;

  CustomDocumentApprovalFlowForm: FormGroup;
  documentApprovalForm: FormGroup;

  approverRoleList: any = [];
  approverEmailList: any = [];
  userList: any = [];
  EmailList: any = [];

  documentLevel: number = 0;
  documentIndex: number = 0;
  approvalMenuId: number = 0;

  documentLevelName: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private pService: SettingsServiceService,
    private attachmentService: AttachmentServiceService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getDocumentApprovalLevel();
    this.initializeDocumentForm();
    this.getMasterDataList();
  }

  initializeForm() {
    this.CustomDocumentApprovalFlowForm = this.fb.group({
      SelectedApprovalFlow: ['0']
    });
  }
  setParentData() {

  }

  submit(){
    this.spinner.show();
    if(Number(this.CustomDocumentApprovalFlowForm.value.SelectedApprovalFlow) != 0){
      this.submittedAttachments.forEach(attachment => attachment.CustomApprovalLevel = this.documentApprovalForm.value.DocumentApprovalLevel);
    }
    this.attachmentService.docDraftToApproval(this.submittedAttachments).subscribe((x) => {
      this.toaster.success("Document submitted Successfully.");
      this.activeModal.close();
      location.reload();
      this.spinner.hide();
    }, (err) => {
      console.log(err);
    });
  }

  getDocumentApprovalLevel = () => {
    this.spinner.show();
    this.pService.GetDocumentApprovalLevel().subscribe((x) => {
      this.spinner.hide();
      this.documentIndex = 0;
      this.documentLevel = 0;
      this.addDocumentLevel(x[0]);
      this.documentLevel = this.documentLevel + 1;
      this.addLevelDocumentItem(x[0].ApprovalLevelItem[0], this.documentIndex);
      this.documentIndex = this.documentIndex + 1;
    });
  }

  addDocumentLevel(x: any) {
    this.DocumentApprovalLevel().push(this.newDocumentLevel(x));
  }

  newDocumentLevel(x: any): FormGroup {
    this.documentLevelName = "Level " + x.LevelId;
    return this.fb.group({
      Id: [0],
      ApprovalMenuId: [x.ApprovalMenuId],
      CompanyId: [x.CompanyId],
      LevelId: [x.LevelId],
      LevelName: [this.documentLevelName],
      CustomApprovalLevelItem: this.fb.array([]),
    });
  }

  addLevelDocumentItem(x: any, levelIndex: number) {
    this.documentLevelItems(levelIndex).push(this.newLevelItem(x));
  }

  newLevelItem(x: any): FormGroup {
    return this.fb.group({
      Id: [0],
      ApprovalLevelId: [0],
      ApproverId: [x.ApproverId],
      ApproverEmailId: [x.ApproverEmailId],
      ModifiedById: [x.ModifiedById],
      ModifiedAt: [x.ModifiedAt],
    });
  }

  getMasterDataList = () => {
    this.pService.GetCompanyUsers().subscribe((x) => {
      this.EmailList = x;
      this.approverEmailList = x;
    });
    this.pService.GetCompanyRoles().subscribe((x) => {
      this.approverRoleList = x.filter(y => y.Name != 'PortalUser');
    });
    this.pService.GetAllUsers().subscribe((x) => {
      this.userList = x;
    });
  }

  initializeDocumentForm() {
    this.documentApprovalForm = this.fb.group({
      DocumentApprovalLevel: this.fb.array([]),
    });
  }

  DocumentApprovalLevel(): FormArray {
    return this.documentApprovalForm.get("DocumentApprovalLevel") as FormArray;
  }

  documentLevelItems(levelIndex: number): FormArray {
    return this.DocumentApprovalLevel().at(levelIndex).get("CustomApprovalLevelItem") as FormArray;
  }

  addBlankDocumentLevel() {
    this.DocumentApprovalLevel().push(this.newBlankDocumentLevel());
    this.addBlankLevelDocumentItem(this.documentLevel - 1);
  }

  newBlankDocumentLevel(): FormGroup {
    this.documentLevel += 1;
    this.documentLevelName = "Level " + this.documentLevel;
    this.approvalMenuId = 3;
    return this.fb.group({
      Id: 0,
      ApprovalMenuId: [this.approvalMenuId],
      CompanyId: [0],
      LevelId: [this.documentLevel],
      LevelName: [this.documentLevelName],
      CustomApprovalLevelItem: this.fb.array([]),
    });
  }

  addBlankLevelDocumentItem(levelIndex: number) {
    this.documentLevelItems(levelIndex).push(this.newBlankLevelDocumentItem());
  }

  newBlankLevelDocumentItem(): FormGroup {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == 1);
    return this.fb.group({
      Id: 0,
      ApprovalLevelId: [0],
      ApproverId: [1, Validators.required],
      ApproverEmailId: ["", Validators.required],
      ModifiedById: [""],
      ModifiedAt: [null],
    });
  }

  ChangeEmailByDocumentRole = (id, index, levelid) => {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == id.value.ApproverId);
    const p = this.DocumentApprovalLevel().at(levelid).get('CustomApprovalLevelItem') as FormArray;
    p.at(index).get('ApproverEmailId').patchValue('');
  }

  changeApproveIfDocumentRoleChange = (id, index, levelid) => {
    const p = this.DocumentApprovalLevel().at(levelid).get("CustomApprovalLevelItem") as FormArray;
    const value = this.EmailList.filter(x => x.Id === p.value[index].ApproverEmailId);
    if (value.length > 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].RoleId == p.at(index).get('ApproverId')) {
          p.at(index).get('ApproverId').patchValue(value[i].RoleId); break;
        }
      }
    }
    else {
      p.at(index).get('ApproverId').patchValue(value[0].RoleId);
    }

  }

  removeLevelDocumentItem(levelIndex: number, itemIndex: number, item: any) {
    if (item.value.Id === 0) {
      if (this.isLastDocumentLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.documentLevelItems(levelIndex).removeAt(itemIndex);
        if (itemIndex == 0 && this.documentLevelItems(levelIndex).value.length == 0) {
          this.removeDocumentLevel(levelIndex);
          this.documentLevel -= 1;
        }
        this.toaster.success("Deleted successfully.");
      }
    } else {
      if (this.isLastDocumentLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.spinner.show();
        this.pService.DeleteApprovalLevelItem(item.value.Id).subscribe((x) => {
          if (x.Success) {
            this.toaster.success("Deleted successfully.");
            this.documentLevelItems(levelIndex).removeAt(itemIndex);
            if (itemIndex == 0 && this.documentLevelItems(levelIndex).value.length == 0) {
              this.removeDocumentLevel(levelIndex);
              this.documentLevel -= 1;
            }
            this.spinner.hide();
          } else {
            this.toaster.warning(x.Message);
            this.spinner.hide();
          }
        });
      }
    }
  }

  isLastDocumentLevel(levelIndex: number) {
    if (levelIndex < this.documentLevel - 1 && this.documentLevelItems(levelIndex).length == 1) {
      return true;
    }
  }

  removeDocumentLevel(levelIndex: number) {
    this.DocumentApprovalLevel().removeAt(levelIndex);
  }

  saveCustomDocumentApprovalFlow() {
    if (this.isDocumentFormValid()) {
      this.spinner.show();
      this.pService.SaveApprovalLevel(this.documentApprovalForm.value.DocumentApprovalLevel).subscribe((x) => {
        if (x.Success) {
          this.approverEmailList = this.EmailList;
          this.initializeDocumentForm();
          this.getDocumentApprovalLevel();
          this.toaster.success("Saved successfully.");
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      });
    } else {
      this.toaster.error("Please provide all the information.");
    }
  }

  isDocumentFormValid() {
    var formData = this.documentApprovalForm.value.DocumentApprovalLevel;
    var len = formData.length;
    for (var i = 0; i < len; i++) {
      var app_len = formData[i].CustomApprovalLevelItem.length;
      var app_data = formData[i].CustomApprovalLevelItem;
      for (var j = 0; j < app_len; j++) {
        if (this.isNull(app_data[j].ApproverEmailId)) {
          return false;
        }
      }
    }
    return true;
  }

  isNull(data) {
    if (data == '' || data == null || data == undefined) {
      return true;
    } else {
      return false;
    }
  }

}
