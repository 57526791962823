import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AssignStatus } from 'src/app/enum/assign-status';
import { AssignedItem, Assignee } from 'src/app/models/assignee';
import { FixedAssetItem } from 'src/app/models/fixedAsset.model';
import { AssigneeService } from 'src/app/service/Assignee/assignee.service';
import { FixedAssetService } from 'src/app/service/FixedAsset/fixedasset.service';

@Component({
  selector: 'app-assigned-item',
  templateUrl: './assigned-item.component.html',
  styleUrls: ['./assigned-item.component.css']
})

export class AssignedItemComponent implements OnInit {

  @Input() fromParent;

  AddAssignedItemForm: FormGroup;

  UnAssignedFixedAssetItemList: FixedAssetItem[];
  AssetItemList: FixedAssetItem[];
  assigneeList: Assignee[];
  assignee: Assignee;
  AssetName: string;
  fixedAssetItem: FixedAssetItem;

  constructor(
    public fb: FormBuilder,
    public fixedAssetService: FixedAssetService,
    public assigneeService: AssigneeService,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAssigneeList();
    this.InitializeForm();
    if(this.fromParent != undefined) {
      this.AssetName = this.fromParent[0]?.AssetName;
      this.setParentData();
    }
    this.fetchFixedAssetItems();
  }

  getAssigneeList(): void {
    this.assigneeService.getAssigneeList().subscribe((assigness: Assignee[]) => {
      this.assigneeList = assigness;
    })
  }

  fetchFixedAssetItems(): void {
    this.assigneeService.GetAllItemList().subscribe((x) => {
      this.AssetItemList = x.AllItems.Result;
      this.UnAssignedFixedAssetItemList = x.AllItems.Result.filter(x => x.AssignedStatus == 2);
    });
  }
  
  InitializeForm() {    
    this.AddAssignedItemForm = this.fb.group({
      AssigneeName: ['', Validators.required],
      AssigneeRole: [''],
      AssigneeEmail: ['', Validators.required],
      AssetId: [''],
      AssetRank: [5, Validators.required],
      AssignedDate: [new Date(), Validators.required],
      EstimatedReturnDate: [''],
      DamageReason: ['']
    });
    this.showDefaultDate(new Date());
  }

  setParentData() {
    this.AddAssignedItemForm.controls["AssigneeName"].patchValue(this.fromParent[0].AssigneeId);
    this.AddAssignedItemForm.controls["AssigneeRole"].patchValue(this.fromParent[0].AssigneeRole);
    this.AddAssignedItemForm.controls["AssigneeEmail"].patchValue(this.fromParent[0].AssigneeEmail);
    this.AddAssignedItemForm.controls["AssetId"].patchValue(this.fromParent[0].FixedAssetItemId);
    this.AddAssignedItemForm.controls["AssetRank"].patchValue(this.fromParent[0].AssetRank);
    this.AddAssignedItemForm.controls["AssignedDate"].patchValue(new Date());
    this.AddAssignedItemForm.controls["EstimatedReturnDate"].patchValue('');
    this.AddAssignedItemForm.controls["DamageReason"].patchValue(this.fromParent[0].DamageReason);

  }

  selectAssignee() {
    const selectedAssignee = this.assigneeList.find(assignee => assignee.Id === this.AddAssignedItemForm.get("AssigneeName").value);
    if (selectedAssignee) {
        this.AddAssignedItemForm.get("AssigneeRole").patchValue(selectedAssignee.Role);
        this.AddAssignedItemForm.get("AssigneeEmail").patchValue(selectedAssignee.Email);
    }
  }

  SaveAssignedItem() {
    if(new Date(this.AddAssignedItemForm.value.EstimatedReturnDate) <  new Date(this.AddAssignedItemForm.value.AssignedDate)) {
      this.toaster.warning('Please choose a return date that is either on or after the assigned date!');
      return;
    }
    if(!this.AddAssignedItemForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    this.spinner.show();
    let data: AssignedItem = this.getFormData();
    this.assigneeService.AssignItem(data).subscribe((x) => {
      if(x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["/fixed-asset/assignee-list/Assigned"]);
        this.activeModal.close();
        this.spinner.hide();
      }
      else{
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  getFormData(): AssignedItem {
    this.fixedAssetItem = this.UnAssignedFixedAssetItemList.filter((x) => x.Id == this.AddAssignedItemForm.value.AssetId)[0];
    this.assignee = this.assigneeList.find(assignee => assignee.Id === this.AddAssignedItemForm.get("AssigneeName").value);

    let assignedItem: AssignedItem = {
      Id: 0,
      Assignee: this.assignee,
      AssigneeId: this.assignee.Id,
      FixedAssetItem: this.fixedAssetItem,
      FixedAssetItemId: this.fixedAssetItem.Id,
      AssignedDate: this.AddAssignedItemForm.value.AssignedDate,
      ReturnDate: null,
      EstimatedReturnDate: this.AddAssignedItemForm.value.EstimatedReturnDate,
      AssetRank: this.AddAssignedItemForm.value.AssetRank,
      DamageReason: null,
      AssignStatus: AssignStatus.Assigned,
      IsActive: 0,
      CompanyId: this.assignee.CompanyId,
    };

    return assignedItem;
  }

  TransferAssignedItem() {
    this.spinner.show();
    let data: AssignedItem = this.getFormDataForTransfer();
    if(data.Assignee.Email == this.fromParent[0].AssigneeEmail){
      this.toaster.warning('Please change assignee name. This asset has already assinged for this assignee!');
      this.spinner.hide();
      return;
    }
    this.assigneeService.TransferAssignedItem(data).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(x.Message);
        this.router.navigate(["/fixed-asset/assignee-list/Assigned"]);
        this.activeModal.close();
        this.spinner.hide();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    });
  }

  getFormDataForTransfer(): AssignedItem {
    this.fixedAssetItem = this.AssetItemList.filter((x) => x.Id == this.AddAssignedItemForm.value.AssetId)[0];
    this.assignee = this.assigneeList.find(assignee => assignee.Id === this.AddAssignedItemForm.get("AssigneeName").value);

    let assignedItem: AssignedItem = {
      Id: this.fromParent[0].Id,
      Assignee: this.assignee,
      AssigneeId: this.assignee.Id,
      FixedAssetItem: this.fixedAssetItem,
      FixedAssetItemId: this.fixedAssetItem.Id,
      AssignedDate: this.AddAssignedItemForm.value.AssignedDate,
      ReturnDate: null,
      EstimatedReturnDate: this.AddAssignedItemForm.value.EstimatedReturnDate,
      AssetRank: this.AddAssignedItemForm.value.AssetRank,
      DamageReason: this.AddAssignedItemForm.value.DamageReason,
      AssignStatus: AssignStatus.Assigned,
      IsActive: 0,
      CompanyId: this.assignee.CompanyId,
    };

    return assignedItem;
  }

  showDefaultDate(e) {
    const value = this.AddAssignedItemForm.value.AssignedDate;
    $('#adstest').on('change', function() {
      this.setAttribute('data-date', moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  showEstimatedReturnDate(e) {
    const value = this.AddAssignedItemForm.value.EstimatedReturnDate;
    const v = $('#adstest2').on('change', function() {
      this.setAttribute('data-date',moment(value, 'YYYY-MM-DD').format( this.getAttribute('data-date-format')));
    }).trigger('change');
  }

  canDisabledAssetName(){
    if(this.fromParent != undefined){
      return true;
    }
    else{
      return false;
    }
  }

}
