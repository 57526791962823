<app-dashboard>
    <app-page-header-title mainTitle="Sales"  subTitle="Conversion Balances" backURL="/accounting/existing-balances">
        <div class="full-width text-align-right pr">
            <button type="button" (click) ="openInvoicePopup()" class="eCount-def-btn ml-4">Add Invoice</button>
        </div> 
        </app-page-header-title>

             <div class="ml">
                <p class="container">Enter invoices sent on or before  {{convertDateIntoDisplay(previousConversionDate)}} that have not been fully paid</p>
                <div class="col-sm-12 pl-0 pr-0">
                    <div>
                        <ag-grid-angular #agGridinvoice id="myGridInvoice" class="ag-theme-balham col"
                            [columnDefs]="gridOptionsInvoice.columnDefs"
                            [defaultColDef]="gridOptionsInvoice.defaultColDef"
                            [domLayout]="gridOptionsInvoice.domLayout" [rowData]="allInvoice"
                            (gridReady)="onGridReadyInvoice($event)" (rowClicked)="onInvoiceRowClicked($event)">
                        </ag-grid-angular>
                    </div>
                    <div class="col-sm-12 ">
                        <div class="total f-ss-14px" style="margin-top:10px;">
                            <div class="subtotal no-border">
                                <label> Total BDT</label>
                                <span>{{TotalInvoiceAmount}}</span>
                            </div>
                            <div class="goal">
                                <label>BDT Accounts Receivable Balance</label>
                                <span>{{TotalDebit}}</span>
                            </div>
                            <div class="bottom-line green" [hidden]='!IsInvoiceMatch'>
                                <label><img src="../../../../assets/image/green-tick-icon.jpg"
                                        style="height: 15px;width: 15px;margin-left: 60px;margin-top: 4px;" />
                                    Matched</label>
                            </div>
                            <div class="bottom-line red">
                                <label> Balance out by</label>
                                <span>{{ReceivableCalculateBalance}}</span>
                            </div>
                            <div class="red joined">
                                <span style="font-size: 13px;">
                                    Add sales invoices or credit notes to bring this balance to zero.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12" style="display: inline-block;">
                        <button type="button"  [disabled]="btndisbaled" [class]="btndisbaled ? 'disableds': 'enabled'" style="float: right;" (click)="NextBtnClick()" class="eCount-def-btn ml-4">Next</button>
                    </div>
                </div>
            </div>
</app-dashboard>