import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup,FormArray, Validators, FormControl } from '@angular/forms';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-factory-out-entry',
  templateUrl: './factory-out-entry.component.html',
  styleUrls: ['./factory-out-entry.component.css']
})
export class FactoryOutEntryComponent implements OnInit {

  productId = null;
  public factoryOutForm: FormGroup;
  public ProductionOutItem: FormArray;
  public FactoryOutSalesOrder: FormArray;
  public OperatingCostItem: FormArray;
  productList=[];
  rawMaterialStockItems=[];
  public salesOrderCount:any = [];
  public salesOrders:any = [];
  totalMaterialCost:number=0;
  public salesQuantityValidation:boolean = false;
  ifSubmit = false;
  constructor(
    private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private router: Router,
    private toaster: ToastrService,
    private pService: FactoryProductionService) { }

  ngOnInit(): void {
    this.initializeForm();
    this.getProducts();
  }

  getProducts = () => { 
    this.pService.GetFinishProducts().subscribe((x) => {
      this.productList=x;
    });
  }
  private getSalesOrderList(productId:number){
    
    this.pService.GetFactoryOutSalesOrder(productId).subscribe((x) => {
      this.salesOrders = x.salesOrders;
      this.salesOrderCount = x.salesOrderCount;
    });
  }
  public loadRawMaterials(){
    var inForm=this.factoryOutForm.get('ProductionOutItem') as FormArray;
    inForm.clear();
    this.pService.GetRawMaterialStock(this.factoryOutForm.value.ProductId).subscribe((x) => {
      this.rawMaterialStockItems=x;
      x.forEach(item => {
        this.addItem(item);
      });
    });
    this.getSalesOrderList(this.factoryOutForm.value.ProductId);
  }

  initializeForm() {
    this.factoryOutForm = this.fb.group({
      Id: [0],
      ProductId: ['', Validators.required],
      TransactionNo: [''],
      OutDate: ['', Validators.required],
      BatchNo: ['',Validators.required],
      ProductOutQuantity: [0, Validators.required],
      PerUnitMaterialCost: [0, Validators.required],
      PerUnitOperatingCost: ['', Validators.required],
      PerUnitProductionCost: [0, Validators.required],
      StatusId: [''],
      ProductionOutItem: this.fb.array([]),
      FactoryOutSalesOrder: this.fb.array([]),
      OperatingCostItem: this.fb.array([]),
    });
    this.factoryOutForm.get('OutDate').patchValue(new Date());
    this.showSelectedDate(this.factoryOutForm.value.OutDate);
  }
 public createItem(x: any): FormGroup {
    return this.fb.group({
      Id: 0,
      MaterialId: [x.Id],
      ItemCode: [x.ItemCode],
      ItemName: [x.ItemName],
      StockQty: [x.StockQuantity],
      UsedQty: [0, Validators.required],
      MaterialCost: [x.PurchasePrice],
      LineTotal: [0]
    });
  }
  public createOperatingCostItem(): FormGroup {
    return this.fb.group({
      Id: 0,
      Name: [''],
      Amount: [0],
      MovementId: [0]
    });
  }
  public addItem(x: any): void {
    this.ProductionOutItem = this.factoryOutForm.get('ProductionOutItem') as FormArray;
    this.ProductionOutItem.push(this.createItem(x));
  }
  public addCostItem(): void {
    this.OperatingCostItem = this.factoryOutForm.get('OperatingCostItem') as FormArray;
    this.OperatingCostItem.push(this.createOperatingCostItem());
  }
  public deleteCostItem(i: number){
    this.OperatingCostItem.removeAt(i);
    this.updateOperatingPrice();
  }
  public deleteItem(i: number){
    this.ProductionOutItem.removeAt(i);
  }
  public deleteSalesItem(i: number){
   this.FactoryOutSalesOrder.removeAt(i);
 }
 public addSalesOrderBlankItem(): void {
  this.FactoryOutSalesOrder = this.factoryOutForm.get('FactoryOutSalesOrder') as FormArray;
  this.FactoryOutSalesOrder.push(this.createSalesOrderBlankItem());
}
public createSalesOrderBlankItem(): FormGroup {
  return this.fb.group({
    Id: [0],
    MovementId: [0],
    OrderId:[0],
    BookedQuantity: [0]
  });
}
  public updateItemPrice(item: any, i: number){
    if(item.value.StockQty<item.value.UsedQty){
      item.controls['UsedQty'].patchValue(0);
      item.controls['LineTotal'].patchValue(0);
      this.toaster.error("Used Qty must be less than or equal to Stock Qty");
    }
    else
    {
      var total=item.value.MaterialCost* (isNaN(item.value.UsedQty)?0:item.value.UsedQty);
      item.controls['LineTotal'].patchValue(total);
    }
    this.totalMaterialCost=0;
    var itemForm = this.factoryOutForm.get('ProductionOutItem') as FormArray;
    itemForm.value.forEach(x => {
      this.totalMaterialCost+=x.LineTotal;
    });

    this.updateProductPrice();
  }


  public updateProductPrice(){
    var materialCostPerUnit = 0;
    var fValue = this.factoryOutForm.value;
    if (fValue.ProductOutQuantity > 0){
      materialCostPerUnit = Number(Number(this.totalMaterialCost / fValue.ProductOutQuantity).toFixed(2));
      this.factoryOutForm.controls['PerUnitMaterialCost'].patchValue(materialCostPerUnit.toFixed(2));
    }
    var prodCost = materialCostPerUnit + (isNaN(fValue.PerUnitOperatingCost) ? 0 : Number(fValue.PerUnitOperatingCost));
    this.factoryOutForm.controls['PerUnitProductionCost'].patchValue(prodCost);
  }
  public updateOperatingPrice(){
    this.OperatingCostItem = this.factoryOutForm.get('OperatingCostItem') as FormArray;
    let total = 0;
    const totalUnit = this.factoryOutForm.value.ProductOutQuantity > 0  ? this.factoryOutForm.value.ProductOutQuantity : 1;
    this.OperatingCostItem.value.forEach(element => {
      total += element.Amount;
    });
    this.factoryOutForm.get('PerUnitOperatingCost').patchValue((total / totalUnit).toFixed(2));
    this.updateProductPrice();
  }
  public approveFactoryOut()
  {
    this.ifSubmit = true;
    this.factoryOutForm.get('StatusId').patchValue(2);
    if (this.factoryOutForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    if(this.totalMaterialCost <= 0){
      this.toaster.warning('At least one item used quantity required!');
      return;
    }
    if(this.factoryOutForm.value.ProductOutQuantity <= 0){
      this.toaster.warning('Product out quantity is required!');
      return;
    }

    this.spinner.show();
    this.pService.ApproveProdOut(this.factoryOutForm.value).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Factory Out has been successfully saved.');
        this.router.navigate(['inventory/factory-out-list/All']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }


  public saveFactoryOut() {
    this.ifSubmit = true;
    this.factoryOutForm.get('StatusId').patchValue(1);
    if (this.factoryOutForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    if (this.totalMaterialCost <= 0){
      this.toaster.warning('At least one item used quantity required!');
      return;
    }
    if (this.factoryOutForm.value.ProductOutQuantity <= 0){
      this.toaster.warning('Product out quantity is required!');
      return;
    }

    this.spinner.show();
    this.pService.SaveOrUpdateProdOut(this.factoryOutForm.value).subscribe((x) => {
      if (x.Success) {
        this.toaster.success('Factory Out has been successfully saved.');
        this.router.navigate(['inventory/factory-out-list/All']);
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }

  showSelectedDate(e) {
    const value = this.factoryOutForm.value.OutDate;
    $("#fotest1").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }
  public checkSalesQuantity(item){
    var sum = 0
    this.salesQuantityValidation = false;
    if(this.factoryOutForm.value.ProductOutQuantity<item.value.BookedQuantity){
      item.controls['BookedQuantity'].patchValue(0);
      this.salesQuantityValidation = true;
      this.toaster.error("Booked Qty must be less than or equal to Factory Qty");
      return;
    }
    this.salesOrderCount.forEach(child => {
      sum=0;
      if(child.OrderId==item.value.OrderId){
        this.factoryOutForm.value.FactoryOutSalesOrder.forEach(x => {
          if(x.OrderId == child.OrderId)
          {
            sum+=Number(x.BookedQuantity);
          }
        });
        if(sum>child.Quantity)
        {
          item.controls['BookedQuantity'].patchValue(0);
          this.salesQuantityValidation = true;
          this.toaster.error("Booked Qty must be less than or equal to Sales Qty");
          return;
        }
      }
    });
  }
}
