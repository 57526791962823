import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ItemType } from 'src/app/enum/item-type';
import { WareHouseItems } from 'src/app/models/warehouseitems.model';
import { InventoryOverviewService } from 'src/app/service/InventoryOverview/inventory-overview.service';
import { ProductServiceService } from 'src/app/service/Products/product-service.service';

@Component({
  selector: 'app-sales-order-modal',
  templateUrl: './sales-order-modal.component.html',
  styleUrls: ['./sales-order-modal.component.css']
})
export class SalesOrderModalComponent implements OnInit {
  @Input() fromParent: any;
  @Input() SalesOrderItemsList: any;
  AllDaTa:any=[]
  receiveProduct:any;
  modalForShiftForm:FormGroup
  allData:any;
  wareHouseData:any;
  wareHouseItem:any
  ProductList:any=[]
  modalList:any=[]
  wareHouseItems:any
  totalStock:number = 0;
  totalDemand:number = 0;
  modalShowData:any[]=[];
  setDisable: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private deliveryOrderService:InventoryOverviewService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private productServiceService: ProductServiceService,
    ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.pageLoad();
    this.ModalData();
  }
  public pageLoad()
  {
    this.AllDaTa = this.fromParent.WareHouseData;
    let wareHouseAbleSalesOrderItem = this.AllDaTa.allDATA.SalesOrderItem.filter( x => x.ItemTypeId != ItemType.Service && x.ItemTypeId != null);
    for(let i = 0; i < wareHouseAbleSalesOrderItem.length; i++){
      let itemName = this.AllDaTa.wareHouseItems.find(x => x.Id == wareHouseAbleSalesOrderItem[i].ItemId).ItemName
      let quantity = wareHouseAbleSalesOrderItem[i].Quantity;
      let stockQuantity = this.AllDaTa.wareHouseItems.find(x => x.Id == wareHouseAbleSalesOrderItem[i].ItemId).StockQuantity;

      let modalShow = {
        itemName: itemName,
        itemStock: stockQuantity >= quantity ? quantity : stockQuantity,
        itemDemand: stockQuantity >= quantity ? 0 : (quantity - stockQuantity)
      }

      this.modalShowData.push(modalShow);
      this.totalStock += Number(modalShow.itemStock);
      this.totalDemand += Number(modalShow.itemDemand);
    }
  }


  public ModalData()
  {
    this.modalList=
    {
      productData:this.ProductList,
      formData:this.AllDaTa
    }
  }

  public initializeForm()
  {
    this.modalForShiftForm=new FormGroup({
      BtnOne: new FormControl('1')
    });
  }

  approve()
  {
    this.setDisable = true;
    let wareHouse=
    {
      ReferenceNo: this.AllDaTa.ReferenceNo,
      statusId:1,
      OperationType:3,
      ParentId:"",
      ContactId:this.AllDaTa.ContactId,
      OperationDate:this.AllDaTa.OperationDate
    }
    this.AllDaTa.WareHouseShippedType=this.modalForShiftForm.controls['BtnOne'].value;

    let WareHouseItemsList: WareHouseItems[] = [];
      this.SalesOrderItemsList.filter( x => x.ItemTypeId != ItemType.Service && x.ItemTypeId != null).forEach( item => {
        let wareHouseItems: WareHouseItems = new WareHouseItems();
        wareHouseItems.Id = 0;
        wareHouseItems.Demand = item.Quantity;
        wareHouseItems.ProductId = item.ItemId;
        wareHouseItems.ProductInOut = 0;
        wareHouseItems.WareHouseId = 0;
        WareHouseItemsList.push(wareHouseItems);
    });

    var data=
    {
      WareHouseData:wareHouse,
      WareHouseItemsData:WareHouseItemsList,
      WareHouseShippedType:this.AllDaTa.WareHouseShippedType
    };

    this.deliveryOrderService.ShipWareHouseData(data).subscribe((x)=>
    {
      if (x.Success) {
        this.activeModal.close('Success')
      }
      else{
        this.toaster.warning('Something Went Wrong!');
        this.activeModal.close('Error')
      }
    });
  }

}
