import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { NgxSmartModalService } from 'ngx-smart-modal';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';
@Component({
  selector: 'app-company-user-role-list',
  templateUrl: './company-user-role-list.component.html',
  styleUrls: ['./company-user-role-list.component.css']
})
export class CompanyUserRoleListComponent implements OnInit {
  allUserRole: any = [];
  searchText: string;
  gridApi;
  userRoleList = [
    {Id: 1, Name: 'Admin'},
    {Id: 2, Name: 'Accountant'},
    {Id: 3, Name: 'Auditor'}
  ];
  CompanyUserRoleForm: FormGroup;
  userList: any;
  constructor(
             private router: ActivatedRoute,
             public fb: FormBuilder,
             public ngxsmartmodal: NgxSmartModalService ,
             private toastr: ToastrService,
             private settingService: SettingsServiceService,
             private spinner: NgxSpinnerService) { }
  gridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1
    },
    columnDefs: [
      { headerName: 'User Name', field: 'UserName', width: 110 },
      { headerName: 'Role', field: 'RuleName', width: 90 },
      { headerName: 'Created At', field: 'CreatedTime', width: 110 },
      { headerName: 'Created By', field: 'CreatedByName', width: 110 },
      { headerName: 'Updated At', field: 'UpdatedTime', width: 90 },
      { headerName: 'Updated By', field: 'UpdatedByName', width: 110 }
  ],
  domLayout: 'autoHeight',
};
  onGridReady = (event) => {
    this.gridApi = event.api;
  }
  ngOnInit(): void {
    this.getAllCompanyUserRole();
    this.initUserRoleForm();
  }


  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  }

  getAllCompanyUserRole = () =>
  {
    this.spinner.show();

    this.settingService.GetAllCompanyUserRole().subscribe((x) => {
      this.allUserRole = x;
      this.spinner.hide();
    });
  }
  convetToPDF = () => {
    const data = document.getElementById('role');
    (html2canvas as any)(data).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('UserRoleList.pdf');
    });
  }
  initUserRoleForm = () => {
    this.CompanyUserRoleForm = this.fb.group({
      UserId: [],
      RoleId: ['', Validators.required],
      CompanyId: [0],
    });
  }
  openUserRolePopup = () => {
    this.getAllUserRuleList();
    this.ngxsmartmodal.create('AddRole', 'content').open();
  }
  getAllUserRuleList = () => {
    this.settingService.GetAllRuleWiseUserList().subscribe((x) =>
    {
      this.userList = x;
    });
  }
  saveComapnyWiseUserRole = () =>
  {
    console.log(this.CompanyUserRoleForm.value);
    this.spinner.show();
    this.settingService.SaveCompanyWiseUserRole(this.CompanyUserRoleForm.value).subscribe((x) =>
    {
      if (x.Success)
      {
        this.getAllCompanyUserRole();
        this.spinner.hide();
        this.ngxsmartmodal.getModal('AddRole').close();
        this.initUserRoleForm();
        this.toastr.success('User Wise Role created successfully.');
      }
      else
      {
        this.spinner.hide();
        this.toastr.warning('The same user role combination already exists.');
      }
    });
  }
}
