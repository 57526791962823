<app-dashboard>
  <app-page-header-title
    mainTitle="Files of {{ this.fileName }}"
    subTitle="Home"
    backURL="/"
    searchBox="{{ true }}"
  >
    <div class="full-width text-align-right pb-2">
      <input
        type="text"
        [(ngModel)]="imageSearch"
        placeholder="search"
        class="searchBox"
      />
    </div>
  </app-page-header-title>

  <div class="row" style="margin: 0px; padding: 20px 0px 0px 0px">
    <div
      *ngFor="let file of files | filter: imageSearch"
      id="demo{{ file.Id }}"
      (mouseover)="mouseOver(file.Id)"
      (mouseout)="mouseOut(file.Id)"
      class="col-3 mdivpadding"
    >
      <div
        id="hover{{ file.Id }}"
        class="hover{{ file.Id }} alls attachment-div"
      >
        <div id="imagesview{{ file.Id }}" class="row images imagediv">
          <div
            id="pdfPr{{ file.Id }}"
            *ngIf="isPdfFile(file)"
            class="pdfViewer"
          >
            <img
              src="../../../assets/main/images/Adobe-PDF-File-Icon-logo-vector-01.svg"
            />
            <p
              style="
                margin: 0 auto;
                width: 100%;
                text-align: center;
                font-size: 13px;
              "
            >
              {{ file.FileName }}
            </p>
          </div>
          <!-- <img class="col-md-12" width="30%" style="border:none; margin:0 auto;" src="https://image.flaticon.com/icons/png/512/179/179483.png" alt="..." class="img-thumbnail"> -->
          <img
            *ngIf="!isPdfFile(file)"
            class="col-md-12"
            width="30%"
            style="border: none; margin: 0 auto"
            src="{{ fileUrl }}{{ file.BlobName }}"
            alt="..."
            class="img-thumbnail"
          />
          <p
            style="
              margin: 0 auto;
              width: 100%;
              text-align: center;
              font-size: 13px;
            "
          >
            {{ file.FileName }}
          </p>
        </div>
        <div class="row" style="margin: 0px; padding: 20px">
          <p style="width: 100%; color: black; margin: 0px; font-weight: bold">
            <b>{{ file.ReferenceNo }}</b>
          </p>
          <table
            *ngIf="!changeOnInventory"
            style="width: 100%; color: #7f7f7f; font-size: 13px"
          >
            <tr>
              <td>{{ customerName }} Name :</td>
              <td>
                {{ file.ContactName }}
              </td>
            </tr>
            <tr>
              <td>Contact Number :</td>
              <td>
                {{ file.ContactNumber }}
              </td>
            </tr>
            <tr>
              <td>Created Date :</td>
              <td>
                {{ convertDateToDisplay(file.CreatedDate) }}
              </td>
            </tr>
            <tr>
              <td>Due Date :</td>
              <td>
                {{ convertDateToDisplay(file.DueDate) }}
              </td>
            </tr>
            <tr *ngIf="fileName != 'Purchase Order' && fileName != 'Quotation'">
              <td>Due Amount :</td>
              <td>
                {{ file.DueAmount }}
              </td>
            </tr>
          </table>
          <table
            *ngIf="changeOnInventory"
            style="width: 100%; color: #7f7f7f; font-size: 13px"
          >
            <tr>
              <td>{{ customerName }} Name : {{ file.ReferenceNo }}</td>
            </tr>
            <tr>
              <td>Item Type : {{ file.Type }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div
        id="options{{ file.Id }}"
        class="row hide fileoptions"
        style="margin: 0px"
      >
        <div style="text-align: center; width: 100%">
          <a class="option-icon-color" (click)="view(file)"
            ><i class="material-icons-outlined">insert_drive_file</i></a
          >
          <a
            href="{{ fileUrl }}{{ file.BlobName }}"
            download="{{ file.BlobName }}"
            class="option-icon-color"
            ><i class="material-icons">file_download</i></a
          >
          <a
            (click)="print(file, null, isPdfFile(file))"
            class="option-icon-color"
            ><i class="material-icons-outlined">print</i></a
          >
          <a
            (click)="share(file)"
            class="option-icon-color"
            data-toggle="modal"
            data-target="#shareModal"
            ><i class="material-icons-outlined">share</i></a
          >
          <a class="option-icon-color" (click)="modalViewFullHistory(file)"><fa-icon [icon]="faInfoCircle" class="seeHistoryIcon" ></fa-icon></a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="seeMoreOption" style="width: 100%; padding-right: 20px">
    <a class="a_link float-right" (click)="more()">see more...</a>
  </div>
  <ngx-smart-modal #AttachmentViewer identifier="AttachmentViewer">
    <div class="pb-4">
      <span class="modalHeaderText pl-2" class="attachment-header">{{
        selected?.FileName
      }}</span>
      <hr class="notShow" />
      <div class="row pr-2 pl-2">
        <div class="col-md-12 notShow">
          <div class="row">
            <div class="col-md-4">
              <table style="color: #7f7f7f; font-size: 13px">
                <tr>
                  <td>Customer Name :</td>
                  <td>
                    {{ selected?.ContactName }}
                  </td>
                </tr>
                <tr>
                  <td>Contact Number :</td>
                  <td>
                    {{ selected?.ContactNumber }}
                  </td>
                </tr>
                <tr>
                  <td>Created Date :</td>
                  <td>
                    {{ convertDateToDisplay(selected?.CreatedDate) }}
                  </td>
                </tr>
                <tr>
                  <td>Due Date :</td>
                  <td>
                    {{ convertDateToDisplay(selected?.DueDate) }}
                  </td>
                </tr>
                <tr
                  *ngIf="
                    fileName != 'Purchase Order' && fileName != 'Quotation'
                  "
                >
                  <td>Due Amount :</td>
                  <td>
                    {{ selected?.DueAmount }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-8" style="text-align: right">
              <a
                ><span class="option-icon-color" (click)="zoomIn()"
                  ><i class="material-icons borderradiusleft attachmenticon"
                    >zoom_in</i
                  ></span
                ></a
              >
              <a
                ><span class="option-icon-color" (click)="zoomOut()"
                  ><i class="material-icons attachmenticon">zoom_out</i></span
                ></a
              >
              <a [hidden]="isPdf"
                ><span class="option-icon-color" (click)="rotate('left')">
                  <i class="material-icons attachmenticon">rotate_left</i>
                </span></a
              >
              <a [hidden]="isPdf"
                ><span class="option-icon-color" (click)="rotate('right')">
                  <i class="material-icons attachmenticon">rotate_right</i>
                </span></a
              >
              <a
                href="{{ fileUrl }}{{ selected?.BlobName }}"
                download="{{ selected?.BlobName }}"
                class="option-icon-color"
                ><i class="material-icons attachmenticon">file_download</i></a
              >
              <a
                ><span
                  (click)="print(selected, 'contentToPrint', isPdf)"
                  class="option-icon-color"
                  ><i class="material-icons-outlined attachmenticon"
                    >print</i
                  ></span
                ></a
              >
              <a
                ><span
                  (click)="share(selected)"
                  class="option-icon-color"
                  data-toggle="modal"
                  data-target="#shareModal"
                  ><i
                    class="
                      material-icons-outlined
                      borderradiusright
                      border-right
                      attachmenticon
                    "
                    >share</i
                  ></span
                ></a
              >
            </div>
          </div>
        </div>
        <div id="contentToPrint" class="col-md-12">
          <div [hidden]="!isPdf">
            <!-- <iframe id="iframepdf" src={{src}}></iframe> -->
            <!-- <embed width="100%" height="1000px" src={{src}}/> -->
            <pdf-viewer
              [src]="src"
              [render-text]="true"
              [fit-to-page]="true"
              [zoom]="zoom"
              style="display: block"
            >
            </pdf-viewer>
          </div>
          <div [hidden]="isPdf">
            <div style="overflow: overlay">
              <img id="image" style="width: 100%" src="{{ src }}" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>

  <!-- Modal -->
  <div
    class="modal fade"
    id="shareModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="shareModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            {{ selected?.FileName }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row pr-2 pl-2">
            <div class="col-md-12">
              <div
                style="
                  width: 300px;
                  margin: 0 auto;
                  height: 200px;
                  overflow: hidden;
                "
                [hidden]="!isPdf"
              >
                <pdf-viewer
                  [src]="src"
                  [render-text]="true"
                  [fit-to-page]="true"
                  [zoom]="zoom"
                  style="display: block"
                >
                </pdf-viewer>
              </div>
              <div style="width: 300px; margin: 0 auto" [hidden]="isPdf">
                <div style="overflow: overlay">
                  <img id="image" style="width: 100%" src="{{ src }}" />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div style="width: 100%; margin: 0 auto; text-align: center">
              <input
                class="userSearch"
                type="text"
                placeholder="search"
                [(ngModel)]="searchText"
              />
            </div>

            <ul
              style="
                list-style: none;
                width: 100%;
                padding: 0 18px;
                margin: 0px;
              "
            >
              <li
                class="row userlist"
                *ngFor="let user of users | filter: searchText"
              >
                <span class="col-md-12"
                  >{{ user?.FirstName }}
                  <button
                    (click)="shareFile(user.Id)"
                    class="btn share-btn"
                    style="float: right"
                  >
                    share
                  </button></span
                >
              </li>
            </ul>
            <!-- <div  *ngFor="let user of contactList" ><p>{{user.PrimaryPersonFirstName}}</p><button>share</button></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</app-dashboard>
