import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvanceService } from '../../../service/Advance/advance.service';

@Component({
  selector: 'app-invoice-advance-adjustment',
  templateUrl: './invoice-advance-adjustment.component.html',
  styleUrls: ['./invoice-advance-adjustment.component.css']
})
export class InvoiceAdvanceAdjustmentComponent implements OnInit {
  public adjustAdvanceForm: FormGroup;
  @Input() fromParent: any;
 @Input() bankAccounts: any;
  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private advanceService: AdvanceService) { }

  ngOnInit(): void {
    console.log(this.fromParent);
    this.initializeForm();
  }

  initializeForm() {
    this.adjustAdvanceForm = this.fb.group({
      RefId: [this.fromParent.RefId],
      ContactId: [this.fromParent.ContactId],
      TotalAdvanceAmount: [this.fromParent.TotalAdvanceAmount],
      AdjustableAmount: [this.fromParent.AdjustableAmount],
      RemainingBalance: [this.fromParent.TotalAdvanceAmount],
      AdjustAmount: [this.fromParent.AdjustAmount, Validators.required],
      RemainingDueAmount: [this.fromParent.RemainingDueAmount],
      Amount: [],
      CreatedAt : [],
      IsNewAdvance: [false],
      AccountId: [null]
    });
  }
  initializeFormForNewAdvance() {
    this.adjustAdvanceForm = this.fb.group({
      RefId: [this.fromParent.RefId],
      ContactId: [this.fromParent.ContactId],
      TotalAdvanceAmount: [this.fromParent.TotalAdvanceAmount],
      AdjustableAmount: [this.fromParent.AdjustableAmount],
      RemainingBalance: [this.fromParent.TotalAdvanceAmount],
      AdjustAmount: [this.fromParent.AdjustAmount, Validators.required],
      RemainingDueAmount: [],
      Amount: [null],
      CreatedAt : [new Date()],
      IsNewAdvance: [true],
      AccountId: [null]
    });
    this.adjustAdvanceForm.get('CreatedAt').patchValue(this.formatDate(new Date()));
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  /**
   * CalculateDue
   */
  public CalculateDue() {
    var fValue=this.adjustAdvanceForm.value;
    console.log(fValue.AdjustableAmount);
    if(fValue.AdjustAmount> fValue.TotalAdvanceAmount){
      this.toaster.warning('Invalid adjustment amount!');
      this.adjustAdvanceForm.controls['AdjustAmount'].patchValue(0);
      this.adjustAdvanceForm.controls['RemainingBalance'].patchValue(fValue.TotalAdvanceAmount);
      return;
    }
    if(fValue.AdjustAmount> fValue.AdjustableAmount){
      this.toaster.warning('Invalid adjustment amount!');
      this.adjustAdvanceForm.controls['AdjustAmount'].patchValue(0);
      this.adjustAdvanceForm.controls['RemainingBalance'].patchValue(fValue.TotalAdvanceAmount);
      return;
    }

    this.adjustAdvanceForm.controls['RemainingDueAmount'].patchValue((fValue.AdjustableAmount - fValue.AdjustAmount).toFixed(2));
    this.adjustAdvanceForm.controls['RemainingBalance'].patchValue((fValue.TotalAdvanceAmount - fValue.AdjustAmount).toFixed(2));
  }
  public save() {
    if ((this.adjustAdvanceForm.invalid) || ((this.adjustAdvanceForm.value.IsNewAdvance == true) &&
         ((this.adjustAdvanceForm.value.AccountId == null) ||
          (this.adjustAdvanceForm.value.Amount <= 0 || this.adjustAdvanceForm.value.Amount == null)))) {
      this.toaster.warning('Please fill all the required field !');
      return;
    }
    if (this.adjustAdvanceForm.value.AdjustAmount <= 0){
      this.toaster.warning('Invalid Adjustment Amount !');
      return;
    }
    this.spinner.show();
    if(this.adjustAdvanceForm.value.IsNewAdvance == false){
    this.advanceService.AdvanceAndInvoiceAdjustment(this.adjustAdvanceForm.value).subscribe((x) => {
      if (x.Success){
        this.toaster.success('Adjustment has been successfully updated.');
        this.activeModal.close(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }
  else
  {
    this.advanceService.AdvanceAndInvoiceAdjustment(this.adjustAdvanceForm.value).subscribe((x) => {
      if (x.Success){
        this.toaster.success('Adjustment has been successfully updated.');
        this.activeModal.close(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }
  }

    /**
   * skipAdjustment
   */
     public skipAdjustment() {
      var obj={Success:false};
      this.activeModal.close(obj);
    }
    numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }

    advanceDeciderByChange = () =>
    {
      if (this.adjustAdvanceForm.value.IsNewAdvance)
      {
        this.initializeForm();
      }
      else
      {
        this.initializeFormForNewAdvance();
      }
    }
    getTheNewAdvanceAmount = () => {
      const amount = Number(this.adjustAdvanceForm.value.Amount);
      const value =   amount  > 0 ? amount + this.fromParent.TotalAdvanceAmount : this.fromParent.TotalAdvanceAmount;
      this.adjustAdvanceForm.get('TotalAdvanceAmount').patchValue(value);
      this.adjustAdvanceForm.get('RemainingBalance').patchValue(value);
      this.CalculateDue();


    }
}
