<app-dashboard>
  <app-page-header-title
    mainTitle="All Transfer Money List"
    subTitle="Home"
    backURL="/"
  >
    <div class="full-width text-align-right">
      <button type="button" (click)="gotoUrl()" class="eCount-def-btn ml-4">
        Transfer Money
      </button>
      <button type="button" class="eCount-button3 ml-4" (click)="convetToPDF()">
        Export
      </button>
    </div>
  </app-page-header-title>

  <div class="headSection" id="transfer">
    <div class="row">
      <ag-grid-angular
        style="font-size: 14px"
        class="ag-theme-balham col"
        [rowData]="TransferMoneyList"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        (rowClicked)="onRowClicked($event)"
        (gridReady)="onGridReady($event)"
        [defaultColDef]="defaultColDef"
      ></ag-grid-angular>
    </div>
  </div>
</app-dashboard>
