<app-dashboard>
    <app-page-header-title mainTitle="VAT/Tax Setup" subTitle="Back" backURL="/settings">
    </app-page-header-title>
    <div class="row" style="margin-bottom: 20px;">
            <button type="button" (click)="CreateNewTaxRate()" class="eCount-def-btn p-save-button">
                New VAT/Tax Rate</button>
   
    </div>
    <div class="row">
        <div class="w-100 col">
            <table class="table  f-ss-14px">
                <thead>
                    <tr class="tableHeader">
                        <th scope="col" class="table-header-color text-align-left">Name
                        </th>
                        <th scope="col" class="table-header-color text-align-center">VAT/Tax Rate(%)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataList">
                        <td class="text-align-left">
                            <span class="item-link" (click)="editTaxRate(item)">{{item.Name}}</span>
                        </td>
                        <td class="text-align-center">{{item.RatePercent}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-dashboard>

