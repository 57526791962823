import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { SettingsServiceService } from "src/app/service/Settings/settings-service.service";

@Component({
  selector: "app-approval-settings",
  templateUrl: "./approval-settings.component.html",
  styleUrls: ["./approval-settings.component.css"],
})
export class ApprovalSettingsComponent implements OnInit {
  billSettings: boolean = true;
  invoiceSettings: boolean = false;
  documentSettings: boolean = false;
  grantLoanSettings: boolean = false;
  level: number = 0;
  invoiceLevel: number = 0;
  index: number = 0;
  invoiceIndex: number = 0;
  approvalMenuId: number = 0;
  approverRoleList: any = [];
  ApprovalData: any = {};
  userList: any = [];
  approverEmailList: any = [];
  EmailList: any = [];
  levelName: string = "";
  invoiceLevelName: string = "";
  approvalForm: FormGroup;
  invoiceApprovalForm: FormGroup;
  documentApprovalForm: FormGroup;
  grantLoanApprovalForm: FormGroup;
  documentLevel: number = 0;
  documentIndex: number = 0;
  documentLevelName: string;
  grantLoanLevel: number = 0;
  grantLoanIndex: number = 0;
  grantLoanLevelName: string;
  constructor(
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    public fb: FormBuilder,
    private pService: SettingsServiceService
  ) { }

  ngOnInit(): void {
    this.getApprovalLevel();
    this.getInvoiceApprovalLevel();
    this.getDocumentApprovalLevel();
    this.getGrantLoanApprovalLevel();
    this.initializeForm();
    this.initializeInvoiceForm();
    this.initializeDocumentForm();
    this.initializeGrantLoanForm();
    this.getMasterDataList();
  }
  initializeForm() {
    this.approvalForm = this.fb.group({
      ApprovalLevel: this.fb.array([]),
    });
  }
  initializeInvoiceForm() {
    this.invoiceApprovalForm = this.fb.group({
      InvoiceApprovalLevel: this.fb.array([]),
    });
  }
  initializeDocumentForm() {
    this.documentApprovalForm = this.fb.group({
      DocumentApprovalLevel: this.fb.array([]),
    });
  }
  initializeGrantLoanForm() {
    this.grantLoanApprovalForm = this.fb.group({
      GrantLoanApprovalLevel: this.fb.array([]),
    });
  }
  getApprovalLevel = () => {
    this.spinner.show();
    this.pService.GetBillApprovalLevel().subscribe((x) => {
      this.spinner.hide();
      this.index = 0;
      this.level = 0;
      x.forEach((item) => {
        this.addLevel(item);
        this.level = this.level + 1;
        item.ApprovalLevelItem.forEach((level) => {
          this.addLevelItem(level, this.index);
        });
        this.index = this.index + 1;
      });
    });
  };

  getDocumentApprovalLevel = () => {
    this.spinner.show();
    this.pService.GetDocumentApprovalLevel().subscribe((x) => {
      this.spinner.hide();
      this.documentIndex = 0;
      this.documentLevel = 0;
      x.forEach((item) => {
        this.addDocumentLevel(item);
        this.documentLevel = this.documentLevel + 1;
        item.ApprovalLevelItem.forEach((level) => {
          this.addLevelDocumentItem(level, this.documentIndex);
        });
        this.documentIndex = this.documentIndex + 1;
      });
    });
  };
  getInvoiceApprovalLevel = () => {
    this.spinner.show();
    this.pService.GetInvoiceApprovalLevel().subscribe((x) => {
      this.spinner.hide();
      this.invoiceIndex = 0;
      this.invoiceLevel = 0;
      x.forEach((item) => {
        this.addInvoiceLevel(item);
        this.invoiceLevel = this.invoiceLevel + 1;
        item.ApprovalLevelItem.forEach((level) => {
          this.addLevelInvoiceItem(level, this.invoiceIndex);
        });
        this.invoiceIndex = this.invoiceIndex + 1;
      });
    });
  }
  getGrantLoanApprovalLevel = () => {
    this.spinner.show();
    this.pService.GetGrantLoanApprovalLevel().subscribe((x) => {
      this.spinner.hide();
      this.grantLoanIndex = 0;
      this.grantLoanLevel = 0;
      x.forEach((item) => {
        this.addGrantLoanLevel(item);
        this.grantLoanLevel = this.grantLoanLevel + 1;
        item.ApprovalLevelItem.forEach((level) => {
          this.addLevelGrantLoanItem(level, this.grantLoanIndex);
        });
        this.grantLoanIndex = this.grantLoanIndex + 1;
      });
    });
  }
  getMasterDataList = () => {
    this.pService.GetCompanyUsers().subscribe((x) => {
      this.EmailList = x;
      this.approverEmailList = x;
    });
    this.pService.GetCompanyRoles().subscribe((x) => {
      this.approverRoleList = x.filter(y => y.Name != 'PortalUser');
    });
    this.pService.GetAllUsers().subscribe((x) => {
      this.userList = x;
    });
  };
  ApprovalLevel(): FormArray {
    return this.approvalForm.get("ApprovalLevel") as FormArray;
  }
  DocumentApprovalLevel(): FormArray {
    return this.documentApprovalForm.get("DocumentApprovalLevel") as FormArray;
  }
  InvoiceApprovalLevel(): FormArray {
    return this.invoiceApprovalForm.get("InvoiceApprovalLevel") as FormArray;
  }
  GrantLoanApprovalLevel(): FormArray {
    return this.grantLoanApprovalForm.get("GrantLoanApprovalLevel") as FormArray;
  }
  newBlankLevel(): FormGroup {
    this.level += 1;
    this.levelName = "Level " + this.level;
    this.approvalMenuId = this.billSettings == true ? 1 : this.invoiceSettings == true ? 2 : 3;
    return this.fb.group({
      Id: 0,
      ApprovalMenuId: [this.approvalMenuId],
      CompanyId: [0],
      LevelId: [this.level],
      LevelName: [this.levelName],
      ApprovalLevelItem: this.fb.array([]),
    });
  }
  newBlankInvoiceLevel(): FormGroup {
    this.invoiceLevel += 1;
    this.invoiceLevelName = "Level " + this.invoiceLevel;
    this.approvalMenuId = this.billSettings == true ? 1 : this.invoiceSettings == true ? 2 : 3;
    return this.fb.group({
      Id: 0,
      ApprovalMenuId: [this.approvalMenuId],
      CompanyId: [0],
      LevelId: [this.invoiceLevel],
      LevelName: [this.invoiceLevelName],
      ApprovalLevelItem: this.fb.array([]),
    });
  }
  newBlankDocumentLevel(): FormGroup {
    this.documentLevel += 1;
    this.documentLevelName = "Level " + this.documentLevel;
    this.approvalMenuId = this.billSettings == true ? 1 : this.invoiceSettings == true ? 2 : 3;
    return this.fb.group({
      Id: 0,
      ApprovalMenuId: [this.approvalMenuId],
      CompanyId: [0],
      LevelId: [this.documentLevel],
      LevelName: [this.documentLevelName],
      ApprovalLevelItem: this.fb.array([]),
    });
  }
  newBlankGrantLoanLevel(): FormGroup {
    this.grantLoanLevel += 1;
    this.grantLoanLevelName = "Level " + this.grantLoanLevel;
    this.approvalMenuId = this.billSettings == true ? 1 : this.invoiceSettings == true ? 2 : this.documentSettings == true ? 3: 4;
    return this.fb.group({
      Id: 0,
      ApprovalMenuId: [this.approvalMenuId],
      CompanyId: [0],
      LevelId: [this.grantLoanLevel],
      LevelName: [this.grantLoanLevelName],
      ApprovalLevelItem: this.fb.array([]),
    });
  }
  newLevel(x: any): FormGroup {
    this.levelName = "Level " + x.LevelId;
    return this.fb.group({
      Id: [x.Id],
      ApprovalMenuId: [x.ApprovalMenuId],
      CompanyId: [x.CompanyId],
      LevelId: [x.LevelId],
      LevelName: [this.levelName],
      ApprovalLevelItem: this.fb.array([]),
    });
  }
  newInvoiceLevel(x: any): FormGroup {
    this.invoiceLevelName = "Level " + x.LevelId;
    return this.fb.group({
      Id: [x.Id],
      ApprovalMenuId: [x.ApprovalMenuId],
      CompanyId: [x.CompanyId],
      LevelId: [x.LevelId],
      LevelName: [this.invoiceLevelName],
      ApprovalLevelItem: this.fb.array([]),
    });
  }

  newDocumentLevel(x: any): FormGroup {
    this.documentLevelName = "Level " + x.LevelId;
    return this.fb.group({
      Id: [x.Id],
      ApprovalMenuId: [x.ApprovalMenuId],
      CompanyId: [x.CompanyId],
      LevelId: [x.LevelId],
      LevelName: [this.documentLevelName],
      ApprovalLevelItem: this.fb.array([]),
    });
  }

  newGrantLoanLevel(x: any): FormGroup {
    this.grantLoanLevelName = "Level " + x.LevelId;
    return this.fb.group({
      Id: [x.Id],
      ApprovalMenuId: [x.ApprovalMenuId],
      CompanyId: [x.CompanyId],
      LevelId: [x.LevelId],
      LevelName: [this.grantLoanLevelName],
      ApprovalLevelItem: this.fb.array([]),
    });
  }

  ChangeEmailByRole = (id, index, levelid) => {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == id.value.ApproverId);
    const p = this.ApprovalLevel().at(levelid).get('ApprovalLevelItem') as FormArray;
    p.at(index).get('ApproverEmailId').patchValue('');
  }
  ChangeEmailByInvoiceRole = (id, index, levelid) => {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == id.value.ApproverId);
    const p = this.InvoiceApprovalLevel().at(levelid).get('ApprovalLevelItem') as FormArray;
    p.at(index).get('ApproverEmailId').patchValue('');
  }

  ChangeEmailByDocumentRole = (id, index, levelid) => {;
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == id.value.ApproverId);
    const p = this.DocumentApprovalLevel().at(levelid).get('ApprovalLevelItem') as FormArray;
    p.at(index).get('ApproverEmailId').patchValue('');
  }

  ChangeEmailByGrantLoanRole = (id, index, levelid) => {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == id.value.ApproverId);
    const p = this.GrantLoanApprovalLevel().at(levelid).get('ApprovalLevelItem') as FormArray;
    p.at(index).get('ApproverEmailId').patchValue('');
  }

  addBlankLevel() {
    this.ApprovalLevel().push(this.newBlankLevel());
    this.addBlankLevelItem(this.level - 1);
  }
  addBlankInvoiceLevel() {
    this.InvoiceApprovalLevel().push(this.newBlankInvoiceLevel());
    this.addBlankLevelInvoiceItem(this.invoiceLevel - 1);
  }
  addBlankDocumentLevel() {
    this.DocumentApprovalLevel().push(this.newBlankDocumentLevel());
    this.addBlankLevelDocumentItem(this.documentLevel - 1);
  }
  addBlankGrantLoanLevel() {
    this.GrantLoanApprovalLevel().push(this.newBlankGrantLoanLevel());
    this.addBlankLevelGrantLoanItem(this.grantLoanLevel - 1);
  }
  addLevel(x: any) {
    this.ApprovalLevel().push(this.newLevel(x));
  }
  addInvoiceLevel(x: any) {
    this.InvoiceApprovalLevel().push(this.newInvoiceLevel(x));
  }
  addDocumentLevel(x: any) {
    this.DocumentApprovalLevel().push(this.newDocumentLevel(x));
  }
  addGrantLoanLevel(x: any) {
    this.GrantLoanApprovalLevel().push(this.newGrantLoanLevel(x));
  }
  removeLevel(levelIndex: number) {
    this.ApprovalLevel().removeAt(levelIndex);
  }
  removeInvoiceLevel(levelIndex: number) {
    this.InvoiceApprovalLevel().removeAt(levelIndex);
  }
  removeDocumentLevel(levelIndex: number) {
    this.DocumentApprovalLevel().removeAt(levelIndex);
  }
  removeGrantLoanLevel(levelIndex: number) {
    this.GrantLoanApprovalLevel().removeAt(levelIndex);
  }
  levelItems(levelIndex: number): FormArray {
    return this.ApprovalLevel().at(levelIndex).get("ApprovalLevelItem") as FormArray;
  }
  invoiceLevelItems(levelIndex: number): FormArray {
    return this.InvoiceApprovalLevel().at(levelIndex).get("ApprovalLevelItem") as FormArray;
  }
  documentLevelItems(levelIndex: number): FormArray {
    return this.DocumentApprovalLevel().at(levelIndex).get("ApprovalLevelItem") as FormArray;
  }
  grantLoanLevelItems(levelIndex: number): FormArray {
    return this.GrantLoanApprovalLevel().at(levelIndex).get("ApprovalLevelItem") as FormArray;
  }
  newBlankLevelItem(): FormGroup {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == 1);
    return this.fb.group({
      Id: 0,
      ApprovalLevelId: [0],
      ApproverId: [1, Validators.required],
      ApproverEmailId: ["", Validators.required],
      ModifiedById: [""],
      ModifiedAt: [null],
    });
  }
  newBlankLevelInvoiceItem(): FormGroup {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == 1);
    return this.fb.group({
      Id: 0,
      ApprovalLevelId: [0],
      ApproverId: [1, Validators.required],
      ApproverEmailId: ["", Validators.required],
      ModifiedById: [""],
      ModifiedAt: [null],
    });
  }

  newBlankLevelDocumentItem(): FormGroup {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == 1);
    return this.fb.group({
      Id: 0,
      ApprovalLevelId: [0],
      ApproverId: [1, Validators.required],
      ApproverEmailId: ["", Validators.required],
      ModifiedById: [""],
      ModifiedAt: [null],
    });
  }
  newBlankLevelGrantLoanItem(): FormGroup {
    this.approverEmailList = this.EmailList.filter(x => x.RoleId == 1);
    return this.fb.group({
      Id: 0,
      ApprovalLevelId: [0],
      ApproverId: [1, Validators.required],
      ApproverEmailId: ["", Validators.required],
      ModifiedById: [""],
      ModifiedAt: [null],
    });
  }
  newLevelItem(x: any): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      ApprovalLevelId: [x.ApprovalLevelId],
      ApproverId: [x.ApproverId],
      ApproverEmailId: [x.ApproverEmailId],
      ModifiedById: [x.ModifiedById],
      ModifiedAt: [this.formatDate(x.ModifiedAt)],
    });
  }
  newLevelInvoiceItem(x: any): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      ApprovalLevelId: [x.ApprovalLevelId],
      ApproverId: [x.ApproverId],
      ApproverEmailId: [x.ApproverEmailId],
      ModifiedById: [x.ModifiedById],
      ModifiedAt: [this.formatDate(x.ModifiedAt)],
    });
  }
  newLevelDocumentItem(x: any): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      ApprovalLevelId: [x.ApprovalLevelId],
      ApproverId: [x.ApproverId],
      ApproverEmailId: [x.ApproverEmailId],
      ModifiedById: [x.ModifiedById],
      ModifiedAt: [this.formatDate(x.ModifiedAt)],
    });
  }
  newLevelGrantLoanItem(x: any): FormGroup {
    return this.fb.group({
      Id: [x.Id],
      ApprovalLevelId: [x.ApprovalLevelId],
      ApproverId: [x.ApproverId],
      ApproverEmailId: [x.ApproverEmailId],
      ModifiedById: [x.ModifiedById],
      ModifiedAt: [this.formatDate(x.ModifiedAt)],
    });
  }
  addBlankLevelItem(levelIndex: number) {
    this.levelItems(levelIndex).push(this.newBlankLevelItem());
  }
  addBlankLevelInvoiceItem(levelIndex: number) {
    this.invoiceLevelItems(levelIndex).push(this.newBlankLevelInvoiceItem());
  }
  addBlankLevelDocumentItem(levelIndex: number) {
    this.documentLevelItems(levelIndex).push(this.newBlankLevelDocumentItem());
  }
  addBlankLevelGrantLoanItem(levelIndex: number) {
    this.grantLoanLevelItems(levelIndex).push(this.newBlankLevelGrantLoanItem());
  }
  addLevelItem(x: any, levelIndex: number) {
    this.levelItems(levelIndex).push(this.newLevelItem(x));
  }
  addLevelInvoiceItem(x: any, levelIndex: number) {
    this.invoiceLevelItems(levelIndex).push(this.newLevelInvoiceItem(x));
  }
  addLevelDocumentItem(x: any, levelIndex: number) {
    this.documentLevelItems(levelIndex).push(this.newLevelItem(x));
  }
  addLevelGrantLoanItem(x: any, levelIndex: number) {
    this.grantLoanLevelItems(levelIndex).push(this.newLevelGrantLoanItem(x));
  }
  isLastLevel(levelIndex: number) {
    if (levelIndex < this.level - 1 && this.levelItems(levelIndex).length == 1) {
      return true;
    }
  }
  removeLevelItem(levelIndex: number, itemIndex: number, item: any) {
    if (item.value.Id === 0) {
      if (this.isLastLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.levelItems(levelIndex).removeAt(itemIndex);
        if (itemIndex == 0 && this.levelItems(levelIndex).value.length == 0) {
          this.removeLevel(levelIndex);
          this.level -= 1;
        }
        this.toaster.success("Deleted successfully.");
      }
    } else {
      if (this.isLastLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.spinner.show();
        this.pService.DeleteApprovalLevelItem(item.value.Id).subscribe((x) => {
          if (x.Success) {
            this.toaster.success("Deleted successfully.");
            this.levelItems(levelIndex).removeAt(itemIndex);
            if (itemIndex == 0 && this.levelItems(levelIndex).value.length == 0) {
              this.removeLevel(levelIndex);
              this.level -= 1;
            }
            this.spinner.hide();
          } else {
            this.toaster.warning(x.Message);
            this.spinner.hide();
          }
        });
      }
    }
  }

  isLastInvoiceLevel(levelIndex: number) {
    if (levelIndex < this.invoiceLevel - 1 && this.invoiceLevelItems(levelIndex).length == 1) {
      return true;
    }
  }
  removeLevelInvoiceItem(levelIndex: number, itemIndex: number, item: any) {
    if (item.value.Id === 0) {
      if (this.isLastInvoiceLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.invoiceLevelItems(levelIndex).removeAt(itemIndex);
        if (itemIndex == 0 && this.invoiceLevelItems(levelIndex).value.length == 0) {
          this.removeInvoiceLevel(levelIndex);
          this.invoiceLevel -= 1;
        }
        this.toaster.success("Deleted successfully.");
      }
    } else {
      if (this.isLastInvoiceLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.spinner.show();
        this.pService.DeleteApprovalLevelItem(item.value.Id).subscribe((x) => {
          if (x.Success) {
            this.toaster.success("Deleted successfully.");
            this.invoiceLevelItems(levelIndex).removeAt(itemIndex);
            if (itemIndex == 0 && this.invoiceLevelItems(levelIndex).value.length == 0) {
              this.removeInvoiceLevel(levelIndex);
              this.invoiceLevel -= 1;
            }
            this.spinner.hide();
          } else {
            this.toaster.warning(x.Message);
            this.spinner.hide();
          }
        });
      }
    }
  }

  isLastDocumentLevel(levelIndex: number) {
    if (levelIndex < this.documentLevel - 1 && this.documentLevelItems(levelIndex).length == 1) {
      return true;
    }
  }
  removeLevelDocumentItem(levelIndex: number, itemIndex: number, item: any) {
    if (item.value.Id === 0) {
      if (this.isLastDocumentLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.documentLevelItems(levelIndex).removeAt(itemIndex);
        if (itemIndex == 0 && this.documentLevelItems(levelIndex).value.length == 0) {
          this.removeDocumentLevel(levelIndex);
          this.documentLevel -= 1;
        }
        this.toaster.success("Deleted successfully.");
      }
    } else {
      if (this.isLastDocumentLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.spinner.show();
        this.pService.DeleteApprovalLevelItem(item.value.Id).subscribe((x) => {
          if (x.Success) {
            this.toaster.success("Deleted successfully.");
            this.documentLevelItems(levelIndex).removeAt(itemIndex);
            if (itemIndex == 0 && this.documentLevelItems(levelIndex).value.length == 0) {
              this.removeDocumentLevel(levelIndex);
              this.documentLevel -= 1;
            }
            this.spinner.hide();
          } else {
            this.toaster.warning(x.Message);
            this.spinner.hide();
          }
        });
      }
    }
  }

  isLastGrantLoanLevel(levelIndex: number) {
    if (levelIndex < this.grantLoanLevel - 1 && this.grantLoanLevelItems(levelIndex).length == 1) {
      return true;
    }
  }
  removeLevelGrantLoanItem(levelIndex: number, itemIndex: number, item: any) {
    if (item.value.Id === 0) {
      if (this.isLastGrantLoanLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.grantLoanLevelItems(levelIndex).removeAt(itemIndex);
        if (itemIndex == 0 && this.grantLoanLevelItems(levelIndex).value.length == 0) {
          this.removeGrantLoanLevel(levelIndex);
          this.grantLoanLevel -= 1;
        }
        this.toaster.success("Deleted successfully.");
      }
    } else {
      if (this.isLastGrantLoanLevel(levelIndex)) {
        this.toaster.warning("You need to delete from last level.");
      }
      else {
        this.spinner.show();
        this.pService.DeleteApprovalLevelItem(item.value.Id).subscribe((x) => {
          if (x.Success) {
            this.toaster.success("Deleted successfully.");
            this.grantLoanLevelItems(levelIndex).removeAt(itemIndex);
            if (itemIndex == 0 && this.grantLoanLevelItems(levelIndex).value.length == 0) {
              this.removeGrantLoanLevel(levelIndex);
              this.grantLoanLevel -= 1;
            }
            this.spinner.hide();
          } else {
            this.toaster.warning(x.Message);
            this.spinner.hide();
          }
        });
      }
    }
  }
  toggleTemplate = (status: string) => {
    if (status == "bill") {
      this.billSettings = true;
      this.approverEmailList = this.EmailList;
      this.invoiceSettings = false;
      this.documentSettings = false;
      this.grantLoanSettings = false;
    } else if (status == "invoice") {
      this.billSettings = false;
      this.documentSettings = false;
      this.invoiceSettings = true;
      this.grantLoanSettings = false;
      this.approverEmailList = this.EmailList;
    }
    else if(status == "document") {
      this.documentSettings = true;
      this.billSettings = false;
      this.invoiceSettings = false;
      this.grantLoanSettings = false;
      this.approverEmailList = this.EmailList;
    }
    else if(status == "grant loan") {
      this.grantLoanSettings = true;
      this.documentSettings = false;
      this.billSettings = false;
      this.invoiceSettings = false;
      this.approverEmailList = this.EmailList;
    }
  }
  changeApproveIfRoleChange = (id, index, levelid) => {
    const p = this.ApprovalLevel().at(levelid).get("ApprovalLevelItem") as FormArray;
    const value = this.EmailList.filter(x => x.Id === p.value[index].ApproverEmailId);
    if (value.length > 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].RoleId == p.at(index).get('ApproverId')) {
          p.at(index).get('ApproverId').patchValue(value[i].RoleId); break;
        }
      }
    }
    else {
      p.at(index).get('ApproverId').patchValue(value[0].RoleId);
    }

  }
  changeApproveIfInvoiceRoleChange = (id, index, levelid) => {
    const p = this.InvoiceApprovalLevel().at(levelid).get("ApprovalLevelItem") as FormArray;
    const value = this.EmailList.filter(x => x.Id === p.value[index].ApproverEmailId);
    if (value.length > 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].RoleId == p.at(index).get('ApproverId')) {
          p.at(index).get('ApproverId').patchValue(value[i].RoleId); break;
        }
      }
    }
    else {
      p.at(index).get('ApproverId').patchValue(value[0].RoleId);
    }
  }
  changeApproveIfDocumentRoleChange = (id, index, levelid) => {
    const p = this.DocumentApprovalLevel().at(levelid).get("ApprovalLevelItem") as FormArray;
    const value = this.EmailList.filter(x => x.Id === p.value[index].ApproverEmailId);
    if (value.length > 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].RoleId == p.at(index).get('ApproverId')) {
          p.at(index).get('ApproverId').patchValue(value[i].RoleId); break;
        }
      }
    }
    else {
      p.at(index).get('ApproverId').patchValue(value[0].RoleId);
    }

  }

  changeApproveIfGrantLoanRoleChange = (id, index, levelid) => {
    const p = this.GrantLoanApprovalLevel().at(levelid).get("ApprovalLevelItem") as FormArray;
    const value = this.EmailList.filter(x => x.Id === p.value[index].ApproverEmailId);
    if (value.length > 1) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].RoleId == p.at(index).get('ApproverId')) {
          p.at(index).get('ApproverId').patchValue(value[i].RoleId); break;
        }
      }
    }
    else {
      p.at(index).get('ApproverId').patchValue(value[0].RoleId);
    }

  }
  save() {
    for (let i = 0; i < this.approvalForm.value.ApprovalLevel.length; i++) {
      for (let j = 0; j < this.approvalForm.value.ApprovalLevel[i].ApprovalLevelItem.length; j++) {
        if (this.approvalForm.value.ApprovalLevel[i].ApprovalLevelItem[j].ApproverEmailId == "") {
          this.toaster.error("Please provide all the information."); return;
        }
      }
    }
    this.spinner.show();
    this.pService.SaveApprovalLevel(this.approvalForm.value.ApprovalLevel).subscribe((x) => {
      if (x.Success) {
        this.approverEmailList = this.EmailList;
        this.billSettings = true;
        this.initializeForm();
        this.getApprovalLevel();
        this.toaster.success("Saved successfully.");
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    },
    (err) => {
      this.toaster.error("ooh, something went wrong !");
    });
  }


  saveInvoice() {
    if (this.isInvoiceFormValid()) {
      this.spinner.show();
      this.pService.SaveApprovalLevel(this.invoiceApprovalForm.value.InvoiceApprovalLevel).subscribe((x) => {
        if (x.Success) {
          this.approverEmailList = this.EmailList;
          this.invoiceSettings = true;
          this.initializeInvoiceForm();
          this.getInvoiceApprovalLevel();
          this.spinner.hide();
          this.toaster.success("Saved successfully.");
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      });
    } else {
      this.toaster.error("Please provide all the information.");
    }
  }
  saveDocument() {
    if (this.isDocumentFormValid()) {
      this.spinner.show();
      this.pService.SaveApprovalLevel(this.documentApprovalForm.value.DocumentApprovalLevel).subscribe((x) => {
        if (x.Success) {
          this.approverEmailList = this.EmailList;
          this.documentSettings = true;
          this.initializeDocumentForm();
          this.getDocumentApprovalLevel();
          this.toaster.success("Saved successfully.");
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      });
    } else {
      this.toaster.error("Please provide all the information.");
    }
  }

  saveGrantLoan() {
    if (this.isGrantLoanFormValid()) {
      this.spinner.show();
      this.pService.SaveApprovalLevel(this.grantLoanApprovalForm.value.GrantLoanApprovalLevel).subscribe((x) => {
        if (x.Success) {
          this.approverEmailList = this.EmailList;
          this.grantLoanSettings = true;
          this.initializeGrantLoanForm();
          this.getGrantLoanApprovalLevel();
          this.toaster.success("Saved successfully.");
        } else {
          this.toaster.error(x.Message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.toaster.error("ooh, something went wrong !");
      });
    } else {
      this.toaster.error("Please provide all the information.");
    }
  }

  private formatDate(date) {
    if (date == null) return "";
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  isDocumentFormValid() {
    var formData = this.documentApprovalForm.value.DocumentApprovalLevel;
    var len = formData.length;
    for (var i = 0; i < len; i++) {
      var app_len = formData[i].ApprovalLevelItem.length;
      var app_data = formData[i].ApprovalLevelItem;
      for (var j = 0; j < app_len; j++) {
        if (this.isNull(app_data[j].ApproverEmailId)) {
          return false;
        }
      }
    }
    return true;
  }

  isInvoiceFormValid() {
    var formData = this.invoiceApprovalForm.value.InvoiceApprovalLevel;
    var len = formData.length;
    for (var i = 0; i < len; i++) {
      var app_len = formData[i].ApprovalLevelItem.length;
      var app_data = formData[i].ApprovalLevelItem;
      for (var j = 0; j < app_len; j++) {
        if (this.isNull(app_data[j].ApproverEmailId)) {
          return false;
        }
      }
    }
    return true;
  }

  isGrantLoanFormValid() {
    var formData = this.grantLoanApprovalForm.value.GrantLoanApprovalLevel;
    var len = formData.length;
    for (var i = 0; i < len; i++) {
      var app_len = formData[i].ApprovalLevelItem.length;
      var app_data = formData[i].ApprovalLevelItem;
      for (var j = 0; j < app_len; j++) {
        if (this.isNull(app_data[j].ApproverEmailId)) {
          return false;
        }
      }
    }
    return true;
  }

  isNull(data) {
    if (data == '' || data == null || data == undefined) {
      return true;
    } else {
      return false;
    }
  }

}
