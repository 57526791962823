import { Component, OnInit ,Input} from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup,FormArray } from '@angular/forms';
import { FactoryProductionService } from '../../../service/FactoryProduction/factory-production.service';

@Component({
  selector: 'app-factory-inventory-popup',
  templateUrl: './factory-inventory-popup.component.html',
  styleUrls: ['./factory-inventory-popup.component.css']
})
export class FactoryInventoryPopupComponent implements OnInit {

  @Input() fromParent: any;
  public factoryReturnForm: FormGroup;
  public RawMaterialItem: FormArray;
  ReturnNote:string;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private pService: FactoryProductionService
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm() {
    this.factoryReturnForm = this.fb.group({
      ReturnNote:[''],
      RawMaterialItem: this.fb.array([])
    });
    this.fromParent.forEach(item => {
      this.addItem(item);
    });
  }
  public addItem(x:any): void {
    this.RawMaterialItem = this.factoryReturnForm.get('RawMaterialItem') as FormArray;
    this.RawMaterialItem.push(this.createItem(x));
  }

  public createItem(x:any): FormGroup {
    return this.fb.group({
      MaterialId: [x.MaterialId],
      ItemCode: [x.ItemCode],
      ItemName: [x.ItemName],
      FactoryQuantity: [x.InQuantity],
      PurchasePrice: [x.PurchasePrice],
      ReturnQty: [0]
    });
  }
  public saveReturnMaterial() {
    if (this.ReturnNote==null || this.ReturnNote==''){
      this.toaster.warning('Return Note is required!');
      return;
    }
    this.spinner.show();
    this.pService.UpdateRawMaterialQty(this.factoryReturnForm.value.RawMaterialItem,this.ReturnNote).subscribe((x)=>{
      if (x.Success) {
        this.toaster.success('Raw material successfully returned.');
        this.router.navigate(['inventory/factory-stock-report']);
        this.activeModal.close();
      } else {
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err => {
        this.toaster.error('ooh, something went wrong !');
    });
  }
  public checkQuantity(item){
    if(item.value.FactoryQuantity<item.value.ReturnQty){
      item.controls['ReturnQty'].patchValue(0);
      this.toaster.error("Return Qty must be less than or equal to Factory Qty");
    }
  }
}
