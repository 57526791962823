
<app-dashboard>
    <app-page-header-title
        mainTitle="{{'SIDEBAR.fixedAsset'|translate}}"
        subTitle="Home"
        backURL="/">
        <!-- <button type="button" (click)="AddAccount()" class="btn eCount-button ml-4">{{'Accounting.chartOfAccounts.addAccounts' | translate}}</button>
        <button type="button" (click)="AddAccount()" class="btn eCount-button ml-4">{{'Accounting.chartOfAccounts.addBankAccount' | translate}}</button> -->
    </app-page-header-title>
    <div class="headSection">
        <div class="row">
            <!-- <ag-grid-angular
                style="width: 100wh; height: 82vh;"
                class="ag-theme-balham col"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                >
            </ag-grid-angular> -->
        </div>
    </div>
</app-dashboard>