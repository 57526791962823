<app-dashboard>
  <app-page-header-title
    mainTitle="Invoice Overview"
    subTitle="Home"
    backURL="/"
  >
    <div class="full-width text-align-right">
      <input
        type="text"
        [(ngModel)]="searchText"
        (ngModelChange)="search()"
        placeholder="search"
        class="searchBoxOfInvoiceOverview"
      />
      <button 
        type="button" 
        *ngIf="status == 'draft' && filteredInvoice?.length != 0" 
        (click)="getSelectedInvoice()" 
        class="eCount-def-btn ml-4"> Submit
      </button>
      <button 
        type="button" 
        *ngIf="status == 'Awaiting-approval' && filteredInvoice?.length != 0" 
        (click)="getSelectedInvoice()" 
        class="eCount-def-btn ml-4"> Approve
      </button>
      <button
        type="button"
        routerLink="/income/create-invoice"
        class="eCount-def-btn ml-4"
      >
        Create New Invoice
      </button>
      <button type="button" class="eCount-button3 ml-4" (click)="convetToPDF()">
        Export
      </button>
    </div>
  </app-page-header-title>
  <div id="invoOverview">
    <ul class="nav nav-tabs justify-content-center">
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('All')"
          [routerLinkActive]="['active']"
          routerLink="/income/invoice-overview/All"
          >All
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('draft')"
          [routerLinkActive]="['active']"
          routerLink="/income/invoice-overview/Draft"
          >Darft ({{ draftCount }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Awaiting-approval')"
          [routerLinkActive]="['active']"
          routerLink="/income/invoice-overview/awaiting-approval"
          >Awaiting Approval ({{ submitCount }})</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Awaiting-payment')"
          [routerLinkActive]="['active']"
          routerLink="/income/invoice-overview/Awaiting-payment"
          >Awaiting Payment ({{ awaitingCount }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('rejected')"
          [routerLinkActive]="['active']"
          routerLink="/income/invoice-overview/rejected"
          >Rejected ({{rejectCount }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Overdue-payment')"
          [routerLinkActive]="['active']"
          routerLink="/income/invoice-overview/Overdue-payment"
          >Overdue ({{overdueCount }})</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link f-ssb-14px nav-option"
          (click)="reLoadRowData('Paid')"
          [routerLinkActive]="['active']"
          routerLink="/income/invoice-overview/Paid"
          >Paid ({{ paidCount }})</a
        >
      </li>
    </ul>

    <div class="row pt-3" *ngIf="!isShowingCheckbox()">
      <ag-grid-angular
        style="font-size: 14px"
        class="ag-theme-balham col"
        [rowData]="filteredInvoice"
        [columnDefs]="columnDefs"
        [domLayout]="domLayout"
        [defaultColDef]="defaultColDef"
        [pagination]="true"
        [paginationPageSize]="50"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>

    <div class="row pt-3" *ngIf="isShowingCheckbox()">
      <ag-grid-angular
        style="font-size: 14px"
        class="ag-theme-balham col"
        [rowData]="filteredInvoice"
        [columnDefs]="columnDefsForDraft"
        [domLayout]="domLayout"
        [defaultColDef]="defaultColDef"
        [rowSelection]="rowSelection"
        [suppressRowClickSelection]="true"
        [pagination]="true"
        [paginationPageSize]="50"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>

    <div class="row d-flex grid ml-0 mr-0">
      <div class="col-sm-5">Total</div>
      <div class="col-sm-1 ml-3 pl-5">{{ totalInvoiceAmount | currency:'':'' }}</div>
      <div class="col-sm-1 ml-5 pl-3">{{ totalDiscountAmount | currency:'':'' }}</div>
      <div class="col-sm-1 ml-2">{{ totalDueAmount | currency:'':'' }}</div>
    </div>
    
  </div>
</app-dashboard>
