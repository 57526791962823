import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { NgxSmartModalService } from "ngx-smart-modal";
import { NgxSpinnerService } from "ngx-spinner";
import { CreateItem } from "src/app/models/product.model";
import { DataService } from "src/app/service/EventEmitter/data.service";
import { InventoryOverviewService } from "src/app/service/InventoryOverview/inventory-overview.service";
import { ProductServiceService } from "src/app/service/Products/product-service.service";
import { SalesOrderService } from "src/app/service/SalesOrder/sales-order.service";

@Component({
  selector: "app-delivery-order",
  templateUrl: "./delivery-order.component.html",
  styleUrls: ["./delivery-order.component.css"],
})
export class DeliveryOrderComponent implements OnInit {
  itemListTitle: string = "Inventory Overview/Delivery Orders";
  public aModel: CreateItem = new CreateItem();
  itemTypeId: number = 1;
  allData: any = [];
  deliveryStatus: any = {
    Waiting: 1,
    Shipped: 2,
    Ready: 3,
  };
  @Input() fromParent: any;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private dataService: DataService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private productServiceService: ProductServiceService,
    private activeroute: ActivatedRoute,
    private deliveryOrderService: InventoryOverviewService,
    private salesOrderService: SalesOrderService
  ) {}
  planId = 0;
  OrderList: any = [];
  grilcol;
  private gridApi;
  domLayout = "autoHeight";
  isItemListUpdate = false;
  searchText: string;
  pageValue: number;
  isBackOrder: boolean = false;

  ngOnInit(): void {
    this.searchText = this.route.snapshot.paramMap.get("id");
    if(this.searchText == "BackOrder"){
      this.searchText = "All";
      this.isBackOrder = true;
    }
    this.pageLoad(this.searchText);
  }

  public pageLoad(status: string) {
    this.deliveryOrderService.GetDeliveryOrderList().subscribe((x) => {
      this.allData = x;
      this.allData = this.allData.filter((x) => x.OperationType == 3 || x.OperationType == 4);
      this.filterData(status);
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].SerialNo = i + 1;
      }
      for (let i = 0; i < this.allData.length; i++) {
        this.allData[i].WHNO = "WH-Out-" + this.allData[i].WareHouseProductNo;
        this.allData[i].ReferenceNo = this.getReferenceNo(i);
      }
    });
  }

  filterData(status: string){
    if(!this.isBackOrder){
      switch (status) {
        case "All":
          this.allData = this.allData.filter((x) => x.OperationType == 3 || x.OperationType == 4);
          break;
        case "Waiting":
          this.allData = this.allData.filter((x) => x.StatusId == 1 && (x.ParentId == null || x.ParentId == ''));
          break;
        case "Ready":
          this.allData = this.allData.filter((x) => x.StatusId == 3);
          break;
        case "Shipped":
          this.allData = this.allData.filter((x) => x.StatusId == 2);
          break;
      }
    }
    else{
      switch (status) {
        case "All":
          this.allData = this.allData.filter((x) => x.ParentId != null && x.StatusId == 1 && (x.OperationType == 3 || x.OperationType == 4));
          break;
        case "Waiting":
          this.allData = this.allData.filter((x) => x.ParentId != null && x.StatusId == 1);
          break;
        case "Ready":
          this.allData = this.allData.filter((x) => x.ParentId != null && x.StatusId == 3);
          break;
        case "Shipped":
          this.allData = this.allData.filter((x) => x.ParentId != null && x.StatusId == 2);
          break;
        
      }
    }
  }

  public getReferenceNo(index: number)
  {
    switch(this.allData[index].OperationType)
    {
      case 1:
        return "PO-"+this.allData[index].ReferenceNo;
      case 2:
        return "Bill-"+this.allData[index].ReferenceNo;
      case 3:
        return "SO-"+this.allData[index].ReferenceNo;
      case 4:
        return "INV-"+this.allData[index].ReferenceNo;
    }
  }

  search = () => {
    this.gridApi.setQuickFilter(this.searchText);
  };
  onBtnExport() {
    var params = this.getParams();
    this.gridApi.exportDataAsCsv(params);
  }
  getParams() {
    return {
      suppressQuotes: "",
      columnSeparator: "",
      customHeader: "",
      customFooter: "",
    };
  }

  columnDefs = [
    {
      headerName: "Serial No.",
      field: "SerialNo",
      cellStyle: { color: "blue", cursor: "pointer" },
    },
    {
      headerName: "WH No.",
      field: "WHNO",
      cellStyle: { color: "blue", cursor: "pointer" },

      cellRenderer: function (params) {
        console.log("Param", params);
        return `<a href="#/inventory/delivery-order-waiting/${params.data.Id}" >${params.data.WHNO}</a>`;
      },
    },
    { headerName: "To", field: "Contacts.CompanyName" },
    {
      headerName: "Date",
      field: "OperationDate",
      valueFormatter: this.convertGridDateIntoDisplay,
    },
    {
      headerName: "Reference",
      field: "ReferenceNo",
      cellStyle: { color: "blue", cursor: "pointer" },
      cellRenderer: (params) => {
        return this.gotoReference(params.data);
      }
      /*cellRenderer: function (params) {
        //The reference no has been modied in the allData array. in the pageLoad function.
        if(params.data.OperationType == 3)
          return this.getSalesOrderDetails(params.data);
          //return `<a href="#/sales/sales-order-details/${params.data.Id}" >${params.data.ReferenceNo}</a>`;
        else
          return `<a href="#/income/invoice/${params.data.ReferenceNo}" >${params.data.ReferenceNo}</a>`; 
      },*/
    },
    {
      headerName: "Status",
      field: "Status",
      valueFormatter: this.setOrderStatus,
    },
  ];

  defaultColDef = {
    sortable: true,
    filter: true,
    flex: 1,
  };

  gotoReference(data) {
    if(data.OperationType != 3) {
      return `<a href="#/income/invoice/${data.ReferenceNo}" >${data.ReferenceNo}</a>`;
    }
    else {
      //let salesData = this.getSalesOrderDetails(data.ReferenceNo.substring(3));
      return `<a href="#/sales/sales-order-details/${data.SalesOrderData.Id}" >${data.ReferenceNo}</a>`;
    }
  }

  getSalesOrderDetails(orderNo) {
    let salesOrder;
    this.salesOrderService.GetSalesOrderBySalesOrderNo(orderNo).subscribe((x) => {
      salesOrder = x;
    });
  }

  convertGridDateIntoDisplay(date) {
    console.log("Date", date);
    const dateString = date.value
      ? moment(date.value).format("Do MMM YYYY")
      : "";
    return dateString;
  }
  setOrderStatus(id) {
    console.log("Id=>", id);
    switch (id.data.StatusId) {
      case 1:
        return "Waiting";
      case 2:
        return "Shipped";
      case 3:
        return "Ready";
      default:
        return "unknown";
    }
  }

  //   rowData = [
  //     { SerialNo: '1', WHNO: 'WH-OUT-20202', To: 'Raddish', Date:'2022-12-12',Reference:'SO-1110245',Status:'Waiting' },

  // ];
}
