import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AssigneeService } from 'src/app/service/Assignee/assignee.service';
import { FixedAssetService } from 'src/app/service/FixedAsset/fixedasset.service';

@Component({
  selector: 'app-fixed-asset-rollback-depreciation-popup',
  templateUrl: './fixed-asset-rollback-depreciation-popup.component.html',
  styleUrls: ['./fixed-asset-rollback-depreciation-popup.component.css']
})
export class FixedAssetRollbackDepreciationPopupComponent implements OnInit {

  RollBackDepreciationForm: FormGroup;
  toDateList:any=[];

  constructor(
    public fb: FormBuilder,
    public fixedAssetService: FixedAssetService,
    public assigneeService: AssigneeService,
    public activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.InitializeForm();
    this.GetDepreciationDate();
  }
  
  InitializeForm() {
    this.RollBackDepreciationForm = this.fb.group({
      toDate: ['', Validators.required],
    });
  }

  GetDepreciationDate() {
    this.fixedAssetService.GetRollBackDepreciationDate().subscribe((x) => {
      if(x) {
        this.toDateList = x.toDateList;
      }
    });
  }

  SaveDepreciation() {
    if(!this.RollBackDepreciationForm.valid){
      this.toaster.warning('Please fill all the required field!');
      return;
    }
    this.spinner.show();
    this.fixedAssetService.ReverseDepreciationAll(this.RollBackDepreciationForm.value.toDate).subscribe((x) => {
      if(x) {
        this.toaster.success('Depreciation successfully reversed!');
        this.router.navigate(['fixed-asset/setup/registered']);
        this.spinner.hide();
        this.activeModal.close();
      }
    });
  }

}
