import { Component, Input, OnInit, HostListener } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { RouterLinkRendereComponent } from "src/app/components/router-link-rendere/router-link-rendere.component";
import { ManufactureService } from "src/app/service/Manufacture/manufacture.service";
import { SalesOrderService } from "src/app/service/SalesOrder/sales-order.service";

@Component({
  selector: "app-sales-order-booked-stock-popup",
  templateUrl: "./sales-order-booked-stock-popup.component.html",
  styleUrls: ["./sales-order-booked-stock-popup.component.css"],
})
export class SalesOrderBookedStockPopupComponent implements OnInit {
  gridApi;
  rowData: any = [];
  singelSaleData: any;
  @Input() salesOrderData: any;
  productData: any;

  salesOrderList: any = [];
  manufactureOrderList: any = [];
  combinedList: any = [];



  constructor(
    public activeModal: NgbActiveModal,
    private salesOrderService: SalesOrderService,
    private manufactureService: ManufactureService
  ) {}

  ngOnInit(): void {
    this.productData = this.salesOrderData;
    this.getSalesOrderByItemId();
    this.getManufactureOrderByItemId();
  }

  @HostListener('click')
  onClick() {
    this.activeModal.close();
  }

  getSalesOrderByItemId() {
    this.salesOrderService
      .GetSalesOrderWithWareHouseItemsByItemId(this.productData.Id)
      .subscribe((p) => {
        this.salesOrderList = p;
        for (var i = 0; i < this.salesOrderList.length; i++) {
          this.salesOrderList[i].orderNo = "SO-" + this.salesOrderList[i].orderNo;
        }
        this.combineLists();
      });
  }

  getManufactureOrderByItemId() {
    this.manufactureService.getManufactureOrderByItemId(this.productData.Id).subscribe((p) => {
        this.manufactureOrderList = p;
        for (var i = 0; i < this.manufactureOrderList.length; i++) {
          this.manufactureOrderList[i].orderNo = "MO-" + this.manufactureOrderList[i].orderNo;
          this.manufactureOrderList[i].companyName = '-';
          this.manufactureOrderList[i].deliveryDate = '-';
        }
        this.combineLists();
      });
  }

  combineLists() {
      this.combinedList = [...this.salesOrderList, ...this.manufactureOrderList];
      for (let i = 0; i < this.combinedList.length; i++) {
        this.combinedList[i].SerialNo = i + 1;
      }
      if(this.combinedList.length != 0) {
          this.combinedList.push(null);
      }
  }

  gridOptions = {
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
    },
    columnDefs: [
      { headerName: "Serial No.", field: "SerialNo" },
      {
        headerName: "Ref",
        field: "orderNo",
        valueFormatter: this.valueFormatter,
        cellRenderer: function (params) {
          if(params.value != undefined) {
            if(params?.value?.substring(0, 2) == 'MO'){
              return `<a href="#/manufacture/manufacutring-order-details/${params?.data?.orderId}" >${params?.value}</a>`;
            }
            else{
              return `<a href="#/sales/sales-order-details/${params?.data?.orderId}" >${params?.value}</a>`;
            }
          }
        },
      },
      { headerName: "To", field: "companyName" },
      { headerName: "Booked Stock", field: "BookedStock" },
      {
        headerName: "Delivery Date",
        field: "deliveryDate",
        valueFormatter: this.convertDateIntoDisplay,
      },
    ],
    rowSelection: "multiple",
    domLayout: "autoHeight",
  };

  valueFormatter(data) {
    if(data.vlue != undefined){
      return data.value;
    }
    else if(data.vlue == undefined){
      return '';
    }
  }

  closeModal() {
    this.activeModal.close("Success");
  }

  onGridReady = (event) => {
    this.gridApi = event.api;
  };

  convertDateIntoDisplay(date) {
    if(date.value == '-'){
      return date.value;
    }
    else if(date.value == undefined) {
      return '';
    }
    else{
      let dateString = moment(date.value).format("Do MMM YYYY");
      return dateString;
    }
  }
}
