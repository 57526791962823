<app-dashboard>
    <app-page-header-title
      mainTitle="INV-{{invoiceNo}}/Delivery Orders"
      subTitle="Back"
      backURL="income/invoice/INV-{{invoiceNo}}"
    >
      <div class="full-width text-align-right">
        <input
          type="text"
          [(ngModel)]="searchText"
          (ngModelChange)="search()"
          placeholder="search"
          class="searchBox"
        />
        <button type="button" class="eCount-button3 ml-4" (click)="onBtnExport()">
          {{ "Contacts.contactList.export" | translate }}
        </button>
      </div>
    </app-page-header-title>
  
      <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('All')"
            [routerLinkActive]="['active']"
            [routerLink]="['/income/invoice-delivery-order-list', invoiceNo, 'All']"
          >
            All
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('Waiting')"
            [routerLinkActive]="['active']"
            [routerLink]="['/income/invoice-delivery-order-list', invoiceNo, 'Waiting']"
            >Waiting</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('Ready')"
            [routerLinkActive]="['active']"
            [routerLink]="['/income/invoice-delivery-order-list', invoiceNo, 'Ready']"
            >Ready</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('Shipped')"
            [routerLinkActive]="['active']"
            [routerLink]="['/income/invoice-delivery-order-list', invoiceNo, 'Shipped']"
            >Shipped</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link f-ssb-14px nav-option"
            (click)="pageLoad('Received')"
            [routerLinkActive]="['active']"
            [routerLink]="['/income/invoice-delivery-order-list', invoiceNo, 'Received']"
            >Received</a
          >
        </li>
      </ul>
  
    <div class="headSection">
      <div class="row">
        <ag-grid-angular
          style="font-size: 14px"
          class="ag-theme-balham col"
          [rowData]="allData"
          [columnDefs]="columnDefs"
          [domLayout]="domLayout"
          [defaultColDef]="defaultColDef"
        ></ag-grid-angular>
      </div>
    </div>
  </app-dashboard>
  