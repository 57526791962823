<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle">Rollback Depreciation</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')"><span aria-hidden="true" class="crossIcon">&times;</span></button>
</div>
<hr>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="RollBackDepreciationForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-12">
                                <label for="SelectedDepreciationDate" class="formLabelFontWeight">Rollback To <span class="required-sign text-red">*</span></label>
                                <ng-select [items]="toDateList" [clearable]="false" bindLabel="Text" bindValue="Value" formControlName="toDate"></ng-select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group col-sm-12">
                                <p class="font-weight-bold">If you click Save, the following will happen:</p>
                                <ul>
                                    <li>All depreciation in the Rollback period will be reversed</li>
                                    <li>Any registered assets will remain registered</li>
                                    <li>Any disposed assets will remain disposed</li>
                                    <li>Any deleted assets will remain deleted</li>
                                </ul>  
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 text-align-right">
                            <button type="button" class="eCount-def-btn ml-4" (click)="SaveDepreciation()">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>