import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../../service/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { MainServiceService } from '../../../service/Main/main-service.service';
import * as firebase from 'firebase';
import { WindowService } from '../../../service/Windows/window.service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  public recaptchaVerifier: firebase.auth.RecaptchaVerifier;

  forgotPasswordForm: FormGroup;
  passwordFrom: FormGroup;
  verfiationForm: FormGroup;
  loginForm: FormGroup;

  showHideEmailMessage: boolean = false;
  showVerificationDiv: boolean = true;
  showPasswordDiv: boolean = true;
  isMobileNo: boolean = false;
  submitted = false;
  emailAddress = null;
  windowRef: any;

  constructor(public fb: FormBuilder,
    private router: Router,
    private mainService: MainServiceService,
    public authService: AuthService,
    private toaster: ToastrService,
    private SpinnerService: NgxSpinnerService,
    public win: WindowService,
    private appConfig: AppConfig) {

    if (!firebase.apps.length) {
      firebase.initializeApp(appConfig.firebaseConfig);
    }
  }

  ngOnInit(): void {
    this.windowRef = this.win.windowRef;
    this.registerData();
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
  }

  registerData() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{11}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
    })
    this.verfiationForm = this.fb.group({
      OTPCode: ['', [Validators.required]],
    })
    this.passwordFrom = this.fb.group({
      Password: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    })
    this.loginForm = this.fb.group({
      Email: [''],
      Password: ['']
    })
  }

  validateEmailOrPhoneNo = emailtxtbx => {
    var emailPattern = /^[a-z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/;
    this.isMobileNo = emailtxtbx.match(emailPattern) ? false : true;
  }

  validatePassword = password => {
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{5,}$/
    return re.test(password)
  }

  submitLoginName = () => {
    this.mainService.CheckForgotPasswordEmail(this.forgotPasswordForm.value.email).subscribe((x) => {
      if (x.Success) {
        this.submitLoginNameService();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
        this.toaster.warning('The email or Phone number you given is not registered in our system');
    })
  }

  submitLoginNameService = () => {

    if (this.forgotPasswordForm.invalid) {
      this.toaster.warning('Please fill all the required fields!');
      return;
    }

    this.validateEmailOrPhoneNo(this.forgotPasswordForm.value.email);

    if (this.isMobileNo) { // Mobile No

      const appVerifier = this.recaptchaVerifier;
      const phoneNumberString = "+88" + this.forgotPasswordForm.value.email; //"+8801715425456" this.ngForm.value.phonenumber;

      firebase.auth().signInWithPhoneNumber(phoneNumberString, appVerifier)
        .then((confirmationResult) => {
          this.showHideEmailMessage = true;
          this.showVerificationDiv = false;
          this.windowRef.confirmationResult = confirmationResult;
        })
        .catch((err) => {
          console.log('sms not sent', err);
        });
    }

    if (!this.isMobileNo) {  //email
      this.SpinnerService.show();
      this.authService.SendForgetPasswordOTP(this.forgotPasswordForm.value).subscribe(res => {
        if (res.Success) {
          this.showHideEmailMessage = true;
          this.showVerificationDiv = false;
          this.SpinnerService.hide();
          this.toaster.success(res.Message, 'Success!');
        } else {
          this.SpinnerService.hide();
          if (!res.Errors) {
            this.toaster.warning(res.Message);
          } else {
            let error = res.Errors
            error.map((s) => {
              this.toaster.warning(s.Description);
            })
          }

        }
      }, err => {
        this.SpinnerService.hide();
        this.toaster.error('Wrong Email or Contact No, please try again', 'Wrong!');
      })
    }
  }

  submitValidationCode = () => {

    if (this.verfiationForm.invalid) {
      this.toaster.warning('This field is required');
      return;
    }

    if (this.isMobileNo) {
      this.windowRef.confirmationResult.confirm(this.verfiationForm.value.OTPCode).then(result => {
        var user = result.user;
        this.showHideEmailMessage = true;
        this.showVerificationDiv = true;
        this.showPasswordDiv = false;
        this.toaster.success("Verification Successful", 'Success!');
      }).catch(err => {
        console.log(err);
        this.toaster.warning(err);
      });
    }

    if (!this.isMobileNo) { //email
      this.authService.IsValidateOtpCode(this.forgotPasswordForm.value.email, this.verfiationForm.value.OTPCode).subscribe(res => {
        if (res.Success) {
          this.showHideEmailMessage = true;
          this.showVerificationDiv = true;
          this.showPasswordDiv = false;
          this.toaster.success(res.Message, 'Success!');
        } else {
          if (!res.Errors) {
            this.toaster.warning(res.Message);
          } else {
            let error = res.Errors
            error.map((s) => {
              this.toaster.warning(s.Description);
            })
          }
        }
      }, err => {
        this.toaster.error('Wrong Email or Contact No, please try again', 'Wrong!');
      })
    }
  }



  submitPassword = () => {

    if (this.passwordFrom.invalid) {
       if(this.passwordFrom.value.Password.length==0||this.passwordFrom.value.ConfirmPassword.length==0)
       {
         this.toaster.warning('All the fields are required');
         return;
       }
       this.toaster.warning('Password should be minimum 6 characters long and must have one Capital letter and small letter');
       return;
    }
    if (!this.validatePassword(this.passwordFrom.value.Password)) {
      this.toaster.warning('Password should be minimum 6 characters  long and must have one Capital letter and small letter');
      return;
    }
    if (this.passwordFrom.value.Password != this.passwordFrom.value.ConfirmPassword) {
      this.toaster.warning('Password and confirm password should be same');
      return;
    }
    this.SpinnerService.show();
    this.authService.ResetPassword(this.forgotPasswordForm.value.email, this.passwordFrom.value.Password).subscribe(res => {
      if (res.Success) {
        this.SpinnerService.hide();
        this.toaster.success(res.Message, 'Success!');
        this.submitLoginForm();
      } else {
        this.SpinnerService.hide();
        if (!res.Errors) {
          this.toaster.warning(res.Message);
        } else {
          let error = res.Errors
          error.map((s) => {
            this.toaster.warning(s.Description);
          })
        }
      }
    }, err => {
      this.SpinnerService.hide();
      this.toaster.error('Wrong Email or Contact No, please try again', 'Wrong!');
    })
  }

  submitLoginForm() {

    this.loginForm.value.Email = this.forgotPasswordForm.value.email
    this.loginForm.value.Password = this.passwordFrom.value.Password;

    this.SpinnerService.show();

    this.authService.LoginService(this.loginForm.value).subscribe(res => {
      this.authService.setUserInfo(JSON.stringify(res));
      this.router.navigate(['/']);
      this.SpinnerService.hide();
      this.toaster.success('Login Successful', 'Success!');
    }, err => {
      console.log(err.error);
      this.toaster.error(err.error.Message);
      this.SpinnerService.hide();
    })
  }

}
