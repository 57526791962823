<div class="modal-header">
    <h4 class="modal-title" style="font-size: 17px; font-weight: 500;">Change Start Date</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" style="font-size: 25px">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body" style="padding: 0em 1em 1em 1em!important;">
    <div class="pb-4">

        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="changeStartDateForm" class="f-ss-14px">
                   
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12" style="display: flex;">
                            <div class="form-group  col-sm-12">
                                <label for="StartDate" style="font-weight: 600;">Start Date</label>
                                <input type="date" class="form-control form-input" formControlName="StartDate" id="cstest" data-date="" data-date-format="D MMM YYYY" (change)="showStartDate($event)">
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div class="col-md-12">

                <button type="button" [disabled]="changeStartDateForm.invalid" (click)="saveChangeStartDate()"
                    class="eCount-def-btn  ml-4 p-save-button">Save</button>

            </div>
        </div>
    </div>
</div>