<div class="modal-header">
    <div class="title-container">
        <h4 *ngIf="fromParent == undefined" class="modal-title modalTitle">Assign To</h4>
        <h4 *ngIf="fromParent != undefined" class="modal-title modalTitle">Transfer To</h4>
    </div>
    <div class="button-container">
        <button *ngIf="fromParent == undefined" type="button" class="eCount-def-btn ml-4" (click)="SaveAssignedItem()">Save</button>
        <button *ngIf="fromParent != undefined" type="button" class="eCount-def-btn ml-4" (click)="TransferAssignedItem()">Transfer</button>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<hr>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="AddAssignedItemForm" class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-4">
                                <label for="AssigneeName" class="formLabelFontWeight">Assignee Name <span class="required-sign text-red">*</span></label>
                                <ng-select [items]="assigneeList" (change)="selectAssignee()" [clearable]="false" bindLabel="Name" bindValue="Id" formControlName="AssigneeName" required></ng-select>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="AssigneeRole" class="formLabelFontWeight">Assignee Role</label>
                                <input class="form-control form-input" type="text" formControlName="AssigneeRole" readonly>
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="AssigneeEmail" class="formLabelFontWeight">Assignee Email <span class="required-sign text-red">*</span></label>
                                <input class="form-control form-input" type="Email" formControlName="AssigneeEmail" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-4">
                                <label for="AssetName" class="formLabelFontWeight">Asset Name <span class="required-sign text-red">*</span></label>
                                <ng-select [items]="UnAssignedFixedAssetItemList" *ngIf="canDisabledAssetName() == false" placeholder="Select Asset" bindLabel="AssetName" bindValue="Id" formControlName="AssetId" readonly></ng-select>
                                <label class="form-control form-input" *ngIf="canDisabledAssetName() == true" readonly>{{ AssetName }}</label>
                            </div>
                            <div class="form-group  col-sm-2">
                                <label for="AssetRate" class="formLabelFontWeight">Rate (1 - 5) <span class="required-sign text-red">*</span></label>
                                <input class="form-control form-input" type="text" pattern="[1-5]$" maxlength="1" oninput="this.value=this.value.replace(/[^1-5]/g,'');" formControlName="AssetRank" required>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="AssignedDate" class="formLabelFontWeight">Assigned Date <span class="required-sign text-red">*</span></label>
                                <input class="form-control form-input" type="date" formControlName="AssignedDate" id="adstest" data-date="" data-date-format="D MMM YYYY" (change)="showDefaultDate($event)" required>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="EstimatedReturnDate" class="formLabelFontWeight">Estimated Return Date</label>
                                <input class="form-control form-input" type="date" formControlName="EstimatedReturnDate" id="adstest2" data-date="" data-date-format="D MMM YYYY" [min]="AddAssignedItemForm.value.AssignedDate" (change)="showEstimatedReturnDate($event)">
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder" *ngIf="fromParent != undefined">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-12">
                                <label for="DamageReason" class="formLabelFontWeight">Comment</label>
                                <div class="textarea-wrapper">
                                    <textarea #myInput rows="5" maxlength="200" type="text" class="form-control brs custom-textarea" id="this-textarea"
                                        placeholder="Write down some important notes using only 200 characters" formControlName="DamageReason"></textarea>
                                    <span class="remaning" >{{myInput.value.length}}/200</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>