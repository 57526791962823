<div class="modal-header">
    <div class="title-container">
        <h4 class="modal-title modalTitle" *ngIf="parentStatus !== 'Unassigned'">Assign To</h4>
        <h4 class="modal-title modalTitle" *ngIf="parentStatus === 'Unassigned'">Assigned To</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
      <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<div class="assetDetails">
    <p>Asset No: {{ fromParent.AssetNo }}</p>
    <p>Asset Name: {{ fromParent.AssetName }}</p>
</div>  
<hr>
<div class="modal-body modalBody">
    <div class="pb-4">
        <div class="row" *ngIf="parentStatus != 'Unassigned'">
            <div class="col-md-12">
                <ul class="nav nav-tabs" style="margin-bottom: 30px;">
                    <li class="nav-item">
                      <a class="nav-link" (click)="setActivePanel(1)" [ngClass]="{'active': activeTabId == 1}">Current Assignee</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" (click)="setActivePanel(2)" [ngClass]="{'active': activeTabId == 2}">History</a>
                    </li>
                  </ul>
            </div>
        </div>
        <div class="row" *ngIf="activeTabId == 1 && parentStatus != 'Unassigned'">
            <div class="col-md-12">
                <div class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-3">
                                <label for="AssigneeId" class="formLabelFontWeight">Assignee No</label>
                                <label class="form-control form-input">{{ fromParent.AssignedNo }}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="AssigneeName" class="formLabelFontWeight">Assignee Name</label>
                                <label class="form-control form-input">{{ fromParent.AssigneeName }}</label>
                            </div>
                            <div class="form-group col-sm-3">
                                <label for="AssigneeRole" class="formLabelFontWeight">Assignee Role</label>
                                <label class="form-control form-input">{{ fromParent.AssigneeRole }}</label>
                            </div>
                            <div class="form-group col-sm-3">
                                <label for="AssigneeEmail" class="formLabelFontWeight">Assignee Email</label>
                                <label class="form-control form-input">{{ fromParent.AssigneeEmail }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-3">
                                <label for="AssetName" class="formLabelFontWeight">Asset Name</label>
                                <label class="form-control form-input">{{ fromParent.AssetName }}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="AssetRate" class="formLabelFontWeight">Asset Rate</label>
                                <label class="form-control form-input">{{ fromParent.AssetRank }}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="AssignedDate" class="formLabelFontWeight">Assigned Date</label>
                                <label class="form-control form-input">{{ fromParent.AssignedDate }}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="EstimatedReturnDate" class="formLabelFontWeight">Estimated Return Date</label>
                                <label class="form-control form-input">{{ fromParent.EstimatedReturnDate }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <div class="form-group  col-sm-6">
                                <label for="Comment" class="formLabelFontWeight">Comment</label>
                                <label class="form-control form-input">{{ fromParent.DamageReason }}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="UsedDays" class="formLabelFontWeight">Used Days</label>
                                <label class="form-control form-input">{{ fromParent.UsedDays }}</label>
                            </div>
                            <div class="form-group  col-sm-3">
                                <label for="RemainingDays" class="formLabelFontWeight">Remaining Days</label>
                                <label *ngIf="fromParent.RemainingDays != 0" class="form-control form-input">{{ fromParent.RemainingDays }}</label>
                                <label *ngIf="fromParent.RemainingDays == 0" class="form-control form-input"> - </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="activeTabId == 2">
            <div class="col-md-12">
                <div class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <table class="table f-ss-14px">
                                <thead>
                                    <tr class="tableHeader">
                                        <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Assignee Name</th>
                                        <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Assignee No</th>
                                        <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Used Days</th>
                                        <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Asset Rank</th>
                                        <th scope="col" class="table-header-color text-align-left col-md-4" style="color: #02aced;">Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of itemsList">
                                        <td class="text-align-left col-md-2">{{ item.AssigneeName }}</td>
                                        <td class="text-align-left col-md-2">{{ item.AssignedNo }}</td>
                                        <td class="text-align-left col-md-2">{{ item.UsedDays }} days</td>
                                        <td class="text-align-left col-md-2">{{ item.AssetRank }}</td>
                                        <td class="text-align-left col-md-4">{{ item.DamageReason }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="parentStatus === 'Unassigned'">
            <div class="col-md-12">
                <div class="f-ss-14px">
                    <div class="row rowBottomBorder">
                        <div class="col-sm-12 d-flex">
                            <table class="table f-ss-14px">
                                <thead>
                                    <tr class="tableHeader">
                                        <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Assignee Name</th>
                                        <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Assignee Id</th>
                                        <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Used Days</th>
                                        <th scope="col" class="table-header-color text-align-left col-md-2" style="color: #02aced;">Asset Rank</th>
                                        <th scope="col" class="table-header-color text-align-left col-md-4" style="color: #02aced;">Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of itemsList">
                                        <td class="text-align-left col-md-2">{{ item.AssigneeName }}</td>
                                        <td class="text-align-left col-md-2">{{ item.AssignedNo }}</td>
                                        <td class="text-align-left col-md-2">{{ item.UsedDays }} days</td>
                                        <td class="text-align-left col-md-2">{{ item.AssetRank }}</td>
                                        <td class="text-align-left col-md-4">{{ item.DamageReason }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>