<div class="modal-header" style="padding-bottom: 10px;">
    <h4 class="modal-title col-md-5" style="font-size: 22px; font-weight: bold;">Version History</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true" class="crossIcon">&times;</span>
    </button>
</div>
<div class="modal-body modalBody">
    <div class="pb-4 m-2">
        <div class="row">
            <div class="col-md-12">
                <div class="row mm-5px pbp-20px">
                    <div class="col-sm-12" style="margin-top: 10px">
                        <!-- <div class="row">
                            <div class="col-sm-12 pl-4">
                                <p class="font-weight-bold" style="font-size: 16px;"><a class="a_link">Delete All Versions</a></p>
                            </div>
                        </div> -->
                        <div class="d-flex mt-3 mb-3">
                            <div class="col-md-1">No</div>
                            <div class="col-md-4">Modified</div>
                            <div class="col-md-3">Modified By</div>
                            <!-- <div class="col-md-2 pl-2">Size</div> -->
                            <div class="col-md-4">Status</div>
                        </div>
                        <div *ngFor="let item of versions; let i = index">
                            <div class="invoiceGridItemRow d-block pb-1">
                                <div style="margin-left: 0px; margin-top: 2px" class="row">
                                    <div class="col-md-1 margin-top-5">
                                        <p>{{item?.TagsAttachmentVersions?.VersionNo}}</p>
                                    </div>
                                    <div class="col-md-4 margin-top-5">
                                        <div style="padding: 7px 10px; color: #c7c2c2;">
                                            <div class="dropdown" (mouseover)="setHoveredIndex(i)" (mouseleave)="resetHoveredIndex()">
                                                <div role="button" style="cursor: pointer; color: black;" [attr.data-toggle]="isHovered(i) ? 'dropdown' : null" aria-haspopup="false" aria-expanded="false">
                                                    {{ convertDateIntoDisplay(item?.TagsAttachmentVersions?.RestoredAt) }} <i id="attachment{{i}}" class="material-icons pt-1" [style.display]="isHovered(i) ? 'inline' : 'none'">arrow_drop_down</i>
                                                </div>
                                                <div class="dropdown-menu context-menu-wrapper" aria-labelledby="navbarDropdown"
                                                    >
                                                    <div class="context-menu-item pb-2" style="cursor: pointer;">
                                                      <span class="context-menu-text text-center" (click)="viewVersion(item)">View</span>
                                                    </div>
                                                    <div *ngIf="file?.ApprovalStatus != 3" class="context-menu-item pb-2"  style="cursor: pointer;">
                                                      <span class="context-menu-text text-center" (click)="restoreVersion(item.TagsAttachmentVersions)">Restore</span>
                                                    </div>
                                                    <!-- <div class="context-menu-item pb-2">
                                                        <span class="context-menu-text text-center" (click)="deleteVersion(item.TagsAttachmentVersions)">Delete</span>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 margin-top-5">
                                        <p>{{item?.ModifiedByName}}</p>
                                    </div>
                                    <!-- <div class="col-md-2 margin-top-5">
                                        <p>{{item.Size}}</p>
                                    </div> -->
                                    <div class="col-md-4 margin-top-5">
                                        <p>{{setStatus(item?.TagsAttachmentVersions?.Status)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-smart-modal #AttachmentViewer identifier="AttachmentViewer">
    <div class="pb-4">
      <span class="modalHeaderText pl-2" class="attachment-header">{{selected?.FileName}}</span>
      <hr class="notShow" />
      <div class="row pr-2 pl-2">
        <div class="col-md-12 notShow">
          <div class="row">
            <div class="col-md-4">
              <table style="color: #7f7f7f; font-size: 13px">
                <tr>
                  <td>Created Date :</td>
                  <td>{{ convertDateToDisplay(selected?.ActionAt) }}</td>
                </tr>
                <tr>
                  <td>Created By :</td>
                  <td>{{ versions[0]?.ModifiedByName }}</td>
                </tr>
              </table>
            </div>
            <div class="col-md-8" style="text-align: right">
              <a>
                <span class="option-icon-color" (click)="zoomIn()">
                  <i class="material-icons borderradiusleft attachmenticon">zoom_in</i>
                </span>
              </a>
              <a>
                <span class="option-icon-color" (click)="zoomOut()"
                  ><i class="material-icons attachmenticon">zoom_out</i>
                </span>
              </a>
              <a [hidden]="isPdf">
                <span class="option-icon-color" (click)="rotate('left')">
                  <i class="material-icons attachmenticon">rotate_left</i>
                </span>
              </a>
              <a [hidden]="isPdf">
                <span class="option-icon-color" (click)="rotate('right')">
                  <i class="material-icons attachmenticon">rotate_right</i>
                </span>
              </a>
              <a href="{{ fileUrl }}{{ selected?.BlobName }}" download="{{ selected?.BlobName }}" class="option-icon-color">
                <i class="material-icons attachmenticon">file_download</i>
              </a>
              <!-- <a>
                <span (click)="print(selected, 'contentToPrint', isPdf)" class="option-icon-color"><i class="material-icons-outlined attachmenticon">print</i></span>
              </a> -->
              <!-- <a>
                <span (click)="share(selected)" class="option-icon-color" data-toggle="modal" data-target="#shareModal">
                  <i class=" material-icons-outlined borderradiusright border-right attachmenticon">share</i>
                </span>
              </a> -->
            </div>
          </div>
        </div>
        <div id="contentToPrint" class="col-md-12">
          <div [hidden]="!isPdf">
            <pdf-viewer [src]="src" [render-text]="true" [fit-to-page]="true" [zoom]="zoom" style="display: block"></pdf-viewer>
          </div>
          <div [hidden]="isPdf">
            <div style="overflow: overlay">
              <img id="image" style="width: 100%" src="{{ src }}" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>