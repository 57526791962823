import { BrowserModule } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { AgGridModule } from 'ag-grid-angular';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponentComponent } from './components/header-component/header-component.component';
import { FooterComponentComponent } from './components/footer-component/footer-component.component';
import { SidebarComponentComponent } from './components/sidebar-component/sidebar-component.component';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';
import { AuthFooterComponent } from './components/auth-footer/auth-footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {AlertService} from './service/Alert/alert.service';
import { DatePipe } from '@angular/common';

//service
import { AuthService } from './service/auth/auth.service';
import { DataService } from './service/EventEmitter/data.service';
import { FileEmitterService } from './service/EventEmitter/file.emitter.service';

//ngRx
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducers/accounting.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ResetPasswordComponent } from './pages/Authentication/reset-password/reset-password.component';
import { PurchaseProductComponent } from './pages/Products/purchase-product/purchase-product.component';
import { EditPurchaseProductComponent } from './pages/Products/edit-purchase-product/edit-purchase-product.component';
import { ProductListComponent } from './pages/Products/product-list/product-list.component';
import { TooltipModule } from 'ng2-tooltip-directive';

import { JwtInterceptor } from './helper/jwt.interceptor';
import { ErrorInterceptor } from './helper/error.interceptor';
import { AuthGuard } from './auth.guard';
import { from } from 'rxjs';
import { RouterLinkRendereComponent } from './components/router-link-rendere/router-link-rendere.component';
import { PageHeaderTitleComponent } from './components/page-header-title/page-header-title.component';
import { ServiceLoaderComponent } from './components/service-loader/service-loader.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AttachmentUploaderComponent } from './components/attachment-uploader/attachment-uploader.component';
import{ DecimaNumericValidator } from './helper/DecimalNumeric.validator';
import{ DisableControlDirective } from './helper/disable-control-directive';
import { FileShowcaseComponent } from './pages/file-showcase/file-showcase.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { InvoiceSettingComponent } from './pages/Settings/invoice-setting/invoice-setting.component';
import { InvoicePreviewComponent } from './pages/invoice-preview/invoice-preview.component';
import { PrintFileComponent } from './pages/print-file/print-file.component';
import { ViewPurchaseProductComponent } from './pages/Products/view-purchase-product/view-purchase-product.component';

import { ChartsModule } from 'ng2-charts';
import { EditChartOfAccountComponent } from './pages/Accounting/edit-chart-of-account/edit-chart-of-account.component';
import { BalanceSheetComponent } from './pages/Reports/balance-sheet/balance-sheet.component';

import { StatementOfCashflowComponent } from './pages/Reports/statement-of-cashflow/statement-of-cashflow.component';
import { AppConfig } from './app.config';
import { SubscriptionComponent } from './pages/Settings/subscription/subscription.component';
import { SpendMoneyListComponent } from './pages/spend-money-list/spend-money-list/spend-money-list.component';
import { ReceiveMoneyListComponent } from './pages/receive-money-list/receive-money-list/receive-money-list.component';
import { ViewReceiveMoneyComponent } from './pages/view-receive-money/view-receive-money/view-receive-money.component';
import { SubscriptionListComponent } from './pages/subscription-List/subscription-list/subscription-list.component';

import { CreateProjectComponent } from './pages/Projects/create-project/create-project.component';
import { ProjectOverviewComponent } from './pages/Projects/project-overview/project-overview.component';
import { ProjectDetailsComponent } from './pages/Projects/project-details/project-details.component';

import { AssetTypeListComponent } from './pages/fixed-asset/asset-type-list/asset-type-list.component';
import { AssetTypeCreateComponent } from './pages/fixed-asset/asset-type-create/asset-type-create.component';
import { FixedAssetSetupComponent } from './pages/fixed-asset/fixed-asset-setup/fixed-asset-setup.component';
import { FixedAssetDraftComponent } from './pages/fixed-asset/fixed-asset-draft/fixed-asset-draft.component';
import { FixedAssetRegisteredComponent } from './pages/fixed-asset/fixed-asset-registered/fixed-asset-registered.component';
import { FixedAssetSolddisposedComponent } from './pages/fixed-asset/fixed-asset-solddisposed/fixed-asset-solddisposed.component';
import { FixedAssetDepreciateComponent } from './pages/fixed-asset/fixed-asset-depreciate/fixed-asset-depreciate.component';
import { ChangeStartDateComponent } from './pages/fixed-asset/change-start-date/change-start-date.component';
import { AssetItemCreateComponent } from './pages/fixed-asset/asset-item-create/asset-item-create.component';
import { AssetItemDetailsComponent } from './pages/fixed-asset/asset-item-details/asset-item-details.component';
import { QuotationListComponent } from './pages/Quotation/quotation-list/quotation-list.component';
import { QuotationCreateComponent } from './pages/Quotation/quotation-create/quotation-create.component';
import { QuotationDetailsComponent } from './pages/Quotation/quotation-details/quotation-details.component';
import { EditQuotationComponent } from './pages/Quotation/edit-quotation/edit-quotation.component';
import { SendMailComponent } from './pages/Quotation/send-mail/send-mail.component';
import { SendBillMailComponent } from './pages/Expense/send-bill-mail/send-bill-mail.component';
import { SendInvoiceMailComponent } from './pages/Income/send-invoice-mail/send-invoice-mail.component';
import { TransferMoneyListComponent } from './pages/transfer-money/transfer-money-list/transfer-money-list.component';
import { ViewTransferMoneyComponent } from './pages/transfer-money/view-transfer-money/view-transfer-money.component';
import { TaxRateListComponent } from './pages/Settings/tax-rate-list/tax-rate-list.component';
import { TaxRateCreateComponent } from './pages/Settings/tax-rate-create/tax-rate-create.component';
import { ViewSpendMoneyComponent } from './pages/spend-money-list/view-spend-money/view-spend-money/view-spend-money.component';
import { BasicBillListComponent } from './pages/Expense/basic-bill-list/basic-bill-list.component';
import { BasicBillCreateComponent } from './pages/Expense/basic-bill-create/basic-bill-create.component';
import { CompanyHistoryComponent } from './pages/user-company-history/company-history/company-history.component';
import { RepeatingBillListComponent } from './pages/Expense/repeating-bill-list/repeating-bill-list.component';
import { ItemCreateComponent } from './pages/Products/item-create/item-create.component';
import { ItemListComponent } from './pages/Products/item-list/item-list.component';

import { UserProfileComponent } from './pages/User-Profile/user-profile/user-profile.component';
import { ChangeUserPasswordComponent } from './pages/change-user-password/change-user-password.component';
import { SubscriptionRenewComponent } from './pages/subscription-renew/subscription-renew.component';

import { EditBasicBillComponent } from './pages/Expense/edit-basic-bill/edit-basic-bill.component';
import { FactoryInEntryComponent } from './pages/Inventory/factory-in-entry/factory-in-entry.component';
import { FactoryOutEntryComponent } from './pages/Inventory/factory-out-entry/factory-out-entry.component';
import { FactoryStockReportComponent } from './pages/Inventory/factory-stock-report/factory-stock-report.component';
import { TodaysTransactionComponent } from './pages/Reports/todays-transaction/todays-transaction.component';
import { ContactBalanceListComponent } from './pages/contact/contact-balance-list/contact-balance-list.component';
import { FactoryInListComponent } from './pages/Inventory/factory-in-list/factory-in-list.component';
import { FactoryOutListComponent } from './pages/Inventory/factory-out-list/factory-out-list.component';
import { FactoryInViewComponent } from './pages/Inventory/factory-in-view/factory-in-view.component';
import { FactoryOutViewComponent } from './pages/Inventory/factory-out-view/factory-out-view.component';
import { CashflowMovementDetailsComponent } from './pages/Reports/cashflow-movement-details/cashflow-movement-details.component';
import { FactoryInventoryPopupComponent } from './pages/Inventory/factory-inventory-popup/factory-inventory-popup.component';
import { AdvanceListComponent } from './pages/Accounting/advance/advance-list/advance-list.component';
import { AdvanceCreateComponent } from './pages/Accounting/advance/advance-create/advance-create.component';
import { InvoiceAdvanceAdjustmentComponent } from './pages/Income/invoice-advance-adjustment/invoice-advance-adjustment.component';
import { BillAdvanceAdjustmentComponent } from './pages/Expense/bill-advance-adjustment/bill-advance-adjustment.component';
import { QuotationPreviewComponent } from './pages/Quotation/quotation-preview/quotation-preview.component';
import { BillPreviewComponent } from './pages/Expense/bill-preview/bill-preview.component';
import { PurchaseOrderListComponent } from './pages/Procurement/purchase-order-list/purchase-order-list.component';
import { PurchaseOrderCreateComponent } from './pages/Procurement/purchase-order-create/purchase-order-create.component';
import { SalesOrderListComponent } from './pages/sales/sales-order-list/sales-order-list.component';
import { AdvanceViewComponent } from './pages/Accounting/advance/advance_view/advance-view/advance-view.component';
import { PurchaseOrderDetailsComponent } from './pages/Procurement/purchase-order-details/purchase-order-details.component';
import { PurchaseOrderPreviewComponent } from './pages/Procurement/purchase-order-preview/purchase-order-preview.component';
import { SendPurchaseOrderMailComponent } from './pages/Procurement/send-purchase-order-mail/send-purchase-order-mail.component';
import { PurchaseOrderEditComponent } from './pages/Procurement/purchase-order-edit/purchase-order-edit.component';
import { SalesOrderDetailsComponent } from './pages/sales/sales-order-details/sales-order-details.component';
import { SalesOrderProgressComponent } from './pages/sales/sales-order-progress/sales-order-progress.component';
import { StatusUpdateComponent } from './pages/sales/status-update/status-update.component';
import { SalesOrderCreateComponent } from './pages/sales/sales-order-create/sales-order-create.component';
import { SalesOrderEditComponent } from './pages/sales/sales-order-edit/sales-order-edit.component';
import { ConfirmationDialogComponentComponent } from './components/confirmation-dialog-component/confirmation-dialog-component.component';
import { SalesOrderPreviewComponent } from './pages/sales/sales-order-preview/sales-order-preview.component';
import { SendSalesOrderMailComponent } from './pages/sales/send-sales-order-mail/send-sales-order-mail.component';
import { MonthlyTabComponent } from './pages/monthly-tab/monthly-tab.component';
import { CreateShipmentComponent } from './pages/sales/create-shipment/create-shipment.component';
import { ShipmentEditComponent } from './pages/sales/shipment-edit/shipment-edit.component';
import { EditShipmentComponent } from './pages/sales/edit-shipment/edit-shipment.component';
import { ShipmentItemViewComponent } from './pages/sales/shipment-item-view/shipment-item-view.component';
import { ViewItemComponent } from './pages/sales/view-item/view-item.component';
import { PendingListComponent } from './pages/pending-list/pending-list.component';
import { StorageItemListComponent } from './pages/Products/storage-item-list/storage-item-list.component';
import { StorageItemCreateComponent } from './pages/Products/storage-item-create/storage-item-create.component';
import { ViewStorageItemComponent } from './pages/Products/view-storage-item/view-storage-item.component';
import { ApprovalSettingsComponent } from './pages/Settings/approval-settings/approval-settings.component';
import {StorageItemConsumptionListComponent} from './pages/Products/storage-item-consumption-list/storage-item-consumption-list.component';
import { ConsumptionHistoryListComponent } from './pages/Products/consumption-history-list/consumption-history-list.component';
import { CreatePopupItemComponent } from './pages/Products/create-popup-item/create-popup-item.component';
import { FixedAssetListComponent } from './pages/Inventory/fixed-asset-list/fixed-asset-list.component';
import { BookedStockViewComponent } from './pages/Products/booked-stock-view/booked-stock-view.component';
import { ViewSalesOrderComponent } from './pages/Products/view-sales-order/view-sales-order.component';
import { PendingListViewComponent } from './pages/pending-list-view/pending-list-view.component';
import { EditBillTransactionComponent } from './pages/Expense/edit-bill-transaction/edit-bill-transaction.component';
import { EditInvoiceTransactionComponent } from './pages/Income/edit-invoice-transaction/edit-invoice-transaction.component';
import { EditFactoryInComponent } from './pages/Inventory/edit-factory-in/edit-factory-in.component';
import { EditFactoryOutComponent } from './pages/Inventory/edit-factory-out/edit-factory-out.component';
import { PendingFactoryListComponent } from './pages/Inventory/pending-factory-list/pending-factory-list.component';
import { BusinessOwnerListComponent } from './pages/Business Owner/business-owner-list/business-owner-list.component';
import { BusinessOwnerCreateComponent } from './pages/Business Owner/business-owner-create/business-owner-create.component';
import { ShareCapitalCreateComponent } from './pages/Business Owner/share-capital-create/share-capital-create.component';
import { EditBusinessOwnerComponent } from './pages/Business Owner/edit-business-owner/edit-business-owner.component';
import { OwnerLoanCreateComponent } from './pages/Business Owner/owner-loan-create/owner-loan-create.component';
import { WarningPopupComponent } from './components/Warning-Popup/warning-popup/warning-popup.component';
import { GrantLoanListComponent } from './components/business-partner-loan/grant-loan-list/grant-loan-list.component';
import { BpLoanCreateComponent } from './components/business-partner-loan/bsuiness-partner-loan-create/bp-loan-create/bp-loan-create.component';
import { ViewBpLoanComponent } from './components/business-partner-loan/view-bp-loan/view-bp-loan/view-bp-loan.component';
import { ReceiveLoanListComponent } from './components/business-partner-loan/Receive-loan-list/receive-loan-list/receive-loan-list.component';
import { ViewOwnerLoanComponent } from './pages/Business Owner/view-owner-loan/view-owner-loan.component';
import { SalesOrderAdvanceComponent } from './pages/sales/sales-order-advance/sales-order-advance.component';
import { SalesOrderAdjustmentComponent } from './pages/sales/sales-order-adjustment/sales-order-adjustment.component';
import { BusinessPartnerReportComponent } from './pages/contact/business-partner-report/business-partner-report.component';
import { DocumentCreatePageComponent } from './pages/Document/document-create-page/document-create-page.component';
import { DocumentOverviewComponent } from './pages/Document/document-overview/document-overview.component';
import { DeliveryPartnerListComponent } from './pages/contact/delivery-partner-list/delivery-partner-list.component';
import { RejectPopupModalComponent } from './pages/Reject-popup-modal/reject-popup-modal/reject-popup-modal.component';
import { CompanyUserRoleListComponent } from './pages/Rule-Set-Up/company-user-role-list/company-user-role-list.component';
import { UserRoleSetUpComponent } from './pages/Rule-Set-Up/user-role-set-up/user-role-set-up.component';
import { BusinessUnitComponent } from './pages/Busines Unit/business-unit/business-unit.component';
import { UpdateReceiveMoneyComponent } from './pages/ReceiveMoney/update-receive-money/update-receive-money.component';
import { AddMoreUsersInSubscriptionComponent } from './pages/add-more-users-in-subscription/add-more-users-in-subscription.component';
import { RejectDocumentModalComponent } from './pages/Document/reject-document-modal/reject-document-modal.component';
import { DocumentInfoModalComponent } from './pages/Document/document-info-modal/document-info-modal.component';
import { SingleDocumentViewComponent } from './pages/Document/single-document-view/single-document-view.component';
import { DeliveryOrderComponent } from './pages/Inventory/delivery-order/delivery-order.component';
import { DeliveryOrderWaitingComponent } from './pages/Inventory/delivery-order-waiting/delivery-order-waiting.component';
import { ModalForCreateBillComponent } from './pages/Inventory/modal-for-create-bill/modal-for-create-bill.component';
import { InventoryOverviewReceiptComponent } from './pages/Inventory/inventory-overview-receipt/inventory-overview-receipt.component';
import { ReceiptWaitingComponent } from './pages/Inventory/receipt-waiting/receipt-waiting.component';
import { ModalForReceiveProductComponent } from './pages/Inventory/modal-for-receive-product/modal-for-receive-product.component';
import { ModalShippedProductComponent } from './pages/Inventory/modal-shipped-product/modal-shipped-product.component';
import { SalesOrderModalComponent } from './pages/Inventory/sales-order-modal/sales-order-modal.component';
import { ApprovalHistoryModalComponent } from './components/approval-history-modal/approval-history-modal/approval-history-modal.component';
import { PurchaseOrderBillDetailsComponent } from './pages/Procurement/purchase-order-bill-details/purchase-order-bill-details.component';
import { SalesOrderInvoiceDetailsComponent } from './pages/sales/sales-order-invoice-details/sales-order-invoice-details.component';
import { SalesOrderAdvanceDetailsComponent } from './pages/sales/sales-order-advance-details/sales-order-advance-details.component';
import { SalesOrderExpenseDetailsComponent } from './pages/sales/sales-order-expense-details/sales-order-expense-details.component';
import { SalesOrderDeliveryListComponent } from './pages/sales/sales-order-delivery-list/sales-order-delivery-list.component';
import { BillReceiptListComponent } from './pages/Expense/bill-receipt-list/bill-receipt-list.component';
import { InvoiceDeliveryOrderListComponent } from './pages/Income/invoice-delivery-order-list/invoice-delivery-order-list.component';
import { SalesOrderBookedStockPopupComponent } from './pages/sales/sales-order-booked-stock-popup/sales-order-booked-stock-popup.component';
import { AssigneeListComponent } from './pages/fixed-asset/assignee-list/assignee-list.component';
import { FixedAssetSettingsComponent } from './pages/fixed-asset/fixed-asset-settings/fixed-asset-settings.component';
import { AssetDisposeDetailsComponent } from './pages/fixed-asset/asset-dispose-details/asset-dispose-details.component';
import { ReviewDisposalJournalsComponent } from './pages/fixed-asset/review-disposal-journals/review-disposal-journals.component';
import { AddAssigneeComponent } from './pages/fixed-asset/add-assignee/add-assignee.component';
import { AssignedItemComponent } from './pages/fixed-asset/assigned-item/assigned-item.component';
import { AssignedItemDetailsComponent } from './pages/fixed-asset/assigned-item-details/assigned-item-details.component';
import { ReturnAssignedItemModalComponent } from './pages/fixed-asset/return-assigned-item-modal/return-assigned-item-modal.component';
import { FixedAssetRollbackDepreciationPopupComponent } from './pages/fixed-asset/fixed-asset-rollback-depreciation-popup/fixed-asset-rollback-depreciation-popup.component';
import { SubscriptionPlanBusinessTypeModalComponent } from './pages/Settings/subscription-plan-business-type-modal/subscription-plan-business-type-modal.component';
import { FixedAssetCreateBillComponent } from './pages/fixed-asset/fixed-asset-create-bill/fixed-asset-create-bill.component';
import { FixedAssetBillOverviewComponent } from './pages/fixed-asset/fixed-asset-bill-overview/fixed-asset-bill-overview.component';
import { FixedAssetBillDetailsComponent } from './pages/fixed-asset/fixed-asset-bill-details/fixed-asset-bill-details.component';
import { FixedAssetEditBillComponent } from './pages/fixed-asset/fixed-asset-edit-bill/fixed-asset-edit-bill.component';
import { FixedAssetAssignedUnassignedListComponent } from './pages/fixed-asset/fixed-asset-assigned-unassigned-list/fixed-asset-assigned-unassigned-list.component';
import { SubscriptionExpiredWarningModalComponent } from './pages/Subscription/subscription-expired-warning-modal/subscription-expired-warning-modal.component';
import { ReplenishmentComponent } from './pages/Products/replenishment/replenishment.component';
import { UpdateSpendMoneyComponent } from './pages/SpendMoney/update-spend-money/update-spend-money.component';
import { CreateFinishedProductMaterialFormulaComponent } from './pages/Manufacture/create-finished-product-material-formula/create-finished-product-material-formula.component';
import { FinishedProductMaterialFormulaListComponent } from './pages/Manufacture/finished-product-material-formula-list/finished-product-material-formula-list.component';
import { ProduceManufacutringOrderComponent } from './pages/Manufacture/produce-manufacutring-order/produce-manufacutring-order.component';
import { CreateManufacutringOrderComponent } from './pages/Manufacture/create-manufacutring-order/create-manufacutring-order.component';
import { ManufactureFormulaDetailsComponent } from './pages/Manufacture/manufacture-formula-details/manufacture-formula-details.component';
import { EditManufactureFormulaComponent } from './pages/Manufacture/edit-manufacture-formula/edit-manufacture-formula.component';
import { ManufactureOrderListComponent } from './pages/Manufacture/manufacture-order-list/manufacture-order-list.component';
import { ManufacutreOrderDetailsComponent } from './pages/Manufacture/manufacutre-order-details/manufacutre-order-details.component';
import { ManufactureBackOrderFlowTypeModalComponent } from './pages/Manufacture/manufacture-back-order-flow-type-modal/manufacture-back-order-flow-type-modal.component';
import { ManufactureOrderProduceDetailsComponent } from './pages/Manufacture/manufacture-order-produce-details/manufacture-order-produce-details.component';
import { EditManufactureOrderComponent } from './pages/Manufacture/edit-manufacture-order/edit-manufacture-order.component';
import { DocumentAdvanceSearchComponent } from './pages/Document/document-advance-search/document-advance-search.component';
import { MonthlyExpenseDetailsComponent } from './pages/Expense/monthly-expense-details/monthly-expense-details.component';
import { ReturnModalComponent } from './pages/Inventory/return-modal/return-modal.component';
import { ReturnPaymentHistoryComponent } from './pages/Accounting/return-payment-history/return-payment-history.component';
import { SalesReturnOverviewComponent } from './pages/Inventory/sales-return-overview/sales-return-overview.component';
import { ReturnPolicySettingsComponent } from './pages/Settings/return-policy-settings/return-policy-settings.component';
import { PurchaseOrderReceiptListComponent } from './pages/Procurement/purchase-order-receipt-list/purchase-order-receipt-list.component';
import { PurchaseReturnOverviewComponent } from './pages/Inventory/purchase-return-overview/purchase-return-overview.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UpdateItemQuantityPopupComponent } from './pages/Products/update-item-quantity-popup/update-item-quantity-popup.component';
import { ManufactureTimelinePopupComponent } from './pages/Manufacture/manufacture-timeline-popup/manufacture-timeline-popup.component';
import { PendingListDashboardComponent } from './pages/pending-list-dashboard/pending-list-dashboard.component';
import { DocumentSettingsComponent } from './pages/Settings/document-settings/document-settings.component';
import { CustomDocumentApprovalFlowModalComponent } from './pages/Document/custom-document-approval-flow-modal/custom-document-approval-flow-modal.component';
import { BusinessPartnerLoanEditComponent } from './components/business-partner-loan/business-partner-loan-edit/business-partner-loan-edit.component';
import { DocumentLockUnlockOverviewPageComponent } from './pages/Document/document-lock-unlock-overview-page/document-lock-unlock-overview-page.component';
import { DocumentLockModalComponent } from './pages/Document/document-lock-modal/document-lock-modal.component';
import { DocumentUnlockModalComponent } from './pages/Document/document-unlock-modal/document-unlock-modal.component';
import { DocumentActiveInactiveOverviewComponent } from './pages/Document/document-active-inactive-overview/document-active-inactive-overview.component';
import { DocumentVersionSettingsComponent } from './pages/Settings/document-version-settings/document-version-settings.component';
import { DocumentVersioningModalComponent } from './pages/Document/document-versioning-modal/document-versioning-modal.component';
import { DocumentTypeGroupSettingsComponent } from './pages/Settings/document-type-group-settings/document-type-group-settings.component';
import { DynamicLabelNameSettingsForDocumentInputsComponent } from './pages/Settings/dynamic-label-name-settings-for-document-inputs/dynamic-label-name-settings-for-document-inputs.component';
export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http)
}


@NgModule({
  declarations: [
    routingComponents,
    AppComponent,
    HeaderComponentComponent,
    FooterComponentComponent,
    SidebarComponentComponent,
    AuthHeaderComponent,
    AuthFooterComponent,
    ConsumptionHistoryListComponent,
    DashboardComponent,
    ResetPasswordComponent,
    PurchaseProductComponent,
    EditPurchaseProductComponent,
    ProductListComponent,
    RouterLinkRendereComponent,
    PageHeaderTitleComponent,
    ServiceLoaderComponent,
    AttachmentUploaderComponent,
    DecimaNumericValidator,
    DisableControlDirective,
    FileShowcaseComponent,
    InvoiceSettingComponent,
    InvoicePreviewComponent,
    PrintFileComponent,
    ViewPurchaseProductComponent,
    EditChartOfAccountComponent,
    BalanceSheetComponent,
    CreateProjectComponent,
    ProjectOverviewComponent,
    ProjectDetailsComponent,
    StatementOfCashflowComponent,
    StorageItemConsumptionListComponent,
    SubscriptionComponent,
    SpendMoneyListComponent,
    ReceiveMoneyListComponent,
    ViewReceiveMoneyComponent,
    SubscriptionListComponent,
    AssetTypeListComponent,
    AssetTypeCreateComponent,
    FixedAssetSetupComponent,
    FixedAssetDraftComponent,
    FixedAssetRegisteredComponent,
    FixedAssetSolddisposedComponent,
    FixedAssetDepreciateComponent,
    ChangeStartDateComponent,
    AssetItemCreateComponent,
    FactoryInventoryPopupComponent,
    AssetItemDetailsComponent,
    QuotationListComponent,
    QuotationCreateComponent,
    QuotationDetailsComponent,
    EditQuotationComponent,
    SendMailComponent,
    SendBillMailComponent,
    SendPurchaseOrderMailComponent,
    SendInvoiceMailComponent,
    TransferMoneyListComponent,
    ViewTransferMoneyComponent,
    TaxRateListComponent,
    TaxRateCreateComponent,
    ViewSpendMoneyComponent,
    BasicBillListComponent,
    BasicBillCreateComponent,
    CompanyHistoryComponent,
    RepeatingBillListComponent,
    ItemCreateComponent,
    ItemListComponent,
    UserProfileComponent,
    ChangeUserPasswordComponent,
    SubscriptionRenewComponent,
    EditBasicBillComponent,
    FactoryInEntryComponent,
    FactoryOutEntryComponent,
    FactoryStockReportComponent,
    TodaysTransactionComponent,
    ContactBalanceListComponent,
    FactoryInListComponent,
    FactoryOutListComponent,
    FactoryInViewComponent,
    FactoryOutViewComponent,
    CashflowMovementDetailsComponent,
    FactoryInventoryPopupComponent,
    AdvanceListComponent,
    AdvanceCreateComponent,
    InvoiceAdvanceAdjustmentComponent,
    BillAdvanceAdjustmentComponent,
    QuotationPreviewComponent,
    BillPreviewComponent,
    PurchaseOrderListComponent,
    PurchaseOrderCreateComponent,
    SalesOrderListComponent,
    AdvanceViewComponent,
    PurchaseOrderDetailsComponent,
    PurchaseOrderPreviewComponent,
    PurchaseOrderEditComponent,
    SalesOrderDetailsComponent,
    SalesOrderProgressComponent,
    StatusUpdateComponent,
    SalesOrderCreateComponent,
    SalesOrderEditComponent,
    ConfirmationDialogComponentComponent,
    SalesOrderPreviewComponent,
    SendSalesOrderMailComponent,
    MonthlyTabComponent,
    CreateShipmentComponent,
    ShipmentEditComponent,
    EditShipmentComponent,
    ShipmentItemViewComponent,
    ViewItemComponent,
    PendingListComponent,
    StorageItemListComponent,
    StorageItemCreateComponent,
    ViewStorageItemComponent,
    ApprovalSettingsComponent,
    CreatePopupItemComponent,
    FixedAssetListComponent,
    BookedStockViewComponent,
    ViewSalesOrderComponent,
    PendingListViewComponent,
    EditBillTransactionComponent,
    EditInvoiceTransactionComponent,
    EditFactoryInComponent,
    EditFactoryOutComponent,
    PendingFactoryListComponent,
    BusinessOwnerListComponent,
    BusinessOwnerCreateComponent,
    ShareCapitalCreateComponent,
    EditBusinessOwnerComponent,
    OwnerLoanCreateComponent,
    WarningPopupComponent,
    GrantLoanListComponent,
    BpLoanCreateComponent,
    ViewBpLoanComponent,
    ReceiveLoanListComponent,
    ViewOwnerLoanComponent,
    SalesOrderAdvanceComponent,
    SalesOrderAdjustmentComponent,
    BusinessPartnerReportComponent,
    DocumentCreatePageComponent,
    DocumentOverviewComponent,
    DeliveryPartnerListComponent,
    RejectPopupModalComponent,
    CompanyUserRoleListComponent,
    UserRoleSetUpComponent,
    BusinessUnitComponent,
    UpdateReceiveMoneyComponent,
    AddMoreUsersInSubscriptionComponent,
    SubscriptionRenewComponent,
    RejectDocumentModalComponent,
    DocumentInfoModalComponent,
    SingleDocumentViewComponent,
    DeliveryOrderComponent,
    DeliveryOrderWaitingComponent,
    ModalForCreateBillComponent,
    InventoryOverviewReceiptComponent,
    ReceiptWaitingComponent,
    ModalForReceiveProductComponent,
    ModalShippedProductComponent,
    SalesOrderModalComponent,
    ApprovalHistoryModalComponent,
    PurchaseOrderBillDetailsComponent,
    SalesOrderInvoiceDetailsComponent,
    SalesOrderAdvanceDetailsComponent,
    SalesOrderExpenseDetailsComponent,
    SalesOrderDeliveryListComponent,
    BillReceiptListComponent,
    InvoiceDeliveryOrderListComponent,
    SalesOrderBookedStockPopupComponent,
    AssigneeListComponent,
    FixedAssetSettingsComponent,
    AssetDisposeDetailsComponent,
    ReviewDisposalJournalsComponent,
    AddAssigneeComponent,
    AddAssigneeComponent,
    AddAssigneeComponent,
    AddAssigneeComponent,
    AssignedItemComponent,
    AssignedItemDetailsComponent,
    ReturnAssignedItemModalComponent,
    FixedAssetRollbackDepreciationPopupComponent,
    SubscriptionPlanBusinessTypeModalComponent,
    FixedAssetCreateBillComponent,
    FixedAssetBillOverviewComponent,
    FixedAssetBillDetailsComponent,
    FixedAssetEditBillComponent,
    FixedAssetAssignedUnassignedListComponent,
    SubscriptionExpiredWarningModalComponent,
    ReplenishmentComponent,
    UpdateSpendMoneyComponent,
    CreateFinishedProductMaterialFormulaComponent,
    FinishedProductMaterialFormulaListComponent,
    ProduceManufacutringOrderComponent,
    CreateManufacutringOrderComponent,
    ManufactureFormulaDetailsComponent,
    EditManufactureFormulaComponent,
    ManufactureOrderListComponent,
    ManufacutreOrderDetailsComponent,
    ManufactureBackOrderFlowTypeModalComponent,
    ManufactureOrderProduceDetailsComponent,
    EditManufactureOrderComponent,
    DocumentAdvanceSearchComponent,
    MonthlyExpenseDetailsComponent,
    ReturnModalComponent,
    ReturnPaymentHistoryComponent,
    SalesReturnOverviewComponent,
    ReturnPolicySettingsComponent,
    PurchaseOrderReceiptListComponent,
    PurchaseReturnOverviewComponent,
    UpdateItemQuantityPopupComponent,
    ManufactureTimelinePopupComponent,
    PendingListDashboardComponent,
    DocumentSettingsComponent,
    CustomDocumentApprovalFlowModalComponent,
    BusinessPartnerLoanEditComponent,
    DocumentLockUnlockOverviewPageComponent,
    DocumentLockModalComponent,
    DocumentUnlockModalComponent,
    DocumentActiveInactiveOverviewComponent,
    DocumentVersionSettingsComponent,
    DocumentVersioningModalComponent,
    DocumentTypeGroupSettingsComponent,
    DynamicLabelNameSettingsForDocumentInputsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    TooltipModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    DragDropModule,
    Ng2SearchPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    StoreModule.forRoot({ Accounting : reducer }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    NgSelectModule,
    SelectDropDownModule,
    AgGridModule.withComponents([]),
    NgxSpinnerModule,
    PdfViewerModule ,
    Ng2SearchPipeModule,
    NgbModule,
    ChartsModule,
    CKEditorModule
  ],
  providers: [
    AppConfig,
    AuthService,
    DataService,
    FileEmitterService,
    AuthGuard,
    AlertService,
    NgbActiveModal,
    DatePipe,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  entryComponents:[SalesOrderProgressComponent,StatusUpdateComponent],
  bootstrap: [AppComponent],

})
export class AppModule { }
