<app-dashboard>
    <app-page-header-title 
        mainTitle="Manufacturing Orders" 
        subTitle="{{ subTitle }}" 
        backURL="{{ backURL }}">
        <div class="full-width text-align-right">
            <input type="text" [(ngModel)]="searchText" (ngModelChange)="search()" placeholder="search" class="searchBox"/>
            <button type="button" class="eCount-def-btn ml-4" (click)=" CreateManufactureOrder()">Create MO</button>
            <button type="button" class="eCount-button3 ml-4" (click)=" refresh()">Refresh</button>
            <button type="button" class="eCount-button3 ml-4" (click)="onBtnExport()">Export</button>
        </div>
    </app-page-header-title>
    <ul class="nav nav-tabs justify-content-center" *ngIf="!isBackOrder">
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('All')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/manufacutring-order-list/All">
                    All
                </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Draft')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/manufacutring-order-list/Draft">
                    Draft
                </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Approve')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/manufacutring-order-list/Approve">
                    Approve
                </a>
        </li>
    </ul>
    <ul class="nav nav-tabs justify-content-center" *ngIf="isApprovedTabOpen">
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Approve')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/manufacutring-order-list/Approve">
                    All
                </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Waiting')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/manufacutring-order-list/Waiting">
                    Waiting
                </a>
        </li>
        <li class="nav-item">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('ToProcess')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/manufacutring-order-list/ToProcess">
                    To Process
                </a>
        </li>
        <li class="nav-item" *ngIf="!isBackOrder">
            <a class="nav-link f-ssb-14px nav-option" 
                (click)="pageLoad('Produced')" 
                [routerLinkActive]="['active']" 
                routerLink="/manufacture/manufacutring-order-list/Produced">
                    Produced
                </a>
        </li>
    </ul>
    <div class="headSection">
        <div class="row">
            <ag-grid-angular style="font-size: 14px" 
                class="ag-theme-balham col" 
                [rowData]="filteredOrderList" 
                [columnDefs]="ColumnDefs"
                [domLayout]="domLayout" 
                [defaultColDef]="defaultColDef" 
                [pagination]="true" 
                [paginationPageSize]="50" 
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</app-dashboard>