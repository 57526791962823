import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
import { CellClickedEvent, GridApi } from 'ag-grid-community';


@Component({
  selector: 'app-business-unit',
  templateUrl: './business-unit.component.html',
  styleUrls: ['./business-unit.component.css']
})

export class BusinessUnitComponent implements OnInit {

  buForm: FormGroup;
  gridApi;
  gridColumnApi;
  businessUnitList: any = [];
  unChangedBusinessUnitList: any = [];

  editType: 'fullRow' = 'fullRow';
  domLayout = 'autoHeight';
  defaultColDef = {
    resizable: true,
    flex: 1,
    cellClass: 'cell-wrap-text'
  };

  constructor(
    private fb: FormBuilder, 
    private toaster: ToastrService,
    private mainService: MainServiceService, 
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.initbuForm();
  }

  initbuForm = () => {
    this.buForm = this.fb.group({
      Name: ['', Validators.required],
      Address: ['', Validators.required],
      RoleId: ['']
    });
  }

  getData = () => {
    this.businessUnitList = [];
    this.mainService.GetAllBusinessUnit().subscribe((x) => {
      this.spinner.hide();
      x.map( p => p.Action = '<i class="material-icons editBtnIcon mt-2" style="color: gray;">edit</i>' );
      this.unChangedBusinessUnitList = x;
      this.businessUnitList = [...x, ...this.businessUnitList];
    }, (err) => {
      this.spinner.hide();
    });
  }

  onGridReady(event) {
    this.gridApi = event.api;
    this.gridApi.setHeaderHeight(35);
    this.gridColumnApi = event.columnApi;
    this.getData();
  }

  columnDefs = [
    { headerName: 'Name', field: 'Name', editable: (params) => params.node.data.editable, cellStyle: {color: 'blue', cursor: 'pointer'} },
    { headerName: 'Address', field: 'Address', editable: (params) => params.node.data.editable, cellStyle: {color: 'blue', cursor: 'pointer'} },
    { headerName: 'Created Date', field: 'CreatedAt', editable: false, valueFormatter: this.convertGridDateIntoDisplay },
    { headerName: 'Action', field: 'Action', editable: false, suppressMenu: true, suppressSorting: true,
      cellRenderer: function (params) { return `${params.value}` },
      onCellClicked: (event: CellClickedEvent) => this.onActionCellClicked(event)
    }
  ];


  convertGridDateIntoDisplay(date) {
    if (date.value != null)
      return moment(date.value).format("Do MMM YYYY");
  }

  onCellClicked(params: any): void {
    if (params.column.colId === 'Action') {
      const newValue = '<i class="material-icons acceptBtnIcon mt-2" style="color: green;">done</i> <i class="material-icons cancelBtnIcon mt-2" style="color: red;">close</i>';
      if (newValue !== null) {
        const newRowData = [...this.businessUnitList];
        newRowData[params.rowIndex].Action = newValue;
        newRowData[params.rowIndex].editable = true;
        this.businessUnitList = newRowData;
        const api: GridApi = params.api;
        api.applyTransaction({ update: [{ ...params.data, Action: newValue, editable: true }] });
        
        setTimeout(() => {
          api.setFocusedCell(params.rowIndex, 'Name', null);
          api.startEditingCell({ rowIndex: params.rowIndex, colKey: 'Name' });
        }, 0);
      }
    }
  }

  onActionCellClicked(event) {
    const targetElement = event.event.target as HTMLElement;
    if(targetElement.classList.contains('cancelBtnIcon')){
      this.getData();
    }
    if(targetElement.classList.contains('acceptBtnIcon')){
      this.UpdateData(event);
    }
  }
  
  onRowClicked(e) {
    const colHeaderName = this.gridApi.getFocusedCell().column.colDef.headerName;
  }

  save = () => {
    if (this.buForm.invalid){
      this.toaster.warning('please fill all the required field!');
      return;
    }
    this.spinner.show();
    let maxRoleId = this.businessUnitList.length > 0 ? Math.max(...this.businessUnitList.map(x => x.RoleId)) : 3;  // 3 because we have 3 default roles
    this.buForm.value.RoleId = maxRoleId + 1;
    this.mainService.saveBSUnit(this.buForm.value).subscribe((x) => {
      if (x.Success) {
        this.spinner.hide();
        this.setFormData();
        this.getData();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.spinner.hide();
    });
  }

  UpdateData(event){
    this.mainService.updateBSUnit(event.data).subscribe((x) => {
      if (x.Success) {
        this.toaster.success(x.Message);
        this.spinner.hide();
        this.getData();
      } else {
        this.toaster.warning(x.Message);
        this.spinner.hide();
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.spinner.hide();
    });
  }

  setFormData() {
    this.buForm.controls['Name'].patchValue('');
    this.buForm.controls['Address'].patchValue('');
    this.buForm.controls['RoleId'].patchValue('');
  }

}

