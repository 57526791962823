<app-dashboard>
    <app-page-header-title
        mainTitle="{{ loanModel.LoanNo}}"
        subTitle="Back"
        backURL= "{{url}}">
        <div class="text-align-right col-md-12 row">
            <div class="dropdown col-md-8">
 
            </div>
         <div class="dropdown col-md-3">
                <button type="button"  class="eCount-def-btn" (click)="openSettlementPopup()" [hidden]="IfSettle == true">New Settlement</button>
    
         </div>
        <div class="col-md-1">
           <button  type="button"  class="eCount-button3">Print</button>
       </div>

        </div>
    </app-page-header-title>
    <div class="row col-sm-12 pt-4">
    <div class="col-sm-4">
   
        <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Loan Type
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.LoanType}}

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Business Partner
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.OwnerName}}

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Description
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.Description}}

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Amount
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.TotalAmount}}

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Interest Amount
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.InterestAmount}}

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Total Amount
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.TotalAmount + loanModel.InterestAmount}}

            </div>
         </div>
     </div>
     <div class="col-sm-4">
   
        <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Date
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.FormattedDate}}

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Account
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.BankName}}

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Loan Terms
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.LoanPeriod}} (months)

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Unsettled Amount
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{(loanModel.TotalAmount + loanModel.InterestAmount) - loanModel.SettleAmount }}

            </div>
         </div>
         <div class="row pb-3"> 
            <div class="col-md-5 f-ss-14px-bold " > 
             
                Settled Amount
            </div>
            <div class="col-md-5 f-ss-14px " > 
             
              {{loanModel.SettleAmount }}

            </div>
         </div>
     </div>
    </div>
    <div class="row pt-5" [hidden]="!SettlementList?.length">
        <div class="col-md-12 invoice-grid f-ss-14px">
            <table class="w-100" border="1|0">
                <thead>
                  <tr>
                    <td>Settlement No</td>
                    <td class="pl-2">Date</td>
                    <td>Account</td>
                    <td>Amount</td>
                    <td> Description</td>

                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of SettlementList">
      
                        <td>{{item.SettleNo}}</td>
                        <td>{{item.DisplayDate}}</td>
                        <td>{{item.AccountName}}</td>
                        
                        <td>{{item.Amount | currency:'':''}}</td>
                        <td>{{item.Description}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <ngx-smart-modal #AddSettlement identifier="AddSettlement">
        <div>
            <span class="modalHeaderText pl-2">Add new Settlement</span>
        <hr>
        <div class="row pr-2 pl-2">
            <div class="col-md-12">
                    <form [formGroup]="SettlementForm" (ngSubmit)="submitDiscountAmount()">
                    <div class="row col-md-12">
                        <div class="form-group col-md-6">
                            <span class="formTitle">Date</span>
                            <input type="Date" formControlName="Date" class="form-control mt-1" >
                        </div>
                        <div class="form-group col-md-6">
                            <span class="formTitle">Account </span>
                            <ng-select [items]="bankAccounts" formControlName="AccountId"  bindLabel="AccountName" bindValue="GLAccountId" ></ng-select>
                        </div>
                    </div>
                    <div class="row col-md-12">
                        <div class="form-group col-md-6">
                            <span class="formTitle">Amount</span>
                            <input type="text" formControlName="Amount" class="form-control mt-1"  placeholder="Amount" appDecimaNumericValidator>
                        </div>
                        <div class="form-group col-md-6">
                            <span class="formTitle">Description</span>
                            <input type="text" formControlName="Description" class="form-control mt-1"  placeholder="">
                        </div>
                    </div>
                    <div class="row col-md-12">
                    <div class="form-group col-md-9"></div>
                    <div class="form-group col-md-3">
                        <button type="submit" class="eCount-def-btn  pr-4">Payment</button>
                    </div>
                </div>
                    </form>

            </div>
        </div>
        
        </div>
    </ngx-smart-modal>


</app-dashboard>
