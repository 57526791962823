import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { TagsAttachmentVersionsSetting } from 'src/app/models/tagsAttachmentVersionsSetting.model';
import { SettingsServiceService } from 'src/app/service/Settings/settings-service.service';

@Component({
  selector: 'app-document-version-settings',
  templateUrl: './document-version-settings.component.html',
  styleUrls: ['./document-version-settings.component.css']
})
export class DocumentVersionSettingsComponent implements OnInit {

  TagsAttachmentVersionSettingForm: FormGroup;
  tagsAttachmentVersionSetting: TagsAttachmentVersionsSetting;

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private settingsService: SettingsServiceService
  ) { }

  ngOnInit(): void {
    this.getTagAttachmentVersionSettings();
    this.initializeForm();
  }

  getTagAttachmentVersionSettings() {
    this.settingsService.GetTagAttachmentVersionSettings().subscribe(x => {
      this.tagsAttachmentVersionSetting = x;
      if(x != null) this.setParentData();
    });
  }

  setParentData() {
    this.TagsAttachmentVersionSettingForm.controls['Id'].patchValue(this.tagsAttachmentVersionSetting.Id);
    this.TagsAttachmentVersionSettingForm.controls['IsActive'].patchValue(this.tagsAttachmentVersionSetting.IsActive);
    this.TagsAttachmentVersionSettingForm.controls['VersionIncrementType'].patchValue(this.tagsAttachmentVersionSetting.VersionIncrementType);
    this.TagsAttachmentVersionSettingForm.controls['CompanyId'].patchValue(this.tagsAttachmentVersionSetting.CompanyId);
    this.TagsAttachmentVersionSettingForm.controls['MaxVersionNo'].patchValue(this.tagsAttachmentVersionSetting.MaxVersionNo);
  }

  initializeForm() {
    this.TagsAttachmentVersionSettingForm = this.fb.group({
      Id: [0],
      IsActive: [false],
      VersionIncrementType: [0],
      CompanyId: [0],
      MaxVersionNo:[0]
    });
  }

  Save() {
    this.spinner.show();
    this.settingsService.UpdateTagAttachmentVersionSettings(this.TagsAttachmentVersionSettingForm.value).subscribe(x => {
      if(x?.Success) {
        this.toaster.success(x?.Message);
      }
      else{
        this.toaster.error(x?.Message)
      }
      this.spinner.hide();
    });
  }

  incrementTypeChange(event) {
    this.TagsAttachmentVersionSettingForm.controls['DocumentApproval'].patchValue(event.value);
  }
}


