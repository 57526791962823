import { Component, OnInit ,NgZone,Input} from '@angular/core';
import { ProductServiceService } from '../../../service/Products/product-service.service';
import { DataService } from '../../../service/EventEmitter/data.service';
import { NgxSpinnerService } from "ngx-spinner";
import { IncomeServiceService } from '../../../service/Income/income-service.service'
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { AcountingServiceService } from '../../../service/Accounting/acounting-service.service'
import { ReceivemoneyServiceService } from 'src/app/service/ReceiveMoney/receivemoney-service.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-view-receive-money',
  templateUrl: './view-receive-money.component.html',
  styleUrls: ['./view-receive-money.component.css']
})
export class ViewReceiveMoneyComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private IncomeService: IncomeServiceService,
    private accountingService: AcountingServiceService,
    private receivemoneyService:ReceivemoneyServiceService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    ) { }
   private sub:any;
   id:any;
   chartOfAccount: any = [];
   Found: boolean=false;
   ReceiveMoney:any;
   allSaleableItem: any = [];
  ngOnInit(): void {
    this.getAllSaleableItem();
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.getDetails();
   });
  };
  formatItem(id) {
    let value = this.allSaleableItem.find(e => e.Id == id);
    if (!value) {
    } else {
      //return value.ItemCode + "-" + value.ItemName;
      return value.ItemName;
    }
  }
  getAllSaleableItem = () => {
    this.IncomeService.GetAllSaleableItem().subscribe((x) => {
      this.allSaleableItem = x;
    })
  }
  getAccountName=(item)=>
  {
    this.accountingService.GetChartOfAccounts().subscribe((s) => {
      this.chartOfAccount = s;

        item.ReceiveMoneyItems.forEach(element =>
        {
          console.log(element);
           var x=this.chartOfAccount.find(p=> p.Id===element.AccountId);
           element.Item=x.AccountName;

        });


      })
      return item;
  }
  getDetails()
  {
    this.receivemoneyService.GetReceiveMoneyDetails(this.id).subscribe((x)=>{
          if(x){
               this.Found=true;
               this.ReceiveMoney=x;
               console.log(this.ReceiveMoney);
               this.getAccountName(this.ReceiveMoney);

          }
    });
  }
  convertDateIntoDisplay = (date) => {
    let dateString = moment(date).format("Do MMM YYYY")
    return dateString;
  }

  convertToLocaleString(num: number) {
    return num.toLocaleString();
  }

  twoDecimalPlace = (num: number) => {
    const total = num.toFixed(2);
    return parseFloat(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  submitReceiveMoneyData(){
    this.spinner.show();
    this.receivemoneyService.submitReceiveMoney(this.id).subscribe((x)=>{
      this.spinner.hide();
      if(x.Success){
        this.toaster.success('Receive money successfully submitted.')
        this.router.navigate(['receivemoney/receive-money-list/all']);
      }
      else{
        this.toaster.error('Something went wrong!', 'Error!');
      }
    });
  }

  confirmReceiveMoneyRejection(){
    this.spinner.show();
    this.receivemoneyService.deleteReceiveMoney(this.id).subscribe((x)=>{
      this.spinner.hide();
      if(x.Success){
        this.toaster.success('Receive money successfully deleted.')
        this.router.navigate(['receivemoney/receive-money-list/all']);
      }
      else{
        this.toaster.error('Something went wrong!', 'Error!');
      }
    });
  }
}
