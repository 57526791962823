<app-auth-header></app-auth-header>
<!-- Page content -->
<div class="page-content">
  <!-- Main content -->
  <div class="content-wrapper">
    <div class="login">
      <div [hidden]="showGettingStartedDiv">
        <h1 class="titleText" style="font-size: 33px;">
          {{ 'AUTH.SIGNUP.tryECount' | translate }}
        </h1>
        <!-- <div class="text ">
                    <p style="text-align: center; margin-bottom: 0px">{{ 'AUTH.SIGNUP.unlimitedUsers' | translate}}</p>
                </div> -->
        <form [formGroup]="preregisterForm">
          <label class="form-group inputField">
            <input
              class="form-control form-input"
              placeholder=" "
              formControlName="firstname"
            />
            <span style="margin-left: 10px;">
              {{ 'AUTH.SIGNUP.name' | translate }}
            </span>
          </label>
          <label class="form-group inputField">
            <input
              class="form-control form-input"
              placeholder=" "
              formControlName="email"
            />
            <span>
              {{ 'AUTH.SIGNUP.emailAddress' | translate }} /
              {{ 'AUTH.SIGNUP.phone' | translate }}
            </span>
          </label>
          <div class="form-groupMsg">
            <span
              class="validationMsg text-danger"
              *ngIf="
                preregisterForm.controls['email'].hasError('required') &&
                (preregisterForm.controls['email'].dirty ||
                  preregisterForm.controls['email'].touched)
              "
            >
              This field is required
            </span>
            <span
              class="validationMsg text-danger"
              *ngIf="
                preregisterForm.controls['email'].hasError('pattern') &&
                (preregisterForm.controls['email'].dirty ||
                  preregisterForm.controls['email'].touched)
              "
            >
              Please enter valid email address or phone number
            </span>
          </div>
          <div class="form-checkbox pt-3">
            <label class="form-label text">
              <input
                type="checkbox"
                name="TermsAccepted"
                formControlName="acceptTerms"
              />
              I have read and I agree to the
              <a href="https://iquidi.com/terms" target="_blank">terms</a>
              ,
              <a href="https://iquidi.com/privacy" target="_blank">privacy</a>
              and
              <a href="https://iquidi.com/pricing" target="_blank">offer details</a>
            </label>
          </div>

          <button
            [disabled]="!preregisterForm.valid"
            type="submit"
            (click)="submitRegisterForm()"
            class="btn btn-primary inputField buttonStyle"
            style="margin-top: 20px;"
          >
            {{ 'AUTH.SIGNUP.submit' | translate }}
          </button>
          <div class="row ml-4 mt-4">
            <p style="margin-left: 50px;">Already have an account?</p>
            <a
              [routerLink]="['/auth/login']"
              style="padding-left: 5px;"
              class="signUpText"
            >
              Sign in
            </a>
          </div>
        </form>
        <div id="recaptcha-container" style="margin: 36px;"></div>
      </div>

      <div [hidden]="showVerificationDiv">
        <div *ngIf="isMobileNo">
          <h1 class="titleText" style="font-size: 30px; width: 500px;">
            {{ 'AUTH.SIGNUP.sendCodePhone' | translate }}
          </h1>
        </div>
        <div *ngIf="!isMobileNo">
          <h1 class="titleText" style="font-size: 30px; width: 500px;">
            {{ 'AUTH.SIGNUP.sendCodeEmail' | translate }}
          </h1>
        </div>

        <div class="text">
          <p style="text-align: center; margin-bottom: 0px;">
            {{ 'AUTH.SIGNUP.verifyCode' | translate }}
          </p>
        </div>
        <div>
          <form [formGroup]="verfiationForm">
            <label class="form-group inputField text-center">
              <input
                class="form-control form-input"
                placeholder=" "
                formControlName="OTPCode"
                maxlength="20"
              />
              <span>{{ 'AUTH.SIGNUP.verfiationCode' | translate }}</span>
            </label>
            <button
              type="submit"
              (click)="submitValidationCode()"
              class="btn btn-primary inputField buttonStyle"
              style="margin-top: 20px;"
            >
              {{ 'AUTH.SIGNUP.nextbtn' | translate }}
            </button>
          </form>
        </div>
      </div>
      <div [hidden]="showPasswordDiv">
        <h1
          class="title"
          style="font-size: 30px; text-align: center;"
          class="clr"
        >
          {{ 'AUTH.SIGNIN.createPassword' | translate }}
        </h1>
        <div>
          <form [formGroup]="passwordFrom">
            <label class="form-group inputField mb-0">
              <input
                type="password"
                class="form-control form-input"
                placeholder=" "
                formControlName="Password"
                maxlength="20"
              />
              <span>
                {{ 'AUTH.SIGNIN.password' | translate }}
              </span>
              <p class="hintText">At least 6 characters long with a small and a capital letter.</p>
            </label>
            <label class="frm inputField text-center mt-2">
              <input
                type="password"
                class="form-control form-input"
                placeholder=" "
                formControlName="ConfirmPassword"
                maxlength="20"
              />
              <span>{{ 'AUTH.SIGNIN.confirmPassword' | translate }}</span>
            </label>
            <button
              type="submit"
              (click)="submitPassword()"
              class="btn btn-primary inputField buttonStyle"
              style="margin-top: 20px;"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Main content -->
</div>
<app-service-loader></app-service-loader>
<app-auth-footer></app-auth-footer>
