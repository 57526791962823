import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AccountTypes } from 'src/app/models/accounting.model';
import { ItemCreateViewModel } from 'src/app/models-viewModels/itemCreateViewModel';
import { ItemDetails } from 'src/app/models/inventoryOverview.model';

@Injectable({
  providedIn: 'root'
})
export class ProductServiceService {
  baseurl = environment.baseUrl;

  constructor(private http: HttpClient) { }
 
  //save create product
  public saveProductData(data: ItemCreateViewModel): Observable<any> {
    return this.http.post<any>(this.baseurl + 'item/SaveOrUpdate', data);
  }
  public saveStorageItemData(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'StorageItem/SaveOrUpdate', data)
      .pipe(
        retry(1)
      );
  }
  public saveStorageItemConsumption(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'StorageItem/SaveConsumption', data)
      .pipe(
        retry(1)
      );
  }
  public updateProductData(data: any): Observable<any> {
    return this.http.post<any>(this.baseurl + 'item/update', data)
      .pipe(
        retry(1)
      );
  }
  // get saleable Item
  public GetAllProductSaleAble(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/getsaleableitems')
      .pipe(
        retry(1)
      )
  }
  // get saleable Item
  public GetAllProductPurchaseAble(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetPurchaseableItems')
      .pipe(
        retry(1)
      )
  }

  //get all Items
  public GetAllItem(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetItems')
      .pipe(
        retry(1)
      )
  }

  //get item by Id Items
  public GetItemById(data:any): Observable<any> {
    return this.http.get<any>(this.baseurl + `item/GetItemById?id=${data}`)
      .pipe(
        retry(1)
      );
  }
  public GetStorageItemById(data: any): Observable<any> {
    return this.http.get<any>(this.baseurl + `StorageItem/GetStorageItemById?id=${data}`)
      .pipe(
        retry(1)
      );
  }

  public GetChartOfAccountsforItems(): Observable<any> {
    return this.http.get<AccountTypes>(this.baseurl + 'ChartOfAccount/GetChartOfAccountsforItems')
      .pipe(
        retry(1)
      )
  }

  public GetItemList(typeId:number): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetItemList?typeId='+typeId)
      .pipe(
        retry(1)
      );
  }

  public getManufactureFinishedProducts(): Observable<ItemDetails[]> {
    return this.http.get<ItemDetails[]>(this.baseurl + 'item/GetManufactureFinishedProducts').pipe(retry(1));
  }

  public GetStorageItemList(typeId:number): Observable<any> {
    return this.http.get<any>(this.baseurl + 'StorageItem/GetStorageItemList?typeId=' +typeId)
      .pipe(
        retry(1)
      );
  }
  public GetTradingItems(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetTradingItems')
      .pipe(
        retry(1)
      );
  }
  public GetLastPlan(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetLastPlan')
      .pipe(
        retry(1)
      );
  }
  public GetRawMaterials(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetRawMaterials')
      .pipe(
        retry(1)
      );
  }

  public GetFinishProducts(): Observable<any> {
    return this.http.get<any>(this.baseurl + 'item/GetFinishProducts')
      .pipe(
        retry(1)
      );
  }
  public GetConsumptionHistory(itemid, id): Observable<any> {
    return this.http.get<any>(this.baseurl + `StorageItem/GetConsumptionHistoryList?itemid=${itemid}&id=${id}`)
      .pipe(
        retry(1)
      );
  }

  public MarkAsInactive(item): Observable<any> {
    return this.http.post<any>(this.baseurl + 'Item/MarkAsInactive', item);
  }

  public updateItemQuantity(ItemId, Quantity): Observable<any> {
    return this.http.get<any>(this.baseurl + `item/UpdateItemQuantityManually?itemId=${ItemId}&quantity=${Quantity}`);
  }

}
