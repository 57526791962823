import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal  } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../../service/Project/project.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { MainServiceService } from 'src/app/service/Main/main-service.service';
@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent implements OnInit {

  projectForm: FormGroup;
  public statusList: any =[];
  public contactList: any =[];
  loading = false;
  selectedCustomer: number;
  @Input() fromParent: any;
  constructor(private spinner: NgxSpinnerService, 
    public fb: FormBuilder, 
    private toaster: ToastrService,
    public activeModal: NgbActiveModal,
    private pService: ProjectService,
    private mainService:MainServiceService) { }

  ngOnInit(): void {
    this.getStatusList();
    this.initializeProjectForm();
  }

  private getStatusList = () => {
    this.pService.GetStatusList().subscribe((x) => {
      this.statusList = x;
    },(err)=>{});

    this.pService.GetContactList().subscribe((x) => {
      this.contactList = x;
    },(err)=>{});

  };

  initializeProjectForm() {
    console.log(this.fromParent);
    this.projectForm = this.fb.group({
      Id:[this.fromParent.Id],
      Title: [this.fromParent.Title, Validators.required],
      StatusId: [this.fromParent.StatusId],
      ContactId: [this.fromParent.ContactId==""?null:this.fromParent.ContactId],
      Deadline: '',
      EstimatedCost: [this.fromParent.EstimatedCost],
      WinPrice: [this.fromParent.WinPrice]
    });
  }
  CreateNewCustomer = (customerName) => {

    let customerData = {
      PrimaryPersonFirstName: customerName,
      CompanyName: customerName,
      IsCustomer : true,
      IsSupplier : false,
    }
    this.loading = true;

    this.mainService.saveContact(customerData).subscribe((x) => {
      if (x.Success) {
  
        this.toaster.success('Contact has been successfully added !');
        this.loading = false;
        this.contactList.push(x["Data"]);
        let customerObj = this.contactList.filter(x=>x.PrimaryPersonFirstName == customerName);
        this.selectedCustomer = customerObj[0]['Id'];
        this.getStatusList();
      } else {
        this.toaster.warning(x.Message);
      }
    }, err => {
      this.toaster.error('ooh, something went wrong !');
      this.loading = false;
    })
  }

  public saveProject(){
    if (this.projectForm.invalid){
      this.toaster.warning('Please fill all the required fields!');
      return;
    }
    this.spinner.show();
    this.pService.SaveProject(this.projectForm.value).subscribe((x)=>{
      if(x.Success){
        this.toaster.success(this.fromParent.Id==0? 'Project has been successfully added.':"Project information updated successfully.");
        this.activeModal.close(x);
      }
      else{
        this.toaster.error(x.Message);
      }
      this.spinner.hide();
    }, err=>{
        this.toaster.error('ooh, something went wrong !');
    });

  }
  showSelectedDate(e) {
    const value = this.projectForm.value.Deadline;
    $("#ptest").on("change", function() {
      this.setAttribute(
          "data-date",
          moment(value, "YYYY-MM-DD")
          .format( this.getAttribute("data-date-format") )
      );
  }).trigger("change");
  }

}
